import { getDefaultState } from '@/domain/employee/store/state'
import { VACATION_STATUS_ABORT_EMPLOYEE, VACATION_STATUS_ABORT_SYSTEM, VACATION_STATUS_APPROVE } from '@/utils/default'
import Vue from 'vue'

export const SET_CREATE_MODE = 'SET_CREATE_MODE'
export const SET_EMPLOYEE = 'SET_EMPLOYEE'
export const SET_ADDITIONAL_HOLIDAYS = 'SET_ADDITIONAL_HOLIDAYS'
export const CREATE_ADDITIONAL_HOLIDAYS = 'CREATE_ADDITIONAL_HOLIDAYS'
export const UPDATE_ADDITIONAL_HOLIDAYS = 'UPDATE_ADDITIONAL_HOLIDAYS'
export const DESTROY_ADDITIONAL_HOLIDAYS = 'DESTROY_ADDITIONAL_HOLIDAYS'
export const CHANGE_USER_ROLE = 'CHANGE_USER_ROLE'

export const CREATE_EVENT = 'CREATE_EVENT'
export const UPDATE_EVENT = 'UPDATE_EVENT'
export const CHANGE_AVATAR = 'CHANGE_AVATAR'

export const SET_ID = 'SET_ID'
export const SET_OPTIONS = 'SET_OPTIONS'
export const SET_DELETED_DATA = 'SET_DELETED_DATA'
export const SET_PROFILE = 'SET_PROFILE'
export const SET_USER = 'SET_USER'
export const SET_DEPARTMENTS = 'SET_DEPARTMENTS'
export const SET_DUTIES = 'SET_DUTIES'
export const SET_DISABILITIES = 'SET_DISABILITIES'
export const SET_CONTACT = 'SET_CONTACT'
export const SET_CONTRACTS = 'SET_CONTRACTS'
export const SET_CHANGELOGS = 'SET_CHANGELOGS'
export const SET_ROLES = 'SET_ROLES'
export const SET_CHILD = 'SET_CHILD'
export const SET_VACATIONS = 'SET_VACATIONS'
export const SET_EVENTS = 'SET_EVENTS'
export const SET_VACATION_INFO = 'SET_VACATION_INFO'
export const SET_PLUS_VACATIONS = 'SET_PLUS_VACATIONS'
export const CREATE_PLUS_VACATIONS = 'CREATE_PLUS_VACATIONS'
export const UPDATE_PLUS_VACATIONS = 'UPDATE_PLUS_VACATIONS'
export const DESTROY_PLUS_VACATIONS = 'DESTROY_PLUS_VACATIONS'
export const CHANGE_CONTRACT = 'CHANGE_CONTRACT'
export const SET_CONTRACT = 'SET_CONTRACT'
export const SET_CONTRACT_DETAIL = 'SET_CONTRACT_DETAIL'
export const CREATE_DISABILITY = 'CREATE_DISABILITY'
export const UPDATE_DISABILITY = 'UPDATE_DISABILITY'
export const DESTROY_DISABILITY = 'DESTROY_DISABILITY'
export const CREATE_CHILD = 'CREATE_CHILD'
export const UPDATE_CHILD = 'UPDATE_CHILD'
export const DESTROY_CHILD = 'DESTROY_CHILD'
export const SET_DISMISSED = 'SET_DISMISSED'
export const SET_DISMISSES = 'SET_DISMISSES'
export const CLEAR_STATE = 'RESET_STATE'
export const CREATE_VACATION = 'CREATE_VACATION'
export const UPDATE_VACATION = 'UPDATE_VACATION'
export const ABORT_VACATION = 'ABORT_VACATION'
export const APPROVE_VACATION = 'APPROVE_VACATION'

export default ({
    [SET_CREATE_MODE]: (state) => {
        state.isCreateMode = true
    },
    [SET_EMPLOYEE]: (state, payload) => {
        state.employee = payload
    },
    [SET_ADDITIONAL_HOLIDAYS]: (state, payload) => {
        state.additionalHolidays = payload
    },
    [CREATE_ADDITIONAL_HOLIDAYS]: (state, payload) => {
        state.additionalHolidays = [
            ...state.additionalHolidays,
            payload
        ]
    },
    [CHANGE_USER_ROLE]: (state, payload) => {
        Vue.set(state.employee.user, 'roles', [payload])
    },
    [CHANGE_AVATAR]: (state, payload) => {
        Vue.set(state.employee.user, 'avatarSrc', payload)
    },
    [UPDATE_ADDITIONAL_HOLIDAYS]: (state, payload) => {
        const index = state.additionalHolidays.findIndex(additional => additional.id === payload.id)
        if (index === -1) {
            return
        }
        Vue.set(state.additionalHolidays, index, payload)
    },
    [DESTROY_ADDITIONAL_HOLIDAYS]: (state, payload) => {
        state.additionalHolidays = state.additionalHolidays.filter(additional => additional.id !== payload)
    },
    [CREATE_DISABILITY]: (state, payload) => {
        state.employee.disabilities = [
            ...state.employee.disabilities,
            payload
        ]
    },
    [UPDATE_DISABILITY]: (state, payload) => {
        const index = state.employee.disabilities.findIndex(disability => disability.id === payload.id)
        if (index === -1) {
            return
        }
        Vue.set(state.employee.disabilities, index, payload)
    },
    [DESTROY_DISABILITY]: (state, payload) => {
        state.employee.disabilities = state.employee.disabilities.filter(({ id }) => id !== payload)
    },
    [CREATE_CHILD]: (state, payload) => {
        state.employee.children = [
            ...state.employee.children,
            payload
        ]
    },
    [UPDATE_CHILD]: (state, payload) => {
        const index = state.employee.children.findIndex(child => child.id === payload.id)
        if (index === -1) {
            return
        }
        Vue.set(state.employee.children, index, payload)
    },
    [DESTROY_CHILD]: (state, payload) => {
        state.employee.children = state.employee.children.filter(({ id }) => id !== payload)
    },
    [CREATE_EVENT]: (state, payload) => state.events = [...state.events, payload],
    [UPDATE_EVENT]: (state, payload) => {
        const index = state.events.findIndex(event => event.id === payload.id)
        if (index > -1) {
            Vue.set(state.events, index, payload)
        }
    },

    [SET_ID]: (state, payload) => {
        state.id = payload
    },
    [SET_OPTIONS]: (state, payload) => {
        state.options = payload
    },
    [SET_DELETED_DATA]: (state, payload) => {
        state.deletedData = payload
    },
    [SET_PROFILE]: (state, payload) => {
        state.profile = payload
    },
    [SET_USER]: (state, payload) => {
        state.user = payload
    },
    [SET_DEPARTMENTS]: (state, payload) => {
        state.base.department = payload
    },
    [SET_DUTIES]: (state, payload) => {
        state.base.duties = payload
    },
    [SET_DISABILITIES]: (state, payload) => {
        state.disabilities = payload
    },
    [SET_CONTACT]: (state, payload) => {
        state.contact = payload
    },
    [SET_CONTRACTS]: (state, payload) => {
        state.contracts = payload
    },
    [SET_VACATIONS]: (state, payload) => {
        state.vacations = payload
    },
    [SET_EVENTS]: (state, payload) => {
        state.events = payload
    },
    [SET_CHILD]: (state, payload) => {
        state.child = payload
    },
    [SET_CHANGELOGS]: (state, payload) => {
        state.changelogs = payload
    },
    [SET_ROLES]: (state, payload) => {
        state.roles = payload
    },
    [SET_VACATION_INFO]: (state, payload) => {
        state.vacationInfo = payload
    },
    [SET_PLUS_VACATIONS]: (state, payload) => {
        state.plusVacations = payload
    },
    [CREATE_PLUS_VACATIONS]: (state, payload) => {
        state.plusVacations.push(payload)
    },
    [UPDATE_PLUS_VACATIONS]: (state, payload) => {
        state.plusVacations = state.plusVacations.map(item => item.id === payload.id ? payload : item)
    },
    [DESTROY_PLUS_VACATIONS]: (state, payload) => {
        state.plusVacations = state.plusVacations.filter(({ id }) => id !== payload)
    },
    [SET_CONTRACT]: (state, payload) => {
        state.contract = payload
    },
    [SET_CONTRACT_DETAIL]: (state, payload) => {
        state.contractDetail = payload
    },
    [SET_DISMISSED]: (state, payload) => {
        state.dismissed = payload
    },
    [SET_DISMISSES]: (state, payload) => {
        state.dismisses = payload
    },
    [CLEAR_STATE]: (state) => {
        Object.assign(state, getDefaultState())
    },
    [CREATE_VACATION]: (state, payload) => state.vacations.push(payload),
    [UPDATE_VACATION]: (state, payload) => {
        state.vacations = state.vacations.map(vacation => vacation.id === payload.id ? payload : vacation)
    },
    [ABORT_VACATION]: (state, payload) => {
        let vacation = state.vacations.find(({ id }) => id === payload)
        if (vacation) {
            vacation.status = VACATION_STATUS_ABORT_EMPLOYEE
            vacation.deputy = null
            vacation.actions = { canVacationInfo: true }
        }
    },
    [APPROVE_VACATION]: (state, payload) => {
        let vacation = state.vacations.find(({ id }) => id === payload)
        console.debug('approve', payload, vacation)
        if (vacation) {
            vacation.status = VACATION_STATUS_APPROVE
        }
    }
})