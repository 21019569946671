<template>
	<v-row class="pa-5" style="max-width: 1024px;">
		<v-col cols="12" class="d-flex justify-end">
			<ui-button
				prepend-icon="plus"
				background="primary"
				:modal="{name: 'DutyCreateModal'}"
			>{{ $t("Nauja pareigybė") }}
			</ui-button>
		</v-col>
		<v-col cols="12">
			<hra-table
				:headers="headers"
				:rows="rows"
				need-actions
			>
				<template #col.actions-base="{row}">
					<div class="d-flex gap-1">
						<ui-button
							size="small"
							prepend-icon="edit"
							:modal="{name: 'DutyUpdateModal', props: {id: row.id, name: row.name}}"
						/>
						<ui-button
							v-if="row.employeesCount === 0"
							size="small"
							background="danger"
							prepend-icon="bin"
							:confirm="{
								props: {title: $t('Ar tikrai norite ištrinti pareigybę?')},
								on: {confirmed: (modal) => destroyDuty(modal, row.id)}
							}"
						/>
					</div>
				</template>
			</hra-table>
		</v-col>
	</v-row>
</template>

<script>
import UiButton from "@/domain/components/UiButton.vue";
import HraTable from "@/components/ui/table/HraTable.vue";
import {mapGetters} from "vuex";

export default {
	name: "CompanyDutiesPage",
	components: {HraTable, UiButton},
	data() {
		return {
			headers: [
				{name: 'name', text: this.$t('Pavadinimas')},
				{name: 'employeesCount', text: this.$t('Darbuotoju sk.')},
			],
		};
	},
	computed: {
		...mapGetters({
			rows: "companyStore/duties",
		}),
	},
	beforeCreate() {
		this.$store.dispatch("companyStore/fetchDuties");
	},
	methods: {
		destroyDuty(modal, id) {
			this.$store.dispatch("companyStore/destroyDuty", id);
			modal.close();
		},
	},
}
</script>

