<template>
    <div class='d-flex flex-row align-center justify-end'>
<!--        <ui-button-->
<!--            v-if='item.item.canSelectToSend'-->
<!--            class='px-0'-->
<!--            background='primary'-->
<!--            size='medium'-->
<!--            only-text-->
<!--            @click='handleOpenDocumentsSendModal'-->
<!--        >{{ $t('Siųsti') }}-->
<!--        </ui-button>-->
        <v-spacer />
        <v-menu
            v-if='isVisibleContextMenu'
            bottom
            right
        >
            <template v-slot:activator='{ on, attrs }'>
                <ui-button
                    class='px-0 mt-1'
                    background='primary'
                    only-text
                    v-bind='attrs'
                    v-on='on'
                >
                    <ui-icon>options</ui-icon>
                </ui-button>
            </template>

            <v-list class='pt-0 pb-0 document-context-menu'>
                <business-trip-context-menu-items :row='row' />
                <sick-leave-context-menu-items :row='row' />
            </v-list>
        </v-menu>
    </div>
<!--    <div class='d-flex justify-end'>-->
<!--        <sick-leave-abort-button :row='row' @aborted='handleUpdated' />-->
<!--        <sick-leave-update-button :row='row' @updated='handleUpdated' />-->
<!--        <sick-leave-info-button :row='row' />-->

<!--        <business-trip-update-button :row='row' :as-admin='actionAsAdmin' :as-leader='actionAsLeader' @updated='handleUpdated' />-->
<!--        <business-trip-info-button :row='row' :as-admin='actionAsAdmin' :as-leader='actionAsLeader' />-->
<!--    </div>-->
</template>

<script>
import UiButton from '@/domain/components/UiButton.vue'
import UiIcon from '@/domain/components/UiIcon.vue'
import BusinessTripContextMenuItems
    from '@/domain/departmentVacations/components/actions/BusinessTripContextMenuItems.vue'
import SickLeaveContextMenuItems from '@/domain/departmentVacations/components/actions/SickLeaveContextMenuItems.vue'
import checkIsAdminMixin from '@/mixins/checkIsAdminMixin'

const eventTypesWithContextMenu = [5001, 5002]
const eventTypesWithContextMenuOnlyForAdmin = [4001]

export default {
    name: 'DepartmentEventRowActions',
    components: { SickLeaveContextMenuItems, BusinessTripContextMenuItems, UiIcon, UiButton },
    mixins: [checkIsAdminMixin],
    props: {
        row: {type: Object, required: true}
    },
    computed: {
        isVisibleContextMenu() {
            return eventTypesWithContextMenu.includes(this.row.type)
                || (eventTypesWithContextMenuOnlyForAdmin.includes(this.row.type) && this.isAdmin)
        }
    }
}
</script>