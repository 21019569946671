import {vacationStatus, vacationType} from "@/utils/default";
import moment from "moment";

export const STATUS_WAIT = 101;
export const STATUS_APPROVE = 102;
export const STATUS_PAST = 103;
export const STATUS_ABORT = 200;
export const STATUS_ABORT_SYSTEM = 201;
export const STATUS_ABORT_EMPLOYEE = 202;
export const STATUS_ABORT_ADMIN = 203;

export const eventCalendarStatus = {
  [STATUS_WAIT]: "wait",
  [STATUS_APPROVE]: "approve",
  [STATUS_PAST]: "approve",
  [STATUS_ABORT_SYSTEM]: "abort",
  [STATUS_ABORT_EMPLOYEE]: "abort",
  [STATUS_ABORT_ADMIN]: "abort",
}

const state = () => ({
  loading: false,
  data: [],
  vacationEditId: null,
});

export const getters = {
  loading(state) {
    return state.loading;
  },
  data(state, getters, rootState, rootGetters) {
    const employeeId = rootGetters['user/id'];
    return state.data
      .sort((a, b) => {
        if (a.period.fromDate > b.period.fromDate) return 1;
        if (a.period.fromDate < b.period.fromDate) return -1;
        return 0;
      })
      .map(item => ({
        ...item,
        isDeputy: employeeId === item.deputy?.employee?.id,
      }));
  },
  wait(state, getters) {
    return getters.data.filter(item => item.statusCode === STATUS_WAIT);
  },
  nextVacations(state, getters) {
    const now = moment().format("YYYY-MM-DD");
    return getters.data.filter(item => {
      return item.period.fromDate > now && item.statusCode === STATUS_APPROVE;
    });
  },
  history(state, getters) {
    return getters.data;
  },
  calendarEvents(state, getters) {
    let events = getters.data
      .filter(item => item.statusCode < STATUS_ABORT)
      .map(item => ({
        id: item.id,
        fromDate: item.period.fromDate,
        toDate: item.period.toDate,
        name: `${item.isDeputy ? 'deputy' : 'vacation'}--${eventCalendarStatus[item.statusCode]}`,
        text: item.typeName,
        clickable: !item.isDeputy,
      }));

    if (state.vacationEditId) {
      return events.filter(event => event.id !== state.vacationEditId);
    }

    return events;
  },
  rows: (state) => state.data
    .filter(item => !item.isDeputy)
    .sort((a, b) => {
      if (a.period.fromDate > b.period.fromDate) {
        return -1;
      }
      if (a.period.fromDate < b.period.fromDate) {
        return 1;
      }
      return 0;
    })
    .map((item) => ({
      id: item.id,
      typeName: item.typeName,
      period: item.period,
      statusCode: item.statusCode,
      createLeader: item.createLeader,
      statusName: vacationStatus[item.statusCode],
    })),
};

const sortData = (itemA, itemB) => {
  if (itemA.period.fromDate < itemB.period.fromDate) {
    return -1;
  }
  if (itemA.period.fromDate > itemB.period.fromDate) {
    return 1;
  }
  return 0;
};

export const mutations = {
  loading(state) {
    state.loading = true;
  },
  loaded(state) {
    state.loading = false;
  },
  setData(state, payload) {
    state.data = payload.map(item => ({
      ...item,
      typeName: vacationType[item.typeId],
    }));
  },
  createVacation(state, payload) {
    state.data = [
      ...state.data,
      {
        ...payload,
        typeName: vacationType[payload.typeId],
      }
    ].sort(sortData);
  },
  updateVacation(state, payload) {
    state.data = state.data.map(item => item.id === payload.id ? ({
      ...payload,
      typeName: vacationType[payload.typeId],
    }) : item);
  },
  abortVacation(state, payload) {
    state.data = state.data.map(item => item.id === payload ? ({
      ...item,
      statusCode: STATUS_ABORT_EMPLOYEE,
      deputy: item.deputy != null ? ({
        ...item.deputy,
        statusCode: STATUS_ABORT_EMPLOYEE
      }) : null
    }) : item);
  },
  changeStatusCode: (state, {id, statusCode}) => {
    state.data = state.data.map(item => item.id === id ? ({
      ...item,
      statusCode,
    }) : item);
  },
  deleteVacation(state, payload) {
    state.data = state.data.filter(item => item.id !== payload);
  },
  approveDeputy(state, payload) {
    state.data = state.data.map(item => item.deputy?.id === payload ? ({
      ...item,
      statusCode: STATUS_APPROVE
    }) : item);
  },
  abortDeputy(state, payload) {
    state.data = state.data.filter(item => item.deputy?.id !== payload);
  },
  changeVacationEditId(state, payload) {
    state.vacationEditId = payload;
  },
  reset: (vuexState) => vuexState = state(),
};

export const actions = {
  async fetch({state, dispatch}) {
    if (state.data.length > 0) {
      return;
    }

    dispatch("fetchInit");
  },
  async fetchInit({commit}) {
    commit("loading");
    try {
      const year = (new Date()).getFullYear();
      const {data} = await this._vm.$api.employee.vacation.fetch({
        fromDate: `${year}-01-01`,
        toDate: `${year+3}-01-01`,
      });
      commit("setData", data);
    } catch (e) {

    } finally {
      commit("loaded");
    }
  },
  init: ({commit}, payload) => commit("setData", payload),
  async openModal({commit}, {id}) {
    commit('changeVacationEditId', id);
  },
  async closeModal({commit}) {
    commit('changeVacationEditId', null);
  },

  create: ({commit}, payload) => commit("createVacation", payload),
  update: ({commit}, payload) => commit("updateVacation", payload),
  change: ({commit}, {id, data}) => {
    commit("abortVacation", id);
    commit("createVacation", data);
  },
  changeStatus: ({commit}, payload) => commit("changeStatusCode", payload),
  destroy: ({commit}) => commit('reset'),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};