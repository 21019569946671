var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('hra-employee-crud-table',{scopedSlots:_vm._u([{key:"buttons",fn:function(){return [(!_vm.readOnly)?_c('ui-button',{attrs:{"size":"small","background":"primary","modal":{name: "EmployeeChildModal", props: {service: _vm.onCreate}}}},[_vm._v(" "+_vm._s(_vm.$t('Pridėti'))+" ")]):_vm._e()]},proxy:true}])},[_c('hra-table',{staticClass:"no-border-around",attrs:{"headers":_vm.headers,"rows":_vm.children,"need-actions":!_vm.readOnly,"hide-headers":""},scopedSlots:_vm._u([{key:"header.custom",fn:function(){return [_c('table-title-view',{staticClass:"mb-3 size-1",attrs:{"title":_vm.$t('Vaikai'),"with-border":""}})]},proxy:true},{key:"col.firstName",fn:function(ref){
var colValue = ref.colValue;
var row = ref.row;
return [_c('div',{staticClass:"pe-3 py-6"},[_vm._v(_vm._s(colValue)+" "+_vm._s(row.validated))])]}},{key:"col.birthDate",fn:function(ref){
var colValue = ref.colValue;
return [_c('div',{staticClass:"px-3 py-6"},[_vm._v(_vm._s(_vm.$t('Gimimo metai: {date}', { date: colValue })))])]}},{key:"col.disabilities",fn:function(ref){
var colValue = ref.colValue;
return [_c('div',{staticClass:"px-3 py-6"},[_vm._v(" "+_vm._s(_vm.$t("Neįgalumas: {param}", { param: colValue.length > 0 ? _vm.$t('taip') : _vm.$t('ne') }))+" ")])]}},{key:"col.disabilityNo",fn:function(ref){
var disabilities = ref.row.disabilities;
return [(disabilities.length > 0)?_c('div',{staticClass:"px-3 py-6"},[_vm._v(" "+_vm._s(_vm.$t('Darbo pažymėjimo nr.: {number}', { number: disabilities[0].number }))+" ")]):_c('div')]}},{key:"col.disabilityFrom",fn:function(ref){
var disabilities = ref.row.disabilities;
return [(disabilities.length > 0 && !!disabilities[0].toDate)?_c('div',{staticClass:"px-3 py-6"},[_vm._v(" "+_vm._s(_vm.$t('Neįgalumas nuo: {from} - {to}', { from: disabilities[0].fromDate, to: disabilities[0].toDate }))+" ")]):(disabilities.length > 0)?_c('div',{staticClass:"px-3 py-6"},[_vm._v(" "+_vm._s(_vm.$t('Neįgalumas nuo: {date}', { date: disabilities[0].fromDate }))+" ")]):_c('div')]}},{key:"col.actions-base",fn:function(ref){
var row = ref.row;
return [_c('hra-tooltip',{attrs:{"text":_vm.$t('Redaguoti')}},[_c('ui-button',{attrs:{"size":"small","prepend-icon":"edit","modal":{name: "EmployeeChildModal", props: {service: _vm.onUpdate, payload: row}}}})],1),_c('base-confirm',{attrs:{"title":_vm.$t('Ar tikrai norite ištrinti?'),"tag":"EMPLOYEE_CHILD_DESTROY_TAG"},on:{"confirm":function($event){return _vm.onDelete($event, row.id)}}},[_c('hra-tooltip',{attrs:{"text":_vm.$t('Ištrinti')}},[_c('ui-button',{attrs:{"size":"small","background":"danger-light","prepend-icon":"bin"}})],1)],1)]}},{key:"empty.text",fn:function(){return [_vm._v(_vm._s(_vm.$t('Įrašų sistemoje pridėta nebuvo.')))]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }