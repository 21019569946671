<template>
  <validation-provider
      :name="name"
      :rules="rules"
      v-slot="{ classes, errors }"
      tag="div"
      class="elvis-field"
      :class="propClasses"
  >
    <label v-if="!noLabel" class="elvis-field__label" :class="{...classes, ...propClasses}" :for="name">{{ label
	    }}</label>
    <div class="elvis-field__container" :class="{...classes, ...propClasses}">
      <slot name="prepend" />
      <input class="elvis-input" :disabled="disabled" :id="name" :type="type"
             :name="name" v-model="formData[name]" autocomplete="new-password"
             :placeholder="placeholder"/>
      <slot name="append" />
    </div>
  </validation-provider>
</template>

<script>
import {ValidationProvider} from "vee-validate";

export default {
  name: "ElvisInput",
  components: {ValidationProvider},
  inject: ["formName", "formData"],
  props: {
    name: {type: String, required: true},
    rules: {type: String, default: ""},
    noLabel: {type: Boolean, default: false},
	  label: {type: String, default: ''},
	  placeholder: {type: String, default: ''},
    type: {type: String, default: "text"},
    disabled: {type: Boolean, default: false},
    autocomplete: {type: String, default: "true"},
  },
  computed: {
    propClasses() {
      return {
        disabled: this.disabled,
      };
    }
  }
};
</script>
