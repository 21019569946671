<template>
    <custom-dialog :max-width='700' has-custom-footer>
        <template #title>{{ $t('Papildomos metinės atostogos') }}</template>
        <template #content>
            <Form v-model='formData' is-dialog :form-loader-name='loaderName' @submit='onSubmit'>
                <template #form>
                    <v-row>
                        <v-col cols='6'>
                            <select-field
                                name='calculateInYear'
                                :options='yesNoOptions'
                                :label="$t('Kaupiasi visus metus')"
                                v-model='formData.calculateInYear'
                            />
                        </v-col>
                        <v-col cols='6'>
                            <text-field
                                name='count'
                                type='number'
                                step='0.0001'
                                rules='required'
                                :label="$t('Kiekis (d. d.)')"
                                v-model='formData.count'
                            />
                        </v-col>
                        <v-col cols='6'>
                            <datepicker-field
                                name='from'
                                rules='required'
                                :label="$t('Data nuo')"
                                v-model='formData.from'
                            />
                        </v-col>
                        <v-col cols='6'>
                            <datepicker-field
                                v-if='formData.calculateInYear'
                                name='toDate'
                                :label="$t('Data iki')"
                                :min-date='formData.from'
                                v-model='formData.to'
                                can-clear
                            />
                        </v-col>
                        <v-col cols='12'>
                            <form-textarea
                                name='comment'
                                rules='required'
                                :label="$t('Komentaras')"
                                v-model='formData.comment'
                            />
                        </v-col>
                    </v-row>
                </template>

                <template #buttons='{loading}'>
                    <hra-button :size='buttonSize.small' @click='close'>
                        {{ $t('Uždaryti') }}
                    </hra-button>
                    <hra-button :color='uiColor.primary' :size='buttonSize.small' :type='buttonType.submit'
                                :loading='loadingButton'>
                        {{ $t('Išsaugoti') }}
                    </hra-button>
                </template>

                <template #footer-messages>
                    <message-alerts :listen='tag' />
                </template>
            </Form>
        </template>
    </custom-dialog>
</template>

<script>
import HraButton from '@/components/ui/button/HraButton'
import HraIcon from '@/components/ui/HraIcon'
import dialogMixin from '@/mixins/dialogMixin'
import CustomDialog from '@/components/ui/dialog/CustomDialog'
import FormMessages from '@/components/ui/form/FormMessages'
import FormAlert from '@/components/ui/form/FormAlert'
import FullRowLoader from '@/components/ui/FullRowLoader'
import Form from '@/components/ui/form/Form'
import DatepickerField from '@/domain/fields/DatepickerField'
import moment from 'moment'
import TextField from '@/domain/fields/TextField'
import FormTextarea from '@/components/ui/form/FormTextarea'
import SelectField from '@/domain/fields/SelectField'
import MessageAlerts from '@/domain/messages/MessageAlerts.vue'

export default {
    name: 'EmployeeVacationPlusModal',
    mixins: [dialogMixin],
    components: {
        MessageAlerts,
        FormTextarea,
        SelectField,
        TextField,
        DatepickerField, Form, FullRowLoader, FormAlert, FormMessages, CustomDialog, HraIcon, HraButton
    },
    props: {
        service: { type: Function, required: true },
        payload: { type: Object, default: null },
        userId: { type: Number, default: null }
    },
    watch: {
        'formData.calcInYear': {
            handler(newValue) {
                if (newValue === 0) {
                    this.formData.toDate = null
                }
            }
        }
    },
    data() {
        const today = moment().format('YYYY-MM-DD')
        const calcInYear = this.payload?.calculateInYear ?? false

        return {
            loadingButton: false,
            closeDialogDelay: 1000,
            message: null,
            minDate: today,
            tag: 'EMPLOYEE_ADDITIONAL_HOLIDAY_MODAL_SUBMIT_TAG',
            formData: {
                from: this.payload?.from ?? today,
                to: this.payload?.to ?? null,
                calculateInYear: calcInYear ? 1 : 0,
                count: this.payload?.count ?? '',
                comment: this.payload?.comment ?? '',
                type: this.payload?.type ?? 'custom',
                userId: this.userId
            }
        }
    },
    methods: {
        async onSubmit(payload) {
            this.loadingButton = true

            if (payload.calculateInYear === 0) {
                payload.to = null
            }

            const service = await this.service(this.tag, payload, this.payload?.id ?? null)

            if (service) {
                this.message = { type: 'success', text: this.$t('Išsaugotą sekmingai') }
                this.closeWithDelay()
            }

            this.loadingButton = false
        }
    }
}
</script>
