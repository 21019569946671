<template>
    <tr>
        <td class='DepartmentVacationCalendarTable__employee-td'>
            <employee-table-view class='px-5 py-2' :employee='employee' />
        </td>
        <td class='DepartmentVacationCalendarTable__balance-td'>{{ employee.balance }}</td>
        <td
            v-for='({colspan, event, classes}, key) in events'
            :key='key'
            :colspan='colspan'
            class='DepartmentVacationCalendarTable__col'
        >
            <department-vacation-calendar-event v-if='event' :event='event' :classes='classes' />
        </td>
    </tr>
</template>

<script>
import EmployeeTableView from '@/components/ui/views/EmployeeTableView'
import moment from 'moment'
import HraTooltip from '@/components/ui/HraTooltip'
import { mapGetters } from 'vuex'
import DepartmentVacationCalendarEvent from '@/domain/departmentVacations/components/DepartmentVacationCalendarEvent'

export default {
    name: 'DepartmentVacationCalendarRow',
    components: { DepartmentVacationCalendarEvent, HraTooltip, EmployeeTableView },
    props: {
        employee: { type: Object, required: true },
        startDate: { type: String, required: true },
        dayCount: { type: Number, required: true }
    },
    computed: {
        ...mapGetters({
            userId: 'currentUserStore/userId',
            isLeader: 'departmentVacationsStore/isLeader',
            isAdmin: 'departmentVacationsStore/isAdmin'
        }),
        events() {
            let endDate = moment(this.startDate).add(this.dayCount, 'days')

            let minDate = moment(this.startDate)
            let maxDate = minDate.clone().add(this.dayCount, 'days')
            let result = []
            this.employee.events
                .map(event => ({
                    ...event,
                    start: moment(event.start),
                    end: moment(event.end)
                }))
                .filter(({ start, end }) => {
                    return end >= minDate && start <= maxDate
                })
                .sort((a, b) => a.start < b.end ? -1 : 1)
                .forEach((event) => {
                    if (minDate > endDate) {
                        return
                    }

                    const { start, end } = event
                    let diff = start.clone().diff(minDate, 'days')

                    if (diff > 0) {
                        result.push({
                            colspan: diff,
                            event: null,
                            classes: {}
                        })
                    }

                    const viewStart = start > minDate ? start : minDate
                    const viewEnd = end > maxDate ? maxDate : end

                    const colspan = viewEnd.clone().diff(viewStart, 'days') + 1

                    if (colspan < 1) {
                        return
                    }

                    event.start = event.start.format('YYYY-MM-DD')
                    event.end = event.end.format('YYYY-MM-DD')
                    result.push({
                        colspan: colspan,
                        event: {
                            ...event,
                            text: `<b>${this.employee.name}</b><br>${event.text}<br>${event.start} - ${event.end}`,
                            clickable: null
                        },
                        classes: {
                            [event.name]: true,
                            'clickable': null
                        }
                    })

                    minDate = end.clone().add(1, 'day')
                })

            if (minDate < endDate) {
                result.push({
                    colspan: endDate.diff(minDate, 'days'),
                    event: null,
                    classes: {}
                })
            }

            return result
        }
    }
}
</script>