<template>
    <vue-draggable-resizable
        v-if='w > 0'
        ref='event'
        :class-name='className'
        class-name-handle='schedule-event__handle'
        :prevent-deactivation='true'
        :handles='[]'
        :grid='grid'
        :w='w'
        :h='h'
        :x='x'
        :y='y'
        :draggable='isDraggable'
        @dragging='onDragging'
        @dragstop='onDragStop'
    >
        <div class='schedule-event__content'>
            <div v-if='!eventOnlyDaysGrid' class='schedule-event__times'>
                <div class='schedule-event__start'>{{ eventStartFormatted }}</div>
                <div class='schedule-event__end'>{{ eventEndFormatted }}</div>
            </div>

            <div v-if='isHolidayEvent' class='schedule-event__content-header text-wrap'>A</div>
            <div v-if='isSickLeaveEvent' class='schedule-event__content-header text-wrap'>L</div>
            <div v-if='showDayGridTime' class='schedule-event__content-text text-wrap'>{{ text }}</div>

            <div
                v-if='!eventOnlyDaysGrid'
                v-for='({style, duration, start}, key) in breaks'
                :key='key'
                class='schedule-event__break'
                :style='style'
            >
                <div v-if='!eventOnlyDaysGrid' class='schedule-event__break-start'>{{ start }}</div>
                <div v-if='!eventOnlyDaysGrid' class='schedule-event__break-duration'>{{ duration }}</div>
            </div>

            <context-menu v-if='isAdmin'>
                <context-menu-item
                    v-if='isDeletable'
                    @click.prevent='onEdit'
                >
                    {{ $t('Redaguoti') }}
                </context-menu-item>
                <context-menu-item
                    v-if='isDeletable'
                    @click.prevent='onEditBreaks'
                >
                    {{ $t('Redaguoti pertraukas') }}
                </context-menu-item>
                <context-menu-item v-if='isDeletable' @click.prevent='onDelete'>{{ $t('Ištrinti') }}</context-menu-item>
            </context-menu>
        </div>
    </vue-draggable-resizable>
</template>

<script>
import moment from 'moment/moment'
import { mapGetters } from 'vuex'
import { SCHEDULE_TAB_EDIT_BREAKS } from '@/domain/scheduler/store/state'
import scheduleEventMixin from '@/domain/scheduler/mixins/scheduleEventMixin'
import ContextMenu from '@/domain/components/ContextMenu.vue'
import ContextMenuItem from '@/domain/components/ContextMenuItem.vue'
import {
    EVENT_SICK_LEAVE,
    eventHolidayTypes
} from '@/utils/default'

export default {
    name: 'ScheduleEvent',
    components: { ContextMenuItem, ContextMenu },
    mixins: [scheduleEventMixin],
    props: {
        event: { type: Object, required: true },
        dayWidth: { type: Number, required: true },
        rowHeight: { type: Number, required: true },
        onePixelToSecond: { type: Number, required: true },
        start: { type: Object, required: true },
        eventOnlyDaysGrid: { type: Boolean, default: false },
        grid: { type: Array, default: [1, 1] },
        maxX: { type: Number, default: null },
        maxY: { type: Number, default: null }
    },
    computed: {
        ...mapGetters({
            visibleBreaksIds: 'schedulerStore/visibleBreaksIds',
            currentTab: 'schedulerStore/currentTab'
        }),
        isDraggable() {
            return this.isScheduleEvent && this.isThisScheduleEvent && this.isAdmin && !this.isWantToWorkEvent
        },
        isScheduleEvent() {
            return this.event.type === 2001 // Schedule event
        },
        isWantToWorkEvent() {
            return this.event.wantToWork // Want to work event
        },
        isThisScheduleEvent() {
            return true
        },
        isDeletable() {
            return this.event.editable
        },
        isHolidayEvent() {
            return eventHolidayTypes.includes(this.event.type)
        },
        isSickLeaveEvent() {
            return this.event.type === EVENT_SICK_LEAVE
        },
        hasHoliday() {
            return this.event.hasHoliday
        },
        headerText() {
            if(this.event.type === 4001) {
                return 'S'
            }

            return 'A';
        },
        className() {
            return Object.entries(
                {
                    'schedule-event': true,
                    'schedule-event_day-view': this.eventOnlyDaysGrid,
                    'schedule-event_hour-view': !this.eventOnlyDaysGrid,
                    'schedule-event_other-event': this.isHolidayEvent || this.isSickLeaveEvent,
                    'schedule-event_want-to-work-event': this.isWantToWorkEvent
                }
            ).filter(([_, active]) => active).map(([name]) => name).join(' ')
        },
        breaks() {
            return this.event.breaks?.filter(({ scheduleBreakId }) => scheduleBreakId == null || this.visibleBreaksIds.includes(scheduleBreakId))
                .map(({ after, duration }) => {
                    const start = this.eventStartXMoment.clone().add('seconds', after * 60)

                    const totalMinutes = duration
                    const hours = Math.floor(totalMinutes / 60)
                    const minutes = totalMinutes % 60

                    let formattedDuration = ''
                    if (hours > 0) {
                        formattedDuration += `${hours}h`
                    }
                    if (minutes > 0) {
                        if (formattedDuration !== '') {
                            formattedDuration += ' '
                        }
                        formattedDuration += `${minutes}m`
                    }

                    return {
                        style: {
                            left: `${after * 60 * this.onePixelToSecond}px`,
                            width: `${duration * 60 * this.onePixelToSecond}px`
                        },
                        duration: formattedDuration,
                        start: start.format('HH:mm')
                    }
                })
        },
        eventStartFormatted() {
            if (!this.isScheduleEvent) {
                return ''
            }

            return this.eventStartXMoment.format('HH:mm')
        },
        eventEndFormatted() {
            if (!this.isScheduleEvent) {
                return ''
            }

            const duration = this.event.duration.seconds
            return this.eventStartXMoment.clone().add('seconds', duration).format('HH:mm')
        },
        text() {
            if (!this.isScheduleEvent) {
                return `00:00 23:59`
            }

            const duration = this.event.duration.seconds
            let start = this.start.clone().add('seconds', this.x / this.onePixelToSecond)

            if (this.eventOnlyDaysGrid) {
                const eventStart = moment(this.event.startWithTime)
                start.set('hour', eventStart.hour())
                    .set('minute', eventStart.minute())
                    .set('second', eventStart.second())
            }

            return start.format('HH:mm') + ' ' + start.add('seconds', duration).format('HH:mm')
        },
        showDayGridTime() {
            return this.eventOnlyDaysGrid && this.isScheduleEvent;
        }
    },
    methods: {
        onEdit() {
            this.$store.dispatch('modals/open', {
                name: 'UpdateScheduleUserEventModal',
                props: {
                    event: this.event
                }
            })
        },
        onEditBreaks() {
            this.$store.dispatch('modals/open', {
                name: 'UpdateScheduleEventBreaksModal',
                props: {
                    event: this.event
                }
            })
        },
        onDelete() {
            this.$store.dispatch('schedulerStore/deleteEventById', this.event.id)
        }
    }
}
</script>

<style lang='scss' scoped>
@import '@/assets/sass/core/colors';
.schedule-event {
    position: absolute;
    color: $white;
    z-index: 3 !important;

    &_other-event {
        .schedule-event__content {
            background-color: $schedule-color-others2;
        }
    }

    &_want-to-work-event {
        .schedule-event__content {
            background-color: $schedule-color-want-to-work;
        }
    }

    &:hover {
        z-index: 10 !important;
    }

    &__content {
        position: absolute;
        background-color: $schedule-color-worktime;
        inset: 15px 0 0;
        z-index: -1;
        display: flex;

        &:hover {
            box-shadow: 0 0 0 1px #0b0d21;
        }

        &:has(.schedule-event__break) {
            inset: 15px 0;
        }
    }

    &__times {
        top: -15px;
        display: flex;
        justify-content: space-between;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        gap: 5px;
        min-width: calc(100% - 2px);
    }

    &__start, &__end {
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 0.3px;
    }

    &__break {
        height: 100%;
        position: absolute;
        top: 0;
        background: $schedule-color-breaks;
        z-index: -1;
        display: flex;
        align-items: center;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            bottom: -8px;
            height: 8px;
            width: 1px;
            background: $schedule-color-breaks;
        }

        &-duration {
            color: $white;
            font-size: 13px;
            font-weight: 600;
            letter-spacing: 0.39px;
        }

        &-start {
            position: absolute;
            bottom: -14px;
            left: 2px;
            text-align: right;
            font-size: 9px;
            font-weight: 500;
            letter-spacing: 0.3px;
        }
    }

    &__content-header {
        width: fit-content;
        display: flex;
        padding: 2px 5px;
        font-size: 14px;
        line-height: 1;
        font-weight: 600;
        text-align: center;
        align-items: center;
        background: $schedule-color-others;
        border-radius: 4px 4px 0 0;
    }

    &__content-text {
        display: flex;
        padding: 1px 5px;
        height: 100%;
        width: 100%;
        align-items: center;
        text-align: center;
        justify-content: center;
        font-size: 12px;
        line-height: 1.4;
        font-weight: 600;
    }

    &_day-view &__content {
        inset: 4px;
        flex-direction: column;
        border-radius: 4px;
    }

    &_day-view &__content-header {
        width: 100%;
        justify-content: center;
    }

    &_hour-view {
        color: $greyscale-800;
    }

    &_hour-view &__content{
        inset: 25px 0 2px;
        height: 26px;
        border-radius: 4px;
    }

    &_hour-view &__content-header {
        background-color: transparent;
        color: $white;
        width: 100%;
        justify-content: center;
    }

    &.draggable {
        cursor: move;
    }
}
</style>