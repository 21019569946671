<template>
  <custom-dialog :max-width="521">
    <template #title>{{ title }}</template>
    <template #content>
      <Form
          ref="form"
          :form-loader-name="formLoaderName"
          is-dialog
          v-model="formData"
          @submit="onSubmit"
      >
        <template #form>
          <full-row-loader v-if="loading"/>
          <v-row v-else>
            <v-col cols="12">
              <form-text-field
                  rules="required"
                  :color-dot="formData.color"
                  name="name"
                  v-model="formData.name"
                  :label="$t('Pavadinimas')"
                  :placeholder="$t('Įrašyti pavadinimą')"
              />
            </v-col>
            <v-col cols="12">
              <form-colors
                  rules="required"
                  name="color"
                  :colors="colors"
                  v-model="formData.color"
                  :label="$t('Spalva')"
              />
            </v-col>
            <v-col cols="12">
	            <select-field
		            rules="required"
		            name="employee_id"
		            :label="$t('Vadovas')"
		            :single-line="false"
		            :options-service="employeeAutocompleteService.find"
		            v-model="formData.employee_id"
		            dynamic filtered
	            >
		            <template #selection="{item}">
			            <employee-table-view class="py-1" :employee="item.employee"/>
		            </template>
		            <template #item="{item}">
			            <employee-table-view class="py-1" :employee="item.employee"/>
		            </template>
	            </select-field>
            </v-col>
          </v-row>
        </template>

        <template #buttons="{loading}">
          <hra-button :color="uiColor.default" :disabled="loading" @click="close">
            <hra-icon :name="iconName.close"/>
            {{ $t('Uždaryti') }}
          </hra-button>

          <hra-button :color="uiColor.primary" :loading="loading" :type="buttonType.submit">
            <hra-icon :name="iconName.ok"/>
            {{ buttonConfirmText }}
          </hra-button>
        </template>
      </Form>
    </template>
  </custom-dialog>
</template>

<script>
import Dialog from "@/components/ui/dialog/Dialog";
import CustomDialog from "@/components/ui/dialog/CustomDialog";
import dialogMixin from "@/mixins/dialogMixin";
import FullRowLoader from "@/components/ui/FullRowLoader";
import FormTextField from "@/components/ui/form/FormTextField";
import FormColors from "@/components/ui/form/FormColors";
import FormEmployeeAutocomplete from "@/components/ui/form/FormEmployeeAutocomplete";
import HraButton from "@/components/ui/button/HraButton";
import HraIcon from "@/components/ui/HraIcon";
import Form from "@/components/ui/form/Form";
import {mapMutations} from "vuex";
import HraConfirm from "@/components/ui/dialog/HraConfirm";
import SelectField from "@/domain/fields/SelectField";
import EmployeeTableView from "@/components/ui/views/EmployeeTableView";
import employeeAutocompleteService from "@/services/employees/employeeAutocompleteService";

export default {
  name: "CompanyStructureCreateDepartmentModal",
  components: {
	  EmployeeTableView,
	  SelectField,
    HraConfirm,
    Form,
    HraIcon,
    HraButton,
    FormEmployeeAutocomplete,
    FormColors, FormTextField, FullRowLoader, CustomDialog, Dialog
  },
  mixins: [dialogMixin],
  props: {
    id: {type: [String, Number], default: "new",},
  },
  data: () => ({
    title: "",
    buttonConfirmText: "",
    canDelete: false,
    colors: [],
    loaderName: "CompanyStructure-Modal",
    formLoaderName: "CompanyStructure-Form",
    formData: {},
	  employeeAutocompleteService,
	  loading: true,
  }),
  created() {
    if (this.id === "new") {
      this.title = this.$t("Naujas skyrius");
      this.buttonConfirmText = this.$t("Sukurti");
    } else {
      this.title = this.$t("Skyriaus redagavimas");
      this.buttonConfirmText = this.$t("Pakeisti");
    }
    this.loadInitData();
  },
  methods: {
    ...mapMutations({
      addRow: "companyStructure/department/addRow",
      changeRow: "companyStructure/department/changeRow",
      removeRow: "companyStructure/department/removeRow",
    }),
    async loadInitData() {
      try {
        const {data} = await this.$api.department.getForm(this.id, this.loaderName);

        this.formData = {
          name: data.name,
          color: data.color,
          employee_id: data.employee_id,
        };

        this.colors = data.colors;
        this.canDelete = data.canDelete;
      } catch (e) {
      } finally {
	      this.loading = false;
      }
    },
    serviceProps() {
      if (this.id === "new") {
        return [];
      }

      return [this.id];
    },
    async onDelete(confirm) {
      confirm.showConfirmLoader();
      try {
        await this.$api.department.remove(this.id, this.formLoaderName);
        this.removeRow(this.id);
        // TODO: message deleted
      } catch (e) {
      } finally {
        confirm.hideConfirmLoader();
        this.closeWithDelay();
      }
    },
    async onSubmit(payload) {
      try {

        if (this.id === "new") {
          const {data} = await this.$api.department.create(payload, this.formLoaderName);
          this.addRow(data);
          // TODO: message created
          // this.$refs.form.showSuccess(this.t("message.created"));
        } else {
          const {data} = await this.$api.department.update(this.id, payload, this.formLoaderName);
          this.changeRow(data);
          // TODO: message updated
          // this.$refs.form.showSuccess(this.t("message.updated"));
        }

        this.closeWithDelay();
      } catch (e) {
      }
    },
  },
};
</script>
