<template>
  <custom-dialog :max-width="521">
    <template #title>{{ $t("Pridėti darbuotoją") }}</template>
    <template #content>
      <Form
          ref="form"
          :form-loader-name="formLoaderName"
          is-dialog
          v-model="formData"
          @submit="onSubmit"
      >
        <template #form>
          <full-row-loader v-if="isLoading"/>
          <v-row v-else>
	          <v-col cols="12">
		          <select-field
			          rules="required"
			          name="employee_id"
			          :label="$t('Darbuotojas')"
			          :single-line="false"
			          :options-service="employeeAutocompleteService.find"
			          v-model="formData.employee_id"
			          dynamic filtered
		          >
			          <template #selection="{item}">
				          <employee-table-view class="py-1" :employee="item.employee"/>
			          </template>
			          <template #item="{item}">
				          <employee-table-view class="py-1" :employee="item.employee"/>
			          </template>
		          </select-field>
	          </v-col>
          </v-row>
        </template>

        <template #buttons="{loading}">
          <hra-button :color="uiColor.default" :disabled="loading" @click="close">
            <hra-icon :name="iconName.close"/>
            {{ $t("Atšaukti") }}
          </hra-button>

          <hra-button :color="uiColor.primary" :loading="loading" :type="buttonType.submit">
            <hra-icon :name="iconName.ok"/>
            {{ $t("Pridėti") }}
          </hra-button>
        </template>
      </Form>
    </template>
  </custom-dialog>
</template>

<script>
import Dialog from "@/components/ui/dialog/Dialog";
import CustomDialog from "@/components/ui/dialog/CustomDialog";
import dialogMixin from "@/mixins/dialogMixin";
import FullRowLoader from "@/components/ui/FullRowLoader";
import FormTextField from "@/components/ui/form/FormTextField";
import FormColors from "@/components/ui/form/FormColors";
import FormEmployeeAutocomplete from "@/components/ui/form/FormEmployeeAutocomplete";
import HraButton from "@/components/ui/button/HraButton";
import HraIcon from "@/components/ui/HraIcon";
import Form from "@/components/ui/form/Form";
import {mapMutations} from "vuex";
import SelectField from "@/domain/fields/SelectField";
import EmployeeTableView from "@/components/ui/views/EmployeeTableView";
import employeeAutocompleteService from "@/services/employees/employeeAutocompleteService";

export default {
  name: "CompanyStructureAddEmployeeModal",
  components: {
	  EmployeeTableView,
	  SelectField,
    Form,
    HraIcon,
    HraButton,
    FormEmployeeAutocomplete,
    FormColors, FormTextField, FullRowLoader, CustomDialog, Dialog
  },
  mixins: [dialogMixin],
  props: {
    departmentId: {type: [String, Number], required: true,},
  },
  data: () => ({
    title: "",
    buttonConfirmText: "",
    canDelete: false,
    colors: [],
    loaderName: "CompanyStructure-Employee-Add-Modal",
    formLoaderName: "CompanyStructure-Employee-Add-Modal-Form",
    formData: {},
	  employeeAutocompleteService,
  }),
  methods: {
    ...mapMutations({
      addEmployee: "companyStructure/department/addEmployee",
      changeEmployeesCount: "companyStructure/department/changeEmployeesCount",
    }),
    async onSubmit(payload) {
      try {
        const {data} = await this.$api.department.employee.add(this.departmentId, payload, this.formLoaderName);

        this.addEmployee({
          id: this.departmentId,
          row: {id: data.id, employee: data},
        });
        this.changeEmployeesCount({id: this.departmentId, count: 1});

        // TODO: message created
        // this.$refs.form.showSuccess(this.t("message.created"));

        this.closeWithDelay();
      } catch (e) {
      }
    },
  },
};
</script>
