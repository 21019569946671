<template>
    <div class='d-flex flex-column gap-2'>
        <select-field
            :label='$t("setting.module.SettingBusinessTripEmployeeVisibleCompanyEvents.form.mode")'
            :options='modeOptions'
            :disabled='!isEdit'
            rules='required'
            v-model='config.mode'
        />
        <form-checkbox
            v-if='visibleHideOtherDepartments'
            :is-checked.sync='config.hideOtherDepartments'
            :disabled='!isEdit'
        >
            <span class='pl-2'>{{ $t('setting.module.SettingBusinessTripEmployeeVisibleCompanyEvents.form.hideOtherDepartments') }}</span>
        </form-checkbox>
<!--        <form-checkbox-->
<!--            v-if='visibleShowAllDepartmentApprovalEmployees'-->
<!--            :is-checked.sync='config.showAllDepartmentApprovalEmployees'-->
<!--            :disabled='!isEdit'-->
<!--        >-->
<!--            <span class='pl-2'>{{ $t('setting.module.SettingBusinessTripEmployeeVisibleCompanyEvents.form.showAllDepartmentApprovalEmployees') }}</span>-->
<!--        </form-checkbox>-->
    </div>
</template>

<script>
import SelectField from '@/domain/fields/SelectField.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'

export default {
    name: 'SettingBusinessTripEmployeeVisibleCompanyEventsConfigForm',
    components: { FormCheckbox, SelectField },
    props: {
        isEdit: { type: Boolean, default: false },
        config: { type: Object, default: () => ({}) }
    },
    watch: {
        'config.mode': {
            handler(mode) {
                if (mode === 'none') {
                    this.config.hideOtherDepartments = false
                    this.config.showAllDepartmentApprovalEmployees = false
                }
            }
        }
    },
    data() {
        return {
            modeOptions: ['none', 'not_at_office', 'all']
                .map(value => ({
                    value,
                    text: this.$t(`setting.module.SettingBusinessTripEmployeeVisibleCompanyEvents.form.modeOptions.${value}`)
                }))
        }
    },
    computed: {
        visibleHideOtherDepartments() {
            return this.config.mode !== 'none'
        },
        visibleShowAllDepartmentApprovalEmployees() {
            return this.config.hideOtherDepartments
        }
    }
}
</script>