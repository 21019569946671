//  initial state
import moment from "moment";

const bufferMonths = 3;
const bufferMinDays = 20 * 24 * 60 * 60; // unix time

const defaultTimelineState = () => ({
    bufferFromDate: moment().subtract(bufferMonths, "months").format("YYYY-MM-DD"),
    bufferToDate: moment().add(bufferMonths, "months").format("YYYY-MM-DD"),
    fromDate: null,
    toDate: null,
    maxDays: null,
    holidays: [],
    data: [],
    changed: null,
    loadInBackground: null,

    filter: {
        employee: null,
        departments: [],
        duties: [],
        dateFrom: null,
        limit: 10,
    },
    options: {
        departments: [],
        duties: [],
        limits: [
            {value: 10, text: 10},
            {value: 20, text: 20},
            {value: 30, text: 30},
            {value: 50, text: 50},
            {value: 100, text: 100},
        ],
    },
    legend: ["wait", "past", "approve", "today", "holiday"],
});

const defaultVacationsState = () => ({
    changed: null,
    currentPage: 1,
    lastPage: 1,
    total: 0,
    data: [],
});

const state = () => ({
    timeline: {
        ...defaultTimelineState(),
    },
    vacations: {
        ...defaultVacationsState(),
    },
});

// getters
const getters = {
    timelineOptions(state) {
        return state.timeline.options;
    },
    timelineFilter(state) {
        return state.timeline.filter;
    },
    timelineLegend(state) {
        return state.timeline.legend;
    },

    timelineBufferDates(state) {
        return {
            from: state.timeline.bufferFromDate,
            to: state.timeline.bufferToDate,
        };
    },
    timelineFromDate(state) {
        return state.timeline.fromDate;
    },
    timelineMaxDays(state) {
        return state.timeline.maxDays;
    },
    timelineToDate(state) {
        return state.timeline.toDate;
    },
    timelineChanged(state) {
        return state.timeline.changed;
    },
    timelineLoadInBackground(state) {
        return state.timeline.loadInBackground;
    },
    timelineHolidays(state) {
        return state.timeline.holidays;
    },
    timelineVacations(state) {
        return state.timeline.vacations;
    },
    timelineData(state) {
        return state.timeline.data;
    },
    timelineDepartments(state) {
        return state.timeline.departments;
    },
    timelineDuties(state) {
        return state.timeline.duties;
    },
    timelineEmployees(state) {
        return state.timeline.employees.map(employee => ({
            value: employee.id,
            text: employee.name,
            employee
        }));
    },

    changed(state) {
        return state.vacations.changed;
    },
    currentPage(state) {
        return state.vacations.currentPage;
    },
    lastPage(state) {
        return state.vacations.lastPage;
    },
    total(state) {
        return state.vacations.total;
    },
    rows(state) {
        return state.vacations.data.map(row => ({
            ...row,
            actions: "",
        }));
    },
}

// actions
const actions = {}

function checkNeedTimelineBufferData(state, dateMin, date) {
    const diff = moment(date).unix() - moment(dateMin).unix();
    if (diff < bufferMinDays) {
        state.timeline.bufferFromDate = moment(date).subtract(bufferMonths, "months").format("YYYY-MM-DD");
        state.timeline.bufferToDate = moment(date).add(bufferMonths, "months").format("YYYY-MM-DD");
    }
}

//  mutations
const mutations = {
    timelineChangeFilter(state, payload) {
        state.timeline.filter = {
            ...state.timeline.filter,
            ...payload,
        };
    },
    timelineChangeLegend(state, payload) {
        state.timeline.legend = payload;
    },
    timelineSetOptions(state, payload) {
        state.timeline.options = {
            ...state.timeline.options,
            ...payload,
        };
    },
    timelineChangeFromTable(state, payload) {
        // TODO: if data isset no to do request
        state.timeline.fromDate = payload.fromDate;
        state.timeline.toDate = moment(state.timeline.fromDate).add(state.timeline.maxDays, "days").format("YYYY-MM-DD");
        state.timeline.filter = {
            ...state.timeline.filter,
            departments: [],
            duties: [],
            employee: payload.employeeId,
            dateFrom: null,
        };

        state.timeline.bufferFromDate = moment(payload.fromDate).subtract(bufferMonths, "months").format("YYYY-MM-DD");
        state.timeline.bufferToDate = moment(payload.fromDate).add(bufferMonths, "months").format("YYYY-MM-DD");

        state.timeline.changed = (new Date()).getTime();
    },

    timelineChangeBufferDates(state, payload) {
        state.timeline.bufferFromDate = moment(payload).subtract(bufferMonths, "months").format("YYYY-MM-DD");
        state.timeline.bufferToDate = moment(payload).add(bufferMonths, "months").format("YYYY-MM-DD");

        state.timeline.fromDate = moment(payload).format("YYYY-MM-DD");
        state.timeline.toDate = moment(payload).add(state.timeline.maxDays, "days").format("YYYY-MM-DD");
    },

    timelineChangeMaxDays(state, maxDays) {
        state.timeline.maxDays = maxDays;
        state.timeline.toDate = moment(state.timeline.fromDate).add(maxDays, "days").format("YYYY-MM-DD");
        state.timeline.changed = (new Date()).getTime();
    },
    timelineInit(state, payload) {
        state.timeline.fromDate = payload.fromDate.format("YYYY-MM-DD");
        state.timeline.maxDays = payload.maxDays;
        state.timeline.toDate = moment(payload.fromDate).add(payload.maxDays, "days").format("YYYY-MM-DD");
        state.timeline.changed = (new Date()).getTime();
    },
    timelineClear(state) {
        state.timeline = defaultTimelineState();
    },
    timelinePrevWeek(state) {
        state.timeline.fromDate = moment(state.timeline.fromDate).subtract(7, "days").format("YYYY-MM-DD");
        state.timeline.toDate = moment(state.timeline.toDate).subtract(7, "days").format("YYYY-MM-DD");

        checkNeedTimelineBufferData(state, state.timeline.bufferFromDate, state.timeline.fromDate);
    },
    timelineNextWeek(state) {
        state.timeline.fromDate = moment(state.timeline.fromDate).add(7, "days").format("YYYY-MM-DD");
        state.timeline.toDate = moment(state.timeline.toDate).add(7, "days").format("YYYY-MM-DD");

        checkNeedTimelineBufferData(state, state.timeline.toDate, state.timeline.bufferToDate);
    },
    timelineLoad(state, payload) {
        state.timeline.holidays = payload.holidays;
        state.timeline.data = payload.employees;
        // state.timeline.departments = payload.departments;
        // state.timeline.duties = payload.duties;
        // state.timeline.employees = payload.employees;
        // state.timeline.vacation = payload.vacation;
    },
    timelineNeedReload(state) {
        state.timeline.changed = (new Date()).getTime();
    },

    needDataRefresh(state) {
        state.vacations.changed = (new Date()).getTime();
        state.timeline.changed = (new Date()).getTime();
    },
    load(state, payload) {
        state.vacations.data = payload.vacations;
        state.vacations.currentPage = payload.currentPage;
        state.vacations.lastPage = payload.lastPage;
        state.vacations.total = payload.total;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
