import Employee from "@/api/department/employee";
import Vacation from "@/api/department/vacation";
import { query } from '@/utils/default';
export default axios => ({

    getAll: (relations, search) => axios.get(`/api/departments` + (relations ? query({ relations, search }) : '')),

    get: (requestName) => axios.get(`/api/v2/departments`, {requestName}),
    getForm: (id, requestName) => axios.get(`/api/v1/departments/${id}`, {requestName}),
    create: (payload, requestName) => axios.post(`/api/v1/departments`, payload, {requestName}),
    changeLeader: (departmentId, employeeId) => axios.post(`/api/v2/departments/${departmentId}/leaders/${employeeId}`),
    update: (id, payload, requestName) => axios.put(`/api/v1/departments/${id}`, payload, {requestName}),
    remove: (id, requestName) => axios.delete(`/api/v1/departments/${id}`, {requestName}),

    fetchUsers: (search) => axios.get(`/api/employees/users/company` + (search ? query({ search }) : '')),

    events: {
        fetchLeader: () => axios.get(`/api/events/companies/holidays/leader`),
        fetchHolidays: (params) => axios.get(`/api/events/companies/holidays`, { params }),
        fetchDeputies: (params) => axios.get(`/api/events/companies/holidays`, { params }),
    },

    employee: Employee(axios),
    vacation: Vacation(axios),
});
