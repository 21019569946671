import {asyncTimeout} from "@/utils/default";
import moment from "moment";

const state = () => ({
  loading: false,
  employees: [],
  vacations: [],
  deputies: [],
  filterOptions: {
    departments: [],
    duties: [],
  },
});

const getters = {
  loading(state) {
    return state.loading;
  },
  vacations(state) {
    return state.vacations;
  },
  deputies(state) {
    return state.deputies;
  },
  employees(state) {
    return state.employees;
  },
  departmentOptions(state) {
    return state.filterOptions.departments;
  },
  dutyOptions(state) {
    return state.filterOptions.duties;
  },
  employeeOptions(state) {
    return state.employees.map(employee => ({
      value: employee.id,
      text: employee.name,
    }));
  },
};

const mutations = {
  hasLoading(state) {
    state.loading = true;
  },
  hasLoaded(state) {
    state.loading = false;
  },
  setEmployees(state, payload) {
    state.employees = payload;
  },
  setVacations(state, payload) {
    state.vacations = payload;
  },
  setDeputies(state, payload) {
    state.deputies = payload;
  },
  setFilterOptions(state, payload) {
    state.filterOptions = payload;
  },
  clear(state) {
  },
};

const employeeCount = 10;
const dateFrom = new Date("2021-12-01");
const dateTo = new Date("2022-12-31");

const mockEmployees = () => {
  const employee = {name: "Stephanie Cook", duties: ["Programmer"], avatar: null};

  return Array(employeeCount).fill(employee).map((employee, id) => ({
    ...employee,
    id,
    name: `${employee.name} - ${id}`
  }));
};

const mockOptions = {
  departments: [{value: 1, text: "IT"}, {value: 2, text: "I2"}, {value: 3, text: "IT4"}],
  duties: [{value: 1, text: "d IT"}, {value: 2, text: "d I2"}, {value: 3, text: "d IT4"}],
};

function randomDate(start, end) {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}


const mockVacations = () => {

  let dates = [];

  const vacationStatuses = [0, 1, 2, 3];
  const _len = vacationStatuses.length - 1;

  return Array(40).fill({}).map((_, id) => {

    const _random_time = randomDate(dateFrom, dateTo).getTime();
    const _days = Math.ceil(Math.random() * 10);
    let _start = moment(_random_time);
    let _end = moment(_random_time);
    _end.add(_days, "days");

    return {
      id,
      employeeId: Math.round(Math.random() * employeeCount),
      fromDate: _start.format("YYYY-MM-DD"),
      toDate: _end.format("YYYY-MM-DD"),
      days: _days + 1,
      status: vacationStatuses[Math.round(Math.random() * _len)]
    };
  }).sort((a, b) => {
    if (a.fromDate < b.fromDate) return -1;
    if (a.fromDate > b.fromDate) return 1;
    return 0;
  });
};

const actions = {
  async fetch({commit}, {dateFrom, departments, duties, employeeId}) {
    commit("hasLoading");
    try {
      // const {data} = await this._vm.$api.employee.getAll({page, limit: state.limit});
      await asyncTimeout(500);
      commit("setFilterOptions", mockOptions);
      commit("setEmployees", mockEmployees());
      commit("setVacations", mockVacations());

      // commit("setData", data);
    } catch (error) {
      
    } finally {
      commit("hasLoaded");
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
