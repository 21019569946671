import {
  SET_AUTHENTICATED,
  SET_LEADER,
  SET_LOADED,
  SET_PROFILE,
  SET_SETTING,
  SET_USER
} from "@/domain/auth/store/mutations";

export const init = ({commit}, payload) => {
  const user = payload.user;
  if (user) {
    user.role = user.roles[0].id;
    delete user.roles;
    user.permissions = user.permissions.map(({name}) => name);
  }

  commit(SET_AUTHENTICATED, payload.isAuthenticated);
  commit(SET_PROFILE, payload.profile);
  commit(SET_LEADER, payload.isLeader);
  commit(SET_USER, user);
  commit(SET_SETTING, payload.settings);
  commit(SET_LOADED, true);
}