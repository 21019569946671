<template>
    <documents-finder
        :store-namespace='storeNamespace'
        :default-breadcrumbs='defaultBreadcrumbs'
        :default-title='$t("Grupės")'
        header-sticky
        :header-sticky-top='64'
        with-sub-header
        with-arrows
        with-modes
        with-search
        with-controls
    >
        <template #header-buttons>
            <ui-button
                background='violet-dark'
                @click="handleOpenCreateGroupModal"
                prepend-icon='plus'
            >
                {{ $t('Nauja grupė') }}
            </ui-button>
            <ui-button
                background='violet-dark'
                @click="handleOpenCreateGroupsFolderModal"
                prepend-icon='plus'
            >
                {{ $t('Grupių aplankas') }}
            </ui-button>
        </template>
    </documents-finder>
</template>

<script>
import store from '@/domain/documents/finder/store/documentsGroupsFinderStore'
import DocumentsFinder from '@/domain/documents/finder/index.vue'
import UiButton from '@/domain/components/UiButton.vue'
import modalMethodsMixin from '@/domain/mixins/modalMethodsMixin'

const storeNamespace = 'documentsGroupsRootStore'

export default {
    name: 'DocumentsGroups',
    components: {
        UiButton,
        DocumentsFinder,
    },
    mixins: [modalMethodsMixin],
    data() {
        return {
            storeNamespace,
            defaultBreadcrumbs: [
                { id: null, title: this.$t('Įmonės dokumentai') },
                { id: null, title: this.$t('Grupės') }
            ]
        }
    },
    beforeCreate() {
        this.$store.registerModuleSafely(storeNamespace, store)
    },
    beforeDestroy() {
        this.$store.unregisterModuleSafely(storeNamespace)
    },
    mounted() {
        this.loadFromRoute()

        this.$watch(
            () => [
                this.$store.getters[`${storeNamespace}/groupsFolderId`],
                this.$store.getters[`${storeNamespace}/search`]
            ],
            () => this.handleChangeFolderIdOrSearch()
        )
    },
    methods: {
        loadFromRoute() {
            const routeFolderId = parseInt(this.$route.params?.folderId ?? null)
            const folderId = isNaN(routeFolderId) ? null : routeFolderId
            const search = this.$route.query?.search ?? ''

            if (!!search) {
                this.$store.dispatch(`${storeNamespace}/search/change`, search)
            } else {
                this.$store.dispatch(`${storeNamespace}/groups-folders/changeId`, folderId)
            }
        },
        handleChangeFolderIdOrSearch() {
            this.$router.push(({
                params: {
                    folderId: this.$store.getters[`${storeNamespace}/groupsFolderId`]
                },
                query: {
                    search: this.$store.getters[`${storeNamespace}/search`]
                }
            })).catch(() => {
            })
        },
        handleOpenCreateGroupsFolderModal() {
            this.onOpenModal('GroupsFolderCreateModal', {
                parentId: this.$store.getters[`${storeNamespace}/groupsFolderId`],
            }, {
                folderCreated: (payload) => {
                    this.$store.dispatch(`${storeNamespace}/groups-folders/create`, payload)
                }
            })
        },
        handleOpenCreateGroupModal() {
            this.onOpenModal('GroupCreateModal', {
                parentId: this.$store.getters[`${storeNamespace}/groupsFolderId`],
            }, {
                groupCreated: (payload) => {
                    this.$store.dispatch(`${storeNamespace}/groups/create`, payload)
                }
            })
        }
    }
}
</script>