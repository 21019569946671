<template>
    <div class='documents-finder-layout'>
        <div
            class='documents-finder-layout__header-container'
            :class='{sticky: headerSticky}'
            :style='headerStyle'
        >
            <div
                class='documents-finder-layout__header'
            >
                <documents-finder-header-arrows v-if='withArrows' />
                <documents-finder-header-title :default-title='defaultTitle' />
                <v-spacer />
                <documents-finder-header-view-modes v-if='withModes' />
                <slot name='header-buttons' />
                <documents-finder-header-search v-if='withSearch' />
            </div>
            <div v-if='withSubHeader' class='documents-finder-layout__sub-header'>
                <documents-finder-sub-header-breadcrumbs
                    :default='defaultBreadcrumbs'
                />
                <v-spacer />
                <sub-header-select-all-documents v-if='documentSelectable' />
            </div>
            <v-progress-linear
                :active='loading'
                :indeterminate='loading'
                color='primary'
                absolute
                style='bottom: 0'
            />
        </div>
        <div class='documents-finder-layout__content'>
            <documents-items-list
                :with-status='withStatus'
                :with-controls='withControls'
                :with-delete-info='withDeleteInfo'
                :selectable='documentSelectable'
            />
            <documents-items-grid
                class='pa-6'
                :with-status='withStatus'
                :with-controls='withControls'
                :selectable='documentSelectable'
            />
            <load-more-button />
        </div>
    </div>
</template>

<script>
import DocumentsFinderHeaderSearch from '@/domain/documents/finder/components/header/HeaderSearch.vue'
import DocumentsFinderHeaderTitle from '@/domain/documents/finder/components/header/HeaderTitle.vue'
import DocumentsFinderHeaderArrows from '@/domain/documents/finder/components/header/HeaderArrows.vue'
import DocumentsFinderHeaderViewModes from '@/domain/documents/finder/components/header/HeaderViewModes.vue'
import DocumentsFinderSubHeaderBreadcrumbs
    from '@/domain/documents/finder/components/subHeader/SubHeaderBreadcrumbs.vue'
import LoadMoreButton from '@/domain/documents/finder/components/content/LoadMoreButton.vue'
import SubHeaderSelectAllDocuments from '@/domain/documents/finder/components/subHeader/SubHeaderSelectAllDocuments.vue'
import DocumentsItemsList from '@/domain/documents/finder/components/content/list/List.vue'
import DocumentsItemsGrid from '@/domain/documents/finder/components/content/grid/Grid.vue'

export default {
    name: 'DocumentsFinder',
    components: {
        DocumentsItemsGrid,
        LoadMoreButton,
        DocumentsItemsList,
        SubHeaderSelectAllDocuments,
        DocumentsFinderSubHeaderBreadcrumbs,
        DocumentsFinderHeaderSearch,
        DocumentsFinderHeaderViewModes,
        DocumentsFinderHeaderTitle,
        DocumentsFinderHeaderArrows
    },
    props: {
        storeNamespace: { type: String, required: true },
        defaultBreadcrumbs: { type: Array, default: () => [] },
        defaultTitle: { type: String, default: '' },
        documentSelectable: { type: Boolean, default: false },
        headerStickyTop: { type: Number, default: 0 },
        headerSticky: { type: Boolean, default: false },

        withSubHeader: { type: Boolean, default: false },
        withSidebarAction: { type: Boolean, default: false },
        withArrows: { type: Boolean, default: false },
        withModes: { type: Boolean, default: false },
        withSearch: { type: Boolean, default: false },
        withStatus: { type: Boolean, default: false },
        withControls: { type: Boolean, default: false },
        withDeleteInfo: { type: Boolean, default: false }
    },

    provide() {
        return {
            storeNamespace: this.storeNamespace,
            withSidebarAction: this.withSidebarAction
        }
    },
    computed: {
        loading() {
            return this.$store.getters[`${this.storeNamespace}/loading`]
        },
        search() {
            return this.$store.getters[`${this.storeNamespace}/search`]
        },
        headerStyle() {
            if (!this.headerSticky) {
                return {}
            }

            return {
                top: `${this.headerStickyTop}px`
            }
        }
    },
    methods: {}
}
</script>

<style scoped lang='scss'>
.documents-finder-layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    & > div {
        border: 1px solid #DAD5E9;
        border-top: 0;

        &:first-child {
            border-top: 1px solid #DAD5E9;
            border-radius: 4px 4px 0 0;
        }

        &:last-child {
            border-radius: 0 0 4px 4px;
        }
    }

    .v-progress-linear {
        border: 0;
        border-left: 1px solid #DAD5E9;
        border-right: 1px solid #DAD5E9;
    }

    &__header-container {
        &.sticky {
            position: sticky;
            z-index: 5;
        }
    }

    &__header {
        background: #DAD5E9;
        height: 75px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px 20px;
        gap: 20px;
    }

    &__sub-header {
        background: #FBFBFF;
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px 20px;
        gap: 8px;
        position: sticky;
    }

    &__content {
        overflow: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__content-more-button {
        margin: 20px 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
    }
}
</style>