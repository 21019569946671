<template>

	<Auth>
		<template #header>{{ $t("Saugumas") }}</template>

		<template #form>
			<validation-form :tag="tag" @submit="onSubmit">
				<v-row>
					<v-col cols="12">
						<div class="d-flex flex-column full-width gap-3">
							<p
								v-html="$t('Didesniam informacijos saugumui užtikrinti <strong>rekomenduojame naudotis papildoma vartotojo patvirtinimo sistema</strong> (two-factor authentification), kurią bet kada galėsite išjungti.')"/>
							<ol>
								<li>
									{{ $t("Parsisiųskite mobiliąją programėlę „Authenicator“ į savo telefoną.") }}<br />
									<strong>{{ $t("Tinka „Google Authenticator“, „Microsoft Authenticator“, „FortiToken“ ir kt.") }}</strong>
								</li>
								<li>{{ $t("Atidarykite programėlę ir paspauskite „Pridėti paskyrą“") }}</li>
								<li>{{ $t("Nuskanuokite žemiau esantį kodą:") }}</li>
								<div class="d-flex align-center justify-center my-3" v-html="qrCodeSvg"/>
								<li>{{ $t("Įveskite programėlės sugeneruotą kodą:") }}</li>
							</ol>
							<text-field
								:rules="'required|min:6,simbolių|max:6,' + $t('simbolių')"
								v-model="code"
								name="code"
								:placeholder="$t('6 skaitmenų kodas')"
							/>
						</div>
					</v-col>

					<v-col cols="12">
						<message-alerts :listen="[tag]" />
					</v-col>

					<v-col cols="12">
						<hra-button
							:loading="buttonLoading"
							:color="uiColor.primary"
							:type="buttonType.submit"
							full-width
						>{{ $t("Patvirtinti") }}
						</hra-button>
					</v-col>
				</v-row>
			</validation-form>
		</template>

		<template #after-card>
			<hra-button-link
				full-width no-background
				:text-width="buttonTextWidth.normal"
				to="/"
			>{{ $t('Praleisti šį žingsnį') }}
			</hra-button-link>
		</template>
	</Auth>
</template>

<script>
import authMixin from "@/domain/auth/mixins/authMixin";
import Auth from "@/domain/auth/components/Auth";
import TextField from "@/domain/fields/TextField";
import HraButton from "@/components/ui/button/HraButton";
import {forgotPassword as forgotPasswordName} from "@/domain/auth/routes"
import {ValidationObserver} from "vee-validate";
import MessageAlerts from "@/domain/messages/MessageAlerts";
import ValidationForm from "@/domain/components/ValidationForm";
import HraButtonLink from "@/components/ui/button/HraButtonLink";

export default {
	name: "EnableTwoFactorPage",
	components: {HraButtonLink, ValidationForm, MessageAlerts, ValidationObserver, HraButton, TextField, Auth},
	mixins: [authMixin],
	data() {
		return {
			tag: "AUTH_ENABLE_T2F_TAG",
			forgotPasswordName,
			code: '',
			qrCodeSvg: ''
		};
	},
	async created() {
		this.qrCodeSvg = await this.service.getQrCodeSvg("AUTH_GET_QR_CODE_TAG");
	},
	methods: {
		async onSubmit() {
			await this.service.enableTwoFactor(this.tag, this.code);
		},
	}
}
</script>