<template>
    <step-container :title='$t("Pasirinkite darbuotojus")'>
        <assign-users-to-documents-table />
    </step-container>
</template>

<script>
import StepContainer from '@/domain/documents/modals/items/documentSendModal/components/StepContainer.vue'
import AssignUsersToDocumentsTable
    from '@/domain/documents/modals/items/documentSendModal/components/AssignUsersToDocumentsTable.vue'

export default {
    name: 'AssignUsersToDocumentsStep',
    components: { AssignUsersToDocumentsTable, StepContainer }
}
</script>