<template>
    <div class='ui-table' :class='{"header_sticky": headerSticky}'>
        <table :style='tableStyles'>
            <thead :style='headerStickyStyle'>
            <slot name='head' />
            <tr class='loader' v-if='loading'>
                <td colspan='100'>
                    <v-progress-linear
                        :active='loading'
                        :indeterminate='loading'
                        color='primary'
                    />
                </td>
            </tr>
            </thead>
            <slot name='colgroup' />
            <tbody>
            <tr v-if='isEmptyTable' class='is-empty-row'>
                <td colspan='100'>
                    <slot name='empty-table'>
                        <message-alert
                            id='table-alert-empty'
                            :message='$t("Naujų įrašų sistemoje pridėta nebuvo.")'
                            type='info'
                        />
                    </slot>
                </td>
            </tr>
            <slot name='body' />
            </tbody>
        </table>

        <ui-table-load-more-button
            v-if='visibleLoadMore'
            class='my-3'
            :loading='loadingMore'
            @click='$emit("clickLoadMore")'
        />
    </div>
</template>

<script>

import UiTableLoadMoreButton from '@/domain/components/uiTable/UiTableLoadMoreButton.vue'
import MessageAlert from '@/domain/messages/components/MessageAlert.vue'

export default {
    name: 'UiTable',
    components: { MessageAlert, UiTableLoadMoreButton },
    props: {
        loading: { type: Boolean, default: false },
        loadingMore: { type: Boolean, default: false },
        visibleLoadMore: { type: Boolean, default: false },
        isEmptyTable: { type: Boolean, default: false },
        headerSticky: { type: Boolean, default: false },
        headerStickyTop: { type: Number, default: 0 }
    },
    computed: {
        headerStickyStyle() {
            if (!this.headerSticky) {
                return {}
            }

            return {
                top: `${this.headerStickyTop}px`
            }
        },
        tableStyles() {
            return {
                tableLayout: 'fixed',
                width: this.$slots.colgroup ? '100%' : 'auto'
            }
        }
    }
}
</script>

<style lang='scss'>

.ui-table {
    overflow: auto;

    table {
        min-width: 100%;
        border-collapse: collapse;
        table-layout: fixed;

        thead {
            tr {
                border: 1px solid #7177C1;
                border-top: 0;
                background: #7177C1;
                color: #FFF;
                height: 44px;

                &.loader {
                    height: auto;
                    background: #FFFFFF;
                }
            }

            th {
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 14px;
                letter-spacing: 0.36px;
                text-transform: uppercase;
                padding: 0 14px;
                background: #7177C1;
            }
        }

        tbody {
            position: relative;

            & > tr {
                border: 1px solid #DAD5E9;
                height: 40px;

                &:nth-child(even) {
                    background-color: #FBF8FF;
                }

                &:hover {
                    background-color: #e1ddf6;
                }

                &.expanded {
                    border-left-color: #32398B;
                    border-right-color: #32398B;
                    border-top-color: #32398B;
                }

                &.expanded-table {
                    background-color: #FFFFFF;
                    border-left-color: #32398B;
                    border-right-color: #32398B;
                    border-bottom-color: #32398B;

                    &:hover {
                        background-color: #FFFFFF;
                    }

                    & > td {
                        padding: 0;
                    }

                    thead {
                        tr {
                            border-color: #F1EEF9;
                            background-color: #F1EEF9;
                            color: #70778C;
                            height: 35px;
                        }
                    }

                    tr {
                        border-left: 0;
                        border-right: 0;
                    }
                }

                &.is-empty-row {
                    background: #FFFFFF;
                }
            }

            td {
                color: #1C2538;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                padding: 6px 14px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    &.header_sticky {
        overflow: unset;

        thead {
            position: sticky;
            top: 0;
            z-index: 3;
        }
    }
}
</style>