<template>
    <custom-dialog :max-width='550'>
        <template #title>{{ $t('Redaguoti aplanką')}}</template>
        <template #content>
            <v-row class='pa-3'>
                <v-col cols='12'>
                    <text-field v-model='title' :label="$t('Pavadinimas')" name='name' rules='required' />
                </v-col>
            </v-row>
        </template>
        <template #footer>
            <ui-button background='default' prepend-icon='close' @click='close'>{{ $t('Uždaryti') }}</ui-button>
            <ui-button :disabled='!title' :loading='loading' background='primary' prepend-icon='ok' @click='handleSubmit'>
                {{ $t('Išsaugoti') }}
            </ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import dialogMixin from '@/mixins/dialogMixin'
import UiButton from '@/domain/components/UiButton.vue'
import TextField from '@/domain/fields/TextField.vue'
import customApi from '@/plugins/customApi'
import { GLOBAL_TOAST_TAG } from '@/utils/default'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'

export default {
    name: 'FolderUpdateModal',
    components: { CustomDialog, UiButton, TextField },
    mixins: [dialogMixin],
    props: {
        item: {type: Object, required: true}
    },
    data() {
        return {
            loading: false,
            id: null,
            parentId: null,
            title: ''
        }
    },
    created() {
        this.id = this.item.id
        this.title = this.item.title
        this.parentId = this.item.documentId
    },
    methods: {
        async handleSubmit() {
            this.loading = true

            try {
                let payload = {
                    title: this.title,
                    parentId: this.parentId
                }

                const {data} = await customApi().factories.documents.folders.update(this.id, payload)

                this.$emit('folderUpdated', data)
                this.showSuccessInRootToast(this.$t('Išsaugotą sekmingai'))
                this.close()
            } catch (error) {
                this.showErrorInRootToast(error)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style scoped>

</style>