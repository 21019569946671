<template>
	<Form
		v-model="formData"
		ref="form"
		@submit="onSubmit"
	>
		<template #form>
			<input type="hidden" v-model="formData.token"/>
			<v-row>
				<v-col cols="12">
					<FormTextField
						rules=""
						v-model="formData.name"
						name="name"
						type="text"
						:label="$t('Vardas Pavardė')"
						disabled
					/>
				</v-col>
				<v-col cols="12">
					<FormTextField
						rules=""
						v-model="formData.email"
						name="email"
						type="email"
						:label="$t('El. pašto adresas')"
						disabled
					/>
				</v-col>
				<v-col cols="12">
					<FormTextField
						:rules="'required|min:8,' + $t('simbolius')"
						v-model="formData.password"
						name="password"
						ref="password"
						type="password"
						:label="$t('Slaptažodis')"
					/>
					<small>{{ $t("Mažiausiai 8 simboliai") }}</small>
				</v-col>
				<v-col cols="12">
					<FormTextField
						:rules="'required|confirmed:password,'"
						v-model="formData.password_confirmation"
						name="password_confirmation"
						type="password"
						:label="$t('Pakartokite slaptažodį')"
					/>
					<form-alert v-if="passwordsNotEquals" class="mt-2" type="error" :text="$t('Nesutampa slaptažodžiai')" />
				</v-col>
				<v-col cols="12">
					<FormCheckbox
						rules="required"
						:is-checked.sync="formData.police_accept"
						name="police_accept"
					>
						<div class="ms-3">
							<a href="">{{ $t("Sutinku su Privatumo politka") }}</a>
							{{ $t("ir") }}
							<a href="">{{ $t("naudojimosi taisyklėmis") }}</a>
						</div>
					</FormCheckbox>
				</v-col>
			</v-row>
		</template>
		<template #buttons>
			<hra-button
				:loading="buttonLoading"
				full-width
				class="mt-7"
				:color="uiColor.primary"
				:type="buttonType.submit"
			>{{ $t("Registruotis") }}
			</hra-button>
		</template>
	</Form>
</template>

<script>
import Form from "@/components/ui/form/Form.vue";
import FormTextField from "@/components/ui/form/FormTextField.vue";
import FormCheckbox from "@/components/ui/form/FormCheckbox.vue";
import Button from "@/components/ui/button/Button.vue";
import {authRouteProps} from "@/router/auth";
import HraButton from "@/components/ui/button/HraButton";
import apiAuth from "@/api/auth/apiAuth";
import FormAlert from "@/components/ui/form/FormAlert";

export default {
	components: {
		FormAlert,
		HraButton,
		Form,
		FormTextField,
		FormCheckbox,
		Button,
	},
	props: {
		formData: {type: Object, required: true},
		submitText: {type: String, required: true},
	},
	data() {
		return {
			buttonLoading: false,
		};
	},
	computed: {
		passwordsNotEquals() {
			return this.formData.password !== this.formData.password_confirmation && !(this.$refs.form?.ignoreErrors ?? true);
		},
	},
	inject: ["authService"],
	methods: {
		async onSubmit(payload) {
			try {
				this.buttonLoading = true;
				await this.authService.register(payload);
			} catch (e) {

			} finally {
				this.buttonLoading = false;
			}
		},
	},
};
</script>
