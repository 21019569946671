<template>
    <page
        :loader-listen='initTag'
        :message-listen='initTag'
        :loader-tag='initTag'
        with-background
    >
        <employee-template is-profile>
            <template #append-profile>
                <employee-profile-description is-profile />
            </template>
            <template #panels>
                <expansion-panels :value='panels'>
                    <expansion-employee-base is-profile />
                    <expansion-employee-contact is-profile />
                    <expansion-employee-vacations is-profile />
                    <expansion-employee-contract is-profile />
                </expansion-panels>
            </template>
        </employee-template>
    </page>
</template>

<script>
import Page from '@/domain/components/Page'
import ExpansionPanels from '@/domain/components/ExpansionPanels'
import ExpansionEmployeeBase from '@/domain/employee/components/ExpansionEmployeeBase'
import EmployeeTemplate from '@/domain/employee/components/EmployeeTemplate'
import ExpansionEmployeeContact from '@/domain/employee/components/ExpansionEmployeeContact'
import ExpansionEmployeeVacations from '@/domain/employee/components/ExpansionEmployeeVacations'
import ExpansionEmployeeContract from '@/domain/employee/components/ExpansionEmployeeContract'
import ExpansionEmployeeChangelog from '@/domain/employee/components/ExpansionEmployeeChangelog'
import ExpansionEmployeePermissions from '@/domain/employee/components/ExpansionEmployeePermissions'
import employeeProfileService from '@/domain/employee/services/EmployeeProfileService'
import EmployeeProfileDescription from '@/domain/employee/components/EmployeeProfileDescription'

export default {
    name: 'EmployeeProfilePage',
    components: {
        EmployeeProfileDescription,
        ExpansionEmployeePermissions,
        ExpansionEmployeeChangelog,
        ExpansionEmployeeContract,
        ExpansionEmployeeVacations,
        ExpansionEmployeeContact, EmployeeTemplate, ExpansionEmployeeBase, ExpansionPanels, Page
    },
    data() {
        const initTag = 'EMPLOYEE_PROFILE_PAGE_INIT'

        return {
            panels: [0],
            // initService: () => employeeProfileService.init(initTag, this.$route.params?.id ?? null),
            initTag
        }
    },
    beforeCreate() {
        this.$store.dispatch('employeeStore/fetchEmployeeById', {
            id: this.$store.getters['currentUserStore/userId'],
            tag: 'EMPLOYEE_PROFILE_PAGE_INIT'
        })
    },
    provide() {
        return {
            service: employeeProfileService
        }
    },
    beforeDestroy() {
        this.$store.dispatch('employeeStore/clearState')
    }
}
</script>