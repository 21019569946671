<template>
	<v-menu
		open-on-hover
		top
		offset-y
		nudge-bottom="2"
	>
		<template v-slot:activator="{on, attrs}">
			<td
				:colspan="days.length"
				:class="classes"
				v-bind="needMenu ? attrs : {}"
				v-on="{...listeners, ...(needMenu ? on : {})}"
				:data-listeners-have="Object.keys(listeners).length > 0 ? 'yes' : 'no'"
			>
				<hra-tooltip v-if="event" :text="event.text">
					<hra-calendar-day
						v-for="day in days"
						:disable-click="disableClickEventDate"
						:key="day.date" v-bind="day"
					/>
				</hra-tooltip>
				<hra-calendar-day
					v-else
					v-for="day in days"
					:key="day.date" v-bind="day"
				/>
			</td>
		</template>
		<slot name="event-menu" />
	</v-menu>
</template>

<script>
import HraCalendarDay from "@/components/ui/calendar/HraCalendarDay";
import HraTooltip from "@/components/ui/HraTooltip";

export default {
  name: "HraCalendarWeekDays",
  components: {HraTooltip, HraCalendarDay},
  props: {
    days: {type: Array, required: true},
    event: {type: [Object], default: () => null},
	  disableClickEvent: {type: Boolean, default: false},
  },
  computed: {
		needMenu() {
			return this.event?.needMenu ?? false;
		},
    classes() {
      let fromDate = this.days[0].date;
      let toDate = this.days[this.days.length - 1].date;

      return [
        this.event && "hra-calendar__week-days-event", this.event?.name,
        this.event?.fromDate === fromDate && "start",
        this.event?.toDate === toDate && "end",
      ]
          .filter(name => name);
    },
	  listeners() {
			if (this.disableClickEvent) {
				return {};
			}

			if (this.event?.id === "new" || this.event?.id == null) {
				return {};
			}

			return this.$listeners;
	  },
	  disableClickEventDate() {
			if (this.disableClickEvent) {
				return false;
			}

			return this.event?.id !== 'new';
	  }
  },
};
</script>
