<template>
    <tr class="schedule-line" :class='rowNameClass'>
        <td :style='{height: `${height}px`}' :colspan='colspan'>
            <div
                class='pa-4'
                :class='columnNameClass'
            >
                <div class="schedule-line__label">
                    <span>{{ nameText }}</span>
                    <span v-if='userDismissed' class='schedule-line__error'>
                        <v-tooltip top>
                            <template #activator='{attrs, on}'>
                                <div
                                    v-bind='attrs'
                                    v-on='on'
                                    class='schedule-line__error-dot'
                                />
                            </template>
                            <span>(atleistas nuo {{ row.user.dismissedDate }})</span>
                        </v-tooltip>
                    </span>
                </div>
                <div v-if="!fakeRow">
                    <ui-button
                        v-if='isAdmin && row.type !== "user"'
                        prepend-icon='change'
                        size='small'
                        class="ml-2"
                        only-hover-background
                        :modal='{name: "GenerateScheduleTimetablesByFilterModal", props: {scheduleLineId: row.id}}'
                    />
                    <ui-button
                        v-if='isAdmin && row.type !== "user"'
                        prepend-icon='plus'
                        size='small'
                        only-hover-background
                        :modal='{name: "CreateScheduleLineModal", props: {scheduleLineId: row.id}}'
                    />
                    <ui-button
                        v-if='isAdmin'
                        prepend-icon='edit'
                        size='small'
                        only-hover-background
                        :modal='{name: "UpdateScheduleLineModal", props: {line: row}}'
                    />
                    <ui-button
                        v-if='isAdmin'
                        prepend-icon='bin'
                        size='small'
                        hover-color='danger'
                        only-hover-background
                        :confirm="{
                            props: {title: $t('Ar tikrai norite ištrinti?')},
                            on: {confirmed: onDeleteRow}
                        }"
                    />
                </div>
            </div>
        </td>
        <td v-if='showTimeCol' :style='{height: `${height}px`}'>
            <div v-if='showTimeText' class='mx-2'>{{ timeText }}</div>
        </td>
    </tr>
</template>

<script>
import UiButton from '@/domain/components/UiButton.vue'
import { GLOBAL_TOAST_TAG } from '@/utils/default'
import {
    permissionsButtonEmployeeCreate,
    permissionsButtonEmployeeDelete,
    permissionsButtonEmployeeEdit
} from '@/utils/permissions'

export default {
    name: 'ScheduleLine',
    components: { UiButton },
    props: {
        row: { type: Object, required: true },
        height: { type: Number, required: true },
        visibleTime: { type: Boolean, default: false },
    },
    computed: {
        hasWarning() {
            return this.$store.getters['schedulerStore/linesWithWarning'].includes(this.row.id)
        },
        isAdmin() {
            return this.hasPermissionTo([
                ...permissionsButtonEmployeeCreate,
                ...permissionsButtonEmployeeEdit,
                ...permissionsButtonEmployeeDelete
            ])
        },
        fakeRow() {
            return this.row.id === 'fake-row-1' || this.row.id === 'fake-row-2'
        },
        colspan() {
            return this.fakeRow ? 2 : (this.row.type !== 'user' ? 2 : (this.visibleTime ? 1 : 2))
        },
        columnNameClass() {
            const tabs = (this.row.tabs ?? 0) + 4

            return {
                [`pl-${tabs}`]: true,
                'text-red': this.hasWarning,
                'schedule-line__empty': this.fakeRow
            }
        },
        rowNameClass() {
            return {
                'schedule-line__workplace': this.row.type !== 'user',
                'schedule-line__user': this.row.type == 'user'
            }
        },
        nameText() {
            if (this.fakeRow) {
                return this.row.type === 'user' ? this.$t('Pridėtų darbuotojų nėra') : this.$t('Pridėtų darbo vietų nėra')
            } else {
                return this.row.type === 'user' ? this.row.user.name : this.row.name
            }
        },
        timeText() {
            return this.row.time
        },
        userDismissed() {
            if(this.fakeRow) {
                return false;
            }

            if(this.row.type !== 'user') {
                return false;
            }

            return this.row.user.dismissed;
        },
        showTimeCol() {
            return this.visibleTime && this.row.type === "user" && !this.fakeRow;
        },
        showTimeText() {
            if(this.isAdmin) {
                return true;
            }

            return this.row.userId === this.$store.getters['currentUserStore/userId']
        }
    },
    methods: {
        async onDeleteRow(confirm) {
            if (!await this.$store.dispatch('schedulerStore/deleteLineById', this.row.id)) {
                await this.$store.dispatch('messagesStore/showErrorMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: 'Line delete error'
                })

                return
            }

            await this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag: GLOBAL_TOAST_TAG,
                message: 'Line delete success'
            })

            confirm.close()
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/core/colors';
.schedule-line {
    &__workplace {
        td {
            background-color: #F3F4FF;

            & > div:not(.schedule-line__empty) {
                text-transform: uppercase;
                font-weight: 600;
            }
        }
    }

    &__user {
        td {
            background-color: $white;

            & > div:not(.schedule-line__empty) {
                font-weight: 500;
            }
        }
    }

    td {
        & > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__empty {
        color: $greyscale-600;
    }

    &__label {
        display: flex;
        width: 230px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        column-gap: 4px;
    }

    &__error {
        right: 4px;
        display: flex;
        justify-content: end;
        top: 4px;

        &-dot {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: $red;
            box-shadow: 0 0 0 1px $white;
        }
    }
}
</style>