<template>
    <div class='page max-page-width' style='position: relative; z-index: 2;'>
        <div class='documents-management-container pl-8 pt-8'>
            <unread-documents-alert
                v-if='unreadCount > 0'
                :count='unreadCount'
            />
<!--            <document-section-title class='mt-8' :icon='titleIcon' :title='title' />-->
        </div>
        <router-view />
        <div class='documents-management-container'>
            <div class='ps-8 py-8 documents-overview-disk-usage' v-if='diskSpace != null'>
                <span>{{ diskSpaceText }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import UnreadDocumentsAlert from '@/domain/documents/components/UnreadDocumentsAlert.vue'
import customApi from '@/plugins/customApi'
import DocumentSectionTitle from '@/domain/documents/components/DocumentSectionTitle.vue'

export default {
    name: 'DocumentsManagementLayout',
    components: { DocumentSectionTitle, UnreadDocumentsAlert },
    data() {
        return {
            status: null,
            diskSpace: null
        }
    },
    computed: {
        title() {
            return this.$route.meta.title
        },
        titleIcon() {
            return this.$route.meta.titleIcon ?? null
        },
        unreadCount() {
            return this.status?.unread_count ?? 0
        },
        diskSpaceText() {
            if (this.diskSpace == null) {
                return ''
            }

            const used = this.diskSpace.usedText
            const total = this.diskSpace.totalText

            return this.$t('Išnaudota vietos {used}/{total}', { used, total })
        }
    },
    methods: {
        async load() {
            const api = customApi()

            // const { data } = await api.factories.documents.overview.status()

            this.status = {
                unread_count: 0,
                space: {
                    used: 0,
                    total: 0
                }
            }
        },
        async fetchDiskSpace() {
            try {
                const {data} = await customApi().factories.documents.disk.fetchSpace()
                this.diskSpace = data
            } catch (error) {
                console.error(error)
            }
        }
    },
    mounted() {
        let breads = []

        this.$route.matched.forEach(match => {
            const title = match.meta?.title ?? null
            if (title == null) {
                return
            }

            breads.push({
                name: match.name,
                title
            })
        })

        this.load()
        this.fetchDiskSpace()
    },
    created() {
        this.$root.$on('documents-overview-disk-usage-refresh', this.fetchDiskSpace)
    },
    beforeDestroy() {
        this.$root.$off('documents-overview-disk-usage-refresh', this.fetchDiskSpace)
    }
}
</script>

<style lang='scss'>
@import '@/assets/sass/core/vars';
@import '@/assets/sass/mixins/responsive';

.documents-overview-disk-usage {
    color: $greyscale-600;
}

.unread-documents-alert {
    padding: 10px;
}

.documents-management-container {
    width: 70% !important;

    @include xl-down {
        width: 100% !important;
    }
}

.documents-management-sidebar {
    width: 30%;
    border-left: 1px solid #E6E9F0;

    @include xl-down {
        width: 0;
        border: 0;
    }
}



</style>