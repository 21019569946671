export default axios => ({
    config: () => axios.get("/api/v2/auth/config"),
    login: (payload) => axios.post("/api/auth/login", payload),
    forgotPassword: (email) => axios.post("/api/v2/auth/forgot-password", {email}),
    resetPassword: (payload) => axios.post("/api/v2/auth/reset-password", payload),
    inviteData: (token) => axios.get(`/api/v2/auth/invite/data/${token}`),
    register: (payload) => axios.post("/api/v2/auth/register", payload),
    twoFactor: (code) => axios.post("/api/auth/two-factor-challenge", {code}),

    configOld: () => axios.get("/api/v1/config"),
    logout: (requestName) => axios.post("/api/logout", {}, {requestName}),
});
