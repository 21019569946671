<template>
    <div>
        <slot name='title'/>
        <div class='approval-stages'>
            <message-alert
                v-if='stages.length === 0'
                id='empty-stages'
                :message='$t("Tvirtinimo eigos istorija galima tik naujiems prašymams (nuo {date})", {date})'
                type='info'
            />
            <slot v-else name='approvals' :stages='stages'>
                <approval-stage
                    v-for='(stage, index) in stages'
                    :key='stage.id'
                    :stage='stage'
                    :stage-index='index + 1'
                    :index='index'
                    :ignore-status='hideProgress'
                />
            </slot>
        </div>
    </div>
</template>

<script>
import HraTooltip from '@/components/ui/HraTooltip.vue'
import EmployeeTableView from '@/components/ui/views/EmployeeTableView.vue'
import UiIcon from '@/domain/components/UiIcon.vue'
import ApprovalStage from '@/domain/components/ApprovalStages/ApprovalStage.vue'
import MessageAlert from '@/domain/messages/components/MessageAlert.vue'

export default {
    name: 'ApprovalStages',
    components: { MessageAlert, ApprovalStage, UiIcon, EmployeeTableView, HraTooltip },
    props: {
        stages: { type: Array, required: true },
        hideProgress: { type: Boolean, default: false }
    },
    data() {
        return {
            date: '2025-05-26'
        }
    }
}
</script>