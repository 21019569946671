<template>
    <div>
        <template v-if="isView">
            <slot name="view.prepend" :value="innerValue" :view-value="viewValue"/>
            <slot name="view" :value="innerValue" :view-value="viewValue">
                {{ viewValid ? viewValue : '?' }}
            </slot>
            <slot name="view.append" :value="innerValue" :view-value="viewValue"/>
        </template>
        <validation-provider v-else :name="name" :rules="rules" immediate v-slot="{ classes, errors }">
            <input :name="name" type="hidden" v-model="innerValue">
            <div class="form-text-field" :class="classes">
                <div :class="`form-text-field__label`">
                    <slot name="label.prepend"/>
                    <label :for="fieldName">
                        <slot name="label" :label="translateLabel">{{ translateLabel }}</slot>
                    </label>
                    <slot name="label.append"/>
                </div>
                <div class="d-flex align-center gap-3" @dblclick="onEditField">
                    <slot name="input.prepend"/>
                    <div :class="`form-text-field__input`">
                        <slot name="input.inner.prepend"/>
                        <input
                            :id="fieldName"
                            :type="type"
                            :disabled="isDisabled"
                            v-model="innerValue"
                            :placeholder="translatePlaceholder"
                        />
                        <hra-field-editable v-if="editable" :now-edit="nowEdit" :loading="isLoading"
                                            @edit="onEditField" @abort="onAbortField" @save="onSaveField" />
                        <slot name="input.inner.append"/>
                    </div>
                    <slot name="input.append"/>
                </div>
            </div>
        </validation-provider>
    </div>
</template>

<script>
import hraFormMixin from "@/mixins/hraFormMixin";
import HraFieldEditable from "@/components/ui/form/HraFieldEditable";

export default {
    name: "HraFormTextField",
    components: {HraFieldEditable},
    props: {
        type: {type: String, default: "text"},
    },
    mixins: [hraFormMixin],
    computed: {},
}
</script>
