import Vue from 'vue'
import { vacationType } from '@/utils/default'
import i18n from '@/plugins/i18n'

export const USER_LOADING = 'USER_LOADING'
export const USER_GUEST = 'USER_GUEST'
export const USER_AUTH = 'USER_AUTH'
export const SETUP_ACTIVE = 'SETUP_ACTIVE'

export const USER_ROLE_ADMIN = 'admin'
export const USER_ROLE_EMPLOYEE = 'employee'
export const USER_ROLE_TEXT = {
    [USER_ROLE_ADMIN]: i18n.t('Admin'),
    [USER_ROLE_EMPLOYEE]: i18n.t('Darbuotojas')
}

const state = () => ({
    setup: null,
    isAuthenticated: null,
    isDepartmentLeader: false,
    profile: null,
    roleId: null,
    permissions: [],
    staticData: null,

    data: {
        isAuthenticated: false,
        profile: null,
        role_id: null,
        permissions: [],
        staticData: null
    },

    settings: {
        vacation: {
            types: {
                employee: [],
                leader: []
            },
            createBeforeDays: 0,
            abortNotApprove: 0,
            monthDay: '07-01'
        }
    }
})

const getters = {
    setup: state => state.setup,
    setupActive: state => state.setup?.active || false,
    setupStep: state => state.setup?.step || null,
    isDepartmentLeader: state => state.isDepartmentLeader ?? [],
    employeeVacationTypes: state => state.settings.vacation.types.employee ?? [],
    emailSettings: state => state.settings.email ?? ({}),
    leaderVacationTypes: state => state.settings.vacation.types.leader,
    vacationAutoApproveEmployeesIds: state => (state.settings.vacation.autoApproveEmployees ?? []).map(item => item.id),
    vacationAutoApproveEmployeesRows: state => {
        return (state.settings.vacation.autoApproveEmployees ?? [])
            .sort((a, b) => a.profile.name.localeCompare(b.profile.name))
            .map(item => ({ id: item.id, employee: item }))
    },
    employeeVacationTypeOptions: state => state.settings.vacation.types.employee.map(key => ({
        value: key,
        text: vacationType[key]
    })),
    leaderVacationTypeOptions: state => state.settings.vacation.types.leader.map(key => ({
        value: key,
        text: vacationType[key]
    })),
    vacationCreateBeforeDays: state => state.settings.vacation.createBeforeDays,
    vacationAbortNotApprove: state => state.settings.vacation.abortNotApprove,
    vacationMonthDay: state => state.settings.vacation.monthDay,

    status: (state) => {
        const status = {
            [USER_LOADING]: state.isAuthenticated == null,
            [USER_GUEST]: state.isAuthenticated === false,
            [USER_AUTH]: state.isAuthenticated === true,
            [SETUP_ACTIVE]: state.setup?.active || false
        }

        return Object.keys(status).filter(key => status[key])
    },

    name(state) {
        return state.profile?.name
    },
    color(state) {
        return state.profile?.color
    },
    avatarSrc(state) {
        return state.profile?.avatarSrc
    },
    id(state) {
        return state.profile?.id
    },
    permissions(state) {
        return state.permissions
    },
    isAuthenticated(state) {
        return state.isAuthenticated
    },
    email: state => state.profile?.email || null,

    vacationBalance(state) {
        return state.staticData?.vacationBalance || { today: 0, yearEnd: 0 }
    },

    employees(state) {
        return state.staticData?.employees || {}
    },

    roleOptions(state) {
        const staticData = state.data.staticData
        if (staticData?.roles) {
            return Object.keys(staticData.roles)
                .map(id => ({
                    value: parseInt(id),
                    text: staticData.roles[id]
                }))
        }
        return []
    },
    departmentOptions(state) {
        const staticData = state.data.staticData
        if (staticData?.departments) {
            return staticData.departments
                .map(department => ({
                    value: parseInt(department.id),
                    text: department.name
                }))
        }
        return []
    },
    vacationDepartmentOptions(state) {
        const staticData = state.data.staticData
        if (staticData?.vacationDepartments) {
            return staticData.vacationDepartments
                .map(department => ({
                    value: parseInt(department.id),
                    text: department.name
                }))
        }
        return []
    },
    dutyOptions(state) {
        const staticData = state.data.staticData
        if (staticData?.duties) {
            return staticData.duties
                .map(duty => ({
                    value: parseInt(duty.id),
                    text: duty.name
                }))
        }
        return []
    },

    vacationTypeOptions(state) {
        const staticData = state.data.staticData
        if (staticData?.vacationTypes) {
            return Object.keys(staticData.vacationTypes)
                .map(id => ({ value: parseInt(id), text: staticData.vacationTypes[id] }))
        }
        return []
    },
    vacationPayTypeOptions(state) {
        const staticData = state.data.staticData
        if (staticData?.vacationPayTypes) {
            return Object.keys(staticData.vacationPayTypes)
                .map(id => ({ value: parseInt(id), text: staticData.vacationPayTypes[id] }))
        }
        return []
    },
    vacationEmployeeOptions(state) {
        const staticData = state.data.staticData
        if (staticData?.employees) {
            const profileId = state.data.profile.id
            return Object.keys(staticData.employees)
                .filter(id => parseInt(id) !== profileId)
                .map(id => {
                    const employee = staticData.employees[id]
                    return { value: parseInt(id), text: employee.name, employee }
                })
        }
        return []
    },

    allEmployeeOptions(state) {
        const staticData = state.data.staticData
        if (staticData?.employees) {
            return Object.keys(staticData.employees)
                .map(id => {
                    const employee = staticData.employees[id]
                    return { value: parseInt(id), text: employee.name, employee }
                })
        }
        return []
    },

    employeeId: ({ profile }) => profile?.id || null,
    getEmployeeById: (state) => (employeeId) => {
        return state.data.staticData?.employees[employeeId] || null
    },
    departmentColors(state) {
        return state.data.staticData?.departmentColors || []
    }
}

const actions = {
    disableSetup: ({ commit }) => {
        commit('disabledSetup')
    },
    login: ({ commit }, payload) => {
        commit('login', payload)
    },
    logout: ({ commit }) => {
        commit('logout')
    },
    changeSettings({ commit }, payload) {
        commit('changeSettings', payload)
    },
    changeEmailSettings: ({ commit }, payload) => commit('changeEmailSettings', payload),
    addVacationAutApproveEmployee: ({ commit }, payload) => commit('addVacationAutApproveEmployee', payload),
    removeVacationAutApproveEmployee: ({ commit }, payload) => commit('removeVacationAutApproveEmployee', payload),
    async init({ commit, dispatch }) {
        try {
            const { data } = await Vue.prototype.$api.auth.config()
            commit('login', data)
        } catch (error) {
            commit('logout')
        }
    },
    changeAvatar({ commit }, payload) {
        commit('changeAvatar', payload)
    },
    changeDescription: ({ commit, state }, { employeeId, description }) => {
        if (state.profile?.id === employeeId) {
            commit('changeDescription', description)
        }
    }
}

const mutations = {
    login(state, payload) {
        state.isAuthenticated = payload.isAuthenticated
        state.isDepartmentLeader = payload.isDepartmentLeader
        state.profile = payload.profile
        state.roleId = payload.roleId
        state.permissions = payload.permissions || []
        state.staticData = payload.staticData
        state.setup = payload.setup
        state.settings = payload.settings
    },
    logout(state) {
        state.isAuthenticated = false
        state.isDepartmentLeader = false
        state.profile = null
        state.roleId = null
        state.permissions = []
        state.staticData = null
        state.setup = null
    },

    changeSettings(state, payload) {
        state.settings = payload
    },
    changeEmailSettings: (state, payload) => {
        delete payload.password
        state.settings.email = payload
    },
    addVacationAutApproveEmployee(state, payload) {
        state.settings.vacation.autoApproveEmployees.push(payload)
    },
    removeVacationAutApproveEmployee(state, payload) {
        state.settings.vacation.autoApproveEmployees = state.settings.vacation.autoApproveEmployees
            .filter(({ id }) => id !== payload)
    },

    updateAppConfig(state, payload) {
        state.data = payload
    },
    disabledSetup: state => state.setup = null,
    changeSetupMode: (state, payload) => {
        if (state.setup) {
            state.setup.active = payload
        }
    },
    changeSetupModeStep: (state, payload) => {
        if (state.setup) {
            state.setup.step = payload
        }
    },
    changeAvatar: (state, payload) => {
        if (state?.profile) {
            state.profile.avatarSrc = payload
        }
    },
    changeDescription: (state, payload) => {
        if (state?.profile) {
            state.profile.description = payload
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
