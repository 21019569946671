<template>
    <div>
        <hra-tooltip :text='text'>
            <ui-icon style='color: #32398B' v-if='!!text'>dtv-question</ui-icon>
        </hra-tooltip>
    </div>
</template>

<script>
import HraTooltip from '@/components/ui/HraTooltip.vue'
import UiIcon from '@/domain/components/UiIcon.vue'

export default {
    name: 'DocumentCommentTooltip',
    components: { UiIcon, HraTooltip },
    props: {
        text: {type: String, default: null}
    }
}
</script>

<style lang='scss'>

</style>