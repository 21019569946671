<template>
  <v-app class="app" id="inspire">
    <v-main class="auth">
      <v-container fluid fill-height>
        <v-row>
          <v-col class="d-flex justify-center flex-column">
            <h1>404 Page not found</h1>
            <Button color="default" no-background @click.prevent="goToHome">Back to
              home</Button>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import Button from "@/components/ui/button/Button";
export default {
    components: {Button},
    methods: {
        goToHome() {
            window.location.href = "/";
        },
    },
}
</script>
