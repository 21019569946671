import DepartmentCreateModal from "@/domain/company/modals/DepartmentCreateModal.vue";
import DepartmentUpdateModal from "@/domain/company/modals/DepartmentUpdateModal.vue";
import EmployeeChangeDepartmentModal from "@/domain/company/modals/EmployeeChangeDepartmentModal.vue";
import DepartmentAddEmployeeModal from "@/domain/company/modals/DepartmentAddEmployeeModal.vue";
import DepartmentChangeLeader from "@/domain/company/modals/DepartmentChangeLeader.vue";
import DutyCreateModal from "@/domain/company/modals/DutyCreateModal.vue";
import DutyUpdateModal from "@/domain/company/modals/DutyUpdateModal.vue";
import EmployeeSelectModal from "@/domain/employee/modals/EmployeeSelectModal.vue";

export default ({
  DepartmentCreateModal,
  DepartmentUpdateModal,
  DepartmentChangeLeader,
  EmployeeChangeDepartmentModal,
  DepartmentAddEmployeeModal,
  DutyCreateModal,
  DutyUpdateModal,
  EmployeeSelectModal
});