<template>
    <custom-dialog :max-width='670'>
        <template #title>{{ modalTitle }}</template>
        <template #content>
            <v-row>
                <v-col>
                    <text-field type='number' min='0' step='30' :label='$t("Po darbo pradžios")' v-model='after' />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <text-field type='number' min='0' step='30' :label='$t("Trukmė")' v-model='duration' />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <text-field type='number' min='0' :label='$t("Dublikuoti")' v-model='duplicates' />
                </v-col>
                <v-col>
                    <form-checkbox
                        :is-checked.sync='subWorkTime'
                        class='mt-9'
                    >
                        <div class='ml-3 hra-text--16'>
                            {{ $t('Subwork time') }}
                        </div>
                    </form-checkbox>
                </v-col>
            </v-row>
            <v-row v-for='user in userOrders' :key='user.id'>
                <v-col>
                    <text-field
                        type='number'
                        min='0'
                        :label='user.label'
                        v-model='user.order'
                    />
                </v-col>
            </v-row>
        </template>

        <template #footer>
            <ui-button
                prepend-icon='close'
                background='default'
                @click='close'
            >{{ $t('Uždaryti') }}
            </ui-button>
            <ui-button
                prepend-icon='ok'
                background='primary'
                @click='onClick'
            >{{ buttonSubmitTitle }}
            </ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import dialogMixin from '@/mixins/dialogMixin'
import { mapGetters } from 'vuex'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import TextField from '@/domain/fields/TextField.vue'
import UiButton from '@/domain/components/UiButton.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import { GLOBAL_TOAST_TAG } from '@/utils/default'

export default {
    name: 'ScheduleBreakModal',
    mixins: [dialogMixin],
    props: {
        payload: { type: Object, default: null }
    },
    components: {
        UiButton,
        TextField,
        CustomDialog,
        FormCheckbox
    },
    data() {
        return {
            after: 0,
            duration: 0,
            duplicates: 0,
            subWorkTime: false,
            userOrders: []
        }
    },
    beforeMount() {
        if (this.payload == null) {
            this.userOrders = this.users.map((user, key) => this.formatUser(user, key + 1))
            return
        }

        console.debug('payload', this.payload)

        this.after = this.payload.after
        this.duration = this.payload.duration
        this.duplicates = this.payload.duplicates
        this.subWorkTime = this.payload.subWorkTime
        this.userOrders = this.payload.userOrders.map(({ user, order }) => this.formatUser(user, order))
    },
    computed: {
        ...mapGetters({
            users: 'schedulerStore/users'
        }),
        modalTitle() {
            return this.payload == null ? this.$t('Pridėti pertrauką') : this.$t('Redaguoti pertrauką')
        },
        buttonSubmitTitle() {
            return this.payload == null ? this.$t('Pridėti') : this.$t('Redaguoti')
        }
    },
    methods: {
        formatUser(user, order) {
            let label = [user.name]

            if (!!user.dismissedDate) {
                label.push('(' + this.$t('Atleistas:') + ' ' + (user.dismissedDate) + ')')
            }

            label.push('order')

            return {
                id: user.id,
                label: label.join(' '),
                order
            }
        },
        async onClick() {
            const data = {
                after: this.after,
                duration: this.duration,
                duplicates: this.duplicates,
                subWorkTime: this.subWorkTime,
                userOrder: this.userOrders.map(({ id, order }) => ({ id, order }))
            }

            const result = this.payload == null
                ? await this.$store.dispatch('schedulerStore/createNewBreak', data)
                : await this.$store.dispatch('schedulerStore/updateBreakById', {
                    id: this.payload.id,
                    payload: data
                })

            if (!result) {
                return
            }

            const successMessage = this.payload == null ? this.$t('Break create success') : this.$t('Break edit success')
            await this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag: GLOBAL_TOAST_TAG,
                message: successMessage
            })

            this.close()
        }
    }
}
</script>