import CustomApi from '@/plugins/customApi'

const state = () => ({
    types: ['all', 'leader', 'leader_with_approvals'],
    employees: {
        all: null,
        leader: null,
        leader_with_approvals: null
    },
    fetching: {
        all: false,
        leader: false,
        leader_with_approvals: false
    }
})

const getters = {
    employeesAllUserOptions: state => {
        if (!state.employees.all) return []
        return state.employees.all.users.map(user => ({
            value: user.id,
            label: user.name
        }))
    },
    employeesAllUserTimeCardNumberOptions: state => {
        if (!state.employees.all) return []
        return state.employees.all.users.map(user => ({
            value: user.timeCardNumber,
            label: user.timeCardNumber
        }))
    },
    employeesAllDepartmentOptions: state => {
        if (!state.employees.all) return []
        return state.employees.all.departments.map(department => ({
            value: department.id,
            label: department.name
        }))
    },
    employeesAllDutyOptions: state => {
        if (!state.employees.all) return []
        return state.employees.all.duties.map(duty => ({
            value: duty.id,
            label: duty.name
        }))
    }
}

const customApi = CustomApi()
const actions = {
    async fetchEmployees({ state }, { type }) {
        if (state.fetching[type] || state.employees[type] != null) return
        state.fetching[type] = true

        try {
            const { data } = await customApi.factories.employee.filters.fetch({
                type
            })

            state.employees[type] = data
        } catch (e) {
            console.error(e)
        } finally {
            state.fetching[type] = false
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions
}