<template>
    <ValidationProvider
        :name="$attrs.name"
        :rules="rules"
        v-slot="{ classes, errors }"
    >
        <div :class="[...fieldClasses, ...classes]">
            <template v-if="isView">
                <input type="hidden" v-model="innerValue"/>
                <div :class="`${baseClassName}__label`">{{ translateLabel }}</div>
                <div :class="`${baseClassName}__input-view`">
                    <slot name="input.view" :value="errors.length === 0 ? dataView : '?'">
                        {{ errors.length === 0 ? dataView : '?' }}
                    </slot>
                </div>
            </template>
            <template v-else>
                <div :class="`${baseClassName}__label`">{{ translateLabel }}</div>
                <input type="hidden" v-bind="$attrs" :id="$attrs.name" :ref="$refs" v-model="dataView"/>
                <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="0"
                    :nudge-bottom="5"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    :class="`${baseClassName}__picker-menu`"
                    :disabled="disabled"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <div
                            :class="`${baseClassName}__field`"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <input
                                readonly
                                v-model="dataView"
                                :placeholder="translatePlaceholder"
                            />
                            <i :class="['icon-calendar', menu ? 'active' : '']"/>
                        </div>
                    </template>
                    <v-date-picker
                        v-model="innerValue"
                        @input="menu = false"
                        no-title
                        locale="lt"
                        :class="`${baseClassName}__picker`"
                        :first-day-of-week="1"
                        color="blue"
                        show-adjacent-months
                        :month-format="monthFormat"
                        :min="dateFrom"
                        :type="type"
                    />
                </v-menu>
            </template>
        </div>
    </ValidationProvider>
</template>

<script>
import moment from "moment";
import formFieldMixin from "@/mixins/formFieldMixin";
import i18n from '@/plugins/i18n'

export default {
    mixins: [formFieldMixin],
    props: {
        dateFrom: {
            type: String,
            default: null,
        },
        isView: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: "date"
        },
    },
    data: () => ({
        innerValue: moment(new Date()).format("YYYY-MM-DD"),
        menu: false,
        baseClassName: "form-date-picker",
        dataView: ""
    }),
    watch: {
        innerValue: {
            handler(newValue) {
                let dateView = "";
                if (newValue != null) {
                    const date = moment(this.innerValue).locale(i18n.locale);
                    switch (this.type) {
                        case "month":
                            dateView = date.format("YYYY MMMM");
                            break;
                        default:
                            dateView = date.format("LL");
                    }
                }

                this.dataView = dateView;
            },
            immediate: true,
        },
        dateFrom: {
            handler(newValue) {
                if (this.value < newValue || (this.value == null && newValue != null)) {
                    this.$emit("input", newValue);
                }
            },
        }
    },
    methods: {
        monthFormat(month) {
            return moment(month + "-01").locale(i18n.locale).format("MMM");
        },
        setInnerValue(newValue) {
            if (newValue === "") {
                newValue = null;
            }

            this.innerValue = newValue;
        },
    }
};
</script>
