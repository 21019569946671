<template>
	<v-overlay
		v-if="hover"
		class="hover-overlay"
		absolute
		color="rgba(218, 213, 233, 0.95)"
		opacity="1"
	>
		<slot />
	</v-overlay>
</template>

<script>
export default {
	name: "HoverOverlay",
	props: {
		hover: {type: Boolean, default: false},
	},
}
</script>