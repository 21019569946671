import { mapMutations } from 'vuex'

export default {
    props: {
        beforeSave: {
            type: Function, default: () => {
            }
        },
        afterSave: {
            type: Function, default: () => {
            }
        }
    },
    data: () => ({
        timeout: null,
        closeDialogDelay: 500
    }),
    created() {
        this.opened(this.$options.name)
    },
    destroyed() {
        this.$emit('destroyed')
        this.closed(this.$options.name)
    },
    methods: {
        ...mapMutations({
            closeModal: 'modals/close',
            opened: 'modals/opened',
            closed: 'modals/closed'
        }),
        close() {
            this.closeModal(this.$options.name)
        },
        closeWithDelay() {
            this.timeout = setTimeout(() => {
                this.close()
            }, this.closeDialogDelay)
        },
        getContentRef() {
            if (this.$refs.dialog == null) {
                return null
            }

            if (this.$refs.dialog?.$refs?.content == null) {
                return null
            }

            return this.$refs.dialog.$refs.content
        },
        scrollToDialogContentTop() {
            const contentRef = this.getContentRef()

            if (contentRef == null) {
                return
            }

            contentRef.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
        },
        scrollToDialogContentBottom() {
            const contentRef = this.getContentRef()

            if (contentRef == null) {
                return
            }

            contentRef.scrollTo({ left: 0, top: contentRef.scrollHeight, behavior: 'smooth' })
        }
    }
}
