import {asyncTimeout} from "@/utils/default";

const state = () => ({
  loading: false,
  data: [],
  total: 0,
});

const getters = {
  loading(state) {
    return state.loading;
  },
  data(state) {
    return state.data;
  },
  total(state) {
    return state.total;
  },
};

const mutations = {
  hasLoading(state) {
    state.loading = true;
  },
  hasLoaded(state) {
    state.loading = false;
  },
  setData(state, payload) {
    state.data = payload.data;
    state.total = payload.meta.total;
  },
  removeRow(state, id) {
    const index = state.data.map(item => item.id).indexOf(id);
    if (index > -1) {
      state.data.splice(index, 1);
      state.total--;
    }
  },
  clear(state) {
    state.data = [];
    state.total = 0;
  },
};

const mockData = page => {
  const total = 2;
  const emp = {
    employee: {name: "Stephanie Cook", duties: ['Programmer'], avatar: null},
    departments: [{color: "red", name: "IT"}],
    period: {fromDate: "2022-03-13", toDate: "2022-03-17", title: "Kasmetinės atostogos"},
    days: Math.round(Math.random() * 10),
    deputy: "Pavaduojančių nebus",
  };
  const data = Array(total).fill(emp).map((row, key) => ({
    ...row,
    employee: {
      ...row.employee,
      name: `Stephanie Cook ${key}`,
    },
    id: key
  }));
  const meta = {
    total,
  };

  return {
    data, meta
  };
}

const actions = {
  async fetch({commit, state}, {page}) {
    commit("hasLoading");
    try {
      // const {data} = await this._vm.$api.employee.getAll({page, limit: state.limit});

      const data = mockData(page);

      commit("setData", data);
    } catch (error) {
      
    } finally {
      commit("hasLoaded");
    }
  },
  async abort({commit}, id) {
    await asyncTimeout(600);
    commit("removeRow", id);
  },
  async accept({commit}, id) {
    await asyncTimeout(600);
    commit("removeRow", id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
