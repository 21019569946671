<template>
  <v-app>
  <v-main>
  <v-container class="document-sign py-8 pe-6">
    <v-row v-if="loading" class="fill-height d-flex justify-center align-center">
      <div class="mt-auto">
        <v-progress-circular color="primary" size="64" indeterminate></v-progress-circular>
      </div>
    </v-row>
    <v-row v-else class="d-flex">
      <v-col cols="9"><img src="@/assets/images/logo-alt.svg" alt="logo"/>       <v-divider />
      </v-col>
      <v-col cols="12">
        <span class="sign-header">{{ $t('Dokumentai susipažinimui')}}</span>
      </v-col>
      <v-col cols="12">
        <span class="sign-file"> {{ item !== null ? item.document.title : '' }} </span>
      </v-col>
      <v-col cols="12">
        <iframe v-if="fileType && fileType === 'pdf'" :src="fileUrl" width="100%" height="700px"></iframe>
        <docx-preview v-else-if="fileType && fileType === 'docx'" :fileUrl="fileUrl"/>
        <excel-preview v-else-if="fileType && fileType === 'xls' || fileType && fileType === 'xlsx'" :fileUrl="fileUrl"/>
      </v-col>
      <v-col cols="12" class="d-flex flex-row-reverse">
        <ui-button prepend-icon="ok" background="primary" @click="acceptDocument" :class="'ml-2'">{{ $t('Priimti') }}</ui-button>
        <ui-button prepend-icon="close" background="danger-light" :modal="{name: 'DocumentCancelModal', props: { id: item !== null ? item.document.id : null, title: item !== null ? item.document.title : null, cancel: false } }">{{ $t('Atmesti') }}</ui-button>
      </v-col>
    </v-row>
  </v-container>
  </v-main>
  </v-app>
</template>

<script>
import FullRowLoader from "@/components/ui/FullRowLoader.vue";
import customApi from "@/plugins/customApi";
import UiButton from "@/domain/components/UiButton.vue";
import DocxPreview from "@/domain/documents/components/DocxPreview.vue";
import ExcelPreview from "@/domain/documents/components/ExcelPreview.vue";
import { axiosInstance } from '@/plugins/customApi';
import {GLOBAL_TOAST_TAG, redirectToAuth} from "@/utils/default";
import ExpansionPanel from "@/domain/components/ExpansionPanel.vue";
import TextField from "@/domain/fields/TextField.vue";

export default {
  name: "DocumentSignComponent",
  components: {TextField, ExpansionPanel, ExcelPreview, DocxPreview, UiButton, FullRowLoader},
  data() {
    return {
      loading: true,
      document: null,
      token: null,
      excelHtml: null,
      item: null,
    };
  },
  mounted() {
    this.$root.$on('documents-close-history', () => {
      redirectToAuth();
    });

    this.token = this.$route.query.token;
    this.load();
  },
  computed: {
    fileType() {
      return this.item !== null ? this.item.document.filename.split('.').pop().toLowerCase() : '';
    },
    fileUrl() {
      if (this.item !== null) {
        return `${axiosInstance.defaults.baseURL}/api/documents/show/${this.item.document.filename}`;
      }

      return '';
    },
  },
  methods: {
    async load() {
        const api = customApi();
        this.item = await api.factories.documents.sign.fetch(this.token)
        this.loading = false

      // this.loading = true;
      // const api = customApi();
      //
      // await api.factories.documents.sign.fetch(this.token).then((data) => {
      //   this.item = data;
      //   this.loading = false;
      // });
      //
      // this.loading = false;
    },
    async acceptDocument() {
      const api = customApi();

      try {
        await api.factories.documents.employees.history.acceptDocument(this.item);

        await this.$store.dispatch('messagesStore/showSuccessMessage', {
          tag: GLOBAL_TOAST_TAG,
          message: this.$t('Dokumentas priimtas')
        });
      } catch {
        await this.$store.dispatch('messagesStore/showErrorMessage', {
          tag: GLOBAL_TOAST_TAG,
          message: this.$t('Nepavyko priimti dokumento'),
        });
      }

      redirectToAuth();
    },
  },
}

</script>

<style scoped lang="scss">
.document-sign {
  background: linear-gradient(180deg, #FBF8FF 0%, rgba(247, 241, 253, 0.00) 98.37%);
}

.sign-header {
  color: #1C2538;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 95.238% */
}

.sign-file {
  color:  #32398B;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
</style>