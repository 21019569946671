<template>
    <setting-module-item-config>
        <template #header>
            {{ $t('setting.module.default.form.allCompanyEmployees') }}
        </template>

        <slot />
    </setting-module-item-config>
</template>

<script>
import SettingModuleItemConfig from '@/domain/settings/components/SettingModuleItemConfig.vue'

export default {
    name: 'SettingModuleItemConfigCompany',
    components: {
        SettingModuleItemConfig,
    }
}
</script>