export const INIT_YEARLY_VACATION_CALENDAR = "INIT_YEARLY_VACATION_CALENDAR";
export const INIT_VACATION_CALCULATIONS = "INIT_VACATION_CALCULATIONS";
export const INIT_VACATIONS = "INIT_VACATIONS";
export const INIT_PLUS_VACATIONS = "INIT_PLUS_VACATIONS";
export const INIT_CHILD = "INIT_CHILD";
export const INIT_DISABILITIES = "INIT_DISABILITIES";
export const INIT_CONTRACTS = "INIT_CONTRACTS";
export const INIT_CONTRACT_DETAILS = "INIT_CONTRACT_DETAILS";

export default ({
  [INIT_YEARLY_VACATION_CALENDAR]: (state, payload) => state.yearlyVacationCalendar = payload,
  [INIT_VACATION_CALCULATIONS]: (state, payload) => state.vacationCalculations = payload,
  [INIT_VACATIONS]: (state, payload) => state.vacations = payload,
  [INIT_PLUS_VACATIONS]: (state, payload) => state.plusVacations = payload,
  [INIT_CHILD]: (state, payload) => state.child = payload,
  [INIT_DISABILITIES]: (state, payload) => state.disabilities = payload,
  [INIT_CONTRACTS]: (state, payload) => state.contracts = payload,
  [INIT_CONTRACT_DETAILS]: (state, payload) => state.contractDetails = payload,
});