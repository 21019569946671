export const categoryTitle = ({categoryTitle}) => categoryTitle;
export const categories = ({categories}) => categories;
export const comments = ({comments}) => comments;
export const documents = ({documents}) => documents;
export const questions = ({questions}) => questions.map(question => ({...question, type: 'dk'}));
export const questionsCount = ({questions}) => questions.length;
export const activePageId = ({activePageId}) => activePageId;
export const homeQuestions = ({homeDkQuestions, homeHrQuestions}) => [
    ...homeDkQuestions.map(question => ({...question, type: 'dk'})),
    ...homeHrQuestions.map(question => ({...question, type: 'hr'}))
].sort((a, b) => new Date(b.created) - new Date(a.created));
export const questionBalance = ({dkQuestionBalance}) => dkQuestionBalance;
export const hrQuestionBalance = ({hrQuestionBalance}) => hrQuestionBalance;