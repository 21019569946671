<template>
	<Auth :fullPageLoader="isLoading">
		<template v-if="payload" #header>{{ $t("Susikurkite slaptažodį") }}</template>
		<template #form>
			<RegisterForm v-if="payload" :submit-text="'test'" :form-data="payload"/>
			<HraAlert v-if="tokenNotFound" :color="uiColor.danger">
				<template #icon>
					<hra-icon :name="iconName.warning"/>
				</template>
				{{ $t("Url nerastas") }}
			</HraAlert>
		</template>
		<template #after-card>
			<hra-button-link full-width no-background :text-width="buttonTextWidth.normal"
			                 :to="{ name: 'login' }">{{ $t('Grįžti į prisijungimą') }}
			</hra-button-link>
		</template>
	</Auth>
</template>

<script>
import Auth from "@/components/Auth.vue";
import RegisterForm from "@/views/forms/auth/RegisterForm.vue";
import HraAlert from "@/components/ui/HraAlert";
import HraIcon from "@/components/ui/HraIcon";
import HraButtonLink from "@/components/ui/button/HraButtonLink";

export default {
	components: {
		HraButtonLink,
		HraIcon,
		HraAlert,
		Auth,
		RegisterForm,
	},
	data: () => ({
		payload: null,
		tokenNotFound: false,
		loaderName: "Auth-Invite-Get-Data",
	}),
	inject: ["authService"],
	async created() {
		this.payload = await this.authService.getInviteData(this.$route.params.token);
	},
};
</script>
