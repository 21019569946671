<template>
    <custom-dialog :max-width='1600' ref='dialog' has-custom-footer :loading='loading'>
        <template #title>{{ $t('Ligonlapių įkėlimas') }}</template>
        <template #content>
            <div class='d-flex flex-column pa-6' style='position: sticky; left: 0;'>
                <import-sick-leaves-upload @selectedFile='handleSelectedFile'/>
            </div>
            <div class='d-flex flex-column pa-6 pt-0'>
                <validation-observer ref='ValidationObserver'>
                    <import-sick-leaves-table />
                </validation-observer>
            </div>
        </template>
        <template #footer>
            <ui-button
                background='primary'
                only-text
                prepend-icon='plus'
                size='medium'
                @click='handleCreateNewRow'
            >{{ $t('Pridėti naują eilutę') }}</ui-button>
            <v-spacer />
            <ui-button :disabled='loading' prepend-icon='close' background='danger-light' @click='close'>{{ $t('Atšaukti') }}</ui-button>
            <ui-button :disabled='loading' prepend-icon='ok' background='primary' @click='handleSave'>{{ $t('Išsaugoti') }}</ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import RemoteWorkForm from '@/domain/remoteWork/components/RemoteWorkForm.vue'
import UiButton from '@/domain/components/UiButton.vue'
import RemoteWorkCalendars from '@/domain/remoteWork/components/RemoteWorkCalendars.vue'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import dialogMixin from '@/mixins/dialogMixin'
import ImportSickLeavesTable from '@/domain/sickLeaves/components/import/ImportSickLeavesTable.vue'
import ImportSickLeavesUpload from '@/domain/sickLeaves/components/import/ImportSickLeavesUpload.vue'
import store from '@/domain/sickLeaves/stores/importSickLeavesModalStore'
import CustomApi from '@/plugins/customApi'
import { GLOBAL_TOAST_TAG } from '@/utils/default'
import { ValidationObserver } from 'vee-validate'

const storeNamespace = 'ImportSickLeavesModalStore'
const customApi = CustomApi()
export default {
    name: 'ImportSickLeavesModal',
    components: { ValidationObserver, ImportSickLeavesUpload, ImportSickLeavesTable, CustomDialog, RemoteWorkCalendars, UiButton, RemoteWorkForm },
    mixins: [dialogMixin],
    data() {
        return {
            loading: false,
            storeNamespace
        }
    },
    provide() {
        return {
            storeNamespace: this.storeNamespace
        }
    },
    beforeCreate() {
        this.$store.registerModuleSafely(storeNamespace, store)
    },
    beforeDestroy() {
        this.$store.unregisterModuleSafely(storeNamespace)
    },
    methods: {
        async handleCreateNewRow() {
            await this.$store.dispatch(`${this.storeNamespace}/createNewRow`)
            this.validateTable()
            this.scrollToDialogContentBottom()
        },
        handleSelectedFile(file) {
            this.importFile(file)
        },
        async importFile(file) {
            this.loading = true
            try {
                const formData = new FormData()
                formData.append('file', file)

                const {data} = await customApi.factories.events.sickLeaves.import(formData)
                await this.$store.dispatch(`${this.storeNamespace}/setRows`, data)
                this.validateTable()
            } catch (e) {
                console.error(e)
            } finally {
                this.loading = false
            }
        },
        validateTable() {
            return this.$refs.ValidationObserver.validate()
        },
        async handleSave() {
            if (!(await this.validateTable())) {
                await this.$store.dispatch('messagesStore/showErrorMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Užpildykite visus privalomus laukus')
                })
                return
            }

            const rows = this.$store.getters[`${this.storeNamespace}/rows`]
            if (rows.length === 0) {
                await this.$store.dispatch('messagesStore/showErrorMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Neįvestas nė vienas ligonlapis')
                })
                return
            }

            this.loading = true
            const payload = rows.map(row => ({
                userId: row.user?.id ?? null,
                code: (row.code ?? '') + '',
                personalCode: (row.personalCode ?? '') + '',
                personalSDSeries: (row.personalSDSeries ?? '') + '',
                personalSDNumber: (row.personalSDNumber ?? '') + '',
                type: (row.type ?? '') + '',
                noteSeries: (row.noteSeries ?? '') + '',
                noteNumber: (row.noteNumber ?? '') + '',
                start: row.start,
                end: row.end,
                sickType: (row.sickType ?? '') + '',
                filledInPolicyholderInfo: row.filledInPolicyholderInfo,
                policyholderNeedsToPay: row.policyholderNeedsToPay,
                noteDeleted: row.noteDeleted,
                submittedNPSD: row.submittedNPSD
            }))

            try {
                await customApi.factories.events.sickLeaves.createMany(payload)
                const userIds = [...new Set(payload.map(row => row.userId))]
                const start = payload.reduce((acc, curr) => acc == null || curr.start < acc ? curr.start : acc, null)
                const end = payload.reduce((acc, curr) => acc == null || curr.end > acc ? curr.end : acc, null)
                this.$emit('imported', userIds, start, end)
                await this.$store.dispatch('messagesStore/showSuccessMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Išsaugota sekmingai')
                })
                this.close()
            } catch (error) {
                const messages = error.response?.data?.message ?? null
                if (messages != null) {
                    await this.$store.dispatch(`${this.storeNamespace}/setRowErrors`, messages)
                }

                if (messages == null) {
                    console.error(error)
                }

                await this.$store.dispatch('messagesStore/showErrorMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Įvyko klaida. Bandykite dar kartą')
                })
                this.scrollToDialogContentTop()
            } finally {
                this.loading = false
            }
        }
    }
}
</script>