<template>
	<page
		:loader-listen="initTag"
		:message-listen="initTag"
	>

<!--		/api/v2/vacations/reports/balance/:date-->


    <ui-button
      :modal="{name: 'EventModal', props: {employeeId: 1}}"
    >
      new event
    </ui-button>

		<div class="d-flex align-end pa-6 gap-6">
			<select-field
				name="employeeId"
				:label="'Darbuotojas'"
				:single-line="false"
				:options-service="employeeAutocompleteService.find"
				v-model="filter.employeeId"
				dynamic filtered
			/>

			<datepicker-field
				style="width: 250px;"
				name="fromDate"
				:label="'Data nuo'"
				v-model="filter.fromDate"
			/>

			<datepicker-field
				style="width: 250px;"
				name="toDate"
				:label="'Data iki'"
				:min-date="filter.fromDate"
				v-model="filter.toDate"
			/>

			<hra-button @click="onRefresh">Refresh</hra-button>
		</div>

		<div class="d-flex flex-column gap-4 px-6 mb-3">
			<hra-table :headers="headersCalculate" :rows="calculations">
				<template #header.custom><h2>Paskaičiavimai</h2></template>
				<template #col.type="{colValue}">{{ vacationType[colValue] }}</template>
				<template #col.accumulated="{colValue}">{{ colValue.toFixed(4) }}</template>
				<template #col.reserved="{colValue}">{{ colValue.toFixed(4) }}</template>
				<template #col.balance="{colValue}">{{ colValue.toFixed(4) }}</template>
			</hra-table>

			<hra-table
				:headers="headersContract"
				:rows="contracts"
				need-actions
				expand-multiple-row
				:expand-row-ids.sync="contractExpandIds"
			>
				<template #header.custom><h2>Sutartis</h2></template>

				<template #col.actions-base="{row, expandRow}">
					<hra-tooltip :text="$t(row.isExpand ? 'Suskleisti' : 'Išskleisti')">
						<hra-button
							:size="buttonSize.small"
							:color="uiColor.default"
							:hover-background="!row.isExpand"
							@click="() => expandRow(row.id)"
						>
							<v-fab-transition>
								<div :key="`${row.isExpand ? 1 : 0}-expand`">
									<hra-icon :name="row.isExpand ? iconName.up : iconName.down"/>
								</div>
							</v-fab-transition>
						</hra-button>
					</hra-tooltip>
				</template>

				<template #row-expansion="{row}">
					<div class="pa-3">
						<hra-table :headers="headersContractDetail" :rows="row.details"/>
					</div>
				</template>
			</hra-table>

			<hra-table :headers="headersDisability" :rows="disabilities">
				<template #header.custom><h2>Neįgalumai</h2></template>
			</hra-table>

			<hra-table :headers="headersPlusVacation" :rows="plusVacations">
				<template #header.custom><h2>Plus atostogos</h2></template>
				<template #col.calcInYear="{colValue}">{{ colValue ? 'Yes' : 'No' }}</template>
				<template #col.count="{colValue}">{{ colValue.toFixed(4) }}</template>
			</hra-table>

			<hra-table :headers="headersVacation" :rows="vacations">
				<template #header.custom><h2>Atostogos</h2></template>
				<template #col.status="{colValue}">{{ vacationStatus[colValue] }}</template>
				<template #col.typeId="{colValue}">{{ vacationType[colValue] }}</template>
			</hra-table>

			<hra-table :headers="headersChild" :rows="child">
				<template #header.custom><h2>Vaikai</h2></template>
				<template #col.disability="{colValue}">{{ colValue ? 'Yes' : 'No' }}</template>
			</hra-table>

			<hra-table :headers="headersRatios" :rows="ratios">
				<template #header.custom><h2>Logai</h2></template>
				<template #col.ratio="{colValue}">{{ colValue.toFixed(4) }}</template>
			</hra-table>
		</div>


	</page>
</template>

<script>
import Page from "@/domain/components/Page";
import debuggerService from "@/domain/debugger/services/debuggerService";
import HraTable from "@/components/ui/table/HraTable";
import {mapGetters} from "vuex";
import SelectField from "@/domain/fields/SelectField";
import EmployeeTableView from "@/components/ui/views/EmployeeTableView";
import employeeAutocompleteService from "@/services/employees/employeeAutocompleteService";
import DatepickerField from "@/domain/fields/DatepickerField";
import moment from "moment";
import HraButton from "@/components/ui/button/HraButton";
import {vacationCalculations} from "@/domain/debugger/store/getters";
import {vacationStatus, vacationType} from "@/utils/default";
import HraTooltip from "@/components/ui/HraTooltip";
import HraIcon from "@/components/ui/HraIcon";
import UiButton from "@/domain/components/UiButton.vue";

export default {
	name: "DebuggerPage",
	components: {UiButton, HraIcon, HraTooltip, HraButton, DatepickerField, EmployeeTableView, SelectField, HraTable, Page},
	data() {
		const initTag = "DEBUGGER_PAGE_INIT";
		const today = moment().format("YYYY-MM-DD");

		return {
			employeeAutocompleteService: employeeAutocompleteService,
			initTag,
			vacationType,
			vacationStatus,
			filter: {
				employeeId: 1,
				fromDate: today,
				toDate: today,
			},
			contractExpandIds: [],
			headersContract: [
				{name: 'id', text: 'ID'},
				{name: 'timeCardNumber', text: 'Tabelio nr.'},
				{name: 'number', text: 'Sutarties nr.'},
				{name: 'date', text: 'Data'},
				{name: 'workStartAt', text: 'Darbo pradžios data'},
			],
			headersContractDetail: [
				{name: 'id', text: 'ID'},
				{name: 'fromDate', text: 'Data nuo'},
				{name: 'employeeContractId', text: 'Employee contract ID'},
				{name: 'vacationDaysPerYear', text: 'Atostogo dienios per metus'},
				{name: 'workDaysPerWeek', text: 'Darbo dienos per savaite'},
				{name: 'workHoursPerWeek', text: 'Darbo valandos per savaite'},
			],
			headersDisability: [
				{name: 'id', text: 'ID'},
				{name: 'number', text: 'Numeris'},
				{name: 'fromDate', text: 'Data nuo'},
				{name: 'toDate', text: 'Data iki'},
			],
			headersChild: [
				{name: 'id', text: 'ID'},
				{name: 'name', text: 'Vardas'},
				{name: 'brithDate', text: 'Gymimo data'},
				{name: 'disability', text: 'Neįgalumas'},
				{name: 'disabilityNo', text: 'Neįgalumo pažymos numeris'},
				{name: 'disabilityFrom', text: 'Neįgalumo data nuo'},
				{name: 'disabilityTo', text: 'Neįgalumo data iki'},
			],
			headersPlusVacation: [
				{name: 'id', text: 'ID'},
				{name: 'calcInYear', text: 'Kaupiasi visus metus'},
				{name: 'fromDate', text: 'Data nuo'},
				{name: 'toDate', text: 'Data iki'},
				{name: 'count', text: 'Kiekis (d. d.)'},
				{name: 'comment', text: 'Komentaras'},
			],
			headersVacation: [
				{name: 'id', text: 'ID'},
				{name: 'typeId', text: 'Tipas'},
				{name: 'status', text: 'Statusas'},
				{name: 'fromDate', text: 'Data nuo'},
				{name: 'toDate', text: 'Data iki'},
				{name: 'calendarDays', text: 'k. d.'},
				{name: 'workDays', text: 'd. d.'},
				{name: 'vacationDays', text: 'a. d.'},
			],
			headersCalculate: [
				{name: 'type', text: 'Tipas'},
				{name: 'date', text: 'Data'},
				{name: 'accumulated', text: 'Sukaupta'},
				{name: 'reserved', text: 'Rezervuoata'},
				{name: 'balance', text: 'Likutis'},
			],
			headersRatios: [
				{name: 'date', text: 'Date'},
				{name: 'ratio', text: 'Ratio'},
			]
		};
	},
	provide() {
		return {
			service: debuggerService,
		};
	},
	watch: {
		filter: {
			handler: function (value) {
				debuggerService.getData("DEBUGGER_PAGE_GET_DATA_TAG", value.employeeId, value.fromDate, value.toDate);
			},
			deep: true,
		},
	},
	computed: {
		...mapGetters({
			calculations: "debuggerStore/vacationCalculationRows",
			ratios: "debuggerStore/yearlyVacationCalendarRows",
			vacations: "debuggerStore/vacations",
			plusVacations: "debuggerStore/plusVacations",
			child: "debuggerStore/child",
			disabilities: "debuggerStore/disabilities",
			contracts: "debuggerStore/contracts",
			contractDetails: "debuggerStore/contractDetails",
		}),
	},
	methods: {
		onRefresh() {
			debuggerService.getData("DEBUGGER_PAGE_GET_DATA_TAG", this.filter.employeeId, this.filter.fromDate,
				this.filter.toDate);
		},
	},
}
</script>