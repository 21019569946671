export const user = ({ user }) => user
export const userId = ({ user }) => user.id
export const permissions = ({ user }) => user.permissions
export const fetchingUserPromise = ({ fetchingUserPromise }) => fetchingUserPromise
export const imported = ({ imported }) => imported
export const isAdmin = ({ user }) => user?.roles?.includes('admin') ?? false
export const isLeader = ({ isLeader }) => isLeader
export const isSubLeader = ({ isSubLeader }) => isSubLeader

export const settings = ({ settings }) => settings

export const configs = ({settings}) => {
    return Object.fromEntries(
        Object.entries(Object.values(settings).reduce((acc, n) => ({...acc, ...n}), {}))
            .map(([key, value]) => [key, value.config])
    )
}
