import CustomApi from '@/plugins/customApi'
import { mapDocument, mapFolder } from '@/domain/documents/finder/store/mappers'
import folderActions from '@/domain/documents/finder/store/actions/folderActions'
import documentActions from '@/domain/documents/finder/store/actions/documentActions'
import { baseGetters, baseState, baseActions } from '@/domain/documents/finder/store/finderBaseStore'

const namespaced = true

const state = () => ({
    ...baseState()
})

const getters = {
    ...baseGetters
}

const customApi = CustomApi()
const actions = {
    ...baseActions,
    getFetchParams({ state }, payload) {
        let params = {
            ...payload,
            limit: state.limit,
            folderId: state.folderId ?? '',
            withBreadcrumbs: true,
            withFolders: true,
            withDocuments: true
        }

        if (state.search != null && state.search !== '') {
            params.search = state.search
        }

        return params
    },
    async fetch({ dispatch, state }, payload = null) {
        try {
            const {
                data: { breadcrumbs, items, hasMore, pagination: { currentPage } }
            } = await customApi.factories.documents.folders
                .fetch(await dispatch('getFetchParams', payload ?? {}))
            const mappedItems = items.map(item => {
                if (item.isFolder) {
                    return mapFolder(item)
                }

                return mapDocument(item)
            })

            state.items = (payload == null ? mappedItems : state.items.concat(mappedItems))
            state.breadcrumbs = breadcrumbs ?? []
            state.hasMore = hasMore
            state.currentPage = currentPage
            return true
        } catch (error) {
            console.error(error)
            return false
        }
    },
    ...folderActions,
    ...documentActions
}

export default {
    namespaced,
    state,
    actions,
    getters
}