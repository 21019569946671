<template>
    <div class='setting-module-item-more'>
        <div class='setting-module-item-more__button' @click='handleToggleExpand'>
            <span class='setting-module-item-more__button-text'>{{ $t('Pagal skyrius') }} ({{ count }})</span>
            <ui-icon>{{ expanded ? 'arrow-up' : 'arrow-down' }}</ui-icon>
        </div>


        <v-slide-y-transition>
            <div v-if='expanded' class='setting-module-item-more__container'>
                <slot />

                <message-alert
                    v-if='count === 0'
                    id='0000'
                    :message='$t("setting.module.info.departmentSettingEmpty")'
                    type='info'
                />

                <ui-button
                    v-if='isEdit'
                    prepend-icon='plus'
                    full-width
                    @click='() => $emit("addDepartment")'
                >{{ $t('Pridėti') }}</ui-button>
            </div>
        </v-slide-y-transition>
    </div>
</template>

<script>
import UiIcon from '@/domain/components/UiIcon.vue'
import UiButton from '@/domain/components/UiButton.vue'
import MessageAlert from '@/domain/messages/components/MessageAlert.vue'

export default {
    name: 'SettingModuleItemMore',
    components: { MessageAlert, UiButton, UiIcon },
    props: {
        isEdit: { type: Boolean, default: false },
        count: { type: Number, default: 0 }
    },
    data() {
        return {
            expanded: false
        }
    },
    methods: {
        handleToggleExpand() {
            this.expanded = !this.expanded
        }
    }
}
</script>

<style lang='scss'>
.setting-module-item-more {
    margin: 0 20px;

    &__button {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row;
        padding: 10px 0;
        cursor: pointer;
        gap: 6px;

        &-text {
            font-size: 12px;
        }

        .ui-icon {
            color: #6E7191;
            font-size: 12px;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-bottom: 10px;
    }
}
</style>