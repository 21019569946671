<template>
    <div class='business-trips-modal-calendars'>
        <ui-calendar
            with-first-month-navigation
            :year-month='calendarYearMonth'
            :clickable-dates='calendarEditable'
            :max-month-count='maxMonthCount'
            :min-select-date='minDate'
            :select-start-date='formData.start'
            :select-end-date='formData.end'
            :events='calendarEvents'

            @update:yearMonth='handleChangeCalendarYearMonth'
            @update:selectStartDate='onChangeField($event, "start")'
            @update:selectEndDate='onChangeField($event, "end")'
        />
    </div>
</template>

<script>
import UiCalendar from '@/domain/components/UiCalendar/UiCalendar.vue'
import { GLOBAL_TOAST_TAG } from '@/utils/default'

export default {
    name: 'BusinessTripsCalendars',
    components: { UiCalendar },
    props: {
        maxMonthCount: {type: Number, default: 1}
    },
    inject: ['storeNamespace', 'asAdmin'],
    computed: {
        calendarYearMonth() {
            return this.$store.getters[`${this.storeNamespace}/calendarYearMonth`]
        },
        calendarEditable() {
            return this.$store.getters[`${this.storeNamespace}/calendarEditable`]
        },
        calendarEvents() {
            return this.$store.getters[`${this.storeNamespace}/calendarEvents`]
        },
        minDate() {
            if (this.asAdmin) {
                return null
            }

            return this.$store.getters[`${this.storeNamespace}/minDate`]
        },
        formData() {
            return this.$store.getters[`${this.storeNamespace}/formData`]
        }
    },
    methods: {
        handleChangeCalendarYearMonth(value) {
            this.$store.dispatch(`${this.storeNamespace}/setCalendarDate`, value)
        },
        async handleChangeFormStartDate(value) {
            await this.$store.dispatch(`${this.storeNamespace}/setFormStartDate`, value)
        },
        async handleChangeFormEndDate(value) {
            await this.$store.dispatch(`${this.storeNamespace}/setFormEndDate`, value)

        },
        handleClickDay(day) {
            if (day.type !== 'event-base') {
                this.$store.dispatch('messagesStore/showErrorMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Negalima pasirinkti {date}', { date: day.date })
                })
                return
            }

            this.$store.dispatch(`${this.storeNamespace}/toggleSelectDate`, day.date)
        },
        onChangeField(value, field) {
            this.$store.dispatch(`${this.storeNamespace}/updateCurrentField`, {field, value})
        }
    }
}
</script>

<style lang='scss'>
.business-trips-modal-calendars {
    width: 380px;
}
</style>