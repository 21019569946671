<template>
    <div class='schedule-container'>
        <div class='schedule-container__content'>
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ScheduleContainer'
}
</script>

<style lang='scss'>
@import '@/assets/sass/core/colors';

.schedule-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__content {
        width: 100%;
        display: flex;
        align-items: stretch;
        background: #F3F4FF;
    }
}

.schedule-table {
    min-width: 100%;
    table-layout: fixed;

    td {
        height: 30px;
        border: 1px solid $schedule-border-primary;
        white-space: nowrap;
        background: $white;
        background-clip: padding-box // For firefox - fix table borders
    }
}
</style>