<template>
    <div>
        <div class='ps-6 pt-6 pb-8 documents-overview-status' v-if='status'>
            <div class='d-flex status-first-group'>
                <div class='group-head-block group-head-block-first'></div>
                <overview-status-item :title="$t('Išsiųsti dokumentai')">
                    <router-link :to='{name: "DocumentSummaryMainPage", query: {sortBy: ["sentAt,desc"]}}'>
                        <b>{{ status.sentUsers.total }}</b>
                    </router-link>
                </overview-status-item>
                <overview-status-item :title="$t('Patvirtinti')">
                    <router-link
                        :to='{name: "DocumentSummaryMainPage", query: {status: ["accepted"], sortBy: ["sentAt,desc"]}}'>
                        <b>{{ status.sentUsers.accepted }}</b>
                    </router-link>
                </overview-status-item>
                <overview-status-item :title="$t('Laukiama')">
                    <router-link
                        :to='{name: "DocumentSummaryMainPage", query: {status: ["pending"], sortBy: ["sentAt,desc"]}}'>
                        <b>{{ status.sentUsers.pending }}</b>
                    </router-link>
                </overview-status-item>
                <overview-status-item :title="$t('Atmesti/atšaukti')">
                    <router-link
                        :to='{name: "DocumentSummaryMainPage", query: {status: ["rejected", "cancelled"], sortBy: ["sentAt,desc"]}}'>
                        <b>{{ status.sentUsers.rejected + status.sentUsers.aborted }}</b>
                    </router-link>
                </overview-status-item>
            </div>
            <div class='d-flex'>
                <div class='group-head-block'></div>
                <overview-status-item :title="$t('Įkelta dokumentų')">
                    <router-link :to='{name: "DocumentSummaryDocumentsPage", query: {sortBy: ["document,asc"]}}'>
                        <b>{{ status.documents.total }}</b>
                    </router-link>
                </overview-status-item>
                <overview-status-item :title="$t('100% patvirtinti')">
                    <router-link :to='{name: "DocumentSummaryDocumentsPage", query: {status: ["full_approved"], sortBy: ["document,asc"]}}'>
                        <b>{{ status.documents.completed }}</b>
                    </router-link>
                </overview-status-item>
                <overview-status-item :title="$t('Išsiųsti')">
                    <router-link :to='{name: "DocumentSummaryDocumentsPage", query: {status: ["sent"], sortBy: ["document,asc"]}}'>
                        <b>{{ status.documents.sent }}</b>
                    </router-link>
                </overview-status-item>
                <overview-status-item :title="$t('Nepradėti')">
                    <router-link :to='{name: "DocumentSummaryDocumentsPage", query: {status: ["new"], sortBy: ["document,asc"]}}'>
                        <b>{{ status.documents.total - status.documents.sent }}</b>
                    </router-link>
                </overview-status-item>
            </div>
        </div>
        <div class='ps-8 d-flex documents-overview-diagram'>
            <div>
                <div class='mt-12'>
                    <router-link :to="{ name: 'DocumentsFolders' }">
                        <img src='/assets/images/documents/overview-button-1.png'>
                    </router-link>
                </div>
                <div class='large-margin-top-alt d-none'><img src='/assets/images/documents/overview-button-3.png'>
                </div>
            </div>
            <div class='mt-12'><img src='/assets/images/documents/overview.png' alt=''></div>
            <div>
                <div>
                    <router-link :to="{ name: 'DocumentSummaryMainPage'}">
                        <img src='/assets/images/documents/overview-button-2.png' alt='' />
                    </router-link>
                </div>
                <router-link :to="{ name: 'DocumentsExpirationTrackingPage'}">
                    <div class='large-margin-top'><img src='/assets/images/documents/overview-button-4.png'>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UiIcon from '@/domain/components/UiIcon.vue'
import OverviewStatusItem from '@/domain/documents/components/OverviewStatusItem.vue'
import DocumentSectionTitle from '@/domain/documents/components/DocumentSectionTitle.vue'
import customApi from '@/plugins/customApi'

export default {
    name: 'DocumentsOverview',
    components: { UiIcon, OverviewStatusItem, DocumentSectionTitle },
    data() {
        return {
            status: null
        }
    },
    computed: {},
    methods: {
        async load() {
            const api = customApi()

            const { data } = await api.factories.documents.overview.fetch()
            this.status = data
        },
        handleClick() {
        }
    },
    mounted() {
        this.load()
    }
}
</script>

<style lang='scss'>
@import '@/assets/sass/core/vars';

.documents-overview-status {
    .documents-overview-status-item {
        border-left: 4px solid #FD9A01;
        width: 250px;

        .status-item-wrapper {
            padding: 10px;
        }
    }

    .group-head-block {
        width: 10px;
    }

    .status-item-title {
        font-size: 16px;
    }

    .status-item-title {
        color: $greyscale-600;
    }

    .status-item-value, .status-item-value a b {
        font-size: 21px;
        color: #293080;
    }

    .status-first-group .status-item-wrapper, .group-head-block.group-head-block-first {
        border-bottom: 1px solid #DAD5E9;
    }
}

.documents-overview-diagram {
    .large-margin-top {
        margin-top: 150px;
    }

    .large-margin-top-alt {
        margin-top: 125px;
    }
}

.documents-main-layout-container h3.document-section-title {
    font-size: 21px;
}

.documents-main-layout-container .unread-documents-alert__message a {
    font-family: "Public Sans", sans-serif;
    color: #32398B;
}
</style>