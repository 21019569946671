<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  computed: {
    classes() {
      return ['button-group', this.$attrs.class];
    }
  },
}
</script>