<template>
    <fragment>
        <div class='leader-event-invite-line-expend-detail__title'>
            <slot name='title' />
        </div>
        <div class='leader-event-invite-line-expend-detail__value'>
            <slot name='value' />
        </div>
    </fragment>
</template>

<script>
export default {
    name: 'LeaderEventInviteLineExpendDetail'
}
</script>

<style lang='scss'>
@import '@/assets/sass/mixins/_responsive';

.leader-event-invite-line-expend-detail {
    &__title {
        color: #70778C;
    }

    &__value {
        text-align: start;

        @include sm-down {
            text-align: end;
        }
    }
}
</style>