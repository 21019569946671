
import {localStorageGet, localStorageRemove, localStorageSave} from "@/utils/localStorage";
import store from "@/store";
import api from "@/plugins/api";
import {appRouteProps} from "@/router/app";

const LOCAL_STORAGE_NAME = "import-data-";

class EmployeeImportService {

  async init() {
    try {
      let data = this.#getLocalData();

      if (!data) {
        const payload = await api.employee.import.init();
        data = payload.data;
        data.rows = data.rows.map(row => ({
          ...row,
          id: this.#uniqueId(),
        }));

        localStorageSave(this.#getLocalName(), data);
      }

      let superAdminEmail = null;
      const setup = store.getters["user/setup"];

      if (setup?.active) {
        superAdminEmail = store.getters["user/email"];
      }

      await store.dispatch("employee/importData/init", {...data, superAdminEmail});
    } catch (e) {
      console.error(e);
    }
  }

  async createNewRow() {
    const {data} = await api.employee.import.createEmptyRow();
    data.id = this.#uniqueId()
    await store.dispatch("employee/importData/addNewRow", data);
    await store.dispatch("employee/importData/startEditRow", data.id);
    this.#storeLocal();
  }

  async convert(file, override = false) {
    if (file == null) {
      return;
    }

    let formData = new FormData();
    formData.append("excel", file);

    const {data} = await api.employee.import.convert(formData);
    const localData = this.#getLocalData();

    let departments = localData.departments || [];
    let duties = localData.duties || [];
    let localRows = localData.rows || [];

    departments = [
      ...departments,
      ...(data.departments.filter(department => !departments.find(({text}) => text === department.text))),
    ];
    duties = [
      ...duties,
      ...(data.duties.filter(duty => !duties.find(({text}) => text === duty.text))),
    ];

    let importRows = data.rows;

    const importRowsMap = override
      ? (row) => {
        const index = localRows.findIndex(({email}) => row.email.value === email.value);

        if (index > -1) {
          row.id = localRows[index].id;
          localRows[index] = {
            ...localRows[index],
            ...row,
          };
        }

        return row;
      }
      : (row) => {
        const localRow = localRows.find(({email}) => row.email.value === email.value);

        if (localRow) {
          row.id = localRow.id;
        }

        return row;
      };

    importRows = importRows.map(importRowsMap).filter(row => !row.id);

    let rowsNotValid = [];
    let rowsValid = [];
    [
      ...localRows,
      ...(importRows.map(row => ({...row, id: this.#uniqueId()}))
      )
    ].forEach(row => {
      if (Object.values(row).filter(row => typeof row === "object" && !row.valid).length > 0) {
        rowsNotValid.push(row);
        return;
      }

      rowsValid.push(row);
    });

    function sortByName(a, b) {
      if (a.name.value == null) {
        return -1;
      }

      if (a.name.value > b.name.value) {
        return 1;
      }

      if (a.name.value < b.name.value) {
        return -1;
      }

      return 0;
    }

    let rows = [
      ...(rowsNotValid.sort(sortByName)),
      ...(rowsValid.sort(sortByName)),
    ];

    localStorageSave(this.#getLocalName(), {
      headers: data.headers,
      departments,
      duties,
      rows,
    });

    await this.init();
  }

  async startEditRow(id) {
    await store.dispatch("employee/importData/startEditRow", id);
  }

  async clear() {
    await store.dispatch("employee/importData/clear");
    this.#clearLocal();
  }

  async cancelEditRow(id) {
    const row = this.#getLocalData()?.rows.find(row => row.id === id) || null;

    if (row) {
      await store.dispatch("employee/importData/updateRow", row);
      return;
    }

    await store.dispatch("employee/importData/cancelEditRow", id);
  }

  async updateRow(row) {
    await store.dispatch("employee/importData/updateRow", row);
    this.#storeLocal();
  }

  async removeRow(id) {
    await store.dispatch("employee/importData/removeRow", id);
    this.#storeLocal();
  }

  async createDepartment(rowId, name) {
    // const row = store.getters["employee/importData/rowById"](rowId);
    await store.dispatch("employee/importData/createDepartment", name);

    // if (row) {
    //   if (!row.departments.value) {
    //     row.departments.value = [];
    //   }
    //
    //   row.departments.value.push(name);
    //   await store.dispatch("employee/importData/changeRow", row);
    // }

    this.#storeLocalDepartments();
  }

  async createDuty(rowId, name) {
    // const row = store.getters["employee/importData/rowById"](rowId);
    await store.dispatch("employee/importData/createDuty", name);

    // if (row) {
    //   if (!row.duties.value) {
    //     row.duties.value = [];
    //   }
    //
    //   row.duties.value.push(name);
    //   await store.dispatch("employee/importData/changeRow", row);
    // }

    this.#storeLocalDuties();
  }

  async store() {
    try {
      const payload = store.getters["employee/importData/storeData"];
      const {success} = await api.employee.import.import(payload);

      if (!success) {
        throw "error";
      }

      await this.clear();

      return true;
    } catch (e) {
      return false;
    }
  }

  async store2() {
    const payload = store.getters["employee/importData/storeData"];
    const time = (new Date()).getTime();
    const _payload = payload.rows.map((item, key) => {
      let child = [];

      for (let key = 1; key < 7; key++) {
        if (item[`child_${key}_brith_date`]) {
          child.push({
            brithDate: item[`child_${key}_brith_date`],
            disability: item[`child_${key}_disability`] === 1,
            disabilityFrom: item[`child_${key}_disability_from_date`],
            disabilityNo: key + time,
          });
        }
      }

      return {
        user: {
          email: `${key}a${item.email}`,
          roles: [1],
          permissions: []
        },
        profile: {
          name: item.name,
          brithDate: item.date_of_birth,
        },
        contact: {
          phone: item.phone,
          address: item.address,
          country: item.country,
        },
        departments: [1],
        duties: [1],
        contract: {
          timeCardNumber: `${item.contract_time_card_number}${time}`,
          number: `${item.contract_number}${time}`,
          date: item.contract_date,
          workStartAt: item.work_start_at,
        },
        contractDetail: {
          vacationDaysPerYear: item.vacation_days_per_year,
          workDaysPerWeek: item.work_days_per_week,
          workHoursPerWeek: item.work_hours_per_week,
        },
        disabilities: item.disability === 0 ? [] : [
          {
            fromDate: item.disability_from_date,
            toDate: item.disability_to_date,
            number: 'import',
          }
        ],
        child
      };
    });

    console.debug(_payload);

    try {
      const {success} = await api.employee.import.import2({employees: _payload});

      if (!success) {
        throw "error";
      }

      await this.clear();

      return true;
    } catch (e) {
      return false;
    }
  }

  #getLocalName() {
    return LOCAL_STORAGE_NAME + store.getters["user/id"];
  }

  #getLocalData() {
    const data = localStorageGet(this.#getLocalName());

    if (!data || typeof data !== "object") {
      return null;
    }

    return data;
  }

  #storeLocal() {
    const data = store.getters["employee/importData/localStoreData"];
    localStorageSave(this.#getLocalName(), data);
  }

  #storeLocalDepartments() {
    const {departments} = store.getters["employee/importData/localStoreData"];
    const localData = this.#getLocalData();
    localData.departments = departments;
    localStorageSave(this.#getLocalName(), localData);
  }

  #storeLocalDuties() {
    const {duties} = store.getters["employee/importData/localStoreData"];
    const localData = this.#getLocalData();
    localData.duties = duties;
    localStorageSave(this.#getLocalName(), localData);
  }

  #clearLocal() {
    localStorageRemove(this.#getLocalName());
  }

  #uniqueId() {
    let dt = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }
}

export default new EmployeeImportService();