<template>
    <div>
        <Header>
            <template #left>
                <hra-button-modal :modal-name='modalName' :modal-props='{handleSelectFile}'>
                    {{ $t('Importuoti dar karta') }}
                </hra-button-modal>
            </template>
            <template #right>
            </template>
        </Header>

        <v-main>
            <v-container fluid>
                <v-row class='px-4'>
                    <v-col cols='12' v-if='hasError(importTag)'>
                        <message-alerts class='mt-1' :listen='[importTag]' />
                    </v-col>
                    <v-col
                        v-for='message in messages'
                        cols='12'
                        :key='message.subTag'
                        v-if='hasError(`${importTag}-${message.subTag}`)'
                    >
                        <h1>{{ message.title }}</h1>
                        <message-alerts class='mt-1' :listen='[`${importTag}-${message.subTag}`]' />
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </div>
</template>

<script>
import Header from '@/components/ui/header/Header.vue'
import CustomTabs from '@/domain/components/CustomTabs.vue'
import EmployeesTable from '@/domain/setup/components/tables/EmployeesTable.vue'
import ContractsTable from '@/domain/setup/components/tables/ContractsTable.vue'
import DisabilitiesTable from '@/domain/setup/components/tables/DisabilitiesTable.vue'
import ChildTable from '@/domain/setup/components/tables/ChildTable.vue'
import VacationsTable from '@/domain/setup/components/tables/VacationsTable.vue'
import HraButtonModal from '@/components/ui/button/HraButtonModal.vue'
import importService from '@/domain/setup/services/importService'
import MessageAlert from '@/domain/messages/components/MessageAlert.vue'
import MessageAlerts from '@/domain/messages/MessageAlerts.vue'

export default {
    name: 'SetupImportPage',
    components: {
        MessageAlerts,
        MessageAlert,
        HraButtonModal,
        VacationsTable, ChildTable, DisabilitiesTable, ContractsTable, EmployeesTable, CustomTabs, Header
    },
    data() {
        return {
            importTag: 'employeesImportTag',
            modalName: 'ImportEmployeesModal',
            messages: [
                { subTag: 'employees', title: 'Darbuotojai' },
                { subTag: 'children', title: 'Darbuotojų vaikai' },
                { subTag: 'disabilities', title: 'Darbuotojų neįgalumai' },
                { subTag: 'holidays', title: 'Jau patvirtintos atostogos' }
            ],
            defaultTabName: 'employees',
            service: importService
        }
    },
    mounted() {
        this.$store.dispatch('modals/open', {
            name: this.modalName,
            props: {
                handleSelectFile: this.handleSelectFile
            }
        })
    },
    methods: {
        async handleSelectFile(file) {
            const tags = this.messages.map(({ subTag }) => `${this.importTag}-${subTag}`)
            await this.$store.dispatch('messagesStore/clearMessages', tags)
            await this.service.convert(this.importTag, file)
        },
        hasError(tag) {
            return this.$store.getters['messagesStore/stackListen'](tag).length > 0
        }
    }
}
</script>