<template>
    <div class='approval-level__employee'>
        <employee-table-view
            :employee='user'
            class='approval-level__employee-info'
        />
        <form-checkbox
            v-if='showCommentCheckbox'
            class='approval-level__employee-comment'
            v-model='needCommentCheckbox'
            name='approvalLeaderComment'
        >
            <span>{{ $t('Reikalingas komentaras') }}</span>
        </form-checkbox>
        <div class='approval-level__employee-action'>
            <hra-tooltip :text="$t('Pašalinti')">
                <ui-button
                    class='pa-0'
                    size='normal'
                    background='none'
                    only-text
                    prepend-icon='minus-circle'
                    style='min-height: auto;'
                    @click='handleRemoveUserFromLevel'
                />
            </hra-tooltip>
        </div>
    </div>
</template>

<script>
import EmployeeTableView from '@/components/ui/views/EmployeeTableView'
import UiButton from '@/domain/components/UiButton.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import HraTooltip from '@/components/ui/HraTooltip'

export default {
    name: 'ApprovalLevelEmployee',
    components: {
        EmployeeTableView, UiButton, FormCheckbox, HraTooltip
    },
    props: {
        user: {type: Object, required: true},
        needComment: {type: Boolean, default: false}
    },
    data() {
        return {
            needCommentCheckbox: false
        }
    },
    computed: {
        showCommentCheckbox() {
            return this.needComment
        }
    },
    methods: {
        handleRemoveUserFromLevel() {
            this.$emit('remove-user', this.user)
        }
    }
}
</script>

<style lang='scss'>
.approval-level__employee {
    padding: 10px 15px;
    border-bottom: 1px solid #DAD5E9;
    display: flex;
    align-items: center;
    gap: 12px;
    
    &:nth-child(2n+1) {
        background-color: #FAF9FF;
    }

    &-info {
        flex: 1 0 50%;
    }

    &-comment {
        flex: 1 0 20%;
    }

    .form-checkbox {
        &__container {
            gap: 4px;
        }
    }
}
</style>