<template>
	<h1 :class="classes">
		<slot>{{ title }}</slot>
		<span class="pl-2" v-if="total !== ''">{{ total }}</span></h1>
</template>

<script>
export default {
	props: {
		title: {type: String, default: ""},
		total: {type: [String, Number], default: ""},
		withBorder: {type: Boolean, default: false},
	},
	computed: {
		classes() {
			return {
				"table__title": true,
				"table__title_border": this.withBorder,
			};
		},
	},
}
</script>
