// initial state
import {tableActions, tableDefaultState, tableGetters, tableMutations} from "@/store/modules/init/table";

const defaultState = () => ({
    ...tableDefaultState(),
    employees: [],
})

const state = () => ({
    ...defaultState(),
});

// getters
const getters = {
    ...tableGetters,
    options(state) {
        return state.rows.map(row => ({
            value: row.id,
            text: row.title.name,
        }));
    },
    employees(state) {
        return (departmentId) => {
            return state.employees.find(item => item.id === departmentId)?.rows || [];
        };
    },
};

// actions
const actions = {
    ...tableActions,
};

//  mutations
const mutations = {
    ...tableMutations,
    changeEmployeesCount(state, payload) {
        state.rows = state.rows.map(row => ({
            ...row,
            employeeCount: row.id === payload.id
                ? row.employeeCount + payload.count
                : row.employeeCount
        }));
    },
    setEmployees(state, payload) {
        state.employees.push(payload);
    },
    addEmployee(state, payload) {
        state.employees = state.employees.map(item => ({
            ...item,
            rows: item.id === payload.id ? [payload.row, ...item.rows] : item.rows
        }));
    },
    removeEmployee(state, payload) {
        state.employees = state.employees.map(item => ({
            ...item,
            rows: item.id === payload.id ? item.rows.filter(employee => employee.id !== payload.employeeId) : item.rows
        }));
    },
    clearEmployees(state, id) {
        state.employees = state.employees.filter(item => item.id !== id);
    },
    clear(state) {
        Object.assign(state, defaultState());
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
