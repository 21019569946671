<template>
    <div class='leader-event-invite-line'>
        <div class='leader-event-invite-line-row'>
            <div class='leader-event-invite-line-row__user'>
                <employee-table-view :employee='user' />
            </div>
            <div class='leader-event-invite-line-row__department'>{{ user.department.name }}</div>
            <div class='leader-event-invite-line-row__interval'>
                <span class='leader-event-invite-line-row__interval-date'>{{ start }} {{ $t('iki') }} {{ end }}</span>
                <span class='leader-event-invite-line-row__interval-text'>{{ typeText }}</span>
            </div>
            <div class='leader-event-invite-line-row__days'>{{ days }}</div>
            <div class='leader-event-invite-line-row__deputies' v-html='deputies'/>
            <div v-if='isBondMobile' class='leader-event-invite-line-row__actions'>
                <ui-button
                    size='medium'
                    background='default'
                    :only-hover-background='!expanded'
                    @click='handleExpand'
                >
                    <v-fab-transition>
                        <div :key='`${expanded ? 1 : 0}-expand`'>
                            <ui-icon>{{ expanded ? 'up' : 'down' }}</ui-icon>
                        </div>
                    </v-fab-transition>
                </ui-button>
            </div>
            <div v-else class='leader-event-invite-line-row__actions'>
                <v-fab-transition>
                    <div v-if='!expanded' class='leader-event-invite-line-row__actions-desktop'>
                        <hra-tooltip :text="$t('Atmesti')">
                            <ui-button
                                size='medium'
                                background='danger-light'
                                only-hover-background
                                prepend-icon='close'
                                :confirm='abortConfirm'
                            />
                        </hra-tooltip>
                    </div>
                </v-fab-transition>
                <v-fab-transition>
                    <div v-if='!expanded' class='leader-event-invite-line-row__actions-desktop'>
                        <hra-tooltip :text="$t('Tvirtinti')">
                            <ui-button
                                size='medium'
                                background='success-light'
                                only-hover-background
                                prepend-icon='ok'
                                :confirm='approveConfirm'
                            />
                        </hra-tooltip>
                    </div>
                </v-fab-transition>

                <hra-tooltip :text="$t(expanded ? 'Suskleisti' : 'Išskleisti')">
                    <ui-button
                        size='medium'
                        background='default'
                        :only-hover-background='!expanded'
                        @click='handleExpand'
                    >
                        <v-fab-transition>
                            <div :key='`${expanded ? 1 : 0}-expand`'>
                                <ui-icon>{{ expanded ? 'up' : 'down' }}</ui-icon>
                            </div>
                        </v-fab-transition>
                    </ui-button>
                </hra-tooltip>
            </div>
        </div>
        <v-expand-transition v-if='!isBondMobile'>
            <div v-if='expanded' class='leader-event-invite-line-expanded'>
                <div class='leader-event-invite-line-expanded__left'>
                    <slot name='expand' />
                </div>

                <div class='leader-event-invite-line-expanded__right'>
                    <textarea-field
                        v-if='!!employeeMessage'
                        name='message'
                        class='DepartmentVacationWaitingTableExpansion__form-message employee'
                        :label="$t('Darbuotojo žinutė')"
                        read-only
                        :value='employeeMessage'
                    />
                    <textarea-field
                        name='message'
                        class='DepartmentVacationWaitingTableExpansion__form-message toEmployee'
                        :label="$t('Atsakyti')"
                        :placeholder="$t('Parašykite žinutę darbuotojui')"
                        v-model='message'
                    />
                    <div class='d-flex gap-2 justify-end'>
                        <ui-button
                            size='medium'
                            background='danger-light'
                            prepend-icon='close'
                            :confirm='abortConfirm'
                        >{{ $t('Atmesti') }}
                        </ui-button>
                        <ui-button
                            size='medium'
                            background='success'
                            prepend-icon='ok'
                            :confirm='approveConfirm'
                        >{{ $t('Tvirtinti') }}
                        </ui-button>
                    </div>
                </div>
            </div>
        </v-expand-transition>

        <v-expand-transition v-if='isBondMobile'>
            <div v-if='expanded' class='leader-event-invite-line-expanded-mobile'>
                <slot name='expand' />

                <textarea-field
                    v-if='!!employeeMessage'
                    style='grid-column: span 2;'
                    name='message'
                    class='DepartmentVacationWaitingTableExpansion__form-message employee'
                    :label="$t('Darbuotojo žinutė')"
                    read-only
                    :value='employeeMessage'
                />
            </div>
        </v-expand-transition>

        <div class='leader-event-invite-line-mobile-view' v-if='isBondMobile'>
            <textarea-field
                name='message'
                class='DepartmentVacationWaitingTableExpansion__form-message toEmployee'
                :label="$t('Atsakyti')"
                :placeholder="$t('Parašykite žinutę darbuotojui')"
                v-model='message'
            />
            <div class='d-flex gap-2 mt-5 justify-end'>
                <ui-button
                    full-width
                    background='danger-light'
                    prepend-icon='close'
                    :confirm='abortConfirm'
                >{{ $t('Atmesti') }}
                </ui-button>
                <ui-button
                    full-width
                    background='success'
                    prepend-icon='ok'
                    :confirm='approveConfirm'
                >{{ $t('Tvirtinti') }}
                </ui-button>
            </div>
        </div>
    </div>
</template>

<script>
import EmployeeTableView from '@/components/ui/views/EmployeeTableView.vue'
import UiButton from '@/domain/components/UiButton.vue'
import UiIcon from '@/domain/components/UiIcon.vue'
import HraTooltip from '@/components/ui/HraTooltip.vue'
import TextareaField from '@/domain/fields/TextareaField.vue'
import isBondMobileMixin from '@/mixins/isBondMobileMixin'

export default {
    name: 'LeaderEventInviteLine',
    components: { TextareaField, HraTooltip, UiIcon, UiButton, EmployeeTableView },
    mixins: [isBondMobileMixin],
    props: {
        user: { type: Object, required: true },
        start: { type: String, required: true },
        end: { type: String, required: true },
        typeText: { type: String, required: true },
        days: { type: String, required: true },
        deputies: { type: String, default: null },
        employeeMessage: { type: String, default: null },
        expanded: { type: Boolean, default: false },
        confirmAbortText: { type: String, required: true },
        confirmApproveText: { type: String, required: true }
    },
    data() {
        return {
            message: null,
            abortConfirm: {
                props: {
                    title: this.confirmAbortText,
                    confirmText: this.$t('Atmesti')
                },
                on: {
                    confirmed: (confirm) => {
                        this.$emit('abort', confirm, this.message)
                    }
                }
            },
            approveConfirm: {
                props: {
                    title: this.confirmApproveText,
                    confirmText: this.$t('Patvirtinti')
                },
                on: {
                    confirmed: (confirm) => {
                        this.$emit('approve', confirm, this.message)
                    }
                }
            }
        }
    },
    methods: {
        handleExpand() {
            if (this.expanded && !this.isBondMobile) {
                this.message = null
            }

            this.$emit('expand')
        }
    }
}
</script>

<style lang='scss'>
@import '@/assets/sass/mixins/_responsive';

.leader-event-invite-line {
    padding: 12px;
    background: #FFFFFF;
    border-bottom: 1px solid #E6E9F0;

    &:nth-child(even) {
        background: #FAF9FF;
    }

    @include sm-down {
        padding: 20px;
    }
}

.leader-event-invite-line-row {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 160px 230px 60px 1fr 200px;
    grid-template-areas: "user department interval days deputies action";
    align-items: center;

    @include sm-down {
        grid-template-columns: 1fr minmax(100px, 20%);
        grid-template-areas:
            "user action"
            "interval days"
            "deputies deputies";
    }

    &__user {
        grid-area: user;
    }

    &__department {
        grid-area: department;
        width: 160px;

        @include sm-down {
            width: auto;
            display: none;
        }
    }

    &__interval {
        grid-area: interval;
        width: 230px;
        display: flex;
        flex-direction: column;

        @include sm-down {
            width: auto;
        }

        &-date {
            font-style: normal;
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            color: #37409E;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }

        &-text {
            font-style: normal;
            font-size: 12px;
            line-height: 14px;
            color: #70778C;
        }
    }

    &__days {
        grid-area: days;

        @include sm-down {
            justify-self: end;
        }
    }

    &__actions {
        grid-area: action;
        width: 200px;
        display: flex;
        justify-content: flex-end;

        @include sm-down {
            width: auto;
        }
    }
}

.leader-event-invite-line-expanded {
    display: grid;
    grid-template-columns: 54% 45%;
    gap: 20px;
    padding: 20px 10px;

    &__left {
        gap: 23px;
        display: grid;
        grid-template-columns: 1fr 35%;
        grid-auto-rows: min-content;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #1C2538;

        @include sm-down {
            grid-template-columns: 1fr 26%;
        }
    }

    &__right {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}

.leader-event-invite-line-expanded-mobile {
    border-top: 1px solid #DAD5E9;
    padding-top: 10px;
    display: grid;
    grid-template-columns: 1fr auto;
    flex-direction: column;
    gap: 10px;
}
</style>