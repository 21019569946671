<template>
    <custom-dialog :max-width='1200'>
        <template #title>{{ $t('Dokumento peržiūra') }}</template>
        <template #content>
            <v-row class='pa-3'>
                <v-col cols='12'>
                    <iframe v-if="fileType && fileType === 'pdf'" :src='fileUrl' width='100%' height='700px'></iframe>
                    <docx-preview v-else-if="fileType && fileType === 'docx'" :fileUrl='fileUrl' />
                    <excel-preview v-else-if="fileType && fileType === 'xls' || fileType && fileType === 'xlsx'"
                                   :fileUrl='fileUrl' />
                </v-col>
            </v-row>
        </template>

        <template #footer>
            <ui-button prepend-icon='close' background='default' @click='close'>{{ $t('Uždaryti') }}</ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import dialogMixin from '@/mixins/dialogMixin'
import UiButton from '@/domain/components/UiButton.vue'
import DocxPreview from '@/domain/documents/components/DocxPreview.vue'
import ExcelPreview from '@/domain/documents/components/ExcelPreview.vue'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'

export default {
    name: 'DocumentPreviewModal',
    components: { UiButton, ExcelPreview, DocxPreview, CustomDialog },
    mixins: [dialogMixin],
    props: {
        item: Object
    },
    computed: {
        fileType() {
            return this.item.filename.split('.').pop().toLowerCase()
        },
        fileUrl() {
            return window.location.protocol + '//' + window.location.hostname + `/api/documents/${this.item.id}/preview`
        }
    },
    data() {
        return {
            excelHtml: null,
            loading: false
        }
    }
}
</script>