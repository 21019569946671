<template>
    <div class='d-flex gap-2'>
        <hra-tooltip
            v-if='canDeputyAbort'
            :text="$t('Atmesti')"
        >
            <ui-button
                background='danger-light'
                :only-hover-background='!isBondMobile'
                size='small'
                prepend-icon='close'
                :confirm='confirmDeputyAbort'
            />
        </hra-tooltip>

        <hra-tooltip
            v-if='canDeputyApprove'
            :text="$t('Patvirtinti')"
        >
            <ui-button
                background='success-light'
                :only-hover-background='!isBondMobile'
                size='small'
                prepend-icon='ok'
                :confirm='confirmDeputyApprove'
            />
        </hra-tooltip>

        <hra-tooltip
            v-if='canHolidayAbort'
            :text="$t('Atšaukti')"
        >
            <ui-button
                background='danger-light'
                :only-hover-background='!isBondMobile'
                size='small'
                prepend-icon='close'
                :confirm='confirmHolidayAbort'
            />
        </hra-tooltip>

        <hra-tooltip v-if='canHolidayChange' :text="$t('Keisti')">
            <ui-button
                background='default'
                :only-hover-background='!isBondMobile'
                size='small'
                :modal="{name: 'VacationModal', props: {userId: userId, eventId: approvalable.id}}"
                prepend-icon='edit'
            />
        </hra-tooltip>

        <hra-tooltip v-if='canHolidayInfo' :text="$t('Plačiau')">
            <ui-button
                background='default'
                :only-hover-background='!isBondMobile'
                size='small'
                :modal="{name: 'VacationModal', props: {userId: userId, eventId: approvalable.id}}"
                prepend-icon='info'
            />
        </hra-tooltip>

        <hra-tooltip
            v-if='canRemoteWorkAbort'
            :text="$t('Atšaukti')"
        >
            <ui-button
                background='danger-light'
                :only-hover-background='!isBondMobile'
                size='small'
                prepend-icon='close'
                :confirm='confirmRemoteWorkAbort'
            />
        </hra-tooltip>

        <hra-tooltip v-if='canRemoteWorkChange' :text="$t('Keisti')">
            <ui-button
                background='default'
                :only-hover-background='!isBondMobile'
                size='small'
                :modal="{name: 'RemoteWorkModal', props: {userId: userId, id: approvalable.id}}"
                prepend-icon='edit'
            />
        </hra-tooltip>
    </div>
</template>

<script>
import HraConfirm from '@/components/ui/dialog/HraConfirm'
import HraTooltip from '@/components/ui/HraTooltip'
import HraButton from '@/components/ui/button/HraButton'
import HraIcon from '@/components/ui/HraIcon'
import HraButtonModal from '@/components/ui/button/HraButtonModal'
import employeeVacationService from '@/domain/employeeVacations/services/employeeVacationService'
import BaseConfirm from '@/domain/confirms/BaseConfirm'
import {
    VACATIONS_DEPUTY_ABORT_TAG,
    VACATIONS_DEPUTY_APPROVE_TAG,
    VACATIONS_EVENT_ABORT_TAG
} from '@/domain/employeeVacations/tags'
import UiButton from '@/domain/components/UiButton.vue'
import customApi from '@/plugins/customApi'
import isBondMobileMixin from '@/mixins/isBondMobileMixin'
import { useModalToOpen } from '@/composables/useModalToOpen'
import { EVENT_HOLIDAY_TYPE_DEPUTY } from '@/utils/default'
import moment from 'moment'
import { mapActions } from 'vuex'

export default {
    name: 'TableActions',
    mixins: [isBondMobileMixin],
    components: { UiButton, BaseConfirm, HraButtonModal, HraIcon, HraButton, HraTooltip, HraConfirm },
    props: {
        type: { type: String, required: true },
        status: { type: String, required: true },
        userId: { type: Number, required: true },
        approvalable: { type: Object, required: true }
    },
    data() {
        return {
            todayDate: moment().format('YYYY-MM-DD'),
            VACATIONS_DEPUTY_ABORT_TAG,
            VACATIONS_DEPUTY_APPROVE_TAG,
            VACATIONS_EVENT_ABORT_TAG
        }
    },
    computed: {
        approvalableNotStar() {
            return this.approvalable.start >= this.todayDate
        },
        isDeputy() {
            return this.type === 'event' && this.approvalable.type === EVENT_HOLIDAY_TYPE_DEPUTY
        },
        canDeputyAbort() {
            return this.isDeputy && this.approvalableNotStar && this.status !== 'aborted'
        },
        confirmDeputyAbort() {
            return {
                props: {
                    title: this.$t('Ar tikrai norite atmesti pavadavimą?')
                },
                on: {
                    confirmed: (confirm) => this.onAbortDeputy(confirm)
                }
            }
        },
        canDeputyApprove() {
            return this.isDeputy && this.status === 'pending'
        },
        confirmDeputyApprove() {
            return {
                props: {
                    title: this.$t('Ar tikrai norite patvirtinti pavadavima?')
                },
                on: {
                    confirmed: (confirm) => this.onApproveDeputy(confirm)
                }
            }
        },
        isHoliday() {
            return this.type === 'event' && this.approvalable.type !== EVENT_HOLIDAY_TYPE_DEPUTY
        },
        canHolidayAbort() {
            return this.isHoliday && this.approvalableNotStar && this.status !== 'aborted'
        },
        confirmHolidayAbort() {
            return {
                props: {
                    title: this.$t('Ar tikrai norite atmesti atostogas?')
                },
                on: {
                    confirmed: (confirm) => this.onAbortHoliday(confirm)
                }
            }
        },
        canHolidayChange() {
            return this.isHoliday && this.approvalableNotStar && this.status !== 'aborted'
        },
        canHolidayInfo() {
            return this.isHoliday && this.approvalable.end < this.todayDate
        },
        isRemoteWork() {
            return this.type === 'remote-work'
        },
        canRemoteWorkAbort() {
            return this.isRemoteWork && this.approvalableNotStar && this.status !== 'aborted'
        },
        confirmRemoteWorkAbort() {
            return {
                props: {
                    title: this.$t('Ar tikrai norite atmesti nuotolini darbą?')
                },
                on: {
                    confirmed: (confirm) => this.onAbortRemoteWork(confirm)
                }
            }
        },
        canRemoteWorkChange() {
            return this.isRemoteWork && this.approvalableNotStar && this.status !== 'aborted'
        }
    },
    methods: {
        async onAbortHoliday(confirm) {
            const api = customApi(confirm.tag)
            const result = await api.request(api.factories.employee.holidays.abort(this.approvalable.id, { message: '' }))
            if (result == null) {
                return
            }

            await this.$store.dispatch('loadersStore/hideLoader', confirm.tag)
            await this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag: confirm.tag,
                message: this.$t('Sėkmingai atšauktas.')
            })
            this.$store.dispatch('employeeVacationsStore/refreshData')
            confirm?.close()
        },
        async onAbortDeputy(confirm) {
            const api = customApi(confirm.tag)
            const result = await api.request(api.factories.employee.holidays.deputies.abort(this.approvalable.id, { message: '' }))
            if (event == null) {
                return
            }

            await this.$store.dispatch('loadersStore/hideLoader', confirm.tag)
            await this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag: confirm.tag,
                message: this.$t('Sėkmingai atšauktas.')
            })
            this.$store.dispatch('employeeVacationsStore/refreshData')
            confirm?.close()
        },
        async onApproveDeputy(confirm) {
            const api = customApi(confirm.tag)
            const result = await api.request(api.factories.employee.holidays.deputies.approve(this.approvalable.id, { message: '' }))
            if (result == null) {
                return
            }

            await this.$store.dispatch('loadersStore/hideLoader', confirm.tag)
            await this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag: confirm.tag,
                message: this.$t('Sėkmingai patvirtintas.')
            })
            this.$store.dispatch('employeeVacationsStore/refreshData')
            confirm?.close()
        },
        async onAbortRemoteWork(confirm) {
            const api = customApi(confirm.tag)
            const result = await api.request(api.factories.events.remoteWork.abort(this.approvalable.id))
            if (result == null) {
                return
            }

            await this.$store.dispatch('loadersStore/hideLoader', confirm.tag)
            await this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag: confirm.tag,
                message: this.$t('Sėkmingai atšauktas.')
            })
            this.$store.dispatch('employeeVacationsStore/refreshData')
            confirm?.close()
        }
    }
}
</script>