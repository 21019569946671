import customApi from "@/plugins/customApi";
import store from "@/store";
import {jsonToFormData} from "@/utils/default";

class DebuggerService {

  async getData(tag, employeeId, fromDate, toDate) {
    if (employeeId == null) {
      return;
    }

    if (fromDate == null) {
      return;
    }

    if (toDate == null) {
      return;
    }

    const api = customApi(tag);
    const response = await api.request(api.factories.debugger.get(employeeId, fromDate, toDate));

    if (response == null) {
      return false;
    }

    await store.dispatch("debuggerStore/init", response);

    await store.dispatch("loadersStore/hideLoader", tag);
    return true;
  }

  async importVacations(tag, attachment) {
    const formData = jsonToFormData({attachment});
    const api = customApi(tag);
    const response = await api.request(api.factories.debugger.importVacations(formData));
    if (response == null) {
      return false;
    }

    await store.dispatch("messagesStore/showSuccessMessage", {tag, message: "Importuota"});
    await store.dispatch("loadersStore/hideLoader", tag);
    return true;
  }

}

export default new DebuggerService;