<template>
    <v-container class='py-6 px-8' fluid>
        <div class='dtv-home-container'>
            <div class='dtv-home-container__left'>
                <div class='breadcrumbs'>
                    <div class='breadcrumb' @click="$router.push({name: 'DtvHomePage'})">{{ $t('Darbo teisės vadovas') }}</div>
                    <ui-icon>arrow-right</ui-icon>
                    <div class='breadcrumb'>{{ title }}</div>
                </div>
            </div>
        </div>
        <div class='dtv-home-container mt-8'>
            <div class='dtv-home-container__left d-flex align-center'>
                <h1>{{ title }}</h1>
            </div>
            <div class='dtv-home-container__right' v-if='false'>
                <text-field
                    v-if='false'
                    class='dtv-search-input'
                    placeholder='Ieškoti...'
                    v-model='search'
                >
                    <template #append-inner>
                        <ui-icon>find</ui-icon>
                    </template>
                </text-field>
            </div>
        </div>
        <div class='dtv-home-container mt-8'>
            <div class='dtv-detail-sidebar'>
                <div class='dtv-detail-list'>
                    <ul class='dtv-list'>
                        <dtv-list-item title='DK komentarai' default-expand v-if='comments.length > 0'>
                            <ul class='dtv-list-tags'>
                                <dtv-list-tag-item
                                    v-for='({title, child, type}, key) in comments'
                                    v-if='type !== "page"'
                                    :key='key'
                                    :title='title'
                                    :sub-title='key + 1'
                                    :child='child'
                                    default-expand
                                />
                            </ul>
                            <ul class='dtv-list-sub-links'>
                                <dtv-list-link-item
                                    v-for='page in comments'
                                    v-if='page.type === "page"'
                                    :key='page.title'
                                    :title='`${page.articleNumber} straipsnis. ${page.title}`'
                                    :page='page'
                                />
                            </ul>
                        </dtv-list-item>
                        <dtv-list-item title='Dokumentų formos' v-if='documents.length > 0'>
                            <ul class='dtv-list-form'>
                                <dtv-list-form-item
                                    v-for='(document, key) in documents'
                                    :key='key'
                                    :title='document.title'
                                    :page='document'
                                />
                            </ul>
                        </dtv-list-item>
                        <dtv-list-item
                            v-if='questionsCount > 0 && isAdmin'
                            :title='`Užduoti klausimai`'
                            @click='viewQuestions'
                            is-link
                            :is-active='isViewQuestions'
                        />
                    </ul>
                </div>
                <div class='dtv-detail-question pt-9' v-if='isAdmin'>
                    <dtv-new-question with-icon />
                </div>
            </div>
            <div v-if='page' class='dtv-article' ref='page'>
                <template v-if="page.type === 'page'">
                    <div class='dtv-article__header'>
                        <h1>{{ page.title }}</h1>
                        <div class='dtv-article__header-link'>
                            <span v-if='page.articleNumber'>{{ page.articleNumber }} straipsnis:</span>
                            <a :href='page.hyperlink' target='_blank'>{{ page.hyperlink }}</a>
                        </div>
                        <employee-table-view
                            avatar-size='54'
                            :employee='{avatarSrc: page.author.avatar, name: page.author.name, duties: [{name: page.author.position}]}'
                        />
                    </div>
                    <dtv-article-content :content='page.content' />
                </template>
                <template v-else>
                    <div class='dtv-article__header dtv-article__header-form'>
                        <ui-icon>dtv-file</ui-icon>
                        <h1>{{ page.title }}</h1>
                        <span>#{{ title }}</span>
                        <a :href='page.docFile' download>
                            Parsisiųsti
                            <ui-icon>download</ui-icon>
                        </a>
                    </div>
                    <div class='dtv-article__content'>
                        <iframe :src='page.pdfFile' width='100%' height='950px' />
                    </div>
                </template>
            </div>
            <div class='dtv-questions' v-if='isViewQuestions'>
                <form-checkbox :is-checked.sync='visibleAllAnswer' @change='onChangeVisibleAllAnswers'>
                    <span class='pl-2'>Rodyti visus atsakymus</span>
                </form-checkbox>
                <div class='dtv-questions__content'>
                    <dtv-question
                        v-for='({content, answer, created}, key) in questionsOnPage'
                        ref='question'
                        :key='key'
                        :date='created'
                        :content='content'
                        :answer='answer'
                        :default-visible-answer='visibleAllAnswer'
                    />
                </div>
                <ui-pagination
                    :active-page='questionsPage'
                    :last-page='questionsPages'
                    @changePage='changeQuestionPage'
                />
            </div>
        </div>
    </v-container>
</template>

<script>
import UiIcon from '@/domain/components/UiIcon.vue'
import TextField from '@/domain/fields/TextField.vue'
import TextareaField from '@/domain/fields/TextareaField.vue'
import UiButton from '@/domain/components/UiButton.vue'
import DtvListItem from '@/domain/dtv/components/DtvListItem.vue'
import { mapGetters } from 'vuex'
import DtvListTagItem from '@/domain/dtv/components/DtvListTabItem.vue'
import EmployeeTableView from '@/components/ui/views/EmployeeTableView.vue'
import DtvListFormItem from '@/domain/dtv/components/DtvListFormItem.vue'
import DtvQuestion from '@/domain/dtv/components/DtvQuestion.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import UiPagination from '@/domain/components/UiPagination.vue'
import DtvNewQuestion from '@/domain/dtv/components/DtvNewQuestion.vue'
import { permissionOnlyAdmin } from '@/utils/permissions'
import DtvListLinkItem from '@/domain/dtv/components/DtvListLink.vue'
import DtvArticleContent from '@/domain/dtv/components/DtvArticleContent.vue'


export default {
    name: 'DtvDetail',
    components: {
        DtvArticleContent,
        DtvListLinkItem,
        DtvNewQuestion,
        UiPagination,
        FormCheckbox,
        DtvQuestion,
        DtvListFormItem,
        EmployeeTableView, DtvListTagItem, DtvListItem, UiButton, TextareaField, TextField, UiIcon
    },
    data() {
        return {
            search: '',
            message: '',
            page: null,
            isViewQuestions: false,
            questionsPage: 1,
            questionsPerPageLimit: 6,
            visibleAllAnswer: false
        }
    },
    computed: {
        ...mapGetters({
            title: 'dtvStore/categoryTitle',
            comments: 'dtvStore/comments',
            documents: 'dtvStore/documents',
            questions: 'dtvStore/questions',
            questionsCount: 'dtvStore/questionsCount'
        }),
        isAdmin() {
            return this.hasPermissionTo(permissionOnlyAdmin)
        },
        questionsOnPage() {
            const start = (this.questionsPage - 1) * this.questionsPerPageLimit
            const end = start + this.questionsPerPageLimit

            return this.questions.slice(start, end)
        },
        questionsPages() {
            return Math.ceil(this.questionsCount / this.questionsPerPageLimit)
        }
    },
    created() {
        this.$store.dispatch('dtvStore/fetchData', this.$route.params.name)
    },
    provide() {
        return {
            viewPage: page => {
                this.page = page
                this.isViewQuestions = false

                this.$nextTick(() => {
                    this.$refs.page?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
                })
            }
        }
    },
    methods: {
        viewQuestions() {
            this.$store.dispatch('dtvStore/changePage', null)
            this.page = null
            this.questionsPage = 1
            this.isViewQuestions = true
        },
        changeQuestionPage(page) {
            this.questionsPage = page
        },
        onChangeVisibleAllAnswers() {
            if (!this.visibleAllAnswer) {
                this.$refs.question.forEach(element => element.hideAnswer())
                return
            }

            this.$refs.question.forEach(element => element.showAnswer())
        }
    },
    beforeDestroy() {
        this.$store.dispatch('dtvStore/changePage', null)
    }
}
</script>