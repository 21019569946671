<template>
  <div class="dtv-button" v-bind="$attrs" v-on="$listeners" @click="onOpenModal">
    <div class="dtv-button__content">
      <slot name="content"/>
    </div>
    <div class="dtv-button__footer">
      <slot name="footer"/>
    </div>
  </div>
</template>

<script>
import modalPropsMixin from "@/domain/mixins/modalPropsMixin";

export default {
  name: "DtvButton",
  mixins: [modalPropsMixin],
}
</script>