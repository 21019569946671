<template>
    <employee-event-invite-line
        :type-text='typeText'
        :start='start'
        :end='end'
        :deputies='deputies'
        :with-status='withStatus'
        :event-id='event.id'
        :event-type='event.type'
    >
        <template #status>
            <event-status-with-approval-stages
                :id='event.id'
                type='holidayEvent'
                :status='event.currentStatus'
                :status-text='event.currentStatusText'
            />
        </template>

        <template #actions>
            <hra-tooltip
                v-if='canHolidayAbort'
                :text="$t('Atšaukti')"
            >
                <ui-button
                    background='danger-light'
                    :only-hover-background='!isBondMobile'
                    size='small'
                    prepend-icon='close'
                    :confirm='confirmHolidayAbort'
                />
            </hra-tooltip>

            <hra-tooltip v-if='canHolidayChange' :text="$t('Keisti')">
                <ui-button
                    background='default'
                    :only-hover-background='!isBondMobile'
                    size='small'
                    :modal="{name: 'VacationModal', props: {userId: event.userId, eventId: event.id}, on: { changed: changed }}"
                    prepend-icon='edit'
                />
            </hra-tooltip>

            <hra-tooltip v-if='canHolidayInfo' :text="$t('Plačiau')">
                <ui-button
                    background='default'
                    :only-hover-background='!isBondMobile'
                    size='small'
                    :modal="{name: 'VacationModal', props: {userId: event.userId, eventId: event.id, showProgress: progressVisible}, on: { changed: changed }}"
                    prepend-icon='info'
                />
            </hra-tooltip>
        </template>
    </employee-event-invite-line>
</template>

<script>
import EmployeeEventInviteLine from '@/domain/eventInvite/components/employee/EmployeeEventInviteLine.vue'
import UiButton from '@/domain/components/UiButton.vue'
import HraTooltip from '@/components/ui/HraTooltip.vue'
import isBondMobileMixin from '@/mixins/isBondMobileMixin'
import moment from 'moment/moment'
import CustomApi from '@/plugins/customApi'
import { GLOBAL_TOAST_TAG } from '@/utils/default'
import { mapGetters } from 'vuex'
import EventStatusWithApprovalStages from '@/domain/components/ApprovalStages/EventStatusWithApprovalStages.vue'

const customApi = CustomApi()
export default {
    name: 'EmployeeHolidayEventInvite',
    components: { EventStatusWithApprovalStages, HraTooltip, UiButton, EmployeeEventInviteLine },
    mixins: [isBondMobileMixin],
    props: {
        invite: { type: Object, required: true },
        withStatus: { type: Boolean, default: false }
    },
    data() {
        return {
            todayDate: moment().format('YYYY-MM-DD'),
            confirmHolidayAbort: {
                props: {
                    title: this.$t('Ar tikrai norite atšaukti atostogas?')
                },
                on: {
                    confirmed: (confirm) => this.abortHoliday(confirm)
                }
            },
            progressVisible: true
        }
    },
    computed: {
        ...mapGetters({
            holidayDates: 'calendar/holidayDates'
        }),
        createBeforeDays() {
            return this.$store.getters['currentUserStore/settings']?.holiday
                ?.settingHolidayAllCreateBeforeWorkDays?.config.days ?? 0
        },
        event() {
            return this.invite.approvalable
        },
        start() {
            return this.event.start
        },
        end() {
            return this.event.end
        },
        typeText() {
            return this.event.typeText
        },
        deputies() {
            const deputies = this.event.deputies
                .filter(({ currentStatus }) => ['approved', 'pending'].includes(currentStatus))
                .map(({ user }) => `<b>${user.name}</b>`)

            return deputies.length === 0
                ? this.$t('Pavaduojančių nebus')
                : this.$t('Pavaduoja') + ' ' + deputies.join(', ')
        },
        minDate() {
            let date = moment()
            let i = 0
            const dateMethod = this.createBeforeDays < 0 ? 'subtract' : 'add'

            while (i < Math.abs(this.createBeforeDays)) {
                date = date[dateMethod](1, 'days')

                if (this.isDateNotHolidayAndWeekend(date)) {
                    i++
                }
            }

            return date.format('YYYY-MM-DD')
        },
        canHolidayChange() {
            return this.start >= this.minDate && this.event.currentStatus !== 'aborted'
        },
        canHolidayAbort() {
            return this.start >= this.todayDate && this.event.currentStatus !== 'aborted'
        },
        canHolidayInfo() {
            return !this.canHolidayChange
        }
    },
    methods: {
        isDateNotHolidayAndWeekend(date) {
            return ![6, 7].includes(date.isoWeekday()) && !this.holidayDates.includes(date.format('YYYY-MM-DD'))
        },
        changed() {
            this.$emit('changed')
        },
        async abortHoliday(confirm) {
            try {
                const result = await customApi.factories.employee.holidays.abort(this.event.id, { message: '' })
                if (result == null) {
                    return
                }

                await this.$store.dispatch('loadersStore/hideLoader', confirm.tag)
                await this.$store.dispatch('messagesStore/showSuccessMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Sėkmingai atšauktas.')
                })
                this.$emit('changed', this.invite.id)
            } catch (e) {
                console.error(e)
                await this.$store.dispatch('messagesStore/showErrorMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Nepaviko atšaukti.')
                })
            } finally {
                confirm?.close()
            }
        }
    }
}
</script>