<template>
    <app-layout>
        <div class='page page_with-background'>
            <route-tabs class='pt-5' :tabs='tabs' />
        </div>
    </app-layout>
</template>

<script>
import AppLayout from '@/layouts/AppLayout'
import CustomTabs from '@/domain/components/CustomTabs'
import RouteTabs from '@/domain/components/RouteTabs'

export default {
    name: 'ReportsLayout',
    components: { RouteTabs, CustomTabs, AppLayout },
    data() {
        return {
            tabs: [
                { name: 'ReportsPage', text: this.$t('Atostogų ataskaita') },
                { name: 'VacationActivityReportPage', text: this.$t('Atostogų tvirtinimų ataskaita') },
                { name: 'VacationBalancePage', text: this.$t('Atostogų likučiai') },
                { name: 'ParentDaysReportPage', text: this.$t('Tėvadieniai/mamadieniai') },
                { name: 'RemoteWorkReportPage', text: this.$t('Nuotolinis darbas') },
                { name: 'SickLeavesReportPage', text: this.$t('Ligonlapiai') },
                { name: 'BusinessTripsReportPage', text: this.$t('Komandiruotės') },
            ]
        }
    }
}
</script>