<template>
    <v-row>
        <v-col cols="12" class="text-center">
            <v-progress-circular indeterminate color="blue" :size="size" width="2" class="m-2" />
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        size: {
            type: [Number, String],
            default: 32,
        },
    },
}
</script>
