<template>
    <div class='d-flex flex-row gap-2'>
        <ui-icon
            class='finder-view-mode-icon'
            :class='{active: isGridMode}'
            @click="handleChangeMode('grid')"
        >grid
        </ui-icon>
        <ui-icon
            class='finder-view-mode-icon'
            :class='{active: isListMode}'
            @click="handleChangeMode('list')"
        >list
        </ui-icon>
    </div>
</template>

<script>
import UiIcon from '@/domain/components/UiIcon.vue'

export default {
    name: 'DocumentsFinderHeaderViewModes',
    components: { UiIcon },
    inject: ['storeNamespace'],
    computed: {
        mode() {
            return this.$store.getters[`${this.storeNamespace}/mode`]
        },
        isGridMode() {
            return this.mode === 'grid'
        },
        isListMode() {
            return this.mode === 'list'
        }
    },
    methods: {
        handleChangeMode(mode) {
            this.$store.dispatch(`${this.storeNamespace}/mode/change`, mode)
        }
    }
}
</script>

<style scoped lang='scss'>
.finder-view-mode-icon {
    color: #70778C;
    cursor: pointer;
    font-size: 1.3rem;

    &.active {
        color: #7177C1;
    }
}
</style>