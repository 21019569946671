<template>
	<text-field
		style="width: 240px"
		:placeholder="$t('Ieškoti...')"
		v-bind="$attrs"
		icon="find"
		can-clear
		v-model="innerValue"
		@input="onChange"
	>
		<template #append-inner>
			<hra-icon class="ml-2" name="find" />
		</template>
	</text-field>
</template>
<script>
import FormSelect from "@/components/ui/form/FormSelect";
import FormTextField from "@/components/ui/form/FormTextField";
import TextField from "@/domain/fields/TextField";
import HraIcon from "@/components/ui/HraIcon";

export default {
	name: "HraFilterSearch",
	components: {HraIcon, TextField, FormTextField, FormSelect},
	props: {
		name: {type: String, required: true}
	},
	data: () => ({
		innerValue: "",
		timeout: null,
	}),
	inject: ['filter', 'changeFilter'],
	created() {
		this.innerValue = this.filter[this.name];
	},
	methods: {
		onChange(value) {
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				this.changeFilter(this.name, value)
			}, 300);
		},
	},
}
</script>
