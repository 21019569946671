<template>
    <div class='document-user-board-page pa-8'>
        <div class='document-user-board-page__title'>
            <ui-icon>writing</ui-icon>
            {{ $t('Dokumentai susipažinti') }}
        </div>

        <documents-waiting-board class='mt-8' />

        <div class='document-user-board-page__title mt-14'>
            {{ $t('Dokumentų istorija') }}
        </div>

        <documents-history-table class='mt-8' />
    </div>
</template>

<script>
import UiIcon from '@/domain/components/UiIcon.vue'
import DocumentsWaitingBoard from '@/domain/documents/userBoard/compontents/DocumentsWaitingBoard.vue'
import DocumentsHistoryTable from '@/domain/documents/userBoard/compontents/DocumentsHistoryTable.vue'

export default {
    name: 'DocumentUserBoardPage',
    components: { DocumentsHistoryTable, DocumentsWaitingBoard, UiIcon }
}
</script>

<style lang='scss'>
.document-user-board-page {
    position: relative;
    z-index: 3;
    max-width: 1220px;

    &__title {
        color: #1C2538;
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 10px;

        .ui-icon {
            font-size: 23px;
            color: #70778C;
        }
    }
}
</style>