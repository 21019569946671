import customApi from "@/plugins/customApi";
import {
    ADD_HOME_DK_QUESTIONS, ADD_HOME_HR_QUESTIONS,
    SET_ACTIVE_PAGE_ID,
    SET_CATEGORIES, SET_CATEGORY_TITLE,
    SET_COMMENTS, SET_DK_QUESTION_BALANCE,
    SET_DOCUMENTS, SET_HOME_DK_QUESTIONS, SET_HOME_HR_QUESTIONS, SET_HR_QUESTION_BALANCE, SET_QUESTION_BALANCE,
    SET_QUESTIONS
} from '@/domain/dtv/store/mutations'
import {GLOBAL_TOAST_TAG} from "@/utils/default";

export const fetchCategories = ({commit}) => {
    const api = customApi('DTV_FETCH_CATEGORIES_TAG');
    api.requests([
        api.factories.dtv.categories.fetch(),
        api.factories.dtv.questions.fetch(),
        api.factories.hr.questions.fetch()
    ])
        .then(([categories, dkQuestions, hrQuestions]) => {
            if (categories == null || dkQuestions == null || hrQuestions == null) {
                return;
            }

            commit(SET_CATEGORIES, categories);

            commit(SET_HOME_DK_QUESTIONS, dkQuestions.questions);
            commit(SET_DK_QUESTION_BALANCE, dkQuestions.balance);

            commit(SET_HOME_HR_QUESTIONS, hrQuestions.questions);
            commit(SET_HR_QUESTION_BALANCE, hrQuestions.balance);
        });
}
export const fetchData = ({commit}, payload) => {
    commit(SET_CATEGORY_TITLE, '');
    commit(SET_COMMENTS, []);
    commit(SET_DOCUMENTS, []);

    const api = customApi('DTV_FETCH_TAG');
    api.requests([
        api.factories.dtv.categories.show(payload),
        api.factories.dtv.questions.fetchByCategory(payload)
    ])
        .then(([dk, dkQuestions]) => {
            if (dk == null || dkQuestions == null) {
                return;
            }

            commit(SET_CATEGORY_TITLE, dk.title);
            commit(SET_COMMENTS, dk.child[0]?.child ?? []);
            commit(SET_DOCUMENTS, dk.child[1]?.child ?? []);

            commit(SET_QUESTIONS, dkQuestions.questions);
            commit(SET_DK_QUESTION_BALANCE, dkQuestions.balance);
        });
}

export const changePage = ({commit}, pageId) => {
    commit(SET_ACTIVE_PAGE_ID, pageId);
}

export const newQuestion = async ({commit, dispatch}, message) => {
    const api = customApi('DTV_NEW_QUESTION');
    const response = await api.request(api.factories.dtv.questions.store({message}))
    if (response == null) {
        return;
    }

    if (response.error === true) {
        dispatch("messagesStore/showErrorMessage", {
            tag: GLOBAL_TOAST_TAG,
            message: response.message ?? '',
        }, {root: true})
        return;
    }

    commit(ADD_HOME_DK_QUESTIONS, response);

    dispatch("messagesStore/showSuccessMessage", {
        tag: GLOBAL_TOAST_TAG,
        message: 'Klausimas buvo uzduotas',
    }, {root: true});
}

export const newHrQuestion = async ({commit, dispatch}, question) => {
    const api = customApi('HR_NEW_QUESTION');
    const response = await api.request(api.factories.hr.questions.store({question}))
    if (response == null) {
        return;
    }

    if (response.error === true) {
        dispatch("messagesStore/showErrorMessage", {
            tag: GLOBAL_TOAST_TAG,
            message: response.message ?? '',
        }, {root: true})
        return;
    }

    commit(ADD_HOME_HR_QUESTIONS, response);

    dispatch("messagesStore/showSuccessMessage", {
        tag: GLOBAL_TOAST_TAG,
        message: 'Klausimas buvo uzduotas',
    }, {root: true});
}