<template>
    <div class='approval-level__button'>
        <ui-button
            class='pa-0 gap-3'
            background='primary'
            only-text
            prepend-icon='plus'
            :modal="{name: 'EmployeeSelectModal', on: {update: handleUpdateLevelUsers}, props: {alreadySelectedEmployees: this.level.users}}"
            style='min-height: auto;'
        >{{ $t('Pridėti tvirtinantį darbuotoją') }}
        </ui-button>
    </div>
</template>

<script>
import UiButton from '@/domain/components/UiButton.vue'

export default {
    name: 'ApprovalLevelButton',
    components: {
        UiButton
    },
    inject: ['storeNamespace'],
    props: {
        level: { type: Object, required: true },
    },
    methods: {
        handleUpdateLevelUsers(payload) {
            const existingIds = this.level.users.map(user => user.id)
            const updatedUserIds = payload.map(user => user.id)
            const usersToAdd = payload.filter(user => !existingIds.includes(user.id))
            const usersToDelete = this.level.users.filter(user => !updatedUserIds.includes(user.id)).map(user => user.id)

            if (usersToAdd.length > 0) {
                this.$store.dispatch(`${this.storeNamespace}/addUserToLevel`, {
                    levelId: this.level.id,
                    selectedUsers: usersToAdd
                })
            }

            if (usersToDelete.length > 0) {
                this.$store.dispatch(`${this.storeNamespace}/removeUserFromLevel`, {
                    levelId: this.level.id,
                    userId: usersToDelete
                })
            }
        }
    }
}
</script>

<style lang='scss'>
@import '@/assets/sass/core/colors';
.approval-level__button {
    background-color: #F7F7F9;
    padding: 8px 15px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .ui-button {
        span {
            font-size: 14px !important;
            font-weight: 600;
        }

        i {
            font-size: 11px !important;
        }

        &:hover {
            span, i {
                color: $violet-700 !important;
            }
        }
    }
}
</style>