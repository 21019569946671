<template>
  <ValidationProvider :name="$attrs.name" :rules="rules" v-slot="{ errors, classes }">
    <div :class="[...fieldClasses, ...classes]">
      <div :class="`${baseClassName}__label`">
        <label>{{ translateLabel }}</label>
        <slot name="right-label"/>
      </div>

      <v-autocomplete
          v-model="innerValue"
          :items="items"
          :placeholder="translatePlaceholder"
          :multiple="multiple"
          :loading="loading"
          solo
          chips
          flat
          single-line
          persistent-placeholder
          persistent-hint
          :menu-props="{
                    maxWidth: 'min-content',
                    padding: 0
                }"
          :disabled="disabled"
          :search-input.sync="search"
          :class="`border__${errors.length ? 'red' : 'gray_300'}`"
          hide-details
      >
        <template #append>
          <hra-icon :name="iconName.find"/>
        </template>
        <template #selection="{item}">
          <slot name="selection" :item="item">
            <div v-html="item.text"/>
          </slot>
        </template>
        <template #item="{item}">
          <slot name="item" :item="item">
            <EmployeeTableView class="py-1" :employee="item.employee"/>
          </slot>
        </template>
        <template #no-data>
          <div class="pa-1" v-show="!loading">
            <FormAlert type="error" text="Empty"/>
          </div>
        </template>
      </v-autocomplete>
    </div>
  </ValidationProvider>
</template>

<script>
import formFieldMixin from "@/mixins/formFieldMixin";
import EmployeeTableView from "@/components/ui/views/EmployeeTableView";
import Button from "@/components/ui/button/Button";
import {mapGetters} from "vuex";
import FullRowLoader from "@/components/ui/FullRowLoader";
import FormAlert from "@/components/ui/form/FormAlert";
import apiEmployee from "@/api/app/apiEmployee";
import HraIcon from "@/components/ui/HraIcon";

export default {
  components: {HraIcon, FormAlert, FullRowLoader, Button, EmployeeTableView},
  mixins: [formFieldMixin],
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => ([]),
    },
    appendIcon: {
      type: String,
      default: "down",
    },
    autocompleteType: {
      type: String,
      default: "vacations",
    },
  },
  data: () => ({
    baseClassName: "form-select",
    loaderName: "Employee-Autocomplete",
    search: null,
    items: [],
    getId: null,
    timeout: null,
    loading: false,
  }),
  computed: {
    ...mapGetters({
      getEmployeeById: "user/getEmployeeById",
    }),
  },
  watch: {
    search(value) {
      value !== this.value && this.querySelections(value);
    },
  },
  created() {
    this.getItems("");
  },
  methods: {
    async getItems(value) {
      const {data} = await this.$api.employee.autocomplete({
        find: value,
        type: this.autocompleteType,
      }, this.loaderName);

      this.items = data.map(employee => ({
        value: employee.id,
        text: employee.name,
        employee
      }));

      this.loading = false;
    },
    async getItemsByIds(ids) {
      if (ids == null) {
        return;
      }

      this.loading = true;

      const {data} = await this.$api.employee.autocomplete({ids}, this.loaderName);

      this.items = data.map(employee => ({
        value: employee.id,
        text: employee.name,
        employee
      }));

      // this.setInnerValue(this.value);

      this.loading = false;
    },
    querySelections(value) {
      this.loading = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.getItems(value);
      }, 500);
      return true;
    },
    emitNewValue(newValue, oldValue) {
      this.$emit("input", newValue);
    },
    setInnerValue(value) {
      const checkValue = Array.isArray(value) ? value : [value];

      if (this.items.filter(item => {
        return checkValue.indexOf(item) > -1 || checkValue.indexOf(parseInt(item)) > -1;
      }).length === 0) {
        this.getItemsByIds(value);
      }

      this.innerValue = value;
    },
    clearValue() {
      this.innerValue = null;
    },
  }
};
</script>
