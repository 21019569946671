<template>
	<validation-provider
		tag="div"
		class="TextareaField"
		immediate
		:rules="rules"
		:name="name"
		v-slot="{ errors }"
	>
		<base-field :invalid="!!errors.length">
			<read-only-field v-if="readOnly" :invalid="!!errors.length" :value="value" class="TextareaField__readonly"/>
			<div v-else class="field TextareaField__field" :class="{...fieldClasses, ['invalid']: !!errors.length}">
				<textarea
					class="TextareaField__textarea"
					:name="name"
					:disabled="hasDisabled"
					:value="value"
					:rows="rows"
					:placeholder="placeholder"
					@input="onChangeInput"
				/>
			</div>
		</base-field>
	</validation-provider>
</template>

<script>
import ReadOnlyField from "@/domain/fields/components/ReadOnlyField";
import BaseField from "@/domain/fields/components/BaseField";
import baseFieldMixin from "@/domain/fields/mixins/baseFieldMixin";
import moment from "moment";
import EditableFieldActions from "@/domain/fields/components/EditableFieldActions";

export default {
	name: "TextareaField",
	components: {EditableFieldActions, BaseField, ReadOnlyField},
	mixins: [baseFieldMixin],
	props: {
		rows: {type: [String, Number], default: 3},
	},
	computed: {
		hasCanClear() {
			if (!this.canClear) {
				return false;
			}

			return !this.hasDisabled && this.value != null && this.value !== "";
		},
	},
	methods: {
		onChangeInput(event) {
			this.onChange(event.target.value);
		},
		onClear() {
			this.onChange("");
		},
	},
}
</script>

<style lang="scss">
@import "@/assets/sass/core/vars";

.TextareaField {

	&__field {
		height: 100%;
		padding: 13px;
	}

	&__textarea {
		width: 100%;
		height: 100%;
		outline: none;
		resize: none;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 20px;
		color: $black-800;

		&::placeholder {
			color: $greyscale-600;
		}
	}

	&__readonly {
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 20px;
		color: $black-800;
	}
}
</style>