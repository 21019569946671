<template>
	<div class="widget" ref="test">
		<div class="widget__title">{{ title }}</div>
		<div class="widget__value">{{ value }}</div>
		<div v-if="tooltip" class="widget__tooltip">
			<hra-tooltip :text="tooltip" max-width="350">
				<hra-icon :name="iconName.info"/>
			</hra-tooltip>
		</div>
	</div>
</template>

<script>
import HraTooltip from "@/components/ui/HraTooltip";
import HraIcon from "@/components/ui/HraIcon";

export default {
	name: "Widget",
	components: {HraIcon, HraTooltip},
	props: {
		title: {type: String, required: true},
		value: {type: String, required: true},
		tooltip: {type: String, default: null},
	},
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/core/vars';

.widget {
	display: grid;
	grid-template-rows: 1fr 20px;
	grid-template-columns: 1fr auto;
	gap: 14px;
	padding: 13px 16px;
	grid-template-areas:
    "title tooltip"
    "value tooltip";
  background: #FFFFFF;
  border: 1px solid #DAD5E9;
  border-radius: 4px;

	&__title {
		grid-area: title;
		box-sizing: border-box;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #70778C;
	}

	&__value {
		grid-area: value;
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 20px;
    color: #293080;
	}

	&__tooltip {
		grid-area: tooltip;
	}
}
</style>