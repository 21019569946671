<template>
    <div class='schedule-events' :style='rootStyle' ref='root'>
        <table
            class='schedule-table'
            :class="headerHoursCount ? 'schedule-table__days' : 'schedule-table__months'"
        >
            <div class='schedule-table__arrows'>
                <ui-button
                    background='white'
                    prepend-icon='arrow-left'
                    class='prev-month'
                    size='small'
                    @click='onPrevMonth'
                />
                <ui-button
                    class='next-month'
                    prepend-icon='arrow-right'
                    background='white'
                    size='small'
                    @click='onNextMonth'
                />
            </div>
            <thead>
            <tr v-if='!headerHoursCount'>
                <td class='px-10' :colspan='getMonthColspan' style='height: 42px;'>
                    <span class='schedule-table__header-text'>{{ getMonthName }}</span>
                </td>
            </tr>
            <tr>
                <td
                    v-for='({key, text, colspan, colClass, weekDay, dateFormatted}) in headerDays'
                    :key='key'
                    :colspan='colspan'
                    :data-date='dateFormatted'
                    :data-week-day='weekDay'
                    class='font-weight-medium'
                    :class="[headerHoursCount ? 'text-left, font-weight-medium' : 'text-center', colClass]"
                    :style="{ height: headerHoursCount ? '42px' : '33px' }"
                >
                    {{ text }}
                </td>
            </tr>
            <tr v-if='headerHoursCount'
                class='schedule-table__hours'
            >
                <td
                    v-for='({key, text, weekDay, colClass}) in headerDays'
                    :key='key'
                    :data-week-day='weekDay'
                    class='text-center text-body-2'
                    style='height: 33px;'
                    :colspan='headerHoursCount'
                >
                    <span v-for='hour in hours'>{{ hour }}</span>
                </td>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td :colspan='colspan' :style='canvasStyle'>
                    <div class='schedule-events__today-line' :style='todayLineStyle' v-if='isCurrentMonth' />
                    <schedule-timetable-event
                        v-for='timetable in timetables'
                        :key='timetable.key'
                        :start='start'
                        :one-pixel-to-second='onePixelToSecond'
                        :event-only-days-grid='eventOnlyDaysGrid'
                        :row-height='rowHeight'
                        :day-width='dayWidth'
                        :event='timetable'
                        :grid='eventGrid'
                        :max-x='maxX'
                        :max-y='maxY'
                        @dragstop='onDragStopTimetable'
                    />
                    <schedule-event
                        v-for='event in events'
                        :key='event.key'
                        :start='start'
                        :one-pixel-to-second='onePixelToSecond'
                        :event-only-days-grid='eventOnlyDaysGrid'
                        :row-height='rowHeight'
                        :day-width='dayWidth'
                        :event='event'
                        :grid='eventGrid'
                        :max-x='maxX'
                        :max-y='maxY'
                        @dragstop='onDragStop'
                    />

                    <!-- hide wantToWorkEvents for now -->
                    <schedule-event v-if='false'
                                    v-for='event in {wantToWorkEvents}'
                                    :key='event.key'
                                    :start='start'
                                    :one-pixel-to-second='onePixelToSecond'
                                    :event-only-days-grid='eventOnlyDaysGrid'
                                    :row-height='rowHeight'
                                    :day-width='dayWidth'
                                    :event='event'
                                    :grid='eventGrid'
                                    :max-x='maxX'
                                    :max-y='maxY'
                                    @dragstop='onDragStop'
                    />
                </td>
            </tr>
            <tr
                v-for='row in rows'
                :key='`events-row-${row.id}`'
            >
                <td
                    :style='{height: `${rowHeight}px`}'
                    class='schedule-table-column hover'
                    v-for='({key, weekDay, date, colClass}) in grid'
                    :class='colClass'
                    :key='`events-row-day-${row.id}-key-${key}`'
                    :colspan='headerHoursCount'
                    :data-week-day='weekDay'
                    @click.prevent='onClickOnDate(row, date)'
                ></td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { getDateArray } from '@/utils/default'
import VueDraggableResizable from 'vue-draggable-resizable'
import moment from 'moment'
import { mapGetters } from 'vuex'
import ContextMenu from '@/domain/components/ContextMenu.vue'
import ContextMenuItem from '@/domain/components/ContextMenuItem.vue'
import HraTooltip from '@/components/ui/HraTooltip.vue'
import ScheduleEvent from '@/domain/scheduler/components/ScheduleEvent.vue'
import ScheduleTimetableEvent from '@/domain/scheduler/components/ScheduleTimetableEvent.vue'
import {
    permissionsButtonEmployeeCreate,
    permissionsButtonEmployeeDelete,
    permissionsButtonEmployeeEdit
} from '@/utils/permissions'
import { SCHEDULE_TAB_EDIT_SCHEDULE, SCHEDULE_TAB_EDIT_TIMETABLE } from '@/domain/scheduler/store/state'
import UiButton from '@/domain/components/UiButton.vue'
import UiIcon from '@/domain/components/UiIcon.vue'
import checkBrowser from '@/mixins/checkBrowser'

export default {
    name: 'ScheduleEvents',
    components: {
        ScheduleTimetableEvent,
        ScheduleEvent,
        VueDraggableResizable,
        HraTooltip,
        ContextMenuItem,
        ContextMenu,
        UiButton,
        UiIcon
    },
    mixins: [checkBrowser],
    props: {
        startDate: { type: String, required: true },
        endDate: { type: String, required: true },
        rows: { type: Array, required: true },
        rowHeight: { type: Number, default: 30 },
        dayWidth: { type: Number, default: 60 },
        headerHoursCount: { type: Number, default: 0 },
        eventOnlyDaysGrid: { type: Boolean, default: false }
    },
    data() {
        return {
            todayCheck: null,
            todayLineStyle: {
                opacity: 0
            },
            wantToWorkEvents: {
                'key': 'ibeda4713a9cf1b4b0faa6b2a2443c8f0ff91',
                'hasHoliday': false,
                'wantToWork': true, // Properties for wantToWork event
                'row': {
                    'id': 9999,
                    'scheduleId': 18,
                    'type': 'user',
                    'name': null,
                    'userId': 5,
                    'user': {
                        'id': 5,
                        'timeCardNumber': '5',
                        'authId': null,
                        'name': 'Tomas Žukauskas',
                        'phone': null,
                        'email': 'tomas.zukauskas@vz-demo.bond.demo',
                        'gender': null,
                        'description': null,
                        'birthDate': null,
                        'avatarSrc': null,
                        'departmentId': 4,
                        'dutyId': 4,
                        'lastSeen': '2023-09-05 11:58:23',
                        'activated': true,
                        'dismissed': false
                    },
                    'childrenCount': 0,
                    'scheduleLineId': null,
                    'tabs': 0,
                    'time': '09h 00min'
                },
                'editable': false,
                'rowIndex': 3,
                'id': 9999,
                'eventId': null,
                'type': 2001,
                'start': '2023-11-20',
                'end': '2023-11-20',
                'startWithTime': '2023-11-20 08:00:00',
                'endWithTime': '2023-11-20 18:00:00',
                'workDays': 1,
                'calendarDays': 1,
                'message': '',
                'userId': 5,
                'attachment': null,
                'createdAt': '2023-11-20',
                'updatedAt': '2023-11-20',
                'currentStatus': null,
                'userBalanceBeforeStart': null,
                'useInYearUninterruptedDays': null,
                'schedule': {
                    'eventId': 981,
                    'scheduleId': 18,
                    'scheduleLineId': 272
                },
                'breaks': [],
                'duration': {
                    'days': 0.20833333333333334,
                    'minutes': 300,
                    'seconds': 18000
                }
            },
            loadingEvents: []
        }
    },

    watch: {
        startDate: {
            handler() {
                this.todayLineFormat()
                this.scrollToToday()
            }
        },
        dayWidth: {
            handler() {
                this.todayLineFormat()
                this.scrollToToday()
            }
        }
    },
    mounted() {
        this.todayLineFormat()
        this.scrollToToday()
    },
    beforeDestroy() {
        clearTimeout(this.todayCheck)
    },
    computed: {
        ...mapGetters({
            events: 'schedulerStore/events',
            timetables: 'schedulerStore/timetables',
            holidayDates: 'schedulerStore/holidayDates',
            currentTab: 'schedulerStore/currentTab',
            datesWithWarning: 'schedulerStore/datesWithWarning'
        }),
        isAdmin() {
            return this.hasPermissionTo([
                ...permissionsButtonEmployeeCreate,
                ...permissionsButtonEmployeeEdit,
                ...permissionsButtonEmployeeDelete
            ])
        },
        headerDays() {
            return getDateArray(moment(this.startDate), moment(this.endDate), null, date => date)
                .map(date => {
                    const dateFormatted = date.format('YYYY-MM-DD')
                    const monthKey = date.format('MMMM').toLowerCase()

                    if (this.headerHoursCount) {
                        return {
                            key: dateFormatted,
                            dateFormatted,
                            text: `${this.$t(`months.${monthKey}`)} ${date.format('DD, YYYY')}`,
                            colspan: this.headerHoursCount,
                            weekDay: date.isoWeekday(),
                            colClass: {
                                'is-holiday': this.holidayDates.includes(dateFormatted),
                                'text-red': this.datesWithWarning.includes(dateFormatted),
                                'px-6': this.headerHoursCount
                            },
                            date
                        }
                    } else {
                        return {
                            key: dateFormatted,
                            dateFormatted,
                            text: date.format('DD'),
                            colspan: this.headerHoursCount,
                            weekDay: date.isoWeekday(),
                            colClass: {
                                'is-holiday': this.holidayDates.includes(dateFormatted),
                                'text-red': this.datesWithWarning.includes(dateFormatted)
                            },
                            date
                        }
                    }
                })
        },
        getMonthColspan() {
            const startOfMonth = moment(this.startDate)
            const endOfMonth = moment(this.endDate)
            const daysInMonth = endOfMonth.diff(startOfMonth, 'days') + 1

            return daysInMonth
        },
        getMonthName() {
            const monthKey = moment(this.startDate).format('MMMM').toLowerCase()
            return this.$t(`months.${monthKey}`) + `, ${moment(this.startDate).format('YYYY')}`
        },
        isCurrentMonth() {
            const currentMonth = moment().format('YYYY-MM')
            const displayedMonth = moment(this.startDate).format('YYYY-MM')
            return currentMonth === displayedMonth
        },
        eventGrid() {
            if (this.eventOnlyDaysGrid) {
                return [this.dayWidth, this.rowHeight]
            }

            return [24 / this.headerHoursCount, this.rowHeight]
        },

        hours() {
            const hourStep = 24 / this.headerHoursCount
            return new Array(this.headerHoursCount).fill(0).map((_, key) => {
                const hour = key * hourStep
                return hour.toString().padStart(2, '0') + ':00'
            })
        },
        start() {
            return moment(this.startDate)
        },
        onePixelToSecond() {
            return this.dayWidth / 24 / 60 / 60
        },
        colspan() {
            if (this.headerHoursCount > 0) {
                return this.headerDays.length
            }

            return this.headerDays.length
        },
        grid() {
            return this.headerDays
        },
        dayColumnCount() {
            return this.headerHoursCount === 0 ? 1 : this.headerHoursCount
        },
        rootStyle() {
            return {
                '--schedule-event-td-width': (this.dayWidth / this.dayColumnCount) + 'px'
            }
        },
        maxX() {
            return this.dayWidth * this.headerDays.length - this.eventGrid[0]
        },
        maxY() {
            return this.rowHeight * this.rows.length - this.eventGrid[1]
        },
        canvasStyle() {
            return {
                position: 'absolute',
                top: this.isBrowserSafari ? '77px' : '0px',
                left: 0,
                border: 0,
                width: `${this.dayWidth * this.headerDays.length}px`,
                height: '100%',
                background: 'transparent'
            }
        }
    },
    methods: {
        scrollToToday() {
            this.$nextTick(() => {
                const today = moment().format('YYYY-MM-DD')
                const element = document.querySelector(`td[data-date="${today}"]`)

                if (element == null) {
                    return
                }

                element.scrollIntoView({ behavior: 'smooth', block: 'end' })
            })
        },
        todayLineFormat() {
            clearTimeout(this.todayCheck)

            const now = moment()
            if (this.start.year() !== now.year() && this.start.month() !== now.month()) {
                this.todayLineStyle = {
                    opacity: 0
                }
            } else {
                let left = now.clone().diff(this.start, 'seconds') * this.onePixelToSecond

                this.todayLineStyle = {
                    left: left + 'px',
                    opacity: 1
                }
            }

            this.todayCheck = setTimeout(() => {
                this.todayLineFormat()
            }, 60 * 1000)
        },
        async onClickOnDate(row, date) {
            if (!this.isAdmin || row.id === 'fake-row') {
                return
            }

            const userDismissed = row.user?.dismissed ?? false
            if (userDismissed) {
                const dismissedDate = moment(row.user.dismissedDate)

                if (date.isAfter(dismissedDate)) return
            }

            if (row.type !== 'user') {
                await this.handleEventSubmit('timetable', row, date)

                return
            }

            await this.handleEventSubmit('event', row, date)
        },
        async handleEventSubmit(type = 'event', row, date) {
            const duplicatedEvents = this.loadingEvents.filter((event) => event.rowId === row.id && event.date.isSame(date))
            const currentTab = type === 'event' ? SCHEDULE_TAB_EDIT_SCHEDULE : SCHEDULE_TAB_EDIT_TIMETABLE

            if (this.currentTab !== currentTab) {
                return
            }

            if (duplicatedEvents.length > 0) {
                return
            }

            this.loadingEvents.push({
                rowId: row.id,
                date: date
            })

            try {
                if (type === 'timetable') {
                    await this.$store.dispatch('schedulerStore/createNewTimetable', { row, date })
                } else {
                    await this.$store.dispatch('schedulerStore/createNewUserEvent', { row, date })
                }
            } catch (e) {
                console.log('Error in request', e)
            } finally {
                this.loadingEvents = this.loadingEvents.filter((event) => event.rowId !== row.id)
            }
        },
        getRowByY(y) {
            const rowIndex = Math.floor(y / this.rowHeight)

            return this.rows[rowIndex] ?? null
        },
        async onDragStop(eventContext, x, y) {
            if (!this.isAdmin) {
                return
            }

            this.$store.dispatch('schedulerStore/changeSelectedTimeInterval', null)
            const row = this.getRowByY(y)

            if (row.type !== 'user') {
                return eventContext.reset()
            }

            const event = eventContext.event
            const dropTimeInMinutes = Math.round( x / this.onePixelToSecond / 60)
            const dropTime = this.start.clone().add('minutes', dropTimeInMinutes)
            let start = moment(event.startWithTime)
                .set('year', dropTime.year())
                .set('month', dropTime.month())
                .set('date', dropTime.date())

            if (!this.eventOnlyDaysGrid) {
                start.set('hour', dropTime.hour()).set('minute', dropTime.minute()).set('second', dropTime.second())
            }
            const end = start.clone().add('seconds', event.duration.seconds)

            const result = await this.$store.dispatch('schedulerStore/updateEventById', {
                id: event.id,
                payload: {
                    id: event.id,
                    scheduleId: row.scheduleId,
                    scheduleLineId: row.id,
                    userId: row.userId,
                    start: start.format('YYYY-MM-DD HH:mm:ss'),
                    end: end.format('YYYY-MM-DD HH:mm:ss')
                }
            })

            if (!result) {
                eventContext.reset()
            }
        },
        async onDragStopTimetable(eventContext, x, y) {
            if (!this.isAdmin) {
                return
            }

            this.$store.dispatch('schedulerStore/changeSelectedTimetableInterval', null)
            const row = this.getRowByY(y)

            if (row.type === 'user') {
                return eventContext.reset()
            }

            const event = eventContext.event
            const dropTime = this.start.clone().add('seconds', x / this.onePixelToSecond)
            let start = moment(event.startWithTime)
                .set('year', dropTime.year())
                .set('month', dropTime.month())
                .set('date', dropTime.date())

            if (!this.eventOnlyDaysGrid) {
                start.set('hour', dropTime.hour()).set('minute', dropTime.minute()).set('second', dropTime.second())
            }
            const end = start.clone().add('seconds', event.duration.seconds)

            const result = await this.$store.dispatch('schedulerStore/updateTimetableById', {
                id: event.id,
                payload: {
                    scheduleId: row.scheduleId,
                    scheduleLineId: row.id,
                    start: start.format('YYYY-MM-DD HH:mm:ss'),
                    end: end.format('YYYY-MM-DD HH:mm:ss'),
                    minUsers: event.minUsers
                }
            })

            if (!result) {
                eventContext.reset()
            }
        },
        onPrevMonth() {
            this.$store.dispatch('schedulerStore/prevMonth')
        },
        onNextMonth() {
            this.$store.dispatch('schedulerStore/nextMonth')
        }
    }
}
</script>

<style lang='scss'>
@import '@/assets/sass/core/colors';

$hoursBackground: linear-gradient(90deg, $schedule-border-secondary 0.74%, transparent 0.74%, transparent 12.50%, $schedule-border-primary 12.50%, $schedule-border-primary 13.24%, transparent 13.24%, transparent 25%, $schedule-border-primary 25%, $schedule-border-primary 25.74%, transparent 25.74%, transparent 37.50%, $schedule-border-primary 37.50%, $schedule-border-primary 38.24%, transparent 38.24%, transparent 50%, $schedule-border-secondary 50%, $schedule-border-secondary 50.74%, transparent 50.74%, $schedule-border-secondary 50.74%, transparent 50.74%, transparent 62.50%, $schedule-border-primary 60.50%, $schedule-border-primary 63.24%, transparent 63.24%, transparent 75%, $schedule-border-primary 75%, $schedule-border-primary 75.74%, transparent 75.74%, transparent 87.50%, $schedule-border-primary 87.50%, $schedule-border-primary 88.24%, transparent 88.24%, transparent 100%, $schedule-border-secondary 100%, $schedule-border-secondary 50.74%, transparent 100.74%);
$hoursBackgroundSize: 133.7px;
$hoursBackgroundPosition: -1px;
$hoursBackgroundRepeat: repeat;

.schedule-events {
    overflow-x: auto;
    overflow-y: hidden;
    --schedule-event-td-width: 60px;
    user-select: none;

    .schedule-table {
        position: relative;

        & > tbody {
            position: relative;
        }

        &__arrows {
            position: absolute;
            width: 100%;
            top: 0;
            z-index: 1;
            left: 0;
            height: 41px;
            display: flex;
            justify-content: space-between;

            .ui-button {
                padding: 0 8px;

                &.prev-month {
                    position: sticky;
                    left: -1px;
                }

                &.next-month {
                    position: sticky;
                    right: -1px;
                }
            }
        }
    }

    td {
        min-width: var(--schedule-event-td-width);
        position: relative;
        text-transform: capitalize;

        &.hover:hover {
            background-color: #FFF7DD;
        }
    }

    thead {
        td {
            &[data-week-day="6"],
            &[data-week-day="7"] {
                color: $greyscale-600;
            }

            &.is-holiday {
                color: $schedule-color-holiday;
            }
        }
    }

    tbody {
        td {
            &[data-week-day="6"],
            &[data-week-day="7"] {
                background-color: $schedule-weekend-body;
            }

            &.is-holiday {
                background-color: $schedule-color-holiday2;
            }
        }
    }

    &__today-line {
        position: absolute;
        left: 10px;
        width: 1px;
        height: 100%;
        border-left: 1px dashed $pink;
        z-index: 2;

        &::before {
            content: "";
            position: absolute;
            top: -8px;
            right: -5px;
            width: 11px;
            height: 11px;
            border-radius: 50%;
            background-color: $pink;
            z-index: 10;
        }
    }
}

.schedule-table__months {
    .ui-button {
        position: sticky;
        min-height: auto !important;
        top: 7px;
    }

    span {
        position: sticky;
        left: 24px;
    }
}

.schedule-table__header-text {
    color: $greyscale-800;
    font-weight: 500;
    letter-spacing: 0.28px;
}

.schedule-table__days {
    tbody {
        tr:not(:first-child) {
            td {
                border-left: none;
                border-right: 1px solid #C1BBD4;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-image: $hoursBackground;
                    background-size: $hoursBackgroundSize;
                    background-position: $hoursBackgroundPosition;
                    background-repeat: $hoursBackgroundRepeat;
                }
            }
        }
    }
}

.schedule-table__hours {
    td {
        border: none;
        position: relative;
        min-width: calc(var(--schedule-event-td-width) * 6);
        border-right: 1px solid $schedule-border-primary;

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 25%;
            background-image: $hoursBackground;
            background-size: $hoursBackgroundSize;
            background-position: $hoursBackgroundPosition;
            background-repeat: $hoursBackgroundRepeat;
        }

        &:after {
            content: '';
            position: absolute;
            right: -1px;
            bottom: 0;
            width: 1px;
            height: 25%;
            background: #C1BBD4;
        }

        span {
            position: absolute;
            top: 9px;

            &:nth-child(1) {
                left: -17px;
            }

            &:nth-child(2) {
                left: 50px;
            }

            &:nth-child(3) {
                left: 116px;
            }

            &:nth-child(4) {
                left: 186px;
            }

            &:nth-child(5) {
                left: 252px;
            }

            &:nth-child(6) {
                left: 317px;
            }
        }
    }
}
</style>