<template>
    <div class='selected-documents-list'>
        <header class='selected-documents-list__header'>
            <div class='selected-documents-list__header-left'>
                {{ $t('Prisegti dokumentai') }}
                <b class='pl-1'>{{ selectedDocuments.length }}</b>
            </div>
            <div class='selected-documents-list__header-right'>
                <div class='selected-documents-list__header-button' @click='handleAddDocuments'>
                    <span class='icon-plus' />
                    {{ $t('Pridėti') }}
                </div>
            </div>
        </header>

        <message-alert v-if='isEmpty' class='mt-4' id='---' :message='$t("Pasirintų dokumentų nėra")' type='info' />
        <div v-else class='selected-documents-list__content mt-4'>
            <div
                v-for='document in selectedDocuments'
                :key='`selected-document-${document.id}`'
                class='selected-documents-list__item'
            >
                <div class='selected-documents-list__item-icon'>
                    <ui-icon>paper</ui-icon>
                </div>
                <div class='selected-documents-list__item-title'>
                    {{ document.title }}
                </div>
                <div
                    class='selected-documents-list__item-button'
                    @click='handleRemoveDocument(document)'
                >
                    <ui-icon>minus-circle</ui-icon>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MessageAlert from '@/domain/messages/components/MessageAlert.vue'
import UiIcon from '@/domain/components/UiIcon.vue'

export default {
    name: 'SelectedDocumentsList',
    components: { UiIcon, MessageAlert },
    props: {
        modalName: {type: String, default: 'DocumentsSelectModal'},
        selectedDocuments: {type: Array, default: () => []}
    },
    computed: {
        isEmpty() {
            return this.selectedDocuments.length === 0
        }
    },
    methods: {
        handleAddDocuments() {
            this.$store.dispatch('modals/open', {
                name: 'DocumentsSelectModal',
                on: {
                    selectedDocuments: this.handleSelectedDocuments
                }
            })
        },
        handleSelectedDocuments(documents) {
            this.$emit('addDocuments', documents)
        },
        handleRemoveDocument(document) {
            this.$emit('removeDocument', document)
        }
    }
}
</script>

<style scoped lang='scss'>
.selected-documents-list {
    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &-left {
            color: #70778C;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;

            b {
                color: #C644A5;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 14px;
                letter-spacing: 0.42px;
                text-transform: uppercase;
            }
        }

        &-button {
            color: #1C2538;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            font-weight: 500;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            cursor: pointer;

            span {
                line-height: normal;
                display: block;
                background: #C644A5;
                border-radius: 50%;
                color: #FFFFFF;
                padding: 4px;
                font-size: 0.7rem;
            }
        }

        &-right {

        }
    }

    &__item {
        border: 1px solid #DAD5E9;
        border-bottom: 0;
        display: flex;
        flex-direction: row;
        padding: 10px 20px;
        align-items: center;
        min-height: 64px;
        gap: 16px;

        &:first-child {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }

        &:last-child {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            border-bottom: 1px solid #DAD5E9;
        }

        &:nth-child(even) {
            background: #FAF9FF;
        }

        &-icon {
            &> .ui-icon {
                color: #8689A8;
                font-size: 1.7rem;
            }
        }

        &-title {
            color: #1C2538;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        &-button {
            margin-left: auto;
            color: #70778C;
            font-size: 1.4rem;
            cursor: pointer;

            &:hover {
                color: #E7384F;
            }
        }
    }
}
</style>