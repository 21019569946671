import {
    ADD_MESSAGE,
    ADD_TO_LOADER_QUEUE,
    REMOVE_FROM_LOADER_QUEUE, SET_CONTEXT_MENU,
    SET_IS_MOBILE
} from '@/store/modules/global/mutations'
import CustomApi from '@/plugins/customApi'

const customApi = CustomApi()

const BOND_MOBILE_FROM_WIDTH = 768

export const resize = ({ commit, state }, payload) => {
    const isMobile = payload <= BOND_MOBILE_FROM_WIDTH

    commit(SET_IS_MOBILE, isMobile)
}
export const checkIsMobile = ({ dispatch }) => {
    window.addEventListener('resize', () => {
        dispatch('resize', window.innerWidth)
    }, false)

    dispatch('resize', window.innerWidth)
}

export const addLoader = ({ commit }, payload) => {
    commit(ADD_TO_LOADER_QUEUE, payload)
}

export const removeLoader = ({ commit }, payload) => {
    commit(REMOVE_FROM_LOADER_QUEUE, payload)
}

export const addErrorMessage = ({ commit }, payload) => {
    commit(ADD_MESSAGE, {
        type: 'error',
        ...payload
    })
}

export const addSuccessMessage = ({ commit }, payload) => {
    commit(ADD_MESSAGE, {
        type: 'success',
        ...payload
    })
}

export const setContextMenu = ({ commit }, payload) => {
    commit(SET_CONTEXT_MENU, payload)
}

export const getCountries = async ({ commit, state }) => {
    if (state.countries != null || state.fetchCountries) {
        return
    }
    state.fetchCountries = true

    try {
        const { data } = await customApi.factories.setup.getCountries({ limit: -1 })
        state.countries = data
    } catch (e) {
        console.error(e)
    } finally {
        state.fetchCountries = false
    }
}

