<template>
  <div class="d-flex gap-3">
    <slot/>
  </div>
</template>

<script>
import HraFilterLimit from "@/components/ui/filter/HraFilterLimit";
import _ from "lodash";

export default {
  name: "HraFilter",
  components: {HraFilterLimit},
  props: {
    value: {type: Object, required: true},
  },
  provide() {
    return {
			filter: this.value,
	    changeFilter: this.changeFilter,
    };
  },
	methods: {
		changeFilter(name, value) {
			this.$set(this.value, name, value);
			this.$emit("change", this.value);
		},
	},
};
</script>
