<template>
	<custom-dialog :max-width="630" ref="dialog">
		<template #title>{{ $t("Atostogų prašymas") }}</template>
		<template #content>
			<full-row-loader v-if="loading"/>
			<v-row v-else class="pa-3">
				<v-col cols="12" v-if="eventLoading">
					<full-row-loader/>
				</v-col>
				<v-col cols="12" v-if="!eventLoading && message">
					<form-alert v-bind="message"/>
				</v-col>
				<v-col cols="12">
					<employee-table-view avatar-size="60" :employee="vacation.profile"/>
				</v-col>
				<v-row>
					<v-col cols="5" class="hra-text--greyscale-600 hra-text--12">{{ $t("Atostogų laikotarpis") }}</v-col>
					<v-col cols="7">
						<vacation-period-view :from-date="vacation.fromDate" :to-date="vacation.toDate" />
					</v-col>
					<v-col cols="5" class="hra-text--greyscale-600 hra-text--12">{{ $t("Dienų skaičius") }}</v-col>
					<v-col cols="7">
						<b>{{ vacation.calendarDays }}</b> {{ $t("k. d.") }},
						<b>{{ vacation.workDays }}</b> {{ $t("d. d.") }}
					</v-col>
					<v-col cols="5" class="hra-text--greyscale-600 hra-text--12">{{ $t("Atostogų rūšis") }}</v-col>
					<v-col cols="7">{{ vacationType[vacation.typeId] }}</v-col>
					<v-col cols="5" class="hra-text--greyscale-600 hra-text--12">{{ $t("Pavaduojantis") }}</v-col>
					<v-col cols="7" class="d-flex gap-2 flex-column">
						<template v-if="vacation.deputy">
							<div>{{ vacation.deputy.employee.name }}</div>
							<div>
                <span v-if="vacation.deputy.status === STATUS_APPROVE"
                      class="hra--blue-light hra-text--blue px-2 py-1 hra-text--12"><b>{{
		                $t("Sutiko pavaduoti")
	                }}</b> {{
		                vacation.deputy.updatedAt
	                }}</span>
								<span v-else
								      class="hra--orange-light hra-text--orange px-2 py-1 hra-text--12"><b>{{
										$t("Laukiame patvirtinimo")
									}}</b></span>
							</div>
						</template>
						<template v-else>
							<div>
                <span
	                class="hra--red-light hra-text--red px-2 py-1 hra-text--12"><b>{{
		                $t("Pavaduojančio asmens nėra")
	                }}</b></span>
							</div>
						</template>
					</v-col>
					<template v-if="vacation.calculate">
						<v-col cols="5" class="hra-text--greyscale-600 hra-text--12">
							{{ $t("Atostogų ({date}) dienai daugiausia galima pasiimti", {date: vacation.calculate.date}) }}
						</v-col>
						<v-col cols="7"><b>{{ vacation.calculate.balance.toFixed(2) }}</b> {{ $t("d. d.") }}</v-col>
					</template>
					<v-col cols="5" class="hra-text--greyscale-600 hra-text--12">{{ $t("Prašymas išsiųstas") }}</v-col>
					<v-col cols="7">{{ vacation.createdAt }}</v-col>
					<template v-if="vacation.uninterruptedDays != null">
						<v-col cols="5" class="hra-text--greyscale-600 hra-text--12">
							{{ $t("{days} d. d. nepertraukiamos atostogos šiais metais", {days: 10}) }}
						</v-col>
						<v-col cols="7" v-if="vacation.uninterruptedDays">{{ $t("Jau pasiimta") }}</v-col>
						<v-col cols="7" v-else>{{ $t('Dar nepasiimta') }}</v-col>
					</template>
				</v-row>
				<v-col cols="12">
					<hr class="ma-0">
				</v-col>
				<v-col cols="12" v-if="vacation.message">
					<form-textarea :label="$t('Žinutė')" :value="vacation.message" is-view/>
				</v-col>
				<v-col cols="12">
					<form-textarea v-if="vacation.status === STATUS_WAIT" :label="$t('Atsakyti')"
					               v-model="formData.message"/>
					<form-textarea v-else-if="vacation.leaderMessage" :label="$t('Atsakyti')" :value="vacation.leaderMessage"
					               is-view/>
				</v-col>
				<v-col cols="12" v-if="vacation.attachment">
					<form-alert icon="file">
						<div class="d-flex justify-space-between align-center gap-3" style="width: 100%">
							<a target="_blank" :href="`/${vacation.attachment}`">{{ $t("Priedas") }}</a>
						</div>
					</form-alert>
				</v-col>
			</v-row>
		</template>
		<template v-if="!loading && vacation.status < STATUS_PAST" #footer>
			<hra-confirm :title="$t('Ar tikrai norite atmesti atostogų prašymą?')"
			             :abort-text="$t('Ne')"
			             :confirm-text="$t('Taip')"
			             @confirm="onAbort"
			>
				<hra-button :color="uiColor.dangerLight">
					<hra-icon :name="iconName.close"/>
					{{ $t("Atmesti") }}
				</hra-button>
			</hra-confirm>

			<hra-confirm v-if="vacation.status === STATUS_WAIT"
			             :title="$t('Ar tikrai norite patvirtinti atostogų prašymą?')"
			             :abort-text="$t('Ne')"
			             :confirm-text="$t('Taip')"
			             @confirm="onAccept"
			>
				<hra-button :color="uiColor.successLight">
					<hra-icon :name="iconName.ok"/>
					{{ $t("Tvirtinti") }}
				</hra-button>
			</hra-confirm>
		</template>
	</custom-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialogMixin";
import {mapGetters, mapMutations} from "vuex";
import CustomDialog from "@/components/ui/dialog/CustomDialog";
import FullRowLoader from "@/components/ui/FullRowLoader";
import EmployeeTableView from "@/components/ui/views/EmployeeTableView";
import HraButton from "@/components/ui/button/HraButton";
import HraIcon from "@/components/ui/HraIcon";
import HraConfirm from "@/components/ui/dialog/HraConfirm";
import FormAlert from "@/components/ui/form/FormAlert";
import FormTextarea from "@/components/ui/form/FormTextarea";
// import departmentVacationDetailService from "@/store/departments/departmentVacationDetailService";
// import departmentVacationChangeService from "@/store/departments/departmentVacationChangeService";
import VacationPeriodView from "@/components/ui/views/VacationPeriodView";
import {STATUS_ABORT, STATUS_APPROVE, STATUS_PAST, STATUS_WAIT} from "@/store/modules/employee/vacations";
import {vacationType} from "@/utils/default";
import departmentVacationsService from "@/domain/departmentVacations/services/departmentVacationsService";
import {detailVacations} from "@/domain/departmentVacations/store/getters";

export default {
	name: "VacationDepartmentDetailsModal",
	components: {
		VacationPeriodView,
		FormTextarea, FormAlert, HraConfirm, HraIcon, HraButton, EmployeeTableView, FullRowLoader, CustomDialog
	},
	mixins: [dialogMixin],
	props: {
		vacationId: {type: [Number, String], required: true},
		employeeUpdateView: {type: Boolean, default: false},
	},
	data: () => ({
		loading: true,
		eventLoading: false,
		formData: {
			message: "",
		},
		STATUS_WAIT,
		STATUS_ABORT,
		STATUS_PAST,
		STATUS_APPROVE,
		vacationType,
	}),
	computed: {
		...mapGetters({
			detailVacations: "departmentVacationsStore/detailVacations",
			// loading: "vacation/departmentDetails/loading",
			// eventLoading: "vacation/departmentDetails/eventLoading",
			// data: "vacation/departmentDetails/data",
			// message: "vacation/departmentDetails/message",
		}),
		// calculate() {
		// 	if (!this.vacation?.calculate) {
		// 		return null;
		// 	}
		//
		// 	return {
		// 		fromDate: this.vacation.calculate.fromDate,
		// 		balance: this.vacation.calculate.balance.toFixed(2),
		// 	}
		// },
		vacation() {
			return this.detailVacations(this.vacationId);
		},
		payload() {
			return {
				id: this.vacationId,
				message: this.formData.message,
			};
		},
		message() {
			if (this.vacation?.status === STATUS_PAST) {
				return {
					type: "info",
					text: this.$t('Atostogos yra įvykusios')
				};
			}

			if (this.vacation?.status === STATUS_APPROVE) {
				return {
					type: "success",
					text: this.$t('Atostogos yra patvirtintos')
				};
			}

			if (this.vacation?.status > STATUS_ABORT) {
				return {
					type: "error",
					text: this.$t("Atostogos yra atmestos")
				};
			}


			return null;
		},
	},
	async created() {
		await departmentVacationsService.getDetailVacation('', this.vacationId);
		// this.vacation = await departmentVacationDetailService.call(this, this.vacationId);
		this.loading = false;
		// this.$store.dispatch("vacation/departmentDetails/fetch", {id: this.vacationId});
	},
	methods: {
		...mapMutations({
			clear: "vacation/departmentDetails/clear"
		}),
		async onAbort(confirm) {
			this.scrollToDialogContentTop();
			await departmentVacationsService.abortVacation(`DEPARTMENT_VACATION_ABORT_${this.vacationId}_TAG`, this.vacationId);
			confirm.closeConfirm();
			this.closeWithDelay();
		},
		async onAccept(confirm) {
			this.scrollToDialogContentTop();
			await departmentVacationsService.approveVacation(`DEPARTMENT_VACATION_APPROVE_${this.vacationId}_TAG`,
				this.vacationId, this.formData.message);
			// await departmentVacationChangeService.call(this, this.vacationId, true, this.formData.message, this.employeeUpdateView);
			confirm.closeConfirm();
			this.closeWithDelay();
		},
	},
	destroyed() {
		this.clear();
	},
};
</script>
