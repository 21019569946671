<template>
    <step-container :title='$t("Terminų nustatymas")' class='document-sent-terms-step'>
        <v-col cols='12'>
            <form-checkbox
                class='mt-2'
                :is-checked='sendOptions.needEmployeesConfirm'
                @change='toggleNeedEmployeesConfirm'
            >
                <div class='pl-2'>{{ $t('Reikalingas darbuotojo patvirtinimas') }}</div>
            </form-checkbox>
        </v-col>
        <v-col v-if='sendOptions.needEmployeesConfirm' cols='12' md='4'>
            <datepicker-field
                name='dueAt'
                :label="$t('Susipažinti iki (įskaitytinai)')"
                :class="'mt-2'"
                :value='sendOptions.dueAt'
                :min-date='minDate'
                @input='handleChangeDueAt'
            />
        </v-col>
        <v-col v-if='sendOptions.needEmployeesConfirm' cols='12'>
            <form-checkbox
                :disabled='disabledReminder'
                :is-checked='sendOptions.useReminder'
                @change='handleToggleUseReminder'
            >
                <div class='pl-2'>{{ $t('Siųsti priminimą') }}</div>
            </form-checkbox>
            <div v-if='sendOptions.useReminder' class='document-sent-terms-step__reminder mt-3'>
                <text-field
                    type='number'
                    :value='sendOptions.reminderBeforeDays'
                    @input='handleReminderBeforeDays'
                >
                    <template #prepend-inner>
                        <div style='min-width: fit-content;' class='pr-1'>{{ $t('prieš dienų') }}:</div>
                    </template>
                </text-field>
                <datepicker-field
                    name='reminderAt'
                    :value='sendOptions.reminderAt'
                    :min-date='reminderAtMinDate'
                    :max-date='reminderAtMaxDate'
                    ignore-auto-change
                    @input='handleChangeReminderAt'
                />
            </div>
            <form-checkbox
                v-if='sendOptions.useReminder'
                class='mt-2'
                :is-checked='sendOptions.reminderEveryDay'
                @change='toggleReminderEveryDay'
            >
                <div class='pl-2'>
                    {{ $t('Kartoti nuo {fromDate} iki {toDate} (įskaitytinai) kiekvieną dieną', {
                    fromDate: sendOptions.reminderAt,
                    toDate: sendOptions.dueAt
                }) }}
                </div>
            </form-checkbox>
        </v-col>
    </step-container>
</template>

<script>
import StepContainer from '@/domain/documents/modals/items/documentSendModal/components/StepContainer.vue'
import TextField from '@/domain/fields/TextField.vue'
import DatepickerField from '@/domain/fields/DatepickerField.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import moment from 'moment'

export default {
    name: 'TermsStep',
    components: { FormCheckbox, DatepickerField, TextField, StepContainer },
    inject: ['storeNamespace'],
    data() {
        const dateFormat = 'YYYY-MM-DD'
        const today = moment()

        return {
            today,
            dateFormat,
            minDate: today.format(dateFormat),
            daysMinDebounce: null
        }
    },
    computed: {
        sendOptions() {
            return this.$store.getters[`${this.storeNamespace}/sendOptions`]
        },
        disabledReminder() {
            return this.sendOptions.dueAt == null || this.sendOptions.dueAt === this.minDate
        },
        reminderAtMaxDate() {
            return this.sendOptions.dueAt
        },
        reminderAtMinDate() {
            return this.today.clone().add(1, 'day').format(this.dateFormat)
        },
        maxDaysToRemind() {
            if (this.sendOptions.dueAt == null) {
                return 0
            }

            return moment(this.sendOptions.dueAt).diff(this.today.clone(), 'days') + 1
        }
    },
    methods: {
        handleChangeDueAt(date) {
            this.$store.dispatch(`${this.storeNamespace}/sendOptions/setDueAt`, date)

            if (this.sendOptions.dueAt === this.minDate) {
                this.$store.dispatch(`${this.storeNamespace}/sendOptions/setUseReminder`, false)
                return
            }

            this.handleReminderBeforeDays(this.sendOptions.reminderBeforeDays)
        },
        handleToggleUseReminder() {
            this.$store.dispatch(`${this.storeNamespace}/sendOptions/setUseReminder`, !this.sendOptions.useReminder)
        },
        handleReminderBeforeDays(value) {
            clearTimeout(this.daysMinDebounce)

            value = parseInt(value)
            if (isNaN(value)) {
                value = 0
            }

            if (value > this.maxDaysToRemind) {
                value = this.maxDaysToRemind
            }

            this.$store.dispatch(`${this.storeNamespace}/sendOptions/setReminderBeforeDays`, value)

            this.daysMinDebounce = setTimeout(() => {
                if (value < 0) {
                    value = 0
                }
                this.$store.dispatch(`${this.storeNamespace}/sendOptions/setReminderBeforeDays`, value)

                this.$store.dispatch(
                    `${this.storeNamespace}/sendOptions/setReminderAt`,
                    moment(this.sendOptions.dueAt).subtract(value, 'days').format(this.dateFormat)
                )
            }, 300)
        },
        handleChangeReminderAt(value) {
            this.$store.dispatch(`${this.storeNamespace}/sendOptions/setReminderAt`, value)
            const days = moment(this.sendOptions.dueAt)
                .diff(moment(value), 'days')
            this.$store.dispatch(`${this.storeNamespace}/sendOptions/setReminderBeforeDays`, days)
        },
        toggleReminderEveryDay() {
            this.$store.dispatch(`${this.storeNamespace}/sendOptions/setReminderEveryDay`, !this.sendOptions.reminderEveryDay)
        },
        toggleNeedEmployeesConfirm() {
            this.$store.dispatch(`${this.storeNamespace}/sendOptions/setNeedEmployeesConfirm`, !this.sendOptions.needEmployeesConfirm)
        }
    }
}
</script>

<style lang='scss' scoped>
.document-sent-terms-step {
    &__reminder {
        display: flex;
        gap: 10px;
        align-items: flex-start;

        & > .TextField {
            min-width: 160px;
            width: 160px;
        }

        & > .DatepickerField {
            min-width: 300px;
        }
    }

    .form-checkbox {
        max-width: fit-content;
    }
}
</style>