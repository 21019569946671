export default () => ({
    activePageId: null,
    categoryTitle: '',
    dkQuestionBalance: 0,
    hrQuestionBalance: 0,
    categories: [],
    comments: [],
    documents: [],
    questions: [],
    homeDkQuestions: [],
    homeHrQuestions: [],
});