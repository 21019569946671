import ElvisForm from "@/components/ui/form/ElvisForm";
import ElvisInput from "@/components/ui/form/ElvisInput";
import HraIcon from "@/components/ui/HraIcon";
import HraButton from "@/components/ui/button/HraButton";
import {mapGetters} from "vuex";
import {appRouteProps} from "@/router/app";
import Header from "@/components/ui/header/Header";
import TextDecorate from "@/components/ui/TextDecorate";
import CustomDialog from "@/components/ui/dialog/CustomDialog";
import HraConfirm from "@/components/ui/dialog/HraConfirm";
import HraButtonModal from "@/components/ui/button/HraButtonModal";
import HraPagination from "@/components/ui/pagination/HraPagination";
import ImportEmployeesManageTable from "@/views/tables/import/ImportEmployeesManageTable";
import importService from "@/services/employees/employeeImportService";

export default {
  components: {
    HraButtonModal, HraConfirm, CustomDialog, TextDecorate, Header, HraButton, HraIcon, ElvisInput,
    ElvisForm, HraPagination, ImportEmployeesManageTable
  },
  data: () => ({
    loaderName: "Import-Manage",
    limit: 10,
    tableLoading: false,
    storing: false,
  }),
  computed: {
    ...mapGetters({
      total: "employee/importData/total",
      headers: "employee/importData/headers",
      pageRows: "employee/importData/pageRows",
      page: "employee/importData/page",
      pages: "employee/importData/pages",
      rowIsEdit: "employee/importData/rowIsEdit",
      haveInvalidRow: "employee/importData/haveInvalidRow",
    }),
  },
  provide() {
    return {
      importService,
      onCreateEmptyRow: this.onCreateEmptyRow,
    };
  },
  async created() {
    await importService.init();
    await this.$store.dispatch("employee/importData/changePage", Number(this.$route.query.page || 1));
  },
  methods: {
    async onCreateEmptyRow() {
      try {
        await importService.createNewRow();
        this.onChangePage(1);
      } catch (e) {
        console.error(e);
      }
    },
    onChangePage(page) {
      this.$refs.table?.$refs.table?.scrollToTop();

      if (this.page !== page) {
        this.$store.dispatch("employee/importData/changePage", page);
        this.$router.push({query: {page}});
      }
    },
    async onClose(event) {
      await importService.clear();
      event.closeConfirm();
      await this.$router.push({name: appRouteProps.employees.name});
    },
    async onClear(event) {
      await importService.clear();
      event.closeConfirm();
    },
  },
};