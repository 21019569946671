<template>
    <hra-employee-crud-table>
        <hra-table :headers='headers' :rows='disabilities' :need-actions='!readOnly' hide-headers
                   class='no-border-around'>
            <template #header.custom>
                <table-title-view class='mb-3 size-1' :title="$t('Neįgalumai')" with-border />
            </template>

            <template #col.name>
                <div class='pe-3 py-6'>{{ $t('Darbuotojo neįgalumas') }}</div>
            </template>

            <template #col.fromDate='{colValue, row: {fromDate, toDate}}'>
                <div class='px-3 py-6'>{{ $t('Laikotarpis: {from} iki {to}', { from: fromDate, to: toDate }) }}</div>
            </template>

            <template #col.number='{colValue}'>
                <div class='px-3 py-6'>{{ $t('Darbo pažymėjimo nr.: {number}', { number: colValue }) }}</div>
            </template>

            <template #col.actions-base='{row}'>
                <hra-tooltip :text="$t('Redaguoti')">
                    <ui-button
                        size='small'
                        prepend-icon='edit'
                        :modal='{name: "EmployeeDisabilityModal", props: {service: onUpdate, payload: row}}'
                    />
                </hra-tooltip>
                <base-confirm
                    :title="$t('Ar tikrai norite ištrinti?')"
                    tag='EMPLOYEE_CHILD_DESTROY_TAG'
                    @confirm='onDelete($event, row.id)'
                >
                    <hra-tooltip :text="$t('Ištrinti')">
                        <ui-button size='small' background='danger-light' prepend-icon='bin' />
                    </hra-tooltip>
                </base-confirm>
            </template>

            <template #empty.text>{{ $t('Įrašų sistemoje pridėta nebuvo.') }}</template>
        </hra-table>
        <template #buttons>
            <ui-button
                v-if='!readOnly'
                size='small'
                background='primary'
                :modal='{name: "EmployeeDisabilityModal", props: {service: onCreate}}'
            >
                {{ $t('Pridėti') }}
            </ui-button>
        </template>
    </hra-employee-crud-table>
</template>

<script>
import HraEmployeeCrudTable from '@/components/ui/table/HraEmployeeCrudTable'
import HraTable from '@/components/ui/table/HraTable'
import TableTitleView from '@/components/ui/views/TableTitleView'
import { mapGetters } from 'vuex'
import UiButton from '@/domain/components/UiButton.vue'
import BaseConfirm from '@/domain/confirms/BaseConfirm.vue'
import HraTooltip from '@/components/ui/HraTooltip.vue'

export default {
    name: 'EmployeeDisabilitiesTable',
    components: {
        HraTooltip,
        BaseConfirm,
        UiButton,
        TableTitleView,
        HraTable,
        HraEmployeeCrudTable
    },
    props: {
        isProfile: { type: Boolean, default: false },
        isCreate: { type: Boolean, default: false },
        isEdit: { type: Boolean, default: false }
    },
    data() {
        return {
            headers: [{ name: 'name' }, { name: 'fromDate' }, { name: 'number' }],
            readOnly: this.isProfile
        }
    },
    computed: {
        ...mapGetters({
            disabilities: 'employeeStore/disabilities',
            userId: 'employeeStore/userId'
        })
    },
    methods: {
        async onCreate(tag, payload) {
            return await this.$store.dispatch('employeeStore/createDisability', { tag, payload, userId: this.userId })
        },
        async onUpdate(tag, payload, id) {
            return await this.$store.dispatch('employeeStore/updateDisability', { tag, id, payload })
        },
        async onDelete(confirm, id) {
            await this.$store.dispatch('employeeStore/destroyDisability', { id, tag: confirm.tag })
            confirm?.close()
        }
    }
}
</script>