import { query } from '@/utils/default'
import axios from 'axios'
import api from '@/plugins/api'

export default axios => ({

    sent: (payload) => axios.post(`/api/documents/sent`, payload),

    expirationTracking: {
        fetch: (params) => axios.get('/api/documents/expiration/tracking', { params }),
    },

    disk: {
        fetchSpace: () => axios.get('/api/documents/disk/space')
    },

    publicSign: {
        status: (token) => axios.get(`/api/documents/public/sign/${token}/status`),
        accept: (token, payload) => axios.post(`/api/documents/public/sign/${token}/accept`, payload),
        reject: (token, payload) => axios.post(`/api/documents/public/sign/${token}/reject`, payload),
    },

    sign: {
        fetch: (token) => axios.get(`/api/documents/sign?token=${token}`),
        acceptDocument: (token) => axios.put(`/api/documents/sign/accept?token=${token}`, { status: 'accepted' }),
        cancelDocument: (token, payload) => axios.put(`/api/documents/sign/cancel?token=${token}`, payload)
    },

    sentUsers: {
        fetchByDocumentId: (documentId, params) => axios.get(`/api/documents/${documentId}/sent-users`, { params }),
        preview: {
            begin: (id) => axios.post(`/api/documents/sent/${id}/users/preview/begin`),
            end: (id, payload) => axios.put(`api/documents/users/previews/${id}/end`, payload)
        },
        sign: {
            acceptDocument: (id) => axios.post(`/api/documents/sent/${id}/accept`),
            rejectDocument: (id, payload) => axios.post(`/api/documents/sent/${id}/reject`, payload)
        }
    },

    sends: {
        histories: (id, params) => axios.get(`/api/documents/sent/${id}/histories`, { params }),
        previews: (id, params) => axios.get(`/api/documents/sent/${id}/previews`, { params }),

        send: (payload) => axios.post(`/api/documents/sends`, payload),
        byDocumentId: {

            fetch: (documentId) => axios.get(`/api/documents/${documentId}/sends?with[]=users`),
            fetchLast: (documentId) => axios.get(`/api/documents/${documentId}/sends?last=1&with[]=users`),
            fetchEmployees: (documentId, params) => axios.get(`/api/documents/${documentId}/sends/employees`, { params }),
            cancel: (documentId) => axios.put(`/api/documents/${documentId}/sends/cancel`)
        },
        history: {
            fetch: params => axios.get('/api/documents/sends/history', { params })
        },
        template: (templateId) => axios.post(`/api/documents/sends/${templateId}`),
        repeat: (payload) => axios.post(`/api/documents/sends/repeat/template`, payload)
    },
    overview: {
        fetch: () => axios.get('/api/documents/overview'),
        status: () => axios.get('/api/documents/status')
    },
    statuses: {
        employeeStatuses: () => axios.get('/api/documents/employee_status'),
        fetch: () => axios.get('/api/documents/statuses')
    },
    employees: {
        fetchByFilter: (params) => axios.get(`/api/documents/sent/users-to-documents`, { params }),
        rejectDocument: (id, comment) => axios.put(`/api/documents/employees/${id}/reject`, { comment }),
        acceptDocument: (id, comment = null) => axios.put(`/api/documents/employees/${id}/accept`, { comment }),
        history: {
            fetch: (params, page) => {
                if (page) {
                    return axios.get(page)
                } else {
                    return axios.get('/api/documents/history' + (params ? params : ''))
                }
            },
            cancelDocument: (id, comment) => axios.put(`/api/documents/${id}/reject`, { comment })
        },
        search: (query) => {
            return axios.get(`/api/documents/history/search?query=${query}`)
        },
        filter: data => {
            return axios.get('/api/documents/history/filter/' + (query(data)))
        }
    },
    summary: {
        fetch: params => axios.get('/api/documents/summaries', { params }),
        fetchDocuments: params => axios.get(`/api/documents/summaries/documents`, { params }),
        fetchUsers: params => axios.get(`/api/documents/summaries/users`, { params }),


        fetchDocumentEmployees: (params) => axios.get(`/api/documents/summary/employees`, { params }),
        fetchDocumentEmployeesByDocumentId: (documentId, params) => axios.get(`/api/documents/summary/${documentId}/employees`, { params }),
        query: (url) => {
            if (url) {
                return axios.get(url)
            }
        },
        filter: data => {
            return axios.get('/api/documents/summary/filter/' + (query(data)))
        },
        search: (query, params, groupByDocument) => {
            if (params) {
                return axios.get(`/api/documents/summary/search?column=${params}&query=${query}`)
            }
            if (groupByDocument) {
                return axios.get(`/api/documents/summary/search?query=${query}&groupByDocument=true`)
            }
            return axios.get(`/api/documents/summary/search?query=${query}`)
        }
    },
    templates: {
        fetch: (params) => axios.get('/api/documents/send/templates', { params }),
        fetchTemplate: (templateId) => axios.get('/api/documents/templates/fetch/' + templateId),
        saveTemplate: (templateId, template) => axios.put('/api/documents/templates/' + templateId, template),
        delete: (id) => axios.delete('/api/documents/templates/' + id)
    },
    documents: {
        show: id => axios.get(`/api/documents/${id}/show`),
        archive: id => axios.post(`/api/documents/${id}/archive`),
        restore: (id, payload) => axios.post(`/api/documents/${id}/restore`, payload),
        // wipe: id => axios.delete(`/api/documents/${id}`),
        destroy: id => axios.delete(`/api/documents/${id}`),

        upload: payload => axios.post(`/api/documents/upload`, payload),

        fetchOptions: () => axios.get(`/api/documents/options`),

        edit: (id, payload) => axios.put(`/api/documents/${id}`, payload),
        move: (id, payload) => axios.put(`/api/documents/${id}/move`, payload),

        details: {
            fetch: id => axios.get(`/api/documents/${id}/details`),
            fetchShort: id => axios.get(`/api/documents/${id}/details/short`)
        },

        types: {
            fetch: () => axios.get(`/api/documents/types`)
        }
    },
    folders: {
        list: (parent_id, paginate, archive, search) => axios.get('/api/documents/folders/list' + (query({
            parent_id,
            paginate,
            archive,
            search
        }))),

        create: (payload) => axios.post(`/api/documents/folders`, payload),
        update: (id, payload) => axios.put(`/api/documents/folders/${id}`, payload),
        destroy: (id) => axios.delete(`/api/documents/folders/${id}`),

        edit: payload => axios.put(`/api/documents/folders/item`, payload),
        query: (url) => {
            if (url) {
                return axios.get(url)
            }
        },
        fetch: (params) => axios.get('/api/documents/items', { params }),
        fetchBreadcrumbs: (documentId = null) => axios.get(`/api/documents/items/breadcrumbs`, { params: { documentId } }),
        // fetch: (payload) => axios.get(`/api/documents/folders/` + query(payload)),
        // fetch: (folder_id) => axios.get(`/api/documents/folders/` + (folder_id ? query({ folder_id }) : '')),
        filter: data => {
            return axios.get('/api/documents/folders/filter/' + (query(data)))
        },
        search: (search, folderId) => {
            return axios.get(`/api/documents/folders/search?search=${search}${folderId ? `&folderId=${folderId}` : ''}`)
        },
        delete: (id) => axios.delete(`/api/documents/folders/` + id),
        move: payload => axios.put(`/api/documents/folders/files/move`, payload)
    },
    groups: {
        folders: {
            fetch: (id, params) => axios.get(`/api/documents/groups/folders/${(id ?? '')}`, { params }),
            create: (payload) => axios.post(`/api/documents/groups/folders`, payload),
            update: (id, payload) => axios.put(`/api/documents/groups/folders/${id}`, payload),
            delete: (id) => axios.delete(`/api/documents/groups/folders/${id}`)
        },
        fetchDocuments: (id, params) => axios.get(`/api/documents/groups/${id}/documents`, { params }),
        create: (payload) => axios.post(`/api/documents/groups`, payload),
        update: (id, payload) => axios.put(`/api/documents/groups/${id}`, payload),
        delete: (id) => axios.delete(`/api/documents/groups/${id}`)
    },
    archive: {
        fetch: (params) => axios.get('/api/documents/archive', { params })
    },
    history: {
        fetch: (params) => {
            if (page) {
                return axios.get(`${page}`)
            } else {
                return axios.get(`/api/documents/history/${id}`)
            }
        },
        query: (url) => {
            if (url) {
                return axios.get(url)
            }
        }
    },
    userBoard: {
        fetchWaiting: () => axios.get(`/api/documents/user/board/waiting`),
        fetchPending: () => axios.get(`/api/documents/user-board/pending`),
        fetchHistory: (params) => axios.get(`/api/documents/user-board/history`, { params }),
        sign: {
            approve: (payload) => axios.post(`/api/documents/user/board/sign/approve`, payload),
            reject: (payload) => axios.post(`/api/documents/user/board/sign/reject`, payload)
        }
    }
});