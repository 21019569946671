<template>
    <div class='content-tabs' ref='tabs'>
        <div class='content-tabs__header' ref='header' :class='headerClasses'>
            <div class='content-tabs__tabs'>
                <div v-for='tab in tabs' :key='tab.name' class='content-tabs__tab'
                     :class='{"active": activeTab === tab.name}'
                     @click='onChangeTab(tab.name)'>
                    <slot :name='"tab-" + tab.name' :tab-name='tab.name'></slot>
                </div>
            </div>
        </div>
        <div class='content-tabs__container'>
            <slot v-for='tab in tabs' :name='tab.name' v-if='tab.name === activeTab' />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContentTabs',
    props: {
        tabs: { type: Array, required: true },
        defaultTabName: { type: String, required: true },
        isSticky: { type: Boolean, default: false },
    },
    data() {
        return {
            activeTab: this.defaultTabName
        }
    },
    computed: {
        headerClasses() {
            return {
                'sticky': this.isSticky
            }
        }
    },
    methods: {
        onChangeTab(tab) {
            if (this.activeTab === tab) {
                return;
            }
            this.activeTab = tab;
            this.$emit('changeTab', tab);
        }
    }
}
</script>

<style lang='scss' scoped>
.content-tabs {
    width: 100%;

    &__header {
        padding-bottom: 30px;

        &.sticky {
            position: sticky;
            top: 0;
            z-index: 5;
        }
    }

    &__tabs {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 16px;
    }
}
</style>