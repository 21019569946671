<template>
	<v-row>
		<v-col cols="4">
			<text-field
				name="workPhone"
				:label="$t('Darbo telefono nr.')"
				editable
				@save="onSave"
				v-model="contact.workPhone"
			/>
		</v-col>
		<v-col cols="4">
			<text-field
				name="phone"
				:label="$t('Asmeninis telefono nr.')"
				editable
				@save="onSave"
				v-model="contact.phone"
			/>
		</v-col>
		<v-col cols="4">
			<text-field
				name="email"
				:label="$t('Asmeninis el. paštas.')"
				editable
				@save="onSave"
				v-model="contact.email"
			/>
		</v-col>
		<v-col cols="6">
			<text-field
				name="address"
				:label="$t('Gyvenamosios vietos gatvė ir namo nr.')"
				editable
				@save="onSave"
				v-model="contact.address"
			/>

		</v-col>
		<v-col cols="3">
			<text-field
				name="city"
				:label="$t('Miestas')"
				editable
				@save="onSave"
				v-model="contact.city"
			/>
		</v-col>
		<v-col cols="3">
			<text-field
				name="country"
				:label="$t('Valstybė')"
				editable
				@save="onSave"
				v-model="contact.country"
			/>
		</v-col>
	</v-row>
</template>

<script>
import DatepickerField from "@/domain/fields/DatepickerField.vue";
import TextField from "@/domain/fields/TextField.vue";

export default {
	name: "ImportEmployeeContact",
	components: {TextField, DatepickerField},
	props: {
		id: {type: String, required: true},
	},
	computed: {
		contact() {
			return this.$store.getters["setupStore/importEmployeeData"](this.id, "contact");
		},
	},
	methods: {
		onSave({saveComplete}) {
			saveComplete();
		},
	},
}
</script>