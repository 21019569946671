<template>
    <setting-module-item
        :title='title'
        :description='description'
        :is-edit='isEdit'
        @edit='handleEdit'
        @save='handleSave'
        @cancel='handleCancel'
    >
        <template #company-config>
            <setting-module-item-config-company>
                <setting-holiday-all-available-pay-types-form
                    :is-edit='isEdit'
                    :config='formData.company'
                />
            </setting-module-item-config-company>
        </template>

        <template #departments-config>
            <setting-module-item-more
                :is-edit='isEdit'
                :count='formData.departments.length'
                @addDepartment='handleAddDepartment'
            >
                <setting-module-item-config-department
                    v-for='(department) in formData.departments'
                    :key='department.uuid'
                    :department-ids='department.departmentIds'
                    :is-edit='isEdit'
                    @changeDepartments='($event) => handleChangeDepartment(department, $event)'
                    @removeDepartment='() => handleRemoveDepartment(department.uuid)'
                >
                    <setting-holiday-all-available-pay-types-form
                        :is-edit='isEdit'
                        :config='department'
                    />
                </setting-module-item-config-department>
            </setting-module-item-more>
        </template>
    </setting-module-item>
</template>

<script>
import SettingModuleItem from '@/domain/settings/components/SettingModuleItem.vue'
import TextField from '@/domain/fields/TextField.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import SettingModuleItemConfig from '@/domain/settings/components/SettingModuleItemConfig.vue'
import MultilineSelectField from '@/domain/components/MultilineSelectField.vue'
import SelectField from '@/domain/fields/SelectField.vue'
import SettingModuleItemMore from '@/domain/settings/components/SettingModuleItemMore.vue'
import settingModuleItemMixin from '@/domain/settings/mixins/settingModuleItemMixin'
import SettingHolidayAllAvailablePayTypesForm
    from '@/domain/settings/components/holiday/allAvailablePayTypes/ConfigForm.vue'
import SettingModuleItemConfigDepartment from '@/domain/settings/components/SettingModuleItemConfigDepartment.vue'
import SettingModuleItemConfigCompany from '@/domain/settings/components/SettingModuleItemConfigCompany.vue'
import { availableHolidayPayTypes, generateUuid } from '@/utils/default'
import { cloneDeep } from 'lodash'

export default {
    name: 'SettingHolidayAllAvailablePayTypes',
    mixins: [settingModuleItemMixin],
    components: {
        SettingModuleItemConfigCompany,
        SettingModuleItemConfigDepartment,
        SettingHolidayAllAvailablePayTypesForm,
        SettingModuleItemMore,
        SelectField,
        MultilineSelectField,
        SettingModuleItemConfig,
        FormCheckbox,
        TextField,
        SettingModuleItem
    },
    data() {
        const types = availableHolidayPayTypes.map(type => type.value)
        const defaultFormData = {
            types: types,
            defaultType: types[0],
            availableToChange: true
        }

        return {
            isEdit: false,
            defaultFormData,
            formData: {
                company: cloneDeep(defaultFormData),
                departments: []
            }
        }
    },
    mounted() {
        this.setFormDataFromSettings()
    },
    methods: {
        setFormDataFromSettings() {
            this.formData = {
                company: {
                    types: this.company.config.types,
                    defaultType: this.company.config.defaultType,
                    availableToChange: this.company.config.availableToChange
                },
                departments: this.departments.map(payload => ({
                    uuid: generateUuid(),
                    departmentIds: payload.departmentIds,
                    types: payload.config.types,
                    defaultType: payload.config.defaultType,
                    availableToChange: payload.config.availableToChange
                }))
            }
        },
        getFromFormDataCompanyPayload() {
            return {
                active: true,
                config: {
                    types: this.formData.company.types,
                    defaultType: this.formData.company.defaultType,
                    availableToChange: this.formData.company.availableToChange,
                }
            }
        },
        getFromFormDataDepartmentsPayload() {
            return this.formData.departments.map(payload => ({
                active: true,
                config: {
                    types: payload.types,
                    defaultType: payload.defaultType,
                    availableToChange: payload.availableToChange
                },
                departmentIds: payload.departmentIds
            }))
        },
        async handleSave() {
            await this.saveAction('holiday')
        }
    }
}
</script>