import modalMethodsMixin from '@/domain/mixins/modalMethodsMixin'
import documentsItemsSidebars from '@/domain/documents/items/mixins/documentsItemsSidebars'

export default {
    mixins: [modalMethodsMixin, documentsItemsSidebars],
    props: {
        item: { type: Object, required: true }
    },
    inject: ['withStatus', 'withControls', 'selectable', 'storeNamespace'],
    computed: {
        folder() {
            return this.item.item
        },
        isDeletable() {
            return this.folder.size === 0
        }
    },
    methods: {
        handleChangeFolder() {
            this.closeSidebar()
            this.$store.dispatch(`${this.storeNamespace}/folders/changeId`, this.folder.id)
        },
        handleOpenUpdateFolderModal() {
            this.closeSidebar()
            this.onOpenModal(
                'FolderUpdateModal',
                {
                    item: this.folder
                },
                {
                    folderUpdated: (payload) => {
                        if (this.storeNamespace !== 'documentsItemsRootStore') {
                            this.$store.dispatch(`documentsItemsRootStore/folders/update`, payload)
                        }

                        this.$store.dispatch(`${this.storeNamespace}/folders/update`, payload)
                    }
                }
            )
        },
        handleOpenDeleteFolderModal() {
            this.closeSidebar()
            this.onOpenModal(
                'FolderDeleteModal',
                {
                    id: this.folder.id
                },
                {
                    folderDeleted: (payload) => {
                        if (this.storeNamespace !== 'documentsItemsRootStore') {
                            this.$store.dispatch(`documentsItemsRootStore/folders/delete`, payload)
                        }

                        this.$store.dispatch(`${this.storeNamespace}/folders/delete`, payload)
                    }
                }
            )
        }
    }
}