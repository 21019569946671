<template>
    <custom-dialog :max-width='900' ref='dialog' has-custom-footer>
        <template #title>{{ $t('Atostogų prašymas v2') }}</template>
        <template #content>
            <div
                class='d-flex flex-row pa-5 pa-md-7'
                :class='{
                    "flex-row": !isBondMobile,
                    "flex-column": isBondMobile,
                    "align-center": isBondMobile
                }'
            >
                <vacation-request-calendars />
                <content-tabs :tabs='tabs' :default-tab-name='tabs[1].name' class='vacation-request'>
                    <template v-for='tab in tabs' v-slot:[`tab-${tab.name}`]>
                        <ui-button :prepend-icon='tab.icon'>{{ tab.text }}</ui-button>
                    </template>

                    <template v-slot:request>
                        <vacation-request-form/>
                    </template>
                    <template v-slot:approvals>
                        <vacation-request-approvals :approvals=approvalsData :show-progress=showProgress>
                            <template #title>
                                <div class='approvals-title'>
                                    <h4>{{ $t('Atostogų tvirtinimo lygiai') }} <span>{{ approvalsData.orders.length }}</span></h4>
                                    <hra-tooltip
                                        :text="$t('Tekstas')"
                                    >
                                        <ui-icon>message-info</ui-icon>
                                    </hra-tooltip>
                                </div>
                            </template>
                        </vacation-request-approvals>
                    </template>
                </content-tabs>
            </div>
        </template>
        <template #footer>
            <ui-button
                prepend-icon='close'
                @click='close'
                background='default'
            >{{ $t('Uždaryti') }}
            </ui-button>

            <base-confirm
                v-if='buttonActions.abort'
                :title="$t('Ar tikrai norite atmesti atostogas?')"
                @confirm='onAbort'
            >
                <ui-button
                    prepend-icon='close'
                    background='danger'
                    :disabled='disabledSubmit'
                >{{ $t('Atšaukti') }}
                </ui-button>
            </base-confirm>

            <base-confirm
                v-if='buttonActions.change'
                :tag='VACATION_MODAL_CREATE_TAG'
                no-header
                @abort='close'
                @confirm='onChange'
            >
                <template #content>
                    <div class='pt-9 pb-6 text-center'>
                        {{
                            $t('Atostogos šiomis dienomis jau yra patvirtintos. Jei norite jas pakeisti, šios bus atšauktos ir sukurtos naujos.')
                        }}
                    </div>
                </template>

                <ui-button
                    prepend-icon='ok'
                    background='primary'
                    :disabled='disabledSubmit'
                >{{ $t('Pakeisti') }}
                </ui-button>
            </base-confirm>

            <ui-button
                v-if='buttonActions.update'
                prepend-icon='ok'
                @click='onUpdate'
                background='primary'
                :disabled='disabledSubmit'
            >{{ $t('Pakeisti') }}
            </ui-button>

            <ui-button
                v-if='buttonActions.create'
                prepend-icon='ok'
                @click='onCreate'
                background='primary'
                :disabled='disabledSubmit'
            >{{ $t('Siųsti prašymą') }}
            </ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import dialogMixin from '@/mixins/dialogMixin'
import {
    VACATION_MODAL_ABORT_TAG,
    VACATION_MODAL_CHANGE_TAG,
    VACATION_MODAL_CREATE_TAG,
    VACATION_MODAL_INIT_TAG,
    VACATION_MODAL_UPDATE_TAG
} from '@/domain/vacationModal/tags'
import { EVENT_HOLIDAY_TYPE_YEARLY, jsonToFormData } from '@/utils/default'
import HraIcon from '@/components/ui/HraIcon.vue'
import UiIcon from '@/domain/components/UiIcon.vue'
import HraButton from '@/components/ui/button/HraButton.vue'
import { mapGetters } from 'vuex'
import customApi from '@/plugins/customApi'
import i18n from '@/plugins/i18n'
import BaseConfirm from '@/domain/confirms/BaseConfirm.vue'
import UiButton from '@/domain/components/UiButton.vue'
import modalMethodsMixin from '@/domain/mixins/modalMethodsMixin'
import VacationRequestForm from '@/domain/vacationModal/components/VacationRequestForm.vue'
import VacationRequestCalendars from '@/domain/vacationModal/components/VacationRequestCalendars.vue'
import VacationRequestApprovals from '@/domain/vacationModal/components/VacationRequestApprovals.vue'
import HraTooltip from '@/components/ui/HraTooltip'
import ContentTabs from '@/domain/components/ContentTabs.vue'
import isBondMobileMixin from '@/mixins/isBondMobileMixin'

export default {
    name: 'VacationRequestModal',
    components: {
        UiButton,
        BaseConfirm,
        HraButton, HraIcon, UiIcon,
        HraTooltip,
        CustomDialog,
        ContentTabs,
        VacationRequestForm,
        VacationRequestCalendars,
        VacationRequestApprovals
    },
    mixins: [dialogMixin, modalMethodsMixin, isBondMobileMixin],
    props: {
        asAdmin: { type: Boolean, default: false },
        userId: { type: [Number, String], required: true },
        eventId: { type: [Number, String], default: null },
        eventType: { type: Number, default: EVENT_HOLIDAY_TYPE_YEARLY },
        start: { type: String, default: null },
        end: { type: String, default: null },
        showProgress: { type: Boolean, default: false }
    },
    data() {
        return {
            checkTimeout: null,
            checkWatcher: null,
            VACATION_MODAL_CREATE_TAG,
            tabs: [
                { name: 'request', text: this.$t('Atostogų prašymas'), icon: 'message-info' },
                { name: 'approvals', text: this.$t('Tvirtinantys gavėjai'), icon: 'message-info' },
            ],
            approvalsData: {
                "id": 260,
                "status": "pending",
                "orders": [
                    {
                        "id": 230,
                        "order": 1,
                        "minToApprove": 1,
                        "status": "approved",
                        "invites": [
                        {
                            "id": 230,
                            "userId": 8,
                            "status": "approved",
                            "message": "",
                            "user": {
                                "id": 8,
                                "name": "Helena Vasiliauskaitė",
                                "email": "helena.vasiliauskaite@vz-demo.bond.demo",
                                "departmentId": 3,
                                "department": {
                                    "id": 3,
                                    "name": "Gamyba"
                                },
                                "dutyId": 6,
                                "duty": {
                                    "id": 6,
                                    "name": "Vyr. surinkėjas"
                                }
                            }
                        }
                        ]
                    },
                    {
                        "id": 231,
                        "order": 2,
                        "minToApprove": 1,
                        "status": "pending",
                        "invites": [
                        {
                            "id": 231,
                            "userId": 8,
                            "status": "aborted",
                            "message": "",
                            "user": {
                                "id": 8,
                                "name": "Helena Vasiliauskaitė",
                                "email": "helena.vasiliauskaite@vz-demo.bond.demo",
                                "departmentId": 3,
                                "department": {
                                    "id": 3,
                                    "name": "Gamyba"
                                },
                                "dutyId": 6,
                                "duty": {
                                    "id": 6,
                                    "name": "Vyr. surinkėjas"
                                }
                            }
                        },
                        {
                            "id": 241,
                            "userId": 1,
                            "status": "approved",
                            "message": "",
                            "user": {
                            "id": 1,
                            "name": "Bernardinas Pocius",
                            "email": "admin@bond.test.dev",
                            "departmentId": 1,
                            "department": {
                                "id": 1,
                                "name": "VZ DEMO"
                            },
                            "dutyId": 1,
                            "duty": {
                                "id": 1,
                                "name": "Generalinis direktorius"
                            }
                            }
                        },
                        {
                            "id": 242,
                            "userId": 1,
                            "status": "skip",
                            "message": "",
                            "user": {
                            "id": 1,
                            "name": "Bernardinas Pocius",
                            "email": "admin@bond.test.dev",
                            "departmentId": 1,
                            "department": {
                                "id": 1,
                                "name": "VZ DEMO"
                            },
                            "dutyId": 1,
                            "duty": {
                                "id": 1,
                                "name": "Generalinis direktorius"
                            }
                            }
                        }
                        ]
                    },
                    {
                        "id": 271,
                        "order": 1,
                        "minToApprove": 1,
                        "status": "",
                        "invites": [
                        {
                            "id": 272,
                            "userId": 9,
                            "status": "",
                            "message": "",
                            "user": {
                                "id": 9,
                                "name": "Zita Urbonienė",
                                "email": "zita.urboniene@vz-demo.bond.demo",
                                "departmentId": 5,
                                "department": {
                                    "id": 5,
                                    "name": "Surinkimas"
                                },
                                "dutyId": 7,
                                "duty": {
                                    "id": 7,
                                    "name": "Surinkėjas"
                                }
                            }
                        },
                        {
                            "id": 273,
                            "userId": 9,
                            "status": "",
                            "message": "",
                            "user": {
                                "id": 9,
                                "name": "Zita Urbonienė",
                                "email": "zita.urboniene@vz-demo.bond.demo",
                                "departmentId": 5,
                                "department": {
                                    "id": 5,
                                    "name": "Surinkimas"
                                },
                                "dutyId": 7,
                                "duty": {
                                    "id": 7,
                                    "name": "Surinkėjas"
                                }
                            }
                        }
                        ]
                    },
                ]
            },
        }
    },
    computed: {
        ...mapGetters({
            hasLoading: 'loadersStore/hasLoading',
            buttonActions: 'vacationModalStore/buttonActions'
        }),
        disabledSubmit() {
            if (this.hasLoading(VACATION_MODAL_CREATE_TAG) ||
                this.hasLoading(VACATION_MODAL_UPDATE_TAG) ||
                this.hasLoading(VACATION_MODAL_CHANGE_TAG) ||
                this.hasLoading(VACATION_MODAL_ABORT_TAG)) {
                return true
            }

            return this.errorMessages != null
        },
    },
    created() {
        this.$store.dispatch('vacationModalStore/init', {
            tag: VACATION_MODAL_INIT_TAG,
            asAdmin: this.asAdmin,
            userId: this.userId,
            eventId: this.eventId,
            eventType: this.eventType,
            start: this.start,
            end: this.end
        })

        this.checkWatcher = this.$store.watch((state) => {
            const { id, type, start, end, deputies } = state.vacationModalStore.currentEvent
            const asAdmin = state.vacationModalStore.asAdmin

            return { eventId: id, type, start, end, deputies, asAdmin }
        }, (payload) => {
            if (payload.start == null) {
                return
            }

            clearTimeout(this.checkTimeout)
            this.checkTimeout = setTimeout(async () => {
                await this.$store.dispatch('vacationModalStore/check', { userId: this.userId, payload })
            }, 300)
        })
    },
    beforeDestroy() {
        this.checkWatcher()
        this.$store.dispatch('vacationModalStore/clear')
    },
    methods: {
        viewConfirm(payload, action, abortedAction = () => {}) {
            this.$store.dispatch('modals/confirm', {
                props: {
                    maxWidth: 550,
                    title: payload.title,
                    content: '<p class="text-left">' + payload.messages.join('<br>') + '</p>',
                    abortText: this.$t('Uždaryti'),
                    confirmText: this.$t('Siųsti prašymą'),
                    hideConfirm: !payload.canSentRequest
                },
                on: {
                    confirmed: (confirm) => {
                        confirm.close()
                        action()
                    },
                    aborted: (confirm) => {
                        confirm.close()
                        abortedAction()
                    }
                }
            })
        },
        getPayload(skipConfirm) {
            return jsonToFormData({
                asAdmin: this.$store.getters['vacationModalStore/asAdmin'],
                sendInviteForEmployeeAndLeader: this.$store.getters['vacationModalStore/sendInviteForEmployeeAndLeader'],
                userId: this.userId,
                skipConfirm: skipConfirm ? 1 : 0,
                ...this.$store.getters['vacationModalStore/currentEvent']
            })
        },
        async createEvent(skipConfirm = false) {
            const tag = VACATION_MODAL_CREATE_TAG
            const api = customApi(tag)
            const result = await api.request(api.factories.employee.holidays.create(this.getPayload(skipConfirm)))
            await this.$store.dispatch('loadersStore/hideLoader', tag)

            if (result == null) {
                return
            }
            const { confirm, event } = result;

            if (confirm != null) {
                this.viewConfirm(confirm, () => this.createEvent(true))
                return
            }

            if (event == null) {
                return
            }

            await this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag, message: i18n.t('Sėkmingai išsiųstas')
            })

            await this.$store.dispatch('employeeStore/createEvent', event)
            this.$emit('changed')
            this.close()
        },
        async changeEvent(baseConfirm, skipConfirm = false) {
            const tag = VACATION_MODAL_CREATE_TAG
            const api = customApi(baseConfirm.tag)
            const result = await api.request(api.factories.employee.holidays.update(this.eventId, this.getPayload(skipConfirm)))
            await this.$store.dispatch('loadersStore/hideLoader', tag)

            if (result == null) {
                return
            }
            const { confirm, event } = result;

            if (confirm != null) {
                this.viewConfirm(confirm, () => this.changeEvent(baseConfirm, true), () => baseConfirm.close())
                return
            }

            if (event == null) {
                return
            }

            await this.$store.dispatch('loadersStore/hideLoader', tag)
            await this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag, message: i18n.t('Sėkmingai išsiųstas')
            })
            await this.$store.dispatch('employeeStore/changeEvent', event)
            this.$emit('changed')
            this.close()
        },
        async updateEvent(skipConfirm = false) {
            const tag = VACATION_MODAL_CREATE_TAG
            const api = customApi(tag)
            const result = await api.request(api.factories.employee.holidays.update(this.eventId, this.getPayload(skipConfirm)))
            await this.$store.dispatch('loadersStore/hideLoader', tag)

            if (result == null) {
                return
            }

            const { confirm, event } = result;
            if (confirm != null) {
                this.viewConfirm(confirm, () => this.updateEvent(true))
                return
            }

            if (event == null) {
                return
            }

            await this.$store.dispatch('loadersStore/hideLoader', tag)
            await this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag, message: i18n.t('Sėkmingai išsiųstas')
            })
            await this.$store.dispatch('employeeStore/updateEvent', event)
            this.$emit('changed')
            this.close()
        },
        onCreate() {
            this.createEvent()
        },
        onChange(confirm) {
            this.changeEvent(confirm)
        },
        onUpdate() {
            this.updateEvent()
        },
        async onAbort(confirm) {
            const api = customApi(confirm.tag)
            const event = this.asAdmin
                ? await api.request(api.factories.department.vacation.admin.abort(this.eventId, { message: '' }))
                : await api.request(api.factories.employee.holidays.abort(this.eventId, { message: '' }))
            await this.$store.dispatch('loadersStore/hideLoader', confirm.tag)
            await this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag: confirm.tag,
                message: this.$t('Sėkmingai atšauktas.')
            })
            await this.$store.dispatcolch('employeeStore/abortEvent', event)
            this.close()
        },
    }
}
</script>

<style lang='scss' scoped>
@import '@/assets/sass/core/colors';

.approvals-title {
    display: flex;
    align-items: center;
    color: #70778C;

    h4 {
        color: $greyscale-800;
        font-size: 18px;
        line-height: 1.1;
        font-weight: 600;
        margin-right: 12px;

        span {
            color: $pink;
        }
    }
}
.vacation-request {
    .content-tabs__tab {
        &.active {
            button {
                border: 1px solid $pink;
                background-color: $white;
            }
        }

        button {
            border-radius: 30px;
        }
    }
}

</style>