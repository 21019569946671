const state = () => ({
  rows: [],
  headers: [],
  departments: [],
  duties: [],
  editRow: null,
  page: 1,
  limit: 10,
});

const getters = {
  total: state => state.rows.length,
  headers: state => state.headers.map(header => ({
    ...header,
    style: {minWidth: "250px"},
  })),
  pageRows: state => {
    const from = (state.page - 1) * state.limit;
    const to = from + state.limit;
    return state.rows.map(row => {
      Object.keys(row).forEach(key => {
        if (row[key].multiple != null) {
          row[key].multiple = !!row[key].multiple;
        }
      });

      return row;
    }).slice(from, to);
  },
  editRow: state => state.editRow,
  rowIsEdit: state => state.editRow != null,
  haveInvalidRow: state => state.rows
    .filter(row => Object.values(row).filter(item => typeof item === "object" && !item.valid).length > 0)
    .length > 0,
  page: state => state.page,
  pages: (state, getters) => Math.ceil(getters.total / state.limit),
  localStoreData: state => ({
    rows: state.rows,
    headers: state.headers,
    departments: state.departments,
    duties: state.duties,
  }),
  storeData: state => {
    return {
      rows: state.rows.map(row => {
        let cols = {};

        Object.keys(row).forEach(name => {
          cols[name] = row[name].value;
        })

        return cols;
      }),
      departments: state.departments,
      duties: state.duties,
    }
  },
  departmentOptions: state => state.departments.map(option => ({
    ...option,
    text: option.text,
  })),
  dutyOptions: state => state.duties.map(option => ({
    ...option,
    text: option.text,
  })),
  rowById: state => id => state.rows.find(row => row.id === id),
};

const actions = {
  init: ({commit}, payload) => {
    const superAdminEmail = payload.superAdminEmail;

    commit("initHeaders", payload.headers || []);
    commit("initDepartments", payload.departments || []);
    commit("initDuties", payload.duties || []);

    const rows = (payload.rows || []).map(row => {
      const isSuperAdmin = superAdminEmail != null && row.email.value === superAdminEmail;

      if (isSuperAdmin) {
        row.email.disabled = true;
      }

      return {
        ...row,
        isSuperAdmin,
      };
    });

    commit("initRows", rows);
  },
  createDepartment: ({commit}, payload) => {
    commit("addDepartment", payload);
  },
  createDuty: ({commit}, payload) => {
    commit("addDuty", payload);
  },
  addNewRow: ({commit}, payload) => {
    commit("addRow", payload);
  },
  startEditRow: ({commit}, payload) => {
    commit("startEditRow", payload);
  },
  cancelEditRow: ({commit}, payload) => {
    commit("cancelEditRow", payload);
  },
  changeRow: ({commit}, payload) => {
    commit("updateRow", payload);
  },
  updateRow: ({commit}, payload) => {
    commit("updateRow", payload);
    commit("cancelEditRow", payload.id);
  },
  removeRow: ({commit}, payload) => {
    commit("removeRow", payload);
  },
  changePage: ({commit}, payload) => {
    commit("changePage", payload);
  },
  clear: ({commit}) => {
    commit("initHeaders", []);
    commit("initDepartments", []);
    commit("initDuties", []);
    commit("initRows", []);
  },
};

const mutations = {
  initHeaders: (state, payload) => state.headers = payload,
  initDepartments: (state, payload) => state.departments = payload,
  initDuties: (state, payload) => state.duties = payload,
  initRows: (state, payload) => state.rows = payload.map(row => ({...row, edit: false})),
  addRow: (state, payload) => state.rows.unshift({...payload, edit: false}),
  updateRow: (state, payload) => state.rows = state.rows.map(row => row.id === payload.id ? {...row, ...payload} : row),
  removeRow: (state, payload) => state.rows = state.rows.filter(row => row.id !== payload),
  startEditRow: (state, payload) => {
    state.rows = state.rows.map(row => row.id === payload ? ({...row, edit: true}) : row);
    state.editRow = payload;
  },
  cancelEditRow: state => {
    state.rows = state.rows.map(row => ({...row, edit: false}));
    state.editRow = null;
  },
  changePage: (state, payload) => state.page = payload,
  addDepartment: (state, payload) => state.departments.unshift({value: payload, text: payload}),
  addDuty: (state, payload) => state.duties.unshift({value: payload, text: payload}),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}