<template>
    <ui-table-head-filter
        ref='Filter'
        :title='title'
        :column='column'
        :sort-options='sortOptions'
        :active='isActive'
        :min-width='minWidth'
        title-sorting-icon-name='sorting-funnel'
        v-model='currentFilter'
        @changeFilter='handleChangeFilter'
        @clearFilter='handleClearFilter'
    >
        <template #prepend.filter>
            <div class='d-flex flex-column gap-2 pa-2'>
                <datepicker-field
                    v-if='interval'
                    :label='$t("Data nuo")'
                    :value='currentFilter.from'
                    can-clear
                    @input='handleChangeDateFrom'
                />
                <datepicker-field
                    v-if='interval'
                    :label='$t("Data iki")'
                    :value='currentFilter.to'
                    can-clear
                    @input='handleChangeDateTo'
                />
                <datepicker-field
                    v-if='!interval'
                    ignore-auto-change
                    :label='$t("Data")'
                    :min-date='minDate'
                    :value='currentFilter.date'
                    @input='handleChangeDate'
                />
            </div>
        </template>
    </ui-table-head-filter>
</template>

<script>
import UiTableHeadFilter from '@/domain/components/uiTable/UiTableHeadFilter.vue'
import i18n from '@/plugins/i18n'
import filterEmployeesAllMixin from '@/domain/filters/mixins/filterEmployeesAllMixin'
import DatepickerField from '@/domain/fields/DatepickerField.vue'

export default {
    name: 'TableHeaderDateFilter',
    components: { DatepickerField, UiTableHeadFilter },
    mixins: [filterEmployeesAllMixin],
    props: {
        title: { type: String, required: true },
        column: { type: String, required: true },
        interval: { type: Boolean, default: false },
        minDate: { type: String, default: null },
        filter: { type: Object, default: () => ({ date: null, from: null, to: null, sort: null }) },
        minWidth: {type: [String,Number], default: 100}
    },
    data() {
        return {
            sortOptions: [
                { value: 'desc', label: this.$t('Naujausi viršuje') },
                { value: 'asc', label: this.$t('Seniausi viršuje') }
            ],
            currentFilter: {
                selected: this.filter.selected,
                sort: this.filter.sort,
                from: this.filter.from,
                to: this.filter.to,
                date: this.filter.date
            }
        }
    },
    watch: {
        filter: {
            handler(filter) {
                this.currentFilter = {
                    from: filter.from,
                    to: filter.to,
                    date: filter.date,
                    selected: filter.selected,
                    sort: filter.sort
                }
            },
            deep: true
        }
    },
    computed: {
        calendarMinDate() {
            const menu = this.$refs.Filter?.menu ?? false
            console.debug('menu', menu)
            if (menu) {
                return this.minDate
            }

            return null
        },
        isActive() {
            return this.currentFilter.from != null || this.currentFilter.to != null || this.currentFilter.date != null
        }
    },
    methods: {
        handleChangeFilter(column, filter) {
            this.$emit('changeFilter', column, filter)
        },
        handleClearFilter() {
            this.$set(this.currentFilter, 'from', null)
            this.$set(this.currentFilter, 'to', null)
            this.$set(this.currentFilter, 'date', null)
        },
        handleChangeDateFrom(value) {
            this.$set(this.currentFilter, 'from', value)
            this.$refs.Filter.changedFilter = true
        },
        handleChangeDateTo(value) {
            this.$set(this.currentFilter, 'to', value)
            this.$refs.Filter.changedFilter = true
        },
        handleChangeDate(value) {
            console.debug('handleChangeDate', this.currentFilter.date, value)
            this.$set(this.currentFilter, 'date', value)
            this.$refs.Filter.changedFilter = true
        }
    }
}
</script>