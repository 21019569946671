export default () => ({
  importEmployees: [],
  importDepartmentOptions: [],
  importDutyOptions: [],
  importData: {
    employees: [],
    contracts: [],
    disabilities: [],
    child: [],
    vacations: [],
  }
});