<template>
    <div class='schedule-interval px-6 d-flex align-center gap-4' :style='{height: `${eventRowHeight}px`}'>
        <form-checkbox
            name='selectedTimeInterval'
            :is-checked.sync='scheduleBreak.checked'
            @change='onToggle'
        >
            <div class='ml-3'>
                {{ $t('Po') }}: {{ scheduleBreak.after }} {{ $t('min') }}, {{ $t('trukmė') }}: {{ scheduleBreak.duration }} {{ $t('min') }}
            </div>
        </form-checkbox>
        <div class='d-flex'>
            <ui-button
                class='pa-2'
                prepend-icon='edit'
                size='normal'
                only-hover-background
                :modal='{name: "ScheduleBreakModal", props: {payload: scheduleBreak}}'
            />
            <ui-button
                class='pa-2'
                prepend-icon='bin'
                size='normal'
                hover-color='danger'
                only-hover-background
                :confirm="{
                    props: {title: $t('Ar tikrai norite ištrinti pertrauką?')},
                    on: {confirmed: onDeleteRow}
                }"
            />
        </div>
    </div>
</template>

<script>
import VueDraggableResizable from 'vue-draggable-resizable'
import { GLOBAL_TOAST_TAG } from '@/utils/default'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import FormRadio from '@/components/ui/form/FormRadio.vue'
import UiButton from '@/domain/components/UiButton.vue'

export default {
    name: 'ScheduleBreakLine',
    components: { FormCheckbox, FormRadio, UiButton, VueDraggableResizable },
    props: {
        scheduleBreak: { type: Object, required: true }
    },
    inject: ['eventRowHeight'],
    methods: {
        onToggle() {
            this.$store.dispatch('schedulerStore/toggleBreakById', this.scheduleBreak.id)
        },
        async onDeleteRow(confirm) {
            if (!await this.$store.dispatch('schedulerStore/deleteBreakById', this.scheduleBreak.id)) {
                await this.$store.dispatch('messagesStore/showErrorMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: 'Break delete error'
                })

                return
            }

            await this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag: GLOBAL_TOAST_TAG,
                message: this.$t('Break delete success')
            })

            confirm.close()
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/sass/core/colors';

.schedule-interval {
    background-color: $white;
    border-bottom: 1px solid $schedule-border-primary;

    .ui-button {
        min-height: auto !important;
    }
}

.form-radio,
.form-checkbox {
    &__container {
        font-size: 14px !important;
    }
}
</style>