<template>
	<div>{{ viewValue }}</div>
</template>

<script>
export default {
	name: "ReadOnlyField",
	props: {
		invalid: {type: Boolean, default: false},
		value: {type: [Number, String], default: null},
	},
	inject: ["rules"],
	computed: {
		viewValue() {
			if (this.rules.indexOf('required') > -1 && this.value == null) {
				return "?";
			}

			return this.value;
		},
	},
}
</script>