<template>
  <Auth>
    <template #header>{{ $t('Įveskite patvirtinimo kodą') }}</template>
    <template #form><TwoFactorForm /></template>
  </Auth>
</template>

<script>
import Auth from "@/components/Auth.vue";
import TwoFactorForm from "@/views/forms/auth/TwoFactorForm.vue";

export default {
  components: {
    Auth,
    TwoFactorForm,
  },
};
</script>
