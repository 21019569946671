import { render, staticRenderFns } from "./FormRemoteWork.vue?vue&type=template&id=15d08b88&scoped=true&"
import script from "./FormRemoteWork.vue?vue&type=script&lang=js&"
export * from "./FormRemoteWork.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15d08b88",
  null
  
)

export default component.exports