<template>
	<div :class="classes" ref="message">
		<div class="message-alert__container">
			<i
				class="message-alert__icon"
				:class="iconClass"
			/>
			<div class="message-alert__message" v-html="message"/>
		</div>
	</div>
	<!--	<div-->
	<!--		class="message-alert"-->
	<!--		:class="classes"-->
	<!--	>-->
	<!--		<i-->
	<!--			class="message-alert__icon"-->
	<!--			:class="iconClass"-->
	<!--		/>-->
	<!--		<div class="message-alert__message" v-html="message"/>-->
	<!--	</div>-->
</template>

<script>
import messageMixin from "@/domain/messages/mixins/messageMixin";

export default {
	name: "MessageAlert",
	mixins: [messageMixin],
	computed: {
		classes() {
			return {
				'message-alert': true,
				[`message-alert--${this.type}`]: true,
				'message-alert--shown': this.shown,
				'message-alert--hidden': !this.shown,
				'message-alert--border-radius': !this.noBorderRadius,
			};
		},
	},
	mounted() {
		// const {height} = this.$refs.message.getBoundingClientRect();
		// this.$refs.message.style.height = `${height}px`;
	},
	destroyed() {
	}
}
</script>

<style lang="scss">
@import "@/assets/sass/core/vars";

.message-alert {
	font-family: 'Public Sans', sans-serif;
	width: 100%;
	overflow: hidden;
	//max-height: 0;
	//transition-property: max-height;
	//transition-timing-function: linear;
	//transition-duration: 100ms;
	//transition: max-height 0.25s ease-in;

	&--shown {
		//max-height: 1000px;
		//transition-property: max-height;
		//transition-timing-function: ease-in;
		//transition: max-height 0.35s ease-out;
	}

	&--hidden {
		//max-height: 0;
	}

	&__container {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		padding: 15px;
	}

	&--border-radius {
		border-radius: 3px;
	}

	&__icon {
		width: 20px;
		height: 20px;
		font-size: 20px;
		line-height: 20px;
		margin-right: 12px;
	}

	&__message {
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
		display: flex;
		flex-direction: column;
		gap: 4px;
		width: 100%;
	}

	&--error {
		background: $red-light;
		color: $red;
	}

	&--success {
		background: $green-light;
		color: $green;
	}

	&--info {
		background: $blue-light;
		color: $black-800;
	}

	&--warning {
		background: $alert-warning-background;
		color: $alert-warning-text;
	}

	&--default {
		background: $greyscale-200;
		color: $black-800;
	}
}
</style>