export default axios => ({
  departments: {
    fetch: () => axios.get(`/api/departments`),
    fetchById: (id) => axios.get(`/api/departments/${id}`),
    tree: () => axios.get(`/api/departments/tree`),
    create: (payload) => axios.post(`/api/departments`, payload),
    update: (id, payload) => axios.put(`/api/departments/${id}`, payload),
    destroy: (id) => axios.delete(`/api/departments/${id}`),
    leaders: {
      change: (id, payload) => axios.put(`/api/v2/companies/departments/${id}/leaders`, payload),
    },
    employees: {
      add: (payload) => axios.post(`/api/departments/employee`, payload),
      change: (payload) => axios.post(`/api/departments/employee`, payload),
    }
  },
  duties: {
    fetch: () => axios.get(`/api/duties`),
    fetchWithUserCount: () => axios.get(`/api/duties/with-user-count`),
    create: (payload) => axios.post(`/api/duties`, payload),
    update: (id, payload) => axios.put(`/api/duties/${id}`, payload),
    destroy: (id) => axios.delete(`/api/duties/${id}`),
  },
});
