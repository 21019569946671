<template>
  <custom-dialog :max-width="670">
    <template #title>{{ $t('Kita tema') }}</template>
    <template #content>
      <validation-form>
        <v-row class="pa-3">
          <v-col cols="12">
            <h1>Pasirinkite kitą norimą temą prie teisinės informacijos:</h1>
          </v-col>
          <v-col cols="12">
            <form-radio
                v-for="item in list"
                :key="item.name"
                name="test"
                :radio-value="item.name"
            ><p class="my-2 ms-3">{{ item.text }}</p></form-radio>
          </v-col>
        </v-row>
      </validation-form>
    </template>

    <template #footer>
      <ui-button
          prepend-icon="close"
          background="default"
          @click="close"
      >{{ $t('Uždaryti') }}
      </ui-button>
      <ui-button
          prepend-icon="ok"
          background="primary"
      >{{ $t('Pasirinkti') }}
      </ui-button>
    </template>
  </custom-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialogMixin";
import Form from "@/components/ui/form/Form.vue";
import TextField from "@/domain/fields/TextField.vue";
import MessageAlerts from "@/domain/messages/MessageAlerts.vue";
import SelectField from "@/domain/fields/SelectField.vue";
import UiButton from "@/domain/components/UiButton.vue";
import EmployeeTableView from "@/components/ui/views/EmployeeTableView.vue";
import ValidationForm from "@/domain/components/ValidationForm.vue";
import CustomDialog from "@/components/ui/dialog/CustomDialog.vue";
import {CREATE_DUTY_TAG} from "@/domain/company/store/tags";
import {GLOBAL_TOAST_TAG} from "@/utils/default";
import FormCheckbox from "@/components/ui/form/FormCheckbox.vue";
import FormRadio from "@/components/ui/form/FormRadio.vue";

export default {
  name: "DtvSurveyModal",
  mixins: [dialogMixin],
  components: {
    FormRadio,
    FormCheckbox,
    CustomDialog,
    ValidationForm,
    EmployeeTableView,
    UiButton, SelectField, MessageAlerts, TextField, Form
  },
  data() {
    return {
      CREATE_DUTY_TAG,
      formData: {
        name: "",
      },
      list: [
        {name: "1", text: "Darbo sutarties šalys ir jų bendrosios pareigos"},
        {name: "2", text: "Darbo sutarties sąvoka, sąlygos, turinys"},
        {name: "3", text: "Darbo sutarties sudarymas, vykdymas, pasibaigimas"},
        {name: "4", text: "Darbo sutarčių rūšys"},
        {name: "5", text: "Darbo santykių ypatumai"},
        {name: "6", text: "Žalos atlyginimas"},
        {name: "7", text: "Darbuotojų sauga ir sveikata"},
        {name: "8", text: "Darbo ginčai"},
        {name: "9", text: "Darbo taryba"},
        {name: "10", text: "BDAR"},
      ]
    };
  },
  methods: {
  },
}
</script>