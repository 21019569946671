<template>
    <div class='d-flex flex-row align-center py-2 document-title-view'>
        <div class='mt-1'>
            <ui-icon>{{ icon }}</ui-icon>
        </div>
        <div class='d-flex flex-column'>
            <h3 :class='{"with-spaces": titleWithSpaces}'>
                {{ title }}
            </h3>
            <span v-if='!!dueAt' class='mt-1'>
                {{ $t('Galioja iki {date}', { date: dueAt }) }}
            </span>
            <slot />
        </div>
    </div>
</template>

<script>
import UiIcon from '@/domain/components/UiIcon.vue'

export default {
    name: 'DocumentTitleView',
    components: { UiIcon },
    props: {
        icon: { type: String, default: 'paper' },
        title: { type: String, required: true },
        dueAt: { type: String, default: null }
    },
    computed: {
        titleWithSpaces() {
            return this.title.indexOf(' ') === -1
        }
    }
}
</script>

<style lang='scss'>
.document-title-view {
    gap: 1rem;

    .ui-icon {
        font-size: 3rem;
        color: #32398B;
    }

    h3 {
        width: 100%;
        color: #1C2538;
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;

        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;

        &.with-spaces {
            word-break: break-all;
        }
    }

    span {
        color: #70778C;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
}
</style>