import {
    RESTORE_TO_DEFAULT_STATE, SET_ACTIONS,
    SET_AS_ADMIN,
    SET_CURRENT_EVENT, SET_CURRENT_EVENT_ABORTED,
    SET_DEPUTIES, SET_EVENTS,
    SET_HOLIDAYS, SET_SEND_INVITE_FOR_EMPLOYEE_AND_LEADER,
    SET_USER
} from '@/domain/vacationModal/store/mutations'
import customApi from '@/plugins/customApi'
import { EVENT_HOLIDAY_TYPE_YEARLY } from '@/utils/default'
import { VACATION_MODAL_CHECK_TAG } from '@/domain/vacationModal/tags'
import moment from 'moment'
import { currentEvent } from '@/domain/vacationModal/store/getters'
import Vue from 'vue'

export const init = async ({ commit, dispatch, rootGetters }, { tag, asAdmin, userId, eventId, eventType, start, end }) => {
    const api = customApi(tag)
    const [user, events] = await api.requests([
        api.factories.user.getById(userId),
        api.factories.employee.holidays.fetch(userId)
    ])

    commit(SET_AS_ADMIN, asAdmin)
    commit(SET_SEND_INVITE_FOR_EMPLOYEE_AND_LEADER, asAdmin)
    commit(SET_USER, user)
    commit(SET_EVENTS, events.filter(({ id }) => id !== eventId))

    const currentEvent = events.find(({ id }) => id === eventId)
    const deputies = currentEvent?.deputies?.map(({ user }) => {
        user.userId = user.id
        return user
    }) ?? []

    commit(SET_CURRENT_EVENT, {
        id: currentEvent?.id ?? null,
        type: currentEvent?.type ?? eventType,
        start: currentEvent?.start ?? start,
        end: currentEvent?.end ?? end,
        payType: currentEvent?.holiday?.payType ?? (rootGetters['currentUserStore/settings']?.holiday
            ?.settingHolidayAllAvailablePayTypes?.config?.defaultType ?? 'pay_before'),
        deputies,
        message: currentEvent?.message ?? '',
        attachment: currentEvent?.attachment ?? null
    })

    if (currentEvent?.currentStatus === 'aborted') {
        commit(SET_CURRENT_EVENT_ABORTED)
    }

    const minDate = (() => {
        const holidayDates = rootGetters['calendar/holidayDates']
        const createBeforeDays = rootGetters['currentUserStore/settings']?.holiday
            ?.settingHolidayAllCreateBeforeWorkDays?.config.days ?? 0

        function isDateNotHolidayAndWeekend(date) {
            return ![6, 7].includes(date.isoWeekday()) && !holidayDates.includes(date.format('YYYY-MM-DD'))
        }

        let date = moment()
        let i = 0
        const dateMethod = createBeforeDays < 0 ? 'subtract' : 'add'

        while (i < Math.abs(createBeforeDays)) {
            date = date[dateMethod](1, 'days')

            if (isDateNotHolidayAndWeekend(date)) {
                i++
            }
        }

        return date.format('YYYY-MM-DD')
    })()

    const eventOnlyInfo = asAdmin ? true : currentEvent?.start >= minDate

    commit(SET_ACTIONS, {
        abort: currentEvent?.currentStatus === 'approved' && eventOnlyInfo,
        change: currentEvent?.currentStatus === 'approved' && eventOnlyInfo,
        create: eventId == null,
        update: currentEvent?.currentStatus === 'pending' && eventOnlyInfo
    })
    await dispatch('loadApprovalTree', { userId, eventId })
    await dispatch('loadersStore/hideLoader', tag, { root: true })
}

export const loadApprovalTree = async ({ state, dispatch }, { userId, eventId }) => {
    // const api = customApi()

    // const data = await (
    //     eventId != null
    //         ? api.request(api.factories.approvals.tree.holidayEvent(eventId))
    //         : api.request(api.factories.approvals.tree.user.fetchHolidayEvent(userId))
    // )
    //
    // Vue.set(state, 'approvalsTree', data)
}

export const check = async ({ dispatch, getters }, { userId, payload }) => {
    if (getters.currentEventAborted) {
        return
    }

    const api = customApi(VACATION_MODAL_CHECK_TAG)
    const messages = await api.request(api.factories.employee.events.check({
        ...payload,
        userId
    }))

    await dispatch('messagesStore/showInfoMessage', {
        tag: VACATION_MODAL_CHECK_TAG,
        message: messages
    }, { root: true })
}

export const setDeputies = ({ state }, { userIds, users }) => {
    Vue.set(state.currentEvent, 'deputies', userIds.map((userId) => ({userId})))
    // Vue.set(state.approvalsTree.orders[0], 'users', users)
    // state.approvalsTree.orders[0].users = users
}

export const clear = ({ commit }) => {
    commit(RESTORE_TO_DEFAULT_STATE)
}