<template>
    <hra-tooltip :text='comment'>
        <div class='document-status__content'>
            <div
                class='document-status__name'
                :data-status-name='status'
            >
                {{ title }}
            </div>
            <slot name='append' />
        </div>
        <div v-if='date' class='document-status__date'>
            {{ date }}
        </div>
    </hra-tooltip>
</template>

<script>
import HraTooltip from '@/components/ui/HraTooltip.vue'
import i18n from '@/plugins/i18n'

const statusTitles = {
    'new': i18n.t('Nepradėtas'),
    'sent': i18n.t('Išsiųstas'),
    'full_approved': i18n.t('100% patvirtintas')
}

export default {
    name: 'DocumentStatus',
    components: { HraTooltip },
    props: {
        status: { type: String, required: true },
        date: { type: String, default: null },
        comment: { type: String, default: null }
    },
    computed: {
        title() {
            return statusTitles[this.status] ?? '-'
        }
    }
}
</script>

<style lang='scss'>
.document-status {
    &__content {
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: flex-end;
    }

    &__name {
        display: inline-block;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border-radius: 4px;
        padding: 5px 10px;

        &[data-status-name="new"] {
            background: #F1EEF9;
            color: #4B56D2;
        }

        &[data-status-name="sent"] {
            background: #FFF2DD;
            color: #64420E;
        }

        &[data-status-name="full_approved"] {
            background: rgba(1, 187, 153, 0.15);
            color: #02856D;
        }
    }

    &__date {
        margin-top: 3px;
        color: #70778C;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}
</style>