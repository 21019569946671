export default {
    props: {
        dialog: {
            type: Boolean,
            required: true,
        },
        closeDialog: {
            type: Function,
            required: true,
        },
    },
    watch: {
        dialog(isOpen) {
            if (!isOpen) {
                this.$refs.form.clearForm();
            }
        },
    },
    methods: {
        closeDialogWithTimeout(time) {
            setTimeout(() => this.closeDialog(), time ? time : 400);
        },
        closeDialogAndClearForm() {
            this.closeDialog();
        },
    }
};
