<template>
    <custom-dialog :max-width='900'>
        <template #title>{{ $t('Įkelti dokumentus') }}</template>
        <template #content>

            <select-folder-field
                class='mx-7 mt-5'
                :title='$t("Dokumentų įkėlimo vieta")'
                :folder-id='folderId'
                @changeFolderId='handleChangeFolderId'
            />

            <v-row class='mx-7 mt-3 mb-0'>
                <upload-drop-component @upload='onFileUpload' />
            </v-row>
            <v-row class='mx-5 mt-3 mb-0 document-upload' v-if='files.length'>
                <v-col col='12' class='py-0'>
                    <span class='d-flex flex-row heading-grey'>{{ $t('Pridėti dokumentai') }}</span>
                </v-col>
                <v-col cols='12' class='pt-0'>
                    <div class='selected-documents-list'>
                        <div
                            v-for='(file, index) in files'
                            :key='`selected-document-${index}`'
                            class='selected-documents-list__item'
                        >
                            <div class='selected-documents-list__item-icon'>
                                <ui-icon>paper</ui-icon>
                            </div>
                            <div class='selected-documents-list__item-title'>
                                {{ file.name }}
                            </div>
                            <div
                                class='selected-documents-list__item-button'
                                @click='removeFile(index)'
                            >
                                <ui-icon>minus-circle</ui-icon>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>

            <v-row class='mx-5 mt-3 mb-0' />

            <div style='background: #FFFFFF; position: sticky; bottom: 0; z-index: 999'>
                <v-divider class='mt-0' />
                <v-row class='mx-5 mt-3 mb-0 d-flex flex-row align-end'>
                    <v-col cols='6'>
                        <select-field
                            :label='$t("Dokumentų tipas")'
                            :options='documentTypeOptions'
                            v-model='documentsType'
                        />
                    </v-col>
                    <v-col cols='6'>
                        <div class='d-flex flex-column gap-2'>
                            <form-checkbox :is-checked.sync='expiryEnabled'>
                                <div class='pl-2'>
                                    <span
                                        class='color-black font-weight-500 fs-15 public-sans'>{{ $t('Turi galiojimo laiką')
                                        }}</span>
                                </div>
                            </form-checkbox>
                            <datepicker-field
                                :disabled='!expiryEnabled'
                                :value.sync='expiresAt'
                                :numbers-only='true'
                            />
                        </div>
                    </v-col>
                </v-row>
                <v-row class='mx-5 my-0'>
                    <v-col cols='12'>
                        <form-checkbox :is-checked.sync='isExternal'>
                            <div class='pl-2'>
                        <span
                            class='color-black font-weight-500 fs-15 public-sans'>{{ $t('Aktyvuoti greitą peržiūrą')
                            }}</span>
                                <span
                                    class='fs-15 public-sans'>{{ $t(' (dokumentas bus prieinamas iš išorės visiems su nuoroda)')
                                    }}</span>
                            </div>
                        </form-checkbox>
                    </v-col>
                </v-row>
            </div>
        </template>
        <template #footer>
            <ui-button prepend-icon='close' background='default' @click='close'>{{ $t('Uždaryti') }}</ui-button>
            <ui-button prepend-icon='ok' background='primary' @click='submit' :loading='loading'
                       :disabled='!files.length'>{{ $t('Įkelti') }}
            </ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import HistoryDialog from '@/components/ui/dialog/HistoryDialog.vue'
import dialogMixin from '@/mixins/dialogMixin'
import UiButton from '@/domain/components/UiButton'
import TextField from '@/domain/fields/TextField'
import UploadDropComponent from '@/domain/documents/components/upload/UploadDropComponent.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import DatepickerField from '@/domain/fields/DatepickerField.vue'
import { GLOBAL_TOAST_TAG } from '@/utils/default'
import customApi from '@/plugins/customApi'
import moment from 'moment'
import SelectField from '@/domain/fields/SelectField.vue'
import documentTypesMixin from '@/domain/documents/mixins/documentTypesMixin'
import UiIcon from '@/domain/components/UiIcon.vue'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import FolderSelectFinder from '@/domain/documents/components/finders/FolderSelectFinder.vue'
import modalMethodsMixin from '@/domain/mixins/modalMethodsMixin'
import SelectFolderField from '@/domain/documents/components/SelectFolderField.vue'

export default {
    name: 'DocumentUploadModal',
    components: {
        SelectFolderField,
        FolderSelectFinder,
        CustomDialog,
        UiIcon,
        SelectField,
        HistoryDialog,
        UiButton,
        TextField,
        FormCheckbox,
        DatepickerField,
        UploadDropComponent
    },
    mixins: [dialogMixin, modalMethodsMixin, documentTypesMixin],
    props: {
        folderId: { type: Number, default: null }
    },
    data() {
        return {
            isExternal: false,
            expiresAt: moment().add(1, 'year').format('YYYY-MM-DD'),

            selectedFolderId: null,
            expiryEnabled: false,
            files: [],
            loading: false,

            allowedFormats: ['.xlsx', '.xls', '.pdf', '.doc', '.docx'],
            documentsType: null,
        }
    },
    watch: {
        documentTypes: {
            handler: function(value, oldValue) {
                if (oldValue == null && value != null) {
                    this.documentsType = value[0].id
                }
            },
            immediate: true
        }
    },
    mounted() {
        this.selectedFolderId = this.folderId
    },
    methods: {
        handleChangeFolderId(folderId) {
            this.selectedFolderId = folderId
        },
        onFileUpload(files) {
            for (const file of files) {
                if (this.allowedFormats.some(f => file.name.endsWith(f))) {
                    this.files.push(file)
                } else {
                    this.showFormatError(file.name)
                }
            }
        },
        showFormatError(fileName) {
            this.$store.dispatch('messagesStore/showErrorMessage', {
                tag: GLOBAL_TOAST_TAG,
                message: this.$t('Netinkamas failo formatas {name}', {
                    name: fileName
                })
            })
        },
        removeFile(index) {
            this.files.splice(index, 1)
        },
        getFormData() {
            const folderId = this.selectedFolderId
            const formData = new FormData()

            formData.append('typeId', this.documentsType)
            formData.append('isExternal', this.isExternal ? '1' : '0')

            for (const file of this.files) {
                formData.append(`files[${file.name}]`, file)
            }

            this.expiryEnabled && formData.append('expirationDate', this.expiresAt)
            folderId && formData.append('folderId', folderId)

            return formData
        },
        uploadSuccess(items) {
            const folderId = this.selectedFolderId
            const messageKey = this.files.length > 1 ? 'multiple_documents_uploaded' : 'single_document_uploaded'
            const message = this.$t(messageKey, { count: this.files.length })

            this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag: GLOBAL_TOAST_TAG,
                message: message
            })

            this.$store.dispatch('documentsItemsRootStore/documents/createMany', {
                folderId,
                items
            })

            this.$router
                .push({ name: 'DocumentsFolders', params: { folderId } })
                .catch(e => {
                })

            this.$root.$emit('documents-overview-disk-usage-refresh')
            this.close()
        },
        uploadFail(error) {
            let errorMessage = this.$t('Nepavyko įkelti')

            if (error.response && error.response.data && error.response.data.message) {
                const responseErrorMessage = error.response.data.message[0]
                if (responseErrorMessage) {
                    errorMessage = responseErrorMessage.trim()
                }
            }

            this.$store.dispatch('messagesStore/showErrorMessage', {
                tag: GLOBAL_TOAST_TAG,
                message: errorMessage
            })
        },
        async submit() {
            this.loading = true

            try {
                const api = customApi()
                const { data } = await api.factories.documents.documents.upload(this.getFormData())
                this.uploadSuccess(data)
            } catch (error) {
                this.uploadFail(error)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang='scss'>
.document-upload-modal-body {
    .remove-file:hover {
        .document-icon-minus-circle path {
            fill: #C644A5 !important;
        }
    }

    .DatepickerField {
        min-width: 236px;
    }
}

span.color-black {
    color: #1C2538;
}

span.public-sans {
    font-family: 'Public Sans', sans-serif;
}

.selected-documents-list {
    &__item {
        border: 1px solid #DAD5E9;
        border-bottom: 0;
        display: flex;
        flex-direction: row;
        padding: 10px 20px;
        align-items: center;
        min-height: 64px;
        gap: 16px;

        &:first-child {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }

        &:last-child {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            border-bottom: 1px solid #DAD5E9;
        }

        &:nth-child(even) {
            background: #FAF9FF;
        }

        &-icon {
            & > .ui-icon {
                color: #8689A8;
                font-size: 1.7rem;
            }
        }

        &-title {
            color: #1C2538;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        &-button {
            margin-left: auto;
            color: #70778C;
            font-size: 1.4rem;
            cursor: pointer;

            &:hover {
                color: #E7384F;
            }
        }
    }
}
</style>