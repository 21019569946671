<template>
	<Form
		v-model="code"
		@submit="onSubmit"
		:form-loader-name="loaderName"
	>
		<template #form>
			<div class="d-flex flex-column full-width gap-3">
				<p
					v-html="$t('Didesniam informacijos saugumui užtikrinti <strong>rekomenduojame naudotis papildoma vartotojo patvirtinimo sistema</strong> (two-factor authentification), kurią bet kada galėsite išjungti.')"/>
				<ol>
					<li>
						{{ $t("Parsisiųskite mobiliąją programėlę „Authenicator“ į savo telefoną.") }}<br />
						<strong>{{ $t("Tinka „Google Authenticator“, „Microsoft Authenticator“, „FortiToken“ ir kt.") }}</strong>
					</li>
					<li>{{ $t("Atidarykite programėlę ir paspauskite „Pridėti paskyrą“") }}</li>
					<li>{{ $t("Nuskanuokite žemiau esantį kodą:") }}</li>
					<div class="d-flex align-center justify-center my-3" v-html="qrCode"/>
					<li>{{ $t("Įveskite programėlės sugeneruotą kodą:") }}</li>
				</ol>
				<FormTextField
					:rules="'required|min:6,simbolių|max:6,' + $t('simbolių')"
					v-model="code"
					name="code"
					:placeholder="$t('6 skaitmenų kodas')"
				/>
			</div>
		</template>
		<template #footer-messages>
			<form-alert v-if="errorText" type="error" :text="errorText"/>
		</template>
		<template #buttons="{loading}">
			<hra-button
				:loading="buttonLoading"
				:color="uiColor.primary"
				full-width
				:type="buttonType.submit"
				class="mt-7"
			>{{ $t("Patvirtinti") }}
			</hra-button>
		</template>
	</Form>
</template>

<script>
import Form from "@/components/ui/form/Form.vue";
import FormTextField from "@/components/ui/form/FormTextField.vue";
import Button from "@/components/ui/button/Button.vue";
import {appRouteProps} from "@/router/app";
import HraButton from "@/components/ui/button/HraButton";
import FormAlert from "@/components/ui/form/FormAlert";

export default {
	components: {
		FormAlert,
		HraButton,
		Form,
		FormTextField,
		Button,
	},
	data() {
		return {
			buttonLoading: false,
			qrCode: null,
			code: "",
			errorText: null,
		};
	},
	inject: ["authService"],
	async created() {
		this.qrCode = await this.authService.getTwoFactorQrCode();
	},
	methods: {
		async onSubmit(code) {
			try {
				this.buttonLoading = true;
				this.errorText = null;
				await this.authService.enableTwoFactor(code);
			} catch (error) {
				if (error?.message) {
					this.errorText = error.message;
				}
			} finally {
				this.buttonLoading = false;
			}
		},
	},
};
</script>
