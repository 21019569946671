<template>
    <h3 class='document-section-title d-flex align-center gap-2'>
        <ui-icon v-if='icon'>{{ icon }}</ui-icon>
        <span>{{ title }}</span>
    </h3>
</template>

<script>
import UiIcon from '@/domain/components/UiIcon.vue'

export default {
    name: 'DocumentSectionTitle',
    components: { UiIcon },
    props: {
        title: { type: String, required: true },
        icon: { type: String, default: null }
    }
}
</script>

<style lang='scss'>
.document-section-title {
    font-weight: 600;
    font-size: 22px;

    .ui-icon {
        color: #70778C;
    }
}
</style>