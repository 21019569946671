import { mapGetters } from 'vuex'

export default {
    props: {
        isProfile: { type: Boolean, default: false },
        isCreate: { type: Boolean, default: false },
        isEdit: { type: Boolean, default: false }
    },
    computed: {
        ...mapGetters({
            dismissed: 'employeeStore/dismissed'
        })
    }
}