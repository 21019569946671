import api from "@/plugins/api";

class DepartmentService {

  async changeLeader(departmentId, employeeId) {
    const {data} = await api.department.changeLeader(departmentId, employeeId);
    return data;
  }

}

export default new DepartmentService();