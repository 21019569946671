<template>
	<div>
		<select-field
			name="limit"
			:label="label"
			:options="options"
			:is-sticky="isSticky"
			v-model="filter.limit"
			@input="onChange"
		/>
	</div>
</template>
<script>
import HraFilterSelect from "@/components/ui/filter/HraFilterSelect";
import SelectField from "@/domain/fields/SelectField";

export default {
	name: "HraFilterLimit",
	components: {SelectField, HraFilterSelect},
	props: {
		label: {type: String, required: true},
		withAll: {type: Boolean, default: false},
		isSticky: {type: Boolean, default: false},
	},
	data: () => ({
		defaultOptions: ['10', '20', '30', '50', '100'],
		allOption: '-1',
	}),
	inject: ['filter', 'changeFilter'],
	computed: {
		options() {
			return (this.withAll ? [
				...this.defaultOptions,
				this.allOption
			] : this.defaultOptions).map(option => ({
				value: option,
				text: option === this.allOption ? this.$t("Visus") : option,
			}));
		}
	},
	methods: {
		onChange(value) {
			this.changeFilter('limit', value);
		},
	},
}
</script>
