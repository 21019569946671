<template>
    <fragment>
        <iframe
            v-if="fileType && fileType === 'pdf'"
            ref='PdfIframe'
            :src='fileUrl'
            width='100%'
            height='700px'
        />
        <docx-preview v-else-if="fileType && fileType === 'docx'" :fileUrl='fileUrl' />
        <excel-preview v-else-if="fileType && fileType === 'xls' || fileType && fileType === 'xlsx'"
                       :fileUrl='fileUrl' />
    </fragment>
</template>

<script>

import CustomApi from '@/plugins/customApi'
import ExcelPreview from '@/domain/documents/components/ExcelPreview.vue'
import DocxPreview from '@/domain/documents/components/DocxPreview.vue'

const customApi = CustomApi()
export default {
    name: 'DocumentSentUserPreview',
    components: { DocxPreview, ExcelPreview },
    props: {
        documentSentId: {type: Number, required: true},
        fileType: {type: String, required: true}
    },

    data() {
        return {
            excelHtml: null,
            loading: false,
            documentPreviewStartId: null,
            documentScrollToEnd: null,
            iframeContainer: null
        }
    },
    computed: {
        fileUrl() {
            return window.location.protocol + '//'
                + window.location.hostname + `/api/documents/sent/${this.documentSentId}/users/preview`
        }
    },
    created() {
        customApi.factories.documents.sentUsers.preview.begin(this.documentSentId)
            .then(({data}) => {
                this.documentPreviewStartId = data.id
            })
        this.handleCloseTab = () => {
            this.sendRequestPreviewEnd()
        }
        window.addEventListener('beforeunload', this.handleCloseTab, false)
    },
    beforeDestroy() {
        window.removeEventListener('beforeunload', this.handleCloseTab)
        this.sendRequestPreviewEnd()
    },
    methods: {
        sendRequestPreviewEnd() {
            if (this.documentPreviewStartId == null) {
                return
            }

            customApi.factories.documents.sentUsers.preview.end(this.documentPreviewStartId, {
                scrolledToEnd: this.documentScrollToEnd
            })
        }
    }
}
</script>