var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('expansion-panel',{attrs:{"header":_vm.$t('Darbotojo paskyros pakeitimų istorija')}},[_c('v-row',{ref:"container"},[_c('v-col',{attrs:{"cols":"12"}},[_c('hra-table',{staticClass:"no-border-around",attrs:{"headers":_vm.headers,"rows":_vm.rows,"hide-headers":"","need-actions":"","expand-multiple-row":"","expand-row-ids":_vm.expandIds},on:{"update:expandRowIds":function($event){_vm.expandIds=$event},"update:expand-row-ids":function($event){_vm.expandIds=$event}},scopedSlots:_vm._u([{key:"col.date",fn:function(ref){
var colValue = ref.colValue;
return [_c('div',{staticClass:"pa-3"},[_vm._v(_vm._s(colValue))])]}},{key:"col.title",fn:function(ref){
var colValue = ref.colValue;
return [_c('div',{staticClass:"pa-3"},[_vm._v(_vm._s(colValue))])]}},{key:"col.adminName",fn:function(ref){
var colValue = ref.colValue;
return [_c('div',{staticClass:"pa-3"},[_vm._v(_vm._s(colValue))])]}},{key:"col.actions-base",fn:function(ref){
var row = ref.row;
var expandRow = ref.expandRow;
return [_c('hra-tooltip',{attrs:{"text":_vm.$t(row.isExpand ? 'Suskleisti' : 'Išskleisti')}},[_c('hra-button',{attrs:{"size":_vm.buttonSize.small,"color":_vm.uiColor.default,"hover-background":!row.isExpand},on:{"click":function () { return expandRow(row.id); }}},[_c('v-fab-transition',[_c('div',{key:((row.isExpand ? 1 : 0) + "-expand")},[_c('hra-icon',{attrs:{"name":row.isExpand ? _vm.iconName.up : _vm.iconName.down}})],1)])],1)],1)]}},{key:"empty.text",fn:function(){return [_vm._v(_vm._s(_vm.$t('Įrašų nėra')))]},proxy:true},{key:"row-expansion",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"pa-3"},[_c('hra-table',{attrs:{"headers":_vm.expandHeaders(row.event, row.needName),"rows":row.expand}})],1)]}}])}),_c('hra-pagination',{staticClass:"mt-3",attrs:{"current-page":_vm.currentPage,"last-page":_vm.totalPages,"max-view-pages":_vm.limit},on:{"changePage":_vm.onChangePage}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }