<template>
	<ValidationProvider
		:name="$attrs.name"
		:rules="rules"
		v-slot="{ classes }"
	>
		<div :class="[...fieldClasses, ...classes]">
			<div :class="`${baseClassName}__label`">
				<label :for="$attrs.name">{{ translateLabel }}</label>
			</div>
			<p v-if="isView" class="ma-0">{{ innerValue }}</p>
			<div v-else :class="`${baseClassName}__input`">

        <textarea
          :ref="$refs"
          v-bind="$attrs"
          :id="$attrs.name"
          :rows="$attrs.rows"
          :disabled="disabled"
          v-model="innerValue"
          :placeholder="translatePlaceholder"
        />
			</div>
		</div>
	</ValidationProvider>
</template>

<script>
import formFieldMixin from "@/mixins/formFieldMixin";

export default {
	mixins: [formFieldMixin],
	data: () => ({
		baseClassName: "form-textarea"
	}),
};
</script>
