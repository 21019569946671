<template>
    <v-row class='ml-5 mt-5'>
        <v-col cols='12'>
            <ui-table
                header-sticky
                :header-sticky-top='64'
                :visible-load-more='hasMore'
                :loading='loading'
                :loading-more='loadingMore'
                @clickLoadMore='handleLoadMore'
            >
                <template #head>
                    <tr>
                        <table-header-document-filter
                            :filter='filter.document'
                            @changeFilter='handleChangeFilter'
                        />
                        <table-header-user-filter
                            column='user'
                            :filter='filter.user'
                            @changeFilter='handleChangeFilter'
                        />
                        <table-header-department-filter
                            column='department'
                            :filter='filter.department'
                            @changeFilter='handleChangeFilter'
                        />
                        <table-header-duty-filter
                            column='duty'
                            :filter='filter.duty'
                            @changeFilter='handleChangeFilter'
                        />
                        <table-header-date-filter
                            interval
                            :title='$t("Dokumentas išsiųstas")'
                            column='sentAt'
                            :filter='filter.sentAt'
                            @changeFilter='handleChangeFilter'
                        />
                        <table-header-document-sent-status-filter
                            :filter='filter.status'
                            @changeFilter='handleChangeFilter'
                        />
                    </tr>
                </template>

                <template #colgroup>
                    <colgroup>
                        <col style='width: 30%;' />
                        <col style='width: 20%' />
                        <col style='width: 14%' />
                        <col style='width: 14%' />
                        <col style='width: 10%' />
                        <col style='width: 10%' />
                    </colgroup>
                </template>

                <template #body>
                    <tr v-for='(row, key) in rows' :key='key' @click='() => handleClickRow(row)'
                        style='cursor: pointer;'>
                        <td>{{ row.document.title }}</td>
                        <td>{{ row.user.name }}</td>
                        <td>{{ row.user.departmentName }}</td>
                        <td>{{ row.user.dutyName }}</td>
                        <td>{{ row.document.sentAt }}</td>
                        <td>
                            <document-sent-status-with-comment
                                :status='row.document.status'
                                :date='row.document.statusAt'
                                :comment='row.document.comment'
                            />
                        </td>
                    </tr>
                </template>
            </ui-table>
        </v-col>
    </v-row>
</template>

<script>
import UiTable from '@/domain/components/uiTable/UiTable.vue'
import CustomApi from '@/plugins/customApi'
import UiTableHeadFilter from '@/domain/components/uiTable/UiTableHeadFilter.vue'
import tableFilterMixin from '@/domain/components/uiTable/mixins/tableFilterMixin'
import TableHeaderDateFilter from '@/domain/components/uiTable/filters/TableHeaderDateFilter.vue'
import TableHeaderDepartmentFilter from '@/domain/components/uiTable/filters/TableHeaderDepartmentFilter.vue'
import TableHeaderDutyFilter from '@/domain/components/uiTable/filters/TableHeaderDutyFilter.vue'
import TableHeaderUserFilter from '@/domain/components/uiTable/filters/TableHeaderUserFilter.vue'
import TableHeaderDocumentSentStatusFilter from '@/domain/components/uiTable/filters/TableHeaderDocumentSentStatusFilter.vue'
import TableHeaderDocumentFilter from '@/domain/components/uiTable/filters/TableHeaderDocumentFilter.vue'
import modalMethodsMixin from '@/domain/mixins/modalMethodsMixin'
import DocumentSentStatusWithComment from '@/domain/documents/components/DocumentSentStatusWithComment.vue'

const customApi = CustomApi()
export default {
    name: 'DocumentSummaryMainPage',
    mixins: [tableFilterMixin, modalMethodsMixin],
    components: {
        DocumentSentStatusWithComment,
        TableHeaderDocumentFilter,
        TableHeaderDocumentSentStatusFilter,
        TableHeaderUserFilter,
        TableHeaderDutyFilter,
        TableHeaderDepartmentFilter, TableHeaderDateFilter, UiTableHeadFilter, UiTable
    },
    data() {
        return {
            loading: false,
            loadingMore: false,
            hasMore: false,
            currentPage: 1,
            rows: [],
            filterSettings: {
                sortOnlyOne: false,
                setRouteQuery: true,
                resetSelected: {
                    'user': ['department', 'duty'],
                    'department': ['user', 'duty'],
                    'duty': ['user', 'department']
                }
            },
            filter: {
                document: { sort: null, selected: [] },
                user: { sort: null, selected: [] },
                department: { sort: null, selected: [] },
                duty: { sort: null, selected: [] },
                sentAt: { sort: 'desc' },
                status: { selected: [] }
            }
        }
    },
    created() {
        this.getFilterFromRouteQuery(this.$route.query)
        this.loadData()
    },
    methods: {
        handleClickRow(row) {
            this.onOpenModal('HistoryOfDocumentSentUserModal', {
                documentSentUserId: row.documentSentUserId,
                documentTitle: row.document.title,
                userFullName: row.user.name
            })
        },
        handleChangeFilter(column, filter) {
            this.setFilter(column, filter)
            this.loadData()
        },
        getRequestParams(page = 1) {
            return {
                page,
                limit: 50,
                ...this.getFilterParams()
            }
        },
        async loadData() {
            this.loading = true
            try {
                await this.fetch(this.getRequestParams(1))
            } catch (error) {

            } finally {
                this.loading = false
            }
        },
        async handleLoadMore() {
            this.loadingMore = true
            try {
                await this.fetch(this.getRequestParams(this.currentPage + 1))
            } catch (error) {

            } finally {
                this.loadingMore = false
            }
        },
        async fetch(params) {
            const {
                data: {
                    items,
                    hasMore,
                    pagination: { currentPage }
                }
            } = await customApi.factories.documents.summary.fetch(params)

            this.rows = currentPage === 1 ? items : [...this.rows, ...items]
            this.currentPage = currentPage
            this.hasMore = hasMore
        }
    }
}
</script>