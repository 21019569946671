<template>
	<v-app class="app" id="inspire">
		<v-main class="auth">
			<v-container fluid fill-height>
				<router-view/>
			</v-container>
		</v-main>
	</v-app>
</template>

<script>

import authService from "@/domain/auth/services/authService";

export default {
	name: "AuthLayout",
	provide() {
		return {
			service: authService,
		};
	},
};
</script>