// NPM
import Vue from 'vue'
import axios from 'axios'

// PROJECT
import store from '@/store'
import Auth from '@/api/auth'
import User from '@/api/user'
import Employee from '@/api/employee'
import Department from '@/api/department'
import Calendar from '@/api/calendar'
import Widgets from '@/api/widgets'
import Setup from '@/api/setup'
import Settings from '@/api/settings'
import Debugger from '@/api/debugger'
import Company from '@/api/company'
import Vacations from '@/api/vacations'
import Dtv from '@/api/dtv'
import Events from '@/api/events'
import i18n from '@/plugins/i18n'
import Schedules from '@/api/schedules'
import Documents from '@/api/documents/documents'
import { redirectToAuth } from '@/utils/default'
import Duties from '@/api/duties'
import Approvals from '@/api/approvals'
import CompanyCalendar from '@/api/companyCalendar'
import Hr from '@/api/hr'

// export const axiosInstance = axios.create()
export const axiosInstance = axios.create({
    baseURL: window.location.origin,
    maxRedirects: 0
})
axiosInstance.defaults.headers.common['lang'] = i18n.locale

axiosInstance.interceptors.response.use(
    response => {
        if (response.status === 204) {
            return 'ok'
        }
        return response.data
    },
    error => {
        let message = 'Server error!'

        if (error.response) {
            if ([401, 403, 419].includes(error.response.status)) {
                redirectToAuth()
                return Promise.reject(error)
            }

            const tag = error.response.config?.tag ?? null

            if (error.response.data.error) {
                message = error.response.data.error
            }

            if (error.response.data.message) {
                message = error.response.data.message
            }

            if (tag) {
                store.dispatch('messagesStore/showErrorMessage', { tag, message })
                store.dispatch('loadersStore/hideLoader', tag)
            }
        }


        return Promise.reject(error)
    }
)

const beginRequest = async (tag) => {
    if (tag == null) {
        return
    }

    await store.dispatch('loadersStore/showLoader', tag)
    await store.dispatch('messagesStore/clearMessage', tag)
}

const customApi = (tag = null) => {
    axiosInstance.defaults.tag = tag

    return {
        tag,
        factories: {
            setup: Setup(axiosInstance),
            auth: Auth(axiosInstance),
            user: User(axiosInstance),
            employee: Employee(axiosInstance),
            department: Department(axiosInstance),
            duties: Duties(axiosInstance),
            calendar: Calendar(axiosInstance),
            widgets: Widgets(axiosInstance),
            settings: Settings(axiosInstance),
            debugger: Debugger(axiosInstance),
            company: Company(axiosInstance),
            vacations: Vacations(axiosInstance),
            dtv: Dtv(axiosInstance),
            hr: Hr(axiosInstance),
            events: Events(axiosInstance),
            schedules: Schedules(axiosInstance),
            documents: Documents(axiosInstance),
            approvals: Approvals(axiosInstance),
            companyCalendar: CompanyCalendar(axiosInstance)
        },
        request: call => new Promise(async (resolve) => {
            await beginRequest(tag)
            Promise.all([call])
                .then(([response]) => resolve(response?.data ?? response))
                .catch(() => resolve(null))
        }),
        requests: calls => new Promise(async (resolve) => {
            await beginRequest(tag)
            Promise.all(calls)
                .then(responses => resolve(responses.map(response => response.data ?? response)))
                .catch(() => resolve(null))
        })
    }
}


Vue.prototype.$customApi = customApi

export default customApi