<template>
    <div class='modals'>
        <component v-for='{modal, props, on} in dynamicComponents' :is='modal' v-bind='props' v-on='on' />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ChangeDepartmentsLeaderModal from '@/views/modals/ChangeDepartmentsLeaderModal'
import CompanyStructureAddEmployeeModal from '@/views/modals/CompanyStructureAddEmployeeModal'
import CompanyStructureCreateDepartmentModal from '@/views/modals/CompanyStructureCreateDepartmentModal'
import CompanyStructureDeleteEmployeeModal from '@/views/modals/CompanyStructureDeleteEmployeeModal'
import CreateDutyModal from '@/views/modals/CreateDutyModal'
import EmployeeChangeAvatarModal from '@/views/modals/EmployeeChangeAvatarModal'
import EmployeeChildModal from '@/views/modals/EmployeeChildModal'
import EmployeeCreateContractDetailModal from '@/views/modals/EmployeeCreateContractDetailModal'
import EmployeeCreateVacationPlusModal from '@/views/modals/EmployeeCreateVacationPlusModal'
import EmployeeDeleteModal from '@/views/modals/EmployeeDeleteModal'
import EmployeeDisabilityModal from '@/views/modals/EmployeeDisabilityModal'
import EmployeeSendInviteModal from '@/views/modals/EmployeeSendInviteModal'
import EmployeeVacationPlusModal from '@/views/modals/EmployeeVacationPlusModal'
import ImportEmployeeModal from '@/views/modals/ImportEmployeeModal'
import VacationDepartmentDetailsModal from '@/views/modals/VacationDepartmentDetailsModal'
import VacationModal from '@/domain/vacationModal'
import VacationRequestModal from '@/domain/vacationModal/modals'
import EmployeeVacationModal from '@/domain/vacationModal/components/EmployeeVacationModal'
import LeaderVacationModal from '@/domain/vacationModal/components/LeaderVacationModal'
import ImportEmployeesModal from '@/domain/setup/modals/ImportEmployeesModal.vue'
import companyModals from '@/domain/company/modals'
import dtvModals from '@/domain/dtv/modals'
import eventsModals from '@/domain/events/modals'
import scheduleModals from '@/domain/scheduler/modals'
import settingModals from '@/domain/settings/modals'
import documentsModals from '@/domain/documents/modals'
import ConfirmModal from '@/domain/confirms/ConfirmModal.vue'
import SentInviteModal from '@/domain/invite/modals/SentInviteModal.vue'
import BusinessTripsModal from "@/domain/businessTrips/modals/BusinessTripsModal"
import RemoteWorkModal from "@/domain/remoteWork/modals/RemoteWorkModal.vue";
import ImportSickLeavesModal from '@/domain/sickLeaves/modals/ImportSickLeavesModal.vue'
import UpdateSickLeaveModal from '@/domain/sickLeaves/modals/UpdateSickLeaveModal.vue'
import InfoSickLeaveModal from '@/domain/sickLeaves/modals/InfoSickLeaveModal.vue'
import ApprovalStatesModal from '@/domain/components/ApprovalStages/ApprovalStatesModal.vue'

export default {
    name: 'ModalsProvider',
    props: {},
    data() {
        return {
            modals: {
                EmployeeVacationModal,
                LeaderVacationModal,
                ChangeDepartmentsLeaderModal,
                CompanyStructureAddEmployeeModal,
                CompanyStructureCreateDepartmentModal,
                CompanyStructureDeleteEmployeeModal,
                // CreateDepartmentModal,
                CreateDutyModal,
                EmployeeChangeAvatarModal,
                EmployeeChildModal,
                EmployeeCreateContractDetailModal,
                EmployeeCreateVacationPlusModal,
                EmployeeDeleteModal,
                EmployeeDisabilityModal,
                EmployeeSendInviteModal,
                EmployeeVacationPlusModal,
                ImportEmployeeModal,
                VacationDepartmentDetailsModal,
                ImportEmployeesModal,
                VacationModal,
                VacationRequestModal,
                RemoteWorkModal,
                BusinessTripsModal,

                ConfirmModal,
                SentInviteModal,
                ImportSickLeavesModal,
                UpdateSickLeaveModal,
                InfoSickLeaveModal,
                ApprovalStatesModal,

                ...companyModals,
                ...dtvModals,
                ...eventsModals,
                ...scheduleModals,
                ...settingModals,
                ...documentsModals
            }
        }
    },
    computed: {
        ...mapGetters({
            openedModals: 'modals/modals'
        }),
        dynamicComponents() {
            return this.openedModals.map(modal => {
                return {
                    modal: this.modals[modal.name],
                    props: modal.props,
                    on: modal.on ?? {}
                }
            })
        }
    }
}
</script>
