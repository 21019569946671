import Vue from "vue";
import customApi from "@/plugins/customApi";
import {jsonToFormData} from "@/utils/default";
import i18n from "@/plugins/i18n";
import {CREATE_REMOTE_WORK, REMOTE_WORK_CHECK_TAG, UPDATE_REMOTE_WORK} from "@/tags/tagsRemoteWork";

const state = () => {
    return {
        currentRemoteWork: {},
        validationPassed: false,
    }
}

const getters = {
    currentRemoteWork: (state) => state.currentRemoteWork,
    validationPassed: (state) => state.validationPassed
}

const actions = {
    init: async (state, {tag, userId, asAdmin, eventId = null}) => {
        const api = customApi(tag);
        const [events] = await api.requests([
            api.factories.employee.holidays.fetch(userId)
        ])
        let currentRemoteWork = {
            asAdmin: asAdmin,
            userId: userId,
            sendInviteForEmployeeAndLeader: true,
            type: 3001
        }
        const currentRemoteFromStack = events.find(({ id }) => id === eventId);

        if(currentRemoteFromStack) {
            currentRemoteWork = {
                ...currentRemoteWork,
                id: parseInt(eventId),
                start: currentRemoteFromStack.start,
                end: currentRemoteFromStack.end,
                message: currentRemoteFromStack.message
            }
        }

        state.commit("setCurrentRemoteWork", currentRemoteWork)
    },
    validate: async (state) => {
        if(!state.state.currentRemoteWork?.start) {
            return;
        }

        const payload = {
            eventId: state.state.currentRemoteWork.id,
                ...state.state.currentRemoteWork
        }

        const api = customApi(REMOTE_WORK_CHECK_TAG)
        const messages = await api.request(api.factories.employee.events.check(payload))

        await state.dispatch('messagesStore/showInfoMessage', {
            tag: REMOTE_WORK_CHECK_TAG,
            message: messages
        }, { root: true })

        messages.length === 0 ? state.commit('setValidationFlag', true) : state.commit('setValidationFlag', false);
    },
    submit: async(state) => {
        const payload = jsonToFormData(state.state.currentRemoteWork);

        const api = customApi(CREATE_REMOTE_WORK)
        const event = await api.request(api.factories.employee.holidays.create(payload))

        if (event == null) {
            return false
        }

        await state.dispatch('loadersStore/hideLoader', CREATE_REMOTE_WORK, {root:true})
        await state.dispatch('messagesStore/showSuccessMessage', {
            tag: CREATE_REMOTE_WORK,
            message: i18n.t('Sėkmingai išsiųstas')
        }, {root:true})
        await state.dispatch('employeeVacationsStore/updateEventsState', null, {root:true})
        await state.dispatch('employeeVacationsStore/updateWidgets', event, {root:true})
        state.commit("setCurrentRemoteWork", {})
        state.commit('setValidationFlag', false)
    },
    update: async(state) => {
        const payload = jsonToFormData(state.state.currentRemoteWork);

        const api = customApi(CREATE_REMOTE_WORK)
        const event = await api.request(api.factories.employee.holidays.update(state.state.currentRemoteWork.id, payload))

        if (event == null) {
            return false
        }

        await state.dispatch('loadersStore/hideLoader', UPDATE_REMOTE_WORK, {root:true})
        await state.dispatch('messagesStore/showSuccessMessage', {
            tag: UPDATE_REMOTE_WORK,
            message: i18n.t('Sėkmingai išsiųstas')
        }, {root:true})
        await state.dispatch('employeeVacationsStore/updateEventsState', null, {root:true})
        await state.dispatch('employeeVacationsStore/updateWidgets', event, {root:true})
        state.commit("setCurrentRemoteWork", {})
        state.commit('setValidationFlag', false)
    },
}

const mutations = {
    setCurrentRemoteWork: (state, payload) => {
        state.currentRemoteWork = payload;
    },
    updateCurrentRemoteWork: (state, {field, value}) => {
        Vue.set(state.currentRemoteWork, field, value)
    },
    setValidationFlag: (state, payload) => {
        state.validationPassed = payload;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}