<template>
    <div
        class='ui-calendar-month__day'
        :class='day.classes'
        :data-type='day.type'
        :data-status='day.status'
        :data-select-start='selectStartDate'
        :data-select-end='selectEndDate'
        :data-select='selectDate'
        :data-select-manual='selectDateManual'
        :data-select-weekday='selectWeekday'
        :data-weekday='day.isoWeekday'
        v-bind='$attrs'
        v-on='$listeners'
    >
        <hra-tooltip :text='day.tooltip'>
            <v-menu
                top
                offset-y
                nudge-bottom='2'
                open-on-hover
                :disabled='menuDisabled'
            >
                <template v-slot:activator='{ on, attrs }'>
                    <div
                        style='position: relative'
                        v-bind='attrs'
                        v-on='on'
                    >
                        <span class='ui-calendar-month__day-text'>{{ day.day }}</span>
                    </div>
                </template>

                <slot name='day-menu' />
            </v-menu>
        </hra-tooltip>
    </div>
</template>

<script>
import HraTooltip from '@/components/ui/HraTooltip.vue'

export default {
    name: 'UiCalendarDay',
    components: { HraTooltip },
    props: {
        day: { type: Object, required: true },
        selectStartDate: { type: Boolean, default: false },
        selectEndDate: { type: Boolean, default: false },
        selectDate: { type: Boolean, default: false },
        selectDateManual: { type: Boolean, default: false },
        selectWeekday: { type: Boolean, default: false },
        shownMenu: { type: Boolean, default: false }
    },
    computed: {
        menuDisabled() {
            if (!this.shownMenu) {
                return true
            }

            return !this.selectDate
        }
    },
    methods: {
        handleFakeClick() {
            console.debug("fake click...")
        }
    }
}
</script>