<template>
    <custom-dialog :max-width='800'>
        <template #title>{{ $t('Pasirinkite norimus pridėti dokumentus') }}</template>
        <template #content>
            <documents-select-finder ref='Finder' />
        </template>
        <template #footer>
            <ui-button @click='close'>{{ $t('Uždaryti') }}</ui-button>
            <ui-button
                prepend-icon='ok'
                background='primary'
                @click='handleSelect'
            >{{ $t('Pridėti') }}
            </ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import UiButton from '@/domain/components/UiButton.vue'
import dialogMixin from '@/mixins/dialogMixin'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import DocumentsSelectFinder from '@/domain/documents/components/finders/DocumentsSelectFinder.vue'

export default {
    name: 'DocumentsSelectModal',
    mixins: [dialogMixin],
    components: {
        DocumentsSelectFinder,
        CustomDialog,
        UiButton
    },
    methods: {
        handleSelect() {
            this.$emit('selectedDocuments', this.$refs.Finder.getSelectedDocuments())
            this.close()
        }
    }
}
</script>