<template>
    <custom-dialog :max-width='1200'>
        <template #title>{{ $t('Logas') }}</template>
        <template #content>
            <v-row class='pa-3'>
                <v-col cols='12'>
                    <v-card outlined class='px-4'>
                        <v-list>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-subtitle>{{ $t('Dokumentas') }}:</v-list-item-subtitle>
                                    <v-list-item-title>{{ documentTitle }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-subtitle>{{ $t('Darbuotojas') }}:</v-list-item-subtitle>
                                    <v-list-item-title>{{ userFullName }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-col>
                <history-of-document-sent-user-table :document-sent-id='documentSentUserId' />
                <previews-of-document-sent-user-table :document-sent-id='documentSentUserId' />
            </v-row>
        </template>
        <template #footer>
            <ui-button
                prepend-icon='close'
                @click='close'
            >{{ $t('Uždaryti') }}
            </ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import UiButton from '@/domain/components/UiButton.vue'
import dialogMixin from '@/mixins/dialogMixin'
import HistoryOfDocumentSentUserTable
    from '@/domain/documents/modals/summary/HistoryOfDocumentSentUserModal/components/HistoryOfDocumentSentUserTable.vue'
import PreviewsOfDocumentSentUserTable
    from '@/domain/documents/modals/summary/HistoryOfDocumentSentUserModal/components/PreiviewsOfDocumentSentUserTable.vue'
import DocumentTitleView from '@/domain/documents/components/DocumentTitleView.vue'
import EmployeeTableView from '@/components/ui/views/EmployeeTableView.vue'
import UiIcon from '@/domain/components/UiIcon.vue'

export default {
    name: 'HistoryOfDocumentSentUserModal',
    components: { UiIcon, EmployeeTableView, DocumentTitleView, PreviewsOfDocumentSentUserTable, HistoryOfDocumentSentUserTable, UiButton, CustomDialog },
    mixins: [dialogMixin],
    props: {
        documentTitle: { type: String, required: true },
        userFullName: { type: String, required: true },
        documentSentUserId: { type: Number, required: true }
    }
}
</script>