import { index as departmentVacations } from '@/domain/departmentVacations/routes'
import { index as vacations } from '@/domain/employeeVacations/routes'
import { permissionOnlyAdmin } from '@/utils/permissions'

export default () => ({
    isMobile: false,
    loaderQueue: ['initial'],
    modals: [],
    messages: [],
    message: {
        show: false,
        type: '',
        text: ''
    },
    fetchCountries: false,
    countries: null,
    currentContextMenu: null,
    sidebarMenu: [
        // {type: "navItem", icon: "email", title: "El. laiškai ir pranešimai", to: {name: appRouteProps.home.name}},
        {
            type: 'navItem',
            icon: 'calendar',
            title: 'Kalendorius',
            to: { name: departmentVacations },
            hasMobileView: true,
            onlyLeaderInMobileView: true,
            isTest: false
            // buttonPermissions: permissionsPageVacations
        },
        {
            type: 'navItem',
            icon: 'palm',
            title: 'Mano atostogos',
            to: { name: vacations },
            hasMobileView: true,
            onlyLeaderInMobileView: false,
            isTest: false
        },
        // {type: "navItem", icon: "file", title: "Ataskaitos", to: {name: appRouteProps.home.name}},
        {
            type: 'navItem',
            icon: 'peoples',
            title: 'Darbuotojai',
            to: { name: 'employees' },
            hasMobileView: false,
            onlyLeaderInMobileView: false,
            isTest: false
        },
        {
            type: 'navItem',
            icon: 'company-structure',
            title: 'Įmonės struktūra',
            to: { name: 'CompanyTreePage' },
            hasMobileView: false,
            onlyLeaderInMobileView: false,
            isTest: false
        },
        {
            type: 'navItem',
            icon: 'xls',
            title: 'Ataskaitos',
            to: { name: 'ReportsPage' },
            buttonPermissions: permissionOnlyAdmin,
            hasMobileView: false,
            onlyLeaderInMobileView: false,
            isTest: false
        },
        // {type: "navDivider"},
        {
            type: 'navItem',
            icon: 'dtv',
            title: 'DTV',
            to: { name: 'DtvHomePage' },
            hasMobileView: false,
            onlyLeaderInMobileView: false,
            isTest: false
        },
        {
            type: 'navItem',
            icon: 'schedule',
            title: 'Grafikai',
            to: { name: 'SchedulesListPage' },
            hasMobileView: false,
            onlyLeaderInMobileView: false,
            isTest: true
        },
        {
            type: 'navItem',
            icon: 'writing-gear',
            title: 'Dokumentų tvirtinimas',
            to: { name: 'DocumentsOverview' },
            buttonPermissions: permissionOnlyAdmin,
            hasMobileView: false,
            onlyLeaderInMobileView: false,
            isTest: false
        },
        {
            type: 'navItem',
            icon: 'writing',
            title: 'Mano dokumentai',
            to: { name: 'DocumentsUser' },
            hasMobileView: false,
            onlyLeaderInMobileView: false,
            isTest: false
        }
        // {
        //   type: "navItem",
        //   icon: "settings",
        //   title: "Debugger",
        //   to: {name: debuggerName},
        //   buttonPermissions: debuggerPageSettings,
        // }
        // {type: "navItem", icon: "info", title: "D.U.K", to: {name: appRouteProps.home.name}},
    ]
});