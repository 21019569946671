<template>
    <div class='document-sidebar'>
        <slot />
    </div>
</template>

<script>
import UiButton from '@/domain/components/UiButton.vue'
import UiIcon from '@/domain/components/UiIcon.vue'

export default {
    name: 'DocumentSidebar',
    components: { UiIcon, UiButton }
}
</script>

<style lang='scss'>
.document-sidebar {
    position: sticky;
    overflow: auto;

    &__sticky {
        position: sticky;
        top: 0;
        background: #FFFFFF;
        z-index: 3;
    }

    &__container {
        padding: 0 0 25px 25px;
    }

    &__nav {
        &-text {
            color: #70778C;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */
            letter-spacing: 0.14px;
            text-transform: uppercase;
        }
    }

    &__header {
        gap: 1rem;

        .ui-icon {
            font-size: 3rem;
            color: #32398B;
        }

        h3 {
            color: #1C2538;
            font-size: 21px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
        }

        span {
            color: #70778C;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }

}
</style>