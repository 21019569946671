<template>
    <div>
        <select-field
            :name='name'
            :multiple='multiple'
            :filtered='filtered'
            :clearable='clearable'
            :style="{
            width: '250px'
        }"
            v-bind='$attrs'
            v-model='innerValue'
            @input='onChange'
            :is-sticky='isSticky'
        />
    </div>
</template>
<script>
import FormSelect from '@/components/ui/form/FormSelect'
import SelectField from '@/domain/fields/SelectField'

export default {
    name: 'HraFilterSelect',
    components: { SelectField, FormSelect },
    props: {
        name: { type: String, required: true },
        isSticky: { type: Boolean, default: false },
        multiple: { type: Boolean, default: false },
        filtered: { type: Boolean, default: false },
        clearable: { type: Boolean, default: false }
    },
    inject: ['filter', 'changeFilter'],
    data() {
        return {
            innerValue: this.filter[this.name]
        }
    },
    methods: {
        onChange(value) {
            this.changeFilter(this.name, value)
        }
    }
}
</script>
