<template>
    <custom-dialog :max-width='521' ref='dialog'>
        <template #title>
            {{ $t(selectLength > 1 ? 'Ar tikrai norite atleisti darbuotojus?' : 'Ar tikrai norite atleisti darbuotoją?')
            }}
        </template>
        <!--    <template #title>{{ $t('Ar tikrai norite pašalinti darbuotojus?') }}</template>-->
        <template #content>
            <v-row>
                <v-col
                    :cols='employees.length === 1 ? 12 : 6'
                    v-for='employee in employees'
                    :key='employee.id'
                >
                    <employee-table-view
                        :employee='employee'
                        :avatar-size='60'
                        style='flex: 30%;'
                    />
                </v-col>
                <!--        <v-col cols="12">-->
                <!--          <select-field full-width name="employeeDelete" :options="deleteOptions"-->
                <!--                                 :label="$t('Nurodykite pašalinimo priežastį')"-->
                <!--                                 v-model="employeeDelete"/>-->
                <!--        </v-col>-->
                <v-col cols='12'>
                    <select-field full-width name='employeeDeleteReasonId'
                                  :single-line='false'
                                  filtered
                                  :options='deleteReasonOptions'
                                  :label="$t('Nurodykite atleidimo priežastį')"
                                  v-model='employeeDeleteReasonId' />
                </v-col>
                <v-col cols='12'>
                    <datepicker-field name='date'
                                      :label="$t('Atleidimo data')"
                                      v-model='date' />
                </v-col>
                <v-col cols='12' v-if='infoMessage'>
                    <form-alert v-bind='infoMessage' />
                    <message-alert
                        v-if='!!error'
                        class='mt-2'
                        id='---'
                        :message='error'
                        type='error'
                    />
                </v-col>
            </v-row>
        </template>
        <template #footer>
            <ui-button
                background='default'
                prepend-icon='close'
                @click='close'
                :loading='loading'
            >{{ $t('Uždaryti') }}</ui-button>
            <!--      <hra-confirm v-if="employeeDelete === 0"-->
            <!--                   :title="$t(selectLength > 1 ? 'Ar tikrai norite pašalinti darbuotojus?' : 'Ar tikrai norite pašalinti darbuotoją?')"-->
            <!--                   @confirm="onDeleteEmployees($event)"-->
            <!--                   :abort-text="$t('Ne')"-->
            <!--                   :confirm-text="$t('Taip')">-->
            <!--        <hra-button :color="uiColor.dangerLight">-->
            <!--          <hra-icon :name="iconName.bin"/>-->
            <!--          {{ $t("Pašalinti") }}-->
            <!--        </hra-button>-->
            <!--      </hra-confirm>-->


            <ui-button
                v-if='employeeDeleteReasonId'
                prepend-icon='employee-delete'
                :confirm='buttonDeleteConfirm'
                background='danger'
                :loading='loading'
            >
                {{ $t('Atleisti') }}
            </ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import CustomDialog from '@/components/ui/dialog/CustomDialog'
import { mapGetters } from 'vuex'
import EmployeeTableView from '@/components/ui/views/EmployeeTableView'
import SelectField from '@/domain/fields/SelectField'
import HraButton from '@/components/ui/button/HraButton'
import HraIcon from '@/components/ui/HraIcon'
import employeeDeleteReasonsService from '@/services/employees/employeeDeleteReasonsService'
import dialogMixin from '@/mixins/dialogMixin'
import HraDialogConfirm from '@/components/ui/dialog/HraDialogConfirm'
import employeesDeleteService from '@/services/employees/employeesDeleteService'
import employeesDismissService from '@/services/employees/employeesDismissService'
import moment from 'moment'
import DatepickerField from '@/domain/fields/DatepickerField'
import HraConfirm from '@/components/ui/dialog/HraConfirm'
import FormAlert from '@/components/ui/form/FormAlert'
import CustomApi from '@/plugins/customApi'
import UiButton from '@/domain/components/UiButton.vue'
import MessageAlert from '@/domain/messages/components/MessageAlert.vue'

const customApi = CustomApi()

export default {
    name: 'EmployeeDeleteModal',
    mixins: [dialogMixin],
    components: {
        MessageAlert,
        UiButton,
        FormAlert,
        HraConfirm,
        DatepickerField,
        HraDialogConfirm, HraIcon, HraButton, SelectField, EmployeeTableView, CustomDialog
    },
    props: {
        ids: { type: Array, required: true }
    },
    data() {
        return {
            loading: false,
            employeeDelete: 1,
            employeeDeleteReasonId: null,
            date: null,
            error: null
        }
    },
    watch: {
        infoMessage: {
            handler(message) {
                if (message) {
                    this.$nextTick(() => {
                        this.$nextTick(() => {
                            this.$refs.dialog?.scrollToContentBottom()
                        })
                    })
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            getEmployees: 'employees/getEmployees',
            deleteReasonOptions: 'employees/deleteReasonOptions'
        }),
        selectLength() {
            return this.ids.length
        },
        employees() {
            return this.getEmployees(this.ids)
        },
        deleteOptions() {
            return [
                { value: 0, text: this.$t('Klaida įkeliant darbuotoją') },
                { value: 1, text: this.$t('Darbo sutarties nutraukimas') }
            ]
        },
        infoMessage() {
            switch (this.employeeDelete) {
                case 0:
                    return {
                        type: 'info',
                        text: this.$t('Informacija apie darbuotoją bus visam laikui pašalinta iš duomenų bazės.')
                    }
                case 1:
                    return {
                        type: 'info',
                        text: this.$t('Informacija apie darbuotoją ir toliau bus saugoma bei pasiekiama darbuotojų sąraše pasirinkus filtrą „atleisti darbuotoja“.')
                    }
                default:
                    return null
            }
        },
        buttonDeleteConfirm() {
            return {
                props: {
                    title: this.$t(this.selectLength > 1 ? 'Ar tikrai norite atleisti darbuotojus?' : 'Ar tikrai norite atleisti darbuotoją?'),
                },
                on: {
                    confirmed: (confirm) => {
                        confirm.close()
                        this.deleteEmployees()
                    }
                }
            }
        },
    },
    created() {
        this.date = moment().format('YYYY-MM-DD')
        this.fetchDeleteReasons();
    },
    methods: {
        async fetchDeleteReasons() {
            if (this.deleteReasonOptions.length > 0) {
                return
            }

            try {
                const { data } = await customApi.factories.employee.deleteReasons()
                await this.$store.dispatch('employees/setDeleteReasons', data)
            } catch (e) {
                console.error(e)
            }
        },

        async deleteEmployees() {
            this.loading = true
            this.error = null

            try {
                const dismisses = this.ids.map(employeeId => ({
                    userId: employeeId,
                    date: this.date,
                    dismissReasonId: this.employeeDeleteReasonId
                }))

                const { data } = await customApi.factories.employee.dismiss({ dismisses })
                await this.$store.dispatch('employees/dismissEmployees', data)

                this.close()
            } catch (error) {
                console.debug(error);
                this.error = error?.response?.data?.message ?? this.$t('Serverio klaida')
            } finally {
                this.loading = false
            }
        }
    }
}
</script>
