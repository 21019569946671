import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    lt: {
        ...require('@/assets/lang/lt.json'),
        ...require('@/domain/settings/lang/lt.json')
    },
    en: {
        ...require('@/assets/lang/en.json'),
        ...require('@/domain/settings/lang/eng.json')
    },
};

const selectedLocale = localStorage.getItem('bond_locale') ?? 'lt';

const options = {
    locale: selectedLocale,
    silentTranslationWarn: true, // to disable not translation warnings
    // pluralizationRules: {
    //     'lt': function (choice, choicesLength) {
    //         if (choice === 0) {
    //             return 0;
    //         }
    //
    //         if (choice === 1) {
    //             return 1;
    //         }
    //
    //         if (choice < 10) {
    //             return 2;
    //         }
    //
    //         if (choice > 9 && choice < 21) {
    //             return 3;
    //         }
    //
    //         if (choice % 10 === 0) {
    //             return 3;
    //         }
    //
    //         return 2;
    //     }
    // },
    messages,
    // missing: (locale, key) => {
    //     console.debug(`"${key}": "",`);
    // }
};

export default new VueI18n(options);
