import {
    CREATE_NEW_LINE,
    UPDATE_LINE_BY_ID,
    DELETE_LINE_BY_ID,
    DELETE_TIME_INTERVAL_BY_ID,
    UPDATE_TIME_INTERVAL_BY_ID,
    CREATE_NEW_TIME_INTERVAL,
    SET_SCHEDULE_ID,
    SET_SCHEDULE_NAME,
    SET_FROM_DATETIME,
    SET_TO_DATETIME,
    INIT_LINES,
    INIT_TIME_INTERVALS,
    INIT_EVENTS,
    SET_SELECTED_TIME_INTERVAL,
    CREATE_NEW_EVENT,
    SET_DURATION_BY_USER_ID,
    DELETE_EVENT_BY_ID,
    UPDATE_EVENT_BY_ID,
    SET_CURRENT_TAB,
    CREATE_NEW_TIMETABLE,
    INIT_TIMETABLES,
    CREATE_NEW_BREAK,
    UPDATE_BREAK_BY_ID,
    DELETE_BREAK_BY_ID,
    INIT_BREAKS,
    SET_MINUS_BREAKS,
    SET_HOLIDAYS,
    TOGGLE_BREAK_BY_ID,
    CREATE_NEW_TIMETABLE_INTERVAL,
    UPDATE_TIMETABLE_INTERVAL_BY_ID,
    DELETE_TIMETABLE_INTERVAL_BY_ID,
    INIT_TIMETABLE_INTERVALS,
    SET_SELECTED_TIMETABLE_INTERVAL, UPDATE_TIMETABLE_BY_ID, DELETE_TIMETABLE_BY_ID, INIT_SCHEDULES
} from '@/domain/scheduler/store/mutations'
import moment from 'moment'
import customApi from '@/plugins/customApi'
import { GLOBAL_TOAST_TAG } from '@/utils/default'
import router from '@/router'

/* BASE */
export const fetchSchedule = ({ commit }, { scheduleId, from, to }) => {
    const api = customApi(GLOBAL_TOAST_TAG)
    const year = moment(from).year()
    scheduleId = parseInt(scheduleId)

    commit(SET_SCHEDULE_ID, scheduleId)
    commit(SET_FROM_DATETIME, from)
    commit(SET_TO_DATETIME, to)


    const start = 8
    const end = 21

    let times = [
        [7, 12],
        [18, 24],
        [8, 21],
        [11, 14],
        [13, 20],
        [8, 21],
        [7, 14],
        [12, 19],
        [20, 21]
    ]
        .map(([a, b]) => [Math.max(a, start), Math.min(b, end)])
        .sort((a, b) => a[0] < b[0] ? -1 : 1)


    let usersCount = 0


    let x = times.length
    let select = times.shift()
    while (select != null && x > 0) {
        if (select[0] === start && select[1] === end) {
            usersCount++;
            x = times.length
            select = times.shift()
            continue;
        }

        for (let i = 0; i < times.length; i++) {
            let isFind = false
            const [a, b] = times[i]

            if (select[0] < a && select[1] >= a) {
                times[i][0] = select[0]
                select[0] = a
                isFind = true
            }

            if (select[1] > b && select[0] <= b) {
                times[i][1] = select[1]
                select[1] = b
                isFind = true
            }

            if (isFind) {
                break;
            }
        }

        select[0] !== select[1] && times.push(select)
        select = times.shift()

        x--
    }

    api.requests([
        api.factories.schedules.getById(scheduleId),
        api.factories.schedules.fetchEventsByScheduleIdInInterval(scheduleId, { from, to }),
        api.factories.schedules.fetchTimetablesByScheduleIdInInterval(scheduleId, { from, to }),
        api.factories.calendar.holidays({ year, countryCode: 'LT' }),
        api.factories.schedules.fetchList()
    ]).then(([schedule, events, timetables, holidays, schedules]) => {
        commit(INIT_TIME_INTERVALS, schedule.timeIntervals)
        commit(SET_SCHEDULE_NAME, schedule.name)
        commit(INIT_TIMETABLE_INTERVALS, schedule.timetableIntervals)
        commit(INIT_LINES, schedule.lines)
        commit(INIT_BREAKS, schedule.breaks)
        commit(INIT_EVENTS, events)
        commit(INIT_TIMETABLES, timetables)
        commit(SET_HOLIDAYS, holidays)
        commit(INIT_SCHEDULES, schedules)
    })
}
export const prevMonth = ({ commit, dispatch, state: { from, to } }) => {
    from = moment(from).subtract(1, 'month')
    commit(SET_FROM_DATETIME, from.format('YYYY-MM-DD HH:mm:ss'))
    commit(SET_TO_DATETIME, moment(to).subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss'))
    dispatch('fetchEvents')
    dispatch('fetchTimetables')
    dispatch('fetchHolidays')

    router.push({ query: { date: from.format('YYYY-MM-DD') } })
}
export const nextMonth = ({ commit, dispatch, state: { from, to } }) => {
    from = moment(from).add(1, 'month')
    commit(SET_FROM_DATETIME, from.format('YYYY-MM-DD HH:mm:ss'))
    commit(SET_TO_DATETIME, moment(to).add(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss'))
    dispatch('fetchEvents')
    dispatch('fetchTimetables')
    dispatch('fetchHolidays')

    router.push({ query: { date: from.format('YYYY-MM-DD') } })
}
export const fetchHolidays = ({ commit, state: { from, to } }) => {
    const api = customApi(GLOBAL_TOAST_TAG)
    const year = moment(from).year()

    api.request(
        api.factories.calendar.holidays({ year, countryCode: 'LT' })
    ).then(result => {
        commit(SET_HOLIDAYS, result ?? [])
    })
}
export const changeSelectedTimeInterval = ({ commit }, payload) => {
    commit(SET_SELECTED_TIME_INTERVAL, payload)
}
export const changeSelectedTimetableInterval = ({ commit }, payload) => {
    commit(SET_SELECTED_TIMETABLE_INTERVAL, payload)
}
export const changeDurationByUserId = ({ commit }, payload) => {
    commit(SET_DURATION_BY_USER_ID, payload)
}
export const changeCurrentTab = ({ commit }, payload) => {
    commit(SET_SELECTED_TIME_INTERVAL, null)
    commit(SET_SELECTED_TIMETABLE_INTERVAL, null)
    commit(SET_CURRENT_TAB, payload)
}
export const generateAutoBreaks = async ({ dispatch, state: { scheduleId } }, payload) => {
    const api = customApi(GLOBAL_TOAST_TAG)
    const result = await api.request(api.factories.schedules.generateAutoBreaks({
        scheduleId,
        ...payload
    }))

    if (result == null) {
        return false
    }

    dispatch('fetchEvents')
    return true
}
export const changeMinusBreaks = ({ commit }, payload) => {
    commit(SET_MINUS_BREAKS, payload)
}

/* LINES */
export const createNewLine = async ({ commit, dispatch, state: { scheduleId } }, payload) => {
    const api = customApi(GLOBAL_TOAST_TAG)
    const result = await api.request(api.factories.schedules.lines.create({
        ...payload,
        scheduleId
    }))

    if (result == null) {
        return false
    }

    commit(CREATE_NEW_LINE, result)
    dispatch('fetchEvents')
    dispatch('fetchBreaks')
    return true
}
export const updateLineById = async ({ commit, dispatch, state: { scheduleId } }, { id, payload }) => {
    const api = customApi(GLOBAL_TOAST_TAG)
    const result = await api.request(api.factories.schedules.lines.update(id, {
        scheduleId,
        ...payload
    }))

    if (result == null) {
        return false
    }

    commit(UPDATE_LINE_BY_ID, result)
    return true
}
export const deleteLineById = async ({ commit, dispatch }, payload) => {
    const api = customApi(GLOBAL_TOAST_TAG)
    const result = await api.request(api.factories.schedules.lines.destroy(payload))

    if (result == null) {
        return false
    }

    commit(DELETE_LINE_BY_ID, payload)
    return true
}

/* BREAKS */
export const toggleBreakById = ({ commit }, payload) => {
    commit(TOGGLE_BREAK_BY_ID, payload)
}

export const fetchBreaks = async ({ commit, state: { scheduleId } }) => {
    const api = customApi(GLOBAL_TOAST_TAG)
    const result = await api.request(
        api.factories.schedules.fetchBreaksByScheduleIdInInterval(scheduleId)
    )

    if (result == null) {
        return false
    }

    commit('INIT_BREAKS', result)
    return true
}
export const createNewBreak = async ({ commit, dispatch, state: { scheduleId } }, payload) => {
    const api = customApi(GLOBAL_TOAST_TAG)
    const result = await api.request(api.factories.schedules.breaks.create({
        ...payload,
        scheduleId
    }))

    if (result == null) {
        return false
    }

    commit(CREATE_NEW_BREAK, result)
    return true
}
export const updateBreakById = async ({ commit, state: { scheduleId } }, { id, payload }) => {
    const api = customApi(GLOBAL_TOAST_TAG)
    const result = await api.request(api.factories.schedules.breaks.update(id, {
        ...payload,
        scheduleId
    }))

    if (result == null) {
        return false
    }

    commit(UPDATE_BREAK_BY_ID, result)
    return true
}
export const deleteBreakById = async ({ commit, dispatch }, payload) => {
    const api = customApi(GLOBAL_TOAST_TAG)
    const result = await api.request(api.factories.schedules.breaks.destroy(payload))

    if (result == null) {
        return false
    }

    commit(DELETE_BREAK_BY_ID, payload)
    dispatch('fetchEvents')
    return true
}


/* TIME INTERVALS */
export const createNewTimeInterval = async ({ commit, state: { scheduleId } }, payload) => {
    const api = customApi(GLOBAL_TOAST_TAG)
    const result = await api.request(api.factories.schedules.timeIntervals.create({
        ...payload,
        scheduleId
    }))

    if (result == null) {
        return false
    }

    commit(CREATE_NEW_TIME_INTERVAL, result)
    return true
}
export const updateTimeIntervalById = async ({ commit, state: { scheduleId } }, { id, payload }) => {
    const api = customApi(GLOBAL_TOAST_TAG)
    const result = await api.request(api.factories.schedules.timeIntervals.update(id, {
        ...payload,
        scheduleId
    }))

    if (result == null) {
        return false
    }

    commit(UPDATE_TIME_INTERVAL_BY_ID, result)
    return true
}
export const deleteTimeIntervalById = async ({ commit }, payload) => {
    const api = customApi(GLOBAL_TOAST_TAG)
    const result = await api.request(api.factories.schedules.timeIntervals.destroy(payload))

    if (result == null) {
        return false
    }

    commit(DELETE_TIME_INTERVAL_BY_ID, payload)
    return true
}

/* EVENTS */
export const fetchEvents = ({ commit, state: { scheduleId, from, to } }) => {
    const api = customApi(GLOBAL_TOAST_TAG)
    api.request(
        api.factories.schedules.fetchEventsByScheduleIdInInterval(scheduleId, { from, to })
    ).then(result => {
        commit(INIT_EVENTS, result ?? [])
    })
}
export const createNewUserEvent = async ({ commit, state, dispatch }, { date, row }) => {
    const api = customApi(GLOBAL_TOAST_TAG)

    if (state.selectedTimeInterval == null) {
        dispatch('messagesStore/showErrorMessage', {
            tag: GLOBAL_TOAST_TAG,
            message: 'Pasirinkite darbo laika!'
        }, { root: true })

        return
    }

    const timeInterval = state.selectedTimeInterval
    const start = date.clone().startOf('day').add(moment.duration(timeInterval.start))
    const end = date.clone().startOf('day').add(moment.duration(timeInterval.end))

    if(moment(start).isSameOrAfter(end)) {
        end.add(1, 'days');
    }

    const result = await api.request(api.factories.schedules.events.create({
        scheduleId: row.scheduleId,
        scheduleLineId: row.id,
        userId: row.userId,
        start: start.format('YYYY-MM-DD HH:mm:ss'),
        end: end.format('YYYY-MM-DD HH:mm:ss')
    }))

    if (result == null) {
        return
    }

    commit(CREATE_NEW_EVENT, result)
    dispatch('fetchTimetables')
}
export const updateEventById = async ({ commit, dispatch }, { id, payload }) => {
    const api = customApi(GLOBAL_TOAST_TAG)

    const event = await api.request(api.factories.schedules.events.update(id, payload))
    if (event == null) {
        return false
    }

    commit(UPDATE_EVENT_BY_ID, event)
    dispatch('fetchTimetables')
    return true
}
export const updateEventBreaksById = async ({ commit, dispatch }, { id, payload }) => {
    const api = customApi(GLOBAL_TOAST_TAG)

    const event = await api.request(api.factories.schedules.events.updateBreaks(id, payload))
    if (event == null) {
        return false
    }

    commit(UPDATE_EVENT_BY_ID, event)
    return true
}
export const deleteEventById = async ({ commit, dispatch }, payload) => {
    const api = customApi()
    const result = await api.request(api.factories.schedules.events.destroy(payload))
    if (result == null) {
        return false
    }

    commit(DELETE_EVENT_BY_ID, payload)
    dispatch('fetchTimetables')
    return true
}

/* TIMETABLES */
export const fetchTimetables = ({ commit, state: { scheduleId, from, to } }) => {
    const api = customApi(GLOBAL_TOAST_TAG)
    api.request(
        api.factories.schedules.fetchTimetablesByScheduleIdInInterval(scheduleId, { from, to })
    ).then(result => {
        commit(INIT_TIMETABLES, result ?? [])
    })
}
export const generateTimetablesByFilter = async ({ commit, dispatch }, payload) => {
    const api = customApi(GLOBAL_TOAST_TAG)

    const event = await api.request(api.factories.schedules.timetables.generate(payload))
    if (event == null) {
        return false
    }

    dispatch('fetchTimetables')
    return true
}
export const createNewTimetable = async ({ commit, dispatch, state: { selectedTimetableInterval } }, { date, row }) => {
    const api = customApi(GLOBAL_TOAST_TAG)

    if (selectedTimetableInterval == null) {
        dispatch('messagesStore/showErrorMessage', {
            tag: GLOBAL_TOAST_TAG,
            message: 'Pasirinkite darbo vietos laika!'
        }, { root: true })

        return
    }

    const start = date.clone().startOf('day').add(moment.duration(selectedTimetableInterval.start))
    const end = date.clone().startOf('day').add(moment.duration(selectedTimetableInterval.end))

    if(moment(start).isSameOrAfter(end)) {
        end.add(1, 'days');
    }

    const result = await api.request(api.factories.schedules.timetables.create({
        scheduleId: row.scheduleId,
        scheduleLineId: row.id,
        start: start.format('YYYY-MM-DD HH:mm:ss'),
        end: end.format('YYYY-MM-DD HH:mm:ss'),
        minUsers: selectedTimetableInterval.minUsers
    }))

    if (result == null) {
        return
    }

    commit(CREATE_NEW_TIMETABLE, result)
}
export const updateTimetableById = async ({ commit }, { id, payload }) => {
    const api = customApi(GLOBAL_TOAST_TAG)

    const event = await api.request(api.factories.schedules.timetables.update(id, payload))
    if (event == null) {
        return false
    }

    commit(UPDATE_TIMETABLE_BY_ID, event)
    return true
}
export const deleteTimetableById = async ({ commit }, payload) => {
    const api = customApi()
    const result = await api.request(api.factories.schedules.timetables.destroy(payload))
    if (result == null) {
        return false
    }

    commit(DELETE_TIMETABLE_BY_ID, payload)
    return true
}

/* TIMETABLE INTERVALS */
export const createNewTimetableInterval = async ({ commit, state: { scheduleId } }, payload) => {
    const api = customApi(GLOBAL_TOAST_TAG)
    const result = await api.request(api.factories.schedules.timetableIntervals.create({
        ...payload,
        scheduleId
    }))

    if (result == null) {
        return false
    }

    commit(CREATE_NEW_TIMETABLE_INTERVAL, result)
    return true
}
export const updateTimetableIntervalById = async ({ commit, state: { scheduleId } }, { id, payload }) => {
    const api = customApi(GLOBAL_TOAST_TAG)
    const result = await api.request(api.factories.schedules.timetableIntervals.update(id, {
        ...payload,
        scheduleId
    }))

    if (result == null) {
        return false
    }

    commit(UPDATE_TIMETABLE_INTERVAL_BY_ID, result)
    return true
}
export const deleteTimetableIntervalById = async ({ commit }, payload) => {
    const api = customApi(GLOBAL_TOAST_TAG)
    const result = await api.request(api.factories.schedules.timetableIntervals.destroy(payload))

    if (result == null) {
        return false
    }

    commit(DELETE_TIMETABLE_INTERVAL_BY_ID, payload)
    return true
}