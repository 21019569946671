<template>
    <custom-dialog is-confirm :max-width='400'>
        <template #title>{{ title }}</template>
        <template #content>
            <form-alert v-if='message' v-bind='message' />
            <full-row-loader v-if='loading' />
        </template>
        <template #buttons>
            <ui-button v-if='welcomeButtons' full-width @click='close'>{{ $t('Ne') }}</ui-button>
            <ui-button v-if='welcomeButtons' full-width background='primary' @click='sendInvites'>{{ $t('Taip') }}
            </ui-button>
            <ui-button v-if='!isError && loading' background='danger' @click='abort' full-width>{{ $t('Atšaukti') }}
            </ui-button>
            <ui-button v-if='isError && !loading' @click='sendInvites' full-width>{{ $t('Bandyti dar kartą') }}
            </ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import { mapMutations } from 'vuex'
import HraButton from '@/components/ui/button/HraButton'
import HraIcon from '@/components/ui/HraIcon'
import dialogMixin from '@/mixins/dialogMixin'
import CustomDialog from '@/components/ui/dialog/CustomDialog'
import FormMessages from '@/components/ui/form/FormMessages'
import FormAlert from '@/components/ui/form/FormAlert'
import FullRowLoader from '@/components/ui/FullRowLoader'
import customApi from '@/plugins/customApi'
import UiButton from '@/domain/components/UiButton.vue'

export default {
    name: 'SentInviteModal',
    mixins: [dialogMixin],
    components: { UiButton, FullRowLoader, FormAlert, FormMessages, CustomDialog, HraIcon, HraButton },
    props: {
        ids: { type: Array, default: null }
    },
    data: () => ({
        closeDialogDelay: 1000,
        message: null,
        checkInterval: null,
        loading: false,
        welcomeButtons: true,
        jobId: null
    }),
    created() {
        // this.sendInvites();
    },
    destroyed() {
        this.clearTableData()
    },
    computed: {
        isError() {
            return this.message && this.message.type === 'error'
        },
        isMany() {
            return this.ids == null || this.ids?.length > 1
        },
        title() {
            if (this.isMany) {
                return this.loading ? this.$t('Pakvietimai siunčiami...') : this.$t('Ar tikrai norite išsiųsti pakvietimus?')
            }

            return this.loading ? this.$t('Pakvietimas siunčiamas...') : this.$t('Ar tikrai norite išsiųsti pakvietimą?')
        }
    },
    beforeDestroy() {
        clearInterval(this.checkInterval)
    },
    methods: {
        ...mapMutations({
            checkSentInvites: 'employees/checkSentInvites',
            clearTableData: 'employees/clearTableData'
        }),
        showSuccessMessage() {
            this.loading = false
            this.message = {
                type: 'success',
                text: this.isMany
                    ? this.$t('Pakvietimai buvo išsiųsti sėkmingai')
                    : this.$t('Pakvietimas buvo išsiųstas sėkmingai')
            }
            this.closeWithDelay()
        },
        showErrorMessage() {
            this.loading = false
            this.message = {
                type: 'error',
                text: this.$t('Siuntimo klaida')
            }
        },
        async checkInvitesStatus(id) {
            const api = customApi('INVITE_STATUS_TAG')
            const response = await api.request(api.factories.user.invites.status(id))

            if (response.finished) {
                clearInterval(this.checkInterval)
                this.showSuccessMessage()
                return
            }

            if (response.failed) {
                clearInterval(this.checkInterval)
                this.showErrorMessage()
            }
        },
        async abort() {
            if (this.jobId == null) {
                this.close()
            }

            const api = customApi('INVITE_ABORT_TAG')
            await api.request(api.factories.user.invites.abort(this.jobId))
            this.close()
        },
        async sendInvites() {
            this.welcomeButtons = false
            this.loading = true
            this.message = null

            const api = customApi('SEND_INVITE_TAG')
            const response = await api.request(
                this.ids == null
                    ? api.factories.user.invites.sendAll()
                    : api.factories.user.invites.sendMany(this.ids)
            )

            if (response == null) {
                this.showErrorMessage()
                return
            }

            this.jobId = response.batchId
            this.checkInterval = setInterval(() => {
                this.checkInvitesStatus(response.batchId)
            }, 2000)
        }
    }
}
</script>
