<template>
	<employee-crud-table>
		<hra-table :headers="headers" :rows="rows" hide-headers class="no-border-around">
			<template #header.custom>
				<table-title-view class="mb-3 size-1" :title="$t('Kasmetinės atostogos')" with-border/>
			</template>

			<template #col.title="{colValue}">
				<div class="pr-3 py-6">{{ colValue }}</div>
			</template>

			<template #col.value="{colValue}">
				<div class="px-3 py-6 text-right">{{ colValue }}</div>
			</template>
		</hra-table>
	</employee-crud-table>
</template>

<script>
import {mapGetters} from "vuex";
import HraTable from "@/components/ui/table/HraTable";
import TableTitleView from "@/components/ui/views/TableTitleView";
import EmployeeCrudTable from "@/domain/employee/components/EmployeeCrudTable";

export default {
	name: "EmployeeVacationInfoTable",
	components: {
		EmployeeCrudTable,
		TableTitleView,
		HraTable
	},
	data: () => ({
		headers: [{name: "title", style: {width: "500px"}}, {name: "value", style: {minWidth: "100px"}}],
	}),
	computed: {
		...mapGetters({
			vacationInfo: "employeeStore/vacationInfo",
		}),
		rows() {
			let rows = [];

			rows.push({
				title: ["Pagal Darbo kodeksą atostogų per metus priklauso", {}],
				value: ["{count} d. d.", {count: this.vacationInfo.daysPerYear.toFixed(2)}]
			});
			rows.push({
				title: ["Papildomai suteikiamų atostogų skaičius per metus", {}],
				value: ["{count} d. d.", {count: this.vacationInfo.plusDays.toFixed(2)}]
			});
			if (this.vacationInfo.motherFatherDays.accumulated > 0) {
				rows.push({
					title: ["Mamadienis/tėvadienis nuo {fromDate} iki {toDate}", {
						fromDate: this.vacationInfo.motherFatherDays.fromDate,
						toDate: this.vacationInfo.motherFatherDays.toDate
					}],
					value: ["{reserved} iš {accumulated} d. d.", {...this.vacationInfo.motherFatherDays}]
				});
			}
			rows.push({
				title: ["Atostogų likutis šiandien", {}],
				value: ["{count} d. d.", {count: this.vacationInfo.yearly.accumulated.toFixed(2)}]
			});
			rows.push({
				title: ["Atostogoms rezervuotų skaičius", {}],
				value: ["{count} d. d.", {count: this.vacationInfo.yearly.reserved.toFixed(2)}]
			});
			rows.push({
				title: ["Nepanaudotų atostogų likutis", {}],
				value: ["{count} d. d.", {count: this.vacationInfo.yearly.balance.toFixed(2)}]
			});

			return rows.map(({title, value}) => ({
				title: this.$t.apply(this, title),
				value: this.$t.apply(this, value),
			}));
		},
	},
}
</script>
