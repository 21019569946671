<template>
    <fragment v-if='visible'>
        <department-event-row-action-context-menu-item
            v-if='canApprove'
            icon='ok'
            :title='$t("Tvirtinti")'
            @click='handleApprove'
        />
        <department-event-row-action-context-menu-item
            v-if='canAbort'
            icon='close'
            :title='$t("Atmesti")'
            @click='handleAbort'
        />
        <department-event-row-action-context-menu-item
            v-if='canEdit'
            icon='edit'
            :title='$t("Redaguoti")'
            @click='handleUpdate'
        />
        <department-event-row-action-context-menu-item
            v-if='canInfo'
            icon='info'
            :title='$t("Informacija")'
            @click='handleInfo'
        />
    </fragment>
</template>

<script>
import DepartmentEventRowActionContextMenuItem
    from '@/domain/departmentVacations/components/actions/DepartmentEventRowActionContextMenuItem.vue'
import modalMethodsMixin from '@/domain/mixins/modalMethodsMixin'
import checkIsAdminMixin from '@/mixins/checkIsAdminMixin'
import checkIsLeaderMixin from '@/mixins/checkIsLeaderMixin'
import CustomApi from '@/plugins/customApi'
import { GLOBAL_TOAST_TAG } from '@/utils/default'

const customApi = CustomApi()
export default {
    name: 'BusinessTripContextMenuItems',
    components: { DepartmentEventRowActionContextMenuItem },
    mixins: [checkIsLeaderMixin, checkIsAdminMixin, modalMethodsMixin],
    inject: ['createRow', 'updateRow'],
    props: {
        row: { type: Object, required: true }
    },
    computed: {
        visible() {
            return [5001, 5002].includes(this.row.type)
        },
        canEdit() {
            return this.row.currentStatus !== 'aborted' && this.isAdmin
        },
        canApprove() {
            return this.row.currentStatus === 'pending' && this.isAdmin
        },
        canAbort() {
            return this.row.currentStatus !== 'aborted' && this.isAdmin
        },
        canInfo() {
            return this.isAdmin || this.isLeader
        }
    },
    methods: {
        handleUpdate() {
            this.onOpenModal('BusinessTripsModal', {
                id: this.row.id,
                asAdmin: this.isAdmin,
                asLeader: this.isLeader
            }, {
                created: (data) => this.createRow(data),
                updated: (data) => this.updateRow(data)
            })
        },
        handleApprove() {
            this.onOpenModal('ConfirmModal', {
                title: this.$t('Ar tikrai norite patvirtinti komandiruotę?')
            }, {
                confirmed: async (confirm) => {
                    try {
                        const method = this.isAdmin ? 'admin' : 'leader'
                        const { data } = await customApi.factories.events.businessTrip.approve(this.row.id, method)
                        await this.$store.dispatch('messagesStore/showSuccessMessage', {
                            tag: GLOBAL_TOAST_TAG,
                            message: this.$t('Komandiruotė patvirtinta')
                        })
                        this.updateRow(data)
                    } catch (e) {
                        console.error(e)
                        await this.$store.dispatch('messagesStore/showErrorMessage', {
                            tag: GLOBAL_TOAST_TAG,
                            message: this.$t('Nepavyko patvirtinti komadiruotės')
                        })
                    } finally {
                        confirm.close()
                    }
                }
            })
        },
        handleAbort() {
            this.onOpenModal('ConfirmModal', {
                title: this.$t('Ar tikrai norite atmesti komandiruotę?')
            }, {
                confirmed: async (confirm) => {
                    try {
                        const method = this.isAdmin ? 'admin' : 'leader'
                        const { data } = await customApi.factories.events.businessTrip.abort(this.row.id, method)
                        await this.$store.dispatch('messagesStore/showSuccessMessage', {
                            tag: GLOBAL_TOAST_TAG,
                            message: this.$t('Komandiruotė atmesta')
                        })
                        this.updateRow(data)
                    } catch (e) {
                        console.error(e)
                        await this.$store.dispatch('messagesStore/showErrorMessage', {
                            tag: GLOBAL_TOAST_TAG,
                            message: this.$t('Nepavyko atmesti komadiruotės')
                        })
                    } finally {
                        confirm.close()
                    }
                }
            })
        },
        handleInfo() {
            this.onOpenModal('BusinessTripsModal', {
                id: this.row.id,
                asAdmin: this.isAdmin,
                asLeader: this.isLeader,
                readonly: true
            })
        }
    }
}
</script>