<template>
    <expansion-panel :header="$t('Paskyros nustatymai')">
        <v-row>
            <v-col v-if='isCreate' cols='12' class='d-flex gap-3 align-end'>
                <select-field
                    style='width: 100%;'
                    name='role'
                    :label="$t('Paskyros tipas')"
                    :options='roleOptions'
                    v-model='formData.role'
                    :disabled='isDisabled'
                    @input='onChangeRole'
                />
            </v-col>
            <v-col v-else cols='12' class='d-flex gap-3 align-end'>
                <select-field
                    style='width: 100%;'
                    name='role'
                    :label="$t('Paskyros tipas')"
                    :options='roleOptions'
                    v-model='formData.role'
                    :disabled='isDisabled'
                />
                <div class='d-flex gap-3' v-if='isEdit'>
                    <ui-button v-if='isDisabled' background='primary' @click='onChange'>
                        {{ $t('Keisti') }}
                    </ui-button>
                    <ui-button v-if='!isDisabled' background='default' @click='onAbort'>
                        {{ $t('Atšaukti') }}
                    </ui-button>

                    <ui-button v-if='!isDisabled' background='primary' :loading='hasLoader' @click='onSave'>
                        {{ $t('Išsaugoti') }}
                    </ui-button>
                </div>
            </v-col>
            <v-col cols='12' v-if='isAdminRole'>
                <message-alert id='test' :message="$t('Administratorius turi visas teises')" type='error' />
            </v-col>
        </v-row>
    </expansion-panel>
</template>

<script>
import _ from 'lodash'
import ExpansionPanel from '@/domain/components/ExpansionPanel'
import SelectField from '@/domain/fields/SelectField'
import EmployeeCrudTable from '@/domain/employee/components/EmployeeCrudTable'
import HraTable from '@/components/ui/table/HraTable'
import FormRadio from '@/components/ui/form/FormRadio'
import HraTableDefaultEmpty from '@/components/ui/table/HraTableDefaultEmpty'
import HraButton from '@/components/ui/button/HraButton'
import { ADMIN_ROLE_ID, permissionsAll } from '@/utils/permissions'
import expansionEmployeeMixin from '@/domain/employee/mixins/expansionEmployeeMixin'
import { mapGetters } from 'vuex'
import MessageAlert from '@/domain/messages/components/MessageAlert.vue'
import UiButton from '@/domain/components/UiButton.vue'

export default {
    name: 'ExpansionEmployeePermissions',
    components: {
        UiButton,
        MessageAlert,
        HraButton, HraTableDefaultEmpty, FormRadio, HraTable, EmployeeCrudTable, SelectField, ExpansionPanel
    },
    mixins: [expansionEmployeeMixin],
    data() {
        return {
            tag: `EMPLOYEE_ROLE_SAVE_TAB_${(new Date()).getTime()}`,
            isDisabled: this.isEdit || this.isProfile,
            formData: {
                role: null
            },
            names: {
                'employee.create': this.$t('Pridėti darbuotojus'),
                'employee.edit': this.$t('Redaguoti darbuotojų informaciją'),
                'employee.delete': this.$t('Šalinti darbuotojus'),
                'vacation.leader.view': this.$t('Rodyti skyriaus atostogų grafiką'),
                'vacation.department.view': this.$t('Sudaryti skyriaus darbuotojų budėjimo grafiką'),
                'vacation.department.approve': this.$t('Tvirtinti skyriaus darbuotojų atostogas')
            }
        }
    },
    computed: {
        ...mapGetters({
            user: 'employeeStore/user',
            role: 'employeeStore/userRole'
        }),
        hasLoader() {
            return this.$store.getters['loadersStore/hasLoading'](this.tag)
        },
        roleOptions() {
            return [
                { value: 'admin', text: this.$t('Admin') },
                { value: 'employee', text: this.$t('Darbuotojas') }
            ]
        },
        isAdminRole() {
            return this.formData.role === ADMIN_ROLE_ID
        }
    },
    watch: {
        role: {
            handler(value) {
                if (value != null) {
                    this.getFormData()
                }
            },
            immediate: true
        }
    },
    methods: {
        getFormData() {
            this.formData = {
                role: this.role
            }
        },
        async onChangeRole(value) {
            const payload = {
                tag: this.tag,
                role: value,
                userId: null
            }
            await this.$store.dispatch('employeeStore/changeUserRole', payload)
        },
        onChange() {
            this.isDisabled = false
        },
        onAbort() {
            this.getFormData()
            this.isDisabled = true
        },
        async onSave() {
            const payload = {
                tag: this.tag,
                role: this.formData.role,
                userId: this.user.id
            }

            if (await this.$store.dispatch('employeeStore/changeUserRole', payload)) {
                this.getFormData()
                this.isDisabled = true
            }
        }
    }
}
</script>