var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{staticClass:"SelectField",attrs:{"tag":"div","immediate":"","rules":_vm.rules,"name":_vm.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var _obj;

var validationErrors = ref.errors;return [(_vm.readOnly)?_c('read-only-field',{attrs:{"invalid":!!validationErrors.length,"value":_vm.viewValue}}):_c('base-field',{ref:"field",attrs:{"invalid":!!validationErrors.length},scopedSlots:_vm._u([{key:"label-append",fn:function(){return [_vm._t("label-append")]},proxy:true}],null,true)},[_c('v-autocomplete',{ref:"select",staticClass:"field SelectField__field",class:Object.assign({}, _vm.fieldClasses, ( _obj = {}, _obj['invalid'] = !!validationErrors.length, _obj )),attrs:{"attach":_vm.attach,"name":_vm.name,"value":_vm.value,"readonly":_vm.hasDisabled,"items":_vm.items,"multiple":_vm.multiple,"solo":"","flat":"","hide-details":"","single-line":_vm.singleLine,"chips":_vm.multiple,"deletable-chips":!_vm.hasDisabled,"menu-props":_vm.menuProps,"search-input":_vm.searchText,"error":!!validationErrors.length,"clearable":_vm.clearable,"filter":_vm.filter},on:{"update:searchInput":function($event){_vm.searchText=$event},"update:search-input":function($event){_vm.searchText=$event},"focus":_vm.onOpenMenu,"blur":_vm.onCloseMenu,"change":_vm.onChangeSelect},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [(_vm.isEmpty)?_c('div',{staticClass:"SelectField__placeholder"},[_vm._v(_vm._s(_vm.selectPlaceholder))]):_vm._e(),_c('loaders',{attrs:{"listen":_vm.initTag}})]},proxy:true},{key:"prepend-item",fn:function(){return [(_vm.filtered)?_c('div',{staticClass:"SelectField__find"},[_c('v-text-field',{attrs:{"solo":"","flat":"","hide-details":"","placeholder":_vm.$t('ieškoti...'),"autofocus":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('i',{staticClass:"icon-find"})]},proxy:true}],null,false,516527914),model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1):_vm._e()]},proxy:true},{key:"selection",fn:function(ref){
var parent = ref.parent;
var item = ref.item;
return [(_vm.multiple)?_c('v-chip',{attrs:{"close":!_vm.hasDisabled},on:{"click:close":function () { return parent.onChipInput(item); }}},[_c('span',[_vm._v(_vm._s(item.text))])]):_c('div',{staticClass:"py-3"},[_vm._t("selection",function(){return [_c('div',{domProps:{"innerHTML":_vm._s(item.text)}})]},{"item":item})],2)]}},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("item",function(){return [_c('v-list-item',_vm._g(_vm._b({class:{hasParent: item.parent != null}},'v-list-item',attrs,false),on),[(_vm.multiple)?_c('v-list-item-action',[_c('v-simple-checkbox')],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(_vm.maskListItem(item.text))}})],1)],1)]},{"item":item,"on":on,"attrs":attrs})]}},{key:"append",fn:function(){return [_c('div',{staticClass:"d-flex gap-3 align-center"},[(_vm.multiple && !!_vm.hiddenChips)?_c('div',{staticClass:"chip-counter"},[_vm._v(" "+_vm._s(_vm.hiddenChips)+" ")]):_vm._e(),(_vm.hasArrow)?_c('hra-icon',{staticClass:"select-arrow clickable",attrs:{"name":_vm.iconName.down}}):_vm._e(),_c('editable-field-actions')],1)]},proxy:true},{key:"no-data",fn:function(){return [_c('loaders',{staticClass:"d-flex align-center justify-center pa-3",attrs:{"listen":_vm.dynamicTag}}),(!_vm.hasDynamicLoader)?_c('message-alert',{attrs:{"id":"select-field-empty","type":"error","message":_vm.$t('Rezultatų nėra'),"no-border-radius":""}}):_vm._e()]},proxy:true}],null,true)}),_c('div',{ref:"menu",style:({position: 'relative', width: '100%', zIndex: 90}),attrs:{"id":_vm.idName}})],1),(!!validationErrors.length > 0)?_c('div',{staticClass:"hra-text--red mt-1"},_vm._l((validationErrors),function(error){return _c('div',[_vm._v(_vm._s(error))])}),0):_vm._e(),(!!_vm.errors.length > 0)?_c('div',{staticClass:"hra-text--red mt-1"},_vm._l((_vm.errors),function(error){return _c('div',[_vm._v(_vm._s(error))])}),0):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }