import Vue from 'vue'
import { mapFolder } from '@/domain/documents/finder/store/mappers'

export default ({
    ['folders/changeId']({state, dispatch}, payload) {
        state.folderId = payload
        state.search = null
        dispatch('load')
    },
    ['folders/create']({state}, payload) {
        if (state.folderId !== payload.documentId) {
            return
        }

        state.items.push(mapFolder(payload))
    },
    ['folders/update']({state}, payload) {
        const index = state.items.findIndex(({id}) => id === payload.id)
        if (index === -1) {
            return
        }
        Vue.set(state.items, index, mapFolder(payload))
    },
    ['folders/delete']({state}, payload) {
        const index = state.items.findIndex(({id}) => id === payload)
        if (index === -1) {
            return
        }
        state.items.splice(index, 1)
    }
})