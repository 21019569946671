<script>
import FormAlert from "@/components/ui/form/FormAlert.vue";
import UiCalendar from "@/domain/components/UiCalendar/UiCalendar.vue";
import FormCheckbox from "@/components/ui/form/FormCheckbox.vue";
import SelectField from "@/domain/fields/SelectField.vue";
import MultilineSelectField from "@/domain/components/MultilineSelectField.vue";
import {mapGetters} from "vuex";
import moment from "moment/moment";

export default {
    name: "CopyScheduleTimetablesVerticalTab",
    components: {MultilineSelectField, SelectField, FormCheckbox, UiCalendar, FormAlert},
    data: () => {
        return {
            verticalTabValues: {
                from: {
                    timetableLine: null,
                    employeeLine: null,
                    date: null
                },
                to: {
                    timetableLines: {
                        0: {
                            id: 0,
                            employeeLines: []
                        }
                    },
                    date: null
                },
                activateDestroyer: false,
            },
            verticalCurrentTimetableFrom: null,
            verticalCurrentTimetableTo: null,
            verticalCurrentEmployeesTo: [],
            yearMonthFrom: null,
            yearMonthTo: null,
        }
    },
    watch: {
        verticalCurrentTimetableFrom: {
            handler(newValue, oldValue) {
                this.verticalTabValues.from.employeeLine = null;
                this.verticalCurrentTimetableTo = null;
                this.verticalCurrentEmployeesTo = [];

                this.verticalTabValues.from.timetableLine = newValue;
            }
        },
        verticalCurrentTimetableTo: {
            handler(newValue, oldValue) {
                this.verticalCurrentEmployeesTo = [];
                this.resetVerticalToTimetablesObj();

                if(!newValue) {
                    return;
                }

                this.verticalTabValues.to.timetableLines[newValue] = { id: newValue, employees: [] };
            },
            immediate: true,
            deep: true,
        },
        verticalCurrentEmployeesTo: {
            handler(newValue, oldValue) {
                const timetableId = this.verticalCurrentTimetableTo || 0;

                if(this.verticalTabValues.to.timetableLines[timetableId]) {
                    this.verticalTabValues.to.timetableLines[timetableId].employeeLines = newValue;
                }
            },
            immediate: true,
            deep: true,
        },
        verticalTabValues: {
            handler(newValue, oldValue) {
                this.$emit('update:verticalTabValues', newValue);
            },
            immediate: true,
            deep: true,
        }
    },
    beforeMount() {
        this.yearMonthFrom = moment().format('YYYY-MM');
        this.yearMonthTo = moment().format('YYYY-MM');
    },
    computed: {
        ...mapGetters({
            lines: 'schedulerStore/rows',
        }),
        selectTimetablesFrom() {
            let timetables = [{
                text: this.$t('Darbuotojai be darbo vietų'),
                value: null
            }];

            this.lines
                .filter((line) => !line.user)
                .filter((line) => Number.isInteger(line.id))
                .forEach((line) => {
                    timetables.push({
                        text: line.user ? line.user.name : line.name,
                        value: line.id
                    })
                })

            return timetables;
        },
        selectTimetablesTo() {
            let timetables = [{
                text: this.$t('Darbuotojai be darbo vietų'),
                value: null
            }];

            this.lines
                .filter((line) => !line.user)
                .filter((line) => Number.isInteger(line.id))
                .forEach((line) => {
                    timetables.push({
                        text: line.user ? line.user.name : line.name,
                        value: line.id
                    })
                })

            return timetables;
        },
        selectEmployeesFrom() {
            let employees = this.lines
                .filter((line) => line.user && !line.user.dismissed)

            if(this.verticalTabValues.from.timetableLine) {
                employees = employees.filter((line) => line.scheduleLineId === this.verticalTabValues.from.timetableLine)
            } else {
                employees = employees.filter((line) => line.scheduleLineId === null)
            }

            return employees
                .map((line) => {
                    return {
                        text: line.user ? line.user.name : line.name,
                        value: line.id
                    }
                })
        },
        selectEmployeesTo() {
            let employees = this.lines
                .filter((line) => line.user && !line.user.dismissed)
                .filter((line) => line.id !== this.verticalTabValues.from.employeeLine)

            if(this.verticalCurrentTimetableTo) {
                employees = employees.filter((line) => line.scheduleLineId === this.verticalCurrentTimetableTo)
            } else {
                employees = employees.filter((line) => line.scheduleLineId === null)
            }

            return employees
                .map((line) => {
                    return {
                        title: line.user ? line.user.name : line.name,
                        value: line.id
                    }
                })
        },
        verticalLeftSideFilled() {
            return this.verticalTabValues.from.timetableLine || this.verticalTabValues.from.employeeLine
        },
        verticalDatePickerActive() {
            let toEmployeesSelected = Object.values(this.verticalTabValues.to.timetableLines)
                .map((line) => line.employeeLines)
                .filter((line) => line.length > 0)
                .length;

            return (this.verticalTabValues.from.timetableLine || this.verticalTabValues.from.employeeLine)
                && (Object.keys(this.verticalTabValues.to.timetableLines).length > 1 || toEmployeesSelected > 0);
        },
        verticalAdditionalSettingsActive() {
            return (this.verticalTabValues.from.timetableLine || this.verticalTabValues.from.employeeLine) && (this.verticalTabValues.from.date && this.verticalTabValues.to.date)
        }
    },
    methods: {
        resetVerticalToTimetablesObj() {
            this.verticalTabValues.to.timetableLines = {
                0: {
                    id: 0,
                    employeeLines: []
                }
            }
        },
        onVerticalCalendarStartDateChange(event) {
            const eventMoment = moment(event);

            this.verticalTabValues.from.date = eventMoment.format('YYYY-MM-DD');
        },
        onVerticalCalendarEndDateChange(event) {
            const eventMoment = moment(event);

            this.verticalTabValues.to.date = eventMoment.format('YYYY-MM-DD');
        },
    }
}
</script>

<template>
    <div class="px-6 py-6 mb-10">
        <v-row>
            <!-- LEFT -->
            <v-col class="coloured-divider">
                <h3 class="mb-5">{{ $t('Kopijuoti iš') }}</h3>
                <div class="d-flex justify-space-between align-end mb-3">
                    <select-field
                        style="width:100%;"
                        :options='selectTimetablesFrom'
                        :label="$t('Darbo vieta')"
                        v-model="verticalCurrentTimetableFrom"
                        placeholder=""
                    />
                </div>
                <div class="d-flex justify-space-between align-end mb-3">
                    <select-field
                        style="width:100%;"
                        :options='selectEmployeesFrom'
                        :label="$t('Darbuotojas')"
                        v-model="verticalTabValues.from.employeeLine"
                    />
                </div>
                <form-alert v-if="!verticalTabValues.from.employeeLine" class="mb-3">
                    {{ $t('Jei nepasirinksite darbuotojo, kopijuojamas bus darbo vietos laikas') }}
                </form-alert>
            </v-col>

            <div class="divider"></div>

            <!-- RIGHT -->
            <v-col class="position-relative coloured-divider">
                <div
                    class="overflow"
                    v-if="!verticalLeftSideFilled"></div>
                <h3 class="mb-5">{{ $t('Kopijuoti į') }}</h3>
                <div class="d-flex justify-space-between align-end mb-3">
                    <select-field
                        style="width:100%;"
                        :options='selectTimetablesTo'
                        :label="$t('Darbo vieta')"
                        v-model="verticalCurrentTimetableTo"
                        placeholder=""
                    />
                </div>
                <div class="mb-3">
                    <multiline-select-field
                        :label="$t('Darbuotojas')"
                        :search-placeholder='$t("Pasirinkti")'
                        select-by-key='value'
                        :dropdown-max-width=340
                        :select-all="true"
                        :min='1'
                        :options='selectEmployeesTo'
                        rules='required'
                        v-model='verticalCurrentEmployeesTo'
                    />
                </div>
            </v-col>
        </v-row>
        <div class="py-7 position-relative">
            <div class="coloured-divider">
                <h3 class="mb-5">{{ $t('Data') }}</h3>
                <div class="d-flex justify-center">
                    <div style="width: 300px">
                        <ui-calendar
                            with-all-month-navigation
                            :year-month.sync='yearMonthFrom'
                            :select-start-date='verticalTabValues.from.date'
                            :select-end-date='verticalTabValues.to.date'
                            :clickable-dates='true'
                            disable-mobile-click
                            @update:selectStartDate='onVerticalCalendarStartDateChange($event)'
                            @update:selectEndDate='onVerticalCalendarEndDateChange($event)'
                        />
                    </div>
                </div>
            </div>
            <div
                class="overflow"
                v-if="!verticalDatePickerActive"></div>
        </div>
        <v-row>
            <div class="coloured-divider flex position-relative">
                <h3 class="mb-5">{{ $t('Papildomi nustatymai') }}</h3>
                <form-checkbox :is-checked.sync="verticalTabValues.activateDestroyer">
                    <div class='ml-3 hra-text--16'>
                        {{ $t('Trinti jau sukurtus darbo laikus laikotarpyje į kurį kopijuoju') }}
                    </div>
                </form-checkbox>
                <div
                    class="overflow"
                    v-if="!verticalAdditionalSettingsActive"></div>
            </div>
        </v-row>
    </div>
</template>

<style scoped lang="scss">
    .position-relative {
        position: relative;
    }

    .divider {
        width: 1px;
        margin-left: 10px;
        margin-right: 10px;
        background-color: #CFD6E6;
    }

    .coloured-divider {
        background-color: #FAF9FF;
        border-radius: 12px;
        padding: 16px;
    }

    .overflow {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background-color: #FFFFFF;
        opacity: 90%;
    }
</style>