import departmentNotApprove from "@/store/modules/vacation/departmentNotApprove";
import departmentDetails from "@/store/modules/vacation/departmentDetails";
import departmentTimeline from "@/store/modules/vacation/departmentTimeline";

export default {
  namespaced: true,
  modules: {
    departmentNotApprove,
    departmentDetails,
    departmentTimeline,
  },
}
