<template>
    <div class='d-flex flex-column gap-2'>
        <select-field
            :label='$t("setting.module.SettingRemoteWorkAddressField.form.value")'
            v-model='config.value'
            :options='valueOptions'
            rules='required'
            :disabled='!isEdit'
        />
    </div>
</template>

<script>
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import SelectField from '@/domain/fields/SelectField.vue'
import TextField from '@/domain/fields/TextField.vue'

export default {
    name: 'SettingRemoteWorkAddressFieldConfigForm',
    components: { TextField, SelectField, FormCheckbox },
    props: {
        isEdit: { type: Boolean, default: false },
        config: { type: Object, default: () => ({}) }
    },
    data() {
        return {
            valueOptions: [
                { value: 'disabled' },
                { value: 'enabled_optional' },
                { value: 'enabled_required' }
            ].map(option => ({
                ...option,
                text: this.$t(`setting.module.SettingRemoteWorkAddressField.form.valueOptions.${option.value}`)
            }))
        }
    }
}
</script>