<template>
    <div>{{value}}</div>
</template>

<script>
import {VAutocomplete, VSelect} from "vuetify/lib/components";

export default {
    name: "VAutocompleteCustom",
    extends: VSelect,
    watch: {
        value: "changeValue"
    },
    methods: {
    },
}
</script>
