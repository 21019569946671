<template>
    <ValidationProvider :name="$attrs.name" :rules="rules" v-slot="{ classes, errors }">
        <div :class="[...fieldClasses, ...classes]">
            <template v-if="isView">
                <input type="hidden" v-model="innerValue"/>
                <div :class="`${baseClassName}__label`">{{ translateLabel }}</div>
                <div :class="`${baseClassName}__input-view`">
                    <slot name="input.view" :value="errors.length === 0 ? viewValue : '?'">
                        {{ errors.length === 0 ? viewValue : '?' }}
                    </slot>
                </div>
            </template>
            <template v-else>
                <div :class="`${baseClassName}__label`">
                    <label>{{ translateLabel }}</label>
                    <slot name="right-label"/>
                </div>
                <v-select
                    v-model="innerValue"
                    :items="options"
                    :placeholder="translatePlaceholder"
                    :append-icon="appendIcon ? `icon-${appendIcon}` : 'icon-down'"
                    :multiple="multiple"
                    solo
                    :menu-props="{
                    maxWidth: 'min-content',
                    padding: 0
                }"
                    :disabled="disabled"
                >
                    <template #selection="{item}">
                        <div v-html="item.text"/>
                    </template>
                    <template #item="{item}"><span v-html="item.text"/></template>
                </v-select>
            </template>
        </div>
    </ValidationProvider>
</template>

<script>
import formFieldMixin from "@/mixins/formFieldMixin";

export default {
    mixins: [formFieldMixin],
    props: {
        multiple: {
            type: [Boolean],
            default: false,
        },
        options: {
            type: Array,
            default: () => ([]),
        },
        appendIcon: {
            type: String,
            default: null,
        },
        isView: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        baseClassName: "form-select",
    }),
    computed: {
        viewValue() {
            const values = Array.isArray(this.innerValue) ? this.innerValue : [this.innerValue];
            return [...this.options].filter(option => {
                if (values.indexOf(option.value) > -1) {
                    return true;
                }

                return values.indexOf(`${option.value}`) > -1;
            }).map(option => option.text).join(", ");
        },
    },
    methods: {
        checkValue(values) {
            return [...this.options].filter(option => {
                if (values.indexOf(option.value) > -1) {
                    return true;
                }

                return values.indexOf(`${option.value}`) > -1;
            }).map(option => option.value);
        },
        emitNewValue(newValue, oldValue) {
            if (Array.isArray(newValue)) {
                if (newValue.length === oldValue.length) {
                    return;
                }
                this.$emit("input", newValue);
                return;
            }
            this.$emit("input", newValue);
        },
        setInnerValue(value) {
            if (Array.isArray(value)) {
                this.innerValue = this.checkValue(value);
                return;
            }
            const values = this.checkValue([value]);
            this.innerValue = values.length === 0 ? "" : values[0];
        },
    }
};
</script>
