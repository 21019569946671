<template>
    <custom-dialog :max-width='900' ref='dialog' has-custom-footer>
        <template #title>{{ modalTitle }}</template>
        <template #content>
            <div
                class='d-flex flex-row px-6 gap-6'
                :class='{
                    "flex-row": !isBondMobile,
                    "flex-column": isBondMobile,
                    "align-center": isBondMobile
                }'
            >
                <business-trips-calendars v-if='!isBondMobile' class='mt-8' :max-month-count='2' />
                <validation-observer ref='ValidationObserver' tag='div' class='mt-5'>
                    <v-row v-if='!!statusText'>
                        <v-col cosl='12'>
                            <b>{{ $t('Statusas') }}: </b>
                            <event-status :status-text='statusText' :status='currentStatus' />
                        </v-col>
                    </v-row>
                    <business-trips-form :disabled-user-select='!!id' :disabled-all-fields='isReadonly' />
                </validation-observer>
            </div>
        </template>
        <template #footer>
            <ui-button
                prepend-icon='close'
                background='default'
                @click='close'
            >{{ $t('Uždaryti') }}
            </ui-button>

            <ui-button
                v-if='isReadonly && editable && isEditableEvent'
                prepend-icon='edit'
                background='primary'
                @click='handleOpenEdit'
            >{{ $t('Redaguoti') }}
            </ui-button>

            <ui-button
                v-if='!!id && !isReadonly'
                prepend-icon='ok'
                background='primary'
                @click='handleChange'
            >{{ $t('Pakeisti') }}
            </ui-button>

            <ui-button
                v-else-if='!isReadonly'
                prepend-icon='ok'
                background='primary'
                @click='handleCreate'
            >{{ saveButtonText }}
            </ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import dialogMixin from '@/mixins/dialogMixin'
import UiButton from '@/domain/components/UiButton.vue'
import UiCalendar from '@/domain/components/UiCalendar/UiCalendar.vue'
import DatepickerField from '@/domain/fields/DatepickerField.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import TextareaField from '@/domain/fields/TextareaField.vue'
import FormTextarea from '@/components/ui/form/FormTextarea.vue'
import isBondMobileMixin from '@/mixins/isBondMobileMixin'
import BusinessTripsCalendars from '@/domain/businessTrips/components/BusinessTripsCalendars.vue'
import BusinessTripsForm from '@/domain/businessTrips/components/BusinessTripsForm.vue'
import store from '@/domain/businessTrips/stores/businessTripsModalStore'
import { mapGetters } from 'vuex'
import { permissionOnlyAdmin } from '@/utils/permissions'
import CustomApi from '@/plugins/customApi'
import { ValidationObserver } from 'vee-validate'
import { GLOBAL_TOAST_TAG } from '@/utils/default'
import EventStatus from '@/domain/components/EventStatus.vue'

const storeNamespace = 'businessTripsModalStore'
const customApi = CustomApi()

export default {
    name: 'BusinessTripsModal',
    components: {
        EventStatus,
        ValidationObserver,
        FormTextarea,
        TextareaField,
        FormCheckbox,
        DatepickerField,
        UiCalendar,
        UiButton,
        CustomDialog,
        BusinessTripsCalendars,
        BusinessTripsForm
    },
    props: {
        id: { type: Number, default: null },
        start: { type: String, default: null },
        end: { type: String, default: null },
        asAdmin: { type: Boolean, default: false },
        asLeader: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
        editable: { type: Boolean, default: false }
    },
    mixins: [dialogMixin, isBondMobileMixin],
    provide() {
        return {
            storeNamespace: storeNamespace,
            asAdmin: this.asAdmin,
            asLeader: this.asLeader
        }
    },
    data() {
        return {
            userEventsWatcher: null,
            isReadonly: this.readonly
        }
    },
    computed: {
        modalMode() {
            if (this.asAdmin) {
                return 'admin'
            }

            if (this.asLeader) {
                return 'leader'
            }

            return 'employee'
        },
        modalTitle() {
            if (this.isReadonly) {
                return this.$t('Komandiruotė')
            }

            if (!!this.id) {
                return {
                    'admin': this.$t('Redaguoti komandiruote'),
                    'leader': this.$t('Komandiruotės įsakymas'),
                    'employee': this.$t('Komandiruotės prašymas')
                }[this.modalMode]
            }

            return {
                'admin': this.$t('Pridėti komandiruote'),
                'leader': this.$t('Komandiruotės įsakymas'),
                'employee': this.$t('Komandiruotės prašymas')
            }[this.modalMode]
        },
        saveButtonText() {
            return {
                'admin': this.$t('Pridėti'),
                'leader': this.$t('Siųsti įsakymas'),
                'employee': this.$t('Siųsti prašymą')
            }[this.modalMode]
        },
        currentStatus() {
            return this.$store.getters[`${storeNamespace}/currentEventStatus`]
        },
        statusText() {
            return this.$store.getters[`${storeNamespace}/currentEventStatusText`]
        },
        isEditableEvent() {
            return !!this.currentStatus && this.currentStatus !== 'aborted'
        }
    },
    beforeCreate() {
        this.$store.registerModuleSafely(storeNamespace, store)
    },
    beforeDestroy() {
        !!this.userEventsWatcher && this.userEventsWatcher()
        this.$store.unregisterModuleSafely(storeNamespace)
    },
    mounted() {
        this.$refs.ValidationObserver.reset()
        if (!!this.id) {
            this.fetchEvent(this.id)
        }

        if (this.id == null) {
            this.$store.dispatch(`${storeNamespace}/setFormStartDate`, this.start)
            this.$store.dispatch(`${storeNamespace}/setFormEndDate`, this.end)
        }

        if (this.asAdmin || this.asLeader) {
            this.startUserEventWatcher()
        } else {
            this.fetchUserEvents(this.$store.getters['currentUserStore/userId'])
        }
    },
    methods: {
        handleOpenEdit() {
            this.isReadonly = false
        },
        startUserEventWatcher() {
            this.userEventsWatcher = this.$store.watch(
                (state) => {
                    const { user } = state[storeNamespace].formData

                    return { userId: user?.id }
                },
                ({ userId }) => {
                    if (!!userId) {
                        this.fetchUserEvents(userId)
                    }
                }
            )
        },
        async fetchUserEvents(userId) {
            try {
                const { data } = await customApi.factories.employee.holidays.fetch(userId)
                await this.$store.dispatch(`${storeNamespace}/setEvents`, data)
            } catch (e) {
                console.error(e)
            }
        },
        async fetchEvent(id) {
            this.loading = true
            try {
                const { data } = await customApi.factories.events.businessTrip.fetchById(id)
                await this.$store.dispatch(`${storeNamespace}/setCurrentEvent`, data)
            } catch (e) {
                console.error(e)
            } finally {
                this.loading = false
            }
        },
        getPayload() {
            const formData = this.$store.getters[`${storeNamespace}/formData`]

            let payload = {
                start: formData.start,
                end: formData.end,
                countries: formData.countries,
                travelMethods: formData.travelMethods,
                payMethod: formData.payMethod
            }

            if (!!formData.message) {
                payload.message = formData.message
            }

            switch (this.modalMode) {
                case 'admin':
                    payload.userId = formData.user?.id ?? null
                    payload.withNotifications = formData.withNotifications
                    break
                case 'leader':
                    payload.userId = formData.user?.id ?? null
                    payload.approvalToEmployee = formData.approvalToEmployee
                    break
            }

            return payload
        },

        async validateFormData() {
            await this.$store.dispatch('messagesStore/clearMessages', ['RemoteWorkModalTag'])
            if (!(await this.$refs.ValidationObserver.validate())) {
                await this.$store.dispatch('messagesStore/showErrorMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Užpildykite visus privalomus laukus')
                })
                return false
            }

            return true
        },
        async throwError(error) {
            const messages = error.response?.data?.message ?? null
            if (messages != null) {
                await this.$store.dispatch('messagesStore/showMessage', {
                    tag: 'RemoteWorkModalTag',
                    type: 'error',
                    message: messages
                })
            }

            if (messages == null) {
                console.error(error)
            }

            await this.$store.dispatch('messagesStore/showErrorMessage', {
                tag: GLOBAL_TOAST_TAG,
                message: this.$t('Įvyko klaida. Bandykite dar kartą')
            })
        },
        async showSuccessMessage() {
            await this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag: GLOBAL_TOAST_TAG,
                message: this.$t('Išsaugota')
            })
        },
        async handleCreate() {
            if (!await this.validateFormData()) {
                return
            }

            this.loading = true

            try {
                const { data } = await customApi.factories.events.businessTrip.create(
                    this.modalMode,
                    this.getPayload()
                )

                this.$emit('created', data)
                await this.showSuccessMessage()
                this.close()
            } catch (error) {
                await this.throwError(error)
            } finally {
                this.loading = false
            }
        },
        async handleChange() {
            if (!await this.validateFormData()) {
                return
            }

            this.loading = true

            try {
                const { data: { created, updated } } = await customApi.factories.events.businessTrip.change(
                    this.id,
                    this.modalMode,
                    this.getPayload()
                )

                this.$emit('created', created)
                this.$emit('updated', updated)
                await this.showSuccessMessage()
                this.close()
            } catch (error) {
                await this.throwError(error)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>