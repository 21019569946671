<template>
    <select-field
        :disabled='disabled'
        :label='label'
        :rules='rules'
        :name='name'
        :multiple='multiple'
        :filtered='filtered'
        :options='options'
        :value='value'
        @input='handleChange'
    />
</template>

<script>
import SelectField from '@/domain/fields/SelectField.vue'

export default {
    name: 'CountrySelectField',
    components: { SelectField },
    props: {
        disabled: {type: Boolean, default: false},
        label: {type: String, default: ''},
        rules: {type: String, default: null},
        name: {type: String, default: ''},
        multiple: {type: Boolean, default: false},
        filtered: {type: Boolean, default: false},
        value: {type: [String, Array], default: ''},
    },
    computed: {
        options() {
            return this.$store.getters['global/countries'].map(country => ({
                value: country.code,
                text: country.name
            }))
        }
    },
    beforeCreate() {
        this.$store.dispatch('global/getCountries')
    },
    methods: {
        handleChange(value) {
            this.$emit('input', value)
        }
    }
}
</script>