<template>
    <validation-provider
        ref='Validator'
        tag='div'
        class='multiline-select-field'
        :immediate='immediate'
        :rules='rules'
        :name='name'
        v-slot='{ failed, ariaInput, failedRules, errors: validationErrors }'
        :class='{disabled: disabled}'
    >
        <div v-if='!!label' class='multiline-select-field__label'>
            <span>{{ label }}</span>
        </div>
        <select style='display: none;' v-model='value' multiple></select>
        <v-menu
            v-model='menu'
            :close-on-content-click='false'
            @input='handleChangeMenu'
            :disabled='disabled'
            :max-width='maxFieldWidth'
        >
            <template #activator='{on}'>
                <div
                    class='multiline-select-field__input border'
                    :class='{"has-error": validationErrors.length > 0}'
                    @click='handleOpenMenu'
                >
                    <div v-if="selectedItems.length === 0">
                        <span class='multiline-select-field__list-input-placeholder'>{{ $t("Pasirinkti") }}</span>
                    </div>
                    <v-chip
                        v-for='(item, key) in selectedItems'
                        :key='key'
                        :close='enableClear'
                        small
                        @click:close='handleRemoveItem(item)'
                    >{{ item.title }}
                    </v-chip>
                    <div v-if='!disabled' class='multiline-select-field__search'>
                        <div @click='handleOpenMenu'>
                            <v-fab-transition>
                                <ui-icon v-if='menu'>arrow-up</ui-icon>
                                <ui-icon v-else>arrow-down</ui-icon>
                            </v-fab-transition>
                        </div>
                    </div>
                </div>
            </template>

            <div class='multiline-select-field__header'>
                <div class='multiline-select-field__list-input'>
                    <span class='multiline-select-field__list-input-placeholder'>{{ $t("Pasirinkti") }}</span>
                    <v-chip
                        v-for='(item, key) in selectedItems'
                        :key='key'
                        :close='enableClear'
                        small
                        @click:close='handleRemoveItem(item)'
                    >{{ item.title }}
                    </v-chip>
                </div>
                <div v-if='searchable' class='multiline-select-field__list-search'>
                    <ui-icon>find</ui-icon>
                    <input :placeholder='searchPlaceholder' :value='searchValue' @input='handleChangeSearchValue' />
                    <ui-icon class='multiline-select-field__list-search-clear' v-if='!!searchValue' @click='handleClearSearchValue'>close</ui-icon>
                    <v-progress-linear
                        :active='loadingSearch'
                        :indeterminate='loadingSearch'
                        color='primary'
                        absolute
                        bottom
                    />
                </div>
            </div>
            <v-list class='multiline-select-field__list'>
                <template v-if="items.length > 0">
                    <v-list-item
                        key='selectAll'
                        v-show='selectAll'
                        class='multiline-select-field__list-item'
                        @click='handleAddItem("selectAll")'
                    >
                        <v-list-item-title>{{ $t('Pasirinkti visus') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        v-for='(item, index) in items'
                        :key='index'
                        v-show='!item.selected'
                        class='multiline-select-field__list-item'
                        @click='handleAddItem(item)'
                    >
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                </template>
                <template v-else>
                    <message-alert id="select-field-empty" type="error" :message="$t('Rezultatų nėra')" no-border-radius />
                </template>
            </v-list>
        </v-menu>
        <div v-for='error in validationErrors' class='hra-text--red mt-1'>{{ error }}</div>
    </validation-provider>
</template>

<script>
import UiIcon from '@/domain/components/UiIcon.vue'
import { ValidationProvider } from 'vee-validate'
import MessageAlert from "@/domain/messages/components/MessageAlert.vue";

export default {
    name: 'MultilineSelectField',
    components: {MessageAlert, UiIcon, ValidationProvider },
    props: {
        loading: {type: Boolean, default: false},
        loadingSearch: {type: Boolean, default: false},
        label: { type: String, default: null },
        immediate: { type: Boolean, default: false },
        name: { type: String, default: null },
        rules: { type: String, default: '' },
        disabled: { type: Boolean, default: false },
        searchable: { type: Boolean, default: false },
        searchPlaceholder: { type: String, default: '' },
        selectByKey: { type: String, default: 'key' },
        min: { type: Number, default: 0 },
        dropdownMaxWidth: { type: Number, default: 0 },
        selectAll: { type: Boolean, default: false },
        options: { type: Array, default: () => [] },
        value: { type: Array, default: () => [] }
    },
    data() {
        return {
            menu: false,
            search: '',
            maxFieldWidth: 0,
            searchValue: '',
            searchTimeout: null
        }
    },
    computed: {
        items() {
            if (this.loading || this.options.length === 0) {
                return []
            }

            return this.options.map((item, key) => ({
                key,
                selected: this.value.includes(item[this.selectByKey]),
                ...item
            }))
        },
        selectedItems() {
            return this.items.filter(({ selected }) => selected)
        },
        enableClear() {
            if (this.disabled) {
                return false
            }

            return this.selectedItems.length > this.min
        }
    },
    mounted() {
        this.getWith()
    },
    methods: {
        getWith() {
            this.maxFieldWidth = this.dropdownMaxWidth ? this.dropdownMaxWidth : this.$refs.Validator.$el.getBoundingClientRect().width
        },
        handleChangeMenu(isOpened) {
            if (isOpened) {
                this.searchValue = ''
                this.search = ''
            }
        },
        handleOpenMenu() {
            this.getWith()
            this.menu = true
        },
        handleAddItem(item) {
            if(item === 'selectAll') {
                this.$emit('input', this.items.map((item) => item[this.selectByKey]))
            } else {
                this.$emit('input', [...this.value, item[this.selectByKey]])
            }
        },
        handleRemoveItem(item) {
            this.$emit('input', this.value.filter(selectedKey => selectedKey !== item[this.selectByKey]))
        },
        handleClearSearchValue() {
            clearTimeout(this.searchTimeout)
            this.searchValue = ''
            this.$emit('search', '')
        },
        handleChangeSearchValue(event) {
            clearTimeout(this.searchTimeout)
            this.searchValue = event.target.value

            this.searchTimeout = setTimeout(() => {
                this.$emit('search', event.target.value)
            }, 300)
        }
    }
}
</script>

<style lang='scss'>
@import "@/assets/sass/core/vars";

.multiline-select-field {
    display: flex;
    flex-direction: column;
    gap: 3px;
    //position: relative;

    &__label {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #70778C;
        display: flex;
    }

    &__input {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 5px;
        padding: 10px;
        width: 100%;
        min-height: 44px;
        background: #FFFFFF !important;
        border-radius: 3px;
        box-sizing: border-box;

        &.border {
            border: 1px solid #E6E9F0;
        }

        &.has-error {
            border-color: $red;
        }
    }

    &__header {
        background: #FFFFFF !important;
        box-sizing: border-box;
        z-index: 4;
        position: sticky;
        top: 0;
        box-shadow: 0 0 2px #000000;
    }

    &__list-input {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 5px;
        padding: 10px;
        width: 100%;
        min-height: 44px;
        border-bottom: 1px solid #E6E9F0;

        &-placeholder {
            color: #70778C;
            font-size: 14px;
            line-height: 20px;
        }

        &:has(.v-chip) &-placeholder {
            display: none;
        }
    }

    &__list-search {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: 44px;
        border-bottom: 1px solid #E6E9F0;
        padding: 10px;
        position: relative;
        gap: 5px;

        input {
            width: 100%;
            border: none;
            outline: none;
            font-size: 14px;
            line-height: 20px;
            color: $black-800;
            background: transparent;
        }

        .ui-icon {
            color: #70778C;
        }
    }

    &__list-search-clear {
        color: $red-hover !important;
        cursor: pointer;
    }

    &__search {
        min-width: 100px;
        height: 24px;
        flex-grow: 1;
        display: flex;
        gap: 3px;
        //background: red;
        align-items: center;

        input {
            width: 100%;
            border: none;
            outline: none;
            font-size: 14px;
            line-height: 20px;
            color: #70778C;
            background: transparent;
        }

        div {
            cursor: pointer;
            font-size: 12px;
            //border-radius: 50%;
            width: 25px;
            height: 24px;
            //border: 1px solid #70778C;
            color: #70778C;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: auto;
        }
    }

    &.disabled &__input {
        background: #F3F4FF;
    }

    &__list {
        max-height: 60vh;
    }

    &__list-item {
        cursor: pointer;
        padding: 10px 13px !important;
        border-bottom: 1px solid $gray-300 !important;;
        font-weight: normal !important;

        &:hover, &:focus {
            background: $greyscale-150 !important;
        }
    }
}
</style>