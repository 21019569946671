<template>
	<Form
		ref="form"
		@submit="onSubmit"
		:form-loader-name="loaderName"
		v-model="formData"
	>
		<template #form>
			<v-row>
				<v-col cols="12">
					<FormTextField
						rules="required|email"
						v-model="formData.email"
						name="email"
						:label="$t('El. pašto adresas')"
					/>
				</v-col>
				<v-col cols="12">
					<FormTextField
						:rules="'required|min:8,' + $t('simbolius')"
						v-model="formData.password"
						name="password"
						ref="password"
						type="password"
						:label="$t('Naujas slaptažodis')"
					/>
					<small>{{ $t("Mažiausiai 8 simboliai") }}</small>
				</v-col>
				<v-col cols="12">
					<FormTextField
						:rules="
        'required|confirmed:password,' +
        $t('Slaptažodžiai nesutampa')
      "
						v-model="formData.password_confirmation"
						name="password_confirmation"
						type="password"
						:label="$t('Pakartokite naują slaptažodį')"
					/>
				</v-col>
				<v-col cols="12">
					<form-alert v-if="message" v-bind="message" />
				</v-col>
			</v-row>
		</template>
		<template #buttons="{ loading }">
			<hra-button full-width class="mt-7" :color="uiColor.primary"
			            :loading="loading" :type="buttonType.submit">{{ $t("Patvirtinti") }}
			</hra-button>
		</template>
	</Form>
</template>

<script>
import Form from "@/components/ui/form/Form.vue";
import FormTextField from "@/components/ui/form/FormTextField.vue";
import FormCheckbox from "@/components/ui/form/FormCheckbox.vue";
import Button from "@/components/ui/button/Button.vue";
import HraButton from "@/components/ui/button/HraButton";
import {authRouteProps} from "@/router/auth";
import FormAlert from "@/components/ui/form/FormAlert";

export default {
	components: {
		FormAlert,
		HraButton,
		Form,
		FormTextField,
		FormCheckbox,
		Button,
	},
	data: () => ({
		formData: {
			email: "",
			password: "",
			password_confirmation: "",
		},
		message: null,
		loaderName: "Auth-Reset-Password",
	}),
	inject: ["authService"],
	methods: {
		async onSubmit(payload) {
			try {
				this.showLoader();
				this.message = {
					type: "success",
					text: await this.authService.resetPassword({...payload, token: this.$route.params.token}),
				};
				setTimeout(() => {
					this.$router.push({name: authRouteProps.login.name});
				}, 1200);
			} catch (error) {
				this.message = {
					type: "error",
					text: error.message,
				};
			} finally {
				this.hideLoader();
			}
		},
	},
};
</script>
