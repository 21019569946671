var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('custom-dialog',{attrs:{"max-width":521},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('Keisti vadovą')))]},proxy:true},{key:"content",fn:function(){return [_c('validation-form',[_c('v-row',{staticClass:"pa-3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('select-field',{attrs:{"disabled":"","name":"departmentId","label":_vm.$t('Skyrius'),"options":_vm.departmentOptions},model:{value:(_vm.departmentId),callback:function ($$v) {_vm.departmentId=$$v},expression:"departmentId"}})],1),(_vm.employeeId != null)?_c('v-col',{attrs:{"cols":"12"}},[_c('select-field',{attrs:{"disabled":"","name":"employeeId","label":_vm.$t('Dabartinis vadovas'),"single-line":false,"options-service":_vm.employeeAutocompleteService.findWithIgnoreIds(),"dynamic":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('employee-table-view',{staticClass:"py-1",attrs:{"employee":item.employee}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('employee-table-view',{staticClass:"py-1",attrs:{"employee":item.employee}})]}}],null,false,1350165372),model:{value:(_vm.employeeId),callback:function ($$v) {_vm.employeeId=$$v},expression:"employeeId"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('select-field',{attrs:{"name":"employeeId","label":_vm.$t('Naujas vadovas (neprivalomas)'),"single-line":false,"options-service":_vm.employeeAutocompleteService.findWithIgnoreIds(),"dynamic":"","filtered":"","clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('employee-table-view',{staticClass:"py-1",attrs:{"employee":item.employee}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('employee-table-view',{staticClass:"py-1",attrs:{"employee":item.employee}})]}}]),model:{value:(_vm.formData.employeeId),callback:function ($$v) {_vm.$set(_vm.formData, "employeeId", $$v)},expression:"formData.employeeId"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('message-alerts',{attrs:{"listen":[_vm.tagId]}})],1)],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('ui-button',{attrs:{"prepend-icon":"close","background":"default"},on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t('Uždaryti'))+" ")]),_c('ui-button',{attrs:{"prepend-icon":"ok","background":"primary"},on:{"click":_vm.onSave}},[_vm._v(_vm._s(_vm.$t('Pakeisti'))+" ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }