<template>
  <table ref="table" :class="['hra-table', $attrs.class]" :style="$attrs.style">
    <thead :class="['hra-table__header', headerSticky ? 'sticky' : '']">
    <tr>
      <th :colspan="colCount">
        <slot name="header.custom"/>
      </th>
    </tr>
    <tr v-if="!hideHeaders && !isEmpty" :class="headerClasses">
      <th v-if="isInlineEdit" :style="{minWidth: '12px'}"></th>
      <th
          v-for="header in generateHeaders"
          :key="header.name"
          :class="header.classes || []"
          :style="header.style || {}"
      >
        <div v-if="header.name === 'checkbox'" class="mx-3">
          <form-checkbox :is-checked="hasCheckedAllRows" @change="onChangeCheckAllRows"/>
        </div>
        <slot v-else :name="`header.${header.name}`" :header="header">
          <div class="hra-table__header-col pa-3">{{ header.text }}</div>
        </slot>
      </th>
    </tr>
    </thead>
    <tbody class="hra-table__body">
    <tr v-if="inlineRowIsEdit">
      <td :colspan="colCount" class="hra-table__editable"/>
    </tr>
    <tr v-if="loading" v-for="key in lastRowCount" :key="key + (new Date).getTime()" class="hra-table__row">
      <td :colspan="colCount" class="hra-table__body-col">
        <v-skeleton-loader
            class="mx-auto"
            width="100%"
            type="list-item"
        />
      </td>
    </tr>
    <tr v-if="!loading && isEmpty" class="hra-table__row">
      <td :colspan="colCount">
        <slot name="empty">
          <hra-table-default-empty>
            <slot name="empty.text">{{ $t("Įrašų sistemoje pridėta nebuvo.") }}</slot>
          </hra-table-default-empty>
        </slot>
      </td>
    </tr>
    <template v-if="!loading" v-for="{row, cols} in generateRows">
      <slot
          v-if="isInlineEdit"
          name="row.inline.edit"
          :row="row" :cols="cols"
          :attrs="{actionsSticky}"
          :on="{changeEditRowId: onChangeEditRowId}"
      />
      <tr v-else :key="row.id" :class="{
				'hra-table__row': true,
				'hra-table__row-expand': row.isExpand,
				'hra-table__row-selected': row.isSelected,
				'hra-table__row-invalid': row.invalid,
			}"
      >
        <td v-for="{value, name} in cols" :key="row.id+name"
            :class="['hra-table__body-col', name === 'actions' && actionsSticky && 'sticky-col']"
        >
          <div v-if="name === 'checkbox'" class="mx-3">
            <slot v-if="!rowsCantCheck.includes(row.id)" name="col.checkbox" :row="row">
              <form-checkbox :is-checked="checkedRowIds.includes(row.id)" @change="onCheckRow(row.id, $event)"/>
            </slot>
          </div>
          <div v-else-if="name === 'actions'" :class="['d-flex', 'pa-3', 'ps-1', 'pa-sm-3', 'gap-3', 'justify-end']">
            <slot name="col.actions-base" :colName="name" :colValue="value" :row="row"
                  :expandRow="onExpandRow"/>
          </div>
          <slot v-else :name="`col.${name}`" :colName="name" :colValue="value" :row="row"
                :expandRow="onExpandRow">
            <div class="pa-3">{{ value }}</div>
          </slot>
        </td>
      </tr>

      <tr
          v-if="row.isExpand"
          :key="`${row.id}-expansion`"
      >
        <td :colspan="colCount">
          <div :class="{'hra-table__row-expansion-body': row.isExpand}">
            <slot name="row-expansion" v-if="row.isExpand" :row="row" :isExpand="row.isExpand"/>
          </div>
          <!--					</div>-->
        </td>
      </tr>
    </template>
    </tbody>
  </table>
</template>

<script>
import _ from "lodash";
import FullRowLoader from "@/components/ui/FullRowLoader";
import FormCheckbox from "@/components/ui/form/FormCheckbox";
import HraTableInlineEditCol from "@/components/ui/table/HraTableInlineEditCol";
import HraTableDefaultEmpty from "@/components/ui/table/HraTableDefaultEmpty";

export default {
  name: "HraTable",
  components: {HraTableDefaultEmpty, HraTableInlineEditCol, FormCheckbox, FullRowLoader},
  props: {
    headers: {type: Array, default: () => ([])},
    headerStyle1: {type: Boolean, default: false},
    rows: {type: Array, default: () => ([])},
    loading: {type: Boolean, default: false},
    expandMultipleRow: {type: Boolean, default: false},
    headerSticky: {type: Boolean, default: false},
    actionsSticky: {type: Boolean, default: false},
    hideHeaders: {type: Boolean, default: false},
    needCheckBox: {type: Boolean, default: false},
    needActions: {type: Boolean, default: false},
    isInlineEdit: {type: Boolean, default: false},
    rowBackground: {type: Function, default: () => '#ffffff'},
    rowsCantCheck: {type: Array, default: () => ([])},
    inlineRowIsEdit: {type: Boolean, default: false},

    expandRowIds: {type: Array, default: () => ([])},
    checkedRowIds: {type: Array, default: () => ([])},
  },
  model: [
    {prop: "expandRowIds", event: "update:expandRowIds"},
    {prop: "checkedRowIds", event: "update:checkedRowIds"},
  ],
  data() {
    return {
      headerColCheckbox: {
        name: "checkbox",
        style: {width: "45px"},
      },
      // lastRowCount: 5,
      editRowId: null,
    };
  },
  computed: {
    rowsCount() {
      return this.generateRows.length;
    },
    availableToCheckRowCount() {
      return this.rowsCount - this.rowsCantCheck.length;
    },
    checkedRowsCount() {
      return this.checkedRowIds.length;
    },
    hasCheckedAllRows() {
      return this.availableToCheckRowCount > 0 && this.availableToCheckRowCount === this.checkedRowsCount;
    },
    headerClasses() {
      return {
        "hra-table__header-row": true,
        "style-1": this.headerStyle1,
      };
    },
    headerColActions() {
      let style = {textAlign: "center"};
      let classes = [];

      if (this.actionsSticky) {
        classes.push("sticky-col");
      }

      return {
        name: "actions",
        text: this.$t('Veiksmai'),
        style,
        classes,
      };
    },
    generateHeaders() {
      return [
        this.needCheckBox ? this.headerColCheckbox : null,
        ...this.headers,
        this.needActions ? this.headerColActions : null,
      ].filter(header => header != null);
    },
    generateRows() {
      return this.rows.map(row => ({
        row: {
          ...row,
          isExpand: this.expandRowIds.includes(row.id),
          isSelected: this.checkedRowIds.includes(row.id),
          invalid: row.invalid ?? false,
        },
        cols: this.generateHeaders.map(({name}) => ({
          name,
          value: row[name] == null ? null : row[name],
        }))
      }));
    },
    lastRowCount() {
      return this.generateRows.length || 1;
    },
    colCount() {
      return this.generateHeaders.length + (this.isInlineEdit ? 1 : 0);
    },
    isEmpty() {
      return this.generateRows.length === 0;
    },
  },
  methods: {
    scrollToTop() {
      this.$refs.table?.scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
    },
    onExpandRow(rowId) {
      const hasRow = this.expandRowIds.includes(rowId);

      const expandRowIds = this.expandMultipleRow
          ? (hasRow ? this.expandRowIds.filter(id => id !== rowId) : [...this.expandRowIds, rowId])
          : hasRow ? [] : [rowId];

      this.$emit("update:expandRowIds", expandRowIds);
    },
    onChangeCheckAllRows(checked) {
      const rows = checked ? this.rows.filter(({id}) => !this.rowsCantCheck.includes(id)).map(({id}) => id) : [];
      this.$emit("update:checkedRowIds", rows);
    },
    onCheckRow(rowId, checked) {
      if (checked) {
        this.$emit("update:checkedRowIds", [...this.checkedRowIds, rowId]);
        return;
      }

      this.$emit("update:checkedRowIds", this.checkedRowIds.filter(_rowId => _rowId !== rowId));
    },
    onChangeEditRowId(rowId) {
      this.editRowId = rowId;
    }
  },
  provide() {
    return {
      onExpandRow: this.onExpandRow
    };
  }
};
</script>
