<template>
    <page
        :loader-listen='initTag'
        :message-listen='initTag'
        :loader-tag='initTag'
        with-background
    >
        <employee-template>
            <template #append-profile>
                <employee-profile-description />
            </template>
            <template #panels>
                <expansion-panels :value='panels'>
                    <expansion-employee-base :is-edit='isEdit' :is-profile='isProfile' />
                    <expansion-employee-contact :is-edit='isEdit' :is-profile='isProfile' />
                    <expansion-employee-vacations :is-edit='isEdit' :is-profile='isProfile' />
                    <expansion-employee-contract :is-edit='isEdit' :is-profile='isProfile' />
                    <expansion-employee-changelog is-edit />
                    <expansion-employee-permissions v-if='!dismissed' is-edit />
                </expansion-panels>
            </template>
        </employee-template>
    </page>
</template>

<script>
import Page from '@/domain/components/Page'
import ExpansionPanels from '@/domain/components/ExpansionPanels'
import ExpansionEmployeeBase from '@/domain/employee/components/ExpansionEmployeeBase'
import EmployeeTemplate from '@/domain/employee/components/EmployeeTemplate'
import ExpansionEmployeeContact from '@/domain/employee/components/ExpansionEmployeeContact'
import ExpansionEmployeeVacations from '@/domain/employee/components/ExpansionEmployeeVacations'
import ExpansionEmployeeContract from '@/domain/employee/components/ExpansionEmployeeContract'
import ExpansionEmployeeChangelog from '@/domain/employee/components/ExpansionEmployeeChangelog'
import ExpansionEmployeePermissions from '@/domain/employee/components/ExpansionEmployeePermissions'
import employeeEditService from '@/domain/employee/services/employeeEditService'
import EmployeeProfileDescription from '@/domain/employee/components/EmployeeProfileDescription'
import { mapGetters } from 'vuex'

export default {
    name: 'EmployeeEditPage',
    components: {
        EmployeeProfileDescription,
        ExpansionEmployeePermissions,
        ExpansionEmployeeChangelog,
        ExpansionEmployeeContract,
        ExpansionEmployeeVacations,
        ExpansionEmployeeContact, EmployeeTemplate, ExpansionEmployeeBase, ExpansionPanels, Page
    },
    data() {
        const initTag = 'EMPLOYEE_UPDATE_PAGE_INIT'

        return {
            panels: [0],
            initTag
        }
    },
    beforeCreate() {
        this.$store.dispatch('employeeStore/fetchEmployeeById', {
            id: this.$route.params.id,
            tag: 'EMPLOYEE_UPDATE_PAGE_INIT'
        })
    },
    computed: {
        ...mapGetters({
            dismissed: 'employeeStore/dismissed'
        }),
        isEdit() {
            return !this.dismissed
        },
        isProfile() {
            return this.dismissed
        }
    },
    beforeDestroy() {
        this.$store.dispatch('employeeStore/clearState')
    }
}
</script>