var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scheduler-events",attrs:{"data-view":_vm.viewType}},[_c('table',{staticClass:"scheduler-table"},[_c('thead',{staticClass:"scheduler-header"},[_c('tr',{staticClass:"scheduler-row scheduler-row_header-events"},[_c('th',{staticClass:"scheduler-column scheduler-column_header scheduler-column-event_header",attrs:{"colspan":_vm.hours.length}},[_c('span',{staticClass:"scheduler-header__text-sticky"},[_vm._v(_vm._s(_vm.title))])])]),_c('tr',{staticClass:"scheduler-row scheduler-row_header-events"},_vm._l((_vm.days),function(day){return _c('th',{key:day.date,staticClass:"scheduler-column scheduler-column_header scheduler-column-event_header header-day",attrs:{"colspan":day.colspan,"data-date":day.date,"data-weekday":day.weekday,"data-holiday":day.holiday}},[_c('span',{staticClass:"scheduler-header__text-sticky"},[_vm._v(_vm._s(day.title))])])}),0),_c('tr',{staticClass:"scheduler-row scheduler-row_header-events"},_vm._l((_vm.hours),function(ref,key){
var hour = ref.hour;
var date = ref.date;
return _c('th',{key:key,staticClass:"scheduler-column scheduler-column_header scheduler-column-event_header text-left pl-1"},[_vm._v(" "+_vm._s(hour)+" ")])}),0),_c('tr',{staticClass:"scheduler-row scheduler-row_header-events-hover"},[_c('td',{staticClass:"scheduler-column_header-events-hover",attrs:{"colspan":_vm.hours.length}},[_c('div',{ref:"highlightColumn",staticClass:"scheduler-events-drop"})])])]),_c('tbody',{staticClass:"scheduler-body"},[_vm._l((_vm.sidebarRows),function(ref){
var key = ref.key;
return [_c('tr',{key:key,staticClass:"scheduler-row"},_vm._l((_vm.hours),function(ref,key){
var weekday = ref.weekday;
var holiday = ref.holiday;
return _c('td',{key:key,staticClass:"scheduler-column",attrs:{"data-weekday":weekday,"data-holiday":holiday}})}),0)]}),_c('tr',{staticClass:"scheduler-events-row"},[_c('td',{ref:"dropArea",staticClass:"scheduler-events-column",attrs:{"colspan":_vm.hours.length},on:{"dragover":function($event){$event.preventDefault();return _vm.onDragOver($event)},"dragenter":function($event){$event.preventDefault();return _vm.onDragEnter($event)},"dragleave":function($event){$event.preventDefault();return _vm.onDragLeave($event)},"drop":function($event){$event.preventDefault();return _vm.onDrop($event)}}},[_c('div',{ref:"highlightRow",staticClass:"scheduler-events-drop"}),_vm._l((_vm.events),function(event){return _c('schedule-event',{key:event.id,attrs:{"id":event.id,"schedule-event-id":event.scheduleEventId,"user-id":event.userId,"shift-id":event.shiftId,"row":event.row,"duration":event.duration,"start":event.start,"color":event.color,"type":event.type,"timestamp":event.timestamp,"row-type":event.rowType,"pixel-to-min":_vm.pixelToMin,"move-minutes":_vm.moveMinutes,"disable-resizable":_vm.disableResizable}})})],2)])],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }