<template>
    <div class='event-invites'>
        <div class='event-invites__header'>
            <ui-icon class='event-invites__icon'>wait-list</ui-icon>
            <h3 class='event-invites__title'>{{ title }}</h3>
            <div class='event-invites__count'>{{ count }}</div>

            <v-progress-linear
                absolute
                bottom
                :active='loading'
                :indeterminate='loading'
                color='primary'
            />
        </div>
        <div class='event-invites__container'>
            <slot />
        </div>
    </div>
</template>

<script>
import UiIcon from '@/domain/components/UiIcon.vue'

export default {
    name: 'EventInvites',
    components: { UiIcon },
    props: {
        title: { type: String, required: true },
        count: { type: Number, default: 0 },
        loading: { type: Boolean, default: false }
    }
}
</script>

<style lang='scss'>
@import '@/assets/sass/mixins/_responsive';

.event-invites {
    &__header {
        display: flex;
        align-items: center;
        gap: 10px;
        position: relative;
        padding-bottom: 17px;
        border-bottom: 1px solid #1C2538;

        @include sm-down {
            padding: 0 10px 17px;
        }
    }

    &__icon {
        font-size: 24px;
        color: #70778C;
    }

    &__title {
        font-style: normal;
        font-weight: 600;
        font-size: 21px;
        line-height: 20px;
        color: #1C2538;
    }

    &__count {
        font-style: normal;
        font-weight: bolder;
        font-size: 21px;
        line-height: 20px;
        color: #C644A5;

        @include sm-down {
            margin-left: auto;
        }
    }
}
</style>