import Vue from "vue";
import Vuetify from "vuetify/es5/index";
import { createSimpleTransition } from "vuetify/lib/components/transitions/createTransition";

Vue.use(Vuetify);


const myTransition = createSimpleTransition("my-transition");
Vue.component("my-transition", myTransition);

const opts = {
  theme: {
    themes: {},
  },
};

export default new Vuetify(opts);
