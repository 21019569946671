<template>
    <multiline-select-field
        :label='label'
        :options='options'
        select-by-key='value'
        :rules='rules'
        :disabled='disabled'
        :value='value'
        :loading='loading'
        :loading-search='loadingSearch'
        :searchable='searchable'
        :search-placeholder='searchPlaceholder'
        @input='handleChangeValue'
        @search='handleSearch'
    />
</template>

<script>
import MultilineSelectField from '@/domain/components/MultilineSelectField.vue'
import CustomApi from '@/plugins/customApi'
import i18n from '@/plugins/i18n'
import _ from 'lodash'

const customApi = CustomApi()
export default {
    name: 'EmployeeMultiSelect',
    components: { MultilineSelectField },
    props: {
        label: { type: String, default: '' },
        onlyAdmins: { type: Boolean, default: false },
        onlyLeaderAndApprovalStaff: { type: Boolean, default: false },
        withoutMe: { type: Boolean, default: false },
        ignoreId: { type: Array, default: null },
        id: { type: Array, default: null },
        disabled: { type: Boolean, default: false },
        value: { type: Array, default: () => [] },
        rules: { type: String, default: null },
        searchable: { type: Boolean, default: false },
        searchPlaceholder: { type: String, default: () => i18n.t('Ieškoti darbuotojo') }
    },
    data() {
        return {
            loading: false,
            loadingSearch: false,
            searchOptions: null,
            initOptions: []
        }
    },
    computed: {
        currentUserId() {
            return this.$store.getters['currentUserStore/userId']
        },
        key() {
            return [
                ['admins', this.onlyAdmins],
                ['leaderAndApprovalStaff', this.onlyLeaderAndApprovalStaff]
            ].filter(([_, value]) => value).map(([key, _]) => key)[0] ?? 'all'
        },
        defaultOptions() {
            if (this.searchOptions != null) {
                return this.searchOptions
            }

            return this.$store.getters['employeesFieldStore/defaultOptions']
        },
        users() {
            const users = [
                ...this.initOptions,
                ...(this.defaultOptions[this.key] ?? []),
                ...(this.searchOptions ?? [])
            ]

            if (this.withoutMe) {
                return users.filter(({ id }) => id !== this.currentUserId)
            }

            return users
        },
        options() {
            return [
                ...new Map(this.users.map(item => [item.id, {
                    value: item.id,
                    title: item.name
                }])).values()
            ].sort((a, b) => a.title.localeCompare(b.title))
        }
    },
    created() {
        this.fetchInitOptions()
    },
    mounted() {
        this.$store.dispatch('employeesFieldStore/fetchDefaultOptions', this.key)
    },
    methods: {
        async fetchInitOptions() {
            if (this.value.length === 0) {
                return
            }
            try {
                this.loading = true
                const { data: { items } } = await customApi.factories.employee.fetchByFilter({
                    id: this.value
                })
                this.initOptions = items
            } catch (error) {
                console.error(error)
            } finally {
                this.loading = false
            }
        },
        handleChangeValue(value) {
            this.$emit('input', value)
        },
        getUsersByIds(ids) {
            return this.users.filter(({ id }) => ids.includes(id))
        },
        async fetchSearchOptions(value) {
            try {
                this.loadingSearch = true
                const { data: { items } } = await customApi.factories.employee.fetchByFilter({
                    onlyAdmins: this.onlyAdmins,
                    onlyLeaderAndApprovalStaff: this.onlyLeaderAndApprovalStaff,
                    withoutMe: this.withoutMe,
                    search: value,
                    limit: 20,
                    sortBy: ['name,asc']
                })
                return items
            } catch (error) {
                console.error(error)
                return []
            } finally {
                this.loadingSearch = false
            }
        },
        async handleSearch(value) {
            this.initOptions = _.cloneDeep(this.users).filter(({ id }) => this.value.includes(id))

            if (value === '' || value == null) {
                this.searchOptions = null
                return
            }

            this.searchOptions = await this.fetchSearchOptions(value)
        }
    }
}
</script>