import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'
import PageNotFound from '@/views/pages/PageNotFound'
import importRoutes from '@/router/import'

import setupRoutes from '@/domain/setup/routes'
import employeeRoutes from '@/domain/employee/routes'
import settingRoutes from '@/domain/settings/routes'
import debuggerRoutes from '@/domain/debugger/routes'
import companyRoutes from '@/domain/company/routes'
import reportsRoutes from '@/domain/reports/routes'
import dtvRoutes from '@/domain/dtv/routes'
import schedulerRoutes from '@/domain/scheduler/routes'
import employeeVacationsRoutes from '@/domain/employeeVacations/routes'
import departmentVacationsRoutes from '@/domain/departmentVacations/routes'
import AppLayout from '@/layouts/AppLayout.vue'
import Employees from '@/views/pages/app/Employees.vue'
import documentsRoutes from '@/domain/documents/routes'
import DocumentSignComponent from "@/domain/documents/components/public/DocumentSignComponent.vue";
import VueGtm from '@gtm-support/vue2-gtm'

Vue.use(VueRouter)

export const mainRouteProps = {
    pageNotFound: { name: 'pageNotFound', path: '/404' }
}

const routes = [
    { ...mainRouteProps.pageNotFound, component: PageNotFound },
    ...setupRoutes,
    // ...authRoutes,
    {
        path: '/employees',
        component: AppLayout,
        children: [
            { name: 'employees', path: '', component: Employees }
        ]
    },
    ...employeeRoutes,
    ...settingRoutes,
    ...importRoutes,
    ...debuggerRoutes,
    ...departmentVacationsRoutes,
    ...employeeVacationsRoutes,
    ...companyRoutes,
    ...reportsRoutes,
    ...dtvRoutes,
    ...schedulerRoutes,
    ...documentsRoutes,
    { path: '/public/sign',
      component: DocumentSignComponent,
      props: true
    },
    {path: "*", component: PageNotFound}
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

function hasPermissionToRoute(route) {
    const permission = route.meta?.permission ?? null
    if (permission == null) {
        return true
    }

    const permissions = store.getters['currentUserStore/permissions']

    if (permissions.indexOf('*') > -1) {
        return true
    }

    if (hasPermissions.indexOf('*') > -1 || hasPermissions.length === 0) {
        return true
    }

    return hasPermissions.filter((permission) => {
        return permissions.indexOf(permission) === -1
    }).length === 0
}

export const MOBILE_AVAILABLE_ROUTE_NAMES = [
    'DepartmentVacationPage',
    'EmployeeVacationsPage',
    'DocumentsUser',
    'DocumentPublicSignPage'
]


store.dispatch('global/checkIsMobile')

router.beforeEach(async (to, from, next) => {
    // if (!store.getters["authStore/isLoaded"]) {
    //     await authService.init("AUTH_INIT_TAG");
    // }
    if (to.meta?.canGuest === true) {
        return next()
    }

    // Check if the route is the public accessible route
    // if (to.path === '/public/sign') {
    //     return next(); // Allow access without any checks
    // }

    const importPageName = 'SetupImportPage'
    await store.dispatch('currentUserStore/fetchUser')

    if (!store.getters['currentUserStore/imported']) {
        if (to.name !== importPageName) {
            return router.push({ name: importPageName })
        }

        return next()
    }

    if (to.name === importPageName) {
        return router.push('/')
    }

    const isLeader = store.getters['currentUserStore/isLeader']
    const isMobile = store.getters['global/isMobile']

    if (to.path === '/' || to.path.indexOf('/auth') > -1) {
        const nameTo = isLeader
            ? 'DepartmentVacationPage'
            : 'EmployeeVacationsPage'

        return router.push({ name: nameTo })
    }

    if (isMobile) {
        if (!MOBILE_AVAILABLE_ROUTE_NAMES.includes(to.name)) {
            return router.push('/')
        }

        if (!isLeader && to.name === 'DepartmentVacationPage') {
            return router.push({name: 'EmployeeVacationsPage'})
        }
    }

    if (!hasPermissionToRoute(to)) {
        return router.push('/404')
    }

    return next()
})

Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_ID,
    nonce: process.env.VUE_APP_GTM_NONCE,
    enabled: process.env.VUE_APP_GTM_ENABLED === 'true',
    debug: process.env.VUE_APP_GTM_DEBUG === 'true',
    vueRouter: router
})

export default router
