import state from "@/store/modules/global/state";
import * as getters from "@/store/modules/global/getters";
import mutations from "@/store/modules/global/mutations";
import * as actions from "@/store/modules/global/actions";

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}