import { ValidationProvider } from 'vee-validate'
import _ from 'lodash'

export default {
    components: { ValidationProvider },
    props: {
        name: { type: String, default: null },
        rules: { type: String, default: '' },
        readOnly: { type: Boolean, default: false },
        editable: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        noBorder: { type: Boolean, default: false },
        label: { type: String, default: null },
        placeholder: { type: String, default: null },
        value: { type: [Number, String, Object, Boolean, Array], default: null },
        fieldTag: { type: String, default: () => `${(new Date()).getTime()}-field` },
        immediate: { type: Boolean, default: false }
    },
    data() {
        return {
            beforeEditValue: null,
            fieldEditing: false
        }
    },
    provide() {
        const field = {}
        Object.defineProperty(field, 'editing', {
            enumerable: true,
            get: () => this.fieldEditing
        })

        return {
            label: this.label,
            editable: this.editable,
            fieldTag: this.fieldTag,
            field,
            rules: this.rules,
            onEdit: this.onEdit,
            onAbort: this.onAbort,
            onSave: this.onSave
        }
    },
    async mounted() {
        await this.$store.dispatch('messagesStore/addToastTag', this.fieldTag)
    },
    computed: {
        fieldClasses() {
            return {
                'border': !this.noBorder,
                'disabled': this.disabled
            }
        },
        hasDisabled() {
            if (this.disabled) {
                return true
            }

            return this.editable && !this.fieldEditing
        }
    },
    methods: {
        onChange(value) {
            this.$emit('input', value)
            this.$emit('update:value', value)
        },
        startEdit() {
        },
        onEdit() {
            if (this.fieldEditing) {
                return
            }
            this.beforeEditValue = _.cloneDeep(this.value)

            this.fieldEditing = true
            this.startEdit()
        },
        afterAbort() {
        },
        onAbort() {
            this.onChange(_.cloneDeep(this.beforeEditValue))
            this.beforeEditValue = null
            this.fieldEditing = false
            this.afterAbort()
        },
        afterSave() {
        },
        onSave() {
            this.$emit('save', this)
        },
        saveComplete() {
            this.$store.dispatch('loadersStore/hideLoader', this.fieldTag)
            this.beforeEditValue = null
            this.fieldEditing = false
            this.afterSave()
        }
    },
    async beforeDestroy() {
        await this.$store.dispatch('loadersStore/hideLoader', this.fieldTag)
    }
}