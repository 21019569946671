import state from "@/domain/employee/store/state";
import * as getters from "@/domain/employee/store/getters";
import mutations from "@/domain/employee/store/mutations";
import * as actions from "@/domain/employee/store/actions";

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}