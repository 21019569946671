<template>
    <div class='upload-area d-flex align-center' :class='areaClasses'
         @dragover.prevent
         @dragenter.prevent='dragEnter'
         @dragleave.prevent='dragLeave'
         @drop.prevent='fileDrop'>
        <ui-icon>upload-document</ui-icon>
        <div class='d-flex flex-row align-center px-8'>
            <ui-icon>upload</ui-icon>
            <span class='pl-8 color-grey'>{{ $t('Įkelkite dokumentus arba ') }}</span>
            <a href='#' @click.prevent='openDialog'>{{ $t('spustelėkite') }}</a>
            <span class='color-grey'>{{ $t(', kad juos pasirinktumėte.') }}</span>
        </div>
        <input
            class='d-none'
            type='file'
            ref='fileInput'
            @change='fileInput'
            accept='.xlsx, .xls, .pdf, .doc, .docx'
            multiple
        />
    </div>
</template>

<script>
import UiIcon from '@/domain/components/UiIcon.vue'

export default {
    name: 'UploadDropComponent',
    components: { UiIcon },
    data() {
        return {
            draggingOver: false,
            draggingOverTimeout: null
        }
    },
    computed: {
        areaClasses() {
            return {
                active: this.draggingOver
            }
        }
    },
    methods: {
        fileInput() {
            this.$emit('upload', this.$refs.fileInput.files)
            this.$refs.fileInput.value = ''

        },
        fileDrop(e) {
            this.dragLeave()
            this.$emit('upload', e.dataTransfer.files)
        },

        openDialog() {
            this.$refs.fileInput.click()
        },

        dragEnter() {
            this.draggingOver = true

            if (this.draggingOverTimeout) {
                clearTimeout(this.draggingOverTimeout)
            }
        },
        dragLeave() {
            this.draggingOverTimeout = setTimeout(() => {
                this.draggingOverTimeout = null
                this.draggingOver = false
            }, 50)
        }
    }
}
</script>

<style lang='scss' scoped>
.upload-area {
    width: 100%;
    height: 70px;
    border-radius: 4px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%2399A6BFFF' stroke-width='2' stroke-dasharray='1%25%2c 2%25' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    display: flex;
    align-items: center;
    flex-direction: row;

    &.active {
        background-color: #d9d9d9;

        * {
            pointer-events: none;
        }
    }

    .ui-icon {
        color: #DAD5E9;
        font-size: 40px;
    }

    span.color-grey {
        color: #7B7B7B;
    }

    a {
        color: #4B56D2;
    }

    span, a {
        font-size: 16px;
    }
}
</style>