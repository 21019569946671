<template>
	<li ref="root">
		<div class="route">
			<div class="route__before" ref="routeBefore"/>
			<div class="route__after" ref="routeAfter"/>
		</div>
		<div class="company-tree-department">
			<div class="company-tree-department__header">{{ title }}</div>
			<Avatar v-if="leader" :employee-name="leader.name" size="44"/>
			<div v-if="leader" class="company-tree-department__name">{{ leader.name }}</div>
			<div v-if="leader" class="company-tree-department__duty">{{ leader.duty }}</div>
			<div class="company-tree-department__count" :class="{clickable: count > 1}">
				{{ count }}
				<hra-icon v-if="count > 1" :name="iconName.up" />
			</div>
		</div>

		<ul>
			<company-tree-department
				v-if="child.length > 0"
				v-for="item in child"
				:key="item.title"
				:title="item.title"
				:leader="item.leader"
				:count="item.count"
				:child="item.child"
			/>

			<company-tree-employee
				v-if="employees.length > 0"
				v-for="item in employees"
				:key="item.name"
				:duty="'test'"
				:name="'test name'"
			/>
		</ul>
	</li>
</template>

<script>
import Avatar from "@/components/ui/Avatar.vue";
import HraIcon from "@/components/ui/HraIcon.vue";
import CompanyTreeEmployee from "@/domain/company/components/CompanyTreeEmployee.vue";

export default {
	name: "CompanyTreeDepartment",
	components: {CompanyTreeEmployee, HraIcon, Avatar},
	props: {
		title: {type: String, required: true},
		leader: {type: Object, default: null},
		count: {type: Number, default: 0},
		child: {type: Array, default: []},
		employees: {type: Array, default: []},
	},
	data() {
		return {
			lineWidth: 0,
		};
	},
	mounted() {
		const parentRoot = this.$parent.$refs.root;

		if (parentRoot == null) {
			return;
		}

		const {x: parentX} = parentRoot.getBoundingClientRect();
		const {x, width} = this.$refs.root.getBoundingClientRect();

		const lineWidth = parentX - x;

		this.$refs.routeAfter.classList.add('border-top');


		const w = (width) / 2;
		this.lineWidth = w;

		// if (lineWidth === 0) {
		// 	this.$refs.routeBefore.style.left = `${w}px`;
		// 	this.$refs.routeAfter.style.left = `${w}px`;
		//
		// 	this.$refs.routeBefore.classList.add('border-right');
		// 	this.$refs.routeAfter.classList.add('border-left');
		// } else
		if (lineWidth > 0) {
			this.$refs.routeBefore.style.left = `${w}px`;
			this.$refs.routeAfter.style.left = `${w}px`;

			this.$refs.routeBefore.classList.add('border-right');
			this.$refs.routeAfter.classList.add('border-left');
		} else {
			this.$refs.routeBefore.style.left = `${(lineWidth + w)}px`;
			this.$refs.routeAfter.style.left = `${(lineWidth + w)}px`;

			this.$refs.routeBefore.classList.add('border-left');
			this.$refs.routeAfter.classList.add('border-right');
		}


		this.$refs.routeAfter.style.width = `${(Math.abs(lineWidth + 0.5))}px`;
		this.$refs.routeBefore.style.width = `${(Math.abs(lineWidth + 0.5))}px`;
	},
}
</script>