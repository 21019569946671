<template>
    <div class='schedule-interval px-6 d-flex align-center gap-4' :style='{height: `${eventRowHeight}px`}'>
        <form-radio
            name='selectedTimeInterval'
            :radio-value='`time-interval-${timeInterval.id}`'
            :value='timeInterval.id === currentId'
            @input='onChangeTimeInterval(timeInterval)'
        >
            <div class='ml-3'>
                {{ timeInterval.start }} - {{ timeInterval.end }}
            </div>
        </form-radio>
        <div class='d-flex'>
            <ui-button
                class='pa-2'
                prepend-icon='edit'
                size='normal'
                only-hover-background
                :modal='{name: "UpdateScheduleTimeIntervalModal", props: {timeInterval}}'
            />
            <ui-button
                class="pa-2"
                prepend-icon='bin'
                size='normal'
                hover-color='danger'
                only-hover-background
                :confirm="{
                    props: {title: $t('Ar tikrai norite ištrinti darbo laiką?')},
                    on: {confirmed: onDeleteRow}
                }"
            />
        </div>
    </div>
</template>

<script>
import VueDraggableResizable from 'vue-draggable-resizable'
import { GLOBAL_TOAST_TAG } from '@/utils/default'
import { mapGetters } from 'vuex'
import UiButton from '@/domain/components/UiButton.vue'
import FormRadio from '@/components/ui/form/FormRadio.vue'

export default {
    name: 'ScheduleTimeInterval',
    components: { FormRadio, UiButton, VueDraggableResizable },
    props: {
        timeInterval: { type: Object, required: true }
    },
    inject: ['eventRowHeight'],
    computed: {
        ...mapGetters({
            selectedTimeInterval: 'schedulerStore/selectedTimeInterval'
        }),
        currentId() {
            return this.selectedTimeInterval?.id
        }
    },
    methods: {
        onChangeTimeInterval(timeInterval) {
            this.$store.dispatch('schedulerStore/changeSelectedTimeInterval', timeInterval)
        },
        async onDeleteRow(confirm) {
            if (!await this.$store.dispatch('schedulerStore/deleteTimeIntervalById', this.timeInterval.id)) {
                await this.$store.dispatch('messagesStore/showErrorMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: 'Time interval delete error'
                })

                return
            }

            await this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag: GLOBAL_TOAST_TAG,
                message: this.$t('Time interval delete success')
            })

            confirm.close()
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/sass/core/colors';

.schedule-interval {
    background-color: $white;
    border-bottom: 1px solid $schedule-border-primary;

    .ui-button {
        min-height: auto !important;
    }
}

.form-radio,
.form-checkbox {
    &__container {
        font-size: 14px !important;
    }
}
</style>