import api from "@/plugins/api";
import {appRouteProps} from "@/router/app";
import router from "@/router";
import store from "@/store";
import {authRouteProps} from "@/router/auth";

class AuthService
{
  async getInviteData(token) {
    const {data} = await api.auth.inviteData(token);
    return data;
  }

  async register(payload) {
    await api.auth.register(payload);
    await this.#getUserInfo();
    await router.push({name: authRouteProps.enableTwoFactorLogin.name});
  }

  async login(email, password) {
    const {two_factor} = await api.auth.login(email, password);

    if (two_factor == null) {
      await this.#redirect();
      return;
    }

    if (two_factor === false) {
      await this.#getUserInfo();
    }

    await router.push({
      name: two_factor ? authRouteProps.twoFactorLogin.name : authRouteProps.enableTwoFactorLogin.name,
    });
  }

  async twoFactor(code) {
    await api.auth.twoFactor(code);
    await this.#getUserInfo();

    await this.#redirect();
  }

  async getTwoFactorQrCode() {
    const {data} = await api.user.qrCode(this.loaderName);
    return data.qrCode;
  }

  async enableTwoFactor(code) {
    await api.user.confirm2fa(code, this.loaderName);
    this.#redirect();
  }

  async ignoreTwoFactor() {
    await this.#redirect();
  }

  async forgotPassword(email) {
    const {message} = await api.auth.forgotPassword(email);
    return message;
  }

  async resetPassword(payload) {
    api.auth.resetPassword(payload);
  }

  async #redirect() {
    const setupActive = store.getters["user/setupActive"];
    const setupStep = store.getters["user/setupStep"];
    const isDepartmentLeader = store.getters["user/isDepartmentLeader"];

    if (setupActive) {
      await router.push(`/setup/${setupStep || 1}`);
      return;
    }

    // if (isDepartmentLeader) {
    //   await router.push({name: departmentVacationsRouteName});
    //   return;
    // }
    //
    // await router.push({name: employeeVacationsRouteName});
  }

  async #getUserInfo() {
    try {
      const {data} = await api.auth.config();
      await store.dispatch("user/login", data);
    } catch (error) {
      await store.dispatch("user/logout");
    }
  }
}

export default new AuthService();