<template>
    <event-status
        style='cursor: pointer;'
        :status='status'
        :status-text='statusText'
        :date='date'
        @click='handleOpenApprovalStages'
    >
        <template #prepend>
            <hra-tooltip
                :text="tooltipText"
            >
                <ui-icon class='pe-1'>message-info</ui-icon>
            </hra-tooltip>
        </template>
    </event-status>
</template>

<script>
import EventStatus from '@/domain/components/EventStatus.vue'
import modalMethodsMixin from '@/domain/mixins/modalMethodsMixin'
import HraTooltip from '@/components/ui/HraTooltip.vue'
import UiIcon from '@/domain/components/UiIcon.vue'

export default {
    name: 'EventStatusWithApprovalStages',
    components: { UiIcon, HraTooltip, EventStatus },
    mixins: [modalMethodsMixin],
    props: {
        id: { type: Number, required: true },
        type: { type: String, required: true },
        status: { type: String, required: true },
        statusText: { type: String, required: true },
        date: { type: String, default: null }
    },
    computed: {
        tooltipText() {
            if (this.type === 'statuses') {
                return this.$t('Paspaudus, matysite statusų istoriją.')
            }

            return this.$t('Paspaudus, matysite tvirtinimo eigą.')
        }
    },
    methods: {
        handleOpenApprovalStages(event) {
            event.stopPropagation()

            this.onOpenModal('ApprovalStatesModal', {
                id: this.id,
                type: this.type
            })
        }
    }
}
</script>