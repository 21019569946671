<template>
    <custom-dialog :max-width='550'>
        <template #title>{{ $t('Ištrinti dokumentą') }}</template>
        <template #content>
            <v-row class='mx-3'>
                <v-col cols='12'>
                    <document-title-view
                        :title='item.title'
                    />
                </v-col>
                <v-col cols='12'>
                    <b>{{ $t('Ar tikrai norite ištrinti dokumentą?') }}</b><br>
                    <span
                        class='text-gray'>{{ $t('Dokumentas ir susipažinimo istorija bus ištrinta iš dokumentų achyvo negrįžtamai.')
                        }}</span>
                </v-col>
            </v-row>

        </template>
        <template #footer>
            <ui-button prepend-icon='close' background='default' @click='close'>{{ $t('Atšaukti') }}</ui-button>
            <ui-button prepend-icon='ok' background='danger' @click='handleSubmit' :loading='loading'>{{ $t('Taip, ištrinti')
                }}
            </ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import UiButton from '@/domain/components/UiButton.vue'
import dialogMixin from '@/mixins/dialogMixin'
import customApi from '@/plugins/customApi'
import { GLOBAL_TOAST_TAG } from '@/utils/default'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import DocumentTitleView from '@/domain/documents/components/DocumentTitleView.vue'

export default {
    name: 'DocumentWipeModal',
    components: { DocumentTitleView, CustomDialog, UiButton },
    mixins: [dialogMixin],
    props: {
        item: { type: Object, required: true }
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        async handleSubmit() {
            this.loading = true

            try {
                await customApi().factories.documents.documents.destroy(this.item.id)
                this.$emit('documentWiped', this.item.id)
                await this.$store.dispatch('messagesStore/showSuccessMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Dokumentas sėkmingai ištrintas')
                })
                this.close()
            } catch {
                await this.$store.dispatch('messagesStore/showErrorMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Nepavyko ištrinti dokumento')
                })
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang='scss' scoped>
.text-gray {
    color: #70778C;
}
</style>