<template>
    <div class='context-menu' ref='contextMenu'>
        <slot />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'ContextMenuContainer',
    computed: {
        ...mapGetters({
            contextMenu: 'global/currentContextMenu'
        })
    },
    watch: {
        contextMenu: {
            handler(value) {
                if (value == null) {
                    return
                }

                const { clientX, clientY } = value.event
                const { x, y } = value.el.root.getBoundingClientRect()
                const moveY = clientY - y
                const moveX = clientX - x

                this.$refs.contextMenu.style.top = `${y + moveY}px`
                this.$refs.contextMenu.style.left = `${x + moveX}px`
                this.$refs.contextMenu.classList.add('visible')
                this.$slots.default = value.el.$slots.default
                this.$forceUpdate()
            }
        }
    },
    mounted() {
        document.addEventListener('click', (event) => {
            if (this.contextMenu == null) {
                return
            }

            // if (event.target.offsetParent !== this.$refs?.contextMenu ?? null) {
            this.closeContextMenu()
            // }
        })
    },
    methods: {
        closeContextMenu() {
            this.$store.dispatch('global/setContextMenu', null)
            this.$refs.contextMenu.classList.remove('visible')
        }
    }
}
</script>

<style lang='scss'>
.context-menu {
    font-family: 'Public Sans', sans-serif;
    position: fixed;
    z-index: 9999999;
    width: 150px;
    background: #1b1a1a;
    border-radius: 5px;
    transform: scale(0);
    transform-origin: top left;

    &.visible {
        transform: scale(1);
        transition: transform 200ms ease-in-out;
    }

    .item {
        padding: 8px 10px;
        font-size: 13px;
        color: #eee;
        cursor: pointer;
        border-radius: inherit;

        &:hover {
            background: #343434;
        }
    }
}
</style>