<template>
    <div class='vacation-legend'>
        <div class='vacation-legend__item' v-for='({name, text}, key) in items' :key='key'>
            <div class='vacation-legend__item-color' :class='name' />
            <div class='vacation-legend__item-text'>{{ text }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VacationLegend',
    props: {
        byUserSettings: { type: Boolean, default: false }
    },
    data() {
        return {
            legendItems: [
                { module: 'holiday', name: 'wait', text: this.$t('Laukiančios patvirtinimo atostogos') },
                { module: 'holiday', name: 'confirmed', text: this.$t('Patvirtintos atostogos') },
                { module: 'other', name: 'wait-deputy', text: this.$t('Laukiantis patvirtinimo pavadavimas') },
                { module: 'other', name: 'confirmed-deputy', text: this.$t('Patvirtintas pavadavimas') },
                {
                    module: 'holiday',
                    name: 'wait-father-or-mother',
                    text: this.$t('Laukiantis patvirtinimo mamadienis/tėvadienis')
                },
                {
                    module: 'holiday',
                    name: 'confirmed-father-or-mother',
                    text: this.$t('Patvirtintas mamadienis/tėvadienis')
                },
                { module: 'holiday', name: 'confirmed-childcare', text: this.$t('Vaiko priežiūros atostogos') },
                { module: 'remoteWork', name: 'confirmed-remote-work', text: this.$t('Confirmed remote work') },
                {
                    module: 'remoteWork',
                    name: 'pending-remote-work',
                    text: this.$t('Laukiantis patvirtinimo nuotolinis darbas')
                },
                { module: 'sickLeave', name: 'sick-leave', text: this.$t('Ligonlapis') },
                { module: 'businessTrip', name: 'business-trip', text: this.$t('Komandiruotė') }
            ]
        }
    },

    computed: {
        ignoreConfigs() {
            const isAdmin = this.$store.getters['currentUserStore/isAdmin']
            const isLeader = this.$store.getters['currentUserStore/isLeader']
            const isSubLeader = this.$store.getters['currentUserStore/isSubLeader']

            return isAdmin || isLeader || isSubLeader
        },
        userConfig() {
            return this.$store.getters['currentUserStore/configs']
        },
        availableUserModules() {
            return {
                holiday: this.userConfig.settingHolidayAllEmployeeVisibleCompanyEvents.mode === 'all',
                businessTrip: this.userConfig.settingBusinessTripEmployeeVisibleCompanyEvents.mode === 'all',
                remoteWork: this.userConfig.settingRemoteWorkEmployeeVisibleCompanyEvents.mode === 'all',
                sickLeave: this.userConfig.settingSickLeaveEmployeeVisibleCompanyEvents.mode === 'all',
                other: false
            }
        },
        visibleItemNotAtWork() {
            return this.userConfig.settingHolidayAllEmployeeVisibleCompanyEvents.mode === 'not_at_work'
                || this.userConfig.settingSickLeaveEmployeeVisibleCompanyEvents.mode === 'not_at_work'
        },
        visibleItemNotAtOffice() {
            return this.userConfig.settingRemoteWorkEmployeeVisibleCompanyEvents.mode === 'not_at_office'
                || this.userConfig.settingBusinessTripEmployeeVisibleCompanyEvents.mode === 'not_at_office'
        },
        items() {
            if (!this.byUserSettings || this.ignoreConfigs) {
                return this.legendItems
            }

            let items = [
                ...this.legendItems.filter(({ module }) => {
                    return this.availableUserModules[module]
                })
            ]

            if (this.visibleItemNotAtWork) {
                items.push({ name: 'not-at-work', text: this.$t('Nedirba') })
            }

            if (this.visibleItemNotAtOffice) {
                items.push({ name: 'not-at-office', text: this.$t('Nėra biure') })
            }

            return items
        }
    }
}
</script>
