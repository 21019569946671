<template>
    <ui-table-head-filter
        :title='title'
        :column='column'
        :select-options='options'
        :min-wdith='minWidth'
        select-option-searchable
        v-model='currentFilter'
        @changeFilter='handleChangeFilter'
    />
</template>

<script>
import UiTableHeadFilter from '@/domain/components/uiTable/UiTableHeadFilter.vue'
import i18n from '@/plugins/i18n'
import filterEmployeesAllMixin from '@/domain/filters/mixins/filterEmployeesAllMixin'

export default {
    name: 'TableHeaderCountryFilter',
    components: { UiTableHeadFilter },
    props: {
        title: {type: String, default: () => i18n.t("Šalis") },
        column: {type: String, default: 'country' },
        filter: {type: Object, default: () => ({selected: [], sort: null}) },
        minWidth: {type: [String,Number], default: 150}
    },
    data() {
        return {
            currentFilter: {
                selected: this.filter.selected
            }
        }
    },
    computed: {
        options() {
            return this.$store.getters['global/countries'].map(country => ({
                value: country.code,
                label: country.name
            }))
        }
    },
    watch: {
        filter: {
            handler(filter) {
                this.currentFilter = {
                    selected: filter.selected
                }
            },
            deep: true
        }
    },
    mounted() {
        this.$store.dispatch('global/getCountries')
    },
    methods: {
        handleChangeFilter(column, filter) {
            this.$emit('changeFilter', column, filter)
        }
    }
}
</script>