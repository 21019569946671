<template>
    <custom-dialog :max-width='550'>
        <template #title>{{ $t('Redaguoti dokumentą') }}</template>
        <template #content>
            <v-row class='pa-3'>
                <v-col cols='12'>
                    <text-field v-model='title' :label="$t('Pavadinimas')" name='name' rules='required' />
                    <div v-if='message' class='text-red mt-3'>{{ message }}</div>
                </v-col>
                <v-col cols='6'>
                    <select-field
                        :label='$t("Tipas")'
                        :options='documentTypeOptions'
                        v-model='documentTypeId'
                    />
                </v-col>
                <v-col cols='6' class='pt-2 d-flex flex-column gap-2'>
                    <form-checkbox :is-checked.sync='hasExpirationDate'>
                        <span class='pl-2'>{{ $t('Turi galiojimo laiką') }}</span>
                    </form-checkbox>

                    <datepicker-field
                        :min-date='minDate'
                        :value.sync='expirationDate'
                        :disabled='!hasExpirationDate'
                    />
                </v-col>
                <v-col cols='12'>
                    <form-checkbox :is-checked.sync='isExternal'>
                        <div class='pl-2'>
                        <span
                            class='color-black font-weight-500 fs-15 public-sans'>{{ $t('Aktyvuoti greitą peržiūrą')
                            }}</span>
                            <span
                                class='fs-15 public-sans'> ({{ $t('dokumentas bus prieinamas iš išorės visiems su nuoroda')
                                }})</span>
                        </div>
                    </form-checkbox>
                </v-col>
            </v-row>
        </template>
        <template #footer>
            <ui-button background='default' prepend-icon='close' @click='close'>{{ $t('Uždaryti') }}</ui-button>
            <ui-button :disabled='!title' :loading='loading' background='primary' prepend-icon='ok'
                       @click='handleSubmit'>
                {{ $t('Išsaugoti') }}
            </ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import dialogMixin from '@/mixins/dialogMixin'
import UiButton from '@/domain/components/UiButton.vue'
import TextField from '@/domain/fields/TextField.vue'
import customApi from '@/plugins/customApi'
import { GLOBAL_TOAST_TAG } from '@/utils/default'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import DatepickerField from '@/domain/fields/DatepickerField.vue'
import moment from 'moment'
import documentTypesMixin from '@/domain/documents/mixins/documentTypesMixin'
import SelectField from '@/domain/fields/SelectField.vue'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'

export default {
    name: 'DocumentEditModal',
    components: { UiButton, DatepickerField, FormCheckbox, SelectField, TextField, CustomDialog },
    mixins: [dialogMixin, documentTypesMixin],
    props: {
        item: { type: Object, required: true }
    },
    data() {
        const tomorrow = moment().add(1, 'day').format('YYYY-MM-DD')

        return {
            title: '',
            loading: false,
            message: null,
            hasExpirationDate: false,
            expirationDate: null,
            minDate: tomorrow,
            documentTypeId: null,
            isExternal: false
        }
    },
    watch: {
        hasExpirationDate: {
            handler: function (value) {
                if (value) {
                    return
                }

                this.expirationDate = null
            }
        }
    },
    mounted() {
        this.title = this.item.title
        this.hasExpirationDate = this.item.expirationDate !== null
        this.expirationDate = this.item.expirationDate
        this.documentTypeId = this.item.documentTypeId
        this.isExternal = this.item.isExternal
    },
    methods: {
        getPayload() {
            let payload = {
                title: this.title,
                typeId: this.documentTypeId,
                isExternal: this.isExternal
            }

            if (!!this.expirationDate) {
                payload.expirationDate = this.expirationDate
            }

            return payload
        },
        async handleSubmit() {
            this.loading = true

            try {
                const { data } = await customApi().factories.documents.documents.edit(
                    this.item.id,
                    this.getPayload()
                )
                this.showSuccessInRootToast(this.$t('Dokumento pakeitimai išsaugoti sėkmingai!'))
                this.$emit('documentUpdated', data)
                this.close()
            } catch (error) {
                this.showErrorInRootToast(error)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>