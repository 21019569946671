<template>
    <documents-finder
        :store-namespace='storeNamespace'
        :default-breadcrumbs='defaultBreadcrumbs'
        :default-title='$t("Aplankai")'
        header-sticky
        with-sub-header
        with-arrows
        with-modes
        with-search
        document-selectable
    />
</template>

<script>
import store from '@/domain/documents/finder/store/documentsFinderStore'
import UiButton from '@/domain/components/UiButton.vue'
import DocumentsFinder from '@/domain/documents/finder'
import modalMethodsMixin from '@/domain/mixins/modalMethodsMixin'

const storeNamespace = 'DocumentsSelectFinder'
export default {
    name: 'DocumentsSelectFinder',
    components: { DocumentsFinder, UiButton },
    mixins: [modalMethodsMixin],
    props: {
        folderId: { type: Number, default: null }
    },
    data() {
        return {
            storeNamespace,
            defaultBreadcrumbs: [
                { id: null, title: this.$t('Įmonės dokumentai') },
                { id: null, title: this.$t('Aplankai') }
            ]
        }
    },
    beforeCreate() {
        this.$store.registerModuleSafely(storeNamespace, store)
    },
    beforeDestroy() {
        this.$store.unregisterModuleSafely(storeNamespace)
    },
    mounted() {
        this.$store.dispatch(`${storeNamespace}/folders/changeId`, this.folderId)
    },
    methods: {
        getFolderId() {
            return this.$store.getters[`${this.storeNamespace}/folderId`]
        },
        getSelectedDocuments() {
            return this.$store.getters[`${this.storeNamespace}/selectedDocuments`]
        }
    }
}
</script>