import customApi from '@/plugins/customApi'
import {
    SET_USER,
    SET_FETCHING_USER_PROMISE,
    CHANGE_AVATAR,
    SET_IMPORTED,
    SET_IS_LEADER, SET_SETTINGS, SET_IS_SUB_LEADER
} from '@/domain/currentUser/store/mutations'

export const fetchUser = async ({ commit, getters }) => {
    if (getters.user != null) {
        return
    }

    if (getters.fetchingUserPromise) {
        return await getters.fetchingUserPromise
    }

    const api = customApi('FETCH_CURRENT_USER')
    const promise = api.request(api.factories.user.current())
    commit(SET_FETCHING_USER_PROMISE, promise)
    const {user, imported, isLeader, isSubLeader, settings} = await promise
    commit(SET_USER, user)
    commit(SET_IMPORTED, imported)
    commit(SET_IS_LEADER, isLeader)
    commit(SET_IS_SUB_LEADER, isSubLeader)
    commit(SET_SETTINGS, settings)
}

export const getUpdatedUser = async ({ commit }) => {
    const api = customApi('FETCH_CURRENT_USER')
    const {user, imported, isLeader, isSubLeader, settings} = await api.request(api.factories.user.current())

    commit(SET_USER, user)
    commit(SET_IMPORTED, imported)
    commit(SET_IS_LEADER, isLeader)
    commit(SET_IS_SUB_LEADER, isSubLeader)
    commit(SET_SETTINGS, settings)
}

export const setImported = ({commit}) => {
    commit(SET_IMPORTED, true)
}

export const changeAvatar = ({ state, commit }, { userId, blob }) => {
    if (state.user.id !== userId) {
        return
    }

    commit(CHANGE_AVATAR, blob)
}