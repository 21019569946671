<template>
    <div id='app' class='main'>
        <router-view />
        <modals-provider />
        <message-toasts :listen='listenToastTags' />
        <context-menu-container />
    </div>
</template>

<script>
import RouteChangeLoader from '@/components/RouteChangeLoader'
import ModalsProvider from '@/views/modals/ModalsProvider'
import { mapGetters } from 'vuex'
import MessageToasts from '@/domain/messages/MessageToasts'
import {
    VACATIONS_DEPUTY_ABORT_TAG,
    VACATIONS_DEPUTY_APPROVE_TAG,
    VACATIONS_EVENT_ABORT_TAG
} from '@/domain/employeeVacations/tags'
import {
    VACATION_MODAL_ABORT_TAG,
    VACATION_MODAL_CHANGE_TAG,
    VACATION_MODAL_CREATE_TAG,
    VACATION_MODAL_UPDATE_TAG
} from '@/domain/vacationModal/tags'
import 'clockwork-browser/toolbar'
import { GLOBAL_TOAST_TAG } from '@/utils/default'
import isBondMobileMixin from '@/mixins/isBondMobileMixin'
import router, { MOBILE_AVAILABLE_ROUTE_NAMES } from '@/router'
import ContextMenuContainer from '@/domain/components/ContextMenuContainer.vue'

export default {
    components: { ContextMenuContainer, MessageToasts, ModalsProvider, RouteChangeLoader },
    mixins: [isBondMobileMixin],
    computed: {
        ...mapGetters({
            toastTags: 'messagesStore/toastTags',
            isLeader: 'currentUserStore/isLeader'
        }),
        listenToastTags() {
            return [
                ...this.toastTags,
                VACATIONS_DEPUTY_ABORT_TAG,
                VACATIONS_DEPUTY_APPROVE_TAG,
                VACATIONS_EVENT_ABORT_TAG,
                VACATION_MODAL_CREATE_TAG,
                VACATION_MODAL_CHANGE_TAG,
                VACATION_MODAL_UPDATE_TAG,
                VACATION_MODAL_ABORT_TAG,
                GLOBAL_TOAST_TAG
            ]
        }
    },
    watch: {
        isBondMobile: {
            handler: function(isMobile) {
                this.checkRouteIsMobile(isMobile)
            }
        }
    },
    methods: {
        checkRouteIsMobile(isMobile) {
            if (!isMobile) {
                return
            }

            const routeName = this.$route.name
            if (!MOBILE_AVAILABLE_ROUTE_NAMES.includes(routeName)) {
                return this.$router.push('/')
            }

            if (!this.isLeader && routeName === 'DepartmentVacationPage') {
                return router.push({ name: 'EmployeeVacationsPage' })
            }
        }
    }
}
</script>
