<template>
    <div :class="['avatar', $attrs.class]" :style="style">
        <img v-if="avatarSrc" :src="`${avatarSrc}`" alt="avatar"/>
        <div v-if="!avatarSrc" class="avatar__name">{{ avatarName }}</div>
    </div>
</template>

<script>
export default {
    props: {
        employeeName: {type: String, default: "",},
        color: {type: String, default: "",},
        src: {type: [String, Object, null], default: null,},
        size: {type: [String, Number], default: "36",}
    },
    data: () => ({
        generateSrc: null
    }),
    computed: {
        avatarSrc() {
            if (this.src instanceof Blob) {
                return URL.createObjectURL(this.src)
            }
            if (this.src instanceof File) {
                this.fileRender(this.src);
            }
            if (this.generateSrc != null) {
                return this.generateSrc;
            }
            if (typeof this.src === "string") {
                return this.src;
            }
            return null;
        },
        avatarName() {
            return this.employeeName
                .split(" ")
                .map((word) => word.length > 0 ? word[0].toUpperCase() : "")
                .slice(0, 2)
                .join("");
        },
        styleSize() {
            const minFontSize = 12;
            const minSize = 36;
            const size = `${this.size}px`;
            const fontSize = parseInt(this.size) * minFontSize / minSize;
            return {
                width: size,
                minWidth: size,
                maxWidth: size,
                height: size,
                fontSize: `${fontSize}px`,
            };
        },
        style() {
            return {
                // backgroundColor: this.color,
                ...this.styleSize,
            };
        },
    },
    methods: {
        fileRender(file) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                this.generateSrc = reader.result;
            });
            reader.readAsDataURL(file);
        },
    }
};
</script>
