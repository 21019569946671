<template>
    <td class="table__col">
        <FormCheckbox :disabled="disabled" v-model="checked" />
    </td>
</template>
<script>
import FormCheckbox from "@/components/ui/form/FormCheckbox";
export default {
    components: {FormCheckbox},
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        isChecked: {
            type: Boolean,
            required: true,
        },
    },
    data: () => ({
        checked: false,
    }),
    watch: {
        isChecked: {
            handler(newValue) {
                this.checked = newValue;
            },
            immediate: true,
        },
        checked: {
            handler(newValue) {
                this.$emit("change", this.id, newValue);
            }
        }
    },
}
</script>
