<template>
    <v-col cols='12'>
        <ui-table
            header-sticky
            :visible-load-more='hasMore'
            :loading='loading'
            :loading-more='loadingMore'
            @clickLoadMore='handleLoadMore'
        >
            <template #head>
                <tr>
                    <th>{{ $t('Išsiuntė') }}</th>
                    <th>{{ $t('Išsiuntimo data') }}</th>
                    <th>{{ $t('Susipažinti iki') }}</th>
                    <th>{{ $t('Statusas') }}</th>
                </tr>
            </template>

            <template #body>
                <tr v-for='(row, key) in rows' :key='key'>
                    <td>{{ row.sentUser.name }}</td>
                    <td>{{ row.sentAt }}</td>
                    <td>{{ row.dueAt }}</td>
                    <td>
                        <document-sent-status-with-comment
                            :status='row.status'
                            :date='row.statusAt'
                            :comment='row.comment'
                        />
                    </td>
                </tr>
            </template>
        </ui-table>
    </v-col>
</template>

<script>
import UiTableHeadFilter from '@/domain/components/uiTable/UiTableHeadFilter.vue'
import UiTable from '@/domain/components/uiTable/UiTable.vue'
import TableHeaderDateFilter from '@/domain/components/uiTable/filters/TableHeaderDateFilter.vue'
import CustomApi from '@/plugins/customApi'
import DocumentCommentTooltip from '@/domain/documents/components/DocumentCommentTooltip.vue'
import DocumentSentStatusWithComment from '@/domain/documents/components/DocumentSentStatusWithComment.vue'

const customApi = CustomApi()
export default {
    name: 'HistoryOfDocumentSentUserTable',
    components: { DocumentSentStatusWithComment, DocumentCommentTooltip, TableHeaderDateFilter, UiTable, UiTableHeadFilter },
    props: {
        documentSentId: { type: Number, required: true }
    },
    data() {
        return {
            loading: false,
            loadingMore: false,
            hasMore: false,
            currentPage: 1,
            rows: []
        }
    },
    mounted() {
        this.loadData()
    },
    methods: {
        getRequestParams(page = 1) {
            return {
                page,
                limit: 50
            }
        },
        async loadData() {
            this.loading = true
            try {
                await this.fetch(this.getRequestParams(1))
            } catch (error) {
                console.debug(error)
            } finally {
                this.loading = false
            }
        },
        async handleLoadMore() {
            this.loadingMore = true
            try {
                await this.fetch(this.getRequestParams(this.currentPage + 1))
            } catch (error) {

            } finally {
                this.loadingMore = false
            }
        },
        async fetch(params) {
            const {
                data: {
                    items,
                    hasMore,
                    pagination: { currentPage }
                }
            } = await customApi.factories.documents.sends.histories(this.documentSentId, params)

            this.rows = currentPage === 1 ? items : [...this.rows, ...items]
            this.currentPage = currentPage
            this.hasMore = hasMore
        }
    }
}
</script>