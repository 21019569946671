<template>
	<div class="mt-6">
		<slot />
		<div class="d-flex justify-end mt-5 gap-5">
			<slot name="buttons" />
		</div>
	</div>
</template>

<script>
export default {
	name: "HraEmployeeCrudTable",
}
</script>