<template>
    <div class='employee-event-invite-line'>
        <div class='employee-event-invite-line__type-text'>{{ typeText }}</div>
        <div class='employee-event-invite-line__period'>{{ start }} {{ $t('iki') }} {{ end }}</div>
        <div class='employee-event-invite-line__deputies'
             v-if='!!deputies'
             v-html='deputies'
        />
        <div v-if='withStatus' class='employee-event-invite-line__status'>
            <slot name='status' />
        </div>
        <div class='employee-event-invite-line__actions'>
            <slot name='actions' />
        </div>
    </div>
</template>

<script>
import EventStatus from '@/domain/components/EventStatus.vue'
import EventStatusWithApprovalStages from '@/domain/components/ApprovalStages/EventStatusWithApprovalStages.vue'

export default {
    name: 'EmployeeEventInviteLine',
    components: { EventStatusWithApprovalStages, EventStatus },
    props: {
        typeText: { type: String, required: true },
        start: { type: String, required: true },
        end: { type: String, required: true },
        deputies: { type: String, default: null },
        withStatus: { type: Boolean, default: false }
    }
}
</script>

<style lang='scss'>
@import '@/assets/sass/mixins/_responsive';

.employee-event-invite-line {
    padding: 12px;
    min-height: 57px;
    background: #FFFFFF;
    border-bottom: 1px solid #E6E9F0;

    display: grid;
    gap: 10px;
    grid-template-areas: "type-text period deputies actions";
    grid-template-columns: 350px 250px 1fr auto;
    align-items: center;

    &:has(&__status) {
        grid-template-areas: "type-text period status deputies actions";
        grid-template-columns: 350px 250px 200px 1fr auto;
    }

    &__type-text {
        grid-area: type-text;

        @include sm-down {
            font-size: 16px;
        }
    }

    &__period {
        grid-area: period;
        font-style: normal;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        color: #37409E;

        @include sm-down {
            font-weight: bold;
        }
    }

    &__deputies {
        grid-area: deputies;
    }

    &__status {
        grid-area: status;
    }

    &__actions {
        grid-area: actions;
        display: flex;

        @include sm-down {
            gap: 5px;
        }
    }

    &:nth-child(even) {
        background: #FAF9FF;
    }

    @include sm-down {
        padding: 20px 10px;
        gap: 2px 10px;
        grid-template-areas: "type-text actions" "period actions" "deputies actions";
        grid-template-columns: 1fr auto;

        &:has(&__status) {
            grid-template-areas: "type-text actions" "status status" "period actions" "deputies actions";
            grid-template-columns: 1fr 100px auto;
        }
    }
}
</style>