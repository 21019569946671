<template>
    <documents-finder
        :store-namespace='storeNamespace'
        :default-breadcrumbs='defaultBreadcrumbs'
        :default-title='$t("Aplankai")'
        header-sticky
        :header-sticky-top='0'
        with-sub-header
        with-arrows
        with-modes
        with-search
        with-status
        with-controls
        with-sidebar-action
        document-selectable
    >
        <template #header-buttons>
            <ui-button
                background='violet-dark'
                @click='handleOpenCreateFolderModal'
                prepend-icon='plus'
            >
                {{ $t('Naujas aplankas') }}
            </ui-button>
            <ui-button
                background='primary'
                @click='handleOpenDocumentsSendModal'
                prepend-icon='send'
            >
                {{ $t('Siųsti') }}
            </ui-button>
        </template>
    </documents-finder>
</template>

<script>
import store from '@/domain/documents/finder/store/documentsFinderStore'
import DocumentsFinder from '@/domain/documents/finder/index.vue'
import UiButton from '@/domain/components/UiButton.vue'
import modalMethodsMixin from '@/domain/mixins/modalMethodsMixin'
import documentsItemsSidebars from '@/domain/documents/items/mixins/documentsItemsSidebars'

const storeNamespace = 'documentsItemsRootStore'

export default {
    name: 'DocumentsFolders',
    components: {
        UiButton,
        DocumentsFinder
    },
    mixins: [modalMethodsMixin],
    data() {
        return {
            storeNamespace,
            defaultBreadcrumbs: [
                { id: null, title: this.$t('Įmonės dokumentai') },
                { id: null, title: this.$t('Aplankai') }
            ]
        }
    },
    beforeCreate() {
        this.$store.registerModuleSafely(storeNamespace, store)
    },
    beforeDestroy() {
        this.$store.unregisterModuleSafely(storeNamespace)
    },
    watch: {
        $route() {
            if (this.setFromRoute()) {
                this.$store.dispatch(`${storeNamespace}/load`)
            }
        }
    },
    mounted() {
        this.setFromRoute()

        this.$watch(
            () => [
                this.$store.getters[`${storeNamespace}/folderId`],
                this.$store.getters[`${storeNamespace}/search`]
            ],
            () => this.handleChangeFolderIdOrSearch()
        )

        this.$store.dispatch(`${storeNamespace}/load`)
    },
    methods: {
        setFromRoute() {
            const routeFolderId = parseInt(this.$route.params?.folderId ?? null)
            const folderId = isNaN(routeFolderId) ? null : routeFolderId
            const search = this.$route.query?.search ?? null

            if (
                folderId === this.$store.getters[`${storeNamespace}/folderId`]
                && search === this.$store.getters[`${storeNamespace}/search`]
            ) {
                return false
            }

            this.$store.dispatch(`${storeNamespace}/setData`, {
                folderId,
                search
            })

            return true
        },
        handleChangeFolderIdOrSearch() {
            const search = this.$store.getters[`${storeNamespace}/search`] || null
            let query = {}
            if (search != null) {
                query.search = search
            }

            this.$router.push(({
                params: { folderId: this.$store.getters[`${storeNamespace}/folderId`] },
                query
            })).catch(() => {
            })
        },
        handleOpenCreateFolderModal() {
            this.onOpenModal('FolderCreateModal', {
                parentId: this.$store.getters[`${storeNamespace}/folderId`]
            }, {
                folderCreated: (payload) => {
                    this.$store.dispatch(`${storeNamespace}/folders/create`, payload)
                }
            })
        },
        handleOpenDocumentsSendModal() {
            const documents = this.$store.getters[`${this.storeNamespace}/selectedDocuments`]
            this.$store.dispatch(`${this.storeNamespace}/documents/unselectAll`)

            this.onOpenModal(
                'DocumentSendModal',
                {
                    documents
                },
                {
                    sent: (payload) => {
                        this.$store.dispatch(`${this.storeNamespace}/documents/updateMany`, payload)
                    }
                }
            )
        }
    }
}
</script>