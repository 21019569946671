<template>
    <custom-dialog :max-width='549'>
        <template #title>{{ $t('Keisti profilio nuotrauką') }}</template>
        <template #content>
            <div class='avatar-upload'>
                <div v-show='step === 1' ref='dropArea' class='avatar-upload-drop-area' @click='onClick'
                     @drop.prevent='onDrop' @dragover.prevent='onHighlight' @dragenter='onHighlight'
                     @dragleave='onUnHighlight'>
                    <img src='@/assets/images/upload-icon.png' alt='upload image'>
                    <p v-html="$t('Įkelkite dokumentą čia arba <span>spustelėkite</span>, kad juos pasirinktumėte.')" />
                    <input ref='fileUpload' type='file' :accept="acceptTypes.join(', ')" @change='onChangeFile' />
                </div>

                <avatar-cropper v-if='step === 2' :image='avatar' ref='cropper' />
                <div v-if='step === 2' class='d-flex justify-end gap-3 mt-3'>
                    <hra-button :size='buttonSize.small' :color='uiColor.default' :disabled='loading' @click='close'>
                        {{ $t('Atšaukti') }}
                    </hra-button>
                    <hra-button :size='buttonSize.small' :color='uiColor.primary' @click='onUpload' :loading='loading'>
                        {{
                            $t('Pakeisti') }}
                    </hra-button>
                </div>

                <div v-if='step === 3' class='d-flex align-center flex-column'>
                    <avatar :src='avatarSrc' :size='132' />
                    <div class='mt-6 hra-text--18'>{{ $t('Profilio nuotrauka sėkmingai pakeista.') }}</div>
                    <hra-button class='mt-9' :size='buttonSize.small' :color='uiColor.primary' @click='close'>
                        {{ $t('Uždaryti') }}
                    </hra-button>
                </div>
            </div>
        </template>
    </custom-dialog>
</template>

<script>
import HraButton from '@/components/ui/button/HraButton'
import HraIcon from '@/components/ui/HraIcon'
import dialogMixin from '@/mixins/dialogMixin'
import CustomDialog from '@/components/ui/dialog/CustomDialog'
import FormMessages from '@/components/ui/form/FormMessages'
import FormAlert from '@/components/ui/form/FormAlert'
import FullRowLoader from '@/components/ui/FullRowLoader'
import Form from '@/components/ui/form/Form'
import DatepickerField from '@/domain/fields/DatepickerField'
import TextField from '@/domain/fields/TextField'
import SelectField from '@/domain/fields/SelectField'
import 'vue-advanced-cropper/dist/style.css'
import AvatarCropper from '@/components/ui/AvatarCropper'
import employeeAvatarService from '@/services/employees/employeeAvatarService'
import Avatar from '@/components/ui/Avatar'

export default {
    name: 'EmployeeChangeAvatarModal',
    mixins: [dialogMixin],
    components: {
        Avatar,
        AvatarCropper,
        SelectField,
        TextField,
        DatepickerField, Form, FullRowLoader, FormAlert, FormMessages, CustomDialog, HraIcon, HraButton
    },
    props: {
        onChange: { type: Function, required: true }
    },
    data: () => ({
        step: 1,
        acceptTypes: ['image/png', 'image/jpeg'],
        avatar: null,
        loading: false,
        avatarSrc: null
    }),
    methods: {
        onHighlight() {
            this.$refs.dropArea.classList.add('highlight')
        },
        onUnHighlight() {
            this.$refs.dropArea.classList.remove('highlight')
        },
        onClick() {
            this.$refs.fileUpload.click()
        },
        async onUpload() {
            const canvas = this.$refs.cropper.getCanvas()

            if (canvas) {
                this.loading = true
                canvas.toBlob(async blob => {
                    if (await this.onChange(blob)) {
                        this.avatarSrc = canvas.toDataURL('image/png')
                        this.step = 3
                    }

                    this.loading = false
                }, 'image/png')
            }
        },
        onChangeFile(event) {
            const files = event.dataTransfer?.files || event.target?.files

            if (files.length > 1) {
                return
            }

            const file = files[0]
            if (!this.acceptTypes.includes(file.type)) {
                return
            }

            this.uploadFile(file)
        },
        onDrop(event) {
            this.onUnHighlight()
            this.onChangeFile(event)
        },
        uploadFile(file) {
            let blob = new Blob([file], { type: 'image/jpeg' })
            let reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onload = () => {
                this.avatar = reader.result
                this.step = 2
            }
        }
    }
}
</script>
