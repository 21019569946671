<template>
    <select-field
        :label='label'
        :options-service='handleFindUsers'
        :value='currentValue'
        filtered
        :multiple='multiple'
        :errors='errors'
        :rules='rules'
        :disabled='disabled'
        @input='handleChange'
    />
</template>

<script>
import SelectField from '@/domain/fields/SelectField.vue'
import customApi from '@/plugins/customApi'

export default {
    name: 'UserSelectField',
    components: { SelectField },
    props: {
        label: { type: String, default: null },
        value: { type: [Array, Object], default: () => null },
        multiple: { type: Boolean, default: false },
        errors: { type: Array, default: () => [] },
        rules: { type: String, default: null },
        disabled: { type: Boolean, default: false },
        withDepartment: { type: Boolean, default: false },
        onlyForUser: { type: Boolean, default: false }
    },
    data() {
        return {
            init: true,
            currentValue: null,
            users: []
        }
    },
    watch: {
        value: {
            handler(value) {
                if (this.multiple) {
                    this.currentValue = value.map(user => user.id)
                    return
                }

                this.currentValue = value?.id ?? null
            },
            immediate: true
        }
    },
    methods: {
        handleChange(value) {
            if (this.multiple) {
                return this.$emit('change', this.users.filter(user => value.includes(user.id)))
            }

            this.$emit('change', this.users.find(user => user.id === value))
        },
        async handleFindUsers(tag, search) {
            if (this.init) {
                this.init = false

                if (this.value == null) {
                    return []
                }

                return this.multiple ? this.value.map(user => ({
                    value: user.id,
                    text: user.name
                })) : [{ value: this.value.id, text: this.value.name }]
            }

            if (typeof search !== 'string') {
                return this.multiple ? this.value.map(user => ({
                    value: user.id,
                    text: user.name
                })) : [{ value: this.value.id, text: this.value.name }]
            }

            const api = customApi(tag)
            this.users = await api.request(api.factories.user.autocomplete2({
                query: search,
                withDepartment: this.withDepartment,
                onlyForUser: this.onlyForUser
            })) ?? []

            return this.users.map(user => ({ value: user.id, text: user.name }))
        }
    }
}
</script>