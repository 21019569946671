<template>
    <Form v-model='formData' is-dialog @submit='() => {}' ref='form'>
        <template #form>
            <v-row>
                <v-col cols='12'>
                    <div class='dialog__main-content'>
                        <div class='dialog__main-calendar'>
                            <employee-table-view v-if='fields.user.visible' class='mb-4' :employee='user' />
                            <div>
                                <ui-calendar
                                    with-first-month-navigation
                                    max-month-count='2'
                                    :year-month.sync='yearMonth'
                                    :min-select-date='minDate'
                                    :events='calendarEvents'
                                    :select-start-date='currentEvent.start'
                                    :select-end-date='currentEvent.end'
                                    :clickable-dates='editableCalendar'
                                    disable-mobile-click
                                    @update:selectStartDate='onChangeField($event, "start")'
                                    @update:selectEndDate='onChangeField($event, "end")'
                                />
                            </div>
                        </div>
                        <div class='dialog__main-form' style='width: 100%;'>
                            <select-field
                                v-show='fields.type.visible'
                                filtered
                                name='type'
                                :label="$t('Atostogų rūšis')"
                                :options='typeOptions'
                                :disabled='fields.type.disabled'
                                :value='currentEvent.type'
                                @input='onChangeField($event, "type")'
                            />
                            <div class='d-flex flex-column flex-sm-row gap-3'>
                                <datepicker-field
                                    v-show='fields.start.visible'
                                    style='width: 100%;'
                                    name='start'
                                    :label="$t('Data nuo')"
                                    :disabled='fields.start.disabled'
                                    :value='currentEvent.start'
                                    :min-date='minDate'
                                    @input='onChangeField($event, "start")'
                                />
                                <datepicker-field
                                    v-show='fields.end.visible'
                                    style='width: 100%;'
                                    name='toDate'
                                    :min-date='currentEvent.start'
                                    :label="$t('Data iki')"
                                    :disabled='fields.end.disabled'
                                    :value='currentEvent.end'
                                    @input='onChangeField($event, "end")'
                                />
                            </div>
                            <select-field
                                v-show='fields.payBeforeStart.visible && availableChangePayType'
                                name='payType'
                                :label="$t('Atostoginių išmokėjimas')"
                                :options='vacationPayBeforeStartOptions'
                                :disabled='fields.payBeforeStart.disabled'
                                :value='currentEvent.payType'
                                @input='onChangeField($event, "payType")'
                            />
                            <employee-multi-select
                                ref='deputies'
                                :label='$t("Pavaduojantis")'
                                without-me
                                :value='selectedDeputies'
                                searchable
                                @input='onChangeDeputies'
                            />
                            <form-textarea
                                v-show='fields.message.visible'
                                name='message'
                                :label="$t('Žinutė')"
                                :disabled='fields.message.disabled'
                                :value='currentEvent.message'
                                @input='onChangeField($event, "message")'
                            />
                            <hra-form-file
                                v-if='fields.attachment.visible'
                                :disabled='fields.attachment.disabled'
                                :value='currentEvent.attachment'
                                @input='onChangeField($event, "attachment")'
                            />
                            <form-checkbox
                                v-if='asAdmin'
                                :is-checked='sendInviteForEmployeeAndLeader'
                                @change='onChangeSendInviteForEmployeeAndLeader'
                            >
                                <span class='pl-2'>
                                    {{ $t('Pranešti darbuotojui ir vadovui (jei yra) apie atostogų pokyčius') }}
                                </span>
                            </form-checkbox>
                            <message-alerts :listen='[VACATION_MODAL_CHECK_TAG]' />
                        </div>
                    </div>
                </v-col>
            </v-row>
        </template>

        <template #buttons>
            <slot name='buttons' />
        </template>
    </Form>
</template>

<script>
import CustomDialog from '@/components/ui/dialog/CustomDialog'
import FullRowLoader from '@/components/ui/FullRowLoader'
import HraCalendar from '@/components/ui/calendar/HraCalendar'
import Form from '@/components/ui/form/Form'
import FormEmployeeAutocomplete from '@/components/ui/form/FormEmployeeAutocomplete'
import FormTextarea from '@/components/ui/form/FormTextarea'
import HraButton from '@/components/ui/button/HraButton'
import HraIcon from '@/components/ui/HraIcon'
import FormAlert from '@/components/ui/form/FormAlert'
import { mapGetters } from 'vuex'
import HraDialogConfirm from '@/components/ui/dialog/HraDialogConfirm'
import HraAlert from '@/components/ui/HraAlert'
import HraFormFile from '@/components/ui/form/HraFormFile'
import Avatar from '@/components/ui/Avatar'
import EmployeeTableView from '@/components/ui/views/EmployeeTableView'
import {
    VACATION_MODAL_CHECK_TAG,
    VACATION_MODAL_INIT_TAG
} from '@/domain/vacationModal/tags'
import Loaders from '@/domain/loaders/Loaders'
import MessageAlerts from '@/domain/messages/MessageAlerts'
import BaseConfirm from '@/domain/confirms/BaseConfirm'
import DatepickerField from '@/domain/fields/DatepickerField'
import SelectField from '@/domain/fields/SelectField'
import UiCalendar from '@/domain/components/UiCalendar/UiCalendar.vue'
import { currentEvent, sendInviteForEmployeeAndLeader } from '@/domain/vacationModal/store/getters'
import moment from 'moment'
import employeeAutocompleteService from '@/services/employees/employeeAutocompleteService'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import UiButton from '@/domain/components/UiButton.vue'
import { availableHolidayPayTypes } from '@/utils/default'
import ApprovalStages from '@/domain/components/ApprovalStages/ApprovalStages.vue'
import EmployeeMultiSelect from '@/domain/fields/EmployeeMultiSelect.vue'

export default {
    name: 'VacationModalForm',
    components: {
        EmployeeMultiSelect,
        ApprovalStages,
        UiButton,
        FormCheckbox,
        UiCalendar,
        DatepickerField,
        BaseConfirm,
        MessageAlerts,
        Loaders,
        EmployeeTableView,
        Avatar,
        HraFormFile,
        HraAlert,
        HraDialogConfirm,
        FormAlert,
        HraIcon,
        HraButton,
        FormTextarea,
        FormEmployeeAutocomplete,
        SelectField, HraCalendar, FullRowLoader, CustomDialog, Form
    },
    data() {
        return {
            VACATION_MODAL_INIT_TAG,
            VACATION_MODAL_CHECK_TAG,
            checkTimeout: null,
            formData: this.$store.state.vacationModalStore.currentEvent,
            yearMonth: null,
            employeeAutocompleteService,
            foundDeputies: []
        }
    },
    watch: {
        startCalendarDate: {
            handler(value) {
                this.yearMonth = (value ? moment(value) : moment()).format('YYYY-MM')
            },
            immediate: true
        }
    },
    computed: {
        ...mapGetters({
            approvals: 'vacationModalStore/approvals',
            asAdmin: 'vacationModalStore/asAdmin',
            typeOptions: 'vacationModalStore/typeOptions',
            fields: 'vacationModalStore/fields',
            user: 'vacationModalStore/user',
            calendarEvents: 'vacationModalStore/calendarEvents',
            currentEvent: 'vacationModalStore/currentEvent',
            sendInviteForEmployeeAndLeader: 'vacationModalStore/sendInviteForEmployeeAndLeader',
            editableCalendar: 'vacationModalStore/editableCalendar',
            holidayDates: 'calendar/holidayDates'
        }),
        createBeforeDays() {
            return this.$store.getters['currentUserStore/settings']?.holiday
                ?.settingHolidayAllCreateBeforeWorkDays?.config.days ?? 0
        },
        minDate() {
            if (this.asAdmin) {
                return null
            }

            let date = moment()
            let i = 0
            const dateMethod = this.createBeforeDays < 0 ? 'subtract' : 'add'

            while (i < Math.abs(this.createBeforeDays)) {
                date = date[dateMethod](1, 'days')

                if (this.isDateNotHolidayAndWeekend(date)) {
                    i++
                }
            }

            return date.format('YYYY-MM-DD')
        },
        selectedDeputies() {
            return this.currentEvent.deputies.map(({ userId }) => userId)
        },
        startCalendarDate() {
            return this.currentEvent.start
        },
        availableChangePayType() {
            return this.$store.getters['currentUserStore/settings']?.holiday
                ?.settingHolidayAllAvailablePayTypes?.config?.availableToChange ?? true
        },
        vacationPayBeforeStartOptions() {
            const texts = availableHolidayPayTypes.reduce((acc, type) => {
                acc[type.value] = type.title
                return acc
            }, {})

            return (this.$store.getters['currentUserStore/settings']?.holiday
                ?.settingHolidayAllAvailablePayTypes?.config?.types ?? []).map((type) => ({
                value: type,
                text: texts[type]
            }))
        }
    },
    methods: {
        isDateNotHolidayAndWeekend(date) {
            return ![6, 7].includes(date.isoWeekday()) && !this.holidayDates.includes(date.format('YYYY-MM-DD'))
        },
        async findDeputies(tag, value) {
            return await employeeAutocompleteService.find(tag, value)
        },
        onChangeDeputies(value) {
            this.$store.dispatch('vacationModalStore/setDeputies', {
                userIds: value,
                users: this.$refs.deputies.getUsersByIds(value)
            })
        },
        onChangeSendInviteForEmployeeAndLeader(checked) {
            this.$store.commit('vacationModalStore/SET_SEND_INVITE_FOR_EMPLOYEE_AND_LEADER', checked)
        },
        onChangeField(value, field) {
            if (!this.editableCalendar) {
                return
            }

            this.$store.commit('vacationModalStore/UPDATE_CURRENT_EVENT', { field, value })
        }
    }
}
</script>