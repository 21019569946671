<template>
    <div class='mobile-nav'>
        <div class='mobile-nav__header'>
            <hra-button @click="$emit('close')" :color='uiColor.white' no-background :size='buttonSize.auto'>
                <hra-icon :name='iconName.close' class='mobile-nav-toggle close' />
            </hra-button>
        </div>
        <div class='mobile-nav__nav'>
            <div class='mobile-nav__wrapper'>
                <div class='mobile-nav__top'>
                    <div class='d-flex flex-column gap-4 pa-4'>
                        <ui-button
                            background='primary'
                            :modal="{ name: 'VacationModal', props: { userId }, on: {changed: () => $root.$emit('EmployeeCalendarRefresh')}}"
                        >
                            {{ $t('Atostogų prašymas') }}
                        </ui-button>

                        <ui-button
                            v-if='isEnabledRemoteWorkRequestEmployee'
                            background='primary'
                            :modal="{name: 'RemoteWorkModal', props: {userId}, on: {changed: () => $root.$emit('EmployeeCalendarRefresh')}}"
                        >
                            {{ $t('Remote work application') }}
                        </ui-button>
                    </div>

                    <ul class='sidebar__nav'>
                        <SidebarNavItem
                            v-for='(menu, key) in sidebarMenu'
                            v-if='menu.hasMobileView'
                            :key='key'
                            v-bind='menu'
                        />
                    </ul>
                </div>
                <div class='mobile-nav__bottom'>
                    <ul class='sidebar__nav'>
                        <li>
                            <a @click.prevent='logout' class='px-5 py-6'>
                                <i class='icon-logout' />
                                <span>{{ $t('Atsijungti') }}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SidebarNavItem from '@/components/ui/sidebar/SidebarNavItem.vue'
import UiButton from '@/domain/components/UiButton.vue'
import HraButton from '@/components/ui/button/HraButton'
import HraIcon from '@/components/ui/HraIcon'
import { mapGetters, mapMutations } from 'vuex'
import { redirectToAuth } from '@/utils/default'

export default {
    name: 'HeaderMobile',
    components: {
        SidebarNavItem,
        UiButton,
        HraButton,
        HraIcon
    },
    computed: {
        ...mapGetters({
            userId: 'currentUserStore/userId',
            sidebarMenu: 'global/sidebarMenu'
        }),
        isEnabledRemoteWorkRequestEmployee() {
            return this.$store.getters['currentUserStore/settings']?.remote_work
                ?.settingRemoteWorkEmployeeCanSendRequest?.config.enabled
        }
    },
    methods: {
        ...mapMutations({
            userLogout: 'user/logout'
        }),
        async logout() {
            try {
                await this.$api.auth.logout()
            } finally {
                redirectToAuth()
            }
        }
    }
}
</script>