export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_CATEGORY_TITLE = 'SET_CATEGORY_TITLE';
export const SET_COMMENTS = "SET_COMMENTS";
export const SET_DOCUMENTS = "SET_DOCUMENTS";
export const SET_QUESTIONS = "SET_QUESTIONS";
export const SET_ACTIVE_PAGE_ID = "SET_ACTIVE_PAGE_ID";
export const SET_DK_QUESTION_BALANCE = "SET_DK_QUESTION_BALANCE";
export const SET_HR_QUESTION_BALANCE = "SET_HR_QUESTION_BALANCE";
export const SET_HOME_DK_QUESTIONS = "SET_HOME_DK_QUESTIONS";
export const ADD_HOME_DK_QUESTIONS = "ADD_HOME_DK_QUESTIONS";
export const SET_HOME_HR_QUESTIONS = "SET_HOME_HR_QUESTIONS";
export const ADD_HOME_HR_QUESTIONS = "ADD_HOME_HR_QUESTIONS";

export default ({
    [SET_CATEGORY_TITLE]: (state, payload) => state.categoryTitle = payload,
    [SET_CATEGORIES]: (state, payload) => state.categories = payload,
    [SET_COMMENTS]: (state, payload) => state.comments = payload,
    [SET_DOCUMENTS]: (state, payload) => state.documents = payload,
    [SET_QUESTIONS]: (state, payload) => state.questions = payload,
    [SET_DK_QUESTION_BALANCE]: (state, payload) => state.dkQuestionBalance = payload,
    [SET_HR_QUESTION_BALANCE]: (state, payload) => state.hrQuestionBalance = payload,
    [SET_ACTIVE_PAGE_ID]: (state, payload) => state.activePageId = payload,
    [SET_HOME_DK_QUESTIONS]: (state, payload) => state.homeDkQuestions = payload,
    [ADD_HOME_DK_QUESTIONS]: (state, payload) => {
        state.homeDkQuestions.unshift(payload)
        state.dkQuestionBalance--;
    },
    [SET_HOME_HR_QUESTIONS]: (state, payload) => state.homeHrQuestions = payload,
    [ADD_HOME_HR_QUESTIONS]: (state, payload) => {
        state.homeHrQuestions.unshift(payload)
        state.hrQuestionBalance--;
    },
})