<template>
    <grid-item
        :title='item.item.title'
        icon='group'
        @click='handleOpenGroupSidebar'
    >
        <template #controls>
            <documents-finder-context-menu>
                <documents-finder-context-menu-item
                    icon='edit'
                    :title='$t("Redaguoti")'
                    @click='handleOpenUpdateGroupModal'
                />
                <documents-finder-context-menu-item
                    icon='bin'
                    :title='$t("Ištrinti")'
                    @click='handleOpenDeleteGroupModal'
                />
            </documents-finder-context-menu>
        </template>
    </grid-item>
</template>

<script>
import DocumentsFinderContextMenuItem from '@/domain/documents/finder/components/ContextMenuItem.vue'
import DocumentsFinderContextMenu from '@/domain/documents/finder/components/ContextMenu.vue'
import UiIcon from '@/domain/components/UiIcon.vue'
import documentFinderFolderItemMixin from '@/domain/documents/finder/mixins/documentFinderFolderItemMixin'
import GridItem from '@/domain/documents/finder/components/content/grid/GridItem.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import documentFinderGroupItemMixin from '@/domain/documents/finder/mixins/documentFinderGroupItemMixin'

export default {
    name: 'GridGroupItem',
    components: { FormCheckbox, GridItem, DocumentsFinderContextMenuItem, DocumentsFinderContextMenu, UiIcon },
    mixins: [documentFinderGroupItemMixin],
}
</script>