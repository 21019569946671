<template>
  <v-main class="auth">
    <v-container fluid fill-height>
      <router-view />
    </v-container>
    <v-overlay :opacity="1" :value="loadingView">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-main>
</template>

<script>
import authService from "@/services/auth/authService";

export default {
  name: "AuthLayout",
	provide() {
		return {
			authService,
		};
	},
  data() {
    return {
      loaderName: "AuthLayout",
    };
  },
};
</script>