import employeeAutocompleteService from '@/services/employees/employeeAutocompleteService'
import customApi from '@/plugins/customApi'
import { generateUuid } from '@/utils/default'

const approvalLevels = 'approvalLevelStore'

export default {
    data() {
        return {
            loading: true,
            tagId: 'COMPANY_DEPARTMENT_MODAL_TAG',
            visibleParent: true,
            employeeAutocompleteService,
            departments: [],
            formData: {
                name: '',
                departmentId: null,
                orders: [],
                holidayEventMinDeputyCount: 0,
                holidayEventMaxDeputyCount: 1
            },
            usersTableHeaders: [
                { name: 'name', text: this.$t('Vardas pavardė') },
                { name: 'email', text: this.$t('El. paštas') },
                { name: 'department', text: this.$t('Skyrius') },
                { name: 'duty', text: this.$t('Pareigybė') }
            ],
            users: [],
            findUsers: [],
            userId: null
        }
    },
    watch: {
        userId: {
            handler(value, oldValue) {
                this.onChangedUserId(value, oldValue)
            }
        }
    },
    created() {
        employeeAutocompleteService.setParams({ withDepartment: true, withDuty: true })
    },
    computed: {
        departmentOptions() {
            const ignoreId = this.id ?? null
            return this.departments
                .filter(({ id }) => id !== ignoreId)
                .map(department => ({
                    value: department.id,
                    text: department.name
                }))
        },
        ordersLength() {
            return this.formData.orders?.length ?? 0
        }
    },
    methods: {
        async findUserService(tag, value) {
            this.findUsers = await employeeAutocompleteService.find(tag, value)
            return this.findUsers
        },
        onChangedUserId(userId, oldUserId) {
            if (this.loading) {
                return
            }

            const user = this.findUsers.find(({ employee }) => employee.id === userId)
            if (user?.employee == null) {
                return
            }

            if (this.formData.orders.length === 0) {
                this.createNewLevel(1, [user.employee])
                return
            }

            if (oldUserId == null) {
                return
            }

            this.formData.orders.forEach(level => {
                const userIndex = level.users.findIndex(({ id }) => id === oldUserId)
                if (userIndex !== -1) {
                    this.$set(level.users, userIndex, user.employee)
                }
            })
        },
        async getDepartments() {
            const api = customApi(null)
            const response = await api.request(api.factories.company.departments.fetch())

            if (response == null) {
                return
            }

            this.setDepartments(response)
        },
        setDepartments(departments) {
            this.departments = departments
        },
        async getPayload() {
            return {
                name: this.formData.name,
                departmentId: this.formData.departmentId,
                userId: this.userId,
                orders: this.formData.orders.map((level, key) => ({
                    order: key + 1,
                    minToApprove: level.minToApprove,
                    userIds: level.users.map(user => user.id)
                })),
                holidayEventMinDeputyCount: this.formData.holidayEventMinDeputyCount,
                holidayEventMaxDeputyCount: this.formData.holidayEventMaxDeputyCount
            }
        },
        getLevelById(id) {
            return this.formData.orders.find(level => level.id === id)
        },
        createNewLevel(minToApprove = 1, users = []) {
            this.formData.orders.push({
                id: generateUuid(),
                users: users,
                minToApprove: minToApprove,
                order: this.formData.orders.length + 1
            })
        },
        handleCreateNewLevel() {
            this.createNewLevel()
        },
        handleUpdateLevelUsers({ levelId, selectedUsers }) {
            const level = this.getLevelById(levelId)
            if (level == null) {
                return
            }

            this.$set(level, 'users', selectedUsers)
        },
        handleUpdateLevelMinToApprove({ levelId, value }) {
            const level = this.getLevelById(levelId)
            if (level == null) {
                return
            }

            this.$set(level, 'minToApprove', value)
        },
        handleRemoveUserFromLevel({ levelId, userId }) {
            const level = this.getLevelById(levelId)
            if (level == null) {
                return
            }

            const userIndex = level.users.findIndex(user => user.id === userId)
            if (userIndex === -1) {
                return
            }

            level.users.splice(userIndex, 1)

            if (level.users.length === 0) {
                this.handleRemoveLevel(levelId)
            }
        },
        handleRemoveLevel(levelId) {
            const index = this.formData.orders.findIndex(order => order.id === levelId)
            if (index === -1) {
                return
            }

            this.formData.orders.splice(index, 1)

            if (this.formData.orders.length === 0 && this.userId != null) {
                const user = this.findUsers.find(({ employee }) => employee.id === this.userId)?.employee ?? null

                if (user == null) {
                    return
                }

                this.createNewLevel(1, [user])
            }
        }

    }
}