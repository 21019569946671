<template>
    <div class='page max-page-width'>
        <v-row class='mx-6'>
            <v-col cols='12'>
                <div class='d-flex gap-3 my-3 align-end'>
                    <datepicker-field :label="$t('Likutis dienai')" v-model='balanceReportDate' />
                    <ui-button @click='fetchReport'>{{ $t('Rodyti ekrane') }}</ui-button>
                    <ui-button background='primary' @click='onGetVacationBalanceReport'>{{ $t('Atsiųsti .xlsx') }}
                    </ui-button>
                </div>
            </v-col>
            <v-col cols='12' class='pb-3'>
                <ui-table
                    :loading='loading'
                    :is-empty-table='rows.length === 0'
                >
                    <template #empty-table>
                        <message-alert
                            id='table-alert-empty'
                            :message='$t("Įrašų sistemoje nerasta.")'
                            type='info'
                        />
                    </template>
                    <template #head>
                        <tr>
                            <th>{{ $t('Vardas Pavardė') }}</th>
                            <th>{{ $t('Tabelio nr.') }}</th>
                            <th>{{ $t('Sukaupta (d. d.)') }}</th>
                            <th>{{ $t('Rezevuota (d. d.)') }}</th>
                            <th>{{ $t('Likutis (d. d.)') }}</th>
                        </tr>
                    </template>
                    <template #body>
                        <tr v-for='(row, key) in rows' :key='key'>
                            <td>{{ row.name }}</td>
                            <td>{{ row.timeCardNumber }}</td>
                            <td>{{ row.accumulated }}</td>
                            <td>{{ row.reserved }}</td>
                            <td>{{ row.balance }}</td>
                        </tr>
                    </template>
                </ui-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import moment from 'moment/moment'
import DatepickerField from '@/domain/fields/DatepickerField'
import SelectField from '@/domain/fields/SelectField'
import UiButton from '@/domain/components/UiButton.vue'
import customApi from '@/plugins/customApi'
import UiTable from '@/domain/components/uiTable/UiTable.vue'
import MessageAlert from '@/domain/messages/components/MessageAlert.vue'

export default {
    name: 'VacationBalancePage',
    components: { MessageAlert, UiTable, UiButton, SelectField, DatepickerField },
    data() {
        const today = moment().format('YYYY-MM-DD')

        return {
            loading: false,
            message: null,
            balanceReportDate: today,
            rows: []
        }
    },
    methods: {
        async fetchReport() {
            this.loading = true
            try {
                const {data} = await customApi().factories.vacations.reports.balance.fetch(this.balanceReportDate)

                if (data.length === 0) {
                    this.rows = []
                    return
                }

                this.rows = data
            } catch (e) {
                console.error(e)
                this.rows = []
            } finally {
                this.loading = false
            }
        },
        onGetVacationBalanceReport() {
            window.location.href = `/api/events/holidays/reports/balance/${this.balanceReportDate}/download`
        }
    }
}
</script>