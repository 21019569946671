
export default () => ({
  yearlyVacationCalendar: [],
  vacationCalculations: [],
  vacations: [],
  plusVacations: [],
  child: [],
  disabilities: [],
  contracts: [],
  contractDetails: [],
});