<template>
    <custom-dialog :max-width='700' has-custom-footer>
        <template #title>{{ $t('Darbuotojo neįgalumas') }}</template>
        <template #content>
            <Form v-model='formData' is-dialog :form-loader-name='loaderName' @submit='onSubmit'>
                <template #form>
                    <v-row>
                        <v-col cols='12'>
                            <text-field
                                name='number'
                                rules='required'
                                :label="$t('Neįgalumo pažymėjimo nr.:')"
                                v-model='formData.number'
                            />
                        </v-col>
                        <v-col cols='12'>
                            <datepicker-field
                                name='fromDate'
                                rules='required'
                                :label="$t('Nuo')"
                                v-model='formData.fromDate'
                            />
                        </v-col>
                        <v-col cols='12'>
                            <datepicker-field
                                name='toDate'
                                rules='required'
                                :date-from='formData.fromDate'
                                :label="$t('Iki')"
                                v-model='formData.toDate'
                            />
                        </v-col>
                    </v-row>
                </template>

                <template #buttons='{loading}'>
                    <hra-button :size='buttonSize.small' @click='close'>
                        {{ $t('Uždaryti') }}
                    </hra-button>
                    <hra-button :color='uiColor.primary' :size='buttonSize.small' :type='buttonType.submit'
                                :loading='loadingButton'>
                        {{ $t('Išsaugoti') }}
                    </hra-button>
                </template>

                <template #footer-messages>
                    <message-alerts :listen='tag' />
                </template>
            </Form>
        </template>
    </custom-dialog>
</template>

<script>
import HraButton from '@/components/ui/button/HraButton'
import HraIcon from '@/components/ui/HraIcon'
import dialogMixin from '@/mixins/dialogMixin'
import CustomDialog from '@/components/ui/dialog/CustomDialog'
import FormMessages from '@/components/ui/form/FormMessages'
import FormAlert from '@/components/ui/form/FormAlert'
import FullRowLoader from '@/components/ui/FullRowLoader'
import Form from '@/components/ui/form/Form'
import DatepickerField from '@/domain/fields/DatepickerField'
import moment from 'moment'
import TextField from '@/domain/fields/TextField'
import MessageAlerts from '@/domain/messages/MessageAlerts.vue'

export default {
    name: 'EmployeeDisabilityModal',
    mixins: [dialogMixin],
    components: {
        MessageAlerts,
        TextField,
        DatepickerField, Form, FullRowLoader, FormAlert, FormMessages, CustomDialog, HraIcon, HraButton
    },
    props: {
        service: { type: Function, required: true },
        payload: { type: Object, default: null }
    },
    created() {
    },
    data() {
        const today = moment().format('YYYY-MM-DD')

        return {
            loadingButton: false,
            closeDialogDelay: 1000,
            message: null,
            today,
            tag: 'EMPLOYEE_DISABILITY_MODAL_SUBMIT_TAG',
            formData: {
                number: this.payload?.number || '',
                fromDate: this.payload?.fromDate || today,
                toDate: this.payload?.toDate || today
            }
        }
    },
    methods: {
        async onSubmit(payload) {
            this.loadingButton = true

            const service = await this.service(this.tag, payload, this.payload?.id ?? null)

            if (service) {
                this.message = { type: 'success', text: this.$t('Išsaugotą sekmingai') }
                this.closeWithDelay()
            }

            this.loadingButton = false
        }
    }
}
</script>
