const state = () => ({
    loadYears: [],
    loadingYears: [],
    holidays: []
})

const getters = {
    holidays(state) {
        return state.holidays
    },
    holidayDates({ holidays }) {
        return holidays.map(holiday => holiday.date)
    },
    eventHolidays(state) {
        let events = {}
        state.holidays.forEach(holiday => events[holiday.date] = holiday.description)

        return events
    }
}

const mutations = {
    appendHolidays(state, payload) {
        state.holidays = [
            ...state.holidays,
            ...payload
        ]
    },
    loadedYear(state, payload) {
        state.loadYears.push(payload)
        state.loadingYears = state.loadingYears.filter(year => year !== payload)
    },
    loadingYear(state, payload) {
        state.loadingYears.push(payload)
    }
}

const actions = {
    async fetch({ state, commit }, { year }) {
        if (state.loadYears.includes(year) || state.loadingYears.includes(year)) {
            return
        }

        commit('loadingYear', year)

        try {
            const { data } = await this._vm.$api.calendar.holidays({ year, countryCode: 'LT' })
            commit('loadedYear', year)
            commit('appendHolidays', data)
        } catch (e) {
        }
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
