<template>
    <button
        v-if="hasPermissions"
        :class="buttonClass"
        :type="type"
        :disabled="disabled"
        @click.prevent="onClick"
        v-on="$listeners"
    >
        <template v-if="loading">
            <slot name="loading">
                <full-row-loader size="20"/>
            </slot>
        </template>
        <template v-else>
            <slot name="icon"/>
            <slot/>
        </template>
    </button>
</template>

<script>
import {mapMutations} from "vuex";
import hraButtonMixin from "@/mixins/hraButtonMixin";
import FullRowLoader from "@/components/ui/FullRowLoader";

export default {
    name: "HraButtonModal",
    components: {FullRowLoader},
    mixins: [hraButtonMixin],
    props: {
        modalName: {type: String, required: true},
        modalProps: {type: Object, default: null},
    },
    methods: {
        ...mapMutations({
            openModal: "modals/open",
        }),
        onClick() {
            this.openModal({
                name: this.modalName,
                props: this.modalProps,
            });
        },
    },
}
</script>
