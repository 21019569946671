<template>
	<hra-table
		:headers="headers"
		:rows="rows"
		is-inline-edit
		:need-actions="!readOnly"
	>
		<template #header.custom>
			<div class="d-flex pb-3 align-end">
				<table-title-view :title="title"/>
				<v-spacer/>
				<hra-button-modal v-if="!readOnly" :size="buttonSize.small" :color="uiColor.primary" small
				                  :modal-name="createModalName" :modal-props="createModalProps">
					<slot name="button.create.text">Prideti</slot>
				</hra-button-modal>
			</div>
		</template>

		<template #row.inline.edit="{row, cols}">
			<hra-table-inline-edit-row :id="row.id" v-model="cols"
			                           :before-save="beforeSave" :before-abort="beforeAbort"
			                           :before-delete="beforeDelete" :before-edit="beforeEdit"
			                           @delete="onDelete"/>
		</template>

		<template #empty.text>
			<slot name="empty.text">table empty</slot>
		</template>
	</hra-table>
</template>

<script>
import HraTable from "@/components/ui/table/HraTable";
import TableTitleView from "@/components/ui/views/TableTitleView";
import HraButtonModal from "@/components/ui/button/HraButtonModal";
import HraTableInlineEditRow from "@/components/ui/table/HraTableInlineEditRow";

export default {
	name: "HraTableEditableInlineTemplate",
	components: {HraTableInlineEditRow, HraButtonModal, TableTitleView, HraTable},
	props: {
		headers: {type: Array, required: true},
		rows: {type: Array, required: true},
		title: {type: String, required: true},
		readOnly: {type: Boolean, default: false},
		createModalName: {type: String, default: ""},
		createModalProps: {type: Object, default: () => ({})},
		beforeEdit: {type: Function, default: () => true},
		beforeSave: {type: Function, default: () => true},
		beforeAbort: {type: Function, default: () => true},
		beforeDelete: {type: Function, default: () => true},
	},
	methods: {
		onDelete(id) {
			this.$emit("delete", id);
		},
	},
}
</script>
