import {mapGetters} from "vuex";

export default {
  props: {
    listen: {type: [String, Array], required: true},
  },
  computed: {
    ...mapGetters({
      stackListen: "messagesStore/stackListen",
    }),
    listenMessages() {
      return this.stackListen(this.listen);
    },
  },
}