import {VacationModalService} from "@/domain/vacationModal/services/vcationModalService";
import store from "@/store";
import customApi from "@/plugins/customApi";
import employeeVacationService from "@/domain/employeeVacations/services/employeeVacationService";

export class EmployeeVacationModalService extends VacationModalService {

  async init(tag, checkTag, currentId, employeeId = null, initPayload = null) {
    const api = customApi(tag);
    employeeId = employeeId ?? store.getters["authStore/employeeId"];

    await this.baseInit(
      tag,
      checkTag,
      await api.request(api.factories.employee.vacation.fetch(employeeId)),
      currentId,
      null,
      initPayload
    );
  }

  async create(tag) {
    const formData = store.getters["vacationModalStore/formData"];
    return await employeeVacationService.create(tag, this.formatFormData(formData));
  }

  async update(tag) {
    const id = store.getters["vacationModalStore/currentId"];
    const formData = store.getters["vacationModalStore/formData"];
    return await employeeVacationService.update(tag, id, this.formatFormData(formData));
  }

  async change(tag) {
    const id = store.getters["vacationModalStore/currentId"];
    const formData = store.getters["vacationModalStore/formData"];
    return await employeeVacationService.change(tag, id, this.formatFormData(formData));
  }

  async abort(tag) {
    const id = store.getters["vacationModalStore/currentId"];
    return await employeeVacationService.abort(tag, id);
  }
}

export default new EmployeeVacationModalService;