<template>
    <v-menu
        offset-y
        nudge-bottom='15'
        nudge-left='0'
        max-width='200'
        origin='bottom right'
        content-class='elevation-0 header__profile-navigation'
    >
        <template v-slot:activator='{ on, attrs }'>
            <div
                class='header__profile ml-5'
                v-bind='attrs'
                v-on='on'
            >
                <Avatar :employee-name='user.name' :src='user.avatarSrc' size='40' />
                <div class='header__profile-content'>
                    <h6>{{ user.name }}</h6>
                    <hra-icon
                        :name='iconName.down'
                    />
                </div>
            </div>
        </template>

        <router-link :to="{ name: 'EmployeeProfilePage' }">
            <i class='icon-person' />
            <span>{{ $t('Mano profilis') }}</span>
        </router-link>

        <router-link v-if='isAdmin' :to="{ name: 'SettingsHomePage' }">
            <i class='icon-settings' />
            <span>{{ $t('Nustatymai') }}</span>
        </router-link>
        <div class='divider' />
        <a @click.prevent='logout'>
            <i class='icon-logout' />
            <span>{{ $t('Atsijungti') }}</span>
        </a>
    </v-menu>
</template>

<script>
import Vue from 'vue'
import { authRouteProps } from '@/router/auth'
import Avatar from '@/components/ui/Avatar'
import { mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import HraIcon from '@/components/ui/HraIcon'
import { redirectToAuth } from '@/utils/default'
import checkIsAdminMixin from '@/mixins/checkIsAdminMixin'

export default {
    components: { HraIcon, Avatar },
    mixins: [checkIsAdminMixin],
    data: () => ({
        active: false
    }),
    computed: {
        ...mapGetters({
            user: 'currentUserStore/user'
        }),
        today() {
            return moment().format('YYYY-MM-DD')
        },
        yearEnd() {
            return moment().format('YYYY-') + '12-31'
        },
        iconClass() {
            return {
                'icon-up': this.active,
                'icon-down': !this.active
            }
        }
    },
    methods: {
        ...mapMutations({
            userLogout: 'user/logout'
        }),
        async logout() {
            try {
                await this.$api.auth.logout()
            } finally {
                redirectToAuth()
            }
        }
    }
}
</script>
