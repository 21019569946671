import {vacationStatus} from "@/utils/default";

import {
  getters as vacationGetters,
  mutations as vacationMutations,
  actions as vacationActions
} from "@/store/modules/employee/vacations";

const state = () => ({
  loading: false,
  data: [],
  vacationEditId: null,
});

const getters = {
  data: vacationGetters.data,
  calendarEvents: vacationGetters.calendarEvents,
  rows: vacationGetters.rows,
};

const mutations = {
  ...vacationMutations,
};

const actions = {
  ...vacationActions,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
