//  initial state
const state = () => ({
    filterData: [],
});

// getters
const getters = {
    data(state) {
        return (name) => {
            return state.filterData.filter(data => data.name === name)[0] || null;
        };
    },
};

// actions
const actions = {};

//  mutations
const mutations = {
    init(state, payload) {
        state.filterData.push(payload);
    },
    change(state, payload) {
        state.filterData = state.filterData.map(data => data.name === payload.name ? payload : data);
    },
    destroy(state, name) {
        state.filterData = state.filterData.filter(data => data.name !== name);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
