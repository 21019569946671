<template>
    <validation-provider
        tag='div'
        :class="['TextField', { 'inline-number-input': isInlineNumberInput }]"
        :immediate='immediate'
        :rules='rules'
        :name='name'
        v-slot='{ failed, ariaInput, failedRules, errors: validationErrors }'
    >
        <read-only-field v-if='readOnly' :invalid='failed' :value='value' />
        <base-field v-else :invalid='failed'>
            <template #label-prepend>
                <slot name='label-prepend' />
            </template>
            <template #label-append>
                <slot name='label-append' />
            </template>
            <div class='field TextField__field' :class="{...fieldClasses, ['invalid']: failed && !disabled}">
                <slot name='prepend-inner'/>
                <i v-if='isInlineNumberInput' class='icon-minus' @click="decrement" />
                <input
                    class='TextField__input'
                    :name='name'
                    :disabled='hasDisabled'
                    :type='type'
                    :value='value'
                    :placeholder='placeholder'
                    :step='step'
                    :min='min'
                    :max='max'
                    @input='isInlineNumberInput ? $event.preventDefault() : onChangeInput($event)'
                    v-bind='ariaInput'
                />
                <i v-if='isInlineNumberInput' class='icon-plus' @click="increment" />
                <i v-if='hasCanClear' class='icon-close TextField__clear' @click='onClear' />
                <editable-field-actions />
                <slot name='append-inner' />
            </div>
        </base-field>
        <div v-if='!!validationErrors' class='hra-text--red mt-1'>
            <div v-for='error in validationErrors'>{{ error }}</div>
        </div>
        <div v-if='!!errors' class='hra-text--red mt-1'>
            <div v-for='error in errors'>{{ error }}</div>
        </div>
        <div v-if='failedRules.confirmed != null' class='hra-text--red mt-1'>
            <slot name='confirmed-error-message' />
        </div>
    </validation-provider>
</template>

<script>
import ReadOnlyField from '@/domain/fields/components/ReadOnlyField'
import BaseField from '@/domain/fields/components/BaseField'
import baseFieldMixin from '@/domain/fields/mixins/baseFieldMixin'
import EditableFieldActions from '@/domain/fields/components/EditableFieldActions'
import UiIcon from '@/domain/components/UiIcon.vue'

export default {
    name: 'TextField',
    components: { EditableFieldActions, BaseField, ReadOnlyField, UiIcon },
    mixins: [baseFieldMixin],
    props: {
        canClear: { type: Boolean, default: null },
        type: {
            type: String,
            default: 'text',
            validator: function(value) {
                return ['text', 'number', 'time', 'email', 'float', 'phone', 'password'].includes(value)
            }
        },
        step: { type: [String, Number], default: null },
        min: { type: [String, Number], default: null },
        max: { type: [String, Number], default: null },
        errors: {type: Array, default: () => []},
        inlineNumberInput: { type: Boolean, default: false },
    },
    watch: {
        min: {
            handler(minValue) {
                if (this.value < minValue) {
                    this.onChange(minValue)
                }
            }
        },
        max: {
            handler(maxValue) {
                if (this.value > maxValue) {
                    this.onChange(maxValue)
                }
            }
        }
    },
    computed: {
        hasCanClear() {
            if (!this.canClear) {
                return false
            }

            return !this.hasDisabled && !!this.value
        },
        isInlineNumberInput() {
            if (this.type == 'number' && this.inlineNumberInput == true) {
                return true
            }
        }
    },
    methods: {
        onChangeInput(event) {
            this.onChange(event.target.value)
        },
        onClear() {
            this.onChange('')
        },
        increment() {
            const parsedValue = parseInt(this.value)
            if (parsedValue < this.max || this.max == null) {
                this.onChange(parsedValue + 1)
            }
        },
        decrement() {
            if (this.value > this.min || this.min == null) {
                this.onChange(this.value - 1)
            }
        }
    }
}
</script>

<style lang='scss'>
@import "@/assets/sass/core/vars";

.TextField {

    &__field {
        padding: 0 13px;

        &.disabled {
            background: #F3F4FF;
        }
    }

    &__input {
        width: 100%;
        height: 100%;
        outline: none;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: $black-800;
        box-shadow: 0 0 0 1000px $white inset;

        &[disabled="disabled"] {
            box-shadow: 0 0 0 1000px #F3F4FF inset;
        }

        &::placeholder {
            color: $greyscale-600;
        }
    }

    &__clear {
        color: $red;
        font-size: 12px;
        cursor: pointer;
    }
}

.TextField__input:has(~.EditableFieldActions) {
    &[disabled="disabled"] {
        box-shadow: 0 0 0 1000px $white inset;
    }
}

.inline-number-input {
    appearance: none;
    min-width: 80px;

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
    }

    .field {
        padding: 0 6px;
        background-color: $violet-200;
        border-radius: 30px;

        input {
            text-align: center;
            box-shadow: none;
            color: $white
        }

        i {
            background-color: $white;
            color: $violet-200;
            font-size: 10px;
            padding: 3px;
            border-radius: 50%;
            user-select: none;
            cursor: pointer;
        }
    }
}
</style>