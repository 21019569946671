<template>
    <div class='custom-tabs' ref='tabs'>
        <div class='custom-tabs__header' ref='header' :class='headerClasses'>
            <div class='custom-tabs__tabs'>
                <div
                    v-for='{name, text} in tabs'
                    :key='name'
                    class='custom-tabs__tab'
                    :class="{'active':  activeTab === name}"
                    @click.prevent='onChangeTab(name)'
                >
                    {{ text }}
                </div>
            </div>
        </div>
        <div class='custom-tabs__container'>
            <slot v-for='{name} in tabs' :name='name' v-if='name === activeTab' />
        </div>
    </div>
</template>

<script>
export default {
    name: 'CustomTabs',
    props: {
        tabs: { type: Array, required: true },
        defaultTabName: { type: String, required: true },
        isSticky: { type: Boolean, default: false },
        scrollToHeader: { type: Boolean, default: false }
    },
    data() {
        return {
            activeTab: this.defaultTabName
        }
    },
    watch: {
        $route: {
            handler(value, oldValue) {
                const tab = value?.query?.tab ?? null
                const oldTab = oldValue?.query?.tab ?? null

                if (tab) {
                    this.activeTab = tab
                    this.$emit('changeTab', tab)

                    this.$nextTick(() => {
                        tab !== oldTab && this.scrollToHeader && this.$refs.tabs.scrollIntoView({
                                behavior: 'smooth', block: 'start', inline: 'start'
                            }
                        )
                    })
                }
            },
            immediate: true
        }
    },
    computed: {
        headerClasses() {
            return {
                'sticky': this.isSticky
            }
        }
    },
    methods: {
        onChangeTab(tab) {
            if (this.activeTab === tab) {
                return
            }

            this.$emit('changeTab', tab)
            this.$router.push({ query: { tab } })
        }
    }
}
</script>

<style lang='scss' scoped>
@import '@/assets/sass/core/vars';
@import '@/assets/sass/mixins/_responsive';

.custom-tabs {
    width: 100%;

    &__header {
        width: 100%;

        &.sticky {
            position: sticky;
            top: 0;
            z-index: 5;
        }
    }

    &__tabs {
        position: relative;
        width: 100%;
        height: 49px;
        display: flex;
        align-items: flex-end;
        gap: 10px;
        padding: 0 32px;
        overflow: hidden;

        @include xs-down {
            padding: 0 20px;
        }

        &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            //background: $gray-300;
            background: #DAD5E9;
        }
    }

    &__tab {
        position: relative;
        height: 100%;
        padding: 0 20px;
        display: flex;
        align-items: center;
        //background: $gray-200;
        background: #F1EEF9;
        cursor: pointer;
        border-radius: 4px 4px 0 0;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: $greyscale-600;

        @include xs-down {
            padding: 0 15px;
        }

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            //background: #1A4BFF;
            background: #454C9C;
            border-radius: 4px 4px 0 0;
            height: 3px;
            width: 100%;
            opacity: 0;
            transition: opacity 300ms;
        }

        &:hover, &.active {
            &:before {
                opacity: 1;
            }
        }

        &.active {
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 16px rgba(0, 0, 0, 0.1);
            background: $white;
            z-index: 2;
            font-weight: 600;
            color: $greyscale-800;
        }
    }

    &__container {
        overflow-x: auto;
        background: $white;
    }
}
</style>