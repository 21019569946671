<template>
    <grid-item
        :title='document.title'
        :size='documentSize'
        icon='paper'
        @dblclick.prevent='handleOpenPreviewDocumentModal'
        @click='handleOpenDocumentDetailsSidebar'
    >
        <template v-if='withStatus' #append-icon>
            <div class='d-flex align-center justify-center mt-1'>
                <document-sent-status
                    :status='status'
                    :comment='tooltipText'
                />
            </div>
        </template>
        <template v-if='withControls' #controls>
            <documents-finder-context-menu>
                <documents-finder-context-menu-item
                    icon='send'
                    :title='$t("Siųsti")'
                    @click='handleOpenDocumentsSendModal'
                />
                <documents-finder-context-menu-item
                    icon='edit'
                    :title='$t("Redaguoti")'
                    @click='handleOpenEditDocumentModal'
                />
                <documents-finder-context-menu-item
                    icon='eye'
                    :title='$t("Peržiūrėti")'
                    @click='handleOpenPreviewDocumentModal'
                />
                <documents-finder-context-menu-item
                    icon='message-info'
                    :title='$t("Istorija")'
                    @click='handleOpenHistoryDocumentModal'
                />
                <documents-finder-context-menu-item
                    icon='rotation'
                    :title='$t("Perkelti dokumentą")'
                    @click='handleOpenMoveDocumentModal'
                />
                <documents-finder-context-menu-item
                    icon='bin'
                    :title='$t("Archyvuoti dokumentą")'
                    @click='handleOpenArchiveDocumentModal'
                />
            </documents-finder-context-menu>
        </template>

        <template v-if='selectable' #checkbox>
            <form-checkbox
                v-if='selectable'
                :is-checked='item.selected'
                @change='handleDocumentToggleSelect'
            />
        </template>
    </grid-item>
</template>

<script>
import DocumentsFinderContextMenuItem from '@/domain/documents/finder/components/ContextMenuItem.vue'
import DocumentsFinderContextMenu from '@/domain/documents/finder/components/ContextMenu.vue'
import UiIcon from '@/domain/components/UiIcon.vue'
import GridItem from '@/domain/documents/finder/components/content/grid/GridItem.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import documentFinderDocumentItemMixin from '@/domain/documents/finder/mixins/documentFinderDocumentItemMixin'
import HraTooltip from '@/components/ui/HraTooltip.vue'
import DocumentSentStatus from '@/domain/documents/components/DocumentSentStatus.vue'

export default {
    name: 'GridDocumentItem',
    components: { DocumentSentStatus, HraTooltip, FormCheckbox, GridItem, DocumentsFinderContextMenuItem, DocumentsFinderContextMenu, UiIcon },
    mixins: [documentFinderDocumentItemMixin],
    computed: {
        documentSize() {
            if (this.withStatus) {
                return null
            }

            return this.document.sizeText
        },
        tooltipText() {
            if (this.accepted === '-') {
                return null
            }

            return `<b>${this.$t('Patvirtino')}:</b> ${this.accepted}`
        }
    }
}
</script>