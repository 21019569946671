<template>
	<custom-dialog is-confirm :max-width="400">
		<template #title>{{ title }}</template>
		<template #content>
			<form-alert v-if="message" v-bind="message"/>
			<full-row-loader v-if="isLoading"/>
		</template>
		<template #buttons>
			<hra-button v-if="message" :size="buttonSize.small" full-width @click="close">
				<template #icon>
					<hra-icon :name="iconName.close"/>
				</template>
				{{ $t("Uždaryti") }}
			</hra-button>
			<hra-button v-show="message && message.type === 'error'" :color="uiColor.primary" :size="buttonSize.small"
			            full-width @click="sentInvites">
				{{ $t("Bandyti dar kartą") }}
			</hra-button>
		</template>
	</custom-dialog>
</template>

<script>
import {mapMutations} from "vuex";
import HraButton from "@/components/ui/button/HraButton";
import HraIcon from "@/components/ui/HraIcon";
import dialogMixin from "@/mixins/dialogMixin";
import CustomDialog from "@/components/ui/dialog/CustomDialog";
import FormMessages from "@/components/ui/form/FormMessages";
import FormAlert from "@/components/ui/form/FormAlert";
import FullRowLoader from "@/components/ui/FullRowLoader";

export default {
	name: "EmployeeSendInviteModal",
	mixins: [dialogMixin],
	components: {FullRowLoader, FormAlert, FormMessages, CustomDialog, HraIcon, HraButton},
	props: {
		ids: {type: Array, required: true},
	},
	data: () => ({
		loaderName: "Employee-Sent-Invites",
		closeDialogDelay: 1000,
		message: null,
	}),
	created() {
		this.sentInvites();
	},
	destroyed() {
		this.clearTableData();
	},
	computed: {
		title() {
			if (this.ids.length > 1) {
				return this.$t("Siųsti pakvietimus");
			}

			return this.$t("Siųsti pakvietimą");
		}
	},
	methods: {
		...mapMutations({
			checkSentInvites: "employees/checkSentInvites",
			clearTableData: "employees/clearTableData",
		}),
		async sentInvites() {
			this.message = null;

			try {
				await this.$api.employee.sentInvites(this.ids, this.loaderName);
				this.message = {
					type: "success",
					text: this.ids.length > 1
						? this.$t("Pakvietimai buvo išsiųsti sėkmingai")
						: this.$t("Pakvietimas buvo išsiųstas sėkmingai")
				};
				this.checkSentInvites(this.ids);
				this.closeWithDelay();
			} catch (error) {
				this.message = {
					type: "error",
					text: this.$t("Siuntimo klaida"),
				};
			}
		}
	},
}
</script>
