<template>
	<div ref="container">
		<template v-if="isView">
			<slot name="view.prepend" :value="innerValue" :view-value="viewValue"/>
			<slot name="view" :value="innerValue" :view-value="viewValue">
				{{ viewValid ? viewValue.join(", ") : "?" }}
			</slot>
			<slot name="view.append" :value="innerValue" :view-value="viewValue"/>
		</template>
		<validation-provider v-else tag="div" :name="name" :rules="rules" immediate
		                     v-slot="{ classes, errors}">
			<input :name="name" type="hidden" :value="viewValue.join(', ')"/>
			<div class="form-select">
				<div :class="`form-select__label`">
					<slot name="label.prepend"/>
					<label>
						<slot name="label" :label="translateLabel">{{ translateLabel }}</slot>
					</label>
					<slot name="label.append"/>
				</div>
				<div class="d-flex align-center hra-multiselect gap-3" @dblclick="onEditField">
					<slot name="input.prepend"/>
					<div class="form-select__input gap-3"
					     :class="`border__${errors.length > 0 ? 'red' : 'gray_300'}`">
						<slot name="input.inner.prepend"/>
						<v-select
							solo
							chips
							flat
							single-line
							hide-details
							:placeholder="$t('Pasirikti')"
							:menu-props="{
                maxWidth: 'min-content',
		          }"
							:items="options"
							:multiple="multiple"
							v-model="innerValue"
						>
							<template #append>
								{{ innerValue.length }}
							</template>
							<template #prepend-item>
								<div style="height: 42px; width: 100%; position: sticky; top: 0; left: 0; background: #FFFFFF;
								padding: 0; margin: 0; z-index: 3;">
									<v-text-field
										solo
										flat
										hide-details
										:placeholder="$t('ieškoti...')"
									>
										<template #append>
											<hra-icon :name="iconName.find"/>
										</template>
									</v-text-field>
								</div>
							</template>
						</v-select>
						<!--            <v-autocomplete-->
						<!--                ref="autocomplete"-->
						<!--                v-model="innerValue"-->
						<!--                :prepend-inner-icon="null"-->
						<!--                :items="options"-->
						<!--                :placeholder="'Pasirinkti'"-->
						<!--                :label="translatePlaceholder"-->
						<!--                :multiple="validMultiple"-->
						<!--                solo-->
						<!--                chips-->
						<!--                flat-->
						<!--                single-line-->
						<!--                persistent-hint-->
						<!--                :menu-props="menuProps"-->
						<!--                hide-details-->
						<!--                @focus="onFocus"-->
						<!--                @blur="onBlur"-->
						<!--                :disabled="isDisabled"-->
						<!--                :error="errors.length > 0"-->
						<!--                :style="autocompleteStyle"-->
						<!--            >-->
						<!--              <template #append>-->
						<!--                <div class="d-flex align-center gap-1 pe-2">-->
						<!--                  <div v-show="selectCountText != null" class="hra-text&#45;&#45;black-800 hra-text&#45;&#45;13 px-1">{{ selectCountText }}</div>-->
						<!--                </div>-->
						<!--              </template>-->
						<!--              <template #selection="{attrs, select, selected, item}">-->
						<!--                <v-chip-->
						<!--                    ref="chip"-->
						<!--                    small-->
						<!--                    v-bind="attrs"-->
						<!--                    :input-value="selected"-->
						<!--                    :close="!isDisabled"-->
						<!--                    @click:close="remove(item)"-->
						<!--                >-->
						<!--                  <span :style="chipStyle">{{ item.text }}</span>-->
						<!--                </v-chip>-->
						<!--              </template>-->
						<!--              <template #item="data">-->
						<!--                <div class="d-flex justify-space-around align-center gap-3 py-2 px-3" style="width: 100%">-->
						<!--                  <div style="width: 100%">-->
						<!--                    <slot name="item" :item="data.item">-->
						<!--                      <span v-html="data.item.text"/>-->
						<!--                    </slot>-->
						<!--                  </div>-->
						<!--                  <div class="form-checkbox__container">-->
						<!--                    <input-->
						<!--                        class="form-checkbox__input"-->
						<!--                        type="checkbox"-->
						<!--                        :name="data.attrs.id"-->
						<!--                        :checked="data.attrs['aria-selected'] === 'true'"-->
						<!--                    />-->
						<!--                    <span class="form-checkbox__checkmark"/>-->
						<!--                  </div>-->
						<!--                </div>-->
						<!--              </template>-->
						<!--            </v-autocomplete>-->
						<hra-field-editable class="pe-3" v-if="editable" :now-edit="nowEdit" :loading="isLoading"
						                    @edit="onEditField" @abort="onAbortField" @save="onSaveField"/>
						<!--            <slot name="input.inner.append"/>-->
					</div>
					<slot name="input.append"/>
				</div>
			</div>
		</validation-provider>
	</div>
</template>

<script>
import hraFormMixin from "@/mixins/hraFormMixin";
import _ from "lodash";
import HraIcon from "@/components/ui/HraIcon";
import VAutocompleteCustom from "@/components/ui/form/VAutocompleteCustom";
import HraFieldEditable from "@/components/ui/form/HraFieldEditable";
import FormCheckbox from "@/components/ui/form/FormCheckbox";

export default {
	name: "HraFormMultiSelectField",
	components: {FormCheckbox, HraFieldEditable, VAutocompleteCustom, HraIcon},
	props: {
		options: {type: Array, default: () => ([])},
		multiple: {type: [Boolean, Number], default: false},
	},
	data: () => ({
		maxWidth: 0,
		innerValue: null,
		focused: false,
		selectCount: 0,
	}),
	mixins: [hraFormMixin],
	mounted() {
		this.maxWidth = this.$refs.container?.offsetWidth;
	},
	// watch: {
	//   innerValue: {
	//     handler: function (newValue, oldValue) {
	//
	//     },
	//     immediate: true,
	//   }
	// },
	computed: {
		validMultiple() {
			return Boolean(this.multiple);
		},
		viewValue() {
			if (this.innerValue == null) {
				return [];
			}

			let values = _.clone((this.validMultiple ? this.innerValue : [this.innerValue]));
			values.forEach(value => !isNaN(parseInt(value)) && values.push(parseInt(value)));

			return this.options.filter(option => values.includes(option.value)).map(option => option.text);
		},
		selectCountText() {
			if (!this.validMultiple) {
				return null;
			}

			if (this.selectCount > 0) {
				return `+ ${this.selectCount}`;
			}

			return null;
		},
		autocompleteStyle() {
			return {
				maxWidth: `${this.maxWidth}px`,
			};
		},
		chipStyle() {
			return {
				textOverflow: "ellipsis",
				overflow: "hidden",
			};
		},
		menuProps() {
			return {
				maxWidth: "min-content",
				padding: 0
			};
		},
	},
	methods: {
		handleChangeInnerValue(newValue, oldValue) {
			if (!_.eq(newValue, oldValue) && !_.eq(newValue, this.value)) {
				if (!this.validMultiple) {
					this.$refs.autocomplete?.blur();
				}

				return newValue;
			}
		},
		handleChangeValue(newValue, oldValue) {
			if (!_.eq(newValue, oldValue) && !_.eq(newValue, this.innerValue)) {
				return newValue;
			}
		},
		remove(item) {
			this.$refs.autocomplete.blur();
			this.innerValue = this.innerValue.filter(value => item.value !== value);
		},
		onFocus() {
			this.focused = true;
		},
		calculateHiddenChips() {
			if (this.validMultiple) {
				const container = this.$refs.container?.querySelector(".v-select__selections");
				if (!container) {
					return 0;
				}

				const bottom = container.getBoundingClientRect().bottom;
				const items = container.querySelectorAll('.v-chip');

				this.selectCount = [...items].filter(item => {
					return item.getBoundingClientRect().top > bottom;
				}).length;

				if (this.selectCount > 0) {
					this.$nextTick(() => {
						this.selectCount = [...items].filter(item => {
							return item.getBoundingClientRect().top > bottom;
						}).length;
					});
				}
			}
		},
		onBlur() {
			this.$nextTick(() => {
				this.$nextTick(() => {
					this.calculateHiddenChips();
				});
			});

			this.focused = false;
		},
	},
};
</script>

<style lang="scss">

.hra-multiselect .v-select {
	width: 100%;

	&__selections {
		max-height: 32px;
		overflow: hidden;
		gap: 10px 3px;
		padding-top: 3px;
		align-items: flex-start;

		.v-chip {
			//display: none;
			background: #222636 !important;
			color: #FFFFFF !important;
			height: 28px;

			&__close {
				background: transparent !important;
				color: #70778C !important;

				&:before {
					content: "\F0156";
					font-size: 14px;
					font-weight: bold;
				}
			}

			&:nth-last-child(2) {
				display: flex;
			}
		}
	}
}
</style>
