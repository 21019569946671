import {ValidationProvider} from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    value: {
      type: [String, Number, Array, Object],
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    unit: {
      type: String,
      default: "",
    },
    borderBottom: {
      type: Boolean,
      default: false,
    },
    fieldTransPrefix: {
      type: String,
      default: ""
    },
    delay: {
      type: Number,
      default: 0
    },
    isView: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    innerValue: "",
    baseClassName: "",
    mixinWatcher: false,
    timeout: null,
  }),
  watch: {
    innerValue: {
      handler(newValue, oldValue) {
        this.emitNewValue(newValue, oldValue);
      },
      deep: false,
      immediate: false,
    },
    value: {
      handler(newValue, oldValue) {
        if (this.innerValue !== newValue) {
          this.setInnerValue(newValue, oldValue);
        }
      },
      deep: false,
      immediate: true,
    },
  },
  // inject: ["$ignoreErrors"],
  computed: {
    ignoreFormErrors() {
      // return this.$ignoreErrors() || false;
      return false;
    },
    fieldClasses() {
      return [
        this.baseClassName,
        this.borderBottom ? "border-bottom" : "",
        this.disabled ? "is-disabled" : "",
        this.isView ? "is-view" : "",
      ];
    },
    translateLabel() {
      if (this.fieldTransPrefix) {
        return this.$t(`${this.fieldTransPrefix}field.${this.$attrs.name}.label`);
      }
      return this.$attrs.label;
    },
    translatePlaceholder() {
      if (this.fieldTransPrefix) {
        return this.$t(`${this.fieldTransPrefix}field.${this.$attrs.name}.placeholder`);
      }
      return this.$attrs.placeholder;
    },
  },
  methods: {
    emitNewValue(newValue) {
      if (this.delay) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.$emit("input", this.innerValue);
        }, this.delay);
      } else {
        this.$emit("input", newValue);
      }
    },
    setInnerValue(newValue) {
      this.innerValue = newValue;
    },
  }
};
