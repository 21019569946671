import moment from "moment";

export const vacationCalculationRows = ({vacationCalculations}) => vacationCalculations.map((item, id) => ({
  id: `calculation-row-${id}`,
  ...item,
}));

export const yearlyVacationCalendarRows = ({yearlyVacationCalendar}) => yearlyVacationCalendar.map((item, id) => ({
  id: `calendar-row-${id}`,
  ...item,
  date: moment(item.date).lang('lt').format("YYYY-MM-DD (dddd)"),
}));

export const vacations = ({vacations}) => vacations;
export const plusVacations = ({plusVacations}) => plusVacations;
export const child = ({child}) => child;
export const disabilities = ({disabilities}) => disabilities;
export const contracts = ({contracts}) => contracts;
export const contractDetails = ({contractDetails}) => contractDetails;