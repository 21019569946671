<template>
	<div class="avatar-cropper">
		<cropper
			class="avatar-cropper__root"
			ref="cropper"
			:src="image"
			image-restriction="stencil"
			stencil-component="circle-stencil"
			:stencil-size="{width: 312, height: 312}"
			:stencil-props="{
				lines: {},
        handlers: {},
				previewClass: 'avatar-cropper__preview',
				resizable: false,
        movable: false,
        scalable: false,
        aspectRatio: 1,
			}"
			:debounce="false"
			:transitions="false"
			:auto-zoom="true"
			@change="onChange"
		/>

		<div class="d-flex justify-center align-center gap-3 my-9">
			<hra-button only-icon no-background @click="onZoomOut">
				<hra-icon :name="iconName.minus" />
			</hra-button>
			<input class="avatar-cropper__range" type="range" min="0.01" max="0.9" step="0.001" :value="zoom" @change="onZoom"/>
			<hra-button only-icon no-background @click="onZoomIn">
				<hra-icon :name="iconName.plus" />
			</hra-button>
		</div>
	</div>
</template>

<script>
import {Cropper} from 'vue-advanced-cropper';
import HraIcon from "@/components/ui/HraIcon";
import HraButton from "@/components/ui/button/HraButton";

export default {
	name: "AvatarCropper",
	components: {HraButton, HraIcon, Cropper},
	props: {
		image: {required: true},
	},
	data() {
		return {
			zoom: 0,
		};
	},
	methods: {
		getResult() {
			return this.$refs.cropper.getResult();
		},
		getCanvas() {
			return this.$refs.cropper.getResult().canvas;
		},
		defaultVisibleArea() {
			return {
				width: 200,
				height: 200,
				left: 0,
				top: 0,
			};
		},
		defaultSize({imageSize}) {
			return {
				height: 412,
				// width: Math.min(imageSize.height, imageSize.width),
				// height: Math.min(imageSize.height, imageSize.width, 412),
			};
		},
		onChange(result) {
			const cropper = this.$refs.cropper;
			if (cropper) {
				const {coordinates, imageSize} = cropper;
				if (
					imageSize.width / imageSize.height >
					coordinates.width / coordinates.height
				) {
					// Determine the position of slider bullet
					// It's 0 if the stencil has the maximum size and it's 1 if the has the minimum size
					this.zoom =
						(cropper.imageSize.height - cropper.coordinates.height) /
						(cropper.imageSize.height - cropper.sizeRestrictions.minHeight);
				} else {
					this.zoom =
						(cropper.imageSize.width - cropper.coordinates.width) /
						(cropper.imageSize.width - cropper.sizeRestrictions.minWidth);
				}
			}
		},
		onZoom(event) {
			const {value} = event.target;
			this.imageZoom(value);
		},
		imageZoom(value) {
			const cropper = this.$refs.cropper;
			if (cropper) {
				if (cropper.imageSize.height < cropper.imageSize.width) {
					const minHeight = cropper.sizeRestrictions.minHeight;
					const imageHeight = cropper.imageSize.height;
					// Determine the current absolute zoom and the new absolute zoom
					// to calculate the sought relative zoom value
					cropper.zoom(
						(imageHeight - this.zoom * (imageHeight - minHeight)) /
						(imageHeight - value * (imageHeight - minHeight))
					);
				} else {
					const minWidth = cropper.sizeRestrictions.minWidth;
					const imageWidth = cropper.imageSize.width;
					cropper.zoom(
						(imageWidth - this.zoom * (imageWidth - minWidth)) /
						(imageWidth - value * (imageWidth - minWidth))
					);
				}

				this.zoom = value;
			}
		},
		onZoomOut() {
			if (this.zoom > 0) {
				this.imageZoom(this.zoom - 0.05);
			}
		},
		onZoomIn() {
			if (this.zoom < 0.9) {
				this.imageZoom(this.zoom + 0.05);
			}
		},
	},
}
</script>