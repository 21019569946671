import {EmployeeService} from "@/domain/employee/services/employeeService";
import store from "@/store";

class EmployeeProfileService extends EmployeeService {

  async init(tag) {
    return super.init(tag, 'profile');
  }

}

export default new EmployeeProfileService;