export const getDefaultState = () => ({
    isCreateMode: false,
    employee: {
        user: {
            name: '',
            email: '',
            timeCardNumber: '',
            departmentId: null,
            dutyId: null,
            permissions: [],
            roles: [
                'employee'
            ]
        },
        disabilities: [],
        children: [],
        contract: {
            number: '',
            date: null,
            workStartAt: null,
            workEndAt: null,
            workDaysPerWeek: 5,
            workHoursPerWeek: 40,
            vacationDaysPerYear: 20
        },
        contacts: {
            address: '',
            city: '',
            country: '',
            email: '',
            phone: ''
        }
    },
    deletedData: null,
    dismissed: false,
    dismisses: [],
    options: {},
    events: [],
    additionalHolidays: [],
    changelogs: [],
});

export default () => getDefaultState();