export default {
    computed: {
        companyDepartments() {
            return this.$store.getters['companyDepartmentsStore/departments']
        },
        companyDepartmentOptions() {
            return this.$store.getters['companyDepartmentsStore/departmentOptions']
        }
    },
    mounted() {
        this.$store.dispatch('companyDepartmentsStore/fetchDepartments')
    }
}