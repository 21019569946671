export const ADD_LOADER = "ADD_LOADER";
export const CLEAR_LOADER = "CLEAR_LOADER";

export default ({
  [ADD_LOADER]: (state, payload) => {
    if (state.stack.includes(payload)) {
      return;
    }

    state.stack.push(payload);
  },
  [CLEAR_LOADER]: (state, payload) => state.stack = state.stack.filter(key => key !== payload),
});