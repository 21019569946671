<template>
    <div
        class='item'
        v-bind='$attrs'
        v-on='$listeners'
    >
        <slot />
    </div>
</template>

<script>
export default {
    name: 'ContextMenuItem'
}
</script>