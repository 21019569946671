import AppLayout from '@/layouts/AppLayout.vue'
import ScheduleEditPage from '@/domain/scheduler/pages/ScheduleEditPage.vue'
import SchedulesListPage from '@/domain/scheduler/pages/SchedulesListPage.vue'

export default [
    {
        path: '/schedules',
        component: AppLayout,
        children: [
            { name: 'SchedulesListPage', path: '', component: SchedulesListPage },
            { name: 'ScheduleEditPage', path: ':id', component: ScheduleEditPage }
        ]
    }
]
