// import {
//     EVENT_CREATE,
//     DEPUTY_ABORT,
//     DEPUTY_APPROVE,
//     INIT_EVENTS,
//     INIT_WIDGETS, EVENT_UPDATE, EVENT_ABORT, SET_HOLIDAYS, SET_DEPUTIES, SET_WIDGETS, SET_EVENTS
// } from '@/domain/employeeVacations/store/mutations'
// import customApi from '@/plugins/customApi'
// import {VACATIONS_INIT_TAG} from "@/domain/employeeVacations/tags";
//
//
// export const fetchData = async ({ commit, rootGetters }, {tag }) => {
//     const api = customApi(tag)
//     const userId = rootGetters['currentUserStore/userId']
//     const [holidays, widgets] = await api.requests([
//         api.factories.employee.holidays.fetch(userId),
//         api.factories.employee.holidays.widgets.fetch(userId)
//     ])
//
//     commit(SET_EVENTS, holidays)
//     commit(SET_WIDGETS, widgets)
// }
//
// export const updateWidgets = async ({state, commit, rootGetters}) => {
//     if (state.widgets.length === 0) {
//         return
//     }
//
//     const api = customApi(null)
//     const userId = rootGetters['currentUserStore/userId']
//     const widgets = await api.request(api.factories.employee.holidays.widgets.fetch(userId))
//
//     commit(SET_WIDGETS, widgets)
// }
//
// export const updateEventsState = async ({ commit, rootGetters }) => {
//     const api = customApi(VACATIONS_INIT_TAG);
//     const userId = rootGetters['currentUserStore/userId']
//     const [holidays, widgets] = await api.requests([
//         api.factories.employee.holidays.fetch(userId),
//         api.factories.employee.holidays.widgets.fetch(userId)
//     ])
//
//     commit(SET_EVENTS, holidays)
//     commit(SET_WIDGETS, widgets)
// }
//
// export const eventCreate = ({commit}, payload) => {
//     commit(EVENT_CREATE, payload)
// }
//
// export const eventUpdate = ({commit}, payload) => {
//     commit(EVENT_UPDATE, payload)
// }
//
// export const eventChange = ({commit}, payload) => {
//     commit(EVENT_UPDATE, payload.parent)
//     commit(EVENT_CREATE, payload)
// }
//
// export const eventAbort = ({commit}, payload) => {
//     commit(EVENT_UPDATE, payload)
// }

import CustomApi from '@/plugins/customApi'
import Vue from 'vue'

const customApi = CustomApi()

export const setUserId = ({ state }, payload) => state.userId = payload

export const changeCurrentTab = ({ state }, payload) => state.currentTab = payload

export const fetchWidgets = async ({ state }) => {
    try {
        const { data } = await customApi.factories.employee.holidays.widgets.fetch(state.userId)
        Vue.set(state, 'widgets', data)
    } catch (e) {
        console.error(e)
    }
}

export const fetchCalendarEvents = async ({ state }) => {
    try {
        const { data } = await customApi.factories.employee.holidays.fetch(state.userId)
        Vue.set(state, 'calendarEvents', data)
    } catch (e) {
        console.error(e)
    }
}

export const fetchApprovalsPending = async ({ state }) => {
    try {
        const { data } = await customApi.factories.approvals.employees
            .fetch(state.userId, { status: ['pending'] })
        Vue.set(state, 'approvalsPending', data)
    } catch (e) {
        console.error(e)
    }
}

export const fetchApprovalsHistory = async ({ state }) => {
    try {
        const { data } = await customApi.factories.approvals.employees
            .fetch(state.userId, { status: ['pending', 'approved'] })
        Vue.set(state, 'approvalsHistory', data)
    } catch (e) {
        console.error(e)
    }
}

export const refreshData = ({state, dispatch}) => {
    if (state.userId == null) {
        return
    }

    dispatch('fetchWidgets')
    dispatch('fetchApprovalsPending')
    state.currentTab === 'calendar' && dispatch('fetchCalendarEvents')
    state.currentTab === 'history' && dispatch('fetchApprovalsHistory')
}