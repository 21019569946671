<template>
	<validation-observer ref="form" tag="form" @submit.prevent="onSubmit">
		<slot />
	</validation-observer>
</template>

<script>
import {ValidationObserver} from "vee-validate";

export default {
	name: "ValidationForm",
	components: {ValidationObserver},
	props: {
		tag: {type: String, default: null},
	},
	methods: {
		async validation() {
			this.tag && await this.$store.dispatch("messagesStore/clearMessage", this.tag);
			const validated = await this.$refs.form.validate();

			validated && this.$emit("submit");

			this.tag && !validated && await this.$store.dispatch("messagesStore/showErrorMessage", {
				tag: this.tag,
				message: this.$t("Supildykite raudonai pažymėtus laukus"),
			});
		},
		onSubmit() {
			this.validation();
		},
	},
}
</script>