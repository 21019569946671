<template>
	<v-app class="app" id="inspire">
		<router-view />
	</v-app>
</template>

<script>
import {
  setupCompanyDateChange,
  setupEnableTwoFactory,
  setupGetCompanyData,
  setupGetQrCode,
  setupRegister,
  setupTokenDecode
} from "@/services/setup/setupService";
import {mapGetters} from "vuex";

export default {
  name: "SetupLayout",
};
</script>
