<template>
  <v-dialog :value="true" persistent scrollable :max-width="maxWidth" transition="dialog-top-transition">
    <template v-if="isConfirm">
      <div class="dialog-confirm gap-5">
        <div v-if="$slots.title" class="dialog-confirm__header px-5">
          <h6>
            <slot name="title"/>
          </h6>
        </div>
        <div class="dialog-confirm__content gap-5">
          <slot name="content"/>
          <div class="d-flex gap-3" :class="maxWidth < 450 ? 'justify-center' : 'justify-end'">
            <slot name="buttons"/>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="dialog" :class="{'hide-footer': hideFooter}" ref="dialog">
        <div class="history-dialog__header px-5">
          <h6>
            <slot name="title"/>
          </h6>
          <v-spacer/>
          <i class="icon-close history-dialog__close" @click="close"/>
        </div>
        <div class="dialog__content" ref="content" :class="className">
          <slot name="content"/>
          <div v-if="$slots.footer" class="dialog__footer justify-end d-flex gap-3">
            <slot name="footer"/>
          </div>
        </div>
      </div>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: "HistoryDialog",
  props: {
    maxWidth: {type: [Number, String], default: 300},
    isConfirm: {type: Boolean, default: false},
    hasCustomFooter: {type: Boolean, default: false},
    className: {type: String, default: ''}
  },
  methods: {
    close() {
      this.$parent?.close();
    },
    checkContent() {
      if (this.$refs.content?.offsetHeight < this.$refs.content?.scrollHeight) {
        this.$refs.dialog?.querySelector(".dialog__footer")?.classList?.add("has-scrollbar");
      } else {
        this.$refs.dialog?.querySelector(".dialog__footer")?.classList?.remove("has-scrollbar");
      }
    },
    scrollToContentTop() {
      this.$refs.content?.scrollTo({top: 0, left: 0, behavior: "smooth",});
    },
    scrollToContentBottom() {
      this.$refs.content?.scrollTo({top: this.$refs.content?.scrollHeight || 0, left: 0, behavior: "smooth",});
    },
  },
  computed: {
    hideFooter() {
      return this.$slots.footer == null && this.hasCustomFooter === false;
    },
  },
  mounted() {
    if (this.$refs.content) {
      new ResizeObserver(() => {
        this.checkContent();
      }).observe(this.$refs.content)
    }
  },
}
</script>