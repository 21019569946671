<template>
	<div class="d-flex gap-3 align-center">
		<slot/>
		<template v-if="nowEdit">
			<hra-button no-background :size="buttonSize.auto" :color="uiColor.danger"
			            custom-class="pa-0" @click="onAbortField">
				<hra-icon :name="iconName.close" style="font-size: 12px;"/>
			</hra-button>
			<v-progress-circular v-if="loading" indeterminate color="blue" :size="14" width="2"/>
			<hra-button v-else no-background :size="buttonSize.auto" :color="uiColor.success"
			            custom-class="pa-0" @click="onSaveField">
				<hra-icon :name="iconName.ok" style="font-size: 12px;"/>
			</hra-button>
		</template>
		<template v-else>
			<hra-button no-background :size="buttonSize.auto" :color="uiColor.default"
			            custom-class="pa-0" @click="onEditField">
				<hra-icon :name="iconName.edit" style="font-size: 12px;"/>
			</hra-button>
		</template>
	</div>
</template>

<script>
import HraButton from "@/components/ui/button/HraButton";
import HraIcon from "@/components/ui/HraIcon";

export default {
	name: "HraFieldEditable",
	components: {HraIcon, HraButton},
	props: {
		nowEdit: {type: Boolean, default: false},
		loading: {type: Boolean, default: false},
	},
	methods: {
		onAbortField() {
			this.$emit("abort");
		},
		onSaveField() {
			this.$emit("save");
		},
		onEditField() {
			this.$emit("edit");
		},
	}
}
</script>
