import {getRequest, postRequest, deleteRequest, putRequest} from "@/utils/request";

const baseEndPoint = "/api/employees";

export default {
    department: {
        getAll: async (employeeId) => await getRequest(`${baseEndPoint}/${employeeId}/departments`),
        change: async (employeeId, payload) =>
            await postRequest(`${baseEndPoint}/${employeeId}/departments`, payload),
        remove: async (employeeId, departmentId) =>
            await deleteRequest(`${baseEndPoint}/${employeeId}/departments/${departmentId}`)
    },

    import: {
        exampleUrl: `${baseEndPoint}/import/example`,
        convert: async (payload) =>
            await postRequest(`${baseEndPoint}/import/convert`, payload, false, {
                "Content-Type": "multipart/form-data"
            }),
        validate: async (payload) => await postRequest(`${baseEndPoint}/import/validate`, payload),
        import: async (payload) => await postRequest(`${baseEndPoint}/import`, payload),
    },

    contract: {
        editField: async (employeeId, contractId, payload) =>
            await putRequest(`${baseEndPoint}/${employeeId}/contracts/${contractId}`, payload),

        details: {

        },
    },

    getAll: async (payload) => await getRequest(`${baseEndPoint}`, payload),
    getOne: async(id) => await getRequest(`${baseEndPoint}/${id}`),
    profile: async(id) => await getRequest(`${baseEndPoint}/${id}/profile`),
    editField: async (id, payload) => await putRequest(`${baseEndPoint}/${id}`, payload),
    autocomplete: async (payload) => await getRequest(`${baseEndPoint}/autocomplete`, payload),
    sentInvite: async (payload) => await postRequest(`${baseEndPoint}/invites`, payload),
}
