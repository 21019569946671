<template>
    <custom-dialog :max-width='1200'>
        <template #title>{{ $t('Dokumento peržiūra') }}</template>
        <template #content>
            <v-row class='pa-3'>
                <v-col cols='12'>
                    <document-sent-user-preview
                        :file-type='fileType'
                        :document-sent-id='item.id'
                    />
                </v-col>
            </v-row>
        </template>

        <template #footer>
            <ui-button
                prepend-icon='close'
                background='danger-light'
                @click='handleReject'
            >{{ $t('Atmesti') }}</ui-button>
            <ui-button
                prepend-icon='ok'
                background='success'
                @click='handleAccept'
            >{{ $t('Priimti') }}</ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import dialogMixin from '@/mixins/dialogMixin'
import UiButton from '@/domain/components/UiButton.vue'
import DocxPreview from '@/domain/documents/components/DocxPreview.vue'
import ExcelPreview from '@/domain/documents/components/ExcelPreview.vue'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import modalMethodsMixin from '@/domain/mixins/modalMethodsMixin'
import { GLOBAL_TOAST_TAG } from '@/utils/default'
import DocumentSentUserPreview from '@/domain/documents/components/DocumentSentUserPreview.vue'
import CustomApi from '@/plugins/customApi'

const customApi = CustomApi()
export default {
    name: 'DocumentSignModal',
    components: { DocumentSentUserPreview, UiButton, ExcelPreview, DocxPreview, CustomDialog },
    mixins: [dialogMixin, modalMethodsMixin],
    props: {
        item: Object
    },
    data() {
        return {
            loading: false
        }
    },
    computed: {
        fileType() {
            return this.item.document.filename.split('.').pop().toLowerCase()
        }
    },
    methods: {
        success(data) {
            this.$root.$emit('employee-document-sign', data)
            this.onCloseModal('DocumentSignCommentModal')
            this.close()
        },
        handleReject() {
            if (this.loading) {
                return
            }

            this.onOpenModal(
                'DocumentSignCommentModal',
                {
                    item: this.item,
                    action: async (comment) => {
                        this.loading = true
                        try {
                            const {data} = await customApi.factories.documents.sentUsers.sign
                                .rejectDocument(this.item.id, {comment})
                            this.showSuccessInRootToast(this.$t('Dokumentas atmestas'))
                            this.success(data)
                        } catch (error) {
                            this.showErrorInRootToast(error)
                        } finally {
                            this.loading = false
                        }
                    }
                }
            )
        },
        async handleAccept() {
            if (this.loading) {
                return
            }

            this.loading = true
            try {
                const {data} = await customApi.factories.documents.sentUsers.sign.acceptDocument(this.item.id)
                this.showSuccessInRootToast(this.$t('Dokumentas priimtas'))
                this.success(data)
            } catch (error) {
                this.showErrorInRootToast(error)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>