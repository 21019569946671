var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('hra-table',{attrs:{"headers":[{name: 'name'}],"rows":_vm.rows,"hide-headers":"","need-actions":"","expand-multiple-row":"","expand-row-ids":_vm.expandRowIds},on:{"update:expandRowIds":function($event){_vm.expandRowIds=$event},"update:expand-row-ids":function($event){_vm.expandRowIds=$event}},scopedSlots:_vm._u([{key:"col.name",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"px-3"},[_vm._v(_vm._s(row.name)+" "),(row.count != null)?_c('span',[_vm._v(_vm._s(row.count))]):_vm._e()])]}},{key:"col.actions-base",fn:function(ref){
var row = ref.row;
var expandRow = ref.expandRow;
return [_c('hra-tooltip',{attrs:{"text":_vm.$t(row.isExpand ? 'Suskleisti' : 'Išskleisti')}},[_c('hra-button',{attrs:{"size":_vm.buttonSize.small,"color":_vm.uiColor.default,"hover-background":!row.isExpand},on:{"click":function () { return expandRow(row.id); }}},[_c('v-fab-transition',[_c('div',{key:((row.isExpand ? 1 : 0) + "-expand")},[_c('hra-icon',{attrs:{"name":row.isExpand ? _vm.iconName.up : _vm.iconName.down}})],1)])],1)],1)]}},{key:"row-expansion",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"pa-3 overflow-x-auto"},[(row.id === 'contract')?_c('import-employee-contract',{attrs:{"id":_vm.id}}):_vm._e(),(row.id === 'contact')?_c('import-employee-contact',{attrs:{"id":_vm.id}}):_vm._e(),(row.id === 'disabilities')?_c('employee-disabilities-table'):_vm._e(),(row.id === 'child')?_c('employee-child-table'):_vm._e(),(row.id === 'vacations')?_c('vacations-table',{attrs:{"id":_vm.id}}):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }