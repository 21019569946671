<template>
    <div
        class='schedule-sidebar'
        ref='element'
    >
        <div class='schedule-sidebar__table'>
            <table class='schedule-table'>
                <thead>
                <slot name='header' />
                </thead>
                <tbody>
                <slot name='body' />
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import UiIcon from '@/domain/components/UiIcon.vue'

export default {
    name: 'ScheduleSidebar',
    components: { UiIcon },
    props: {
        minWidth: { type: Number, default: 10 },
        maxWidth: { type: Number, default: 1000 }
    },
    methods: {
        getIntStyle(key) {
            return parseInt(window.getComputedStyle(this.$refs.element).getPropertyValue(key))
        },
        resizeX(width) {
            if (width < this.minWidth) {
                width = this.minWidth
            }

            if (width > this.maxWidth) {
                width = this.maxWidth
            }

            this.$refs.element.style.minWidth = `${width}px`
            this.$refs.element.style.width = `${width}px`
        },
        resizeXNegative(event) {
            let offsetX
            let startX
            let startW

            const elementDrag = event => {
                const { clientX } = event
                this.resizeX(startW + startX - (clientX - offsetX))
            }

            const closeDragElement = () => {
                document.removeEventListener('mouseup', closeDragElement)
                document.removeEventListener('mousemove', elementDrag)
            }

            if (event.button !== 0) {
                return
            }
            event = event || window.event
            event.preventDefault()

            const { clientX } = event
            startX = this.$refs.element.offsetLeft
            startW = this.getIntStyle('width')
            offsetX = clientX - startX

            document.addEventListener('mouseup', closeDragElement)
            document.addEventListener('mousemove', elementDrag)
        },
        resizeXPositive(event) {
            let offsetX

            const elementDrag = (event) => {
                const { clientX } = event
                this.resizeX(clientX - this.$refs.element.offsetLeft - offsetX)
            }

            const closeDragElement = () => {
                document.removeEventListener('mouseup', closeDragElement)
                document.removeEventListener('mousemove', elementDrag)
            }

            if (event.button !== 0) {
                return
            }

            event = event || window.event
            event.preventDefault()

            const { clientX } = event
            offsetX = clientX - this.$refs.element.offsetLeft - this.getIntStyle('width')
            document.addEventListener('mouseup', closeDragElement)
            document.addEventListener('mousemove', elementDrag)
        }
    }
}
</script>

<style lang='scss' scoped>
.schedule-sidebar {
    display: flex;

    &__table {
        overflow: auto;
        width: 100%;
    }
}
</style>