import i18n from '@/plugins/i18n'
import Vue from 'vue'
import moment from 'moment'

const state = () => ({
    steps: [
        { key: 0, number: 1, title: i18n.t('Pridėti dokumentus') },
        { key: 1, number: 2, title: i18n.t('Pridėti gavėjus') },
        { key: 2, number: 3, title: i18n.t('Nustatyti terminus') }
    ],
    currentStepKey: 0,
    selectedDocuments: [],
    assignUsersToDocumentsRows: [],
    usersDocumentsFilter: {
        userId: { selected: [], sort: 'asc' },
        departmentId: { selected: [], sort: null },
        dutyId: { selected: [], sort: null },
        documentId: { selected: [], sort: null },
        status: { selected: ['new', 'rejected', 'cancelled'] }
    },
    sendOptions: {
        dueAt: null,
        useReminder: false,
        reminderBeforeDays: null,
        reminderAt: null,
        reminderEveryDay: false,
        needEmployeesConfirm: true
    }
})

const getters = {
    currentStepKey: state => state.currentStepKey,
    selectedDocumentLength: state => state.selectedDocuments.length,
    selectedAssignUsersToDocumentsRowsLength: state => state.assignUsersToDocumentsRows.filter(({selected}) => selected).length,
    selectedAssignUsersToDocumentsRowsAll: (state, getters) => {
        return state.assignUsersToDocumentsRows.length === getters.selectedAssignUsersToDocumentsRowsLength
    },
    completeStepKeys: (state, getters) => {
        const hasSelectedDocuments = getters.selectedDocumentLength > 0
        const hasAssignUsersToDocumentsRows = getters.selectedAssignUsersToDocumentsRowsLength > 0

        return [
            hasSelectedDocuments ? 0 : null,
            hasSelectedDocuments && hasAssignUsersToDocumentsRows ? 1 : null
        ].filter(stepKey => stepKey !== null)
    },
    availableStepKeys: (state, getters) => {
        const completeStepLength = getters.completeStepKeys.length
        const lastCompleteStepKey = completeStepLength === 0
            ? 0
            : getters.completeStepKeys[completeStepLength - 1] + 1

        return state.steps
            .filter(step => step.key <= lastCompleteStepKey)
            .map(step => step.key)
    },
    steps: (state, getters) => {
        return state.steps.map(step => ({
            ...step,
            current: step.key === state.currentStepKey,
            complete: getters.completeStepKeys.includes(step.key),
            available: getters.availableStepKeys.includes(step.key)
        }))
    },
    selectedDocuments: state => state.selectedDocuments,
    usersDocumentsFilter: state => state.usersDocumentsFilter,
    assignUsersToDocumentsRows: state => state.assignUsersToDocumentsRows,
    sendOptions: state => state.sendOptions
}

const actions = {
    setStep: ({ state }, stepKey) => {
        state.currentStepKey = stepKey
    },

    setDocuments: ({ state }, documents) => {
        const ignoreIds = state.selectedDocuments.map(document => document.id)

        state.selectedDocuments = [
            ...state.selectedDocuments,
            ...documents.filter(document => !ignoreIds.includes(document.id))
        ]
    },
    removeDocument: ({ state }, documentId) => {
        state.selectedDocuments = state.selectedDocuments.filter(document => document.id !== documentId)
    },

    setUsersDocuments: ({ state }, usersDocuments) => {
        state.selectedUsersDocuments = [
            ...state.selectedUsersDocuments,
            ...usersDocuments
        ]
    },
    setUsersDocumentsFilter: ({ state }, { column, filter }) => {
        if (column === 'userId') {
            Vue.set(state.usersDocumentsFilter, 'departmentId', { selected: [], sort: null })
            Vue.set(state.usersDocumentsFilter, 'dutyId', { selected: [], sort: null })
        }

        if (column === 'departmentId') {
            Vue.set(state.usersDocumentsFilter, 'userId', { selected: [], sort: null })
            Vue.set(state.usersDocumentsFilter, 'dutyId', { selected: [], sort: null })
        }

        if (column === 'dutyId') {
            Vue.set(state.usersDocumentsFilter, 'userId', { selected: [], sort: null })
            Vue.set(state.usersDocumentsFilter, 'departmentId', { selected: [], sort: null })
        }

        Vue.set(state.usersDocumentsFilter, column, filter)
    },
    clearAssignUsersToDocumentsRows: ({ state }) => {
        state.assignUsersToDocumentsRows = []
    },
    toggleSelectUserDocument: ({state}, {userId, documentId}) => {
        const index = state.assignUsersToDocumentsRows.findIndex(row => row.userId === userId && row.documentId === documentId)

        if (index === -1) {
            return
        }

        Vue.set(state.assignUsersToDocumentsRows[index], 'selected', !state.assignUsersToDocumentsRows[index].selected)
    },
    setSelectedAssignUsersToDocumentsRows: ({ state }, rows) => {
        state.assignUsersToDocumentsRows = rows.map(row => ({
            ...row,
            selected: true
        }))
    },
    setAssignUsersToDocumentsRows: ({ state }, { rows, nextPage }) => {
        rows = rows.map(row => ({
            ...row,
            selected: false
        }))

        state.assignUsersToDocumentsRows = nextPage
            ? [...state.assignUsersToDocumentsRows, ...rows]
            : rows
    },
    toggleSelectAllAssignUsersToDocumentsRows: ({ state, getters }) => {
        const selected = !getters.selectedAssignUsersToDocumentsRowsAll

        state.assignUsersToDocumentsRows = state.assignUsersToDocumentsRows.map(row => ({
            ...row,
            selected
        }))
    },


    ['sendOptions/setDueAt']: ({state}, payload) => {
        Vue.set(state.sendOptions, 'dueAt', payload)
    },
    ['sendOptions/setUseReminder']: ({state}, payload) => {
        Vue.set(state.sendOptions, 'useReminder', payload)
        if (payload) {
            Vue.set(state.sendOptions, 'reminderBeforeDays', 0)
            Vue.set(state.sendOptions, 'reminderAt',
                moment(state.sendOptions.dueAt).subtract(0, 'day').format('YYYY-MM-DD'))
            Vue.set(state.sendOptions, 'reminderEveryDay', false)
        } else {
            Vue.set(state.sendOptions, 'reminderBeforeDays', null)
            Vue.set(state.sendOptions, 'reminderAt', null)
            Vue.set(state.sendOptions, 'reminderEveryDay', false)
        }
    },
    ['sendOptions/setReminderBeforeDays']: ({state}, payload) => {
        Vue.set(state.sendOptions, 'reminderBeforeDays', payload)
    },
    ['sendOptions/setReminderAt']: ({state}, payload) => {
        Vue.set(state.sendOptions, 'reminderAt', payload)
    },
    ['sendOptions/setReminderEveryDay']: ({state}, payload) => {
        Vue.set(state.sendOptions, 'reminderEveryDay', payload)
    },
    ['sendOptions/setNeedEmployeesConfirm']: ({state}, payload) => {
        Vue.set(state.sendOptions, 'needEmployeesConfirm', payload)
        if (!payload) {
            Vue.set(state.sendOptions, 'dueAt', null)
            Vue.set(state.sendOptions, 'useReminder', false)
            Vue.set(state.sendOptions, 'reminderBeforeDays', null)
            Vue.set(state.sendOptions, 'reminderAt', null)
            Vue.set(state.sendOptions, 'reminderEveryDay', false)
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions
}