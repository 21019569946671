var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('hra-employee-crud-table',{scopedSlots:_vm._u([(!_vm.dismissed)?{key:"buttons",fn:function(){return [(!_vm.readOnly)?_c('ui-button',{attrs:{"size":"small","background":"primary","modal":{ name: "VacationModal", props: {asAdmin: !_vm.isProfile, userId: _vm.userId}}}},[_vm._v(" "+_vm._s(_vm.$t('Pridėti'))+" ")]):_vm._e()]},proxy:true}:null],null,true)},[_c('hra-table',{staticClass:"no-border-around",attrs:{"headers":_vm.headers,"rows":_vm.events,"need-actions":!_vm.dismissed,"hide-headers":""},scopedSlots:_vm._u([{key:"header.custom",fn:function(){return [_c('table-title-view',{staticClass:"mb-3 size-1",attrs:{"title":_vm.$t('Darbuotojo atostogų prašymai'),"with-border":""}})]},proxy:true},{key:"col.typeName",fn:function(ref){
var colValue = ref.colValue;
return [_c('div',{staticClass:"pe-3 py-6"},[_vm._v(_vm._s(colValue))])]}},{key:"col.period",fn:function(ref){
var ref_row = ref.row;
var start = ref_row.start;
var end = ref_row.end;
return [_c('div',{staticClass:"px-3 py-6"},[_c('vacation-period-view',{attrs:{"from-date":start,"to-date":end}})],1)]}},{key:"col.days",fn:function(ref){
var ref_row = ref.row;
var workDays = ref_row.workDays;
var calendarDays = ref_row.calendarDays;
return [_c('div',{staticClass:"px-3 py-6"},[_vm._v(" "+_vm._s(workDays)+" "+_vm._s(_vm.$t('d. d.'))+", "+_vm._s(calendarDays)+" "+_vm._s(_vm.$t('k. d.'))+" ")])]}},{key:"col.statusName",fn:function(ref){
var colValue = ref.colValue;
return [_c('div',{staticClass:"px-3 py-6"},[_vm._v(_vm._s(colValue))])]}},{key:"col.actions-base",fn:function(ref){
var row = ref.row;
return [(_vm.isProfile)?void 0:[(row.currentStatus !== "aborted")?_c('hra-tooltip',{attrs:{"text":_vm.$t('Redaguoti')}},[_c('ui-button',{attrs:{"size":"small","background":"primary-light","prepend-icon":"edit","modal":{ name: "VacationModal", props: {asAdmin: true, userId: row.userId, eventId: row.id}}}})],1):_vm._e(),(row.currentStatus !== "aborted")?_c('hra-tooltip',{attrs:{"text":_vm.$t('Atmesti')}},[_c('base-confirm',{attrs:{"tag":"EVENT_ABORT_TAG","title":_vm.$t('Ar tikrai norite atmesti atostogų prašymą?')},on:{"confirm":function($event){return _vm.onAbort($event, row.id)}}},[_c('ui-button',{attrs:{"size":"small","background":"danger-light","prepend-icon":"close"}})],1)],1):_vm._e(),(row.currentStatus === "pending")?_c('hra-tooltip',{attrs:{"text":_vm.$t('Tvirtinti')}},[_c('base-confirm',{attrs:{"tag":"EVENT_APPROVE_TAG","title":_vm.$t('Ar tikrai norite patvirtinti atostogų prašymą?')},on:{"confirm":function($event){return _vm.onAccept($event, row.id)}}},[_c('ui-button',{attrs:{"size":"small","background":"success-light","prepend-icon":"ok"}})],1)],1):_vm._e(),_c('ui-button',{attrs:{"size":"small","modal":{ name: "VacationModal", props: {asAdmin: true, userId: row.userId, eventId: row.id}}}},[_vm._v(" "+_vm._s(_vm.$t('Plačiau'))+" ")])]]}},{key:"empty.text",fn:function(){return [_vm._v(_vm._s(_vm.$t('Įrašų sistemoje pridėta nebuvo.')))]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }