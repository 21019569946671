<template>
    <ui-table-head-filter
        :title='title'
        :column='column'
        :sort-options='sortOptions'
        :select-options='documentStatusOptions'
        :min-wdith='minWidth'
        v-model='currentFilter'
        select-option-searchable
        @changeFilter='handleChangeFilter'
    />
</template>

<script>
import UiTableHeadFilter from '@/domain/components/uiTable/UiTableHeadFilter.vue'
import i18n from '@/plugins/i18n'
import CustomApi from '@/plugins/customApi'

const customApi = CustomApi()
export default {
    name: 'TableHeaderDocumentFilter',
    components: { UiTableHeadFilter },
    props: {
        title: {type: String, default: () => i18n.t("Dokumentas") },
        column: {type: String, default: 'document' },
        filter: {type: Object, default: () => ({selected: [], sort: null}) },
        minWidth: {type: [String,Number], default: 150}
    },
    data() {
        return {
            documentStatusOptions: [],
            sortOptions: [
                { value: 'asc', label: 'A-Z' },
                { value: 'desc', label: 'Z-A' }
            ],
            currentFilter: {
                selected: this.filter.selected,
                sort: this.filter.sort
            }
        }
    },
    watch: {
        filter: {
            handler(filter) {
                this.currentFilter = {
                    selected: filter.selected,
                    sort: filter.sort
                }
            },
            deep: true
        }
    },
    created() {
        this.fetchDocuments()
    },
    methods: {
        fetchDocuments() {
            customApi.factories.documents.documents.fetchOptions()
                .then(({ data }) => {
                    this.documentStatusOptions = data.map(document => ({
                        value: document.id,
                        label: document.title
                    }))
                })
        },
        handleChangeFilter(column, filter) {
            this.$emit('changeFilter', column, filter)
        }
    }
}
</script>