<template>
	<thead class="table__header">
	<tr>
		<th v-for="header in headers">{{ header.text }}</th>
	</tr>
	<tr v-if="loading">
		<td :colSpan="colLength">
			<div class="table__loader p-2">
				<v-progress-circular indeterminate size="32" width="2" color="primary"/>
			</div>
		</td>
	</tr>
	</thead>
</template>

<script>
export default {
	name: "HraTableHeader",
	props: {
		store: {
			type: String,
			required: true,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	created() {
	},
	beforeDestroy() {
		this.$store.commit(`${this.store}/clear`);
	},
	computed: {
		headers() {
			return this.$store.getters[`${this.store}/headers`];
		},
		rows() {
			return this.$store.getters[`${this.store}/rows`];
		},
		colLength() {
			return 3;
		},
	},

}
</script>
