<template>
    <div class='setting-module-item-config'>
        <div class='setting-module-item-config__title'>
            <slot name='header' />
        </div>
        <div class='setting-module-item-config__container'>
            <slot />
        </div>
    </div>
</template>

<script>
import TextField from '@/domain/fields/TextField.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import UiButton from '@/domain/components/UiButton.vue'

export default {
    name: 'SettingModuleItemConfig',
    components: { UiButton, FormCheckbox, TextField }
}
</script>

<style lang='scss'>
.setting-module-item-config {
    background: #fcfbfe;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid #e7e9f0;

    &__title {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
    }
}
</style>