import {ValidationProvider} from "vee-validate";

export default {
    components: {ValidationProvider},
    props: {
        name: {type: String, required: true},
        rules: {type: [Object, String], default: ""},
        value: {type: [Number, String, Array], default: null},
        disabled: {type: Boolean, default: false},
        isView: {type: Boolean, default: false},
        label: {type: [Number, String], default: ""},
        placeholder: {type: String, default: ""},
        viewValid: {type: Boolean, default: true},
        editable: {type: Boolean, default: false},
        beforeSave: {type: Function, default: () => true},
        fullWidth: {type: Boolean, default: false},
        saveLoaderName: {type: String, default: "init"},
    },
    data: () => ({
        innerValue: "",
        nowEdit: false,
    }),
    created() {
        this.loaderName = this.saveLoaderName;
    },
    computed: {
        fieldName() {
            return "field_" + this.name;
        },
        translateLabel() {
            return this.label;
        },
        translatePlaceholder() {
            return this.placeholder;
        },
        viewValue() {
            return this.innerValue;
        },
        isDisabled() {
            if (this.editable) {
                return !this.nowEdit;
            }

            return this.disabled;
        }
    },
    watch: {
        innerValue: {
            handler(newValue, oldValue) {
                const value = this.handleChangeInnerValue(newValue, oldValue);
                if (value !== undefined && !this.nowEdit) {
                    this.$emit("input", value);
                }
            },
        },
        value: {
            handler(newValue, oldValue) {
                const value = this.handleChangeValue(newValue, oldValue);
                if (value !== undefined) {
                    this.innerValue = value;
                }
            },
            immediate: true,
        }
    },
    methods: {
        handleChangeInnerValue(newValue, oldValue) {
            if (newValue !== oldValue && newValue !== this.value) {
                return newValue;
            }
        },
        handleChangeValue(newValue, oldValue) {
            if (newValue !== oldValue && newValue !== this.innerValue) {
                return newValue;
            }
        },
        onEditField() {
            if (this.editable && !this.nowEdit) {
                this.nowEdit = true;
            }
        },
        async onSaveField() {
            if (await this.beforeSave(this.name, this.innerValue)) {
                this.nowEdit = false;
                this.$emit("input", this.innerValue);
            }
        },
        onAbortField() {
            this.nowEdit = false;
            this.innerValue = this.value;
        },
    },
}
