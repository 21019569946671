<template>
	<Auth>
		<template #header>{{ $t("Susikurkite slaptažodį") }}</template>
		<template #form>
			<validation-form v-if="payload" :tag="tag" @submit="onSubmit">
				<v-row>
					<v-col cols="12">
						<text-field
							rules=""
							v-model="payload.email"
							name="email"
							type="email"
							:label="$t('El. pašto adresas')"
							disabled
						/>
					</v-col>
					<v-col cols="12">
						<text-field
							:rules="'required|min:8,' + $t('simbolius')"
							v-model="password"
							name="password"
							type="password"
							:label="$t('Slaptažodis')"
						/>
					</v-col>
					<v-col cols="12">
						<text-field
							rules="required|confirmed:password,"
							v-model="passwordConfirmation"
							name="passwordConfirmation"
							type="password"
							:label="$t('Pakartokite slaptažodį')"
						>
							<template #confirmed-error-message>{{ $t('Nesutampa slaptažodžiai') }}</template>
						</text-field>
					</v-col>
					<v-col cols="12">
						<form-checkbox
							rules="required"
							:is-checked.sync="policeAccept"
							name="policeAccept"
						>
							<div class="ms-3">
								<a href="">{{ $t("Sutinku su Privatumo politka") }}</a>
								{{ $t("ir") }}
								<a href="">{{ $t("naudojimosi taisyklėmis") }}</a>
							</div>
						</form-checkbox>
					</v-col>
					<v-col cols="12">
						<message-alerts :listen="[tag]"/>
					</v-col>
					<v-col cols="12">
						<hra-button
							:loading="buttonLoading"
							:color="uiColor.primary"
							:type="buttonType.submit"
							full-width
							class="mt-3"
						>{{ $t("Registruotis") }}
						</hra-button>
					</v-col>
				</v-row>
			</validation-form>
			<message-alerts :listen="[initTag]" />
		</template>
		<template #after-card>
			<hra-button-link
				full-width no-background
				:text-width="buttonTextWidth.normal"
				:to="{ name: loginName }"
			>{{ $t('Grįžti į prisijungimą') }}
			</hra-button-link>
		</template>
	</Auth>
</template>

<script>
import {ValidationObserver} from "vee-validate";
import authMixin from "@/domain/auth/mixins/authMixin";
import Auth from "@/domain/auth/components/Auth";
import TextField from "@/domain/fields/TextField";
import HraButton from "@/components/ui/button/HraButton";
import {login as loginName} from "@/domain/auth/routes"
import HraButtonLink from "@/components/ui/button/HraButtonLink";
import FormCheckbox from "@/components/ui/form/FormCheckbox";
import ValidationForm from "@/domain/components/ValidationForm";
import HraAlert from "@/components/ui/HraAlert";
import HraIcon from "@/components/ui/HraIcon";
import MessageAlerts from "@/domain/messages/MessageAlerts";

export default {
	name: "RegisterPage",
	components: {
		MessageAlerts,
		HraIcon,
		HraAlert, ValidationForm, FormCheckbox, ValidationObserver, HraButtonLink, HraButton, TextField, Auth},
	mixins: [authMixin],
	data() {
		return {
			loginName,
			initTag: "AUTH_INIT_REGISTER_TAG",
			tag: "AUTH_REGISTER_TAG",
			payload: null,
			invalidToken: false,
			password: "",
			passwordConfirmation: "",
			policeAccept: false,
		};
	},
	async created() {
		const payload = await this.service.getInvitePayload(this.initTag, this.$route.params.token);
		if (payload != null) {
			this.payload = {
				email: payload?.user?.email,
				name: payload?.profile?.name,
			}
		}
	},
	methods: {
		onSubmit() {
			this.service.register(this.tag, {
				token: this.$route.params.token,
				password: this.password,
				password_confirmation: this.passwordConfirmation,
				police_accept: this.policeAccept,
			});
		},
	}
}
</script>