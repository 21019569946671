<template>
    <custom-dialog :max-width='1200'>
        <template #title>{{ $t('Dokumento darbuotojų suvestinė') }}</template>
        <template #content>
            <v-row class='pa-3'>
                <v-col cols='12'>
                    <v-card outlined class='px-4'>
                        <v-list>
                            <v-list-item>
                                <!--                                <v-list-item-icon>-->
                                <!--                                    <ui-icon-->
                                <!--                                        class='document-history-table-eye-icon'-->
                                <!--                                    >eye</ui-icon>-->
                                <!--                                </v-list-item-icon>-->
                                <v-list-item-content>
                                    <v-list-item-subtitle>{{ $t('Dokumentas') }}:</v-list-item-subtitle>
                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <div class='d-flex gap-1'>
                                        <v-sheet class='pa-2' outlined rounded>
                                            <v-badge :content='item.sentToUsersCount + ""' bordered overlap inline>
                                                {{ $t('Išsiųsta') }}
                                            </v-badge>
                                        </v-sheet>
                                        <v-sheet class='pa-2' outlined rounded>
                                            <v-badge :content='item.sentToUsersNewCount + ""' bordered overlap inline>
                                                {{ $t('Nepradėta') }}
                                            </v-badge>
                                        </v-sheet>
                                        <v-sheet class='pa-2' outlined rounded>
                                            <v-badge :content='item.sentToUsersPendingCount + ""' bordered overlap
                                                     inline>{{ $t('Laukiama') }}
                                            </v-badge>
                                        </v-sheet>
                                        <v-sheet class='pa-2' outlined rounded>
                                            <v-badge :content='item.sentToUsersAcceptedCount + ""' bordered overlap
                                                     inline>{{ $t('Patvirtinta') }}
                                            </v-badge>
                                        </v-sheet>
                                        <v-sheet class='pa-2' outlined rounded>
                                            <v-badge :content='item.sentToUsersRejectedCount + ""' bordered overlap
                                                     inline>{{ $t('Atmesta') }}
                                            </v-badge>
                                        </v-sheet>
                                        <v-sheet class='pa-2' outlined rounded>
                                            <v-badge :content='item.sentToUsersAbortedCount + ""' bordered overlap
                                                     inline>{{ $t('Atšaukta') }}
                                            </v-badge>
                                        </v-sheet>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-col>
                <v-col cols='12'>
                    <ui-table
                        header-sticky
                        :visible-load-more='hasMore'
                        :loading='loading'
                        :loading-more='loadingMore'
                        @clickLoadMore='handleLoadMore'
                    >
                        <template #head>
                            <tr>
                                <table-header-user-filter
                                    column='user'
                                    :filter='filter.user'
                                    @changeFilter='handleChangeFilter'
                                />
                                <table-header-department-filter
                                    column='department'
                                    :filter='filter.department'
                                    @changeFilter='handleChangeFilter'
                                />
                                <table-header-duty-filter
                                    column='duty'
                                    :filter='filter.duty'
                                    @changeFilter='handleChangeFilter'
                                />
                                <table-header-date-filter
                                    interval
                                    :title='$t("Dokumentas išsiųstas")'
                                    column='sentAt'
                                    :filter='filter.sentAt'
                                    @changeFilter='handleChangeFilter'
                                />
                                <table-header-document-sent-status-filter
                                    :filter='filter.status'
                                    @changeFilter='handleChangeFilter'
                                />
                            </tr>
                        </template>

                        <template #body>
                            <tr v-for='(row, key) in rows' :key='key' @click='() => handleClickRow(row)'
                                style='cursor: pointer;'>
                                <td>{{ row.user.name }}</td>
                                <td style='width: 100px;'>{{ row.user.departmentName }}</td>
                                <td style='width: 100px;'>{{ row.user.dutyName }}</td>
                                <td>{{ row.document.sentAt }}</td>
                                <td>
                                    <document-sent-status-with-comment
                                        :status='row.document.status'
                                        :date='row.document.statusAt'
                                        :comment='row.document.comment'
                                    />
                                </td>
                            </tr>
                        </template>
                    </ui-table>
                </v-col>
            </v-row>
        </template>
        <template #footer>
            <ui-button
                prepend-icon='close'
                @click='close'
            >{{ $t('Uždaryti') }}
            </ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import UiButton from '@/domain/components/UiButton.vue'
import dialogMixin from '@/mixins/dialogMixin'
import UiTable from '@/domain/components/uiTable/UiTable.vue'
import TableHeaderDateFilter from '@/domain/components/uiTable/filters/TableHeaderDateFilter.vue'
import UiTableHeadFilter from '@/domain/components/uiTable/UiTableHeadFilter.vue'
import tableFilterMixin from '@/domain/components/uiTable/mixins/tableFilterMixin'
import CustomApi from '@/plugins/customApi'
import modalMethodsMixin from '@/domain/mixins/modalMethodsMixin'
import UiIcon from '@/domain/components/UiIcon.vue'
import TableHeaderDocumentSentStatusFilter from '@/domain/components/uiTable/filters/TableHeaderDocumentSentStatusFilter.vue'
import TableHeaderDepartmentFilter from '@/domain/components/uiTable/filters/TableHeaderDepartmentFilter.vue'
import TableHeaderDutyFilter from '@/domain/components/uiTable/filters/TableHeaderDutyFilter.vue'
import TableHeaderUserFilter from '@/domain/components/uiTable/filters/TableHeaderUserFilter.vue'
import DocumentSentStatusWithComment from '@/domain/documents/components/DocumentSentStatusWithComment.vue'

const customApi = CustomApi()
export default {
    name: 'UsersSummaryByDocumentModal',
    components: {
        DocumentSentStatusWithComment,
        TableHeaderUserFilter,
        TableHeaderDutyFilter,
        TableHeaderDepartmentFilter, TableHeaderDocumentSentStatusFilter, UiIcon, UiTableHeadFilter, TableHeaderDateFilter, UiTable, UiButton, CustomDialog },
    mixins: [dialogMixin, tableFilterMixin, modalMethodsMixin],
    props: {
        item: { type: Object, required: true }
    },
    data() {
        return {
            loading: false,
            loadingMore: false,
            hasMore: false,
            currentPage: 1,
            rows: [],
            filterSettings: {
                sortOnlyOne: true,
                resetSelected: {
                    'user': ['department', 'duty'],
                    'department': ['user', 'duty'],
                    'duty': ['user', 'department']
                }
            },
            filter: {
                user: { sort: null, selected: [] },
                department: { sort: null, selected: [] },
                duty: { sort: null, selected: [] },
                sentAt: { sort: null },
                status: { selected: [] }
            }
        }
    },
    created() {
        this.loadData()
    },
    methods: {
        handleClickRow(row) {
            this.onOpenModal('HistoryOfDocumentSentUserModal', {
                documentSentUserId: row.documentSentUserId,
                documentTitle: row.document.title,
                userFullName: row.user.name
            })
        },
        handleChangeFilter(column, filter) {
            this.setFilter(column, filter)
            this.loadData()
        },
        getRequestParams(page = 1) {
            return {
                page,
                limit: 50,
                document: [this.item.id],
                ...this.getFilterParams()
            }
        },
        async loadData() {
            this.loading = true
            try {
                await this.fetch(this.getRequestParams(1))
            } catch (error) {

            } finally {
                this.loading = false
            }
        },
        async handleLoadMore() {
            this.loadingMore = true
            try {
                await this.fetch(this.getRequestParams(this.currentPage + 1))
            } catch (error) {

            } finally {
                this.loadingMore = false
            }
        },
        async fetch(params) {
            const {
                data: {
                    items,
                    hasMore,
                    pagination: { currentPage }
                }
            } = await customApi.factories.documents.summary.fetch(params)

            this.rows = currentPage === 1 ? items : [...this.rows, ...items]
            this.currentPage = currentPage
            this.hasMore = hasMore
        }
    }
}
</script>