import customApi from "@/plugins/customApi";

export const checkDays = ({commit}, {type, fromDate, toDate, employeeId2}) => {
    const api = customApi('test');

    // api.request(api.factories.events.checkDays(type, fromDate, toDate))
    //     .then(response => {
    //         console.debug(response);
    //     });

    api.request(api.factories.events.balance(type, fromDate, employeeId2))
        .then(response => {
            console.debug(response);
        });
}