<template>
  <div class="header__icon m-r-2">
    <i :class="`icon-${icon}`" />
    <span v-if="count" :class="`header__icon-bandate header__icon-bandate_${bandage}`">{{ count }}</span>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    count: {
      type: String,
      default: "",
    },
    bandage: {
      type: String,
      default: "danger"
    }
  },
};
</script>