<template>
	<custom-dialog :max-width="400" ref="dialog">
		<template #title>{{ t("title") }}</template>
		<template #content>
			<v-row>
				<v-col cols="12">
					<elvis-form ref="form" form-name="CreateDutyForm">
						<elvis-input name="name" rules="required|min:3" />
					</elvis-form>
				</v-col>
			</v-row>
		</template>
		<template #footer>
			<hra-button :size="buttonSize.small" :color="uiColor.default" @click="close" :disabled="loading"
			>{{ $t('Uždaryti') }}</hra-button>
			<hra-button :size="buttonSize.small" :color="uiColor.primary" @click="onSave" :loading="loading"
			>{{ t("button.create") }}</hra-button>
		</template>
	</custom-dialog>
</template>

<script>
import CustomDialog from "@/components/ui/dialog/CustomDialog";
import {mapGetters, mapMutations} from "vuex";
import EmployeeTableView from "@/components/ui/views/EmployeeTableView";
import SelectField from "@/domain/fields/SelectField";
import HraIcon from "@/components/ui/HraIcon";
import dialogMixin from "@/mixins/dialogMixin";
import HraDialogConfirm from "@/components/ui/dialog/HraDialogConfirm";
import DatepickerField from "@/domain/fields/DatepickerField";
import HraConfirm from "@/components/ui/dialog/HraConfirm";
import Button from "@/components/ui/button/Button";
import FormFileButton from "@/components/ui/form/FormFileButton";
import FormAlert from "@/components/ui/form/FormAlert";
import {importRouteProps} from "@/router/import";
import HraButton from "@/components/ui/button/HraButton";
import HraButtonLink from "@/components/ui/button/HraButtonLink";
import employeeImportService from "@/services/employees/employeeImportService";
import FormCheckbox from "@/components/ui/form/FormCheckbox";
import ElvisInput from "@/components/ui/form/ElvisInput";
import ElvisForm from "@/components/ui/form/ElvisForm";

export default {
	name: "CreateDutyModal",
	mixins: [dialogMixin],
	components: {
		ElvisForm,
		ElvisInput,
		FormCheckbox,
		FormAlert,
		HraConfirm,
		DatepickerField,
		HraDialogConfirm, HraIcon, HraButton, SelectField, EmployeeTableView, CustomDialog,
		HraButtonLink, FormFileButton, Button
	},
	data() {
		return {
			loading: false,
			translatePrefix: "modals.CreateDutyModal.",
			formData: {
				name: "",
			},
		};
	},
	provide() {
		return {
			formData: this.formData,
		};
	},
	methods: {
		async onSave() {
			if (!await this.$refs.form.isValid()) {
				return;
			}

			this.loading = true;

			if (this.beforeSave(this.formData.name)) {
				this.close();
			}

			this.loading = false;
		},
	},
};
</script>
