<template>
    <div>{{ duties.join(", ") }}</div>
</template>

<script>
export default {
    props: {
        duties: {
            type: Array,
            required: true
        }
    }
}
</script>
