import moment from 'moment/moment'
import {GLOBAL_TOAST_TAG} from "@/utils/default";

export default {
    data() {
        return {
            formData: {
                start: '00:00',
                end: '01:00',
                minUsers: 1
            }
        }
    },
    computed: {
        duration() {
            let today = moment().set('second', 0)
            let start = today.clone().add(moment.duration(this.formData.start))
            let end = today.clone().add(moment.duration(this.formData.end))

            if (start > end) {
                end.add(1, 'day')
            }

            const duration = Math.abs(end.diff(start, 'minutes'))
            return `${String(Math.floor(duration / 60)).padStart(2, '0')}h ${String(duration % 60).padStart(2, '0')}min`
        },
        startTime: {
            get: function() {
                return this.formData.start;
            },
            set: async function(newValue) {
                this.formData.start = newValue
            }
        },
        endTime: {
            get: function() {
                return this.formData.end;
            },
            set: async function(newValue) {
                this.formData.end = newValue
            }
        }
    },
    methods: {
        async throwErrorNotification() {
            await this.$store.dispatch('messagesStore/showErrorMessage', {
                tag: GLOBAL_TOAST_TAG,
                message: this.$t('Identical time')
            })
        }
    }
}