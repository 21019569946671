var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{ref:"table",class:['hra-table', _vm.$attrs.class],style:(_vm.$attrs.style)},[_c('thead',{class:['hra-table__header', _vm.headerSticky ? 'sticky' : '']},[_c('tr',[_c('th',{attrs:{"colspan":_vm.colCount}},[_vm._t("header.custom")],2)]),(!_vm.hideHeaders && !_vm.isEmpty)?_c('tr',{class:_vm.headerClasses},[(_vm.isInlineEdit)?_c('th',{style:({minWidth: '12px'})}):_vm._e(),_vm._l((_vm.generateHeaders),function(header){return _c('th',{key:header.name,class:header.classes || [],style:(header.style || {})},[(header.name === 'checkbox')?_c('div',{staticClass:"mx-3"},[_c('form-checkbox',{attrs:{"is-checked":_vm.hasCheckedAllRows},on:{"change":_vm.onChangeCheckAllRows}})],1):_vm._t(("header." + (header.name)),function(){return [_c('div',{staticClass:"hra-table__header-col pa-3"},[_vm._v(_vm._s(header.text))])]},{"header":header})],2)})],2):_vm._e()]),_c('tbody',{staticClass:"hra-table__body"},[(_vm.inlineRowIsEdit)?_c('tr',[_c('td',{staticClass:"hra-table__editable",attrs:{"colspan":_vm.colCount}})]):_vm._e(),_vm._l((_vm.lastRowCount),function(key){return (_vm.loading)?_c('tr',{key:key + (new Date).getTime(),staticClass:"hra-table__row"},[_c('td',{staticClass:"hra-table__body-col",attrs:{"colspan":_vm.colCount}},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"width":"100%","type":"list-item"}})],1)]):_vm._e()}),(!_vm.loading && _vm.isEmpty)?_c('tr',{staticClass:"hra-table__row"},[_c('td',{attrs:{"colspan":_vm.colCount}},[_vm._t("empty",function(){return [_c('hra-table-default-empty',[_vm._t("empty.text",function(){return [_vm._v(_vm._s(_vm.$t("Įrašų sistemoje pridėta nebuvo.")))]})],2)]})],2)]):_vm._e(),_vm._l((_vm.generateRows),function(ref){
var row = ref.row;
var cols = ref.cols;
return (!_vm.loading)?[(_vm.isInlineEdit)?_vm._t("row.inline.edit",null,{"row":row,"cols":cols,"attrs":{actionsSticky: _vm.actionsSticky},"on":{changeEditRowId: _vm.onChangeEditRowId}}):_c('tr',{key:row.id,class:{
				'hra-table__row': true,
				'hra-table__row-expand': row.isExpand,
				'hra-table__row-selected': row.isSelected,
				'hra-table__row-invalid': row.invalid,
			}},_vm._l((cols),function(ref){
			var value = ref.value;
			var name = ref.name;
return _c('td',{key:row.id+name,class:['hra-table__body-col', name === 'actions' && _vm.actionsSticky && 'sticky-col']},[(name === 'checkbox')?_c('div',{staticClass:"mx-3"},[(!_vm.rowsCantCheck.includes(row.id))?_vm._t("col.checkbox",function(){return [_c('form-checkbox',{attrs:{"is-checked":_vm.checkedRowIds.includes(row.id)},on:{"change":function($event){return _vm.onCheckRow(row.id, $event)}}})]},{"row":row}):_vm._e()],2):(name === 'actions')?_c('div',{class:['d-flex', 'pa-3', 'ps-1', 'pa-sm-3', 'gap-3', 'justify-end']},[_vm._t("col.actions-base",null,{"colName":name,"colValue":value,"row":row,"expandRow":_vm.onExpandRow})],2):_vm._t(("col." + name),function(){return [_c('div',{staticClass:"pa-3"},[_vm._v(_vm._s(value))])]},{"colName":name,"colValue":value,"row":row,"expandRow":_vm.onExpandRow})],2)}),0),(row.isExpand)?_c('tr',{key:((row.id) + "-expansion")},[_c('td',{attrs:{"colspan":_vm.colCount}},[_c('div',{class:{'hra-table__row-expansion-body': row.isExpand}},[(row.isExpand)?_vm._t("row-expansion",null,{"row":row,"isExpand":row.isExpand}):_vm._e()],2)])]):_vm._e()]:_vm._e()})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }