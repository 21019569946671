<template>
	<div class="BaseField">
		<div v-if="label" class="BaseField__label">
			<slot name="label-prepend" />
			<span v-html="label" />
			<slot name="label-append" />
		</div>
		<div class="BaseField__field" @dblclick="onEdit">
			<slot/>
		</div>
	</div>
</template>

<script>
export default {
	name: "BaseField",
	props: {
		invalid: {type: Boolean, default: false},
	},
	inject: ["label", "onEdit"],
}
</script>

<style lang="scss">
@import "@/assets/sass/core/vars";

.BaseField {
	display: flex;
	flex-direction: column;
	gap: 3px;
	width: 100%;

	&__label {
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color: #70778C;
		display: flex;
	}

	& .field {
		display: flex;
		align-items: center;
		width: 100%;
		min-height: 44px;
		background: #FFFFFF;
		border-radius: 3px;
		box-sizing: border-box;

		&.border {
			border: 1px solid #E6E9F0;
		}

		&.invalid {
			border-color: $red;
		}
	}
}
</style>