<template>
    <div class='ui-table-load-more-button'>
        <v-progress-circular v-if='loading' color="primary" indeterminate></v-progress-circular>
        <div v-else class='d-flex flex-column align-center' style='cursor: pointer' v-on='$listeners'>
            <span>{{ $t('Rodyti daugiau') }}</span>
            <ui-icon class='mt-1' style='color: #3D45A9;'>arrow-down</ui-icon>
        </div>
    </div>
</template>

<script>
import UiIcon from '@/domain/components/UiIcon.vue'

export default {
    name: 'UiTableLoadMoreButton',
    components: { UiIcon },
    props: {
        loading: {type: Boolean, default: false}
    }
}
</script>

<style scoped lang='scss'>
.ui-table-load-more-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>