<template>
	<Auth>
		<template #header>{{ $t('Pamiršau slaptažodį') }}</template>
		<template #form>
			<ForgotPasswordForm/>
		</template>
		<template #after-card>
			<hra-button-link full-width no-background :text-width="buttonTextWidth.normal"
			                 :to="{ name: 'login' }">{{ $t('Grįžti į prisijungimą') }}
			</hra-button-link>
		</template>
	</Auth>
</template>

<script>
import Auth from "@/components/Auth.vue";
import ForgotPasswordForm from "@/views/forms/auth/ForgotPasswordForm.vue";
import HraButtonLink from "@/components/ui/button/HraButtonLink";

export default {
	components: {
		HraButtonLink,
		Auth,
		ForgotPasswordForm,
	},
};
</script>
