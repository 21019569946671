import Department from '@/api/employee/department'
import Import from '@/api/employee/import'
import Contract from '@/api/employee/contract'
import Disability from '@/api/employee/disability'
import VacationPlus from '@/api/employee/vacationPlus'
import Child from '@/api/employee/child'
import Duty from '@/api/employee/duty'
import Calendar from '@/api/employee/calendar'
import Vacation from '@/api/employee/vacation'
import LeaderVacations from '@/api/employee/leaderVacations'
import api from '@/plugins/api'

export default axios => ({
    getAll: (params, requestName) => axios.get('/api/employees/pagination', { params, requestName }),
    get: (id, requestName) => axios.get(`/api/v2/employees/${id}`, { requestName }),

    changeRole: (payload) => axios.post(`/api/authorization/role`, payload),
    fetchByFilter: (params) => axios.get(`/api/employees`, { params }),

    filters: {
        fetch: (params = {}) => axios.get(`/api/employees/filters/list`, {params}),
    },

    // getProfile: () => axios.get(`/api/v1/employees/profile`),
    getProfile: () => axios.get(`/api/v2/employees/profile`),
    getCreateForm: (requestName) => axios.get(`/api/v1/employees/form`, { requestName }),
    create: (payload) => axios.post(`/api/employees`, payload, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }),
    profile: (id, requestName) => axios.get(`/api/v1/employees/${id}/profile`, { requestName }),


    fetch: (id) => axios.get(`/api/employees/${id}`),

    users: {
        fetch: (id) => axios.get(`/api/employees/users/${id}`),
        update: (id, payload) => axios.put(`/api/employees/users/${id}`, payload),
        updateCustom: (id, param, payload) => axios.put(`/api/employees/users/${id}/${param}`, payload)
    },
    contracts: {
        getAllByUserId: (id) => axios.get(`/api/employees/${id}/contracts`),
        getByUserId: (id) => axios.get(`/api/employees/${id}/contracts/current`),
        update: (id, payload) => axios.put(`/api/employees/contracts/${id}`, payload),
        updateCustom: (id, param, payload) => axios.put(`/api/employees/contracts/${id}/${param}`, payload)
    },
    contacts: {
        fetchAllByUserId: (id) => axios.get(`/api/employees/${id}/contacts`),
        update: (id, payload) => axios.put(`/api/employees/${id}/contacts`, payload)
    },
    disabilities: {
        fetchAllByUserId: (id) => axios.get(`/api/employees/${id}/disabilities`),
        create: (userId, payload) => axios.post(`/api/employees/${userId}/disabilities`, payload),
        update: (id, payload) => axios.put(`/api/employees/disabilities/${id}`, payload),
        destroy: (id) => axios.delete(`/api/employees/disabilities/${id}`)
    },
    children: {
        fetchAllByUserId: (id) => axios.get(`/api/employees/${id}/children`),
        create: (userId, payload) => axios.post(`/api/employees/${userId}/children`, payload),
        update: (id, payload) => axios.put(`/api/employees/children/${id}`, payload),
        destroy: (id) => axios.delete(`/api/employees/children/${id}`)
    },
    additionalHolidays: {
        fetchByUserId: (userId) => axios.get(`/api/employees/${userId}/additional-holidays`),
        create: (payload) => axios.post(`/api/employees/additional-holidays`, payload),
        update: (id, payload) => axios.put(`/api/employees/additional-holidays/${id}`, payload),
        destroy: (id) => axios.delete(`/api/employees/additional-holidays/${id}`)
    },
    changeLogs: {
        fetchByUserId: (userId) => axios.get(`/api/employees/${userId}/change-logs`)
    },
    events: {
        fetch: (id, params) => axios.get(`/api/events/employees/${id}`, params),
        check: (payload) => axios.post(`/api/events/check`, payload)
    },
    eventDeputies: {
        fetch: (id, params) => axios.get(`/api/events/deputies/employees/${id}`, params)
    },

    holidays: {
        fetch: (id) => axios.get(`/api/events/employees/${id}/holidays`),
        create: (payload) => axios.post(`/api/events`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }),
        update: (id, payload) => axios.post(`/api/events/${id}`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }),
        check: (id, payload) => axios.post(`/api/events/employees/${id}/holidays/check`, payload),
        abort: (id, payload) => axios.post(`/api/events/${id}/holidays/abort`, payload),

        deputies: {
            fetch: (id) => axios.get(`/api/events/employees/${id}/holidays/deputies`),
            approve: (id) => axios.post(`/api/events/${id}/holidays/deputies/approve`),
            abort: (id) => axios.post(`/api/events/${id}/holidays/deputies/abort`)
        },
        widgets: {
            fetch: (id) => axios.get(`/api/events/employees/${id}/holidays/widgets`)
        }
    },


    updateProfile: (id, payload) => axios.post(`/api/v2/employees/${id}/profiles`, payload),

    editField: (id, payload, requestName) => axios.put(`/api/v1/employees/${id}`, payload, { requestName }),

    autocomplete: (params) => axios.get(`/api/employees/autocomplete`, { params }),

    manyById: (params, requestName) => axios.get(`/api/v2/employees/many/by/id`, { params, requestName }),
    sentInvites: (ids, requestName) => axios.post(`/api/employees/invites/sent-many`, { ids }, { requestName }),
    changeRoles: (id, payload, requestName) => axios.post(`/api/v1/employees/${id}/roles`, payload, { requestName }),
    deleteReasons: () => axios.get(`/api/employees/dismiss/reasons`),
    dismiss: (payload) => axios.post(`/api/employees/dismissMany`, payload),
    destroy: (payload) => axios.post(`/api/v2/employees/destroy`, payload),
    changelogs: (id) => axios.get(`/api/v2/employees/${id}/changelogs`),
    adminVacationData: (id) => axios.get(`/api/v1/employees/${id}/admin-vacation-data`),
    // changeDescription: (id, description) => axios.put(`/api/v1/employees/${id}/description`, {description}),
    // changeAvatar: (id, data) => axios.post(`/api/v1/employees/${id}/avatar`, data, {
    //     headers: {
    //         "Content-Type": "multipart/form-data"
    //     }
    // }),

    changeProfile: (id, payload) => axios.put(`/api/v2/employees/${id}/profiles`, payload),
    changeDescription: (id, payload) => axios.put(`/api/v2/employees/${id}/description`, payload),
    changeContact: (id, payload) => axios.put(`/api/v2/employees/${id}/contacts`, payload),
    changeDepartments: (id, payload) => axios.put(`/api/v2/employees/${id}/departments`, payload),
    changeDuties: (id, payload) => axios.put(`/api/v2/employees/${id}/duties`, payload),
    changeContract: (id, contractId, payload) => axios.put(`/api/v2/employees/${id}/contracts/${contractId}`, payload),
    storeContractDetails: (id, contractId, payload) => axios.post(`/api/v2/employees/${id}/contracts/${contractId}/details`, payload),
    changeAvatar: (id, payload) => axios.post(`/api/employees/users/${id}/avatar`, payload, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }),
    createPlusVacation: (id, payload) => axios.post(`/api/v2/employees/${id}/plusVacations`, payload),
    updatePlusVacation: (id, plusVacationId, payload) => axios.put(`/api/v2/employees/${id}/plusVacations/${plusVacationId}`, payload),
    destroyPlusVacation: (id, plusVacationId) => axios.delete(`/api/v2/employees/${id}/plusVacations/${plusVacationId}`),
    createDisability: (id, payload) => axios.post(`/api/v2/employees/${id}/disabilities`, payload),
    updateDisability: (id, disabilityId, payload) => axios.put(`/api/v2/employees/${id}/disabilities/${disabilityId}`, payload),
    destroyDisability: (id, disabilityId) => axios.delete(`/api/v2/employees/${id}/disabilities/${disabilityId}`),
    createChild: (id, payload) => axios.post(`/api/v2/employees/${id}/child`, payload),
    updateChild: (id, childId, payload) => axios.put(`/api/v2/employees/${id}/child/${childId}`, payload),
    destroyChild: (id, childId) => axios.delete(`/api/v2/employees/${id}/child/${childId}`),

    department: Department(axios),
    duty: Duty(axios),
    import: Import(axios),
    contract: Contract(axios),
    disability: Disability(axios),
    vacationPlus: VacationPlus(axios),
    child: Child(axios),
    calendar: Calendar(axios),
    vacation: Vacation(axios),
    leaderVacations: LeaderVacations(axios)
});
