<template>
	<div class="hra-calendar">
		<div class="hra-calendar__header">
			<hra-icon v-if="navigation" :name="iconName.arrowLeft" @click="onPrevMonth"/>
			<span>{{ title }}</span>
			<hra-icon v-if="navigation" :name="iconName.arrowRight" @click="onNextMonth"/>
		</div>
		<table class="hra-calendar__weeks">
			<tr class="hra-calendar__week-header">
				<td class="hra-calendar__week-day" v-for="weekDay in weekDays" :key="weekDay">
					{{ weekDay }}
				</td>
			</tr>
			<tr class="hra-calendar__week" v-for="(week, key) in weeks" :key="`week-data-${key}`">
				<hra-calendar-week-days
					v-for="({days, event}, key) in week"
					:class="{'clickable': event && event.clickable}"
					:key="`week-${key}`"
					:days="days"
					:event="event"
					:disableClickEvent="disableClickEvent"
					@click.prevent="() => $emit('clickEvent', event, days)"
				>
					<template #event-menu>
						<slot name="event-menu" :event="event"/>
					</template>
				</hra-calendar-week-days>
			</tr>
		</table>
	</div>
</template>

<script>
import moment from "moment";
import {mapGetters} from "vuex";
import {getDateArray} from "@/utils/default";
import HraIcon from "@/components/ui/HraIcon";
import HraCalendarDay from "@/components/ui/calendar/HraCalendarDay";
import HraCalendarWeek from "@/components/ui/calendar/HraCalendarWeek";
import HraCalendarWeekDays from "@/components/ui/calendar/HraCalendarWeekDays";
import i18n from '@/plugins/i18n'

export default {
	name: "HraCalendar",
	components: {HraCalendarWeekDays, HraCalendarWeek, HraCalendarDay, HraIcon},
	props: {
		start: {type: [String], required: true},
		navigation: {type: Boolean, default: false},
		events: {type: Array, default: () => ([])},
		disableClickEvent: {type: Boolean, default: false},
		minSelectDate: {type: String, default: null},
		maxSelectDate: {type: String, default: null},
	},
	data() {
		return {
			errorTag: "CALENDAR_ERROR_TAG",
			locale: i18n.locale,
		};
	},
	watch: {
		start: {
			handler: function (newStart) {
				this.$store.dispatch("calendar/fetch", {year: moment(newStart).year()});
			},
			immediate: true,
		},
	},
	provide() {
		return {
			handleClickDay: this.handleClickDay
		};
	},
	created() {
		this.$store.dispatch("messagesStore/addToastTag", this.errorTag);
	},
	computed: {
		...mapGetters({
			eventHolidays: "calendar/eventHolidays",
		}),
		calendarEvents() {
			let _e = {};
			this.events.forEach((event, key) => {
				if (event.fromDate == null || event.toDate == null) {
					return;
				}

				getDateArray(moment(event.fromDate), moment(event.toDate)).forEach(date => {
					_e[date] = key;
				})
			});

			return _e;
		},
		title() {
			return moment(this.start).locale(this.locale).format("MMMM, YYYY");
		},
		weekDays() {
			return Array.apply(null, Array(7)).map(
				(_, i) => moment(i, 'e').startOf('week').locale(this.locale).isoWeekday(i + 1).format("dd")
			);
		},
		preDays() {
			if (moment(this.start).isoWeekday() === 1) {
				return [];
			}

			const start = moment(this.start).subtract(moment(this.start).isoWeekday() - 1, 'day');
			const end = moment(this.start).subtract(1, 'day');

			return getDateArray(start, end, null, this.dayFormat);
		},

		weeks() {
			const nowMonth = moment(this.start).month();
			const today = moment().format("YYYY-MM-DD");
			const start = moment(this.start).subtract(moment(this.start).isoWeekday() - 1, 'day');
			let end = moment(this.start).add(moment(this.start).daysInMonth(), 'day');

			end.add(end.isoWeekday() === 1 ? -1 : 7 - end.isoWeekday(), 'days');

			let weeks = [];
			let week = [];
			let days = [];
			let eventKey = null;

			const insertWeek = () => {
				weeks.push(week);
				week = [];
			};

			const insertDays = () => {
				if (days.length > 0) {
					week.push({days, event: eventKey != null ? this.events[eventKey] : null});
				}

				days = [];
			};

			Array(end.diff(start, 'days') + 1).fill(null).forEach((_, key) => {
				if (key !== 0 && key % 7 === 0) {
					insertDays();
					insertWeek();
				}

				const date = start.format("YYYY-MM-DD");
				const disabled = nowMonth !== start.month();

				if (eventKey != null && this.calendarEvents[date] !== eventKey && days.length > 0) {
					insertDays();
				}

				if (eventKey != null && disabled) {
					insertDays();
				}

				eventKey = !disabled ? this.calendarEvents[date] : null;

				const payload = {
					day: start.format("DD"),
					date,
					disabled,
					today: !disabled && today === date,
					holiday: disabled ? null : this.eventHolidays[date],
				};

				if (eventKey != null) {
					days.push(payload);
				} else {
					days = [];
					week.push({days: [payload], event: null});
				}

				start.add(1, "day");
			});

			if (week.length > 0 || days.length > 0) {
				insertDays();
				insertWeek();
			}

			return weeks;
		},

		days() {
			const start = moment(this.start);
			return getDateArray(start, null, start.daysInMonth(), this.dayFormat);
		},
		afterDays() {
			const start = moment(this.start).add(moment(this.start).daysInMonth(), "day");

			if (start.isoWeekday() === 1) {
				return [];
			}

			const duration = 8 - start.isoWeekday();
			return getDateArray(start, null, duration, this.dayFormat);
		},
	},
	methods: {
		dayFormat(date) {
			return {
				date: date.format("YYYY-MM-DD"),
				day: date.format("DD"),
			};
		},
		onPrevMonth() {
			this.$emit("prev");
		},
		onNextMonth() {
			this.$emit("next");
		},
		handleClickDay(date) {
			if (this.minSelectDate && this.minSelectDate > date) {
				this.$store.dispatch("messagesStore/showErrorMessage", {
					tag: this.errorTag,
					message: this.$t("Jus negalite pasirinkti ankstesnės datos nei {date}", {date: this.minSelectDate})
				});
				return;
			}

			if (this.maxSelectDate && this.maxSelectDate > date) {
				this.$store.dispatch("messagesStore/showErrorMessage", {
					tag: this.errorTag,
					message: this.$t("Jus negalite pasirinkti vėlesnės datos nei {date}", {date: this.maxSelectDate})
				});
				return;
			}

			this.$emit("clickDate", date);
		},
	}
}
</script>
