import i18n from "@/plugins/i18n";

const ALL = "*";

export const ADMIN_ROLE_ID = 'admin';
export const EMPLOYEE_ROLE_ID = 'employee';

const EMPLOYEE_VIEW = 'employee.view';
const EMPLOYEE_CREATE = 'employee.create';
const EMPLOYEE_EDIT = 'employee.edit';
const EMPLOYEE_DELETE = 'employee.delete';

const VACATION_LEADER_VIEW = 'vacation.leader.view';
const VACATION_DEPARTMENT_VIEW = 'vacation.department.view';
const VACATION_DEPARTMENT_APPROVE = 'vacation.department.approve';

const SETTINGS_ALL = 'settings.all';

const PERMISSION_ONLY_ADMIN = 'admin.only';

export const roleTexts = {
  [ADMIN_ROLE_ID]: i18n.t("Admin"),
  [EMPLOYEE_ROLE_ID]: i18n.t("Darbuotojas")
};

export const permissionTexts = {
  [EMPLOYEE_VIEW]: i18n.t("Pridėti darbuotojus"),
  [EMPLOYEE_CREATE]: i18n.t("Pridėti darbuotojus"),
  [EMPLOYEE_EDIT]: i18n.t("Redaguoti darbuotojų informaciją"),
  [EMPLOYEE_DELETE]: i18n.t("Šalinti darbuotojus"),
  [VACATION_LEADER_VIEW]: i18n.t("Rodyti skyriaus atostogų grafiką"),
  [VACATION_DEPARTMENT_VIEW]: i18n.t("Sudaryti skyriaus darbuotojų budėjimo grafiką"),
  [VACATION_DEPARTMENT_APPROVE]: i18n.t("Tvirtinti skyriaus darbuotojų atostogas"),
};

export const permissionsWildCard = [ALL];

export const permissionOnlyAdmin = [PERMISSION_ONLY_ADMIN]

export const permissionsPageSettings = [SETTINGS_ALL];
export const debuggerPageSettings = [SETTINGS_ALL];
export const permissionsPageEmployeeView = [EMPLOYEE_VIEW];
export const permissionsPageEmployees = [EMPLOYEE_VIEW];
export const permissionsPageVacations = [VACATION_DEPARTMENT_VIEW];
export const permissionsPageEmployeesArchive = [EMPLOYEE_VIEW];
export const permissionsPageEmployeeCreate = [EMPLOYEE_CREATE];
export const permissionsPageEmployeeEdit = [EMPLOYEE_EDIT];

export const permissionsButtonEmployeeCreate = [EMPLOYEE_CREATE];
export const permissionsButtonEmployeeEdit = [EMPLOYEE_EDIT];
export const permissionsButtonEmployeeDelete = [EMPLOYEE_DELETE];
export const permissionsButtonEmployeeImport = [EMPLOYEE_CREATE, EMPLOYEE_EDIT, EMPLOYEE_DELETE];

export const permissionCanApproveVacations = [VACATION_DEPARTMENT_APPROVE];

export const permissionsAll = [EMPLOYEE_CREATE, EMPLOYEE_EDIT, EMPLOYEE_DELETE, VACATION_LEADER_VIEW, VACATION_DEPARTMENT_VIEW, VACATION_DEPARTMENT_APPROVE];
