<template>
    <expansion-panel :header="$t('Informacija apie darbuotoją')">
        <v-row>
            <v-col cols='6'>
                <text-field
                    name='name'
                    :label="$t('Vardas Pavardė')"
                    rules='required|min:3'
                    :editable='isEdit'
                    :disabled='isProfile'
                    @save='onUpdateUser'
                    v-model='user.name'
                />
            </v-col>
            <v-col cols='6'>
                <datepicker-field
                    name='birthDate'
                    :label="$t('Gimimo metai')"
                    :editable='isEdit'
                    :disabled='isProfile'
                    @save='onUpdateUser'
                    v-model='user.birthDate'
                />
            </v-col>
            <v-col cols='6'>
                <select-field
                    name='departmentId'
                    rules='required'
                    :label="$t('Skyrius')"
                    filtered
                    :editable='isEdit'
                    :disabled='isProfile'
                    :options='departmentOptions'
                    @save='onUpdateUserCustom'
                    v-model='user.departmentId'
                />
            </v-col>
            <v-col cols='6'>
                <select-field
                    name='dutyId'
                    rules='required'
                    :label="$t('Pareigos')"
                    filtered
                    :editable='isEdit'
                    :disabled='isProfile'
                    :options='dutyOptions'
                    @save='onUpdateUserCustom'
                    v-model='user.dutyId'
                />
            </v-col>
            <v-col cols='12'>
                <employee-disabilities-table :is-profile='isProfile' :is-create='isCreate' :is-edit='isEdit' />
            </v-col>
            <v-col cols='12'>
                <employee-child-table :is-profile='isProfile' :is-create='isCreate' :is-edit='isEdit' />
            </v-col>
        </v-row>
    </expansion-panel>
</template>

<script>
import { mapGetters } from 'vuex'
import ExpansionPanel from '@/domain/components/ExpansionPanel'
import TextField from '@/domain/fields/TextField'
import DatepickerField from '@/domain/fields/DatepickerField'
import SelectField from '@/domain/fields/SelectField'
import expansionEmployeeMixin from '@/domain/employee/mixins/expansionEmployeeMixin'
import EmployeeDisabilitiesTable from '@/domain/employee/components/tables/EmployeeDisabilitiesTable'
import EmployeeChildTable from '@/domain/employee/components/tables/EmployeeChildTable'

export default {
    name: 'ExpansionEmployeeBase',
    components: {
        EmployeeChildTable,
        EmployeeDisabilitiesTable,
        SelectField,
        DatepickerField,
        TextField,
        ExpansionPanel
    },
    mixins: [expansionEmployeeMixin],
    data() {
        return {
            departments: [],
            duties: []
        }
    },
    computed: {
        ...mapGetters({
            user: 'employeeStore/user',
            departmentOptions: 'employeeStore/departmentOptions',
            dutyOptions: 'employeeStore/dutyOptions'
        })
    },
    methods: {
        async onUpdateUser({ fieldTag, name, value, saveComplete }) {
            if (await this.$store.dispatch('employeeStore/updateUser', {
                tag: fieldTag,
                id: this.user.id,
                payload: { [name]: value }
            })) {
                saveComplete()
            }
        },
        async onUpdateUserCustom({ fieldTag, name, value, saveComplete }) {
            if (await this.$store.dispatch('employeeStore/updateUserCustom', {
                tag: fieldTag,
                id: this.user.id,
                param: {
                    dutyId: 'duty',
                    departmentId: 'department'
                }[name],
                payload: { [name]: value }
            })) {
                saveComplete()
            }
        }
    }
}
</script>