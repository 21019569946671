<template>
    <div class='custom-tabs' ref='tabs'>
        <div class='custom-tabs__header' ref='header' :class='headerClasses'>
            <div class='custom-tabs__tabs'>
                <div ref='TabsContainer' class='custom-tabs__tabs-container'>
                    <div
                        v-for='{name, prependIcon, text} in tabs'
                        :key='name'
                        class='custom-tabs__tab'
                        :class="{'active':  activeTab === name}"
                        @click.prevent='onChangeTab(name)'
                    >
                        <ui-icon v-if='!!prependIcon' class='pr-2'>{{ prependIcon }}</ui-icon>
                        {{ text }}
                    </div>
                    <div class='ml-auto custom-tabs__tab additional' v-if='$slots.additional'>
                        <slot name='additional'></slot>
                    </div>
                </div>
            </div>
        </div>
        <div class='custom-tabs__container'>
            <slot>
                <router-view />
            </slot>
        </div>
    </div>
</template>

<script>
import UiIcon from '@/domain/components/UiIcon.vue'

export default {
    name: 'RouteTabs',
    components: { UiIcon },
    props: {
        tabs: { type: Array, required: true },
        isSticky: { type: Boolean, default: false },
        scrollToHeader: { type: Boolean, default: false },
    },
    computed: {
        activeTab() {
            return this.$route.name
        },
        headerClasses() {
            return {
                'sticky': this.isSticky
            }
        }
    },
    methods: {
        onChangeTab(name) {
            if (this.activeTab === name) {
                return
            }

            this.$emit('changeTab', name)

            this.$router.push({ name })
        }
    }
}
</script>

<style lang='scss' scoped>
@import '@/assets/sass/core/vars';

.custom-tabs {
    position: relative;
    z-index: 2;
    //width: 100%;

    &__header {
        width: 100%;

        &.sticky {
            position: sticky;
            top: 0;
            z-index: 5;
        }
    }

    &__tabs {
        position: relative;
        width: 100%;
        height: 49px;
        padding: 0 32px;
        overflow: hidden;

        &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background: $gray-300;
        }
    }

    &__tabs-container {
        display: flex;
        height: 100%;
        gap: 10px;
        align-items: flex-end;
    }

    &__container {
        background: $white;
    }

    &__tab {
        position: relative;
        height: 100%;
        padding: 0 20px;
        display: flex;
        align-items: center;
        background: $gray-200;
        cursor: pointer;
        border-radius: 4px 4px 0 0;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: $greyscale-600;

        .ui-icon {
            color: #70778C;
        }

        &.additional {
            background: none;
        }

        &:not(.additional):before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            background: #1A4BFF;
            border-radius: 4px 4px 0 0;
            height: 3px;
            width: 100%;
            opacity: 0;
            transition: opacity 300ms;
        }

        &:hover, &.active {
            &:before {
                opacity: 1;
            }
        }

        &.active {
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 16px rgba(0, 0, 0, 0.1);
            background: $white;
            z-index: 2;
            font-weight: 600;
            color: $greyscale-800;

            .ui-icon {
                color: #0F166A;
            }
        }
    }
}
</style>