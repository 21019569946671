<template>
    <div
        class="schedule-tabs__item"
        :class="{'active': currentTab === tabKey}"
        :style="{height: `${headerTdStyle}px`}"
        @click="onChangeTab(tabKey)"
    >
        {{ $t(label) }}
    </div>
</template>

<script>
export default {
    props: {
        currentTab: { type: String, required: true },
        tabKey: { type: String, required: true },
        label: { type: String, required: true },
    },
    inject: ['headerTdStyle'],
    methods: {
        onChangeTab(tabName) {
            this.$emit('change-tab', tabName)
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/core/colors';

.schedule-tabs__item {
    padding: 10px;
    border: 0;
    border-bottom: 4px solid #DEDFE4;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 0;
    color: $violet-400;
    font-weight: 500;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
        color: $greyscale-800;
    }
    &.active {
        color: $greyscale-800;
        font-weight: 700;
        border-bottom: 4px solid $pink;
    }
}
</style>