import customApi from "@/plugins/customApi";
import store from "@/store";
import {ADMIN_ROLE_ID} from "@/utils/permissions";

export class EmployeeService {
  async init(tag, id) {
    const api = customApi(tag);

    const data = await api.request(api.factories.employee.get(id));

    if (data == null) {
      return false;
    }

    data.employee.department = data.employee.department?.id ?? null;
    data.employee.duties = data.employee.duties.map(({id}) => id);

    await store.dispatch("employeeStore/init", data);
    await store.dispatch("loadersStore/hideLoader", tag);

    return true;
  }

  async updateField({request, requests, factories, tag}, employeeId) {
    const responses = await requests([
      factories.employee.changelogs(employeeId),
      factories.employee.adminVacationData(employeeId),
    ]);

    if (responses == null) {
      return false;
    }

    const [changelogs, vacations] = responses;
    await store.dispatch("employeeStore/setChangelogs", changelogs);
    // await store.dispatch("employee/vacationData/init", vacations);
    await store.dispatch("loadersStore/hideLoader", tag);
    await store.dispatch("messagesStore/showSuccessMessage", {tag, message: "Changed success!"});
    return true;
  }

  async updateBaseField(tag, name, value) {
    const api = customApi(tag);
    const employeeId = store.getters["employeeStore/id"];
    const response = await api.request(api.factories.employee.editField(employeeId, ({[name]: value})));

    if (response == null) {
      return false;
    }

    return await this.updateField(api, employeeId);
  }

  async updateBaseContact(tag, name, value) {
    return await this.updateBaseField(tag, name, value);
  }

  async changeUserGuard(tag, role, permissions) {
    const api = customApi(tag);
    const userId = store.getters["employeeStore/user"].id;
    const response = await api.request(api.factories.user.changeGuard(userId, {
      role,
      permissions,
    }));

    if (response == null) {
      return false;
    }

    this.getChangelogs();

    await store.dispatch("employeeStore/changeUser", response);
    await store.dispatch("loadersStore/hideLoader", tag);
    await store.dispatch("messagesStore/showSuccessMessage", {tag, message: "Changed success!"});
    return true;
  }

  async getChangelogs() {
    const tag = "EMPLOYEE_GET_CHANGELOGS";
    const api = customApi(tag);
    const employeeId = store.getters["employeeStore/id"];

    const data = await api.request(api.factories.employee.changelogs(employeeId));

    if (data == null) {
      return;
    }

    await store.dispatch("employeeStore/setChangelogs", data);
    await store.dispatch("loadersStore/hideLoader", tag);
  }

  async changeEmployee(tag, apiMethod, storeMethod, ...args) {
    const api = customApi(tag);
    const employeeId = store.getters["employeeStore/id"];

    const data = await api.request(api.factories.employee[apiMethod](employeeId, ...args));

    if (data == null) {
      return false;
    }

    this.getChangelogs();

    await store.dispatch(`employeeStore/${storeMethod}`, data);
    await store.dispatch("loadersStore/hideLoader", tag);
    await store.dispatch("messagesStore/showSuccessMessage", {tag, message: "Changed success!"});
    return true;
  }

  async changeProfile(tag, payload) {
    const profile = store.getters["employeeStore/profile"];
    delete profile.avatarSrc;
    return this.changeEmployee(tag, "changeProfile", "changeProfile", {
      ...profile,
      ...payload,
    });
  }

  async changeUser(tag, payload) {
    const api = customApi(tag);
    const { id } = store.getters["employeeStore/user"];

    const data = await api.request(api.factories.employee.users.update(id, payload));

    if (data == null) {
      return false;
    }

    // await store.dispatch(`employeeStore/${storeMethod}`, data);
    await store.dispatch("loadersStore/hideLoader", tag);
    await store.dispatch("messagesStore/showSuccessMessage", {tag, message: "Changed success!"});
    return true;
  }

  async changeContacts(tag, payload) {
    const api = customApi(tag);
    const { id } = store.getters["employeeStore/user"];

    const data = await api.request(api.factories.employee.contacts.update(id, payload));

    if (data == null) {
      return false;
    }

    // await store.dispatch(`employeeStore/${storeMethod}`, data);
    await store.dispatch("loadersStore/hideLoader", tag);
    await store.dispatch("messagesStore/showSuccessMessage", {tag, message: "Changed success!"});
    return true;
  }

  async changeDescription(tag, payload) {
    return this.changeEmployee(tag, "changeDescription", "changeProfile", {
      description: payload,
    });
  }

  async changeContact(tag, payload) {
    const contact = store.getters["employeeStore/contact"];
    return this.changeEmployee(tag, "changeContact", "changeContact", {
      ...contact,
      ...payload,
    });
  }

  async changeDepartments(tag, payload) {
    return this.changeEmployee(tag, "changeDepartments", "changeDepartments", {
      id: payload
    });
  }

  async changeDuties(tag, payload) {
    return this.changeEmployee(tag, "changeDuties", "changeDuties", {
      ids: payload
    });
  }

  async changeContract(tag, payload) {
    const contract = store.getters["employeeStore/contract"];
    return this.changeEmployee(tag, "changeContract", "changeContract", contract.id, {
      ...contract,
      ...payload
    });
  }

  async addContractDetails(tag, payload) {
    const contract = store.getters["employeeStore/contract"];
    const contractDetail = store.getters["employeeStore/contractDetail"];
    return this.changeEmployee(tag, "storeContractDetails", "addContractDetail", contract.id, {
      ...contractDetail,
      ...payload,
    });
  }

  async changeAvatar(tag, payload) {
    const formData = new FormData();
    formData.append("avatar", payload, "avatar.png");
    return this.changeEmployee(tag, "changeAvatar", "changeProfile", formData);
  }

  async createPlusVacation(tag, payload) {
    return this.changeEmployee(tag, "createPlusVacation", "createPlusVacation", payload);
  }

  async updatePlusVacation(tag, id, payload) {
    return this.changeEmployee(tag, "updatePlusVacation", "updatePlusVacation", id, payload);
  }

  async destroyPlusVacation(tag, id) {
    return this.changeEmployee(tag, "destroyPlusVacation", "destroyPlusVacation", id);
  }

  async createDisability(tag, payload) {
    return this.changeEmployee(tag, "createDisability", "createDisability", payload);
  }

  async updateDisability(tag, id, payload) {
    return this.changeEmployee(tag, "updateDisability", "updateDisability", id, payload);
  }

  async destroyDisability(tag, id) {
    return this.changeEmployee(tag, "destroyDisability", "destroyDisability", id);
  }

  async createChild(tag, payload) {
    return this.changeEmployee(tag, "createChild", "createChild", payload);
  }

  async updateChild(tag, id, payload) {
    return this.changeEmployee(tag, "updateChild", "updateChild", id, payload);
  }

  async destroyChild(tag, id) {
    return this.changeEmployee(tag, "destroyChild", "destroyChild", id);
  }

  getDisabilities() {
    return store.getters["employeeStore/disabilities"];
  }

  getChild() {
    return store.getters["employeeStore/child"];
  }
}