<template>
    <custom-dialog :max-width='450' ref='dialog' has-custom-footer :loading='loading'>
        <template #title>{{ title }}</template>
        <template #content>
            <v-row v-if='!loading'>
                <v-col v-if='availableStatuses' cols='12'>
                    <ui-table>
                        <template #head>
                            <tr>
                                <th>{{ $t('Darbuotojas') }}</th>
                                <th>{{ $t('Statusas') }}</th>
                            </tr>
                        </template>
                        <template #body>
                            <tr v-for='row in statuses' :key='row.id'>
                                <td>{{ row.user.name }}</td>
                                <td>
                                    <event-status
                                        :status='row.status'
                                        :status-text='row.statusText'
                                        :date='row.date'
                                    />
                                </td>
                            </tr>
                        </template>
                    </ui-table>
                </v-col>
                <v-col v-if='availableStatusWithStages' cols='12' class='pt-8 px-8'>
                    <approval-stages
                        v-if='statusWithStages.stages != null'
                        :stages='statusWithStages.stages'
                    />
                    <div v-else>
                        <approval-skip-stages
                            :status='statusWithStages.status'
                            :user='statusWithStages.user'
                            :deputies='statusWithStages.deputies'
                        />
                    </div>
                </v-col>
                <v-col v-if='availableStages' cols='12' class='pt-8 px-8'>
                    <approval-stages
                        v-if='!loading'
                        :stages='stages'
                    />
                </v-col>
            </v-row>
        </template>
        <template #footer>
            <ui-button prepend-icon='close' background='default' @click='close'>{{ $t('Uždaryti') }}</ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import CustomApi from '@/plugins/customApi'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import ApprovalStages from '@/domain/components/ApprovalStages/ApprovalStages.vue'
import dialogMixin from '@/mixins/dialogMixin'
import UiButton from '@/domain/components/UiButton.vue'
import EventStatus from '@/domain/components/EventStatus.vue'
import UiTable from '@/domain/components/uiTable/UiTable.vue'
import EmployeeTableView from '@/components/ui/views/EmployeeTableView.vue'
import ApprovalSkipStages from '@/domain/components/ApprovalStages/ApprovalSkipStages.vue'

const customApi = CustomApi()
export default {
    name: 'ApprovalStatesModal',
    components: { ApprovalSkipStages, EmployeeTableView, UiTable, EventStatus, UiButton, ApprovalStages, CustomDialog },
    mixins: [dialogMixin],
    props: {
        id: { type: Number, required: true },
        type: { type: String, required: true }
    },
    data() {
        return {
            loading: true,
            titles: {
                businessTripEvent: this.$t('Komandiruotės tvirtinimo eiga'),
                holidayEvent: this.$t('Atostogų tvirtinimo eiga'),
                remoteWorkEvent: this.$t('Nuotolinio darbo tvirtinimo eiga'),
                remoteWork: this.$t('Nuotolinio darbo tvirtinimo eiga'),
                statuses: this.$t('Statusų istoriją')
            },
            stages: [],
            statuses: [],
            statusWithStages: null
        }
    },
    created() {
        this.fetch()
    },
    computed: {
        title() {
            return this.titles[this.type] ?? ''
        },
        availableStages() {
            return ['remoteWork'].includes(this.type)
        },
        availableStatusWithStages() {
            return ['businessTripEvent', 'holidayEvent', 'remoteWorkEvent'].includes(this.type)
        },
        availableStatuses() {
            return ['statuses'].includes(this.type)
        }
    },
    methods: {
        fetch() {
            this.loading = true

            Promise.all([
                this.fetchStages(),
                this.fetchStatuses(),
                this.fetchStatusWithStages()
            ]).then(([stages, statuses, statusWithStages]) => {
                this.stages = stages
                this.statuses = statuses
                this.statusWithStages = statusWithStages
            }).finally(() => {
                this.loading = false
            })
        },
        fetchStages() {
            return new Promise((resolve, reject) => {
                if (!this.availableStages) {
                    return resolve([])
                }

                customApi.factories.approvals.tree[this.type](this.id)
                    .then(({ data }) => {
                        resolve(data)
                    })
                    .catch((error) => {
                        console.error('error', error)
                    })
            })
        },
        fetchStatusWithStages() {
            return new Promise((resolve, reject) => {
                if (!this.availableStatusWithStages) {
                    return resolve([])
                }

                customApi.factories.events.statuses.fetchOneWithDetails(this.id)
                    .then(({ data }) => {
                        resolve(data)
                    })
                    .catch((error) => {
                        console.error('error', error)
                    })
            })
        },
        fetchStatuses() {
            return new Promise((resolve, reject) => {
                if (!this.availableStatuses) {
                    return resolve([])
                }

                customApi.factories.events.statuses.fetchOne(this.id)
                    .then(({ data }) => {
                        resolve(data)
                    })
                    .catch((error) => {
                        console.error('error', error)
                    })
            })
        }
    }
}
</script>