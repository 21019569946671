export default axios => ({
    fetchList: () => axios.get(`/api/schedules`),
    create: (payload) => axios.post(`/api/schedules`, payload),
    getById: (id) => axios.get(`/api/schedules/${id}`),


    fetchEventsByScheduleIdInInterval: (id, params) => axios.get(`/api/schedules/${id}/events`, {params}),
    fetchTimetablesByScheduleIdInInterval: (id, params) => axios.get(`/api/schedules/${id}/timetables`, {params}),
    fetchBreaksByScheduleIdInInterval: (id, params) => axios.get(`/api/schedules/${id}/breaks`, {params}),

    deleteById: (id) => axios.delete(`/api/schedules/${id}`),
    generateAutoBreaks: (payload) => axios.post(`/api/schedules/auto/breaks`, payload),

    lines: {
        create: (payload) => axios.post(`/api/schedules/lines`, payload),
        update: (id, payload) => axios.put(`/api/schedules/lines/${id}`, payload),
        destroy: (id) => axios.delete(`/api/schedules/lines/${id}`)
    },

    timetables: {
        create: (payload) => axios.post(`/api/schedules/timetables`, payload),
        generate: (payload) => axios.post(`/api/schedules/timetables/generate`, payload),
        copy: (payload) => axios.post(`/api/schedules/timetables/copy/interval`, payload),
        update: (id, payload) => axios.put(`/api/schedules/timetables/${id}`, payload),
        destroy: (id) => axios.delete(`/api/schedules/timetables/${id}`)
    },

    events: {
        getByScheduleIdWithFilter: (scheduleId, from, to) => axios.get(`/api/schedules/${scheduleId}/events?from=${from}&to=${to}`),
        // create: (scheduleId, payload) => axios.post(`/api/schedules/${scheduleId}/events`, payload),
        create: (payload) => axios.post(`/api/schedules/events`, payload),

        changeParent: (id, payload) => axios.put(`/api/schedules/events/${id}/parent`, payload),

        move: (id, payload) => axios.put(`/api/schedules/events/${id}/move`, payload),
        resize: (id, payload) => axios.put(`/api/schedules/events/${id}/resize`, payload),

        child: {
            move: (id, payload) => axios.put(`/api/schedules/events/${id}/child/move`, payload),
            resize: (id, payload) => axios.put(`/api/schedules/events/${id}/child/resize`, payload)
        },

        update: (id, payload) => axios.put(`/api/schedules/events/${id}`, payload),
        updateBreaks: (id, payload) => axios.put(`/api/schedules/events/${id}/breaks`, payload),
        changeShift: (id, payload) => axios.put(`/api/schedules/events/${id}/parent`, payload),
        destroy: (id) => axios.delete(`/api/schedules/events/${id}`),
        copy: (payload) => axios.post(`/api/schedules/timetables/copy/graph`, payload),
    },
    shifts: {
        create: (payload) => axios.post(`/api/schedules/shifts`, payload),
        events: {
            create: (payload) => axios.post(`/api/schedules/shifts/events`, payload),
            move: (id, payload) => axios.put(`/api/schedules/shifts/events/${id}/move`, payload),
            resize: (id, payload) => axios.put(`/api/schedules/shifts/events/${id}/resize`, payload)
        }
    },
    employees: {
        create: (payload) => axios.post(`/api/schedules/employees`, payload),
        events: {
            create: (payload) => axios.post(`/api/schedules/employees/events`, payload),
            move: (id, payload) => axios.put(`/api/schedules/employees/events/${id}/move`, payload),
            resize: (id, payload) => axios.put(`/api/schedules/employees/events/${id}/resize`, payload)
        }
    },
    timeIntervals: {
        create: (payload) => axios.post(`/api/schedules/time-intervals`, payload),
        update: (id, payload) => axios.put(`/api/schedules/time-intervals/${id}`, payload),
        destroy: (id) => axios.delete(`/api/schedules/time-intervals/${id}`)
    },
    timetableIntervals: {
        create: (payload) => axios.post(`/api/schedules/timetable-intervals`, payload),
        update: (id, payload) => axios.put(`/api/schedules/timetable-intervals/${id}`, payload),
        destroy: (id) => axios.delete(`/api/schedules/timetable-intervals/${id}`)
    },
    breaks: {
        create: (payload) => axios.post(`/api/schedules/breaks`, payload),
        update: (id, payload) => axios.put(`/api/schedules/breaks/${id}`, payload),
        destroy: (id) => axios.delete(`/api/schedules/breaks/${id}`)
    }

    // checkDays: (type, fromDate, toDate) => axios.get(`/api/v2/events/${type}/days?fromDate=${fromDate}&toDate=${toDate}`),
    // balance: (type, date, employeeId, ignoreEventId = null) => axios.get(`/api/v2/events/${type}/employees/${employeeId}/balance/${date}`),
})