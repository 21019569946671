<template>
    <div class='d-flex mt-3 lighten-4 rounded gap-4 pa-4'>
        <div class='d-flex flex-column align-content-space-between'>
            <ui-button
                v-if='!order.isFirst'
                prepend-icon='up'
                size='small'
                @click='onMoveOrder(-1)'
            />
            <div class='my-1 text-center py-1 white rounded-lg'>{{ order.order }}</div>
            <ui-button
                v-if='!order.isLast'
                prepend-icon='down'
                size='small'
                @click='onMoveOrder( 1)'
            />
        </div>
        <v-row>
            <v-col cols='12' class='d-flex justify-space-between align-end px-0'>
                <text-field
                    :label="$t('Min to approve')"
                    type='number'
                    :min='1'
                    :max='maxToApprove'
                    :step='1'
                    :value='order.minToApprove'
                    @input='onChangeMinToApprove'
                />
                <ui-button
                    v-if='canDeleteOrder'
                    class='ml-2'
                    prepend-icon='bin'
                    background='danger-light'
                    @click='onDeleteOrder(order.id)'
                >
                    {{ $t('Ištrinti lygį') }}
                </ui-button>
            </v-col>
            <v-col
                cols='12'
                class='elevation-1 rounded mb-2 d-flex justify-space-between align-center'
                v-for='user in order.users'
                :key='`${user.id}-order`'
            >
                <employee-table-view :employee='user' />
                <ui-button
                    v-if='canDeletesUsers'
                    class='ml-2'
                    prepend-icon='bin'
                    size='small'
                    background='danger-light'
                    @click='onDeleteUser(user.id)'
                />
            </v-col>
            <v-col cols='12' class='d-flex justify-space-between align-end pa-0 mt-3'>
                <select-field
                    :label="$t('Pridėti tvirtintoja')"
                    style='width: 100%'
                    :options-service='service'
                    v-model='userId'
                    dynamic
                    filtered
                    single-line
                    clearable
                >
                    <template #selection='{item}'>
                        <employee-table-view class='py-1' :employee='item.employee' />
                    </template>
                    <template #item='{item}'>
                        <employee-table-view class='py-1' :employee='item.employee' />
                    </template>
                </select-field>

                <ui-button
                    class='ml-2'
                    prepend-icon='plus'
                    background='success-light'
                    @click='onAddUser'
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import TextField from '@/domain/fields/TextField.vue'
import EmployeeTableView from '@/components/ui/views/EmployeeTableView.vue'
import UiButton from '@/domain/components/UiButton.vue'
import SelectField from '@/domain/fields/SelectField.vue'
import employeeAutocompleteService from '@/services/employees/employeeAutocompleteService'
import _ from 'lodash'

export default {
    name: 'SettingEventApprovalByIdOrderLevel',
    components: { SelectField, UiButton, EmployeeTableView, TextField },
    props: {
        index: {type: Number, required: true},
        order: {type: Object, required: true},
        canDeleteOrder: {type: Boolean, default: false}
    },
    data() {
        return {
            employeeAutocompleteService,
            users: [],
            userId: null
        }
    },
    computed: {
        maxToApprove() {
            return this.order.users.length
        },
        canDeletesUsers() {
            return this.order.users.length > 1
        }
    },
    methods: {
        async service(tag, value) {
            const idsToIgnore = this.order.users.map((user) => user.id);
            this.users = await employeeAutocompleteService.find(tag, value, idsToIgnore)

            return this.users
        },
        onMoveOrder(to) {
            this.$emit('move', this.order.id, to)
        },
        onDeleteOrder() {
            this.$emit('delete', this.order.id)
        },
        onAddUser() {
            const user = this.users.find(({ employee }) => employee.id === this.userId)
            if (user == null) {
                return
            }

            this.$emit('userAdd', this.order.id, _.clone(user.employee))
            this.userId = null
        },
        onDeleteUser(userId) {
            this.$emit('userDelete', this.order.id, userId)
        },
        onChangeMinToApprove(value) {
            this.$emit('changeMinToApprove', this.order.id, value)
        }
    }
}
</script>