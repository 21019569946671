import moment from 'moment/moment'
import { GLOBAL_TOAST_TAG } from '@/utils/default'

export default {
    computed: {
        timeDiff() {
            return moment(this.formData.endTime, 'HH:mm')
                .diff(moment(this.formData.startTime,'HH:mm'), 'minutes')
        },
        minEndDate() {
            return moment(this.formData.startDate, 'YYYY-MM-DD')
                .add(this.timeDiff > 0 ? 0 : 1, 'day')
                .format('YYYY-MM-DD')
        }
    },
    methods: {
        getMomentStartDateTime() {
            return moment(`${this.formData.startDate} ${this.formData.startTime}`, 'YYYY-MM-DD HH:mm')
                .startOf('seconds')
        },
        getMomentEndDateTime() {
            return moment(`${this.formData.endDate} ${this.formData.endTime}`, 'YYYY-MM-DD HH:mm')
                .startOf('seconds')
        },
        async throwInfoNotification() {
            await this.$store.dispatch('messagesStore/showInfoMessage', {
                tag: GLOBAL_TOAST_TAG,
                message: this.$t('Date change')
            })
        },
        shiftEndDateIfNeeded() {
            const start = this.getMomentStartDateTime()
            const end = this.getMomentEndDateTime()

            if (end.isSame(start) || end.isBefore(start)) {
                this.formData.endDate = end.add(1, 'day').format('YYYY-MM-DD')
                return this.throwInfoNotification()
            }

            if (start.calendar() !== end.calendar() && this.timeDiff > 0) {
                this.formData.endDate = start.format('YYYY-MM-DD')
                return this.throwInfoNotification()
            }
        },
        handleChangeStartDate() {
            this.formData.endDate = this.minEndDate
        },
        handleChangeStartTime() {
            this.shiftEndDateIfNeeded()
        },
        handleChangeEndTime() {
            this.shiftEndDateIfNeeded()
        }
    }
}