<template>
    <Form
        ref="form"
        v-model="formData"
        :service="service"
        @submit="onSubmit"
        :form-loader-name="loaderName"
        is-dialog
    >
        <template #form>
            <v-row>
                <v-col cols="12" v-for="(employee, key) in employees" :key="key">
                    <EmployeeTableView :employee="employee"/>
                </v-col>
                <v-col cols="12">
                    <FormSelect
                        :field-trans-prefix="translatePrefix"
                        :options="reasons"
                        name="reason_id"
                        v-model="formData.reason_id"
                    />
                </v-col>
                <v-col cols="12">
                    <FormDatePicker
                        :field-trans-prefix="translatePrefix"
                        :date-from="minDate"
                        name="delete_date"
                        v-model="formData.delete_date"
                    />
                </v-col>
            </v-row>
        </template>
        <template #buttons="{loading}">
            <v-spacer/>
            <Button
                color="default"
                class="m-r-1"
                icon="close"
                @click.prevent="closeDialogAndClearForm"
                :disabled="loading"
            >{{ t("delete.button.abort") }}
            </Button>
            <Button
                color="primary"
                icon="ok"
                :loading="loading"
                type="submit"
            >{{ t("delete.button.submit") }}
            </Button>
        </template>
    </Form>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import Button from "@/components/ui/button/Button.vue";
import Form from "@/components/ui/form/Form.vue";
import formDialogMixin from "@/mixins/formDialogMixin";
import Avatar from "@/components/ui/Avatar";
import FormSelect from "@/components/ui/form/FormSelect";
import EmployeeTableView from "@/components/ui/views/EmployeeTableView";
import FormDatePicker from "@/components/ui/form/FormDatePicker";
import moment from "moment";

export default {
    mixins: [formDialogMixin],
    components: {
        FormDatePicker,
        EmployeeTableView,
        FormSelect,
        Avatar,
        Button,
        Form,
    },
    props: {
        ids: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            loaderName: "App-Delete-Employee",
            translatePrefix: "form.app.employee.",
            service: null,
            minDate: moment().format("YYYY-MM-DD"),

            formData: {
                ids: this.ids,
                reason_id: "",
                delete_date: moment().format("YYYY-MM-DD"),
            },
        };
    },
    computed: {
        ...mapGetters({
            employeesById: "employees/employeesById",
            reasons: "employees/deleteReasons",
        }),
        employees() {
            return this.employeesById(this.ids);
        },
    },
    methods: {
        ...mapMutations({
            setNeedToRefresh: "employees/setNeedToRefresh",
        }),
        onSubmit(response) {
            if (response) {
                this.$refs.form.showSuccess(this.t("delete.message.success"));
                this.setNeedToRefresh();
                this.closeDialogWithTimeout(600);
            }
        },
    },
};
</script>
