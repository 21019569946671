<template>
    <custom-dialog :max-width='670'>
        <template #title>{{ $t('Darbo grafiko kartojimo generavimas') }}</template>
        <template #content>
            <v-row>
                <v-col cols='6'>
                    <datepicker-field
                        :label='$t("Kartojamo intervalo pradžia")'
                        v-model='filter.intervalStart'
                    />
                </v-col>
                <v-col cols='6'>
                    <datepicker-field
                        :label='$t("Kartojamo intervalo pabaiga")'
                        :min-date='filter.intervalStart'
                        v-model='filter.intervalEnd'
                    />
                </v-col>
                <v-col cols='6'>
                    <datepicker-field
                        :label='$t("Kartoti nuo")'
                        :min-date='minRangeStartDate'
                        v-model='filter.rangeStart'
                    />
                </v-col>
                <v-col cols='6'>
                    <datepicker-field
                        :label='$t("Kartoti iki")'
                        :min-date='filter.rangeStart'
                        v-model='filter.rangeEnd'
                    />
                </v-col>
                <v-col cols='12' v-if='canSelectWeekDays'>
                    <select-field
                        :options='weekDaysOptions'
                        :label='$t("Kartoti dienomis")'
                        multiple
                        v-model='filter.weekDays'
                    />
                </v-col>
            </v-row>
        </template>

        <template #footer>
            <ui-button
                prepend-icon='close'
                background='default'
                @click='close'
            >{{ $t('Atšaukti') }}
            </ui-button>
            <ui-button
                prepend-icon='ok'
                background='primary'
                :disabled='disabledButton'
                @click='onCreate'
            >{{ $t('Generuoti grafiką') }}
            </ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import dialogMixin from '@/mixins/dialogMixin'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import UiButton from '@/domain/components/UiButton.vue'
import DatepickerField from '@/domain/fields/DatepickerField.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import SelectField from '@/domain/fields/SelectField.vue'
import moment from 'moment'

export default {
    name: 'GenerateScheduleTimetablesByFilterModal',
    mixins: [dialogMixin],
    props: {
        scheduleLineId: {type: Number, required: true}
    },
    components: { SelectField, FormCheckbox, DatepickerField, UiButton, CustomDialog },
    data() {
        return {
            disabledButton: false,
            filter: {
                scheduleLineId: null,
                intervalStart: moment().format('YYYY-MM-DD'),
                intervalEnd: null,
                rangeStart: null,
                rangeEnd: null,
                weekDays: null
            },
            weekDaysOptions: [
                {value: 1, text: this.$t('Pirmadienis')},
                {value: 2, text: this.$t('Antradienis')},
                {value: 3, text: this.$t('Trečiadienis')},
                {value: 4, text: this.$t('Ketvirtadienis')},
                {value: 5, text: this.$t('Penktadienis')},
                {value: 6, text: this.$t('Šeštadienis')},
                {value: 0, text: this.$t('Sekmadienis')},
            ]
        }
    },
    beforeMount() {
        this.filter.scheduleLineId = this.scheduleLineId
    },
    computed: {
        intervalSelected() {
            return moment(this.filter.intervalEnd).diff(moment(this.filter.intervalStart), 'days')
        },
        minRangeStartDate() {
            return moment(this.filter.intervalEnd).add(1, 'day').format('YYYY-MM-DD')
        },
        canSelectWeekDays() {
            return this.intervalSelected === 0
        }
    },
    methods: {
        async onCreate() {
            this.disabledButton = true
            const result = await this.$store.dispatch('schedulerStore/generateTimetablesByFilter', {
                ...this.filter,
                weekDays: this.canSelectWeekDays ? this.filter.weekDays : null
            })
            this.disabledButton = false
            result && this.close()
        }
    }
}
</script>