<template>
	<div
		class="message-toast"
		:class="`message-toast--${type}`"
	>
		<div class="message-toast__content">
			<i
				class="message-toast__icon"
				:class="iconClass"
			/>
			<div class="message-toast__message" v-html="message"/>
			<i class="message-toast__close icon-close" @click="close"/>
		</div>
		<div class="message-toast__hide-progress" :style="{animationDuration: `${closeDelay}ms`}"/>
	</div>
</template>

<script>
import messageMixin from "@/domain/messages/mixins/messageMixin";

export default {
	name: "MessageToast",
	mixins: [messageMixin],
	created() {
		this.closeWithDelay();
	},
}
</script>

<style lang="scss">
@import "@/assets/sass/core/vars";

.message-toast {
	font-family: 'Public Sans', sans-serif;
	width: 300px;
	height: auto;
	border-radius: 4px;
	overflow: hidden;

	&__content {
		display: flex;
		gap: 10px;
		padding: 17px 20px;
		align-items: center;
	}

	&__icon {
		width: 20px;
		height: 20px;
		font-size: 20px;
		line-height: 20px;
		color: $white;
	}

	&__message {
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 14px;
		display: flex;
		align-items: center;
		letter-spacing: 0.03em;
		color: $white;
	}

	&__close {
		font-size: 10px;
		line-height: 10px;
		margin-left: auto;
		cursor: pointer;
	}

	&__hide-progress {
		width: 100%;
		height: 5px;
		animation: toast-progress;
	}

	&--error {
		background: #D71635;
	}

	&--error &__hide-progress {
		background: #FFD7DD;
	}

	&--error &__close {
		color: #FFD7DD;
	}

	&--success {
		background: #01BB99;
	}

	&--success &__hide-progress {
		background: #7CDFCE;
	}

	&--success &__close {
		color: #7CDFCE;
	}

	&--info {
		background: #1A4BFF;
	}

	&--info &__hide-progress {
		background: #C6D4FF;
	}

	&--info &__close {
		color: #C6D4FF;
	}

	&--warning {
	}

	&--default {
	}
}

@keyframes toast-progress {
	0% {
		width: 100%;
	}
	100% {
		width: 0;
	}
}
</style>