// NPM
import Vue from 'vue'

// PROJECT
import i18n from '@/plugins/i18n'
import router from '@/router'
import store from '@/store'
import '@/plugins/api'
import '@/plugins/customApi'
import vuetify from '@/plugins/vuetify'
import Main from '@/components/Main.vue'
import axiosRequestMixin from '@/mixins/axiosRequestMixin.js'
import globalMixin from '@/mixins/globalMixin'
import '@/plugins/vee-validate'
import '@/assets/sass/main.scss'

function loadClarity(host) {
    if (process.env.VUE_APP_CLARITY_ENABLED !== "true") {
        return
    }

    const availableHosts = process.env.VUE_APP_CLARITY_AVAILABLE_HOSTS ?? null
    if (availableHosts !== '*' && !(availableHosts?.split(',') ?? []).includes(host)) {
        return
    }

    console.info('clarity enabled');
    (function(c, l, a, r, i, t, y) {
        c[a] = c[a] || function() {
            (c[a].q = c[a].q || []).push(arguments)
        }
        t = l.createElement(r)
        t.async = 1
        t.src = 'https://www.clarity.ms/tag/' + i
        y = l.getElementsByTagName(r)[0]
        y.parentNode.insertBefore(t, y)
    })(window, document, 'clarity', 'script', process.env.VUE_APP_CLARITY_ID)
}

function loadCookieBot(host) {
    if (process.env.VUE_APP_COOKIE_BOT_ENABLED !== "true") {
        return
    }

    const availableHosts = process.env.VUE_APP_COOKIE_BOT_AVAILABLE_HOSTS ?? null
    if (availableHosts !== '*' && !(availableHosts?.split(',') ?? []).includes(host)) {
        return
    }

    console.info('cookie bot enabled')
    const id = process.env.VUE_APP_COOKIE_BOT_ID
    let externalScript = document.createElement('script')
    externalScript.setAttribute('id', 'Cookiebot')
    externalScript.setAttribute('type', 'text/javascript')
    externalScript.setAttribute('src', `https://consent.cookiebot.com/uc.js?cbid=${id}&data-blockingmode=auto`)
    document.head.appendChild(externalScript)
}

(function(host) {
    loadClarity(host)
    loadCookieBot(host)
})(window.location.host)

Vue.mixin(axiosRequestMixin)
Vue.mixin(globalMixin)

Vue.directive('resize', {
    inserted: function(element, binding) {
        const onResizeCallback = binding.value

        window.addEventListener('resize', () => {
            const width = document.documentElement.clientWidth
            const height = document.documentElement.clientHeight

            onResizeCallback({ width, height })
        })
    }
})

new Vue({
    router, i18n, vuetify, store, render: h => h(Main)
}).$mount('#app')
