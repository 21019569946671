<template>
    <custom-dialog :max-width='800'>
        <template #title>{{ $t('Pasirinkite įkeliamo dokumento vietą') }}</template>
        <template #content>
            <folder-select-finder ref='Finder' :folder-id='folderId' />
        </template>
        <template #footer>
            <ui-button @click='close'>{{ $t('Uždaryti') }}</ui-button>
            <ui-button
                prepend-icon='ok'
                background='primary'
                @click='handleSelect'
            >{{ $t('Pasirinkti') }}
            </ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import UiButton from '@/domain/components/UiButton.vue'
import dialogMixin from '@/mixins/dialogMixin'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import FolderSelectFinder from '@/domain/documents/components/finders/FolderSelectFinder.vue'

export default {
    name: 'DocumentsSelectUploadDirModal',
    mixins: [dialogMixin],
    props: {
        folderId: {type: [Number, null], default: null},
    },
    components: {
        FolderSelectFinder,
        CustomDialog,
        UiButton
    },
    methods: {
        handleSelect() {
            this.$emit('selectedFolderId', {
                folderId: this.$refs.Finder.getFolderId(),
                breadcrumbs: this.$refs.Finder.getBreadcrumbs()
            })
            this.close()
        }
    }
}
</script>