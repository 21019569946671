<template>
    <custom-dialog
        ref='Dialog'
        :max-width='1200'
        class='document-send-dialog'
    >
        <template #title>{{ $t('Dokumentų siuntimas') }}</template>
        <template #content>
            <v-row class='px-3'>
                <v-col cols='12'>
                    <steps-wizard />
                </v-col>
                <component :is='currentStepComponent' />
            </v-row>
        </template>
        <template #footer>
            <ui-button
                v-if='currentStepKey > 0'
                prepend-icon='arrow-left'
                background='default'
                @click='handlePrevStep'
            >{{ $t('Atgal') }}
            </ui-button>
            <ui-button
                v-if='currentStepKey < 2'
                prepend-icon='arrow-right'
                background='primary'
                @click='handleNextStep'
            >{{ $t('Toliau') }}
            </ui-button>
            <ui-button
                v-if='currentStepKey === 2'
                prepend-icon='send'
                background='primary'
                :loading='loading'
                @click='handleSent'
            >{{ $t('Siųsti') }}
            </ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import AssignUsersToDocumentsStep
    from '@/domain/documents/modals/items/documentSendModal/components/AssignUsersToDocumentsStep.vue'
import DocumentsSelectStep from '@/domain/documents/modals/items/documentSendModal/components/DocumentsSelectStep.vue'
import TermsStep from '@/domain/documents/modals/items/documentSendModal/components/TermsStep.vue'
import store from '@/domain/documents/modals/items/documentSendModal/store'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import UiButton from '@/domain/components/UiButton.vue'
import dialogMixin from '@/mixins/dialogMixin'
import StepsWizard from '@/domain/documents/modals/items/documentSendModal/components/StepsWizard.vue'
import CustomApi from '@/plugins/customApi'

const storeNamespace = 'documentSendModalStore'
const customApi = CustomApi()

export default {
    name: 'DocumentSendModal',
    mixins: [dialogMixin],
    components: { StepsWizard, UiButton, CustomDialog },
    props: {
        step: { type: Number, default: 0 },
        documents: { type: Array, default: () => [] },
        userIds: { type: Array, default: null }
    },
    data() {
        return {
            loading: false,
            storeNamespace
        }
    },
    computed: {
        currentStepKey() {
            return this.$store.getters[`${storeNamespace}/currentStepKey`]
        },
        currentStepComponent() {
            const components = {
                0: DocumentsSelectStep,
                1: AssignUsersToDocumentsStep,
                2: TermsStep
            }

            return components[this.currentStepKey]
        }
    },
    provide() {
        return {
            storeNamespace: this.storeNamespace
        }
    },
    methods: {
        async getParams() {
            await this.$store.dispatch(`${storeNamespace}/setUsersDocumentsFilter`, {
                column: 'userId',
                filter: { selected: this.userIds, sort: 'asc' }
            })

            await this.$store.dispatch(`${storeNamespace}/setUsersDocumentsFilter`, {
                column: 'status',
                filter: { selected: [], sort: null }
            })

            const filter = this.$store.getters[`${this.storeNamespace}/usersDocumentsFilter`]
            let params = {
                page: 1,
                limit: this.documents.length * this.userIds.length,
                sortBy: [],
                documentId: this.documents.map(document => document.id),
                userId: this.userIds
            }

            for (const [column, value] of Object.entries(filter)) {
                if (value.sort != null) {
                    params.sortBy.push(`${column},${value.sort}`)
                }

                if ((value.selected?.length ?? 0) > 0) {
                    params[column] = value.selected
                }
            }

            return params
        },
        async firstLoad() {
            await this.$store.dispatch(`${storeNamespace}/setDocuments`, this.documents)

            if (this.userIds != null) {
                try {
                    const { data: { items } } = await customApi.factories.documents.employees.fetchByFilter(await this.getParams())
                    await this.$store.dispatch(`${this.storeNamespace}/setSelectedAssignUsersToDocumentsRows`, items)
                } catch (error) {
                    console.error(error)
                    this.showErrorInRootToast(error)
                } finally {
                    this.loading = false
                }
            }

            await this.$store.dispatch(`${storeNamespace}/setStep`, this.step)
        },
        handleSelectStepByKey(key) {
            if (!this.$store.getters[`${storeNamespace}/availableStepKeys`].includes(key)) {
                this.showErrorInRootToast(this.$t('Negalimas žingsnis'))
                return
            }

            this.$store.dispatch(`${this.storeNamespace}/setStep`, key)
        },
        handlePrevStep() {
            this.handleSelectStepByKey(this.currentStepKey - 1)
        },
        handleNextStep() {
            this.handleSelectStepByKey(this.currentStepKey + 1)
        },
        getSentPayload() {
            const sendOptions = this.$store.getters[`${this.storeNamespace}/sendOptions`]
            const assignUsersDocuments = this.$store
                .getters[`${this.storeNamespace}/assignUsersToDocumentsRows`]
                .filter(row => row.selected)
                .map(({ userId, documentId }) => ({ userId, documentId }))

            if (!sendOptions.needEmployeesConfirm) {
                return {
                    needEmployeesConfirm: false,
                    assignUsersDocuments
                }
            }

            if (sendOptions.dueAt == null) {
                throw new Error(this.$t('Pasirinkite susipažinimo iki data'))
            }

            let payload = {
                assignUsersDocuments,
                dueAt: sendOptions.dueAt,
                needEmployeesConfirm: sendOptions.needEmployeesConfirm
            }

            if (sendOptions.useReminder) {
                payload.reminder = {
                    at: sendOptions.reminderAt,
                    repeatEveryDay: sendOptions.reminderEveryDay
                }
            }

            return payload
        },
        async handleSent() {
            this.loading = true
            try {
                const { data } = await customApi.factories.documents.sent(this.getSentPayload())
                this.showSuccessInRootToast(this.$t('Dokumentai išsiųsti sėkmingai'))
                this.$emit('sent', data)
                this.close()
            } catch (error) {
                console.error(error)
                this.showErrorInRootToast(error)
            } finally {
                this.loading = false
            }
        }
    },
    beforeCreate() {
        this.$store.registerModuleSafely(storeNamespace, store)
    },
    created() {
        this.firstLoad()
    },
    beforeDestroy() {
        this.$store.unregisterModuleSafely(storeNamespace)
    }
}
</script>