<template>
    <custom-dialog :max-width='550'>
        <template #title>{{ $t('Atstatyti dokumentą') }}</template>
        <template #content>
            <v-row class='mx-3'>
                <v-col cols='12'>
                    <document-title-view
                        :title='item.title'
                    />
                </v-col>
                <v-col cols='12' class='pt-0'>
                    <b>{{ $t('Ar tikrai norite atstatyti dokumentą?') }}</b>
                </v-col>
                <v-col cols='12' class='pt-0'>
                    <select-folder-field
                        :title='$t("Dokumentas bus atsatytas į pasirinktą aplanką")'
                        :folder-id='folderId'
                        @changeFolderId='handleChangeFolderId'
                    />
                </v-col>
            </v-row>
        </template>
        <template #footer>
            <ui-button prepend-icon='close' background='default' @click='close'>{{ $t('Uždaryti') }}</ui-button>
            <ui-button prepend-icon='ok' background='primary' @click='handleSubmit' :loading='loading'>
                {{ $t('Atstatyti') }}
            </ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import UiButton from '@/domain/components/UiButton.vue'
import dialogMixin from '@/mixins/dialogMixin'
import customApi from '@/plugins/customApi'
import { GLOBAL_TOAST_TAG } from '@/utils/default'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import SelectFolderField from '@/domain/documents/components/SelectFolderField.vue'
import DocumentTitleView from '@/domain/documents/components/DocumentTitleView.vue'

export default {
    name: 'DocumentRestoreModal',
    components: { DocumentTitleView, SelectFolderField, CustomDialog, UiButton },
    mixins: [dialogMixin],
    props: {
        item: { type: Object, required: true }
    },
    data() {
        return {
            loading: false,
            folderId: this.item.documentId
        }
    },
    methods: {
        handleChangeFolderId(folderId) {
            this.folderId = folderId
        },
        async handleSubmit() {
            this.loading = true

            try {
                let payload = {}
                if (this.folderId != null) {
                    payload.folderId = this.folderId
                }

                await customApi().factories.documents.documents.restore(this.item.id, payload)
                this.$emit('documentRestored', this.item.id)
                await this.$store.dispatch('messagesStore/showSuccessMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Dokumentas sėkmingai atstatytas')
                })
                this.$root.$emit('documents-overview-disk-usage-refresh')
                this.close()
            } catch {
                await this.$store.dispatch('messagesStore/showErrorMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Nepavyko atstatyti dokumento')
                })
            } finally {
                this.loading = false
            }
        }
    }
}
</script>