<template>
    <app-layout>
        <div class='page page_with-background'>
            <router-view />
        </div>
    </app-layout>
</template>

<script>
import AppLayout from '@/layouts/AppLayout'
import RouteTabs from '@/domain/components/RouteTabs.vue'

export default {
    name: 'MainLayout',
    components: { RouteTabs, AppLayout }
}
</script>

<style lang='scss'>
.documents-main-layout-container {
    width: 1199px !important;
    margin-left: 0 !important;
}
</style>