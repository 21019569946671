<template>
    <ui-table-head-filter
        :title='title'
        :column='column'
        :select-options='employeesAllUserOptions'
        :sort-options='sortOptions'
        :min-wdith='minWidth'
        select-option-searchable
        v-model='currentFilter'
        @changeFilter='handleChangeFilter'
    />
</template>

<script>
import UiTableHeadFilter from '@/domain/components/uiTable/UiTableHeadFilter.vue'
import i18n from '@/plugins/i18n'
import filterEmployeesAllMixin from '@/domain/filters/mixins/filterEmployeesAllMixin'

export default {
    name: 'TableHeaderUserFilter',
    components: { UiTableHeadFilter },
    mixins: [filterEmployeesAllMixin],
    props: {
        title: {type: String, default: () => i18n.t("Darbuotojas") },
        column: {type: String, default: 'userId' },
        filter: {type: Object, default: () => ({selected: [], sort: null}) },
        minWidth: {type: [String,Number], default: 150}
    },
    data() {
        return {
            sortOptions: [
                { value: 'asc', label: 'A-Z' },
                { value: 'desc', label: 'Z-A' }
            ],
            currentFilter: {
                selected: this.filter.selected,
                sort: this.filter.sort
            }
        }
    },
    watch: {
        filter: {
            handler(filter) {
                this.currentFilter = {
                    selected: filter.selected,
                    sort: filter.sort
                }
            },
            deep: true
        }
    },
    methods: {
        handleChangeFilter(column, filter) {
            this.$emit('changeFilter', column, filter)
        }
    }
}
</script>