<template>
    <v-dialog
        v-model="dialog"
        persistent
        scrollable
        :fullscreen="fullscreen"
        :max-width="maxWidth"
        transition="dialog-top-transition"
    >
        <template v-slot:activator="{ on, attrs }">
            <div v-if="modalQueryName" v-bind="attrs" v-on="{click: onClickButton}">
                <slot name="button"/>
            </div>
            <div v-else v-bind="attrs" v-on="on">
                <slot name="button"/>
            </div>
        </template>

        <div class="dialog" v-if="dialog">
            <div class="dialog__header px-5">
                <h6>{{ title }}</h6>
                <v-spacer/>
                <i
                    class="icon-close dialog__close"
                    @click.prevent="closeDialog"
                />
            </div>
            <div class="dialog__content">
                <slot name="content" :dialog="dialog" :closeDialog="closeDialog"/>
            </div>
        </div>
    </v-dialog>
</template>

<script>
export default {
    name: "Dialog",
    props: {
        title: {
            type: String,
            required: true,
        },
        fullscreen: {
            type: Boolean,
            default: false,
        },
        maxWidth: {
            type: Number,
            default: 848,
        },
        modalQueryName: {
            type: String,
            default: null,
        },
    },
    data: () => ({
        dialog: false,
    }),
    watch: {
        $route: {
            handler(newRoute) {
                if (newRoute.query.modal && this.modalQueryName) {
                    if (this.modalQueryName === newRoute.query.modal) {
                        this.dialog = true;
                    }
                }
            },
            immediate: true,
        },
    },
    methods: {
        closeDialog() {
            this.dialog = false;
            if (this.modalQueryName) {
                const {modal, ...query} = this.$route.query;
                this.$router.push({query});
            }
        },
        onClickButton() {
            this.$router.push({
                query: {
                    ...this.$route.query,
                    modal: this.modalQueryName,
                },
            });
        },
    },
}
</script>
