// import initState from "@/domain/settings/store/state";
//
// export const SET_VACATIONS = "SET_VACATIONS";
// export const ADD_VACATION_AUTO_APPROVE_EMPLOYEE = "ADD_VACATION_AUTO_APPROVE_EMPLOYEE";
// export const REMOVE_VACATION_AUTO_APPROVE_EMPLOYEE = "REMOVE_VACATION_AUTO_APPROVE_EMPLOYEE";
// export const CLEAR_VACATIONS = "CLEAR_VACATIONS";
// export const SET_EMAIL = "SET_EMAIL";
// export const CLEAR_EMAIL = "CLEAR_EMAIL";
//
// export default ({
//   [SET_VACATIONS]: (state, payload) => state.vacations = payload,
//   [ADD_VACATION_AUTO_APPROVE_EMPLOYEE]: (state, payload) => state.vacations.autoApproveEmployees.push(payload),
//   [REMOVE_VACATION_AUTO_APPROVE_EMPLOYEE]: (state, payload) => {
//     state.vacations.autoApproveEmployees = state.vacations.autoApproveEmployees.filter(({employeeId}) => employeeId !== payload);
//   },
//   [CLEAR_VACATIONS]: (state) => state.vacations = initState().vacations,
//   [SET_EMAIL]: (state, payload) => state.email = payload,
//   [CLEAR_EMAIL]: (state) => state.email = initState().email,
// })

export const FETCH_TREE = "FETCH_TREE";


export const FETCH_DUTIES = "FETCH_DUTIES";
export const CREATE_DUTY = "CREATE_DUTY";
export const UPDATE_DUTY = "UPDATE_DUTY";
export const DESTROY_DUTY = "DESTROY_DUTY";
export const EXPAND_DEPARTMENT = "EXPAND_DEPARTMENT";
export const SUSPEND_DEPARTMENT = "SUSPEND_DEPARTMENT";


export default ({
  [FETCH_TREE]: (state, {tree, links, expandedDepartments}) => {
    state.tree = tree;
    state.links = links;
    state.expandedDepartments = expandedDepartments;
  },
  [FETCH_DUTIES]: (state, payload) => state.duties = payload,
  [CREATE_DUTY]: (state, payload) => state.duties.push(payload),
  [UPDATE_DUTY]: (state, payload) => state.duties = state.duties
    .map(duty => duty.id === payload.id ? payload : duty),
  [DESTROY_DUTY]: (state, payload) => state.duties = state.duties
    .filter(duty => duty.id !== payload),
  [EXPAND_DEPARTMENT]: (state, payload) => state.expandedDepartments.push(payload),
  [SUSPEND_DEPARTMENT]: (state, payload) => state.expandedDepartments = state.expandedDepartments.filter(id => id !== payload),
});