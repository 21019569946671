<template>
  <div class="d-flex gap-3" v-if="!vacation.createLeader && vacation.statusCode >= STATUS_WAIT">
	  <hra-confirm
		  v-if="[STATUS_WAIT, STATUS_APPROVE].includes(vacation.statusCode)"
		  :title="$t('Ar tikrai norite atmesti atostogas?')"
		  @confirm="onAbortVacation($event)"
		  :abort-text="$t('Ne')"
		  :confirm-text="$t('Taip')"
	  >
		  <hra-tooltip :text="$t('Atšaukti')">
			  <hra-button :color="uiColor.dangerLight" :size="buttonSize.small">
				  <hra-icon :name="iconName.close"/>
			  </hra-button>
		  </hra-tooltip>
	  </hra-confirm>
    <hra-tooltip v-if="vacation.statusCode === STATUS_WAIT"
                 :text="$t('Keisti')">
      <hra-button-modal :modal-props="{id: vacation.id}" modal-name="EmployeeVacationModal"
                        :color="uiColor.default" :size="buttonSize.small">
        <hra-icon :name="iconName.edit"/>
      </hra-button-modal>
    </hra-tooltip>
    <hra-tooltip v-if="vacation.statusCode === STATUS_APPROVE"
                 :text="$t('Keisti')">
      <hra-button-modal :modal-props="{id: vacation.id}" modal-name="EmployeeVacationModal"
                        :color="uiColor.default" :size="buttonSize.small">
        <hra-icon :name="iconName.edit"/>
      </hra-button-modal>
    </hra-tooltip>
    <hra-tooltip v-if="![STATUS_WAIT, STATUS_APPROVE].includes(vacation.statusCode)"
                 :text="$t('Plačiau')">
      <hra-button-modal :modal-props="{id: vacation.id}" modal-name="EmployeeVacationModal"
                        :color="uiColor.default" :size="buttonSize.small">
        <hra-icon :name="iconName.info"/>
      </hra-button-modal>
    </hra-tooltip>
  </div>
	<div v-else>
		<hra-tooltip :text="$t('Plačiau')">
			<hra-button-modal :modal-props="{id: vacation.id}" modal-name="EmployeeVacationModal"
			                  :color="uiColor.default" :size="buttonSize.small">
				<hra-icon :name="iconName.info"/>
			</hra-button-modal>
		</hra-tooltip>
	</div>
</template>

<script>
import HraDialogConfirm from "@/components/ui/dialog/HraDialogConfirm";
import HraTooltip from "@/components/ui/HraTooltip";
import HraButton from "@/components/ui/button/HraButton";
import HraIcon from "@/components/ui/HraIcon";
import HraButtonModal from "@/components/ui/button/HraButtonModal";
import {mapMutations} from "vuex";
import {STATUS_APPROVE, STATUS_WAIT} from "@/store/modules/employee/vacations";
import HraConfirm from "@/components/ui/dialog/HraConfirm";
import employeeVacationWidgetService from "@/services/widgets/employeeVacationWidgetService";

export default {
  name: "EmployeeVacationTableActions",
  components: {HraConfirm, HraButtonModal, HraIcon, HraButton, HraTooltip, HraDialogConfirm},
  props: {vacation: Object, required: true},
  data: () => ({
    STATUS_WAIT,
    STATUS_APPROVE,
  }),
  methods: {
    ...mapMutations({
      abortVacation: "employee/vacations/abortVacation",
      approveDeputy: "employee/vacations/approveDeputy",
      abortDeputy: "employee/vacations/abortDeputy",
    }),
    async onAbortVacation(confirm) {
      try {
        await this.$api.employee.vacation.abort(this.vacation.id);
        this.abortVacation(this.vacation.id);
        await employeeVacationWidgetService.call(this);
        confirm.closeConfirm();
      } catch (e) {

      }
    },
  },
};
</script>
