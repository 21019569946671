<template>
    <custom-dialog :max-width="700"  has-custom-footer>
        <template #title>{{ $t("modals.EmployeeCreateContractDetailModal.title") }}</template>
        <template #content>
            <Form v-model="formData" is-dialog :form-loader-name="loaderName" @submit="onSubmit">
                <template #form>
                    <v-row>
                        <v-col cols="12">
                            <datepicker-field
                                name="date_from"
                                rules="required"
                                :date-from="today"
                                :label="$t('modals.EmployeeCreateContractDetailModal.form.dateFrom')"
                                v-model="formData.from_date"
                            />
                        </v-col>
                        <v-col cols="4">
                            <text-field
                                name="vacation_days_per_year"
                                rules="required"
                                type="number"
                                :label="$t('modals.EmployeeCreateContractDetailModal.form.vacationDaysPerYear')"
                                v-model="formData.vacation_days_per_year"
                            />
                        </v-col>
                        <v-col cols="4">
                            <text-field
                                name="work_days_per_week"
                                rules="required"
                                type="number"
                                :label="$t('modals.EmployeeCreateContractDetailModal.form.workDaysPerWeek')"
                                v-model="formData.work_days_per_week"
                            />
                        </v-col>
                        <v-col cols="4">
                            <text-field
                                name="work_hours_per_week"
                                rules="required"
                                type="number"
                                :label="$t('modals.EmployeeCreateContractDetailModal.form.workHoursPerWeek')"
                                v-model="formData.work_hours_per_week"
                            />
                        </v-col>
                    </v-row>
                </template>

                <template #buttons="{loading}">
                    <hra-button :size="buttonSize.small" @click="close">
                        <template #icon>
                            <hra-icon :name="iconName.close"/>
                        </template>
                        {{ $t("modals.EmployeeCreateContractDetailModal.button.close") }}
                    </hra-button>
                    <hra-button :color="uiColor.primary" :size="buttonSize.small" :type="buttonType.submit" :loading="loading">
                        {{ $t("modals.EmployeeCreateContractDetailModal.button.save") }}
                    </hra-button>
                </template>

                <template v-if="message" #footer-messages>
                    <form-alert v-bind="message" />
                </template>
            </Form>
        </template>
    </custom-dialog>
</template>

<script>
import {mapMutations} from "vuex";
import HraButton from "@/components/ui/button/HraButton";
import HraIcon from "@/components/ui/HraIcon";
import dialogMixin from "@/mixins/dialogMixin";
import CustomDialog from "@/components/ui/dialog/CustomDialog";
import FormMessages from "@/components/ui/form/FormMessages";
import FormAlert from "@/components/ui/form/FormAlert";
import FullRowLoader from "@/components/ui/FullRowLoader";
import Form from "@/components/ui/form/Form";
import DatepickerField from "@/domain/fields/DatepickerField";
import moment from "moment";
import TextField from "@/domain/fields/TextField";

export default {
    name: "EmployeeCreateContractDetailModal",
    mixins: [dialogMixin],
    components: {
        TextField,
        DatepickerField, Form, FullRowLoader, FormAlert, FormMessages, CustomDialog, HraIcon, HraButton},
    props: {
        employeeId: {type: [String, Number], required: true},
        editableApi: {type: Boolean, default: false},
    },
    data: () => {
        const today = moment().format("YYYY-MM-DD");

        return {
            loaderName: "Employee-Add-Contract-Detail",
            closeDialogDelay: 1000,
            message: null,
            today,
            formData: {
                from_date: today,
                vacation_days_per_year: 20,
                work_hours_per_week: 40,
                work_days_per_week: 5,
            },
        }
    },
    methods: {
        ...mapMutations({
            create: "employee/contract/details/create"
        }),
        async save(payload) {
            if (this.editableApi) {
                return await this.$api.employee.contract.details.create({
                    employee_id: this.employeeId,
                    ...payload,
                }, this.loaderName);
            }

            return {
                data: {
                    id: new Date().getTime(),
                    fromDate: payload.from_date,
                    toDate: null,
                    workDaysPerWeek: payload.work_days_per_week,
                    workHoursPerWeek: payload.work_hours_per_week,
                    vacationDaysPerYear: payload.vacation_days_per_year,
                    contractId: null,
                    employeeId: this.employeeId,
                },
            };
        },
        async onSubmit(payload) {
            try {
                const {data} = await this.save(payload);
                this.create(data);
                this.message = {type: "success", text: "Prideta"};
                this.closeWithDelay();
            } catch (e) {
            }
        }
    }
}
</script>
