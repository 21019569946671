<template>
    <div class='approval-stage' :data-status='status'>
        <div class='approval-stage__spacer'>
            <div />
            <div />
        </div>
        <div class='approval-stage__header'>
            <div />
            <div />
            <div>
                <div class='approval-stage__title'>
                    <b>
                        <slot name='title'>{{ title }}</slot>
                    </b>
                    <hra-tooltip
                        v-if='hasToolTip'
                        :text='tooltipText'
                    >
                        <ui-icon>message-info</ui-icon>
                    </hra-tooltip>
                </div>
                <div
                    v-if='stage.minToApprove != null'
                    class='approval-stage__info'
                    v-html='$t("Min. patvirtinimų sk. {count}", { count: `<b>${stage.minToApprove}</b>` })'
                />
            </div>
            <div class='approval-stage__circle'>
                <ui-icon>{{ statusIcon }}</ui-icon>
            </div>
        </div>
        <div
            class='approval-stage__employee'
            v-for='({id, user, status}) in invites'
            :key='id'
        >
            <div />
            <div />
            <div />
            <div />
            <approval-state-employee
                :status='status'
                :user='user'
                :ignore-status='ignoreStatus'
            />
        </div>
    </div>
</template>

<script>
import HraTooltip from '@/components/ui/HraTooltip.vue'
import EmployeeTableView from '@/components/ui/views/EmployeeTableView.vue'
import UiIcon from '@/domain/components/UiIcon.vue'
import ApprovalStateEmployee from '@/domain/components/ApprovalStages/ApprovalStateEmployee.vue'

export default {
    name: 'ApprovalStage',
    components: { ApprovalStateEmployee, UiIcon, EmployeeTableView, HraTooltip },
    props: {
        stage: { type: Object, required: true },
        stageIndex: { type: Number, required: true },
        ignoreStatus: { type: Boolean, default: false }
    },
    data() {
        return {
            statusIcons: {
                approved: 'ok',
                aborted: 'close',
                skip: 'restore'
            }
        }
    },
    computed: {
        title() {
            if (this.stage.title != null) {
                return this.stage.title
            }

            return {
                'deputy': this.$t('Pavadavimai'),
                'approval': this.$t('{order} tvirtinimo lygis', { order: this.stage.orderIndex ?? 0 }),
                'summary': this.$t('...')
            }[this.stage.type]
        },
        hasToolTip() {
            return !(this.stage.skipTooltip ?? false)
        },
        tooltipText() {
            if (this.stage.tooltipText != null) {
                return this.stage.tooltipText
            }

            return this.$t('Prašymai gali turėti keletą tvirtinimo lygių, priklausomai nuo<br/>konfigūracijos. Minimalus patvirtinimų skaičius apibrėžia kiek<br/>reikia patvirtinimų, kad prašymas pereitų į kitą tvirtinimo lygį.<br/>Kai prašymas esančiame lygyje surenka minimalų patvirtinimų<br/>skaičių, tampa patvirtintu arba keliauja į kitą lygį.<br/>Kai tvirtinimo lygyje, minimalus tvirtinimų skaičius mažesnis už<br/>tvirtinančių darbuotojų – surinkus pakankamai patvirtinimų iš<br/>bet kurių darbuotojų, prašymas keliauja į kitą lygį, o likę<br/>prašymai patvirtinti - tampa nebeaktyvūs.')
        },
        invites() {
            if (this.stage.users == null) {
                return this.stage.invites ?? []
            }

            return this.stage.users.map(user => {
                return {
                    id: user.id,
                    user,
                    status: null
                }
            })
        },
        status() {
            return this.stage.status ?? ''
        },
        statusIcon() {
            return this.statusIcons[this.stage.status] || ''
        }
    }
}
</script>

<style lang='scss'>
@import '@/assets/sass/core/colors';

.approval-stage {
    --main-border-color: #{$border-color-primary};
    --second-border-color: #{$border-color-primary};
    --circle-text-color: #{$white};
    --circle-bacground-color: #{$white};
    --circle-border-color: #{$border-color-primary};
    position: relative;

    &[data-status='approved'],
    &[data-status='pending'],
    &[data-status='skip'] {
        --main-border-color: #{$violet-700};
        --circle-border-color: #{$violet-700};
        --spacer-border-color: #{$violet-700};
    }

    &[data-status='skip'] {
        --circle-text-color: #{$violet-700};
    }

    &[data-status='approved'] {
        --circle-bacground-color: #{$violet-700};
        --circle-border-color: #{$violet-700};
    }

    &[data-status='aborted'] {
        --circle-bacground-color: #E7384F;
        --circle-border-color: #E7384F;
        --main-border-color: #E7384F;
    }

    &:has(+ &[data-status='approved']),
    &:has(+ &[data-status='pending']),
    &:has(+ &[data-status='skip']) {
        --second-border-color: #{$violet-700};
    }

    &:has(+ &[data-status='aborted']) {
        --second-border-color: #E7384F;
    }


    &__spacer {
        display: grid;
        grid-template-rows: 35px;
        grid-template-columns: 11px 1fr;

        & > div:nth-child(2) {
            border-left: 1px solid var(--main-border-color);
        }
    }

    &__header {
        display: grid;
        grid-template-columns: 11px 24px 1fr;
        position: relative;

        & > div:nth-child(2) {
            border-left: 1px solid var(--main-border-color);
        }
    }

    &__circle {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background: var(--circle-bacground-color);
        color: var(--circle-text-color);
        font-size: 11px;
        border: 2px solid var(--circle-border-color);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__employee {
        display: grid;
        grid-template-columns: 11px 24px 1fr;
        grid-template-rows: 10px 1fr 1fr;

        & > div:last-child {
            grid-column: 3;
            grid-row: 2 / 4;
        }

        & > div:nth-child(1),
        & > div:nth-child(2) {
            grid-row: 1 / 3;
        }

        & > div:nth-child(3),
        & > div:nth-child(4) {
            grid-row: 3 / 4;
        }

        & > div:nth-child(2) {
            border-left: 1px solid var(--main-border-color);
            border-bottom: 1px solid var(--main-border-color);
            border-radius: 0;
        }

        & > div:nth-child(4) {
            border-left: 1px solid var(--main-border-color);
        }
    }

    &__title {
        padding-top: 2px;
        color: $greyscale-600;
        font-size: 14px;
        line-height: 1;
        letter-spacing: 0.03em;
        text-transform: uppercase;

        b {
            color: $violet-700;
            margin-right: 8px;
        }
    }

    &__info {
        color: $greyscale-600;
        font-size: 14px;
        letter-spacing: 0.03em;
        padding-top: 6px;
    }

    &__employee:last-child {
        & > div:nth-child(4) {
            border-left-color: var(--second-border-color);
        }
    }

    &:last-child &__employee:last-child {
        & > div:nth-child(2) {
            border-radius: 0 0 0 5px;
        }

        & > div:nth-child(4) {
            border-left: 0;
        }
    }

    &:first-child &__spacer {
        display: none;
    }
}
</style>