import customApi from '@/plugins/customApi'

class EmployeeAutocompleteService {

    constructor(params = {}) {
        this.params = {
            withDepartment: false,
            withDuty: false,
            ...params
        }
    }

    setParams(params) {
        this.params = {
            ...this.params,
            ...params
        }
    }

    async find(tag, value, ignoreIds = null) {
        const api = customApi(tag)

        const employees = Array.isArray(value)
            ? await api.request(api.factories.employee.autocomplete({ ids: value, ignoreIds, ...this.params }))
            : await api.request(api.factories.employee.autocomplete({ query: value, ignoreIds, ...this.params }))

        return employees.map(employee => ({
            value: employee.id,
            text: employee.name,
            employee
        }))
    }

    findWithIgnoreIds(ignoreIds) {
        return async (tag, value) => await this.find(tag, value, ignoreIds)
    }

}

export default new EmployeeAutocompleteService