<template>
    <v-menu
        offset-y
        nudge-bottom='28'
        nudge-left='-2'
        max-width='80'
        origin='bottom right'
        content-class='elevation-0 header__lang-navigation'
    >
        <template v-slot:activator='{ on, attrs }'>
            <div
                class='header__lang'
                v-bind='attrs'
                v-on='on'
            >
                <div class='header__lang-content'>
                    <img :src='currentLanguage.image' :alt='currentLanguage.locale' />
                    <h6>{{ currentLanguage.text }}</h6>
                    <hra-icon
                        class='pl-1'
                        :name='iconName.down'
                    />
                </div>
            </div>
        </template>

        <template v-for='language in languages'>
            <a
                @click.prevent='onChangeLocale(language.locale)'
            >
                <img :src='language.image' :alt='language.locale' />
                <span>{{ language.text }}</span>
            </a>
            <div v-if='!language.isLast' class='divider' />
        </template>
    </v-menu>
</template>

<script>
import Vue from 'vue'
import Avatar from '@/components/ui/Avatar'
import HraIcon from '@/components/ui/HraIcon'
import i18n from '@/plugins/i18n'

export default {
    name: 'HeaderLang',
    components: { HraIcon, Avatar },
    props: {},
    data() {
        return {
            active: false,
            availableLanguages: [
                { locale: 'lt', text: 'LT', image: require('@/assets/images/languages/lang-lt.png'), current: false },
                { locale: 'en', text: 'EN', image: require('@/assets/images/languages/lang-en.png'), current: false }
                // { locale: 'ru', text: 'RU', image: require('@/assets/images/languages/lang-ru.png'), current: false }
            ]
        }
    },
    computed: {
        currentLocale() {
            return i18n.locale
        },
        currentLanguage() {
            return this.availableLanguages.find(({ locale }) => this.currentLocale === locale)
        },
        languages() {
            let languages = this.availableLanguages.filter(({ locale }) => this.currentLocale !== locale)
            languages[languages.length - 1].isLast = true

            return languages
        }
    },
    methods: {
        onChangeLocale(locale) {
            localStorage.setItem('bond_locale', locale)
            window.location.reload()
        }
    }
}
</script>
