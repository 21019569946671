<template>
    <div class='approval-skip-stages'>
        <approval-stages
            :stages='stages'
            hide-progress
        />
    </div>
</template>

<script>
import EmployeeTableView from '@/components/ui/views/EmployeeTableView.vue'
import ApprovalStages from '@/domain/components/ApprovalStages/ApprovalStages.vue'

export default {
    name: 'ApprovalSkipStages',
    components: { ApprovalStages, EmployeeTableView },
    props: {
        status: { type: String, required: true },
        user: { type: Object, default: null },
        deputies: { type: Array, default: [] }
    },
    computed: {
        statusTitle() {
            return {
                'aborted_by_admin': this.$t('Atšaukta administratoriaus'),
                'aborted_by_system': this.$t('Atšaukta automatiškai'),
                'aborted_by_owner': this.$t('Atšaukta darbuotojo'),
                'approved_by_admin': this.$t('Patvirtinta administratoriaus'),
                'approved_by_owner': this.$t('Patvirtinta darbuotojo'),
                'approved_by_leader': this.$t('Patvirtinta vadovo'),
                'created_by_admin': this.$t('Sukurtė administratoriaus')
            }[this.status] ?? '-'
        },
        statusTooltip() {
            return {
                'aborted_by_system': this.$t('Bond HR sistema automatiškai atšaukia nepatvirtintus prašymus suėjus pirmai įvykio dienai (arba kaip nustatyta konfigūracijoje).'),
            }[this.status] ?? null
        },
        stages() {
            let stageDeputies = []

            const status = {
                'aborted_by_admin': 'aborted',
                'aborted_by_system': 'aborted',
                'aborted_by_owner': 'aborted',
                'approved_by_leader': 'approved',
                'approved_by_admin': 'approved',
                'approved_by_owner': 'approved',
                'created_by_admin': 'approved'
            }[this.status] ?? ''

            if (this.deputies != null && this.deputies.length > 0) {
                stageDeputies = [
                    {
                        id: 1,
                        type: 'deputy',
                        status,
                        title: this.$t('Pavaduojantys'),
                        skipTooltip: true,
                        users: this.deputies
                    }
                ]
            }

            return [
                ...stageDeputies,
                {
                    id: 2,
                    type: 'approval',
                    status,
                    title: this.statusTitle,
                    skipTooltip: this.statusTooltip == null,
                    tooltipText: this.statusTooltip,
                    users: [
                        this.user ?? { id: 1, name: this.$t('HR Sistema') }
                    ]
                }
            ]
        }
    }
}
</script>

<style lang='scss' scoped>
@import '@/assets/sass/core/colors';

.approval-skip-stages {
    &__status {
        background-color: #FAF9FF;
        border: 1px solid $border-color-primary;
        border-radius: 5px;
        position: relative;
        padding: 7px 18px;
        display: flex;
        width: fit-content;
        flex-direction: column;
    }
}
</style>