import { render, staticRenderFns } from "./HraTableDefaultEmpty.vue?vue&type=template&id=4be7791e&"
import script from "./HraTableDefaultEmpty.vue?vue&type=script&lang=js&"
export * from "./HraTableDefaultEmpty.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports