<template>
    <div v-if='isProfile' class='d-flex flex-column px-9 pb-8' style='width: 100%'>
        <textarea-field
            :label="$t('Apie mane')"
            :disabled='!descriptionEdit'
            v-model='description'
            rows='4'
        />
        <div class='d-flex justify-end gap-3'>
            <template v-if='descriptionEdit'>
                <hra-button no-background :color='uiColor.default' custom-class='pa-0'
                            @click='onCancelEditDescription'>{{ $t('Atšaukti') }}
                </hra-button>
                <hra-button no-background :color='uiColor.primary' custom-class='pa-0'
                            @click='onSaveDescription'>{{ $t('Išsaugoti') }}
                </hra-button>
            </template>
            <template v-else>
                <hra-button no-background :color='uiColor.primary' custom-class='pa-0'
                            @click='onEditDescription'>{{ $t('Redaguoti') }}
                </hra-button>
            </template>
        </div>
    </div>
    <div v-else-if='description' class='px-9 pb-8 text-center hra-text--13 hra-text--greyscale-600'>
        {{ description }}
    </div>
</template>

<script>
import TextareaField from '@/domain/fields/TextareaField'
import HraButton from '@/components/ui/button/HraButton'
import { mapGetters } from 'vuex'

export default {
    name: 'EmployeeProfileDescription',
    components: { HraButton, TextareaField },
    props: {
        isProfile: { type: Boolean, default: false },
        isCreate: { type: Boolean, default: false },
        isEdit: { type: Boolean, default: false }
    },
    data() {
        const changeDescriptionTag = 'EMPLOYEE_CHANGE_DESCRIPTION_TAG'

        return {
            changeDescriptionTag,
            descriptionEdit: false,
            description: ''
        }
    },
    computed: {
        ...mapGetters({
            user: 'employeeStore/user',
            userDescription: 'employeeStore/userDescription'
        })
    },
    watch: {
        userDescription: {
            handler(description) {
                this.description = description
            },
            immediate: true
        }
    },
    methods: {
        onCancelEditDescription() {
            this.descriptionEdit = false
            this.description = this.userDescription
        },
        async onSaveDescription() {
            const result = await this.$store.dispatch('employeeStore/updateUser', {
                tag: this.changeDescriptionTag,
                id: this.user.id,
                payload: { description: this.description }
            })

            if (!result) {
                return
            }

            this.descriptionEdit = false
        },
        onEditDescription() {
            this.descriptionEdit = true
        }
    }
}
</script>