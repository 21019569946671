<template>
    <div class='DepartmentVacationCalendarTable' ref='container'>
        <table ref='table'>
            <thead ref='header'>
            <tr>
                <th :colspan='visibleBalance ? 2 : 1'
                    class='DepartmentVacationCalendarTable__header'
                    ref='sidebar'>
                    <div class='px-5'><b>{{ $t('Darbuotojai') }}</b></div>
                </th>
                <th
                    class='DepartmentVacationCalendarTable__header'
                    v-for='({colspan, name}, key) in headerMonths'
                    :colspan='colspan'
                    :key='key'
                >
                    <div class='DepartmentVacationCalendarTable__header-month'>
                        <hra-icon
                            v-if='key === 0'
                            class='DepartmentVacationCalendarTable__header-month-prev'
                            name='arrow-left'
                            size='10px'
                            @click='onPrevFromDate'
                        />
                        <span>{{ name }}</span>
                        <hra-icon
                            v-if='key + 1 === headerMonthCount'
                            class='DepartmentVacationCalendarTable__header-month-next'
                            name='arrow-right'
                            size='10px'
                            @click='onNextFromDate'
                        />
                    </div>
                </th>
            </tr>
            <tr>
                <th class='DepartmentVacationCalendarTable__employee'>
                    <select-field
                        ref='selectEmployees'
                        class='px-2'
                        :placeholder="$t('Pasirinkti darbuotojus')"
                        name='employeeIds'
                        multiple
                        filtered
                        :options='employeeOptions'
                        v-model='filter.employeeIds'
                        no-border
                        is-sticky
                    />
                </th>
                <th v-if='visibleBalance' class='DepartmentVacationCalendarTable__balance'><b>{{
                        $t('Atostogų likutis')
                    }}</b><br />{{ filter.calculateDate }}
                </th>
                <th
                    class='DepartmentVacationCalendarTable__day'
                    v-for='({day, dayName, endMonth, isWeekend, date}, key) in headerDays'
                    :key='key'
                    :class="{
						'DepartmentVacationCalendarTable__day--end-month': endMonth,
						'DepartmentVacationCalendarTable__day--weekend': isWeekend,
						'DepartmentVacationCalendarTable__day--holiday': !!holidays[date],
					}"
                >
                    <div class='DepartmentVacationCalendarTable__day-date'>{{ day }}</div>
                    <div class='DepartmentVacationCalendarTable__day-name'>{{ dayName }}</div>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td :colspan='visibleBalance ? 2 : 1' class='DepartmentVacationCalendarTable__hidden' />
                <td
                    class='DepartmentVacationCalendarTable__hidden'
                    v-for='({isWeekend, date}, key) in headerDays'
                    :key='key'
                    :class="{
						'DepartmentVacationCalendarTable__hidden--weekend': isWeekend,
						'DepartmentVacationCalendarTable__hidden--holiday': !!holidays[date],
					}"
                />
            </tr>
            <department-vacation-calendar-row
                v-for='employee in employees'
                :key='employee.id'
                :employee='employee'
                :start-date='fromDate'
                :day-count='maxDays'
            />
            </tbody>
        </table>
        <vacation-legend
            by-user-settings
            class='DepartmentVacationCalendar__legend'
            :class="{'DepartmentVacationCalendar__legend--leader': visibleBalance}"
        />
    </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import SelectField from '@/domain/fields/SelectField'
import EmployeeTableView from '@/components/ui/views/EmployeeTableView'
import DepartmentVacationCalendarRow from '@/domain/departmentVacations/components/DepartmentVacationCalendarRow'
import HraIcon from '@/components/ui/HraIcon'
import VacationLegend from '@/domain/employeeVacations/components/VacationLegend'
import i18n from '@/plugins/i18n'

export default {
    name: 'DepartmentVacationCalendarTable',
    components: { VacationLegend, HraIcon, DepartmentVacationCalendarRow, EmployeeTableView, SelectField },
    data() {
        return {
            yearEnd: `${(new Date()).getFullYear()}-12-31`,
            maxDays: 0,
            colSize: 33,
            headerMonths: [],
            headerDays: []
        }
    },
    computed: {
        ...mapGetters({
            isLeader: 'departmentVacationsStore/isLeader',
            isAdmin: 'departmentVacationsStore/isAdmin',
            employees: 'departmentVacationsStore/employees',
            employeeOptions: 'departmentVacationsStore/employeeOptions',
            // holidays: 'departmentVacationsStore/holidays',
            holidays: 'calendar/eventHolidays',
            filter: 'departmentVacationsStore/filter',
            fromDate: 'departmentVacationsStore/fromDate'
        }),
        headerMonthCount() {
            return this.headerMonths.length
        },
        visibleBalance() {
            // return this.isLeader || this.isAdmin
            return true
        }
    },
    inject: ['service'],
    watch: {
        fromDate: {
            handler(value) {
                this.service.loadData(value, moment(value).add(this.maxDays, 'days').format('YYYY-MM-DD'))
                this.generateHeaders()
                this.getHolidays()
            }
        }
    },
    created() {
        moment.locale(i18n.locale, {
            week: {
                dow: 1
            }
        })
    },
    mounted() {
        this.generateHeaders()
        this.getHolidays()
        const rects = this.$refs.header.getClientRects()[0]

        // const stickyObserver = new IntersectionObserver(
        // 	(entries) => {
        // 		entries.forEach(entry => {
        // 			console.debug(entry.intersectionRatio);
        // 			/* Here's where we deal with every intersection */
        // 		});
        // 	},
        // 	{
        // 		rootMargin: `${-41}px`,
        // 		threshold: [0,1],
        // 	}
        // );
        //
        // console.debug(rects);
        //
        // stickyObserver.observe(this.$refs.header);


        // console.debug(rects);

        // const stickyObserver = new IntersectionObserver(
        // 	(entries) => {
        // 		entries.forEach(entry => {
        // 			console.debug(entry.intersectionRatio);
        // 			/* Here's where we deal with every intersection */
        // 		});
        // 	},
        // 	{
        // 		rootMargin: `${(rects.top)}px`,
        // 		// rootMargin: '-40px',
        // 		threshold: 0.8,
        // 	}
        // );
        //
        // stickyObserver.observe(this.$refs.table);
    },

    beforeDestroy() {
    },

    methods: {
        getHolidays() {
            const startDate = moment(this.fromDate)
            const endDate = startDate.clone().add(this.maxDays, 'days')
            const years = [startDate.year(), endDate.year()]

            for (let year = Math.min(...years);year <= Math.max(...years); year++) {
                this.$store.dispatch('calendar/fetch', { year })
            }
        },
        fixPosition() {
            const uid = this.$refs.selectEmployees.$refs.select._uid
            const menu = document.getElementById(`list-${uid}`)
            if (!menu) return

            // console.debug(menu.parentElement.s);

            // console.debug("fix...");
            // const input = this.$refs.selectEmployees.$refs.select.$el.getBoundingClientRect();
            // menu.parentElement.style.top = input.bottom - 50 + 'px';

            menu.parentElement.style.display = 'fixed'
        },
        generateHeaders() {
            const { width: tableWidth } = this.$refs.container.getBoundingClientRect()
            const { width: sidebarWidth } = this.$refs.sidebar.getBoundingClientRect()
            this.maxDays = Math.floor((tableWidth - sidebarWidth) / this.colSize)

            let startDate = moment(this.fromDate)
            const endDate = startDate.clone().add(this.maxDays, 'days')

            let month = null
            let monthEndDate = null

            this.headerMonths = []
            this.headerDays = []

            while (startDate < endDate) {
                if (month !== startDate.month()) {
                    monthEndDate = startDate.clone().endOf('month').date()
                    month = startDate.month()

                    this.headerMonths.push({
                        colspan: monthEndDate - startDate.date() + 1,
                        name: startDate.format('MMMM YYYY')
                    })
                }

                this.headerDays.push({
                    day: startDate.format('DD'),
                    dayName: startDate.format('dd'),
                    endMonth: startDate.date() === monthEndDate,
                    isWeekend: [5, 6].includes(startDate.weekday()),
                    date: startDate.format('YYYY-MM-DD')
                })

                startDate.add(1, 'day')
            }
        },
        onPrevFromDate() {
            this.$store.dispatch('departmentVacationsStore/prevFromDate', this.maxDays - 1)
        },
        onNextFromDate() {
            this.$store.dispatch('departmentVacationsStore/nextFromDate', this.maxDays - 1)
        }
    }
}
</script>