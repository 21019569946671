<template>
  <custom-dialog :max-width="521">
    <template #title>{{ $t("Pašalinti darbuotoją iš skyriaus") }}</template>
    <template #content>
      <full-row-loader v-if="isLoading"/>
      <Form
          v-else
          ref="form"
          :form-loader-name="formLoaderName"
          is-dialog
          v-model="formData"
          @submit="onSubmit"
      >
        <template #header-messages>
          <form-alert type="info" :text="$t('Norėdami pašalinti darbuotoją iš skyriaus, turite jam priskirti kitą.')" />
        </template>

        <template #form>
          <v-row>
            <v-col cols="12">
              <select-field
                  name="department_id"
                  class="my-3 full-width"
                  :label="$t('Skyrius')"
                  :options="departmentOptions"
                  v-model="formData.department_id"
              />
            </v-col>
          </v-row>
        </template>

        <template #buttons="{loading}">
          <hra-button :color="uiColor.default" :disabled="loading" @click="close">
            <hra-icon :name="iconName.close"/>
            {{ $t("Uždaryti") }}
          </hra-button>

          <hra-button :color="uiColor.primary" :loading="loading" :type="buttonType.submit">
            <hra-icon :name="iconName.ok"/>
            {{ $t("Pasirinkti") }}
          </hra-button>
        </template>
      </Form>
    </template>
  </custom-dialog>
</template>

<script>
import Dialog from "@/components/ui/dialog/Dialog";
import CustomDialog from "@/components/ui/dialog/CustomDialog";
import dialogMixin from "@/mixins/dialogMixin";
import FullRowLoader from "@/components/ui/FullRowLoader";
import FormTextField from "@/components/ui/form/FormTextField";
import FormColors from "@/components/ui/form/FormColors";
import FormEmployeeAutocomplete from "@/components/ui/form/FormEmployeeAutocomplete";
import HraButton from "@/components/ui/button/HraButton";
import HraIcon from "@/components/ui/HraIcon";
import Form from "@/components/ui/form/Form";
import {mapGetters, mapMutations} from "vuex";
import HraAlert from "@/components/ui/HraAlert";
import SelectField from "@/domain/fields/SelectField";
import FormAlert from "@/components/ui/form/FormAlert";

export default {
  name: "CompanyStructureDeleteEmployeeModal",
  components: {
    FormAlert,
    SelectField,
    HraAlert,
    Form,
    HraIcon,
    HraButton,
    FormEmployeeAutocomplete,
    FormColors, FormTextField, FullRowLoader, CustomDialog, Dialog
  },
  mixins: [dialogMixin],
  props: {
    employeeId: {type: [String, Number], required: true,},
    departmentId: {type: [String, Number], required: true,},
  },
  data: () => ({
    message: null,
    loaderName: "CompanyStructure-Employee-Remove-Modal",
    formLoaderName: "CompanyStructure-Employee-Remove-Modal-Form",
    formData: {},
  }),
  created() {
    this.formData.department_id = this.departmentId;
  },
  computed: {
    ...mapGetters({
      departmentOptions: "companyStructure/department/options",
    }),
  },
  methods: {
    ...mapMutations({
      addEmployee: "companyStructure/department/addEmployee",
      removeEmployee: "companyStructure/department/removeEmployee",
      changeEmployeesCount: "companyStructure/department/changeEmployeesCount",
    }),
    async onSubmit(payload) {

      try {
        const {data} = await this.$api.employee.department
            .change(this.employeeId, payload, this.formLoaderName);

        this.removeEmployee({id: this.departmentId, employeeId: this.employeeId});
        this.addEmployee({
          id: this.formData.department_id, row: {id: data.id, employee: data}
        });
        this.changeEmployeesCount({id: this.departmentId, count: -1});
        this.changeEmployeesCount({id: this.formData.department_id, count: 1});

        this.message = {type: "success", text: this.$t("Skyrius sekmingai buvo pakeistas")};

        this.closeWithDelay();
      } catch (e) {
      }
    },
  },
};
</script>
