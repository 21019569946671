import DebuggerPage from "@/domain/debugger/pages";
import DebuggerVacationsPage from "@/domain/debugger/pages/DebuggerVacations";
import DebuggerReportsPage from "@/domain/debugger/pages/Reports";
import {permissionOnlyAdmin, permissionsWildCard} from "@/utils/permissions";
import DebuggerLayout from "@/domain/debugger/layouts/DebuggerLayout";

export const index = "DebuggerPage";
export const debuggerVacations = "DebuggerVacationsPage";
export const debuggerReports = "DebuggerReportsPage";

export default [
  {
    path: "/debugger",
    component: DebuggerLayout,
    children: [
      {name: index, path: "", component: DebuggerPage, meta: {permissions: permissionOnlyAdmin}},
      {
        name: debuggerVacations,
        path: "vacations",
        component: DebuggerVacationsPage,
        meta: {permissions: permissionOnlyAdmin}
      },
      {name: debuggerReports, path: "reports", component: DebuggerReportsPage, meta: {permissions: permissionOnlyAdmin}}
    ],
  }
]