import Vue from 'vue'

export const SET_USER = 'SET_USER'
export const SET_FETCHING_USER_PROMISE = 'SET_FETCHING_USER_PROMISE'
export const CHANGE_AVATAR = 'CHANGE_AVATAR'
export const SET_IMPORTED = 'SET_IMPORTED'
export const SET_IS_LEADER = 'SET_IS_LEADER'
export const SET_IS_SUB_LEADER = 'SET_IS_SUB_LEADER'
export const SET_SETTINGS = 'SET_SETTINGS'

export default ({
    [SET_IMPORTED]: (state, payload) => {
        state.imported = payload
    },
    [SET_IS_LEADER]: (state, payload) => {
        state.isLeader = payload
    },
    [SET_IS_SUB_LEADER]: (state, payload) => {
        state.isSubLeader = payload
    },
    [SET_USER]: (state, payload) => {
        state.user = payload
        state.fetchingUserPromise = null
    },
    [SET_FETCHING_USER_PROMISE]: (state, payload) => {
        state.fetchingUserPromise = payload
    },
    [SET_SETTINGS]: (state, payload) => {
        state.settings = payload
    },
    [CHANGE_AVATAR]: (state, payload) => {
        Vue.set(state.user, 'avatarSrc', payload)
    }
})