import moment from "moment";
import { EVENT_HOLIDAY_TYPE_YEARLY } from '@/utils/default'

export const getDefaultState = () => ({
  asAdmin: false,
  minDate: null,
  currentEventAborted: false,
  sendInviteForEmployeeAndLeader: false,
  currentEvent: {
    id: null,
    type: EVENT_HOLIDAY_TYPE_YEARLY,
    start: null,
    end: null,
    payType: null,
    deputies: [],
    attachment: null,
    message: ''
  },
  events: [],
  additionalHolidays: [],
  user: null,
  actions: {
    abort: false,
    change: false,
    create: false,
    update: false
  },
  approvalsTree: null
})

export default () => getDefaultState();