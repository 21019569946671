<template>
    <li :class='{expand: isVisible}'>
        <span @click='toggle'>{{ subTitle }}</span>
        <span @click='toggle'>{{ title }}</span>
        <ui-icon @click='toggle'>{{ isVisible ? 'up' : 'down' }}</ui-icon>

        <ul v-if='isVisible' class='dtv-list-sub-links'>
            <dtv-list-link-item
                v-for='page in child'
                :key='page.title'
                :title='`${page.articleNumber} straipsnis. ${page.title}`'
                :page='page'
            />
        </ul>
    </li>
</template>

<script>
import DtvListLinkItem from '@/domain/dtv/components/DtvListLink.vue'
import UiIcon from '@/domain/components/UiIcon.vue'

export default {
    name: 'DtvListSubTagItem',
    components: { UiIcon, DtvListLinkItem },
    props: {
        subTitle: { type: String, required: true },
        title: { type: String, required: true },
        child: { type: Array, default: () => [] }
    },
    data() {
        return {
            isVisible: false
        }
    },
    methods: {
        toggle() {
            this.isVisible = !this.isVisible
        }
    }
}
</script>