<template>
	<hra-table
		class="pa-3"
		:headers="headers"
		:rows="rows"
	>

	</hra-table>
</template>

<script>
import HraTable from "@/components/ui/table/HraTable.vue";
import {mapGetters} from "vuex";

export default {
	name: "ContractsTable",
	components: {HraTable},
	props: {
		timeCardNumber: {type: String, required: true},
	},
	data() {
		return {
			headers: [
				{name: "timeCardNumber", text: this.$t("Tabelio nr. *"), style: {minWidth: "200px"}},
				{name: "workEmail", text: this.$t("El. paštas *"), style: {minWidth: "200px"}},
				{name: "departments", text: this.$t("Skyriai *"), style: {minWidth: "200px"}},
				{name: "duties", text: this.$t("Pareigos *"), style: {minWidth: "200px"}},
				{name: "number", text: this.$t("Darbo sutarties nr. *"), style: {minWidth: "200px"}},
				{name: "date", text: this.$t("Darbo sutarties data *"), style: {minWidth: "200px"}},
				{name: "workStartAt", text: this.$t("Darbo pradžios data *"), style: {minWidth: "200px"}},
				{name: "workHoursPerWeek", text: this.$t("Darbo laiko norma (val. per savaitę) *"), style: {minWidth: "200px"}},
				{name: "workDaysPerWeek", text: this.$t("Darbo dienų skaičius per savaitę *"), style: {minWidth: "200px"}},
				{name: "vacationDaysPerYear", text: this.$t("Atostogų skaičius per metus (d.d.) *"), style: {minWidth: "200px"}},
				{name: "vacationBalanceDate", text: this.$t("Atostogų likutis (data) *"), style: {minWidth: "200px"}},
				{name: "vacationBalance", text: this.$t("Atostogų likutis *"), style: {minWidth: "200px"}},
			],
		}
	},
	computed: {
		rows() {
			return this.$store.getters["setupStore/importEmployeeData"](this.timeCardNumber, 'contracts');
		},
	},
}
</script>