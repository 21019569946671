<template>
    <router-link
        v-if="hasPermissions"
        :class="[buttonClass, $attrs.class]"
        :to="to"
        :disabled="disabled"
        v-on="$listeners"
    >
        <slot name="icon"/>
        <slot />
    </router-link>
</template>

<script>
import hraButtonMixin from "@/mixins/hraButtonMixin";

export default {
    name: "HraButtonLink",
    mixins: [hraButtonMixin],
    props: {
        to: {type: [String, Object], required: true}
    },
}
</script>
