import { generateUuid } from '@/utils/default'
import Vue from 'vue'

const state = () => ({
    rows: [],
    rowErrors: []
})

const getters = {
    rows: state => {
        return state.rows.map(row => ({
            ...row,
            errors: state.rowErrors.filter((error) => error.id === row.id)
        }))
    }
}

const actions = {
    setRowErrors({ state }, payload) {
        Vue.set(state, 'rowErrors', payload.map((error) => ({
            ...error,
            id: state.rows[error.key].id ?? null
        })))
    },
    setRows({ state}, payload) {

        state.rows = [
            ...state.rows,
            ...payload.map(row => ({
                id: generateUuid(),
                ...row
            }))
        ]
    },
    createNewRow({state}) {
        state.rows.push({
            id: generateUuid(),
            createdAt: null,
            code: null,
            userName: null,
            personalCode: null,
            personalSDSeries: null,
            personalSDNumber: null,
            createdAt2: null,
            type: null,
            noteSeries: null,
            noteNumber: null,
            start: null,
            end: null,
            sickType: null,
            filledInPolicyholderInfo: false,
            policyholderNeedsToPay: false,
            noteDeleted: false,
            submittedNPSD: false,
            user: null
        })
    },
    updateRowColumn({ state }, { rowId, column, value }) {
        const index = state.rows.findIndex(row => row.id === rowId)

        if (index === -1) {
            return
        }

        Vue.set(state.rows[index], column, value)
    },
    deleteRow({state}, rowId) {
        const index = state.rows.findIndex(row => row.id === rowId)

        if (index === -1) {
            return
        }

        // Vue.set(state, 'rowErrors', state.rowErrors.filter((error) => error.id !== state.rows[index].id))
        state.rowErrors = state.rowErrors.filter((error) => error.id !== state.rows[index].id)
        state.rows.splice(index, 1)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions
}