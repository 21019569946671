<template>
	<validation-provider
		tag="div"
		class="SelectField"
		immediate
		:rules="rules"
		:name="name"
		v-slot="{errors: validationErrors}"
	>
		<read-only-field v-if="readOnly" :invalid="!!validationErrors.length" :value="viewValue"/>
		<base-field v-else :invalid="!!validationErrors.length" ref="field">
            <template #label-append>
                <slot name="label-append"/>
            </template>
			<v-autocomplete
				ref="select"
				:attach="attach"
				:name="name"
				:value="value"
				:readonly="hasDisabled"
				:items="items"
				:multiple="multiple"
				solo
				flat
				hide-details
				:single-line="singleLine"
				:chips="multiple"
				:deletable-chips="!hasDisabled"
				:menu-props="menuProps"
				:search-input.sync="searchText"
				:error="!!validationErrors.length"
				:clearable="clearable"
				class="field SelectField__field"
				:class="{...fieldClasses, ['invalid']: !!validationErrors.length}"
				:filter="filter"
				@focus="onOpenMenu"
				@blur="onCloseMenu"
				@change="onChangeSelect"
			>

				<template #prepend-inner>
					<div v-if="isEmpty" class="SelectField__placeholder">{{ selectPlaceholder }}</div>
					<loaders :listen="initTag"/>
				</template>

				<template #prepend-item>
					<div v-if="filtered" class="SelectField__find">
						<v-text-field
							solo
							flat
							hide-details
							:placeholder="$t('ieškoti...')"
							v-model="searchText"
							autofocus
						>
							<template #append>
								<i class="icon-find"/>
							</template>
						</v-text-field>
					</div>
				</template>

				<template #selection="{parent, item}">
					<v-chip v-if="multiple" :close="!hasDisabled" @click:close="() => parent.onChipInput(item)">
						<span>{{ item.text }}</span>
					</v-chip>
					<div v-else class="py-3">
						<slot name="selection" :item="item">
							<div v-html="item.text"/>
						</slot>
					</div>
				</template>

				<template #item="{item, on, attrs}">
					<slot name="item" :item="item" :on="on" :attrs="attrs">
						<v-list-item
							:class="{hasParent: item.parent != null}"
							v-on="on"
							v-bind="attrs"
						>
							<v-list-item-action v-if="multiple">
								<v-simple-checkbox/>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title v-html="maskListItem(item.text)"/>
							</v-list-item-content>
						</v-list-item>
					</slot>
				</template>

				<template #append>
					<div class="d-flex gap-3 align-center">
						<div v-if="multiple && !!hiddenChips" class="chip-counter">
							{{ hiddenChips }}
						</div>
						<hra-icon v-if="hasArrow" :name="iconName.down" class="select-arrow clickable"/>
						<editable-field-actions/>
					</div>
				</template>


				<template #no-data>
					<loaders :listen="dynamicTag" class="d-flex align-center justify-center pa-3"/>
					<message-alert v-if="!hasDynamicLoader" id="select-field-empty" type="error" :message="$t('Rezultatų nėra')"
					               no-border-radius/>
				</template>

			</v-autocomplete>
			<div :id="idName" ref="menu" :style="{position: 'relative', width: '100%', zIndex: 90}"/>
		</base-field>
        <div v-if='!!validationErrors.length > 0' class='hra-text--red mt-1'>
            <div v-for='error in validationErrors'>{{ error }}</div>
        </div>
        <div v-if='!!errors.length > 0' class='hra-text--red mt-1'>
            <div v-for='error in errors'>{{ error }}</div>
        </div>
	</validation-provider>
</template>

<script>
import _ from "lodash";
import baseFieldMixin from "@/domain/fields/mixins/baseFieldMixin";
import EditableFieldActions from "@/domain/fields/components/EditableFieldActions";
import BaseField from "@/domain/fields/components/BaseField";
import ReadOnlyField from "@/domain/fields/components/ReadOnlyField";
import HraIcon from "@/components/ui/HraIcon";
import MessageAlert from "@/domain/messages/components/MessageAlert";
import Loader from "@/domain/loaders/components/Loader";
import Loaders from "@/domain/loaders/Loaders";
import {generateUuid} from "@/utils/default";
import {replaceString} from '@/utils/replaceString'


export default {
	name: "SelectField",
	components: {Loaders, Loader, MessageAlert, HraIcon, EditableFieldActions, BaseField, ReadOnlyField},
	mixins: [baseFieldMixin],
	props: {
		isSticky: {type: Boolean, default: false},
		multiple: {type: Boolean, default: false},
		filtered: {type: Boolean, default: false},
		clearable: {type: Boolean, default: false},
		singleLine: {type: Boolean, default: true},
		optionsService: {type: Function, default: null},
		options: {type: Array, default: () => ([])},
        errors: {type: Array, default: () => []},
	},
	data() {
		const time = (new Date()).getTime();

		return {
			scrollPosition: null,
			menuProps: {
				closeOnClick: false,
				closeOnContentClick: false,
				disableKeys: true,
				openOnClick: false,
				offsetY: true,
				offsetOverflow: true,
				nudgeLeft: 1,
				nudgeTop: -5,
			},
			attach: false,
			idName: generateUuid(true),
			hiddenSelectCount: 0,
			searchText: "",
			items: [],
			isEmpty: true,
			loading: false,
			initLoading: false,
			timeout: null,

			initTag: `SELECT_FIELD_INIT_TAG_${time}`,
			dynamicTag: `SELECT_FIELD_DYNAMIC_TAG_${time}`,

			parents: {},
			child: {}
		};
	},
	watch: {
		readOnly: {
			handler: function (value, oldValue) {
				if (value !== oldValue && !value) {
					this.init();
				}
			},
		},
		value: {
			handler: function (newValue, oldValue) {
				this.isEmpty = Array.isArray(newValue)
					? newValue.length === 0
					: newValue == null;

				if (!_.eq(newValue, oldValue)) {
					this.getDynamicOptions(newValue);
				}
			},
			immediate: true,
		},
		options: {
			handler: function (value) {
				this.parents = {};
				this.child = {};

				value.forEach(item => {
					if (item.parent) {
						this.parents[item.value] = item.parent;
						if (this.child[item.parent] == null) {
							this.child[item.parent] = [];
						}
						this.child[item.parent].push(item.value);
					}
				});

				this.items = _.cloneDeep(value);
			},
			immediate: true,
		},
		searchText: {
			handler: function (value) {
				this.isDynamic && this.querySelections(value);
			},
		},
		hiddenChips: {
			handler: function () {
				this.calculateHiddenChips();
			},
		}
	},
	mounted() {
		!this.readOnly && this.init();
	},
	computed: {
		viewValue() {
			if (this.value == null) {
				return null;
			}

			if (Array.isArray(this.value)) {
				if (this.value.length === 0) {
					return null;
				}

				return this.items.filter(item => this.value.includes(item.value)).map(item => item.text).join(",");
			}

			return this.items.find(item => item.value === this.value)?.text ?? null;
		},
		hasDynamicLoader() {
			return this.$store.getters["loadersStore/hasLoading"](this.dynamicTag);
		},
		isDynamic() {
			return this.optionsService != null;
		},
		selectPlaceholder() {
			return this.placeholder ?? this.$t('Pasirinkti');
		},
		hiddenChips() {
			if (this.hiddenSelectCount < 1) {
				return "";
			}

			return `+${this.hiddenSelectCount}`;
		},
		hasArrow() {
			if (this.editable) {
				return this.fieldEditing;
			}

			return !this.hasDisabled;
		}
	},
	methods: {
		init() {
			this.$nextTick(() => {
				this.calculateHiddenChips();
			});
		},
		onOpenMenu() {
			if (this.isSticky) {
				this.attach = `[id=${this.idName}]`;
				this.menuProps.nudgeLeft = 0;
				this.menuProps.nudgeTop = 36;
			}
			const {width} = this.$refs.field.$el.getBoundingClientRect();
			this.menuProps.minWidth = width;
			this.menuProps.maxWidth = width;
		},
		onCloseMenu() {
			this.searchText = "";
		},
		onChangeSelect(value) {
			// const changeValues = value.filter(({value}) => value != null).map(({value}) => value);

			this.onChange(value);
			this.calculateHiddenChips();
		},
		async querySelections(value) {
			if (value == null) {
				return;
			}

			await this.$store.dispatch("loadersStore/showLoader", this.dynamicTag);
			clearTimeout(this.timeout);
			this.timeout = setTimeout(async () => {
				const currentItem = this.value && this.items.find(item => item.value === this.value);
				this.items = await this.optionsService(null, value);
				currentItem && this.items.push(currentItem);
				await this.$store.dispatch("loadersStore/hideLoader", this.dynamicTag);
			}, 500);

			return true;
		},
		startEdit() {
			this.calculateHiddenChips();
		},
		afterAbort() {
			this.$refs.select.blur();
			this.calculateHiddenChips();
		},
		afterSave() {
			this.$refs.select.blur();
			this.calculateHiddenChips();
		},
		async getDynamicOptions(value) {
			if (!this.isDynamic) {
				return;
			}

			let values = Array.isArray(value) ? value : value == null ? [] : [value];
			let optionsIds = this.items.map(option => option.value);

			if (optionsIds.length > 0) {
				if (values.filter(id => optionsIds.includes(id)).length === values.length) {
					return;
				}
			}

			this.items = await this.optionsService(this.initTag, values);
			await this.$store.dispatch("loadersStore/hideLoader", this.initTag);

            this.$nextTick(() => this.calculateHiddenChips())
		},
		calculateHiddenChips() {
			this.$nextTick(() => {
				if (this.multiple) {
					const container = this.$refs.field.$el.querySelector(".v-select__selections");
					if (container == null) {
						return 0;
					}

					const bottom = container.getBoundingClientRect().bottom - 30;
					const items = container.querySelectorAll('.v-chip');

					this.hiddenSelectCount = [...items].filter(item => {
						item.classList.remove('hidden');
						if (item.getBoundingClientRect().top < bottom) {
							return false;
						}

						item.classList.add('hidden');
						return true;
					}).length;
				}
			});
		},
		filter(item, queryText, itemText) {
			if (this.isDynamic) {
				return true;
			}

			return replaceString(itemText).indexOf(replaceString(queryText)) > -1
		},
		maskListItem(text) {
			if (this.isDynamic || !this.searchText) {
				return text;
			}

			let index = replaceString(text).indexOf(replaceString(this.searchText));

			if (index > -1) {
				const end = index + this.searchText.length;
				return `${text.slice(0, index)}<span class="v-list-item__mask">${text.slice(index, end)}</span>${text.slice(end)}`;
			}

			return text;
		},
	}
}
</script>