<template>
    <page withBackground>
        <div class='pa-8 d-flex flex-column gap-8' style="position:relative;z-index:2;">
            <div class='breadcrumbs'>
                <div class='breadcrumb' @click="$router.push({name: 'SchedulesListPage'})">{{ $t('Grafikų sąrašas') }}</div>
                <ui-icon>arrow-right</ui-icon>
                <div class='breadcrumb'>{{ scheduleName }}</div>
            </div>
            <div>
                <h1>
                    <ui-icon>schedules</ui-icon>
                    {{ $t('Darbo grafikai') }}
                </h1>
            </div>
            <div class='d-flex gap-3 align-end flex-wrap justify-space-between'>
                <div class="d-flex gap-3">
                    <div class='d-flex align-end gap-3' v-if='isAdmin'>
                        <ui-button
                            background='violet-light'
                            prepend-icon='plus'
                            :modal='{name: "CreateScheduleLineModal", props: {type: "group"}}'
                            size='normal'
                        >
                            {{ $t('Pridėti darbo vietą') }}
                        </ui-button>
                        <ui-button
                            background='primary-dark'
                            prepend-icon='plus'
                            :modal='{name: "CreateScheduleLineModal", props: {type: "user"}}'
                            size='normal'
                        >
                            {{ $t('Pridėti darbuotoją') }}
                        </ui-button>
                        <ui-button
                            background='primary-dark'
                            prepend-icon='plus'
                            :modal='{name: "CopyScheduleTimetablesModal"}'
                            size='normal'
                        >
                            {{ $t('Kopijuoti grafiką') }}
                        </ui-button>
                    </div>
                    <select-field
                        :label="$t('Peržiūros tipas')"
                        :options='[{value: "month", text: $t("Mėnesis")}, {value: "day", text: $t("Diena")}]'
                        v-model='viewType'
                    />
                </div>
                <div class='d-flex align-center gap-3'>
                    <form-checkbox
                        :is-checked.sync='minusBreaks'
                        @change='onChangeMinusBreaks'
                    >
                        <div class='ml-3 hra-text--16'>
                            {{ $t('Laikas be pertraukų') }}
                        </div>
                    </form-checkbox>
                    <form-checkbox
                        :is-checked.sync='timeCol'
                    >
                        <div class='ml-3 hra-text--16'>
                            {{ $t('Faktinis laikas') }}
                        </div>
                    </form-checkbox>
                    <ui-button
                        v-if="isAdmin"
                        size='normal'
                        background='default'
                        prepend-icon='generate'
                        :modal='{name: "GenerateScheduleAutoBreaksModal"}'
                        class="generate-breaks-btn"
                    >
                        {{ $t('Automatiškai generuoti pertraukas') }}
                    </ui-button>
                    <ui-button background='primary' @click='onExport'>
                        {{ $t('Atsiųsti .xlsx') }}
                    </ui-button>
<!--                    <ui-button-->
<!--                        background='white'-->
<!--                        prepend-icon='vertical-dots'-->
<!--                        class='expand-options'-->
<!--                    >-->
<!--                    </ui-button>-->
                </div>
            </div>

            <schedule-container>
                <schedule-sidebar :min-width='100' v-if="showSidebarLeft">
                    <template #header>
                        <tr>
                            <td
                                class='hra-text--16 font-weight-bold pa-4'
                                :style="{ height: `${headerTdStyle}px` }"
                            >
                                {{ $t("Pamainos") }}
                            </td>
                            <td
                                v-if='timeCol'
                                class='hra-text--16 font-weight-bold pa-4'
                                :style="{ height: `${headerTdStyle}px` }"
                            >
                                {{ $t("Laikas") }}
                            </td>
                        </tr>
                    </template>
                    <template #body>
                        <schedule-line
                            v-for='row in rows'
                            :key='`sidebar-row-${row.id}`'
                            :row='row'
                            :height='eventRowHeight'
                            :visible-time='timeCol'
                            :is-empty='false'
                        />
                    </template>
                </schedule-sidebar>
                <div
                    v-if='cornerLeft'
                    class='schedule-sidebar__corner'
                    @click="toggleSidebar('left')"
                >
                    <ui-icon>{{ showSidebarLeft ? 'arrow-left' : 'arrow-right' }}</ui-icon>
                </div>
                <schedule-events
                    :start-date='startDate'
                    :end-date='endDate'
                    :rows='rows'
                    :row-height='eventRowHeight'
                    :day-width='eventDayWidth'
                    :header-hours-count='headerHoursCount'
                    :event-only-days-grid='eventOnlyDaysGrid'
                />
                <template v-if="renderRightSidebar">
                    <div
                        v-if='cornerRight'
                        class='schedule-sidebar__corner'
                        @click="toggleSidebar('right')"
                    >
                        <ui-icon>{{ showSidebarRight ? 'arrow-right' : 'arrow-left' }}</ui-icon>
                    </div>

                    <schedule-sidebar-tabs
                        v-if="showSidebarRight"
                    />
                </template>
            </schedule-container>

            <schedule-legend />

        </div>
    </page>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import ScheduleEvent from '@/domain/scheduler/components/ScheduleEvent.vue'
import DatepickerField from '@/domain/fields/DatepickerField.vue'
import { mapGetters } from 'vuex'
import Page from '@/domain/components/Page.vue'
import SchedulerSidebar from '@/domain/scheduler/components/SchedulerSidebar.vue'
import SchedulerEvents from '@/domain/scheduler/components/SchedulerEvents.vue'
import {
    SCHEDULE_TAB_EDIT_BREAKS,
    SCHEDULE_TAB_EDIT_SCHEDULE,
    SCHEDULE_TAB_EDIT_TIMETABLE,
    sidebarRowTypes
} from '@/domain/scheduler/store/state'
import moment from 'moment'
import ScheduleTimeInterval from '@/domain/scheduler/components/ScheduleTimeInterval.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import TableCheckbox from '@/components/ui/table.old/TableCheckbox.vue'
import UiButton from '@/domain/components/UiButton.vue'
import UiIcon from '@/domain/components/UiIcon.vue'
import HraTable from '@/components/ui/table/HraTable.vue'
import SelectField from '@/domain/fields/SelectField.vue'
import ScheduleTable from '@/domain/scheduler/components/ScheduleTable.vue'
import SchedulerContainer from '@/domain/scheduler/components/ScheduleContainer.vue'
import ScheduleContainer from '@/domain/scheduler/components/ScheduleContainer.vue'
import ScheduleSidebar from '@/domain/scheduler/components/ScheduleSidebar.vue'
import ScheduleEvents from '@/domain/scheduler/components/ScheduleEvents.vue'
import FormRadio from '@/components/ui/form/FormRadio.vue'
import CustomTabs from '@/domain/components/CustomTabs.vue'
import ScheduleLine from '@/domain/scheduler/components/ScheduleLine.vue'
import { GLOBAL_TOAST_TAG } from '@/utils/default'
import {
    permissionsButtonEmployeeCreate,
    permissionsButtonEmployeeDelete,
    permissionsButtonEmployeeEdit
} from '@/utils/permissions'
import ScheduleSidebarTabs from '@/domain/scheduler/components/sidebars/ScheduleSidebarTabs.vue'
import ScheduleLegend from '@/domain/scheduler/components/ScheduleLegend.vue'
import customApi from "@/plugins/customApi";
import {INIT_EVENTS} from "@/domain/scheduler/store/mutations";

export default {
    name: 'ScheduleEditPage',
    components: {
        ScheduleLine,
        CustomTabs,
        FormRadio,
        ScheduleEvents,
        ScheduleSidebar,
        ScheduleContainer,
        SchedulerContainer,
        ScheduleTable,
        SelectField,
        HraTable,
        UiIcon,
        UiButton,
        TableCheckbox,
        FormCheckbox,
        ScheduleTimeInterval,
        SchedulerEvents,
        SchedulerSidebar,
        Page,
        DatepickerField,
        ScheduleEvent,
        ScheduleSidebarTabs,
        ScheduleLegend,
        Datepicker
    },
    props: {
        cornerLeft: { type: Boolean, default: true },
        cornerRight: { type: Boolean, default: true },
    },
    data() {
        return {
            viewType: 'month',
            editable: false,
            expandedGroup: [],
            timeCol: true,
            sidebarRowTypes,
            SCHEDULE_TAB_EDIT_SCHEDULE,
            SCHEDULE_TAB_EDIT_TIMETABLE,
            SCHEDULE_TAB_EDIT_BREAKS,
            showSidebarRight: true,
            showSidebarLeft: true,
        }
    },
    provide() {
        return {
            eventRowHeight: this.eventRowHeight,
            headerTdStyle: this.headerTdStyle,
        }
    },
    mounted() {
        let date = moment(this.$route.query.date ?? '')

        if (!date.isValid()) {
            date = moment()
        }

        const from = date.clone()
            .startOf('month')
            .startOf('day')
            .format('YYYY-MM-DD HH:mm:ss')
        const to = date.clone()
            .endOf('month')
            .endOf('day')
            .format('YYYY-MM-DD HH:mm:ss')

        this.$store.dispatch('schedulerStore/fetchSchedule', {
            scheduleId: this.$route.params.id,
            scheduleName: this.name,
            from,
            to
        })
    },
    computed: {
        ...mapGetters({
            fromMoment: 'schedulerStore/fromMoment',
            toMoment: 'schedulerStore/toMoment',
            currentTab: 'schedulerStore/currentTab',
            scheduleId: 'schedulerStore/scheduleId',
            scheduleName: 'schedulerStore/scheduleName',
            showOtherEvents: 'schedulerStore/showOtherEvents',
            durationByUserId: 'schedulerStore/durationByUserId',
            timeIntervals: 'schedulerStore/timeIntervals',
            minusBreaks: 'schedulerStore/minusBreaks',
            breaks: 'schedulerStore/breaks',
            rows: 'schedulerStore/rows',
        }),

        isAdmin() {
            return this.hasPermissionTo([
                ...permissionsButtonEmployeeCreate,
                ...permissionsButtonEmployeeEdit,
                ...permissionsButtonEmployeeDelete
            ])
        },
        startDate() {
            return this.fromMoment.format('YYYY-MM-DD')
        },
        endDate() {
            return this.toMoment.format('YYYY-MM-DD')
        },

        eventOnlyDaysGrid() {
            return this.viewType === 'month'
        },
        headerTdStyle() {
            if (this.headerHoursCount === 0) {
                return 75
            }

            return 75
        },
        eventRowHeight() {
            if (this.viewType === 'month') {
                return 65
            }

            return 65
        },
        eventDayWidth() {
            if (this.viewType === 'month') {
                return 65
            }

            return 400
        },
        headerHoursCount() {
            if (this.viewType === 'month') {
                return 0
            }

            return 6
        },

        columnWidth() {
            if (this.viewType === 'month') {
                return 30
            }

            return 40
        },
        cssVars() {
            return {
                '--min-columns-size': this.columnWidth + 'px'
            }
        },
        moveMinutes() {
            if (this.viewType === 'month') {
                return 24 * 60
            }

            return 5
        },
        hours() {
            if (this.viewType === 'month') {
                return ['']
            }

            return ['00', '03', '06', '09', '12', '15', '18', '21']
        },
        renderRightSidebar() {
            return this.cornerRight && this.isAdmin;
        }
    },
    methods: {
        toggleSidebar(side) {
            if (side === 'left') {
                this.showSidebarLeft = !this.showSidebarLeft
            } else if (side === 'right') {
                this.showSidebarRight = !this.showSidebarRight
            }
        },

        generateData() {
            this.$store.dispatch('schedulerStore/generateRandomSchedule')
        },
        onEdit() {
            this.editable = !this.editable
        },
        toggleGroup(id) {
            this.$store.dispatch('schedulerStore/toggleGroup', id)
        },
        onChangeMinusBreaks(event) {
            this.$store.dispatch('schedulerStore/changeMinusBreaks', event ?? false)
        },
        onChangeShowOtherEvents(event) {
            this.$store.dispatch('schedulerStore/changeShowOtherEvents', event ?? false)
        },
        onGenerateAutoBreaks() {
            this.$store.dispatch('schedulerStore/generateAutoBreaks')
        },
        onEditSchedule() {
            this.$store.dispatch('schedulerStore/setEditSchedule')
        },
        onEditBreaks() {
            this.$store.dispatch('schedulerStore/setEditBreaks')
        },
        backToSchedules() {
            this.$router.push({ name: 'SchedulesListPage' })
        },
        async onDeleteBreak(confirm, id) {
            if (!await this.$store.dispatch('schedulerStore/deleteBreakById', id)) {
                await this.$store.dispatch('messagesStore/showErrorMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: 'Break delete error'
                })

                return
            }

            await this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag: GLOBAL_TOAST_TAG,
                message: 'Break delete success'
            })

            confirm.close()
        },
        onExport() {
            window.location.href = `${window.location.origin}/api/schedules/export/excel?scheduleId=${this.scheduleId}&date=${this.startDate}`
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/sass/core/colors';

.ui-button {
    &.expand-options {
        border: 2px solid $schedule-border-primary;
        padding: 7px 7px !important;

        .ui-icon {
            font-size: 24px;
        }

        &:hover {
            background-color: #F1EEF9;
        }
    }

    &.generate-breaks-btn {
        .ui-icon {
            font-size: 18px;
        }

        .ui-icon, span {
            color: $violet-900 !important;
        }
    }
}

.schedule-sidebar__corner {
    position: relative;
    min-width: 14px;
    min-height: 100%;
    background: #FAFAFA;
    box-shadow: inset 0 0 0 1px $schedule-border-primary;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .ui-icon {
        color: $greyscale-600;
    }
}

.schedule__no-data {
    background-color: #F3F4FF !important;
    border: none !important;
    color: $greyscale-600;
    font-weight: 500;
    text-align: center;
}
</style>