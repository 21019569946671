<template>
    <v-main>
        <v-app v-if="isAuthenticated">
            <v-container fluid fill-height class="import">
                <v-layout flex-row>
                    <router-view />
                </v-layout>
            </v-container>
        </v-app>
        <v-overlay :opacity="1" :value="isLoading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-main>
</template>

<script>
import Sidebar from "@/components/ui/sidebar/Sidebar.vue";
import Header from "@/components/ui/header/Header.vue";
import RouteChangeLoader from "@/components/RouteChangeLoader";

export default {
    name: "ImportLayout",
    components: {RouteChangeLoader, Sidebar, Header },
    data() {
        return {
            loaderName: "ImportLayout",
        };
    },
    beforeRouteUpdate(to, from, next) {
        this.showLoader();
        next();
    },
    updated() {
        if (this.isLoading) {
            this.hideLoader();
        }
    },
};
</script>
