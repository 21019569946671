<template>
    <department-vacations-web-view-page />
</template>

<script>
import DepartmentVacationsWebViewPage from '@/domain/departmentVacations/components/DepartmentVacationsWebViewPage.vue'

export default {
    name: 'DepartmentVacationsPage',
    components: { DepartmentVacationsWebViewPage },
}
</script>