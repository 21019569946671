<template>
	<v-fade-transition>
		<v-progress-circular
			indeterminate
			color="blue"
			:size="size"
			width="2"
		/>
	</v-fade-transition>
</template>

<script>
export default {
	name: "Loader",
	props: {
		size: {type: [Number, String], default: 32},
	}
}
</script>