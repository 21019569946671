<template>
  <div class="hra-table-default-empty" :class="classes">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "HraTableDefaultEmpty",
	props: {
		ignoreDefaultMargins: {type: Boolean, default: false},
	},
	computed: {
		classes() {
			return {
				// "mt-6": !this.ignoreDefaultMargins
			};
		},
	}
};
</script>
