import defaultState from '@/domain/departmentVacations/store/state'
import Vue from 'vue'

export const INIT_EVENTS = 'INIT_EVENTS'
export const SET_LEADER = 'SET_LEADER'
export const SET_ADMIN = 'SET_ADMIN'
export const SET_EMPLOYEES = 'SET_EMPLOYEES'
export const SET_CAN_APPROVE_USER_IDS = 'SET_CAN_APPROVE_USER_IDS'
export const SET_CALCULATE = 'SET_CALCULATE'
export const SET_HOLIDAYS = 'SET_HOLIDAYS'
export const SET_EVENTS = 'SET_EVENTS'
export const SET_USER_BALANCES = 'SET_USER_BALANCES'
export const SET_DEPARTMENT_OPTIONS = 'SET_DEPARTMENT_OPTIONS'
export const SET_DUTY_OPTIONS = 'SET_DUTY_OPTIONS'
export const SET_FILTER_FROM_DATE = 'SET_FILTER_FROM_DATE'
export const SET_FILTER_DEPARTMENTS = 'SET_FILTER_DEPARTMENTS'
export const SET_FILTER_DUTIES = 'SET_FILTER_DUTIES'
export const SET_FILTER_EMPLOYEES = 'SET_FILTER_EMPLOYEES'
export const SET_FILTER_CALCULATE_DATE = 'SET_FILTER_CALCULATE_DATE'
export const ADD_DETAIL_VACATION = 'ADD_DETAIL_VACATION'
export const SET_SELECTED_EVENT_ID = 'SET_SELECTED_EVENT_ID'
export const SET_VACATIONS_TO_APPROVE = 'SET_VACATIONS_TO_APPROVE'
export const CHANGE_VACATION = 'CHANGE_VACATION'
export const CLEAR_FROM_WAITING = 'CLEAR_FROM_WAITING'
export const CLEAR_STATE = 'CLEAR_STATE'
export const APPROVE_EVENT = 'APPROVE_EVENT'
export const ABORT_EVENT = 'ABORT_EVENT'

export default ({
    [INIT_EVENTS]: (state, payload) => state.events = payload,
    [SET_LEADER]: (state, payload) => state.isLeader = payload,
    [SET_ADMIN]: (state, payload) => state.isAdmin = payload,
    [SET_CAN_APPROVE_USER_IDS]: (state, payload) => state.canApproveUserIds = payload,
    [SET_EMPLOYEES]: (state, payload) => state.employees = payload,
    [SET_CALCULATE]: (state, payload) => state.calculate = payload,
    [SET_EVENTS]: (state, payload) => state.events = payload,
    [SET_HOLIDAYS]: (state, payload) => state.holidays = payload,
    [SET_USER_BALANCES]: (state, payload) => Vue.set(state, 'userBalances', payload),
    [SET_DEPARTMENT_OPTIONS]: (state, payload) => state.departmentOptions = payload,
    [SET_DUTY_OPTIONS]: (state, payload) => state.dutyOptions = payload,
    [ADD_DETAIL_VACATION]: (state, payload) => state.detailVacations[payload.id] = payload,
    [SET_FILTER_FROM_DATE]: (state, payload) => {
        state.filter.fromDate = payload
    },
    [SET_FILTER_DEPARTMENTS]: (state, payload) => state.filter.departments = payload,
    [SET_FILTER_DUTIES]: (state, payload) => state.filter.duties = payload,
    [SET_FILTER_EMPLOYEES]: (state, payload) => state.filter.employeeIds = payload,
    [SET_FILTER_CALCULATE_DATE]: (state, payload) => state.filter.calculateDate = payload,
    [SET_SELECTED_EVENT_ID]: (state, payload) => state.selectedEventId = payload,
    [SET_VACATIONS_TO_APPROVE]: (state, payload) => state.vacationsToApprove = payload,
    [CLEAR_FROM_WAITING]: (state, payload) => {
        state.vacationsToApprove = state.vacationsToApprove.filter(({ id }) => id !== payload)
    },
    [APPROVE_EVENT]: (state, payload) => {
        const events = [payload, ...(payload.deputies ?? [])]
        console.debug(events, payload)
        const deleteIds = events.map(({ id }) => id)
        events.forEach(updatedEvent => {
            const eventKey = state.events.findIndex(event => event.id === updatedEvent.id)

            console.debug(eventKey, updatedEvent.id)

            if (eventKey > -1) {
                Vue.set(state.events, eventKey, {
                    ...state.events[eventKey],
                    ...updatedEvent
                })
            }
        })

        state.vacationsToApprove = state.vacationsToApprove.filter(event => !deleteIds.includes(event.id))
    },
    [ABORT_EVENT]: (state, payload) => {
        const deleteIds = [payload, ...(payload.deputies ?? [])].map(({ id }) => id)
        state.events = state.events.filter(event => !deleteIds.includes(event.id))
        state.vacationsToApprove = state.vacationsToApprove.filter(event => !deleteIds.includes(event.id))
    },
    [CLEAR_STATE]: (state) => Object.assign(state, defaultState())
})