<template>
    <v-row>
        <v-col cols='12' class='selected-users-list-header'>
            <div class='selected-users-list-header__left'>
                {{ $t('Pasirinkti gavėjai') }}
                <b class='pl-1'>{{ selectedAssignUsersToDocumentsRowsLength }}</b>
            </div>
            <div class='selected-users-list-header__right'>
            </div>
        </v-col>

        <v-col cols='12'>
            <ui-table
                header-sticky
                :loading='loading'
                :is-empty-table='rows.length === 0'
                :visible-load-more='hasMore'
                @clickLoadMore='handleLoadMore'
            >
                <template #head>
                    <tr>
                        <th>
                            <form-checkbox
                                :is-checked='selectedAssignUsersToDocumentsRowsAll'
                                @change='handleSelectAll'
                            />
                        </th>
                        <table-header-user-filter
                            :filter='filter.userId'
                            @changeFilter='handleChangeFilter'
                        />
                        <table-header-department-filter
                            :filter='filter.departmentId'
                            @changeFilter='handleChangeFilter'
                        />
                        <table-header-duty-filter
                            :filter='filter.dutyId'
                            @changeFilter='handleChangeFilter'
                        />
                        <ui-table-head-filter
                            :title='$t("Dokumentas")'
                            column='documentId'
                            :value='filter.documentId'
                            :sort-options='sortOptions'
                            @changeFilter='handleChangeFilter'
                        />
                        <ui-table-head-filter
                            :title='$t("Statusas")'
                            column='status'
                            :value='filter.status'
                            :select-options='documentStatusOptions'
                            @changeFilter='handleChangeFilter'
                        />
                    </tr>
                </template>

                <template #body>
                    <tr
                        v-for='(row, key) in rows'
                        :key='key'
                        style='cursor: pointer'
                        @click='() => handleToggleUserId(row.userId, row.documentId)'
                    >
                        <td>
                            <form-checkbox
                                :is-checked='row.selected'
                                @change='() => handleToggleUserId(row.userId, row.documentId)'
                            />
                        </td>
                        <td style='max-width: 150px'>{{ row.userName }}</td>
                        <td style='max-width: 100px'>{{ row.userDepartmentName }}</td>
                        <td style='max-width: 100px'>{{ row.userDutyName }}</td>
                        <td style='max-width: 400px'>{{ row.documentTitle }}</td>
                        <td>
                            <document-sent-status-with-comment
                                :status='row.status'
                                :date='row.statusAt'
                                :comment='row.comment'
                            />
                        </td>
                    </tr>
                </template>
            </ui-table>
        </v-col>
    </v-row>
</template>

<script>
import TableHeaderDepartmentFilter from '@/domain/components/uiTable/filters/TableHeaderDepartmentFilter.vue'
import TableHeaderDutyFilter from '@/domain/components/uiTable/filters/TableHeaderDutyFilter.vue'
import UiTable from '@/domain/components/uiTable/UiTable.vue'
import UiTableHeadFilter from '@/domain/components/uiTable/UiTableHeadFilter.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import TableHeaderUserFilter from '@/domain/components/uiTable/filters/TableHeaderUserFilter.vue'
import CustomApi from '@/plugins/customApi'
import DocumentSentStatusWithComment from '@/domain/documents/components/DocumentSentStatusWithComment.vue'

const customApi = CustomApi()
export default {
    name: 'AssignUsersToDocumentsTable',
    components: {
        DocumentSentStatusWithComment,
        TableHeaderUserFilter,
        FormCheckbox,
        UiTableHeadFilter,
        UiTable,
        TableHeaderDutyFilter,
        TableHeaderDepartmentFilter
    },
    inject: ['storeNamespace'],
    data() {
        const sortOptions = [
            { value: 'asc', label: 'A-Z' },
            { value: 'desc', label: 'Z-A' }
        ]

        return {
            loading: false,
            hasMore: false,
            page: 1,
            selectedAllEmployees: false,
            documentStatusOptions: [
                { value: 'new', label: this.$t('Nepradėtas') },
                { value: 'pending', label: this.$t('Laukiama') },
                { value: 'rejected', label: this.$t('Atmesta') },
                { value: 'accepted', label: this.$t('Priimta') },
                { value: 'cancelled', label: this.$t('Atšaukta') }
            ],
            sortOptions
        }
    },
    computed: {
        documentIds() {
            return this.$store.getters[`${this.storeNamespace}/selectedDocuments`].map(({ id }) => id)
        },
        filter() {
            return this.$store.getters[`${this.storeNamespace}/usersDocumentsFilter`]
        },
        selectedAssignUsersToDocumentsRowsLength() {
            return this.$store.getters[`${this.storeNamespace}/selectedAssignUsersToDocumentsRowsLength`]
        },
        selectedAssignUsersToDocumentsRowsAll() {
            return this.$store.getters[`${this.storeNamespace}/selectedAssignUsersToDocumentsRowsAll`]
        },
        rows() {
            return this.$store.getters[`${this.storeNamespace}/assignUsersToDocumentsRows`]
        }
    },
    mounted() {
        if (this.rows.length === 0) {
            this.fetch()
        }
    },
    methods: {
        handleToggleUserId(userId, documentId) {
            this.$store.dispatch(`${this.storeNamespace}/toggleSelectUserDocument`, { userId, documentId })
        },
        handleSelectAll() {
            this.$store.dispatch(`${this.storeNamespace}/toggleSelectAllAssignUsersToDocumentsRows`)
        },
        handleChangeFilter(column, filter) {
            this.$store.dispatch(`${this.storeNamespace}/setUsersDocumentsFilter`, { column, filter })
            this.fetch()
        },
        handleLoadMore() {
            this.fetch(true)
        },
        getFetchParams(nextPage) {
            let params = {
                page: 1,
                limit: 200,
                sortBy: [],
                documentId: this.documentIds
            }

            if (nextPage) {
                params.page = this.page + 1
            }

            for (const [column, value] of Object.entries(this.filter)) {
                if (value.sort != null) {
                    params.sortBy.push(`${column},${value.sort}`)
                }

                if ((value.selected?.length ?? 0) > 0) {
                    params[column] = value.selected
                }
            }

            return params
        },
        async fetch(nextPage = false) {
            this.loading = true
            try {
                const {
                    data: {
                        items,
                        hasMore,
                        pagination: { currentPage }
                    }
                } = await customApi.factories.documents.employees.fetchByFilter(this.getFetchParams(nextPage))

                await this.$store.dispatch(`${this.storeNamespace}/setAssignUsersToDocumentsRows`, {
                    rows: items,
                    nextPage
                })
                this.page = currentPage
                this.hasMore = hasMore
            } catch (error) {
                console.error(error)
                this.showErrorInRootToast(error)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>