<template>
    <ValidationProvider
        :name="$attrs.name"
        :rules="rules"
        v-slot="{ errors, classes }"
    >
        <div :class="fieldClasses">
            <label class="form-radio__container">
                <span class="form-radio__label">
                    <slot/>
                </span>
                <input
                    class="form-radio__input"
                    type="radio"
                    ref="input"
                    :id="$attrs.name"
                    :name="$attrs.name"
                    :value="radioValue"
                    @change.prevent="onChange"
                    :checked="isChecked"
                    :disabled="disabled"
                />
                <span class="form-radio__checkmark"></span>
            </label>
        </div>
    </ValidationProvider>
</template>

<script>
import {ValidationProvider} from "vee-validate";
import FormAlert from "./FormAlert.vue";
import formFieldMixin from "@/mixins/formFieldMixin";

export default {
    components: {
        ValidationProvider,
        FormAlert,
    },
    props: {
        rules: {
            type: [Object, String],
            default: "",
        },
        radioValue: {
            type: String,
            required: true,
        },
        value: {
            type: [Boolean, String],
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        isChecked: false,
    }),
    computed: {
        fieldClasses() {
            let classes = ["form-radio"];
            if (this.disabled) {
                classes.push("disabled");
            }
            return classes;
        },
    },
    watch: {
        value: {
            handler(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.isChecked = newValue === this.radioValue;
                }

                // this.setInnerValue(newValue, oldValue);
                // if (newValue === this.radioValue) {
                    //
                    // this.$refs.input.checked = true;
                // }
            },
            deep: false,
            immediate: true,
        },
    },
    methods: {
        onChange({target}) {
            //
            this.$emit('input', this.radioValue);
        }
    }
};
</script>
