// initial state
export const tableDefaultState = () => ({
    rows: [],
});

// getters
export const tableGetters = {
    headers(state) {
        return state.headers;
    },
    rows(state) {
        return state.rows.sort((a, b) => a.title.name.localeCompare(b.title.name));


        // {
        //     if (a.title.name > b.title.name) return 1;
        //     if (a.title.name < b.title.name) return -1;
        //     return 0;
        // });
    },
};

// actions
export const tableActions = {};

//  mutations
export const tableMutations = {
    setRows(state, payload) {
        state.rows = payload;
    },
    addRow(state, payload) {
        state.rows = [
            ...state.rows,
            payload,
        ];
    },
    changeRow(state, payload) {
        state.rows = state.rows.map(row => row.id === payload.id ? payload : row);
    },
    removeRow(state, id) {
        state.rows = state.rows.filter(row => row.id !== id);
    },
    clear(state) {
        Object.assign(state, tableDefaultState());
    },
};
