<template>
    <custom-dialog :max-width='670'>
        <template #title>{{ $t('Redaguoti darbo laiką') }}</template>
        <template #content>
            <v-row class='px-3'>
                <v-col cols='6'>
                    <text-field type='time' v-model='startTime' min='00:00' max='23:59' :label='$t("Nuo")' />
                </v-col>
                <v-col cols='6'>
                    <text-field type='time' v-model='endTime' min='00:00' max='23:59' :label='$t("Iki")' />
                </v-col>
            </v-row>
            <v-row class='px-3'>
                <v-col cols='12'>
                    {{ $t('Trukmė') }}: {{ duration }}
                </v-col>
            </v-row>
        </template>

        <template #footer>
            <ui-button
                prepend-icon='close'
                background='default'
                @click='close'
            >{{ $t('Uždaryti') }}
            </ui-button>
            <ui-button
                prepend-icon='ok'
                background='primary'
                @click='onCreate'
            >{{ $t('Redaguoti') }}
            </ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import dialogMixin from '@/mixins/dialogMixin'
import TextField from '@/domain/fields/TextField.vue'
import UiButton from '@/domain/components/UiButton.vue'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import scheduleTimeIntervalModalMixin from '@/domain/scheduler/mixins/scheduleTimeIntervalModalMixin'
import {GLOBAL_TOAST_TAG} from "@/utils/default";

export default {
    name: 'UpdateScheduleTimeIntervalModal',
    mixins: [dialogMixin, scheduleTimeIntervalModalMixin],
    props: {
        timeInterval: {type: Object, required: true}
    },
    components: {
        UiButton,
        TextField,
        CustomDialog
    },
    beforeMount() {
        this.formData.start = this.timeInterval.start
        this.formData.end = this.timeInterval.end
    },
    methods: {
        async onCreate() {
            await this.$store.dispatch('schedulerStore/updateTimeIntervalById', {
                id: this.timeInterval.id,
                payload: {
                    start: this.formData.start,
                    end: this.formData.end,
                    type: 'schedule'
                }
            })

            await this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag: GLOBAL_TOAST_TAG,
                message: this.$t('Time interval update success')
            })

            this.close()
        }
    }
}
</script>