<script>
import CustomDialog from "@/components/ui/dialog/CustomDialog.vue";
import dialogMixin from "@/mixins/dialogMixin";
import UiButton from "@/domain/components/UiButton.vue";
import DatepickerField from "@/domain/fields/DatepickerField.vue";
import UiIcon from "@/domain/components/UiIcon.vue";
import SelectField from "@/domain/fields/SelectField.vue";
import EmployeeTableView from "@/components/ui/views/EmployeeTableView.vue";
import FormAlert from "@/components/ui/form/FormAlert.vue";
import customApi from "@/plugins/customApi";
import {GLOBAL_TOAST_TAG} from "@/utils/default";
import FormCheckbox from "@/components/ui/form/FormCheckbox.vue";
import UiCalendar from "@/domain/components/UiCalendar/UiCalendar.vue";
import MultilineSelectField from "@/domain/components/MultilineSelectField.vue";
import CopyScheduleVerticallyTab from "@/domain/scheduler/components/CopyScheduleVerticallyTab.vue";
import CopyScheduleHorizontallyTab from "@/domain/scheduler/components/CopyScheduleHorizontallyTab.vue";
import FullRowLoader from "@/components/ui/FullRowLoader.vue";

export default {
    name: "CopyScheduleTimetablesModal",
    mixins: [dialogMixin],
    components: {
        FullRowLoader,
        CopyScheduleHorizontallyTab,
        CopyScheduleVerticallyTab,
        MultilineSelectField,
        UiCalendar,
        FormCheckbox,
        FormAlert, EmployeeTableView, SelectField, UiIcon, DatepickerField, UiButton, CustomDialog},
    data: () => {
        return {
            tabs: {
                vertical: 1,
                horizontal: 2
            },
            activeTab: 1,
            verticalTabValues: {},
            verticalSubmitActive: false,
            horizontalTabValues: {},
            horizontalSubmitActive: false,
            loading: false,
        }
    },
    methods: {
        changeTab(tabId) {
            this.activeTab = tabId
        },
        onVerticalValuesChange(event) {
            this.verticalTabValues = event;
            this.verticalSubmitActive = (this.verticalTabValues.to.timetableLines)
                && (this.verticalTabValues.from.timetableLine || this.verticalTabValues.from.employeeLine)
                && this.verticalTabValues.from.date
                && this.verticalTabValues.to.date
        },
        onHorizontalValuesChange(event) {
            this.horizontalTabValues = event;
            this.horizontalSubmitActive = (this.horizontalTabValues.timetableLine || this.horizontalTabValues.employeeLine)
                && this.horizontalTabValues.from.start
                && this.horizontalTabValues.from.end
                && this.horizontalTabValues.to.start
                && this.horizontalTabValues.to.end
        },
        async onVerticalSubmit() {
            this.loading = true;
            this.verticalTabValues.scheduleId = parseInt(this.$route.params.id);

            const api = customApi(GLOBAL_TOAST_TAG)
            const result = await api.request(api.factories.schedules.events.copy(this.verticalTabValues))

            if (result == null) {
                await this.$store.dispatch('messagesStore/showErrorMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Siuntimo klaida')
                })
                this.loading = false;

                return;
            }

            await this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag: GLOBAL_TOAST_TAG,
                message: this.$t('Sėkmingai nukopijuota')
            })

            await this.$store.dispatch('schedulerStore/fetchEvents');
            await this.$store.dispatch('schedulerStore/fetchTimetables');
            this.loading = false;
            this.close()
        },
        async onHorizontalSubmit() {
            this.loading = true;
            this.horizontalTabValues.scheduleId = parseInt(this.$route.params.id);
            const api = customApi(GLOBAL_TOAST_TAG)
            const result = await api.request(api.factories.schedules.timetables.copy(this.horizontalTabValues))

            if (result == null) {
                await this.$store.dispatch('messagesStore/showErrorMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Siuntimo klaida')
                })
                this.loading = false;

                return;
            }

            await this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag: GLOBAL_TOAST_TAG,
                message: this.$t('Sėkmingai nukopijuota')
            })

            await this.$store.dispatch('schedulerStore/fetchEvents');
            await this.$store.dispatch('schedulerStore/fetchTimetables');
            this.loading = false;
            this.close()
        },
    }
}
</script>

<template>
    <custom-dialog :max-width="1000" draggable>
        <template #title>{{ $t('Grafiko kopijavimas') }}</template>
        <template #content>
            <div class="d-flex relative">
                <div class="copy-schedule-modal__tab-container">
                    <button
                        class="button__tab"
                        :class="[activeTab === tabs.vertical ? 'active': 'not-active']"
                        @click="changeTab(tabs.vertical)">
                            {{ $t('Vertikalus kopijavimas') }}
                    </button>
                    <button
                        class="button__tab"
                        :class="[activeTab === tabs.horizontal ? 'active' : 'not-active']"
                        @click="changeTab(tabs.horizontal)"
                        >
                            {{ $t('Horizontalus kopijavimas') }}
                    </button>
                </div>
                <div class="copy-schedule-modal__content">
                    <!-- COPY VERTICALLY -->
                    <copy-schedule-vertically-tab
                        v-if="activeTab === tabs.vertical"
                        @update:verticalTabValues="onVerticalValuesChange($event)" />

                    <!-- COPY HORIZONTALLY -->
                    <copy-schedule-horizontally-tab
                        v-if="activeTab === tabs.horizontal"
                        @update:horizontalTabValues="onHorizontalValuesChange($event)" />

                    <!-- FOOTER/SUBMIT -->
                    <div class="copy-schedule-modal__footer">
                        <ui-button
                            prepend-icon='close'
                            background='default'
                            @click='close'
                        >{{ $t('Atšaukti') }}
                        </ui-button>
                        <ui-button
                            v-if="activeTab === tabs.vertical"
                            prepend-icon='ok'
                            background='primary'
                            @click="onVerticalSubmit"
                            :disabled="!verticalSubmitActive"
                        >{{ $t('Kopijuoti grafiką') }}
                        </ui-button>
                        <ui-button
                            v-if="activeTab === tabs.horizontal"
                            prepend-icon='ok'
                            background='primary'
                            @click="onHorizontalSubmit"
                            :disabled="!horizontalSubmitActive"
                        >{{ $t('Kopijuoti laikotarpi') }}
                        </ui-button>
                    </div>
                </div>
                <div class="copy-schedule-modal__loader" v-if="loading">
                    <div class="d-flex flex-column">
                        <full-row-loader />
                        <p><strong>{{ $t('Kopijuojama') }}</strong></p>
                    </div>
                </div>

            </div>
        </template>
    </custom-dialog>
</template>

<style scoped lang="scss">
    .copy-schedule-modal {
        &__tab-container {
            background-color: #EDF1FF;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            button {
                width: 200px;
                padding: 15px 20px 15px 20px;
                text-align: start;
            }

            .active {
                background-color: #FFFFFF;
            }

            .not-active {
                background-color: #EDF1FF;
            }
        }

        &__content {
            flex-grow: 1;
            flex-shrink: 1;
        }

        &__footer {
            display: flex;
            justify-content: flex-end;
            column-gap: 20px;
            padding-bottom: 20px;
            padding-right: 20px;
        }

        &__loader {
            position: absolute;
            top: 0;
            left: 0;
            background-color: #FFFFFF;
            width: 100%;
            height: 100%;
            opacity: 0.7;
            z-index: 10;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .divider {
        width: 1px;
        margin-left: 10px;
        margin-right: 10px;
        background-color: #CFD6E6;
    }

    .button {
        &__tab {
            font-weight: 600;
            font-size: 1.17em;
        }
    }

    .coloured-divider {
        background-color: #FAF9FF;
        border-radius: 12px;
        padding: 16px;
    }
</style>