<template>
    <custom-dialog :max-width='670'>
        <template #title>{{ $t('Redaguoti darbo vietos laiką') }}</template>
        <template #content>
            <v-row class='px-3'>
                <v-col cols='6'>
                    <datepicker-field
                        v-model='formData.startDate'
                        @input='handleChangeStartDate'
                        :label='$t("Data nuo")'
                    />
                </v-col>
                <v-col cols='6'>
                    <datepicker-field
                        v-model='formData.endDate'
                        :label='$t("Data iki")'
                        :min-date='minEndDate'
                    />
                </v-col>
                <v-col cols='6'>
                    <text-field
                        v-model='formData.startTime'
                        @input='handleChangeStartTime'
                        :label='$t("Laikas nuo")'
                        type='time'
                        min='00:00'
                        max='23:59'
                    />
                </v-col>
                <v-col cols='6'>
                    <text-field
                        v-model='formData.endTime'
                        @input='handleChangeEndTime'
                        :label='$t("Laikas iki")'
                        type='time'
                        min='00:00'
                        max='23:59'
                    />
                </v-col>
                <v-col cols='6'>
                    <text-field v-model='formData.minUsers' :label='$t("Min users")' type='number' :min='1' />
                </v-col>
            </v-row>
        </template>

        <template #footer>
            <ui-button
                prepend-icon='close'
                background='default'
                @click='close'
            >{{ $t('Uždaryti') }}
            </ui-button>
            <ui-button
                prepend-icon='ok'
                background='primary'
                :disabled='disabledButton'
                @click='onClick'
            >{{ $t('Išsaugoti') }}
            </ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import TextField from '@/domain/fields/TextField.vue'
import SelectField from '@/domain/fields/SelectField.vue'
import UiButton from '@/domain/components/UiButton.vue'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import EmployeeTableView from '@/components/ui/views/EmployeeTableView.vue'
import dialogMixin from '@/mixins/dialogMixin'
import DatepickerField from '@/domain/fields/DatepickerField.vue'
import moment from 'moment'
import { GLOBAL_TOAST_TAG } from '@/utils/default'
import equalTimeSelectBlockerMixin from "@/domain/scheduler/mixins/equalTimeSelectBlockerMixin";

export default {
    name: 'UpdateScheduleTimetableModal',
    components: { DatepickerField, EmployeeTableView, CustomDialog, UiButton, SelectField, TextField },
    mixins: [dialogMixin, equalTimeSelectBlockerMixin],
    props: {
        payload: { type: Object, required: true }
    },
    data() {
        return {
            disabledButton: false,
            formData: {
                startDate: null,
                startTime: null,
                endDate: null,
                endTime: null,
                minUsers: null
            }
        }
    },
    beforeMount() {
        const startWithTime = this.payload.startWithTime.split(' ')
        this.formData.startDate = startWithTime[0] ?? null
        this.formData.startTime = startWithTime[1] ?? null

        const endWithTime = this.payload.endWithTime.split(' ')
        this.formData.endDate = endWithTime[0] ?? null
        this.formData.endTime = endWithTime[1] ?? null

        this.formData.minUsers = this.payload.minUsers
    },
    methods: {
        async onClick() {
            this.disabledButton = true
            const start = moment(`${this.formData.startDate} ${this.formData.startTime}`)
            const end = moment(`${this.formData.endDate} ${this.formData.endTime}`)

            const result = await this.$store.dispatch('schedulerStore/updateTimetableById', {
                id: this.payload.id,
                payload: {
                    scheduleId: this.payload.scheduleId,
                    scheduleLineId: this.payload.scheduleLineId,
                    start: start.format('YYYY-MM-DD HH:mm:ss'),
                    end: end.format('YYYY-MM-DD HH:mm:ss'),
                    minUsers: parseInt(this.formData.minUsers)
                }
            })
            this.disabledButton = false

            if (!result) {
                return
            }

            await this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag: GLOBAL_TOAST_TAG,
                message: 'Edit success'
            })
            this.close()
        }
    }
}
</script>