<template>
    <div class='pa-8 d-flex flex-column gap-8'>
        <div class='DepartmentVacationFilter'>
            <div class='DepartmentVacationFilter__inputs d-flex gap-2'>
                <select-field
                    name='departments'
                    :label="$t('Skyrius')"
                    :options='departmentOptions'
                    multiple
                    filtered
                    :value='filter.departments'
                    @input='onChangeDepartment'
                    style='width: 286px;'
                />
                <select-field
                    name='duties'
                    :label="$t('Pareigos')"
                    :options='dutyOptions'
                    multiple
                    filtered
                    v-model='filter.duties'
                    style='width: 286px;'
                />
                <datepicker-field
                    name='fromDate'
                    :label="$t('Data nuo')"
                    :value='filter.fromDate'
                    style='width: 286px;'
                    @input='onChangeFromDate'
                />
                <datepicker-field
                    name='calculateDate'
                    :label="$t('Atostogų likutis dienai')"
                    :value='filter.calculateDate'
                    @input='onChangeCalculateDate'
                    style='width: 286px;'
                />
            </div>
        </div>
        <department-vacation-calendar-table />
    </div>
</template>

<script>
import SelectField from '@/domain/fields/SelectField'
import DepartmentVacationCalendarTable from '@/domain/departmentVacations/components/DepartmentVacationCalendarTable'
import { mapGetters } from 'vuex'
import DatepickerField from '@/domain/fields/DatepickerField'
import TableTitle from '@/components/ui/views/TableTitle'
import HraIcon from '@/components/ui/HraIcon'

export default {
    name: 'DepartmentVacationCalendar',
    components: { HraIcon, TableTitle, DatepickerField, SelectField, DepartmentVacationCalendarTable },
    computed: {
        ...mapGetters({
            isLeader: 'departmentVacationsStore/isLeader',
            isAdmin: 'departmentVacationsStore/isAdmin',
            filter: 'departmentVacationsStore/filter',
            departmentOptions: 'departmentVacationsStore/departmentOptions',
            dutyOptions: 'departmentVacationsStore/dutyOptions'
        })
    },
    methods: {
        onChangeCalculateDate(date) {
            this.$store.dispatch('departmentVacationsStore/changeBalanceDate', {
                tag: 'DEPARTMENT_VACATIONS_CHANGE_CALCULATE_DATE',
                date
            })
        },
        onChangeDepartment(ids) {
            this.$store.dispatch('departmentVacationsStore/selectDepartment', ids)
        },
        onChangeFromDate(date) {
            this.$store.dispatch('departmentVacationsStore/updateTimelineDate', {
                tag: 'DEPARTMENT_VACATIONS_CHANGE_FROM_DATE',
                date
            })
        }
    }
}
</script>