import SetupCompanyPage from "@/domain/setup/pages/Company";
import SetupImportPage from "@/domain/setup/pages/Import";
import SetupLayout from "@/layouts/SetupLayout.vue";

export const companyPage = "SetupCompanyPage";
export const importPage = "SetupImportPage";

export default [
  {
    path: "/setup",
    component: SetupLayout,
    children: [
      {name: companyPage, path: "company", component: SetupCompanyPage},
      {name: importPage, path: "import", component: SetupImportPage},
    ]
  }
]