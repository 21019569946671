export function mapFolder(item) {
    return {
        id: item.id,
        selected: false,
        isFolder: true,
        item
    }
}

export function mapDocument(item) {
    return {
        id: item.id,
        selected: false,
        isDocument: !item.isArchived,
        item
    }
}

export function mapGroupsFolder(item) {
    return {
        id: item.id,
        selected: false,
        item
    }
}

export function mapGroupsFolders(items) {
    return items.map(mapGroupsFolder)
}

export function mapGroup(item) {
    return {
        id: item.id,
        selected: false,
        item
    }
}

export function mapGroups(items) {
    return items.map(mapGroup)
}

export function mapArchiveDocument(item) {
    return {
        id: item.id,
        selected: false,
        isArchivedDocument: true,
        item
    }
}