import AppLayout from '@/layouts/AppLayout'
import EmployeeVacationsPage from '@/domain/employeeVacations/pages'

export const index = 'EmployeeVacationsPage'

export default [
    {
        path: '/vacations',
        component: AppLayout,
        children: [
            { name: index, path: '', component: EmployeeVacationsPage }
        ]
    }
]