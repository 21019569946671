<template>
    <div class='documents-items-grid-item'>
        <div class='documents-items-grid-item__container' v-on='$listeners'>
            <div class='documents-items-grid-item__icon'>
                <ui-icon>{{ icon }}</ui-icon>
            </div>
            <slot name='append-icon' />
            <div v-if='!!size' class='documents-items-grid-item__size'>
                {{ size }}
            </div>
            <div class='documents-items-grid-item__title' :class='{"without-spaces": titleWithoutSpaces}'>
                <hra-tooltip :text='title'>
                    {{ title }}
                </hra-tooltip>
            </div>
            <div v-if='$slots.controls != null' class='documents-items-grid-item__controls'>
                <slot name='controls' />
            </div>
            <div v-if='$slots.checkbox != null' class='documents-items-grid-item__checkbox'>
                <slot name='checkbox' />
            </div>
        </div>
    </div>
</template>

<script>
import UiIcon from '@/domain/components/UiIcon.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import HraTooltip from '@/components/ui/HraTooltip.vue'

export default {
    name: 'GridItem',
    components: { HraTooltip, FormCheckbox, UiIcon },
    props: {
        icon: { type: String, default: 'folder' },
        size: { type: String, default: null },
        title: { type: String, default: '' }
    },
    computed: {
        titleWithoutSpaces() {
            return this.title.indexOf(' ') === -1
        }
    }
}
</script>

<style lang='scss'>
.documents-items-grid-item {
    width: 100%;

    &__container {
        display: block;
        position: relative;
        cursor: pointer;
        width: 100%;
        overflow: hidden;
    }

    &__icon {
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-size: 56px;
        color: #293080;
    }

    &__size {
        margin-top: 6px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        color: #70778C;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    &__title {
        margin-top: 6px;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;

        &.without-spaces {
            word-break: break-all;
        }
    }

    &__checkbox {
        position: absolute;
        top: 5px;
        left: 5px;
    }

    &__controls {
        position: absolute;
        right: 5px;
        top: 5px;

        .ui-button {
            margin: 0 !important;
            padding: 0 !important;
            min-height: auto !important;
        }
    }
}
</style>