<template>
	<Form
		v-model="code"
		@submit="onSubmit"
		:form-loader-name="loaderName"
	>
		<template #form>
			<v-row>
				<v-col cosl="12">
					<FormTextField
						:rules="'required|min:6,simbolių|max:6,' + $t('simbolių')"
						v-model="code"
						name="code"
						:placeholder="$t('6 skaitmenų kodas')"
					/>
				</v-col>
				<v-col cols="12">
					<form-alert v-if="message" v-bind="message" />
				</v-col>
			</v-row>
		</template>
		<template #buttons>
			<hra-button full-width class="mt-7" :color="uiColor.primary"
			            :loading="loadingButton" :type="buttonType.submit">{{ $t("Patvirtinti") }}
			</hra-button>
		</template>
	</Form>
</template>

<script>
import Form from "@/components/ui/form/Form.vue";
import FormTextField from "@/components/ui/form/FormTextField.vue";
import Button from "@/components/ui/button/Button.vue";
import {mapGetters} from "vuex";
import {appRouteProps} from "@/router/app";
import HraButton from "@/components/ui/button/HraButton";
import FormAlert from "@/components/ui/form/FormAlert";

export default {
	components: {
		FormAlert,
		HraButton,
		Form,
		FormTextField,
		Button,
	},
	inject: ["authService"],
	data() {
		return {
			message: null,
			loadingButton: false,
			code: "",
		};
	},
	computed: {
		...mapGetters({
			setupActive: "user/setupActive",
			setupStep: "user/setupStep",
		}),
	},
	methods: {
		async onSubmit(code) {
			try {
				this.loadingButton = true;
				await this.authService.twoFactor(code);
			} catch (error) {
				this.message = {
					type: "error",
					text: error.message,
				}
			} finally {
				this.loadingButton = false;
			}
		},
	},
};
</script>
