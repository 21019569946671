<template>
	<custom-dialog :max-width="521">
		<template #title>{{ $t('Keisti vadovą') }}</template>
		<template #content>
			<validation-form>
				<v-row class="pa-3">
					<v-col cols="12">
						<select-field
							disabled
							name="departmentId"
							:label="$t('Skyrius')"
							:options="departmentOptions"
							v-model="departmentId"
						/>
					</v-col>
					<v-col cols="12" v-if="employeeId != null">
						<select-field
							disabled
							name="employeeId"
							:label="$t('Dabartinis vadovas')"
							:single-line="false"
							:options-service="employeeAutocompleteService.findWithIgnoreIds()"
							v-model="employeeId"
							dynamic
						>
							<template #selection="{item}">
								<employee-table-view class="py-1" :employee="item.employee"/>
							</template>
							<template #item="{item}">
								<employee-table-view class="py-1" :employee="item.employee"/>
							</template>
						</select-field>
					</v-col>
					<v-col cols="12">
						<select-field
							name="employeeId"
							:label="$t('Naujas vadovas (neprivalomas)')"
							:single-line="false"
							:options-service="employeeAutocompleteService.findWithIgnoreIds()"
							v-model="formData.employeeId"
							dynamic filtered clearable
						>
							<template #selection="{item}">
								<employee-table-view class="py-1" :employee="item.employee"/>
							</template>
							<template #item="{item}">
								<employee-table-view class="py-1" :employee="item.employee"/>
							</template>
						</select-field>
					</v-col>
					<v-col cols="12">
						<message-alerts :listen="[tagId]"/>
					</v-col>
				</v-row>
			</validation-form>
		</template>

		<template #footer>
			<ui-button
				prepend-icon="close"
				background="default"
				@click="close"
			>{{ $t('Uždaryti') }}
			</ui-button>
			<ui-button
				prepend-icon="ok"
				background="primary"
				@click="onSave"
			>{{ $t('Pakeisti') }}
			</ui-button>
		</template>
	</custom-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialogMixin";
import HraIcon from "@/components/ui/HraIcon.vue";
import Form from "@/components/ui/form/Form.vue";
import TextField from "@/domain/fields/TextField.vue";
import MessageAlerts from "@/domain/messages/MessageAlerts.vue";
import SelectField from "@/domain/fields/SelectField.vue";
import customApi from "@/plugins/customApi";
import UiButton from "@/domain/components/UiButton.vue";
import EmployeeTableView from "@/components/ui/views/EmployeeTableView.vue";
import departmentModalMixin from "@/domain/company/mixins/departmentModalMixin";
import ValidationForm from "@/domain/components/ValidationForm.vue";
import CustomDialog from "@/components/ui/dialog/CustomDialog.vue";
import {GLOBAL_TOAST_TAG} from "@/utils/default";

export default {
	name: "DepartmentChangeLeader",
	mixins: [dialogMixin, departmentModalMixin],
	components: {
		CustomDialog,
		ValidationForm,
		EmployeeTableView,
		UiButton, SelectField, MessageAlerts, TextField, Form, HraIcon
	},
	props: {
		employeeId: {type: [Number, String], default: null},
		departmentId: {type: [Number, String], required: true},
	},
	data() {
		return {
			formData: {
				departmentId: null,
				employeeId: null,
			},
		};
	},
	created() {
		this.getDepartments();
	},
	beforeDestroy() {
		this.$store.dispatch("messagesStore/clearMessages", this.tagId);
	},
	methods: {
		async onSave() {
			const api = customApi(this.tagId);
			const response = await api.request(api.factories.company.departments.leaders.change(this.departmentId, {
				employeeId: this.formData.employeeId
			}));

			if (response == null) {
				return;
			}

			this.$emit("saved");

			await this.$store.dispatch("messagesStore/showSuccessMessage", {
				tag: GLOBAL_TOAST_TAG,
				message: this.$t("Sėkmingai pakeista")
			});

			this.close();
		}
	},
}
</script>