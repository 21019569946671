<template>
    <div :class="classes">
        <i v-if="icon" :class="iconClasses" />
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            default: ''
        },
        iconColor: {
            type: String,
            default: ''
        },
        color: {
            type: String,
            default: '',
        },
        backgroundColor: {
            type: String,
            default: ''
        },
    },
    computed: {
        classes() {
            let classes = [
                this.$attrs.class,
                'text-decorate',
            ];
            if (this.color) {
                classes.push('text-' + this.color);
            }
            if (this.backgroundColor) {
                classes.push('bg-' + this.backgroundColor);
            }
            return classes;
        },
        iconClasses() {
            let classes = [];
            if (this.icon) {
                classes.push('icon-' + this.icon);
            }
            if (this.iconColor) {
                classes.push('text-' + this.iconColor);
            }
            return classes;
        }
    }
}
</script>
