<template>
	<Auth :fullPageLoader="isLoading">
		<template #header>{{ $t("Saugumas") }}</template>
		<template #form>
			<EnableTwoFactorLoginForm/>
		</template>
		<template #after-card>
			<hra-button full-width no-background :text-width="buttonTextWidth.normal" @click="onIgnore"
			>{{ $t("Praleisti šį žingsnį") }}</hra-button>
		</template>
	</Auth>
</template>

<script>
import Auth from "@/components/Auth.vue";
import EnableTwoFactorLoginForm from "@/views/forms/auth/EnableTwoFactorLoginForm.vue";
import Button from "@/components/ui/button/Button.vue";
import apiAuth from "@/api/auth/apiAuth";
import HraButtonLink from "@/components/ui/button/HraButtonLink";
import HraButton from "@/components/ui/button/HraButton";

export default {
	components: {
		HraButton,
		HraButtonLink,
		Auth,
		EnableTwoFactorLoginForm,
		Button,
	},
	data() {
		return {
			loaderName: "Auth-Get-Qr-Code",
			translatePrefix: "page.auth.enableTwoFaction.",
		};
	},
	inject: ["authService"],
	methods: {
		async onIgnore() {
			await this.authService.ignoreTwoFactor();
		},
	}
};
</script>
