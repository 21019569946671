<template>
    <ui-table
        v-if='mode === "list"' class='documents-items-list-table'
        :is-empty-table='items.length === 0'
    >
        <template #head>
            <tr style='position: sticky; top: 0; z-index: 9'>
                <th>{{ $t('Dokumento pavadinimas') }}</th>
                <th v-if='withStatus'>{{ $t('Statusas') }}</th>
                <th v-if='withStatus'>{{ $t('Patvirtino') }}</th>
                <th>{{ $t('Dydis') }}</th>
                <th>{{ $t('Įkėlimo data') }}</th>
                <th>{{ $t('Redaguota') }}</th>
                <th v-if='withDeleteInfo'>{{ $t('Suarchyvuotas') }}</th>
                <th v-if='withControls'>{{ $t('Funkcijos') }}</th>
            </tr>
        </template>

        <template #body>
            <template v-for='item in items'>
                <list-folder-item
                    v-if='item.isFolder'
                    :key='`list-folder-${item.id}`'
                    :item='item'
                />
                <list-document-item
                    v-if='item.isDocument'
                    :key='`list-document-${item.id}`'
                    :item='item'
                />
                <list-archived-document-item
                    v-if='item.isArchivedDocument'
                    :key='`list-archived-document-${item.id}`'
                    :item='item'
                />
            </template>
<!--            <list-group-folder-item-->
<!--                v-for='item in groupsFolders'-->
<!--                :key='`list-group-folder-${item.id}`'-->
<!--                :item='item'-->
<!--            />-->
<!--            <list-group-item-->
<!--                v-for='item in groups'-->
<!--                :key='`list-group-${item.id}`'-->
<!--                :item='item'-->
<!--            />-->
<!--            <list-archived-document-item-->
<!--                v-for='item in archivedDocuments'-->
<!--                :key='`list-archived-document-${item.id}`'-->
<!--                :item='item'-->
<!--            />-->
        </template>
        <template #empty-table>
            <message-alert
                id='table-alert-empty'
                :message='$t("Tuščias aplankas. Norėdami pridėti dokumentą, paspauskite mygtuką „Įkelti dokumentus“.")'
                type='info'
            />
        </template>
    </ui-table>
</template>

<script>
import documentsItemsFoldersMixin from '@/domain/documents/items/mixins/documentsItemsFoldersMixin'
import UiTable from '@/domain/components/uiTable/UiTable.vue'
import ListFolderItem from '@/domain/documents/finder/components/content/list/ListFolderItem.vue'
import ListDocumentItem from '@/domain/documents/finder/components/content/list/ListDocumentItem.vue'
import ListArchivedDocumentItem from '@/domain/documents/finder/components/content/list/ListArchivedDocumentItem.vue'
import ListGroupFolderItem from '@/domain/documents/finder/components/content/list/ListGroupFolderItem.vue'
import ListGroupItem from '@/domain/documents/finder/components/content/list/ListGroupItem.vue'
import MessageAlert from '@/domain/messages/components/MessageAlert.vue'

export default {
    name: 'DocumentsItemsList',
    components: { MessageAlert, ListGroupItem, ListGroupFolderItem, ListArchivedDocumentItem, ListDocumentItem, ListFolderItem, UiTable },
    mixins: [documentsItemsFoldersMixin]
}
</script>

<style lang='scss'>
.documents-items-list-table {
    overflow: visible;

    table {
        //width: 100%;
    }
}
</style>