var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"avatar-cropper"},[_c('cropper',{ref:"cropper",staticClass:"avatar-cropper__root",attrs:{"src":_vm.image,"image-restriction":"stencil","stencil-component":"circle-stencil","stencil-size":{width: 312, height: 312},"stencil-props":{
				lines: {},
        handlers: {},
				previewClass: 'avatar-cropper__preview',
				resizable: false,
        movable: false,
        scalable: false,
        aspectRatio: 1,
			},"debounce":false,"transitions":false,"auto-zoom":true},on:{"change":_vm.onChange}}),_c('div',{staticClass:"d-flex justify-center align-center gap-3 my-9"},[_c('hra-button',{attrs:{"only-icon":"","no-background":""},on:{"click":_vm.onZoomOut}},[_c('hra-icon',{attrs:{"name":_vm.iconName.minus}})],1),_c('input',{staticClass:"avatar-cropper__range",attrs:{"type":"range","min":"0.01","max":"0.9","step":"0.001"},domProps:{"value":_vm.zoom},on:{"change":_vm.onZoom}}),_c('hra-button',{attrs:{"only-icon":"","no-background":""},on:{"click":_vm.onZoomIn}},[_c('hra-icon',{attrs:{"name":_vm.iconName.plus}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }