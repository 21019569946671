<template>
	<tr class="hra-calendar__week">
		<hra-calendar-week-days
			v-for="({days, event}, key) in week"
			:key="`week-${key}`" :days="days" :event="event"
		/>
	</tr>
</template>

<script>
import HraCalendarDay from "@/components/ui/calendar/HraCalendarDay";
import HraCalendarWeekDays from "@/components/ui/calendar/HraCalendarWeekDays";
export default {
	name: "HraCalendarWeek",
	components: {HraCalendarWeekDays, HraCalendarDay},
	props: {
		week: {type: Array, required: true},
	}
}
</script>
