<template>
    <grid-item
        :title='archiveDocument.title'
        :size='documentSize'
        icon='paper'
    >
        <template v-if='withControls' #controls>
            <documents-finder-context-menu>
                <documents-finder-context-menu-item
                    icon='restore'
                    :title='$t("Grąžinti")'
                    @click='handleOpenRestoreDocumentModal'
                />
                <documents-finder-context-menu-item
                    icon='eye'
                    :title='$t("Peržiūrėti")'
                    @click='handleOpenPreviewDocumentModal'
                />
                <documents-finder-context-menu-item
                    icon='bin'
                    :title='$t("Ištrinti dokumentą")'
                    @click='handleOpenWipeDocumentModal'
                />
            </documents-finder-context-menu>
        </template>
    </grid-item>
</template>

<script>
import DocumentsFinderContextMenuItem from '@/domain/documents/finder/components/ContextMenuItem.vue'
import DocumentsFinderContextMenu from '@/domain/documents/finder/components/ContextMenu.vue'
import UiIcon from '@/domain/components/UiIcon.vue'
import GridItem from '@/domain/documents/finder/components/content/grid/GridItem.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import documentFinderDocumentItemMixin from '@/domain/documents/finder/mixins/documentFinderDocumentItemMixin'
import HraTooltip from '@/components/ui/HraTooltip.vue'
import documentFinderArchivedDocumentItemMixin
    from '@/domain/documents/finder/mixins/documentFinderArchivedDocumentItemMixin'

export default {
    name: 'GridArchivedDocumentItem',
    components: { HraTooltip, FormCheckbox, GridItem, DocumentsFinderContextMenuItem, DocumentsFinderContextMenu, UiIcon },
    mixins: [documentFinderArchivedDocumentItemMixin],
    computed: {
        documentSize() {
            return this.archiveDocument.sizeText
        }
    }
}
</script>