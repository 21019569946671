import Vue from 'vue'
import { getDefaultState } from '@/domain/vacationModal/store/state'

export const SET_AS_ADMIN = 'SET_AS_ADMIN'
export const SET_SEND_INVITE_FOR_EMPLOYEE_AND_LEADER = 'SET_SEND_INVITE_FOR_EMPLOYEE_AND_LEADER'
export const SET_EVENTS = 'SET_EVENTS'
export const SET_DEPUTIES = 'SET_DEPUTIES'
export const SET_USER = 'SET_USER'
export const SET_CURRENT_EVENT = 'SET_CURRENT_EVENT'
export const SET_CURRENT_EVENT_ABORTED = 'SET_CURRENT_EVENT_ABORTED'
export const SET_ACTIONS = 'SET_ACTIONS'
export const UPDATE_CURRENT_EVENT = 'UPDATE_CURRENT_EVENT'
export const RESTORE_TO_DEFAULT_STATE = 'RESTORE_TO_DEFAULT_STATE'

export default ({
    [SET_AS_ADMIN]: (state, payload) => {
        state.asAdmin = payload
    },
    [SET_SEND_INVITE_FOR_EMPLOYEE_AND_LEADER]: (state, payload) => {
        state.sendInviteForEmployeeAndLeader = payload
    },
    [SET_EVENTS]: (state, payload) => {
        state.events = payload
    },
    [SET_DEPUTIES]: (state, payload) => {
        Vue.set(state.currentEvent, 'deputies', payload)
    },
    [SET_USER]: (state, payload) => {
        state.user = payload
    },
    [SET_CURRENT_EVENT]: (state, payload) => {
        state.currentEvent = payload
    },
    [SET_CURRENT_EVENT_ABORTED]: (state) => {
        state.currentEventAborted = true
    },
    [SET_ACTIONS]: (state, payload) => {
        state.actions = payload
    },
    // payload => {field: 'test', value: 'test'}
    [UPDATE_CURRENT_EVENT]: (state, { field, value }) => {
        Vue.set(state.currentEvent, field, value)
    },
    [RESTORE_TO_DEFAULT_STATE]: (state) => {
        Object.assign(state, Object.assign(state, getDefaultState()))
    }
})