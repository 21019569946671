<template>
    <div class='page max-page-width'>
        <v-row class='mx-6'>
            <v-col cols='12' class='d-flex align-end my-3 gap-3'>
                <datepicker-field
                    :label="$t('Nuo')"
                    :type='calendarType'
                    :value='fromDate'
                    @update:value='handleChangeFromDate'
                />
                <datepicker-field
                    :min-date='fromDate'
                    :label="$t('Iki')"
                    :type='calendarType'
                    :value='toDate'
                    @update:value='handleChangeToDate'
                />
                <select-field
                    :label="$t('Grupavimas')"
                    :options='groupByOptions'
                    v-model='groupBy'
                    @input='handleChangeGroupBy'
                />
                <ui-button @click='handleFetchReport'>{{ $t('Rodyti ekrane') }}</ui-button>
                <ui-button background='primary' @click='handleDownloadReport'>{{ $t('Atsiųsti .xlsx') }}
                </ui-button>
            </v-col>

            <v-col cols='12' class='pb-3'>
                <ui-table
                    :loading='loading'
                    :is-empty-table='this.rows.length === 0'
                >
                    <template #empty-table>
                        <message-alert
                            id='table-alert-empty'
                            :message='$t("Įrašų sistemoje nerasta.")'
                            type='info'
                        />
                    </template>

                    <template #head>
                        <tr>
                            <th>{{ $t('Vardas Pavardė') }}</th>
                            <th>{{ $t('Tabelio Nr.') }}</th>
                            <th>{{ $t('Skyrius') }}</th>
                            <th>{{ $t('Pareigos') }}</th>
                            <th v-if='groupBy === "day"'>{{ $t('Data') }}</th>
                            <th v-if='["year", "month"].includes(groupBy)'>{{ $t('Metai') }}</th>
                            <th v-if='groupBy === "month"'>{{ $t('Mėnuo') }}</th>
                            <th v-if='["year", "month"].includes(groupBy)'>{{ $t('Dienu sk.') }}</th>
                            <th v-if='groupBy === "day"'>{{ $t('Nuotolinio darbo vietos') }}</th>
                        </tr>
                    </template>

                    <template #body>
                        <tr v-for='(row, key) in rows' :key='key'>
                            <td>{{ row.user.name }}</td>
                            <td>{{ row.user.timeCardNumber }}</td>
                            <td>{{ row.user.department }}</td>
                            <td>{{ row.user.duty }}</td>
                            <td v-if='groupBy === "day"'>{{ row.date }}</td>
                            <td v-if='["year", "month"].includes(groupBy)'>{{ row.year }}</td>
                            <td v-if='groupBy === "month"'>{{ row.month }}</td>
                            <td v-if='["year", "month"].includes(groupBy)'>{{ row.count }}</td>
                            <td v-if='groupBy === "day"'>{{ row.address }}</td>
                        </tr>
                    </template>

                </ui-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import moment from 'moment'
import CustomApi from '@/plugins/customApi'
import DatepickerField from '@/domain/fields/DatepickerField.vue'
import SelectField from '@/domain/fields/SelectField.vue'
import UiButton from '@/domain/components/UiButton.vue'
import UiTable from '@/domain/components/uiTable/UiTable.vue'
import MessageAlert from '@/domain/messages/components/MessageAlert.vue'

const customApi = CustomApi()
export default {
    name: 'RemoteWorkReportPage',
    components: { MessageAlert, UiTable, UiButton, SelectField, DatepickerField },
    data() {
        const today = moment().format('YYYY-MM-DD')

        return {
            loading: false,
            fromDate: today,
            toDate: today,
            groupBy: 'day',
            groupByOptions: [
                { value: 'day', text: this.$t('Diena') },
                { value: 'month', text: this.$t('Mėnuo') },
                // { value: 'year', text: this.$t('Metai') }
            ],
            rows: []
        }
    },
    computed: {
        calendarType() {
            return {
                'day': 'date',
                'month': 'month',
                'year': 'year'
            }[this.groupBy]
        }
    },
    methods: {
        formatFromDateByType() {
            const fromDate = {
                'day': this.fromDate,
                'month': moment(this.fromDate).startOf('month').format('YYYY-MM-DD'),
                'year': moment(this.fromDate).startOf('year').format('YYYY-MM-DD')
            }[this.groupBy]

            if (fromDate !== this.fromDate) {
                this.fromDate = fromDate
            }
        },
        formatToDateByType() {
            const toDate = {
                'day': this.toDate,
                'month': moment(this.toDate).endOf('month').format('YYYY-MM-DD'),
                'year': moment(this.toDate).endOf('year').format('YYYY-MM-DD')
            }[this.groupBy]

            if (toDate !== this.toDate) {
                this.toDate = toDate
            }
        },
        handleChangeFromDate(date) {
            this.fromDate = {
                'day': date,
                'month': moment(date).startOf('month').format('YYYY-MM-DD'),
                'year': moment(date).startOf('year').format('YYYY-MM-DD')
            }[this.groupBy]
        },
        handleChangeToDate(date) {
            this.toDate = {
                'day': date,
                'month': moment(date).endOf('month').format('YYYY-MM-DD'),
                'year': moment(date).endOf('year').format('YYYY-MM-DD')
            }[this.groupBy]
        },
        getRequestParams() {
            return {
                fromDate: this.fromDate,
                toDate: this.toDate,
                groupBy: this.groupBy
            }
        },
        handleChangeGroupBy() {
            this.formatFromDateByType()
            this.formatToDateByType()

            this.rows = []
            this.handleFetchReport()
        },
        async handleFetchReport() {
            this.loading = true
            try {
                const { data } = await customApi.factories.events.remoteWork.reports.fetch(this.getRequestParams())

                if (data.length === 0) {
                    this.rows = []
                    return
                }

                this.rows = data
            } catch (e) {
                console.error(e)
                this.rows = []
            } finally {
                this.loading = false
            }
        },
        handleDownloadReport() {
            window.location.href = `/api/events/remote-work/reports/download?fromDate=${this.fromDate}&toDate=${this.toDate}&groupBy=${this.groupBy}`
        }
    }
}
</script>