export default axios => ({
    checkDays: (type, fromDate, toDate) => axios.get(`/api/v2/events/${type}/days?fromDate=${fromDate}&toDate=${toDate}`),
    balance: (type, date, employeeId, ignoreEventId = null) => axios.get(`/api/v2/events/${type}/employees/${employeeId}/balance/${date}`),

    list: {
        fetch: (params) => axios.get(`/api/events/list`, { params })
    },

    remoteWork: {
        fetchOne: (id) => axios.get(`/api/events/remote-work/${id}`),
        store: (payload) => axios.post(`/api/events/remote-work`, payload),
        change: (id, payload) => axios.put(`/api/events/remote-work/${id}`, payload),
        abort: (id) => axios.post(`/api/events/remote-work/${id}/abort`),
        leader: {
            approve: (id, payload) => axios.post(`/api/events/remote-work/${id}/leader/approve`, payload),
            abort: (id, payload) => axios.post(`/api/events/remote-work/${id}/leader/abort`, payload)
        },
        reports: {
            fetch: (params) => axios.get(`/api/events/remote-work/reports`, { params })
        }
        // admin: {
        //     approve: (id, payload) => axios.post(`/api/events/remote-work/${id}/admin/approve`, payload),
        //     abort: (id, payload) => axios.post(`/api/events/remote-work/${id}/admin/abort`, payload),
        // }
    },

    holiday: {
        fetchActivityReport: (params) => axios.get(`/api/events/holidays/reports/activity`, { params }),
    },
    sickLeaves: {
        fetchOne: (id) => axios.get(`/api/events/sick-leaves/${id}`),
        fetchReport: (params) => axios.get(`/api/events/sick-leaves/report`, { params }),
        fetchTypes: () => axios.get(`/api/events/sick-leaves/types`),
        createMany: (payload) => axios.post(`/api/events/sick-leaves/many`, payload),
        import: (payload) => axios.post(`/api/events/sick-leaves/import`, payload, { 'Content-Type': 'multipart/form-data' }),
        update: (id, payload) => axios.put(`/api/events/sick-leaves/${id}`, payload),
        abortById: (id) => axios.post(`/api/events/sick-leaves/${id}/abort`),
    },
    businessTrip: {
        fetchById: (id) => axios.get(`/api/events/business-trips/${id}`),
        fetchReport: (params) => axios.get(`/api/events/business-trips/report`, { params }),
        create: (method, payload) => axios.post(`/api/events/business-trips/${method}`, payload),
        change: (id, method, payload) => axios.put(`/api/events/business-trips/${id}/${method}`, payload),
        abort: (id, method) => axios.post(`/api/events/business-trips/${id}/${method}/abort`),
        approve: (id, method) => axios.post(`/api/events/business-trips/${id}/${method}/approve`),
    },
    statuses: {
        fetchOne: (eventId) => axios.get(`/api/events/statuses/${eventId}`),
        fetchOneWithDetails: (eventId) => axios.get(`/api/events/statuses/${eventId}/details`)
    }
})