<template>
	<v-row>
		<v-col cols="6">
			<message-alerts :listen="[tag]" />
			<file-field
				name="attachment"
				label="Attachment"
				v-model="attachment"
				:accept-types="['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']"
				:max-file-size="100"
			/>

			<hra-button class="mt-3" @click="onClick" :loading="loading">Importuoti</hra-button>
		</v-col>
	</v-row>
</template>

<script>
import HraFormFile from "@/components/ui/form/HraFormFile";
import FormFileButton from "@/components/ui/form/FormFileButton";
import HraButton from "@/components/ui/button/HraButton";
import FileField from "@/domain/fields/FileField";
import debuggerService from "@/domain/debugger/services/debuggerService";
import MessageAlerts from "@/domain/messages/MessageAlerts";
export default {
	name: "DebuggerVacations",
	components: {MessageAlerts, FileField, HraButton, FormFileButton, HraFormFile},
	data() {
		return {
			tag: "DEBUGGER_IMPORT_VACATIONS",
			attachment: null,
		};
	},
	computed: {
		loading() {
			return this.$store.getters["loadersStore/hasLoading"](this.tag);
		},
	},
	methods: {
		async onClick() {
			if (await debuggerService.importVacations(this.tag, this.attachment)) {
				this.attachment = null;
			}
		},
	},
}
</script>