//  initial state

const initState = () => ({
  data: [],
});

const state = () => initState();

// getters
const getters = {
  rows: state => state.data
};

// actions
const actions = {
  init: ({commit}, payload) => {
    commit("init", payload);
  },
  create: ({commit}, payload) => {
    commit("create", payload);
    commit("sort");
  },
  update: ({commit}, payload) => {
    commit("update", payload);
    commit("sort");
  },
  remove: ({commit}, payload) => {
    commit("remove", payload);
  },
  destroy: ({commit}) => commit('destroy'),
};

//  mutations
const mutations = {
  init: (state, payload) => state.data = payload,
  sort: state => state.data = state.data.sort((a, b) => {
    if (a.fromDate > b.fromDate) return -1;
    if (a.fromDate < b.fromDate) return 1;
    return 0;
  }),
  create: (state, payload) => state.data.push(payload),
  update: (state, payload) => state.data = state.data.map(item => item.id === payload.id ? payload : item),
  remove: (state, payload) => state.data = state.data.filter(item => item.id !== payload),
  destroy: state => Object.assign(state, initState()),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
