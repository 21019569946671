<template>
	<custom-dialog :max-width="521">
		<template #title>{{ $t('Redaguoti pareigybę') }}</template>
		<template #content>
			<validation-form>
				<v-row class="pa-3">
					<v-col cols="12">
						<text-field
							rules="required"
							name="name"
							:label="$t('Pavadinimas')"
							v-model="formData.name"
						/>
					</v-col>
					<v-col cols="12">
						<message-alerts :listen="[UPDATE_DUTY_TAG]"/>
					</v-col>
				</v-row>
			</validation-form>
		</template>

		<template #footer>
			<ui-button
				prepend-icon="close"
				background="default"
				@click="close"
			>{{ $t('Uždaryti') }}
			</ui-button>
			<ui-button
				prepend-icon="ok"
				background="primary"
				@click="onSave"
			>{{ $t('Pakeisti') }}
			</ui-button>
		</template>
	</custom-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialogMixin";
import Form from "@/components/ui/form/Form.vue";
import TextField from "@/domain/fields/TextField.vue";
import MessageAlerts from "@/domain/messages/MessageAlerts.vue";
import SelectField from "@/domain/fields/SelectField.vue";
import UiButton from "@/domain/components/UiButton.vue";
import EmployeeTableView from "@/components/ui/views/EmployeeTableView.vue";
import ValidationForm from "@/domain/components/ValidationForm.vue";
import CustomDialog from "@/components/ui/dialog/CustomDialog.vue";
import {UPDATE_DUTY_TAG} from "@/domain/company/store/tags";
import {GLOBAL_TOAST_TAG} from "@/utils/default";

export default {
	name: "DutyUpdateModal",
	mixins: [dialogMixin],
	components: {
		CustomDialog,
		ValidationForm,
		EmployeeTableView,
		UiButton, SelectField, MessageAlerts, TextField, Form
	},
	props: {
		id: {type: Number, required: true},
		name: {type: String, required: true},
	},
	data() {
		return {
			UPDATE_DUTY_TAG,
			formData: {
				name: "",
			},
		};
	},
	created() {
		this.formData.name = this.name;
	},
	methods: {
		async onSave() {
			if (!await this.$store.dispatch("companyStore/updateDuty", {id: this.id, payload: this.formData})) {
				return;
			}

			await this.$store.dispatch("messagesStore/showSuccessMessage", {
				tag: GLOBAL_TOAST_TAG,
				message: this.$t("Sėkmingai pakeista")
			});

			this.close();
		}
	},
}
</script>