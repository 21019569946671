var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{attrs:{"loader-listen":_vm.initTag,"message-listen":_vm.initTag}},[_c('ui-button',{attrs:{"modal":{name: 'EventModal', props: {employeeId: 1}}}},[_vm._v(" new event ")]),_c('div',{staticClass:"d-flex align-end pa-6 gap-6"},[_c('select-field',{attrs:{"name":"employeeId","label":'Darbuotojas',"single-line":false,"options-service":_vm.employeeAutocompleteService.find,"dynamic":"","filtered":""},model:{value:(_vm.filter.employeeId),callback:function ($$v) {_vm.$set(_vm.filter, "employeeId", $$v)},expression:"filter.employeeId"}}),_c('datepicker-field',{staticStyle:{"width":"250px"},attrs:{"name":"fromDate","label":'Data nuo'},model:{value:(_vm.filter.fromDate),callback:function ($$v) {_vm.$set(_vm.filter, "fromDate", $$v)},expression:"filter.fromDate"}}),_c('datepicker-field',{staticStyle:{"width":"250px"},attrs:{"name":"toDate","label":'Data iki',"min-date":_vm.filter.fromDate},model:{value:(_vm.filter.toDate),callback:function ($$v) {_vm.$set(_vm.filter, "toDate", $$v)},expression:"filter.toDate"}}),_c('hra-button',{on:{"click":_vm.onRefresh}},[_vm._v("Refresh")])],1),_c('div',{staticClass:"d-flex flex-column gap-4 px-6 mb-3"},[_c('hra-table',{attrs:{"headers":_vm.headersCalculate,"rows":_vm.calculations},scopedSlots:_vm._u([{key:"header.custom",fn:function(){return [_c('h2',[_vm._v("Paskaičiavimai")])]},proxy:true},{key:"col.type",fn:function(ref){
var colValue = ref.colValue;
return [_vm._v(_vm._s(_vm.vacationType[colValue]))]}},{key:"col.accumulated",fn:function(ref){
var colValue = ref.colValue;
return [_vm._v(_vm._s(colValue.toFixed(4)))]}},{key:"col.reserved",fn:function(ref){
var colValue = ref.colValue;
return [_vm._v(_vm._s(colValue.toFixed(4)))]}},{key:"col.balance",fn:function(ref){
var colValue = ref.colValue;
return [_vm._v(_vm._s(colValue.toFixed(4)))]}}])}),_c('hra-table',{attrs:{"headers":_vm.headersContract,"rows":_vm.contracts,"need-actions":"","expand-multiple-row":"","expand-row-ids":_vm.contractExpandIds},on:{"update:expandRowIds":function($event){_vm.contractExpandIds=$event},"update:expand-row-ids":function($event){_vm.contractExpandIds=$event}},scopedSlots:_vm._u([{key:"header.custom",fn:function(){return [_c('h2',[_vm._v("Sutartis")])]},proxy:true},{key:"col.actions-base",fn:function(ref){
var row = ref.row;
var expandRow = ref.expandRow;
return [_c('hra-tooltip',{attrs:{"text":_vm.$t(row.isExpand ? 'Suskleisti' : 'Išskleisti')}},[_c('hra-button',{attrs:{"size":_vm.buttonSize.small,"color":_vm.uiColor.default,"hover-background":!row.isExpand},on:{"click":function () { return expandRow(row.id); }}},[_c('v-fab-transition',[_c('div',{key:((row.isExpand ? 1 : 0) + "-expand")},[_c('hra-icon',{attrs:{"name":row.isExpand ? _vm.iconName.up : _vm.iconName.down}})],1)])],1)],1)]}},{key:"row-expansion",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"pa-3"},[_c('hra-table',{attrs:{"headers":_vm.headersContractDetail,"rows":row.details}})],1)]}}])}),_c('hra-table',{attrs:{"headers":_vm.headersDisability,"rows":_vm.disabilities},scopedSlots:_vm._u([{key:"header.custom",fn:function(){return [_c('h2',[_vm._v("Neįgalumai")])]},proxy:true}])}),_c('hra-table',{attrs:{"headers":_vm.headersPlusVacation,"rows":_vm.plusVacations},scopedSlots:_vm._u([{key:"header.custom",fn:function(){return [_c('h2',[_vm._v("Plus atostogos")])]},proxy:true},{key:"col.calcInYear",fn:function(ref){
var colValue = ref.colValue;
return [_vm._v(_vm._s(colValue ? 'Yes' : 'No'))]}},{key:"col.count",fn:function(ref){
var colValue = ref.colValue;
return [_vm._v(_vm._s(colValue.toFixed(4)))]}}])}),_c('hra-table',{attrs:{"headers":_vm.headersVacation,"rows":_vm.vacations},scopedSlots:_vm._u([{key:"header.custom",fn:function(){return [_c('h2',[_vm._v("Atostogos")])]},proxy:true},{key:"col.status",fn:function(ref){
var colValue = ref.colValue;
return [_vm._v(_vm._s(_vm.vacationStatus[colValue]))]}},{key:"col.typeId",fn:function(ref){
var colValue = ref.colValue;
return [_vm._v(_vm._s(_vm.vacationType[colValue]))]}}])}),_c('hra-table',{attrs:{"headers":_vm.headersChild,"rows":_vm.child},scopedSlots:_vm._u([{key:"header.custom",fn:function(){return [_c('h2',[_vm._v("Vaikai")])]},proxy:true},{key:"col.disability",fn:function(ref){
var colValue = ref.colValue;
return [_vm._v(_vm._s(colValue ? 'Yes' : 'No'))]}}])}),_c('hra-table',{attrs:{"headers":_vm.headersRatios,"rows":_vm.ratios},scopedSlots:_vm._u([{key:"header.custom",fn:function(){return [_c('h2',[_vm._v("Logai")])]},proxy:true},{key:"col.ratio",fn:function(ref){
var colValue = ref.colValue;
return [_vm._v(_vm._s(colValue.toFixed(4)))]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }