<template>
    <folder-breadcrumbs :breadcrumbs='items' @change-folder-id='handleChangeFolderId' />
</template>

<script>
import UiIcon from '@/domain/components/UiIcon.vue'
import FolderBreadcrumbs from '@/domain/documents/components/FolderBreadcrumbs.vue'

export default {
    name: 'DocumentsFinderSubHeaderBreadcrumbs',
    components: { FolderBreadcrumbs, UiIcon },
    props: {
        default: {type: Array, default: () => []}
    },
    inject: ['storeNamespace'],
    computed: {
        items() {
            const list = [
                ...this.default,
                ...this.$store.getters[`${this.storeNamespace}/breadcrumbs`]
            ]

            const lastKey = list.length - 1
            const currentFolderId = this.$store.getters[`${this.storeNamespace}/folderId`]

            return list.map((breadcrumb, key) => {
                return {
                    title: breadcrumb.title,
                    currentFolder: breadcrumb.id === currentFolderId,
                    folderId: breadcrumb.id,
                    isLast: key === lastKey
                }
            })
        }
    },
    methods: {
        handleChangeFolderId(folderId) {
            this.$store.dispatch(`${this.storeNamespace}/breadcrumbs/changeId`, folderId)
        }
    }
}
</script>