<template>
    <button
        class='ui-button'
        :type='type'
        :class='buttonClasses'
        v-bind='$attrs'
        v-on='loading || disabled ? null : $listeners'
        @click='onOpenModal'
    >
        <ui-icon v-if='prependIcon'>{{ prependIcon }}</ui-icon>
        <span v-if='$slots.default' class='ui-button__text'><slot /></span>
        <ui-icon v-if='appendIcon'>{{ appendIcon }}</ui-icon>
        <span class='ui-button__spinner' :style='{display: loading ? "block" : "none"}'>
            <v-progress-circular indeterminate color='black' :size='20' :width='3' />
        </span>
    </button>
</template>

<script>
import UiIcon from '@/domain/components/UiIcon.vue'
import modalPropsMixin from '@/domain/mixins/modalPropsMixin'


const availableTypes = ['button', 'submit']
const availableSizes = ['small', 'normal', 'medium']
const availableColors = ['default', 'primary', 'primary-light', 'danger', 'danger-light', 'success', 'info', 'white', 'none', 'violet-dark']

export default {
    name: 'UiButton',
    mixins: [modalPropsMixin],
    components: { UiIcon },
    props: {
        type: { type: String, default: 'button', validator: value => availableTypes.includes(value) },
        prependIcon: { type: String, default: null },
        appendIcon: { type: String, default: null },
        fullWidth: { type: Boolean, default: false },
        size: { type: String, default: 'normal', validator: value => availableSizes.includes(value) },
        background: { type: String, default: 'default' },
        hoverColor: { type: String, default: null, validator: value => availableColors.includes(value) },
        loading: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        onlyHoverBackground: { type: Boolean, default: false },
        onlyText: { type: Boolean, default: false },
        // TODO: autoheight props
    },
    computed: {
        buttonClasses() {
            return {
                [`size-${this.size}`]: true,
                [`background-${this.background}`]: true,
                [`hover-${this.hoverColor ?? this.background}`]: true,
                'full-width': this.fullWidth,
                'only-hover-background': this.onlyHoverBackground,
                'only-text': this.onlyText,
                'disabled': this.disabled
            }
        }
    }
}
</script>