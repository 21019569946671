<template>
    <div class='files-upload-field d-flex flex-column gap-2'>
        <div v-if='label && isUploadArea' class='files-upload-field__label'>{{ label }}</div>
        <div
            v-if='isUploadArea'
            class='files-upload-field__field'
            @dragover.prevent
            @dragenter.prevent='handleDragEnter'
            @dragleave.prevent='handleDragLeave'
            @drop.prevent='handleFileDrop'
        >
            <ui-icon>upload-document</ui-icon>
            <div class='d-flex flex-row align-center px-8' @click.prevent='handleOpenDialog'>
                <ui-icon>upload</ui-icon>
                <span class='pl-8'
                      v-html='$t("Įkelkite .xml dokumentą arba <a>spustelėkite</a>, kad juos pasirinktumėte.")' />
            </div>
            <input
                class='d-none'
                type='file'
                ref='FilesInput'
                @change='handleChangeFile'
                :accept='accept'
                :multiple='multiple'
            />
        </div>
        <div v-if='isFilesArea' class='files-upload-field__label'>{{ $t('Dokumentai') }}:</div>
        <div v-if='isFilesArea' class='files-upload-field__files'>
            <div v-for='file in filesRows' :key='file.name' class='files-upload-field__file'>
                <ui-icon>{{ file.iconName }}</ui-icon>
                <span>{{ file.name }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import UiIcon from '@/domain/components/UiIcon.vue'

const iconNamesByFileExt = {
    '.xlsx': 'export-excel',
    '.xls': 'export-excel'
}

export default {
    name: 'FilesUploadField',
    components: { UiIcon },
    props: {
        label: { type: String, default: null },
        accept: { type: String, default: null },
        multiple: { type: Boolean, default: false }
    },
    data() {
        return {
            draggingOver: false,
            draggingOverTimeout: null,
            files: []
        }
    },
    computed: {
        areaClasses() {
            return {
                active: this.draggingOver
            }
        },
        isUploadArea() {
            return this.files.length === 0 && !this.multiple
        },
        isFilesArea() {
            return this.files.length > 0
        },
        filesRows() {
            return this.files.map((file) => ({
                name: file.name,
                iconName: iconNamesByFileExt[file.name.substring(file.name.lastIndexOf('.'))] ?? 'file',
            }))
        }
    },
    methods: {
        selectedFiles(files) {
            if (this.multiple) {
                this.files = [...this.files, ...files]
            } else {
                this.files = [files[0]]
            }

            this.$emit('upload', files)
        },
        handleOpenDialog() {
            this.$refs.FilesInput.click()
        },
        handleChangeFile() {
            this.selectedFiles(this.$refs.FilesInput.files)
            this.$refs.FilesInput.value = ''
        },
        handleDragEnter() {
            this.draggingOver = true

            if (this.draggingOverTimeout) {
                clearTimeout(this.draggingOverTimeout)
            }
        },
        handleDragLeave() {
            clearTimeout(this.draggingOverTimeout)

            this.draggingOverTimeout = setTimeout(() => {
                this.draggingOverTimeout = null
                this.draggingOver = false
            }, 50)
        },
        handleFileDrop(event) {
            this.handleDragLeave()
            this.selectedFiles(event.dataTransfer.files)
        }
    }
}
</script>

<style lang='scss'>
.files-upload-field {
    width: 100%;

    &__label {
        color: #70778C;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
    }

    &__field {
        height: 70px;
        border-radius: 4px;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%2399A6BFFF' stroke-width='2' stroke-dasharray='1%25%2c 2%25' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        display: flex;
        align-items: center;
        flex-direction: row;

        &.active {
            background-color: #d9d9d9;

            * {
                pointer-events: none;
            }
        }

        .ui-icon {
            color: #DAD5E9;
            font-size: 40px;
        }

        span.color-grey {
            color: #7B7B7B;
        }

        a {
            color: #4B56D2;
        }

        span, a {
            font-size: 16px;
        }
    }

    &__files {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: fit-content;
    }

    &__file {
        border-radius: 4px;
        border: 1px solid #DAD5E9;
        background: #FFF;
        padding: 20px;
        display: flex;
        gap: 15px;
        color: #1C2538;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        align-items: center;

        & > .ui-icon {
            color: #70778C;
            font-size: 24px;
        }
    }
}
</style>