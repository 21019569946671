<template>
	<v-expansion-panel>
		<v-expansion-panel-header>
			<slot name="header">
				{{ header }}
			</slot>
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<slot/>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
export default {
	name: "ExpansionPanels",
	props: {
		header: {type: String, default: ""},
	},
}
</script>
