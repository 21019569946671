<template>
	<div class="table-title" :class="classes">
		<div class="table-title__text"><slot /></div>
		<div v-if="total > 0" class="table-title__total">{{ total }}</div>
	</div>
</template>

<script>
export default {
	name: "TableTitle",
	props: {
		total: {type: Number, default: 0},
		hasBorder: {type: Boolean, default: false},
	},
	computed: {
		classes() {
			return {
				'table-title_border': this.hasBorder,
			};
		},
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/core/vars';
@import '@/assets/sass/mixins/_responsive';

.table-title {
	display: flex;
	align-items: flex-end;
	gap: 16px;

	@include sm-down {
		justify-content: space-between;
	}

	&__text {
		font-style: normal;
		font-weight: 600;
		font-size: 21px;
		line-height: 20px;
		color: $greyscale-800;
		display: flex;
		align-items: center;
		gap: 10px;

		@include sm-down {
			font-size: 18px;
		}
	}

	&__total {
		font-style: normal;
		font-weight: 700;
		font-size: 21px;
		line-height: 20px;
		color: $pink;

		@include sm-down {
			font-size: 18px;
		}
	}

	&_border {
		border-bottom: 1px solid $greyscale-800;
		padding-bottom: 17px;
	}
}
</style>