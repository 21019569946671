import modalMethodsMixin from '@/domain/mixins/modalMethodsMixin'
import documentsItemsSidebars from '@/domain/documents/items/mixins/documentsItemsSidebars'

export default {
    mixins: [modalMethodsMixin, documentsItemsSidebars],
    props: {
        item: { type: Object, required: true }
    },
    inject: ['withStatus', 'withControls', 'selectable', 'storeNamespace'],
    methods: {
        handleChangeFolder() {
            this.closeSidebar()
            this.$store.dispatch(`${this.storeNamespace}/groups-folders/changeId`, this.item.id)
        },
        handleOpenUpdateGroupsFolderModal() {
            this.closeSidebar()
            this.onOpenModal(
                'GroupsFolderUpdateModal',
                {
                    item: this.item.item
                },
                {
                    folderUpdated: (payload) => {
                        if (this.storeNamespace !== 'documentsGroupsRootStore') {
                            this.$store.dispatch(`documentsItemsRootStore/groups-folders/update`, payload)
                        }

                        this.$store.dispatch(`${this.storeNamespace}/groups-folders/update`, payload)
                    }
                }
            )
        },
        handleOpenDeleteGroupsFolderModal() {
            this.closeSidebar()
            this.onOpenModal(
                'GroupsFolderDeleteModal',
                {
                    id: this.item.id
                },
                {
                    folderDeleted: (payload) => {
                        if (this.storeNamespace !== 'documentsGroupsRootStore') {
                            this.$store.dispatch(`documentsItemsRootStore/groups-folders/delete`, payload)
                        }

                        this.$store.dispatch(`${this.storeNamespace}/groups-folders/delete`, payload)
                    }
                }
            )
        }
    }
}