<template>
	<li>
		{{ name }}
		{{ duty }}
	</li>
</template>

<script>
export default {
	name: "CompanyTreeEmployee",
	props: {
		name: {type: String, required: true},
		duty: {type: String, required: true},
	}
}
</script>