<template>
    <div class="table-department">
        <div
            class="table-department__content"
            v-for="department in departments"
        >
            <i :style="{ backgroundColor: department.color }"/>
            <span>{{ department.name }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        departments: {
            type: Array,
            required: true,
        },
    },
}
</script>
