export default axios => ({
    autocomplete: (query) => axios.get(`/api/employees/autocomplete`, { params: { query } }),
    autocomplete2: (params) => axios.get(`/api/employees/autocomplete`, { params }),
    current: () => axios.get(`/api/authorization/user`),
    getById: (id) => axios.get(`/api/employees/users/${id}`),

    qrCode: () => axios.get('/api/v2/users/qr-code'),
    confirm2fa: (code) => axios.post('/api/v2/users/enable/2fa', { code }),
    changeGuard: (id, payload) => axios.put(`/api/v2/users/${id}/guard`, payload),

    invites: {
        abort: (id) => axios.post(`/api/batches/${id}/abort`),
        status: (id) => axios.get(`/api/batches/${id}`),
        sendAll: () => axios.post(`/api/employees/invites/sent-all`),
        sendMany: (id) => axios.post(`/api/employees/invites/sent-many`, { id })
    }
});
