<template>
    <v-app>
        <v-main>
            <v-progress-linear
                :active='loading'
                :indeterminate='loading'
                color='primary'
                absolute
                style='top: 0'
            />
            <v-container class='document-sign py-8'>
                <v-row>
                    <v-col cols='12'>
                        <img src='@/assets/images/logo-alt.svg' alt='logo' />
                        <v-divider class='pa-0 ma-0' />
                        <message-alert v-if='!!error' class='mt-2' id='document-sign-error' :message='errorMessage'
                                       :type='errorType' />
                        <message-alert v-if='!!statusMessage' class='mt-2' id='document-sign-error' :message='statusMessage.message'
                                       :type='statusMessage.type' />
                    </v-col>
                </v-row>
                <v-row v-if='!loading && error == null'>
                    <v-col cols='12'>
                        <document-title-view :title='documentTitle' />
                    </v-col>
                    <v-col cols='12'>
                        <iframe v-if="fileType && fileType === 'pdf'" :src='fileUrl'
                                width='100%'
                                height='700px'></iframe>
                        <docx-preview v-else-if="fileType && fileType === 'docx'" :fileUrl='fileUrl' />
                        <excel-preview v-else-if="fileType && fileType === 'xls' || fileType && fileType === 'xlsx'"
                                       :fileUrl='fileUrl' />
                    </v-col>
                    <v-col v-if='status === "pending"' cols='12' class='d-flex flex-row-reverse'>
                        <ui-button
                            :disabled='signLoading'
                            prepend-icon='ok'
                            background='primary'
                            :class="'ml-2'"
                            @click='handleAccept'
                        >
                            {{ $t('Priimti') }}
                        </ui-button>
                        <ui-button
                            :disabled='signLoading'
                            prepend-icon='close'
                            background='danger-light'
                            @click='handleReject'
                        >
                            {{ $t('Atmesti') }}
                        </ui-button>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>

import CustomApi from '@/plugins/customApi'
import UiButton from '@/domain/components/UiButton.vue'
import ExcelPreview from '@/domain/documents/components/ExcelPreview.vue'
import UiIcon from '@/domain/components/UiIcon.vue'
import DocxPreview from '@/domain/documents/components/DocxPreview.vue'
import MessageAlert from '@/domain/messages/components/MessageAlert.vue'
import DocumentTitleView from '@/domain/documents/components/DocumentTitleView.vue'
import modalMethodsMixin from '@/domain/mixins/modalMethodsMixin'

const customApi = CustomApi()
export default {
    name: 'DocumentPublicSignPage',
    components: { DocumentTitleView, MessageAlert, DocxPreview, UiIcon, ExcelPreview, UiButton },
    mixins: [modalMethodsMixin],
    data() {
        return {
            loading: false,
            signLoading: false,
            token: null,
            error: null,
            status: null,
            documentTitle: ''
        }
    },
    computed: {
        errorMessage() {
            return this.getErrorMessage(this.error, this.$t('Įvyko klaida'))
        },
        errorType() {
            return 'error'
        },
        fileType() {
            return this.documentTitle.split('.').pop().toLowerCase()
        },
        fileUrl() {
            return `${window.location.origin}/api/documents/public/sign/${this.token}/preview`
        },
        statusMessage() {
            if (this.status === 'pending' || this.status == null) {
                return null
            }

            if (this.status === 'accepted') {
                return {
                    type: 'success',
                    message: this.$t('Dokumentas priimtas')
                }
            }

            return {
                type: 'error',
                message: this.$t('Dokumentas atmestas')
            }
        }
    },
    mounted() {
        this.token = this.$route.params.token
        this.fetchStatus()
    },
    methods: {
        async fetchStatus() {
            this.loading = true
            try {
                const {
                    data: {
                        status,
                        documentTitle
                    }
                } = await customApi.factories.documents.publicSign.status(this.token)
                this.status = status
                this.documentTitle = documentTitle
            } catch (error) {
                this.error = error
            } finally {
                this.loading = false
            }
        },
        handleReject() {
            if (this.signLoading) {
                return
            }

            this.onOpenModal(
                'DocumentSignCommentModal',
                {
                    item: {
                        document: {
                            title: this.documentTitle
                        }
                    },
                    action: async (comment) => {
                        if (this.signLoading) {
                            return
                        }

                        this.signLoading = true
                        try {
                            const {
                                data: {
                                    status
                                }
                            } = await customApi.factories.documents.publicSign
                                .reject(this.token, {comment})

                            this.status = status
                            this.onCloseModal('DocumentSignCommentModal')
                            window.scrollTo({top: 0, behavior: 'smooth'})
                        } catch (error) {
                            this.showErrorInRootToast(error)
                        } finally {
                            this.signLoading = false
                        }
                    }
                }
            )
        },
        async handleAccept() {
            if (this.signLoading) {
                return
            }

            this.signLoading = true
            try {
                const {
                    data: {
                        status
                    }
                } = await customApi.factories.documents.publicSign.accept(this.token)
                this.status = status
                window.scrollTo({top: 0, behavior: 'smooth'})
            } catch (error) {
                this.showErrorInRootToast(error)
            } finally {
                this.signLoading = false
            }
        }
    }
}
</script>

<style scoped lang='scss'>
.document-sign {
    //background: linear-gradient(180deg, #FBF8FF 0%, rgba(247, 241, 253, 0.00) 98.37%);
}

.sign-header {
    color: #1C2538;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 95.238% */
}

.sign-file {
    color: #32398B;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
</style>