var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"name":_vm.$attrs.name,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:_vm.fieldClasses.concat( classes)},[(_vm.isView)?[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.innerValue),expression:"innerValue"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.innerValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.innerValue=$event.target.value}}}),_c('div',{class:(_vm.baseClassName + "__label")},[_vm._v(_vm._s(_vm.translateLabel))]),_c('div',{class:(_vm.baseClassName + "__input-view")},[_vm._t("input.view",function(){return [_vm._v(" "+_vm._s(errors.length === 0 ? _vm.viewValue : '?')+" ")]},{"value":errors.length === 0 ? _vm.viewValue : '?'})],2)]:[_c('div',{class:(_vm.baseClassName + "__label")},[_c('label',[_vm._v(_vm._s(_vm.translateLabel))]),_vm._t("right-label")],2),_c('v-select',{attrs:{"items":_vm.options,"placeholder":_vm.translatePlaceholder,"append-icon":_vm.appendIcon ? ("icon-" + _vm.appendIcon) : 'icon-down',"multiple":_vm.multiple,"solo":"","menu-props":{
                maxWidth: 'min-content',
                padding: 0
            },"disabled":_vm.disabled},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.text)}})]}}],null,true),model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}})]],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }