<template>
    <div class="schedule-legend">
        <div class="schedule-legend__item" v-for="({names, text}, key) in items" :key="key">
            <div
                v-for="(name, index) in names" :key="index" :class="name"
                class="schedule-legend__item-color"
            />
            <div class="schedule-legend__item-text">{{ text }}</div>
        </div>
    </div>
</template>

  <script>
export default {
    name: "ScheduleLegend",
    computed: {
        items() {
            return [
                {names: ["workplace"], text: this.$t("Darbo vietos laikai")},
                {names: ["worktime"], text: this.$t("Darbuotojo darbo laikas")},
                {names: ["others", "others2"], text: this.$t("Atostogų/ligonlapių ir kt.")},
                {names: ["want-to-work"], text: this.$t("Norintis dirbti tą dieną")},
                {names: ["breaks"], text: this.$t("Pertraukos")},
                {names: ["holiday", "holiday2"], text: this.$t("Šventinė diena")},
            ];
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/core/colors';
@import '@/assets/sass/mixins/responsive';
.schedule-legend {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 100%;
    gap: 20px;

    @include xs-down {
        justify-content: center;
    }

    &__item {
        display: flex;
        gap: 5px;
        align-items: center;

        @include xs-down {
            flex: 0 0 45%;
        }
    }

    &__item-color {
        min-width: 16px;
        min-height: 16px;
        border-radius: 4px;

        &.workplace {
            background: $schedule-color-event;
        }
        &.worktime {
            background: $schedule-color-worktime;
        }
        &.others{
            background: $schedule-color-others;
        }
        &.others2{
            background: $schedule-color-others2;
        }
        &.want-to-work {
            background: $schedule-color-want-to-work;
        }
        &.breaks {
            background: $schedule-color-breaks;
        }
        &.holiday {
            background: $schedule-color-holiday;
        }
        &.holiday2 {
            background: $schedule-color-holiday2;
        }
    }

    &__item-text {
        font-size: 12px;
        font-weight: 400;
        line-height: 1.2;
        color: $greyscale-800;
    }
}
</style>