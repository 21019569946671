export const localStorageSave = (name, payload) => {
    window.localStorage.setItem(name, JSON.stringify(payload));
};

export const localStorageGet = (name) => {
    const data = window.localStorage.getItem(name);
    if (data) {
        return JSON.parse(data);
    }
    return null;
}

export const localStorageRemove = (name) => {
    window.localStorage.removeItem(name);
}
