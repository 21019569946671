const replaceChars = {
    'ą': 'a',
    'č': 'c',
    'ę': 'e',
    'ė': 'e',
    'į': 'i',
    'š': 's',
    'ų': 'u',
    'ū': 'u',
    'ž': 'z'
}
const findToReplaceChar = new RegExp(`[${Object.keys(replaceChars).join('')}]`, 'g')
const replaceChar = match => replaceChars[match] ?? match
export const replaceString = text => {
    return text.toLowerCase().replaceAll(findToReplaceChar, replaceChar)
}