<template>
    <grid-item
        :title='item.item.title'
        icon='folder'
        @click='handleChangeFolder'
    >
        <template v-if='withControls' #controls>
            <documents-finder-context-menu>
                <documents-finder-context-menu-item
                    icon='edit'
                    :title='$t("Redaguoti")'
                    @click='handleOpenUpdateGroupsFolderModal'
                />
                <documents-finder-context-menu-item
                    icon='bin'
                    :title='$t("Ištrinti")'
                    @click='handleOpenDeleteGroupsFolderModal'
                />
            </documents-finder-context-menu>
        </template>
    </grid-item>
</template>

<script>
import DocumentsFinderContextMenuItem from '@/domain/documents/finder/components/ContextMenuItem.vue'
import DocumentsFinderContextMenu from '@/domain/documents/finder/components/ContextMenu.vue'
import UiIcon from '@/domain/components/UiIcon.vue'
import documentFinderFolderItemMixin from '@/domain/documents/finder/mixins/documentFinderFolderItemMixin'
import GridItem from '@/domain/documents/finder/components/content/grid/GridItem.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import documentFinderGroupFolderItemMixin from '@/domain/documents/finder/mixins/documentFinderGroupFolderItemMixin'

export default {
    name: 'GridGroupFolderItem',
    components: { FormCheckbox, GridItem, DocumentsFinderContextMenuItem, DocumentsFinderContextMenu, UiIcon },
    mixins: [documentFinderGroupFolderItemMixin],
}
</script>