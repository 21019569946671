<template>
    <hra-employee-crud-table>
        <hra-table :headers='headers' :rows='children' :need-actions='!readOnly' hide-headers class='no-border-around'>
            <template #header.custom>
                <table-title-view class='mb-3 size-1' :title="$t('Vaikai')" with-border />
            </template>

            <template #col.firstName='{colValue, row}'>
                <div class='pe-3 py-6'>{{ colValue }} {{ row.validated }}</div>
            </template>

            <template #col.birthDate='{colValue}'>
                <div class='px-3 py-6'>{{ $t('Gimimo metai: {date}', { date: colValue }) }}</div>
            </template>

            <template #col.disabilities='{colValue}'>
                <div class='px-3 py-6'>
                    {{ $t(`Neįgalumas: {param}`, { param: colValue.length > 0 ? $t('taip') : $t('ne') }) }}
                </div>
            </template>

            <template #col.disabilityNo='{row: {disabilities}}'>
                <div v-if='disabilities.length > 0' class='px-3 py-6'>
                    {{ $t('Darbo pažymėjimo nr.: {number}', { number: disabilities[0].number }) }}
                </div>
                <div v-else />
            </template>

            <template #col.disabilityFrom='{row: {disabilities}}'>
                <div v-if='disabilities.length > 0 && !!disabilities[0].toDate' class='px-3 py-6'>
                    {{ $t('Neįgalumas nuo: {from} - {to}', {
                    from: disabilities[0].fromDate,
                    to: disabilities[0].toDate
                }) }}
                </div>
                <div v-else-if='disabilities.length > 0' class='px-3 py-6'>
                    {{ $t('Neįgalumas nuo: {date}', { date: disabilities[0].fromDate }) }}
                </div>
                <div v-else />
            </template>

            <template #col.actions-base='{row}'>
                <hra-tooltip :text="$t('Redaguoti')">
                    <ui-button
                        size='small'
                        prepend-icon='edit'
                        :modal='{name: "EmployeeChildModal", props: {service: onUpdate, payload: row}}'
                    />
                </hra-tooltip>
                <base-confirm
                    :title="$t('Ar tikrai norite ištrinti?')"
                    tag='EMPLOYEE_CHILD_DESTROY_TAG'
                    @confirm='onDelete($event, row.id)'
                >
                    <hra-tooltip :text="$t('Ištrinti')">
                        <ui-button size='small' background='danger-light' prepend-icon='bin' />
                    </hra-tooltip>
                </base-confirm>
            </template>

            <template #empty.text>{{ $t('Įrašų sistemoje pridėta nebuvo.') }}</template>
        </hra-table>

        <template #buttons>
            <ui-button
                v-if='!readOnly'
                size='small'
                background='primary'
                :modal='{name: "EmployeeChildModal", props: {service: onCreate}}'
            >
                {{ $t('Pridėti') }}
            </ui-button>
        </template>
    </hra-employee-crud-table>
</template>

<script>
import HraEmployeeCrudTable from '@/components/ui/table/HraEmployeeCrudTable'
import HraTable from '@/components/ui/table/HraTable'
import TableTitleView from '@/components/ui/views/TableTitleView'
import HraTooltip from '@/components/ui/HraTooltip'
import { mapGetters } from 'vuex'
import UiButton from '@/domain/components/UiButton.vue'
import BaseConfirm from '@/domain/confirms/BaseConfirm.vue'

export default {
    name: 'EmployeeChildTable',
    components: {
        BaseConfirm,
        UiButton,
        HraTooltip,
        TableTitleView,
        HraTable,
        HraEmployeeCrudTable
    },
    props: {
        isProfile: { type: Boolean, default: false },
        isCreate: { type: Boolean, default: false },
        isEdit: { type: Boolean, default: false }
    },
    data() {
        return {
            headers: [
                { name: 'firstName' },
                { name: 'birthDate' },
                { name: 'disabilities' },
                { name: 'disabilityNo' },
                { name: 'disabilityFrom' }
            ],
            readOnly: this.isProfile
        }
    },
    computed: {
        ...mapGetters({
            children: 'employeeStore/children',
            userId: 'employeeStore/userId'
        })
    },
    methods: {
        async onCreate(tag, payload) {
            return await this.$store.dispatch('employeeStore/createChild', { tag, payload, userId: this.userId })
        },
        async onUpdate(tag, payload, id) {
            return await this.$store.dispatch('employeeStore/updateChild', { tag, id, payload })
        },
        async onDelete(confirm, id) {
            await this.$store.dispatch('employeeStore/destroyChild', { id, tag: confirm.tag })
            confirm?.close()
        }
    }
}
</script>