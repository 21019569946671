<template>
	<hra-table ref="table" :headers="headers" :rows="rows" :loading="loading"
	           need-actions header-sticky actions-sticky is-inline-edit
	           :inline-row-is-edit="rowIsEdit">
		<template #row.inline.edit="{attrs, on, row, cols}">
			<import-employees-manage-table-row :attrs="attrs" :on="on" :id="row.id" :cols="cols"
			                                   :options="options" :is-removable="!row.isSuperAdmin" :is-edit="row.edit"/>
		</template>
		<template #empty.text>
			<div class="d-flex align-center flex-column gap-3 ma-4">
				<div>{{ $t("Naujų įrašų sistemoje pridėta nebuvo.") }}</div>
			</div>
		</template>
	</hra-table>
</template>

<script>
import {mapGetters} from "vuex";
import Avatar from "@/components/ui/Avatar";
import HraTable from "@/components/ui/table/HraTable";
import HraTableInlineEditRow from "@/components/ui/table/HraTableInlineEditRow";
import ImportEmployeesManageTableRow from "@/views/tables/import/ImportEmployeesManageTableRow";
import HraButton from "@/components/ui/button/HraButton";
import HraButtonModal from "@/components/ui/button/HraButtonModal";

export default {
	name: "ImportEmployeesManageTable",
	components: {HraButtonModal, HraButton, ImportEmployeesManageTableRow, Avatar, HraTable, HraTableInlineEditRow},
	props: {
		headers: {type: Array, default: []},
		rows: {type: Array, default: []},
		loading: {type: Boolean, default: false},
	},
	inject: ["onCreateEmptyRow"],
	computed: {
		...mapGetters({
			departmentOptions: "employee/importData/departmentOptions",
			dutyOptions: "employee/importData/dutyOptions",
			rowIsEdit: "employee/importData/rowIsEdit",
		}),
		validated() {
			return false;
		},
		options() {
			return {
				"select-departments": this.departmentOptions,
				"select-duties": this.dutyOptions,
				"select-yes-no": this.yesNoOptions,
			};
		},
	}
}
</script>
