export default function RegistrationPlugin(store) {
    const modules = new Map()

    store.registerModuleSafely = function (name, module) {
        const count = modules.get(name) || 0

        if (count === 0) {
            store.registerModule(name, module)
        }

        modules.set(name, count + 1)
    }

    store.unregisterModuleSafely = function (name) {
        const count = modules.get(name) || 0

        if (count === 1) {
            store.unregisterModule(name)
            modules.delete(name)
        } else if (count > 1) {
            modules.set(name, count - 1)
        }
    }
}