<template>
    <div :class="alertClass" ref="hraAlert">
        <slot name="icon"/>
        <slot/>
    </div>
</template>

<script>
import _ from "lodash";
import {UI_COLORS} from "@/utils/default";

const ALERT_COLORS = _.values(UI_COLORS);
export default {
    name: "HraAlert",
    props: {
        color: {default: ALERT_COLORS[0], validator: value => ALERT_COLORS.includes(value)},
        autoScrollTo: {type: Boolean, default: false},
    },
    mounted() {
        if (this.autoScrollTo) {
            this.$refs.hraAlert?.scrollIntoView();
        }
    },
    computed: {
        alertClass() {
            return [
                "hra-alert d-flex align-center gap-3 pa-3",
                `color-${this.color}`,
            ];
        },
    },
}
</script>
