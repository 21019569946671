<template>
	<v-fab-transition>
		<div v-if="viewLoaders" class="loaders">
			<loader v-for="key in listenLoaders" :key="key" :size="size"/>
		</div>
	</v-fab-transition>
</template>

<script>
import {mapGetters} from "vuex";
import {stackListen} from "@/domain/loaders/store/getters";
import Loader from "@/domain/loaders/components/Loader";

export default {
	name: "Loaders",
	components: {Loader},
	props: {
		listen: {type: [String, Array], required: true},
		size: {type: [Number, String], default: 32},
	},
	computed: {
		...mapGetters({
			stackListen: "loadersStore/stackListen",
		}),
		listenLoaders() {
			return this.stackListen(this.listen);
		},
		viewLoaders() {
			return this.listenLoaders.length > 0 || this.$slots.default;
		},
		viewSlot() {
			return this.listenLoaders.length === 0;
		}
	},
	mounted() {
	}
}
</script>

<style lang="scss">
.loaders {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 3px;
}
</style>