<template>
    <div>
        <FormTextField
            v-if="['text', 'phone', 'email', 'number'].indexOf(componentName) > -1"
            :type="componentName"
            v-bind="attrs"
            v-on="on"
            v-model="value[attrs.name]"
            :field-trans-prefix="fieldTransPrefix"
            :is-view="isView"
        />
        <FormSelect
            v-if="['select-departments', 'select-duties', 'select-yes-no'].indexOf(componentName) > -1"
            v-bind="attrs"
            v-on="on"
            v-model="value[attrs.name]"
            :field-trans-prefix="fieldTransPrefix"
        />
        <FormDatePicker
            v-if="componentName === 'date'"
            v-bind="attrs"
            v-on="on"
            v-model="value[attrs.name]"
            :field-trans-prefix="fieldTransPrefix"
        />
    </div>
</template>

<script>
import formDynamicLineMixin from "@/mixins/formDynamicLineMixin";

export default {
    mixins: [formDynamicLineMixin],
    props: {
        isView: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
