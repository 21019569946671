<template>
    <div class='schedule-container'>
        <div class='schedule-sidebar'>
            <table class='schedule-table'>
                <thead>
                <tr>
                    <td>Pamainos</td>
                    <td>Laikas</td>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <div>Tadeus Tunkevic</div>
                    </td>
                    <td>
                        <div>00h 00min</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div>Tadeus Tunkevic</div>
                    </td>
                    <td>
                        <div>00h 00min</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div>Tadeus Tunkevic</div>
                    </td>
                    <td>
                        <div>00h 00min</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div>Tadeus Tunkevic</div>
                    </td>
                    <td>
                        <div>00h 00min</div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class='schedule-separator' />
        <div class='schedule-events'>
            <table class='schedule-table'>
                <thead>
                <tr>
                    <td
                        class='schedule-table-column'
                        v-for='day in days'
                        :key='`day-key-${day}`'
                    >{{ day }}
                    </td>
                </tr>
                </thead>
                <tbody style='position: relative'>
                <tr
                    v-for='row in [1,2,3,4]'
                    :key='`days-${row}`'
                >
                    <td
                        class='schedule-table-column'
                        v-for='day in days'
                        :key='`day-${row}-key-${day}`'
                    />
                </tr>
                </tbody>
            </table>
        </div>
        <div class='schedule-separator' />
        <div class='schedule-sidebar'>
            <table class='schedule-table'>
                <thead>
                <tr>
                    <td>actions</td>
                </tr>
                </thead>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ScheduleTable',
    computed: {
        days() {
            return new Array(31).fill(0).map((_, index) => index + 1)
        }
    }
}
</script>