import axios from "axios";

async function request(method, url, payload, needCsrf, headers) {
    try {
        if (needCsrf) {
            await axios.get('/api/sanctum/csrf-cookie');
        }

        let _payload = payload;

        if (headers['Content-Type'] === 'multipart/form-data') {
            _payload = new FormData();
            Object.keys(payload).map((key) => {
                let value = payload[key];
                _payload.set(key, value);
            });
        }

        return await axios[method](`${url}`, _payload, {headers});
    } catch (error) {
        let code = null;
        let message = "Įvyko klaida.";
        if (error.response) {
            // if ([404, 403].indexOf(error.response.status) > -1) {
            //     window.location.href = '/404';
            // }

            if ([401, 419].indexOf(error.response.status) > -1) {
                window.location.href = '/auth';
                return;
            }

            code = error.response.status;

            if (error.response.data.error) {
                message = error.response.data.error;
            }

            if (error.response.data.message) {
                message = error.response.data.message;
            }
        }
        throw {code, message};
    }
}

function getQueriesFromPayload(payload = {}) {
    const keys = Object.keys(payload);

    if (keys.length < 1) {
        return  "";
    }

    let queries = [];

    keys.forEach(key => {
        let value = payload[key];
        if (Array.isArray(value)) {
            value.forEach(_value => queries.push(`${key}[]=${_value}`));
        } else {
            queries.push(`${key}=${value}`);
        }
    });

    return "?" + queries.join("&");
}

export const getRequest = async (url, payload = {}, needCsrf = false, headers = {}) => {
    url+= getQueriesFromPayload(payload);

    const { data } = await request("get", url, {}, needCsrf, headers);

    return data;
}

export const postRequest = async (url, payload = {}, needCsrf = false, headers = {}) => {
    const {data} = await request("post", url, payload, needCsrf, headers);

    return data;
}

export const putRequest = async (url, payload = {}, needCsrf = false, headers = {}) => {
    const {data} = await request("put", url, payload, needCsrf, headers);

    return data;
}

export const deleteRequest = async (url, payload = {}, needCsrf = false, headers = {}) => {
    url+= getQueriesFromPayload(payload);

    const {data} = await request("delete", url, {}, needCsrf, headers);

    return data;
}
