import Vue from 'vue'
import { mapGroupsFolder } from '@/domain/documents/finder/store/mappers'

export default ({
    ['groups-folders/changeId']({state, dispatch}, payload) {
        state.groupsFolderId = payload
        state.search = ''
        dispatch('load')
    },
    ['groups-folders/create']({state}, payload) {
        if (state.groupsFolderId !== payload.parentId) {
            return
        }

        state.groupsFolders.push(mapGroupsFolder(payload))
    },
    ['groups-folders/update']({state}, payload) {
        const index = state.groupsFolders.findIndex(({id}) => id === payload.id)
        if (index === -1) {
            return
        }
        Vue.set(state.groupsFolders, index, mapGroupsFolder(payload))
    },
    ['groups-folders/delete']({state}, payload) {
        const index = state.groupsFolders.findIndex(({id}) => id === payload)
        if (index === -1) {
            return
        }
        state.groupsFolders.splice(index, 1)
    }
})