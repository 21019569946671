<template>
    <custom-dialog :max-width='1200' ref='dialog' has-custom-footer>
        <template #title>{{ $t('Pasirinkite darbuotojus') }}</template>
        <template #content>
            <div class="employeeSelect--selected px-9 mb-6" ref='EmployeeSelected'>
                <div class="employeeSelect--selected__header d-flex justify-space-between align-center gap-3">
                    <div class="employeeSelect--selected__title py-7" v-html="$t('Pasirinkti tvirtinantys darbuotojai {count}', { count: selectedEmployees.length })"></div>
                    <ui-button
                        v-if='!selectedEmployeesTableIsEmpty'
                        class='px-3 py-0'
                        :prepend-icon="isTableExpanded ? 'arrow-up' : 'arrow-down'"
                        background='primary'
                        only-text
                        style='min-height: auto;'
                        @click="handleToggleExpand"
                    />
                </div>
                <ui-table v-if="!selectedEmployeesTableIsEmpty && isTableExpanded">
                    <template #body>
                        <tr v-for='(employee, key) in selectedEmployees' :key='key'>
                            <td>{{ employee.name }}</td>
                            <td>{{ employee.department ? employee.department.name : employee.departmentId }}</td>
                            <td>{{ employee.duty ? employee.duty.name : employee.dutyId }}</td>
                            <td>{{ employee.email }}</td>
                            <td>
                                <hra-tooltip :text="$t('Pašalinti')">
                                    <ui-button
                                        prepend-icon='minus-circle'
                                        only-text
                                        background='none'
                                        class='pa-0'
                                        @click="removeFromSelectedEmployees(employee)"
                                    />
                                </hra-tooltip>
                            </td>
                        </tr>
                    </template>
                </ui-table>
            </div>

            <div class='employeeSelect--search d-flex align-center gap-3 white pt-2 px-9' ref='SearchField' :style='searchStyle'>
                <text-field
                    style='width: 100%'
                    :label="$t('Ieškoti darbuotojo')"
                    :placeholder="$t('Ieškoti...')"
                    name="query"
                    can-clear
                    :value="filter.query"
                    @input="handleChangeQuery"
                >
                    <template #append-inner>
                        <ui-icon class="pl-2">find</ui-icon>
                    </template>
                </text-field>
            </div>

            <div class="employeeSelect--list px-9 pb-8">
                <ui-table
                    :loading='loading'
                    :is-empty-table='isEmptyTable'
                    :visible-load-more='hasMore'
                    @clickLoadMore='handleLoadMore'
                    header-sticky
                    :header-sticky-top='tableStickyTop'
                    class="pt-5"
                >
                    <template #head>
                        <tr>
                            <th>{{ $t('Darbuotojas') }}</th>
                            <table-header-department-filter
                                :filter='filter.departmentId'
                                @changeFilter='handleChangeFilter'
                            />
                            <table-header-duty-filter
                                :filter='filter.dutyId'
                                @changeFilter='handleChangeFilter'
                            />
                            <th>{{ $t('El. paštas') }}</th>
                            <th></th>
                        </tr>
                    </template>
                    <template #body>
                        <tr v-for='(employee, key) in employees' :key='key'>
                            <td style='width: 240px; max-width: 240px;'>{{ employee.name }}</td>
                            <td style='max-width: 200px;'>{{ employee.department.name }}</td>
                            <td style='max-width: 200px;'>{{ employee.duty.name }}</td>
                            <td>{{ employee.email }}</td>
                            <td>
                                <ui-button
                                    prepend-icon="plus"
                                    background="violet-dark"
                                    style='min-height: auto;'
                                    @click="addToSelectedEmployees(employee)"
                                >{{ $t('Pasirinkti') }}
                                </ui-button>
                            </td>
                        </tr>
                    </template>
                </ui-table>
            </div>
        </template>
        <template #footer>
            <ui-button prepend-icon='close' background='default' @click="close">{{ $t('Uždaryti') }}</ui-button>
            <ui-button prepend-icon='ok' background='primary' @click="onSave">{{ $t('Išsaugoti') }}</ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import dialogMixin from '@/mixins/dialogMixin'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import UiButton from '@/domain/components/UiButton.vue'
import UiTable from '@/domain/components/uiTable/UiTable.vue'
import HraFilter from '@/components/ui/filter/HraFilter'
import HraFilterSearch from '@/components/ui/filter/HraFilterSearch'
import CustomApi from '@/plugins/customApi'
import HraTooltip from '@/components/ui/HraTooltip'
import TableHeaderDepartmentFilter from '@/domain/components/uiTable/filters/TableHeaderDepartmentFilter.vue'
import TableHeaderDutyFilter from '@/domain/components/uiTable/filters/TableHeaderDutyFilter.vue'
import TextField from '@/domain/fields/TextField.vue'
import UiIcon from '@/domain/components/UiIcon.vue'

const customApi = CustomApi()

export default {
    name: 'EmployeeSelectModal',
    mixins: [dialogMixin],
    components: {
        CustomDialog,
        UiButton,
        UiTable,
        HraFilter,
        HraFilterSearch,
        HraTooltip,
        TableHeaderDepartmentFilter,
        TableHeaderDutyFilter,
        TextField,
        UiIcon
    },
    props: {
        alreadySelectedEmployees: {type: Array, default: []}
    },
    data() {
        return {
            queryDebounce: null,
            employees: [],
            selectedEmployees: [],
            loading: false,
            hasMore: false,
            page: 1,
            filter: {
                query: '',
                departmentId: {
                    selected: [],
                    sort: null
                },
                dutyId: {
                    selected: [],
                    sort: null
                },
            },
            isTableExpanded: true,
            employeeSelectedHeight: 0,
            searchHeight: 0,
            employeeSelectedResizeObserver: null
        }
    },
    mounted() {
        this.selectedEmployees = [...this.alreadySelectedEmployees]
        this.fetchEmployees()

        this.employeeSelectedResizeObserver = new ResizeObserver(() => {
            this.employeeSelectedHeight = this.$refs.EmployeeSelected.offsetHeight
        })
        this.employeeSelectedResizeObserver.observe(this.$refs.EmployeeSelected)
        this.searchHeight = this.$refs.SearchField.offsetHeight
    },
    beforeDestroy() {
        this.employeeSelectedResizeObserver?.disconnect()
    },
    computed: {
        selectedEmployeesTableIsEmpty() {
            return this.selectedEmployees.length === 0
        },
        isEmptyTable() {
            return this.employees.length === 0
        },
        searchStyle() {
            return {
                position: 'sticky',
                top: `${this.employeeSelectedHeight}px`,
                left: '0',
                zIndex: '5',
            }
        },
        tableStickyTop() {
            return this.employeeSelectedHeight + this.searchHeight
        }
    },
    methods: {
        getPayload(nextPage) {
            let params = {
                limit: 50,
                page: nextPage ? this.page + 1 : 1,
                ignoreId: this.selectedEmployees.map(employee => employee.id),
                withDepartment: true,
                withDuty: true,
                search: null,
                sortBy: ['name,asc'],
            }

            if (!!this.filter.query) {
                params.search = this.filter.query
            }

            for (const [column, value] of Object.entries(this.filter)) {
                if (value.sort != null) {
                    if(column !== 'query') {
                        params.sortBy = []
                    }
                    params.sortBy.push(`${column},${value.sort}`)
                }

                if ((value.selected?.length ?? 0) > 0) {
                    params[column] = value.selected
                }
            }

            return params
        },
        async fetchEmployees(nextPage = false) {
            try {
                this.loading = true
                const {data: {items, pagination}} = await customApi.factories.employee.fetchByFilter(this.getPayload(nextPage))
                this.page = pagination.currentPage
                this.employees = nextPage ? [...this.employees, ...items] : items
                this.hasMore = pagination.currentPage < pagination.lastPage
            } catch (e) {
                this.hasMore = false
                this.page = 1
                console.error(e)
            } finally {
                this.loading = false
            }
        },
        handleToggleExpand() {
            this.isTableExpanded = !this.isTableExpanded
        },
        handleLoadMore() {
            this.fetchEmployees(true)
        },
        addToSelectedEmployees(employee) {
            const isAlreadySelected = this.selectedEmployees.some(selectedEmployee => selectedEmployee.id === employee.id)
            if(!isAlreadySelected) {
                this.selectedEmployees.push(employee)
                this.fetchEmployees()
            }
        },
        removeFromSelectedEmployees(employee) {
            const index = this.selectedEmployees.findIndex(({ id }) => id === employee.id)
            if (index !== -1) {
                this.selectedEmployees.splice(index, 1)
                this.fetchEmployees()
            }
        },
        handleChangeFilter(column, filter) {
            if (column === 'departmentId') {
                this.$set(this.filter, 'dutyId', { selected: [], sort: null })
            }

            if (column === 'dutyId') {
                this.$set(this.filter, 'departmentId', { selected: [], sort: null })
            }

            this.$set(this.filter, column, filter)
            this.fetchEmployees()
        },
        handleChangeQuery(value) {
            this.filter.query = value
            clearTimeout(this.queryDebounce)
            this.queryDebounce = setTimeout(() => {
                this.fetchEmployees()
            }, 400)
        },
        async onSave() {
            this.$emit('update', this.selectedEmployees)
            this.close()
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/sass/core/vars';
.employeeSelect--selected {
    background-color: #F3F4FF;
    border-bottom: 1px solid #C1BBD4;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 5;

    &__title {
        color: $greyscale-800;
        font-size: 18px;
        line-height: 1;
        font-weight: 600;

        span {
            color: $pink;
            font-weight: 700;
        }
    }

    .ui-table {
        background-color: $white;
        border-top: 1px solid #DAD5E9;
        max-height: 250px;
        overflow-y: auto;

        tbody {
            tr:first-child {
                border-top: none;
            }
            tr:last-child {
                border-bottom: none;
            }
        }
    }
}
</style>