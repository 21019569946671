<template>
    <hra-table
        :headers='headers'
        :rows='rows'
        hide-headers
        class='no-border-around'
        need-actions
    >
        <template #header.custom>
            <table-title :total='rows.length' has-border class='mb-4'>
                <hra-icon :name='iconName.waitList' class='hra-text--greyscale-600 mr-1' size='24px' />
                {{ $t('Laukiantys patvirtinimo prašymai') }}
            </table-title>
        </template>

        <template #col.typeText='{colValue, row}'>
            <div v-if='isBondMobile' class='waiting-vacation-mobile-info py-3'>
                <div class='type'>{{ colValue }}</div>
                <div
                    class='period mb-1'
                    v-html='$t("<b>{fromDate}</b> iki <b>{toDate}</b>", {fromDate: row.period.start, toDate: row.period.end})'
                />
                <div class='deputy-title mb-1'>
                    <span v-html='row.deputyTitle' />
                </div>
            </div>
            <div v-else class='pa-3 py-5'>{{ colValue }}</div>
        </template>
        <template #col.period='{colValue}'>
            <vacation-period-view :from-date='colValue.start' :to-date='colValue.end' />
        </template>
        <template #col.deputyTitle='{colValue, row}'>
            <div class='px-3 py-5' v-html='colValue' />
        </template>


        <template #col.actions-base='{row}'>
            <table-actions class='' :type='row.type' :approvalable='row.approvalable' :status='row.status' :user-id='userId' />
        </template>
    </hra-table>
</template>

<script>
import { mapGetters } from 'vuex'
import HraTable from '@/components/ui/table/HraTable'
import TableTitleView from '@/components/ui/views/TableTitleView'
import TableTitle from '@/components/ui/views/TableTitle'
import HraIcon from '@/components/ui/HraIcon'
import VacationPeriodView from '@/components/ui/views/VacationPeriodView'
import TableActions from '@/domain/employeeVacations/components/TableActions'
import isBondMobileMixin from '@/mixins/isBondMobileMixin'
import CustomApi from '@/plugins/customApi'
import { EVENT_HOLIDAY_TYPE_DEPUTY, eventHolidayType } from '@/utils/default'

const customApi = CustomApi()

export default {
    name: 'WaitingTable',
    mixins: [isBondMobileMixin],
    components: { TableActions, VacationPeriodView, HraIcon, TableTitle, TableTitleView, HraTable },
    computed: {
        ...mapGetters({
            userId: 'currentUserStore/userId',
            rows: 'employeeVacationsStore/approvalsPending'
        }),
        headers() {
            if (this.isBondMobile) {
                return [
                    { name: 'typeText' }
                ]
            }

            return [
                { name: 'typeText', style: { width: '300px' } },
                { name: 'period', style: { width: '300px' } },
                { name: 'deputyTitle' }
            ]
        }
    }
}
</script>

<style lang='scss'>
.waiting-vacation-mobile-info {
    & > div {
        color: #1C2538;
        font-size: 14px;
        line-height: 1.3;

        span, b {
            font-weight: 600;
        }

        &.type {
            font-size: 16px;
        }
    }
}
</style>