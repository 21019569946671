<template>
	<div class="page max-page-width">
		<v-row class="mx-6">
			<v-col cols="12">
				<div class="d-flex gap-3 my-3">
					<datepicker-field v-model="balanceReportDate" />
					<hra-button @click="onGetVacationBalanceReport">Atsiusti atostogų likučius</hra-button>
				</div>

				<hra-button class="my-3" @click="onGetVacationReport">Test šiandienos atostogų ataskaita</hra-button>
			</v-col>
			<v-col cols="12">
				<h3>Atostogu ataskaita</h3>
				<div class="d-flex align-end gap-3">
					<datepicker-field label="Nuo (atostogu pirma diena)" v-model="fromDate" />
					<datepicker-field :min-date="fromDate" label="Iki (atostogu pirma diena)" v-model="toDate" />
					<div style="width: 320px">
						<select-field label="Statusas" :options="statusOptions" multiple v-model="status" />
					</div>
					<hra-button @click="onGetVacationsReport">Atsiusti</hra-button>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import moment from "moment/moment";
import DatepickerField from "@/domain/fields/DatepickerField";
import HraButtonLink from "@/components/ui/button/HraButtonLink";
import HraButton from "@/components/ui/button/HraButton";
import SelectField from "@/domain/fields/SelectField";

export default {
	name: "ReportsPage",
	components: {SelectField, HraButton, HraButtonLink, DatepickerField},
	data() {
		const today = moment().format('YYYY-MM-DD');

		return {
			fromDate: today,
			toDate: today,
			status: ["0", "1"],
			balanceReportDate: today,
			statusOptions: [
				{value: "0", text: "Patvirtintos"},
				{value: "1", text: "Laukiančios patvirtinimo"},
				{value: "2", text: "Atšauktos"},
			]
		};
	},
	methods: {
		onGetVacationsReport() {
			const status = this.status.map(item => `status[]=${item}`).join("&")
			window.location.href = `/api/v2/vacations/reports/?fromDate=${this.fromDate}&toDate=${this.toDate}&${status}`;
		},
		onGetVacationReport() {
			window.location.href = "/api/v2/vacations/reports/daily"
		},
		onGetVacationBalanceReport() {
			window.location.href = `/api/v2/vacations/reports/balance/${this.balanceReportDate}`;
		},
	}
}
</script>