import changelogs from "@/store/modules/employee/changelogs";
import disabilities from "@/store/modules/employee/disabilities";
import child from "@/store/modules/employee/child";
import vacationData from "@/store/modules/employee/vacationData";
import vacationPlus from "@/store/modules/employee/vacationPlus";
import vacations from "@/store/modules/employee/vacations";
import vacationModal from "@/store/modules/employee/vacationModal";
import importData from "@/store/modules/employee/importData";
import leaderVacations from "@/store/modules/employee/leaderVacations";

const initState = () => ({
  profile: null,
  contract: null,
  contact: null,
  fields: {},
  roles: {
    role_id: 2,
    permissions: [],
  },
  options: {
    departments: [],
    duties: [],
  },
  deleted: null,
  deleteDate: null,
  deleteReason: null,
  disabilities: [],
  child: [],
});

const state = () => initState();

// getters
const getters = {
  profile: state => state.profile,
  contract: state => state.contract,
  contact: state => state.contact,
  fields: state => state.fields,
  roles: state => state.roles,
  options: state => state.options,
  deleted: state => state.deleted,
  deleteDate: state => state.deleteDate,
  deleteReason: state => state.deleteReason,
  contractDetails: state => {
    if (!state.contract) {
      return null;
    }

    return state.contract.details[state.contract.details.length - 1];
  },
};

// actions
const actions = {
  init: ({commit}, payload) => {
    commit("init", payload);
  },
  changeRoles: ({commit}, payload) => commit("changeRoles", payload),
  createAvatar: ({commit, state}, {url, blob}) => {
    commit('setAvatarBlob', blob);
    commit('changeAvatar', url);
  },
  changeAvatar: ({commit, state}, {id, avatarSrc}) => {
    if (state.profile?.id === id) {
      commit("changeAvatar", avatarSrc);
    }
  },
  changeDescription: ({commit, state}, {employeeId, description}) => {
    if (state.profile?.id === employeeId) {
      commit("changeDescription", description);
    }
  },
  destroy: ({commit}) => commit('destroy'),
};

//  mutations
const mutations = {
  init: (state, payload) => Object.assign(state, payload),
  destroy: state => Object.assign(state, initState()),
  changeRoles: (state, payload) => state.roles = payload,
  setAvatarBlob: (state, payload) => {
    if (state?.profile) {
      state.profile.avatarBlob = payload;
    }
  },
  changeAvatar: (state, payload) => {
    if (state?.profile) {
      state.profile.avatarSrc = payload;
    }
  },
  changeDescription: (state, payload) => {
    if (state?.profile) {
      state.profile.description = payload;
    }
  },
  createDepartmentOption(state, payload) {
    state.options.departments.push({value: payload, text: payload});
  },
  createDutyOption(state, payload) {
    state.options.duties.push({value: payload, text: payload});
  }
};

export default {
  namespaced: true,
  modules: {
    changelogs,
    disabilities,
    child,
    vacationData,
    vacationPlus,
    vacations,
    leaderVacations,
    vacationModal,
    importData
  },
  state,
  getters,
  actions,
  mutations
}
