<template>
    <div class='event-status' v-on='$listeners'>
        <div class='event-status__container' :data-status-name='statusName'>
            <slot name='prepend' />
            <div class='event-status__name'>{{ statusText }}</div>
        </div>

        <div v-if='!!date' class='event-status__date'>
            {{ date }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'EventStatus',
    props: {
        status: { type: String, required: true },
        statusText: { type: String, required: true },
        date: { type: String, default: null }
    },
    computed: {
        statusName() {
            return {
                'pending': 'pending',
                'created': 'pending',
                'created_by_owner': 'pending',
                'created_by_admin': 'pending',
                'approved': 'approved',
                'approved_by_owner': 'approved',
                'approved_by_leader': 'approved',
                'approved_by_admin': 'approved',
                'aborted': 'aborted',
                'changed': 'aborted',
                'updated': 'aborted',
                'aborted_by_admin': 'aborted',
                'aborted_by_system': 'aborted',
                'aborted_by_leader': 'aborted',
                'aborted_by_owner': 'aborted'
            }[this.status] ?? ''
        }
    }
}
</script>

<style lang='scss'>
.event-status {
    .hra-tooltip {
        line-height: 1;
    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        padding: 7px 10px;
        width: fit-content;

        &[data-status-name="pending"] {
            background: #FFF2DD;
            color: #64420E;
        }

        &[data-status-name="aborted"] {
            background: #E7384F;
            color: #FFEDF0;
        }

        &[data-status-name="approved"] {
            background: rgba(1, 187, 153, 0.15);
            color: #02856D;
        }
    }

    &__name {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    &__date {
        margin-top: 3px;
        color: #70778C;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}
</style>