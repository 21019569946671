import * as baseTableExample from "@/store/examples/baseTableExample";
import {convertRowToInlineRowData} from "@/utils/default";

  export const stateInit = () => ({
      ...baseTableExample.stateInit(),
  });

  export const gettersInit = {
      ...baseTableExample.gettersInit,
  };

  export const mutationsInit = {
      ...baseTableExample.mutationsInit,
      init(state, payload) {
          state.data = payload.map(row => convertRowToInlineRowData(state.fields, row));
      },
    create(state, payload) {
          state.data.push(convertRowToInlineRowData(state.fields, payload));
      },
    update(state, payload) {
          state.data = state.data
                .map(item => item.id === payload.id ? convertRowToInlineRowData(state.fields, payload) : item);
      },
};