import {
    SET_ID,
    SET_DELETED_DATA,
    SET_DISABILITIES,
    SET_PROFILE,
    SET_OPTIONS,
    SET_CONTRACTS,
    SET_CHANGELOGS,
    SET_ROLES,
    SET_VACATION_INFO,
    SET_PLUS_VACATIONS,
    SET_USER,
    SET_DEPARTMENTS,
    SET_DUTIES,
    SET_CONTACT,
    SET_CONTRACT_DETAIL,
    SET_VACATIONS,
    SET_CONTRACT,
    CREATE_PLUS_VACATIONS,
    DESTROY_PLUS_VACATIONS,
    UPDATE_PLUS_VACATIONS,
    CREATE_DISABILITY,
    UPDATE_DISABILITY,
    DESTROY_DISABILITY,
    CREATE_CHILD,
    UPDATE_CHILD,
    DESTROY_CHILD,
    SET_CHILD,
    SET_DISMISSED,
    SET_DISMISSES,
    CLEAR_STATE,
    CREATE_VACATION,
    UPDATE_VACATION,
    ABORT_VACATION,
    APPROVE_VACATION,
    SET_EMPLOYEE,
    SET_EVENTS,
    SET_ADDITIONAL_HOLIDAYS,
    CREATE_ADDITIONAL_HOLIDAYS,
    UPDATE_ADDITIONAL_HOLIDAYS,
    DESTROY_ADDITIONAL_HOLIDAYS,
    UPDATE_EVENT,
    CREATE_EVENT,
    CHANGE_USER_ROLE, CHANGE_AVATAR, SET_CREATE_MODE
} from '@/domain/employee/store/mutations'
import { USER_ROLE_ADMIN, USER_ROLE_EMPLOYEE } from '@/store/modules/user'
import customApi from '@/plugins/customApi'
import _ from 'lodash'

const formatUser = (user) => {
    if (user == null) {
        return null
    }

    user.role = user.roles.pop().id
    user.permissions = user.permissions.map(({ name }) => name)
    delete user.roles

    return user
}

export const fetchToCreateEmployee = async ({ commit, dispatch }, { tag }) => {
    const api = customApi(tag)
    const [departments, duties] = await api.requests([
        api.factories.department.getAll(),
        api.factories.duties.getAll()
    ])

    const options = {
        departments: departments.map(item => ({ value: item.id, text: item.name })),
        duties: duties.map(item => ({ value: item.id, text: item.name }))
    }

    commit(SET_OPTIONS, options)
    commit(SET_CREATE_MODE)
}

export const fetchEmployeeById = async ({ commit, dispatch }, { id, tag }) => {
    const api = customApi(tag)
    const [employee, changeLogs, departments, duties] = await api.requests([
        api.factories.employee.fetch(id),
        api.factories.employee.changeLogs.fetchByUserId(id),
        api.factories.department.getAll(),
        api.factories.duties.getAll()
    ])

    const { user, contract, contact, disabilities, children, additionalHolidays, events } = employee

    commit(SET_EMPLOYEE, { user, contract, contacts: contact, disabilities, children })
    commit(SET_ADDITIONAL_HOLIDAYS, additionalHolidays)

    const options = {
        departments: departments.map(item => ({ value: item.id, text: item.name })),
        duties: duties.map(item => ({ value: item.id, text: item.name }))
    }

    commit(SET_OPTIONS, options)
    commit(SET_EVENTS, events)
    commit(SET_CHANGELOGS, changeLogs)
    dispatch('loadersStore/hideLoader', tag, { root: true })
}

export const fetchChangeLogs = async ({ commit, getters }) => {
    const userId = getters['userId'] ?? null
    if (userId == null) {
        return
    }

    const api = customApi(null)
    const changeLogs = await api.request(api.factories.employee.changeLogs.fetchByUserId(userId))
    if (changeLogs == null) {
        return
    }

    commit(SET_CHANGELOGS, changeLogs)
}

export const fetchAdditionalHolidays = async ({ commit, getters }) => {
    const userId = getters['userId'] ?? null
    if (userId == null) {
        return
    }

    const api = customApi(null)
    const additionalHolidays = await api.request(api.factories.employee.additionalHolidays.fetchByUserId(userId))
    if (additionalHolidays == null) {
        return
    }

    commit(SET_ADDITIONAL_HOLIDAYS, additionalHolidays)
}

export const updateUser = async ({ commit, dispatch, state }, { tag, id, payload }) => {
    if (state.isCreateMode) {
        return true
    }

    const api = customApi(tag)
    const response = await api.request(api.factories.employee.users.update(id, payload))
    dispatch('fetchChangeLogs')

    return response != null
}

export const updateUserCustom = async ({ commit, dispatch, state }, { tag, id, param, payload }) => {
    if (state.isCreateMode) {
        return true
    }

    const api = customApi(tag)
    const response = await api.request(api.factories.employee.users.updateCustom(id, param, payload))
    dispatch('fetchChangeLogs')

    return response != null
}

export const onUpdateContactOptional = async ({ commit, dispatch, state }, { tag, id, payload }) => {
    if (state.isCreateMode) {
        return true
    }

    const api = customApi(tag)
    const response = await api.request(api.factories.employee.contracts.update(id, payload))
    dispatch('fetchChangeLogs')

    return response != null
}

export const onUpdateContactWithSyncAdditionalData = async ({ commit, dispatch, state }, {
    tag,
    id,
    param,
    payload
}) => {
    if (state.isCreateMode) {
        return true
    }

    const api = customApi(tag)
    const response = await api.request(api.factories.employee.contracts.updateCustom(id, param, payload))
    dispatch('fetchAdditionalHolidays')
    dispatch('fetchChangeLogs')

    return response != null
}

export const updateContact = async ({ commit, dispatch, state }, { tag, id, payload }) => {
    if (state.isCreateMode) {
        return true
    }

    const api = customApi(tag)
    const response = await api.request(api.factories.employee.contacts.update(id, payload))
    dispatch('fetchChangeLogs')

    return response != null
}

export const createAdditionalHoliday = async ({ commit, dispatch, state }, { tag, payload }) => {
    if (state.isCreateMode) {
        commit(CREATE_ADDITIONAL_HOLIDAYS, {
            ...payload,
            id: _.uniqueId()
        })
        return true
    }

    const api = customApi(tag)
    const additionalHoliday = await api.request(api.factories.employee.additionalHolidays.create(payload))
    if (additionalHoliday == null) {
        return false
    }

    commit(CREATE_ADDITIONAL_HOLIDAYS, additionalHoliday)
    dispatch('fetchChangeLogs')
    return true
}

export const updateAdditionalHoliday = async ({ commit, dispatch, state }, { tag, id, payload }) => {
    if (state.isCreateMode) {
        commit(UPDATE_ADDITIONAL_HOLIDAYS, {
            ...payload,
            id
        })
        return true
    }

    const api = customApi(tag)
    const additionalHoliday = await api.request(api.factories.employee.additionalHolidays.update(id, payload))
    if (additionalHoliday == null) {
        return false
    }

    commit(UPDATE_ADDITIONAL_HOLIDAYS, additionalHoliday)
    dispatch('fetchChangeLogs')
    return true
}

export const destroyAdditionalHoliday = async ({ commit, dispatch, state }, { tag, id }) => {
    if (state.isCreateMode) {
        commit(DESTROY_ADDITIONAL_HOLIDAYS, id)
        return true
    }

    const api = customApi(tag)
    const result = await api.request(api.factories.employee.additionalHolidays.destroy(id))
    if (result == null) {
        return
    }

    dispatch('fetchChangeLogs')
    commit(DESTROY_ADDITIONAL_HOLIDAYS, id)
}

export const createDisability = async ({ commit, dispatch, state }, { tag, userId, payload }) => {
    if (state.isCreateMode) {
        commit(CREATE_DISABILITY, {
            ...payload,
            id: _.uniqueId()
        })
        return true
    }

    const api = customApi(tag)
    const disability = await api.request(api.factories.employee.disabilities.create(userId, payload))
    if (disability == null) {
        return false
    }

    dispatch('fetchChangeLogs')
    commit(CREATE_DISABILITY, disability)
    return true
}

export const updateDisability = async ({ commit, dispatch, state }, { tag, id, payload }) => {
    if (state.isCreateMode) {
        commit(UPDATE_DISABILITY, {
            ...payload,
            id
        })
        return true
    }

    const api = customApi(tag)
    const disability = await api.request(api.factories.employee.disabilities.update(id, payload))
    if (disability == null) {
        return false
    }

    dispatch('fetchChangeLogs')
    commit(UPDATE_DISABILITY, disability)
    return true
}

export const destroyDisability = async ({ commit, dispatch, state }, { tag, id }) => {
    if (state.isCreateMode) {
        commit(DESTROY_DISABILITY, id)
        return true
    }

    const api = customApi(tag)
    const result = await api.request(api.factories.employee.disabilities.destroy(id))
    if (result == null) {
        return
    }

    dispatch('fetchChangeLogs')
    commit(DESTROY_DISABILITY, id)
}

export const createChild = async ({ commit, dispatch, state }, { tag, userId, payload }) => {
    if (state.isCreateMode) {
        commit(CREATE_CHILD, {
            ...payload,
            id: _.uniqueId()
        })
        return true
    }

    const api = customApi(tag)
    const child = await api.request(api.factories.employee.children.create(userId, payload))
    if (child == null) {
        return false
    }

    dispatch('fetchChangeLogs')
    commit(CREATE_CHILD, child)
    return true
}

export const updateChild = async ({ commit, dispatch, state }, { tag, id, payload }) => {
    if (state.isCreateMode) {
        commit(UPDATE_CHILD, {
            ...payload,
            id
        })
        return true
    }

    const api = customApi(tag)
    const child = await api.request(api.factories.employee.children.update(id, payload))
    if (child == null) {
        return false
    }

    dispatch('fetchChangeLogs')
    commit(UPDATE_CHILD, child)
    return true
}

export const destroyChild = async ({ commit, dispatch, state }, { tag, id }) => {
    if (state.isCreateMode) {
        commit(DESTROY_CHILD, id)
        return true
    }

    const api = customApi(tag)
    const result = await api.request(api.factories.employee.children.destroy(id))
    if (result == null) {
        return
    }

    dispatch('fetchChangeLogs')
    commit(DESTROY_CHILD, id)
}

export const createEvent = ({ commit }, payload) => {
    commit(CREATE_EVENT, payload)
}

export const updateEvent = ({ commit }, payload) => {
    commit(UPDATE_EVENT, payload)
}

export const changeEvent = ({ commit }, payload) => {
    commit(UPDATE_EVENT, payload.parent)
    commit(CREATE_EVENT, payload)
}

export const abortEvent = ({ commit }, payload) => {
    commit(UPDATE_EVENT, payload)
}

export const approveEvent = ({ commit }, payload) => {
    commit(UPDATE_EVENT, payload)
}

export const changeUserRole = async ({ commit, dispatch, state }, { tag, userId, role }) => {
    if (state.isCreateMode) {
        commit(CHANGE_USER_ROLE, role)
        return true
    }

    const api = customApi(tag)
    const result = await api.request(api.factories.employee.changeRole({ userId, role }))
    if (result == null) {
        return false
    }

    dispatch('loadersStore/hideLoader', tag, { root: true })
    dispatch('fetchChangeLogs')

    commit(CHANGE_USER_ROLE, role)
    return true
}

export const changeAvatar = async ({ commit, dispatch, state }, { tag, userId, blob }) => {
    if (state.isCreateMode) {
        commit(CHANGE_AVATAR, blob)
        return true
    }

    const formData = new FormData()
    formData.append('avatar', blob, 'avatar.png')

    const api = customApi(tag)
    const response = await api.request(api.factories.employee.changeAvatar(userId, formData))
    if (response == null) {
        return false
    }

    commit(CHANGE_AVATAR, blob)

    dispatch('loadersStore/hideLoader', tag, { root: true })
    dispatch('currentUserStore/changeAvatar', { userId, blob }, { root: true })

    return true
}


// export const updateUser = async ({ commit }, { id, tag, payload }) => {
//     const api = customApi(tag)
//     const response = await api.request(api.factories.employee.users.update(id, payload))
// }

export const init = ({ commit }, { departments, duties, employee }) => {
    // commit(SET_ID, employee.id)
    // const options = {
    //     departments: departments.map(item => ({ value: item.id, text: item.name })),
    //     duties: duties.map(item => ({ value: item.id, text: item.name }))
    // }
    //
    // commit(SET_OPTIONS, options)
    // commit(SET_DELETED_DATA, employee.deleteData)
    // commit(SET_PROFILE, employee.profile)
    // commit(SET_USER, formatUser(employee.user))
    // commit(SET_DEPARTMENTS, employee.department)
    // commit(SET_DUTIES, employee.duties)
    // commit(SET_DISABILITIES, employee.disabilities)
    // commit(SET_CHILD, employee.child)
    // commit(SET_CONTACT, employee.contact)
    // commit(SET_VACATIONS, employee.vacations)
    // const contract = employee.contracts.slice(-1).pop()
    // commit(SET_CONTRACT, contract)
    // commit(SET_CONTRACT_DETAIL, contract.details.slice(-1).pop())
    // commit(SET_CHANGELOGS, employee.changelogs)
    // commit(SET_VACATION_INFO, employee.vacationInfo)
    // commit(SET_PLUS_VACATIONS, employee.plusVacations)
    // commit(SET_DISMISSED, employee.dismissed)
    // commit(SET_DISMISSES, employee.dismisses)
}

export const initToCreate = ({ commit }, payload) => {
    // const options = {
    //     departments: payload.departments.map(item => ({ value: item.id, text: item.name })),
    //     duties: payload.duties.map(item => ({ value: item.id, text: item.name }))
    // }
    //
    // commit(SET_OPTIONS, options)
    // commit(SET_USER, { role: USER_ROLE_EMPLOYEE, permissions: [] })
    // commit(SET_USER, {email: 'test@test.com', role: USER_ROLE_EMPLOYEE, permissions: []});
    // commit(SET_PROFILE, {"avatarSrc":null,"name":"Tadeuš Tunkevič"});
    // commit(SET_DEPARTMENTS, [9]);
    // commit(SET_DUTIES, [9]);
    // commit(SET_DISABILITIES, [{"id":"ce892835-1ec9-42f2-95d1-da23308e595b","number":"fdsf","fromDate":"2022-11-28","toDate":"2022-11-28"}])
    // commit(SET_CHILD, [{"id":"4cac01f9-a3f6-444b-95e8-e44f62760e41","name":"sss","brithDate":"2022-11-28","disability":0,"disabilityNo":"","disabilityFrom":null,"disabilityTo":null}]);
    // commit(SET_CONTACT, {"phone":"+37063842764","address":"Pranciskonu g. 21, Medininkai, Vilniaus r. sav.","city":"Vilnius","country":"Lithuania"});
    // commit(SET_CONTRACT, {"timeCardNumber":"xtest","number":"x2test","date":"2022-11-01",workStartAt: "2022-11-10"});
    // commit(SET_CONTRACT_DETAIL, {"workDaysPerWeek":"5","workHoursPerWeek":"40","vacationDaysPerYear":"20"});
}

export const setChangelogs = ({ commit }, payload) => {
    commit(SET_CHANGELOGS, payload)
}

export const setRoles = ({ commit }, payload) => {
    commit(SET_ROLES, payload)
}

export const changeUser = ({ commit }, payload) => {
    commit(SET_USER, formatUser(payload))
}

export const changeProfile = ({ commit }, payload) => {
    commit(SET_PROFILE, payload)
}

export const changeContact = ({ commit }, payload) => {
    commit(SET_CONTACT, payload)
}

export const changeDepartments = ({ commit }, payload) => {
    commit(SET_DEPARTMENTS, payload)
}

export const changeDuties = ({ commit }, payload) => {
    commit(SET_DUTIES, payload)
}

export const changeContract = ({ commit }, payload) => {
    commit(SET_CONTRACT, payload)
}

export const addContractDetail = ({ commit }, payload) => {
    commit(SET_CONTRACT_DETAIL, payload)
}

export const createPlusVacation = ({ commit }, payload) => {
    commit(CREATE_PLUS_VACATIONS, payload)
}

export const updatePlusVacation = ({ commit }, payload) => {
    commit(UPDATE_PLUS_VACATIONS, payload)
}

export const destroyPlusVacation = ({ commit }, payload) => {
    commit(DESTROY_PLUS_VACATIONS, payload)
}

// export const createChild = ({ commit }, payload) => {
//     commit(CREATE_CHILD, payload)
// }
//
// export const updateChild = ({ commit }, payload) => {
//     commit(UPDATE_CHILD, payload)
// }
//
// export const destroyChild = ({ commit }, payload) => {
//     commit(DESTROY_CHILD, payload)
// }

export const clearState = ({ commit }) => commit(CLEAR_STATE)

export const createVacation = ({ commit }, payload) => commit(CREATE_VACATION, payload)
export const updateVacation = ({ commit }, payload) => commit(UPDATE_VACATION, payload)
export const changeVacation = ({ commit, state }, payload) => {
    if (state.vacations.find(({ id }) => id === payload.id)) {
        commit(UPDATE_VACATION, payload)
        return
    }

    commit(ABORT_VACATION, payload.parentId)
    commit(CREATE_VACATION, payload)
}
export const abortVacation = ({ commit }, payload) => commit(ABORT_VACATION, payload)
export const approveVacation = ({ commit }, payload) => commit(APPROVE_VACATION, payload)