<template>
	<expansion-panel :header="$t('Atostogos')">
		<v-row>
<!--			<v-col cols="12">-->
<!--				<employee-vacation-info-table />-->
<!--			</v-col>-->
			<v-col cols="12">
				<employee-additional-holidays :readOnly="!isEdit" />
			</v-col>
			<v-col cols="12">
				<employee-vacations-table :is-edit="isEdit" :is-create="isCreate" :is-profile="isProfile" />
			</v-col>
		</v-row>
	</expansion-panel>
</template>

<script>
import ExpansionPanel from "@/domain/components/ExpansionPanel";
import expansionEmployeeMixin from "@/domain/employee/mixins/expansionEmployeeMixin";
import EmployeeVacationInfoTable from "@/domain/employee/components/tables/EmployeeVacationInfoTable";
import EmployeeVacationsTable from "@/domain/employee/components/tables/EmployeeVacationsTable";
import EmployeeAdditionalHolidays from '@/domain/employee/components/tables/EmployeeAdditionalHolidaysTable.vue'

export default {
	name: "ExpansionEmployeeVacations",
	components: { EmployeeAdditionalHolidays, EmployeeVacationsTable, EmployeeVacationInfoTable, ExpansionPanel},
	mixins: [expansionEmployeeMixin],
}
</script>