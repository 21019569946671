<template>
    <custom-dialog :max-width='550'>
        <template #title>{{ $t('Archyvuoti dokumentą') }}</template>
        <template #content>
            <v-row class='pa-3'>
                <v-col cols='12'>
                    <p>{{ $t('Ar tikrai norite archyvuoti dokumentą?') }}</p>
                </v-col>
            </v-row>
        </template>
        <template #footer>
            <ui-button prepend-icon='close' background='default' @click='close'>{{ $t('Uždaryti') }}</ui-button>
            <ui-button prepend-icon='ok' background='danger' @click='handleSubmit' :loading='loading'>{{ $t('Archyvuoti') }}
            </ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import UiButton from '@/domain/components/UiButton.vue'
import dialogMixin from '@/mixins/dialogMixin'
import customApi from '@/plugins/customApi'
import { GLOBAL_TOAST_TAG } from '@/utils/default'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'

export default {
    name: 'DocumentArchiveModal',
    components: { CustomDialog, UiButton },
    mixins: [dialogMixin],
    props: {
        item: { type: Object, required: true }
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        async handleSubmit() {
            this.loading = true

            try {
                await customApi().factories.documents.documents.archive(this.item.id)
                this.$emit('documentArchived', this.item.id)
                this.showSuccessInRootToast(this.$t('Dokumentas perkeltas į archyvą'))
                this.$root.$emit('documents-overview-disk-usage-refresh')
                this.close()
            } catch (error) {
                this.showErrorInRootToast(error, this.$t('Nepavyko archyvuoti dokumento'))
            } finally {
                this.loading = false
            }
        }
    }
}
</script>