<template>
    <leader-event-invite-line
        :days='days'
        :type-text='typeText'
        :end='end'
        :start='start'
        :user='user'
        :employee-message='employeeMessage'
        :expanded='expanded'
        :confirm-abort-text='$t("Ar tikrai norite atmesti komandiruotės prašymą?")'
        :confirm-approve-text='$t("Ar tikrai norite patvirtinti komandiruotės prašymą?")'

        @approve='handleApprove'
        @abort='handleAbort'
        @expand='handleExpand'
    >
        <template #expand>
            <leader-event-invite-line-expend-detail>
                <template #title>{{ $t('Dienų skaičius') }}</template>
                <template #value><b>{{ event.workDays }}</b> {{ $t('d. d.') }}</template>
            </leader-event-invite-line-expend-detail>

            <leader-event-invite-line-expend-detail>
                <template #title>{{ $t('Šalis') }}</template>
                <template #value>{{ countries }}</template>
            </leader-event-invite-line-expend-detail>

            <leader-event-invite-line-expend-detail>
                <template #title>{{ $t('Kelionės būdas') }}</template>
                <template #value>{{ travelMethods }}</template>
            </leader-event-invite-line-expend-detail>

            <leader-event-invite-line-expend-detail>
                <template #title>{{ $t('Prašymas išsiųstas') }}</template>
                <template #value>{{ event.createdAt }}</template>
            </leader-event-invite-line-expend-detail>
        </template>
    </leader-event-invite-line>
</template>

<script>
import UiButton from '@/domain/components/UiButton.vue'
import LeaderEventInviteLineExpendDetail
    from '@/domain/eventInvite/components/leader/LeaderEventInviteLineExpendDetail.vue'
import LeaderEventInviteLine from '@/domain/eventInvite/components/leader/LeaderEventInviteLine.vue'
import { GLOBAL_TOAST_TAG } from '@/utils/default'
import CustomApi from '@/plugins/customApi'

const customApi = CustomApi()
export default {
    name: 'LeaderBusinessTripEventInvite',
    components: { LeaderEventInviteLine, LeaderEventInviteLineExpendDetail, UiButton },
    props: {
        invite: { type: Object, required: true },
        expanded: { type: Boolean, default: false }
    },
    computed: {
        event() {
            return this.invite.approvalable
        },
        user() {
            return this.event.user
        },
        start() {
            return this.event.start
        },
        end() {
            return this.event.end
        },
        typeText() {
            return this.event.typeText
        },
        days() {
            return this.event.calendarDays + ' ' + this.$t('k. d.')
        },
        employeeMessage() {
            return this.event.message
        },
        countries() {
            return this.event.businessTrip.countries.join(', ')
        },
        travelMethods() {
            return this.event.businessTrip.travelMethods.join(', ')
        }
    },
    methods: {
        showSuccessMessage(message) {
            this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag: GLOBAL_TOAST_TAG,
                message
            })
        },
        showErrorMessage(message) {
            this.$store.dispatch('messagesStore/showErrorMessage', {
                tag: GLOBAL_TOAST_TAG,
                message
            })
        },
        async handleApprove(confirm, message) {
            try {
                const result = await customApi.factories.approvals.invites.approve(this.invite.id, message)

                if (result == null) {
                    throw new Error('Request error')
                }

                this.showSuccessMessage(this.$t('Prašymas patvirtintas'))
                this.$emit('deleted', this.invite.id)
            } catch (e) {
                console.error(e)
                this.showErrorMessage(this.$t('Nepaviko patvirtinti prašymą'))
            } finally {
                confirm.close()
            }
        },
        async handleAbort(confirm, message) {
            try {
                const result = await customApi.factories.approvals.invites.abort(this.invite.id, message)

                if (result == null) {
                    throw new Error('Request error')
                }

                this.showSuccessMessage(this.$t('Prašymas atmestas'))
                this.$emit('deleted', this.invite.id)
            } catch (e) {
                console.error(e)
                this.showErrorMessage(this.$t('Nepaviko atmesti prašymą'))
            } finally {
                confirm.close()
            }
        },
        handleExpand() {
            this.$emit('expand', this.invite.id)
        }
    }
}
</script>