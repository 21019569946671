<template>
    <custom-dialog :max-width='1200'>
        <template #title>{{ $t('Dokumento peržiūra') }}</template>
        <template #content>
            <v-row class='pa-3'>
                <v-col cols='12'>
                    <document-sent-user-preview
                        :file-type='fileType'
                        :document-sent-id='item.id'
                    />
                </v-col>
            </v-row>
        </template>

        <template #footer>
            <ui-button prepend-icon='close' background='default' @click='close'>{{ $t('Uždaryti') }}</ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import dialogMixin from '@/mixins/dialogMixin'
import UiButton from '@/domain/components/UiButton.vue'
import DocxPreview from '@/domain/documents/components/DocxPreview.vue'
import ExcelPreview from '@/domain/documents/components/ExcelPreview.vue'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import CustomApi from '@/plugins/customApi'
import DocumentSentUserPreview from '@/domain/documents/components/DocumentSentUserPreview.vue'

const customApi = CustomApi()
export default {
    name: 'DocumentSentUserPreviewModal',
    components: { DocumentSentUserPreview, UiButton, ExcelPreview, DocxPreview, CustomDialog },
    mixins: [dialogMixin],
    props: {
        item: Object
    },
    computed: {
        fileType() {
            return this.item.document.filename.split('.').pop().toLowerCase()
        }
    }
}
</script>