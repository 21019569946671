<template>
    <ui-table
        :loading='loading'
        :loading-more='loadingMore'
        :visible-load-more='visibleLoadMore'
        :is-empty-table='isEmptyTable'
        @clickLoadMore='handleLoadMore'
    >
        <template #head>
            <tr>
                <ui-table-head-filter
                    column='document'
                    :title='$t("Dokumento pavadinimas")'
                    :sort-options='filterSortTextOptions'
                    v-model='filter.document'
                    @changeFilter='handleChangeFilter'
                />
                <table-header-document-sent-status-filter
                    :title='$t("Būsena")'
                    :filter='filter.status'
                    @changeFilter='handleChangeFilter'
                />
                <ui-table-head-filter
                    column='dueAt'
                    :title='$t("Susipažinti iki")'
                    :sort-options='filterSortDateOptions'
                    v-model='filter.dueAt'
                    @changeFilter='handleChangeFilter'
                />
                <th>{{ $t('Siuntėjas') }}</th>
                <th class='text-end'>{{ $t('Funkcijos') }}</th>
            </tr>
        </template>

        <template #body>
            <tr
                v-for='row in rows'
                :key='`row-id-${row.id}`'
                style='height: 64px'
            >
                <td style='max-width: 540px'>{{ row.document.title }}</td>
                <td style='width: 150px'>
                    <document-sent-status-with-comment
                        :status='row.status'
                        :date='row.statusAt'
                        :comment='row.comment'
                    />
                </td>
                <td>{{ row.dueAt }}</td>
                <td>
                    <employee-table-view :employee='row.sentUser' />
                </td>
                <td>
                    <div class='d-flex justify-end'>
                        <ui-button
                            v-if='row.status === "pending"'
                            background='violet-dark'
                            size='medium'
                            @click='handleOpenModalDocumentSign(row)'
                        >
                            {{ $t('Susipažinti') }}
                        </ui-button>
                        <ui-icon
                            v-else
                            class='document-history-table-eye-icon'
                            @click='handleOpenModalDocumentPreview(row)'
                        >eye
                        </ui-icon>
                    </div>
                </td>
            </tr>
        </template>
    </ui-table>
</template>

<script>
import UiTable from '@/domain/components/uiTable/UiTable.vue'
import customApi from '@/plugins/customApi'
import EmployeeTableView from '@/components/ui/views/EmployeeTableView.vue'
import UiButton from '@/domain/components/UiButton.vue'
import UiIcon from '@/domain/components/UiIcon.vue'
import modalMethodsMixin from '@/domain/mixins/modalMethodsMixin'
import DocumentSentStatusWithComment from '@/domain/documents/components/DocumentSentStatusWithComment.vue'
import tableFilterMixin from '@/domain/components/uiTable/mixins/tableFilterMixin'
import UiTableHeadFilter from '@/domain/components/uiTable/UiTableHeadFilter.vue'
import TableHeaderDocumentSentStatusFilter from '@/domain/components/uiTable/filters/TableHeaderDocumentSentStatusFilter.vue'

export default {
    name: 'DocumentsHistoryTable',
    components: { TableHeaderDocumentSentStatusFilter, UiTableHeadFilter, DocumentSentStatusWithComment, UiIcon, UiButton, EmployeeTableView, UiTable },
    mixins: [modalMethodsMixin, tableFilterMixin],
    data() {
        return {
            items: [],
            loading: false,
            loadingMore: false,
            visibleLoadMore: false,
            page: 1,
            limit: 15,
            filter: {
                document: { sort: null },
                status: { selected: [] },
                dueAt: { sort: 'desc' }
            }
        }
    },
    computed: {
        isEmptyTable() {
            return this.items.length === 0 && !this.loading && !this.loadingMore
        },
        rows() {
            return this.items
        }
    },
    created() {
        this.loadData()
        this.$root.$on('employee-document-sign', this.handleEmployeeDocumentSign)
    },
    beforeDestroy() {
        this.$root.$off('employee-document-sign', this.handleEmployeeDocumentSign)
    },
    methods: {
        handleChangeFilter(column, filter) {
            this.setFilter(column, filter)
            this.loadData()
        },
        getRequestParams(page = 1) {
            return {
                page,
                limit: this.limit,
                ...this.getFilterParams()
            }
        },
        async fetch(params) {
            try {
                const {
                    data: {
                        items,
                        pagination: { currentPage },
                        hasMore
                    }
                } = await customApi().factories.documents.userBoard.fetchHistory(params)
                this.items = currentPage === 1 ? items : [...this.items, ...items]
                this.page = currentPage
                this.visibleLoadMore = hasMore
            } catch (e) {

            }
        },
        async loadData() {
            this.loading = true
            await this.fetch(this.getRequestParams())
            this.loading = false
        },
        async handleLoadMore() {
            this.loadingMore = true
            await this.fetch(this.getRequestParams(this.page + 1))
            this.loadingMore = false
        },
        handleEmployeeDocumentSign(item) {
            const index = this.items.findIndex(({ id }) => item.id === id)
            if (index === -1) {
                return
            }
            this.$set(this.items, index, item)
        },
        handleOpenModalDocumentSign(item) {
            this.onOpenModal('DocumentSignModal', { item })
        },
        handleOpenModalDocumentPreview(item) {
            this.onOpenModal('DocumentSentUserPreviewModal', { item })
        }
    }
}
</script>

<style lang='scss'>
.document-history-table-eye-icon {
    display: flex;
    width: 36px;
    height: 36px;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    border: 1px solid rgba(153, 166, 191, 0.50);
    color: #70778C;
    font-size: 20px;
    cursor: pointer;
}
</style>