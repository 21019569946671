//  initial state
import {gettersInit, mutationsInit, stateInit} from "@/store/examples/baseTableInlineEditExample";
import {yesNoOptions} from "@/utils/default";

const initState = () => ({
  ...stateInit(),
  fields: {
    brithDate: {type: "date", rules: 'required'},
    disability: {type: "select", options: yesNoOptions},
    disabilityNo: {type: "number"},
    disabilityFrom: {type: "date"},
  },
});

const state = () => initState();

// getters
const getters = {
  ...gettersInit,
  createData: state => state.data.map(item => ({
    brith_date: item.brithDate.value,
    disability: item.disability.value,
    disability_no: item.disabilityNo.value,
    disability_from: item.disabilityFrom.value,
  })),
  rows: state => state.data.map((item, key) => ({
    id: item.id,
    name: key + 1,
    brithDate: item.brithDate.value,
    disability: item.disability.value,
    disabilityNo: item.disabilityNo.value,
    disabilityFrom: item.disabilityFrom.value,
  })),
};

// actions
const actions = {
  init: ({commit}, payload) => {
    commit("init", payload);
  },
  create: ({commit}, payload) => {
    commit("create", payload);
  },
  update: ({commit}, payload) => {
    commit("update", payload);
  },
  remove: ({commit}, payload) => {
    commit("remove", payload);
  },
  destroy: ({commit}) => commit('destroy'),
};

//  mutations
const mutations = {
  ...mutationsInit,
  destroy: state => Object.assign(state, initState()),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
