<template>
    <custom-dialog
        :max-width='800'
        ref='dialog'
        has-custom-footer
        :loading='loading'
    >
        <template #title>{{ $t('Informacija ligonlapio') }}</template>
        <template #content>
            <v-row class='pa-3'>
                <v-col cols='6'>
                    <user-select-field
                        disabled
                        :label="$t('Darbuotojas')"
                        :value='formData.user'
                    />
                </v-col>
                <v-col cols='6'>
                    <text-field
                        disabled
                        :label="$t('Asmens kodas')"
                        :value='formData.personalCode'
                    />
                </v-col>
                <v-col cols='6'>
                    <text-field
                        disabled
                        :value='formData.type'
                        rules='required'
                        :label="$t('Pažymėjimo tipas')"
                    />
                </v-col>

                <v-col cols='6'>
                    <text-field
                        disabled
                        :label="$t('Nedarbingumo priežastis')"
                        :value='formData.sickType'
                    />
                </v-col>
                <v-col cols='6'>
                    <datepicker-field
                        disabled
                        :label="$t('Data nuo')"
                        :value='formData.start'
                        numbers-only
                    />
                </v-col>
                <v-col cols='6'>
                    <datepicker-field
                        disabled
                        :label="$t('Data iki')"
                        :min-date='formData.start'
                        :value='formData.end'
                        numbers-only
                    />
                </v-col>
                <v-col cols='6'>
                    <text-field
                        disabled
                        :label="$t('SD serija')"
                        :value='formData.personalSDSeries'
                    />
                </v-col>
                <v-col cols='6'>
                    <text-field
                        disabled
                        :label="$t('SD numeris')"
                        :value='formData.personalSDNumber'
                    />
                </v-col>
                <v-col cols='6'>
                    <text-field
                        disabled
                        :label="$t('Pažymėjimo serija')"
                        :value='formData.noteSeries'
                    />
                </v-col>
                <v-col cols='6'>
                    <text-field
                        disabled
                        :label="$t('Pažymėjimo numeris')"
                        :value='formData.noteNumber'
                    />
                </v-col>
                <v-col cols='4'>
                    <select-field
                        disabled
                        :label="$t('Ar užpildyta draudėjui info')"
                        :value='formData.filledInPolicyholderInfo'
                        :options='booleanOptions'
                    />
                </v-col>
                <v-col cols='4'>
                    <select-field
                        disabled
                        :label="$t('Ar reikia draudejui mokėti')"
                        :value='formData.policyholderNeedsToPay'
                        :options='booleanOptions'
                    />
                </v-col>
                <v-col cols='4'>
                    <select-field
                        disabled
                        :label="$t('Pateikė NPSD')"
                        :value='formData.submittedNPSD'
                        :options='booleanOptions'
                    />
                </v-col>
            </v-row>
        </template>
        <template #footer>
            <v-spacer />
            <ui-button prepend-icon='close' background='default' @click='close'>
                {{ $t('Uždaryti') }}
            </ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import CustomApi from '@/plugins/customApi'
import ImportSickLeavesTable from '@/domain/sickLeaves/components/import/ImportSickLeavesTable.vue'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import ImportSickLeavesUpload from '@/domain/sickLeaves/components/import/ImportSickLeavesUpload.vue'
import UiButton from '@/domain/components/UiButton.vue'
import dialogMixin from '@/mixins/dialogMixin'
import DatepickerField from '@/domain/fields/DatepickerField.vue'
import UserSelectField from '@/domain/fields/UserSelectField.vue'
import TextField from '@/domain/fields/TextField.vue'
import SelectField from '@/domain/fields/SelectField.vue'
import { ValidationObserver } from 'vee-validate'
import { GLOBAL_TOAST_TAG } from '@/utils/default'
import MessageAlert from '@/domain/messages/components/MessageAlert.vue'

const customApi = CustomApi()
export default {
    name: 'InfoSickLeaveModal',
    mixins: [dialogMixin],
    components: {
        MessageAlert,
        ValidationObserver,
        SelectField,
        TextField,
        UserSelectField,
        DatepickerField, UiButton, ImportSickLeavesUpload, CustomDialog, ImportSickLeavesTable
    },
    props: {
        id: { type: Number, required: true }
    },
    data() {
        return {
            loading: false,
            booleanOptions: [
                { value: true, text: 'Taip' },
                { value: false, text: 'Ne' }
            ],
            formData: {
                code: null,
                personalCode: null,
                personalSDSeries: null,
                personalSDNumber: null,
                type: null,
                noteSeries: null,
                noteNumber: null,
                start: null,
                end: null,
                sickType: null,
                filledInPolicyholderInfo: false,
                policyholderNeedsToPay: false,
                submittedNPSD: false,
                user: null
            }
        }
    },
    mounted() {
        this.fetchEvent()
    },
    methods: {
        async fetchEvent() {
            this.loading = true
            try {
                const { data } = await customApi.factories.events.sickLeaves.fetchOne(this.id)

                const formData = {
                    code: data.sickLeave.code,
                    personalCode: data.sickLeave?.personalCode ?? null,
                    personalSDSeries: data.sickLeave.personalSDSeries,
                    personalSDNumber: data.sickLeave.personalSDNumber,
                    type: data.sickLeave.type,
                    noteSeries: data.sickLeave.noteSeries,
                    noteNumber: data.sickLeave.noteNumber,
                    start: data.start,
                    end: data.end,
                    sickType: data.sickLeave.sickType,
                    filledInPolicyholderInfo: data.sickLeave.filledInPolicyholderInfo,
                    policyholderNeedsToPay: data.sickLeave.policyholderNeedsToPay,
                    submittedNPSD: data.sickLeave.submittedNPSD,
                    user: data.user
                }

                this.$set(this, 'formData', formData)
            } catch (e) {

            } finally {
                this.loading = false
            }
        },
    }
}
</script>