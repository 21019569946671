<template>
    <div class='employee-vacations-header pt-4 px-5 px-sm-8' :class='{"mb-12": hasPendingInvites}'>
        <waiting-empty
            v-if='!hasPendingInvites'
            class='employee-vacations-header__empty'
            :coming-vacation='comingHoliday'
        />
        <div class='employee-vacations-header__widgets'>
            <widget
                v-for='({title, value, tooltip}) in widgets'
                :title='title'
                :value='value'
                :tooltip='tooltip'
            />
        </div>
    </div>
</template>

<script>
import Widget from '@/domain/employeeVacations/components/Widget.vue'
import WaitingEmpty from '@/domain/employeeVacations/components/WaitingEmpty.vue'
import CustomApi from '@/plugins/customApi'

const customApi = CustomApi()
export default {
    name: 'EmployeeCalendarHeader',
    components: { WaitingEmpty, Widget },
    data() {
        return {
            hasPendingInvites: false,
            widgets: [],
            comingHoliday: null
        }
    },
    mounted() {
        this.fetch()
    },
    methods: {
        async fetch() {
            try {
                const { data: { widgets, hasPendingInvites, comingHoliday } } = await customApi
                    .factories.employee.holidays.widgets.fetch(this.$store.getters['currentUserStore/userId'])

                this.widgets = widgets
                this.hasPendingInvites = hasPendingInvites
                this.$set(this, 'comingHoliday', comingHoliday)
            } catch (e) {
                console.error(e)
            }
        }
    }
}
</script>

<style lang='scss'>
@import '@/assets/sass/mixins/_responsive';

.employee-vacations-header {
    display: grid;
    grid-template-columns: 4fr;
    grid-template-rows: 1fr;
    gap: 7px;

    &__widgets {
        display: flex;
        flex-direction: row;
        gap: 7px;
        height: 76px;

        & > div {
            width: 100%;
            height: 90px;
        }
    }

    &:has(&__empty):has(&__widgets > div:nth-child(1)) {
        grid-template-columns: 3fr 1fr;
    }

    &:has(&__empty):has(&__widgets > div:nth-child(3)) {
        grid-template-columns: 2fr 2fr;
    }

    &:has(&__empty) &__widgets {
        height: 188px;
        flex-direction: column;
        flex-wrap: wrap;
    }

    &:has(&__empty):has(&__widgets > div:nth-child(3)) &__widgets {
        & > div {
            width: calc(50% - 7px / 2);
        }
    }

    @include lg-down {
        display: flex;
        flex-direction: column;

        &__widgets {
            flex-direction: row !important;
            height: fit-content !important;
            flex-wrap: wrap !important;
        }

        &:has(&__widgets > div:nth-child(3)) &__widgets {
            & > div {
                width: calc(50% - 7px / 2);
            }
        }
    }

    @include md-down {
        &__widgets {
            flex-direction: column !important;
            flex-wrap: nowrap !important;

            & > div {
                width: 100% !important;
                height: fit-content !important;
            }
        }
    }
}
</style>