import store from "@/store";
import {generateUuid} from "@/utils/default";

export class ImportEmployeeService {

  constructor(id) {
    this.id = id;
    this.employeeData = store.getters["setupStore/importEmployeeData"];
  }

  getDisabilities() {
    return this.employeeData(this.id, "disabilities");
  }

  getChild() {
    return this.employeeData(this.id, "child");
  }

  getVacations() {
    return this.employeeData(this.id, "vacations");
  }

  create(name, payload) {
    this.employeeData(this.id, name).push(({
      ...payload,
      timeCardNumber: this.id,
      id: generateUuid(),
    }));
  }

  update(name, id, payload) {
    let item = this.employeeData(this.id, name).find(item => item.id === id);
    Object.assign(item, payload);
  }

  destroy(name, id) {
    let items = this.employeeData(this.id, name);
    const index =  items.findIndex(disability => disability.id === id);
    items.splice(index, 1);
  }

  async createDisability(tag, payload) {
    this.create("disabilities", payload);
    return true;
  }

  async updateDisability(tag, id, payload) {
    this.update("disabilities", id, payload);
    return true;
  }

  async destroyDisability(tag, id) {
    this.destroy("disabilities", id);
    return true;
  }

  async createChild(tag, payload) {
    this.create("child", payload);
    return true;
  }

  async updateChild(tag, id, payload) {
    this.update("child", id, payload);
    return true;
  }

  async destroyChild(tag, id) {
    this.destroy("child", id);
    return true;
  }
}