//  initial state
const state = () => ({
    loaded: false,
    language: "lt",
    editRowId: null,
    fields: [],
    groups: [{name: "main", title: {lt: "Main", eng: "Main"}}],
    icons: ["settings"],
    types: ["text", "number", "date", "select", "email", "phone"],
    rules: ["required", "min", "max", "email", "phone", "departments", "duties", "yes-no"],
    locales: ["lt", "eng"],
});

// getters
const getters = {
    isLoaded(state) {
        return state.loaded;
    },
    fieldRows(state) {
        return state.fields.map(field => ({
            ...field,
            label: field.label[state.language] || "",
            rules: field.rules.split(","),
            actions: "",
        }));
    },
    rowEditId(state) {
        return state.editRowId;
    },
    typeOptions(state) {
        return state.types.map(type => ({
            value: type,
            text: type,
        }));
    },
    groupOptions(state) {
        return state.groups.map(group => ({
            value: group.name,
            text: group.title[state.language] || "",
        }));
    },
    iconOptions(state) {
        return state.icons.map(icon => ({
            value: icon,
            text: `<i class="icon-${icon}"></i> ${icon}`,
        }));
    },
    ruleOptions(state) {
        return state.rules.map(rule => ({
            value: rule,
            text: rule,
        }));
    },
    yesNoOptions(state) {
        return [
            { value: 0, text: "Ne" },
            { value: 1, text: "Taip" },
        ];
    },
    locales(state) {
        return state.locales;
    },
    groups(state) {
        return state.groups.map(group => ({
            name: group.name,
            title: group.title[state.language] || group.name,
        }));
    },
    fields(state) {
        return state.fields.map(field => ({
            ...field,
            label: field.label[state.language] || "",
        }));
    },
    fieldViews(state) {
        return state.fields.map(field => ({
            name: field.name,
            key_in_create: field.key_in_create,
            key_in_edit: field.key_in_edit,
            key_in_excel: field.key_in_excel,
            view_in_create: field.view_in_create,
            view_in_edit: field.view_in_edit,
            view_in_profile: field.view_in_profile,
            view_in_excel: field.view_in_excel,
        }));
    },
};

// actions
const actions = {};

//  mutations
const mutations = {
    load(state, payload) {
        state.fields = payload.fields.map((field, id) => ({
            ...field,
            id
        }));
        state.groups = payload.groups;

        state.loaded = true;
    },
    setEditRowId(state, payload) {
        state.editRowId = payload;
    },
    createField(state, payload) {
        const id = Math.max.apply(this, state.fields.map(field => field.id)) + 1;
        state.fields.push({
            ...payload,
            id,
        });
        state.editRowId = id;
    },
    editField(state, payload) {
        state.fields = state.fields.map(field => field.id === payload.id ? payload : field);
    },
    deleteField(state, id) {
        state.fields = state.fields.filter(field => field.id !== id);
    },
    changeKeys(state, payload) {
        state.fields = state.fields.map(field => {
            if (payload.keys[field.name] != null) {
                field[payload.type] = payload.keys[field.name];
            }
            return field;
        });
    },
    changeView(state, payload) {
        state.fields = state.fields.map(field => {
            if (field.id === payload.id) {
                field[payload.type] = payload.view;
            }
            return field;
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
