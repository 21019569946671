<template>
	<v-hover>
		<template #default="{hover}">
			<div class="company-tree-employee">
				<Avatar :employee-name="profile.name" :src="profile.avatarSrc" size="32"/>
				<strong>{{ profile.name }}</strong>

				<v-fade-transition v-if="isAdmin">
					<hover-overlay :hover="hover">
						<ui-button
							size="small"
							prepend-icon="change"
							background="white"
							hover-color="primary"
							:modal="modalData"
						>{{$t('Keisti skyrių')}}
						</ui-button>
					</hover-overlay>
				</v-fade-transition>
			</div>
		</template>
	</v-hover>
</template>

<script>
import HraButton from "@/components/ui/button/HraButton.vue";
import HraIcon from "@/components/ui/HraIcon.vue";
import Avatar from "@/components/ui/Avatar.vue";
import HoverOverlay from "@/domain/components/HoverOverlay.vue";
import UiButton from "@/domain/components/UiButton.vue";

export default {
	name: "CompanyTreeItemEmployee",
	components: {UiButton, HoverOverlay, Avatar, HraIcon, HraButton},
	props: {
		departmentId: {type: [Number, String], required: true},
		profile: {type: Object, required: true},
	},
	inject: ["fetchTree", "isAdmin"],
	computed: {
		modalData() {
			return {
				name: 'EmployeeChangeDepartmentModal',
				props: {user: this.profile, departmentId: this.departmentId},
				on: {saved: this.fetchTree}
			};
		},
	},
}
</script>