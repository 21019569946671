import {
    ADD_DETAIL_VACATION,
    SET_LEADER,
    SET_EMPLOYEES,
    SET_DEPARTMENT_OPTIONS,
    SET_DUTY_OPTIONS,
    SET_FILTER_FROM_DATE,
    SET_FILTER_DEPARTMENTS,
    SET_FILTER_DUTIES,
    SET_FILTER_EMPLOYEES,
    SET_SELECTED_EVENT_ID,
    CHANGE_VACATION,
    SET_VACATIONS_TO_APPROVE,
    CLEAR_FROM_WAITING,
    CLEAR_STATE,
    SET_ADMIN,
    SET_EVENTS,
    SET_USER_BALANCES,
    SET_CAN_APPROVE_USER_IDS, APPROVE_EVENT, ABORT_EVENT, SET_FILTER_CALCULATE_DATE
} from '@/domain/departmentVacations/store/mutations'
import moment from 'moment'
import { availableDutyIds } from '@/domain/departmentVacations/store/getters'
import customApi from '@/plugins/customApi'
import _ from 'lodash'
import store from '@/store'

export const fetch = async ({ commit, rootGetters }, { tag }) => {
    const date = moment()
    commit(SET_FILTER_FROM_DATE, date.format('YYYY-MM-DD'))
    const from = date.clone().subtract(2, 'months').format('YYYY-MM-DD')
    const to = date.clone().add(2, 'months').format('YYYY-MM-DD')

    const api = customApi(tag)
    const [events, users, balanceReport] = await api.requests([
        // api.factories.department.events.fetchLeader(),
        // api.factories.department.events.fetchHolidays({ from, to }),
        // api.factories.department.fetchUsers(),
        api.factories.companyCalendar.fetchEvents({from, to}),
        api.factories.companyCalendar.fetchEmployees(),
        api.factories.vacations.reports.balance.fetchDepartmentLeader(date.endOf('year').format('YYYY-MM-DD'))
    ])

    let departmentOptions = []
    let dutyOptions = []

    users.forEach(user => {
        departmentOptions.push({ value: user.department.id, text: user.department.name })
        dutyOptions.push({ value: user.duty.id, text: user.duty.name })
    })

    let userBalances = {}
    balanceReport.forEach(({ id, balance }) => userBalances[id] = balance)
    commit(SET_USER_BALANCES, userBalances)
    commit(SET_EMPLOYEES, users)
    commit(SET_DEPARTMENT_OPTIONS, _.uniq(departmentOptions))
    commit(SET_DUTY_OPTIONS, _.uniq(dutyOptions))
    commit(SET_EVENTS, events)
    // commit(SET_CAN_APPROVE_USER_IDS, leaderData.userIds)
    // commit(SET_VACATIONS_TO_APPROVE, leaderData.invites)

    const currentUser = rootGetters['currentUserStore/user']
    commit(SET_FILTER_DEPARTMENTS, [currentUser.departmentId])
    // commit(SET_LEADER, leaderData.isLeader)
    commit(SET_ADMIN, currentUser.roles.includes('admin'))
}

export const fetchMobile = async ({ commit, rootGetters }, { tag }) => {
    const date = moment()
    commit(SET_FILTER_FROM_DATE, date.format('YYYY-MM-DD'))

    const api = customApi(tag)
    const [leaderData] = await api.requests([
        api.factories.department.events.fetchLeader()
    ])

    commit(SET_CAN_APPROVE_USER_IDS, leaderData.userIds)
    commit(SET_VACATIONS_TO_APPROVE, leaderData.invites)

    const currentUser = rootGetters['currentUserStore/user']
    commit(SET_FILTER_DEPARTMENTS, [currentUser.departmentId])
    commit(SET_LEADER, leaderData.isLeader)
    commit(SET_ADMIN, currentUser.roles.includes('admin'))
}

export const updateDepartmentEventsState = async ({ commit }) => {
    const date = moment()
    commit(SET_FILTER_FROM_DATE, date.format('YYYY-MM-DD'))
    const from = date.clone().subtract(2, 'months').format('YYYY-MM-DD')
    const to = date.clone().add(2, 'months').format('YYYY-MM-DD')

    const api = customApi('DEPARTMENT_VACATIONS_PAGE_INIT');
    const [leaderData, events] = await api.requests([
        api.factories.department.events.fetchLeader(),
        api.factories.companyCalendar.fetchEvents({from, to})
        // api.factories.department.events.fetchHolidays({ from, to })
    ])

    commit(SET_EVENTS, events ?? [])
    commit(SET_VACATIONS_TO_APPROVE, leaderData.invites)
}

export const changeBalanceDate = async ({ commit }, { tag, date }) => {
    const api = customApi(tag)
    const balanceReport = await api.request(api.factories.vacations.reports.balance.fetchDepartmentLeader(date))
    let userBalances = {}
    balanceReport.forEach(({ id, balance }) => userBalances[id] = balance)

    commit(SET_USER_BALANCES, userBalances)
    commit(SET_FILTER_CALCULATE_DATE, date)
}

export const updateTimelineDate = async ({ commit }, { tag, date }) => {
    commit(SET_FILTER_FROM_DATE, date)
    date = moment(date)
    const from = date.clone().subtract(2, 'months').format('YYYY-MM-DD')
    const to = date.clone().add(2, 'months').format('YYYY-MM-DD')

    const api = customApi(tag)
    // const events = await api.request(api.factories.department.events.fetchHolidays({ from, to }))
    const events = await api.request(api.factories.companyCalendar.fetchEvents({from, to}))
    commit(SET_EVENTS, events)
}

export const abortEvent = async ({ commit }, { tag, inviteId, message }) => {
    const api = customApi(tag)
    const result = await api.request(api.factories.department.vacation.leader.abort(inviteId, { message: message ?? '' }))
    if (result == null) {
        return
    }

    await updateDepartmentEventsState({ commit })
    await store.dispatch('messagesStore/showSuccessMessage', { tag, message: message })
}

export const approveEvent = async ({ commit }, { tag, inviteId, message }) => {
    const api = customApi(tag)
    const result = await api.request(api.factories.department.vacation.leader.approve(inviteId, { message: message ?? '' }))
    if (result == null) {
        return
    }

    await updateDepartmentEventsState({ commit })
    await store.dispatch('messagesStore/showSuccessMessage', { tag, message: message })
}

export const prevFromDate = ({ commit, state: { filter: { fromDate } }, dispatch }, payload) => {
    const date = moment(fromDate).subtract(payload, 'days').format('YYYY-MM-DD')

    dispatch('updateTimelineDate', { tag: 'DEPARTMENT_VACATIONS_CHANGE_FROM_DATE', date })
}

export const nextFromDate = ({ commit, state: { filter: { fromDate } }, dispatch }, payload) => {
    const date = moment(fromDate).add(payload, 'days').format('YYYY-MM-DD')

    dispatch('updateTimelineDate', { tag: 'DEPARTMENT_VACATIONS_CHANGE_FROM_DATE', date })
}

export const selectByDuties = ({ commit, dispatch }, { fromDate, duties }) => {
    dispatch('updateTimelineDate', { tag: 'DEPARTMENT_VACATIONS_CHANGE_FROM_DATE', date: fromDate })
    commit(SET_FILTER_DEPARTMENTS, [])
    commit(SET_FILTER_DUTIES, duties)
    commit(SET_FILTER_EMPLOYEES, [])
}

export const selectDepartment = ({ commit, dispatch }, payload) => {
    commit(SET_FILTER_DEPARTMENTS, payload)
    commit(SET_FILTER_EMPLOYEES, [])
    dispatch('formatFilter')
}

export const formatFilter = ({ commit, state: { filter }, getters: { availableDutyIds } }) => {
    commit(SET_FILTER_DUTIES, filter.duties.filter((id) => availableDutyIds.includes(id)))
}

let scrollToEventTimeout = null

export const scrollToEvent = ({ commit }, payload) => {
    clearTimeout(scrollToEventTimeout)
    commit(SET_SELECTED_EVENT_ID, payload)

    scrollToEventTimeout = setTimeout(() => {
        commit(SET_SELECTED_EVENT_ID, null)
    }, 5000)
}

export const setDetailVacation = ({ commit }, payload) => commit(ADD_DETAIL_VACATION, payload)
export const clearFromWaiting = ({ commit }, payload) => commit(CLEAR_FROM_WAITING, payload)
export const changeVacation = ({ commit }, payload) => commit(CHANGE_VACATION, payload)
export const clearState = ({ commit }) => commit(CLEAR_STATE)