<template>
    <div class='remote-work-modal-calendars'>
        <ui-calendar
            hide-background-selected-interval
            show-select-week-days
            with-first-month-navigation
            :year-month='calendarYearMonth'
            :clickable-dates='calendarEditable'
            :max-month-count='maxMonthCount'
            :min-select-date='minDate'
            :select-start-date='formData.start'
            :select-end-date='formData.end'
            :selected-weekdays='calendarSelectedWeekdays'
            :events='calendarEvents'
            :select-dates='formData.dates'

            @update:yearMonth='handleChangeCalendarYearMonth'
            @clickDay='handleClickDay'
        />
    </div>
</template>

<script>
import UiCalendar from '@/domain/components/UiCalendar/UiCalendar.vue'
import { GLOBAL_TOAST_TAG } from '@/utils/default'

export default {
    name: 'RemoteWorkCalendars',
    components: { UiCalendar },
    props: {
        maxMonthCount: {type: Number, default: 1}
    },
    inject: ['storeNamespace'],
    computed: {
        calendarYearMonth() {
            return this.$store.getters[`${this.storeNamespace}/calendarYearMonth`]
        },
        calendarEditable() {
            return this.$store.getters[`${this.storeNamespace}/calendarEditable`]
        },
        calendarSelectedWeekdays() {
            return this.$store.getters[`${this.storeNamespace}/calendarSelectedWeekdays`]
        },
        calendarEvents() {
            return this.$store.getters[`${this.storeNamespace}/calendarEvents`]
        },
        minDate() {
            return this.$store.getters[`${this.storeNamespace}/minDate`]
        },
        formData() {
            return this.$store.getters[`${this.storeNamespace}/formData`]
        }
    },
    methods: {
        handleChangeCalendarYearMonth(value) {
            this.$store.dispatch(`${this.storeNamespace}/calendarSetDate`, value)
        },
        handleClickDay(day) {
            if (day.type !== 'event-base') {
                this.$store.dispatch('messagesStore/showErrorMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Negalima pasirinkti {date}', { date: day.date })
                })
                return
            }

            this.$store.dispatch(`${this.storeNamespace}/calendarToggleDate`, day.date)
        }
    }
}
</script>

<style lang='scss'>
.remote-work-modal-calendars {
    width: 380px;
}
</style>