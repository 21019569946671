<template>
    <grid-item
        :title='folder.title'
        :size='folder.sizeText'
        icon='folder'
        @click='handleChangeFolder'
    >
        <template v-if='withControls' #controls>
            <documents-finder-context-menu>
                <documents-finder-context-menu-item
                    icon='edit'
                    :title='$t("Redaguoti")'
                    @click='handleOpenUpdateFolderModal'
                />
                <documents-finder-context-menu-item
                    v-if='isDeletable'
                    icon='bin'
                    :title='$t("Ištrinti")'
                    @click='handleOpenDeleteFolderModal'
                />
            </documents-finder-context-menu>
        </template>
    </grid-item>
</template>

<script>
import DocumentsFinderContextMenuItem from '@/domain/documents/finder/components/ContextMenuItem.vue'
import DocumentsFinderContextMenu from '@/domain/documents/finder/components/ContextMenu.vue'
import UiIcon from '@/domain/components/UiIcon.vue'
import documentFinderFolderItemMixin from '@/domain/documents/finder/mixins/documentFinderFolderItemMixin'
import GridItem from '@/domain/documents/finder/components/content/grid/GridItem.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'

export default {
    name: 'GridFolderItem',
    components: { FormCheckbox, GridItem, DocumentsFinderContextMenuItem, DocumentsFinderContextMenu, UiIcon },
    mixins: [documentFinderFolderItemMixin],
}
</script>