<template>
    <v-app class='app' id='inspire'>
        <sidebar v-if='!isBondMobile' />
        <Header has-sidebar :class='{mobile: isBondMobile}'>
            <template #left>
                <img v-if='isBondMobile' class='header__logo' src='@/assets/images/logo-full.png' alt='Logo' />
                <div v-if='!isBondMobile' class='d-flex flex-row gap-3'>
                    <ui-button
                        background='primary'
                        :modal="{name: 'VacationModal', props: {userId}, on: {changed: () => $root.$emit('EmployeeCalendarRefresh')}}"
                    >
                        {{ $t('Atostogų prašymas') }}
                    </ui-button>
                    <ui-button
                        v-if='isEnabledRemoteWorkRequestEmployee'
                        background='primary'
                        :modal="{name: 'RemoteWorkModal', props: {userId}, on: {changed: () => $root.$emit('EmployeeCalendarRefresh')}}"
                    >
                        {{ $t('Remote work application') }}
                    </ui-button>
                </div>
            </template>
            <template #right>
                <HeaderLang />
                <HeaderProfile />
                <button v-if='isBondMobile' @click='showMobileMenu = !showMobileMenu' role='button'
                        class='mobile-nav-toggle open'>
                    <svg width='21' height='17' viewBox='0 0 21 17' fill='#1C2538' xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M0.698775 0.0775325C-0.167245 0.377151 -0.245228 1.57562 0.575644 1.98606L0.789071 2.09277H10.5H20.2109L20.4243 1.98606C21.0892 1.65361 21.2001 0.779378 20.6419 0.274542C20.3135 -0.0209723 21.118 -0.000450373 10.4836 -0.000450373C1.31032 0.003654 0.908098 0.003654 0.698775 0.0775325Z'
                            fill='1C2538' />
                        <path
                            d='M0.76036 7.40465C0.514099 7.47032 0.296568 7.64681 0.136498 7.90128C0.0297841 8.07777 0.0133667 8.13523 0.0133667 8.41022C0.0133667 8.677 0.0297841 8.75088 0.124184 8.91095C0.25142 9.12848 0.436116 9.29676 0.649543 9.39527C0.780882 9.45683 1.69205 9.46094 10.5 9.46094C19.308 9.46094 20.2191 9.45683 20.3505 9.39527C20.5639 9.29676 20.7486 9.12848 20.8758 8.91095C20.9702 8.75088 20.9866 8.677 20.9866 8.41022C20.9866 8.13523 20.9702 8.07777 20.8635 7.90128C20.6993 7.6386 20.449 7.4498 20.1863 7.39234C19.858 7.32257 1.02304 7.33488 0.76036 7.40465Z'
                            fill='1C2538' />
                        <path
                            d='M0.575657 14.8413C-0.0892492 15.1738 -0.200067 16.048 0.358126 16.5528C0.686475 16.8483 -0.113875 16.8278 10.5 16.8278C21.1139 16.8278 20.3135 16.8483 20.6419 16.5528C21.2042 16.0439 21.081 15.145 20.4161 14.8331L20.2068 14.7346H10.4959H0.789084L0.575657 14.8413Z'
                            fill='1C2538' />
                    </svg>
                </button>
                <HeaderMobile
                    v-if='isBondMobile && showMobileMenu'
                    @close='showMobileMenu = false'
                />
                <div v-if='showMobileMenu' class='mobile-nav__bg'></div>
            </template>
        </Header>
        <div v-if='showMobileMenu' class='mobile-nav__bg'></div>
        <v-main>
            <slot />
            <router-view v-if='$slots.default == null' />
            <div v-if='isBondMobile' class='ui-button__fixed'>
                <ui-button
                    background='primary'
                    :modal="{name: 'VacationModal', props: {userId}, on: {changed: () => $root.$emit('EmployeeCalendarRefresh')}}"
                    class='full-width'
                >
                    {{ $t('Atostogų prašymas') }}
                </ui-button>
            </div>
        </v-main>
    </v-app>
</template>

<script>
import Sidebar from '@/components/ui/sidebar/Sidebar.vue'
import Header from '@/components/ui/header/Header.vue'
import VacationCreateForm from '@/components/forms/app/vacation/VacationCreateForm'
import Dialog from '@/components/ui/dialog/Dialog'
import HeaderProfile from '@/components/ui/header/HeaderProfile'
import HeaderIcon from '@/components/ui/header/HeaderIcon'
import HeaderMobile from '@/components/ui/header/HeaderMobile'
import Button from '@/components/ui/button/Button'
import ButtonGroup from '@/components/ui/button/ButtonGroup'
import HraButtonModal from '@/components/ui/button/HraButtonModal'
import Main from '@/components/Main'
import UiButton from '@/domain/components/UiButton.vue'
import HeaderLang from '@/components/ui/header/HeaderLang.vue'
import { mapGetters } from 'vuex'
import isBondMobileMixin from '@/mixins/isBondMobileMixin'

export default {
    name: 'AppLayout',
    mixins: [isBondMobileMixin],
    components: {
        HeaderLang,
        UiButton,
        Main,
        HraButtonModal,
        Sidebar,
        Header,
        VacationCreateForm,
        Dialog,
        HeaderProfile,
        HeaderIcon,
        HeaderMobile,
        Button,
        ButtonGroup
    },
    data() {
        return {
            loaderName: 'AppLayout',
            dialog: false,
            locales: [
                { text: 'LT', locale: 'lt' },
                { text: 'ENG', locale: 'en' }
            ],
            showMobileMenu: false,
            isBondMobileMenuOpen: false
        }
    },
    watch: {
        isBondMobile: {
            handler: function(isBondMobile) {
                if (!isBondMobile) {
                    this.showMobileMenu = false
                }
            },
            immediate: true
        }
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside)
        this.$router.afterEach(() => {
            this.showMobileMenu = false
        })
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside)
    },
    methods: {
        handleClickOutside(event) {
            const mobileMenuElement = this.$el.querySelector('.mobile-nav')
            const mobileMenuToggleElement = this.$el.querySelector('.mobile-nav-toggle')

            if (
                this.showMobileMenu &&
                mobileMenuElement &&
                !mobileMenuElement.contains(event.target) &&
                mobileMenuToggleElement &&
                !mobileMenuToggleElement.contains(event.target)
            ) {
                this.showMobileMenu = false
            }
        }
    },
    computed: {
        ...mapGetters({
            userId: 'currentUserStore/userId'
        }),
        isEnabledRemoteWorkRequestEmployee() {
            return this.$store.getters['currentUserStore/settings']?.remote_work
                ?.settingRemoteWorkEmployeeCanSendRequest?.config.enabled
        }
    }
}
</script>
