export default axios => ({
    init: () => axios.get(`/api/v1/employees/import/init`),
    convert: (payload) => axios.post(`/api/imports/company-data`, payload, {
        "Content-Type": "multipart/form-data",
    }),
    validate: (payload, requestName) => axios.post(`/api/v1/employees/import/validate`, payload, {requestName}),
    import: (payload, requestName) => axios.post(`/api/v1/employees/import`, payload, {requestName}),
    import2: (payload, requestName) => axios.post(`/api/v2/employees/many`, payload, {requestName}),
    createEmptyRow: () => axios.post(`/api/v1/employees/import/empty-row`),
});
