<template>
    <route-tabs
        ref='Tabs'
        class='mt-8'
        :tabs='tabs'
        default-tab-name='DocumentsFolders'
        @changeTab='handleChangeTab'
    >
        <template #additional>
            <ui-button
                background='primary'
                only-text
                prepend-icon='upload'
                @click='handleOpenUploadModal'
                class='px-0'
            >
                {{ $t('Įkelti dokumentus') }}
            </ui-button>
            <div class='documents-management-sidebar' />
        </template>

        <items-resizable-content>
            <template #content>
                <router-view />
            </template>

            <template #sidebar>
                <component v-if='!!sidebar' :is='sidebar.component' v-bind='sidebar.props' v-on='sidebar.on' />
            </template>
        </items-resizable-content>
<!--        <div class='d-flex flex-row'>-->
<!--            <div class='documents-management-container px-8 pt-8'>-->
<!--                <router-view />-->
<!--            </div>-->
<!--            <document-sidebar class='documents-management-sidebar'>-->
<!--                <component v-if='!!sidebar' :is='sidebar.component' v-bind='sidebar.props' v-on='sidebar.on' />-->
<!--            </document-sidebar>-->
<!--        </div>-->
    </route-tabs>
</template>

<script>
import DocumentSectionTitle from '@/domain/documents/components/DocumentSectionTitle.vue'
import RouteTabs from '@/domain/documents/components/RouteTabs.vue'
import UiButton from '@/domain/components/UiButton'
import modalMethodsMixin from '@/domain/mixins/modalMethodsMixin'
import Page from '@/domain/components/Page.vue'
import DocumentSidebar from '@/domain/documents/components/DocumentSidebar.vue'
import ItemsResizableContent from '@/domain/documents/items/components/ItemsResizableContent.vue'

export default {
    name: 'DocumentsItemsLayout',
    components: { ItemsResizableContent, DocumentSidebar, Page, DocumentSectionTitle, RouteTabs, UiButton },
    mixins: [modalMethodsMixin],
    data() {
        return {
            tabs: [
                { name: 'DocumentsFolders', prependIcon: 'folder', text: this.$t('Aplankai') },
                { name: 'DocumentsArchive', prependIcon: 'bin', text: this.$t('Ištrintų dokumentų archyvas') }
            ],
            sidebar: null
        }
    },
    created() {
        this.$root.$on('documents-manage-items-open-sidebar', this.handleOpenSidebar)
        this.$root.$on('documents-manage-items-close-sidebar', this.handleCloseSidebar)
    },
    beforeDestroy() {
        this.$root.$off('documents-manage-items-open-sidebar', this.handleOpenSidebar)
        this.$root.$off('documents-manage-items-close-sidebar', this.handleCloseSidebar)
    },
    mounted() {
        this.$refs.Tabs.$refs.TabsContainer.classList.add('documents-management-container')
    },
    methods: {
        handleOpenSidebar(sidebar) {
            this.sidebar = sidebar
        },
        handleCloseSidebar() {
            this.sidebar = null
        },
        getFolderId() {
            const routeFolderId = parseInt(this.$route.params?.folderId ?? null)
            return isNaN(routeFolderId) ? null : routeFolderId
        },
        handleOpenUploadModal() {
            this.onOpenModal('DocumentUploadModal', {
                folderId: this.getFolderId()
            })
        },
        handleChangeTab() {
            this.sidebar = null
        }
    }
}
</script>

<style scoped>
ul.documents-breadcrumbs {
    padding: 0 0 30px 0;
}

::v-deep .documents-breadcrumbs a.v-breadcrumbs__item {
    color: #70778C !important;
}

::v-deep .documents-breadcrumbs a.v-breadcrumbs__item--disabled {
    color: #454C9C !important;
}
</style>