<template>
	<Auth>
		<template #header>{{ $t("Prisijungti") }}</template>
		<template #form>
			<validation-form :tag="tag" @submit="onSubmit">
				<v-row>
					<v-col cols="12">
						<text-field
							rules="required|email"
							v-model="email"
							name="email"
							:label="$t('El. pašto adresas')"
						/>
					</v-col>
					<v-col cols="12">
						<text-field
							:rules="'required|min:4,' + $t('simbolius')"
							v-model="password"
							name="password"
							type="password"
							:label="$t('Slaptažodis')"
						>
							<template #label-append>
								<v-spacer/>
								<router-link :to="{name: forgotPasswordName}">{{ $t("Pamiršau slaptažodį") }}</router-link>
							</template>
						</text-field>
					</v-col>
					<v-col cols="12">
						<message-alerts :listen="[tag]" />
					</v-col>
					<v-col cols="12">
						<hra-button
							:loading="buttonLoading"
							:color="uiColor.primary"
							:type="buttonType.submit"
							full-width
						>{{ $t("Prisijungti") }}
						</hra-button>
					</v-col>
				</v-row>
			</validation-form>
		</template>
	</Auth>
</template>

<script>
import authMixin from "@/domain/auth/mixins/authMixin";
import Auth from "@/domain/auth/components/Auth";
import TextField from "@/domain/fields/TextField";
import HraButton from "@/components/ui/button/HraButton";
import {forgotPassword as forgotPasswordName} from "@/domain/auth/routes"
import {ValidationObserver} from "vee-validate";
import MessageAlerts from "@/domain/messages/MessageAlerts";
import ValidationForm from "@/domain/components/ValidationForm";

export default {
	name: "LoginPage",
	components: {ValidationForm, MessageAlerts, ValidationObserver, HraButton, TextField, Auth},
	mixins: [authMixin],
	data() {
		return {
			tag: "AUTH_LOGIN_TAG",
			forgotPasswordName,
			email: '',
			password: '',
		};
	},
	methods: {
		async onSubmit() {
			await this.service.login(this.tag, {email: this.email, password: this.password});
		},
	}
}
</script>