import _ from "lodash";
import {UI_COLORS} from "@/utils/default";

export const BUTTON_TYPE = {
    button: "button",
    submit: "submit",
}
export const BUTTON_SIZE = {
    normal: "normal",
    small: "small",
    auto: "auto",
}
export const BUTTON_TEXT_WIDTH = {
    bold: "bold",
    normal: "normal",
}

const BUTTON_TYPES = _.values(BUTTON_TYPE);
const BUTTON_COLORS = _.values(UI_COLORS);
const BUTTON_SIZES = _.values(BUTTON_SIZE);
const BUTTON_TEXT_WIDTHS = _.values(BUTTON_TEXT_WIDTH);
const BUTTON_DEFAULT_PADDING = {
    [BUTTON_SIZE.normal]: "px-4",
    [BUTTON_SIZE.small]: "px-3",
}
const BUTTON_DEFAULT_GAP = {
    [BUTTON_SIZE.normal]: "gap-3",
    [BUTTON_SIZE.small]: "gap-2",
}

export default {
    props: {
        customClass: {type: [String, Array], default: null},
        type: {default: BUTTON_TYPES[0], validator: value => BUTTON_TYPES.includes(value)},
        color: {default: BUTTON_COLORS[0], validator: value => BUTTON_COLORS.includes(value)},
        size: {default: BUTTON_SIZES[0], validator: value => BUTTON_SIZES.includes(value)},
        textWidth: {default: BUTTON_TEXT_WIDTHS[0], validator: value => BUTTON_TEXT_WIDTHS.includes(value)},
        fullWidth: {type: Boolean, default: false},
        loading: {type: Boolean, default: false},
        noBackground: {type: Boolean, default: false},
        hoverBackground: {type: Boolean, default: false},
        border: {type: Boolean, default: false},
        onlyIcon: {type: Boolean, default: false},
        disabled: {type: Boolean, default: false},
        buttonPermissions: {type: Array, default: () => ([])},
    },
    computed: {
        buttonClass() {
            const baseClass = "hra-button"

            const baseClasses = [
                BUTTON_DEFAULT_PADDING[this.size],
            ];

            return [
                baseClass,
                `color-${this.color}`,
                `size-${this.size}`,
                `text-width-${this.textWidth}`,
                this.fullWidth ? "full-width" : null,
                this.noBackground ? "no-background" : null,
                this.hoverBackground ? "hover-background" : null,
                this.border ? "border" : null,
                BUTTON_DEFAULT_GAP[this.size],
                this.customClass ? this.customClass : baseClasses
            ].filter(_class => _class != null);
        },
        hasPermissions() {
            return this.hasPermissionTo(this.buttonPermissions);
        },
    }
}
