import ImportLayout from '@/layouts/ImportLayout';
import ImportEmployeesManage from "@/views/pages/import/ImportEmployeesManage";

export const importRouteProps = {
    employeeManage: {name: 'import-employees-manage', path: 'employees/manage'},
};

export default [
    {
        path: '/import',
        component: ImportLayout,
        children: [
            {...importRouteProps.employeeManage, component: ImportEmployeesManage},
        ],
    }
]
