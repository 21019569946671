<template>
    <documents-finder
        :store-namespace='storeNamespace'
        :default-breadcrumbs='defaultBreadcrumbs'
        :default-title='$t("Aplankai")'
        header-sticky
        with-sub-header
        with-arrows
        with-modes
        with-search
    >
        <template #header-buttons>
            <ui-button
                background='violet-dark'
                @click="handleOpenCreateFolderModal"
                prepend-icon='plus'
            >
                {{ $t('Naujas aplankas') }}
            </ui-button>
        </template>
    </documents-finder>
</template>

<script>
import store from '@/domain/documents/finder/store/documentsFinderStore'
import UiButton from '@/domain/components/UiButton.vue'
import DocumentsFinder from '@/domain/documents/finder'
import modalMethodsMixin from '@/domain/mixins/modalMethodsMixin'

const storeNamespace = 'FolderSelectFinderStore'
export default {
    name: 'FolderSelectFinder',
    components: { DocumentsFinder, UiButton },
    mixins: [modalMethodsMixin],
    props: {
        folderId: { type: Number, default: null }
    },
    data() {
        return {
            storeNamespace,
            defaultBreadcrumbs: [
                { id: null, title: this.$t('Įmonės dokumentai') },
                { id: null, title: this.$t('Aplankai') }
            ]
        }
    },
    beforeCreate() {
        this.$store.registerModuleSafely(storeNamespace, store)
    },
    beforeDestroy() {
        this.$store.unregisterModuleSafely(storeNamespace)
    },
    mounted() {
        this.$store.dispatch(`${storeNamespace}/folders/changeId`, this.folderId)
    },
    methods: {
        getFolderId() {
            return this.$store.getters[`${this.storeNamespace}/folderId`]
        },
        getBreadcrumbs() {
            return this.$store.getters[`${this.storeNamespace}/breadcrumbs`]
        },
        handleOpenCreateFolderModal() {
            this.onOpenModal('FolderCreateModal', {
                parentId: this.$store.getters[`${storeNamespace}/folderId`],
            }, {
                folderCreated: (payload) => {
                    this.$store.dispatch(`documentsItemsRootStore/folders/create`, payload)
                    this.$store.dispatch(`${storeNamespace}/folders/create`, payload)
                }
            })
        }
    }
}
</script>