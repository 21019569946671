<template>
    <expansion-panel :header="$t('Darbo sutarties informacija')">
        <v-row>
            <v-col cols='6'>
                <text-field
                    name='timeCardNumber'
                    :label="$t('Tabelio nr.')"
                    :editable='isEdit'
                    :disabled='isProfile'
                    rules='required'
                    @save='onUpdateUser'
                    v-model='user.timeCardNumber'
                />
            </v-col>
            <v-col cols='6'>
                <text-field
                    name='number'
                    :label="$t('Darbo sutarties nr.')"
                    :editable='isEdit'
                    :disabled='isProfile'
                    @save='onUpdateContactOptional'
                    v-model='contract.number'
                />
            </v-col>
            <v-col cols='6'>
                <datepicker-field
                    name='date'
                    :label="$t('Darbo sutarties data')"
                    :editable='isEdit'
                    :disabled='isProfile'
                    @save='onUpdateContactOptional'
                    v-model='contract.date'
                />
            </v-col>
            <v-col cols='6'>
                <datepicker-field
                    name='workStartAt'
                    :label="$t('Darbo pradžios data')"
                    :editable='isEdit'
                    :disabled='isProfile'
                    rules='required'
                    @save='onUpdateContactCustom'
                    v-model='contract.workStartAt'
                />
            </v-col>
            <v-col cols='6'>
                <text-field
                    name='workDaysPerWeek'
                    :label="$t('Darbo dienų skaičius per savaitę')"
                    type='number'
                    :editable='isEdit'
                    :disabled='isProfile'
                    rules='required'
                    @save='onUpdateContactCustom'
                    v-model='contract.workDaysPerWeek'
                />
            </v-col>
            <v-col cols='6'>
                <text-field
                    name='workHoursPerWeek'
                    :label="$t('Darbo laiko norma (val. per savaitę)')"
                    type='number'
                    :editable='isEdit'
                    :disabled='isProfile'
                    rules='required'
                    @save='onUpdateContactCustom'
                    v-model='contract.workHoursPerWeek'
                />
            </v-col>
            <v-col cols='6'>
                <text-field
                    name='vacationDaysPerYear'
                    :label="$t('Atostogų skaičius per metus (d.d.)')"
                    type='number'
                    :editable='isEdit'
                    :disabled='isProfile'
                    rules='required'
                    @save='onUpdateContactCustom'
                    v-model='contract.vacationDaysPerYear'
                />
            </v-col>
        </v-row>
    </expansion-panel>
</template>

<script>
import ExpansionPanel from '@/domain/components/ExpansionPanel'
import TextField from '@/domain/fields/TextField'
import DatepickerField from '@/domain/fields/DatepickerField'
import { mapGetters } from 'vuex'
import expansionEmployeeMixin from '@/domain/employee/mixins/expansionEmployeeMixin'

export default {
    name: 'ExpansionEmployeeContract',
    components: { DatepickerField, TextField, ExpansionPanel },
    mixins: [expansionEmployeeMixin],
    computed: {
        ...mapGetters({
            user: 'employeeStore/user',
            contract: 'employeeStore/contract'
        })
    },
    methods: {
        async onUpdateUser({ fieldTag, name, value, saveComplete }) {
            if (await this.$store.dispatch('employeeStore/updateUser', {
                tag: fieldTag,
                id: this.user.id,
                payload: { [name]: value }
            })) {
                saveComplete()
            }
        },
        async onUpdateContactOptional({ fieldTag, name, value, saveComplete }) {
            if (await this.$store.dispatch('employeeStore/onUpdateContactOptional', {
                tag: fieldTag,
                id: this.contract.id,
                payload: { [name]: value }
            })) {
                saveComplete()
            }
        },
        async onUpdateContactCustom({ fieldTag, name, value, saveComplete }) {
            if (await this.$store.dispatch('employeeStore/onUpdateContactWithSyncAdditionalData', {
                tag: fieldTag,
                id: this.contract.id,
                param: name,
                payload: { [name]: value }
            })) {
                saveComplete()
            }
        }
    }
}
</script>