<template>
	<div class="message-toasts">
		<message-toast
			v-for="({id, type, message, shown}) in listenMessages"
			:shown="shown"
			:key="id"
			:id="id"
			:type="type"
			:message="message"
		/>
	</div>
</template>

<script>
import MessageToast from "@/domain/messages/components/MessageToast";
import messagesMixin from "@/domain/messages/mixins/messagesMixin";

export default {
	name: "MessageToasts",
	components: {MessageToast},
	mixins: [messagesMixin],
}
</script>

<style lang="scss">
.message-toasts {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 3px;
	position: fixed;
	bottom: 88px;
	z-index: 99999;
}
</style>