import store from "@/store";
import {VACATION_STATUS_ABORT, VACATION_STATUS_APPROVE} from "@/utils/default";
import i18n from "@/plugins/i18n";
import customApi from "@/plugins/customApi";
import moment from "moment";

export class VacationModalService {

  checkTimeout = null;
  checkWatcher = null;

  async baseInit(tag, checkTag, events, currentId, employeeId = null, initPayload = null) {
    if (events == null) {
      return;
    }

    const event = events.vacations.find(event => event.id === currentId);
    await store.dispatch("vacationModalStore/init", events);
    await store.dispatch("vacationModalStore/initNewEvent", initPayload);

    if (event) {
      await store.dispatch("vacationModalStore/initEvent", event);

      event.statusCode === VACATION_STATUS_APPROVE && await store.dispatch(
        "messagesStore/showSuccessMessage",
        {tag, message: i18n.t("Atostogos yra patvirtintos")}
      );

      event.statusCode > VACATION_STATUS_ABORT && await store.dispatch(
        "messagesStore/showErrorMessage",
        {tag, message: i18n.t("Atostogos yra atmestos")}
      );
    }

    await this.check(checkTag, employeeId);

    this.checkWatcher = store.watch((state) => {
      const {typeId, fromDate, toDate, deputyId} = state.vacationModalStore.formData;
      return {typeId, fromDate, toDate, deputyId};
    }, ({fromDate}) => {
      const calendarDate = store.getters["vacationModalStore/calendarDate"];
      const date = moment(calendarDate).add(1, 'month').endOf('month').format('YYYY-MM-DD');
      date < fromDate && store.dispatch("vacationModalStore/changeCalendarDate", fromDate);
      // store.dispatch("vacationModalStore/changeCalendarDate", fromDate);
      clearTimeout(this.checkTimeout);
      this.checkTimeout = setTimeout(async () => {
        await this.check(checkTag, employeeId);
      }, 300);
    });

    await store.dispatch("loadersStore/hideLoader", tag);
  }

  async check(tag, employeeId) {
    if (!store.getters["vacationModalStore/canCheck"]) {
      return;
    }
    const id = store.getters["vacationModalStore/currentId"];
    const formData = store.getters["vacationModalStore/formData"];

    const payload = {
      typeId: formData.typeId,
      fromDate: formData.fromDate,
      toDate: formData.toDate,
      payBeforeStart: formData.payBeforeStart,
      deputyId: formData.deputyId,
    }

    const api = customApi(tag);

    employeeId = employeeId == null ? store.getters["authStore/employeeId"] : employeeId;

    const message = await api.request(api.factories.employee.vacation.check(employeeId, id, payload));
    if (message) {
      await store.dispatch("messagesStore/showInfoMessage", {tag, message});
      await store.dispatch("loadersStore/hideLoader", tag);
    }
  }

  fields(typeId) {
    return {
      fromDate: true,
      toDate: true,
      payType: [1, 2, 4, 5, 11].includes(typeId),
      deputy: [1, 2, 4, 5, 6, 7, 8, 9, 10, 11].includes(typeId),
      attachment: [6, 8].includes(typeId),
      message: true,
    };
  }

  formatFormData(payload) {
    const formData = new FormData();

    Object.keys(payload).forEach(key => {
      let value = payload[key];

      if (key === 'attachment') {
        if (value != null) {
          if (typeof value === "object") {
            formData.append(key, value, value.name);
          }

          formData.append(key, value);
          return;
        }
        return;
      }

      if (value != null && Array.isArray(value)) {
        value.forEach(item => {
          formData.append(`${key}[]`, item);
        });
        return;
      }

      if (value != null) {
        formData.append(key, value);
      }
    });

    return formData;
  }

  async clearData(tags) {
    this.checkWatcher && this.checkWatcher();
    await store.dispatch("vacationModalStore/clear");
    await store.dispatch("messagesStore/clearMessages", tags);
  }

  async changeCalendarDate(date) {
    await store.dispatch("vacationModalStore/changeCalendarDate", date);
  }

  async subMonthCalendarDate() {
    await store.dispatch("vacationModalStore/subMonthCalendarDate");
  }

  async addMonthCalendarDate() {
    await store.dispatch("vacationModalStore/addMonthCalendarDate");
  }

}