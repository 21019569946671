<template>
    <div/>
<!--    <div class='context-menu' ref='contextMenu'>-->
<!--        <slot />-->
<!--    </div>-->
</template>

<script>
import { generateUuid } from '@/utils/default'

export default {
    name: 'ContextMenu',
    data() {
        return {
            uuid: generateUuid()
        }
    },
    computed: {
        root() {
            return this.$parent.$el
        }
    },
    methods: {
        normalizePosition(mouseX, mouseY) {
            // ? compute what is the mouse position relative to the container element (scope)
            let {
                left: scopeOffsetX,
                top: scopeOffsetY
            } = this.root.getBoundingClientRect()

            scopeOffsetX = scopeOffsetX < 0 ? 0 : scopeOffsetX
            scopeOffsetY = scopeOffsetY < 0 ? 0 : scopeOffsetY

            const scopeX = mouseX - scopeOffsetX
            const scopeY = mouseY - scopeOffsetY

            const outOfBoundsOnX =
                scopeX + this.$refs.contextMenu.clientWidth > this.root.clientWidth

            const outOfBoundsOnY =
                scopeY + this.$refs.contextMenu.clientHeight > this.root.clientHeight

            let normalizedX = mouseX
            let normalizedY = mouseY

            // ? normalize on X
            if (outOfBoundsOnX) {
                normalizedX =
                    scopeOffsetX + this.root.clientWidth - this.$refs.contextMenu.clientWidth
            }

            // ? normalize on Y
            if (outOfBoundsOnY) {
                normalizedY =
                    scopeOffsetY + this.root.clientHeight - this.$refs.contextMenu.clientHeight
            }

            return { normalizedX, normalizedY }
        },
        openContextMenu() {
            const event = new CustomEvent('open-new-contextmenu', { uuid: this.uuid })
            document.dispatchEvent(event)

            this.$refs.contextMenu?.classList?.add('visible')
        },
        closeContextMenu() {
            this.$refs.contextMenu?.classList?.remove('visible')
        },
        onContextMenu(event) {
            event.preventDefault()

            console.debug('to open context menu')
            this.$store.dispatch('global/setContextMenu', {
                event,
                el: this
            })

            // const { clientX, clientY } = event
            // const { x, y } = this.root.getBoundingClientRect()
            //
            // this.closeContextMenu()
            //
            // this.$refs.contextMenu.style.top = `${clientY - y}px`
            // this.$refs.contextMenu.style.left = `${clientX - x}px`
            //
            // setTimeout(() => {
            //     this.openContextMenu()
            // })
        },
        onOpenNewContextMenu(uuid) {
            if (this.uuid === uuid) {
                return
            }

            this.closeContextMenu()
        }
    },
    provide() {
        return {
            closeContextMenu: this.closeContextMenu
        }
    },
    mounted() {
        this.root?.addEventListener('contextmenu', this.onContextMenu)

        // document.addEventListener('click', (event) => {
        //     if (event.target.offsetParent !== this.$refs?.contextMenu ?? null) {
        //         this.closeContextMenu()
        //     }
        // })

        document.addEventListener('open-new-contextmenu', this.onOpenNewContextMenu)
    },
    beforeDestroy() {
        this.root?.removeEventListener('contextmenu', this.onContextMenu)
    }
}
</script>