<template>
    <fragment>
        <event-invites
            :title='$t("Nauji prašymai, laukiantys patvirtinimo")'
            :count='invites.length'
            :loading='loading'
        >
            <component
                v-for='(invite, index) in invitesPaginate'
                :key='index'
                :is='invite.componentName'
                :invite='invite'
                :expanded='expandInvites.includes(invite.id)'
                @expand='toggleExpand'
                @deleted='handleRowDeleted'
            />
        </event-invites>
        <hra-pagination
            class='mt-3'
            :last-page='lastPage'
            :current-page='currentPage'
            @changePage='handleChangePage'
        />
    </fragment>
</template>

<script>
import CustomApi from '@/plugins/customApi'
import LeaderHolidayEventInvite from '@/domain/eventInvite/components/leader/LeaderHolidayEventInvite.vue'
import EventInvites from '@/domain/eventInvite/components/EventInvites.vue'
import LeaderRemoteWorkInvite from '@/domain/eventInvite/components/leader/LeaderRemoteWorkInvite.vue'
import LeaderBusinessTripEventInvite from '@/domain/eventInvite/components/leader/LeaderBusinessTripEventInvite.vue'
import HraPagination from '@/components/ui/pagination/HraPagination.vue'

const customApi = CustomApi()

const inviteComponentNames = {
    holiday: 'LeaderHolidayEventInvite',
    businessTrip: 'LeaderBusinessTripEventInvite',
    remoteWork: 'LeaderRemoteWorkInvite'
}

function getComponentName(invite) {
    if (invite.type === 'remote-work') {
        return inviteComponentNames.remoteWork
    }

    if (invite.type === 'event') {
        return {
            1001: inviteComponentNames.holiday,
            1002: inviteComponentNames.holiday,
            1003: inviteComponentNames.holiday,
            1004: inviteComponentNames.holiday,
            1005: inviteComponentNames.holiday,
            1006: inviteComponentNames.holiday,
            1007: inviteComponentNames.holiday,
            1008: inviteComponentNames.holiday,
            1009: inviteComponentNames.holiday,
            1010: inviteComponentNames.holiday,
            1011: inviteComponentNames.holiday,
            1012: inviteComponentNames.holiday,
            5002: inviteComponentNames.businessTrip
        }[invite.approvalable.type] ?? null
    }

    return null
}

export default {
    name: 'LeaderEventInvites',
    components: {
        HraPagination,
        EventInvites,
        LeaderHolidayEventInvite,
        LeaderRemoteWorkInvite,
        LeaderBusinessTripEventInvite
    },
    data() {
        return {
            loading: false,
            page: 1,
            limit: 5,
            invites: [],
            expandInvites: [],
        }
    },
    computed: {
        lastPage() {
            return Math.ceil(this.invites.length / this.limit)
        },
        currentPage() {
            return this.page > this.lastPage ? this.lastPage : this.page
        },
        total() {
            return this.data.length
        },
        invitesPaginate() {
            const start = (this.currentPage - 1) * this.limit
            const end = start + this.limit

            return this.invites.slice(start, end)
        }
    },
    mounted() {
        this.fetchInvites()
    },
    methods: {
        handleChangePage(page) {
            this.page = page
        },
        handleRowDeleted(inviteId) {
            this.invites = this.invites.filter(({ id }) => id !== inviteId)
        },
        toggleExpand(key) {
            const index = this.expandInvites.indexOf(key)
            if (index === -1) {
                this.expandInvites.push(key)
                return
            }

            this.expandInvites.splice(index, 1)
        },
        async fetchInvites() {
            try {
                this.loading = true
                this.page = 1
                const { data: { invites } } = await customApi.factories.department.events.fetchLeader()
                this.invites = invites.map(invite => {
                    const componentName = getComponentName(invite)
                    return { ...invite, componentName }
                }).filter(({ componentName }) => componentName !== null)
            } catch (e) {
                console.error(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>