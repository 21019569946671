import Vue from 'vue'
import { mapDocument } from '@/domain/documents/finder/store/mappers'

export default ({
    ['documents/toggleSelect']({ state, getters }, payload) {
        const item = state.items.find(({ id }) => payload === id)

        if (item == null) {
            return
        }

        Vue.set(item, 'selected', !item.selected)
    },
    ['documents/toggleSelectAll']({ state, getters }) {
        const allSelected = getters.isSelectedAllDocuments

        state.items.forEach((item, key) => {
            if (item.item.isFolder) {
                return
            }
            Vue.set(state.items[key], 'selected', !allSelected)
        })
    },
    ['documents/unselectAll']({ state }) {
        state.items.forEach((item, key) => {
            if (item.item.isFolder) {
                return
            }
            Vue.set(state.items[key], 'selected', false)
        })
    },
    ['documents/createMany']({ state }, { folderId, items }) {
        if (state.folderId !== folderId) {
            return
        }

        state.items.push(...items.map(item => mapDocument(item)))
    },
    ['documents/update']({ state }, payload) {
        const index = state.items.findIndex(({ id }) => id === payload.id)
        if (index === -1) {
            return
        }
        Vue.set(state.items, index, mapDocument(payload))
    },
    ['documents/updateMany']({ dispatch }, payload) {
        for (const item of payload) {
            dispatch('documents/update', item)
        }
    },
    ['documents/delete']({ state }, payload) {
        const index = state.items.findIndex(({ id }) => id === payload)
        if (index === -1) {
            return
        }
        state.items.splice(index, 1)
    },
    ['documents/deleteMany']({ state }, { folderId, ids }) {
        if (state.folderId !== folderId) {
            return
        }

        state.items = state.items.filter(({ id }) => !ids.includes(id))
    }
})