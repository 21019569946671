<template>
    <v-menu
        bottom
        right
    >
        <template v-slot:activator='{ on, attrs }'>
            <ui-button
                class='px-0 mt-1'
                background='primary'
                only-text
                v-bind='attrs'
                v-on='on'
            >
                <ui-icon>options</ui-icon>
            </ui-button>
        </template>

        <v-list class='pt-0 pb-0 document-context-menu'>
            <slot />
        </v-list>
    </v-menu>
</template>

<script>
import UiButton from '@/domain/components/UiButton.vue'
import UiIcon from '@/domain/components/UiIcon.vue'

export default {
    name: 'DocumentsFinderContextMenu',
    components: { UiIcon, UiButton }
}
</script>

<style lang='scss'>

.document-context-menu {
    .v-list-item {
        cursor: pointer;
        border-bottom: 1px solid #CCD3DF;
        padding: 7px 10px;

        &:last-child {
            border-bottom: 0;
        }

        &__icon {
            color: #70778C;
        }

        &__title {
            color: #1A1A1A;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }

        &.danger {
            color: #E7384F !important;
        }
    }
}
</style>