<template>
	<hra-table
		class="pa-3"
		:headers="headers"
		:rows="rows"
	>

	</hra-table>
</template>

<script>
import HraTable from "@/components/ui/table/HraTable.vue";

export default {
	name: "VacationsTable",
	components: {HraTable},
	props: {
		id: {type: String, required: true},
	},
	data() {
		return {
			headers: [
				{name: "fromDate", text: this.$t("Pirma diena *")},
				{name: "toDate", text: this.$t("Paskutinė diena *")},
				{name: "type", text: this.$t("Rūšis *")},
				{name: "pay", text: this.$t("Apmokėjimas *")},
				{name: "message", text: this.$t("Komentaras")},
			],
		}
	},
	computed: {
		rows() {
			return this.$store.getters["setupStore/importEmployeeData"](this.id, "vacations");
		},
	},
}
</script>