class DeclensionService {

  endingsVocative = {
    'as': 'ai',
    'ė': 'e',
    'is': 'i',
    'ys': 'y',
    'us': 'au'
  };

  changeWord(word) {
    for (const needle in this.endingsVocative) {
      if (word.endsWith(needle)) {
          return word.slice(0, word.lastIndexOf(needle)) + this.endingsVocative[needle];
        }
    }

    return word;
  }

  make(name) {
    [' ', ',', '-', '_'].forEach(delim => {
      if (name.indexOf(delim) > -1) {
        name = name.split(delim).map(w => this.changeWord(w)).join(delim);
      }
    });

    return this.changeWord(name);
  }

}

export default new DeclensionService