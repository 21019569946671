<template>
    <div>
        <slot name='title' />

        <div class='approval-stages'>
            <ul class='progress'>
                <li class='progress__item'
                    v-for='(order, index) in approvals.orders'
                    :key='order.id'
                    :class='statusClass(order.status)'
                >
                    <div class='progress__circle'>
                        <ui-icon>{{ statusIcon[order.status] }}</ui-icon>
                    </div>
                    <ul class='pa-0'>
                        <div class='progress__title'>
                            <b>{{ index + 1 }} tvirtinimo lygis</b>
                            <hra-tooltip
                                :text="$t('Kažkoks tekstas')"
                            >
                                <ui-icon>message-info</ui-icon>
                            </hra-tooltip>
                        </div>
                        <div class='progress__info'>Min. patvirtinimų sk. <b>{{ order.minToApprove }}</b></div>
                        <div class='d-flex flex-column gap-3'>
                            <div class='progress__box'
                                v-for='invite in order.invites'
                                :key='invite.id'
                                :class='statusClass(invite.status)'
                            >
                                <div class='progress__line'></div>
                                <div v-if='showProgress' class='progress__box-circle'>
                                    <ui-icon>{{ statusIcon[invite.status] }}</ui-icon>
                                </div>
                                <employee-table-view
                                    :employee='invite.user'
                                />
                            </div>
                        </div>
                    </ul>
                </li>
            </ul>
        </div>

    </div>
</template>

<script>
import EmployeeTableView from '@/components/ui/views/EmployeeTableView.vue'
import UiIcon from '@/domain/components/UiIcon.vue'
import HraTooltip from '@/components/ui/HraTooltip'

export default {
    name: 'VacationRequestApprovals',
    components: {
        EmployeeTableView, UiIcon, HraTooltip
    },
    props: {
        approvals: { type: Object, required: true },
        showProgress: { type: Boolean, default: false }
    },
    data() {
        return {
            statusIcon: {
                approved: 'ok',
                aborted: 'close',
                // TODO: skip icon
                // skip: 'skip'
            }
        }
    },
    methods: {
        statusClass(status) {
            if (!this.showProgress) return ''
            const statusClasses = {
                approved: 'approved',
                pending: 'pending',
                aborted: 'aborted',
                skip: 'skip'
            }

            return statusClasses[status] || ''
        }
    }
}
</script>

<style lang='scss' scoped>
@import '@/assets/sass/core/colors';

.approval-stages {
    .progress {
        margin: 30px 0 0;
        padding-left: 30px;
    }

    .progress__item {
        position: relative;
        min-height: 75px;
        padding-bottom: 30px;
        padding-left: 5px;

        .progress__circle {
            position: absolute;
            top: 0;
            left: -31px;
            z-index: 1;
            width: 21px;
            height: 21px;
            border-radius: 50%;
            background: $white;
            color: $white;
            font-size: 12px;
            border: 2px solid $border-color-primary;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &:before {
            content: "";
            position: absolute;
            left: -21px;
            top: 21px;
            height: calc(100% - 21px);
            border-left: 1px solid $border-color-primary;
        }

        &:last-child {
            padding-bottom: 0;

            .progress__box:last-child  {
                .progress__line {
                    border-bottom-left-radius: 10px;
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: 1px;
                    left: -27px;
                    width: 1px;
                    height: 100%;
                    border-left: 1px solid $white;
                }
            }
        }

        &.approved {
            .progress__circle {
                background-color: $violet-700;
                border-color: $violet-700;
            }
            &:before {
                border-color: $violet-700;
            }
            .progress__box {
                .progress__line {
                    border-color: $violet-700;
                }
            }
        }

        &.aborted {
            .progress__circle {
                background-color: #E7384F;
                border: 1px solid #E7384F;
            }
        }

        &.pending {
            .progress__circle {
                background-color: $white;
                border-color: $violet-700;
            }
            &:before {
                border-color: $violet-700;
            }
            .progress__box {
                .progress__line {
                    border-color: $violet-700;
                }
            }
        }
    }

    .progress__title {
        color: $greyscale-600;
        font-size: 14px;
        line-height: 1;
        letter-spacing: 0.03em;
        text-transform: uppercase;

        b {
            color: $violet-700;
            margin-right: 8px;
        }
    }

    .progress__info {
        color: $greyscale-600;
        font-size: 14px;
        letter-spacing: 0.03em;
        margin: 6px 0 16px 0;
    }
}

.progress__box {
    background-color: #FAF9FF;
    border: 1px solid $border-color-primary;
    border-radius: 5px;
    position: relative;
    padding: 7px 18px;
    display: flex;
    width: fit-content;

    .progress__line {
        position: absolute;
        top: 0;
        left: -27px;
        width: 27px;
        height: 50%;
        border-left: 1px solid $border-color-primary;
        border-bottom: 1px solid $border-color-primary;
    }

    .progress__box-circle {
        position: absolute;
        left: -10px;
        top: 50%;
        transform: translate(0, -50%);
        z-index: 1;
        width: 21px;
        height: 21px;
        border-radius: 50%;
        background: $white;
        color: $white;
        font-size: 12px;
        border: 1px solid $border-color-primary;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.approved {
        .progress__box-circle {
            background-color: $violet-200;
        }
    }

    &.aborted {
        .progress__box-circle {
            background-color: #E7384F;
            border: 1px solid #E7384F;
        }
    }

    &.pending {
        .progress__box-circle {
            border: 1px solid $violet-700;
        }
    }
}
</style>