<template>
    <custom-dialog :max-width='770'>
        <template #title>{{ $t('Automatiškai generuoti pertraukas') }}</template>
        <template #content>
            <v-row class='px-3'>
                <v-col cols='5'>
                    <datepicker-field v-model='formData.from' :label='$t("Nuo")' />
                </v-col>
                <v-col cols='7'>
                    <form-checkbox
                        :is-checked.sync='formData.removeAutoGenerates'
                        class="mt-9"
                    >
                        <div class='ml-3 hra-text--16'>
                            {{ $t('Overwrite manually generated breaks') }}
                        </div>
                    </form-checkbox>
                </v-col>
            </v-row>
        </template>

        <template #footer>
            <ui-button
                prepend-icon='close'
                background='default'
                @click='close'
            >{{ $t('Uždaryti') }}
            </ui-button>
            <ui-button
                prepend-icon='ok'
                background='primary'
                :disabled='disabledButton'
                @click='onClick'
            >{{ $t('Generuoti') }}
            </ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import TextField from '@/domain/fields/TextField.vue'
import UiButton from '@/domain/components/UiButton.vue'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import dialogMixin from '@/mixins/dialogMixin'
import DatepickerField from '@/domain/fields/DatepickerField.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import moment from 'moment'
import { GLOBAL_TOAST_TAG } from '@/utils/default'

export default {
    name: 'GenerateScheduleAutoBreaksModal',
    components: { DatepickerField, CustomDialog, UiButton, TextField, FormCheckbox },
    mixins: [dialogMixin],
    data() {
        return {
            disabledButton: false,
            formData: {
                from: moment().format('YYYY-MM-DD'),
                removeAutoGenerates: false
            }
        }
    },
    methods: {
        async onClick() {
            this.disabledButton = true
            const result = await this.$store.dispatch('schedulerStore/generateAutoBreaks', {
                from: this.formData.from,
                removeAutoGenerates: this.formData.removeAutoGenerates
            })
            this.disabledButton = false

            if (!result) {
                return
            }

            await this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag: GLOBAL_TOAST_TAG,
                message: 'Generate success'
            })
            this.close()
        }
    }
}
</script>