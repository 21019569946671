import modalMethodsMixin from '@/domain/mixins/modalMethodsMixin'
import i18n from '@/plugins/i18n'
import documentsItemsSidebars from '@/domain/documents/items/mixins/documentsItemsSidebars'

const statusTitles = {
    'new': i18n.t('Nepradėtas'),
    'waiting': i18n.t('Laukiama'),
    'rejected': i18n.t('Atmesta'),
    'accepted': i18n.t('Priimta'),
    'cancelled': i18n.t('Atšaukta')
}
export default {
    props: {
        item: { type: Object, required: true }
    },
    mixins: [modalMethodsMixin, documentsItemsSidebars],
    inject: ['withStatus', 'withControls', 'selectable', 'withDeleteInfo', 'storeNamespace'],
    computed: {
    },
    methods: {
        handleOpenGroupSidebar() {
            this.openDocumentGroupSidebar({item: this.item.item})
        },
        handleOpenUpdateGroupModal() {
            this.closeSidebar()
            this.onOpenModal(
                'GroupUpdateModal',
                { item: this.item.item },
                {
                    groupUpdated: (payload) => {
                        this.$store.dispatch(`${this.storeNamespace}/groups/update`, payload)
                    }
                }
            )
        },
        handleOpenDeleteGroupModal() {
            this.closeSidebar()
            this.onOpenModal(
                'GroupDeleteModal',
                { id: this.item.item.id },
                {
                    groupDeleted: (payload) => {
                        this.$store.dispatch(`${this.storeNamespace}/groups/delete`, payload)
                    }
                }
            )
        }
    }
}