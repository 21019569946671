import moment from 'moment'

export default () => ({
  selectedEventId: null,
  isLeader: false,
  isAdmin: false,
  canApproveUserIds: [],
  vacationsToApprove: [],
  departmentOptions: [],
  dutyOptions: [],
  detailVacations: {},
  employees: [],
  userBalances: {},
  events: [],
  holidays: [],
  filter: {
    fromDate: moment().format('YYYY-MM-DD'),
    calculateDate: `${(new Date()).getFullYear()}-12-31`,
    departments: [],
    duties: [],
    employeeIds: [],
  }
});