
const actions = {
    createFolder: async ({commit}, payload) => {

    },
    updateFolder: async ({commit}, payload) => {

    },
    deleteFolder: async ({commit}, payload) => {

    }
}

export default {
    namespaced: true,
    actions,
    modules: {
    }
}