<template>
    <v-dialog
        :value='true'
        persistent
        scrollable
        :max-width='width'
        transition='dialog-top-transition'
        content-class='custom-dialog'
        ref='dialogMain'
    >
        <template v-if='isConfirm'>
            <div class='dialog-confirm gap-5'>
                <div v-if='$slots.title' class='dialog-confirm__header px-5'>
                    <h6>
                        <slot name='title' />
                    </h6>
                </div>
                <div class='dialog-confirm__content gap-5'>
                    <slot name='content' />
                    <div class='d-flex gap-3' :class="maxWidth < 450 ? 'justify-center' : 'justify-end'">
                        <slot name='buttons' />
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div
                v-if='prepend'
                class='dialog dialog_prepend'
                :style='{maxWidth: `${prependWidth}px`, maxHeight: `${prependMaxHeight}px`}'
                :class="{'hide-footer': hidePrependFooter}"
                ref='prependDialog'
            >
                <div class='dialog__header px-5' ref='dialogHeader'>
                    <h6>
                        <slot name='prepend.title' />
                    </h6>
                    <v-spacer />
                    <i class='icon-arrow-right dialog__close pl-1' @click='closePrepend' />
                </div>
                <div class='dialog__content' ref='prependContent'>
                    <slot name='prepend.content' />
                    <div v-if="$slots['prepend.footer'] != null" class='dialog__footer'>
                        <slot name='prepend.footer' />
                    </div>
                </div>
            </div>
            <div class='dialog' :class="{'hide-footer': hideFooter}" ref='dialog'>
                <div class='dialog__header px-5' ref='dialogHeader'>
                    <h6>
                        <slot name='title' />
                    </h6>
                    <v-spacer />
                    <i class='icon-close dialog__close' @click='close' />
                </div>
                <v-progress-linear
                    :active='loading'
                    :indeterminate='loading'
                    color='primary'
                />
                <div class='dialog__content' ref='content'>
                    <slot name='content' />
                    <div v-if='$slots.footer' class='dialog__footer'>
                        <slot name='footer' />
                    </div>
                </div>
            </div>
        </template>
    </v-dialog>
</template>

<script>
export default {
    name: 'CustomDialog',
    props: {
        maxWidth: { type: [Number, String], default: 300 },
        prependWidth: { type: Number, default: 300 },
        isConfirm: { type: Boolean, default: false },
        hasCustomFooter: { type: Boolean, default: false },
        hasCustomPrependFooter: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
        draggable: { type: Boolean, default: false }
    },
    data() {
        return {
            headerTarget: null,
            prepend: false,
            prependMaxHeight: 0,
            draggableDialog: null
        }
    },
    computed: {
        width() {
            return this.maxWidth
                + (this.prepend ? this.prependWidth : 0)
        },
        hideFooter() {
            return this.$slots.footer == null && this.hasCustomFooter === false
        },
        hidePrependFooter() {
            return this.$slots['prepend.footer'] == null && this.hasCustomPrependFooter === false
        }
    },
    methods: {
        togglePrepend() {
            this.prepend = !this.prepend
        },
        openPrepend() {
            this.prepend = true
        },
        closePrepend() {
            this.prepend = false
            this.$emit('closePrepend')
        },
        close() {
            this.$parent?.close()
        },
        checkContent() {

            this.prependMaxHeight = this.$refs.dialog?.offsetHeight

            if (this.$refs.content?.offsetHeight < this.$refs.content?.scrollHeight) {
                this.$refs.prependDialog?.querySelector('.dialog__footer')?.classList?.add('has-scrollbar')
                this.$refs.dialog?.querySelector('.dialog__footer')?.classList?.add('has-scrollbar')
            } else {
                this.$refs.prependDialog?.querySelector('.dialog__footer')?.classList?.remove('has-scrollbar')
                this.$refs.dialog?.querySelector('.dialog__footer')?.classList?.remove('has-scrollbar')
            }
        },
        setDraggableDialog() {
            let data = null
            let baseData = null
            let dialogElement = null
            const overflow = 0.2
            this.$refs.dialogHeader.style.cursor = 'move'

            const handleMouseDown = (event) => {
                const element = dialogElement ?? event.target.closest('.custom-dialog')
                if (element != null && event.button === 0 && event.target.closest('.dialog__header')) {
                    data = {
                        mouseStartX: event.clientX,
                        mouseStartY: event.clientY,
                        elStartX: element.getBoundingClientRect().left,
                        elStartY: element.getBoundingClientRect().top,
                        oldTransition: element.style.transition
                    }

                    if (dialogElement == null) {
                        baseData = {
                            position: element.style.position,
                            left: element.style.left,
                            top: element.style.top,
                            margin: element.style.margin,
                            transition: element.style.transition
                        }

                        element.style.position = 'fixed'
                        element.style.left = data.elStartX + 'px'
                        element.style.top = data.elStartY + 'px'
                        element.style.margin = 0
                        element.style.transition = 'none'

                        dialogElement = element
                    }
                }
            }

            const handleMouseMove = (event) => {
                if (data == null) {
                    return
                }

                const overflowX = dialogElement.getBoundingClientRect().width * overflow * -1

                dialogElement.style.left = Math.min(
                    Math.max(data.elStartX + event.clientX - data.mouseStartX, overflowX),
                    window.innerWidth + overflowX
                ) + 'px'
                dialogElement.style.top = Math.min(
                    Math.max(data.elStartY + event.clientY - data.mouseStartY, 0),
                    window.innerHeight - (dialogElement.getBoundingClientRect().height * overflow)
                ) + 'px'
            }

            const handleMouseUp = () => {
                if (data == null) {
                    return
                }

                data = null
            }

            document.addEventListener('mousedown', handleMouseDown)
            document.addEventListener('mousemove', handleMouseMove)
            document.addEventListener('mouseup', handleMouseUp)

            return {
                resize() {
                    if (dialogElement == null) {
                        return
                    }

                    dialogElement.style.left = baseData.left
                    dialogElement.style.top = baseData.top
                    dialogElement.style.margin = baseData.margin
                    dialogElement.style.transition = baseData.transition
                    dialogElement.style.position = baseData.position

                    dialogElement = null
                    baseData = null
                    data = null
                },
                destroy() {
                    document.removeEventListener('mousedown', handleMouseDown)
                    document.removeEventListener('mousemove', handleMouseMove)
                    document.removeEventListener('mouseup', handleMouseUp)
                }
            }
        }

    },
    mounted() {
        if (this.$refs.content) {
            if (this.draggable) {
                this.$nextTick(() => {
                    this.draggableDialog = this.setDraggableDialog()
                })
            }

            new ResizeObserver(() => {
                this.checkContent()
                this.draggableDialog?.resize()
            }).observe(this.$refs.content)
        }
    },
    beforeDestroy() {
        this.draggableDialog?.destroy()
    }
}
</script>
