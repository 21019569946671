<template>
    <div class='page max-page-width'>
        <v-row class='mx-6'>
            <v-col cols='12'>
                <div class='d-flex align-end my-3 gap-3'>
                    <datepicker-field :label="$t('Nuo')" v-model='fromDate' type='month' />
                    <datepicker-field :min-date='fromDate' :label="$t('Iki')" v-model='toDate' type='month' />
                    <div style='width: 270px'>
                        <select-field :label="$t('Išnaudojo')" :options='statusOptions' v-model='status' />
                    </div>
                    <ui-button @click='fetchReport'>{{ $t('Rodyti ekrane') }}</ui-button>
                    <ui-button background='primary' @click='onGetParentDaysReport'>{{ $t('Atsiųsti .xlsx') }}
                    </ui-button>
                </div>
            </v-col>
            <v-col cols='12' class='pb-3'>
                <ui-table
                    :loading='loading'
                    :is-empty-table='rows.length === 0'
                >
                    <template #empty-table>
                        <message-alert
                            id='table-alert-empty'
                            :message='$t("Įrašų sistemoje nerasta.")'
                            type='info'
                        />
                    </template>
                    <template #head>
                        <tr>
                            <th>Vardas Pavardė</th>
                            <th>Tabelio nr.</th>
                            <th>Skyrius</th>
                            <th>Pareigos</th>
                            <th>Vaikai</th>
                            <th>Neįgalumai</th>
                            <th>Apskaita nuo</th>
                            <th>Apskaita iki</th>
                            <th>Priklauso (d. d.)</th>
                            <th>Rezevuota (d. d.)</th>
                            <th>Likutis (d. d.)</th>
                        </tr>
                    </template>
                    <template #body>
                        <tr v-for='(row, key) in rows' :key='key'>
                            <td>{{ row.name }}</td>
                            <td>{{ row.timeCardNumber }}</td>
                            <td>{{ row.department }}</td>
                            <td>{{ row.duty }}</td>
                            <td>{{ row.childrenCount }}</td>
                            <td>{{ row.childrenWithDisabilityCount }}</td>
                            <td>{{ row.from }}</td>
                            <td>{{ row.to }}</td>
                            <td>{{ row.accumulated }}</td>
                            <td>{{ row.reserved }}</td>
                            <td>{{ row.balance }}</td>
                        </tr>
                    </template>
                </ui-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import moment from 'moment/moment'
import DatepickerField from '@/domain/fields/DatepickerField'
import customApi from '@/plugins/customApi'
import UiButton from '@/domain/components/UiButton.vue'
import MessageAlert from '@/domain/messages/components/MessageAlert.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import TableCheckbox from '@/components/ui/table.old/TableCheckbox.vue'
import UiTable from '@/domain/components/uiTable/UiTable.vue'
import SelectField from '@/domain/fields/SelectField.vue'

export default {
    name: 'VacationReportsPage',
    components: {
        SelectField,
        UiTable,
        TableCheckbox,
        FormCheckbox, MessageAlert, UiButton, DatepickerField
    },
    data() {
        const today = moment().format('YYYY-MM-DD')

        return {
            message: null,
            loading: false,
            fromDate: today,
            toDate: today,
            status: '0',
            statusOptions: [
                { value: '0', text: this.$t('Visi') },
                { value: '1', text: this.$t('Ne') },
                { value: '2', text: this.$t('Taip') },
                { value: '3', text: this.$t('Iš dalies') }
            ],
            rows: []
        }
    },
    methods: {
        getEventStatusText(status) {
            switch (status) {
                case 'approved':
                    return this.$t('patvirtintos')
                case 'aborted':
                    return this.$t('atšauktos')
                default:
                    return this.$t('laukiančios patvirtinimo')
            }
        },
        async fetchReport() {
            this.loading = true
            try {
                const { data } = await customApi().factories.vacations.reports.parentDays.fetch({
                    fromDate: this.fromDate,
                    toDate: this.toDate,
                    status: this.status
                })

                if (data.length === 0) {
                    this.rows = []
                    return
                }

                this.rows = data
            } catch (e) {
                console.error(e)
                this.rows = []
            } finally {
                this.loading = false
            }
        },
        onGetParentDaysReport() {
            window.location.href = `/api/events/holidays/reports/parentdays/download?fromDate=${this.fromDate}&toDate=${this.toDate}&status=${this.status}`
        }
    }
}
</script>