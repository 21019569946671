<template>
    <div class='unread-documents-alert d-flex align-center py-4 px-5 gap-2'>
        <ui-icon>writing</ui-icon>
        <div class='unread-documents-alert__message'>
            <span v-html="$t('Turite <b>{count}</b> laukiančius susipažinimo dokumentus.', { count })" />
        </div>
        <router-link :to="{ name: 'DocumentsUser' }">{{ $t('Peržiūrėti') }}</router-link>
    </div>
</template>

<script>
import UiIcon from '@/domain/components/UiIcon.vue'

export default {
    name: 'UnreadDocumentsAlert',
    components: { UiIcon },
    props: ['count']
}
</script>

<style lang='scss'>
@import "@/assets/sass/core/vars";

.unread-documents-alert {
    font-family: 'Public Sans', sans-serif;
    width: 100%;
    overflow: hidden;
    border-radius: 3px;

    background: $blue-light;
    color: $black-800;

    a {
        color: #32398B !important;
    }

    .ui-icon {
        font-size: 20px;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            left: -2px;
            top: -2px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #FD9A01;
        }
    }

    &__message {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16.45px;
        width: 100%;
    }
}
</style>