import { mapGetters } from 'vuex'

const BOND_MOBILE_FROM_WIDTH = 768

export default {
    computed: {
        ...mapGetters({
            isBondMobile: 'global/isMobile'
        })
    }
}