var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('hra-table',{staticClass:"no-border-around",attrs:{"headers":_vm.headers,"rows":_vm.rows,"hide-headers":"","need-actions":""},scopedSlots:_vm._u([{key:"header.custom",fn:function(){return [_c('table-title',{staticClass:"mb-4",attrs:{"total":_vm.rows.length,"has-border":""}},[_c('hra-icon',{staticClass:"hra-text--greyscale-600 mr-1",attrs:{"name":_vm.iconName.waitList,"size":"24px"}}),_vm._v(" "+_vm._s(_vm.$t('Laukiantys patvirtinimo prašymai'))+" ")],1)]},proxy:true},{key:"col.typeText",fn:function(ref){
var colValue = ref.colValue;
var row = ref.row;
return [(_vm.isBondMobile)?_c('div',{staticClass:"waiting-vacation-mobile-info py-3"},[_c('div',{staticClass:"type"},[_vm._v(_vm._s(colValue))]),_c('div',{staticClass:"period mb-1",domProps:{"innerHTML":_vm._s(_vm.$t("<b>{fromDate}</b> iki <b>{toDate}</b>", {fromDate: row.period.start, toDate: row.period.end}))}}),_c('div',{staticClass:"deputy-title mb-1"},[_c('span',{domProps:{"innerHTML":_vm._s(row.deputyTitle)}})])]):_c('div',{staticClass:"pa-3 py-5"},[_vm._v(_vm._s(colValue))])]}},{key:"col.period",fn:function(ref){
var colValue = ref.colValue;
return [_c('vacation-period-view',{attrs:{"from-date":colValue.start,"to-date":colValue.end}})]}},{key:"col.deputyTitle",fn:function(ref){
var colValue = ref.colValue;
var row = ref.row;
return [_c('div',{staticClass:"px-3 py-5",domProps:{"innerHTML":_vm._s(colValue)}})]}},{key:"col.actions-base",fn:function(ref){
var row = ref.row;
return [_c('table-actions',{attrs:{"type":row.type,"approvalable":row.approvalable,"status":row.status,"user-id":_vm.userId}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }