<template>
    <ValidationProvider
        tag='div'
        class='form-checkbox'
        :name='$attrs.name'
        :rules='rules'
        v-slot='{ errors, classes }'
    >
        <label class='form-checkbox__container' :class='classes'>
      <span class='form-checkbox__label'>
        <slot />
      </span>
            <input
                class='form-checkbox__input'
                type='checkbox'
                :name='$attrs.name'
                :disabled='disabled'
                :checked='isChecked'
                :value="isChecked ? 'ok' : null"
                @change='updateIsChecked'
            />
            <span class='form-checkbox__checkmark'></span>
        </label>
    </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import FormAlert from './FormAlert.vue'
import formFieldMixin from '@/mixins/formFieldMixin'

export default {
    components: { ValidationProvider, FormAlert },
    props: {
        rules: { type: [Object, String], default: '' },
        disabled: { type: Boolean, default: false },
        isChecked: { type: Boolean, default: false }
    },
    model: {
        prop: 'isChecked',
        event: 'update.isChecked'
    },
    methods: {
        updateIsChecked() {
            this.$emit('update:isChecked', !this.isChecked)
            this.$emit('change', !this.isChecked)
        }
    }
}
</script>
