<template>
    <i class='ui-icon' :class='iconClasses' v-on='$listeners' />
</template>

<script>
export default {
    name: 'UiIcon',
    // TODO: props BG border radius
    data() {
        return {
            iconName: ''
        }
    },
    computed: {
        iconClasses() {
            return {
                [`icon-${this.iconName}`]: true
            }
        }
    },
    methods: {
        getIconName() {
            return this.$slots.default[0].text ?? ''
        }
    },
    beforeUpdate() {
        this.iconName = this.getIconName()
    },
    created() {
        this.iconName = this.getIconName()
    }
}
</script>