<template>
    <div class='dtv-home-container px-8 mt-5'>
        <div class='dtv-home-container__left'>
            <hr-new-question class='mt-4' />
        </div>
        <div class='dtv-home-container__right'>
            <h1 class='mt-4'>Kaip tai veikia?</h1>
            <ol class='mt-4'>
                <li>Užduokite bendro pobūdžio klausimus susijusius su darbuotojų atrankos, adaptacijos, ugdymo, veiklos valdymo, darbdavio kultūros, identiteto, komunikacijos ir kt. klausimais.</li>
                <li>Per 3-4 darbo dienas personalo partneriai  išanalizuos Jūsų klausimą ir pateiks atsakymą</li>
                <li>Apie pateiktą atsakymą būsite informuoti el. paštu, o atsakymą galėsite perskaityti skiltyje „Užduoti klausimai“</li>
            </ol>
        </div>
    </div>
</template>

<script>
import TextareaField from '@/domain/fields/TextareaField.vue'
import UiButton from '@/domain/components/UiButton.vue'
import HrNewQuestion from '@/domain/dtv/components/HrNewQuestion.vue'

export default {
    name: 'HrNewQuestionTab',
    components: { HrNewQuestion, UiButton, TextareaField },
    data() {
        return {
            message: ''
        }
    }
}
</script>