<template>
    <Dialog :title="title" :max-width="520" v-if="ids.length > 0">
        <template #button>
            <slot name="button" />
        </template>
        <template #content="{dialog, closeDialog}">
            <EmployeesDeleteForm :ids="ids" :dialog="dialog" :closeDialog="closeDialog"/>
        </template>
    </Dialog>
</template>

<script>
import Dialog from "@/components/ui/dialog/Dialog";
import ButtonIcon from "@/components/ui/button/ButtonIcon";
import EmployeesDeleteForm from "@/components/forms/app/employee/EmployeesDeleteForm";
import { permissionsButtonEmployeeDelete } from "@/utils/permissions";

export default {
    components: {Dialog, ButtonIcon, EmployeesDeleteForm},
    props: {
        ids: {
            type: Array,
            required: true,
        },
    },
    data: () => ({
        permissionsButtonEmployeeDelete
    }),
    computed: {
        title() {
            if (this.ids.length === 1) {
                return this.$t('page.app.employees.dialog.delete');
            }

            return this.$t('page.app.employees.dialog.deletes');
        },
    },
}
</script>
