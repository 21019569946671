<template>
    <route-tabs
        ref='Tabs'
        class='mt-8'
        :tabs='tabs'
        default-tab-name='DocumentsExpirationTrackingPage'
    >
    </route-tabs>
</template>

<script>
import RouteTabs from '@/domain/documents/components/RouteTabs.vue'

export default {
    name: 'DocumentExpirationTrackingLayout',
    components: { RouteTabs },
    data() {
        return {
            tabs: [
                { name: 'DocumentsExpirationTrackingPage', text: this.$t('Dokumentų galiojimo sekimas') },
            ],
            sidebar: null
        }
    },
}
</script>