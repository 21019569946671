<template>
    <div class='documents-upload-dir'>
        <div class='documents-upload-dir__title'>
            {{ title }}
        </div>
        <div
            class='d-flex flex-row align-center gap-3 px-3 mt-1 documents-upload-dir__content'
            @click='handleOpenUploadDirModal'
        >
            <folder-breadcrumbs :breadcrumbs='breadcrumbs' />

            <ui-button
                class='ml-auto px-0'
                only-text
                background='primary'
                size='medium'
            >{{ $t('Keisti') }}
            </ui-button>
        </div>
    </div>
</template>

<script>
import UiButton from '@/domain/components/UiButton.vue'
import UiIcon from '@/domain/components/UiIcon.vue'
import customApi from '@/plugins/customApi'
import modalMethodsMixin from '@/domain/mixins/modalMethodsMixin'
import FolderBreadcrumbs from '@/domain/documents/components/FolderBreadcrumbs.vue'

export default {
    name: 'SelectFolderField',
    mixins: [modalMethodsMixin],
    components: { FolderBreadcrumbs, UiIcon, UiButton },
    props: {
        title: { type: String, required: true },
        folderId: { type: Number, default: null }
    },
    data() {
        return {
            selectedFolderId: this.folderId,
            baseBreadcrumbs: [
                { id: null, title: this.$t('Įmonės dokumentai') },
                { id: null, title: this.$t('Aplankai') }
            ],
            serverBreadcrumbs: []
        }
    },
    computed: {
        breadcrumbs() {
            const items = [
                ...this.baseBreadcrumbs,
                ...this.serverBreadcrumbs
            ]
            const lastKey = items.length - 1
            return items.map((item, key) => ({
                id: item.id,
                title: item.title,
                isLast: key === lastKey
            }))
        }
    },
    mounted() {
        this.fetchBreadcrumbs(this.folderId)
    },
    methods: {
        async fetchBreadcrumbs(folderId = null) {
            try {
                const { data } = await customApi().factories.documents
                    .folders.fetchBreadcrumbs(folderId)

                this.serverBreadcrumbs = data
            } catch (e) {

            }
        },
        handleOpenUploadDirModal() {
            this.onOpenModal(
                'DocumentsSelectUploadDirModal',
                { folderId: this.selectedFolderId },
                {
                    selectedFolderId: ({ folderId, breadcrumbs }) => {
                        this.selectedFolderId = folderId
                        this.serverBreadcrumbs = breadcrumbs
                        this.$emit('changeFolderId', folderId)
                    }
                }
            )
        }
    }
}
</script>

<style lang='scss'>
.documents-upload-dir {
    &__title {
        color: #70778C;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }

    &__content {
        border: 1px solid #DAD5E9;
        border-radius: 3px;
        cursor: pointer;
    }
}
</style>