import _ from 'lodash'
import customApi from '@/plugins/customApi'

export default async function employeeFetchService(isAdmin = true) {
    try {
        const filter = {
            ...this.$store.getters['employees/filter'],
            page: this.$store.getters['employees/page']
        }
        const { query } = this.$route

        if (!isAdmin) {
            delete filter.dismissed
        }

        if (!_.isEqual(query, filter)) {
            await this.$router.push({ query: filter })
        }

        const api = customApi('EMPLOYEES_FETCH')
        const response = await api.request(api.factories.employee.getAll(filter))
        await this.$store.dispatch('employees/load', { employees: response.items, total: response.pagination.total })

        return response.pagination
    } catch (e) {
        return {}
    }
}
