<template>
    <div
        class='ui-calendar'
    >
        <header v-if='withHeader' class='ui-calendar__header'>
            <div class='ui-calendar__header-container'>
                <hra-icon :name='iconName.arrowLeft' @click='onPrevMonth' />
                <datepicker-field name='calendar' type='month' :value.sync='yearMonth' />
                <hra-icon :name='iconName.arrowRight' @click='onNextMonth' />
            </div>
        </header>
        <div class='ui-calendar__months'>
            <div
                v-for='(month, key) in months'
                :key='month.dateMonth'
                class='ui-calendar-month'
                :class='{
                    "hide-background-selected-interval": hideBackgroundSelectedInterval
                }'
            >
                <header class='ui-calendar-month__header'>
                    <hra-icon v-if='hasMonthNavigation(key)' :name='iconName.arrowLeft' @click='onPrevMonth' />
                    <span>{{ month.title }}</span>
                    <hra-icon v-if='hasMonthNavigation(key)' :name='iconName.arrowRight' @click='onNextMonth' />
                </header>

                <div class='ui-calendar-month__weekdays'>
                    <div
                        v-for='weekDay in weekDays'
                        :key='`${month.dateMonth}-${weekDay}`'
                        class='ui-calendar-month__weekday'
                    >{{ weekDay }}
                    </div>
                </div>

                <div class='ui-calendar-month__day-grid'>
                    <ui-calendar-day
                        v-for='day in month.days'
                        :key='day.date'
                        :day='day'
                        :select-start-date='day.date === selectStartDate'
                        :select-end-date='day.date === selectEndDate'
                        :select-date-manual='selectDates.includes(day.date)'
                        :select-date='selectDatesInInterval.includes(day.date)'
                        :shown-menu='shownMenu'
                        @click.prevent='onClick(day, day.eventClickHandler)'

                    >
                        <template #day-menu>
                            <slot name='day-menu' />
                        </template>
                    </ui-calendar-day>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HraCalendarWeekDays from '@/components/ui/calendar/HraCalendarWeekDays.vue'
import HraIcon from '@/components/ui/HraIcon.vue'
import moment from 'moment'
import { getDateArray, GLOBAL_TOAST_TAG } from '@/utils/default'
import HraTooltip from '@/components/ui/HraTooltip.vue'
import DatepickerField from '@/domain/fields/DatepickerField.vue'
import { mapGetters } from 'vuex'
import HraCalendarDay from '@/components/ui/calendar/HraCalendarDay.vue'
import MessageToasts from '@/domain/messages/MessageToasts.vue'
import UiCalendarDay from '@/domain/components/UiCalendar/UiCalendarDay.vue'
import isBondMobileMixin from '@/mixins/isBondMobileMixin'

export default {
    name: 'UiCalendar',
    mixins: [isBondMobileMixin],
    components: {
        UiCalendarDay,
        MessageToasts,
        HraCalendarDay,
        DatepickerField,
        HraTooltip,
        HraIcon,
        HraCalendarWeekDays
    },
    props: {
        withHeader: { type: Boolean, default: false },
        withFirstMonthNavigation: { type: Boolean, default: false },
        withAllMonthNavigation: { type: Boolean, default: false },
        yearMonth: { type: [String], required: true },
        maxMonthCount: { type: [Number, String], default: 1 },
        events: { type: Object, default: () => ({}) },
        minSelectDate: { type: String, default: null },
        maxSelectDate: { type: String, default: null },
        selectStartDate: { type: String, default: null },
        selectEndDate: { type: String, default: null },
        clickableDates: { type: Boolean, default: false },
        shownMenu: { type: Boolean, default: false },
        subViewMonth: { type: Number, default: 0 },
        selectDates: { type: Array, default: () => [] },
        hideBackgroundSelectedInterval: { type: Boolean, default: false },
        disableMobileClick: { type: Boolean, default: false }
    },
    model: [
        { prop: 'yearMonth', event: 'update:yearMonth' },
        { prop: 'selectStartDate', event: 'update:selectStartDate' },
        { prop: 'selectEndDate', event: 'update:selectEndDate' }
    ],
    data() {
        return {
            errorTag: GLOBAL_TOAST_TAG
        }
    },
    watch: {
        yearMonth: {
            handler: function(newYearMonth) {
                const date = moment(`${newYearMonth}-01`)
                const subDate = date.clone().subtract(this.subViewMonth, 'months')
                const endDate = date.clone().add(this.maxMonthCount, 'months')
                const years = [date.year(), subDate.year(), endDate.year()]

                for (let year = Math.min(...years); year <= Math.max(...years); year++) {
                    this.$store.dispatch('calendar/fetch', { year })
                }
            },
            immediate: true
        }
    },
    computed: {
        ...mapGetters({
            eventHolidays: 'calendar/eventHolidays'
        }),
        baseStart() {
            return moment(`${this.yearMonth}-01`)
        },
        todayDate() {
            return moment().format('YYYY-MM-DD')
        },
        weekDays() {
            return Array.apply(null, Array(7)).map(
                (_, i) => moment(i, 'e').startOf('week').locale(this.currentLocale)
                    .isoWeekday(i + 1).format('dd')
            )
        },
        selectDatesInInterval() {
            if (this.selectStartDate == null) {
                return []
            }

            return getDateArray(moment(this.selectStartDate), moment(this.selectEndDate))
        },
        months() {
            let months = []

            for (let i = 0; i < this.maxMonthCount; i++) {
                const start = this.baseStart.clone().subtract(this.subViewMonth, 'months').add(i, 'months').startOf('month')
                const end = start.clone().endOf('month')
                const month = {
                    'date': start.format('YYYY-MM-DD'),
                    'dateMonth': start.format('YYYY-MM'),
                    'title': start.locale(this.currentLocale).format('MMMM, YYYY'),
                    'days': []
                }

                const firstMonthDate = start.format('YYYY-MM-DD')
                const lastMonthDate = end.format('YYYY-MM-DD')

                let beforeDays = []
                const beforeSubDays = start.isoWeekday() - 1
                if (beforeSubDays > 0) {
                    const beforeStart = start.clone().subtract(start.isoWeekday() - 1, 'days')
                    beforeDays = getDateArray(beforeStart, beforeStart.clone().endOf('month')).map(date => {
                        return {
                            date,
                            isoWeekday: moment(date).isoWeekday(),
                            day: date.split('-').pop(),
                            type: 'before-month'
                        }
                    })
                }

                let afterDays = []
                const afterAddDays = 7 - end.isoWeekday()
                if (afterAddDays > 0) {
                    const afterEnd = end.clone().add(7 - end.isoWeekday(), 'days')
                    afterDays = getDateArray(afterEnd.clone().startOf('month'), afterEnd).map(date => {
                        return {
                            date,
                            isoWeekday: moment(date).isoWeekday(),
                            day: date.split('-').pop(),
                            type: 'after-month'
                        }
                    })
                }

                month.days = [
                    ...beforeDays,
                    ...getDateArray(start, end).map(date => {
                        const event = this.events[date] ?? null
                        const holiday = this.eventHolidays[date] ?? null
                        const isToday = date === this.todayDate

                        let tooltip = []
                        if (event != null) {
                            tooltip.push(event.tooltip)
                        }

                        if (holiday != null) {
                            tooltip.push(holiday)
                        }

                        if (isToday) {
                            tooltip.push(this.$t('Šiandien'))
                        }

                        return {
                            date,
                            isoWeekday: moment(date).isoWeekday(),
                            day: date.split('-').pop(),
                            classes: {
                                'ui-calendar-month__day_radius-left': event != null ? date === firstMonthDate || event.isStart : false,
                                'ui-calendar-month__day_radius-right': event != null ? date === lastMonthDate || event.isEnd : false,
                                'ui-calendar-month__day_today': isToday,
                                'ui-calendar-month__day_holiday': holiday != null
                            },
                            type: 'event-' + (event?.type ?? 'base'),
                            status: event?.status ?? 'none',
                            tooltip: tooltip.length > 0 ? tooltip.join('<br/>') : null,
                            eventClickHandler: event?.click ?? null
                        }
                    }),
                    ...afterDays
                ]

                months.push(month)
            }

            return months
        }
    },
    methods: {
        onPrevMonth() {
            const newYearMonth = this.baseStart.clone().subtract(1, 'month').format('YYYY-MM')

            this.$emit('update:yearMonth', newYearMonth)
        },
        onNextMonth() {
            const newYearMonth = this.baseStart.clone().add(1, 'month').format('YYYY-MM')

            this.$emit('update:yearMonth', newYearMonth)
        },
        hasMonthNavigation(key) {
            if (this.withFirstMonthNavigation) {
                return key === 0
            }

            return this.withAllMonthNavigation
        },
        onClick(day, eventClickHandler = null) {
            const date = day.date

            if (this.isBondMobile && this.disableMobileClick) {
                return
            }

            if (this.selectStartDate == null && eventClickHandler != null) {
                return eventClickHandler()
            }

            if (!this.clickableDates) {
                return
            }

            if (this.minSelectDate && this.minSelectDate > date) {
                this.$store.dispatch('messagesStore/showErrorMessage', {
                    tag: this.errorTag,
                    message: this.$t('Jus negalite pasirinkti ankstesnės datos nei {date}', { date: this.minSelectDate })
                })
                return
            }

            if (this.maxSelectDate && this.maxSelectDate > date) {
                this.$store.dispatch('messagesStore/showErrorMessage', {
                    tag: this.errorTag,
                    message: this.$t('Jus negalite pasirinkti vėlesnės datos nei {date}', { date: this.maxSelectDate })
                })
                return
            }

            this.$emit('clickDay', day)

            if (this.selectStartDate == null) {
                this.$emit('update:selectStartDate', date)
                this.$emit('update:selectEndDate', date)
                return
            }

            if (date < this.selectStartDate) {
                this.$emit('update:selectStartDate', date)
                return
            }

            if (date > this.selectEndDate) {
                this.$emit('update:selectEndDate', date)
                return
            }

            this.$emit('update:selectStartDate', date)
            this.$emit('update:selectEndDate', date)
        }
    }
}
</script>