<template>
	<Form
		v-model="formData"
		@submit="onSubmit"
		:form-loader-name="loaderName"
	>
		<template #form>
			<v-row>
				<v-col cols="12">
					<FormTextField
						rules="required|email"
						v-model="formData.email"
						name="email"
						:label="$t('El. pašto adresas')"
					/>
				</v-col>
				<v-col cols="12">
					<FormTextField
						:rules="'required|min:8,' + $t('simbolius')"
						v-model="formData.password"
						name="password"
						type="password"
						:label="$t('Slaptažodis')"
					>
						<template #right-label>
							<router-link to="/auth/forgot/password">{{$t("Pamiršau slaptažodį") }}</router-link>
						</template>
					</FormTextField>
				</v-col>
				<v-col cols="12">
					<form-alert v-if="message" v-bind="message" />
				</v-col>
			</v-row>
		</template>
		<template #buttons>
			<hra-button
				:loading="loadingButton"
				:color="uiColor.primary"
				:type="buttonType.submit"
				full-width
				class="mt-7"
			>{{ $t("Prisijungti") }}
			</hra-button>
		</template>
	</Form>
</template>

<script>
import Form from "@/components/ui/form/Form.vue";
import FormTextField from "@/components/ui/form/FormTextField.vue";
import Button from "@/components/ui/button/Button.vue";
import {authRouteProps} from "@/router/auth";
import HraButton from "@/components/ui/button/HraButton";
import {appRouteProps} from "@/router/app";
import {mapGetters} from "vuex";
import FormAlert from "@/components/ui/form/FormAlert";

export default {
	components: {FormAlert, HraButton, Form, FormTextField, Button,},
	data: () => ({
		message: null,
		loadingButton: false,
		formData: {email: "", password: ""},
	}),
	inject: ["authService"],
	computed: {
		...mapGetters({
			setup: "user/setup",
		}),
	},
	methods: {
		async onSubmit({email, password}) {
			try {
				this.loadingButton = true;
				await this.authService.login(email, password)
			} catch (error) {
				this.message = {
					type: "error",
					text: error.message,
				};
			} finally {
				this.loadingButton = false;
			}
		},
	},
};
</script>
