<template>
    <custom-dialog :max-width='700' has-custom-footer>
        <template #title>{{ $t('Darbuotojo vaikas') }}</template>
        <template #content>
            <Form v-model='formData' is-dialog :form-loader-name='loaderName' @submit='onSubmit'>
                <template #form>
                    <v-row>
                        <v-col cols='6'>
                            <text-field
                                name='firstName'
                                rules='required'
                                :label="$t('Vardas')"
                                v-model='formData.firstName'
                            />
                        </v-col>
                        <v-col cols='6'>
                            <datepicker-field
                                name='birthDate'
                                rules='required'
                                :label="$t('Gimimo metai')"
                                v-model='formData.birthDate'
                            />
                        </v-col>
                        <v-col cols='6'>
                            <select-field
                                name='disability'
                                :options='yesNoOptions'
                                :label="$t('Neįgalumas')"
                                v-model='formData.disability'
                            />
                        </v-col>
                        <template v-if='formData.disability'>
                            <v-col cols='6'>
                                <text-field
                                    name='disabilityNo'
                                    rules='required'
                                    :label="$t('Neįgalumo pažymos numeris')"
                                    v-model='formData.disabilityNo'
                                />
                            </v-col>
                            <v-col cols='6'>
                                <datepicker-field
                                    name='disabilityFrom'
                                    rules='required'
                                    :min-date='formData.disability ? formData.birthDate : null'
                                    :label="$t('Neįgalumas nuo')"
                                    v-model='formData.disabilityFrom'
                                />
                            </v-col>
                            <v-col cols='6'>
                                <datepicker-field
                                    name='disabilityTo'
                                    :min-date='formData.disability ? formData.disabilityFrom : null'
                                    :label="$t('Neįgalumas iki')"
                                    v-model='formData.disabilityTo'
                                    can-clear
                                />
                            </v-col>
                        </template>
                    </v-row>
                </template>

                <template #buttons='{loading}'>
                    <hra-button :size='buttonSize.small' @click='close'>
                        {{ $t('Uždaryti') }}
                    </hra-button>
                    <hra-button :color='uiColor.primary' :size='buttonSize.small' :type='buttonType.submit'
                                :loading='loadingButton'>
                        {{ $t('Išsaugoti') }}
                    </hra-button>
                </template>

                <template #footer-messages>
                    <message-alerts :listen='tag' />
                </template>
            </Form>
        </template>
    </custom-dialog>
</template>

<script>
import HraButton from '@/components/ui/button/HraButton'
import HraIcon from '@/components/ui/HraIcon'
import dialogMixin from '@/mixins/dialogMixin'
import CustomDialog from '@/components/ui/dialog/CustomDialog'
import FormMessages from '@/components/ui/form/FormMessages'
import FormAlert from '@/components/ui/form/FormAlert'
import FullRowLoader from '@/components/ui/FullRowLoader'
import Form from '@/components/ui/form/Form'
import DatepickerField from '@/domain/fields/DatepickerField'
import moment from 'moment'
import TextField from '@/domain/fields/TextField'
import SelectField from '@/domain/fields/SelectField'
import MessageAlerts from '@/domain/messages/MessageAlerts.vue'

export default {
    name: 'EmployeeChildModal',
    mixins: [dialogMixin],
    components: {
        MessageAlerts,
        SelectField,
        TextField,
        DatepickerField, Form, FullRowLoader, FormAlert, FormMessages, CustomDialog, HraIcon, HraButton
    },
    props: {
        service: { type: Function, required: true },
        payload: { type: Object, default: null }
    },
    watch: {
        'formData.disability': {
            handler(oldValue, newValue) {
                if (newValue === 0) {
                    this.formData.disabilityNo = ''
                    this.formData.disabilityFrom = null
                    this.formData.disabilityTo = null
                }
            }
        }
    },
    data() {
        const today = moment().format('YYYY-MM-DD')
        const disability = this.payload?.disabilities[0] ?? false

        return {
            translatePrefix: 'modals.EmployeeChildModal.',
            loadingButton: false,
            closeDialogDelay: 1000,
            message: null,
            tag: 'EMPLOYEE_CHILD_SUBMIT_TAG',
            today,
            formData: {
                firstName: this.payload?.firstName || null,
                birthDate: this.payload?.birthDate || today,
                disability: disability ? 1 : 0,
                disabilityNo: disability?.number || '',
                disabilityFrom: disability?.fromDate || null,
                disabilityTo: disability?.toDate || null
            }
        }
    },
    methods: {
        async onSubmit(payload) {
            this.loadingButton = true

            const service = await this.service(this.tag, {
                firstName: payload.firstName,
                birthDate: payload.birthDate,
                disabilities: payload.disability === 0 ? [] : [
                    {
                        number: payload.disabilityNo,
                        fromDate: payload.disabilityFrom,
                        toDate: payload.disabilityTo
                    }
                ]
            }, this.payload?.id ?? null)

            if (service) {
                this.message = { type: 'success', text: this.$t('Išsaugotą sekmingai') }
                this.closeWithDelay()
            }

            this.loadingButton = false
        }
    }
}
</script>
