<template>
    <v-tooltip top :max-width='maxWidth'>
        <template #activator='{attrs, on}'>
          <span class='hra-tooltip' v-bind='attrs' v-on='disabled ? {} : on'>
              <slot />
          </span>
        </template>
        <span v-html='text' />
    </v-tooltip>
</template>

<script>
export default {
    name: 'HraTooltip',
    props: {
        text: { type: String, default: '' },
        maxWidth: { type: [String, Number], default: 'auto' }
    },
    computed: {
        disabled() {
            return this.text === '' || this.text == null
        }
    }
}
</script>
