<template>
    <custom-dialog :max-width='maxWidth' is-confirm>
        <template v-if='!!title' #title>{{ title }}</template>

        <template v-if='!!content' #content>
            <div v-html='content' />
        </template>

        <template #buttons>
            <ui-button
                prepend-icon='close'
                background='default'
                @click='onAbort'
            >{{ buttonAbortText }}
            </ui-button>
            <ui-button
                v-if='!hideConfirm'
                prepend-icon='ok'
                background='primary'
                @click='onConfirm'
            >{{ buttonConfirmText }}
            </ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import dialogMixin from '@/mixins/dialogMixin'
import UiButton from '@/domain/components/UiButton.vue'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'

export default {
    name: 'ConfirmModal',
    mixins: [dialogMixin],
    components: {
        CustomDialog,
        UiButton
    },
    props: {
        maxWidth: { type: Number, default: 400 },
        title: { type: String, default: null },
        content: { type: String, default: null },
        hideConfirm: { type: Boolean, default: false },
        abortText: { type: String, default: null },
        confirmText: { type: String, default: null }
    },
    computed: {
        buttonAbortText() {
            return this.abortText ?? this.$t('Ne')
        },
        buttonConfirmText() {
            return this.confirmText ?? this.$t('Taip')
        }
    },
    methods: {
        onAbort() {
            if (!this.$listeners.hasOwnProperty('aborted')) {
                this.close()
                return
            }

            this.$emit('aborted', this)
        },
        onConfirm() {
            if (!this.$listeners.hasOwnProperty('confirmed')) {
                this.close()
                return
            }

            this.$emit('confirmed', this)
        }
    }
}
</script>