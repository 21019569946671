
const initState = () => ({
  data: [],
  currentPage: 1,
  limit: 20,
});

const state = () => initState();

// getters
const getters = {
  currentPage: state => state.currentPage,
  limit: state => state.limit,
  totalPages: state => Math.ceil(state.data.length / state.limit),
  rows: state => {
    const from = (state.currentPage - 1) * state.limit;
    const to = from + state.limit;

    return state.data.slice(from, to).map(item => ({
      id: item.id,
      title: item.title,
      date: item.date,
      values: item.values,
      employeeName: item.adminEmployee.name,
    }))
  },
};

// actions
const actions = {
  init: ({commit}, payload) => {
    commit("init", payload);
  },
  destroy: ({commit}) => commit('destroy'),
};

//  mutations
const mutations = {
  init: (state, payload) => state.data = payload,
  changePage: (state, payload) => state.currentPage = payload,
  destroy: state => Object.assign(state, initState()),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
