import _ from 'lodash'

export default {
    data() {
        return {
            filter: {},
            filterSettings: {
                setRouteQuery: false,
                sortOnlyOne: false,
                resetSelected: {}
            },
            filterSortNumberOptions: [
                { label: this.$t('Didėjimo tvarka'), value: 'asc' },
                { label: this.$t('Mažėjimo tvarka'), value: 'desc' }
            ],
            filterSortDateOptions: [
                { value: 'desc', label: this.$t('Naujausi viršuje') },
                { value: 'asc', label: this.$t('Seniausi viršuje') }
            ],
            filterSortTextOptions: [
                { label: 'A-Z', value: 'asc' },
                { label: 'Z-A', value: 'desc' }
            ]
        }
    },
    methods: {
        setFilter(column, filter) {
            const currentFilter = this.filter[column]

            if (this.filterSettings.sortOnlyOne && filter.sort != null) {
                Object.keys(this.filter).forEach(key => {
                    this.$set(this.filter, key, { ...this.filter[key], sort: null })
                })
            }

            if (Array.isArray(filter.selected) && !_.isEqual(currentFilter?.selected ?? [], filter.selected)) {
                const resetSelected = this.filterSettings.resetSelected[column] ?? []
                resetSelected.forEach(key => {
                    this.$set(this.filter, key, { ...this.filter[key], selected: [] })
                })
            }

            this.$set(this.filter, column, filter)
            if (this.filterSettings.setRouteQuery) {
                this.$router.push({ query: this.getFilterParams() }).catch(() => {
                })
            }
        },
        getFilterFromRouteQuery(query) {
            if (query == null) {
                return
            }

            if (query.sortBy != null) {
                (Array.isArray(query.sortBy) ? query.sortBy : [query.sortBy])
                    .forEach(sortBy => {
                        const [column, sort] = sortBy.split(',')
                        this.setFilter(column, { ...(this.filter[column] ?? {}), sort })
                    })
            }

            Object.keys(this.filter).forEach(column => {
                if (query[column] != null) {
                    const selected = Array.isArray(query[column]) ? query[column] : [query[column]]
                    this.setFilter(column, { ...(this.filter[column] ?? {}), selected })
                }
            })
        },
        getFilterParams() {
            if (this.filter == null) {
                return {}
            }

            let params = {
                sortBy: []
            }

            for (const [column, value] of Object.entries(this.filter)) {
                if (value.sort != null) {
                    params.sortBy.push(`${column},${value.sort}`)
                }

                if ((value.selected?.length ?? 0) > 0) {
                    params[column] = value.selected
                }

                if (value.date != null) {
                    params[column] = value.date
                }

                if (value.from != null) {
                    params[`${column}From`] = value.from
                }

                if (value.to != null) {
                    params[`${column}To`] = value.to
                }
            }

            return params
        }
    }
}