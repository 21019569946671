function getMode() {
    const modeFromStore = localStorage.getItem('documents-finder-mode')

    if (['grid', 'list'].includes(modeFromStore)) {
        return modeFromStore
    }

    return 'grid'
}

const baseState = () => ({
    folderId: null,
    loading: false,
    loadingMore: false,
    mode: getMode(),
    search: '',
    limit: 35,
    currentPage: 1,
    hasMore: false,
    breadcrumbs: [],
    items: [],
    selectedItems: [],
    historyNext: []
})

const baseGetters = {
    folderId: ({ folderId }) => folderId,
    loading: ({ loading }) => loading,
    loadingMore: ({ loadingMore }) => loadingMore,
    visibleLoadMore: ({ hasMore }) => hasMore,
    search: ({ search }) => search,
    breadcrumbs: ({ breadcrumbs }) => breadcrumbs,
    mode: ({ mode }) => mode,
    title: ({ breadcrumbs }) => breadcrumbs[breadcrumbs.length - 1]?.title ?? null,
    items: ({ items }) => items,
    documentsCount: ({ items }) => items.filter(({ isFolder }) => !isFolder).length,
    selectedDocuments: ({ items }) => {
        return items.filter(({ selected, isFolder }) => selected && !isFolder).map(({ item }) => item)
    },
    isSelectedAllDocuments: (state, { selectedDocuments, documentsCount }) => {
        if (documentsCount === 0) {
            return false
        }

        return selectedDocuments.length === documentsCount
    },
    hasHistoryNext: ({ historyNext }) => !!historyNext.length,
    hasHistoryPrev: ({ breadcrumbs }) => !!breadcrumbs.length
}

const baseActions = {
    setData({ state }, payload) {
        Object.assign(state, payload)
    },
    async load({ dispatch, state }) {
        if (state.loading) {
            return
        }

        state.historyNext = []
        state.loading = true
        await dispatch('fetch')
        state.loading = false
    },
    async loadMore({ dispatch, state }) {
        if (state.loadingMore) {
            return
        }

        state.loadingMore = true
        await dispatch('fetch', {
            page: state.currentPage + 1
        })
        state.loadingMore = false
    },
    async ['breadcrumbs/changeId']({ state, dispatch }, payload) {
        state.folderId = payload
        state.search = null
        dispatch('load')
    },
    async ['history/prev']({ dispatch, state }) {
        const folderId = state.breadcrumbs[state.breadcrumbs.length - 2]?.id ?? null
        state.loading = true
        state.historyNext.push(state.folderId)
        state.folderId = folderId
        await dispatch('fetch')
        state.loading = false
    },
    async ['history/forward']({ dispatch, state }) {
        state.loading = true
        state.folderId = state.historyNext.pop()
        await dispatch('fetch')
        state.loading = false
    },
    ['mode/change']({ state }, payload) {
        localStorage.setItem('documents-finder-mode', payload)
        state.mode = payload
    },
    ['search/change']({ state, dispatch }, payload) {
        state.folderId = null
        state.search = payload
        dispatch('load')
    }
}

export { baseState, baseGetters, baseActions }