<template>
    <hra-dialog-confirm
        ref="dialog"
        :title="t(ids.length === 1 ? 'titleOne' : 'titleMany')"
        :hide-buttons="hideButtons"
        :hide-confirm-button="hideConfirmButton"
        @open="onOpen"
        @close="onClose"
        @confirm="sent"
    >
        <template #content>
            <div  class="my-4">
                <full-row-loader v-if="loadingView" />
                <hra-alert v-if="message" :color="sentSuccess ? uiColor.success : uiColor.danger">
                    <template #icon><hra-icon :name="sentSuccess ? iconName.ok :iconName.close" /></template>
                    {{ message }}
                </hra-alert>
            </div>
        </template>
        <template #button.abort.text>{{ $t('Uždaryti') }}</template>
        <template #button.confirm>
            <hra-button v-if="!sentSuccess" :color="uiColor.primary" :size="buttonSize.small" full-width>
                {{ t("button.tryAgain") }}
            </hra-button>
            <div v-else/>
        </template>
        <template #button><slot /></template>
    </hra-dialog-confirm>
</template>

<script>
import {mapMutations} from "vuex";
import FullRowLoader from "@/components/ui/FullRowLoader";
import HraDialogConfirm from "@/components/ui/dialog/HraDialogConfirm";
import HraAlert from "@/components/ui/HraAlert";
import HraIcon from "@/components/ui/HraIcon";
import apiEmployee from "@/api/app/apiEmployee";
import HraButton from "@/components/ui/button/HraButton";

export default {
    components: {
        HraButton,
        HraIcon,
        HraAlert,
        HraDialogConfirm,
        FullRowLoader
    },
    props: {
        ids: {
            type: Array,
            required: true,
        },
    },
    data: () => ({
        translatePrefix: "dialog.app.employee.invite.",
        loaderName: "Employees-Send-Invite",
        sentSuccess: false,
        message: "",
        hideButtons: true,
        hideConfirmButton: true,
    }),
    computed: {
        title() {
            return this.t(`title.${this.ids.length > 1 ? 'many' : 'one'}.${this.wait ? 'wait' : 'done'}`);
        },
    },
    methods: {
        ...mapMutations({
            checkSentInvites: "employees/checkSentInvites",
            clearTableData: "employees/clearTableData",
        }),
        onOpen() {
            this.sent();
        },
        onClose() {
            this.clearTableData();
        },
        async sent() {
            this.sentSuccess = false;
            this.message = "";
            this.hideButtons = true;
            this.hideConfirmButton = true;

            try {
                const {data} = await this.axiosRequest(apiEmployee.sentInvite, {ids: this.ids});
                this.sentSuccess = true;
                this.message = this.t(`message.${this.ids.length === 1 ? 'sentOneSuccess' : 'sentManySuccess'}`);
                this.hideButtons = false;
                this.checkSentInvites(data.ids);
                // this.$refs.dialog?.closeWithDelay(3000);
            } catch (error) {
                this.message = this.t("message.sentFail");
                this.hideButtons = false;
                this.hideConfirmButton = false;
            }
        }
    },
}
</script>
