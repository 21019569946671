<template>
    <text-field
        style='width: 240px'
        :placeholder='placeholder'
        icon='find'
        can-clear
        v-model='search'
        @input='handleChangeSearch'
    >
        <template #append-inner>
            <hra-icon class='ml-2' name='find' />
        </template>
    </text-field>
</template>

<script>
import i18n from '@/plugins/i18n'
import HraIcon from '@/components/ui/HraIcon.vue'
import TextField from '@/domain/fields/TextField.vue'

export default {
    name: 'DocumentsFinderHeaderSearch',
    components: { TextField, HraIcon },
    props: {
        label: {type: String, default: null},
        placeholder: {type: String, default: () => i18n.t('Ieškoti...')},
        debounce: {type: Number, default: 300},
    },
    inject: ['storeNamespace'],
    data() {
        return {
            searchTimeout: null,
            search: ''
        }
    },
    created() {
        this.$watch(
            () => [this.$store.getters[`${this.storeNamespace}/search`]],
            () => {
                this.search = this.$store.getters[`${this.storeNamespace}/search`]
            }
        )
    },
    methods: {
        handleChangeSearch() {
            clearTimeout(this.searchTimeout)

            this.searchTimeout = setTimeout(() => {
                this.$store.dispatch(`${this.storeNamespace}/search/change`, this.search)
            }, this.debounce)
        }
    }
}
</script>