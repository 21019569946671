<template>
    <custom-dialog
        :max-width='800'
        ref='dialog'
        has-custom-footer
        :loading='loading'
    >
        <template #title>{{ $t('Redaguoti ligonlapi') }}</template>
        <template #content>
            <validation-observer ref='ValidationObserver' tag='div' class='row pa-3'>
                <v-col cols='6'>
                    <user-select-field
                        :label="$t('Darbuotojas')"
                        :value='formData.user'
                        rules='required'
                        @change='value => handleChange("user", value)'
                    />
                </v-col>
                <v-col cols='6'>
                    <text-field
                        :label="$t('Asmens kodas')"
                        :value='formData.personalCode'
                        :placeholder='personalCodePlaceholder'
                        rules='min:11'
                        @input='value => handleChange("personalCode", value)'
                    />
                </v-col>
                <v-col cols='6'>
                    <text-field
                        :value='formData.type'
                        rules='required'
                        :label="$t('Pažymėjimo tipas')"
                        @input='value => handleChange("type", value)'
                    />
                </v-col>

                <v-col cols='6'>
                    <text-field
                        :label="$t('Nedarbingumo priežastis')"
                        :value='formData.sickType'
                        rules='required'
                        @input='value => handleChange("sickType", value)'
                    />
                </v-col>
                <v-col cols='6'>
                    <datepicker-field
                        :label="$t('Data nuo')"
                        :value='formData.start'
                        rules='required'
                        numbers-only
                        @input='value => handleChange("start", value)'
                    />
                </v-col>
                <v-col cols='6'>
                    <datepicker-field
                        :label="$t('Data iki')"
                        :min-date='formData.start'
                        :value='formData.end'
                        rules='required'
                        numbers-only
                        @input='value => handleChange("end", value)'
                    />
                </v-col>
                <v-col cols='6'>
                    <text-field
                        :label="$t('SD serija')"
                        :value='formData.personalSDSeries'
                        @input='value => handleChange("personalSDSeries", value)'
                    />
                </v-col>
                <v-col cols='6'>
                    <text-field
                        :label="$t('SD numeris')"
                        :value='formData.personalSDNumber'
                        @input='value => handleChange("personalSDNumber", value)'
                    />
                </v-col>
                <v-col cols='6'>
                    <text-field
                        :label="$t('Pažymėjimo serija')"
                        :value='formData.noteSeries'
                        @input='value => handleChange("noteSeries", value)'
                    />
                </v-col>
                <v-col cols='6'>
                    <text-field
                        :label="$t('Pažymėjimo numeris')"
                        :value='formData.noteNumber'
                        @input='value => handleChange("noteNumber", value)'
                    />
                </v-col>
                <v-col cols='4'>
                    <select-field
                        :label="$t('Ar užpildyta draudėjui info')"
                        :value='formData.filledInPolicyholderInfo'
                        :options='booleanOptions'
                        @input='value => handleChange("filledInPolicyholderInfo", value)'
                    />
                </v-col>
                <v-col cols='4'>
                    <select-field
                        :label="$t('Ar reikia draudejui mokėti')"
                        :value='formData.policyholderNeedsToPay'
                        :options='booleanOptions'
                        @input='value => handleChange("policyholderNeedsToPay", value)'
                    />
                </v-col>
                <v-col cols='4'>
                    <select-field
                        :label="$t('Pateikė NPSD')"
                        :value='formData.submittedNPSD'
                        :options='booleanOptions'
                        @input='value => handleChange("submittedNPSD", value)'
                    />
                </v-col>
                <v-col cols='12' v-if='errorMessages.length > 0' class='d-flex flex-column gap-2'>
                    <message-alert v-for='(message, key) in errorMessages' :id='`error-message-${key}`' :message='message' type='error' />
                </v-col>
            </validation-observer>
        </template>
        <template #footer>
            <v-spacer />
            <ui-button :disabled='loading' prepend-icon='close' background='danger-light' @click='close'>
                {{ $t('Atšaukti') }}
            </ui-button>
            <ui-button :disabled='loading' prepend-icon='ok' background='primary' @click='handleSave'>{{ $t('Išsaugoti')
                }}
            </ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import CustomApi from '@/plugins/customApi'
import ImportSickLeavesTable from '@/domain/sickLeaves/components/import/ImportSickLeavesTable.vue'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import ImportSickLeavesUpload from '@/domain/sickLeaves/components/import/ImportSickLeavesUpload.vue'
import UiButton from '@/domain/components/UiButton.vue'
import dialogMixin from '@/mixins/dialogMixin'
import DatepickerField from '@/domain/fields/DatepickerField.vue'
import UserSelectField from '@/domain/fields/UserSelectField.vue'
import TextField from '@/domain/fields/TextField.vue'
import SelectField from '@/domain/fields/SelectField.vue'
import { ValidationObserver } from 'vee-validate'
import { GLOBAL_TOAST_TAG } from '@/utils/default'
import MessageAlert from '@/domain/messages/components/MessageAlert.vue'

const customApi = CustomApi()
export default {
    name: 'UpdateSickLeaveModal',
    mixins: [dialogMixin],
    components: {
        MessageAlert,
        ValidationObserver,
        SelectField,
        TextField,
        UserSelectField,
        DatepickerField, UiButton, ImportSickLeavesUpload, CustomDialog, ImportSickLeavesTable
    },
    props: {
        id: { type: Number, required: true }
    },
    data() {
        return {
            loading: false,
            errorMessages: [],
            booleanOptions: [
                { value: true, text: 'Taip' },
                { value: false, text: 'Ne' }
            ],
            personalCodePlaceholder: null,
            formData: {
                code: null,
                personalCode: null,
                personalSDSeries: null,
                personalSDNumber: null,
                type: null,
                noteSeries: null,
                noteNumber: null,
                start: null,
                end: null,
                sickType: null,
                filledInPolicyholderInfo: false,
                policyholderNeedsToPay: false,
                submittedNPSD: false,
                user: null
            }
        }
    },
    mounted() {
        this.fetchEvent()
    },
    methods: {
        async fetchEvent() {
            this.loading = true
            try {
                const { data } = await customApi.factories.events.sickLeaves.fetchOne(this.id)

                const formData = {
                    code: data.sickLeave.code,
                    personalCode: null,
                    personalSDSeries: data.sickLeave.personalSDSeries,
                    personalSDNumber: data.sickLeave.personalSDNumber,
                    type: data.sickLeave.type,
                    noteSeries: data.sickLeave.noteSeries,
                    noteNumber: data.sickLeave.noteNumber,
                    start: data.start,
                    end: data.end,
                    sickType: data.sickLeave.sickType,
                    filledInPolicyholderInfo: data.sickLeave.filledInPolicyholderInfo,
                    policyholderNeedsToPay: data.sickLeave.policyholderNeedsToPay,
                    submittedNPSD: data.sickLeave.submittedNPSD,
                    user: data.user
                }

                this.personalCodePlaceholder = data.sickLeave?.personalCode ?? null

                this.$set(this, 'formData', formData)
            } catch (e) {

            } finally {
                this.loading = false
            }
        },
        handleChange(fieldName, value) {
            if (fieldName === 'user') {
                this.personalCodePlaceholder = null
                this.$set(this.formData, 'personalCode', value.personalCode ?? null)
            }

            this.$set(this.formData, fieldName, value)
        },
        getPayload() {
            return {
                code: this.formData.code,
                personalCode: this.formData.personalCode ?? null,
                personalSDSeries: this.formData.personalSDSeries,
                personalSDNumber: this.formData.personalSDNumber,
                type: this.formData.type,
                noteSeries: this.formData.noteSeries,
                noteNumber: this.formData.noteNumber,
                start: this.formData.start,
                end: this.formData.end,
                sickType: this.formData.sickType,
                filledInPolicyholderInfo: this.formData.filledInPolicyholderInfo,
                policyholderNeedsToPay: this.formData.policyholderNeedsToPay,
                submittedNPSD: this.formData.submittedNPSD,
                noteDeleted: false,
                userId: this.formData.user.id,
            }
        },
        async handleSave() {
            if (!(await this.$refs.ValidationObserver.validate())) {
                await this.$store.dispatch('messagesStore/showErrorMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Užpildykite visus privalomus laukus')
                })
                return
            }

            this.loading = true
            this.errorMessages = []
            try {
                const { data } = await customApi.factories.events.sickLeaves.update(this.id, this.getPayload())
                this.$emit('updated', data)
                this.close()
            } catch (error) {
                const messages = error.response?.data?.message ?? null

                if (messages != null) {
                    this.errorMessages = messages
                }

                if (messages == null) {
                    console.error(error)
                }

                await this.$store.dispatch('messagesStore/showErrorMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Įvyko klaida. Bandykite dar kartą')
                })
            } finally {
                this.loading = false
            }
        }
    }
}
</script>