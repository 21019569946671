<template>
    <expansion-panel :header="$t('Darbotojo paskyros pakeitimų istorija')">
        <v-row ref='container'>
            <v-col cols='12'>
                <hra-table
                    :headers='headers'
                    :rows='rows'
                    hide-headers
                    class='no-border-around'
                    need-actions
                    expand-multiple-row
                    :expand-row-ids.sync='expandIds'
                >
                    <template #col.date='{colValue}'>
                        <div class='pa-3'>{{ colValue }}</div>
                    </template>

                    <template #col.title='{colValue}'>
                        <div class='pa-3'>{{ colValue }}</div>
                    </template>

                    <template #col.adminName='{colValue}'>
                        <div class='pa-3'>{{ colValue }}</div>
                    </template>

                    <template #col.actions-base='{row, expandRow}'>
                        <hra-tooltip :text="$t(row.isExpand ? 'Suskleisti' : 'Išskleisti')">
                            <hra-button
                                :size='buttonSize.small'
                                :color='uiColor.default'
                                :hover-background='!row.isExpand'
                                @click='() => expandRow(row.id)'
                            >
                                <v-fab-transition>
                                    <div :key='`${row.isExpand ? 1 : 0}-expand`'>
                                        <hra-icon :name='row.isExpand ? iconName.up : iconName.down' />
                                    </div>
                                </v-fab-transition>
                            </hra-button>
                        </hra-tooltip>
                    </template>

                    <template #empty.text>{{ $t('Įrašų nėra') }}</template>

                    <template #row-expansion='{row}'>
                        <div class='pa-3'>
                            <hra-table :headers='expandHeaders(row.event, row.needName)' :rows='row.expand' />
                        </div>
                    </template>
                </hra-table>
                <hra-pagination
                    class='mt-3'
                    :current-page='currentPage'
                    :last-page='totalPages'
                    :max-view-pages='limit'
                    @changePage='onChangePage'
                />
            </v-col>
        </v-row>
    </expansion-panel>
</template>

<script>
import ExpansionPanel from '@/domain/components/ExpansionPanel'
import HraTable from '@/components/ui/table/HraTable'
import HraPagination from '@/components/ui/pagination/HraPagination'
import { mapGetters } from 'vuex'
import expansionEmployeeMixin from '@/domain/employee/mixins/expansionEmployeeMixin'
import HraTooltip from '@/components/ui/HraTooltip'
import HraButton from '@/components/ui/button/HraButton'
import HraIcon from '@/components/ui/HraIcon'
import { permissionTexts, roleTexts } from '@/utils/permissions'

export default {
    name: 'ExpansionEmployeeChangelog',
    components: { HraIcon, HraButton, HraTooltip, HraPagination, HraTable, ExpansionPanel },
    mixins: [expansionEmployeeMixin],
    data() {
        return {
            currentPage: 1,
            limit: 15,
            headers: [{ name: 'date' }, { name: 'title' }, { name: 'adminName' }],
            expandIds: []
        }
    },
    computed: {
        ...mapGetters({
            changelogs: 'employeeStore/changelogs'
        }),
        rows() {
            const from = (this.currentPage - 1) * this.limit
            const to = from + this.limit

            const formatValue = (type, id, value) => {
                if (typeof value === 'boolean') {
                    return value ? this.$t('Taip') : this.$t('Ne')
                }

                if (type === 'guards') {
                    if (id === 'role') {
                        return roleTexts[value] ?? value
                    }

                    if (id === 'permissions') {
                        return value.map(name => permissionTexts[name] ?? '').join(', ')
                    }
                }

                return value
            }

            return this.changelogs.slice(from, to).map(item => {
                let expand = []
                const values = item.oldValue ?? item.newValue

                if (Array.isArray(values)) {
                    expand = [{ id: '-', from: item.oldValue?.join(', ') ?? '-', to: item.newValue?.join(', ') ?? '-' }]
                } else {
                    expand = Object.keys(values).map(id => ({
                        id: this.$t(`changelog.${item.type}.${id}`),
                        from: formatValue(item.type, id, item.oldValue ? item.oldValue[id] : ''),
                        to: formatValue(item.type, id, item.newValue ? item.newValue[id] : '')
                    }))
                }


                return {
                    id: item.id,
                    title: this.$t(`changelog.${item.event}.${item.type}`),
                    date: item.createdAt,
                    adminName: item.whoUser?.name ?? '',
                    event: item.event,
                    needName: !Array.isArray(values),
                    expand
                }
            })
        },
        totalPages() {
            return Math.ceil(this.changelogs.length / this.limit)
        }
    },
    methods: {
        expandHeaders(event, needName) {
            let headers = needName ? [{ name: 'id', text: 'Pavadinimas' }] : []

            switch (event) {
                case 'create':
                    return [...headers, { name: 'to', text: '' }]
                case 'update':
                    return [...headers, { name: 'from', text: this.$t('Iš') }, { name: 'to', text: this.$t('Į') }]
                case 'destroy':
                    return [...headers, { name: 'from', text: '' }]
            }

            return headers
        },
        onChangePage(page) {
            this.currentPage = page
            this.$refs.container.scrollIntoView()
        }
    }
}
</script>