<template>
    <div class='sticky-company-tree-content pa-5 pl-7':style='dynamicStyle'>
        <div class='sticky-company-tree-header'>
            <div cols='12' class='pb-5 d-flex justify-end gap-3' v-if='isAdmin'>
                <ui-button
                    prepend-icon='send-email'
                    background='primary'
                    :disabled='tree.leader == null'
                    :modal="{name: 'SentInviteModal'}"
                >{{ $t('Siųsti pakvietimus') }}
                </ui-button>
                <ui-button
                    prepend-icon='plus'
                    background='primary'
                    :modal="{name: 'DepartmentCreateModal', on: {saved: fetchTree}}"
                >{{ $t('Naujas skyrius') }}
                </ui-button>
                <ui-button
                    prepend-icon='info'
                    background='default'
                    @click='onToggleInfo'
                >
                </ui-button>
            </div>
            <message-alert
                v-if='visibleMessage && isAdmin'
                id='Test'
                class='sticky-company-tree-message mb-5'
                :message="$t('<b>Svarbu!!!</b> Pakvietimus darbuotojams siųskite tik tada, kai paruošite įmonės struktūrą. Kurdami įmonės struktūrą nustatysite ryšius tarp skyrių ir nurodysite vadovus, kurie tvirtins atostogas.  Jei vadovo nenurodysite, atostogų tvirtinimas pereis hierarchiškai aukščiau esančiam vadovui (pvz., jei nenurodysite skyriaus vadovo, atostogas tvirtins departamento vadovas). Darbuotojų pakvietimų funkcija taps aktyvi, kai nurodysite aukščiausią įmonės vadovą (generalinis direktorius / CEO). Jei nenurodysite kitų skyrių ar departamentų vadovų, visas atostogas galės tvirtinti tik aukščiausias vadovas.')"
                type='info'
            />
        </div>
        <div class='company-tree' ref='tree'>
            <company-tree-item
                :item='tree'
                is-department
            />
        </div>
    </div>
</template>

<script>
import Avatar from '@/components/ui/Avatar.vue'
import CompanyTreeDepartment from '@/domain/company/components/CompanyTreeDepartment.vue'
import customApi from '@/plugins/customApi'
import CompanyTreeItem from '@/domain/company/components/CompanyTreeItem.vue'
import HraButton from '@/components/ui/button/HraButton.vue'
import HraIcon from '@/components/ui/HraIcon.vue'
import { mapGetters } from 'vuex'
import HraButtonModal from '@/components/ui/button/HraButtonModal.vue'
import UiButton from '@/domain/components/UiButton.vue'
import MessageAlert from '@/domain/messages/components/MessageAlert.vue'
import { permissionOnlyAdmin } from '@/utils/permissions'

export default {
    name: 'CompanyTreePage',
    components: {
        MessageAlert,
        UiButton, HraButtonModal, HraIcon, HraButton, CompanyTreeItem, CompanyTreeDepartment, Avatar
    },
    data() {
        return {
            visibleInfo: false,
            distanceFromTop: 133,
        }
    },
    computed: {
        ...mapGetters({
            tree: 'companyStore/tree',
            selectedCEO: 'companyStore/selectedCEO'
        }),
        visibleMessage() {
            return this.visibleInfo || this.tree.leader == null
        },
        isAdmin() {
            return this.hasPermissionTo(permissionOnlyAdmin)
        },
        dynamicStyle() {
            return {
                position: 'sticky',
                bottom: '0',
                overflow: 'auto',
                height: `calc(100dvh - ${this.distanceFromTop}px)`,
            }
        }
    },
    mounted() {
        setTimeout(() => {
            this.getHeight()
        }, 300)
    },
    beforeCreate() {
        this.$store.dispatch('companyStore/fetchTree')
    },
    provide() {
        return {
            fetchTree: this.fetchTree,
            isAdmin: this.isAdmin
        }
    },
    methods: {
        fetchTree() {
            this.$store.dispatch('companyStore/fetchTree')
        },
        onToggleInfo() {
            if (this.tree.length === 0) {
                return
            }

            this.visibleInfo = !this.visibleInfo
        },
        getHeight() {
            const element = document.querySelector('.sticky-company-tree-content')
            if (element) {
                let rect = element.getBoundingClientRect()
                this.distanceFromTop = rect.top + window.scrollY
            }
        }
    }
}
</script>