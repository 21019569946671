<template>
    <v-row class='document-send-step-container'>
        <v-col cols='12' class='document-send-step-container__title'>
            {{ title }}
        </v-col>
        <slot />
    </v-row>
</template>

<script>
export default {
    name: 'StepContainer',
    props: {
        title: { type: String, required: true }
    }
}
</script>

<style scoped lang='scss'>
.document-send-step-container {
    &__title {
        color: #1C2538;
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }
}
</style>