<template>
    <documents-finder
        :store-namespace='storeNamespace'
        :default-title='$t("Ištrintų dokumentų archyvas")'
        header-sticky
        :header-sticky-top='64'
        with-modes
        with-search
        with-controls
        with-delete-info
    />
</template>

<script>
import store from '@/domain/documents/finder/store/documentsArchivedFinderStore'
import DocumentsFinder from '@/domain/documents/finder/index.vue'
import UiButton from '@/domain/components/UiButton.vue'
import modalMethodsMixin from '@/domain/mixins/modalMethodsMixin'

const storeNamespace = 'documentsArchiveItemsRootStore'

export default {
    name: 'DocumentsArchive',
    components: {
        UiButton,
        DocumentsFinder,
    },
    mixins: [modalMethodsMixin],
    data() {
        return {
            storeNamespace,
            defaultBreadcrumbs: [
                { id: null, title: this.$t('Įmonės dokumentai') },
                { id: null, title: this.$t('Aplankai') }
            ]
        }
    },
    beforeCreate() {
        this.$store.registerModuleSafely(storeNamespace, store)
    },
    beforeDestroy() {
        this.$store.unregisterModuleSafely(storeNamespace)
    },
    mounted() {
        this.$store.dispatch(`${storeNamespace}/load`)
    }
}
</script>