export default {
    methods: {

        // appService() {
        //     const doRequest = async (method, ...props) => {
        //         this.showLoader(this.loaderName);
        //
        //         try {
        //             return await appService[method](...props);
        //         } catch (errorMessage) {
        //             this.showError(errorMessage);
        //         } finally {
        //             this.hideLoader(this.loaderName);
        //         }
        //     };
        //
        //     return {
        //         get: async (...props) => await doRequest("get", ...props),
        //         post: async (...props) => await doRequest("post", ...props),
        //         put: async (...props) => await doRequest("put", ...props),
        //         delete: async (...props) => await doRequest("delete", ...props),
        //     }
        // },


        async axiosRequest(service, ...props) {
            this.showLoader(this.loaderName);

            try {
                return await service.call(this, ...props);
            } catch (error) {
                if (!error.code) {
                    throw error;
                }

                this.showError(error.message);
            } finally {
                this.hideLoader(this.loaderName);
            }
        },

        async axiosRequestWithoutLoader(service, payload = null) {
            try {
                return await service.call(this, payload);
            } catch (error) {
                if (error.code) {
                    throw error;
                }
                this.showError(error.message);
            }
        },
    },
}
