<template>
    <custom-dialog :max-width='900' ref='dialog'>
        <template #title>{{ $t('Atostogų prašymas') }}</template>
        <template #content>
            <loaders :listen='VACATION_MODAL_INIT_TAG' />
            <vacation-modal-form :id='id' :employee='employee' :service='service'>
                <template #buttons>
                    <hra-button :color='uiColor.default' @click='close'>
                        <hra-icon :name='iconName.close' />
                        {{ $t('Uždaryti') }}
                    </hra-button>

                    <!--					<template v-if="editable">-->
                    <hra-button v-if="id === 'new'" @click='onCreate' :color='uiColor.primary'
                                :disabled='disabledSubmit'
                                :loading='buttonLoading'>
                        <hra-icon :name='iconName.ok' />
                        {{ $t('Pridėti') }}
                    </hra-button>
                    <hra-button v-else @click='onChange' :color='uiColor.primary' :disabled='disabledSubmit'
                                :loading='buttonLoading'>
                        <hra-icon :name='iconName.ok' />
                        {{ $t('Redaguoti') }}
                    </hra-button>
                    <!--					</template>-->
                </template>
            </vacation-modal-form>
        </template>
    </custom-dialog>
</template>

<script>
import BaseVacationModal from '@/domain/vacationModal/components/VacationModalForm'
import HraButton from '@/components/ui/button/HraButton'
import HraIcon from '@/components/ui/HraIcon'
import BaseConfirm from '@/domain/confirms/BaseConfirm'
import { mapGetters } from 'vuex'
import {
    VACATION_MODAL_ABORT_TAG,
    VACATION_MODAL_CHANGE_TAG,
    VACATION_MODAL_CREATE_TAG,
    VACATION_MODAL_INIT_TAG, VACATION_MODAL_UPDATE_TAG
} from '@/domain/vacationModal/tags'
import Loaders from '@/domain/loaders/Loaders'
import CustomDialog from '@/components/ui/dialog/CustomDialog'
import VacationModalForm from '@/domain/vacationModal/components/VacationModalForm'
import dialogMixin from '@/mixins/dialogMixin'
import leaderVacationModalService from '@/domain/vacationModal/services/leaderVacationModalService'

export default {
    name: 'LeaderVacationModal',
    components: { VacationModalForm, CustomDialog, Loaders, BaseConfirm, HraIcon, HraButton, BaseVacationModal },
    mixins: [dialogMixin],
    // props: {
    //     id: { type: [Number, String], default: 'new' },
    //     employee: { type: Object, required: true }
    // },
    data() {
        return {
            service: leaderVacationModalService,
            VACATION_MODAL_INIT_TAG
        }
    },
    computed: {
        ...mapGetters({
            // 	editable: "vacationModalStore/editable",
            // 	buttonActions: "vacationModalStore/buttonActions",
            // hasLoading: "loadersStore/hasLoading",
        }),
        disabledSubmit() {
            // return this.errorMessages != null;
        },
        buttonLoading() {
            // return this.$store.getters["loadersStore/hasLoading"](VACATION_MODAL_CREATE_TAG)
            // 	|| this.$store.getters["loadersStore/hasLoading"](VACATION_MODAL_CHANGE_TAG);
        }
    },
    methods: {
        async onCreate() {
            // if (await this.service.create(VACATION_MODAL_CREATE_TAG, this.employee.id)) {
            // 	this.close();
            // }
        },
        async onChange() {
            // if (await this.service.change(VACATION_MODAL_CHANGE_TAG, this.employee.id)) {
            // 	this.close();
            // }
        }
    }
}
</script>