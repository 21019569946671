import {
  INIT_YEARLY_VACATION_CALENDAR,
  INIT_VACATION_CALCULATIONS,
  INIT_VACATIONS, INIT_PLUS_VACATIONS, INIT_CHILD, INIT_DISABILITIES, INIT_CONTRACTS, INIT_CONTRACT_DETAILS
} from "@/domain/debugger/store/mutations";

export const init = ({commit}, payload) => {
  commit(INIT_YEARLY_VACATION_CALENDAR, payload.calendar);
  commit(INIT_VACATION_CALCULATIONS, payload.employee.vacationCalculations);
  commit(INIT_VACATIONS, payload.employee.vacations);
  commit(INIT_PLUS_VACATIONS, payload.employee.plusVacations);
  commit(INIT_CHILD, payload.employee.child);
  commit(INIT_DISABILITIES, payload.employee.disabilities);
  commit(INIT_CONTRACTS, payload.employee.contracts);
  commit(INIT_CONTRACT_DETAILS, payload.employee.contractDetails);
};