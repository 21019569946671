import {
    permissionsButtonEmployeeCreate,
    permissionsButtonEmployeeDelete,
    permissionsButtonEmployeeEdit
} from '@/utils/permissions'

export default {
    computed: {
        isAdmin() {
            return this.hasPermissionTo([
                ...permissionsButtonEmployeeCreate,
                ...permissionsButtonEmployeeEdit,
                ...permissionsButtonEmployeeDelete
            ])
        }
    }
}