<template>
    <v-row class='ml-5 mt-5'>
        <v-col cols='12'>
            <ui-table
                header-sticky
                :header-sticky-top='64'
                :visible-load-more='hasMore'
                :loading='loading'
                :loading-more='loadingMore'
                @clickLoadMore='handleLoadMore'
            >
                <template #head>
                    <tr>
                        <table-header-document-filter
                            :filter='filter.document'
                            @changeFilter='handleChangeFilter'
                        />
                        <table-header-document-status-filter
                            :filter='filter.status'
                            @changeFilter='handleChangeFilter'
                        />
                        <ui-table-head-filter
                            :title='$t("Išsiųsta")'
                            column='sentToUsersCount'
                            :sort-options='filterSortNumberOptions'
                            :value='filter.sentToUsersCount'
                            @changeFilter='handleChangeFilter'
                        />
                        <ui-table-head-filter
                            :title='$t("Nepradėta")'
                            column='sentToUsersNewCount'
                            :sort-options='filterSortNumberOptions'
                            :value='filter.sentToUsersNewCount'
                            @changeFilter='handleChangeFilter'
                        />
                        <ui-table-head-filter
                            :title='$t("Laukiama")'
                            column='sentToUsersPendingCount'
                            :sort-options='filterSortNumberOptions'
                            :value='filter.sentToUsersPendingCount'
                            @changeFilter='handleChangeFilter'
                        />
                        <ui-table-head-filter
                            :title='$t("Patvirtinta")'
                            column='sentToUsersAcceptedCount'
                            :sort-options='filterSortNumberOptions'
                            :value='filter.sentToUsersAcceptedCount'
                            @changeFilter='handleChangeFilter'
                        />
                        <ui-table-head-filter
                            :title='$t("Atmesta")'
                            column='sentToUsersRejectedCount'
                            :sort-options='filterSortNumberOptions'
                            :value='filter.sentToUsersRejectedCount'
                            @changeFilter='handleChangeFilter'
                        />
                        <ui-table-head-filter
                            :title='$t("Atšaukta")'
                            column='sentToUsersAbortedCount'
                            :sort-options='filterSortNumberOptions'
                            :value='filter.sentToUsersAbortedCount'
                            @changeFilter='handleChangeFilter'
                        />
                    </tr>
                </template>

                <template #colgroup>
                    <colgroup>
                        <col style='min-width: 40%;' />
                        <col style='width: 12%' />
                        <col style='width: 8%' />
                        <col style='width: 8%' />
                        <col style='width: 8%' />
                        <col style='width: 8%' />
                        <col style='width: 8%' />
                        <col style='width: 8%' />
                    </colgroup>
                </template>

                <template #body>
                    <tr v-for='(row, key) in rows' :key='key' @click='() => handleClickRow(row)'
                        style='cursor: pointer;'>
                        <td>{{ row.title }}</td>
                        <td><document-status :status='row.status' /></td>
                        <td>{{ row.sentToUsersCount }}</td>
                        <td>{{ row.sentToUsersNewCount }}</td>
                        <td>{{ row.sentToUsersPendingCount }}</td>
                        <td>{{ row.sentToUsersAcceptedCount }}</td>
                        <td>{{ row.sentToUsersRejectedCount }}</td>
                        <td>{{ row.sentToUsersAbortedCount }}</td>
                    </tr>
                </template>
            </ui-table>
        </v-col>
    </v-row>
</template>

<script>
import UiTable from '@/domain/components/uiTable/UiTable.vue'
import CustomApi from '@/plugins/customApi'
import DocumentSentStatus from '@/domain/documents/components/DocumentSentStatus.vue'
import tableFilterMixin from '@/domain/components/uiTable/mixins/tableFilterMixin'
import UiTableHeadFilter from '@/domain/components/uiTable/UiTableHeadFilter.vue'
import modalMethodsMixin from '@/domain/mixins/modalMethodsMixin'
import TableHeaderDocumentFilter from '@/domain/components/uiTable/filters/TableHeaderDocumentFilter.vue'
import TableHeaderDateFilter from '@/domain/components/uiTable/filters/TableHeaderDateFilter.vue'
import TableHeaderDocumentStatusFilter from '@/domain/components/uiTable/filters/TableHeaderDocumentStatusFilter.vue'
import DocumentStatus from '@/domain/documents/components/DocumentStatus.vue'

const customApi = CustomApi()
export default {
    name: 'DocumentSummaryDocumentsPage',
    mixins: [tableFilterMixin, modalMethodsMixin],
    components: {
        DocumentStatus,
        TableHeaderDocumentStatusFilter,
        TableHeaderDateFilter, TableHeaderDocumentFilter, UiTableHeadFilter, DocumentSentStatus, UiTable },
    data() {
        return {
            loading: false,
            loadingMore: false,
            hasMore: false,
            currentPage: 1,
            rows: [],
            filterSettings: {
                sortOnlyOne: false,
                setRouteQuery: true
            },
            filter: {
                document: { sort: 'asc', selected: [] },
                status: { selected: [] },
                sentToUsersCount: { sort: null },
                sentToUsersNewCount: { sort: null },
                sentToUsersPendingCount: { sort: null },
                sentToUsersAcceptedCount: { sort: null },
                sentToUsersRejectedCount: { sort: null },
                sentToUsersAbortedCount: { sort: null }
            }
        }
    },
    created() {
        this.getFilterFromRouteQuery(this.$route.query)
        this.loadData()
    },
    methods: {
        handleClickRow(row) {
            this.onOpenModal('UsersSummaryByDocumentModal', { item: row })
        },
        handleChangeFilter(column, filter) {
            this.setFilter(column, filter)
            this.loadData()
        },
        getRequestParams(page = 1) {
            return {
                page,
                limit: 50,
                ...this.getFilterParams()
            }
        },
        async loadData() {
            this.loading = true
            try {
                await this.fetch(this.getRequestParams(1))
            } catch (error) {

            } finally {
                this.loading = false
            }
        },
        async handleLoadMore() {
            this.loadingMore = true
            try {
                await this.fetch(this.getRequestParams(this.currentPage + 1))
            } catch (error) {

            } finally {
                this.loadingMore = false
            }
        },
        async fetch(params) {
            const {
                data: {
                    items,
                    hasMore,
                    pagination: { currentPage }
                }
            } = await customApi.factories.documents.summary.fetchDocuments(params)

            this.rows = currentPage === 1 ? items : [...this.rows, ...items]
            this.currentPage = currentPage
            this.hasMore = hasMore
        }
    }
}
</script>