import DtvLayout from "@/domain/dtv/layouts/DtvLayout.vue";
import DtvHome from "@/domain/dtv/pages/DtvHome.vue";
import DtvDetail from "@/domain/dtv/pages/DtvDetail.vue";

export default [
    {
        path: "/dk",
        component: DtvLayout,
        children: [
            {name: 'DtvHomePage', path: "", component: DtvHome},
            {name: 'DtvDetailPage', path: ":name", component: DtvDetail},
        ],
    },
]