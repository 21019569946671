<template>
    <page
        with-background
    >
        <div style='position: relative; z-index: 2'>
            <div
                v-if='isEnabledBusinessTripRequestEmployee'
                class='d-flex pt-6' :class='{"px-8": !isBondMobile, "px-6": isBondMobile}'
            >
                <ui-button
                    background='primary'
                    :modal="{name: 'BusinessTripsModal', props: {}, on: {created: this.handleRefresh}}"
                >
                    {{ $t('Komandiruotės prašymas') }}
                </ui-button>
            </div>
            <employee-calendar-header ref='EmployeeCalendarHeader' />
            <div :class='{"px-8": !isBondMobile, "px-6": isBondMobile}'>
                <employee-event-invites @changed='handleRefresh' ref='EmployeeEventInvites' />
            </div>
            <custom-tabs :tabs='tabs' @changeTab='handleChangeTab' :default-tab-name='defaultTab'
                         class='d-flex flex-column mt-4 mt-md-10 pb-12 mb-12'>
                <template #calendar>
                    <events-calendar ref='EventsCalendar' />
                </template>
                <template #history>
                    <employee-event-invites-history @changed='handleRefresh' ref='EmployeeEventInvitesHistory' />
                </template>
            </custom-tabs>
        </div>
    </page>
</template>

<script>
import { mapGetters } from 'vuex'
import isBondMobileMixin from '@/mixins/isBondMobileMixin'
import Page from '@/domain/components/Page.vue'
import WaitingEmpty from '@/domain/employeeVacations/components/WaitingEmpty.vue'
import Widget from '@/domain/employeeVacations/components/Widget.vue'
import WaitingTable from '@/domain/employeeVacations/components/WaitingTable.vue'
import CustomTabs from '@/domain/components/CustomTabs.vue'
import EventsCalendar from '@/domain/employeeVacations/components/EventsCalendar.vue'
import HistoryTable from '@/domain/employeeVacations/components/HistoryTable.vue'
import UiButton from '@/domain/components/UiButton.vue'
import EmployeeEventInvites from '@/domain/eventInvite/EmployeeEventInvites.vue'
import EmployeeEventInvitesHistory from '@/domain/eventInvite/EmployeeEventInvitesHistory.vue'
import EmployeeCalendarHeader from '@/domain/employeeVacations/components/EmployeeCalendarHeader.vue'

export default {
    name: 'EmployeeVacationsPage',
    mixins: [isBondMobileMixin],
    components: { EmployeeCalendarHeader, EmployeeEventInvitesHistory, EmployeeEventInvites, UiButton, HistoryTable, EventsCalendar, CustomTabs, WaitingTable, Widget, WaitingEmpty, Page },
    data() {
        return {
            defaultTab: 'calendar'
        }
    },
    computed: {
        ...mapGetters({
            widgets: 'employeeVacationsStore/widgets',
            approvalsPendingIsEmpty: 'employeeVacationsStore/approvalsPendingIsEmpty',
            userId: 'currentUserStore/userId'
        }),
        tabs() {
            return [
                { name: 'calendar', text: this.$t('Kalendorius') },
                { name: 'history', text: this.$t('Atostogų prašymų istorija') }
            ].filter(({name}) => this.isBondMobile ? name !== 'history' : true)
        },
        isEnabledBusinessTripRequestEmployee() {
            return this.$store.getters['currentUserStore/settings']?.business_trip
                ?.settingBusinessTripEmployeeCanSendRequest?.config.enabled
        },
        maxMonthCount() {
            return this.isBondMobile ? 1 : 5
        },
        subViewMonth() {
            return this.isBondMobile ? 0 : 1
        }
    },
    provide() {
        return {
            rootRefresh: this.handleRefresh
        }
    },
    created() {
        this.load()
        this.$root.$on('EmployeeCalendarRefresh', this.handleRefresh)
    },
    mounted() {
        window.addEventListener('resize', this.updateScreenWidth)
    },
    beforeDestroy() {
        this.$root.$off('EmployeeCalendarRefresh', this.handleRefresh)
    },
    methods: {
        handleRefresh() {
            console.debug('EmployeeCalendarRefresh')
            this.$refs.EmployeeCalendarHeader?.fetch()
            this.$refs.EmployeeEventInvitesHistory?.fetchInvites()
            this.$refs.EmployeeEventInvites?.fetchInvites()
            this.$refs.EventsCalendar?.refresh()
        },
        load() {
            Promise.all([
                this.$store.dispatch('employeeVacationsStore/setUserId', this.userId)
            ]).then(() => {
            })
        },
        handleChangeTab(tab) {
            this.$store.dispatch('employeeVacationsStore/changeCurrentTab', tab)
        }
    }
}
</script>