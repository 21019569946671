import FolderCreateModal from '@/domain/documents/modals/items/folder/FolderCreateModal.vue'
import FolderUpdateModal from '@/domain/documents/modals/items/folder/FolderUpdateModal.vue'
import FolderDeleteModal from '@/domain/documents/modals/items/folder/FolderDeleteModal.vue'

import DocumentUploadModal from '@/domain/documents/modals/items/document/DocumentUploadModal.vue'
import DocumentEditModal from '@/domain/documents/modals/items/document/DocumentEditModal.vue'
import DocumentPreviewModal from '@/domain/documents/modals/items/document/DocumentPreviewModal.vue'
import DocumentMoveModal from '@/domain/documents/modals/items/document/DocumentMoveModal.vue'
import DocumentsSelectModal from '@/domain/documents/modals/items/document/DocumentsSelectModal.vue'
import DocumentArchiveModal from '@/domain/documents/modals/items/document/DocumentArchiveModal.vue'
import DocumentRestoreModal from '@/domain/documents/modals/items/document/DocumentRestoreModal.vue'
import DocumentWipeModal from '@/domain/documents/modals/items/document/DocumentWipeModal.vue'

import GroupsFolderUpdateModal from '@/domain/documents/modals/items/groupsFolder/GroupsFolderUpdateModal.vue'
import GroupsFolderDeleteModal from '@/domain/documents/modals/items/groupsFolder/GroupsFolderDeleteModal.vue'
import GroupsFolderCreateModal from '@/domain/documents/modals/items/groupsFolder/GroupsFolderCreateModal.vue'
import GroupCreateModal from '@/domain/documents/modals/items/group/GroupCreateModal.vue'
import GroupUpdateModal from '@/domain/documents/modals/items/group/GroupUpdateModal.vue'
import GroupDeleteModal from '@/domain/documents/modals/items/group/GroupDeleteModal.vue'
import DocumentSignModal from '@/domain/documents/modals/sign/DocumentSignModal.vue'
import DocumentSignCommentModal from '@/domain/documents/modals/sign/DocumentSignCommentModal.vue'
import DocumentHistoryModal from '@/domain/documents/modals/items/document/DocumentHistoryModal.vue'
import DocumentsSelectUploadDirModal from '@/domain/documents/modals/items/document/DocumentsSelectUploadDirModal.vue'
import DocumentSendModal from '@/domain/documents/modals/items/documentSendModal'
import DocumentSentUserPreviewModal from '@/domain/documents/modals/items/document/DocumentSentUserPreviewModal.vue'
import DocumentsSummaryByUserModal from '@/domain/documents/modals/summary/DocumentsSummaryByUserModal.vue'
import UsersSummaryByDocumentModal from '@/domain/documents/modals/summary/UsersSummaryByDocumentModal.vue'
import HistoryOfDocumentSentUserModal from '@/domain/documents/modals/summary/HistoryOfDocumentSentUserModal/index.vue'

export default ({
    FolderCreateModal,
    FolderUpdateModal,
    FolderDeleteModal,

    DocumentSendModal,
    DocumentsSelectModal,
    DocumentUploadModal,
    DocumentEditModal,
    DocumentPreviewModal,
    DocumentSentUserPreviewModal,
    DocumentMoveModal,
    DocumentArchiveModal,
    DocumentRestoreModal,
    DocumentWipeModal,
    DocumentHistoryModal,

    GroupsFolderCreateModal,
    GroupsFolderUpdateModal,
    GroupsFolderDeleteModal,

    GroupCreateModal,
    GroupUpdateModal,
    GroupDeleteModal,

    DocumentSignModal,
    DocumentSignCommentModal,
    DocumentsSelectUploadDirModal,

    UsersSummaryByDocumentModal,
    DocumentsSummaryByUserModal,
    HistoryOfDocumentSentUserModal
})