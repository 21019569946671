<template>
    <select-field
        :label='label'
        filtered
        :options-service='findUser'
        v-model='userId'
    />
</template>

<script>
import SelectField from '@/domain/fields/SelectField.vue'
import customApi from '@/plugins/customApi'

export default {
    name: 'UserAutocompleteField',
    components: { SelectField },
    props: {
        label: {type: String, default: ''},
        clearUserAfterSelect: { type: Boolean, default: false }
    },
    data: function() {
        return {
            users: [],
            userId: null
        }
    },
    watch: {
        userId: {
            handler: function(value) {
                value != null && this.selectedUser(value)
            },
            immediate: true
        }
    },
    methods: {
        async findUser(tag, query) {
            const api = customApi(tag)
            this.users = await api.request(api.factories.user.autocomplete(query)) ?? []

            return this.users.map(user => ({ value: user.id, text: user.name }))
        },
        selectedUser(id) {
            this.$emit('change', this.users.find(user => user.id === id))

            if (this.clearUserAfterSelect) {
                this.userId = null
            }
        }
    }
}
</script>