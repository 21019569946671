var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"name":_vm.$attrs.name,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',{class:_vm.fieldClasses.concat( classes)},[_c('div',{class:(_vm.baseClassName + "__label")},[_c('label',[_vm._v(_vm._s(_vm.translateLabel))]),_vm._t("right-label")],2),_c('v-autocomplete',{class:("border__" + (errors.length ? 'red' : 'gray_300')),attrs:{"items":_vm.items,"placeholder":_vm.translatePlaceholder,"multiple":_vm.multiple,"loading":_vm.loading,"solo":"","chips":"","flat":"","single-line":"","persistent-placeholder":"","persistent-hint":"","menu-props":{
                  maxWidth: 'min-content',
                  padding: 0
              },"disabled":_vm.disabled,"search-input":_vm.search,"hide-details":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('hra-icon',{attrs:{"name":_vm.iconName.find}})]},proxy:true},{key:"selection",fn:function(ref){
              var item = ref.item;
return [_vm._t("selection",function(){return [_c('div',{domProps:{"innerHTML":_vm._s(item.text)}})]},{"item":item})]}},{key:"item",fn:function(ref){
              var item = ref.item;
return [_vm._t("item",function(){return [_c('EmployeeTableView',{staticClass:"py-1",attrs:{"employee":item.employee}})]},{"item":item})]}},{key:"no-data",fn:function(){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"pa-1"},[_c('FormAlert',{attrs:{"type":"error","text":"Empty"}})],1)]},proxy:true}],null,true),model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}})],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }