<template>
    <div class='vacation-period-view' :class="clickable ? 'clickable' : ''" v-on='$listeners'>
        <div
            class='vacation-period-view__period'
            v-html='$t("<b>{fromDate}</b> iki <b>{toDate}</b>", {fromDate, toDate})'
        />
        <div v-if='title' class='vacation-period-view__title'>{{ title }}</div>
    </div>
</template>

<script>
export default {
    name: 'VacationPeriodView',
    props: {
        fromDate: { type: String, required: true },
        toDate: { type: String, required: true },
        title: { type: String, default: null },
        clickable: { type: Boolean, default: false }
    }
}
</script>
