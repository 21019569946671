<template>
	<hra-table
		class="pa-3"
		:headers="headers"
		:rows="rows"
		expand-multiple-row
		:expand-row-ids.sync="expandRowIds"
		need-actions
	>
		<template #col.timeCardNumber="{colValue}">
			<div class="px-3">{{colValue}}</div>
		</template>
		<template #col.departments="{colValue}">
			<div class="px-3">{{colValue.join(', ')}}</div>
		</template>

		<template #col.duties="{colValue}">
			<div class="px-3">{{colValue.join(', ')}}</div>
		</template>

		<template #col.actions-base="{row, expandRow}">
			<div class="d-flex justify-end align-center gap-3">
				<hra-tooltip v-if="row.invalid" :text="'test error...'">
					<hra-icon :name="iconName.info" :color="uiColor.danger" />
				</hra-tooltip>
				<hra-tooltip :text="$t(row.isExpand ? 'Suskleisti' : 'Išskleisti')">
					<hra-button
						:size="buttonSize.small"
						:color="uiColor.default"
						:hover-background="!row.isExpand"
						@click="() => expandRow(row.id)"
					>
						<v-fab-transition>
							<div :key="`${row.isExpand ? 1 : 0}-expand`">
								<hra-icon :name="row.isExpand ? iconName.up : iconName.down"/>
							</div>
						</v-fab-transition>
					</hra-button>
				</hra-tooltip>
			</div>
		</template>

		<template #row-expansion="{row}">
			<div class="pa-3">
				<employee-expand-table :id="row.id" :validated="row.validated" />
			</div>
		</template>
	</hra-table>
</template>

<script>
import HraTable from "@/components/ui/table/HraTable.vue";
import {mapGetters} from "vuex";
import HraTooltip from "@/components/ui/HraTooltip.vue";
import HraButton from "@/components/ui/button/HraButton.vue";
import HraIcon from "@/components/ui/HraIcon.vue";
import EmployeeExpandTable from "@/domain/setup/components/tables/EmployeeExpandTable.vue";

export default {
	name: "EmployeesTable",
	components: {EmployeeExpandTable, HraIcon, HraButton, HraTooltip, HraTable},
	data() {
		return {
			headers: [
				{name: "timeCardNumber", text: this.$t("Tabelio nr."), style: {minWidth: "120px", maxWidth: "120px"}},
				{name: "number", text: this.$t("Darbo sutarties nr."), style: {minWidth: "150px", maxWidth: "150px"}},
				{name: "name", text: this.$t("Vardas, Pavardė"), style: {minWidth: "300px"}},
				{name: "email", text: this.$t("El. paštas"), style: {minWidth: "300px"}},
				{name: "departments", text: this.$t("Skyriai"), style: {minWidth: "150px"}},
				{name: "duties", text: this.$t("Pareigos"), style: {minWidth: "150px"}},
				{name: "brithDate", text: this.$t("Gimimo data"), style: {minWidth: "100px"}},
			],
			expandRowIds: [],
		}
	},
	computed: {
		...mapGetters({
			rows: "setupStore/importEmployees",
		}),
	},
}
</script>