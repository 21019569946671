<template>
    <vue-draggable-resizable
        v-if='w > 0'
        ref='event'
        :class-name='className'
        class-name-handle='schedule-timetable-event__handle'
        :prevent-deactivation='true'
        :handles='[]'
        :grid='grid'
        :w='w'
        :h='h'
        :x='x'
        :y='y'
        :draggable='isDraggable'
        @dragging='onDragging'
        @dragstop='onDragStop'
    >
        <div class='schedule-timetable-event__content'>
            <div v-if='!eventOnlyDaysGrid' class='schedule-timetable-event__times'>
                <div class='schedule-timetable-event__start'>{{ eventStartFormatted }}</div>
                <div class='schedule-timetable-event__end'>{{ eventEndFormatted }}</div>
            </div>

            <div v-if='eventOnlyDaysGrid' class='schedule-timetable-event__content-text text-wrap'>{{ text }}</div>

            <div v-if='hasError' class='schedule-timetable-event__error'>
                <v-tooltip top>
                    <template #activator='{attrs, on}'>
                        <div
                            v-bind='attrs'
                            v-on='on'
                            class='schedule-timetable-event__error-dot'
                        />
                    </template>
                    <span>{{ $t("Min users error") }}</span>
                </v-tooltip>
            </div>

            <context-menu v-if='isAdmin'>
                <context-menu-item
                    v-if='isDeletable'
                    @click.prevent='onEdit'
                >
                    {{ $t('Redaguoti') }}
                </context-menu-item>
                <context-menu-item v-if='isDeletable' @click.prevent='onDelete'>{{ $t('Ištrinti') }}</context-menu-item>
            </context-menu>
        </div>
    </vue-draggable-resizable>
</template>

<script>
import ContextMenu from '@/domain/components/ContextMenu.vue'
import ContextMenuItem from '@/domain/components/ContextMenuItem.vue'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { SCHEDULE_TAB_EDIT_TIMETABLE } from '@/domain/scheduler/store/state'
import scheduleEventMixin from '@/domain/scheduler/mixins/scheduleEventMixin'
import moment from 'moment/moment'
import HraTooltip from '@/components/ui/HraTooltip.vue'

export default {
    name: 'ScheduleEvent',
    components: { HraTooltip, ContextMenuItem, ContextMenu },
    mixins: [scheduleEventMixin],
    props: {
        event: { type: Object, required: true },
        dayWidth: { type: Number, required: true },
        rowHeight: { type: Number, required: true },
        onePixelToSecond: { type: Number, required: true },
        start: { type: Object, required: true },
        eventOnlyDaysGrid: { type: Boolean, default: false },
        grid: { type: Array, default: [1, 1] },
        maxX: { type: Number, default: null },
        maxY: { type: Number, default: null }
    },
    computed: {
        ...mapGetters({
            visibleBreaksIds: 'schedulerStore/visibleBreaksIds',
            currentTab: 'schedulerStore/currentTab'
        }),
        isDraggable() {
            return this.currentTab === SCHEDULE_TAB_EDIT_TIMETABLE && this.isAdmin
        },
        isDeletable() {
            return true
        },
        className() {
            const baseClass = 'schedule-timetable-event'
            const additionalClass = this.eventOnlyDaysGrid ? 'schedule-timetable-event_day-view' : 'schedule-timetable-event_hour-view'

            return [baseClass, additionalClass].join(' ')
        },
        eventStartFormatted() {
            return this.eventStartXMoment.format('HH:mm')
        },
        eventEndFormatted() {
            const duration = this.event.duration.seconds
            return this.eventStartXMoment.clone().add('seconds', duration).format('HH:mm')
        },
        text() {
            const duration = this.event.duration.seconds
            let start = this.start.clone().add('seconds', this.x / this.onePixelToSecond)

            if (this.eventOnlyDaysGrid) {
                const eventStart = moment(this.event.startWithTime)
                start.set('hour', eventStart.hour())
                    .set('minute', eventStart.minute())
                    .set('second', eventStart.second())
            }

            return start.format('HH:mm') + ' ' + start.add('seconds', duration).format('HH:mm')
        },
        hasError() {
            return !this.event.hasMinUsers
        }
    },
    methods: {
        onEdit() {
            this.$store.dispatch('modals/open', {
                name: 'UpdateScheduleTimetableModal',
                props: {
                    payload: this.event
                }
            })
        },
        onDelete() {
            this.$store.dispatch('schedulerStore/deleteTimetableById', this.event.id)
        }
    }
}
</script>

<style lang='scss' scoped>
@import '@/assets/sass/core/colors';

.schedule-timetable-event {
    position: absolute;
    color: $white;
    z-index: 3 !important;

    &_other-event {
        .schedule-event__content {
            background-color: $schedule-color-others2;
        }
    }

    &:hover {
        z-index: 10 !important;
    }

    &__error--tooltip {
    }

    &__error {
        position: absolute;
        right: 4px;
        top: 4px;

        &-dot {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: $red;
            box-shadow: 0 0 0 1px $white;
        }
    }

    &__content {
        position: absolute;
        background-color: $schedule-color-event;
        z-index: -1;

        &:hover {
            box-shadow: 0 0 0 1px #0b0d21;
        }
    }

    &__times {
        top: -15px;
        display: flex;
        justify-content: space-between;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        gap: 5px;
        min-width: calc(100% - 2px);
    }

    &__start, &__end {
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 0.3px;
    }

    &__content-header {
        width: fit-content;
        display: flex;
        padding: 0 5px;
        text-align: center;
        align-items: center;
        background: #ffffff80;
    }

    &__content-text {
        display: flex;
        padding: 1px 5px;
        height: 100%;
        width: 100%;
        align-items: center;
        text-align: center;
        justify-content: center;
        font-size: 12px;
        line-height: 1.4;
        font-weight: 600;
    }

    &_day-view &__content {
        inset: 4px;
        flex-direction: column;
        border-radius: 4px;
    }

    &_day-view &__content-header {
        width: 100%;
        justify-content: center;
    }

    &_hour-view {
        color: $greyscale-800;
    }

    &_hour-view &__content{
        inset: 25px 0 2px;
        height: 26px;
        border-radius: 4px;
    }

    &.draggable {
        cursor: move;
    }
}
</style>