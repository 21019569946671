import {getRequest, postRequest, deleteRequest} from "@/utils/request";

const baseEndPoint = "/api/auth";

export default {
    user: {
        // confirmPasswordStatus: async () => await getRequest(`${baseEndPoint}/user/confirmed-password-status`),
        getQrCode: async () => await getRequest(`${baseEndPoint}/user/qr-code`),
        confirm2fa: async (code) => await postRequest(`${baseEndPoint}/user/enable/2fa`, {code}),
        // confirmPassword: async (password) => await postRequest(`${baseEndPoint}/user/confirm-password`, {password}),
        // enableTwoFactorAuth: async () => await postRequest(`${baseEndPoint}/user/two-factor-authentication`),
        // disableTwoFactorAuth: async () => await deleteRequest(`${baseEndPoint}/user/two-factor-authentication`),
        // confirmTwoFactorAuth: async () => await deleteRequest(`${baseEndPoint}/user/confirmed-two-factor-authentication`),
    },

    login: async (payload) => await postRequest(`${baseEndPoint}/login`, payload, true),
    twoFactor: async (code) => await postRequest(`${baseEndPoint}/two-factor-challenge`, {code}, true),
    logout: async () => await postRequest(`${baseEndPoint}/logout`),
    forgotPassword: async (email) => await postRequest(`${baseEndPoint}/forgot-password`, {email}),
    resetPassword: async (payload) => await postRequest(`${baseEndPoint}/reset-password`, payload),
    getInviteDate: async (token) => await getRequest(`${baseEndPoint}/invite/data/${token}`),
    register: async (payload) => await postRequest(`${baseEndPoint}/register`, payload),
}
