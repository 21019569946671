<template>
	<div v-if="editable" class="EditableFieldActions">
		<loaders :listen="fieldTag" size="18"/>
		<i v-show="!hasLoading && editing" class="icon-close EditableFieldActions__icon hra-text--red" @click.prevent="onAbort"/>
		<i v-show="!hasLoading && editing" class="icon-ok EditableFieldActions__icon hra-text--green" @click.prevent="onSave"/>
		<i v-show="!hasLoading && !editing" class="icon-edit EditableFieldActions__icon hra-text--black-800"
		   @click.prevent="onEdit"/>
	</div>
</template>

<script>
import Loaders from "@/domain/loaders/Loaders";

export default {
	name: "EditableFieldActions",
	components: {Loaders},
	inject: ["editable", "field", "fieldTag", "onEdit", "onAbort", "onSave"],
	computed: {
		editing() {
			return this.field.editing;
		},
		hasLoading() {
			return this.$store.getters["loadersStore/hasLoading"](this.fieldTag);
		},
	},
}
</script>

<style lang="scss">
.EditableFieldActions {
	margin-left: auto;
	display: flex;
	gap: 10px;

	i {
		font-size: 12px;
		cursor: pointer;
	}
}
</style>