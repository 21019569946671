<template>
    <expansion-panel :header="$t('Kontaktai')">
        <v-row>
            <v-col cols='6'>
                <text-field
                    name='email'
                    rules='required|email'
                    :label="$t('El. paštas')"
                    :editable='isEdit'
                    :disabled='isProfile'
                    v-model='user.email'
                    @save='onUpdateUserEmail'
                />
            </v-col>
            <v-col cols='6'>
                <text-field
                    name='phone'
                    :label="$t('Darbo telefono nr.')"
                    :editable='isEdit'
                    :disabled='isProfile'
                    @save='onUpdateUser'
                    v-model='user.phone'
                />
            </v-col>
            <v-col cols='6'>
                <text-field
                    name='phone'
                    :label="$t('Asmeninis telefono nr.')"
                    :editable='isEdit'
                    :disabled='isProfile'
                    @save='onUpdateContact'
                    v-model='contacts.phone'
                />
            </v-col>
            <v-col cols='6'>
                <text-field
                    name='personalEmail'
                    :label="$t('Asmeninis el. paštas.')"
                    :editable='isEdit'
                    :disabled='isProfile'
                    @save='onUpdateContact'
                    v-model='contacts.email'
                />
            </v-col>
            <v-col cols='12'>
                <text-field
                    name='address'
                    :label="$t('Gyvenamosios vietos gatvė ir namo nr.')"
                    :editable='isEdit'
                    :disabled='isProfile'
                    @save='onUpdateContact'
                    v-model='contacts.address'
                />

            </v-col>
            <v-col cols='6'>
                <text-field
                    name='city'
                    :label="$t('Miestas')"
                    :editable='isEdit'
                    :disabled='isProfile'
                    @save='onUpdateContact'
                    v-model='contacts.city'
                />
            </v-col>
            <v-col cols='6'>
                <text-field
                    name='country'
                    :label="$t('Valstybė')"
                    :editable='isEdit'
                    :disabled='isProfile'
                    @save='onUpdateContact'
                    v-model='contacts.country'
                />
            </v-col>
        </v-row>
    </expansion-panel>
</template>

<script>
import { mapGetters } from 'vuex'
import ExpansionPanel from '@/domain/components/ExpansionPanel'
import TextField from '@/domain/fields/TextField'
import DatepickerField from '@/domain/fields/DatepickerField'
import expansionEmployeeMixin from '@/domain/employee/mixins/expansionEmployeeMixin'

export default {
    name: 'ExpansionEmployeeContact',
    components: { DatepickerField, TextField, ExpansionPanel },
    mixins: [expansionEmployeeMixin],
    computed: {
        ...mapGetters({
            user: 'employeeStore/user',
            contacts: 'employeeStore/contacts'
        })
    },
    methods: {
        async onUpdateUser({ fieldTag, name, value, saveComplete }) {
            if (await this.$store.dispatch('employeeStore/updateUser', {
                tag: fieldTag,
                id: this.user.id,
                payload: { [name]: value }
            })) {
                saveComplete()
            }
        },
        async onUpdateUserEmail({ fieldTag, name, value, saveComplete }) {
            if (await this.$store.dispatch('employeeStore/updateUserCustom', {
                tag: fieldTag,
                id: this.user.id,
                param: 'email',
                payload: { [name]: value }
            })) {
                saveComplete()
            }
        },
        async onUpdateContact({ fieldTag, name, value, saveComplete }) {
            if (name === 'personalEmail') {
                name = 'email'
            }

            if (await this.$store.dispatch('employeeStore/updateContact', {
                tag: fieldTag,
                id: this.user.id,
                payload: { [name]: value }
            })) {
                saveComplete()
            }
        }
    }
}
</script>