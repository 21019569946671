import {asyncTimeout} from "@/utils/default";

const state = () => ({
  loading: false,
  eventLoading: false,
  data: [],
  message: null,
});

const getters = {
  loading(state) {
    return state.loading;
  },
  eventLoading(state) {
    return state.eventLoading;
  },
  data(state) {
    return state.data;
  },
  message(state) {
    return state.message;
  },
};

const mutations = {
  hasLoading(state) {
    state.loading = true;
  },
  hasLoaded(state) {
    state.loading = false;
  },
  hasEventLoading(state) {
    state.eventLoading = true;
  },
  hasEventLoaded(state) {
    state.eventLoading = false;
  },
  setData(state, payload) {
    state.data = payload;

    if (payload.status < 0) {
      state.message = {
        type: "error",
        text: "Prasymas yra atsauktas"
      };
    }

    if (payload.status > 0) {
      state.message = {
        type: "success",
        text: "Prasymas yra jau patvirtintas"
      };
    }
  },
  setMessage(state, payload) {
    state.message = payload;
  },
  clear(state) {
    state.data = [];
    state.message = null;
  },
};

const actions = {
  async fetch({commit, state}, {id}) {
    commit("hasLoading");
    try {
      // const {data} = await this._vm.$api.employee.getAll({page, limit: state.limit});

      const data = {
        id,
        employee: {name: "Stephanie Cook", duties: ['Programmer'], avatar: null},
        period: {fromDate: "2022-06-20", toDate: "2022-07-20"},
        days: {work: 5, calendar: 7},
        type: "Kasmetinės atostogos",
        deputy: {
          name: "Vardenis Pavardenis",
          status: 1,
          at: "2020-12-01  14:23"
        },
        canUseDays: 4.75,
        sentAt: "2022-06-18",
        useDays: false,
        message: "Dėl pavadavimo su Petru susiderinau. Lauksiu atsakymo!",
        status: 1,
      };

      commit("setData", data);
    } catch (error) {
      
    } finally {
      commit("hasLoaded");
    }
  },
  async abort({commit, dispatch}, {id, message}) {
    commit("hasEventLoading");
    
    await asyncTimeout(600);
    commit("vacation/departmentNotApprove/removeRow", id, {root: true});
    commit("setMessage", {type: "success", text: "Prasymas atmestas"});
    commit("hasEventLoaded");

  },
  async accept({commit, dispatch}, {id, message}) {
    commit("hasEventLoading");
    
    await asyncTimeout(600);
    commit("vacation/departmentNotApprove/removeRow", id, {root: true});
    commit("setMessage", {type: "success", text: "Prasymas patvirtintas"});
    commit("hasEventLoaded");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
