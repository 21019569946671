<template>
    <validation-observer v-slot='{invalid}' ref='validator'>
        <employee-template>
            <template #append-profile>
                <div class='d-flex flex-column gap-3 pa-3' style='width: 100%;'>
                    <form-alert v-if='invalid' type='error' :text="$t('Raudonai pažymėti laukai yra privalomi')" />
                    <message-alerts :listen='[createTag]' />
                    <div class='d-flex align-content-space-between gap-3'>
                        <hra-button-link full-width :to='{name: employeesPageName}'>
                            <template #icon>
                                <hra-icon :name='iconName.close' />
                            </template>
                            {{ $t('Atšaukti') }}
                        </hra-button-link>
                        <hra-button
                            full-width
                            :color='uiColor.success'
                            :loading='createLoading'
                            :disabled='invalid'
                            @click='onCreate'
                        >
                            <template #icon>
                                <hra-icon :name='iconName.ok' />
                            </template>
                            {{ $t('Išsaugoti') }}
                        </hra-button>
                    </div>
                </div>
            </template>
            <template #panels>
                <expansion-panels :value='panels'>
                    <expansion-employee-base is-create />
                    <expansion-employee-contact is-create />
                    <expansion-employee-contract is-create />
                    <expansion-employee-permissions is-create />
                </expansion-panels>
            </template>
        </employee-template>
    </validation-observer>
</template>

<script>
import MessageAlerts from '@/domain/messages/MessageAlerts.vue'
import Form from '@/components/ui/form/Form.vue'
import HraIcon from '@/components/ui/HraIcon.vue'
import HraButtonLink from '@/components/ui/button/HraButtonLink.vue'
import FormAlert from '@/components/ui/form/FormAlert.vue'
import HraButton from '@/components/ui/button/HraButton.vue'
import EmployeeProfileDescription from '@/domain/employee/components/EmployeeProfileDescription.vue'
import ExpansionEmployeePermissions from '@/domain/employee/components/ExpansionEmployeePermissions.vue'
import ExpansionEmployeeChangelog from '@/domain/employee/components/ExpansionEmployeeChangelog.vue'
import ExpansionEmployeeContract from '@/domain/employee/components/ExpansionEmployeeContract.vue'
import ExpansionEmployeeVacations from '@/domain/employee/components/ExpansionEmployeeVacations.vue'
import ExpansionEmployeeContact from '@/domain/employee/components/ExpansionEmployeeContact.vue'
import EmployeeTemplate from '@/domain/employee/components/EmployeeTemplate.vue'
import ExpansionEmployeeBase from '@/domain/employee/components/ExpansionEmployeeBase.vue'
import ExpansionPanels from '@/domain/components/ExpansionPanels.vue'
import Page from '@/domain/components/Page.vue'
import { ValidationObserver } from 'vee-validate'
import { appRouteProps } from '@/router/app'
import { mapGetters } from 'vuex'
import customApi from '@/plugins/customApi'
import { GLOBAL_TOAST_TAG, jsonToFormData } from '@/utils/default'
import i18n from '@/plugins/i18n'

export default {
    name: 'EmployeeCreateForm', components: {
        MessageAlerts,
        Form,
        HraIcon,
        HraButtonLink,
        FormAlert,
        HraButton,
        EmployeeProfileDescription,
        ExpansionEmployeePermissions,
        ExpansionEmployeeChangelog,
        ExpansionEmployeeContract,
        ExpansionEmployeeVacations,
        ExpansionEmployeeContact,
        EmployeeTemplate,
        ExpansionEmployeeBase,
        ExpansionPanels,
        Page,
        ValidationObserver
    },
    data() {
        const createTag = 'EMPLOYEE_CREATE_PAGE_REQUEST'

        return {
            panels: [0, 1, 2, 3],
            createTag,
            employeesPageName: appRouteProps.employees.name
        }
    },
    computed: {
        ...mapGetters({
            hasLoading: 'loadersStore/hasLoading'
        }),
        createLoading() {
            return this.hasLoading(this.createTag)
        }
    },
    mounted() {
        this.$refs.validator.validate()
    },
    methods: {
        async onCreate() {
            const payload = {
                user: this.$store.getters['employeeStore/user'],
                contract: this.$store.getters['employeeStore/contract'],
                contact: this.$store.getters['employeeStore/contacts'],
                children: this.$store.getters['employeeStore/children'],
                disabilities: this.$store.getters['employeeStore/disabilities'],
                avatar: this.$store.getters['employeeStore/user'].avatarSrc,
                role: this.$store.getters['employeeStore/userRole']
            }

            const api = customApi(this.createTag)
            const response = await api.request(api.factories.employee.create(jsonToFormData(payload)))
            if (response == null) {
                return
            }

            await this.$store.dispatch('loadersStore/hideLoader', this.createTag)
            await this.$store.dispatch("messagesStore/showSuccessMessage", {
                tag: GLOBAL_TOAST_TAG,
                message: i18n.t("Darbuotojas sukurtas sėkmingai"),
            });
            await this.$router.push({name: appRouteProps.employees.name});
        }
    }
}
</script>