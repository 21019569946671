<!-- TODO: validator... -->
<template>
    <div :class="['form-file-button', ]">
        <label>
            <slot :onClick="onClick" :loading="loading"/>
            <input ref="file" type="file" :name="$attrs.name" :accept="accept" @change.prevent="onChange"/>
        </label>
    </div>
</template>

<script>
import {ValidationProvider} from "vee-validate";

export default {
    components: {ValidationProvider},
    props: {
        rules: {
            type: [Object, String],
            default: "",
        },
        value: {
            type: null,
        },
        accept: {
            type: String,
            default: "image/png, image/jpeg",
        },
    },
    data: () => ({
        loading: false,
    }),
    methods: {
        onClick() {
            // this.$refs.file.dispatchEvent(new Event("click"));
            this.$refs.file.click();
        },
        async onChange({target: {files}}) {
            this.$emit("input", files[0]);
        },
    },
    watch: {
        value(avatarSrc) {
            if (typeof avatarSrc === "string") {
                this.avatarSrc = avatarSrc;
            }
        },
    },
    created() {
        this.avatarSrc =
            this.value && typeof this.value === "string" ? this.value : null;
    },
}
</script>
