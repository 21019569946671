<template>
	<v-layout flex-column class="import">
		<Header class="px-3">
			<template #left>
				<text-decorate>{{ $t('Darbuotojai') }} ({{ total }})</text-decorate>
				<hra-button :disabled="rowIsEdit || storing" :color="uiColor.successLight" :size="buttonSize.small"
				            @click.prevent="onCreateEmptyRow"
				>{{ $t("Pridėti darbuotoją") }}
				</hra-button>
				<hra-button-modal :disabled="rowIsEdit || storing" :color="uiColor.primaryLight" :size="buttonSize.small"
				                  modal-name="ImportEmployeeModal"
				>{{ $t("Įkelti „MS Excel“ dokumentą") }}
				</hra-button-modal>
			</template>
			<template #right>
				<div class="d-flex gap-3" ref="header">
					<hra-confirm :title="$t('Ar tikrai norite atšaukti?')"
					             :abort-text="$t('Ne')"
					             :confirm-text="$t('Taip')"
					             @confirm="onClose($event)"
					>
						<hra-button :disabled="storing"
						            :color="uiColor.default" :size="buttonSize.small"
						>{{ $t("Atšaukti") }}
						</hra-button>
					</hra-confirm>
					<hra-button :disabled="haveInvalidRow" :loading="storing"
					            :color="uiColor.primary" :size="buttonSize.small" @click="onNextStep"
					>{{ $t("Toliau") }}
					</hra-button>
					<hra-button :disabled="haveInvalidRow" :loading="storing"
					            :color="uiColor.primary" :size="buttonSize.small" @click="onNextStep2"
					>{{ $t("Toliau") }} 2
					</hra-button>
				</div>
			</template>
		</Header>
		<div class="import__container">
			<import-employees-manage-table
				ref="table"
				:headers="headers"
				:rows="pageRows"
				:loading="tableLoading"
			/>
			<hra-pagination class="sticky-content-left ma-3"
			                :current-page="page" :last-page="pages" @changePage="onChangePage"/>
		</div>
		<hra-confirm :title="$t('Jau turite įkeltų darbuotojų')" ref="welcomeDialog"
		             :confirm-text="$t('Pildyti is naujo')" :abort-text="$t('Grižti į sarašą')" @confirm="onClear($event)">
		</hra-confirm>
	</v-layout>
</template>


<script>
import {appRouteProps} from "@/router/app";
import importService from "@/services/employees/employeeImportService";
import importEmployeeMixin from "@/mixins/importEmployeeMixin";

export default {
	name: "ImportEmployeeManage",
	mixins: [importEmployeeMixin],
	mounted() {
		if (this.total > 0) {
			this.$refs.welcomeDialog?.openConfirm();
		}
	},
	methods: {
		async onNextStep() {
			this.storing = true;

			if (await importService.store()) {
				this.storing = false;
				await this.$router.push({name: appRouteProps.companyStructure.name});
				return;
			}

			this.storing = false;
		},
		async onNextStep2() {
			this.storing = true;

			if (await importService.store2()) {
				this.storing = false;
				await this.$router.push({name: appRouteProps.companyStructure.name});
				return;
			}

			this.storing = false;
		},
	},
};
</script>
