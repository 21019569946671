const SIDEBAR_ROW_TYPE_SHIFT = 'SIDEBAR_ROW_TYPE_SHIFT'
const SIDEBAR_ROW_TYPE_SHIFT_EMPLOYEE = 'SIDEBAR_ROW_TYPE_SHIFT_EMPLOYEE'
const SIDEBAR_ROW_TYPE_SHIFT_ADDITIONAL_EMPLOYEE = 'SIDEBAR_ROW_TYPE_SHIFT_ADDITIONAL_EMPLOYEE'
const SIDEBAR_ROW_TYPE_EMPLOYEE = 'SIDEBAR_ROW_TYPE_EMPLOYEE'

export const sidebarRowTypes = {
    shift: SIDEBAR_ROW_TYPE_SHIFT,
    shiftEmployee: SIDEBAR_ROW_TYPE_SHIFT_EMPLOYEE,
    shiftAdditionalEmployee: SIDEBAR_ROW_TYPE_SHIFT_ADDITIONAL_EMPLOYEE,
    employee: SIDEBAR_ROW_TYPE_EMPLOYEE
}

export const SCHEDULE_TAB_EDIT_SCHEDULE = 'SCHEDULE_TAB_EDIT_SCHEDULE'
export const SCHEDULE_TAB_EDIT_TIMETABLE = 'SCHEDULE_TAB_EDIT_TIMETABLE'
export const SCHEDULE_TAB_EDIT_BREAKS = 'SCHEDULE_TAB_EDIT_BREAKS'

export default () => ({
    currentTab: SCHEDULE_TAB_EDIT_SCHEDULE,
    editingSchedule: true,
    editingBreaks: false,
    breaks: [],
    schedules: [],

    scheduleId: null,
    scheduleName: null,
    from: '2023-08-01 00:00:00',
    to: '2023-08-31 23:59:59',
    expandShifts: [],
    shifts: [],
    employees: [],
    timeIntervals: [],
    durationByUserId: false,
    minusBreaks: false,
    showOtherEvents: true,
    lines: [],
    events: [],
    timetables: [],
    selectedTimeInterval: null,
    selectedTimetableInterval: null,
    holidays: [],
    timetableIntervals: []
});