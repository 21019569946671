import { generateUuid, GLOBAL_TOAST_TAG } from '@/utils/default'
import { cloneDeep } from 'lodash'
import CustomApi from '@/plugins/customApi'
import store from '@/domain/settings/stores/settingModuleItemStore'

const storeNamespace = 'settingModuleItemStore'
const customApi = CustomApi()
export default {
    props: {
        setting: { type: Object, required: true }
    },
    provide() {
        return {
            isEdit: this.isEdit,
            storeNamespace: this.storeNamespace
        }
    },
    data() {
        return {
            storeNamespace: storeNamespace + '/' + this.$options.name
        }
    },
    mounted() {
        this.setFormDataFromSettings()
    },
    created() {
        this.$store.registerModuleSafely(this.storeNamespace, store)
    },
    beforeDestroy() {
        this.$store.unregisterModuleSafely(this.storeNamespace)
    },
    computed: {
        title() {
            return this.$t(`setting.module.${this.$options.name}.title`)
        },
        description() {
            return this.$t(`setting.module.${this.$options.name}.description`)
        },
        company() {
            return this.setting.company
        },
        departments() {
            return this.setting.departments
        }
    },
    watch: {
        formData: {
            handler(formData) {
                const departmentIds = formData.departments?.map(({ departmentIds }) => departmentIds)?.flat() ?? []
                this.$store.dispatch(`${this.storeNamespace}/setDepartmentIds`, departmentIds)
            },
            deep: true
        }
    },
    methods: {
        setFormDataCompany(payload) {
            this.$set(this.formData, 'company', payload)
        },
        setFormDataDepartments(payload) {
            this.$set(this.formData, 'departments', payload)
        },
        handleAddDepartment() {
            this.formData.departments.push({
                uuid: generateUuid(),
                departmentIds: [],
                ...cloneDeep(this.defaultFormData)
            })
        },
        async handleChangeDepartment(department, departmentIds) {
            department.departmentIds = departmentIds
        },
        async handleRemoveDepartment(uuid) {
            const index = this.formData.departments.findIndex(department => department.uuid === uuid)
            this.formData.departments.splice(index, 1)
        },
        getFromFormDataPayload(module) {
            return {
                key: this.setting.key,
                module,
                company: this.getFromFormDataCompanyPayload(),
                departments: this.getFromFormDataDepartmentsPayload()
            }
        },
        async saveAction(module) {
            try {
                const payload = this.getFromFormDataPayload(module)
                const { data } = await customApi.factories.settings.set(payload)
                this.$emit('changed', module, this.setting.key, data)

                this.isEdit = false
            } catch (e) {
                console.error(e)
                await this.$store.dispatch('messagesStore/showErrorMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('setting.module.error.save')
                })
            } finally {

            }
        },
        handleEdit() {
            this.isEdit = true
        },
        handleCancel() {
            this.setFormDataFromSettings()
            this.isEdit = false
        }
    }
}