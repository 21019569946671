<template>
    <div class='d-flex flex-column gap-2'>
        <form-checkbox
            :is-checked.sync='config.enabled'
            :disabled='!isEdit'
        >
            <span class='pl-2'>{{ $t("setting.module.SettingHolidayParentOrMotherAllowUseAllCurrentYear.form.enabled") }}</span>
        </form-checkbox>
    </div>
</template>

<script>
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'

export default {
    name: 'SettingHolidayParentOrMotherAllowUseAllCurrentYearConfigForm',
    components: { FormCheckbox },
    props: {
        isEdit: { type: Boolean, default: false },
        config: { type: Object, default: () => ({}) }
    }
}
</script>