<template>
    <ValidationProvider
        :name="$attrs.name"
        :rules="rules"
        v-slot="{ classes }"
    >
        <div :class="[...fieldClasses, ...classes]">
            <div :class="`${baseClassName}__label`">
                <label>{{ translateLabel }}</label>
                <slot name="right-label"/>
            </div>
            <div :class="`${baseClassName}__container`">
                <input type="hidden" :name="$attrs.name" :value="innerValue" />
                <div
                    v-for="color in colors"
                    :class="[
                        `${baseClassName}__color`,
                        color === innerValue ? 'selected' : '',
                    ]"
                    :style="{ background: color }"
                    :key="color"
                    @click.prevent="() => onSetColor(color)"
                >
                    <i class="icon-ok"/>
                </div>
            </div>
        </div>
    </ValidationProvider>
</template>

<script>
import formFieldMixin from "@/mixins/formFieldMixin";

export default {
    mixins: [formFieldMixin],
    props: {
        colors: {
            type: Array,
            required: true,
        },
    },
    data: () => ({
        baseClassName: "form-colors",
    }),
    methods: {
        onSetColor(color) {
            this.innerValue = color;
        },
    },
};
</script>
