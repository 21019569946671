<template>
	<custom-dialog :max-width="521">
		<template #title>{{ $t('Skyriaus vadovas') }}</template>
		<template #content>
			<Form
				ref="form"
				is-dialog
				v-model="formData"
				@submit="onSubmit"
			>
				<template #form>
					<v-row>
						<v-col cols="12">
							<select-field
								name="department_id"
								:options="departmentOptions"
								v-model="formData.department_id"
								:label="$t('Skyrius')"
							/>
						</v-col>
						<v-col cols="12">
							<select-field
								rules="required"
								name="employee_id"
								:label="$t('Vadovas')"
								:single-line="false"
								:options-service="employeeAutocompleteService.find"
								v-model="formData.employee_id"
								dynamic filtered
							>
								<template #selection="{item}">
									<employee-table-view class="py-1" :employee="item.employee"/>
								</template>
								<template #item="{item}">
									<employee-table-view class="py-1" :employee="item.employee"/>
								</template>
							</select-field>
						</v-col>
					</v-row>
				</template>

				<template #buttons="{}">
					<hra-button :color="uiColor.default" :disabled="loadingButton" @click="close">
						<hra-icon :name="iconName.close"/>
						{{ $t('Uždaryti') }}
					</hra-button>

					<hra-button :color="uiColor.primary" :loading="loadingButton" :type="buttonType.submit">
						<hra-icon :name="iconName.ok"/>
						{{ $t('Išsaugoti') }}
					</hra-button>
				</template>
			</Form>
		</template>
	</custom-dialog>
</template>

<script>
import Dialog from "@/components/ui/dialog/Dialog";
import CustomDialog from "@/components/ui/dialog/CustomDialog";
import dialogMixin from "@/mixins/dialogMixin";
import FullRowLoader from "@/components/ui/FullRowLoader";
import FormTextField from "@/components/ui/form/FormTextField";
import FormColors from "@/components/ui/form/FormColors";
import FormEmployeeAutocomplete from "@/components/ui/form/FormEmployeeAutocomplete";
import HraButton from "@/components/ui/button/HraButton";
import HraIcon from "@/components/ui/HraIcon";
import Form from "@/components/ui/form/Form";
import {mapMutations} from "vuex";
import HraConfirm from "@/components/ui/dialog/HraConfirm";
import SelectField from "@/domain/fields/SelectField";
import EmployeeTableView from "@/components/ui/views/EmployeeTableView";
import employeeAutocompleteService from "@/services/employees/employeeAutocompleteService";

export default {
	name: "ChangeDepartmentsLeaderModal",
	components: {
		EmployeeTableView,
		SelectField,
		HraConfirm,
		Form,
		HraIcon,
		HraButton,
		FormEmployeeAutocomplete,
		FormColors, FormTextField, FullRowLoader, CustomDialog, Dialog
	},
	mixins: [dialogMixin],
	props: {
		departments: {type: Array, required: true},
		service: {type: Function, required: true}
	},
	data() {
		return {
			loadingButton: false,
			employeeAutocompleteService,
			formData: {
				employee_id: null,
				department_id: this.departments[0].id,
			},
		};
	},
	computed: {
		departmentOptions() {
			return this.departments.map(department => ({
				value: department.id,
				text: department.name
			}));
		},
	},
	methods: {
		async onSubmit(payload) {
			try {
				this.loadingButton = true;
				await this.service(payload);
				this.close();
			} finally {
				this.loadingButton = false;
			}
		}
	},
};
</script>
