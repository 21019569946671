<template>
    <div :class="['hra-pagination']">
        <div class="hra-pagination__container" v-if="lastPage > 1">
            <Button
                v-if="isArrowLeft"
                icon="arrow-left"
                small
                @click.prevent="() => onChangePage(viewPages[0] - 1)"
            />
            <Button
                v-for="page in viewPages"
                :class="[page === currentPage ? 'active' : '']"
                small
                @click.prevent="() => onChangePage(page)"
            >
                {{page }}
            </Button>
            <Button
                v-if="isArrowRight"
                icon="arrow-right"
                small
                @click.prevent="() => onChangePage(viewPages[viewPages.length - 1] + 1)"
            />
        </div>
    </div>
</template>

<script>
import Button from "@/components/ui/button/Button";

export default {
    components: {Button},
    props: {
        currentPage: {
            type: Number,
            default: 1,
        },
        lastPage: {
            type: Number,
            required: true,
        },
        maxViewPages: {
            type: Number,
            default: 4,
        },
    },
    computed: {
        isArrowLeft() {
            const minPage = Math.ceil(this.maxViewPages / 2);
            return this.currentPage > minPage && this.lastPage > this.maxViewPages;
        },
        viewPages() {
            const minPage = Math.ceil(this.maxViewPages / 2);
            let pages = [];
            let page = this.currentPage - minPage + 1;
            let maxPage = page + this.maxViewPages - 1;

            if (maxPage > this.lastPage) {
                page = this.lastPage - this.maxViewPages + 1;
                if (page < 1) {
                    page = 1;
                }
                maxPage = this.lastPage;
            }

            if (this.currentPage <= minPage) {
                page = 1;
                maxPage = this.maxViewPages;
                if (maxPage > this.lastPage) {
                    maxPage = this.lastPage;
                }
            }

            for (; page <= maxPage; page++) {
                pages.push(page);
            }

            return pages;
        },
        isArrowRight() {
            const maxPage = this.lastPage - (this.maxViewPages / 2);
            return this.currentPage < maxPage && this.lastPage > this.maxViewPages;
        },
    },
    methods: {
        onChangePage(page) {
            this.$emit("changePage", page);
        },
    },
}
</script>
