import {
    EVENT_HOLIDAY_TYPE_DEPUTY,
    eventHolidayType,
    getEventStatusText,
} from '@/utils/default'

export const employee = ({ employee }) => employee
export const user = ({ employee }) => employee.user
export const userRole = ({ employee }) => employee.user.roles[0]
export const userDescription = ({ employee }) => employee.user.description
export const userId = ({ employee }) => employee.user?.id ?? null
export const children = ({ employee }) => employee.children.sort((a, b) => a.birthDate < b.birthDate ? -1 : 1)
export const disabilities = ({ employee }) => employee.disabilities.sort((a, b) => a.fromDate < b.fromDate ? -1 : 1)
export const contacts = ({ employee }) => employee.contacts
export const contract = ({ employee }) => employee.contract
export const dismissed = ({ employee }) => employee.contract.workEndAt != null
export const dismissData = ({ employee }, { dismissed }) => {
    return dismissed ? ({ date: employee.contract.workEndAt, reason: employee.contract.dismissReason }) : null
}


export const deletedData = ({ deletedData }) => deletedData

export const departmentOptions = ({ options }) => options?.departments ?? []
export const dutyOptions = ({ options }) => options?.duties ?? []

export const changelogs = ({ changelogs }) => changelogs.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)

export const events = ({ events }) => {
    return events.filter(({ type }) => type !== EVENT_HOLIDAY_TYPE_DEPUTY).map(event => {
        return {
            ...event,
            typeName: eventHolidayType[event.type] ?? '',
            statusName: getEventStatusText(event.end, event.currentStatus)
        }
    }).sort((a, b) => a.start < b.start ? -1 : 1)
}
export const additionalHolidays = ({ additionalHolidays }) => {
    return additionalHolidays
        .sort((a, b) => a.from < b.from ? -1 : 1)
        .map(additional => ({
            ...additional,
            editable: !['seniority'].includes(additional.type)
        }))
}