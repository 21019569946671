<template>
    <ValidationProvider
        :name="$attrs.name"
        :rules="rules"
        v-slot="{ classes, errors }"
    >
        <div :class="[...fieldClasses, ...classes]">
            <template v-if="isView">
                <input type="hidden" v-model="innerValue"/>
                <div :class="`${baseClassName}__label`">{{ translateLabel }}</div>
                <div :class="`${baseClassName}__input-view d-flex align-center`">
                    <slot name="input.view" :value="errors.length === 0 ? innerValue : '?'">
                        {{ errors.length === 0 ? innerValue : '?' }}
                    </slot>
                </div>
            </template>
            <template v-else>
                <div :class="`${baseClassName}__label`">
                    <label :for="$attrs.name">{{ translateLabel }}</label>
                    <slot name="right-label"/>
                </div>
                <div class="d-flex align-center" style="width: 100%;">
                    <Avatar
                        v-if="isEmployee"
                        :employee-name="innerValue"
                        class="m-r-1"
                    />
                    <div :class="`${baseClassName}__input`">
                        <span v-if="unit">{{ unit }}</span>
                        <span v-if="icon" :class="`icon-${icon}`"/>
                        <div v-if="colorDot" :class="`${baseClassName}__dot`" :style="{ background: colorDot }"/>
                        <input
                            :ref="$refs"
                            v-bind="$attrs"
                            :id="$attrs.name"
                            :disabled="disabled"
                            v-model.trim="innerValue"
                            :placeholder="translatePlaceholder"
                            v-if="!isView"
                        />
                        <span v-if="canClear && innerValue.length > 0" :class="`${baseClassName}__clear-btn icon-close`"
                              @click.prevent="onClear"/>
                    </div>
                </div>
            </template>
        </div>
    </ValidationProvider>
</template>

<script>
import formFieldMixin from "@/mixins/formFieldMixin";
import Avatar from "@/components/ui/Avatar";

export default {
    components: {Avatar},
    mixins: [formFieldMixin],
    props: {
        canClear: {
            type: Boolean,
            default: false,
        },
        colorDot: {
            type: String,
            default: null,
        },
        isView: {
            type: Boolean,
            default: false,
        },
        isEmployee: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        baseClassName: "form-text-field",
    }),
    methods: {
        onClear() {
            this.innerValue = "";
        },
    },
};
</script>
