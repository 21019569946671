<template>
    <validation-provider
        tag='div'
        class='DatepickerField'
        immediate
        :rules='rules'
        :name='name'
        v-slot='{ errors: validationErrors }'
    >
        <read-only-field v-if='readOnly' :invalid='!!validationErrors.length' :value='viewValue' />
        <base-field v-else :invalid='!!validationErrors.length'>
            <input type='hidden' :value='viewValue' :name='name' />
            <v-menu
                v-model='menu'
                :close-on-content-click='false'
                :nudge-right='0'
                :nudge-bottom='5'
                transition='scale-transition'
                offset-y
                min-width='fit-content'
                :disabled='hasDisabled'
            >
                <template v-slot:activator='{ on, attrs }'>
                    <div class='field DatepickerField__field'
                         :class="{...fieldClasses, ['invalid']: !!validationErrors.length}">
                        <div class='DatepickerField__activator' v-bind='attrs' v-on='on'>
                            <i :class="['icon-calendar', menu ? 'active' : '']" />
                            <span>{{ viewValue }}</span>
                        </div>
                        <i v-if='hasCanClear' class='icon-close TextField__clear' @click='onClear' />
                        <editable-field-actions />
                    </div>
                </template>
                <v-date-picker
                    no-title
                    :locale='locale'
                    :first-day-of-week='1'
                    color='blue'
                    show-adjacent-months
                    :month-format='monthFormat'
                    :min='minDate'
                    :max='maxDate'
                    :type='type'
                    :value='value'
                    :numbers-only='numbersOnly'
                    @input='menu = false'
                    @change='onChange'
                />
            </v-menu>
            <div :id='idName' :style="{position: 'fixed', width: '100%', zIndex: 90}" />
        </base-field>
        <div v-if='validationErrors != null' v-for='error in validationErrors' class='hra-text--red mt-1'>{{ error }}</div>
    </validation-provider>
</template>

<script>
import ReadOnlyField from '@/domain/fields/components/ReadOnlyField'
import BaseField from '@/domain/fields/components/BaseField'
import baseFieldMixin from '@/domain/fields/mixins/baseFieldMixin'
import moment from 'moment'
import EditableFieldActions from '@/domain/fields/components/EditableFieldActions'
import { generateUuid } from '@/utils/default'
import i18n from '@/plugins/i18n'

export default {
    name: 'DatepickerField',
    components: { EditableFieldActions, BaseField, ReadOnlyField },
    mixins: [baseFieldMixin],
    props: {
        minDate: { type: String, default: null },
        maxDate: { type: String, default: null },
        ignoreAutoChange: { type: Boolean, default: false },
        type: {
            type: String,
            default: 'date',
            validator: function(value) {
                return ['date', 'month', 'year'].includes(value)
            }
        },
        canClear: { type: Boolean, default: false },
        numbersOnly: { type: Boolean, default: false }
    },
    watch: {
        minDate: {
            handler(newValue) {
                if (this.ignoreAutoChange) {
                    return
                }

                if (this.value < newValue || (this.value == null && newValue != null)) {
                    this.onChange(newValue)
                }
            }
        },
        maxDate: {
            handler(newValue) {
                if (this.ignoreAutoChange) {
                    return
                }

                if (this.value > newValue || (this.value == null && newValue != null)) {
                    this.onChange(newValue)
                }
            }
        }
    },
    data() {
        return {
            menu: false,
            idName: generateUuid(true)
        }
    },
    computed: {
        hasCanClear() {
            if (!this.canClear) {
                return false
            }

            return !this.hasDisabled && this.value != null && this.value !== ''
        },
        viewValue() {
            if (this.value == null) {
                return ''
            }

            if (this.numbersOnly) {
                return moment(this.value).locale(i18n.locale).format('YYYY-MM-DD')
            }

            return moment(this.value).locale(i18n.locale).format(({
                'month': 'YYYY MMMM'
            })[this.type] ?? 'LL')
        },
        locale() {
            return i18n.locale
        }
    },
    methods: {
        monthFormat(month) {
            return moment(month + '-01').locale(i18n.locale).format('MMM')
        },
        onClear() {
            this.onChange(null)
        }
    }
}
</script>