export default axios => ({
  reports: {
    fetch: (params) => axios.get(`/api/events/holidays/reports`, {params}),
    balance: {
      fetch: (date) => axios.get(`/api/events/holidays/reports/balance/${date}`),
      fetchDepartmentLeader: (date) => axios.get(`/api/events/approvals/holidays/balances/${date}`)
    },
    parentDays: {
      fetch: (params) => axios.get(`/api/events/holidays/reports/parentdays/`, {params}),
    },
  },
});
