<template>
    <div class='schedule-interval px-6 d-flex align-center gap-4' :style='{height: `${eventRowHeight}px`}'>
        <form-radio
            name='selectedTimetableInterval'
            :radio-value='`timetable-interval-${timetableInterval.id}`'
            :value='timetableInterval.id === currentId'
            @input='onChangeTimetableInterval(timetableInterval)'
        >
            <div class='ml-3'>
                {{ timetableInterval.start }} - {{ timetableInterval.end }} (min: {{ timetableInterval.minUsers}})
            </div>
        </form-radio>
        <div class='d-flex'>
            <ui-button
                class='pa-2'
                prepend-icon='edit'
                size='normal'
                only-hover-background
                :modal='{name: "UpdateScheduleTimetableIntervalModal", props: {timetableInterval}}'
            />
            <ui-button
                class="pa-2"
                prepend-icon='bin'
                size='normal'
                hover-color='danger'
                only-hover-background
                :confirm="{
                    props: {title: $t('Ar tikrai norite ištrinti darbo vietos laiką?')},
                    on: {confirmed: onDeleteRow}
                }"
            />
        </div>
    </div>
</template>

<script>
import VueDraggableResizable from 'vue-draggable-resizable'
import { GLOBAL_TOAST_TAG } from '@/utils/default'
import { mapGetters } from 'vuex'
import UiButton from '@/domain/components/UiButton.vue'
import FormRadio from '@/components/ui/form/FormRadio.vue'

export default {
    name: 'ScheduleTimetableInterval',
    components: { FormRadio, UiButton, VueDraggableResizable },
    props: {
        timetableInterval: { type: Object, required: true }
    },
    inject: ['eventRowHeight'],
    computed: {
        ...mapGetters({
            selectedTimetableInterval: 'schedulerStore/selectedTimetableInterval'
        }),
        currentId() {
            return this.selectedTimetableInterval?.id
        }
    },
    methods: {
        onChangeTimetableInterval(timetableInterval) {
            this.$store.dispatch('schedulerStore/changeSelectedTimetableInterval', timetableInterval)
        },
        async onDeleteRow(confirm) {
            if (!await this.$store.dispatch('schedulerStore/deleteTimetableIntervalById', this.timetableInterval.id)) {
                await this.$store.dispatch('messagesStore/showErrorMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: 'Timetable interval delete error'
                })

                return
            }

            await this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag: GLOBAL_TOAST_TAG,
                message: this.$t('Timetable interval delete success')
            })

            confirm.close()
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/sass/core/colors';

.schedule-interval {
    background-color: $white;
    border-bottom: 1px solid $schedule-border-primary;

    .ui-button {
        min-height: auto !important;
    }
}

.form-radio,
.form-checkbox {
    &__container {
        font-size: 14px !important;
    }
}
</style>