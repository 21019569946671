const state = () => ({
  loading: false,
  items: [],
});

const getters = {
  items: state => state.items,
};

const mutations = {
  setItems: (state, payload) => state.items = payload,
};

const actions = {
  setItems: ({commit}, payload) => {
    commit('setItems', payload);
  },
};


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
