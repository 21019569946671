export default {
  inject: ["service"],
  computed: {
    buttonLoading() {
      return this.$store.getters["loadersStore/hasLoading"](this.tag);
    },
  },
  mounted() {
    // this.$store.dispatch("messagesStore/addToastTag", this.tag);
  },
}