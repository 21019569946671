<template>
    <v-row class='ml-5 mt-5'>
        <v-col cols='12'>
            <ui-table
                header-sticky
                :header-sticky-top='64'
                :visible-load-more='hasMore'
                :loading='loading'
                :loading-more='loadingMore'
                @clickLoadMore='handleLoadMore'
            >
                <template #head>
                    <tr>
                        <th>{{ $t('Dokumento pavadinimas') }}</th>
                        <th>{{ $t('Įkėlęs asmuo') }}</th>
                        <th>{{ $t('Galioja iki') }}</th>
                        <ui-table-head-filter
                            :title='$t("Liko dienų")'
                            column='expirationDate'
                            :sort-options='filterSortNumberOptions'
                            v-model='filter.expirationDate'
                            @changeFilter='handleChangeFilter'
                        />
                        <th>{{ $t('Funkcijos') }}</th>
                    </tr>
                </template>
                <template #body>
                    <tr
                        v-for='(row, key) in formattedRows'
                        :key='key'
                        @click='(event) => handleOpenEditDocumentModal(event, row)'
                        style='cursor: pointer;'
                    >
                        <td>{{ row.title }}</td>
                        <td>{{ row.user == null ? '-' : row.user.name }}</td>
                        <td>{{ row.expirationDate }}</td>
                        <td>
                            <div :class='row.expire.classNames'>
                                <ui-icon v-if='row.expire.visibleIcon'>message-info</ui-icon>
                                <span>{{ row.expire.text }}</span>
                            </div>
                        </td>
                        <td>
                            <div class='d-flex flex-row align-center justify-end gap-3'>
                                <ui-button
                                    class='px-0'
                                    background='primary'
                                    size='medium'
                                    only-text
                                    @click.prevent='(event) => handleOpenPreviewModal(event, row)'
                                >{{ $t('Peržiūrėti') }}
                                </ui-button>
                                <ui-button
                                    class='px-0'
                                    background='primary'
                                    size='medium'
                                    only-text
                                    @click.prevent='(event) => handleOpenEditDocumentModal(event, row)'
                                >{{ $t('Redaguoti') }}
                                </ui-button>
                            </div>
                        </td>
                    </tr>
                </template>
            </ui-table>
        </v-col>
    </v-row>
</template>

<script>
import CustomApi from '@/plugins/customApi'
import UiTable from '@/domain/components/uiTable/UiTable.vue'
import tableFilterMixin from '@/domain/components/uiTable/mixins/tableFilterMixin'
import MessageAlert from '@/domain/messages/components/MessageAlert.vue'
import UiIcon from '@/domain/components/UiIcon.vue'
import UiTableHeadFilter from '@/domain/components/uiTable/UiTableHeadFilter.vue'
import UiButton from '@/domain/components/UiButton.vue'
import modalMethodsMixin from '@/domain/mixins/modalMethodsMixin'

const customApi = CustomApi()
export default {
    name: 'DocumentsExpirationTrackingPage',
    mixins: [tableFilterMixin, modalMethodsMixin],
    components: { UiButton, UiTableHeadFilter, UiIcon, MessageAlert, UiTable },
    data() {
        return {
            loading: false,
            loadingMore: false,
            hasMore: false,
            currentPage: 1,
            rows: [],
            filterSettings: {},
            filter: {
                expirationDate: {
                    sort: 'asc'
                }
            }
        }
    },
    computed: {
        formattedRows() {
            return this.rows.map(row => ({
                ...row,
                expire: {
                    classNames: {
                        'cell-expire-in-days': true,
                        'cell-expire-in-days__info': row.expireInDays >= 0 && row.expireInDays <= 10,
                        'cell-expire-in-days__error': row.expireInDays < 0
                    },
                    visibleIcon: row.expireInDays <= 10,
                    text: row.expireInDays < 0
                        ? this.$t('Negalioja')
                        : row.expireInDays + ' ' + this.$t('k. d.')
                }
            }))
        }
    },
    mounted() {
        this.loadData()
    },
    methods: {
        handleChangeFilter(column, filter) {
            this.setFilter(column, filter)
            this.loadData()
        },
        getRequestParams(page = 1) {
            return {
                page,
                limit: 50,
                ...this.getFilterParams()
            }
        },
        async loadData() {
            this.loading = true
            try {
                await this.fetch(this.getRequestParams(1))
            } catch (error) {

            } finally {
                this.loading = false
            }
        },
        async handleLoadMore() {
            this.loadingMore = true
            try {
                await this.fetch(this.getRequestParams(this.currentPage + 1))
            } catch (error) {

            } finally {
                this.loadingMore = false
            }
        },
        async fetch(params) {
            const {
                data: {
                    items,
                    hasMore,
                    pagination: { currentPage }
                }
            } = await customApi.factories.documents.expirationTracking.fetch(params)

            this.rows = currentPage === 1 ? items : [...this.rows, ...items]
            this.currentPage = currentPage
            this.hasMore = hasMore
        },
        handleOpenPreviewModal(event, row) {
            event.stopPropagation()
            this.onOpenModal('DocumentPreviewModal', { item: row })
        },
        handleOpenEditDocumentModal(event, item) {
            event.stopPropagation()
            this.onOpenModal(
                'DocumentEditModal',
                {
                    item
                },
                {
                    documentUpdated: (payload) => {
                        const index = this.rows.findIndex(row => row.id === payload.id)
                        if (index == null) {
                            return
                        }
                        this.$set(this.rows, index, {
                            ...this.rows[index],
                            ...payload
                        })
                    }
                }
            )
        },
    }
}
</script>

<style lang='scss' scoped>
.cell-expire-in-days {
    display: inline-block;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 4px;
    padding: 7px 12px;

    & > .ui-icon {
        position: relative;
        padding-right: 5px;
        top: 0;
    }

    &__info {
        background: #FFF2DD;
        color: #64420E;
    }

    &__error {
        background: #FFEDF0;
        color: #E7384F;
    }
}
</style>