<template>
    <custom-dialog :max-width='670'>
        <template #title>{{ $t("Redaguoti") }}</template>
        <template #content>
            <validation-observer ref="ValidationObserver" tag="div" class="row px-3">
                <v-col cols='12'>
                    <select-field :label='$t("Grafikas")' :options='schedulesOptions' v-model='formData.scheduleId' />
                </v-col>
                <v-col v-if='!changedSchedule' cols='12'>
                    <select-field :label="$t('Parent')" :options='parentUpdateOptions' v-model='formData.parentId' />
                </v-col>
                <v-col cols='12'>
                    <select-field disabled :label='$t("Rūšis")' :options='typeOptions' v-model='formData.type' />
                </v-col>
                <v-col v-if='isGroup' cols='12'>
                    <text-field
                        :label='$t("Pavadinimas")'
                        rules='required'
                        v-model='formData.name' />
                </v-col>
                <v-col v-if='isUser' cols='12'>
                    <select-field
                        name='userId'
                        :label="$t('Darbuotojas')"
                        rules='required'
                        :single-line='false'
                        :options-service='employeeAutocompleteService.findWithIgnoreIds()'
                        v-model='formData.userId'
                        dynamic filtered
                    >
                        <template #selection='{item}'>
                            <employee-table-view class='py-1' :employee='item.employee' />
                        </template>
                        <template #item='{item}'>
                            <employee-table-view class='py-1' :employee='item.employee' />
                        </template>
                    </select-field>
                </v-col>
            </validation-observer>
        </template>

        <template #footer>
            <ui-button
                prepend-icon='close'
                background='default'
                @click='close'
            >{{ $t('Uždaryti') }}
            </ui-button>
            <ui-button
                prepend-icon='ok'
                background='primary'
                :disabled='disabledButton'
                @click='onCreate'
            >{{ $t('Redaguoti') }}
            </ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import TextField from '@/domain/fields/TextField.vue'
import UiButton from '@/domain/components/UiButton.vue'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import dialogMixin from '@/mixins/dialogMixin'
import EmployeeTableView from '@/components/ui/views/EmployeeTableView.vue'
import SelectField from '@/domain/fields/SelectField.vue'
import scheduleLineModalMixin from '@/domain/scheduler/mixins/scheduleLineModalMixin'
import {ValidationObserver} from "vee-validate";
import {GLOBAL_TOAST_TAG} from "@/utils/default";

export default {
    name: 'UpdateScheduleLineModal',
    mixins: [dialogMixin, scheduleLineModalMixin],
    props: {
        line: {type: Object, required: true}
    },
    components: {
        ValidationObserver,
        EmployeeTableView,
        CustomDialog,
        UiButton,
        SelectField,
        TextField
    },
    beforeMount() {
        this.formData.type = this.line.type
        this.formData.name = this.line.name
        this.formData.userId = this.line.userId
        this.formData.parentId = this.line.scheduleLineId ?? -1
        this.formData.scheduleId = this.line.scheduleId
    },
    computed: {
        schedulesOptions() {
            return this.$store.getters['schedulerStore/schedules']
                .map(({id, name}) => ({value: id, text: name}))
        },
        changedSchedule() {
            return this.formData.scheduleId !== this.line.scheduleId
        }
    },
    methods: {
        async onCreate() {
            this.disabledButton = true

            if (!(await this.$refs.ValidationObserver.validate())) {
                await this.$store.dispatch('messagesStore/showErrorMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Supildykite raudonai pažymėtus laukus')
                })
                return
            }

            const result = await this.$store.dispatch('schedulerStore/updateLineById', {
                id: this.line.id,
                payload: this.getPayload()
            })

            if (result == null) {
                return
            }

            this.disabledButton = false
            this.close()
        }
    }
}
</script>