<template>
    <div class='setting-module'>
        <div class='setting-module__title'>{{ title }}</div>
        <div class='setting-module__container'>
            <slot />
        </div>
    </div>
</template>

<script>
import UiButton from '@/domain/components/UiButton.vue'
import UiIcon from '@/domain/components/UiIcon.vue'

export default {
    name: 'SettingModule',
    components: { UiIcon, UiButton },
    props: {
        title: { type: String, required: true }
    }
}
</script>

<style lang='scss'>
.setting-module {
    width: 100%;

    &__title {
        font-size: 18px;
        font-weight: 400;
        padding: 0 0 10px;
        //background-color: #c7c9f2;
        //padding: 10px;
        //position: sticky;
        //top: 64px;
        //z-index: 10;
    }

    &__container {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 0 0 5px 5px;
        background-color: #FFFFFF;
        //box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
        //border: 1px solid rgba(0, 0, 0, 0.1);
        border: 1px solid #E6E9F0;
    }
}
</style>