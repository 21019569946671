<template>
    <button
        v-if="hasPermissions"
        :class="classes"
        :disabled="loading"
        :type="type"
        v-on="$listeners"
        v-bind="$attrs"
    >
        <i v-if="!loading && icon" :class="`icon-${icon}`"/>
        <v-progress-circular
            v-if="loading"
            indeterminate
            class="button-loader"
        />
        <slot v-if="!loading"/>
    </button>
</template>

<script>
import buttonMixin from "@/mixins/buttonMixin";
import buttonPermissionsMixin from "@/mixins/buttonPermissionsMixin";

export default {
    mixins: [buttonMixin, buttonPermissionsMixin],
    data: () => ({
        baseClass: 'button',
    }),
};
</script>
