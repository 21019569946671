import customApi from '@/plugins/customApi'
import { GLOBAL_TOAST_TAG, jsonToFormData } from '@/utils/default'
import router from '@/router'
import store from '@/store'

class ImportService {

    async convert(tag, attachment) {
        const api = customApi(tag)
        const data = await api.request(api.factories.employee.import.convert(jsonToFormData({ attachment })))

        if (data == null) {
            return
        }

        await store.dispatch('messagesStore/showSuccessMessage', {
            tag: GLOBAL_TOAST_TAG,
            message: 'Import success'
        })

        // const response = await api.request(api.factories.auth.config());
        // await store.dispatch("authStore/init", response);
        await store.dispatch('currentUserStore/getUpdatedUser')
        await router.push({ name: 'CompanyTreePage' })
    }

}

export default new ImportService()