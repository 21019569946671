import {
    permissionsButtonEmployeeCreate,
    permissionsButtonEmployeeDelete,
    permissionsButtonEmployeeEdit
} from '@/utils/permissions'
import moment from 'moment'
import VueDraggableResizable from 'vue-draggable-resizable'

export default {
    components: { VueDraggableResizable },
    props: {
        event: { type: Object, required: true },
        dayWidth: { type: Number, required: true },
        rowHeight: { type: Number, required: true },
        onePixelToSecond: { type: Number, required: true },
        start: { type: Object, required: true },
        eventOnlyDaysGrid: { type: Boolean, default: false },
        grid: { type: Array, default: [1, 1] },
        maxX: { type: Number, default: null },
        maxY: { type: Number, default: null }
    },
    computed: {
        isAdmin() {
            return this.hasPermissionTo([
                ...permissionsButtonEmployeeCreate,
                ...permissionsButtonEmployeeEdit,
                ...permissionsButtonEmployeeDelete
            ])
        },
        eventStart() {
            if (this.eventOnlyDaysGrid) {
                return moment(this.event.start).set('hour', 0).set('minute', 0).set('second', 0)
            }

            return moment(this.event.start)
        },
        eventStartXMoment() {
            return this.start.clone().add('seconds', this.x / this.onePixelToSecond)
        }
    },
    data() {
        return {
            x: this.calculateX(),
            y: this.calculateY(),
            w: this.calculateWidth(),
            h: this.calculateHeight(),
            z: 3
        }
    },
    watch: {
        eventOnlyDaysGrid: {
            handler() {
                this.reset()
            }
        },
        eventStart: {
            handler() {
                this.reset()
            }
        },
        start: {
            handler() {
                this.reset()
            }
        },
        onePixelToSecond: {
            handler() {
                this.reset()
            }
        },
        rowIndex: {
            handler() {
                this.reset()
            }
        },
        rowHeight: {
            handler() {
                this.reset()
            }
        }
    },
    methods: {
        calculateWidth() {
            if (this.eventOnlyDaysGrid) {
                return this.dayWidth * Math.ceil(this.event.duration.days)
            }

            return this.event.duration.seconds * this.onePixelToSecond
        },
        calculateHeight() {
            return this.rowHeight
        },
        calculateX() {
            if (this.eventOnlyDaysGrid) {
                return moment(this.event.start).startOf('day')
                    .diff(this.start, 'seconds') * this.onePixelToSecond
            }

            return moment(this.event.startWithTime).clone().diff(this.start, 'seconds') * this.onePixelToSecond
        },
        calculateY() {
            return this.event.rowIndex * this.rowHeight
        },
        reset() {
            this.x = this.calculateX()
            this.y = this.calculateY()
            this.w = this.calculateWidth()
            this.h = this.calculateHeight()
        },
        onDragging(x, y) {
            this.x = x
            this.y = y
        },
        onDragStop(x, y) {
            if (x < 0 && this.w + x < this.grid[0]) {
                x = 0
            }

            if (y < 0 && this.h + y < this.grid[1]) {
                y = 0
            }

            if (this.maxX != null && x > this.maxX) {
                x = this.maxX
            }

            if (this.maxY != null && y > this.maxY) {
                y = this.maxY
            }

            this.x = x
            this.y = y

            this.$emit('dragstop', this, x, y)
        }
    }
}