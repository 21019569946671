export default {
    props: {
        selectedDocuments: { type: Array, default: () => [] },
        selectable: {type: Boolean, default: false},
        withStatus: { type: Boolean, default: false },
        withControls: { type: Boolean, default: false },
        withDeleteInfo: {type: Boolean, default: false}
    },
    inject: ['storeNamespace'],
    provide() {
        return {
            withStatus: this.withStatus,
            withControls: this.withControls,
            withDeleteInfo: this.withDeleteInfo,
            selectable: this.selectable,
        }
    },
    computed: {
        mode() {
            return this.$store.getters[`${this.storeNamespace}/mode`]
        },
        items() {
            return this.$store.getters[`${this.storeNamespace}/items`]
        },
        // folders() {
        //     return this.$store.getters[`${this.storeNamespace}/folders`]
        // },
        // documents() {
        //     return this.$store.getters[`${this.storeNamespace}/documents`]
        // },
        groupsFolders() {
            return this.$store.getters[`${this.storeNamespace}/groupsFolders`]
        },
        groups() {
            return this.$store.getters[`${this.storeNamespace}/groups`]
        },
        archivedDocuments() {
            return this.$store.getters[`${this.storeNamespace}/archivedDocuments`]
        },
        selectedDocumentsIds() {
            return this.selectedDocuments.map(({ id }) => id)
        }
    },
    methods: {
        hasSelectedDocument(id) {
            return this.selectedDocumentsIds.includes(id)
        },
        handleSelectDocument(item) {
            this.$emit('toggleSelectDocument', item)
        },
        handleChangeFolderId(item) {
            this.$emit('changeFolderId', item.id)
        },
        handleShowDocumentSidebar(item) {
            this.$emit('showDocumentSidebar', item)
        },
        handleClick() {

        }
    }
}