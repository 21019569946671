<!--SettingHolidayAllRemindEventHolidayActivity-->
<template>
    <setting-module-item
        :title='title'
        :description='description'
        :is-edit='isEdit'
        @edit='handleEdit'
        @save='handleSave'
        @cancel='handleCancel'
    >
        <template #company-config>
            <setting-module-item-config-company>
                <setting-holiday-all-remind-event-holiday-activity-config-form
                    :is-edit='isEdit'
                    :config='formData.company'
                />
            </setting-module-item-config-company>
        </template>

<!--        <template #departments-config>-->
<!--            <setting-module-item-more-->
<!--                :is-edit='isEdit'-->
<!--                :count='formData.departments.length'-->
<!--                @addDepartment='handleAddDepartment'-->
<!--            >-->
<!--                <setting-module-item-config-department-->
<!--                    v-for='(department) in formData.departments'-->
<!--                    :key='department.uuid'-->
<!--                    :department-ids='department.departmentIds'-->
<!--                    :is-edit='isEdit'-->
<!--                    @changeDepartments='($event) => handleChangeDepartment(department, $event)'-->
<!--                    @removeDepartment='() => handleRemoveDepartment(department.uuid)'-->
<!--                >-->
<!--                    <setting-holiday-all-remind-event-holiday-activity-config-form-->
<!--                        :is-edit='isEdit'-->
<!--                        :config='department'-->
<!--                    />-->
<!--                </setting-module-item-config-department>-->
<!--            </setting-module-item-more>-->
<!--        </template>-->
    </setting-module-item>
</template>

<script>
import SettingModuleItem from '@/domain/settings/components/SettingModuleItem.vue'
import SettingModuleItemConfig from '@/domain/settings/components/SettingModuleItemConfig.vue'
import settingModuleItemMixin from '@/domain/settings/mixins/settingModuleItemMixin'
import { cloneDeep } from 'lodash'
import { generateUuid } from '@/utils/default'
import SettingModuleItemConfigDepartment from '@/domain/settings/components/SettingModuleItemConfigDepartment.vue'
import SettingModuleItemMore from '@/domain/settings/components/SettingModuleItemMore.vue'
import SettingModuleItemConfigCompany from '@/domain/settings/components/SettingModuleItemConfigCompany.vue'
import SettingHolidayAllRemindEventHolidayActivityConfigForm
    from '@/domain/settings/components/holiday/allRemindEventHolidayActivity/ConfigForm.vue'

export default {
    name: 'SettingHolidayAllRemindEventHolidayActivity',
    components: {
        SettingHolidayAllRemindEventHolidayActivityConfigForm,
        SettingModuleItemConfigCompany,
        SettingModuleItemMore,
        SettingModuleItemConfigDepartment,
        SettingModuleItemConfig,
        SettingModuleItem
    },
    mixins: [settingModuleItemMixin],
    data() {
        const defaultFormData = {
            enabled: false,
            adminIds: []
        }

        return {
            isEdit: false,
            defaultFormData,
            formData: {
                company: cloneDeep(defaultFormData),
                departments: []
            }
        }
    },
    methods: {
        setFormDataFromSettings() {
            this.setFormDataCompany({
                enabled: this.company.config.enabled,
                adminIds: this.company.config.adminIds
            })
            this.setFormDataDepartments(this.departments.map(payload => ({
                uuid: generateUuid(),
                departmentIds: payload.departmentIds,
                enabled: payload.config.enabled,
                adminIds: payload.config.adminIds
            })))
        },
        getFromFormDataCompanyPayload() {
            return {
                active: true,
                config: {
                    enabled: this.formData.company.enabled,
                    adminIds: this.formData.company.adminIds
                }
            }
        },
        getFromFormDataDepartmentsPayload() {
            return this.formData.departments.map(payload => ({
                active: true,
                config: {
                    enabled: payload.enabled,
                    adminIds: payload.adminIds
                },
                departmentIds: payload.departmentIds
            }))
        },
        async handleSave() {
            await this.saveAction('holiday')
        }
    }
}
</script>