<template>
  <li :class='{expand: isVisible}'>
    <span @click='toggle'>{{ subTitle }}</span>
    <span @click='toggle'>{{ title }}</span>
    <ui-icon @click='toggle'>{{ isVisible ? 'up' : 'down' }}</ui-icon>

    <ul v-if='isVisible && isChildrenPage' class='dtv-list-sub-links'>
      <dtv-list-link-item
          v-for='page in child'
          :key='page.title'
          :title='`${page.articleNumber} straipsnis. ${page.title}`'
          :page='page'
      />
    </ul>
    <ul v-if='isVisible && !isChildrenPage' class='dtv-list-sub-tags'>
      <dtv-list-sub-tag-item
          v-for='({title, child: itemChild}, key) in child'
          :key='key'
          :title='title'
          :sub-title='`${subTitle}.${key+1}.`'
          :child='itemChild'
      />
    </ul>
  </li>
</template>

<script>
import DtvListSubTagItem from '@/domain/dtv/components/DtvListSubTabItem.vue'
import UiIcon from '@/domain/components/UiIcon.vue'
import DtvListLinkItem from '@/domain/dtv/components/DtvListLink.vue'

export default {
  name: 'DtvListTagItem',
  components: { DtvListLinkItem, UiIcon, DtvListSubTagItem },
  props: {
    subTitle: { type: Number, required: true },
    title: { type: String, required: true },
    child: { type: Array, default: () => [] },
    defaultExpand: { type: Boolean, default: false }
  },
  data() {
    return {
      isVisible: this.defaultExpand
    }
  },
  computed: {
    isChildrenPage() {
      return this.child[0]?.type === 'page'
    }
  },
  methods: {
    toggle() {
      this.isVisible = !this.isVisible
    }
  }
}
</script>