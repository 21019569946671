<template>
    <v-col cols='12'>
        <div v-if='loading' class='d-flex justify-center'>
            <v-progress-circular indeterminate size='32' width='2' color='primary' />
        </div>
        <div v-else>
            <iframe :src='iframeSrc' width='100%' height='700px'></iframe>
        </div>
    </v-col>
</template>

<script>
const mammoth = require('mammoth/mammoth.browser')

export default {
    props: {
        fileUrl: String
    },
    data() {
        return {
            iframeSrc: null,
            loading: true
        }
    },
    async mounted() {
        await this.previewDocx()
        this.loading = false
    },
    methods: {
        async previewDocx() {
            if (this.fileUrl) {
                const response = await fetch(this.fileUrl)
                const docxBuffer = await response.arrayBuffer()
                const result = await mammoth.convertToHtml({ arrayBuffer: docxBuffer })
                const sanitizedHtml = result.value
                const encodedHtml = encodeURIComponent(sanitizedHtml)

                this.iframeSrc = `data:text/html;charset=utf-8,${encodedHtml}`
            }
        }
    }
}
</script>

<style>

</style>