<template>
    <div class='d-flex flex-column gap-2'>
        <form-checkbox
            :is-checked.sync='config.enabled'
            :disabled='!isEdit'
        >
            <span class='pl-2'>{{ $t("setting.module.SettingDocumentsRemindAboutExpireEndDate.form.enabled") }}</span>
        </form-checkbox>

        <text-field
            v-if='config.enabled'
            :label='$t("setting.module.SettingDocumentsRemindAboutExpireEndDate.form.daysBeforeExpire")'
            rules='required'
            v-model='config.daysBeforeExpire'
            :disabled='!isEdit'
        />
        <employee-multi-select
            v-if='config.enabled'
            :label='$t("setting.module.SettingDocumentsRemindAboutExpireEndDate.form.adminIds")'
            only-admins
            v-model='config.adminIds'
            rules='required'
            :disabled='!isEdit'
        />
    </div>
</template>

<script>
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import TextField from '@/domain/fields/TextField.vue'
import EmployeeMultiSelect from '@/domain/fields/EmployeeMultiSelect.vue'

export default {
    name: 'SettingDocumentsRemindAboutExpireEndDateConfigForm',
    components: { EmployeeMultiSelect, TextField, FormCheckbox },
    props: {
        isEdit: { type: Boolean, default: false },
        config: { type: Object, default: () => ({}) }
    }
}
</script>