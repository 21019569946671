<template>
    <custom-dialog :max-width='470'>
        <template #title>{{ $t('Klausimų personalistui užsakymas') }}</template>
        <template #content>
            <div class='pa-6'>
                <p>Jūs išnaudojote klausimų personalistui limitą, norėdami įsigyti daugiau susisiekite:</p>
                <div class='dtv-modal-contact-line mt-5'>
                    <ui-icon>dtv-phone</ui-icon>
                    <a href='tel:+37052509457'>+370 5 250 9457</a>
                </div>
                <div class='dtv-modal-contact-line my-4'>
                    <ui-icon>dtv-email</ui-icon>
                    <a href='mailto:milda.guobiene@verslozinios.lt'>milda.guobiene@verslozinios.lt</a>
                </div>
            </div>
        </template>
    </custom-dialog>
</template>

<script>
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import dialogMixin from '@/mixins/dialogMixin'
import FormRadio from '@/components/ui/form/FormRadio.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import ValidationForm from '@/domain/components/ValidationForm.vue'
import EmployeeTableView from '@/components/ui/views/EmployeeTableView.vue'
import UiButton from '@/domain/components/UiButton.vue'
import SelectField from '@/domain/fields/SelectField.vue'
import MessageAlerts from '@/domain/messages/MessageAlerts.vue'
import TextField from '@/domain/fields/TextField.vue'
import Form from '@/components/ui/form/Form.vue'
import UiIcon from '@/domain/components/UiIcon.vue'

export default {
    name: 'HrQuestionOrderModal',
    mixins: [dialogMixin],
    components: {
        UiIcon,
        FormRadio,
        FormCheckbox,
        CustomDialog,
        ValidationForm,
        EmployeeTableView,
        UiButton, SelectField, MessageAlerts, TextField, Form
    }
}
</script>