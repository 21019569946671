import store from "@/store";
import customApi from "@/plugins/customApi";
import i18n from "@/plugins/i18n";

export class EmployeeVacationService {
  async init(tag) {
    const api = customApi(tag);
    // const employeeId = store.getters["currentUserStore/userId"];
    const employeeId = 2;

    const responses = await api.requests([
      api.factories.employee.vacation.fetch(employeeId),
      api.factories.widgets.employeeVacationWidget(employeeId),
    ]);

    if (responses == null) {
      return false;
    }

    const [events, widgets] = responses;
    await store.dispatch("employeeVacationsStore/initEvents", events);
    await store.dispatch("employeeVacationsStore/initWidgets", widgets);
    await store.dispatch("loadersStore/hideLoader", tag);

    return true;
  }

  syncWidgets() {
    const api = customApi(null);
    (async () => {
      const employeeId = store.getters["authStore/employeeId"];
      const widgets = await api.request(api.factories.widgets.employeeVacationWidget(employeeId));

      if (widgets == null) {
        return;
      }

      await store.dispatch("employeeVacationsStore/initWidgets", widgets);
    })();
  }

  async create(tag, payload) {
    const api = customApi(tag);
    const employeeId = store.getters["authStore/employeeId"];
    const event = await api.request(api.factories.employee.vacation.store(employeeId, payload));

    if (event == null) {
      return false;
    }

    this.syncWidgets();

    await store.dispatch("employeeVacationsStore/eventCreate", event);

    // TODO: change to domain profile...
    // await store.dispatch("employee/vacations/create", event);

    await store.dispatch("employeeStore/createVacation", event);
    await store.dispatch("loadersStore/hideLoader", tag);
    await store.dispatch("messagesStore/showSuccessMessage", {
      tag, message: i18n.t("Sėkmingai išsiųstas")
    });

    return true;
  }

  async update(tag, id, payload) {
    const api = customApi(tag);
    const employeeId = store.getters["authStore/employeeId"];
    const event = await api.request(api.factories.employee.vacation.update(employeeId, id, payload));

    if (event == null) {
      return false;
    }

    this.syncWidgets();

    await store.dispatch("employeeStore/updateVacation", event);
    await store.dispatch("employeeVacationsStore/eventUpdate", event);

    // TODO: change to domain profile...
    // await store.dispatch("employee/vacations/update", event);

    await store.dispatch("loadersStore/hideLoader", tag);
    await store.dispatch("messagesStore/showSuccessMessage", {
      tag, message: i18n.t("Sėkmingai pakeista")
    });

    return true;
  }

  async change(tag, id, payload) {
    const api = customApi(tag);
    const employeeId = store.getters["authStore/employeeId"];
    const event = await api.request(api.factories.employee.vacation.change(employeeId, id, payload));

    if (event == null) {
      return false;
    }

    this.syncWidgets();

    await store.dispatch("employeeStore/changeVacation", event);;
    await store.dispatch("employeeVacationsStore/eventChange", {id, event});

    // TODO: change to domain profile...
    // await store.dispatch("employee/vacations/change", {id, data: event});

    await store.dispatch("loadersStore/hideLoader", tag);
    await store.dispatch("messagesStore/showSuccessMessage", {
      tag, message: i18n.t("Sėkmingai pakeista")
    });

    return true;
  }

  async abort(tag, id) {
    const api = customApi(tag);
    const employeeId = store.getters["authStore/employeeId"];
    if (await api.request(api.factories.employee.vacation.abort(employeeId, id)) == null) {
      return false;
    }

    this.syncWidgets();

    await store.dispatch("employeeStore/abortVacation", id);
    await store.dispatch("employeeVacationsStore/eventAbort", id);
    await store.dispatch("loadersStore/hideLoader", tag);
    await store.dispatch("messagesStore/showSuccessMessage", {
      tag, message: i18n.t("Sėkmingai atšauktas.")
    });

    return true;
  }

  async abortDeputy(tag, deputyId) {
    const api = customApi(tag);

    if (await api.request(api.factories.employee.vacation.abortDeputy(deputyId)) == null) {
      return false;
    }

    await store.dispatch("employeeVacationsStore/deputyAbort", deputyId);
    await store.dispatch("loadersStore/hideLoader", tag);
    await store.dispatch("messagesStore/showSuccessMessage", {
      tag, message: i18n.t("Sekmingai atmestas pavadavimas.")
    });
    return true;
  }

  async approveDeputy(tag, deputyId) {
    const api = customApi(tag);

    if (await api.request(api.factories.employee.vacation.approveDeputy(deputyId)) == null) {
      return false;
    }

    await store.dispatch("employeeVacationsStore/deputyApprove", deputyId);
    await store.dispatch("loadersStore/hideLoader", tag);
    await store.dispatch("messagesStore/showSuccessMessage", {
      tag, message: i18n.t("Sekmingai patvirtintas pavadavimas.")
    });
    return true;
  }
}

export default new EmployeeVacationService;