<template>
	<div>
		<hra-table
			ref="table"
			:headers="headers"
			:rows="rows"
			:need-actions="hasPermissionToCRUD"
			:need-check-box="hasPermissionToCRUD"
			header-sticky
			:loading="isLoading"
			:checked-row-ids.sync="tableData.selectRowIds"
			class="no-border-around"
			:row-background="rowBackground"
			:rows-cant-check="rows.filter(row => row.dismissed).map(row => row.id)"
		>
			<template #header.custom>
				<div class="pt-9">
					<table-title-view :title="$t('Darbuotojai')" :total="employeesTotal"/>
				</div>
				<div class="d-flex align-end gap-3 my-3">
					<hra-filter :value="filter" @change="onChangeFilter">
						<hra-filter-search :label="$t('Darbuotojų paieška')" name="query"/>
						<hra-filter-limit is-sticky :label="$t('Rodyti darbuotojų puslapyje')" with-all/>
						<hra-filter-select
							v-if="hasPermissionToCRUD"
							name="dismissed"
							:label="$t('Rodyti sąraše darbuotojus')"
							:options="dismissedOptions"
							is-sticky
						/>
					</hra-filter>
					<hra-tooltip
						v-if="tableData.selectRowIds.length > 0"
						:text="$t('Pašalinti pasirinktus darbuotojus')"
					>
						<ui-button
							prepend-icon="employee-delete"
							background="danger-light"
							:modal="{name: 'EmployeeDeleteModal', props: {ids: tableData.selectRowIds}}"
						/>
					</hra-tooltip>
					<hra-tooltip
						v-if="tableData.selectRowIds.length > 0"
						:text="$t('Siųsti pakvetimus pasirinktiems darbuotojams')"
					>
						<ui-button
							prepend-icon="send-email"
							background="primary-light"
							:modal="{name: 'SentInviteModal', props: {ids: tableData.selectRowIds}}"
						/>
					</hra-tooltip>
				</div>
			</template>
			<template #col.profile="{colValue}">
				<employee-table-view class="pa-3" :employee="colValue"/>
			</template>
			<template #col.role="{colValue}">
				<div class="pa-3">{{ colValue }}</div>
			</template>
			<template #col.declension="{colValue}">{{ colValue }}</template>
			<template #col.department="{colValue}">
				<duties-view class="pa-3" :duties="[colValue.name]"/>
			</template>
			<template #col.leader="{colValue, row}">
				<employee-table-view v-if="colValue" class="pa-3" :employee="colValue"/>
			</template>
			<template #col.dismissData="{colValue, row}">
				<hra-tooltip
					v-if="colValue"
					:text="`<b>${colValue.reason.article}</b><br/> ${colValue.reason.description}`"
				>
					<div class="text-center">{{ colValue.date }}</div>
				</hra-tooltip>
				<div v-else/>
			</template>
			<template #col.inviteStatus="{row, colValue}">
				<div class="d-flex justify-center">
					<hra-tooltip v-if="!row.dismissed"
					             :text="inviteStatusText[colValue ? '1' : '-1']"
					>
						<hra-icon v-if="colValue" :name="iconName.ok" :color="uiColor.success"/>

<!--						<ui-button-->
<!--							v-else-->
<!--							prepend-icon="send-email"-->
<!--							background="primary-light"-->
<!--							size="small"-->
<!--							:modal="{name: 'SentInviteModal', props: {ids: [row.id]}}"-->
<!--						/>-->
						<hra-button-modal v-else small :color="uiColor.primaryLight" :size="buttonSize.small"
						                  modal-name="SentInviteModal" :modal-props="{ids: [row.id]}">
							<hra-icon :name="iconName.sendEmail"/>
						</hra-button-modal>
					</hra-tooltip>
				</div>
			</template>
			<template #col.actions-base="{row}">
				<hra-tooltip
					:text="$t(row.dismissed ? 'Informaciją apie darbuotoją' : 'Redaguoti darbuotoją')"
				>
					<hra-button-link
						:color="uiColor.default"
						:size="buttonSize.small"
						:to="{ name: employeeEditName, params: {id: row.id} }"
						:button-permissions="permissionsButtonEmployeeEdit"
					>
						<hra-icon :name="row.dismissed ? iconName.info : iconName.edit"/>
					</hra-button-link>
				</hra-tooltip>

				<hra-tooltip
					v-if="!row.dismissed && row.id !== adminId"
					:text="$t('Atleisti darbuotoją')"
				>
					<hra-button-modal
						:color="uiColor.dangerLight"
						:size="buttonSize.small"
						:button-permissions="permissionsButtonEmployeeDelete"
						modal-name="EmployeeDeleteModal"
						:modal-props="{ids: [row.id]}"
					>
						<hra-icon :name="iconName.employeeDelete"/>
					</hra-button-modal>
				</hra-tooltip>
			</template>
			<template #empty.text>{{ $t('Darbuotojų nėra.') }}</template>
		</hra-table>
		<div class="sticky-content-left my-3 d-flex">
			<hra-pagination :current-page="currentPage" :last-page="lastPage" @changePage="onChangePage"/>
			<v-spacer/>

		</div>
	</div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {
	permissionsWildCard,
	permissionsButtonEmployeeCreate,
	permissionsButtonEmployeeEdit,
	permissionsButtonEmployeeDelete, permissionsButtonEmployeeImport
} from "@/utils/permissions";
import {importRouteProps} from "@/router/import";
import DutiesView from "@/components/ui/views/DutiesView";
import DepartmentsView from "@/components/ui/views/DepartmentsView";
import TableTitleView from "@/components/ui/views/TableTitleView";
import EmployeeTableView from "@/components/ui/views/EmployeeTableView";
import EmployeesDeleteDialog from "@/components/dialogs/employee/EmployeesDeleteDialog";
import {appRouteProps} from "@/router/app";
import EmployeeSendInviteConfirmDialog from "@/components/dialogs/employee/EmployeeSendInviteConfirmDialog";
import HraTable from "@/components/ui/table/HraTable";
import HraPagination from "@/components/ui/pagination/HraPagination";
import paginationMixin from "@/mixins/paginationMixin";
import HraFilter from "@/components/ui/filter/HraFilter";
import HraFilterLimit from "@/components/ui/filter/HraFilterLimit";
import HraFilterSelect from "@/components/ui/filter/HraFilterSelect";
import _ from "lodash";
import HraFilterSearch from "@/components/ui/filter/HraFilterSearch";
import HraButtonLink from "@/components/ui/button/HraButtonLink";
import HraIcon from "@/components/ui/HraIcon";
import HraButton from "@/components/ui/button/HraButton";
import HraTooltip from "@/components/ui/HraTooltip";
import HraButtonModal from "@/components/ui/button/HraButtonModal";
import departmentService from "@/services/departments/departmentService";
import {edit as employeeEditName} from "@/domain/employee/routes";
import UiButton from "@/domain/components/UiButton.vue";

export default {
	mixins: [paginationMixin],
	components: {
		UiButton,
		HraButtonModal,
		HraButton,
		HraIcon,
		HraButtonLink,
		HraFilterSearch,
		HraFilterSelect,
		HraFilterLimit,
		HraPagination,
		HraTable,
		EmployeeSendInviteConfirmDialog,
		EmployeesDeleteDialog,
		EmployeeTableView,
		TableTitleView,
		DepartmentsView,
		DutiesView,
		HraFilter,
		HraTooltip,
	},
	data() {
		return {
			loaderName: "Api-Employees",
			permissionsButtonEmployeeCreate,
			permissionsButtonEmployeeEdit,
			permissionsButtonEmployeeDelete,
			permissionsButtonEmployeeImport,
			permissionsWildCard,
			appRouteProps,
			importRouteProps,
			inviteStatusText: {
				"-1": this.$t("Siųsti pakvietimą užsiregistruoti"),
				"0": this.$t("Siųsti pakvietimą pakartotinai registracijai"),
				"1": this.$t("Priimtas"),
			},
			checkedRowsIds: [],
			employeeEditName,
		};
	},
	inject: ['employeeFetchService'],
	beforeDestroy() {
		this.clear();
	},
	async created() {
		this.changeFilter(this.$route.query);
		this.setPagination(await this.employeeFetchService(this.hasPermissionToCRUD));
	},
	computed: {
		...mapGetters({
			needRefresh: "employees/needRefresh",
			rows: "employees/rows",
			page: "employees/page",
			filter: "employees/filter",
			employeesTotal: "employees/total",
			tableData: "employees/tableData",
			adminId: "user/id",
		}),
		headers() {
			return [
				{name: "profile", style: {maxWidth: "220px"}, text: this.$t("Darbuotojas")},
                this.hasPermissionToCRUD ? {name: "email", style: {maxWidth: "155px"}, text: this.$t("El. paštas")} : null,
				this.hasPermissionToCRUD ? {name: "role", style: {maxWidth: "110px"}, text: this.$t("Paskyros tipas")} : null,
				{name: "department", style: {maxWidth: "150px"}, text: this.$t("Skyrius")},
				// {name: "duties", style: {maxWidth: "150px"}, text: this.$t("Pareigos")},
				{name: "leader", style: {maxWidth: "220px"}, text: this.$t("Vadovas")},
				this.hasPermissionToCRUD
					? {name: "dismissData", style: {maxWidth: "80px", textAlign: "center"}, text: this.$t("Atleidimo data")} :
					null,
				this.hasPermissionToCRUD
					? {
						name: "inviteStatus",
						style: {maxWidth: "140px", textAlign: "center"},
						text: this.$t("Pakvietimas")
					} : null,
			].filter(header => header != null);
		},
		dismissedOptions() {
			return [
				{value: "-1", text: this.$t("Visus")},
				{value: "0", text: this.$t("Dirbančius")},
				{value: "1", text: this.$t("Atleistus")},
			];
		},
		hasPermissionToCRUD() {
			return this.hasPermissionTo([
				...permissionsButtonEmployeeCreate,
				...permissionsButtonEmployeeEdit,
				...permissionsButtonEmployeeDelete
			]);
		},
	},
	methods: {
		...mapMutations({
			load: "employees/load",
			clearEmployees: "employees/clearEmployees",
			clear: "employees/clear",
			changeTableData: "employees/changeTableData",
			clearTableData: "employees/clearTableData",
			changeFilter: "employees/changeFilter",
		}),
		onTest() {
			this.checkedRowsIds = [];
		},
		onSelectRows(rowIds) {
			this.selectRowIds = rowIds;
		},
		rowBackground(row) {
			if (row.dismissed) {
				return '#FFEDF0';
			}

			return '#ffffff';
		},
		async onChangePage(page) {
			await this.$store.dispatch("employees/changePage", page);
			this.setPagination(await this.employeeFetchService(this.hasPermissionToCRUD));
		},
		async onChangeFilter(payload) {
			await this.$store.dispatch("employees/changeFilter", payload);
			this.setPagination(await this.employeeFetchService(this.hasPermissionToCRUD));
		},
		async onChangeLeader(payload) {
			const data = await departmentService.changeLeader(payload.department_id, payload.employee_id);
			await this.$store.dispatch("employees/changeLeader", data);
		},
	},
};
</script>
