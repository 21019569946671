<template>
	<v-app-bar app class="header">
			<div class="header__content gap-3">
				<slot name="left" />
				<v-spacer/>
				<slot name="right" />
			</div>
	</v-app-bar>
</template>

<script>
import HeaderProfile from "./HeaderProfile.vue";
import HeaderIcon from "./HeaderIcon.vue";
import Button from "../button/Button.vue";
import ButtonGroup from "../button/ButtonGroup.vue";
import Dialog from "@/components/ui/dialog/Dialog";
import VacationCreateForm from "@/components/forms/app/vacation/VacationCreateForm";

export default {
    components: {
        VacationCreateForm,
        Dialog,
        HeaderProfile,
        HeaderIcon,
        Button,
        ButtonGroup,
    },
    props: {
        hasSidebar: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            dialog: false,
        };
    },
    computed: {
        classes() {
            let classes = ['header'];

            if (this.hasSidebar) {
                classes.push('has-sidebar');
            }

            return classes;
        },
    },
};
</script>
