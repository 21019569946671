<template>
    <div class='dtv-home-container px-8 mt-5'>
        <div class='dtv-home-container__left'>
            <div class='d-flex align-end mt-4'>
                <h1>Mano užduoti klausimai</h1>
                <div class='dtv-counter pl-3'>{{ questions.length }}</div>
            </div>

            <div class='d-flex flex-column gap-3 my-4'>
                <dtv-question
                    v-for='({content, answer, created, type}, key) in questions'
                    ref='question'
                    :key='key'
                    :type='type'
                    :date='created'
                    :content='content'
                    :answer='answer'
                />
            </div>

        </div>
    </div>
</template>

<script>
import TextareaField from '@/domain/fields/TextareaField.vue'
import UiButton from '@/domain/components/UiButton.vue'
import DtvQuestion from '@/domain/dtv/components/DtvQuestion.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'DtvQuestionsTab',
    components: { DtvQuestion, UiButton, TextareaField },
    data() {
        return {
            message: ''
        }
    },
    computed: {
        ...mapGetters({
            questions: 'dtvStore/homeQuestions'
        })
    }
}
</script>