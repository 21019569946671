<template>
    <div class='d-flex flex-row align-center gap-3'>
        <ui-icon
            class='finder-history-icon'
            :class='{active: hasHistoryPrev}'
            @click='handleHistoryBack'
        >arrow-left
        </ui-icon>
        <ui-icon
            class='finder-history-icon'
            :class='{active: hasHistoryNext}'
            @click='handleHistoryForward'
        >arrow-right
        </ui-icon>
    </div>
</template>

<script>
import UiIcon from '@/domain/components/UiIcon.vue'

export default {
    name: 'DocumentsFinderHeaderArrows',
    components: { UiIcon },
    inject: ['storeNamespace'],
    computed: {
        hasHistoryPrev() {
            return this.$store.getters[`${this.storeNamespace}/hasHistoryPrev`]
        },
        hasHistoryNext() {
            return this.$store.getters[`${this.storeNamespace}/hasHistoryNext`]
        }
    },
    methods: {
        handleHistoryBack() {
            this.$store.dispatch(`${this.storeNamespace}/history/prev`)
        },
        handleHistoryForward() {
            this.$store.dispatch(`${this.storeNamespace}/history/forward`)
        }
    }
}
</script>

<style scoped lang='scss'>
.finder-history-icon {
    cursor: pointer;
    color: #99A6BF80;
    font-size: 2rem;

    &.active {
        color: #70778C;
    }
}
</style>