<template>
    <employee-event-invite-line
        :type-text='typeText'
        :start='start'
        :end='end'
        :with-status='withStatus'
    >
        <template #status>
            <event-status-with-approval-stages
                :id='event.id'
                type='businessTripEvent'
                :status='event.currentStatus'
                :status-text='event.currentStatusText'
            />
        </template>

        <template #actions>
            <hra-tooltip
                v-if='canAbort'
                :text="$t('Atšaukti')"
            >
                <ui-button
                    background='danger-light'
                    :only-hover-background='!isBondMobile'
                    size='small'
                    prepend-icon='close'
                    :confirm='confirmAbort'
                />
            </hra-tooltip>
            <hra-tooltip v-if='canEdit' :text="$t('Redaguoti')">
                <ui-button
                    :only-hover-background='!isBondMobile'
                    size='small'
                    prepend-icon='edit'
                    :modal='{ name: "BusinessTripsModal", props: { id: event.id }, on: { created: changed } }'
                />
            </hra-tooltip>
            <hra-tooltip v-if='canInfo' :text="$t('Informacija')">
                <ui-button
                    :only-hover-background='!isBondMobile'
                    size='small'
                    prepend-icon='info'
                    :modal='{ name: "BusinessTripsModal", props: { id: event.id, readonly: true } }'
                />
            </hra-tooltip>
        </template>
    </employee-event-invite-line>
</template>

<script>
import EmployeeEventInviteLine from '@/domain/eventInvite/components/employee/EmployeeEventInviteLine.vue'
import UiButton from '@/domain/components/UiButton.vue'
import HraTooltip from '@/components/ui/HraTooltip.vue'
import CustomApi from '@/plugins/customApi'
import moment from 'moment/moment'
import { GLOBAL_TOAST_TAG } from '@/utils/default'
import isBondMobileMixin from '@/mixins/isBondMobileMixin'
import EventStatusWithApprovalStages from '@/domain/components/ApprovalStages/EventStatusWithApprovalStages.vue'

const customApi = CustomApi()
export default {
    name: 'EmployeeBusinessTripEmployeeEventInvite',
    components: { EventStatusWithApprovalStages, HraTooltip, UiButton, EmployeeEventInviteLine },
    mixins: [isBondMobileMixin],
    props: {
        invite: { type: Object, required: true },
        withStatus: { type: Boolean, default: false }
    },
    data() {
        return {
            todayDate: moment().format('YYYY-MM-DD'),
            confirmAbort: {
                props: {
                    title: this.$t('Ar tikrai norite atmesti komatiruote?')
                },
                on: {
                    confirmed: (confirm) => this.abort(confirm)
                }
            }
        }
    },
    computed: {
        event() {
            return this.invite.approvalable
        },
        start() {
            return this.event.start
        },
        end() {
            return this.event.end
        },
        typeText() {
            return this.event.typeText
        },
        canAbort() {
            return this.start >= this.todayDate && this.event.currentStatus !== 'aborted'
        },
        canEdit() {
            return this.canAbort
        },
        canInfo() {
            return !this.canEdit
        }
    },
    methods: {
        changed() {
            this.$emit('changed')
        },
        async abort(confirm) {
            try {
                const result = await customApi.factories.events.businessTrip.abort(this.event.id, 'employee')
                if (result == null) {
                    return
                }

                await this.$store.dispatch('loadersStore/hideLoader', confirm.tag)
                await this.$store.dispatch('messagesStore/showSuccessMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Sėkmingai atšauktą.')
                })
                this.$emit('changed', this.invite.id)
            } catch (e) {
                console.error(e)
                await this.$store.dispatch('messagesStore/showErrorMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Nepaviko atšaukti.')
                })
            } finally {
                confirm?.close()
            }
        }
    }
}
</script>