<template>
  <v-dialog v-model="dialog" persistent scrollable :max-width="maxWidth" transition="dialog-top-transition">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot name="button"/>
      </div>
    </template>

    <div class="dialog-confirm" v-if="dialog">
      <div class="dialog-confirm__header px-5" v-if="title">
        <h6>{{ title }}</h6>
      </div>
      <div class="dialog-confirm__content">
        <slot name="content" :dialog="dialog" :closeDialog="closeDialog">
          <p/>
        </slot>
        <div v-if="!hideButtons" class="d-flex justify-center gap-3">
          <div @click.prevent="closeDialog" style="width: 100%">
            <slot name="button.abort">
              <hra-button :size="buttonSize.small" full-width>
                <template #icon>
                  <hra-icon :name="iconName.close"/>
                </template>
                <slot name="button.abort.text">Abort</slot>
              </hra-button>
            </slot>
          </div>
          <div v-if="!hideConfirmButton" @click.prevent="() => onConfirm(closeDialog)" style="width: 100%">
            <slot name="button.confirm">
              <hra-button :color="uiColor.danger" :size="buttonSize.small" full-width>
                <template #icon>
                  <hra-icon :name="iconName.ok"/>
                </template>
                <slot name="button.confirm.text">Confirm</slot>
              </hra-button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import Dialog from "@/components/ui/dialog/Dialog";
import HraButton from "@/components/ui/button/HraButton";
import HraIcon from "@/components/ui/HraIcon";

export default {
  name: "HraDialogConfirm",
  components: {HraIcon, HraButton, Dialog},
  props: {
    maxWidth: {type: Number, default: 400},
    title: {type: String, default: null},
    hideButtons: {type: Boolean, default: false},
    hideConfirmButton: {type: Boolean, default: false},
    value: {type: Boolean, default: false},
  },
  data: () => ({
    dialog: false,
    timeout: null,
  }),
  watch: {
    value: {
      handler(value, oldValue) {
        if (value !== oldValue) {
          this.dialog = value;
        }
      },
      immediate: true,
    },
    dialog: {
      handler(value) {
        clearTimeout(this.timeout);
        // TODO: delete $emits open/close
        this.$emit(value ? "dialogOpen" : "dialogClose");
        this.$emit(value ? "open" : "close");
        this.$emit("input", value);
      },
    },
  },
  methods: {
    closeDialog() {
      this.$emit("abort");
      this.dialog = false;
    },
    onConfirm(closeDialog) {
      this.$emit("confirm", closeDialog);
    },
    closeWithDelay(delay = 4000) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.closeDialog();
      }, delay);
    },
  }
};
</script>
