import { mapGetters, mapMutations } from 'vuex'
import { appRouteProps } from '@/router/app'
import { BUTTON_TYPE, BUTTON_SIZE, BUTTON_TEXT_WIDTH } from '@/mixins/hraButtonMixin'
import { ICON_NAME } from '@/components/ui/HraIcon'
import { GLOBAL_TOAST_TAG, UI_COLORS } from '@/utils/default'
import { FORM_FIELD_TYPE, FORM_FIELD_TYPES } from '@/components/ui/form/HraFormTextField'
import moment from 'moment'
import i18n from '@/plugins/i18n'
import authService from '@/domain/auth/services/authService'
import store from '@/store'

export default {
    data() {
        return {
            language: 'lt',
            loaderName: 'initial',
            errorName: 'initial',
            loadingError: false, // loadingErrorMessage: "",
            // loadingErrorMessage: 'Įvyko klaida.',
            translatePrefix: '',
            yesNoOptions: [{ value: 0, text: i18n.t('Ne') }, { value: 1, text: i18n.t('Taip') }],
            buttonType: BUTTON_TYPE,
            uiColor: UI_COLORS,
            buttonSize: BUTTON_SIZE,
            buttonTextWidth: BUTTON_TEXT_WIDTH,
            iconName: ICON_NAME,
            formFieldType: FORM_FIELD_TYPE,
            formFieldTypes: FORM_FIELD_TYPES
        }
    },
    computed: {
        ...mapGetters({
            globalLoaders: 'global/loaders',
            globalMessages: 'global/messages',
            userName: 'user/name',
            isAuthenticated: 'user/isAuthenticated',
            userPermissions: 'user/permissions'
        }),
        loadingView() {
            // return this.loaders.indexOf(this.loaderName) > -1;
            return false
        },
        isLoading() {
            return this.globalLoaders.includes(this.loaderName)
        },
        loadingMessage() {
            return this.globalMessages.filter(({ name }) => name === this.loaderName)[0] || null
        },
        currentLocale() {
            return i18n.locale
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        ...mapMutations({
            addToLoaderQueue: 'global/addToLoaderQueue', removeFromLoaderQueue: 'global/removeFromLoaderQueue'
        }),
        getErrorMessage(error, defaultMessage) {
            if (typeof error === 'string') {
                return error
            }

            if (error.response && error.response.data && error.response.data.message) {
                if (Array.isArray(error.response.data.message)) {
                    return error.response.data.message[0]
                }

                return error.response.data.message
            }

            if (typeof error?.message === 'string') {
                return error.message
            }

            return defaultMessage
        },
        showSuccessInRootToast(message) {
            this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag: GLOBAL_TOAST_TAG,
                message: message ?? this.$t('Įvykdyta sėkmingai.')
            })
        },
        showErrorInRootToast(error, defaultMessage = null) {
            this.$store.dispatch('messagesStore/showErrorMessage', {
                tag: GLOBAL_TOAST_TAG,
                message: this.getErrorMessage(error, defaultMessage ?? this.$t('Įvyko klaida.'))
            })
        },
        handleResize() {},
        showMessage() {},
        closeMessage() {},
        showLoaderByName() {},
        hideLoaderByName() {},
        async getConfigData() {
            // const response = await this.axiosRequest(appConfigService);
            // if (response) {
            //     this.updateAppConfig(response.data);
            //     return true;
            // }
            // return false;
        },
        showLoader() {
            this.addToLoaderQueue(this.loaderName)
        },
        hideLoader() {
            this.removeFromLoaderQueue(this.loaderName)
        },
        showSuccess(payload) {
            this.showMessage({ message: payload, type: 'successMsg' })
        },
        showError(payload) {
            this.showMessage({ message: payload, type: 'errorMsg' })
        },
        t(value, props = {}) {
            return this.$t(this.translatePrefix + value, props)
        },
        tc(value, count) {
            return this.$tc(this.translatePrefix + value, count)
        },
        hasPermissionTo(hasPermissions) {
            const permissions = this.$store.getters['currentUserStore/permissions']

            if (permissions.indexOf('*') > -1) {
                return true
            }

            if (hasPermissions.indexOf('*') > -1 || hasPermissions.length === 0) {
                return true
            }

            return hasPermissions.filter((permission) => {
                return permissions.indexOf(permission) === -1
            }).length === 0
        }
    }
}
