<template>
  <div class="ui-pagination" v-if="lastPage > 1">
    <ui-button
        v-if="activePage > 1 && lastPage > 4"
        size="small"
        background="white"
        hover-color="primary"
        prepend-icon="arrow-left"
        @click="onChangePage(activePage - 1)"
    />
    <ui-button
        v-for="page in pagesRange"
        :key="page"
        size="small"
        :background="page === activePage ? 'primary' : 'white'"
        hover-color="primary"
        @click="onChangePage(page)"
      >{{ page }}</ui-button>
    <ui-button
        v-if="activePage !== lastPage && lastPage > 4"
        size="small"
        background="white"
        hover-color="primary"
        prepend-icon="arrow-right"
        @click="onChangePage(activePage + 1)"
    />
  </div>
</template>

<script>
import UiIcon from "@/domain/components/UiIcon.vue";
import UiButton from "@/domain/components/UiButton.vue";

export default {
  name: "UiPagination",
  components: {UiButton, UiIcon},
  props: {
    activePage: {type: Number, default: 1},
    lastPage: {type: Number, default: 1},
  },
  computed: {
    pagesRange() {
      if (this.activePage === 1) {
        return this.lastPage > 3 ? 4 : this.lastPage;
      }

      if (this.lastPage < 5) {
        return this.lastPage;
      }

      let startRange = this.activePage - 1;

      if (this.lastPage - this.activePage < 3) {
        startRange = this.lastPage - 3;
      }

      return Array(4).fill(1).map((_, key) => key + startRange);
    },
  },
  methods: {
    onChangePage(page) {
      this.$emit("changePage", page);
    },
  },
}
</script>