import Vue from 'vue'
import moment from 'moment'
import {
    EVENT_HOLIDAY_TYPE_DEPUTY,
    eventHolidayType,
    getDateArray
} from '@/utils/default'
import i18n from '@/plugins/i18n'

const state = () => {
    return {
        minDate: moment().format('YYYY-MM-DD'),
        calendar: {
            editable: true,
            date: moment().startOf('month').format('YYYY-MM')
        },
        currentEventId: null,
        currentEventStatusText: null,
        currentEventStatus: null,
        formData: {
            user: null,
            start: null,
            end: null,
            countries: [],
            travelMethods: [],
            payMethod: 'pay_with_salary',
            message: '',
            approvalToEmployee: false,
            withNotifications: false
        },
        events: [],
        asAdmin: false
    }
}

const getters = {
    minDate: ({ minDate }) => minDate,
    calendarYearMonth: ({ calendar }) => calendar.date,
    calendarEditable: ({ calendar }) => calendar.editable,
    formData: ({ formData }) => formData,
    asAdmin: ({ asAdmin }) => asAdmin,
    currentEventStatusText: ({ currentEventStatusText }) => currentEventStatusText,
    currentEventStatus: ({ currentEventStatus }) => currentEventStatus,
    calendarEvents: ({ events, currentEventId }) => {
        const eventCalendarStatus = {
            'pending': 'wait',
            'approved': 'approve',
            'aborted': 'abort',
        }
        let payload = {};

        events.filter(({ currentStatus, id }) => currentStatus !== 'aborted' && id !== currentEventId)
            .map(event => {
                const start = moment(event.start)
                const end = moment(event.end)
                const status = eventCalendarStatus[event.currentStatus] ?? 'none'
                const type = event.type
                let tooltip = eventHolidayType[event.type] ?? null
                if (event.type === EVENT_HOLIDAY_TYPE_DEPUTY) {
                    tooltip = i18n.t('Pavaduojate <b>{name}</b>', { name: event?.parent?.user?.name ?? '' })
                }
                getDateArray(start, end).map(date => {
                    payload[date] = {
                        isStart: date === event.start,
                        isEnd: date === event.end,
                        tooltip,
                        type,
                        status
                    }
                })
            })

        return payload
    }
}

const actions = {
    setCurrentEvent({ state }, payload) {
        Vue.set(state, 'formData', {
            user: payload.user,
            start: payload.start,
            end: payload.end,
            countries: payload.businessTrip.countries?.map(({ code }) => code) ?? [],
            travelMethods: payload.businessTrip.travelMethods.map(({ id }) => id),
            payMethod: payload.businessTrip.payMethod,
            message: payload.message,
            approvalToEmployee: false,
            withNotifications: false
        })

        Vue.set(state, 'currentEventId', payload.id)
        Vue.set(state, 'currentEventStatusText', payload.currentStatusText)
        Vue.set(state, 'currentEventStatus', payload.currentStatus)
    },
    setEvents({ state }, payload) {
        Vue.set(state, 'events', payload)
    },
    setFormStartDate({ state, dispatch }, payload) {
        Vue.set(state.formData, 'start', payload)

        if (state.formData.end < payload) {
            Vue.set(state.formData, 'end', payload)
        }
    },
    setFormEndDate({ state, dispatch }, payload) {
        Vue.set(state.formData, 'end', payload)
    },
    setCalendarDate({ state }, payload) {
        Vue.set(state.calendar, 'date', payload)
    },
    setFormMessage({ state }, payload) {
        Vue.set(state.formData, 'message', payload)
    },
    toggleSelectDate({ state }, payload) {
        const index = state.formData.dates.indexOf(payload)

        if (index === -1) {
            if (state.formData.start == null || state.formData.start > payload) {
                Vue.set(state.formData, 'start', payload)
            }

            if (state.formData.end == null || state.formData.end < payload) {
                Vue.set(state.formData, 'end', payload)
            }

            state.formData.dates.push(payload)
            return
        }

        state.formData.dates.splice(index, 1)
    },
    updateCurrentField({ commit }, { field, value }) {
        commit('SET_FIELD_VALUE', { field, value })
    }
}

const mutations = {
    SET_FIELD_VALUE(state, { field, value }) {
        Vue.set(state.formData, field, value)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}