<template>
    <history-dialog :max-width='550'>
        <template #title>{{ $t('Trinti grupę') }}</template>
        <template #content>
            <v-row class='pa-3'>
                <v-col cols='12'>
                    <p>{{ $t('Ar tikrai norite trinti grupę?') }}</p>
                </v-col>
            </v-row>
        </template>
        <template #footer>
            <ui-button prepend-icon='close' background='default' @click='close'>{{ $t('Uždaryti') }}</ui-button>
            <ui-button prepend-icon='ok' background='danger' @click='submit' :loading='loading'>{{ $t('Trinti') }}
            </ui-button>
        </template>
    </history-dialog>
</template>

<script>
import HistoryDialog from '@/components/ui/dialog/HistoryDialog.vue'
import UiButton from '@/domain/components/UiButton.vue'
import dialogMixin from '@/mixins/dialogMixin'
import { GLOBAL_TOAST_TAG } from '@/utils/default'
import customApi from '@/plugins/customApi'
import documentsFinderEventsMixin from '@/domain/documents/mixins/documentsFinderEventsMixin'

export default {
    name: 'GroupDeleteModal',
    components: { HistoryDialog, UiButton },
    mixins: [dialogMixin, documentsFinderEventsMixin],
    props: {
        id: { type: Number, required: true }
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        async submit() {
            this.loading = true

            try {
                await customApi().factories.documents.groups.delete(this.id)
                await this.$store.dispatch('messagesStore/showSuccessMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Grupė sėkmingai ištrintas')
                })
                this.$emit('groupDeleted', this.id)
                this.close()
            } catch (error) {
                await this.$store.dispatch('messagesStore/showErrorMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Nepavyko ištrinti grupės')
                })
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style scoped>

</style>