<template>
	<v-row>
		<v-col cols="6">
			<text-field
				name="name"
				:label="$t('Vardas, pavardė')"
				editable
				rules="required"
				@save="onSave"
				v-model="contract.name"
			/>
		</v-col>
		<v-col cols="6">
			<text-field
				name="email"
				:label="$t('El. paštas')"
				editable
				rules="required"
				@save="onSave"
				v-model="contract.email"
			/>
		</v-col>
		<v-col cols="4">
			<text-field
				name="timeCardNumber"
				:label="$t('Tabelio nr.')"
				editable
				rules="required"
				@save="onSaveTimeCardNumber"
				v-model="contract.timeCardNumber"
			/>
		</v-col>
		<v-col cols="4">
			<select-field
				name="departments"
				rules="required"
				:label="$t('Skyrius')"
				multiple
				filtered
				editable
				@save="onSave"
				:options="departmentOptions"
				v-model="contract.departments"
			/>
		</v-col>
		<v-col cols="4">
			<select-field
				name="duties"
				rules="required"
				:label="$t('Pareigos')"
				multiple
				filtered
				editable
				@save="onSave"
				:options="dutyOptions"
				v-model="contract.duties"
			/>
		</v-col>
		<v-col cols="4">
			<text-field
				name="number"
				:label="$t('Darbo sutarties nr.')"
				editable
				rules="required"
				@save="onSaveNumber"
				v-model="contract.number"
			/>
		</v-col>
		<v-col cols="4">
			<datepicker-field
				name="date"
				:label="$t('Darbo sutarties data')"
				editable
				rules="required"
				@save="onSave"
				v-model="contract.date"
			/>
		</v-col>
		<v-col cols="4">
			<datepicker-field
				name="workStartAt"
				:label="$t('Darbo pradžios data')"
				editable
				rules="required"
				@save="onSave"
				v-model="contract.workStartAt"
			/>
		</v-col>
		<v-col cols="4">
			<text-field
				name="workDaysPerWeek"
				:label="$t('Darbo dienų skaičius per savaitę')"
				type="number"
				editable
				rules="required"
				@save="onSave"
				v-model="contract.workDaysPerWeek"
			/>
		</v-col>
		<v-col cols="4">
			<text-field
				name="workHoursPerWeek"
				:label="$t('Darbo laiko norma (val. per savaitę)')"
				type="number"
				editable
				rules="required"
				@save="onSave"
				v-model="contract.workHoursPerWeek"
			/>
		</v-col>
		<v-col cols="4">
			<text-field
				name="vacationDaysPerYear"
				:label="$t('Atostogų skaičius per metus (d.d.)')"
				type="number"
				editable
				rules="required"
				@save="onSave"
				v-model="contract.vacationDaysPerYear"
			/>
		</v-col>
		<v-col cols="4">
			<datepicker-field
				name="vacationBalanceDate"
				:label="$t('Atostogų likutis (data)')"
				editable
				rules="required"
				@save="onSave"
				v-model="contract.vacationBalanceDate"
			/>
		</v-col>
		<v-col cols="4">
			<text-field
				name="vacationBalance"
				:label="$t('Atostogų likutis')"
				type="number"
				step="0.001"
				editable
				rules="required"
				@save="onSave"
				v-model="contract.vacationBalance"
			/>
		</v-col>
	</v-row>
</template>

<script>
import DatepickerField from "@/domain/fields/DatepickerField.vue";
import TextField from "@/domain/fields/TextField.vue";
import {GLOBAL_TOAST_TAG} from "@/utils/default";
import {mapGetters} from "vuex";
import SelectField from "@/domain/fields/SelectField.vue";

export default {
	name: "ImportEmployeeContract",
	components: {SelectField, TextField, DatepickerField},
	props: {
		id: {type: String, required: true},
	},
	inject: ["onExpandRow"],
	computed: {
		...mapGetters({
			departmentOptions: "setupStore/importDepartmentOptions",
			dutyOptions: "setupStore/importDutyOptions",
		}),
		contract() {
			return this.$store.getters["setupStore/importEmployeeData"](this.id, "contract");
		},
	},
	methods: {
		onSave({saveComplete}) {
			saveComplete();
		},
		onSaveTimeCardNumber({value, saveComplete}) {
			if (this.$store.getters["setupStore/issetContractTimeCardNumber"](this.id, value)) {
				this.$store.dispatch("messagesStore/showErrorMessage", {
					tag: GLOBAL_TOAST_TAG,
					message: this.$t("Toks tabelio numeris jau yra"),
				});
				return;
			}
			saveComplete();
		},
		onSaveNumber({value, saveComplete}) {
			if (this.$store.getters["setupStore/issetContractNumber"](this.id, value)) {
				this.$store.dispatch("messagesStore/showErrorMessage", {
					tag: GLOBAL_TOAST_TAG,
					message: this.$t("Toks sutarties numeris jau yra"),
				});
				return;
			}
			saveComplete();
		},
	},
}
</script>