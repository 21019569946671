<template>
	<Form
		ref="form"
		v-model="email"
		@submit="onSubmit"
		:form-loader-name="loaderName"
	>
		<template #form>
			<v-row>
				<v-col cols="12">
					<FormTextField
						rules="required|email"
						v-model="email"
						name="email"
						:label="$t('El. pašto adresas')"
					/>
				</v-col>
				<v-col cols="12">
					<form-alert v-if="message" v-bind="message" />
				</v-col>
			</v-row>
		</template>
		<template #buttons="{loading}">
			<hra-button full-width class="mt-7" :color="uiColor.primary"
			            :loading="loading" :type="buttonType.submit">{{ $t("Siųsti") }}
			</hra-button>
		</template>
	</Form>
</template>

<script>
import Form from "@/components/ui/form/Form.vue";
import FormTextField from "@/components/ui/form/FormTextField.vue";
import Button from "@/components/ui/button/Button.vue";
import HraButton from "@/components/ui/button/HraButton";
import FormAlert from "@/components/ui/form/FormAlert";

export default {
	name: "ForgotPasswordForm",
	components: {
		FormAlert,
		HraButton,
		Form,
		FormTextField,
		Button,
	},
	data() {
		return {
			email: "",
			message: null,
			loaderName: "ForgotPasswordForm"
		};
	},
	inject: ["authService"],
	methods: {
		async onSubmit(email) {
			try {
				this.showLoader();
				this.message = {
					type: "success",
					text: await this.authService.forgotPassword(email),
				};
			} catch (error) {
				this.message = {
					type: "error",
					text: error.message,
				};
			} finally {
				this.hideLoader();
			}
		},
	},
};
</script>
