import {EmployeeService} from "@/domain/employee/services/employeeService";
import customApi from "@/plugins/customApi";
import store from "@/store";
import {generateUuid, GLOBAL_TOAST_TAG, jsonToFormData} from "@/utils/default";
import i18n from "@/plugins/i18n";
import router from "@/router";
import {appRouteProps} from "@/router/app";

class EmployeeCreateService extends EmployeeService {
  async init(tag) {
    const api = customApi(tag);

    const response = await api.requests([
      api.factories.company.departments.fetch(),
      api.factories.company.duties.fetch(),
    ])

    if (response == null) {
      return false;
    }

    const [departments, duties] = response;
    await store.dispatch("employeeStore/initToCreate", {departments, duties});
    await store.dispatch("loadersStore/hideLoader", tag);

    return true;
  }

  async store(tag, payload) {
    const api = customApi(tag);
    const response = await api.request(api.factories.employee.create(jsonToFormData(payload)));

    if (response == null) {
      return;
    }

    // await store.dispatch("messagesStore/addToastTag", tag);
    await store.dispatch("loadersStore/hideLoader", tag);
    await store.dispatch("messagesStore/showSuccessMessage", {
      tag: GLOBAL_TOAST_TAG,
      message: i18n.t("Darbuotojas sukurtas sėkmingai"),
    });
    await router.push({name: appRouteProps.employees.name});
  }

  async changeAvatar(tag, payload) {
    const profile = store.getters["employeeStore/profile"];
    profile.avatarSrc = payload;
    await store.dispatch(`employeeStore/changeProfile`, profile);
    return true;
  }

  async createDisability(tag, payload) {
    const id = generateUuid();
    await store.dispatch(`employeeStore/createDisability`, {id, ...payload,});
    return true;
  }

  async updateDisability(tag, id, payload) {
    await store.dispatch(`employeeStore/updateDisability`, {id, ...payload});
    return true;
  }

  async destroyDisability(tag, id) {
    await store.dispatch(`employeeStore/destroyDisability`, id);
    return true;
  }

  async createChild(tag, payload) {
    const id = generateUuid();
    await store.dispatch(`employeeStore/createChild`, {id, ...payload});
    return true;
  }

  async updateChild(tag, id, payload) {
    await store.dispatch(`employeeStore/updateChild`, {id, ...payload});
    return true;
  }

  async destroyChild(tag, id) {
    await store.dispatch(`employeeStore/destroyChild`, id);
    return true;
  }
}

export default new EmployeeCreateService()