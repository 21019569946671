export default {
    props: {
        buttonPermissions: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        hasPermissions() {
            return this.hasPermissionTo(this.buttonPermissions);
        }
    },
}
