<template>
    <hra-employee-crud-table>
        <hra-table
            :headers='headers'
            :rows='events'
            :need-actions='!dismissed'
            hide-headers
            class='no-border-around'
        >
            <template #header.custom>
                <table-title-view class='mb-3 size-1' :title="$t('Darbuotojo atostogų prašymai')" with-border />
            </template>

            <template #col.typeName='{colValue}'>
                <div class='pe-3 py-6'>{{ colValue }}</div>
            </template>

            <template #col.period='{row: {start, end}}'>
                <div class='px-3 py-6'>
                    <vacation-period-view :from-date='start' :to-date='end' />
                </div>
            </template>

            <template #col.days='{row: {workDays, calendarDays}}'>
                <div class='px-3 py-6'>
                    {{ workDays }} {{ $t('d. d.') }}, {{ calendarDays }} {{ $t('k. d.') }}
                </div>
            </template>

            <template #col.statusName='{colValue}'>
                <div class='px-3 py-6'>{{ colValue }}</div>
            </template>

            <template #col.actions-base='{row}'>
                <template v-if='isProfile'>
                    <!--					<table-actions :id="row.id" :deputy-id="row.deputyId" v-bind="row.actions" />-->
                </template>
                <template v-else>
                    <hra-tooltip
                        v-if='row.currentStatus !== "aborted"'
                        :text="$t('Redaguoti')"
                    >
                        <ui-button
                            size='small'
                            background='primary-light'
                            prepend-icon='edit'
                            :modal='{ name: "VacationModal", props: {asAdmin: true, userId: row.userId, eventId: row.id}}'
                        />
                    </hra-tooltip>
                    <hra-tooltip
                        v-if='row.currentStatus !== "aborted"'
                        :text="$t('Atmesti')"
                    >

                        <base-confirm
                            tag='EVENT_ABORT_TAG'
                            :title="$t('Ar tikrai norite atmesti atostogų prašymą?')"
                            @confirm='onAbort($event, row.id)'
                        >
                            <ui-button size='small' background='danger-light' prepend-icon='close' />
                        </base-confirm>
                    </hra-tooltip>
                    <hra-tooltip
                        v-if='row.currentStatus === "pending"'
                        :text="$t('Tvirtinti')"
                    >
                        <base-confirm
                            tag='EVENT_APPROVE_TAG'
                            :title="$t('Ar tikrai norite patvirtinti atostogų prašymą?')"
                            @confirm='onAccept($event, row.id)'
                        >
                            <ui-button size='small' background='success-light' prepend-icon='ok' />
                        </base-confirm>
                    </hra-tooltip>
                    <ui-button
                        size='small'
                        :modal='{ name: "VacationModal", props: {asAdmin: true, userId: row.userId, eventId: row.id}}'
                    >
                        {{ $t('Plačiau') }}
                    </ui-button>
                    <!--                    <hra-button-modal :size='buttonSize.small' :color='uiColor.default'-->
                    <!--                                      modal-name='VacationDepartmentDetailsModal'-->
                    <!--                                      :modal-props='{vacationId: row.id, employeeUpdateView: true}'>-->
                    <!--                    </hra-button-modal>-->
                </template>
            </template>

            <template #empty.text>{{ $t('Įrašų sistemoje pridėta nebuvo.') }}</template>
        </hra-table>

        <template #buttons v-if='!dismissed'>
            <ui-button
                v-if='!readOnly'
                size='small'
                background='primary'
                :modal='{ name: "VacationModal", props: {asAdmin: !isProfile, userId}}'
            >
                {{ $t('Pridėti') }}
            </ui-button>
        </template>
    </hra-employee-crud-table>
</template>

<script>
import HraEmployeeCrudTable from '@/components/ui/table/HraEmployeeCrudTable'
import HraTable from '@/components/ui/table/HraTable'
import TableTitleView from '@/components/ui/views/TableTitleView'
import HraTooltip from '@/components/ui/HraTooltip'
import HraButtonModal from '@/components/ui/button/HraButtonModal'
import { mapGetters } from 'vuex'
import EmployeeVacationTableActions from '@/views/tables/app/employee/vacation/EmployeeVacationTableActions'
import VacationPeriodView from '@/components/ui/views/VacationPeriodView'
import HraTableEditableInlineTemplate from '@/components/ui/table/HraTableEditableInlineTemplate'
import { STATUS_ABORT, STATUS_PAST, STATUS_WAIT } from '@/store/modules/employee/vacations'
import TableActions from '@/domain/employeeVacations/components/TableActions'
import departmentVacationsService from '@/domain/departmentVacations/services/departmentVacationsService'
import UiButton from '@/domain/components/UiButton.vue'
import customApi from '@/plugins/customApi'
import BaseConfirm from '@/domain/confirms/BaseConfirm.vue'

export default {
    name: 'EmployeeVacationTable',
    components: {
        BaseConfirm,
        UiButton,
        TableActions,
        EmployeeVacationTableActions,
        VacationPeriodView,
        HraEmployeeCrudTable,
        HraTooltip,
        HraTableEditableInlineTemplate,
        HraButtonModal,
        TableTitleView,
        HraTable
    },
    props: {
        isProfile: { type: Boolean, default: false },
        isCreate: { type: Boolean, default: false },
        isEdit: { type: Boolean, default: false }
    },
    data() {
        return {
            STATUS_WAIT,
            STATUS_PAST,
            STATUS_ABORT,
            readOnly: this.isProfile
        }
    },
    computed: {
        ...mapGetters({
            dismissed: 'employeeStore/dismissed',
            profile: 'employeeStore/profile',
            vacations: 'employeeStore/vacations',
            events: 'employeeStore/events',
            userId: 'employeeStore/userId'
        }),
        headers() {
            if (!this.readOnly) {
                return [
                    { name: 'typeName' }, { name: 'period' }, { name: 'statusName' }, { name: 'days' }
                ]
            }

            return [
                { name: 'typeName' }, { name: 'period' }, { name: 'statusName' }
            ]
        }
    },
    methods: {
        async onDelete(event, id) {
            event.showConfirmLoader()
            if (await this.service.destroyDisability('EMPLOYEE_DISABILITY_DESTROY_TAG', id)) {
                event.hideConfirmLoader()
                event.closeConfirm()
            }
        },
        async onAbort(confirm, id) {
            const api = customApi(confirm.tag)
            const event = await api.request(api.factories.department.vacation.admin.abort(id, { message: '' }))
            await this.$store.dispatch('loadersStore/hideLoader', confirm.tag)
            await this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag: confirm.tag,
                message: this.$t('Sėkmingai atšauktas.')
            })
            await this.$store.dispatch('employeeVacationsStore/eventAbort', event)
            await this.$store.dispatch('employeeStore/abortEvent', event)
            await this.$store.dispatch('employeeVacationsStore/updateWidgets')
            confirm?.close()
        },
        async onAccept(confirm, id) {
            const api = customApi(confirm.tag)
            const event = await api.request(api.factories.department.vacation.admin.approve(id, { message: '' }))
            await this.$store.dispatch('loadersStore/hideLoader', confirm.tag)
            await this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag: confirm.tag,
                message: this.$t('Sėkmingai patvirtintos.')
            })
            await this.$store.dispatch('employeeStore/approveEvent', event)
            confirm?.close()
            confirm.showConfirmLoader()
            await departmentVacationsService.approveVacation('EMPLOYEE_APPROVE_VACATION', id)
            confirm.closeConfirm()
        }
    }
}
</script>