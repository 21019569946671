<template>
    <div class='d-flex flex-column gap-2'>
        <text-field
            :label='$t("setting.module.SettingHolidayYearlyMinusDays.form.days")'
            v-model='config.days'
            rules='required|min:0'
            type='number'
            :min='0'
            :disabled='!isEdit'
        />
    </div>
</template>

<script>
import MultilineSelectField from '@/domain/components/MultilineSelectField.vue'
import SelectField from '@/domain/fields/SelectField.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import TextField from '@/domain/fields/TextField.vue'

export default {
    name: 'SettingHolidayYearlyMinusDaysConfigForm',
    components: { TextField, FormCheckbox, SelectField, MultilineSelectField },
    props: {
        isEdit: { type: Boolean, default: false },
        config: { type: Object, default: () => ({}) }
    }
}
</script>