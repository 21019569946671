<template>
    <div class='scheduler-events' :data-view='viewType'>
        <table class='scheduler-table'>
            <thead class='scheduler-header'>
            <tr class='scheduler-row scheduler-row_header-events'>
                <th
                    class='scheduler-column scheduler-column_header scheduler-column-event_header'
                    :colspan='hours.length'
                >
                    <span class='scheduler-header__text-sticky'>{{ title }}</span>
                </th>
            </tr>
            <tr class='scheduler-row scheduler-row_header-events'>
                <th
                    class='scheduler-column scheduler-column_header scheduler-column-event_header header-day'
                    v-for='day in days'
                    :key='day.date'
                    :colspan='day.colspan'
                    :data-date='day.date'
                    :data-weekday='day.weekday'
                    :data-holiday='day.holiday'
                >
                    <span class='scheduler-header__text-sticky'>{{ day.title }}</span>
                </th>
            </tr>
            <tr class='scheduler-row scheduler-row_header-events'>
                <th
                    class='scheduler-column scheduler-column_header scheduler-column-event_header text-left pl-1'
                    v-for='({hour, date}, key) in hours'
                    :key='key'
                >
                    {{ hour }}
                </th>
            </tr>
            <tr class='scheduler-row scheduler-row_header-events-hover'>
                <td :colspan='hours.length' class='scheduler-column_header-events-hover'>
                    <div class='scheduler-events-drop' ref='highlightColumn' />
                </td>
            </tr>
            </thead>
            <tbody
                class='scheduler-body'
            >
            <template v-for='({key}) in sidebarRows'>
                <tr
                    class='scheduler-row'
                    :key='key'
                >
                    <td
                        class='scheduler-column'
                        v-for='({weekday, holiday}, key) in hours'
                        :key='key'
                        :data-weekday='weekday'
                        :data-holiday='holiday'
                    />
                </tr>
            </template>

            <tr class='scheduler-events-row'>
                <td
                    class='scheduler-events-column'
                    :colspan='hours.length'
                    ref='dropArea'
                    @dragover.prevent='onDragOver($event)'
                    @dragenter.prevent='onDragEnter($event)'
                    @dragleave.prevent='onDragLeave($event)'
                    @drop.prevent='onDrop($event)'
                >
                    <div class='scheduler-events-drop' ref='highlightRow' />
                    <schedule-event
                        v-for='event in events'
                        :key='event.id'
                        :id='event.id'
                        :schedule-event-id='event.scheduleEventId'
                        :user-id='event.userId'
                        :shift-id='event.shiftId'
                        :row='event.row'
                        :duration='event.duration'
                        :start='event.start'
                        :color='event.color'
                        :type='event.type'
                        :timestamp='event.timestamp'
                        :row-type='event.rowType'
                        :pixel-to-min='pixelToMin'
                        :move-minutes='moveMinutes'
                        :disable-resizable='disableResizable'
                    />
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import ScheduleEvent from '@/domain/scheduler/components/ScheduleEvent.vue'
import { mapGetters } from 'vuex'
import moment from 'moment/moment'

moment.locale('lt_LT')

export default {
    name: 'SchedulerEvents',
    components: { ScheduleEvent },
    props: {
        moveMinutes: { type: [Number], required: true },
        dayHours: { type: Array, required: true },
        width: { type: Number, default: 40 },
        viewType: { type: String, required: true }
    },
    data() {
        return {
            // startDate: moment('2023-05-01'),
            today: moment().format('YYYY-MM-DD')
        }
    },
    computed: {
        ...mapGetters({
            from: 'schedulerStore/from',
            sidebarRows: 'schedulerStore/sidebarRows',
            events: 'schedulerStore/events',
            holidayDates: 'calendar/holidayDates'
        }),
        title() {
            return this.startDate.format('YYYY (MMMM)')
        },
        startDate() {
            return moment(this.from)
        },
        pixelToMin() {
            return this.width / (this.viewType === 'month' ? 12 : 3) / 60
        },
        disableResizable() {
            return this.dayHoursLength === 2
        },
        days() {
            let start = this.startDate.clone()
            let end = start.clone().endOf('month')

            let days = []

            while (start <= end) {
                const date = start.format('YYYY-MM-DD')

                days.push({
                    title: `${start.format('DD')} (${start.format('ddd')})`,
                    date,
                    hours: this.dayHours,
                    colspan: this.dayHoursLength,
                    weekday: start.isoWeekday(),
                    holiday: this.holidayDates.includes(date)
                })
                start.add(1, 'day')
            }

            return days
        },
        dayHoursLength() {
            return this.dayHours.length
        },
        hours() {
            return this.days
                .map(
                    ({ date, hours, weekday, holiday }) => hours.map(hour => ({
                        date,
                        hour,
                        weekday,
                        holiday
                    }))
                ).flat()
        }
    },
    methods: {
        getDatetimeByX(x) {
            let minutes = x / this.pixelToMin
            return this.startDate.clone().add(minutes, 'minutes')
        },
        onDragOver(event) {
            const { x, y } = this.$refs.dropArea.getBoundingClientRect()

            let datetime = this.getDatetimeByX(event.clientX - x)
            datetime.set('hour', 10)
            datetime.set('minute', 0)

            const moveToX = datetime.diff(this.startDate, 'minutes') * this.pixelToMin
            const width = this.dayHoursLength * 60 * this.pixelToMin

            const Y = (event.clientY - y)
            const row = Y % 40 > 20 ? Math.ceil(Y / 40) : Math.floor(Y / 40)

            this.$refs.highlightRow.style.top = `${row * 40}px`
            this.$refs.highlightColumn.style.left = `${moveToX}px`
            this.$refs.highlightColumn.style.width = `${width}px`
        },
        onDragEnter(event) {
            console.debug('drag enter')
        },
        onDragLeave(event) {
            console.debug('drag leave')
        },
        onDrop(event) {
            const { startHour, startMinute, duration, eventType } = JSON.parse(event.dataTransfer.getData('text'))
            const { x, y } = this.$refs.dropArea.getBoundingClientRect()
            //
            let datetime = this.getDatetimeByX(event.clientX - x)
            datetime.set('hour', startHour)
            datetime.set('minute', startMinute)
            datetime.set('seconds', 0)

            const Y = (event.clientY - y)
            // const rowKey = Y % 40 > 20 ? Math.ceil(Y / 40) : Math.floor(Y / 40)
            const rowKey = Math.max(Math.round(Y / 40), 0)

            const row = this.$store.getters['schedulerStore/sidebarRows'][rowKey]

            // if (row.shiftId == null) {
            //     // this.$store.dispatch('schedulerStore/createEvent', {
            //     //     userId,
            //     //     start: datetime.format('YYYY-MM-DD HH:mm'),
            //     //     end: datetime.add('minutes', duration).format('YYYY-MM-DD HH:mm:ss'),
            //     //     type: eventType
            //     // })
            //     return
            // }

            if (row.shiftId != null) {
                this.$store.dispatch('schedulerStore/createShiftEvent', {
                    shiftId: row.shiftId,
                    start: datetime.format('YYYY-MM-DD HH:mm:ss'),
                    end: datetime.add('minutes', duration).format('YYYY-MM-DD HH:mm:ss'),
                    duration,
                    type: eventType
                })
                return
            }

            this.$store.dispatch('schedulerStore/createEmployeeEvent', {
                userId: row.userId,
                start: datetime.format('YYYY-MM-DD HH:mm:ss'),
                end: datetime.add('minutes', duration).format('YYYY-MM-DD HH:mm:ss'),
                duration,
                type: eventType
            })
        },
        onDratLeave(event, date) {
            event.target.classList.remove('hover')
        },
        onDragEnterRow(event, row) {
            console.debug(event, row)
        },
        onDratLeaveRow(event, row) {
            console.debug(event, row)
        }
    }
}
</script>