<template>
    <div v-if='mode === "grid"' class='documents-items-grid' ref='Grid'>
        <template v-for='item in items'>
            <grid-folder-item
                v-if='item.isFolder'
                :key='`list-folder-${item.id}`'
                :item='item'
            />
            <grid-document-item
                v-if='item.isDocument'
                :key='`list-document-${item.id}`'
                :item='item'
            />
            <grid-archived-document-item
                v-if='item.isArchivedDocument'
                :key='`list-archived-document-${item.id}`'
                :item='item'
            />
        </template>

        <message-alert
            v-if='items.length === 0'
            id='table-alert-empty'
            :message='$t("Tuščias aplankas. Norėdami pridėti dokumentą, paspauskite mygtuką „Įkelti dokumentus“.")'
            type='info'
        />


        <!--        <grid-group-folder-item-->
        <!--            v-for='item in groupsFolders'-->
        <!--            :key='`grid-group-folder-${item.id}`'-->
        <!--            :item='item'-->
        <!--        />-->
        <!--        <grid-group-item-->
        <!--            v-for='item in groups'-->
        <!--            :key='`grid-group-${item.id}`'-->
        <!--            :item='item'-->
        <!--        />-->
    </div>
</template>

<script>
import documentsItemsFoldersMixin from '@/domain/documents/items/mixins/documentsItemsFoldersMixin'
import UiTable from '@/domain/components/uiTable/UiTable.vue'
import GridFolderItem from '@/domain/documents/finder/components/content/grid/GridFolderItem.vue'
import UiButton from '@/domain/components/UiButton.vue'
import GridDocumentItem from '@/domain/documents/finder/components/content/grid/GridDocumentItem.vue'
import GridArchivedDocumentItem from '@/domain/documents/finder/components/content/grid/GridArchivedDocumentItem.vue'
import GridGroupFolderItem from '@/domain/documents/finder/components/content/grid/GridGroupFolderItem.vue'
import GridGroupItem from '@/domain/documents/finder/components/content/grid/GridGroupItem.vue'
import LoadMoreButton from '@/domain/documents/finder/components/content/LoadMoreButton.vue'
import MessageAlert from '@/domain/messages/components/MessageAlert.vue'

export default {
    name: 'DocumentsItemsGrid',
    components: {
        MessageAlert,
        LoadMoreButton,
        GridGroupItem,
        GridGroupFolderItem,
        GridArchivedDocumentItem,
        GridDocumentItem,
        UiButton,
        GridFolderItem,
        UiTable
    },
    mixins: [documentsItemsFoldersMixin],
    data() {
        return {
            width: 1238
        }
    },
    methods: {
        handleChangeSize() {
            this.width = this.width === 1238 ? 720 : 1238
            this.$refs.Grid.style.width = `${this.width}px`
        }
    }
}
</script>

<style scoped lang='scss'>
.documents-items-grid {
    //width: 1238px;
    //width: 720px;
    --grid-columns: 7;
    display: grid;
    grid-template-columns: repeat(var(--grid-columns), calc((100% - 60px) / 7));
    grid-auto-rows: max-content;
    gap: 30px 10px;
    //height: 100%;
    //overflow: auto;

    .message-alert {
        grid-column: span var(--grid-columns);
    }
}
</style>