import {
  SET_IMPORT_DATA,
  SET_IMPORT_DEPARTMENT_OPTIONS,
  SET_IMPORT_DUTY_OPTIONS,
  SET_IMPORT_EMPLOYEES
} from "@/domain/setup/store/mutations";

export const initImportData = ({commit}, payload) => {
  commit(SET_IMPORT_DATA, payload);
}
export const initImportEmployees = ({commit}, payload) => {
  commit(SET_IMPORT_EMPLOYEES, payload);
};
export const initImportDepartmentOptions = ({commit}, payload) => {
  commit(SET_IMPORT_DEPARTMENT_OPTIONS, payload);
};
export const initImportDutyOptions = ({commit}, payload) => {
  commit(SET_IMPORT_DUTY_OPTIONS, payload);
};