export default {
    methods: {
        onOpenModal(name, props = null, on = null) {
            this.$store.dispatch('modals/open', {
                name,
                props: props ?? {},
                on: on ?? {}
            })
        },
        onCloseModal(name) {
            this.$store.dispatch('modals/close', name)
        }
    }
}