<template>
    <leader-event-invite-line
        :days='days'
        :type-text='typeText'
        :end='end'
        :start='start'
        :user='user'
        :employee-message='employeeMessage'
        :expanded='expanded'
        :confirm-abort-text='$t("Ar tikrai norite atmesti nuotolinio darbo prašymą?")'
        :confirm-approve-text='$t("Ar tikrai norite patvirtinti nuotolinio darbo prašymą?")'

        @approve='handleApprove'
        @abort='handleAbort'
        @expand='handleExpand'
    >
        <template #expand>
            <leader-event-invite-line-expend-detail>
                <template #title>{{ $t('Dienų skaičius') }}</template>
                <template #value><b>{{ eventsLength }}</b> {{ $t('k. d.') }}</template>
            </leader-event-invite-line-expend-detail>

            <leader-event-invite-line-expend-detail>
                <template #title>{{ $t('Prašymas išsiųstas') }}</template>
                <template #value>{{ remoteWork.createdAt }}</template>
            </leader-event-invite-line-expend-detail>

            <div style='grid-column: span 2;' v-if='!isBondMobile'>
                <ui-calendar
                    class='py-2 gap-10 align-start'
                    show-select-week-days
                    with-first-month-navigation
                    :max-month-count='2'
                    :year-month.sync='yearMonth'
                    v-bind='calendarProps'
                />
            </div>
        </template>
    </leader-event-invite-line>
</template>

<script>
import UiButton from '@/domain/components/UiButton.vue'
import UiCalendar from '@/domain/components/UiCalendar/UiCalendar.vue'
import moment from 'moment/moment'
import isBondMobileMixin from '@/mixins/isBondMobileMixin'
import { GLOBAL_TOAST_TAG } from '@/utils/default'
import CustomApi from '@/plugins/customApi'
import LeaderEventInviteLine from '@/domain/eventInvite/components/leader/LeaderEventInviteLine.vue'
import LeaderEventInviteLineExpendDetail
    from '@/domain/eventInvite/components/leader/LeaderEventInviteLineExpendDetail.vue'

const customApi = CustomApi()
export default {
    name: 'LeaderRemoteWorkInvite',
    components: { LeaderEventInviteLineExpendDetail, LeaderEventInviteLine, UiCalendar, UiButton },
    mixins: [isBondMobileMixin],
    props: {
        invite: { type: Object, required: true },
        expanded: { type: Boolean, default: false }
    },
    computed: {
        remoteWork() {
            return this.invite.approvalable
        },
        yearMonth() {
            return moment(this.remoteWork.start).format('YYYY-MM')
        },
        eventsLength() {
            return this.remoteWork.events.length
        },
        calendarProps() {
            const selectDates = this.remoteWork.events.map(({start}) => start)

            return {
                selectStartDate: this.start,
                selectEndDate: this.end,
                selectDates
            }
        },
        user() {
            return this.remoteWork.user
        },
        start() {
            return this.remoteWork.start
        },
        end() {
            return this.remoteWork.end
        },
        typeText() {
            return this.$t('Remote work')
        },
        days() {
            return this.eventsLength + ' ' + this.$t('k. d.')
        },
        employeeMessage() {
            return this.remoteWork.message
        }
    },
    methods: {
        showSuccessMessage(message) {
            this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag: GLOBAL_TOAST_TAG,
                message
            })
        },
        showErrorMessage(message) {
            this.$store.dispatch('messagesStore/showErrorMessage', {
                tag: GLOBAL_TOAST_TAG,
                message
            })
        },
        async handleApprove(confirm, message) {
            try {
                const result = await customApi.factories.events.remoteWork.leader
                    .approve(this.invite.id, { message: message ?? '' })

                if (result == null) {
                    throw new Error('Request error')
                }

                this.showSuccessMessage(this.$t('Prašymas patvirtintas'))
                this.$emit('deleted', this.invite.id)
            } catch (e) {
                console.error(e)
                this.showErrorMessage(this.$t('Nepaviko patvirtinti prašymą'))
            } finally {
                confirm.close()
            }
        },
        async handleAbort(confirm, message) {
            try {
                const result = await customApi.factories.events.remoteWork.leader
                    .abort(this.invite.id, { message: message ?? '' })

                if (result == null) {
                    throw new Error('Request error')
                }

                this.showSuccessMessage(this.$t('Prašymas atmestas'))
                this.$emit('deleted', this.invite.id)
            } catch (e) {
                console.error(e)
                this.showErrorMessage(this.$t('Nepaviko atmesti prašymą'))
            } finally {
                confirm.close()
            }
        },
        handleExpand() {
            this.$emit('expand', this.invite.id)
        }
    }
}
</script>