import moment from "moment";

const state = () => ({
  calendarLoading: false,
  isValid: false,
  errorMessages: [],
  infoMessages: [],
  messages: [],
});

const getters = {
  isValid(state) {
    return state.isValid;
  },
  events(state) {
    return state.events;
  },
  messages(state) {
    return state.messages;
  },
  errorMessages: state => state.errorMessages,
  infoMessages: state => state.infoMessages,
};

const mutations = {
  setEvents(state, payload) {
    state.events = payload;
  },
  setIsValid(state, payload) {
    this.isValid = payload;
  },
  setInfoMessages(state, payload) {
    state.infoMessages = payload;
  },
  setErrorMessage(state, payload) {
    state.errorMessages = payload;
  },
  clearData(state) {
    state.isValid = false;
    state.errorMessage = null;
    state.infoMessages = [];
    state.calendarLoading = false;
  },
};

const actions = {
  // check({commit}, {isValid, messages}) {
  //   commit("setMessages", messages);
  //   commit("setIsValid", isValid);
  // }

  setErrorMessage: ({commit}, payload) => {
    if (typeof payload === "string") {
      payload = [payload]
    }

    commit("setErrorMessage", payload);
    commit("setInfoMessages", []);
    commit("setIsValid", false)
  },

  clearErrorMessage: ({commit}) => {
    commit("setErrorMessage", null);
    commit("setIsValid", true);
  },

  setInfoMessages: ({commit}, payload) => {
    commit("setErrorMessage", null);
    commit("setInfoMessages", payload);
    commit("setIsValid", true);
  }


};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
