<template>
    <div :class="`form__${(isHeader ? 'header' : 'footer')}-errors`">
        <FormAlert v-if="isNotValidMessage" type="error" has-scroll-to-error :text="isNotValidMessage" />
        <template v-if="message">
            <FormAlert v-if="message.type === 'error'" has-scroll-to-error type="error" :text="message.message"/>
            <FormAlert v-if="message.type === 'success'" has-scroll-to-error type="success" :text="message.message"/>
        </template>
    </div>
</template>

<script>
import FormAlert from "@/components/ui/form/FormAlert";

export default {
    components: {FormAlert},
    props: {
        isHeader: {
            type: Boolean,
            default: false,
        },
        validateErrors: {
            type: Object,
            default: () => ({}),
        },
        message: {
            type: Object,
            default: null
        },
    },
    data: () => ({
        isNotValidMessage: null,
    }),
    watch: {
        validateErrors: {
            handler() {
                this.changeValidationMessage();
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        changeValidationMessage() {
            this.isNotValidMessage = null;
            if (Object.keys(this.validateErrors).filter(key => this.validateErrors[key].length !== 0).length > 0) {
                this.isNotValidMessage = this.$t('Užpildykite raudonai pažymėtus laukelius');
            }
        }
    }
}
</script>
