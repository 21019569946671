<template>
    <div class='d-flex'>
        <files-upload-field
            :label='$t("Įkelti dokumentą")'
            accept='text/xml'
            @upload='handleChangeFile'
        />
    </div>
</template>

<script>
import HraFormFile from '@/components/ui/form/HraFormFile.vue'
import FormTextarea from '@/components/ui/form/FormTextarea.vue'
import FilesUploadField from '@/domain/fields/FilesUploadField.vue'
import CustomApi from '@/plugins/customApi'

const customApi = CustomApi()
export default {
    name: 'ImportSickLeavesUpload',
    components: { FilesUploadField, FormTextarea, HraFormFile },
    inject: ['storeNamespace'],
    data() {
        return {
            file: null
        }
    },
    methods: {
        handleChangeFile(files) {
            const file = files[0]

            if (file != null) {
                this.$emit('selectedFile', file)
            }
        },
    }
}
</script>