<template>
<!--	<transition-group class="message-alerts" name="slide-y-transition" :duration="{ enter: 700, leave: 100 }" >-->
<!--		<message-alert-->
<!--			v-for="({id, type, shown, message}) in listenMessages"-->
<!--			:key="id"-->
<!--			:shown="shown"-->
<!--			:id="id"-->
<!--			:type="type"-->
<!--			:message="message"-->
<!--		/>-->
<!--	</transition-group>-->

	<div class="message-alerts">
		<message-alert
			v-for="({id, type, shown, message, transitionDelay}) in listenMessages"
			:key="id"
			:shown="shown"
			:id="id"
			:type="type"
			:message="message"
			:transitionDelay="transitionDelay"
		/>
	</div>
<!--	<v-slide-y-transition group tag="div" class="message-alerts">-->
<!--		<template v-for="({id, type, shown, message}) in listenMessages">-->
<!--			<message-alert-->
<!--				:key="id"-->
<!--				:id="id"-->
<!--				:type="type"-->
<!--				:message="message"-->
<!--				:shown="shown"-->
<!--			/>-->
<!--		</template>-->
<!--	</v-slide-y-transition>-->
</template>

<script>
import MessageAlert from "@/domain/messages/components/MessageAlert";
import messagesMixin from "@/domain/messages/mixins/messagesMixin";

export default {
	name: "MessageAlerts",
	components: {MessageAlert},
	mixins: [messagesMixin],
}
</script>

<style lang="scss">
.message-alerts {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 3px;
}
</style>