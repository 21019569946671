<template>
  <custom-dialog class="empVacationModal" :max-width="900" ref="dialog" has-custom-footer>
    <template #title>{{ $t("Atostogų prašymas") }}</template>
    <template #content>
      <loaders :listen="VACATION_MODAL_INIT_TAG"/>
      <vacation-modal-form
          :id="id"
          :service="service"
          :init-payload="initPayload"
          :start-date="startDate"
      >
        <template #buttons>
          <hra-button :color="uiColor.default" @click="close">
            <hra-icon :name="iconName.close"/>
            {{ $t("Uždaryti") }}
          </hra-button>

          <template v-if="editable">
            <base-confirm
                v-if="buttonActions.canAbort"
                :title="$t('Ar tikrai norite atmesti atostogas?')"
                @confirm="onAbort"
            >
              <hra-button :color="uiColor.danger" :loading="formLoading"
                          :disabled="disabledSubmit">
                <hra-icon :name="iconName.close"/>
                {{ $t("Atšaukti") }}
              </hra-button>
            </base-confirm>

            <base-confirm v-if="buttonActions.canChange" no-header @abort="close" @confirm="onChange">
              <template #content>
                <div class="pt-9 pb-6 text-center">
                  {{
                    $t("Atostogos šiomis dienomis jau yra patvirtintos. Jei norite jas pakeisti, šios bus atšauktos ir sukurtos naujos.")
                  }}
                </div>
              </template>

              <hra-button :color="uiColor.primary" :disabled="disabledSubmit">
                <hra-icon :name="iconName.ok"/>
                {{ $t("Pakeisti") }}
              </hra-button>
            </base-confirm>

            <hra-button v-if="buttonActions.canUpdate" :color="uiColor.primary" @click="onUpdate"
                        :loading="formLoading"
                        :disabled="disabledSubmit">
              <hra-icon :name="iconName.ok"/>
              {{ $t("Pakeisti") }}
            </hra-button>

            <hra-button v-if="buttonActions.canCreate" :color="uiColor.primary" @click="onCreate"
                        :loading="formLoading" :disabled="disabledSubmit">
              <hra-icon :name="iconName.ok"/>
              {{ $t("Siųsti prašymą") }}
            </hra-button>
          </template>
        </template>
      </vacation-modal-form>
    </template>
  </custom-dialog>
</template>

<script>
import BaseVacationModal from "@/domain/vacationModal/components/VacationModalForm";
import HraButton from "@/components/ui/button/HraButton";
import HraIcon from "@/components/ui/HraIcon";
import BaseConfirm from "@/domain/confirms/BaseConfirm";
import {mapGetters} from "vuex";
import {
  VACATION_MODAL_ABORT_TAG,
  VACATION_MODAL_CHANGE_TAG,
  VACATION_MODAL_CREATE_TAG,
  VACATION_MODAL_INIT_TAG,
  VACATION_MODAL_UPDATE_TAG
} from "@/domain/vacationModal/tags";
import employeeVacationModalService from "@/domain/vacationModal/services/employeeVacationModalService";
import Loaders from "@/domain/loaders/Loaders";
import CustomDialog from "@/components/ui/dialog/CustomDialog";
import VacationModalForm from "@/domain/vacationModal/components/VacationModalForm";
import dialogMixin from "@/mixins/dialogMixin";

export default {
  name: "EmployeeVacationModal",
  components: {VacationModalForm, CustomDialog, Loaders, BaseConfirm, HraIcon, HraButton, BaseVacationModal},
  mixins: [dialogMixin],
  props: {
    id: {type: [Number, String], default: "new"},
    initPayload: {type: Object, default: null},
  },
  data() {
    return {
      service: employeeVacationModalService,
      VACATION_MODAL_INIT_TAG,
    };
  },
  computed: {
    ...mapGetters({
      editable: "vacationModalStore/editable",
      buttonActions: "vacationModalStore/buttonActions",
      startDate: "vacationModalStore/startDate",
      hasLoading: "loadersStore/hasLoading",
    }),
    formLoading() {
      return false;
    },
    disabledSubmit() {
      if (this.hasLoading(VACATION_MODAL_CREATE_TAG) ||
          this.hasLoading(VACATION_MODAL_UPDATE_TAG) ||
          this.hasLoading(VACATION_MODAL_CHANGE_TAG) ||
          this.hasLoading(VACATION_MODAL_ABORT_TAG)) {
        return true;
      }

      return this.errorMessages != null;
    },
  },
  methods: {
    async onCreate() {
      if (await this.service.create(VACATION_MODAL_CREATE_TAG)) {
        this.close();
      }
    },
    async onUpdate() {
      if (await this.service.update(VACATION_MODAL_UPDATE_TAG)) {
        this.close();
      }
    },
    async onChange() {
      if (await this.service.change(VACATION_MODAL_CHANGE_TAG)) {
        this.close();
      }
    },
    async onAbort() {
      if (await this.service.abort(VACATION_MODAL_ABORT_TAG)) {
        this.close();
      }
    }
  }
}
</script>