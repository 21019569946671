import customApi from '@/plugins/customApi'
import {
    CREATE_DUTY_TAG,
    DESTROY_DUTY_TAG,
    FETCH_DUTIES_TAG,
    FETCH_TREE_TAG,
    UPDATE_DUTY_TAG
} from '@/domain/company/store/tags'
import {
    CREATE_DUTY,
    DESTROY_DUTY,
    EXPAND_DEPARTMENT,
    FETCH_DUTIES,
    FETCH_TREE, SUSPEND_DEPARTMENT,
    UPDATE_DUTY
} from '@/domain/company/store/mutations'
import { GLOBAL_TOAST_TAG } from '@/utils/default'
import i18n from '@/plugins/i18n'

export const fetchTree = async ({ commit, state }) => {
    const api = customApi(FETCH_TREE_TAG)
    const response = await api.request(api.factories.company.departments.tree())

    const links = {}

    let expandedDepartments = []

    function mapTree(item) {
        if (item.children.length > 0) {
            expandedDepartments.push(item.id)
            item.children.forEach(item => mapTree(item))
        }
    }

    const tree = response
    mapTree(tree)

    if (state.expandedDepartments.length !== 0) {
        expandedDepartments = state.expandedDepartments
    }

    commit(FETCH_TREE, { tree, links, expandedDepartments })
}

export const fetchDuties = async ({ commit }) => {
    const api = customApi(FETCH_DUTIES_TAG)
    const response = await api.request(api.factories.company.duties.fetchWithUserCount())

    commit(FETCH_DUTIES, response)
}

export const createDuty = async ({ commit }, payload) => {
    const api = customApi(CREATE_DUTY_TAG)
    const response = await api.request(api.factories.company.duties.create(payload))

    if (response == null) {
        return false
    }

    commit(CREATE_DUTY, response)
    return true
}

export const updateDuty = async ({ commit }, { id, payload }) => {
    const api = customApi(UPDATE_DUTY_TAG)
    const response = await api.request(api.factories.company.duties.update(id, payload))

    if (response == null) {
        return false
    }

    commit(UPDATE_DUTY, response)
    return true
}

export const destroyDuty = async ({ commit, dispatch }, id) => {
    const api = customApi(GLOBAL_TOAST_TAG)
    const response = await api.request(api.factories.company.duties.destroy(id))

    if (response == null) {
        return false
    }

    commit(DESTROY_DUTY, id)
    dispatch('messagesStore/showSuccessMessage', {
        tag: GLOBAL_TOAST_TAG,
        message: i18n.t('Pareigybė sekmingai ištrinta')
    }, { root: true })
}

export const toggleDepartment = ({ commit, state }, id) => {
    if (state.expandedDepartments.includes(id)) {
        commit(SUSPEND_DEPARTMENT, id)
        return
    }

    commit(EXPAND_DEPARTMENT, id)
}

export const expandDepartment = ({ commit }, id) => {
    commit(EXPAND_DEPARTMENT, id)
}


export const suspendDepartment = ({ commit }, id) => {
    commit(SUSPEND_DEPARTMENT, id)
}