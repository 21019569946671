<template>
  <v-row class='fit-content'>
    <v-col cols='12' class='px-8  max-page-width'>
      <div class='d-flex gap-3 pt-9'
           v-if='hasPermissionTo([...permissionsButtonEmployeeCreate, ...permissionsButtonEmployeeImport])'>
        <hra-button-link
            :color='uiColor.primaryLight'
            :to='{ name: employeeCreatePageName }'
            :button-permissions='permissionsButtonEmployeeCreate'
            :size='buttonSize.small'
        >
          <template #icon>
            <hra-icon :name='iconName.plus' />
          </template>
          {{ $t('Naujas darbuotojas') }}
        </hra-button-link>
        <v-spacer />
      </div>
      <EmployeeTable />
    </v-col>
  </v-row>
</template>

<script>
import Button from '@/components/ui/button/Button'
import {
  permissionsWildCard,
  permissionsButtonEmployeeCreate,
  permissionsButtonEmployeeImport
} from '@/utils/permissions'
import { importRouteProps } from '@/router/import'
import { appRouteProps } from '@/router/app'
import EmployeeTable from '@/views/tables/app/employees/EmployeeTable'
import HraButton from '@/components/ui/button/HraButton'
import HraIcon from '@/components/ui/HraIcon'
import HraButtonLink from '@/components/ui/button/HraButtonLink'
import HraButtonModal from '@/components/ui/button/HraButtonModal'
import ModalsProvider from '@/views/modals/ModalsProvider'
import employeeFetchService from '@/services/employees/employeeFetchService'
import { create as employeeCreatePageName } from '@/domain/employee/routes'

export default {
  name: 'Employees',
  components: { ModalsProvider, HraButtonModal, HraButtonLink, HraIcon, HraButton, EmployeeTable, Button },
  data: () => ({
    permissionsWildCard,
    permissionsButtonEmployeeCreate,
    permissionsButtonEmployeeImport,
    appRouteProps,
    importRouteProps,
    employeeCreatePageName
  }),
  provide() {
    return {
      employeeFetchService
    }
  }
}
</script>
