<template>
    <step-container :title='$t("Pasirinkite dokumentus")'>
        <v-col cols='12'>
            <selected-documents-list
                :selected-documents='selectedDocuments'
                @addDocuments='handleAddDocuments'
                @removeDocument='handleRemoveDocument'
            />
        </v-col>
    </step-container>
</template>

<script>
import SelectedDocumentsList from '@/domain/documents/components/sending/documentsSelection/SelectedDocumentsList.vue'
import StepContainer from '@/domain/documents/modals/items/documentSendModal/components/StepContainer.vue'

export default {
    name: 'DocumentsSelectStep',
    components: { StepContainer, SelectedDocumentsList },
    inject: ['storeNamespace'],
    computed: {
        selectedDocuments() {
            return this.$store.getters[`${this.storeNamespace}/selectedDocuments`]
        }
    },
    methods: {
        handleAddDocuments(documents) {
            this.$store.dispatch(`${this.storeNamespace}/setDocuments`, documents)
            this.$store.dispatch(`${this.storeNamespace}/clearAssignUsersToDocumentsRows`)
        },
        handleRemoveDocument(document) {
            this.$store.dispatch(`${this.storeNamespace}/removeDocument`, document.id)
            this.$store.dispatch(`${this.storeNamespace}/clearAssignUsersToDocumentsRows`)
        }
    }
}
</script>


<style scoped lang='scss'>
.selected-users-list {
    &__item {
        border: 1px solid #DAD5E9;
        border-bottom: 0;
        display: flex;
        flex-direction: row;
        padding: 10px 20px;
        align-items: center;
        min-height: 64px;
        gap: 16px;

        &:first-child {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }

        &:last-child {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            border-bottom: 1px solid #DAD5E9;
        }

        &:nth-child(even) {
            background: #FAF9FF;
        }

        &-icon {
            & > .ui-icon {
                color: #8689A8;
                font-size: 1.7rem;
            }
        }

        &-title {
            color: #1C2538;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        &-checkbox {
            margin-left: auto;
        }

        &-button {
            color: #70778C;
            font-size: 1.4rem;
            cursor: pointer;

            &:hover {
                color: #E7384F;
            }
        }
    }
}

.selected-users-list-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__left {
        color: #70778C;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;

        b {
            color: #C644A5;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 14px;
            letter-spacing: 0.42px;
            text-transform: uppercase;
        }
    }

    &__right {

    }
}
</style>