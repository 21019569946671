import AppLayout from "@/layouts/AppLayout";
import EmployeeCreatePage from "@/domain/employee/pages/Create";
import EmployeeProfilePage from "@/domain/employee/pages/Profile";
import EmployeeEditPage from "@/domain/employee/pages/Edit";
import {permissionsPageEmployeeEdit} from "@/utils/permissions";

export const create = "EmployeeCreatePage";
export const profile = "EmployeeProfilePage";
export const edit = "EmployeeEditPage";

export default [
  {
    path: "/employee",
    component: AppLayout,
    children: [
      {name: create, path: "create", component: EmployeeCreatePage},
      {name: profile, path: "profile", component: EmployeeProfilePage},
      {name: edit, path: ":id", component: EmployeeEditPage, meta: {permissions: permissionsPageEmployeeEdit}},
    ]
  }
]