<template>
    <div class='remote-work-form'>
        <v-row>
            <v-col cols='6'>
                <datepicker-field
                    :label="$t('Data nuo')"
                    :min-date='minDate'
                    :value='formData.start'
                    @update:value='handleChangeFormStartDate'
                />
            </v-col>
            <v-col cols='6'>
                <datepicker-field
                    :label="$t('Data iki')"
                    :min-date='formData.start'
                    :value='formData.end'
                    @update:value='handleChangeFormEndDate'
                />
            </v-col>
            <v-col cols='12'>
                <div class='BaseField__label'>{{ $t('Kartoti savaitės dienom:') }}</div>
                <div class='d-flex flex-wrap gap-3 mt-2'>
                    <form-checkbox
                        v-for='({name, label}) in formWeekdays'
                        :key='`form-weekday-${name}`'
                        :is-checked='formData.weekdays[name]'
                        @update:isChecked='(value) => handleChangeFormWeekday(name, value)'
                    >
                        <span class='ml-2'>{{ label }}</span>
                    </form-checkbox>
                </div>
            </v-col>
            <v-col
                v-if='settingRemoteWorkAddressField.value !== "disabled"'
                cols='12'
            >
                <textarea-field
                    name='address'
                    :label="$t('Nuotolinio darbo vieta')"
                    :value='formData.address'
                    @update:value='handleChangeFormAddress'
                />
            </v-col>
            <v-col cols='12'>
                <textarea-field
                    name='message'
                    :label="$t('Žinutė')"
                    :value='formData.message'
                    @update:value='handleChangeFormMessage'
                />
            </v-col>
        </v-row>
        <message-alerts class='px-3' :listen='["RemoteWorkModalTag"]'/>
    </div>
</template>

<script>
import TextareaField from '@/domain/fields/TextareaField.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import DatepickerField from '@/domain/fields/DatepickerField.vue'
import MessageAlerts from '@/domain/messages/MessageAlerts.vue'

export default {
    name: 'RemoteWorkForm',
    components: { MessageAlerts, DatepickerField, FormCheckbox, TextareaField },
    inject: ['storeNamespace'],
    data() {
        return {
            formWeekdays: [
                {name: 'monday', label: this.$t('Pirmadienis')},
                {name: 'tuesday', label: this.$t('Antradienis')},
                {name: 'wednesday', label: this.$t('Trečiadienis')},
                {name: 'thursday', label: this.$t('Ketvirtadienis')},
                {name: 'friday', label: this.$t('Penktadienis')},
                {name: 'saturday', label: this.$t('Šeštadienis')},
                {name: 'sunday', label: this.$t('Sekmadienis')},
            ]
        }
    },
    computed: {
        settingRemoteWorkAddressField() {
            return this.$store.getters['currentUserStore/configs'].settingRemoteWorkAddressField
        },
        minDate() {
            return this.$store.getters[`${this.storeNamespace}/minDate`]
        },
        formData() {
            return this.$store.getters[`${this.storeNamespace}/formData`]
        }
    },
    methods: {
        async handleChangeFormStartDate(value) {
            await this.$store.dispatch(`${this.storeNamespace}/formChangeStartDate`, value)
        },
        async handleChangeFormEndDate(value) {
            await this.$store.dispatch(`${this.storeNamespace}/formChangeEndDate`, value)
        },
        handleChangeFormWeekday(weekday, value) {
            this.$store.dispatch(`${this.storeNamespace}/formToggleWeekday`, {weekday, value})
        },
        handleChangeFormMessage(value) {
            this.$store.dispatch(`${this.storeNamespace}/formSetMessage`, value)
        },
        handleChangeFormAddress(value) {
            this.$store.dispatch(`${this.storeNamespace}/formAddress`, value)
        },
    }
}
</script>