<template>
    <document-sidebar-container
        :loading='loading'
        :title='$t("sugrupuoti dokumentai")'
    >
        <template #header>
            <document-title-view
                class='pe-3'
                :title='item.title'
                icon='group'
            />
        </template>

        <ui-table
            class='mt-8'
            :loading-more='loadingMore'
            :visible-load-more='hasMore'
            :is-empty-table='isEmpty'
            @clickLoadMore='handleLoadMore'
        >
            <template #head>
                <tr>
                    <th>{{ $t('Dokumentai') }}</th>
                </tr>
            </template>
            <template #body>
                <tr v-for='row in rows' :key='`row-id-${row.id}`'>
                    <td>
                        <div class='d-flex align-center flex-row gap-2'>
                            <ui-icon style='color: #70778C; font-size: 22px;'>paper</ui-icon>
                            <span>{{ row.document.title }}</span>
                        </div>
                    </td>
                </tr>
            </template>
        </ui-table>
    </document-sidebar-container>
</template>

<script>
import DocumentSidebarContainer from '@/domain/documents/components/DocumentSidebarContainer.vue'
import DocumentTitleView from '@/domain/documents/components/DocumentTitleView.vue'
import UiButton from '@/domain/components/UiButton.vue'
import CustomApi from '@/plugins/customApi'
import UiTable from '@/domain/components/uiTable/UiTable.vue'
import UiIcon from '@/domain/components/UiIcon.vue'

const customApi = CustomApi()
export default {
    name: 'DocumentGroupSidebar',
    components: { UiIcon, UiTable, UiButton, DocumentTitleView, DocumentSidebarContainer },
    props: {
        item: { type: Object, required: true }
    },
    data() {
        return {
            loading: false,
            loadingMore: false,
            hasMore: false,
            page: 1,
            limit: 20,
            rows: []
        }
    },
    computed: {
        isEmpty() {
            return !this.loading && !this.loadingMore && this.rows.length === 0
        }
    },
    mounted() {
        this.load()
    },
    methods: {
        async load() {
            this.loading = true
            await this.fetch()
            this.loading = false
        },
        async handleLoadMore() {
            this.loadingMore = true
            await this.fetch(this.page + 1)
            this.loadingMore = false
        },
        async fetch(page = null) {
            try {
                const { data: { items, pagination } } = await customApi.factories.documents.groups
                    .fetchDocuments(this.item.id, {
                        page: page,
                        limit: this.limit
                    })

                this.rows = page == null ? items : [...this.rows, ...items]
                this.page = pagination.currentPage
                this.hasMore = pagination.currentPage < pagination.lastPage
            } catch (e) {

            }
        }
    }
}
</script>