<template>
    <div class='d-flex gap-2'>
        <document-sent-status
            :status='status'
            :date='date'
        >
            <template #append>
                <document-comment-tooltip v-if='!!comment' class='mt-2' :text='comment' />
            </template>
        </document-sent-status>
    </div>
</template>

<script>
import HraTooltip from '@/components/ui/HraTooltip.vue'
import DocumentSentStatus from '@/domain/documents/components/DocumentSentStatus.vue'
import DocumentCommentTooltip from '@/domain/documents/components/DocumentCommentTooltip.vue'

export default {
    name: 'DocumentSentStatusWithComment',
    components: { DocumentCommentTooltip, DocumentSentStatus, HraTooltip },
    props: {
        status: {type: String, required: true},
        date: {type: String, default: null},
        comment: {type: String, default: null}
    }
}
</script>