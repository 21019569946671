<template>
    <custom-dialog :max-width='900' ref='dialog' has-custom-footer>
        <template #title>{{ $t('Remote work application') }}</template>
        <template #content>
            <div
                class='d-flex flex-row px-6 gap-6'
                :class='{
                    "flex-row": !isBondMobile,
                    "flex-column": isBondMobile,
                    "align-center": isBondMobile
                }'
            >

                <remote-work-calendars class='mt-8' :max-month-count='isBondMobile ? 1 : 2' />
                <validation-observer ref='ValidationObserver' tag='div'>
                    <remote-work-form class='mt-5' />
                </validation-observer>
            </div>
        </template>
        <template #footer>
            <ui-button prepend-icon='close' background='default' @click='close'>{{ $t('Uždaryti') }}</ui-button>
            <ui-button v-if='!!id' prepend-icon='ok' background='primary' @click='handleChange'>{{ $t('Pakeisti') }}
            </ui-button>
            <ui-button v-else prepend-icon='ok' background='primary' @click='handleSave'>{{ $t('Siųsti prašymą') }}
            </ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import dialogMixin from '@/mixins/dialogMixin'
import FormRemoteWork from '@/components/forms/FormRemoteWork.vue'
import UiButton from '@/domain/components/UiButton.vue'
import { MODAL_REMOTE_WORK } from '@/tags/tagsRemoteWork'
import UiCalendar from '@/domain/components/UiCalendar/UiCalendar.vue'
import store from '@/domain/remoteWork/stores/remoteWorkModalStore'
import DatepickerField from '@/domain/fields/DatepickerField.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import TextareaField from '@/domain/fields/TextareaField.vue'
import FormTextarea from '@/components/ui/form/FormTextarea.vue'
import RemoteWorkForm from '@/domain/remoteWork/components/RemoteWorkForm.vue'
import RemoteWorkCalendars from '@/domain/remoteWork/components/RemoteWorkCalendars.vue'
import customApi from '@/plugins/customApi'
import i18n from '@/plugins/i18n'
import { getDateArray, GLOBAL_TOAST_TAG } from '@/utils/default'
import isBondMobileMixin from '@/mixins/isBondMobileMixin'
import moment from 'moment'
import {ValidationObserver} from 'vee-validate'

const storeNamespace = 'remoteWorkModalStore'

export default {
    name: 'RemoteWorkModal',
    tag: MODAL_REMOTE_WORK,
    components: {
        RemoteWorkCalendars,
        RemoteWorkForm,
        FormTextarea,
        TextareaField,
        FormCheckbox,
        DatepickerField,
        UiCalendar,
        UiButton,
        FormRemoteWork,
        CustomDialog,
        ValidationObserver
    },
    props: {
        asAdmin: { type: Boolean, default: false },
        userId: { type: [Number, String], required: true },
        id: { type: Number, default: null },
        start: { type: String, default: null },
        end: { type: String, default: null }
    },
    mixins: [dialogMixin, isBondMobileMixin],
    created() {
        this.load()
    },
    provide() {
        return {
            storeNamespace: storeNamespace
        }
    },
    beforeCreate() {
        this.$store.registerModuleSafely(storeNamespace, store)
    },
    beforeDestroy() {
        this.$store.unregisterModuleSafely(storeNamespace)
    },
    methods: {
        getPayload() {
            let payload = this.$store.getters[`${storeNamespace}/formData`]

            if (payload.message === '' || payload.message == null) {
                delete payload.message
            }

            if (payload.address === '' || payload.address == null) {
                delete payload.address
            }

            payload.userId = this.userId

            return payload
        },
        async load() {
            await this.$store.dispatch(`${storeNamespace}/fetchEvents`, this.userId)
            await this.setCalendarDates(this.start, this.end)


            if (this.id != null) {
                await this.fetch()
            }
        },
        async setCalendarDates(start, end) {
            if (start == null || end == null) {
                return
            }

            start = start ?? end
            end = end ?? start

            await this.$store.dispatch(`${storeNamespace}/formChangeStartDate`, start)
            await this.$store.dispatch(`${storeNamespace}/formChangeEndDate`, end)

            const dates = getDateArray(moment(start), moment(end))
            await this.$store.dispatch(`${storeNamespace}/formSetDates`, dates)
        },
        async fetch() {
            try {
                const api = customApi(null)
                const { data } = await api.factories.events.remoteWork.fetchOne(this.id)
                await this.$store.dispatch(`${storeNamespace}/initFormData`, data)
            } catch (e) {
                console.error(e)
            }
        },
        async handleSave() {
            const api = customApi('RemoteWorkModalTag')
            const result = await api.request(api.factories.events.remoteWork.store(this.getPayload()))
            if (result == null) {
                return
            }

            await this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag: GLOBAL_TOAST_TAG, message: i18n.t('Sėkmingai išsiųstas')
            })
            this.$emit('changed')
            this.close()
        },
        async handleChange() {
            const api = customApi('RemoteWorkModalTag')
            const result = await api.request(api.factories.events.remoteWork.change(this.id, this.getPayload()))
            if (result == null) {
                return
            }

            await this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag: GLOBAL_TOAST_TAG, message: i18n.t('Sėkmingai išsiųstas')
            })
            this.$emit('changed')
            this.close()
        }
    }
}
</script>