<template>
    <div class='dialog__main-calendar'>
        <employee-table-view v-if='fields.user.visible' class='mb-4' :employee='user' />
        <ui-calendar
            with-first-month-navigation
            max-month-count='2'
            :year-month.sync='yearMonth'
            :min-select-date='minDate'
            :events='calendarEvents'
            :select-start-date='currentEvent.start'
            :select-end-date='currentEvent.end'
            :clickable-dates='editableCalendar'
            disable-mobile-click
            @update:selectStartDate='onChangeField($event, "start")'
            @update:selectEndDate='onChangeField($event, "end")'
        />
    </div>
</template>

<script>
import UiCalendar from '@/domain/components/UiCalendar/UiCalendar.vue'
import { currentEvent, sendInviteForEmployeeAndLeader } from '@/domain/vacationModal/store/getters'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
    name: 'VacationRequestCalendars',
    components: {
        UiCalendar,
    },
    data() {
        return {
            formData: this.$store.state.vacationModalStore.currentEvent,
            yearMonth: null,
        }
    },
    watch: {
        startCalendarDate: {
            handler(value) {
                this.yearMonth = (value ? moment(value) : moment()).format('YYYY-MM')
            },
            immediate: true
        }
    },
    computed: {
        ...mapGetters({
            asAdmin: 'vacationModalStore/asAdmin',
            typeOptions: 'vacationModalStore/typeOptions',
            fields: 'vacationModalStore/fields',
            user: 'vacationModalStore/user',
            calendarEvents: 'vacationModalStore/calendarEvents',
            currentEvent: 'vacationModalStore/currentEvent',
            sendInviteForEmployeeAndLeader: 'vacationModalStore/sendInviteForEmployeeAndLeader',
            editableCalendar: 'vacationModalStore/editableCalendar',
            holidayDates: 'calendar/holidayDates'
        }),
        createBeforeDays() {
            return this.$store.getters['currentUserStore/settings']?.holiday
                ?.settingHolidayAllCreateBeforeWorkDays?.config.days ?? 0
        },
        minDate() {
            if (this.asAdmin) {
                return null
            }

            let date = moment()
            let i = 0
            const dateMethod = this.createBeforeDays < 0 ? 'subtract' : 'add'

            while (i < Math.abs(this.createBeforeDays)) {
                date = date[dateMethod](1, 'days')

                if (this.isDateNotHolidayAndWeekend(date)) {
                    i++
                }
            }

            return date.format('YYYY-MM-DD')
        },
        startCalendarDate() {
            return this.currentEvent.start
        },
    },
    methods: {
        isDateNotHolidayAndWeekend(date) {
            return ![6, 7].includes(date.isoWeekday()) && !this.holidayDates.includes(date.format('YYYY-MM-DD'))
        },
        onChangeField(value, field) {
            if (!this.editableCalendar) {
                return
            }

            this.$store.commit('vacationModalStore/UPDATE_CURRENT_EVENT', { field, value })
        }
    }
}
</script>