<template>
    <td class="hra-table__body-col hra-table__body-col_error">
        <div :class="['error-td', count > 0 && 'has-error']" />
        <div v-if="count > 0" class="error-td__count">{{count}}</div>
    </td>
</template>

<script>
export default {
    name: "HraTableInlineEditErrorCol",
    props: {
        count: {type: Number, default: 0},
    },
}
</script>
