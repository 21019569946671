import LoginPage from "@/domain/auth/pages/Login";
import RegisterPage from "@/domain/auth/pages/Register";
import ResetPasswordPage from "@/domain/auth/pages/ResetPassword";
import EnableTwoFactorPage from "@/domain/auth/pages/EnableTwoFactor";
import TwoFactorPage from "@/domain/auth/pages/TwoFactor";
import ForgotPasswordPage from "@/domain/auth/pages/ForgotPassword";
import Auth2Layout from "@/layouts/Auth2Layout";

export const login = "LoginPage";
export const register = "RegisterPage";
export const resetPassword = "ResetPasswordPage";
export const enableTwoFactor = "EnableTwoFactorPage";
export const towFactor = "TwoFactorPage";
export const forgotPassword = "ForgotPasswordPage";

export default [
  {
    path: "/auth",
    component: Auth2Layout,
    children: [
      {name: login, path: "", component: LoginPage, meta: {canGuest: true}},
      {name: register, path: "register/:token", component: RegisterPage, meta: {canGuest: true}},
      {name: resetPassword, path: "reset/password/:token", component: ResetPasswordPage, meta: {canGuest: true}},
      {name: towFactor, path: "two-factor", component: TwoFactorPage, meta: {canGuest: true}},
      {name: enableTwoFactor, path: "two-factor/enable", component: EnableTwoFactorPage},
      {name: forgotPassword, path: "forgot", component: ForgotPasswordPage, meta: {canGuest: true}},
    ]
  }
]