<template>
	<Auth>
		<template #header>{{ $t("Prisijungti") }}</template>
		<template #form>
			<LoginForm/>
		</template>
		<template #footer>
<!--			<div class="d-flex flex-column gap-3">-->
<!--				<div class="auth__or-text">{{ $t("arba prisijungti su") }}</div>-->
<!--				<div class="d-flex justify-center gap-3">-->
<!--					<hra-button :color="uiColor.default" full-width>-->
<!--						<img :src="images.google"/>-->
<!--					</hra-button>-->

<!--					<hra-button :color="uiColor.default" full-width>-->
<!--						<img :src="images.microsoft"/>-->
<!--					</hra-button>-->
<!--				</div>-->
<!--			</div>-->
		</template>
	</Auth>
</template>

<script>
import Auth from "@/components/Auth.vue";
import LoginForm from "@/views/forms/auth/LoginForm.vue";
import Button from "@/components/ui/button/Button.vue";
import HraButton from "@/components/ui/button/HraButton";

export default {
	components: {
		HraButton,
		Auth,
		LoginForm,
		Button,
	},
	data() {
		return {
			images: {
				google: require("../../../assets/icons/google.svg"),
				microsoft: require("../../../assets/icons/microsoft.svg"),
			},
		};
	},
};
</script>
