<template>
    <div class='waiting-empty'>
        <div class='waiting-empty__texts'>
            <div class='waiting-empty__text'>{{ $t('Nepatvirtintų atostogų prašymų neturite') }}</div>
            <div
                v-if='comingVacation'
                class='waiting-empty__text active'
                v-html="$t('Artimiausios patvirtintos atostogos nuo <span>{fromDate} iki {toDate}</span>',
				{fromDate: comingVacation.start, toDate: comingVacation.end})"
                @click='onClickComingVacation'
            />
        </div>
        <img
            :class='`waiting-empty__image waiting-empty__image_${image}`'
            :src='imageSrc'
            alt='empty'
        />
    </div>
</template>

<script>

export default {
    name: 'WaitingEmpty',
    props: {
        comingVacation: { type: Object, default: null }
    },
    data: function() {
        const image = this.getRandomImageKey()

        return {
            image,
            imageSrc: `/assets/images/vacations/vacation-image-${image}.png`
        }
    },
    methods: {
        onClickComingVacation() {
            this.$store.dispatch('modals/open', {
                name: 'VacationModal',
                props: { userId: this.comingVacation.userId, eventId: this.comingVacation.id }
            })
        },
        getRandomImageKey() {
            const localStorageKey = 'employee-vacation-image'
            const nowTimestamp = (new Date()).getTime()
            const randomLiveTimeMax = 24 * 60 * 60 * 1000
            const availableImageKeys = [1, 2, 3, 4, 5]

            try {
                let payload = JSON.parse(localStorage.getItem(localStorageKey))

                if (availableImageKeys.includes(payload.key) && payload.timestamp + randomLiveTimeMax < nowTimestamp) {
                    throw new Error('need new random image key')
                }

                return payload.key
            } catch (e) {
                let payload = {
                    key: _.sample(availableImageKeys),
                    timestamp: nowTimestamp
                }

                localStorage.setItem(localStorageKey, JSON.stringify(payload))
                return payload.key
            }
        }
    }
}
</script>

<style lang='scss'>
@import '@/assets/sass/core/vars';
@import '@/assets/sass/mixins/_responsive';

.waiting-empty {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 0 0 0 42px;
    background: #FFFFFF;
    border: 1px solid #DAD5E9;
    border-radius: 4px;
    position: relative;

    @include xs-down {
        flex-direction: column;
        padding: 25px;
    }

    &__image {
        // align-self: end;
        //position: absolute;
        object-fit: contain;

        &_1 {
            margin: 0 45px 9px 0;

            @include xs-down {
                margin: 0;
            }
        }

        &_2 {
            margin: 7px 25px 7px 0;

            -down {
                margin: 0;
            }
        }

        &_3 {
            margin: 0 10px 6px 0;

            @include xs-down {
                margin: 0;
            }
        }

        &_4 {
            margin: 0 49px 10px 0;

            @include xs-down {
                margin: 0;
            }
        }

        &_5 {
            margin: 0 10px 6px 0;

            @include xs-down {
                margin: 0;
            }
        }
    }

    &__texts {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
    }

    &__text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #70778C;

        &.active {
            cursor: pointer;
            color: #1C2538;
        }

        span {
            color: #37409E;
        }
    }
}
</style>