<template>
  <i class="hra-icon" :class="iconClass" :style="style" v-on="$listeners"/>
</template>

<script>
import _ from "lodash";
import {UI_COLORS} from "@/utils/default";

export const ICON_NAME = {
  down: "down",
  up: "up",
  xls: "xls",
  archive: "archive",
  find: "find",
  bin: "bin",
  plus: "plus",
	minus: "minus",
  settings: "settings",
  ok: "ok",
  close: "close",
  warning: "warning",
  questionMark: "question-mark",
  peoples: "peoples",
  palm: "palm",
  microsoft: "microsoft",
  info: "info",
  google: "google",
  files: "files",
  file: "file",
  email: "email",
  calendar: "calendar",
  bell: "bell",
  arrowLeft: "arrow-left",
  arrowRight: "arrow-right",
  edit: "edit",
  companyStructure: 'company-structure',
  employeeDelete: 'employee-delete',
  sendEmail: 'send-email',
	waitList: 'wait-list',
};

const ICON_NAMES = _.values(ICON_NAME);
const ICON_COLORS = _.values(UI_COLORS);

export default {
  name: "HraIcon",
  props: {
    name: {default: ICON_NAMES[0], validator: value => ICON_NAMES.includes(value)},
    color: {default: null, validator: value => ICON_COLORS.includes(value)},
    tooltip: {type: String, default: null},
	  size: {type: String, default: null},
  },
  computed: {
    iconClass() {
			return {
				[`icon-${this.name}`]: true,
				[`color-${this.color}`]: this.color ?? false
			};
    },
	  style() {
			return {
				fontSize: this.size ?? "auto",
			};
	  },
  },
};
</script>
