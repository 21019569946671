<template>
    <div></div>
</template>

<script>
export default {
    beforeRouteUpdate(to, from, next) {
        //
        next();
    },
}
</script>
