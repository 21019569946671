<template>
    <div style='width: 100%'>
        <div v-if='!!name' class='document-table-employee__name'>
            {{ name }}
        </div>
        <div v-if='!!departmentName' class='document-table-employee__department-name'>
            {{ departmentName }}
        </div>
        <div v-if='!!dutyName' class='document-table-employee__duty-name'>
            {{ dutyName }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'DocumentTableEmployee',
    props: {
        name: {type: String, default: null},
        departmentName: {type: String, default: null},
        dutyName: {type: String, default: null}
    }
}
</script>

<style lang='scss'>
.document-table-employee {
    &__name {
        color: #1C2538;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
    }

    &__department-name,
    &__duty-name {
        color: #70778C;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 1px;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
    }
}
</style>