<template>
    <button
        v-if="hasPermissions"
        :class="buttonClass"
        :type="type"
        :disabled="disabled"
        v-on="$listeners"
    >
        <template v-if="loading">
            <slot name="loading">
                <full-row-loader size="20" />
            </slot>
        </template>
        <template v-else>
            <slot name="icon"/>
            <slot />
        </template>
    </button>
</template>

<script>
import hraButtonMixin from "@/mixins/hraButtonMixin";
import FullRowLoader from "@/components/ui/FullRowLoader";

export default {
    name: "HraButton",
    components: {FullRowLoader},
    mixins: [hraButtonMixin]
}
</script>
