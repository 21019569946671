<template>
	<div :class="classes" ref="error">
		<div class="form-alert__icon">
			<i :class="`icon-${icon}`"/>
		</div>
		<div class="form-alert__text">
			<slot><div v-html="text" /></slot>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		hasScrollToError: {type: Boolean, default: false,},
		type: {type: String, default: 'default'},
		icon: {type: String, default: 'warning'},
		text: {type: String, default: ''}
	},
	computed: {
		classes() {
			return {
				'form-alert': true,
				'form-alert-error': this.type === 'error',
				'form-alert-success': this.type === 'success',
				'form-alert-info': this.type === 'info',
				'form-alert-warning': this.type === 'warning',
			};
			//
			// let classes = ['form-alert', this.class?.split(' ') || []];
			// switch (this.type) {
			// 	case 'error':
			// 		classes.push('form-alert-error');
			// 		break;
			// 	case 'success':
			// 		classes.push('form-alert-success');
			// 		break;
			// 	case 'info':
			// 		classes.push('form-alert-info');
			// 		break;
			// 	case 'warning':
			// 		classes.push('form-alert-warning');
			// 		break;
			// }
			// return classes;
		}
	},
	watch: {
		text: {
			handler(newText, oldText) {
				this.scrollToError();
			},
			immediate: true,
		},
	},
	mounted() {
		this.scrollToError();
	},
	// beforeDestroy() {
	//
	//
	// },
	methods: {
		scrollToError() {
			if (this.hasScrollToError) {
				//
				this.$refs.error?.scrollIntoView();
			}
		}
	}

}
</script>
