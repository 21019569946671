import Vue from 'vue'
import Vuex from 'vuex'
import global from '@/store/modules/global/index'
import user from '@/store/modules/user'
import vacations from '@/store/modules/vacations'
import employeeVacations from '@/store/modules/employeeVacations'
import employees from '@/store/modules/employees'
import employeesArchive from '@/store/modules/employeesArchive'
import employee from '@/store/modules/employee'
import employeesImport from '@/store/modules/employeesImport'
import employeeDynamicFieldSettings from '@/store/modules/employeeDynamicFieldSettings'
import companyStructure from '@/store/modules/companyStructure'
import filter from '@/store/modules/filter'
import modals from '@/store/modules/modals'
import vacation from '@/store/modules/vacation'
import calendar from '@/store/modules/calendar'
import widgets from '@/store/modules/widgets'
import department from '@/store/modules/department'
import storeRemoteWork from "@/store/modules/storeRemoteWork";

import currentUserStore from '@/domain/currentUser/store'
import authStore from '@/domain/auth/store'
import setupStore from '@/domain/setup/store'
import debuggerStore from '@/domain/debugger/store'
import employeeVacationsStore from '@/domain/employeeVacations/store'
import departmentVacationsStore from '@/domain/departmentVacations/store'
import vacationModalStore from '@/domain/vacationModal/store'
import messagesStore from '@/domain/messages/store'
import loadersStore from '@/domain/loaders/store'
import employeeStore from '@/domain/employee/store'
import companyStore from '@/domain/company/store'
import inviteStore from '@/domain/invite/store'
import dtvStore from '@/domain/dtv/store'
import eventsStore from '@/domain/events/store'
import schedulerStore from '@/domain/scheduler/store'
import documents from '@/store/modules/documents'
import filtersStore from '@/domain/filters/stores'

import companyDepartmentsStore from '@/domain/department/stores/companyDepartmentsStore'

import documentStores from '@/domain/documents/stores'
import RegistrationPlugin from '@/plugins/RegistrationPlugin'
import employeesFieldStore from '@/domain/fields/stores/employeesFieldStore'

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [
        RegistrationPlugin
    ],
    modules: {
        currentUserStore,
        authStore,
        setupStore,
        dtvStore,
        eventsStore,
        employeeVacationsStore,
        storeRemoteWork,
        departmentVacationsStore,
        vacationModalStore,
        messagesStore,
        loadersStore,
        employeeStore,
        debuggerStore,
        companyStore,
        inviteStore,
        schedulerStore,

        documentStores,

        companyStructure,
        global,
        modals,
        user,
        vacations,
        employeeVacations,
        employees,
        employeesArchive,
        employee,

        employeesImport,
        employeeDynamicFieldSettings,
        filter,
        vacation,
        calendar,
        widgets,
        department,
        documents,
        filtersStore,
        companyDepartmentsStore,

        employeesFieldStore,
        // companyStructure,
    }
})
