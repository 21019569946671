<template>
    <div>
        <form-dynamic-field v-bind="data" />
    </div>
</template>

<script>
import FormDynamicField from "@/components/ui/form/FormDynamicField";
export default {
    name: "HraTableInlineEditCol",
    components: {FormDynamicField},
    props: {
        data: {type: Object, required: true},
    },
}
</script>
