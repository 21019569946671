<template>
    <custom-dialog :max-width='670'>
        <template #title>{{ $t('Pridėti darbo vietą') }}</template>
        <template #content>
            <validation-observer ref="ValidationObserver" tag="div" class="row px-3">
                <v-col cols='12'>
                    <select-field :label="$t('Parent')" :options='parentOptions' v-model='formData.parentId' />
                </v-col>
                <v-col cols='12'>
                    <select-field :label="$t('Rūšis')" :options='typeOptions' v-model='formData.type' />
                </v-col>
                <v-col v-if='isGroup' cols='12'>
                    <text-field
                        :label='groupNameText'
                        rules='required'
                        v-model='formData.name' />
                </v-col>
                <v-col v-if='isUser' cols='12'>
                    <select-field
                        name='userId'
                        rules='required'
                        :label="userNameText"
                        :single-line='false'
                        :options-service='employeeAutocompleteService.findWithIgnoreIds()'
                        v-model='formData.userId'
                        dynamic filtered
                    >
                        <template #selection='{item}'>
                            <employee-table-view class='py-1' :employee='item.employee' />
                        </template>
                        <template #item='{item}'>
                            <employee-table-view class='py-1' :employee='item.employee' />
                        </template>
                    </select-field>
                </v-col>
            </validation-observer>
        </template>

        <template #footer>
            <ui-button
                prepend-icon='close'
                background='default'
                @click='close'
            >{{ $t('Uždaryti') }}
            </ui-button>
            <ui-button
                prepend-icon='ok'
                background='primary'
                :disabled='disabledButton'
                @click='onCreate'
            >{{ $t('Pridėti') }}
            </ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import TextField from '@/domain/fields/TextField.vue'
import UiButton from '@/domain/components/UiButton.vue'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import dialogMixin from '@/mixins/dialogMixin'
import SelectField from '@/domain/fields/SelectField.vue'
import scheduleLineModalMixin from '@/domain/scheduler/mixins/scheduleLineModalMixin'
import EmployeeTableView from '@/components/ui/views/EmployeeTableView.vue'
import {ValidationObserver} from "vee-validate";
import {GLOBAL_TOAST_TAG} from "@/utils/default";

export default {
    name: 'CreateScheduleLineModal',
    mixins: [dialogMixin, scheduleLineModalMixin],
    props: {
        type: { type: String, default: 'group' },
        scheduleLineId: { type: Number, default: -1 }
    },
    components: {
        ValidationObserver,
        EmployeeTableView,
        CustomDialog,
        UiButton,
        SelectField,
        TextField
    },
    beforeMount() {
        this.formData.type = this.type
        this.formData.parentId = this.scheduleLineId
    },
    mounted() {
        this.$refs.ValidationObserver.reset()
    },
    methods: {
        async onCreate() {
            if (!(await this.$refs.ValidationObserver.validate())) {
                await this.$store.dispatch('messagesStore/showErrorMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Supildykite raudonai pažymėtus laukus')
                })
                return
            }

            this.disabledButton = true
            const result = await this.$store.dispatch('schedulerStore/createNewLine', this.getPayload())

            if (result == null) {
                return
            }

            this.disabledButton = false
            this.close()
        }
    }
}
</script>