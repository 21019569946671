//  initial state
import {gettersInit, mutationsInit, stateInit} from "@/store/examples/baseTableInlineEditExample";

const initState = () => ({
  ...stateInit(),
  fields: {
    fromDate: {type: "date", rules: "required"},
    toDate: {type: "date", rules: "required"},
  }
});

const state = () => initState();

// getters
const getters = {
  ...gettersInit,
  createData: state => state.data.map(item => ({
    from_date: item.fromDate.value,
    to_date: item.toDate.value,
    number: item.number?.value || null,
  })),
  rows: state => state.data.map(item => ({
    id: item.id,
    period: {from: item.fromDate.value, to: item.toDate.value},
    number: item.number?.value || null,
  })),
};

// actions
const actions = {
  init: ({commit}, payload) => {
    commit("init", payload);
  },
  create: ({commit}, payload) => {
    commit("create", payload);
  },
  update: ({commit}, payload) => {
    commit("update", payload);
  },
  remove: ({commit}, payload) => {
    commit("remove", payload);
  },
  destroy: ({commit}) => commit('destroy'),
};

//  mutations
const mutations = {
  ...mutationsInit,
  destroy: state => Object.assign(state, initState()),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
