import { permissionOnlyAdmin } from '@/utils/permissions'
import VacationReportsPage from '@/domain/reports/pages/VacationReportsPage.vue'
import ReportsLayout from '@/domain/reports/layouts/ReportsLayout.vue'
import VacationBalancePage from '@/domain/reports/pages/VacationBalancePage.vue'
import ParentDaysReportPage from '@/domain/reports/pages/ParentDaysReportPage.vue'
import RemoteWorkReportPage from '@/domain/reports/pages/RemoteWorkReportPage.vue'
import SickLeavesReportPage from '@/domain/reports/pages/SickLeavesReportPage.vue'
import BusinessTripsReportPage from '@/domain/reports/pages/BusinessTripsReportPage.vue'
import VacationActivityReportPage from '@/domain/reports/pages/VacationActivityReportPage.vue'

export default [
    {
        path: '/reports',
        component: ReportsLayout,
        children: [
            {
                name: 'ReportsPage',
                path: 'vacation',
                component: VacationReportsPage,
                meta: { permissions: permissionOnlyAdmin }
            },
            {
                name: 'VacationActivityReportPage',
                path: 'vacation/activity',
                component: VacationActivityReportPage,
                meta: { permissions: permissionOnlyAdmin }
            },
            {
                name: 'VacationBalancePage',
                path: 'balance',
                component: VacationBalancePage,
                meta: { permissions: permissionOnlyAdmin }
            }, {
                name: 'ParentDaysReportPage',
                path: 'parentdays',
                component: ParentDaysReportPage,
                meta: { permissions: permissionOnlyAdmin }
            },
            {
                name: 'RemoteWorkReportPage',
                path: 'remotework',
                component: RemoteWorkReportPage,
                meta: { permissions: permissionOnlyAdmin }
            },
            {
                name: 'BusinessTripsReportPage',
                path: 'businessTrips',
                component: BusinessTripsReportPage,
                meta: { permissions: permissionOnlyAdmin }
            },
            {
                name: 'SickLeavesReportPage',
                path: 'sick-leaves',
                component: SickLeavesReportPage,
                meta: { permissions: permissionOnlyAdmin }
            }
        ]
    }
]