<template>
    <custom-dialog :max-width='670'>
        <template #title>{{ $t('Sukurti grafiką') }}</template>
        <template #content>
            <v-row>
                <v-col class="px-5">
                    <text-field :label='$t("Pavadinimas")' v-model='name' />
                </v-col>
            </v-row>
            <message-alerts class='px-5' :listen='tag' />
        </template>

        <template #footer>
            <ui-button
                prepend-icon='close'
                background='default'
                @click='close'
            >{{ $t('Uždaryti') }}
            </ui-button>
            <ui-button
                prepend-icon='ok'
                background='primary'
                :disabled='disabledButton'
                @click='onCreate'
            >{{ $t('Sukurti') }}
            </ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import dialogMixin from '@/mixins/dialogMixin'
import Form from '@/components/ui/form/Form.vue'
import TextField from '@/domain/fields/TextField.vue'
import MessageAlerts from '@/domain/messages/MessageAlerts.vue'
import SelectField from '@/domain/fields/SelectField.vue'
import UiButton from '@/domain/components/UiButton.vue'
import EmployeeTableView from '@/components/ui/views/EmployeeTableView.vue'
import ValidationForm from '@/domain/components/ValidationForm.vue'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import FormRadio from '@/components/ui/form/FormRadio.vue'
import DatepickerField from '@/domain/fields/DatepickerField.vue'
import UserAutocompleteField from '@/domain/users/components/UserAutocompleteField.vue'
import { mapGetters } from 'vuex'
import customApi from '@/plugins/customApi'

export default {
    name: 'CreateScheduleModal',
    mixins: [dialogMixin],
    props: {
    },
    components: {
        UserAutocompleteField,
        DatepickerField,
        FormRadio,
        FormCheckbox,
        CustomDialog,
        ValidationForm,
        EmployeeTableView,
        UiButton, SelectField, MessageAlerts, TextField, Form
    },
    data() {
        return {
            tag: 'CREATE_SCHEDULE_TAG',
            disabledButton: false,
            name: ''
        }
    },
    methods: {
        async onCreate() {
            this.disabledButton = true
            const api = customApi(this.tag)
            const schedule = await api.request(api.factories.schedules.create({ name: this.name }))

            if (schedule == null) {
                return
            }

            this.$emit('created', schedule)
            this.disabledButton = false
            this.close()
        }
    }
}
</script>