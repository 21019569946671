// import i18n from '@/plugins/i18n'
// import { EVENT_HOLIDAY_TYPE_DEPUTY, eventHolidayType, getDateArray, getEventStatusText } from '@/utils/default'
// import moment from 'moment'
import store from '@/store'
//
// export const comingVacations = ({ events }) => {
//     const today = moment().format('YYYY-MM-DD')
//
//     return events.filter(event => {
//         return event.currentStatus === 'approved' && event.start > today && event.type !== EVENT_HOLIDAY_TYPE_DEPUTY
//     }).sort((a, b) => a.start > b.start ? 1 : -1)
// }
// export const comingVacation = (state, getters) => {
//     return getters.comingVacations[0] ?? null
// }
// export const widgets = state => state.widgets
//
// export const formattedEvents = ({ events }) => {
//     const today = moment().format('YYYY-MM-DD')
//
//     return events.map(event => {
//         const status = event.currentStatus
//         let data = {
//             id: event.id,
//             event,
//             period: {
//                 start: event.start,
//                 end: event.end
//             },
//             status
//         }
//
//         if (event.type === EVENT_HOLIDAY_TYPE_DEPUTY) {
//             return {
//                 ...data,
//                 deputyId: event.id,
//                 type: i18n.t('Pavadavimas'),
//                 deputyTitle: i18n.t('Pavaduojate <b>{name}</b>', { name: event.parent.user.name }),
//                 statusText: status === 'approved' ? i18n.t('Patvirtintas') : i18n.t('Nepatvirtintas'),
//                 holiday: event,
//                 actions: {
//                     canDeputyAbort: !['aborted', 'changed'].includes(status),
//                     canDeputyApprove: ['pending'].includes(status)
//                 }
//             }
//         }
//
//         let deputyTitle = i18n.t('Pavaduojančių nebus')
//
//         if (event.deputies.length > 0) {
//             if (!['aborted', 'changed'].includes(event.deputies[0].currentStatus)) {
//                 deputyTitle = i18n.t('Pavaduoja <b>{name}</b>', { name: event.deputies[0].user.name })
//             }
//         }
//
//         return {
//             ...data,
//             deputyId: null,
//             type: eventHolidayType[event.type],
//             deputyTitle,
//             statusText: getEventStatusText(event.end, status),
//             actions: {
//                 canVacationAbort: !['aborted', 'changed'].includes(status) && today <= event.start,
//                 canVacationChange: !['aborted', 'changed'].includes(status) && today <= event.start,
//                 canVacationInfo: today > event.start || ['aborted', 'changed'].includes(status)
//             }
//         }
//     }).sort(({ event: { start: startA } }, { event: { start: startB } }) => {
//         if (startA > startB) {
//             return -1
//         }
//         if (startA < startB) {
//             return 1
//         }
//         return 0
//     })
// }
//
//
//
// export const waiting = (state, getters) => {
//     return getters.formattedEvents
//         .filter(({ status }) => ['pending'].includes(status))
// }
// export const emptyWaiting = (state, getters) => getters.waiting.length === 0
// export const history = (state, getters) => {
//     return getters.formattedEvents
// }


import {
    EVENT_BUSINESS_TRIP_EMPLOYEE, EVENT_BUSINESS_TRIP_LEADER,
    EVENT_HOLIDAY_TYPE_CHILDCARE, EVENT_HOLIDAY_TYPE_CREATIVE,
    EVENT_HOLIDAY_TYPE_DEPUTY,
    EVENT_HOLIDAY_TYPE_FATHER_OR_MOTHER, EVENT_HOLIDAY_TYPE_FIRST_SCHOOL_DAY, EVENT_HOLIDAY_TYPE_FREE,
    EVENT_HOLIDAY_TYPE_MATERNITY, EVENT_HOLIDAY_TYPE_ONE_WORK_DAY,
    EVENT_HOLIDAY_TYPE_PARENTAL, EVENT_HOLIDAY_TYPE_PUBLIC, EVENT_HOLIDAY_TYPE_REMOTE_WORK, EVENT_HOLIDAY_TYPE_STUDY,
    EVENT_HOLIDAY_TYPE_YEARLY, EVENT_SICK_LEAVE,
    eventHolidayType,
    getDateArray,
    getEventStatusText
} from '@/utils/default'
import i18n from '@/plugins/i18n'
import moment from 'moment'
import Vue from 'vue'

function getTypeText(type, approvalable) {
    if (type === 'remote-work') {
        return i18n.t('Remote work')
    }

    return eventHolidayType[approvalable.type] ?? '-'
}

function getDeputyTitle(type, approvalable) {
    if (type === 'remote-work') {
        return ''
    }

    if (approvalable.type === EVENT_HOLIDAY_TYPE_DEPUTY) {
        return i18n.t('Pavaduojate <b>{name}</b>', { name: approvalable.parent.user.name })
    }

    if (approvalable.deputies.length === 0) {
        return i18n.t('Pavaduojančių nebus')
    }

    return i18n.t('Pavaduoja')
        + ' '
        + approvalable.deputies.map(({ user }) => `<b>${(user?.name ?? '-')}</b>`).join(', ')
}

function getStatusText(type, status, approvalable) {
    return getEventStatusText(approvalable.end, status)
}

export const widgets = state => state.widgets

export const comingVacations = ({ calendarEvents }) => {
    const today = moment().format('YYYY-MM-DD')

    return calendarEvents.filter(event => {
        return event.currentStatus === 'approved' && event.start > today && [
            EVENT_HOLIDAY_TYPE_YEARLY,
            EVENT_HOLIDAY_TYPE_FATHER_OR_MOTHER,
            EVENT_HOLIDAY_TYPE_MATERNITY,
            EVENT_HOLIDAY_TYPE_PARENTAL,
            EVENT_HOLIDAY_TYPE_CHILDCARE,
            EVENT_HOLIDAY_TYPE_STUDY,
            EVENT_HOLIDAY_TYPE_CREATIVE,
            EVENT_HOLIDAY_TYPE_PUBLIC,
            EVENT_HOLIDAY_TYPE_FREE,
            EVENT_HOLIDAY_TYPE_ONE_WORK_DAY,
            EVENT_HOLIDAY_TYPE_FIRST_SCHOOL_DAY
        ].includes(event.type)
    }).sort((a, b) => a.start > b.start ? 1 : -1)
}
export const comingVacation = (state, getters) => {
    return getters.comingVacations[0] ?? null
}

export const approvalsPending = ({ approvalsPending }) => {
    return approvalsPending.filter(item => !!item.approvalable)
        .sort((a, b) => a.approvalable.start.localeCompare(b.approvalable.start))
        .map(item => {
            return {
                id: item.id,
                type: item.type,
                typeText: getTypeText(item.type, item.approvalable),
                period: {
                    start: item.approvalable.start,
                    end: item.approvalable.end
                },
                deputyTitle: getDeputyTitle(item.type, item.approvalable),
                status: item.status,
                approvalable: item.approvalable
            }
        })
}

export const approvalsPendingIsEmpty = (_, { approvalsPending }) => approvalsPending.length === 0

export const approvalsHistory = ({ approvalsHistory }) => {
    return approvalsHistory.filter(item => !!item.approvalable)
        .sort((a, b) => b.approvalable.start.localeCompare(a.approvalable.start))
        .map(item => {
            return {
                id: item.id,
                type: item.type,
                typeText: getTypeText(item.type, item.approvalable),
                period: {
                    start: item.approvalable.start,
                    end: item.approvalable.end
                },
                deputyTitle: getDeputyTitle(item.type, item.approvalable),
                status: item.status,
                statusText: getStatusText(item.type, item.status, item.approvalable),
                approvalable: item.approvalable
            }
        })
}

export const calendarEvents = ({ calendarEvents }) => (doRefresh = () => {}) => {
    const eventCalendarStatus = {
        'pending': 'wait',
        'approved': 'approve',
        'aborted': 'abort',
        'changed': 'abort'
    }

    let payload = {}
    const today = moment().format('YYYY-MM-DD')

    calendarEvents
        .filter(({ currentStatus }) => !['aborted', 'changed'].includes(currentStatus))
        .map(event => {
            const start = moment(event.start)
            const end = moment(event.end)
            const status = eventCalendarStatus[event.currentStatus] ?? 'none'
            const type = event.type
            let tooltip = eventHolidayType[event.type] ?? null
            let click = null

            if (event.type === EVENT_HOLIDAY_TYPE_DEPUTY) {
                click = () => {
                }
                tooltip = i18n.t('Pavaduojate <b>{name}</b>', { name: event?.parent?.user?.name ?? '' })
            }

            if (
                [
                    EVENT_HOLIDAY_TYPE_YEARLY,
                    EVENT_HOLIDAY_TYPE_FATHER_OR_MOTHER,
                    EVENT_HOLIDAY_TYPE_MATERNITY,
                    EVENT_HOLIDAY_TYPE_PARENTAL,
                    EVENT_HOLIDAY_TYPE_CHILDCARE,
                    EVENT_HOLIDAY_TYPE_STUDY,
                    EVENT_HOLIDAY_TYPE_CREATIVE,
                    EVENT_HOLIDAY_TYPE_PUBLIC,
                    EVENT_HOLIDAY_TYPE_FREE,
                    EVENT_HOLIDAY_TYPE_ONE_WORK_DAY,
                    EVENT_HOLIDAY_TYPE_FIRST_SCHOOL_DAY
                ].includes(event.type)
            ) {
                click = () => store.dispatch('modals/open', {
                    name: 'VacationModal',
                    props: { userId: event.userId, eventId: event.id },
                    on: {
                        changed: doRefresh
                    }
                })
            }

            if (event.type === EVENT_HOLIDAY_TYPE_REMOTE_WORK) {
                click = () => (event.start < today)
                    ? {}
                    : store.dispatch('modals/open', {
                        name: 'RemoteWorkModal',
                        props: { userId: event.userId, id: event.userRemoteWorkId },
                        on: {
                            changed: doRefresh
                        }
                    })
            }

            if (event.type === EVENT_BUSINESS_TRIP_EMPLOYEE) {
                click = () => (event.start < today)
                    ? {}
                    : store.dispatch('modals/open', {
                        name: 'BusinessTripsModal',
                        props: { id: event.id },
                        on: {
                            created: doRefresh
                        }
                    })
            }

            if ([EVENT_SICK_LEAVE, EVENT_BUSINESS_TRIP_LEADER].includes(event.type)) {
                click = () => {}
            }

            getDateArray(start, end).map(date => {
                payload[date] = {
                    isStart: date === event.start,
                    isEnd: date === event.end,
                    tooltip,
                    type,
                    status,
                    click
                }
            })
        })

    return payload
}