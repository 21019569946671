<script>
import {defineComponent} from 'vue'
import FormAlert from "@/components/ui/form/FormAlert.vue";
import UiCalendar from "@/domain/components/UiCalendar/UiCalendar.vue";
import FormCheckbox from "@/components/ui/form/FormCheckbox.vue";
import SelectField from "@/domain/fields/SelectField.vue";
import moment from "moment/moment";
import {mapGetters} from "vuex";

export default defineComponent({
    name: "CopyScheduleHorizontallyTab",
    components: {SelectField, FormCheckbox, UiCalendar, FormAlert},
    data: () => {
        return {
            horizontalTabValues: {
                timetableLine: null,
                employeeLine: null,
                from: {
                    start: null,
                    end: null
                },
                to: {
                    start: null,
                    end: null
                },
                activateDestroyer: false
            },
            yearMonthFrom: null,
            yearMonthTo: null,
            scheduleType: 'simple',
        }
    },
    watch: {
        horizontalTabValues: {
            handler(newValue, oldValue) {
                this.$emit('update:horizontalTabValues', newValue);
            },
            immediate: true,
            deep: true,
        },
        yearMonthFrom: {
            handler(newValue, oldValue) {
                this.yearMonthTo = newValue;
            },
            immediate: true
        }
    },
    beforeMount() {
        this.yearMonthFrom = moment().format('YYYY-MM');
    },
    computed: {
        ...mapGetters({
            lines: 'schedulerStore/rows',
        }),
        horizontalTabTimetablesList() {
            let timetables = [{
                text: this.$t('Darbuotojai be darbo vietų'),
                value: null
            }];

            this.lines
                .filter((line) => !line.user)
                .filter((line) => Number.isInteger(line.id))
                .forEach((line) => {
                    timetables.push({
                        text: line.user ? line.user.name : line.name,
                        value: line.id
                    })
                })

            return timetables
        },
        horizontalTabEmployeesList() {
            let employees = this.lines
                .filter((line) => line.user && !line.user.dismissed)

            if(this.horizontalTabValues.timetableLine) {
                employees = employees.filter((line) => line.scheduleLineId === this.horizontalTabValues.timetableLine)
            } else {
                employees = employees.filter((line) => line.scheduleLineId === null)
            }

            return employees
                .map((line) => {
                    return {
                        text: line.user ? line.user.name : line.name,
                        value: line.id
                    }
                })
        },
        horizontalSubmitActive() {
            return (this.horizontalTabValues.timetableLine || this.horizontalTabValues.employeeLine)
                && this.horizontalTabValues.from.start
                && this.horizontalTabValues.from.end
                && this.horizontalTabValues.to.start
                && this.horizontalTabValues.to.end
        },
        horizontalMinSelectDate() {
            return moment(this.horizontalTabValues.from.end).add(1, 'day').format('YYYY-MM-DD');
        }
    },
    methods: {
        onHorizontalCalendarStartDateChange(event, date = 'from') {
            const eventMoment = moment(event);

            if(date === 'from') {
                this.horizontalTabValues.from.start = this.scheduleType === 'simple'
                    ? eventMoment.startOf('isoweek').format('YYYY-MM-DD')
                    : eventMoment.format('YYYY-MM-DD');
            }

            if(date === 'to') {
                this.horizontalTabValues.to.start = this.scheduleType === 'simple'
                    ? eventMoment.startOf('isoweek').format('YYYY-MM-DD')
                    : eventMoment.format('YYYY-MM-DD');
            }
        },
        onHorizontalCalendarEndDateChange(event, date = 'from') {
            const eventMoment = moment(event);

            if(date === 'from') {
                this.horizontalTabValues.from.end = this.scheduleType === 'simple'
                    ? eventMoment.endOf('isoweek').format('YYYY-MM-DD')
                    : eventMoment.format('YYYY-MM-DD');
            }

            if(date === 'to') {
                this.horizontalTabValues.to.end = this.scheduleType === 'simple'
                    ? eventMoment.endOf('isoweek').format('YYYY-MM-DD')
                    : eventMoment.format('YYYY-MM-DD');
            }
        },
    }
})
</script>

<template>
    <div class="px-6 py-6 mb-10">
        <v-row>
            <v-col class="coloured-divider">
                <h3>{{ $t('Pasirinkite') }}</h3>
                <div class="d-flex justify-space-between align-end mb-3">
                    <select-field
                        style="width:100%;"
                        :label="$t('Darbo vieta')"
                        :options='horizontalTabTimetablesList'
                        v-model="horizontalTabValues.timetableLine"
                        placeholder=""
                    />
                </div>
                <div class="d-flex justify-space-between align-end mb-3">
                    <select-field
                        style="width:100%;"
                        :label="$t('Darbuotojas')"
                        :options='horizontalTabEmployeesList'
                        v-model="horizontalTabValues.employeeLine"
                    />
                </div>
                <form-alert v-if="!horizontalTabValues.employeeLine" class="mb-3">
                    {{ $t('Jei nepasirinksite darbuotojo, kopijuojamas bus darbo vietos laikas') }}
                </form-alert>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="d-flex align-center position-relative coloured-divider">
                <h3 class="mr-2">{{ $t('Grafiko tipas') }}:</h3>
                <div
                    class='graph-selector mr-2'
                    :class='{ "active": scheduleType === "simple" }'
                    @click='scheduleType = "simple"'
                >{{ $t('Paprastas') }}</div>
                <div
                    class='graph-selector'
                    :class='{ "active": scheduleType === "rolling" }'
                    @click='scheduleType = "rolling"'
                >{{ $t('Slenkantis') }}</div>
                <div
                    class="overflow"
                    v-if="!(horizontalTabValues.timetableLine || horizontalTabValues.employeeLine)"></div>
            </v-col>
        </v-row>
        <v-row class="coloured-divider">
            <v-col class="position-relative">
                <h3 class="mb-5">{{ $t('Kopijuoti iš') }}</h3>
                <ui-calendar
                    with-all-month-navigation
                    :year-month.sync='yearMonthFrom'
                    :select-start-date='horizontalTabValues.from.start'
                    :select-end-date='horizontalTabValues.from.end'
                    :clickable-dates='true'
                    disable-mobile-click
                    @update:selectStartDate='onHorizontalCalendarStartDateChange($event, "from")'
                    @update:selectEndDate='onHorizontalCalendarEndDateChange($event, "from")'
                />
                <div
                    class="overflow"
                    v-if="!(horizontalTabValues.timetableLine || horizontalTabValues.employeeLine)"></div>
            </v-col>
            <div class="divider">
            </div>
            <v-col class="position-relative">
                <h3 class="mb-5">{{ $t('Kopijuoti į') }}</h3>
                <ui-calendar
                    with-all-month-navigation
                    :year-month.sync='yearMonthTo'
                    :min-select-date='horizontalMinSelectDate'
                    :select-start-date='horizontalTabValues.to.start'
                    :select-end-date='horizontalTabValues.to.end'
                    :clickable-dates='true'
                    disable-mobile-click
                    @update:selectStartDate='onHorizontalCalendarStartDateChange($event, "to")'
                    @update:selectEndDate='onHorizontalCalendarEndDateChange($event, "to")'
                />
                <div
                    class="overflow"
                    v-if="!horizontalTabValues.from.start || !horizontalTabValues.from.end"></div>
            </v-col>
        </v-row>
        <v-row>
            <div class="coloured-divider flex position-relative">
                <h3 class="mb-5">{{ $t('Papildomi nustatymai') }}</h3>
                <form-checkbox :is-checked.sync="horizontalTabValues.activateDestroyer">
                    <div class='ml-3 hra-text--16'>
                        {{ $t('Trinti jau sukurtus darbo laikus laikotarpyje į kurį kopijuoju') }}
                    </div>
                </form-checkbox>
                <div
                    class="overflow"
                    v-if="!horizontalTabValues.from.start || !horizontalTabValues.from.end"></div>
            </div>
        </v-row>
    </div>
</template>

<style scoped lang="scss">
    .graph-selector {
        padding: 10px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
        border: 1px solid rgba(75, 86, 210, 0.1);

        &:hover {
            background-color: rgba(75, 86, 210, 0.3);
        }

        &.active {
            background-color: rgba(75, 86, 210, 1);
            color: #FFFFFF;
        }
    }

    .divider {
        width: 1px;
        margin-left: 10px;
        margin-right: 10px;
        background-color: #CFD6E6;
    }

    .coloured-divider {
        background-color: #FAF9FF;
        border-radius: 12px;
        padding: 16px;
    }

    .overflow {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background-color: #FFFFFF;
        opacity: 90%;
    }

    .position-relative {
        position: relative;
    }
</style>