export default {
    props: {
        color: {
            type: String,
            default: "primary",
        },
        text: {
            type: String,
            default: "bold",
        },
        type: {
            type: String,
            default: "button",
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
        },
        inline: {
            type: Boolean,
            default: false,
        },
        noBackground: {
            type: Boolean,
            default: false,
        },
        border: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classes() {
            let classes = [this.baseClass, `${this.baseClass}-${this.color}`, `${this.baseClass}-text-${this.text}`];
            if (this.fullWidth) {
                classes.push('button-icon-full-width');
            }
            if (this.small) {
                classes.push('button-icon-small');
            }
            if (this.inline) {
                classes.push('button-icon-inline');
            }
            if (this.noBackground) {
                classes.push('no-background');
            }
            if (this.border) {
                classes.push('border');
            }
            return [...classes, this.$attrs.class];
        },
    },
}
