import modalMethodsMixin from '@/domain/mixins/modalMethodsMixin'
import i18n from '@/plugins/i18n'

const statusTitles = {
    'new': i18n.t('Nepradėtas'),
    'waiting': i18n.t('Laukiama'),
    'rejected': i18n.t('Atmesta'),
    'accepted': i18n.t('Priimta'),
    'cancelled': i18n.t('Atšaukta')
}
export default {
    props: {
        item: { type: Object, required: true }
    },
    mixins: [modalMethodsMixin],
    inject: ['withStatus', 'withControls', 'selectable', 'withDeleteInfo', 'storeNamespace'],
    computed: {
        archiveDocument() {
            return this.item.item
        }
    },
    methods: {
        handleOpenPreviewDocumentModal() {
            this.onOpenModal('DocumentPreviewModal', { item: this.archiveDocument })
        },
        handleOpenRestoreDocumentModal() {
            this.onOpenModal(
                'DocumentRestoreModal',
                { item: this.archiveDocument },
                {
                    documentRestored: (payload) => {
                        this.$store.dispatch(`${this.storeNamespace}/archived-documents/delete`, payload)
                    }
                }
            )
        },
        handleOpenWipeDocumentModal() {
            this.onOpenModal(
                'DocumentWipeModal',
                { item: this.archiveDocument },
                {
                    documentWiped: (payload) => {
                        this.$store.dispatch(`${this.storeNamespace}/archived-documents/delete`, payload)
                    }
                }
            )
        }
    }
}