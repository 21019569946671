<template>
    <div class='d-flex flex-column gap-2'>
        <select-field
            :label='$t("setting.module.SettingHolidayParentOrMotherQuarterStart.form.month")'
            rules='required'
            :disabled='!isEdit'
            :options='monthOptions'
            v-model='config.month'
        />
        <select-field
            :label='$t("setting.module.SettingHolidayParentOrMotherQuarterStart.form.day")'
            rules='required'
            :options='monthDaysOptions'
            :disabled='!isEdit'
            v-model='config.day'
        />
    </div>
</template>

<script>
import MultilineSelectField from '@/domain/components/MultilineSelectField.vue'
import SelectField from '@/domain/fields/SelectField.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import TextField from '@/domain/fields/TextField.vue'
import moment from 'moment'

export default {
    name: 'SettingHolidayParentOrMotherQuarterStartConfigForm',
    components: { TextField, FormCheckbox, SelectField, MultilineSelectField },
    props: {
        isEdit: { type: Boolean, default: false },
        config: { type: Object, default: () => ({}) }
    },
    watch: {
        'config.month': {
            handler(month) {
                if (month === 2) {
                    this.config.day = Math.min(this.config.day, 28)
                } else if ([4, 6, 9, 11].includes(month)) {
                    this.config.day = Math.min(this.config.day, 30)
                }
            }
        }
    },
    data() {
        return {
            monthOptions: Array.apply(0, Array(12))
                .map((_,month) => ({ value: month + 1, text: moment().month(month).format('MMMM') }))
        }
    },
    computed: {
        monthDaysOptions() {
            const month = this.config.month
            return  Array.apply(0, Array(moment().month(month - 1).daysInMonth()))
                .map((_,day) => ({ value: day + 1, text: day + 1 }))
        }
    }
}
</script>