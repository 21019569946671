<template>
    <div class='pt-2' :class='{"ps-8": !isBondMobile}'>
        <hra-table :loading='loading' :headers='headers' :rows='rows' hide-headers class='no-border-around' need-actions>
            <template #col.typeText='{colValue, row}'>
                <div v-if='isBondMobile' class='vacation-history-info ps-4 py-4'>
                    <div class='type'>{{ row.typeText }}</div>
                    <div class='period mb-1'><span>{{ row.period.start }}</span> iki <span>{{ row.period.end }}</span>
                    </div>
                    <div class='deputy mb-1' v-html='row.deputyTitle'></div>
                    <div class='status first-letter-uppercase'>{{ row.statusText }}</div>
                </div>
                <div v-else class='px-1 px-sm-3 py-5'>{{ colValue }}</div>
            </template>
            <template #col.period='{colValue}'>
                <vacation-period-view class='px-1 px-sm-3 py-5' :from-date='colValue.start'
                                      :to-date='colValue.end' />
            </template>
            <template #col.deputyTitle='{colValue, row}'>
                <div class='px-1 px-sm-3 py-2' v-html='colValue' />
            </template>
            <template #col.statusText='{colValue}'>
                <span class='px-1 px-sm-3 py-5 first-letter-uppercase'>{{ colValue }}</span>
            </template>
            <template #col.actions-base='{row}'>
                <table-actions class='' :type='row.type' :approvalable='row.approvalable' :status='row.status' :user-id='userId' />
            </template>
        </hra-table>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HraTable from '@/components/ui/table/HraTable'
import TableTitleView from '@/components/ui/views/TableTitleView'
import TableTitle from '@/components/ui/views/TableTitle'
import HraIcon from '@/components/ui/HraIcon'
import VacationPeriodView from '@/components/ui/views/VacationPeriodView'
import TableActions from '@/domain/employeeVacations/components/TableActions'
import isBondMobileMixin from '@/mixins/isBondMobileMixin'

export default {
    name: 'HistoryTable',
    mixins: [isBondMobileMixin],
    components: { TableActions, VacationPeriodView, HraIcon, TableTitle, TableTitleView, HraTable },
    data() {
        return {
            loading: false
        }
    },
    computed: {
        ...mapGetters({
            rows: 'employeeVacationsStore/approvalsHistory',
            userId: 'currentUserStore/userId'
        }),
        headers() {
            if (this.isBondMobile) {
                return [
                    { name: 'typeText' }
                ]
            }

            return [
                { name: 'typeText', style: { width: '300px' } },
                { name: 'period', style: { width: '300px' } },
                { name: 'deputyTitle' },
                { name: 'statusText', style: { width: '200px' } }
            ]
        }
    },
    mounted() {
        this.load()
    },
    methods: {
        load() {
            this.loading = true
            Promise.all([
                this.$store.dispatch('employeeVacationsStore/setUserId', this.userId),
                this.$store.dispatch('employeeVacationsStore/fetchApprovalsHistory')
            ]).finally(() => this.loading = false)
        }
    }
}
</script>

<style lang='scss' scoped>
.vacation-history-info {
    //margin-bottom: 40px;

    //&__item {
    //    display: flex;
    //    justify-content: space-between;
    //    gap: 10px;
    //    border-bottom: 1px solid #F1EEF9;
    //}

    //&__info {
    & > div {
        color: #1C2538;
        font-size: 14px;
        line-height: 1.3;

        span, b {
            font-weight: 600;
        }

        &.type {
            font-size: 16px;
        }
    }

    //}
}
</style>