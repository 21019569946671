import AppLayout from '@/layouts/AppLayout.vue'
import SettingsHomePage from '@/domain/settings/pages/SettingsHomePage.vue'

export default [
    {
        path: '/settings',
        component: AppLayout,
        children: [
            {
                path: '',
                component: SettingsHomePage,
                name: 'SettingsHomePage'
            }
        ]
    }
]