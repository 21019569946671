<template>
    <div class='approval-level mt-5'>
        <approval-level-header
            :order='order'
            :min='1'
            :max='level.users.length'
            :min-to-approve='level.minToApprove'

            @remove-level='handleRemoveLevel'
            @update-min-to-approve='handleUpdateLevelMinToApprove'
        />
        <div>
            <approval-level-employee
                v-for='(user, index) in level.users'
                :key='user.id + index'
                :user='user'
                @remove-user='handleRemoveUserFromLevel'
            />
        </div>
        <div class='approval-level__button'>
            <ui-button
                class='pa-0 gap-3'
                background='primary'
                only-text
                prepend-icon='plus'
                :modal="{name: 'EmployeeSelectModal', on: {update: handleUpdateLevelUsers}, props: {alreadySelectedEmployees: this.level.users}}"
                style='min-height: auto;'
            >{{ $t('Pridėti tvirtinantį darbuotoją') }}
            </ui-button>
        </div>
    </div>
</template>

<script>
import ApprovalLevelHeader from '@/domain/components/ApprovalLevel/components/ApprovalLevelHeader.vue'
import ApprovalLevelEmployee from '@/domain/components/ApprovalLevel/components/ApprovalLevelEmployee.vue'
import ApprovalLevelButton from '@/domain/components/ApprovalLevel/components/ApprovalLevelButton.vue'
import UiButton from '@/domain/components/UiButton.vue'

export default {
    name: 'ApprovalLevel',
    components: {
        UiButton,
        ApprovalLevelHeader,
        ApprovalLevelEmployee,
        ApprovalLevelButton
    },
    props: {
        level: { type: Object, required: true },
        order: { type: Number, required: true }
    },
    computed: {
        employeeSelectModal() {
            return {
                name: 'EmployeeSelectModal',
                on: { update: payload => this.handleUpdateLevelUsers(payload) },
                props: { alreadySelectedEmployees: this.level.users }
            }
        }
    },
    methods: {
        handleUpdateLevelUsers(payload) {
            this.$emit('update-level-users', {
                levelId: this.level.id,
                selectedUsers: payload
            })
        },
        handleUpdateLevelMinToApprove(value) {
            this.$emit('update-level-min-to-approve', {
                levelId: this.level.id,
                value
            })
        },
        handleRemoveUserFromLevel(user) {
            this.$emit('remove-user-from-level', {
                levelId: this.level.id,
                userId: user.id
            })
        },
        handleRemoveLevel() {
            this.$emit('remove-level', this.level.id)
        }
    }
}
</script>

<style lang='scss' scoped>
.approval-level {
    border: 1px solid #DAD5E9;
    border-radius: 4px;
    overflow: hidden;
}
</style>