export const loaders = ({ loaderQueue }) => loaderQueue
export const messages = ({ messages }) => messages
export const sidebarMenu = ({ sidebarMenu }, { isMobile }, rootState, rootGetters) => {
    if (!isMobile) {
        return sidebarMenu
    }

    const isLeader = rootGetters['currentUserStore/isLeader']
    return sidebarMenu.filter(menu => {
        if (!menu.hasMobileView) {
            return false
        }

        if (menu.onlyLeaderInMobileView) {
            return isLeader
        }

        return true
    })
}
export const modals = ({ modals }) => modals
export const isMobile = ({ isMobile }) => isMobile
export const currentContextMenu = ({ currentContextMenu }) => currentContextMenu
export const countries = ({ countries }) => countries ?? []