<template>
	<div :class="['table-employee', $attrs.class]" :style="$attrs.style">
		<Avatar
			class="table-employee__avatar"
			:employeeName="employee.name"
			:color="employee.color"
			:src="employee.avatarSrc"
			:size="avatarSize"
		/>
		<div class="detail-employee">
			<div class="detail-employee__name">{{ employee.name }}</div>
			<div v-if="employee.duty" class="detail-employee__duties">{{ employee.duty.name }}</div>
		</div>
	</div>
</template>

<script>
import Avatar from "@/components/ui/Avatar";
import {capitalizeFirstLetter} from "@/utils/default";

export default {
	components: {Avatar},
	props: {
		employee: {type: Object, required: true},
		avatarSize: {type: [Number, String], default: 36},
	},
	data() {
		return {
			capitalizeFirstLetter,
		};
	},
}
</script>
