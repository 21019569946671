<template>
    <div class='documents-waiting-board' :class='{empty: isEmpty}'>
        <div v-if='isEmpty' class='documents-waiting-board__empty'>
            {{ $t('Neperžiūrėtų dokumentų neturite') }}
        </div>
        <div v-else class='documents-waiting-board__content'>
            <div class='documents-waiting-board__title'>{{ $t('Turite neperžiūrėtus {count} ', { count }) }}</div>
            <div class='documents-waiting-board__sub-title'>{{ $t('Susipažinti iki') }}</div>
            <div class='documents-waiting-board__list'>
                <div
                    v-for='row in rows'
                    :key='`document-waiting-${row.item.id}`'
                    class='documents-waiting-board__list-item'
                    @click='handleClick(row)'
                >
                    <ui-icon>paper</ui-icon>
                    <span>{{ row.item.document.title }}</span>
                    <span>{{ row.item.dueAt }}</span>
                    <hra-tooltip :text='row.status.text'>
                        <ui-icon
                            class='documents-waiting-board__list-item-icon'
                            :style='row.status.style'
                        >message-info
                        </ui-icon>
                    </hra-tooltip>
                </div>
            </div>
        </div>

        <div class='documents-waiting-board__image'>
            <img v-show='isEmpty' src='/assets/images/documents/user-not-pending.png' />
            <img v-show='!isEmpty' src='/assets/images/documents/user-pending.png' />
        </div>

        <v-progress-linear
            absolute
            bottom
            style='bottom: 4px'
            :active='loading'
            :indeterminate='loading'
            color='primary'
        />
    </div>
</template>

<script>
import customApi from '@/plugins/customApi'
import UiIcon from '@/domain/components/UiIcon.vue'
import HraTooltip from '@/components/ui/HraTooltip.vue'
import moment from 'moment'
import modalMethodsMixin from '@/domain/mixins/modalMethodsMixin'

export default {
    name: 'DocumentsWaitingBoard',
    components: { HraTooltip, UiIcon },
    mixins: [modalMethodsMixin],
    data() {
        return {
            today: moment(),
            loading: true,
            items: []
        }
    },
    computed: {
        count() {
            return this.items.length
        },
        isEmpty() {
            return this.count === 0
        },
        rows() {
            return this.items.map(item => {
                const days = moment(item.dueAt).diff(this.today.clone(), 'days')

                const statusText = days >= 0
                    ? this.$t('Susipažinti liko: {days} d.', { days })
                    : this.$t('Pavėlavote susipažinti: {days} d.', { days: Math.abs(days) })

                let statusStyle = 'color: #1C2538'
                if (days < 3) {
                    statusStyle = 'color: #FD9A01'
                }
                if (days < 0) {
                    statusStyle = 'color: #E7384F'
                }
                return {
                    item,
                    isMissing: days < 0,
                    status: {
                        style: statusStyle,
                        text: statusText
                    }
                }
            })
        }
    },
    created() {
        this.load()
        this.$root.$on('employee-document-sign', this.handleEmployeeDocumentSign)
    },
    beforeDestroy() {
        this.$root.$off('employee-document-sign', this.handleEmployeeDocumentSign)
    },
    methods: {
        async load() {
            this.loading = true
            try {
                const { data } = await customApi().factories.documents.userBoard.fetchPending()
                this.items = data
            } catch (e) {

            } finally {
                this.loading = false
            }
        },
        handleEmployeeDocumentSign(item) {
            const index = this.items.findIndex(({id}) => item.id === id)
            if (index === -1) {
                return
            }
            this.items.splice(index, 1)
        },
        handleClick(row) {
            this.onOpenModal('DocumentSignModal', { item: row.item })
        }
    }
}
</script>

<style lang='scss'>
@import "@/assets/sass/mixins/responsive";

.documents-waiting-board {
    width: 100%;
    height: 190px;
    border-radius: 4px;
    border: 1px solid #DAD5E9;
    background: #FFF;
    position: relative;

    &:before {
        content: "";
        width: 4px;
        height: 100%;
        position: absolute;
        background: #FD9A01;
        left: 0;
        top: 0;
        z-index: 3;
        border-radius: 4px 0 0 4px;
    }

    display: grid;
    grid-template: "content image";
    grid-template-columns: 1fr 265px;
    grid-template-rows: 100%;


    @include md-down {
        grid-template: "content";
        grid-template-columns: 1fr;
    }

    &__content {
        grid-area: content;
        height: 100%;
        padding: 0 30px;
        display: grid;
        grid-template-rows: 56px 14px 1fr 11px;
    }

    &__title {
        color: #1C2538;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        margin-top: 30px;
    }

    &__sub-title {
        color: #70778C;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px; /* 116.667% */
        letter-spacing: 0.36px;
        text-transform: uppercase;
        text-align: right;
        width: 100%;
    }

    &__list {
        width: 100%;
        height: 100%;
        overflow: hidden;

        &-item {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 7px;
            height: 36px;
            cursor: pointer;

            border-bottom: 1px solid #F1EEF9;

            &:last-child {
                border-bottom: 0;
            }

            span {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

                &:nth-of-type(1) {
                    color: #32398B;
                }

                &:nth-of-type(2) {
                    margin-left: auto;
                    color: #1C2538;
                    min-width: max-content;
                }
            }

            .ui-icon {
                font-size: 20px;
                color: #70778C;
            }
        }
    }

    &__empty {
        grid-area: content;
        height: 100%;
        color: #70778C;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;

        display: flex;
        align-items: center;
        padding-left: 50px;
    }

    &__image {
        grid-area: image;
        margin-right: 50px;
        margin-bottom: 20px;
        position: relative;

        img {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
        }

        @include md-down {
            display: none;
        }
    }
}
</style>