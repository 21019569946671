export default {
    data: () => ({
        currentPage: 1,
        lastPage: 1,
        total: 0,
    }),
    methods: {
        setPagination(pagination = null) {
            this.currentPage = pagination?.currentPage ?? 0;
            this.lastPage = pagination?.lastPage ?? 0;
            this.total = pagination?.total ?? 0;
        },
    },
}
