export const stateInit = () => ({
    data: [],
  });

  export const gettersInit = {
    data(state) {
      return state.data[state.data.length - 1];
    },
};

  export const mutationsInit = {
    init(state, payload) {
      state.data = payload;
    },
  clear(state) {
      state.data = [];
    },
  create(state, payload) {
      state.data.push(payload);
    },
  update(state, payload) {
      state.data = state.data.map(item => item.id === payload.id ? payload : item);
    },
  remove(state, payload) {
      state.data = state.data.filter(item => item.id !== payload);
    },
};