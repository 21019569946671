import ScheduleBreakModal from '@/domain/scheduler/modals/ScheduleBreakModal.vue'
import CreateScheduleModal from '@/domain/scheduler/modals/CreateScheduleModal.vue'
import CreateScheduleLineModal from '@/domain/scheduler/modals/CreateScheduleLineModal.vue'
import UpdateScheduleLineModal from '@/domain/scheduler/modals/UpdateScheduleLineModal.vue'
import CreateScheduleTimeIntervalModal from '@/domain/scheduler/modals/CreateScheduleTimeIntervalModal.vue'
import UpdateScheduleTimeIntervalModal from '@/domain/scheduler/modals/UpdateScheduleTimeIntervalModal.vue'
import UpdateScheduleUserEventModal from '@/domain/scheduler/modals/UpdateScheduleUserEventModal.vue'
import UpdateScheduleEventBreaksModal from '@/domain/scheduler/modals/UpdateScheduleEventBreaksModal.vue'
import GenerateScheduleAutoBreaksModal from '@/domain/scheduler/modals/GenerateScheduleAutoBreaksModal.vue'
import CreateScheduleTimetableIntervalModal from '@/domain/scheduler/modals/CreateScheduleTimetableIntervalModal.vue'
import UpdateScheduleTimetableIntervalModal from '@/domain/scheduler/modals/UpdateScheduleTimetableIntervalModal.vue'
import GenerateScheduleTimetablesByFilterModal
    from '@/domain/scheduler/modals/GenerateScheduleTimetablesByFilterModal.vue'
import CopyScheduleTimetablesModal from '@/domain/scheduler/modals/CopyScheduleTimetablesModal.vue'
import UpdateScheduleTimetableModal from '@/domain/scheduler/modals/UpdateScheduleTimetableModal.vue'

export default ({
    CreateScheduleModal,
    CreateScheduleTimeIntervalModal,
    UpdateScheduleTimeIntervalModal,
    ScheduleBreakModal,
    CreateScheduleLineModal,
    UpdateScheduleLineModal,
    UpdateScheduleUserEventModal,
    UpdateScheduleEventBreaksModal,
    GenerateScheduleAutoBreaksModal,
    CreateScheduleTimetableIntervalModal,
    UpdateScheduleTimetableIntervalModal,
    GenerateScheduleTimetablesByFilterModal,
    CopyScheduleTimetablesModal,
    UpdateScheduleTimetableModal
})