import {STATUS_ABORT_ADMIN, STATUS_APPROVE, STATUS_WAIT} from "@/store/modules/employee/vacations";
import moment from "moment";

const state = () => ({
  loading: true,
  isLeader: false,
  employees: [],
  departments: [],
  duties: [],
  vacations: [],
  events: {
    vacations: {},
    deputies: {},
  },
  calculateTo: {},
  filter: {
    departments: [],
    duties: [],
    employees: [],
    date: moment().subtract(5, "days").format("YYYY-MM-DD"),
  },
});

export const VACATION_VIEW_TYPE_VACATION = 'vacation';
export const VACATION_VIEW_TYPE_DEPUTY = 'deputy';

const getters = {
  loading: state => state.loading,
  isLeader: state => state.isLeader,
  filter: state => state.filter,
  getCalculate: state => (employeeId, date) => {
    return state.calculate?.[employeeId]?.balance.toFixed(2) || '-';
  },
  employees: state => {
    const filterEmployee = state.filter.employees.length === 0
      ? () => true
      : employeeId => state.filter.employees.includes(employeeId);

    const filterDepartments = state.filter.departments.length === 0
      ? () => true
      : departments => departments.filter(department => state.filter.departments.includes(department.id)).length > 0;

    const filterDuties = state.filter.duties.length === 0
      ? () => true
      : duties => duties.filter(duty => state.filter.duties.includes(duty.id)).length > 0;

    return state.employees.filter(employee => {
      return filterEmployee(employee.id)
        && filterDepartments(employee.departments)
        && filterDuties(employee.duties);
    });
  },
  employeeOptions: state => state.employees.map(employee => ({
    value: employee.id,
    text: employee.name,
  })),
  departments: state => state.departments,
  departmentOptions: state => state.departments
    .map(item => ({value: item.id, text: item.name})),
  duties: state => state.duties,
  dutyOptions: state => state.duties
    .map(item => ({value: item.id, text: item.name})),
  toApproveVacation: state => {
    let vacations = [];

    Object.values(state.events.vacations).forEach(employee => {
      employee.forEach(vacation => {
        if (vacation.canApprove && vacation.statusCode === STATUS_WAIT) {
          vacations.push(vacation);
        }
      })
    });

    return vacations.sort((a, b) => {
      if (a.period.fromDate > b.period.fromDate) return -1;
      if (a.period.fromDate < b.period.fromDate) return 1;
      return 0;
    });
  },
  getEmployeeEvents: state => employeeId => {
    let vacations = state.events.vacations[employeeId]?.map(vacation => ({
      ...vacation,
      viewType: VACATION_VIEW_TYPE_VACATION
    })) || [];
    let deputies = state.events.deputies[employeeId]?.map(deputy => ({
      ...deputy,
      viewType: VACATION_VIEW_TYPE_DEPUTY
    })) || [];

    return [...vacations, ...deputies];
  },
  getEmployeeVacations: state => employeeId => state.vacations
    .filter(vacation => vacation.employee.id === employeeId)
};

const mutations = {
  init: (state, payload) => {
    state = Object.assign(state, {
      ...payload,
      filter: {
        ...state.filter,
        departments: [payload.defaultDepartment],
      }
    });
    state.loading = false;
  },
  addVacations: (state, payload) => {
    let vacations = state.vacations;
    state.vacations = [
      ...vacations,
      ...payload.filter(vacation => vacations.find(item => item.id === vacation.id) == null),
    ];
  },
  changeVacationStatus: (state, payload) => {
    state.vacations = state.vacations.map(vacation => {
      if (vacation.id !== payload.id) {
        return vacation;
      }

      return {
        ...vacation,
        statusCode: payload.statusCode,
        leaderMessage: payload.message,
        deputy: vacation.deputy ? {
          ...vacation.deputy,
          statusCode: payload.statusCode,
        } : null
      };
    });
  },
  changeFilter: (state, payload) => {
    state.filter = {
      ...state.filter,
      ...payload
    };
  },
  updateVacation: (state, payload) => {
    if (state.events.vacations[payload.employee.id]) {
      state.events.vacations[payload.employee.id] = state.events.vacations[payload.employee.id]
        .map(vacation => vacation.id === payload.id ? ({...vacation, ...payload}) : vacation);
    }
  },
  reset: (vuexState) => vuexState = state(),
};

const actions = {
  init: ({state, commit}, payload) => {
    commit("init", payload);
  },
  vacationAbort: ({commit}, payload) => {
    commit("changeVacationStatus", {
      ...payload,
      statusCode: STATUS_ABORT_ADMIN
    });
  },
  vacationApprove: ({commit}, payload) => {
    commit("changeVacationStatus", {
      ...payload,
      statusCode: STATUS_APPROVE
    });
  },
  selectEmployees: ({commit}, payload) => {
    commit("changeFilter", {
      employees: payload,
      departments: [],
      duties: [],
    });
  },
  selectDepartments: ({commit}, payload) => {
    commit("changeFilter", {
      departments: payload,
    });
  },
  selectDuties: ({commit}, payload) => {
    commit("changeFilter", {
      duties: payload,
    });
  },
  changeDate: ({commit}, payload) => {
    commit("changeFilter", {
      date: payload,
    });
  },
  changeVacation: ({commit}, payload) => {
    commit("updateVacation", payload);
  },
  updateVacation: ({commit}, payload) => {
    commit("updateVacation", {
      id: payload.id,
      period: payload.period,
      deputy: payload.deputy,
      typeId: payload.typeId,
      calendarDays: payload.calendarDays,
      workDays: payload.workDays,
      vacationDays: payload.vacationDays,
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
