<template>
    <div>
        <div class='d-flex justify-space-between align-end'>
            <h1>
                <ui-icon v-if='withIcon'>dtv-question</ui-icon>
                Naujas klausimas
            </h1>
            <div class='dtv-counter pl-3'>Liko: {{ balance }}</div>
        </div>
        <p class='mt-2' v-if='isVisible'>Užduokite jums rūpimą klausimą personalistui ir gaukite atsakymą vos per keletą
            dienų.</p>
        <p class='mt-2' v-else>Jūs išnaudojote klausimų personalistui limitą, norėdami įsigyti daugiau susisiekite.</p>
        <textarea-field
            v-if='isVisible'
            class='mt-4'
            placeholder='Parašykite savo klausimą personalistui'
            v-model='message'
        />
        <div class='d-flex mt-8'>
            <v-spacer />
            <ui-button
                v-if='isVisible'
                class='align-self-end'
                background='success'
                prepend-icon='ok'
                size='medium'
                :loading='blockButton'
                @click='onClick'
            >Siųsti
            </ui-button>
            <ui-button
                v-else
                class='align-self-end'
                background='success'
                size='medium'
                :modal="{name: 'HrQuestionOrderModal'}"
            >Užsakyti
            </ui-button>
        </div>
    </div>
</template>

<script>
import TextareaField from '@/domain/fields/TextareaField.vue'
import UiButton from '@/domain/components/UiButton.vue'
import UiIcon from '@/domain/components/UiIcon.vue'
import { mapGetters } from 'vuex'
import { GLOBAL_TOAST_TAG } from '@/utils/default'

export default {
    name: 'HrNewQuestion',
    components: { UiIcon, UiButton, TextareaField },
    props: {
        withIcon: { type: Boolean, default: false }
    },
    data() {
        return {
            message: '',
            blockButton: false
        }
    },
    computed: {
        ...mapGetters({
            balance: 'dtvStore/hrQuestionBalance'
        }),
        isVisible() {
            return this.balance > 0
        }
    },
    methods: {
        async onClick() {
            if (this.blockButton) {
                return
            }

            if (this.message.length < 1) {
                return this.$store.dispatch('messagesStore/showErrorMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Užduokite klausimą')
                })
            }

            this.blockButton = true
            await this.$store.dispatch('dtvStore/newHrQuestion', this.message)
            this.message = ''
            this.blockButton = false
        }
    }
}
</script>