<template>
	<custom-dialog :max-width="400" ref="dialog">
		<template #title>{{ $t(`Įkelti „MS Excel“ dokumentą`) }}</template>
		<template #content>
			<v-row>
				<v-col cols="12" class="d-flex justify-center"><img :src="excelImage"/></v-col>
				<v-col cols="12"><p class="text-center">
					{{
						$t('Užpildykite excel dokumento šabloną "pavadinimas"  suvesdami reikalingą informaciją apie įmonės darbuotojus ir  įkelkite dokumentą. Duomenys bus automatiškai importuoti ir pereisite prie kito žingsnio - struktūros sudarymo, kuri bus sudaryta iš Jūsų užpildytų skyrių informacijos.')
					}}
				</p></v-col>
<!--				<v-col cols="12">-->
<!--					<form-checkbox :is-checked.sync="override"><div class="pl-2">-->
<!--						{{-->
<!--							$t('Sukurtų darbuotojų informaciją atnaujinti pagal įkeliamą „MS Excel“ dokumento versiją')-->
<!--						}}-->
<!--					</div></form-checkbox>-->
<!--				</v-col>-->
				<v-col cols="12" class="d-flex flex-column gap-3">
					<hra-button
						:color="uiColor.primary"
						no-background
						border
						full-width
						@click.prevent="onDownloadExample"
					>{{ $t("Atsisiųsti šabloną informacijos importavimui.") }}
					</hra-button>
					<form-file-button
						accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
						v-slot="{onClick}"
						v-model="excel"
					>
						<hra-button :color="uiColor.primary" full-width :loading="isLoading"
						            @click="onClick">{{ $t("Įkelti dokumentą") }}
						</hra-button>
					</form-file-button>
					<hra-button full-width @click="onAbort">{{ $t("Atšaukti") }}</hra-button>
				</v-col>
			</v-row>
		</template>
	</custom-dialog>
</template>

<script>
import CustomDialog from "@/components/ui/dialog/CustomDialog";
import {mapGetters, mapMutations} from "vuex";
import EmployeeTableView from "@/components/ui/views/EmployeeTableView";
import SelectField from "@/domain/fields/SelectField";
import HraIcon from "@/components/ui/HraIcon";
import dialogMixin from "@/mixins/dialogMixin";
import HraDialogConfirm from "@/components/ui/dialog/HraDialogConfirm";
import DatepickerField from "@/domain/fields/DatepickerField";
import HraConfirm from "@/components/ui/dialog/HraConfirm";
import Button from "@/components/ui/button/Button";
import FormFileButton from "@/components/ui/form/FormFileButton";
import FormAlert from "@/components/ui/form/FormAlert";
import {importRouteProps} from "@/router/import";
import HraButton from "@/components/ui/button/HraButton";
import HraButtonLink from "@/components/ui/button/HraButtonLink";
import employeeImportService from "@/services/employees/employeeImportService";
import FormCheckbox from "@/components/ui/form/FormCheckbox";

export default {
	name: "ImportEmployeesModal",
	mixins: [dialogMixin],
	components: {
		FormCheckbox,
		FormAlert,
		HraConfirm,
		DatepickerField,
		HraDialogConfirm, HraIcon, HraButton, SelectField, EmployeeTableView, CustomDialog,
		HraButtonLink, FormFileButton, Button
	},
	props: {
		handleSelectFile: {type: Function, default: () => {}},
	},
	data: () => ({
		excelImage: require("@/assets/icons/excel.svg"),
		fileLoading: false,
		excel: null,
		translatePrefix: "page.import.employees.upload.",
		loaderName: "ApiEmployeesImportConvert",
		override: false,
		importRouteProps,
	}),
	computed: {
		...mapGetters({
			isLoaded: "employeesImport/isLoaded",
		}),
	},
	watch: {
		excel: {
			async handler(newFile) {
				if (newFile != null) {
					await this.handleSelectFile(newFile, this.override);
					this.closeWithDelay();
				}
				this.excel = null;
			},
		},
	},
	created() {
		this.load();
	},
	methods: {
		...mapMutations({
			load: "employeesImport/load",
			save: "employeesImport/save",
			clear: "employeesImport/clear",
		}),
		onTest() {
			this.handleSelectFile(this.excel, this.override)
		},
		async onDownloadExample() {
			const element = document.createElement("a");
			element.href = "/api/imports/example";
			element.click();
		},
		onAbort() {
			this.close();
		},
	},
};
</script>
