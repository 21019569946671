<template>
    <div class='document-sidebar__container'>
        <v-progress-linear
            :active='loading'
            :indeterminate='loading'
            absolute
            top
            color='primary'
        />
        <div class='document-sidebar__sticky'>
            <div class='d-flex flex-row align-center py-2 document-sidebar__nav'>
                <div class='document-sidebar__nav-text'>{{ title }}</div>
                <v-spacer />
                <ui-button
                    only-text
                    background='primary'
                    @click='handleCloseSidebar'
                >
                    <ui-icon>arrow-left</ui-icon>
                </ui-button>
            </div>
            <slot name='header' />
        </div>

        <div class='pe-5'>
            <slot />
        </div>
    </div>
</template>

<script>
import UiButton from '@/domain/components/UiButton.vue'
import UiIcon from '@/domain/components/UiIcon.vue'
import documentsItemsSidebars from '@/domain/documents/items/mixins/documentsItemsSidebars'

export default {
    name: 'DocumentSidebarContainer',
    components: { UiIcon, UiButton },
    mixins: [],
    props: {
        title: { type: String, required: true },
        loading: { type: Boolean, default: false }
    },
    methods: {
        handleCloseSidebar() {
            this.$root.$emit('documents-manage-items-close-sidebar')
        }
    }
}
</script>

<style lang='scss'>
</style>