<template>
    <v-container class='py-6 pe-8' fluid>
        <div class='dtv-home-container ps-8'>
            <div class='dtv-home-container__left'>
                <h1>
                    <ui-icon>dtv</ui-icon>
                    {{ $t('Darbo teisės vadovas') }}
                </h1>
            </div>
            <div class='dtv-home-container__right'>
                <text-field
                    v-if='false'
                    class='dtv-search-input'
                    placeholder='Ieškoti...'
                    v-model='search'
                >
                    <template #append-inner>
                        <ui-icon>find</ui-icon>
                    </template>
                </text-field>
            </div>
        </div>
        <div class='dtv-home-container ps-8 mt-5'>
            <div class='dtv-home-container__left'>
                <message-alert
                    v-if='isNotLt'
                    id='not-lt'
                    :message='$t("Content in english is not available")'
                    type='info'
                    class='mb-2'
                />
                <div class='dtv-buttons'>
                    <dtv-button
                        v-for='category in categories'
                        :key='category.id'
                        @click="$router.push({name: 'DtvDetailPage', params: {name: category.name}})"
                    >
                        <template #content>
                            <ui-icon>dtv-button-icon-1</ui-icon>
                        </template>
                        <template #footer>{{ category.title }}</template>
                    </dtv-button>
                </div>
            </div>
            <div class='dtv-home-container__right'>
            </div>
        </div>
        <custom-tabs v-if='isAdmin' class='mt-5' :default-tab-name='tabs[0].name' :tabs='tabs'>
            <template #new-dk-question>
                <dtv-new-question-tab />
            </template>
            <template #new-hr-question>
                <hr-new-question-tab />
            </template>
            <template #questions>
                <dtv-questions-tab />
            </template>
        </custom-tabs>
    </v-container>
</template>

<script>
import UiIcon from '@/domain/components/UiIcon.vue'
import DtvButton from '@/domain/dtv/components/DtvButton.vue'
import TextField from '@/domain/fields/TextField.vue'
import CustomTabs from '@/domain/components/CustomTabs.vue'
import DtvNewQuestionTab from '@/domain/dtv/components/tabs/DtvNewQuestionTab.vue'
import DtvQuestionsTab from '@/domain/dtv/components/tabs/DtvQuestionsTab.vue'
import { mapGetters } from 'vuex'
import { permissionOnlyAdmin } from '@/utils/permissions'
import i18n from '@/plugins/i18n'
import MessageAlert from '@/domain/messages/components/MessageAlert.vue'
import HrNewQuestionTab from '@/domain/dtv/components/tabs/HrNewQuestionTab.vue'

export default {
    name: 'DtvHomePage',
    components: { HrNewQuestionTab, MessageAlert, DtvQuestionsTab, DtvNewQuestionTab, CustomTabs, TextField, DtvButton, UiIcon },
    data() {
        return {
            search: ''
        }
    },
    beforeCreate() {
        this.$store.dispatch('dtvStore/fetchCategories')
    },
    computed: {
        ...mapGetters({
            categories: 'dtvStore/categories',
            questions: 'dtvStore/homeQuestions'
        }),
        isNotLt() {
            return i18n.locale !== 'lt'
        },
        isAdmin() {
            return this.hasPermissionTo(permissionOnlyAdmin)
        },
        tabs() {
            return [
                { name: 'new-dk-question', text: 'Klausimai teisininkui' },
                { name: 'new-hr-question', text: 'Klausimai personalistui' },
                { name: 'questions', text: `Mano užduoti klausimai (${this.questions.length})` }
            ]
        }
    }
}
</script>