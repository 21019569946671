<template>
    <div ref='content' class='dtv-article__content' v-html='content' />
</template>

<script>
export default {
    name: 'DtvArticleContent',
    props: {
        content: { type: String, required: true }
    },
    watch: {
        content: {
            handler() {
                this.$nextTick(() => {
                    const aTags = this.$refs.content.querySelectorAll('a')
                    aTags.forEach(aTag => aTag.setAttribute('target', '_blank'))
                })
            },
            immediate: true
        }
    }
}
</script>