<template>
    <div class='page' :class="{'page_with-background': withBackground}">
        <message-alerts v-if='messageListen' class='pt-3' :listen='messageListen' />
        <div class='page__container max-page-width'>
<!--            <loader v-if='!pageLoaded' style='position: absolute; margin-left: 48px;' />-->
            <slot v-if='pageLoaded' />
        </div>
    </div>
</template>

<script>
import FullRowLoader from '@/components/ui/FullRowLoader'
import FormAlert from '@/components/ui/form/FormAlert'
import Loaders from '@/domain/loaders/Loaders'
import MessageAlerts from '@/domain/messages/MessageAlerts'
import Loader from '@/domain/loaders/components/Loader.vue'

export default {
    name: 'Page',
    components: { Loader, MessageAlerts, Loaders, FormAlert, FullRowLoader },
    props: {
        initService: { type: Function, default: null },
        loaderListen: { type: [String, Array], default: null },
        messageListen: { type: [String, Array], default: null },
        withBackground: { type: Boolean, default: false },
        loaderTag: { type: [String], default: null }
    },
    data() {
        return {
            viewPage: false
        }
    },
    watch: {
        loadedByListen: {
            handler(value) {
                if (value === false) {
                    this.viewPageByListen = true
                }
            },
            immediate: true
        }
    },
    computed: {
        loadedByListen() {
            if (this.loaderTag == null) {
                return true
            }

            return !this.$store.getters['loadersStore/hasLoading'](this.loaderTag)
        },
        pageLoaded() {
            return this.viewPage && this.loadedByListen
        }
    },
    async created() {
        if (this.initService != null) {
            this.viewPage = await this.initService()
            return
        }

        this.viewPage = true
    }
}
</script>