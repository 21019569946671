<template>
    <Auth>
        <template #header>{{ $t("Pakeisti slaptažodį") }}</template>
        <template #form>
            <ResetPasswordForm/>
        </template>
    </Auth>
</template>

<script>
import Auth from "@/components/Auth.vue";
import ResetPasswordForm from "@/views/forms/auth/ResetPasswordForm.vue";
import HraButtonLink from "@/components/ui/button/HraButtonLink";

export default {
    components: {
        HraButtonLink,
        Auth,
        ResetPasswordForm,
    },
    data() {
        return {
            translatePrefix: "page.auth.resetPassword.",
        };
    },
};
</script>
