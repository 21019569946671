import customApi from "@/plugins/customApi";
import store from "@/store";
import moment from "moment";

class DepartmentVacationsService {

  lastFetchPayload = null;

  async init(tag) {
    const api = customApi(tag);
    const calculateDate = store.getters["departmentVacationsStore/filter"].calculateDate;
    // const year = (new Date()).getFullYear();
    // const fromDate = `${year-1}-01-01`;
    // const toDate = `${year+1}-12-31`;
    // this.lastFetchPayload = {fromDate, toDate, init: true};
    const response = await api.requests([
      api.factories.department.vacation.fetch({calculateDate}),
      api.factories.department.vacation.fetchWaiting(),
    ])

    if (response == null) {
      return false;
    }

    const [employees, waiting] = response;

    await store.dispatch("departmentVacationsStore/init", {
      ...employees,
      vacationsToApprove: waiting,
      fromDate: moment().subtract(5, "days").format("YYYY-MM-DD")
    });
    await store.dispatch("loadersStore/hideLoader", tag);
    return true;
  }

  async updateTimeline(tag, calculateDate) {
    const api = customApi(tag);
    const response = await api.request(api.factories.department.vacation.fetch({calculateDate}));

    if (response == null) {
      return false;
    }

    await store.dispatch("departmentVacationsStore/append", {
      ...response,
    });
    await store.dispatch("loadersStore/hideLoader", tag);
    return true;
  }

  async loadData(fromDate, toDate) {
    // const api = customApi(null);
    // this.lastFetchPayload = {fromDate, toDate, init: true};
    // const response = await api.request(api.factories.department.vacation.fetch(this.lastFetchPayload))
    //
    // if (response == null) {
    //   return false;
    // }
    //
    // await store.dispatch("departmentVacationsStore/append", {
    //   ...response,
    // });
    // return true;
  }

  reload() {
    // return new Promise(async (resolve) => {
    //   const api = customApi(null);
    //   const response = await api.request(api.factories.department.vacation.fetch(this.lastFetchPayload))
    //
    //   if (response == null) {
    //     return resolve(false);
    //   }
    //
    //   await store.dispatch("departmentVacationsStore/append", {
    //     ...response,
    //   });
    //   return resolve(true);
    // });
  }

  async getDetailVacation(tag, id) {
    const api = customApi(tag);
    const vacation = await api.request(api.factories.department.vacation.get(id));

    if (vacation == null) {
      return false;
    }

    await store.dispatch("departmentVacationsStore/setDetailVacation", vacation);
    await store.dispatch("loadersStore/hideLoader", tag);
    return true;
  }

  async changeVacation(tag, id, approve, message = "") {
    const api = customApi(tag);
    const response = await api.request(api.factories.department.vacation.change(id, {approve, message}));

    if (response == null) {
      return false;
    }

    if (approve) {
      await store.dispatch("employeeStore/approveVacation", id);
    } else {
      await store.dispatch("employeeStore/abortVacation", id);
    }
    await this.updateTimeline(tag);
    // await store.dispatch("departmentVacationsStore/changeVacation", vacation);
    await store.dispatch("departmentVacationsStore/clearFromWaiting", id);
    await store.dispatch("loadersStore/hideLoader", tag);
    const alertMessage = approve ? "approved vacation" : "aborted vacation";
    await store.dispatch("messagesStore/showSuccessMessage", {tag, message: alertMessage});
    return true;
  }

  async abortVacation(tag, id, message = "") {
    return this.changeVacation(tag, id, false, message);
  }

  async approveVacation(tag, id, message = "") {
    return this.changeVacation(tag, id, true, message);
  }
}

export default new DepartmentVacationsService;