export const SET_LOADED = "SET_LOADED";
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_LEADER = "SET_LEADER";
export const SET_PROFILE = "SET_PROFILE";
export const SET_USER = "SET_USER";
export const SET_SETTING = "SET_SETTING";

export default ({
  [SET_LOADED]: (state, payload) => state.isLoaded = payload,
  [SET_AUTHENTICATED]: (state, payload) => state.isAuthenticated = payload,
  [SET_LEADER]: (state, payload) => state.isLeader = payload,
  [SET_PROFILE]: (state, payload) => state.profile = payload,
  [SET_USER]: (state, payload) => state.user = payload,
  [SET_SETTING]: (state, payload) => state.setting = payload,
})