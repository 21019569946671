<template>
	<validation-observer ref="observer" tag="tr" :class="rowClass" :style="{position: 'relative'}"
	                     @dblclick.prevent="onEdit" v-slot="{errors}">
		<hra-table-inline-edit-error-col :count="calcErrorCount(errors)"/>
		<td v-for="{value: field, name} in fields" :key="`${id}-${name}`"
		    :class="{'hra-table__body-col': true, 'sticky-col': name === 'actions' && actionsSticky}"
		>
			<div v-if="name === 'actions'">
				<div class="d-flex justify-end gap-2 pa-3">
					<template v-if="isEdit">
						<hra-tooltip :text="$t('Išsaugoti')">
							<hra-button :size="buttonSize.small" :color="uiColor.successLight" @click="onSave">
								<hra-icon :name="iconName.ok"/>
							</hra-button>
						</hra-tooltip>
						<hra-tooltip :text="$t('Atšaukti')">
							<hra-button :size="buttonSize.small" :color="uiColor.dangerLight" @click="onAbort">
								<hra-icon :name="iconName.close"/>
							</hra-button>
						</hra-tooltip>
					</template>
					<template v-else>
						<hra-tooltip :text="$t('Redaguoti')">
							<hra-button :size="buttonSize.small" :color="uiColor.default" @click="onEdit">
								<hra-icon :name="iconName.edit"/>
							</hra-button>
						</hra-tooltip>
						<hra-tooltip v-if="isRemovable" :text="$t('Ištrinti')">
							<hra-confirm
								:title="$t('Ar tikrai norite ištrinti?')"
								:confirm-text="$t('Taip')"
								:abort-text="$t('Ne')"
								@confirm="onDelete"
							>
								<hra-button :size="buttonSize.small" :color="uiColor.dangerLight">
									<hra-icon :name="iconName.bin"/>
								</hra-button>
							</hra-confirm>
						</hra-tooltip>
					</template>
				</div>

				<hra-confirm
					ref="canSaveConfirm"
					:title="$t('Išsaugojimas negalimas. Užpildykite tuščius laukelius.')"
					:abort-text="$t('Uždaryti')"
				/>
			</div>
			<div v-else class="px-3 py-2 d-flex gap-3 align-center">
				<slot :name="`field.${name}.prepend`" :field="field" :value="field.value"/>
				<text-field
					v-if="['text', 'number', 'email', 'phone', 'float'].includes(field.type)"
					:name="name" :read-only="!isEdit" :view-valid="field.valid"
					v-bind="field" v-model="field.value"
				/>
				<select-field
					v-if="['select', 'select-departments', 'select-duties', 'select-yes-no'].includes(field.type) &&
                    !field.multiple"
					:name="name" :read-only="!isEdit" :options="field.options ? field.options : getOptions(field.type)"
					:view-valid="field.valid"
					v-bind="field" v-model="field.value"
				/>
				<select-field
					v-if="['select', 'select-departments', 'select-duties', 'select-yes-no'].includes(field.type) &&
                    field.multiple"
					:name="name" :read-only="!isEdit" :options="field.options ? field.options : getOptions(field.type)"
					v-bind="field" v-model="field.value"
					:creatable="['select-departments', 'select-duties'].includes(field.type)"
					filtered
				/>
<!--					<template #input.inner.append>-->
<!--						<slot :name="`field.${name}.input.append`" :field="field" :value="field.value" :isEdit="isEdit"/>-->
<!--					</template>-->
<!--				</select-field>-->
				<datepicker-field
					v-if="['date'].includes(field.type)"
					:name="name" :read-only="!isEdit" :view-valid="field.valid"
					v-bind="field" v-model="field.value"
				/>
				<slot :name="`field.${name}.append`" :field="field" :value="field.value" :isEdit="isEdit"/>
			</div>
		</td>
	</validation-observer>
</template>

<script>
import HraButton from "@/components/ui/button/HraButton";
import HraIcon from "@/components/ui/HraIcon";
import FormTextField from "@/components/ui/form/FormTextField";
import FormSelect from "@/components/ui/form/FormSelect";
import FormDatePicker from "@/components/ui/form/FormDatePicker";
import {ValidationObserver} from "vee-validate";
import _ from "lodash";
import HraTableInlineEditErrorCol from "@/components/ui/table/HraTableInlineEditErrorCol";
import Avatar from "@/components/ui/Avatar";
import TextField from "@/domain/fields/TextField";
import HraDialogConfirm from "@/components/ui/dialog/HraDialogConfirm";
import SelectField from "@/domain/fields/SelectField";
import DatepickerField from "@/domain/fields/DatepickerField";
import HraFormMultiSelectField from "@/components/ui/form/HraFormMultiSelectField";
import HraConfirm from "@/components/ui/dialog/HraConfirm";
import importService from "@/services/employees/employeeImportService";
import HraTooltip from "@/components/ui/HraTooltip";

export default {
	name: "HraTableInlineEditRow",
	components: {
		HraTooltip,
		HraConfirm,
		HraFormMultiSelectField,
		SelectField,
		DatepickerField,
		HraDialogConfirm, TextField, Avatar, HraTableInlineEditErrorCol,
		ValidationObserver, FormDatePicker, FormSelect, FormTextField, HraIcon, HraButton
	},
	props: {
		// row: {type: Object, required: true},
		// cols: {type: Array, required: true},
		id: {type: [Number, String], required: true},
		actionsSticky: {type: Boolean, default: false},
		options: {type: Object, default: () => ({})},
		isEdit: {type: Boolean, default: false},
		isEditable: {type: Boolean, default: true},
		isRemovable: {type: Boolean, default: true},
		value: {type: Array, default: () => ([])},
		beforeEdit: {type: Function, default: () => true},
		beforeSave: {type: Function, default: () => true},
		beforeAbort: {type: Function, default: () => true},
		beforeDelete: {type: Function, default: () => true},
	},
	data: () => ({
		// isEdit: false,
		isActive: false,
		fields: [],
		errorCount: 0,

		confirmCantSave: false,
		confirmDelete: false,
	}),
	watch: {
		value: {
			handler(newValue, oldValue) {
				if (!_.eq(newValue, oldValue)) {
					// if (this.isEdit) {
					// 	this.$emit("changeEditRowId", null);
					// }
					// this.isEdit = false;
					this.confirmCantSave = false;
					this.confirmDelete = false;
					this.fields = _.cloneDeep(newValue);
				}
			},
			deep: true,
			immediate: true,
		},
	},
	inject: ["importService", "createDepartments", "createDuties"],
	beforeDestroy() {
		this.$emit("changeEditRowId", null);
		this.confirmCantSave = false;
		this.confirmDelete = false;
	},
	computed: {
		rowClass() {
			return {
				// editRow
				"hra-table__row": true,
				"hra-table__row_edit": this.isEdit
			};
		},
	},
	created() {
		this.isActive = false;
	},
	mounted() {
	},
	updated() {
		if (this.isActive) {
			this.isActive = false;
		}
	},
	methods: {
		async onCreate(name, value) {
			const service = this[`create${name.charAt(0).toUpperCase() + name.slice(1)}`];
			if (service) {
				await service(value);
			}
		},
		getOptions(type) {
			if (this.options.hasOwnProperty(type)) {
				return this.options[type];
			}

			return [];
		},
		calcErrorCount(errors) {
			if (!this.isEdit) {
				return this.fields.filter(field => field.value != null && !field.value.valid).length;
			}

			this.errorCount = Object.keys(errors).filter(name => errors[name].length > 0).length;
			return this.errorCount;
		},
		async validateRow() {
			return await this.$refs.observer?.validate();
		},
		edit() {
			// this.$emit("changeEditRowId", this.id);
			// this.isEdit = true;
		},
		async save() {
			// this.$emit("changeEditRowId", null);
			// this.$emit("input", this.fields);
			// this.isEdit = false;
		},
		abort() {
			// this.$emit("changeEditRowId", null);
			// this.fields = _.cloneDeep(this.value);
			// this.isEdit = false;
		},
		async onEdit() {
			if (!this.isEdit) {
				this.$emit("startEdit", this.id);
			}
		},
		async onSave() {
			if (!await this.validateRow()) {
				this.$refs.canSaveConfirm[0]?.openConfirm();
				return;
			}

			let payload = {};
			this.fields.forEach(({name, value}) => {
				if (["actions"].includes(name)) {
					return;
				}

				payload[name] = {
					...value,
					valid: true,
				};
			});

			this.$emit("save", payload);
		},
		async onAbort() {
			this.$emit("cancelEdit", this.id);
			// await this.beforeAbort(this.fields) && this.abort();
		},
		onDelete() {
			this.$emit("delete", this.id);
		},
		onConfirmDelete() {
			// this.$emit("delete", this.id);
		},
	},
}
</script>
