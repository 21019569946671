<script>

import FormTextarea from "@/components/ui/form/FormTextarea.vue";
import UiButton from "@/domain/components/UiButton.vue";
import EmployeeTableView from "@/components/ui/views/EmployeeTableView.vue";
import DatepickerField from "@/domain/fields/DatepickerField.vue";
import HraFormFile from "@/components/ui/form/HraFormFile.vue";
import FormCheckbox from "@/components/ui/form/FormCheckbox.vue";
import MessageAlerts from "@/domain/messages/MessageAlerts.vue";
import Form from "@/components/ui/form/Form.vue";
import SelectField from "@/domain/fields/SelectField.vue";
import UiCalendar from "@/domain/components/UiCalendar/UiCalendar.vue";
import {mapGetters} from "vuex";
import moment from "moment/moment";
import {REMOTE_WORK_CHECK_TAG} from "@/tags/tagsRemoteWork";

export default {
    name: "FormRemoteWork",
    components: {
        UiCalendar,
        SelectField,
        Form,
        MessageAlerts,
        FormCheckbox,
        HraFormFile,
        DatepickerField,
        EmployeeTableView,
        UiButton,
        FormTextarea
    },
    data() {
        return {
            yearMonth: null,
            minDate: moment().format('YYYY-MM-DD'),
            validateTimeout: null,
            REMOTE_WORK_CHECK_TAG
        }
    },
    watch: {
        startCalendarDate: {
            handler(value) {
                this.yearMonth = (value ? moment(value) : moment()).format('YYYY-MM')
            },
            immediate: true
        }
    },
    computed: {
        ...mapGetters({
            currentRemoteWork: 'storeRemoteWork/currentRemoteWork',
            currentEvent: 'vacationModalStore/currentEvent',
            calendarEvents: 'vacationModalStore/calendarEvents',
            editableCalendar: 'vacationModalStore/editableCalendar'
        })
    },
    methods: {
        onChangeField(value, field) {
            this.$store.commit('storeRemoteWork/updateCurrentRemoteWork', { field, value })

            clearTimeout(this.validateTimeout)
            this.validateTimeout = setTimeout(async () => {
                await this.$store.dispatch('storeRemoteWork/validate');
            }, 300)

        }
    }
}
</script>

<template>
    <Form v-model='currentRemoteWork' is-dialog @submit='() => {}' ref='form'>
        <template #form>
            <v-row>
                <v-col cols='12'>
                    <div class='dialog__main-content'>
                        <div class='dialog__main-calendar'>
                            <div>
                                <ui-calendar
                                    with-first-month-navigation
                                    max-month-count='2'
                                    :year-month.sync='yearMonth'
                                    :events='calendarEvents'
                                    :select-start-date='currentRemoteWork.start'
                                    :select-end-date='currentRemoteWork.end'
                                    :clickable-dates='true'
                                    disable-mobile-click
                                    @update:selectStartDate='onChangeField($event, "start")'
                                    @update:selectEndDate='onChangeField($event, "end")'
                                />
                            </div>
                        </div>
                        <div class='dialog__main-form' style='width: 100%;'>
                            <div class='d-flex flex-column flex-sm-row gap-3'>
                                <datepicker-field
                                    style='width: 100%;'
                                    name='start'
                                    :label="$t('Data nuo')"
                                    :value='currentRemoteWork.start'
                                    :min-date='minDate'
                                    @input='onChangeField($event, "start")'
                                />
                                <datepicker-field
                                    style='width: 100%;'
                                    name='toDate'
                                    :min-date='currentRemoteWork.start'
                                    :label="$t('Data iki')"
                                    :value='currentRemoteWork.end'
                                    @input='onChangeField($event, "end")'
                                />
                            </div>
                            <form-textarea
                                name='message'
                                :label="$t('Žinutė')"
                                :value='currentRemoteWork.message'
                                @input='onChangeField($event, "message")'
                            />
                            <message-alerts :listen='[REMOTE_WORK_CHECK_TAG]' />
                        </div>
                    </div>
                </v-col>
            </v-row>
        </template>

        <template #buttons>
            <slot name="button" />
        </template>
    </Form>
</template>

<style scoped lang="scss">

</style>