export default axios => ({
  // fetch: (employeeId, params) => axios.get(`/api/v2/employees/${employeeId}/vacations`, {params}),
  fetch: (employeeId, params) => axios.get(`/api/events/holidays/employees/${employeeId}`, {params}),
  get: (employeeId, id) => axios.get(`/api/v2/employees/${employeeId}/vacations/${id}`),
  check: (employeeId, id, params) => axios.get(`/api/v2/employees/${employeeId}/vacations/${id}/check`, {params}),
  store: (employeeId, payload) => axios.post(`/api/v2/employees/${employeeId}/vacations`, payload),
  update: (employeeId, id, payload) => axios.post(`/api/v2/employees/${employeeId}/vacations/${id}`, payload),
  change: (employeeId, id, payload) => axios.post(`/api/v2/employees/${employeeId}/vacations/${id}/change`, payload),
  abort: (employeeId, id) => axios.post(`/api/v2/employees/${employeeId}/vacations/${id}/abort`, {}),

  checkAdmin: (employeeId, id, params) => axios.get(`/api/v2/admins/employees/${employeeId}/vacations/${id}/check`, {params}),
  storeAdmin: (employeeId, payload) => axios.post(`/api/v2/admins/employees/${employeeId}/vacations`, payload),
  updateAdmin: (employeeId, id, payload) => axios.post(`/api/v2/admins/employees/${employeeId}/vacations/${id}`, payload),

  approveDeputy: (deputyId) => axios.post(`/api/v2/employees/vacations/deputies/${deputyId}/approve`, {}),
  abortDeputy: (deputyId) => axios.post(`/api/v2/employees/vacations/deputies/${deputyId}/abort`, {}),
})
