<template>
    <validation-observer
        ref='Validator'
        tag='div'
        class='setting-module-item'
        :class='{editable: isEdit}'
    >
        <div class='setting-module-item__sticky' ref='Sticky' :class='{isSticky: isSticky}'>
            <div class='setting-module-item__header'>
                <div class='setting-module-item__title'>{{ title }}</div>
                <div v-if='isEdit' class='setting-module-item__actions'>
                    <ui-button
                        prepend-icon='close'
                        size='small'
                        only-hover-background
                        :confirm='cancelConfirm'
                    />
                    <ui-button
                        prepend-icon='ok'
                        size='small'
                        background='success-light'
                        only-hover-background
                        :confirm='saveConfirm'
                    />
                </div>
                <div v-else class='setting-module-item__actions'>
                    <ui-button
                        prepend-icon='edit'
                        size='small'
                        only-hover-background
                        @click='handleEdit'
                    />
                </div>
            </div>
            <div class='setting-module-item__description' :class='{"show-short": !expandDescription}' v-html='description' />
            <div class='setting-module-item__show-more' @click='handleToggleShowMoreDescription'>
                <ui-icon>{{ expandDescription ? 'arrow-up' : 'arrow-down' }}</ui-icon>
                <span>{{ $t(expandDescription ? 'Rodyti mažiau' : 'Rodyti daugiau') }}</span>
            </div>
        </div>
        <div class='setting-module-item__config'>
            <slot name='company-config' />
        </div>
        <slot name='departments-config' />
    </validation-observer>
</template>

<script>
import UiButton from '@/domain/components/UiButton.vue'
import UiIcon from '@/domain/components/UiIcon.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import TextField from '@/domain/fields/TextField.vue'
import { ValidationObserver } from 'vee-validate'
import { GLOBAL_TOAST_TAG } from '@/utils/default'

export default {
    name: 'SettingModuleItem',
    components: { TextField, FormCheckbox, UiIcon, UiButton, ValidationObserver },
    props: {
        isEdit: { type: Boolean, default: false },
        title: { type: String, required: true },
        description: { type: String, required: true }
    },
    data() {
        return {
            isSticky: false,
            observer: null,
            expandDescription: false,
            saveConfirm: {
                props: {
                    title: this.$t('setting.module.confirm.save')
                },
                on: {
                    confirmed: async (confirm) => {
                        confirm.close()

                        if (!(await this.$refs.Validator.validate())) {
                            await this.$store.dispatch('messagesStore/showErrorMessage', {
                                tag: GLOBAL_TOAST_TAG,
                                message: this.$t('setting.module.error.validate')
                            })
                            return
                        }

                        this.$emit('save')
                    }
                }
            },
            cancelConfirm: {
                props: {
                    title: this.$t('setting.module.confirm.cancel')
                },
                on: {
                    confirmed: (confirm) => {
                        confirm.close()
                        this.$emit('cancel')
                    }
                }
            }
        }
    },
    methods: {
        handleEdit() {
            this.$emit('edit')
        },
        handleToggleShowMoreDescription() {
            this.expandDescription = !this.expandDescription
        }
    }
}
</script>

<style lang='scss'>
.setting-module-item {
    &.editable {
        //box-shadow: inset 0 0 6px -1px rgba(0, 0, 0, 0.1);
    }


    &:not(:last-child) {
        border-bottom: 1px solid #E6E9F0;
    }

    &__sticky {
        padding: 20px 20px 10px;
        position: sticky;
        top: 64px;
        z-index: 3;
        background: #FFFFFF;

        &.isSticky {
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.09);
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    &__title {
        font-size: 16px;
        font-weight: 600;
    }

    &__actions {
        display: flex;
        gap: 5px;
    }

    &__description {
        position: relative;
        font-size: 14px;
        color: #6E7191;
        max-width: 95%;
        overflow: hidden;
        display: -webkit-box;
        max-height: 1000px;
        transition: all 200ms ease-in-out;

        &::after {
            content: '';
            background: rgb(255,255,255);
            background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0;
        }

        &.show-short {
            //-webkit-line-clamp: 2; /* number of lines to show */
            //line-clamp: 2;
            //-webkit-box-orient: vertical;
            max-height: 42px;

            &::after {
                height: 21px;
            }
        }
    }

    &__show-more {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        cursor: pointer;
        gap: 6px;
        font-size: 12px;
    }

    &__config {
        margin: 0 20px 20px;
    }

    &:has(.setting-module-item-more) &__config {
        margin-bottom: 0;
    }
}
</style>