<template>
    <div class='approval-level--header'>
        <div class='approval-level--header__title'>
            {{ $t('Level {count} approval', { count: order }) }}
        </div>
        <div class='approval-level--header__mincount'>
            <span>
                {{ $t('Tvirtinimų kiekis') }}
            </span>
            <text-field
                name='minToApprove'
                type='number'
                :min='min'
                :max='max'
                :step='1'
                :value='minToApprove'
                @input='handleChangeMinToApprove'
                inlineNumberInput
            />
            <hra-tooltip
                :text='$t("Nurodoma, kiek šiame lygyje reikia patvirtinimų iki kol prašymai tvirtinti pereina į aukštesnį. Pvz: jei nurodysime, kad reikia „1\" patvirtinimo, bet pridėsime 2 tvirtinančius darbuotojus, tai bet kuriam iš jų patvirtinus prašymas pereis į kitą lygį. Jei norime, kad abu patvirtintų, prie kiekio nustatome „2.")'
            >
                <ui-icon>message-info</ui-icon>
            </hra-tooltip>
        </div>
        <div v-if='visibleSelectedCount' class='approval-level--header__count'>
            <span v-html="$t('Pasirinkti tvirtinantys darbuotojai {count}', { count: this.level.users.length })"></span>
        </div>
        <div class='approval-level--header__button'>
            <hra-tooltip
                :text="$t('Pašalinti lygį')"
            >
                <ui-button
                    type='button'
                    size='normal'
                    prepend-icon='bin'
                    background='none'
                    class='pa-0'
                    style='min-height: auto;'
                    @click='handleRemoveLevel'
                />
            </hra-tooltip>
        </div>
    </div>
</template>

<script>
import TextField from '@/domain/fields/TextField.vue'
import UiButton from '@/domain/components/UiButton.vue'
import UiIcon from '@/domain/components/UiIcon.vue'
import HraTooltip from '@/components/ui/HraTooltip'

export default {
    name: 'ApprovalLevelHeader',
    components: {
        TextField, UiButton, UiIcon, HraTooltip
    },
    props: {
        order: { type: Number, required: true },
        minToApprove: { type: Number, required: true },
        min: { type: Number, required: true },
        max: { type: Number, required: true },
        visibleSelectedCount: { type: Boolean, default: false }
    },
    methods: {
        handleRemoveLevel() {
            this.$emit('remove-level')
        },
        handleChangeMinToApprove(value) {
            this.$emit('update-min-to-approve', value)
        }
    }
}
</script>

<style lang='scss'>
@import '@/assets/sass/core/vars';

.approval-level--header {
    background-color: $violet-200;
    color: $white;
    padding: 7px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;

    & > div {
        display: flex;
        align-items: center;
        gap: 0 8px;
    }

    &__title {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 700;
    }

    &__mincount {
        .TextField {
            transform: translateY(2px);
        }

        span {
            font-size: 12px;
            font-weight: 500;

            &:has(.ui-icon) {
                line-height: 1;
            }
        }

        .ui-icon {
            font-size: 16px;
        }
    }

    &__count {
        span {
            & > span {
                font-weight: 700;
            }
        }
    }

    &__button {
        justify-self: end;
    }

    .TextField {
        max-width: 80px;

        .field {
            min-height: 30px;
        }
    }
}
</style>