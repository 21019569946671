export default axios => ({
    getUserData: token => axios.get(`/api/v1/setup/register/${token}`),
    getQrCode: () => axios.get(`/api/v1/setup/qr-code`),
    getCompanyData: () => axios.get(`/api/v1/setup/company-data`),

    register: payload => axios.post(`/api/v1/setup/register`, payload),
    enableTwoFactor: payload => axios.post(`/api/v1/setup/enable-two-factor`, payload),
    companyDataSave: payload => axios.post(`/api/v1/setup/company-data`, payload),

    getCountries: (params) => axios.get(`/api/countries`, { params })
});
