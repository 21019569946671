import CustomApi from '@/plugins/customApi'

const state = () => ({
    fetching: false,
    departments: null
})

const getters = {
    departments: ({ departments }) => departments ?? [],
    departmentOptions: ({ departments }) => {
        if (departments == null) {
            return []
        }

        return departments.map(({ id, name }) => ({ id, title: name }))
    }
}

const customApi = CustomApi()
const actions = {
    async fetchDepartments({ state }) {
        if (state.fetching || state.departments != null) {
            return
        }

        state.fetching = true
        try {
            const { data } = await customApi.factories.company.departments.fetch()
            state.departments = data
        } catch (e) {
            console.error(e)
        } finally {
            state.fetching = false
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions
}