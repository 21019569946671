<template>
    <div @click='open'>
        <slot />

        <v-dialog
            v-model='dialog'
            persistent
            scrollable
            :max-width='maxWidth'
            transition='dialog-top-transition'
            content-class='is-confirm'
        >
            <template>
                <div class='dialog-confirm gap-6'>
                    <div v-if='!noHeader' class='dialog-confirm__header px-5'>
                        <h6>
                            <slot name='title'>{{ title }}</slot>
                        </h6>
                    </div>
                    <div v-if='$slots.content' class='dialog-confirm__content'>
                        <slot name='content' />
                    </div>
                    <div class='d-flex flex-column gap-3 px-5'>
                        <loaders v-if='tag' :listen='tag' />
                        <!--					<message-alerts :listen="tag"/>-->
                        <div v-if='!loading' class='dialog-confirm__content d-flex flex-row gap-3'>
                            <div @click.prevent='onClose' style='width: 100%;'>
                                <slot name='button.abort'>
                                    <hra-button :size='buttonSize.small' :color='uiColor.default' full-width>
                                        <slot name='button.abort.text'>{{ $t('Ne') }}</slot>
                                    </hra-button>
                                </slot>
                            </div>
                            <div @click.prevent='onConfirm' style='width: 100%;'>
                                <slot name='button.confirm'>
                                    <hra-button :size='buttonSize.small' :color='uiColor.primary' full-width>
                                        <slot name='button.confirm.text'>{{ $t('Taip') }}</slot>
                                    </hra-button>
                                </slot>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </v-dialog>
    </div>
</template>

<script>
import Loaders from '@/domain/loaders/Loaders'
import MessageAlerts from '@/domain/messages/MessageAlerts'
import HraButton from '@/components/ui/button/HraButton'
import HraIcon from '@/components/ui/HraIcon'
import { mapGetters } from 'vuex'

export default {
    name: 'BaseConfirm',
    components: { HraIcon, HraButton, MessageAlerts, Loaders },
    props: {
        maxWidth: { type: Number, default: 400 },
        noHeader: { type: Boolean, default: false },
        tag: { type: String, default: null },
        title: { type: String, default: '' }
    },
    data() {
        return {
            dialog: false
        }
    },
    computed: {
        ...mapGetters({
            hasLoading: 'loadersStore/hasLoading'
        }),
        loading() {
            return this.hasLoading(this.tag)
        }
    },
    created() {
        if (!!this.tag) {
            this.$store.dispatch('messagesStore/addToastTag', this.tag)
        }
    },
    beforeDestroy() {
        this.close()
        if (!!this.tag) {
            this.$store.dispatch('messagesStore/removeToastTag', this.tag)
            this.$store.dispatch('loadersStore/hideLoader', this.tag)
        }
    },
    methods: {
        getDialog() {
            return this.dialog
        },
        open() {
            this.dialog = true
        },
        close() {
            // this.$store.dispatch("messagesStore/clearMessage", this.tag);
            this.dialog = false
        },
        onClose() {
            this.close()
            this.$emit('abort')
        },
        onConfirm() {
            this.$emit('confirm', this)
        }
    }
}
</script>