<template>
	<ValidationObserver ref="observer" v-slot="{ invalid }"
                      tag="form" class="form custom-form"
                      @submit.prevent="onSubmit">
    <slot v-if="!messagesViewFooter" name="messages">
      <form-alert v-if="invalid && isMessageVisible" type="error" :text="defaultErrorMessage" />
      <slot name="messages.custom" />
    </slot>
    <slot/>
    <slot v-if="messagesViewFooter" name="messages">
      <form-alert v-if="invalid && isMessageVisible" type="error" :text="defaultErrorMessage" />
      <slot name="messages.custom" />
    </slot>
	</ValidationObserver>
</template>

<script>
import {ValidationObserver} from "vee-validate";
import FormAlert from "@/components/ui/form/FormAlert";

export default {
	name: "ElvisForm",
	components: {FormAlert, ValidationObserver},
	props: {
		formName: {type: String, required: true},
    messagesViewFooter: {type: Boolean, default: false},
    autocomplete: {type: String, default: "on"},
	},
	data() {
		return {
			language: "LT",
      submitted: false,
			defaultErrorMessage: this.$t("Užpildykite raudonai pažymėtus laukelius"),
		};
	},
	provide() {
		return {
			formName: this.formName,
		};
	},
  computed: {
    isMessageVisible() {
      return this.submitted;
    },
  },
	methods: {
		async isValid() {
			return await this.$refs.observer?.validate();
		},
		async onSubmit() {
      this.submitted = true;

			if (!await this.isValid()) {
				return;
			}

			this.$emit("submit");
		},
	}
};
</script>
