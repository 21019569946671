<template>
    <custom-dialog :max-width='550'>
        <template #title>{{ $t('Dokumentų grupavimas') }}</template>
        <template #content>
            <v-row class='pa-3'>
                <v-col cols='12'>
                    <text-field v-model='title' :label="$t('Pavadinimas')" name='name' rules='required' />
                </v-col>

                <v-col cols='12'>
                    <!--                    loadingDocuments-->
                    <selected-documents-list
                        :selected-documents='documents'
                        @addDocuments='handleAddDocuments'
                        @removeDocument='handleRemoveDocument'
                    />
                </v-col>
            </v-row>
        </template>
        <template #footer>
            <ui-button background='default' prepend-icon='close' @click='close'>{{ $t('Uždaryti') }}</ui-button>
            <ui-button :disabled='!title' :loading='loading || loadingDocuments' background='primary' prepend-icon='ok'
                       @click='handleSubmit'>
                {{ $t('Išsaugoti') }}
            </ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import dialogMixin from '@/mixins/dialogMixin'
import UiButton from '@/domain/components/UiButton.vue'
import TextField from '@/domain/fields/TextField.vue'
import customApi from '@/plugins/customApi'
import { GLOBAL_TOAST_TAG } from '@/utils/default'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import SelectedDocumentsList from '@/domain/documents/components/sending/documentsSelection/SelectedDocumentsList.vue'

export default {
    name: 'GroupUpdateModal',
    components: { SelectedDocumentsList, CustomDialog, UiButton, TextField },
    mixins: [dialogMixin],
    props: {
        item: { type: Object, required: true }
    },
    data() {
        return {
            loading: false,
            loadingDocuments: false,
            title: '',
            documents: []
        }
    },
    created() {
        this.title = this.item.title
        this.fetchDocuments()
    },
    computed: {
        documentsIds() {
            return this.documents.map(({ id }) => id)
        }
    },
    methods: {
        async fetchDocuments() {
            this.loadingDocuments = true
            try {
                const { data: { items } } = await customApi().factories.documents.groups.fetchDocuments(this.item.id, { page: 0 })

                this.documents = items.map(({ document }) => document)
            } catch (e) {

            } finally {
                this.loadingDocuments = false
            }
        },
        handleAddDocuments(documents) {
            this.documents.push(...(documents.filter(({ id }) => !this.documentsIds.includes(id))))
        },
        handleRemoveDocument(document) {
            const index = this.documents.findIndex(({ id }) => id === document.id)
            if (index === -1) {
                return
            }

            this.documents.splice(index, 1)
        },
        async handleSubmit() {
            this.loading = true

            try {
                let payload = {
                    title: this.title,
                    documentIds: this.documentsIds
                }

                const { data } = await customApi().factories.documents.groups.update(this.item.id, payload)

                this.$emit('groupUpdated', data)
                await this.$store.dispatch('messagesStore/showSuccessMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Išsaugotą sekmingai')
                })
                this.close()
            } catch (error) {
                await this.$store.dispatch('messagesStore/showErrorMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: error?.response?.data?.message[0] ?? this.$t('Serverio klaida')
                })
            } finally {
                this.loading = false
            }
        }
    }
}
</script>