//  initial state

const defaultState = () => ({
    currentPage: 1,
    lastPage: 1,
    total: 0,
    data: [],
});

const state = () => ({
    ...defaultState(),
});

// getters
const getters = {
    currentPage(state) {
        return state.currentPage;
    },
    lastPage(state) {
        return state.lastPage;
    },
    total(state) {
        return state.total;
    },
    rows(state) {
        return state.data;
    },
};

// actions
const actions = {};

//  mutations
const mutations = {
    load(state, payload) {
        state.currentPage = payload.currentPage;
        state.lastPage = payload.lastPage;
        state.total = payload.total;
        state.data = payload.data;
    },
    clearEmployees(state) {
        state.data = [];
    },
    clear(state) {
        Object.assign(state, defaultState());
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
