import _ from 'lodash'
import moment from 'moment'
import { generateUuid } from '@/utils/default'

export const scheduleId = ({ scheduleId }) => scheduleId
export const scheduleName = ({ scheduleName }) => scheduleName
export const schedules = ({ schedules }) => schedules
export const selectedTimeInterval = ({ selectedTimeInterval }) => selectedTimeInterval
export const selectedTimetableInterval = ({ selectedTimetableInterval }) => selectedTimetableInterval
export const fromMoment = ({ from }) => moment(from)
export const toMoment = ({ to }) => moment(to)
export const minusBreaks = ({ minusBreaks }) => minusBreaks
export const currentTab = ({ currentTab }) => currentTab
export const showOtherEvents = ({ showOtherEvents }) => showOtherEvents
export const durationByUserId = ({ durationByUserId }) => durationByUserId
export const breaks = ({ breaks }) => breaks

export const visibleBreaksIds = ({ breaks }) => breaks.filter(({ checked }) => checked).map(({ id }) => id)
export const holidayDates = ({ holidays }) => {
    return holidays.map(({ date }) => date)
}
export const timeIntervals = ({ timeIntervals, breaks }) => {
    return [
        ...timeIntervals.map(interval => ({
            ...interval,
            eventType: 'work'
        }))
    ]
}
export const timetableIntervals = ({ timetableIntervals }) => timetableIntervals

export const datesWithWarning = ({ timetables }) => {
    return [
        ...(
            new Set(timetables
                .filter(({ hasMinUsers }) => !hasMinUsers)
                .map(({ start, end }) => ([start, end]))
                .flat()
            )
        )
    ]
}

export const linesWithWarning = ({ timetables }) => {
    return [
        ...(
            new Set(timetables
                .filter(({ hasMinUsers }) => !hasMinUsers)
                .map(({ scheduleLineId }) => scheduleLineId)
            )
        )
    ]
}

function sortLines(a, b) {
    const aType = a.type === 'group' ? 1 : 0
    const bType = b.type === 'group' ? 1 : 0

    return aType > bType ? -1 : 1
}

export const rows = ({ lines: storeLines, events, durationByUserId, minusBreaks, scheduleId }) => {
    const lines = _.cloneDeep(storeLines)

    function expandedRow(line, level = 0) {
        let time = ''
        const eventCount = events.filter(event => event.userId === line.userId)?.length ?? 0

        if (line.type === 'user') {
            const eventsFilter = durationByUserId
                ? event => event.type === 2001 && event.userId === line.userId
                : event => event.type === 2001 && event.schedule?.scheduleLineId === line.id

            const minutes = events
                .filter(eventsFilter)
                .reduce((result, event) => {
                    if (minusBreaks) {
                        const breaksDuration = event.breaks?.filter(({ subWorkTime }) => subWorkTime)
                            .map(({ duration }) => duration).reduce((a, b) => a + b, 0) ?? 0

                        return result + event.duration.minutes - breaksDuration
                    }

                    return result + event.duration.minutes
                }, 0)
            time = `${String(Math.ceil(minutes / 60)).padStart(2, '0')}h ${String(Math.floor(minutes % 60)).padStart(2, '0')}min`
        }

        return [
            {
                ...line,
                tabs: level,
                time,
                hideLine: line.user && line.user.dismissed && eventCount === 0
            },
            ...lines
                .sort(sortLines)
                .filter(
                    ({
                        scheduleLineId,
                        scheduleId: lineScheduleId,
                    }) => scheduleLineId === line.id && lineScheduleId === scheduleId
                )
                .map((line) => expandedRow(line, level + 2))
                .flat(),
        ];
    }

    const resultRows = lines
        .sort(sortLines)
        .filter(
            ({
                scheduleLineId,
                scheduleId: lineScheduleId,
            }) => scheduleLineId == null && lineScheduleId === scheduleId
        )
        .map((line) => expandedRow(line))
        .flat()
        .filter(({hideLine}) => !hideLine)

    const hasGroupRows = resultRows.some((row) => row.type === 'group');
    const hasUserRows = resultRows.some((row) => row.type === 'user');

    if (!hasGroupRows) {
        resultRows.unshift({
            type: 'group',
            id: 'fake-row-1',
            name: null,
        });
    }

    if (!hasUserRows) {
        resultRows.push({
            type: 'user',
            id: 'fake-row-2',
            user: {
                name: null,
            },
        });
    }

    return resultRows;
};

export const linesTypeGroup = ({ lines }) => lines.filter(({ type }) => type !== 'user')
export const linesTypeUser = ({ lines }) => lines.filter(({ type }) => type === 'user')

export const holidayEventByUserId = ({ events, from, to }) => {
    let result = {}

    const momentFrom = moment(from).startOf('day')
    const momentTo = moment(to).endOf('day')

    events
        .filter(({ type }) => type !== 2001)
        .forEach(event => {
            let date = moment(event.start)
            for (let i = 0; i < event.calendarDays; i++) {
                if (result[event.userId] == null) {
                    result[event.userId] = {}
                }

                if (date.isBefore(momentFrom) || date.isAfter(momentTo)) {
                    date.add(1, 'day')
                    continue;
                }

                const dateFormatted = date.format('YYYY-MM-DD')
                result[event.userId][dateFormatted] = {
                    ...event,
                    start: dateFormatted,
                    end: dateFormatted,
                    startWithTime: `${dateFormatted} 00:00:00`,
                    endWithTime: `${dateFormatted} 23:59:59`,
                    duration: {
                        seconds: 24 * 60 * 60,
                        minutes: 24 * 60,
                        days: 1
                    }
                }
                date.add(1, 'day')
            }
        })

    return result
}
export const scheduleEventsByScheduleLineId = ({ events }) => {
    let result = {}
    events.filter(({ type, schedule }) => type === 2001 && schedule != null)
        .forEach(event => {
            if (result[event.schedule.scheduleLineId] == null) {
                result[event.schedule.scheduleLineId] = []
            }

            result[event.schedule.scheduleLineId].push(event)
        })

    return result
}
export const events = (state, { rows, holidayEventByUserId, scheduleEventsByScheduleLineId }) => {
    let result = []

    rows.forEach((row, rowIndex) => {
        if (row.type !== 'user') {
            return
        }

        let userHolidayEvents = _.cloneDeep(holidayEventByUserId[row.userId] ?? {})
        scheduleEventsByScheduleLineId[row.id]?.forEach(event => {
            const hasHoliday = userHolidayEvents.hasOwnProperty(event.start)

            if (hasHoliday) {
                delete userHolidayEvents[event.start]
            }

            if(row.user.dismissed) {
                const dismissedDate = moment(row.user.dismissedDate);
                const eventDate = moment(event.start);

                if(eventDate.isAfter(dismissedDate)) {
                    return;
                }
            }

            result.push({
                key: generateUuid(),
                hasHoliday,
                row,
                editable: true,
                rowIndex,
                ...event
            })
        })

        Object.values(userHolidayEvents).forEach(event => {
            result.push({
                key: generateUuid(),
                hasHoliday: true,
                row,
                editable: false,
                rowIndex,
                ...event
            })
        })
    })

    return result
}

export const timetables = ({ timetables }, { rows }) => {
    let result = []

    timetables.forEach(timetable => {
        rows.forEach((row, rowIndex) => {
            if (timetable.scheduleLineId === row.id) {
                result.push({
                    key: generateUuid(),
                    row,
                    editable: true,
                    rowIndex,
                    ...timetable
                })
            }
        })
    })

    return result
}
export const users = ({ lines }) => {
    return _.uniqBy(lines.filter(({ type }) => type === 'user').map(({ user }) => user), 'id')
}