import customApi from "@/plugins/customApi";
import store from "@/store";
import router from "@/router";
import {
    login as loginName,
    towFactor as twoFactorName,
    enableTwoFactor as enableTwoFactorName
} from "@/domain/auth/routes";
import {GLOBAL_TOAST_TAG} from "@/utils/default";

class AuthService {
    async init(tag) {
        // const api = customApi(tag);
        // const response = await api.request(api.factories.auth.config());
        //
        // if (response == null) {
        //   return;
        // }

        const response = {
            user: {
                name: "Test Test",
                roles: [1],
                permissions: [{name: "*"}],
            },
            isAuthenticated: true,
            profile: {
                name: "Test Test",
            },
            settings: {}
        };

        await store.dispatch("authStore/init", response);
        await store.dispatch("loadersStore/hideLoader", tag);
    }

    async login(tag, payload) {
        const api = customApi(tag);
        const response = await api.request(api.factories.auth.login(payload));

        if (response == null) {
            return;
        }

        if (response.two_factor) {
            await router.push({name: twoFactorName});
            return;
        }

        await this.init(tag);
        await store.dispatch("loadersStore/hideLoader", tag);
        await router.push({name: enableTwoFactorName});
    }

    async getQrCodeSvg(tag) {
        const api = customApi(tag);
        const response = await api.request(api.factories.user.qrCode());
        await store.dispatch("loadersStore/hideLoader", tag);
        return response;
    }

    async enableTwoFactor(tag, code) {
        const api = customApi(tag);
        const response = await api.request(api.factories.user.confirm2fa(code));

        if (response == null) {
            return;
        }

        await store.dispatch("loadersStore/hideLoader", tag);
        await router.push("/");
    }

    async towFactor(tag, code) {
        const api = customApi(tag);
        const response = await api.request(api.factories.auth.twoFactor(code));

        if (response == null) {
            return;
        }

        await this.init(tag);
        await store.dispatch("loadersStore/hideLoader", tag);
        await router.push("/");
    }

    async getInvitePayload(tag, token) {
        const api = customApi(tag);
        const response = await api.request(api.factories.auth.inviteData(token));

        if (response == null) {
            return null;
        }

        await store.dispatch("loadersStore/hideLoader", tag);
        return response;
    }

    async register(tag, payload) {
        const api = customApi(tag);
        const response = await api.request(api.factories.auth.register(payload));

        if (response == null) {
            return;
        }

        response.message && await store.dispatch("messagesStore/showSuccessMessage", {
            tag: GLOBAL_TOAST_TAG,
            message: response.message,
        });

        await this.init(tag);
        await store.dispatch("loadersStore/hideLoader", tag);
        await router.push({name: enableTwoFactorName});
    }

    async forgotPassword(tag, email) {
        const api = customApi(tag);
        const {message} = await api.request(api.factories.auth.forgotPassword(email));

        if (message == null) {
            return;
        }

        message && await store.dispatch("messagesStore/showSuccessMessage", {
            tag,
            message,
        });
        await store.dispatch("loadersStore/hideLoader", tag);
    }

    async resetPassword(tag, payload) {
        const api = customApi(tag);
        const response = await api.request(api.factories.auth.resetPassword(payload));

        if (response == null) {
            return;
        }

        response.message && await store.dispatch("messagesStore/showSuccessMessage", {
            tag: GLOBAL_TOAST_TAG,
            message: response.message,
        });
        await store.dispatch("loadersStore/hideLoader", tag);
        await router.push({name: loginName});
    }

    hasPermissionTo(hasPermissions) {
        const permissions = store.getters["authStore/permissions"];

        if (permissions.indexOf('*') > -1) {
            return true;
        }

        if (hasPermissions.indexOf('*') > -1 || hasPermissions.length === 0) {
            return true;
        }

        return hasPermissions.filter((permission) => {
            return permissions.indexOf(permission) === -1;
        }).length === 0;
    }
}

export default new AuthService();