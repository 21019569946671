<template>
    <div class="dtv-question" :class="{expand: isAnswerVisible}">
        <div class="dtv-question__header">
            <div class="dtv-question__date">{{ date }} <b>({{ questionTo }})</b></div>
            <div v-if="answer" class="dtv-question__button" @click="toggle">
                <template v-if="isAnswerVisible">
                    Slėpti atsakymą
                </template>
                <template v-else>
                    Rodyti atsakymą
                </template>
                <ui-icon>{{ isAnswerVisible ? "up" : "down" }}</ui-icon>
            </div>
            <div v-else class="dtv-question__wait">{{waitText}}</div>
        </div>
        <p class="mt-2" v-html="content"/>
        <!--    <slot name="question" /></p>-->
        <template v-if="isAnswerVisible">
            <hr>
            <p v-html="answer.content"/>
            <employee-table-view
                    :employee="{avatarSrc: answer.author.avatar, name: answer.author.name, duties: [{name: answer.author.position}]}"
            />
        </template>
    </div>
</template>

<script>

import EmployeeTableView from "@/components/ui/views/EmployeeTableView.vue";
import UiIcon from "@/domain/components/UiIcon.vue";

export default {
    name: "DtvQuestion",
    components: {UiIcon, EmployeeTableView},
    props: {
        date: {type: String, default: '2023-02-13'},
        defaultVisibleAnswer: {type: Boolean, default: false},
        content: {type: String, default: ''},
        answer: {type: Object, default: null},
        type: {type: String, default: 'dk'}
    },
    data() {
        return {
            isAnswerVisible: this.defaultVisibleAnswer,
            employee: {
                avatarSrc: null,
                name: "Jurgita Judickienė,",
                duties: [{name: "Advokatų kontora TRINITI JUREX"}],
            },
        };
    },
    computed: {
        waitText() {
            return {
                dk: 'Laukiama atsakymo iš teisininko',
                hr: 'Laukiama atsakymo iš personalisto'
            }[this.type];
        },
        questionTo() {
            return {
                dk: 'Klausimas teisininkui',
                hr: 'Klausimas personalistui'
            }[this.type];
        }
    },
    methods: {
        showAnswer() {
            this.isAnswerVisible = true;
        },
        hideAnswer() {
            this.isAnswerVisible = false;
        },
        toggle() {
            this.isAnswerVisible = !this.isAnswerVisible;
        },
    },
}
</script>