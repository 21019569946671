<template>
    <page
        :loader-listen='initTag'
        :message-listen='initTag'
        with-background
    >
        <div style='position: relative; z-index: 2'>
            <div class='d-flex flex-column pt-6 gap-10'>
                <div v-if='!isBondMobile && isLeader && isEnabledBusinessTripCreateLeader' class='d-flex' :class='{"px-8": !isBondMobile}'>
                    <ui-button
                        background='primary'
                        :modal="{name: 'BusinessTripsModal', props: {asLeader: true}}"
                    >
                        {{ $t('Komandiruotės įsakymas') }}
                    </ui-button>
                </div>
                <div v-if='isVisibleEventsTab' :class='{"px-8": !isBondMobile}'>
                    <leader-event-invites />
                </div>

                <custom-tabs v-if='!isBondMobile' :key='1' :default-tab-name='defaultTabName' :tabs='tabs' @changeTab='handleChangeTab'>
                    <template #calendar>
                        <department-vacation-calendar />
                    </template>

                    <template #events>
                        <department-events-tab v-if='isVisibleEventsTab' />
                    </template>
                </custom-tabs>
            </div>
        </div>
    </page>
</template>

<script>
import Page from '@/domain/components/Page'
import departmentVacationsService from '@/domain/departmentVacations/services/departmentVacationsService'
import DepartmentVacationCalendar from '@/domain/departmentVacations/components/DepartmentVacationCalendar'
import SelectField from '@/domain/fields/SelectField.vue'
import isBondMobileMixin from '@/mixins/isBondMobileMixin'
import CustomTabs from '@/domain/components/CustomTabs.vue'
import DepartmentEventsTab from '@/domain/departmentVacations/components/DepartmentEventsTab.vue'
import checkIsAdminMixin from '@/mixins/checkIsAdminMixin'
import UiButton from '@/domain/components/UiButton.vue'
import checkIsLeaderMixin from '@/mixins/checkIsLeaderMixin'
import LeaderEventInvites from '@/domain/eventInvite/LeaderEventInvites.vue'

export default {
    name: 'DepartmentVacationsWebViewPage',
    components: {
        LeaderEventInvites,
        UiButton,
        DepartmentEventsTab,
        CustomTabs,
        SelectField,
        DepartmentVacationCalendar,
        Page
    },
    mixins: [checkIsAdminMixin, checkIsLeaderMixin, isBondMobileMixin],
    beforeCreate() {
        this.$store.dispatch('departmentVacationsStore/fetch', { tag: this.initTag })
    },
    data() {
        const initTag = 'DEPARTMENT_VACATIONS_PAGE_INIT'

        return {
            initService: () => departmentVacationsService.init(initTag),
            defaultTabName: 'calendar',
            initTag
        }
    },
    computed: {
        isVisibleEventsTab() {
            return this.isAdmin || this.isLeader || this.isSubLeader
        },
        isEnabledBusinessTripCreateLeader() {
            return this.$store.getters['currentUserStore/settings']?.business_trip
                ?.settingBusinessTripLeaderCanCreate?.config?.enabled ?? false
        },
        tabs() {
            const tabs = [
                { name: 'calendar', text: this.$t('Kalendorius') }
            ]

            if (this.isVisibleEventsTab) {
                tabs.push({ name: 'events', text: this.$t('Įvykiai') })
            }

            return tabs
        }
    },
    methods: {
        handleChangeTab(tab) {
            if (tab === 'calendar') {
                this.$store.dispatch('departmentVacationsStore/fetch', { tag: this.initTag })
            }
        }
    },
    provide() {
        return {
            service: departmentVacationsService
        }
    },
    beforeDestroy() {
        this.$store.dispatch('departmentVacationsStore/clearState')
    }
}
</script>