<template>
    <div class='pt-2' :class='{"px-8": !isBondMobile}'>
        <v-progress-linear
            absolute
            top
            :active='loading'
            :indeterminate='loading'
            color='primary'
        />
        <div>
            <component
                v-for='(invite, index) in invitesPaginate'
                :key='index'
                :is='invite.componentName'
                :invite='invite'
                with-status
                @changed='handleRowDeleted'
            />
        </div>
        <hra-pagination
            class='mt-2'
            :last-page='lastPage'
            :current-page='currentPage'
            @changePage='handleChangePage'
        />
    </div>
</template>

<script>
import CustomApi from '@/plugins/customApi'
import HraPagination from '@/components/ui/pagination/HraPagination.vue'
import EmployeeHolidayEventInvite from '@/domain/eventInvite/components/employee/EmployeeHolidayEventInvite.vue'
import EmployeeHolidayDeputyEventInvite
    from '@/domain/eventInvite/components/employee/EmployeeHolidayDeputyEventInvite.vue'
import EmployeeBusinessTripLeaderEventInvite
    from '@/domain/eventInvite/components/employee/EmployeeBusinessTripLeaderEventInvite.vue'
import EmployeeBusinessTripEmployeeEventInvite
    from '@/domain/eventInvite/components/employee/EmployeeBusinessTripEmployeeEventInvite.vue'
import EmployeeRemoteWorkEventInvite from '@/domain/eventInvite/components/employee/EmployeeRemoteWorkEventInvite.vue'
import isBondMobileMixin from '@/mixins/isBondMobileMixin'

const customApi = CustomApi()

const inviteComponentNames = {
    holiday: 'EmployeeHolidayEventInvite',
    holidayDeputy: 'EmployeeHolidayDeputyEventInvite',
    businessTripLeader: 'EmployeeBusinessTripLeaderEventInvite',
    businessTripEmployee: 'EmployeeBusinessTripEmployeeEventInvite',
    remoteWork: 'EmployeeRemoteWorkEventInvite'
}

function getComponentName(invite) {
    if (invite.type === 'remote-work') {
        return inviteComponentNames.remoteWork
    }

    if (invite.type === 'event') {
        return {
            1001: inviteComponentNames.holiday,
            1002: inviteComponentNames.holiday,
            1003: inviteComponentNames.holiday,
            1004: inviteComponentNames.holiday,
            1005: inviteComponentNames.holiday,
            1006: inviteComponentNames.holiday,
            1007: inviteComponentNames.holiday,
            1008: inviteComponentNames.holiday,
            1009: inviteComponentNames.holiday,
            1010: inviteComponentNames.holiday,
            1011: inviteComponentNames.holiday,
            1012: inviteComponentNames.holiday,
            1013: inviteComponentNames.holidayDeputy,
            5001: inviteComponentNames.businessTripLeader,
            5002: inviteComponentNames.businessTripEmployee
        }[invite.approvalable.type] ?? null
    }

    return null
}

export default {
    name: 'EmployeeEventInvitesHistory',
    components: {
        HraPagination,
        EmployeeHolidayEventInvite,
        EmployeeHolidayDeputyEventInvite,
        EmployeeBusinessTripLeaderEventInvite,
        EmployeeBusinessTripEmployeeEventInvite,
        EmployeeRemoteWorkEventInvite
    },
    mixins: [isBondMobileMixin],
    data() {
        return {
            loading: false,
            page: 1,
            limit: 10,
            invites: []
        }
    },
    computed: {
        lastPage() {
            return Math.ceil(this.invites.length / this.limit)
        },
        currentPage() {
            return this.page > this.lastPage ? this.lastPage : this.page
        },
        total() {
            return this.data.length
        },
        invitesPaginate() {
            const start = (this.currentPage - 1) * this.limit
            const end = start + this.limit

            return this.invites.slice(start, end)
        }
    },
    mounted() {
        this.fetchInvites()
    },
    methods: {
        handleChangePage(page) {
            this.page = page
        },
        handleRowDeleted() {
            this.$store.dispatch('employeeVacationsStore/refreshData')
            this.$emit('changed')
        },
        async fetchInvites() {
            try {
                this.loading = true
                this.page = 1
                const { data } = await customApi.factories.approvals.employees
                    .fetch(this.$store.getters['currentUserStore/userId'], {
                        status: ['pending', 'approved'], sortBy: ['start,desc']
                    })
                this.invites = data.map(invite => {
                    const componentName = getComponentName(invite)
                    return { ...invite, componentName }
                }).filter(({ componentName }) => componentName !== null)
            } catch (e) {
                console.error(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>