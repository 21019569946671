<template>
    <div class='approval-stage-employee' :data-status='inviteStatus'>
<!--        <div class='approval-stage-employee__progress' />-->
<!--        <div class='progress__line'></div>-->
<!--        <div class='approval-stage-employee__box-circle'>-->
<!--            <ui-icon>{{ statusIcon }}</ui-icon>-->
<!--        </div>-->
        <div v-if='!ignoreStatus' class='approval-stage-employee__circle'>
            <ui-icon>{{ statusIcon }}</ui-icon>
        </div>
        <employee-table-view
            :employee='user'
        />
    </div>
</template>

<script>
import EmployeeTableView from '@/components/ui/views/EmployeeTableView.vue'
import UiIcon from '@/domain/components/UiIcon.vue'

export default {
    name: 'ApprovalStateEmployee',
    components: { UiIcon, EmployeeTableView },
    props: {
        user: { type: Object, required: true },
        status: { type: String, default: null },
        ignoreStatus: { type: Boolean, default: false }
    },
    data() {
        return {
            statusIcons: {
                approved: 'ok',
                aborted: 'close',
                skip: 'restore'
            },
            statusClasses: {
                approved: 'approved',
                pending: 'pending',
                aborted: 'aborted',
                skip: 'skip'
            }
        }
    },
    computed: {
        inviteStatus() {
            if (this.ignoreStatus) {
                return ''
            }

            return this.status ?? ''
        },
        statusIcon() {
            return this.statusIcons[this.status] || ''
        }
    }
}
</script>

<style lang='scss' scoped>
@import '@/assets/sass/core/colors';

.approval-stage-employee {
    --circle-bacground-color: #{$white};
    --circle-text-color: #{$white};
    --circle-border-color: #{$border-color-primary};

    &[data-status='pending'] {
        --circle-border-color: #{$violet-700};
        --circle-text-color: #{$violet-700};
    }

    &[data-status='skip'] {
        --circle-border-color: #{$violet-700};
        --circle-text-color: #{$violet-700};
    }

    &[data-status='approved'] {
        --circle-bacground-color: #{$violet-700};
        --circle-border-color: #{$violet-700};
    }

    &[data-status='aborted'] {
        --circle-bacground-color: #E7384F;
        --circle-border-color: #E7384F;
    }

    background-color: #FAF9FF;
    border: 1px solid $border-color-primary;
    border-radius: 5px;
    position: relative;
    padding: 7px 18px;
    display: flex;
    width: fit-content;

    &__circle {
        position: absolute;
        top: calc(50% - 11px);
        left: -11px;
        z-index: 2;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background: var(--circle-bacground-color);
        color: var(--circle-text-color);
        font-size: 11px;
        border: 1px solid var(--circle-border-color);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &[data-status='skip']:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        z-index: 1;
        background: rgba(255, 255, 255, 0.5);
    }
}
</style>