<template>
    <div class='business-trips-form'>
        <v-row>
            <v-col cols='12' v-if='asLeader || asAdmin'>
                <user-select-field
                    :disabled='disabledUserSelect || disabledAllFields'
                    :label="$t('Darbuotojas')"
                    name='user'
                    :value='formData.user'
                    rules='required'
                    :only-for-user='asLeader'
                    @change='value => onChangeField(value, "user")'
                />
            </v-col>
            <v-col v-if='!asAdmin && asLeader && !disabledAllFields' cols='12'>
                <form-checkbox
                    name='approvalToEmployee'
                    :is-checked='formData.approvalToEmployee'
                    @change='onChangeField($event, "approvalToEmployee")'
                >
                    <span class='ml-2'>{{ $t('Darbuotojas turi patvirtinti') }}</span>
                </form-checkbox>
            </v-col>
            <v-col cols='6'>
                <datepicker-field
                    :disabled='disabledAllFields'
                    :label="$t('Data nuo')"
                    rules='required'
                    name='start'
                    :min-date='minDate'
                    :value='formData.start'
                    @update:value='handleChangeFormStartDate'
                />
            </v-col>
            <v-col cols='6'>
                <datepicker-field
                    :disabled='disabledAllFields'
                    :label="$t('Data iki')"
                    rules='required'
                    name='toDate'
                    :min-date='formData.start'
                    :value='formData.end'
                    @update:value='handleChangeFormEndDate'
                />
            </v-col>
            <v-col cols='12'>
                <country-select-field
                    :disabled='disabledAllFields'
                    :label="$t('Šalis')"
                    rules='required'
                    name='countries'
                    multiple
                    filtered
                    :value='formData.countries'
                    @input='onChangeField($event, "countries")'
                />
            </v-col>
            <v-col cols='12'>
                <select-field
                    :disabled='disabledAllFields'
                    :label="$t('Kelionės būdas')"
                    name='travelMethods'
                    rules='required'
                    multiple
                    filtered
                    :options='businessTripsTravelMethods'
                    :value='formData.travelMethods'
                    @input='onChangeField($event, "travelMethods")'
                >
                </select-field>
            </v-col>
            <v-col cols='12'>
                <select-field
                    :disabled='disabledAllFields'
                    :label="$t('Avansas')"
                    name='payMethod'
                    :options='businessTripsPayOptions'
                    :value='formData.payMethod'
                    @input='onChangeField($event, "payMethod")'
                >
                </select-field>
            </v-col>
            <v-col cols='12'>
                <textarea-field
                    :disabled='disabledAllFields'
                    :label="$t('Komandiruotės tikslas')"
                    name='message'
                    :value='formData.message'
                    @input='onChangeField($event, "message")'
                />
            </v-col>
            <v-col v-if='asAdmin && !disabledAllFields' cols='12'>
                <form-checkbox
                    name='withNotifications'
                    :is-checked='formData.withNotifications'
                    @change='onChangeField($event, "withNotifications")'
                >
                    <span class='ml-2'>{{ $t('Siųsti pranešimus') }}</span>
                </form-checkbox>
            </v-col>
        </v-row>
        <message-alerts class='px-3' :listen='["RemoteWorkModalTag"]' />
    </div>
</template>

<script>
import SelectField from '@/domain/fields/SelectField'
import TextareaField from '@/domain/fields/TextareaField.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import DatepickerField from '@/domain/fields/DatepickerField.vue'
import MessageAlerts from '@/domain/messages/MessageAlerts.vue'

import employeeAutocompleteService from '@/services/employees/employeeAutocompleteService'
import EmployeeTableView from '@/components/ui/views/EmployeeTableView'
import UserSelectField from '@/domain/fields/UserSelectField.vue'
import CountrySelectField from '@/domain/fields/CountrySelectField.vue'

export default {
    name: 'BusinessTripsForm',
    components: {
        CountrySelectField,
        UserSelectField,
        SelectField,
        MessageAlerts,
        DatepickerField,
        FormCheckbox,
        TextareaField,
        EmployeeTableView
    },
    inject: ['storeNamespace', 'asLeader', 'asAdmin'],
    props: {
        disabledUserSelect: { type: Boolean, default: false },
        disabledAllFields: { type: Boolean, default: false }
    },
    data() {
        return {
            businessTripsTravelMethods: [
                { value: 'car', text: this.$t('Automobiliu') },
                { value: 'fly', text: this.$t('Oro bendrovėmis') }
            ],
            businessTripsPayOptions: [
                { value: 'pay_with_salary', text: this.$t('Išmokėti kartu su pareiginiu atlyginimu') },
                { value: 'pay_before_business_trip', text: this.$t('Išmokėti prieš komandiruotę') }
            ],
            employeeAutocompleteService
        }
    },
    computed: {
        minDate() {
            if (this.asAdmin) {
                return null
            }

            return this.$store.getters[`${this.storeNamespace}/minDate`]
        },
        formData() {
            return this.$store.getters[`${this.storeNamespace}/formData`]
        }
    },
    methods: {
        async handleChangeFormStartDate(value) {
            await this.$store.dispatch(`${this.storeNamespace}/setFormStartDate`, value)
        },
        async handleChangeFormEndDate(value) {
            await this.$store.dispatch(`${this.storeNamespace}/setFormEndDate`, value)
        },
        async findDeputies(tag, value) {
            return await employeeAutocompleteService.find(tag, value)
        },
        onChangeField(value, field) {
            this.$store.dispatch(`${this.storeNamespace}/updateCurrentField`, { field, value })
        }
    }
}
</script>