import { VACATION_STATUS_ABORT_EMPLOYEE, VACATION_STATUS_APPROVE } from '@/utils/default'
import Vue from 'vue'

export const INIT_EVENTS = 'INIT_EVENTS'
export const INIT_WIDGETS = 'INIT_WIDGETS'
export const DEPUTY_APPROVE = 'DEPUTY_APPROVE'
export const DEPUTY_ABORT = 'DEPUTY_ABORT'

export const EVENT_CREATE = 'EVENT_CREATE'
export const EVENT_UPDATE = 'EVENT_UPDATE'
export const EVENT_CHANGE = 'EVENT_CHANGE'
export const EVENT_ABORT = 'EVENT_ABORT'


export const SET_EVENTS = 'SET_EVENTS'
export const SET_DEPUTIES = 'SET_DEPUTIES'
export const SET_WIDGETS = 'SET_WIDGETS'

export default ({
    [INIT_EVENTS]: (state, payload) => state.events = payload,
    [INIT_WIDGETS]: (state, payload) => state.widgets = payload,
    [DEPUTY_APPROVE]: ({ events: { deputies } }, payload) => {
        let deputy = deputies.find(item => item.id === payload)
        if (deputy) {
            deputy.status = VACATION_STATUS_APPROVE
            deputy.actions = { ...deputy.actions, canDeputyApprove: false }
        }
    },
    [DEPUTY_ABORT]: (state, payload) => state.events.deputies = state.events.deputies.filter(item => item.id !== payload),
    [EVENT_CREATE]: (state, payload) => state.events = [...state.events, payload],
    [EVENT_UPDATE]: (state, payload) => {
        let index = state.events.findIndex(event => event.id === payload.id)

        if (index > -1) {
            Vue.set(state.events, index, payload)
        }
    },
    [EVENT_ABORT]: ({ events: { vacations } }, payload) => {
        let vacation = vacations.find(item => item.id === payload)
        if (vacation) {
            vacation.status = VACATION_STATUS_ABORT_EMPLOYEE
            vacation.deputy = null
            vacation.actions = { canVacationInfo: true }
        }
    },


    [SET_EVENTS]: (state, payload) => {
        state.events = payload
    },
    [SET_WIDGETS]: (state, payload) => {
        state.widgets = payload
    }
})