<template>
    <tr>
        <td>{{ rowKey }}</td>
        <td style='min-width: 150px'>
            <user-select-field
                :value='row.user'
                rules='required'
                with-department
                @change='value => handleChange("user", value)'
            />
        </td>
        <td style='min-width: 150px'>{{ department }}</td>
        <td style='min-width: 250px'>
            <text-field
                :value='row.type'
                rules='required'
                @input='value => handleChange("type", value)'
            />
        </td>
        <td style='min-width: 250px'>
            <text-field
                :value='row.sickType'
                rules='required'
                @input='value => handleChange("sickType", value)'
            />
        </td>
        <td style='min-width: 150px'>
            <datepicker-field
                :value='row.start'
                rules='required'
                numbers-only
                @input='value => handleChange("start", value)'
            />
        </td>
        <td style='min-width: 150px'>
            <datepicker-field
                :min-date='row.start'
                :value='row.end'
                rules='required'
                numbers-only
                @input='value => handleChange("end", value)'
            />
        </td>
        <td style='min-width: 200px'>
            <text-field
                :value='row.personalCode'
                rules='min:11'
                @input='value => handleChange("personalCode", value)'
            />
        </td>
        <td style='min-width: 80px'>
            <text-field
                :value='row.personalSDSeries'
                @input='value => handleChange("personalSDSeries", value)'
            />
        </td>
        <td style='min-width: 100px'>
            <text-field
                :value='row.personalSDNumber'
                @input='value => handleChange("personalSDNumber", value)'
            />
        </td>
        <td style='min-width: 150px'>
            <text-field
                :value='row.noteSeries'
                @input='value => handleChange("noteSeries", value)'
            />
        </td>
        <td style='min-width: 150px'>
            <text-field
                :value='row.noteNumber'
                @input='value => handleChange("noteNumber", value)'
            />
        </td>
        <td style='min-width: 150px'>
            <select-field
                :value='row.filledInPolicyholderInfo'
                :options='booleanOptions'
                @input='value => handleChange("filledInPolicyholderInfo", value)'
            />
        </td>
        <td style='min-width: 150px'>
            <select-field
                :value='row.policyholderNeedsToPay'
                :options='booleanOptions'
                @input='value => handleChange("policyholderNeedsToPay", value)'
            />
        </td>
        <td style='min-width: 150px'>
            <select-field
                :value='row.noteDeleted'
                :options='booleanOptions'
                @input='value => handleChange("noteDeleted", value)'
            />
        </td>
        <td style='min-width: 150px'>
            <select-field
                :value='row.submittedNPSD'
                :options='booleanOptions'
                @input='value => handleChange("submittedNPSD", value)'
            />
        </td>
        <td class='text-end'>
            <ui-button
                size='medium'
                only-text
                background='danger'
                prepend-icon='bin'
                :confirm='deleteRowConfirm'
            />
        </td>
    </tr>
</template>

<script>
import TextField from '@/domain/fields/TextField.vue'
import DatepickerField from '@/domain/fields/DatepickerField.vue'
import SelectField from '@/domain/fields/SelectField.vue'
import UserSelectField from '@/domain/fields/UserSelectField.vue'
import UiButton from '@/domain/components/UiButton.vue'

export default {
    name: 'ImportSickLeavesTable',
    components: { UiButton, UserSelectField, SelectField, DatepickerField, TextField },
    inject: ['storeNamespace'],
    props: {
        rowKey: {type: Number, required: true},
        row: {type: Object, required: true}
    },
    computed: {
        department() {
            return this.row.user?.department?.name ?? '-'
        }
    },
    data() {
        return {
            booleanOptions: [
                { value: true, text: 'Taip' },
                { value: false, text: 'Ne' }
            ],
            deleteRowConfirm: {
                props: {
                    title: 'Ar tikrai norite ištrinti?',
                },
                on: {
                    confirmed: (confirm) => this.handleDeleteRow(confirm)
                }
            }
        }
    },
    methods: {
        handleChange(column, value) {
            this.$store.dispatch(`${this.storeNamespace}/updateRowColumn`, {
                rowId: this.row.id,
                column,
                value
            })
        },
        handleDeleteRow(confirm) {
            this.$store.dispatch(`${this.storeNamespace}/deleteRow`, this.row.id)
            confirm.close()
        }
    }
}
</script>