export default {
    methods: {
        documentsCreateGroup(folderId, payload) {
            this.$root.$emit('documents-finder-create-group', folderId, payload)
        },
        documentsUpdateGroup(folderId, payload) {
            this.$root.$emit('documents-finder-update-group', folderId, payload)
        },
        documentsDeleteGroup(folderId, payload) {
            this.$root.$emit('documents-finder-delete-group', folderId, payload)
        },
        documentsCreateFolder(folderId, payload) {
            this.$root.$emit('documents-finder-create-folder', folderId, payload)
        },
        documentsUpdateFolder(folderId, payload) {
            this.$root.$emit('documents-finder-update-folder', folderId, payload)
        },
        documentsDeleteFolder(folderId, payload) {
            this.$root.$emit('documents-finder-delete-folder', folderId, payload)
        },
        documentsCreateDocument(folderId, payload) {
            this.$root.$emit('documents-finder-create-document', folderId, payload)
        },
        documentsUpdateDocument(folderId, payload) {
            this.$root.$emit('documents-finder-update-document', folderId, payload)
        },
        documentsDeleteDocument(folderId, payload) {
            this.$root.$emit('documents-finder-delete-document', folderId, payload)
        }
    }
}