import customApi from '@/plugins/customApi'
import Vue from 'vue'

const state = {
    fetchingStatuses: false,
    fetchingTypes: false,
    documentStatuses: null,
    documentTypes: null,
    documentIcons: {}
}

const getters = {
    getDocumentStatuses: state => state.documentStatuses,
    isDocumentStatusesLoaded: state => !!state.documentStatuses && !state.fetchingTypes,
    getDocumentIcon: state => name => state.documentIcons[name],
    getDocumentTypes: ({documentTypes}) => documentTypes
}

const actions = {
    loadDocumentStatuses({ commit, state }) {
        if (state.fetchingStatuses || state.documentStatuses != null) {
            return
        }

        commit('setFetchingStatuses')
        const api = customApi('DOCUMENTS_FETCH_STATUSES')
        return api.request(api.factories.documents.statuses.fetch()).then((response) => {
            commit('loadDocumentStatuses', response)
        }).catch((error) => {
            commit('loadDocumentStatusesError')
        })
    },
    loadDocumentTypes({commit, state}) {
        if (state.fetchingTypes || state.documentTypes != null) {
            return
        }

        commit('setFetchingTypes')
        const api = customApi('DOCUMENTS_FETCH_TYPES')
        return api.request(api.factories.documents.documents.types.fetch()).then((data) => {
            commit('loadDocumentTypes', data)
        }).catch((error) => {
            commit('loadDocumentTypesError')
        })
    },
    async loadDocumentIcon({ commit }, name) {
        commit('loadDocumentIcon', { name, icon: `<span>${name}</span>` })

        const response = await fetch(`/assets/icons/documents/${name}.svg`)

        if (response.status === 200) {
            const icon = await response.text()
            commit('loadDocumentIcon', { name, icon })
        } else {
            console.warn('Failed to fetch icon ' + name)
        }
    }
}

const mutations = {
    setFetchingStatuses(state) {
        state.fetchingStatuses = true
    },
    loadDocumentStatuses(state, response) {
        state.fetchingStatuses = false
        state.documentStatuses = response
    },
    loadDocumentStatusesError: state => {
        state.fetchingStatuses = false
        state.documentStatuses = null
    },
    setFetchingTypes(state) {
        state.fetchingTypes = true
    },
    loadDocumentTypes(state, payload) {
        state.fetchingTypes = false
        state.documentTypes = payload
    },
    loadDocumentTypesError: state => {
        state.fetchingTypes = false
        state.documentTypes = null
    },
    loadDocumentIcon(state, { name, icon }) {
        Vue.set(state.documentIcons, name, icon)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}