<template>
    <v-layout align-center justify-center flex-column>
        <v-card elevation="0" rounded="lg" class="auth__card d-flex flex-column gap-6">
            <h1>
                <slot name="header"/>
            </h1>
            <h2 v-if="loadingView">loading...</h2>
            <slot name="form"/>
            <slot name="footer"/>
        </v-card>

        <div class="auth__footer">
            <slot name="after-card"/>
        </div>
        <v-overlay :opacity="1" :value="fullPageLoader">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-layout>
</template>

<script>
export default {
    props: {
        fullPageLoader: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loaderName: "Auth",
        };
    },
};
</script>
