<template>
    <div>
        <h3>{{ title }}</h3>
        <slot />
        <ui-button
            type='button'
            background='none'
            @click='handleCreateNewLevel'
            prepend-icon='plus'
            style='min-height: auto;'
            class='approval-level__add-level pa-0 mt-5'
        >{{ $t('Add level {count} approval', { count: levelCount + 1 }) }}
        </ui-button>
    </div>
</template>

<script>
import i18n from '@/plugins/i18n'
import ApprovalLevel from '@/domain/components/ApprovalLevel/components/ApprovalLevel.vue'
import UiButton from '@/domain/components/UiButton.vue'

export default {
    name: 'ApprovalLevels',
    components: { UiButton, ApprovalLevel },
    props: {
        title: { type: String, default: () => i18n.t('Approval map') },
        levelCount: { type: Number, default: 0 }
    },
    methods: {
        handleCreateNewLevel() {
            this.$emit('create-new-level')
        }
    }
}
</script>

<style lang='scss'>
@import '@/assets/sass/core/colors';

.approval-level__add-level {
    span {
        font-size: 16px !important;
        color: $greyscale-800;
    }

    i {
        font-size: 10px !important;
        color: $white;
        background-color: $violet-200;
        padding: 4px;
        border-radius: 50%;
    }

    &:hover {
        i {
            background-color: $violet-600;
            opacity: 0.9;
        }
    }

}
</style>