<template>
  <app-layout>
    <div class="dtv-background" />
    <div class="dtv-route-view max-page-width">
      <router-view />
    </div>
  </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout.vue";

export default {
  name: "DtvLayout",
  components: {AppLayout},
}
</script>