<template>
    <ui-table style='overflow: unset'>
        <template #head>
            <tr style='position: sticky; top: 0; z-index: 3;'>
                <th>#</th>
                <th>{{ $t('Darbuotojas') }}</th>
                <th>{{ $t('Skyrius') }}</th>
                <th>{{ $t('Pažymėjimo tipas') }}</th>
                <th>{{ $t('Nedarbingumo priežastis') }}</th>
                <th>{{ $t('Data nuo') }}</th>
                <th>{{ $t('Data iki') }}</th>
                <th>{{ $t('Asmens kodas') }}</th>
                <th>{{ $t('SD serija') }}</th>
                <th>{{ $t('SD numeris') }}</th>
                <th>{{ $t('Pažymėjimo serija') }}</th>
                <th>{{ $t('Pažymėjimo numeris') }}</th>
                <th>{{ $t('Ar užpildyta draudėjui info') }}</th>
                <th>{{ $t('Ar reikia draudejui mokėti') }}</th>
                <th>{{ $t('Ar pažymėjimas panaikintas') }}</th>
                <th>{{ $t('Pateikė NPSD') }}</th>
                <th>{{ $t('Veiksmai') }}</th>
            </tr>
        </template>

        <template #body>
            <template v-for='(row, key) in rows'>
                <import-sick-leaves-table-row :key='`row-${row.id}`' :row-key='key + 1' :row='row' />
                <tr v-if='row.errors.length > 0'>
                    <td colspan='17' style='overflow: inherit'>
                        <div class='d-flex flex-column gap-2' style='width: max-content;position: sticky;left: 10px;'>
                            <message-alert v-for='({message}) in row.errors' id='---' :message='message' type='error' />
                        </div>
                    </td>
                </tr>
            </template>
        </template>
    </ui-table>
</template>

<script>
import UiTable from '@/domain/components/uiTable/UiTable.vue'
import ImportSickLeavesTableRow from '@/domain/sickLeaves/components/import/ImportSickLeavesTableRow.vue'
import UiButton from '@/domain/components/UiButton.vue'
import MessageAlert from '@/domain/messages/components/MessageAlert.vue'

export default {
    name: 'ImportSickLeavesTable',
    components: { MessageAlert, UiButton, UiTable, ImportSickLeavesTableRow },
    inject: ['storeNamespace'],
    computed: {
        rows() {
            return this.$store.getters[`${this.storeNamespace}/rows`]
        }
    }
}
</script>