export const ADD_MESSAGE = "ADD_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const CLOSE_MESSAGE = "CLOSE_MESSAGE";
export const CLEAR_MESSAGES = "CLEAR_MESSAGES";
export const ADD_TOAST_TAG = "ADD_TOAST_TAG";
export const REMOVE_TOAST_TAG = "REMOVE_TOAST_TAG";

export default ({
  [ADD_MESSAGE]: (state, {tag: findTag, messages}) => {
    // if (state.stack.find(({tag}) => tag === findTag)) {
    //   return;
    // }
    state.stack = [...state.stack, ...messages,];
  },
  [CLEAR_MESSAGE]: (state, payload) => {
    state.stack = state.stack.map(message => ({
      ...message,
      shown: message.tag !== payload,
    }));
  },
  [CLEAR_MESSAGES]: (state, payload) => {
    state.stack = state.stack.map(message => ({
      ...message,
      shown: !payload.includes(message.tag),
    }));
  },
  [CLOSE_MESSAGE]: (state, payload) => state.stack = state.stack.filter(({id}) => id !== payload),
  [ADD_TOAST_TAG]: (state, payload) => {
    if (!state.toastTags.includes(payload)) {
      state.toastTags.push(payload);
    }
  },
  [REMOVE_TOAST_TAG]: (state, payload) => {
    state.toastTags = state.toastTags.filter(tag => tag !== payload);
  },
});