<template>
    <div v-if='visibleLoadMore' class='documents-finder-layout__content-more-button'>
        <v-progress-circular v-if='loadingMore' color='primary' indeterminate></v-progress-circular>
        <div v-else class='d-flex flex-column align-center' style='cursor: pointer' @click='handleLoadMore'>
            <span>{{ $t('Rodyti daugiau') }}</span>
            <ui-icon class='mt-1' style='color: #3D45A9;'>arrow-down</ui-icon>
        </div>
    </div>
</template>

<script>
import UiIcon from '@/domain/components/UiIcon.vue'

export default {
    name: 'LoadMoreButton',
    components: { UiIcon },
    inject: ['storeNamespace'],
    computed: {
        loadingMore() {
            return this.$store.getters[`${this.storeNamespace}/loadingMore`]
        },
        visibleLoadMore() {
            return this.$store.getters[`${this.storeNamespace}/visibleLoadMore`]
        }
    },
    methods: {
        handleLoadMore() {
            this.$store.dispatch(`${this.storeNamespace}/loadMore`)
        }
    }
}
</script>