<template>
    <th class='ui-table-head-filter' :style='thStyles'>
        <v-menu
            ref='Menu'
            offset-y
            :close-on-content-click='false'
            v-model='menu'
            nudge-bottom='10'
            @input='handleCloseMenu'
        >
            <template #activator='{on}'>
                <span class='ui-table-head-filter__column' :class='{active: isActive}' v-on='on'>
                    <span>{{ title }} <span v-if='!!selectedCount'>({{ selectedCount }})</span></span>
                    <ui-icon>{{ titleIconName }}</ui-icon>
                </span>
            </template>

            <div class='ui-table-head-filter__container'>
                <slot name='prepend.filter' />
                <text-field
                    v-if='selectOptionSearchable'
                    class='ma-2'
                    can-clear
                    :label='$t("Paieška")'
                    :value='searchOption'
                    @input='handleSearchOption'
                />
                <div class='ui-table-head-filter-select'>
                    <form-checkbox
                        v-for='option in filteredSelectOptions'
                        :key='`${column}-option-${option.value}`'
                        v-show='option.visible'
                        class='ui-table-head-filter-select__item'
                        :is-checked='value.selected.includes(option.value)'
                        @change='handleToggleSelect(option.value)'

                    >
                        <div v-html='option.matchedLabel'/>
                    </form-checkbox>
                </div>
                <div v-if='sortOptions != null' class='ui-table-head-filter-title'>
                    <ui-icon>sorting-az</ui-icon>
                    <span>{{ $t('Rušiuoti pagal') }}</span>
                </div>
                <div class='ui-table-head-filter-sort'>
                    <form-radio
                        v-for='option in sortOptions'
                        :key='`${column}-sort-${option.value}`'
                        class='ui-table-head-filter-sort__item'
                        :radio-value='option.value'
                        :name='`${column}-sort`'
                        :value='value.sort'
                        @input='handleChangeSort'
                    >
                        {{ option.label }}
                    </form-radio>
                </div>
                <slot name='append.filter' />

                <div class='ui-table-head-filter-footer'>
                    <ui-button
                        size='small'
                        background='default'
                        @click='handleClear'
                    >{{ $t('Išvalyti') }}
                    </ui-button>
                    <ui-button
                        size='small'
                        background='primary'
                        @click='handleChange'
                    >{{ $t('Pritaikyti') }}
                    </ui-button>
                </div>
            </div>
        </v-menu>
    </th>
</template>

<script>
import UiIcon from '@/domain/components/UiIcon.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import FormRadio from '@/components/ui/form/FormRadio.vue'
import UiButton from '@/domain/components/UiButton.vue'
import TextField from '@/domain/fields/TextField.vue'
import { replaceString } from '@/utils/replaceString'

export default {
    name: 'UiTableHeadFilter',
    components: { TextField, UiButton, FormRadio, FormCheckbox, UiIcon },
    props: {
        title: { type: String, require: true },
        column: { type: String, require: true },
        sortOptions: { type: Array, default: () => null },
        selectOptions: { type: Array, default: () => null },
        titleSortingIconName: { type: String, default: null },
        active: { type: Boolean, default: false },
        selectOptionSearchable: { type: Boolean, default: false },
        value: { type: Object, required: true },
        minWidth: {type: [String, Number], default: null}
    },
    data() {
        return {
            menu: false,
            searchOption: null,
            changedFilter: false,
            filterTimeout: null,
            filteredSelectOptions: null
        }
    },
    computed: {
        titleIconName() {
            if (this.titleSortingIconName != null) {
                return this.titleSortingIconName
            }

            if (this.selectOptions != null) {
                return 'sorting-funnel'
            }

            return 'sorting-az'
        },
        isActive() {
            return this.menu || this.value.sort != null || !!(this.value.selected?.length ?? null) || this.active
        },
        selectedCount() {
            if (this.value.selected == null) {
                return null
            }

            return this.value.selected.length
        },
        thStyles() {
            if (this.minWidth == null) {
                return {}
            }

            return {
                minWidth: typeof this.minWidth === 'string' ? this.minWidth : `${this.minWidth}px`
            }
        }
    },
    watch: {
        selectOptions: {
            handler(value) {
                this.filteredSelectOptions = value?.map(option => {
                    return {
                        ...option,
                        match: replaceString(option.label),
                        matchedLabel: option.label,
                        visible: true
                    }
                }) ?? null
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        handleCloseMenu(isOpened) {
            if (isOpened) {
                this.handleSearchOption(null)
                this.changedFilter = false
                return
            }

            this.emitFilterChanged()
        },
        handleToggleSelect(value) {
            this.changedFilter = true
            if (!this.value.selected.includes(value)) {
                this.$set(this.value, 'selected', [...this.value.selected, value])
                return
            }

            this.$set(this.value, 'selected', this.value.selected.filter(item => item !== value))
        },
        handleChangeSort(value) {
            this.changedFilter = true
            this.$set(this.value, 'sort', value)
        },
        filterSelectOptions(search = null) {
            if (this.filteredSelectOptions == null) {
                return
            }

            if (search == null) {
                this.filteredSelectOptions = this.filteredSelectOptions.map(option => ({
                    ...option,
                    visible: true,
                    matchedLabel: option.label
                }))
                return
            }

            search = replaceString(search)
            this.filteredSelectOptions = this.filteredSelectOptions.map(option => {
                const index = option.match.indexOf(search)

                if (index === -1) {
                    return {
                        ...option,
                        visible: false,
                        matchedLabel: option.label
                    }
                }

                const matchedLabel = option.label.slice(0, index)
                    + '<b>'
                    + option.label.slice(index, index + search.length)
                    + '</b>'
                    + option.label.slice(index + search.length)

                return {
                    ...option,
                    visible: true,
                    matchedLabel
                }
            }) ?? null
        },
        handleSearchOption(value) {
            this.searchOption = value

            clearTimeout(this.filterTimeout)
            this.filterTimeout = setTimeout(() => {
                this.filterSelectOptions(value)
            }, 150)
        },
        clearFilter() {
            this.changedFilter = true
            this.$set(this.value, 'selected', [])
            this.$set(this.value, 'sort', null)
            this.$emit('clearFilter', this.column)
        },
        handleClear() {
            this.clearFilter()
            this.emitFilterChanged()
            this.menu = false
        },
        handleChange() {
            this.emitFilterChanged()
            this.menu = false
        },
        emitFilterChanged() {
            if (!this.changedFilter) {
                return
            }

            this.$emit('changeFilter', this.column, this.value)
        }
    }
}
</script>

<style lang='scss'>

$border-bottom-color: #DAD5E9;
$hover-background: #FAF9FF;

.ui-table-head-filter {
    &__container {
        min-width: 200px;
    }

    &__column {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        flex-direction: row;
        position: relative;
        gap: 5px;
        cursor: pointer;

        span, .ui-icon {
            z-index: 2;
        }

        &.active {
            &:before {
                content: '';
                position: absolute;
                left: -2px;
                right: -2px;
                top: -2px;
                bottom: -2px;
                border-radius: 2px;
                background: rgba(0, 0, 0, 0.2);
            }
        }
    }
}

.ui-table-head-filter-select {
    max-height: 250px;
    overflow: auto;

    &__item {
        padding: 7px 14px;
        border-bottom: 1px solid $border-bottom-color;

        &:last-child {
            border-bottom: 0;
        }

        &:hover {
            background: $hover-background;
        }

        .form-checkbox {
            &__label {
                padding-left: 13px;
            }
        }
    }
}

.ui-table-head-filter-title {
    border: 1px solid #DAD5E9;
    background: #7177C1;
    padding: 8px 14px;
    display: flex;
    gap: 10px;
    align-items: center;

    .ui-icon {
        color: #FFFFFF;
    }

    span {
        color: #FFFFFF;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 166.667% */
        text-transform: uppercase;
    }
}

.ui-table-head-filter-sort {
    &__item {
        display: block;
        border-bottom: 1px solid $border-bottom-color;

        &:last-child {
            border-bottom: 0;
        }

        &:hover {
            background: $hover-background;
        }

        .form-radio {
            &__container {
                padding: 7px 14px;
            }

            &__label {
                padding-left: 13px;
            }
        }
    }
}

.ui-table-head-filter-footer {
    border-top: 1px solid $border-bottom-color;
    display: flex;
    flex-direction: row;
    padding: 7px 13px;
    gap: 8px;
    justify-content: flex-end;
    align-items: center;
}
</style>