<template>
    <page with-background>
        <v-progress-linear
            absolute
            :active='loading'
            :indeterminate='loading'
            color='primary'
        />
        <div class='settings-home pa-10'>
            <div class='settings-home-sidebar'>
                <h1>{{ $t('Nustatymai') }}</h1>
                <v-divider class='mt-1' />
                <div
                    v-for='({module, title, active}) in companyModules'
                    :key='module'
                    class='settings-home-sidebar__item'
                    :class='{active}'
                    @click='() => handleChangeModule(module)'
                >{{ title }}
                </div>
            </div>
            <div class='settings-home-container'>
                <!--                <text-field-->
                <!--                    can-clear-->
                <!--                    :placeholder='$t("Paiėška...")'-->
                <!--                    :label='$t("Paiėška")'-->
                <!--                    class='full-width settings-home-search'-->
                <!--                />-->

                <div class='settings-modules'>
                    <setting-module
                        v-for='module in viewModules'
                        :key='module'
                        :title='$t(`setting.modules.${module}`)'
                    >
                        <component
                            v-for='(setting, key) in settings[module]'
                            :key='key'
                            :is='setting.component'
                            :setting='setting'
                            @changed='handleChangeSetting'
                        />
                    </setting-module>
                </div>
            </div>
        </div>
    </page>
</template>

<script>
import Page from '@/domain/components/Page.vue'
import TextField from '@/domain/fields/TextField.vue'
import UiIcon from '@/domain/components/UiIcon.vue'
import UiButton from '@/domain/components/UiButton.vue'
import SettingModule from '@/domain/settings/components/SettingModule.vue'
import SettingModuleItem from '@/domain/settings/components/SettingModuleItem.vue'
import * as holidaySettingComponents from '@/domain/settings/components/holiday'
import * as sickLeaveSettingComponents from '@/domain/settings/components/sickLeave'
import * as businessTripSettingComponents from '@/domain/settings/components/businessTrip'
import * as remoteWorkSettingComponents from '@/domain/settings/components/remoteWork'
import * as documentsSettingComponents from '@/domain/settings/components/documents'
import MultilineSelectField from '@/domain/components/MultilineSelectField.vue'
import EmployeeMultiSelect from '@/domain/fields/EmployeeMultiSelect.vue'
import SelectField from '@/domain/fields/SelectField.vue'
import CustomApi from '@/plugins/customApi'

const customApi = CustomApi()

const availableSettingItemComponents = {
    ...holidaySettingComponents,
    ...sickLeaveSettingComponents,
    ...businessTripSettingComponents,
    ...remoteWorkSettingComponents,
    ...documentsSettingComponents
}

export default {
    name: 'SettingsHomePage',
    components: {
        SelectField, EmployeeMultiSelect,
        MultilineSelectField,
        SettingModuleItem,
        SettingModule,
        UiButton,
        UiIcon,
        TextField,
        Page,
        ...availableSettingItemComponents
    },
    data() {
        return {
            loading: false,
            enabledModules: [],
            activeModule: null,
            settings: {},
            departmentIds: []
        }
    },
    computed: {
        companyModules() {
            return this.enabledModules.map(module => ({
                module,
                title: this.$t(`setting.modules.${module}`),
                active: module === this.activeModule
            }))
        },
        viewModules() {
            if (this.activeModule == null) {
                return this.enabledModules
            }

            return [this.activeModule]
        }
    },
    mounted() {
        this.fetch()
    },
    methods: {
        handleChangeSetting(module, key, value) {
            const index = this.settings[module].findIndex(item => item.key === key)
            if (index === -1) return
            this.$set(this.settings[module], index, {
                ...this.settings[module][index],
                ...value
            })
        },
        handleChangeModule(module) {
            if (this.activeModule === module) {
                this.activeModule = null
                return
            }

            this.activeModule = module
        },
        async fetch() {
            try {
                this.loading = true
                const { data: { settings, enabledModules } } = await customApi.factories.settings.fetchCompany()
                this.enabledModules = enabledModules
                this.$set(this, 'settings', Object.fromEntries(
                    Object.entries(settings)
                        .map(([key, value]) => {
                            return [
                                key,
                                value.map(item => {
                                    return {
                                        ...item,
                                        component: item.key.charAt(0).toUpperCase() + item.key.slice(1)
                                    }
                                })
                            ]
                        })
                ))

            } catch (e) {
                console.error(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang='scss'>
.settings-home {
    position: relative;
    z-index: 2;
    display: flex;
    gap: 20px;
}

.settings-home-sidebar {
    min-width: 300px;
    display: flex;
    gap: 5px;
    flex-direction: column;
    position: sticky;
    top: 80px;
    height: fit-content;

    &__item {
        padding: 10px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
        border: 1px solid rgba(75, 86, 210, 0.1);

        &:hover {
            background-color: rgba(75, 86, 210, 0.3);
        }

        &.active {
            background-color: rgba(75, 86, 210, 1);
            color: #FFFFFF;
        }
    }
}

.settings-home-search {
    //position: sticky;
    //top: 42px;
    //z-index: 10;
}

.settings-home-container {
    width: 100%;
    max-width: 800px;
}

.settings-modules {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
}
</style>