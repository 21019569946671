import _ from "lodash";
import {
  ADD_MESSAGE,
  ADD_TOAST_TAG,
  CLEAR_MESSAGE, CLEAR_MESSAGES,
  CLOSE_MESSAGE,
  REMOVE_TOAST_TAG
} from "@/domain/messages/store/mutations";
import {GLOBAL_TOAST_TAG} from "@/utils/default";

const formatMessagePayload = (tag, type, message) => {
  return {
    tag,
    messages: (typeof message === "string" ? [message] : message).map((message, key) => ({
      tag,
      shown: true,
      id: _.uniqueId(`${tag}_${(new Date()).getTime()}_`),
      type,
      message,
      // transitionDelay: 100 * key
      transitionDelay: 0
    }))
  };
};

export const showMessage = ({commit}, {tag, type, message}) => {
  if (typeof message === "object" && !Array.isArray(message)) {
    Object.keys(message).forEach(key => {
      commit(ADD_MESSAGE, formatMessagePayload(`${tag}-${key}`, type, message[key]));
    });

    return;
  }

  commit(ADD_MESSAGE, formatMessagePayload(tag, type, message));
};
export const showErrorMessage = ({dispatch}, {tag, message}) => dispatch("showMessage", {tag, type: "error", message});
export const showInfoMessage = ({dispatch}, {tag, message}) => dispatch("showMessage", {tag, type: "info", message});
export const showSuccessMessage = ({dispatch}, {tag, message}) => dispatch("showMessage", {tag, type: "success", message});
export const showWarningMessage = ({dispatch}, {tag, message}) => dispatch("showMessage", {tag, type: "warning", message});
export const showDefaultMessage = ({dispatch}, {tag, message}) => dispatch("showMessage", {tag, type: "default", message});
export const clearMessage = ({commit}, payload) => commit(CLEAR_MESSAGE, payload);
export const clearMessages = ({commit}, payload) => commit(CLEAR_MESSAGES, payload);
export const closeMessage = ({commit}, payload) => commit(CLOSE_MESSAGE, payload);
export const addToastTag = ({commit}, payload) => commit(ADD_TOAST_TAG, payload);
export const removeToastTag = ({commit}, payload) => commit(REMOVE_TOAST_TAG, payload);