//  initial state
import _ from 'lodash'
import declensionService from '@/services/employees/declensionService'
import { USER_ROLE_TEXT } from '@/store/modules/user'
import Vue from 'vue'

const defaultState = () => ({
    page: '1',
    filter: {
        limit: '10',
        query: '',
        dismissed: '0'
    },
    employees: [],
    needRefresh: null,
    total: 0,
    departments: [],
    duties: [],
    roles: [],
    permissions: [],
    deleteReasons: [],

    tableData: {
        selectRowIds: []
    }
})

const state = () => ({
    ...defaultState()
})

// getters
const getters = {
    filter: state => state.filter,
    page: state => state.page,
    needRefresh(state) {
        return state.needRefresh
    },
    tableData(state) {
        return state.tableData
    },
    checkedRows(state) {
        return state.employees.map(employee => employee.id)
            .reduce((obj, id) => ({ ...obj, [id]: false }), {})
    },
    total: state => state.total,
    getEmployees: state => ids => state.employees.filter(employee => ids.includes(employee.id)),
    deleteReasonOptions: state => state.deleteReasons.map(item => ({
        value: item.id,
        text: `<b>${item.article}</b> ${item.description}`
    })),
    rows(state) {
        return state.employees.map(item => {
            let dismissData = null

            if (item.contract.workEndAt != null) {
                dismissData = { date: item.contract.workEndAt, reason: item.contract.dismissReason }
            }

            if (item.dismiss != null) {
                dismissData = { date: item.dismiss.date, reason: item.dismiss.reason }
            }

            return {
                ...item,
                profile: item,
                // role: item.user.
                email: item.email ?? '-',
                role: item.roles?.length === 0 ? '-' : item.roles.map(role => USER_ROLE_TEXT[role]).join(', '),
                inviteStatus: item.activated ?? false,
                leader: item.leader ?? null,
                department: item.department,
                dismissData
                // dismissData: item.dismisses != null
                //     ? item.dismissed ? item.dismisses.pop() : item.dismisses.filter(item => !item.dismissed).pop()
                //     : null
            }
        })
    }
}

//  mutations
const mutations = {
    setNeedToRefresh(state) {
        state.needRefresh = (new Date()).getTime()
    },
    load(state, payload) {
        state.total = payload.total
        state.employees = payload.employees
    },
    clear(state) {
        Object.assign(state, defaultState())
    },
    clearEmployees(state) {
        state.employees.data = []
    },
    changeTableData(state, payload) {
        state.tableData = {
            ...state.tableData,
            ...payload
        }
    },
    clearTableData(state) {
        state.tableData.selectRowIds = []
    },
    create(state, payload) {
        state.employees.total++
        state.employees.data.unshift(payload)
    },
    update(state, payload) {
        state.employees.data = state.employees.data.map(row => row.id === payload.id ? payload : row)
    },
    checkSentInvites(state, ids) {
        state.employees = state.employees.map(employee => {
            if (ids.indexOf(employee.id) === -1) {
                return employee
            }

            return {
                ...employee,
                inviteStatus: 0
            }
        })
    },
    setDeleteReasons: (state, payload) => state.deleteReasons = payload,
    dismissEmployees: (state, payload) => {

        payload.forEach(employee => {
            const index = state.employees.findIndex(({id}) => id === employee.id)
            if (index === -1) {
                return
            }

            Vue.set(state.employees, index, employee)
        })

        // payload.forEach(dismiss => {
        //     const employee = state.employees.find(item => item.id === dismiss.employeeId)
        //     if (employee) {
        //         employee.dismisses = [dismiss]
        //     }
        // })
    },
    deleteEmployees: (state, payload) => {
        state.total -= payload.length
        state.employees = state.employees.filter(employee => !payload.includes(employee.id))
    },
    changePage: (state, payload) => state.page = payload,
    changeFilter: (state, payload) => {
        state.filter = {
            ...state.filter,
            ...payload
        }
    },
    changeLeader: (state, payload) => {
        // state.employees = state.employees.map(employee => {
        //   if (_.find(employee.departments, department => department.id === payload.departmentId) == null) {
        //     return employee;
        //   }
        //
        //   return {
        //     ...employee,
        //     departmentLeaders: [payload]
        //   }
        // })
    }
}

// actions
const actions = {
    load: ({ commit }, payload) => {
        commit('clearTableData')
        commit('load', payload)
    },
    changePage: ({ commit }, payload) => {
        commit('changePage', payload)
    },
    changeFilter: ({ commit }, payload) => {
        commit('changePage', 1)
        commit('changeFilter', payload)
    },
    changeLeader: ({ commit }, payload) => commit('changeLeader', payload),
    setDeleteReasons: ({ commit }, payload) => commit('setDeleteReasons', payload),
    dismissEmployees: ({ commit }, payload) => {
        commit('dismissEmployees', payload)
        commit('clearTableData')
    },
    deleteEmployees: ({ commit }, payload) => {
        commit('deleteEmployees', payload)
        commit('changeFilter', { page: '1', query: '' })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
