<template>
    <fragment v-if='visible'>
        <department-event-row-action-context-menu-item
            v-if='isEdit'
            icon='edit'
            :title='$t("Redaguoti")'
            @click='handleUpdate'
        />
        <department-event-row-action-context-menu-item
            v-if='isAbort'
            icon='close'
            :title='$t("Atmesti")'
            @click='handleAbort'
        />
        <department-event-row-action-context-menu-item
            v-if='isInfo'
            icon='info'
            :title='$t("Informacija")'
            @click='handleInfo'
        />
    </fragment>
</template>

<script>
import DepartmentEventRowActionContextMenuItem
    from '@/domain/departmentVacations/components/actions/DepartmentEventRowActionContextMenuItem.vue'
import checkIsLeaderMixin from '@/mixins/checkIsLeaderMixin'
import checkIsAdminMixin from '@/mixins/checkIsAdminMixin'
import modalMethodsMixin from '@/domain/mixins/modalMethodsMixin'
import { GLOBAL_TOAST_TAG } from '@/utils/default'
import CustomApi from '@/plugins/customApi'

const customApi = CustomApi()

export default {
    name: 'SickLeaveContextMenuItems',
    components: { DepartmentEventRowActionContextMenuItem },
    mixins: [checkIsLeaderMixin, checkIsAdminMixin, modalMethodsMixin],
    inject: ['createRow', 'updateRow'],
    props: {
        row: { type: Object, required: true }
    },
    computed: {
        visible() {
            return this.row.type === 4001 && this.isAdmin
        },
        isEdit() {
            return this.visible && this.row.currentStatus !== "aborted"
        },
        isAbort() {
            return this.visible && this.row.currentStatus !== "aborted"
        },
        isInfo() {
            return this.visible
        }
    },
    methods: {
        handleUpdate() {
            this.onOpenModal('UpdateSickLeaveModal', {
                id: this.row.id
            }, {
                updated: (data) => {
                    this.updateRow(data)
                }
            })
        },
        handleAbort() {
            this.onOpenModal('ConfirmModal', {
                title: this.$t('Ar tikrai norite atmesti ligonlapį?')
            }, {
                confirmed: async (confirm) => {
                    try {
                        const { data } = await customApi.factories.events.sickLeaves.abortById(this.row.id)
                        this.updateRow(data)
                        await this.$store.dispatch('messagesStore/showSuccessMessage', {
                            tag: GLOBAL_TOAST_TAG,
                            message: this.$t('Ligonlapis atmestas')
                        })
                    } catch (e) {
                        console.error(e)
                        await this.$store.dispatch('messagesStore/showErrorMessage', {
                            tag: GLOBAL_TOAST_TAG,
                            message: this.$t('Nepavyko atmesti ligonlapio')
                        })
                    } finally {
                        confirm.close()
                    }
                }
            })
        },
        handleInfo() {
            // this.createRow(this.row)
            // this.updateRow(this.row)
        }
    }
}
</script>