import CompanyLayout from "@/domain/company/layouts/CompanyLayout.vue";
import CompanyTreePage from "@/domain/company/pages/CompanyTreePage.vue";
import CompanyDutiesPage from "@/domain/company/pages/CompanyDutiesPage.vue";
import {permissionOnlyAdmin} from "@/utils/permissions";

export default [
  {
    path: "/company",
    component: CompanyLayout,
    children: [
      {name: "CompanyTreePage", path: "tree", component: CompanyTreePage},
      {name: "CompanyDutiesPage", path: "duties", component: CompanyDutiesPage, meta: {permissions: permissionOnlyAdmin}},
    ]
  }
]