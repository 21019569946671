export default axios => ({

  fetchCompany: () => axios.get(`/api/settings/company`),
  set: (payload) => axios.post(`/api/settings/company`, payload),

  // fetchEventRequests: () => axios.get(`/api/settings/event-requests`),
  // updateEventType: (payload) => axios.put(`/api/settings/event-requests`, payload),
  //
  // approvals: {
  //   fetchByType: (type) => axios.get(`/api/approvals/settings/?type=${type}`),
  //   fetchById: (id) => axios.get(`/api/approvals/settings/${id}`),
  //   fetchUsersByFilter: (params) => axios.get(`/api/approvals/settings/users`, {params}),
  //   createByType: (type, payload) => axios.post(`/api/approvals/settings/${type}`, payload),
  //   updateById: (id, payload) => axios.put(`/api/approvals/settings/${id}`, payload),
  //   deleteById: (id) => axios.delete(`/api/approvals/settings/${id}`)
  // }


  // get: () => axios.get(`/api/v1/settings`),
  // changeVacation: data => axios.post(`/api/v1/settings/vacations`, {data}),
  // addVacationAutApproveEmployee: id => axios.post(`/api/v1/settings/vacations/auto-approve-employee`, {id}),
  // removeVacationAutApproveEmployee: id => axios.delete(`/api/v1/settings/vacations/auto-approve-employee/${id}`),
  // changeEmail: payload => axios.post(`/api/v1/settings/email`, payload),
  //
  // getVacations: () => axios.get(`/api/v2/settings/vacations`),
  // vacationChangeOptions: (payload) => axios.put(`/api/v2/settings/vacations/options`, payload),
  // vacationAddAutoApproveEmployee: (payload) => axios.post(`/api/v2/settings/vacations/auto-approve`, payload),
  // vacationRemoveAutoApproveEmployee: (id) => axios.delete(`/api/v2/settings/vacations/auto-approve/${id}`),
  //
  // getEmail: () => axios.get(`/api/v2/settings/email`),
  // updateEmail: (payload) => axios.post(`/api/v2/settings/email`, payload),
});
