<template>
	<hra-table-inline-edit-row
		v-bind="attrs" v-on="on" v-model="rowData"
		:options="options" :id="id" :before-save="beforeSaveRow"
		:is-edit="isEdit"
		:is-removable="isRemovable"
		@startEdit="onStartEdit" @cancelEdit="onCancelEdit"
		@save="onSave" @delete="onDelete">
		<template #field.name.prepend="{value}">
			<avatar :employee-name="value || ''"/>
		</template>
<!--		<template #field.departments.input.append="{isEdit}">-->
<!--			<hra-button-modal v-if="isEdit" :color="uiColor.primary" no-background custom-class="pa-0"-->
<!--			                  modal-name="CreateDepartmentModal" :modal-props="{beforeSave: onSaveDepartment}">-->
<!--				<hra-icon :name="iconName.plus" />-->
<!--			</hra-button-modal>-->
<!--		</template>-->
<!--		<template #field.duties.input.append="{isEdit}">-->
<!--			<hra-button-modal v-if="isEdit" :color="uiColor.primary" no-background custom-class="pa-0"-->
<!--			                  modal-name="CreateDutyModal" :modal-props="{beforeSave: onSaveDuty}">-->
<!--				<hra-icon :name="iconName.plus" />-->
<!--			</hra-button-modal>-->
<!--		</template>-->
	</hra-table-inline-edit-row>
</template>

<script>
import Avatar from "@/components/ui/Avatar";
import HraTableInlineEditRow from "@/components/ui/table/HraTableInlineEditRow";
import _ from "lodash";
import {mapMutations} from "vuex";
import HraIcon from "@/components/ui/HraIcon";
import HraButton from "@/components/ui/button/HraButton";
import HraButtonModal from "@/components/ui/button/HraButtonModal";

export default {
	name: "ImportEmployeesManageTableRow",
	components: {HraButtonModal, HraButton, HraIcon, Avatar, HraTableInlineEditRow},
	props: {
		attrs: {type: Object, default: () => ({})},
		on: {type: Object, default: () => ({})},
		options: {type: Object, default: () => ({})},
		id: {type: [Number, String], required: true},
		cols: {type: Array, required: true},
		isEdit: {type: Boolean, default: false},
		isRemovable: {type: Boolean, default: true},
	},
	inject: ["importService"],
	data: () => ({
		rowData: [],
	}),
	watch: {
		cols: {
			handler(newValue, oldValue) {
				if (!_.eq(newValue, oldValue)) {
					this.rowData = this.cols;
				}
			},
			immediate: true,
			deep: true,
		},
	},
	provide() {
		return {
			createDepartments: this.createDepartments,
			createDuties: this.createDuties,
		}
	},
	methods: {
		...mapMutations({
			saveRow: "employeesImport/saveRow",
			deleteRow: "employeesImport/deleteRow",
		}),
		beforeSaveRow(fields) {
			let payload = {
				id: this.id,
				row: {},
			};

			fields.forEach(field => {
				if (field.value == null) {
					return;
				}

				payload.row[field.name] = {
					...field.value,
					valid: true,
				};
			});

			this.saveRow(payload);
			return true;
		},
		async onSave(payload) {
			await this.importService.updateRow({id: this.id, ...payload});
		},
		async onStartEdit() {
			await this.importService.startEditRow(this.id);
		},
		async onCancelEdit() {
			await this.importService.cancelEditRow(this.id);
		},
		async onDelete() {
			await this.importService.removeRow(this.id);
		},

		async createDepartments(name) {
			await this.importService.createDepartment(this.id, name);
		},
		async createDuties(name) {
			await this.importService.createDuty(this.id, name);
		},

		async onSaveDepartment(name) {
			await this.importService.createDepartment(this.id, name);
		},
		async onSaveDuty(name) {
			await this.importService.createDuty(this.id, name);
		},
	},
}
</script>
