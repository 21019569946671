<template>
	<v-dialog v-model="dialog" persistent scrollable :max-width="maxWidth" transition="dialog-top-transition">
		<template v-slot:activator="{ on, attrs }">
			<div v-bind="attrs" v-on="on">
				<slot/>
			</div>
		</template>

		<template>
			<div class="dialog-confirm gap-6">
				<div class="dialog-confirm__header px-5">
					<h6>{{ title }}</h6>
				</div>
				<div v-if="$slots.content" class="dialog-confirm__content">
					<slot name="content"/>
				</div>
				<div class="dialog-confirm__content d-flex flex-row gap-3">
					<template v-if="loading">
						<full-row-loader />
					</template>
					<template v-else>
						<div v-if="abortText" @click.prevent="onClose" style="width: 100%;">
							<slot name="button.abort">
								<hra-button :size="buttonSize.small"
								            :color="abortColor || uiColor.default" full-width
								>
									<hra-icon v-if="abortIconName" :name="abortIconName"/>
									{{ abortText }}
								</hra-button>
							</slot>
						</div>
						<div v-if="confirmText" @click.prevent="onConfirm" style="width: 100%;">
							<slot name="button.confirm">
								<hra-button :size="buttonSize.small"
								            :color="confirmColor || uiColor.primary" full-width
								>
									<hra-icon v-if="confirmIconName" :name="confirmIconName"/>
									{{ confirmText }}
								</hra-button>
							</slot>
						</div>
					</template>
				</div>
			</div>
		</template>
	</v-dialog>
</template>

<script>
import HraButton from "@/components/ui/button/HraButton";
import HraIcon from "@/components/ui/HraIcon";
import FullRowLoader from "@/components/ui/FullRowLoader";

export default {
	name: "HraConfirm",
	components: {FullRowLoader, HraIcon, HraButton},
	props: {
		maxWidth: {type: Number, default: 400},
		title: {type: String, required: true},
		abortText: {type: String, default: null},
		abortColor: {type: String, default: null},
		abortIconName: {type: String, default: null},
		confirmText: {type: String, default: null},
		confirmColor: {type: String, default: null},
		confirmIconName: {type: String, default: null},
	},
	data: () => ({
		dialog: false,
		loading: false,
	}),
	methods: {
		getDialog() {
			return this.dialog;
		},
		openConfirm() {
			this.dialog = true;
		},
		closeConfirm() {
			this.dialog = false;
		},
		showConfirmLoader() {
			this.loading = true;
		},
		hideConfirmLoader() {
			this.loading = false;
		},
		onClose() {
			this.closeConfirm();
			this.$emit("abort");
		},
		onConfirm() {
			this.$emit("confirm", this);
		},
	}
}
</script>