import DocumentGroupSidebar from '@/domain/documents/items/sidebar/DocumentGroupSidebar.vue'
import DocumentLastHistorySidebar from '@/domain/documents/items/sidebar/DocumentLastHistorySidebar.vue'

export default {
    methods: {
        closeSidebar() {
            this.$root.$emit('documents-manage-items-close-sidebar')
        },
        openDocumentLastHistorySidebar(props = {}, on = {}) {
            this.closeSidebar()
            this.$nextTick(() => {
                this.$root.$emit('documents-manage-items-open-sidebar', {
                    component: DocumentLastHistorySidebar,
                    props,
                    on
                })
            })
        },
        openDocumentGroupSidebar(props = {}, on = {}) {
            this.closeSidebar()
            this.$nextTick(() => {
                this.$root.$emit('documents-manage-items-open-sidebar', {
                    component: DocumentGroupSidebar,
                    props,
                    on
                })
            })
        }
    }
}