<template>
    <custom-dialog :max-width='670'>
        <template #title>{{ $t('Redaguoti pertraukas') }}</template>
        <template #content>
            <v-row class='px-3'>
                <v-col cols='12'>
                    {{ $t('Darbo laikas nuo')}} <i class='text-body-1'>{{ eventStart }}</i> {{ $t('Iki').toLowerCase() }} <i class='text-body-1'>{{ eventEnd }}</i>
                </v-col>
            </v-row>
            <template
                v-for='eventBreak in formData.breaks'
            >
                <v-row class="bg-blue-light">
                    <v-col class="flex-grow-1">
                        <v-row>
                            <v-col class="d-flex align-end">
                                <text-field type='number' min='0' step='30' :label='$t("Po darbo pradžios")' v-model='eventBreak.after' />
                            </v-col>
                            <v-col class="d-flex align-end">
                                <text-field type='number' min='0' step='30' :label='$t("Trukmė")' v-model='eventBreak.duration' />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <form-checkbox
                                    :is-checked.sync='eventBreak.subWorkTime'
                                >
                                    <div class='ml-3 hra-text--16'>
                                        {{ $t('Subwork time') }}
                                    </div>
                                </form-checkbox>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col class="flex-grow-0 width-120 align-center d-flex">
                        <ui-button
                            background='danger'
                            append-icon='bin'
                            @click='onDeleteBreakByKey(eventBreak.key)'
                        />
                    </v-col>
                </v-row>
            </template>
            <v-row>
                <v-col cols='12'>
                    <ui-button @click='onAddNewBreak'>{{ $t('Pridėti pertrauką') }}</ui-button>
                </v-col>
            </v-row>
        </template>

        <template #footer>
            <ui-button
                prepend-icon='close'
                background='default'
                @click='close'
            >{{ $t('Uždaryti') }}
            </ui-button>
            <ui-button
                prepend-icon='ok'
                background='primary'
                :disabled='disabledButton'
                @click='onClick'
            >{{ $t('Išsaugoti') }}
            </ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import TextField from '@/domain/fields/TextField.vue'
import SelectField from '@/domain/fields/SelectField.vue'
import UiButton from '@/domain/components/UiButton.vue'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import EmployeeTableView from '@/components/ui/views/EmployeeTableView.vue'
import dialogMixin from '@/mixins/dialogMixin'
import DatepickerField from '@/domain/fields/DatepickerField.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import moment from 'moment'
import { generateUuid, GLOBAL_TOAST_TAG } from '@/utils/default'

export default {
    name: 'UpdateScheduleEventBreaksModal',
    components: { DatepickerField, EmployeeTableView, CustomDialog, UiButton, SelectField, TextField, FormCheckbox },
    mixins: [dialogMixin],
    props: {
        event: { type: Object, required: true }
    },
    data() {
        return {
            disabledButton: false,
            eventStart: '',
            eventEnd: '',
            formData: {
                breaks: []
            }
        }
    },
    beforeMount() {
        const start = moment(this.event.startWithTime)
        const end = moment(this.event.endWithTime)

        this.eventStart = start.format('YYYY-MM-DD HH:mm')
        this.eventEnd = end.format('YYYY-MM-DD HH:mm')

        this.event.breaks.forEach(({ after, duration, subWorkTime }) => {
            this.formData.breaks.push({
                key: generateUuid(),
                after,
                duration,
                subWorkTime
            })
        })
    },
    computed: {},
    methods: {
        async onClick() {
            this.disabledButton = true
            const result = await this.$store.dispatch('schedulerStore/updateEventBreaksById', {
                id: this.event.id,
                payload: {
                    breaks: this.formData.breaks.map(({after, duration, subWorkTime}) => ({
                        after: parseInt(after),
                        duration: parseInt(duration),
                        subWorkTime
                    }))
                }
            })
            this.disabledButton = false

            if (!result) {
                return
            }

            await this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag: GLOBAL_TOAST_TAG,
                message: this.$t('Break edit success')
            })
            this.close()
        },
        onAddNewBreak() {
            this.formData.breaks.push({
                key: generateUuid(),
                after: 0,
                duration: 0,
                subWorkTime: false
            })
        },
        onDeleteBreakByKey(searchKey) {
            const index = this.formData.breaks.findIndex(({key}) => key === searchKey)
            if (index < 0) {
                return
            }

            this.formData.breaks.splice(index, 1)
        }
    }
}
</script>