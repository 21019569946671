<template>
    <route-tabs
        ref='Tabs'
        class='mt-8'
        :tabs='tabs'
        default-tab-name='DocumentSummaryMainPage'
    >
    </route-tabs>
</template>

<script>
import RouteTabs from '@/domain/documents/components/RouteTabs.vue'
import modalMethodsMixin from '@/domain/mixins/modalMethodsMixin'

export default {
    name: 'DocumentSummaryLayout',
    components: { RouteTabs },
    mixins: [modalMethodsMixin],
    data() {
        return {
            tabs: [
                { name: 'DocumentSummaryMainPage', text: this.$t('Susipažindinmas') },
                { name: 'DocumentSummaryDocumentsPage', text: this.$t('Susipažindinimas pagal dokumentą') },
                { name: 'DocumentSummaryUsersPage', text: this.$t('Susipažindinmas pagal darbuotoją') }
            ],
            sidebar: null
        }
    },
}
</script>