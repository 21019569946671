<template>
  <div class="documents-overview-status-item">
    <div class="status-item-wrapper">
      <div class="status-item-title">{{ title }}</div>
      <div class="status-item-value">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OverviewStatusItem",
  props: ['title'],

  provide() {

  },
}
</script>