<template>
	<div class="FileField">
		<base-field v-if="!readOnly">
			<div ref="messages" class="FileField__messages">
				<form-alert v-for="message in messages" v-bind="message"/>
			</div>

			<div
				ref="dropArea"
				class="FileField__drop-area"
				@click="onClick"
				@drop.prevent="onDrop"
				@dragover.prevent="onHighlight"
				@dragenter="onHighlight"
				@dragleave="onUnHighlight"
			>
				<img src="@/assets/images/upload-icon.png" alt="upload image">
				<p v-html="$t('Įkelkite dokumentą čia arba <span>spustelėkite</span>, kad juos pasirinktumėte.')"/>
				<input ref="fileUpload" type="file" :multiple="multiple" :accept="acceptTypes.join(', ')"
				       @change="onChange"/>
				<span>{{ $t('Dokumento dydis ne didesnis nei {size} MB.', {size: maxFileSize}) }}</span>
			</div>
		</base-field>

		<div ref="files" class="FileField__files">
			<form-alert v-for="({key, name, url}) in files" :key="key" icon="file">
				<div class="d-flex justify-space-between align-center gap-3" style="width: 100%">
					<a v-if="url" target="_blank" :href="url">{{ name }}</a>
					<span v-else>{{ name }}</span>
					<hra-button
						:size="buttonSize.auto"
						:color="uiColor.dangerLight"
						no-background
						only-icon
						@click="onDelete(key)"
					>
						<hra-icon :name="iconName.bin"/>
					</hra-button>
				</div>
			</form-alert>
		</div>
	</div>
</template>

<script>
import ReadOnlyField from "@/domain/fields/components/ReadOnlyField";
import EditableFieldActions from "@/domain/fields/components/EditableFieldActions";
import BaseField from "@/domain/fields/components/BaseField";
import baseFieldMixin from "@/domain/fields/mixins/baseFieldMixin";
import FormAlert from "@/components/ui/form/FormAlert";
import HraButton from "@/components/ui/button/HraButton";
import HraIcon from "@/components/ui/HraIcon";

export default {
	name: "FileField",
	components: {HraIcon, HraButton, FormAlert, EditableFieldActions, BaseField, ReadOnlyField},
	props: {
		value: {type: [Array, String, Object, File, null], default: null},
		acceptTypes: {type: Array, default: () => ([])},
		multiple: {type: Boolean, default: false},
		maxFileSize: {type: Number, default: 10},
	},
	mixins: [baseFieldMixin],
	data() {
		return {
			messages: [],
		};
	},
	computed: {
		files() {
			if (this.value == null) {
				return [];
			}

			return (this.multiple ? this.value : [this.value])
				.map((file, key) => ({
					key,
					name: typeof file === "string" ? this.$t("Priedas") : file.name,
					url: typeof file === "string" ? `${file}` : null,
				}));
		},
	},
	methods: {
		onHighlight() {
			this.$refs.dropArea.classList.add("highlight");
		},
		onUnHighlight() {
			this.$refs.dropArea.classList.remove("highlight");
		},
		onClick() {
			this.$refs.fileUpload.click();
		},
		onChange(event) {
			this.messages = [];
			let files = [...(event.dataTransfer?.files || event.target?.files)];
			event.target.value = "";

			if (!this.multiple && files.length > 1) {
				this.messages.push({
					type: "error",
					text: this.$t("Galite pasirinkti tik vieną dokumentą")
				});
				return;
			}

			files = files.filter(file => {
				if (this.multiple && this.value != null) {
					if (this.value.find(({name}) => name === file.name)) {
						this.messages.push({
							type: "error",
							text: this.$t("Tokiu pavadinimu <i>{filename}</i> dokumentas jau įkeltas", {filename: file.name})
						});
						return false;
					}
				}

				if (this.acceptTypes.length > 0 && !this.acceptTypes.includes(file.type)) {
					this.messages.push({
						type: "error",
						text: this.$t("Pasirinkto dokumento <i>{filename}</i> tipas yra netinkamas", {filename: file.name})
					});
					return false;
				}

				if (file.size / 1024 / 1024 > this.maxFileSize) {
					this.messages.push({
						type: "error",
						text: this.$t("Pasirinktas dokumentas<i>{filename}</i> per \"didelis\"", {filename: file.name})
					});
					return false;
				}

				return true;
			});

			if (this.messages.length > 0) {
				this.$refs.messages.scrollIntoView();
			} else {
				this.$refs.files.scrollIntoView();
			}

			if (this.multiple) {
				this.$emit("input", [...files, ...(this.value ?? [])]);
				return;
			}

			this.$emit("input", files[0] ?? null);
		},
		onDrop(event) {
			this.onUnHighlight();
			this.onChange(event);
		},
		onDelete(deleteKey) {
			let files = this.value;

			if (Array.isArray(files)) {
				// TODO: clear files when multiple
				files = files.filter((_, key) => key !== deleteKey);
			} else {
				files = null;
			}

			this.$emit("input", files);
		},
	}
}
</script>