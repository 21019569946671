import AuthLayout from '@/layouts/AuthLayout.vue';
import Login from '@/views/pages/auth/Login';
import Register from '@/views/pages/auth/Register';
import ForgotPassword from '@/views/pages/auth/ForgotPassword';
import TwoFactorLogin from '@/views/pages/auth/TwoFactorLogin';
import ResetPassword from '@/views/pages/auth/ResetPassword';
import EnableTwoFactorLogin from '@/views/pages/auth/EnableTwoFactorLogin';

const meta = {canGuest: true};

export const authRouteProps = {
  login: {name: 'login', path: '', meta,},
  register: {name: 'register', path: 'register/:token', meta,},
  forgotPassword: {name: 'forgotPassword', path: 'forgot/password', meta,},
  twoFactorLogin: {name: 'twoFactorLogin', path: 'code', meta,},
  enableTwoFactorLogin: {name: 'enableTwoFactorLogin', path: 'enable/2fa',},
  resetPassword: {name: 'resetPassword', path: 'reset/password/:token', meta,},
};

export default [
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {...authRouteProps.login, component: Login},
      {...authRouteProps.register, component: Register},
      {...authRouteProps.forgotPassword, component: ForgotPassword},
      {...authRouteProps.twoFactorLogin, component: TwoFactorLogin},
      {...authRouteProps.enableTwoFactorLogin, component: EnableTwoFactorLogin},
      {...authRouteProps.resetPassword, component: ResetPassword},
    ]
  },
];
