<template>
    <employee-event-invite-line
        :type-text='typeText'
        :start='start'
        :end='end'
        :deputies='deputies'
        :with-status='withStatus'
    >
        <template #status>
            <event-status
                :status='event.currentStatus'
                :status-text='event.currentStatusText'
            />
        </template>

        <template #actions>
            <hra-tooltip
                v-if='canAbort'
                :text="$t('Atmesti')"
            >
                <ui-button
                    background='danger-light'
                    :only-hover-background='!isBondMobile'
                    size='small'
                    prepend-icon='close'
                    :confirm='confirmAbort'
                />
            </hra-tooltip>

            <hra-tooltip
                v-if='canApprove'
                :text="$t('Patvirtinti')"
            >
                <ui-button
                    background='success-light'
                    :only-hover-background='!isBondMobile'
                    size='small'
                    prepend-icon='ok'
                    :confirm='confirmApprove'
                />
            </hra-tooltip>
        </template>
    </employee-event-invite-line>
</template>

<script>
import EmployeeEventInviteLine from '@/domain/eventInvite/components/employee/EmployeeEventInviteLine.vue'
import UiButton from '@/domain/components/UiButton.vue'
import HraTooltip from '@/components/ui/HraTooltip.vue'
import isBondMobileMixin from '@/mixins/isBondMobileMixin'
import CustomApi from '@/plugins/customApi'
import moment from 'moment/moment'
import { GLOBAL_TOAST_TAG } from '@/utils/default'
import EventStatus from '@/domain/components/EventStatus.vue'

const customApi = CustomApi()
export default {
    name: 'EmployeeHolidayDeputyEventInvite',
    components: { EventStatus, HraTooltip, UiButton, EmployeeEventInviteLine },
    mixins: [isBondMobileMixin],
    props: {
        invite: { type: Object, required: true },
        withStatus: { type: Boolean, default: false }
    },
    data() {
        return {
            todayDate: moment().format('YYYY-MM-DD'),
            confirmAbort: {
                props: {
                    title: this.$t('Ar tikrai norite atmesti pavadavimą?')
                },
                on: {
                    confirmed: (confirm) => this.abort(confirm)
                }
            },
            confirmApprove: {
                props: {
                    title: this.$t('Ar tikrai norite patvirtinti pavadavima?')
                },
                on: {
                    confirmed: (confirm) => this.approve(confirm)
                }
            }
        }
    },
    computed: {
        event() {
            return this.invite.approvalable
        },
        start() {
            return this.event.start
        },
        end() {
            return this.event.end
        },
        typeText() {
            return this.event.typeText
        },
        deputies() {
            const parentUserName = this.event.parent?.user?.name ?? '-'

            return `${this.$t('Pavaduojate')} <b>${parentUserName}</b>`
        },
        canAbort() {
            return this.start >= this.todayDate && this.currentStatus !== 'aborted'
        },
        canApprove() {
            return this.event.currentStatus === 'pending'
        }
    },
    methods: {
        async abort(confirm) {
            try {
                const result = await customApi.factories.employee.holidays.deputies.abort(this.event.id, { message: '' })
                if (result == null) {
                    return
                }

                await this.$store.dispatch('loadersStore/hideLoader', confirm.tag)
                await this.$store.dispatch('messagesStore/showSuccessMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Sėkmingai atšauktą.')
                })
                this.$emit('changed', this.invite.id)
            } catch (e) {
                console.error(e)
                await this.$store.dispatch('messagesStore/showErrorMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Nepaviko atšaukti.')
                })
            } finally {
                confirm?.close()
            }
        },
        async approve(confirm) {
            try {
                const result = await customApi.factories.employee.holidays.deputies.approve(this.event.id, { message: '' })
                if (result == null) {
                    return
                }

                await this.$store.dispatch('loadersStore/hideLoader', confirm.tag)
                await this.$store.dispatch('messagesStore/showSuccessMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Sėkmingai patvirtintą.')
                })
                this.$emit('changed', this.invite.id)
            } catch (e) {
                console.error(e)
                await this.$store.dispatch('messagesStore/showErrorMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Nepaviko patvirtinti.')
                })
            } finally {
                confirm?.close()
            }
        }
    }
}
</script>