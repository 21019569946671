<template>
    <setting-module-item
        :title='title'
        :description='description'
        :is-edit='isEdit'
        @edit='handleEdit'
        @save='handleSave'
        @cancel='handleCancel'
    >
        <template #company-config>
            <setting-module-item-config-company>
                <setting-documents-remind-about-expire-end-date-config-form
                    :is-edit='isEdit'
                    :config='formData.company'
                />
            </setting-module-item-config-company>
        </template>
    </setting-module-item>
</template>

<script>
import SettingModuleItem from '@/domain/settings/components/SettingModuleItem.vue'
import SettingModuleItemConfig from '@/domain/settings/components/SettingModuleItemConfig.vue'
import settingModuleItemMixin from '@/domain/settings/mixins/settingModuleItemMixin'
import SettingModuleItemConfigDepartment from '@/domain/settings/components/SettingModuleItemConfigDepartment.vue'
import SettingModuleItemMore from '@/domain/settings/components/SettingModuleItemMore.vue'
import SettingModuleItemConfigCompany from '@/domain/settings/components/SettingModuleItemConfigCompany.vue'
import { cloneDeep } from 'lodash'
import { generateUuid } from '@/utils/default'
import SettingDocumentsRemindAboutExpireEndDateConfigForm
    from '@/domain/settings/components/documents/remindAboutExpireEndDate/ConfigForm.vue'

export default {
    name: 'SettingDocumentsRemindAboutExpireEndDate',
    components: {
        SettingDocumentsRemindAboutExpireEndDateConfigForm,
        SettingModuleItemConfigCompany,
        SettingModuleItemMore,
        SettingModuleItemConfigDepartment,
        SettingModuleItemConfig,
        SettingModuleItem
    },
    mixins: [settingModuleItemMixin],
    data() {
        const defaultFormData = {
            enabled: true,
            daysBeforeExpire: 1,
            adminIds: []
        }

        return {
            isEdit: false,
            defaultFormData,
            formData: {
                company: cloneDeep(defaultFormData),
                departments: []
            }
        }
    },
    methods: {
        setFormDataFromSettings() {
            this.setFormDataCompany({
                enabled: this.company.config.enabled,
                daysBeforeExpire: this.company.config.daysBeforeExpire,
                adminIds: this.company.config.adminIds
            })
            this.setFormDataDepartments(this.departments.map(payload => ({
                uuid: generateUuid(),
                departmentIds: payload.departmentIds,
                enabled: payload.config.enabled,
                daysBeforeExpire: payload.daysBeforeExpire,
                adminIds: payload.adminIds
            })))
        },
        getFromFormDataCompanyPayload() {
            return {
                active: true,
                config: {
                    enabled: this.formData.company.enabled,
                    daysBeforeExpire: this.formData.company.daysBeforeExpire,
                    adminIds: this.formData.company.adminIds
                }
            }
        },
        getFromFormDataDepartmentsPayload() {
            return this.formData.departments.map(payload => ({
                active: true,
                config: {
                    enabled: payload.enabled,
                    daysBeforeExpire: payload.daysBeforeExpire,
                    adminIds: payload.adminIds
                },
                departmentIds: payload.departmentIds
            }))
        },
        async handleSave() {
            await this.saveAction('documents')
        }
    }
}
</script>