var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('hra-table',{staticClass:"pa-3",attrs:{"headers":_vm.headers,"rows":_vm.rows,"expand-multiple-row":"","expand-row-ids":_vm.expandRowIds,"need-actions":""},on:{"update:expandRowIds":function($event){_vm.expandRowIds=$event},"update:expand-row-ids":function($event){_vm.expandRowIds=$event}},scopedSlots:_vm._u([{key:"col.timeCardNumber",fn:function(ref){
var colValue = ref.colValue;
return [_c('div',{staticClass:"px-3"},[_vm._v(_vm._s(colValue))])]}},{key:"col.departments",fn:function(ref){
var colValue = ref.colValue;
return [_c('div',{staticClass:"px-3"},[_vm._v(_vm._s(colValue.join(', ')))])]}},{key:"col.duties",fn:function(ref){
var colValue = ref.colValue;
return [_c('div',{staticClass:"px-3"},[_vm._v(_vm._s(colValue.join(', ')))])]}},{key:"col.actions-base",fn:function(ref){
var row = ref.row;
var expandRow = ref.expandRow;
return [_c('div',{staticClass:"d-flex justify-end align-center gap-3"},[(row.invalid)?_c('hra-tooltip',{attrs:{"text":'test error...'}},[_c('hra-icon',{attrs:{"name":_vm.iconName.info,"color":_vm.uiColor.danger}})],1):_vm._e(),_c('hra-tooltip',{attrs:{"text":_vm.$t(row.isExpand ? 'Suskleisti' : 'Išskleisti')}},[_c('hra-button',{attrs:{"size":_vm.buttonSize.small,"color":_vm.uiColor.default,"hover-background":!row.isExpand},on:{"click":function () { return expandRow(row.id); }}},[_c('v-fab-transition',[_c('div',{key:((row.isExpand ? 1 : 0) + "-expand")},[_c('hra-icon',{attrs:{"name":row.isExpand ? _vm.iconName.up : _vm.iconName.down}})],1)])],1)],1)],1)]}},{key:"row-expansion",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"pa-3"},[_c('employee-expand-table',{attrs:{"id":row.id,"validated":row.validated}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }