var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-2",class:{"ps-8": !_vm.isBondMobile}},[_c('hra-table',{staticClass:"no-border-around",attrs:{"loading":_vm.loading,"headers":_vm.headers,"rows":_vm.rows,"hide-headers":"","need-actions":""},scopedSlots:_vm._u([{key:"col.typeText",fn:function(ref){
var colValue = ref.colValue;
var row = ref.row;
return [(_vm.isBondMobile)?_c('div',{staticClass:"vacation-history-info ps-4 py-4"},[_c('div',{staticClass:"type"},[_vm._v(_vm._s(row.typeText))]),_c('div',{staticClass:"period mb-1"},[_c('span',[_vm._v(_vm._s(row.period.start))]),_vm._v(" iki "),_c('span',[_vm._v(_vm._s(row.period.end))])]),_c('div',{staticClass:"deputy mb-1",domProps:{"innerHTML":_vm._s(row.deputyTitle)}}),_c('div',{staticClass:"status first-letter-uppercase"},[_vm._v(_vm._s(row.statusText))])]):_c('div',{staticClass:"px-1 px-sm-3 py-5"},[_vm._v(_vm._s(colValue))])]}},{key:"col.period",fn:function(ref){
var colValue = ref.colValue;
return [_c('vacation-period-view',{staticClass:"px-1 px-sm-3 py-5",attrs:{"from-date":colValue.start,"to-date":colValue.end}})]}},{key:"col.deputyTitle",fn:function(ref){
var colValue = ref.colValue;
var row = ref.row;
return [_c('div',{staticClass:"px-1 px-sm-3 py-2",domProps:{"innerHTML":_vm._s(colValue)}})]}},{key:"col.statusText",fn:function(ref){
var colValue = ref.colValue;
return [_c('span',{staticClass:"px-1 px-sm-3 py-5 first-letter-uppercase"},[_vm._v(_vm._s(colValue))])]}},{key:"col.actions-base",fn:function(ref){
var row = ref.row;
return [_c('table-actions',{attrs:{"type":row.type,"approvalable":row.approvalable,"status":row.status,"user-id":_vm.userId}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }