<template>
    <custom-dialog :max-width='900' ref='dialog' has-custom-footer>
        <template #title>{{ $t('Atostogų prašymas') }}</template>
        <template #content>
            <vacation-modal-form>
                <template #buttons>
                    <ui-button
                        prepend-icon='close'
                        @click='close'
                        background='default'
                    >{{ $t('Uždaryti') }}
                    </ui-button>

                    <base-confirm
                        v-if='buttonActions.abort'
                        :title="$t('Ar tikrai norite atmesti atostogas?')"
                        @confirm='onAbort'
                    >
                        <ui-button
                            prepend-icon='close'
                            background='danger'
                            :disabled='disabledSubmit'
                        >{{ $t('Atšaukti') }}
                        </ui-button>
                    </base-confirm>

                    <base-confirm
                        v-if='buttonActions.change'
                        :tag='VACATION_MODAL_CREATE_TAG'
                        no-header
                        @abort='close'
                        @confirm='onChange'
                    >
                        <template #content>
                            <div class='pt-9 pb-6 text-center'>
                                {{
                                    $t('Atostogos šiomis dienomis jau yra patvirtintos. Jei norite jas pakeisti, šios bus atšauktos ir sukurtos naujos.')
                                }}
                            </div>
                        </template>

                        <ui-button
                            prepend-icon='ok'
                            background='primary'
                            :disabled='disabledSubmit'
                        >{{ $t('Pakeisti') }}
                        </ui-button>
                    </base-confirm>

                    <ui-button
                        v-if='buttonActions.update'
                        prepend-icon='ok'
                        @click='onUpdate'
                        background='primary'
                        :disabled='disabledSubmit'
                    >{{ $t('Pakeisti') }}
                    </ui-button>

                    <ui-button
                        v-if='buttonActions.create'
                        prepend-icon='ok'
                        @click='onCreate'
                        background='primary'
                        :disabled='disabledSubmit'
                    >{{ $t('Siųsti prašymą') }}
                    </ui-button>
                </template>
            </vacation-modal-form>
        </template>
    </custom-dialog>
</template>

<script>
import VacationModalForm from '@/domain/vacationModal/components/VacationModalForm.vue'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import dialogMixin from '@/mixins/dialogMixin'
import {
    VACATION_MODAL_ABORT_TAG,
    VACATION_MODAL_CHANGE_TAG,
    VACATION_MODAL_CREATE_TAG,
    VACATION_MODAL_INIT_TAG,
    VACATION_MODAL_UPDATE_TAG
} from '@/domain/vacationModal/tags'
import { EVENT_HOLIDAY_TYPE_YEARLY, jsonToFormData } from '@/utils/default'
import HraIcon from '@/components/ui/HraIcon.vue'
import HraButton from '@/components/ui/button/HraButton.vue'
import { mapGetters } from 'vuex'
import customApi from '@/plugins/customApi'
import i18n from '@/plugins/i18n'
import BaseConfirm from '@/domain/confirms/BaseConfirm.vue'
import UiButton from '@/domain/components/UiButton.vue'
import { VACATIONS_INIT_TAG } from '@/domain/employeeVacations/tags'
import modalMethodsMixin from '@/domain/mixins/modalMethodsMixin'
import baseConfirm from '@/domain/confirms/BaseConfirm.vue'

export default {
    name: 'VacationModal',
    components: {
        UiButton,
        BaseConfirm,
        HraButton, HraIcon,
        CustomDialog,
        VacationModalForm
    },
    mixins: [dialogMixin, modalMethodsMixin],
    props: {
        asAdmin: { type: Boolean, default: false },
        userId: { type: [Number, String], required: true },
        eventId: { type: [Number, String], default: null },
        eventType: { type: Number, default: EVENT_HOLIDAY_TYPE_YEARLY },
        start: { type: String, default: null },
        end: { type: String, default: null }
    },
    data() {
        return {
            checkTimeout: null,
            checkWatcher: null,
            VACATION_MODAL_CREATE_TAG
        }
    },
    computed: {
        ...mapGetters({
            hasLoading: 'loadersStore/hasLoading',
            buttonActions: 'vacationModalStore/buttonActions'
        }),
        disabledSubmit() {
            if (this.hasLoading(VACATION_MODAL_CREATE_TAG) ||
                this.hasLoading(VACATION_MODAL_UPDATE_TAG) ||
                this.hasLoading(VACATION_MODAL_CHANGE_TAG) ||
                this.hasLoading(VACATION_MODAL_ABORT_TAG)) {
                return true
            }

            return this.errorMessages != null
        }
    },
    created() {
        this.$store.dispatch('vacationModalStore/init', {
            tag: VACATION_MODAL_INIT_TAG,
            asAdmin: this.asAdmin,
            userId: this.userId,
            eventId: this.eventId,
            eventType: this.eventType,
            start: this.start,
            end: this.end
        })

        this.checkWatcher = this.$store.watch((state) => {
            const { id, type, start, end, deputies } = state.vacationModalStore.currentEvent
            const asAdmin = state.vacationModalStore.asAdmin

            return { eventId: id, type, start, end, deputies, asAdmin }
        }, (payload) => {
            if (payload.start == null) {
                return
            }

            clearTimeout(this.checkTimeout)
            this.checkTimeout = setTimeout(async () => {
                await this.$store.dispatch('vacationModalStore/check', { userId: this.userId, payload })
            }, 300)
        })
    },
    beforeDestroy() {
        this.checkWatcher()
        this.$store.dispatch('vacationModalStore/clear')
    },
    methods: {
        viewConfirm(payload, action, abortedAction = () => {}) {
            this.$store.dispatch('modals/confirm', {
                props: {
                    maxWidth: 550,
                    title: payload.title,
                    content: '<p class="text-left">' + payload.messages.join('<br>') + '</p>',
                    abortText: this.$t('Uždaryti'),
                    confirmText: this.$t('Siųsti prašymą'),
                    hideConfirm: !payload.canSentRequest
                },
                on: {
                    confirmed: (confirm) => {
                        confirm.close()
                        action()
                    },
                    aborted: (confirm) => {
                        confirm.close()
                        abortedAction()
                    }
                }
            })
        },
        getPayload(skipConfirm) {
            return jsonToFormData({
                asAdmin: this.$store.getters['vacationModalStore/asAdmin'],
                sendInviteForEmployeeAndLeader: this.$store.getters['vacationModalStore/sendInviteForEmployeeAndLeader'],
                userId: this.userId,
                skipConfirm: skipConfirm ? 1 : 0,
                ...this.$store.getters['vacationModalStore/currentEvent']
            })
        },
        async createEvent(skipConfirm = false) {
            const tag = VACATION_MODAL_CREATE_TAG
            const api = customApi(tag)
            const result = await api.request(api.factories.employee.holidays.create(this.getPayload(skipConfirm)))
            await this.$store.dispatch('loadersStore/hideLoader', tag)

            if (result == null) {
                return
            }
            const { confirm, event } = result;

            if (confirm != null) {
                this.viewConfirm(confirm, () => this.createEvent(true))
                return
            }

            if (event == null) {
                return
            }

            await this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag, message: i18n.t('Sėkmingai išsiųstas')
            })

            await this.$store.dispatch('employeeStore/createEvent', event)
            this.$emit('changed')
            this.close()
        },
        async changeEvent(baseConfirm, skipConfirm = false) {
            const tag = VACATION_MODAL_CREATE_TAG
            const api = customApi(baseConfirm.tag)
            const result = await api.request(api.factories.employee.holidays.update(this.eventId, this.getPayload(skipConfirm)))
            await this.$store.dispatch('loadersStore/hideLoader', tag)

            if (result == null) {
                return
            }
            const { confirm, event } = result;

            if (confirm != null) {
                this.viewConfirm(confirm, () => this.changeEvent(baseConfirm, true), () => baseConfirm.close())
                return
            }

            if (event == null) {
                return
            }

            await this.$store.dispatch('loadersStore/hideLoader', tag)
            await this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag, message: i18n.t('Sėkmingai išsiųstas')
            })
            await this.$store.dispatch('employeeStore/changeEvent', event)
            this.$emit('changed')
            this.close()
        },
        async updateEvent(skipConfirm = false) {
            const tag = VACATION_MODAL_CREATE_TAG
            const api = customApi(tag)
            const result = await api.request(api.factories.employee.holidays.update(this.eventId, this.getPayload(skipConfirm)))
            await this.$store.dispatch('loadersStore/hideLoader', tag)

            if (result == null) {
                return
            }

            const { confirm, event } = result;
            if (confirm != null) {
                this.viewConfirm(confirm, () => this.updateEvent(true))
                return
            }

            if (event == null) {
                return
            }

            await this.$store.dispatch('loadersStore/hideLoader', tag)
            await this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag, message: i18n.t('Sėkmingai išsiųstas')
            })
            await this.$store.dispatch('employeeStore/updateEvent', event)
            this.$emit('changed')
            this.close()
        },
        onCreate() {
            this.createEvent()
        },
        onChange(confirm) {
            this.changeEvent(confirm)
        },
        onUpdate() {
            this.updateEvent()
        },
        async onAbort(confirm) {
            const api = customApi(confirm.tag)
            const event = this.asAdmin
                ? await api.request(api.factories.department.vacation.admin.abort(this.eventId, { message: '' }))
                : await api.request(api.factories.employee.holidays.abort(this.eventId, { message: '' }))
            await this.$store.dispatch('loadersStore/hideLoader', confirm.tag)
            await this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag: confirm.tag,
                message: this.$t('Sėkmingai atšauktas.')
            })
            await this.$store.dispatch('employeeStore/abortEvent', event)
            this.close()
        },
    }
}
</script>