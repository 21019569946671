//  initial state
const state = () => ({
    modals: [],
    beforeOpenModals: [],
    beforeCloseModals: []
})

// getters
const getters = {
    modals(state) {
        return state.modals
    },
    beforeCloseModals(state) {
        return state.beforeCloseModals
    }
}

// actions
const actions = {
    open: ({ commit }, payload) => commit('open', payload),
    close: ({ commit }, payload) => commit('close', payload),
    confirm: ({ commit }, payload) => commit('open', { name: 'ConfirmModal', ...payload })
}

//  mutations
const mutations = {
    open(state, payload) {
        state.modals.push(payload)
        state.beforeOpenModals.push(payload.name)
    },
    opened(state, payload) {
        state.beforeOpenModals = state.beforeOpenModals.filter(name => name !== payload)
    },
    close(state, payload) {
        state.modals = state.modals.filter(modal => modal.name !== payload)
        state.beforeCloseModals.push(payload)
    },
    closed(state, payload) {
        state.beforeCloseModals = state.beforeCloseModals.filter(name => name !== payload)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
