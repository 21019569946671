<template>
    <page
        :loader-listen='initTag'
        :message-listen='initTag'
        with-background
    >
        <employee-create-form />
    </page>
</template>

<script>
import Page from '@/domain/components/Page'
import employeeCreateService from '@/domain/employee/services/employeeCreateService'
import EmployeeCreateForm from '@/domain/employee/components/EmployeeCreateForm.vue'

export default {
    name: 'EmployeeCreatePage',
    components: {
        EmployeeCreateForm,
        Page
    },
    data() {
        const initTag = 'EMPLOYEE_CREATE_PAGE_INIT'

        return {
            initTag
        }
    },
    beforeCreate() {
        this.$store.dispatch('employeeStore/fetchToCreateEmployee', {tag: this.initTag})
    },
    beforeDestroy() {
        this.$store.dispatch('employeeStore/clearState')
    }
}
</script>