<template>
    <div class='folder-breadcrumbs'>
        <div
            v-for='(item, key) in breadcrumbs'
            :key='key'
            class='folder-breadcrumb-wrap'
            @click='() => !item.currentFolder && handleChangeFolderId(item.folderId)'
        >
            <ui-icon v-if='key !== 0'>arrow-right</ui-icon>
            <span class='folder-breadcrumb'>{{ item.title }}</span>
        </div>
    </div>
</template>

<script>
import UiIcon from '@/domain/components/UiIcon.vue'

export default {
    name: 'FolderBreadcrumbs',
    components: { UiIcon },
    props: {
        breadcrumbs: { type: Array, required: true }
    },
    methods: {
        handleChangeFolderId(folderId) {
            this.$emit('change-folder-id', folderId)
        }
    }
}
</script>

<style lang='scss'>
.folder-breadcrumbs {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
}

.folder-breadcrumb-wrap {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 0 auto;
    flex-shrink: 1000;
    display: inline-block;
    padding: 0;
    position: relative;

    & > .ui-icon {
        font-size: 8px;
        color: #70778C;
        position: relative;
        top: -2px;
        padding: 0 8px;
    }

    &:first-child {
        flex: 0 0 auto;
        flex-shrink: 0.5;
        font-weight: normal;
        opacity: 1;
    }

    &:last-child {
        flex: 1 0 auto !important;
        font-weight: normal;

        & > .breadcrumb {
            color: #454C9C !important;
        }
    }
}

.folder-breadcrumb {
    flex: 0 1 auto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #70778C;
    cursor: pointer;
}
</style>