var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{staticClass:"py-4 px-8",attrs:{"loader-tag":_vm.fetchTag}},[_c('div',{staticClass:"d-flex gap-4 align-center"},[_c('h1',[_vm._v(_vm._s(_vm.$t('Grafikai')))]),(_vm.isAdmin)?_c('ui-button',{attrs:{"size":"small","modal":{name: "CreateScheduleModal", on: {created: _vm.scheduleCreated}}}},[_vm._v(" "+_vm._s(_vm.$t('Sukurti grafiką'))+" ")]):_vm._e()],1),_c('hra-table',{staticClass:"mt-4",attrs:{"headers":_vm.headers,"rows":_vm.rows,"need-actions":""},scopedSlots:_vm._u([{key:"col.actions-base",fn:function(ref){
var row = ref.row;
return [_c('ui-button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.editSchedule(row.id)}}},[_vm._v(_vm._s(_vm.isAdmin ? _vm.$t('Redaguoti') : _vm.$t('Peržiūrėti'))+" ")]),(_vm.isAdmin)?_c('ui-button',{attrs:{"size":"small","background":"danger","confirm":{
                    props: {
                        title: _vm.deleteText
                    },
                    on: {
                        confirmed: function (confirm) { return _vm.deleteSchedule(confirm, row.id); },
                    }
                }}},[_vm._v(_vm._s(_vm.$t('Ištrinti'))+" ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }