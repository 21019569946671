<template>
    <VacationFormTemplate
        :dialog="dialog"
        :close-dialog="closeDialog"
        :service="null"
    />
</template>

<script>
import formDialogMixin from "@/mixins/formDialogMixin";
import VacationFormTemplate from "@/components/forms/app/vacation/VacationFormTemplate";

export default {
    mixins: [formDialogMixin],
    components: { VacationFormTemplate },
    data: () => ({
    }),
};
</script>
