var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('hra-employee-crud-table',{scopedSlots:_vm._u([{key:"buttons",fn:function(){return [(!_vm.readOnly)?_c('ui-button',{attrs:{"size":"small","background":"primary","modal":{name: "EmployeeVacationPlusModal", props: {service: _vm.onCreate, userId: _vm.userId}}}},[_vm._v(" "+_vm._s(_vm.$t('Pridėti'))+" ")]):_vm._e()]},proxy:true}])},[_c('hra-table',{staticClass:"no-border-around",attrs:{"headers":_vm.headers,"rows":_vm.rows,"hide-headers":"","need-actions":!_vm.readOnly},scopedSlots:_vm._u([{key:"header.custom",fn:function(){return [_c('table-title-view',{staticClass:"mb-3 size-1",attrs:{"title":_vm.$t('Papildomos atostogų dienos prie kasmetinių atostogų'),"with-border":""}})]},proxy:true},{key:"col.from",fn:function(ref){
var colValue = ref.colValue;
var row = ref.row;
return [_c('div',{staticClass:"pe-3 py-6",class:{'hra-text--greyscale-500': !row.editable}},[_vm._v(" "+_vm._s(colValue)+" "),(row.to)?[_c('b',[_vm._v(_vm._s(_vm.$t('iki')))]),_vm._v(" "+_vm._s(row.to)+" ")]:_vm._e()],2)]}},{key:"col.comment",fn:function(ref){
var colValue = ref.colValue;
var row = ref.row;
return [_c('div',{staticClass:"pa-3 py-6",class:{'hra-text--greyscale-500': !row.editable}},[_vm._v(_vm._s(colValue))])]}},{key:"col.count",fn:function(ref){
var colValue = ref.colValue;
var row = ref.row;
return [_c('div',{staticClass:"px-3 py-6 text-end",class:{'hra-text--greyscale-500': !row.editable},domProps:{"innerHTML":_vm._s(_vm.$t("{count} d. d.", {count: colValue}))}})]}},{key:"col.actions-base",fn:function(ref){
var row = ref.row;
return [(row.editable)?[_c('hra-tooltip',{attrs:{"text":_vm.$t('Redaguoti')}},[_c('ui-button',{attrs:{"size":"small","prepend-icon":"edit","modal":{name: "EmployeeVacationPlusModal", props: {service: _vm.onUpdate, payload: row, userId: _vm.userId}}}})],1),(row.type !== "base")?_c('base-confirm',{attrs:{"tag":"EMPLOYEE_ADDITIONAL_HOLIDAY_DESTROY_TAG","title":_vm.$t('Ar tikrai norite ištrinti?')},on:{"confirm":function($event){return _vm.onDelete($event, row.id)}}},[_c('hra-tooltip',{attrs:{"text":_vm.$t('Ištrinti')}},[_c('ui-button',{attrs:{"size":"small","background":"danger-light","prepend-icon":"bin"}})],1)],1):_vm._e()]:_vm._e()]}},{key:"empty.text",fn:function(){return [_vm._v(_vm._s(_vm.$t('Įrašų sistemoje pridėta nebuvo.')))]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }