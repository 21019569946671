<template>

	<Auth>
		<template #header>{{ $t("Įveskite patvirtinimo kodą") }}</template>

		<template #form>
			<validation-form :tag="tag" @submit="onSubmit">
				<v-row>
					<v-col cols="12">
						<text-field
							:rules="'required|min:6,simbolių|max:6,' + $t('simbolių')"
							v-model="code"
							name="code"
							:placeholder="$t('6 skaitmenų kodas')"
						/>
					</v-col>

					<v-col cols="12">
						<message-alerts :listen="[tag]"/>
					</v-col>

					<v-col cols="12">
						<hra-button
							:loading="buttonLoading"
							:color="uiColor.primary"
							:type="buttonType.submit"
							full-width
						>{{ $t("Patvirtinti") }}
						</hra-button>
					</v-col>
				</v-row>
			</validation-form>
		</template>
	</Auth>
</template>

<script>
import authMixin from "@/domain/auth/mixins/authMixin";
import Auth from "@/domain/auth/components/Auth";
import TextField from "@/domain/fields/TextField";
import HraButton from "@/components/ui/button/HraButton";
import {forgotPassword as forgotPasswordName} from "@/domain/auth/routes"
import {ValidationObserver} from "vee-validate";
import MessageAlerts from "@/domain/messages/MessageAlerts";
import ValidationForm from "@/domain/components/ValidationForm";
import HraButtonLink from "@/components/ui/button/HraButtonLink";

export default {
	name: "TwoFactor",
	components: {HraButtonLink, ValidationForm, MessageAlerts, ValidationObserver, HraButton, TextField, Auth},
	mixins: [authMixin],
	data() {
		return {
			tag: "AUTH_T2F_TAG",
			forgotPasswordName,
			code: '',
		};
	},
	methods: {
		async onSubmit() {
			await this.service.towFactor(this.tag, this.code);
		},
	}
}
</script>