<template>
    <setting-module-item-config>
        <template #header>
            <multiline-select-field
                style='width: 100%'
                :label='$t("setting.module.default.form.departments")'
                :search-placeholder='$t("setting.module.default.form.departmentsPlaceholder")'
                select-by-key='id'
                :options='departmentOptions'
                :disabled='!isEdit'
                :value='departmentIds'
                rules='required'
                @input='($event) => $emit("changeDepartments", $event)'
            />
            <ui-button
                v-if='isEdit'
                class='ml-2 mt-6 align-self-start'
                prepend-icon='trash'
                background='danger-light'
                @click='() => $emit("removeDepartment")'
            />
        </template>
        <slot />
    </setting-module-item-config>
</template>

<script>
import SettingModuleItemConfig from '@/domain/settings/components/SettingModuleItemConfig.vue'
import MultilineSelectField from '@/domain/components/MultilineSelectField.vue'
import SettingHolidayAllAvailablePayTypesForm
    from '@/domain/settings/components/holiday/allAvailablePayTypes/ConfigForm.vue'
import companyDepartmentsMixin from '@/domain/department/mixins/companyDepartmentsMixin'
import UiButton from '@/domain/components/UiButton.vue'

export default {
    name: 'SettingModuleItemConfigDepartment',
    mixins: [companyDepartmentsMixin],
    inject: ['storeNamespace'],
    props: {
        isEdit: { type: Boolean, default: false },
        departmentIds: {type: Array, default: () => ([])},
    },
    components: {
        UiButton,
        SettingModuleItemConfig,
        SettingHolidayAllAvailablePayTypesForm,
        MultilineSelectField
    },
    computed: {
        ignoreDepartmentIds() {
            return this.$store.getters[`${this.storeNamespace}/selectedDepartmentIds`]
        },
        departmentOptions() {
            return this.companyDepartmentOptions
                .filter(option => !this.ignoreDepartmentIds.includes(option.id) || this.departmentIds.includes(option.id))
        }
    }
}
</script>