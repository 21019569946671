<template>
    <div class='d-flex gap-6 pa-10 max-page-width' v-if='user' style='position: relative; z-index: 2;'>
        <div class='employee-template__profile'>
            <div class='employee-template__profile-header'>
                <div class='employee-template__profile-header-bg' />
                <div class='employee-template__profile-header-avatar mt-6'>
                    <avatar :employee-name='user.name' :src='avatarSrc' :size='80' />
                </div>
                <h3 class='mt-1'>{{ user.name }}</h3>
                <hra-button-modal
                    v-if='!dismissed'
                    :color='uiColor.primary' :text-width='buttonTextWidth.normal' no-background
                    modal-name='EmployeeChangeAvatarModal' :modal-props='{onChange}'
                    custom-class='mt-1'
                    :size='buttonSize.auto'
                >{{ $t('Keisti profilio nuotrauką') }}
                </hra-button-modal>
                <hr class='my-4' />
                <slot name='append-profile' />
                <div v-if='dismissData' class='pb-3 px-3 d-flex flex-column gap-3' style='width: 100%'>
                    <form-alert
                        type='error'
                        v-if='dismissed'
                        :text="$t('Darbuotojas buvo atleistas {deleteDate}', {deleteDate: dismissData.date})"
                    />
                    <template v-else>
                        <form-alert
                            v-if='isProfile'
                            type='info'
                            :text="$t('Būsite atleisti {deleteDate}', {deleteDate: dismissData.date})"
                        />
                        <form-alert
                            v-else
                            type='info'
                            :text="$t('Darbuotojas bus atleistas {deleteDate}', {deleteDate: dismissData.date})"
                        />
                    </template>
                    <form-alert type='info' v-if='dismissData.reason'>
                        <b>{{ dismissData.reason.article }}</b> {{ dismissData.reason.description }}
                    </form-alert>
                </div>
            </div>
            <div class='employee-template__profile-content'>
                <slot name='profile.content' />
            </div>
        </div>
        <div class='employee-template__details'>
            <slot name='panels' />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Avatar from '@/components/ui/Avatar'
import HraButtonModal from '@/components/ui/button/HraButtonModal'
import { dismissData, dismissDate, reason, waitToDismiss } from '@/domain/employee/store/getters'
import FormAlert from '@/components/ui/form/FormAlert'
import UiButton from '@/domain/components/UiButton.vue'
import customApi from '@/plugins/customApi'

export default {
    name: 'EmployeeTemplate',
    components: { UiButton, FormAlert, HraButtonModal, Avatar },
    computed: {
        ...mapGetters({
            dismissData: 'employeeStore/dismissData',
            dismissed: 'employeeStore/dismissed',
            user: 'employeeStore/user'
        }),
        avatarSrc() {
            return this.user.avatarSrc
        }
    },
    props: {
        isProfile: { type: Boolean, default: false }
    },
    methods: {
        async onChange(blob) {
            const tag = 'CHANGE_AVATAR_TAG'

            return await this.$store.dispatch('employeeStore/changeAvatar', {
                tag,
                userId: this.user.id,
                blob
            })
        }
    }
}
</script>