<template>
    <custom-dialog :max-width='920'>
        <template #title>{{ $t('Dokumento istorija') }}</template>
        <template #content>
            <v-row class='px-3'>
                <v-col cols='12' style='position: sticky; top: 0; z-index: 5;' class='white'>
                    <document-title-view
                        :title='item.title'
                        :due-at='item.dueAt'
                        icon='paper'
                    />
                    <div v-if='!!itemTypeText'><b>{{ $t('Dokumento tipas:') }}</b> {{ itemTypeText }}</div>
                </v-col>
                <v-col cols='12'>
                    <ui-table
                        :loading='loadingEmployees'
                        :loading-more='loadingMoreEmployees'
                        :visible-load-more='hasMoreEmployees'
                        :is-empty-table='isEmpty'
                        header-sticky
                        :header-sticky-top='89'
                        @clickLoadMore='handleLoadMoreEmployees'
                    >
                        <template #head>
                            <tr>
                                <th>
                                    <form-checkbox
                                        :is-checked='isSelectAllDocumentEmployeeIds'
                                        @change='handleSelectAllDocumentEmployees'
                                    />
                                </th>
                                <ui-table-head-filter
                                    :title='$t("Darbuotojas")'
                                    column='user'
                                    :sort-options='filter.user.options.sort'
                                    v-model='filter.user.value'
                                    @changeFilter='handleChangeFilter'
                                />
                                <ui-table-head-filter
                                    :title='$t("statusas")'
                                    column='status'
                                    :select-options='filter.status.options.select'
                                    v-model='filter.status.value'
                                    @changeFilter='handleChangeFilter'
                                />
                                <ui-table-head-filter
                                    :title='$t("Išsiųsta")'
                                    column='sentAt'
                                    :sort-options='filter.sentAt.options.sort'
                                    v-model='filter.sentAt.value'
                                    @changeFilter='handleChangeFilter'
                                />
                                <th>{{ $t('priminimas') }}</th>
                                <ui-table-head-filter
                                    :title='$t("Susipažinti iki")'
                                    column='dueAt'
                                    :sort-options='filter.dueAt.options.sort'
                                    v-model='filter.dueAt.value'
                                    @changeFilter='handleChangeFilter'
                                />
                            </tr>
                        </template>
                        <template #body>
                            <tr
                                v-for='(row, key) in rows'
                                :key='`row-id-${row.id}-key-${key}`'
                            >
                                <td>
                                    <form-checkbox
                                        :is-checked='selectedDocumentEmployeeIds.includes(row.id)'
                                        @change='handleSelectDocumentEmployee(row.id)'
                                    />
                                </td>
                                <td style='width: 270px; max-width: 270px;'>
                                    <document-table-employee
                                        :name='row.user.name'
                                        :duty-name='row.user.duty.name'
                                    />
                                </td>
                                <td style='width: 150px'>
                                    <document-sent-status-with-comment
                                        :status='row.status'
                                        :date='row.statusAt'
                                        :comment='row.comment'
                                    />
                                </td>
                                <td style='width: 120px'>{{ row.sentAt }}</td>
                                <td style='width: 120px'>{{ row.remindAt != null ? row.remindAt : '-' }}</td>
                                <td style='width: 120px'>{{ row.dueAt }}</td>
                            </tr>
                        </template>
                    </ui-table>
                </v-col>
            </v-row>
        </template>

        <template #footer>
            <ui-button prepend-icon='close' background='default' @click='close'>{{ $t('Uždaryti') }}</ui-button>
            <ui-button
                :loading='loadingSendRepeat'
                prepend-icon='send'
                background='primary'
                @click='handleRepeatSend'
            >{{ $t('Siųsti dar karta') }}</ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import dialogMixin from '@/mixins/dialogMixin'
import UiButton from '@/domain/components/UiButton.vue'
import DocxPreview from '@/domain/documents/components/DocxPreview.vue'
import ExcelPreview from '@/domain/documents/components/ExcelPreview.vue'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import CustomApi from '@/plugins/customApi'
import UiTable from '@/domain/components/uiTable/UiTable.vue'
import UiTableHeadFilter from '@/domain/components/uiTable/UiTableHeadFilter.vue'
import i18n from '@/plugins/i18n'
import UiIcon from '@/domain/components/UiIcon.vue'
import DocumentTableEmployee from '@/domain/documents/DocumentTableEmployee.vue'
import DocumentTitleView from '@/domain/documents/components/DocumentTitleView.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import modalMethodsMixin from '@/domain/mixins/modalMethodsMixin'
import { GLOBAL_TOAST_TAG } from '@/utils/default'
import DocumentSentStatusWithComment from '@/domain/documents/components/DocumentSentStatusWithComment.vue'

const customApi = CustomApi()
const statusTitles = {
    'new': i18n.t('Nepradėtas'),
    'pending': i18n.t('Laukiama'),
    'rejected': i18n.t('Atmesta'),
    'accepted': i18n.t('Priimta'),
    'cancelled': i18n.t('Atšaukta')
}

export default {
    name: 'DocumentHistoryModal',
    components: {
        DocumentSentStatusWithComment,
        FormCheckbox,
        DocumentTitleView,
        DocumentTableEmployee,
        UiIcon,
        UiTableHeadFilter,
        UiTable,
        UiButton,
        ExcelPreview,
        DocxPreview,
        CustomDialog
    },
    mixins: [dialogMixin, modalMethodsMixin],
    props: {
        item: { type: Object, required: true }
    },
    data() {
        return {
            loading: false,
            loadingSendRepeat: false,
            loadingEmployees: false,
            loadingMoreEmployees: false,
            rows: [],
            page: 1,
            limit: 30,
            hasMoreEmployees: false,
            selectedDocumentEmployeeIds: [],
            filter: {
                user: {
                    options: {
                        sort: [
                            { value: 'asc', label: 'A-Z' },
                            { value: 'desc', label: 'Z-A' }
                        ]
                    },
                    value: {
                        sort: null
                    }
                },
                sentAt: {
                    options: {
                        sort: [
                            { value: 'desc', label: this.$t('Naujausi viršuje') },
                            { value: 'asc', label: this.$t('Seniausi viršuje') }
                        ]
                    },
                    value: {
                        sort: 'desc'
                    }
                },
                dueAt: {
                    options: {
                        sort: [
                            { value: 'desc', label: this.$t('Naujausi viršuje') },
                            { value: 'asc', label: this.$t('Seniausi viršuje') }
                        ]
                    },
                    value: {
                        sort: null
                    }
                },
                status: {
                    options: {
                        select: Object.entries(statusTitles).map(([value, label]) => ({ value, label }))
                    },
                    value: {
                        selected: []
                    }
                }
            }
        }
    },
    computed: {
        isEmpty() {
            return !this.loadingMoreEmployees && !this.loading && this.rows.length === 0
        },
        itemTypeText() {
            return this.item?.type?.name ?? null
        },
        isSelectAllDocumentEmployeeIds() {
            return this.rows.length > 0 && this.selectedDocumentEmployeeIds.length === this.rows.length
        }
    },
    mounted() {
        this.firstLoad()
    },
    methods: {
        handleSelectDocumentEmployee(id) {
            const index = this.selectedDocumentEmployeeIds.indexOf(id)
            if (index === -1) {
                this.selectedDocumentEmployeeIds.push(id)
                return
            }

            this.selectedDocumentEmployeeIds.splice(index, 1)
        },
        handleSelectAllDocumentEmployees() {
            if (this.isSelectAllDocumentEmployeeIds) {
                this.selectedDocumentEmployeeIds = []
                return
            }

            this.selectedDocumentEmployeeIds = this.rows.map(({ id }) => id)
        },
        getFetchParams(page = null) {
            let params = {
                limit: this.limit,
                page: page ?? 1,
                sortBy: []
            }

            for (const [column, payload] of Object.entries(this.filter)) {
                if (payload.value.sort != null) {
                    params.sortBy.push(`${column},${payload.value.sort}`)
                }

                if ((payload.value.selected?.length ?? 0) > 0) {
                    params[column] = payload.value.selected
                }
            }

            return params
        },
        handleChangeFilter() {
            this.fetchSentUsers()
        },
        async handleLoadMoreEmployees() {
            this.loadingMoreEmployees = true
            await this.fetchSentUsers(this.page + 1)
            this.loadingMoreEmployees = false
        },
        async firstLoad() {
            this.loading = true
            await this.fetchSentUsers()
            this.loading = false
        },
        async fetchSentUsers(page = null) {
            try {
                const {
                    data: {
                        items, hasMore, pagination: { currentPage }
                    }
                } = await customApi.factories.documents.sentUsers.fetchByDocumentId(this.item.id, this.getFetchParams(page))

                this.page = currentPage
                this.hasMoreEmployees = hasMore
                this.rows = page == null ? items : [...this.rows, ...items]
            } catch (e) {
                console.error(e)
            }
        },
        async handleRepeatSend() {
            if (this.selectedDocumentEmployeeIds.length === 0) {
                await this.$store.dispatch('messagesStore/showErrorMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Nepasirinktas nei vienas darbuotojas')
                })
                return
            }

            const userIds = this.rows
                .filter(({id}) => this.selectedDocumentEmployeeIds.includes(id))
                .map(({userId}) => userId)

            this.onOpenModal(
                'DocumentSendModal',
                {
                    documents: [this.item],
                    userIds,
                    step: 2
                },
                {
                    destroyed: () => {
                        this.selectedDocumentEmployeeIds = []
                        this.firstLoad()
                    }
                }
            )
        }
    }
}
</script>