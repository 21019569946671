<template>
  <li :class="{active: isActive}" @click="onClick">{{ title }}</li>
</template>

<script>
export default {
  name: "DtvListLinkItem",
  props: {
    title: {type: String, required: true},
    page: {type: Object, default: () => ({})},
  },
  inject: ["viewPage"],
  computed: {
    isActive() {
      if (this.page.id == null) {
        return false;
      }

      return this.page.id === this.$store.getters["dtvStore/activePageId"];
    }
  },
  methods: {
    onClick() {
      this.$store.dispatch("dtvStore/changePage", this.page.id ?? null)
      this.viewPage(this.page);
    },
  },
}
</script>