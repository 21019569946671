import { render, staticRenderFns } from "./VacationRequestApprovals.vue?vue&type=template&id=4f3d8b9a&scoped=true&"
import script from "./VacationRequestApprovals.vue?vue&type=script&lang=js&"
export * from "./VacationRequestApprovals.vue?vue&type=script&lang=js&"
import style0 from "./VacationRequestApprovals.vue?vue&type=style&index=0&id=4f3d8b9a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f3d8b9a",
  null
  
)

export default component.exports