<template>
    <li :class='classes' v-if='visible'>
        <router-link v-if="this.type === 'navItem'" :to='to'>
            <i :class='{[`icon-${icon}`]: true, "is-test": isTest}' />
            <span>{{ $t(title) }}</span>
<!--            <div v-if='isTest' class='nav-is-test'>TEST</div>-->
        </router-link>
    </li>
</template>

<script>
import buttonPermissionsMixin from '@/mixins/buttonPermissionsMixin'

export default {
    name: 'SidebarNavigation',
    mixins: [
        buttonPermissionsMixin
    ],
    props: {
        title: { type: String },
        type: { type: String },
        to: { type: Object },
        icon: { type: String },
        isTest: { type: Boolean }
    },
    data() {
        return {
            active: false
        }
    },
    computed: {
        classes() {
            return {
                active: this.$route.name === this.to?.name,
                divider: this.type === 'navDivider'
            }
        },
        visible() {
            if (this.type === 'navItem') {
                return this.hasPermissions
            }

            return true
        }
    }
}
</script>
