<template>
    <v-breadcrumbs :items='steps' class='document-wizard'>
        <template #item='{item}'>
            <v-breadcrumbs-item @click='handleSelectStep(item)'>
                <div class='document-wizard-item'>
                    <div
                        class='document-wizard-item__step'
                        :class='{
                            "document-wizard-item__step_current": item.current,
                            "document-wizard-item__step_completed": item.complete
                        }'
                    >
                        <div v-if='item.complete' class='icon-ok'></div>
                        <div v-else>{{ item.number }}</div>
                    </div>
                    <div class='document-wizard-item__title'>{{ item.title }}</div>
                </div>
            </v-breadcrumbs-item>
        </template>
        <template v-slot:divider>
            <ui-icon>path</ui-icon>
        </template>
    </v-breadcrumbs>
</template>

<script>
import UiIcon from '@/domain/components/UiIcon.vue'

export default {
    name: 'StepsWizard',
    components: { UiIcon },
    inject: ['storeNamespace'],
    computed: {
        steps() {
            return this.$store.getters[`${this.storeNamespace}/steps`]
        }
    },
    methods: {
        handleSelectStep(step) {
            if (!step.available) {
                this.showErrorInRootToast(this.$t('Negalimas žingsnis'))
                return
            }

            this.$store.dispatch(`${this.storeNamespace}/setStep`, step.key)
        }
    }
}
</script>

<style scoped lang='scss'>
.document-wizard {
    padding-left: 0;
}

.document-wizard-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    cursor: pointer;

    &__step {
        border-radius: 50%;
        border: 1px solid #DAD5E9;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;

        div.icon-ok {
            margin-top: 2px;
            //margin-left: 1px;
            font-size: 0.7rem;
        }

        &_current {
            border-color: #8E2574;
            color: #8E2574;
        }

        &_completed {
            background: #8E2574;
            border-color: #8E2574;
            color: #FFFFFF;
        }
    }

    &__title {
        font-weight: 500;
        font-size: 1.1rem;
    }
}
</style>