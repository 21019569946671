// NPM
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";

// PROJECT
import store from "@/store";
import Auth from "@/api/auth";
import User from "@/api/user";
import Employee from "@/api/employee";
import Department from "@/api/department";
import Calendar from "@/api/calendar";
import Widgets from "@/api/widgets";
import Setup from "@/api/setup";
import Settings from "@/api/settings";
import Fragment from 'vue-fragment';

//uzkomentuota kad nereiketu kankintis TODO remove later
// const axiosInstance = axios.create({
//   baseURL: 'http://localhost:8222'
// });

Vue.use(VueAxios, axios);
Vue.use(Fragment.Plugin);

const changeUrlToName = (url) => url.split("/").map(name => name.charAt(0).toUpperCase() + name.slice(1)).join("");

Vue.axios.interceptors.request.use(
  config => {
    store.dispatch("global/addLoader", config?.requestName || "init");
    return config;
  }
);

Vue.axios.interceptors.response.use(
  response => {
    const name = response.config?.requestName || "init";
    const message = response.data.message || null;

    message && store.dispatch("global/addSuccessMessage", {name, message});
    store.dispatch("global/removeLoader", name);

    return response.data;
  },
  error => {
    let code = null;
    let name = "init";
    let message = "Server error!";
    let data = {};

    if (error.response) {
      if ([401, 419].indexOf(error.response.status) > -1) {
        window.location.href = "/auth";
        return;
      }

      code = error.response.status;
      name = error.response.config?.requestName || "init";

      if (error.response.data.error) {
        message = error.response.data.error;
      }

      if (error.response.data.message) {
        message = error.response.data.message;
      }

      if (error.response.data) {
        data = error.response.data;
      }

      store.dispatch("global/removeLoader", name);
    }

    store.dispatch("global/addErrorMessage", {name, message});

    return Promise.reject({code, name, message, data});
  }
);

// Initialize all or API factories
const factories = {
  setup: Setup(Vue.axios),
  auth: Auth(Vue.axios),
  user: User(Vue.axios),
  employee: Employee(Vue.axios),
  department: Department(Vue.axios),
  calendar: Calendar(Vue.axios),
  widgets: Widgets(Vue.axios),
  settings: Settings(Vue.axios),
};

Vue.prototype.$api = factories;

export default factories;