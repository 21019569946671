<template>
	<v-navigation-drawer
		v-model="drawer"
		app
		width="84"
		stateless
	>
		<div
			class="sidebar">
			<div class="sidebar__header">
				<img src="@/assets/images/logo.png" alt="logo"/>
			</div>
			<ul class="sidebar__nav">
				<SidebarNavItem
					v-for="(menu, key) in sidebarMenu"
					:key="key"
					v-bind="menu"
				/>
			</ul>
		</div>
	</v-navigation-drawer>
</template>

<script>
import SidebarNavItem from "@/components/ui/sidebar/SidebarNavItem.vue";
import {permissionsPageEmployees, permissionsPageEmployeesArchive} from "@/utils/permissions";
import {mapGetters} from "vuex";

export default {
	components: {
		SidebarNavItem,
	},
	data: () => ({
		permissionsPageEmployees,
		permissionsPageEmployeesArchive,
		logo: "",
		drawer: true,
	}),
	computed: {
		...mapGetters({
			sidebarMenu: "global/sidebarMenu",
		})
	}
};
</script>
