import Vue from 'vue'
import { mapGroup } from '@/domain/documents/finder/store/mappers'

export default ({
    ['groups/create']({state}, payload) {
        if (state.groupsFolderId !== payload.documentGroupFolderId) {
            return
        }

        state.groups.push(mapGroup(payload))
    },
    ['groups/update']({state}, payload) {
        const index = state.groups.findIndex(({id}) => id === payload.id)
        if (index === -1) {
            return
        }
        Vue.set(state.groups, index, mapGroup(payload))
    },
    ['groups/delete']({state}, payload) {
        const index = state.groups.findIndex(({id}) => id === payload)
        if (index === -1) {
            return
        }
        state.groups.splice(index, 1)
    }
})