<template>
    <div class='d-flex flex-column gap-2'>
        <form-checkbox
            :is-checked.sync='config.enabled'
            :disabled='!isEdit'
        >
            <span class='pl-2'>{{ $t("setting.module.SettingHolidayAllRemindEventHolidayActivity.form.enabled") }}</span>
        </form-checkbox>
        <employee-multi-select
            v-if='config.enabled'
            :label='$t("setting.module.SettingHolidayAllRemindEventHolidayActivity.form.adminIds")'
            only-admins
            v-model='config.adminIds'
            rules='required'
            :disabled='!isEdit'
        />
    </div>
</template>

<script>
import MultilineSelectField from '@/domain/components/MultilineSelectField.vue'
import SelectField from '@/domain/fields/SelectField.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import TextField from '@/domain/fields/TextField.vue'
import EmployeeMultiSelect from '@/domain/fields/EmployeeMultiSelect.vue'

export default {
    name: 'SettingHolidayAllRemindEventHolidayActivityConfigForm',
    components: { EmployeeMultiSelect, TextField, FormCheckbox, SelectField, MultilineSelectField },
    props: {
        isEdit: { type: Boolean, default: false },
        config: { type: Object, default: () => ({}) }
    },
    watch: {
        'config.enabled': {
            handler(enabled) {
                if (!enabled) {
                    this.config.adminIds = []
                }
            }
        }
    }
}
</script>