<template>
    <div class="schedule-tabs">
        <div class="schedule-tabs__items">
            <schedule-sidebar-tabs-item
                v-for="(tab, index) in tabs"
                :key="index"
                :current-tab="currentTab"
                :tab-key="tab.key"
                :label="tab.label"
                @change-tab="onChangeTab"
            />
        </div>
        <div class="schedule-tabs__list" v-if='isAdmin'>
            <div v-if='currentTab === SCHEDULE_TAB_EDIT_SCHEDULE'>
                <schedule-time-interval
                    v-for='timeInterval in timeIntervals'
                    :key='timeInterval.id'
                    :time-interval='timeInterval'
                />
                <div v-if="timeIntervals.length === 0" class="schedule__no-data pa-5">{{ $t('Pridėtų darbo laikų nėra') }}</div>
            </div>
            <div v-if='currentTab === SCHEDULE_TAB_EDIT_TIMETABLE'>
                <schedule-timetable-interval
                    v-for='timetableInterval in timetableIntervals'
                    :key='timetableInterval.id'
                    :timetable-interval='timetableInterval'
                />
                <div v-if="timetableIntervals.length === 0" class="schedule__no-data pa-5">{{ $t('Pridėtų darbo vietos laikų nėra') }}</div>
            </div>
            <div v-if='currentTab === SCHEDULE_TAB_EDIT_BREAKS'>
                <schedule-break-line
                    v-for='scheduleBreak in breaks'
                    :key='scheduleBreak.id'
                    :schedule-break='scheduleBreak'
                />
                <div v-if="breaks.length === 0" class="schedule__no-data pa-5">{{ $t('Pridėtų pertraukų nėra') }}</div>
            </div>
            <ui-button
                v-if='currentTab === SCHEDULE_TAB_EDIT_SCHEDULE'
                :modal='{name: "CreateScheduleTimeIntervalModal"}'
                size='normal'
                background='primary-dark'
                prepend-icon='plus'
            />
            <ui-button
                v-if='currentTab === SCHEDULE_TAB_EDIT_TIMETABLE'
                :modal='{name: "CreateScheduleTimetableIntervalModal"}'
                size='normal'
                background='primary-dark'
                prepend-icon='plus'
            />
            <ui-button
                v-if='currentTab === SCHEDULE_TAB_EDIT_BREAKS'
                :modal='{name: "ScheduleBreakModal"}'
                size='normal'
                background='primary-dark'
                prepend-icon='plus'
            />
        </div>
    </div>
</template>

<script>
import ScheduleSidebar from '@/domain/scheduler/components/ScheduleSidebar.vue'
import UiButton from '@/domain/components/UiButton.vue'
import ScheduleTimeInterval from '@/domain/scheduler/components/ScheduleTimeInterval.vue'
import ScheduleTimetableInterval from '@/domain/scheduler/components/ScheduleTimetableInterval.vue'
import ScheduleBreakLine from '@/domain/scheduler/components/ScheduleBreakLine.vue'
import ScheduleSidebarTabsItem from '@/domain/scheduler/components/sidebars/ScheduleSidebarTabsItem.vue'
import {
    SCHEDULE_TAB_EDIT_BREAKS,
    SCHEDULE_TAB_EDIT_SCHEDULE,
    SCHEDULE_TAB_EDIT_TIMETABLE,
} from '@/domain/scheduler/store/state'
import { mapGetters } from 'vuex'
import {
    permissionsButtonEmployeeCreate,
    permissionsButtonEmployeeDelete,
    permissionsButtonEmployeeEdit
} from '@/utils/permissions'
export default {
    name: 'ScheduleSidebarTabs',
    components: {
        ScheduleSidebar,
        UiButton,
        ScheduleTimeInterval,
        ScheduleTimetableInterval,
        ScheduleBreakLine,
        ScheduleSidebarTabsItem,
    },
    data() {
        return {
            SCHEDULE_TAB_EDIT_SCHEDULE,
            SCHEDULE_TAB_EDIT_TIMETABLE,
            SCHEDULE_TAB_EDIT_BREAKS,
            // activeTab: 'work-times',
            tabs: [
                { key: 'SCHEDULE_TAB_EDIT_SCHEDULE', label: 'Darbo laikai' },
                { key: 'SCHEDULE_TAB_EDIT_TIMETABLE', label: 'Vietos laikai' },
                { key: 'SCHEDULE_TAB_EDIT_BREAKS', label: 'Pertraukos' },
            ],
        }
    },
    inject: ['eventRowHeight'],
    computed: {
        ...mapGetters({
            currentTab: 'schedulerStore/currentTab',
            timeIntervals: 'schedulerStore/timeIntervals',
            timetableIntervals: 'schedulerStore/timetableIntervals',
            breaks: 'schedulerStore/breaks',
        }),
        isAdmin() {
            return this.hasPermissionTo([
                ...permissionsButtonEmployeeCreate,
                ...permissionsButtonEmployeeEdit,
                ...permissionsButtonEmployeeDelete
            ])
        },
    },
    methods: {
        onChangeTab(tabName) {
            this.$store.dispatch('schedulerStore/changeCurrentTab', tabName)
        },
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/core/colors';

.schedule-tabs {
    display: flex;
    flex-direction: column;
    border: 1px solid $schedule-border-primary;
    position: relative;

    &__items {
        display: inline-flex;
        justify-content: center;
        padding: 0;
        background-color: $white;
        border-bottom: 0;
        border-left: 0;
    }

    &__list {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
        height: 100%;
        .ui-button {
            align-self: end;
            margin: 0 20px 20px 0;
            padding: 15px 17px;
        }
    }

    &__no-data {
        padding: 20px;
    }
}
</style>