<template>
  <li :class="{expand: isVisible || isActive}" v-on="$listeners">
    <span @click="toggle" v-html="title" />
    <ui-icon @click="toggle">{{ icon }}</ui-icon>
    <slot v-if="isVisible" />
  </li>
</template>

<script>

import DtvListTagItem from "@/domain/dtv/components/DtvListTabItem.vue";
import UiIcon from "@/domain/components/UiIcon.vue";

export default {
  name: "DtvListItem",
  components: {UiIcon, DtvListTagItem},
  props: {
    title: {type: String, required: true},
    child: {type: Array, default: () => []},
    defaultExpand: {type: Boolean, default: false},
    isLink: {type: Boolean, default: false},
    isActive: {type: Boolean, default: false},
  },
  data() {
    return {
      isVisible: this.defaultExpand,
    };
  },
  computed: {
    icon() {
      if (this.isActive) {
        return "arrow-right";
      }

      return this.isVisible ? "up" : "down";
    },
  },
  methods: {
    toggle() {
      if (this.isLink) {
        return;
      }

      this.isVisible = !this.isVisible;
    },
  },
}
</script>