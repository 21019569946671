import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters({
            employeesAllUserOptions: 'filtersStore/employees/employeesAllUserOptions',
            employeesAllDepartmentOptions: 'filtersStore/employees/employeesAllDepartmentOptions',
            employeesAllDutyOptions: 'filtersStore/employees/employeesAllDutyOptions',
            employeesAllUserTimeCardNumberOptions: 'filtersStore/employees/employeesAllUserTimeCardNumberOptions'
        })
    },
    mounted() {
        this.$store.dispatch('filtersStore/employees/fetchEmployees', {
            type: 'all'
        })
    }
}