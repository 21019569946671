import AppLayout from '@/layouts/AppLayout'
import DepartmentVacationPage from '@/domain/departmentVacations/pages'

export const index = 'DepartmentVacationPage'

export default [
    {
        path: '/department/vacations',
        component: AppLayout,
        children: [
            { name: index, path: '', component: DepartmentVacationPage }
        ]
    }
]