<template>
    <div class='page max-page-width'>
        <v-row class='mx-6'>
            <v-col cols='12'>
                <div class='d-flex align-end my-3 gap-3'>
                    <v-spacer />
                    <ui-button background='primary' @click='onGetVacationsReport'>{{ $t('Atsiųsti .xlsx') }}</ui-button>
                </div>
            </v-col>
            <v-col cols='12' class='pb-3'>
                <ui-table
                    :loading='loading'
                    :is-empty-table='rows.length === 0'
                >
                    <template #empty-table>
                        <message-alert
                            id='table-alert-empty'
                            :message='$t("Įrašų sistemoje nerasta.")'
                            type='info'
                        />
                    </template>
                    <template #head>
                        <tr style='position: sticky; top: 0; z-index: 3;'>
                            <table-header-user-filter
                                :filter='filter.userId'
                                @changeFilter='handleChangeFilter'
                            />
                            <table-header-user-time-card-number-filter
                                :filter='filter.timeCardNumber'
                                @changeFilter='handleChangeFilter'
                            />
                            <th style='min-width: 150px'>{{ $t('Asmens kodas') }}</th>
                            <table-header-department-filter
                                :filter='filter.departmentId'
                                @changeFilter='handleChangeFilter'
                            />
                            <table-header-duty-filter
                                :filter='filter.dutyId'
                                @changeFilter='handleChangeFilter'
                            />
                            <ui-table-head-filter
                                :title='$t("Pažymėjimo tipas")'
                                column='type'
                                :value='filter.type'
                                :select-options='typeOptions'
                                :min-width='150'
                                @changeFilter='handleChangeFilter'
                            />
                            <ui-table-head-filter
                                :title='$t("Nedarbingumo priežastis")'
                                column='sickType'
                                :value='filter.sickType'
                                :select-options='sickTypeOptions'
                                :min-width='150'
                                @changeFilter='handleChangeFilter'
                            />
                            <table-header-date-filter
                                :title='$t("Data nuo")'
                                column='start'
                                :filter='filter.start'
                                @changeFilter='handleChangeFilter'
                            />
                            <table-header-date-filter
                                :title='$t("Data iki")'
                                column='end'
                                :filter='filter.end'
                                :min-date='filter.start.date'
                                @changeFilter='handleChangeFilter'
                            />
                            <ui-table-head-filter
                                :title='$t("Statusas")'
                                column='status'
                                :value='filter.status'
                                :select-options='eventStatusOptions'
                                :min-width='100'
                                @changeFilter='handleChangeFilter'
                            />
                            <table-header-date-filter
                                :title='$t("Statuso data")'
                                column='statusDate'
                                interval
                                :filter='filter.statusDate'
                                :min-width='150'
                                @changeFilter='handleChangeFilter'
                            />
                            <th style='min-width: 80px'>{{ $t('SD serija') }}</th>
                            <th style='min-width: 150px'>{{ $t('SD numeris') }}</th>
                            <th style='min-width: 150px'>{{ $t('Pažymėjimo serija') }}</th>
                            <th style='min-width: 150px'>{{ $t('Pažymėjimo numeris') }}</th>
                            <th style='min-width: 150px'>{{ $t('Ar užpildyta draudėjui info') }}</th>
                            <th style='min-width: 150px'>{{ $t('Ar reikia draudejui mokėti') }}</th>
                            <th style='min-width: 150px'>{{ $t('Ar pažymėjimas panaikintas') }}</th>
                            <th style='min-width: 150px'>{{ $t('Pateikė NPSD') }}</th>
                        </tr>
                    </template>

                    <template #body>
                        <tr v-for='(row, key) in rows' :key='key'>
                            <td style='min-width: 150px'>{{ row.user.name }}</td>
                            <td style='min-width: 150px'>{{ row.user.timeCardNumber }}</td>
                            <td style='min-width: 150px'>{{ row.user.personalCode }}</td>
                            <td style='min-width: 150px'>{{ row.user.department.name }}</td>
                            <td style='min-width: 150px'>{{ row.user.duty.name }}</td>
                            <td style='min-width: 150px'>{{ row.sickLeave.type }}</td>
                            <td style='min-width: 150px'>{{ row.sickLeave.sickType }}</td>
                            <td style='min-width: 100px'>{{ row.start }}</td>
                            <td style='min-width: 100px'>{{ row.end }}</td>
                            <td style='min-width: 100px'><event-status :status-text='row.currentStatusText' :status='row.currentStatus' /></td>
                            <td style='min-width: 150px'>{{ row.status.date }}</td>
                            <td style='min-width: 80px'>{{ row.sickLeave.personalSDSeries }}</td>
                            <td style='min-width: 150px'>{{ row.sickLeave.personalSDNumber }}</td>
                            <td style='min-width: 150px'>{{ row.sickLeave.noteSeries }}</td>
                            <td style='min-width: 150px'>{{ row.sickLeave.noteNumber }}</td>
                            <td style='min-width: 150px'>{{ row.sickLeave.filledInPolicyholderInfo }}</td>
                            <td style='min-width: 150px'>{{ row.sickLeave.policyholderNeedsToPay }}</td>
                            <td style='min-width: 150px'>{{ row.sickLeave.noteDeleted }}</td>
                            <td style='min-width: 150px'>{{ row.sickLeave.submittedNPSD }}</td>
                        </tr>
                    </template>
                </ui-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import moment from 'moment/moment'
import DatepickerField from '@/domain/fields/DatepickerField'
import SelectField from '@/domain/fields/SelectField'
import { eventHolidayType, eventHolidayTypes } from '@/utils/default'
import customApi from '@/plugins/customApi'
import UiButton from '@/domain/components/UiButton.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import TableCheckbox from '@/components/ui/table.old/TableCheckbox.vue'
import UiTable from '@/domain/components/uiTable/UiTable.vue'
import MessageAlert from '@/domain/messages/components/MessageAlert.vue'
import TableHeaderUserFilter from '@/domain/components/uiTable/filters/TableHeaderUserFilter.vue'
import TableHeaderDepartmentFilter from '@/domain/components/uiTable/filters/TableHeaderDepartmentFilter.vue'
import TableHeaderDutyFilter from '@/domain/components/uiTable/filters/TableHeaderDutyFilter.vue'
import TableHeaderDateFilter from '@/domain/components/uiTable/filters/TableHeaderDateFilter.vue'
import UiTableHeadFilter from '@/domain/components/uiTable/UiTableHeadFilter.vue'
import TableHeaderUserTimeCardNumberFilter from '@/domain/components/uiTable/filters/TableHeaderUserTimeCardFilter.vue'
import EventStatus from '@/domain/components/EventStatus.vue'

export default {
    name: 'SickLeavesReportPage',
    components: {
        EventStatus,
        TableHeaderUserTimeCardNumberFilter,
        UiTableHeadFilter,
        TableHeaderDateFilter,
        TableHeaderDutyFilter,
        TableHeaderDepartmentFilter,
        TableHeaderUserFilter,
        MessageAlert,
        UiTable,
        TableCheckbox,
        FormCheckbox, UiButton, SelectField, DatepickerField
    },
    data() {
        return {
            loading: false,
            message: null,
            eventStatusOptions: [
                { value: 'approved', label: this.$t('Įkelta') },
                { value: 'aborted', label: this.$t('Panaikinta') }
            ],
            typeOptions: [],
            sickTypeOptions: [],
            filter: {
                userId: {
                    selected: [],
                    sort: null
                },
                timeCardNumber: {
                    selected: [],
                    sort: null
                },
                departmentId: {
                    selected: [],
                    sort: null
                },
                dutyId: {
                    selected: [],
                    sort: null
                },
                start: {
                    date: null,
                    sort: null
                },
                end: {
                    date: null,
                    sort: null
                },
                type: {
                    selected: []
                },
                sickType: {
                    selected: []
                },
                status: {
                    selected: []
                },
                statusDate: {
                    from: null,
                    to: null,
                    sort: 'desc'
                }
            },
            rows: []
        }
    },
    mounted() {
        this.fetchTypes()
        this.fetchReport()
    },
    methods: {
        async fetchTypes() {
            try {
                const { data } = await customApi().factories.events.sickLeaves.fetchTypes()
                this.typeOptions = data.types?.map(value => ({value, label: value})) ?? []
                this.sickTypeOptions = data.sickTypes?.map(value => ({value, label: value})) ?? []
            } catch (e) {
                console.error(e)
            }
        },
        handleChangeFilter(column, filter) {
            if (column === 'userId') {
                this.$set(this.filter, 'departmentId', { selected: [], sort: null })
                this.$set(this.filter, 'dutyId', { selected: [], sort: null })
                this.$set(this.filter, 'timeCardNumber', { selected: [], sort: null })
            }

            if (column === 'timeCardNumber') {
                this.$set(this.filter, 'userId', { selected: [], sort: null })
                this.$set(this.filter, 'dutyId', { selected: [], sort: null })
                this.$set(this.filter, 'departmentId', { selected: [], sort: null })
            }

            if (column === 'departmentId') {
                this.$set(this.filter, 'userId', { selected: [], sort: null })
                this.$set(this.filter, 'dutyId', { selected: [], sort: null })
                this.$set(this.filter, 'timeCardNumber', { selected: [], sort: null })
            }

            if (column === 'dutyId') {
                this.$set(this.filter, 'userId', { selected: [], sort: null })
                this.$set(this.filter, 'departmentId', { selected: [], sort: null })
                this.$set(this.filter, 'timeCardNumber', { selected: [], sort: null })
            }

            if (column === 'start') {
                if (this.filter.end.date != null && this.filter.end.date < filter.date) {
                    this.$set(this.filter, 'end', { date: null, sort: null })
                }
            }

            this.$set(this.filter, column, filter)
            this.fetchReport()
        },
        booleanToText(value) {
            if (value) {
                return this.$t('Taip')
            }

            return this.$t('Ne')
        },
        getFetchParams() {
            let params = {
                sortBy: []
            }

            for (const [column, value] of Object.entries(this.filter)) {
                if (value.sort != null) {
                    params.sortBy.push(`${column},${value.sort}`)
                }

                if ((value.selected?.length ?? 0) > 0) {
                    params[column] = value.selected
                }

                if (value.date != null) {
                    params[column] = value.date
                }

                if (value.from != null) {
                    params[`${column}From`] = value.from
                }

                if (value.to != null) {
                    params[`${column}To`] = value.to
                }
            }

            return params
        },
        getFetchParamsToDownload() {
            let params = this.getFetchParams()
            params.page = this.page

            return Object.entries(params)
                .map(([key, value]) => {
                    if (Array.isArray(value)) {
                        return value.map(v => `${key}[]=${v}`).join('&')
                    }

                    return `${key}=${value}`
                })
                .join('&')
        },
        async fetchReport() {
            this.loading = true

            try {
                const { data } = await customApi().factories.events.sickLeaves.fetchReport(this.getFetchParams())

                if (data.length === 0) {
                    this.rows = []
                    return
                }

                this.rows = data.map(row => ({
                    ...row,
                    sickLeave: {
                        ...row.sickLeave,
                        filledInPolicyholderInfo: this.booleanToText(row.sickLeave.filledInPolicyholderInfo),
                        policyholderNeedsToPay: this.booleanToText(row.sickLeave.policyholderNeedsToPay),
                        noteDeleted: this.booleanToText(row.sickLeave.noteDeleted),
                        submittedNPSD: this.booleanToText(row.sickLeave.submittedNPSD)
                    }
                }))
            } catch (e) {
                this.rows = []
            } finally {
                this.loading = false
            }
        },
        onGetVacationsReport() {
            window.location.href = `${window.location.origin}/api/events/sick-leaves/report/download?${this.getFetchParamsToDownload()}`
        }
    }
}
</script>