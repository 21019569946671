export default {
    props: {
        modal: { type: Object, default: null },
        confirm: { type: Object, default: null }
    },
    methods: {
        onOpenModal() {
            if (this.loading) {
                return false
            }

            this.modal != null && this.$store.dispatch('modals/open', this.modal)
            this.confirm != null && this.$store.dispatch('modals/confirm', this.confirm)
        }
    }
}