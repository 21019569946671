<template>
    <div class='page max-page-width'>
        <v-row class='mx-6'>
            <v-col cols='12'>
                <div class='d-flex align-end my-3 gap-3'>
                    <datepicker-field :label="$t('Data nuo')" v-model='fromDate' />
                    <datepicker-field :label="$t('Data iki')" v-model='toDate' />
                    <v-spacer />
                    <ui-button background='primary' @click='onGetVacationsReport'>{{ $t('Atsiųsti .xlsx') }}</ui-button>
                </div>
            </v-col>
            <v-col cols='12' class='pb-3'>
                <ui-table
                    :loading='loading'
                    :is-empty-table='rows.length === 0'
                >
                    <template #empty-table>
                        <message-alert
                            id='table-alert-empty'
                            :message='$t("Įrašų sistemoje nerasta.")'
                            type='info'
                        />
                    </template>
                    <template #head>
                        <tr style='position: sticky; top: 0; z-index: 3;'>
                            <th>{{ $t('Vardas Pavardė') }}</th>
                            <th>{{ $t('Tabelio nr.') }}</th>
                            <th>{{ $t('Skyrius') }}</th>
                            <th>{{ $t('Pareigos') }}</th>
                            <th :style='{ minWidth: "150px" }'>{{ $t('Pirma diena') }}</th>
                            <th :style='{ minWidth: "150px" }'>{{ $t('Paskutinė diena') }}</th>
                            <th :style='{ minWidth: "150px" }'>{{ $t('Statusas') }}</th>
                            <th>{{ $t('Rūšis') }}</th>
                            <th>{{ $t('Apmokėjimas') }}</th>
                        </tr>
                    </template>

                    <template #body>
                        <tr v-for='(row, key) in rows' :key='key'>
                            <td>{{ row.user.name }}</td>
                            <td>{{ row.user.timeCardNumber }}</td>
                            <td>{{ row.user.department.name }}</td>
                            <td>{{ row.user.duty.name }}</td>
                            <td>{{ row.start }}</td>
                            <td>{{ row.end }}</td>
                            <td>
                                <div class='d-flex gap-3 align-center'>
                                    <div v-if='row.previousStatus'>
                                        <event-status
                                            :status='row.previousStatus.status'
                                            :status-text='row.previousStatus.text'
                                        />
                                    </div>
                                    <ui-icon v-if='row.previousStatus'>arrow-right</ui-icon>
                                    <div>
                                        <event-status
                                            :status='row.currentStatus.status'
                                            :status-text='row.currentStatus.text'
                                        />
                                    </div>
                                </div>
                            </td>
                            <td>{{ row.type }}</td>
                            <td>{{ row.holiday.payTypeText }}</td>
                        </tr>
                    </template>
                </ui-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import moment from 'moment/moment'
import DatepickerField from '@/domain/fields/DatepickerField'
import SelectField from '@/domain/fields/SelectField'
import customApi from '@/plugins/customApi'
import UiButton from '@/domain/components/UiButton.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import TableCheckbox from '@/components/ui/table.old/TableCheckbox.vue'
import UiTable from '@/domain/components/uiTable/UiTable.vue'
import MessageAlert from '@/domain/messages/components/MessageAlert.vue'
import UiTableHeadFilter from '@/domain/components/uiTable/UiTableHeadFilter.vue'
import TableHeaderUserTimeCardNumberFilter from '@/domain/components/uiTable/filters/TableHeaderUserTimeCardFilter.vue'
import TableHeaderDutyFilter from '@/domain/components/uiTable/filters/TableHeaderDutyFilter.vue'
import TableHeaderUserFilter from '@/domain/components/uiTable/filters/TableHeaderUserFilter.vue'
import TableHeaderDateFilter from '@/domain/components/uiTable/filters/TableHeaderDateFilter.vue'
import TableHeaderDepartmentFilter from '@/domain/components/uiTable/filters/TableHeaderDepartmentFilter.vue'
import EventStatus from '@/domain/components/EventStatus.vue'
import { EVENT_BUSINESS_TRIP_EMPLOYEE, EVENT_BUSINESS_TRIP_LEADER } from '@/utils/default'
import TableHeaderCountryFilter from '@/domain/components/uiTable/filters/TableHeaderCountryFilter.vue'
import UiIcon from '@/domain/components/UiIcon.vue'

export default {
    name: 'VacationActivityReportPage',
    components: {
        UiIcon,
        TableHeaderCountryFilter,
        EventStatus,
        TableHeaderUserTimeCardNumberFilter,
        UiTableHeadFilter,
        TableHeaderDateFilter,
        TableHeaderDutyFilter,
        TableHeaderDepartmentFilter,
        TableHeaderUserFilter,
        MessageAlert,
        UiTable,
        TableCheckbox,
        FormCheckbox, UiButton, SelectField, DatepickerField
    },
    data() {
        return {
            loading: false,
            message: null,
            fromDate: moment().format('YYYY-MM-DD'),
            toDate: moment().format('YYYY-MM-DD'),
            filter: {},
            rows: []
        }
    },
    watch: {
        fromDate() {
            this.changeQuery()
            this.fetchReport()
        },
        toDate() {
            this.changeQuery()
            this.fetchReport()
        }
    },
    mounted() {
        this.fromDate = this.$route.query.from ?? moment().format('YYYY-MM-DD')
        this.toDate = this.$route.query.to ?? moment().format('YYYY-MM-DD')
        this.fetchReport()
    },
    methods: {
        changeQuery() {
            this.$router.push({ name: this.$route.name, query: { from: this.fromDate, to: this.toDate } })
                .catch(error => {
                    if (error?.name !== 'NavigationDuplicated') {
                        throw error
                    }
                })
        },
        getFetchParams() {
            return {
                from: this.fromDate,
                to: this.toDate
            }
        },
        getFetchParamsToDownload() {
            let params = this.getFetchParams()
            // params.page = this.page

            return Object.entries(params)
                .map(([key, value]) => {
                    if (Array.isArray(value)) {
                        return value.map(v => `${key}[]=${v}`).join('&')
                    }

                    return `${key}=${value}`
                })
                .join('&')
        },
        async fetchReport() {
            this.loading = true

            try {
                const { data } = await customApi().factories.events.holiday.fetchActivityReport(this.getFetchParams())

                if (data.length === 0) {
                    this.rows = []
                    return
                }

                this.rows = data.map(row => ({
                    ...row
                }))
            } catch (e) {
                this.rows = []
            } finally {
                this.loading = false
            }
        },
        onGetVacationsReport() {
            window.location.href = `${window.location.origin}/api/events/holidays/reports/activity/download?${this.getFetchParamsToDownload()}`
        }
    }
}
</script>