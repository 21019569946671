import { mapGetters } from 'vuex'
import employeeAutocompleteService from '@/services/employees/employeeAutocompleteService'

export default {
    data() {
        return {
            employeeAutocompleteService,
            disabledButton: false,
            formData: {
                scheduleId: null,
                type: 'group',
                name: '',
                userId: null,
                parentId: -1
            },
            groupNameText: `${this.$t("Pavadinimas")}*`,
            userNameText: `${this.$t('Darbuotojas')}*`
        }
    },
    computed: {
        ...mapGetters({
            linesTypeGroup: 'schedulerStore/linesTypeGroup'
        }),
        isGroup() {
            return this.formData.type === 'group'
        },
        isUser() {
            return this.formData.type === 'user'
        },
        typeOptions() {
            return [
                { value: 'group', text: this.$t('Darbo vieta') },
                { value: 'user', text: this.$t('Darbuotojas') }
            ]
        },
        parentOptions() {
            return [
                { value: -1, text: '-' },
                ...this.linesTypeGroup.map(({ id, name }) => ({
                    value: id,
                    text: name
                }))
            ]
        },
        parentUpdateOptions() {
            console.log(this.line);
            return [
                { value: -1, text: '-' },
                ...this.linesTypeGroup
                    .filter(({ id }) => this.line.id !== id)
                    .map(({ id, name }) => ({
                        value: id,
                        text: name
                    }))
            ]
        }
    },
    methods: {
        getPayload() {
            return {
                scheduleId: this.formData.scheduleId,
                scheduleLineId: this.formData.parentId === -1 ? null : this.formData.parentId,
                type: this.formData.type,
                name: this.isGroup ? this.formData.name : '',
                userId: this.isUser ? (this.formData.userId ?? null) : null,
            }
        }
    }
}