<template>
    <v-col cols='12'>
        <ui-table
            header-sticky
            :visible-load-more='hasMore'
            :loading='loading'
            :loading-more='loadingMore'
            @clickLoadMore='handleLoadMore'
        >
            <template #head>
                <tr>
                    <th>{{ $t('Pradėjo žiūrėti') }}</th>
                    <th>{{ $t('Baigė žiūrėti') }}</th>
                    <th>{{ $t('Praleido laiko') }}</th>
                </tr>
            </template>

            <template #body>
                <tr v-for='(row, key) in rows' :key='key'>
                    <td>{{ row.beginAt }}</td>
                    <td>{{ row.endAt }}</td>
                    <td>{{ row.spendTime }}</td>
                </tr>
            </template>
        </ui-table>
    </v-col>
</template>

<script>
import UiTableHeadFilter from '@/domain/components/uiTable/UiTableHeadFilter.vue'
import UiTable from '@/domain/components/uiTable/UiTable.vue'
import DocumentSentStatus from '@/domain/documents/components/DocumentSentStatus.vue'
import TableHeaderDateFilter from '@/domain/components/uiTable/filters/TableHeaderDateFilter.vue'
import CustomApi from '@/plugins/customApi'

const customApi = CustomApi()
export default {
    name: 'PreviewsOfDocumentSentUserTable',
    components: { TableHeaderDateFilter, DocumentSentStatus, UiTable, UiTableHeadFilter },
    props: {
        documentSentId: { type: Number, required: true }
    },
    data() {
        return {
            loading: false,
            loadingMore: false,
            hasMore: false,
            currentPage: 1,
            rows: []
        }
    },
    mounted() {
        this.loadData()
    },
    methods: {
        getRequestParams(page = 1) {
            return {
                page,
                limit: 50
            }
        },
        async loadData() {
            this.loading = true
            try {
                await this.fetch(this.getRequestParams(1))
            } catch (error) {
                console.debug(error)
            } finally {
                this.loading = false
            }
        },
        async handleLoadMore() {
            this.loadingMore = true
            try {
                await this.fetch(this.getRequestParams(this.currentPage + 1))
            } catch (error) {

            } finally {
                this.loadingMore = false
            }
        },
        async fetch(params) {
            const {
                data: {
                    items,
                    hasMore,
                    pagination: { currentPage }
                }
            } = await customApi.factories.documents.sends.previews(this.documentSentId, params)

            this.rows = currentPage === 1 ? items : [...this.rows, ...items]
            this.currentPage = currentPage
            this.hasMore = hasMore
        }
    }
}
</script>