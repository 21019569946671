export const ADD_TO_LOADER_QUEUE = 'ADD_TO_LOADER_QUEUE'
export const REMOVE_FROM_LOADER_QUEUE = 'REMOVE_FROM_LOADER_QUEUE'
export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const CLOSE_MESSAGE = 'CLOSE_MESSAGE'
export const ADD_MESSAGE = 'ADD_MESSAGE'
export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const SET_IS_MOBILE = 'SET_IS_MOBILE'
export const SET_CONTEXT_MENU = 'SET_CONTEXT_MENU'
export default ({
    [ADD_TO_LOADER_QUEUE]: (state, payload) => {
        state.loaderQueue.push(payload)
    }, [REMOVE_FROM_LOADER_QUEUE]: (state, payload) => {
        state.loaderQueue = state.loaderQueue.filter((v) => v !== payload)
    }, [SHOW_MESSAGE]: (state, payload) => {
        state.message.show = true
        state.message.text = payload.message
        state.message.type = payload.type || 'normalMsg'
    }, [CLOSE_MESSAGE]: (state, payload) => {
        state.message.show = false
        setTimeout(() => {
            state.message.text = ''
            state.message.type = ''
        }, 110)
    }, [ADD_MESSAGE]: (state, payload) => {
        state.messages = [...state.messages.filter(message => message.name !== payload.name), payload]
    }, [OPEN_MODAL]: (state, payload) => {
        state.modals.push(payload)
    }, [CLOSE_MODAL]: (state, payload) => {
        state.modals = state.modals.filter(modal => modal.name !== payload)
    }, [SET_IS_MOBILE]: (state, payload) => {
        state.isMobile = payload
    },
    [SET_CONTEXT_MENU]: (state, payload) => state.currentContextMenu = payload
})
