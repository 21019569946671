<template>
    <tr>
        <td>
            <div class='d-flex flex-row align-center'>
                <form-checkbox
                    v-if='selectable'
                    :is-checked='item.selected'
                    @change='handleDocumentToggleSelect'
                    class='mr-2'
                />
                <div
                    @click='handleOpenDocumentDetailsSidebar'
                    @dblclick.prevent='handleOpenPreviewDocumentModal'
                >
                    <ui-icon style='color: #8689A8'>paper</ui-icon>
                    <span
                        class='ml-2'
                    >{{ document.title }}</span>
                </div>
            </div>
        </td>
        <td v-if='withStatus'>
            <document-sent-status
                :status='status'
            />
        </td>
        <td v-if='withStatus'>{{ accepted }}</td>
        <td>{{ document.sizeText }}</td>
        <td>{{ document.createdAt }}</td>
        <td>{{ document.updatedAt }}</td>
        <td v-if='withControls'>
            <div class='d-flex flex-row align-center justify-end'>
                <ui-button
                    class='px-0'
                    background='primary'
                    size='medium'
                    only-text
                    @click='handleOpenDocumentsSendModal'
                >{{ $t('Siųsti') }}
                </ui-button>
                <v-spacer />
                <documents-finder-context-menu>
                    <documents-finder-context-menu-item
                        icon='eye'
                        :title='$t("Peržiūrėti")'
                        @click='handleOpenPreviewDocumentModal'
                    />
                    <documents-finder-context-menu-item
                        icon='edit'
                        :title='$t("Redaguoti")'
                        @click='handleOpenEditDocumentModal'
                    />
                    <documents-finder-context-menu-item
                        icon='message-info'
                        :title='$t("Istorija")'
                        @click='handleOpenHistoryDocumentModal'
                    />
                    <documents-finder-context-menu-item
                        icon='rotation'
                        :title='$t("Perkelti dokumentą")'
                        @click='handleOpenMoveDocumentModal'
                    />
                    <documents-finder-context-menu-item
                        icon='bin'
                        :title='$t("Archyvuoti dokumentą")'
                        @click='handleOpenArchiveDocumentModal'
                    />
                </documents-finder-context-menu>
            </div>
        </td>
    </tr>
</template>

<script>
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import UiIcon from '@/domain/components/UiIcon.vue'
import DocumentsFinderContextMenu from '@/domain/documents/finder/components/ContextMenu.vue'
import UiButton from '@/domain/components/UiButton.vue'
import DocumentsFinderContextMenuItem from '@/domain/documents/finder/components/ContextMenuItem.vue'
import documentFinderDocumentItemMixin from '@/domain/documents/finder/mixins/documentFinderDocumentItemMixin'
import DocumentSentStatus from '@/domain/documents/components/DocumentSentStatus.vue'
import GridItem from '@/domain/documents/finder/components/content/grid/GridItem.vue'

export default {
    name: 'ListDocumentItem',
    components: { GridItem, DocumentSentStatus, DocumentsFinderContextMenuItem, UiButton, DocumentsFinderContextMenu, UiIcon, FormCheckbox },
    mixins: [documentFinderDocumentItemMixin]

}
</script>