<template>
    <v-list-item ripple v-on='$listeners'>
        <v-list-item-icon class='ma-0 pa-0 align-self-center'>
            <ui-icon>{{ icon }}</ui-icon>
        </v-list-item-icon>
        <v-list-item-title>
            {{ title }}
        </v-list-item-title>
    </v-list-item>
</template>

<script>
import UiButton from '@/domain/components/UiButton.vue'
import UiIcon from '@/domain/components/UiIcon.vue'

export default {
    name: 'DocumentsFinderContextMenuItem',
    components: { UiIcon },
    props: {
        icon: {type: String, default: ''},
        title: {type: String, default: ''}
    }
}
</script>