<template>
    <form-checkbox
        style='min-width: max-content;'
        :is-checked='isSelectedAllDocuments'
        @change='handleToggleSelectAll'
    >
        <span class='ml-3'>{{ $t('Pažymėti visus documentus') }}</span>
    </form-checkbox>
</template>

<script>
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'

export default {
    name: 'SubHeaderSelectAllDocuments',
    components: { FormCheckbox },
    inject: ['storeNamespace'],
    computed: {
        isSelectedAllDocuments() {
            return this.$store.getters[`${this.storeNamespace}/isSelectedAllDocuments`]
        }
    },
    methods: {
        handleToggleSelectAll() {
            this.$store.dispatch(`${this.storeNamespace}/documents/toggleSelectAll`)
        }
    }
}
</script>