<template>
    <div class='DepartmentVacationCalendarTable__event' ref='event'>
        <hra-tooltip :text='event.text'>
            <div
                class='DepartmentVacationCalendarTable__event-bg'
                :class="{
					...classes,
					'DepartmentVacationCalendarTable__event-bg--animation': animation,
				}"
            />
        </hra-tooltip>
    </div>
</template>

<script>
import HraTooltip from '@/components/ui/HraTooltip'
import { mapGetters } from 'vuex'

export default {
    name: 'DepartmentVacationCalendarEvent',
    components: { HraTooltip },
    props: {
        event: { type: Object, required: true },
        classes: { type: Object, required: true }
    },
    computed: {
        ...mapGetters({
            selectedEventId: 'departmentVacationsStore/selectedEventId'
        }),

        animation() {
            if (this.selectedEventId !== this.event.id) {
                return false
            }

            this.$nextTick(() => {
                this.$refs.event.scrollIntoView({ block: 'start', behavior: 'smooth' })
            })

            return true
        }
    },
    methods: {
        openModal(event) {
            if (!event.clickable) {
                return
            }

            this.$store.dispatch('modals/open', {
                name: event.canApprove ? 'VacationDepartmentDetailsModal' : 'EmployeeVacationModal',
                props: {
                    id: event.id,
                    vacationId: event.id
                }
            })
        }
    }
}
</script>