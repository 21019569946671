import api from "@/plugins/api";
import store from "@/store";

class EmployeeAvatarService {

  async upload(id, blob) {
    const formData = new FormData();
    formData.append("avatar", blob, "avatar.png");
    const {data} = await api.employee.changeAvatar(id, formData);
    return data.avatarSrc;
  }

  async create(url, blob) {
    await store.dispatch("employee/createAvatar", {url, blob});
  }

  async change(id, avatarSrc) {
    if (store.getters["user/id"] === id) {
      await store.dispatch("user/changeAvatar", avatarSrc);
    }

    await store.dispatch("employee/changeAvatar", {id, avatarSrc});
  }

}

export default new EmployeeAvatarService();