<template>
    <v-row class='ml-5 mt-5'>
        <v-col cols='12'>
            <ui-table
                header-sticky
                :header-sticky-top='64'
                :visible-load-more='hasMore'
                :loading='loading'
                :loading-more='loadingMore'
                @clickLoadMore='handleLoadMore'
            >
                <template #head>
                    <tr>
                        <table-header-user-filter
                            column='user'
                            :filter='filter.user'
                            @changeFilter='handleChangeFilter'
                        />
                        <table-header-department-filter
                            column='department'
                            :filter='filter.department'
                            @changeFilter='handleChangeFilter'
                        />
                        <table-header-duty-filter
                            column='duty'
                            :filter='filter.duty'
                            @changeFilter='handleChangeFilter'
                        />
                        <ui-table-head-filter
                            :title='$t("Išsiųsta")'
                            column='sentDocumentCount'
                            :sort-options='filterSortNumberOptions'
                            :value='filter.sentDocumentCount'
                            @changeFilter='handleChangeFilter'
                        />
                        <ui-table-head-filter
                            :title='$t("Nepradėta")'
                            column='sentDocumentNewCount'
                            :sort-options='filterSortNumberOptions'
                            :value='filter.sentDocumentNewCount'
                            @changeFilter='handleChangeFilter'
                        />
                        <ui-table-head-filter
                            :title='$t("Laukiama")'
                            column='sentDocumentPendingCount'
                            :sort-options='filterSortNumberOptions'
                            :value='filter.sentDocumentPendingCount'
                            @changeFilter='handleChangeFilter'
                        />
                        <ui-table-head-filter
                            :title='$t("Patvirtinta")'
                            column='sentDocumentAcceptedCount'
                            :sort-options='filterSortNumberOptions'
                            :value='filter.sentDocumentAcceptedCount'
                            @changeFilter='handleChangeFilter'
                        />
                        <ui-table-head-filter
                            :title='$t("Atmesta")'
                            column='sentDocumentRejectedCount'
                            :sort-options='filterSortNumberOptions'
                            :value='filter.sentDocumentRejectedCount'
                            @changeFilter='handleChangeFilter'
                        />
                        <ui-table-head-filter
                            :title='$t("Atšaukta")'
                            column='sentDocumentAbortedCount'
                            :sort-options='filterSortNumberOptions'
                            :value='filter.sentDocumentAbortedCount'
                            @changeFilter='handleChangeFilter'
                        />
                    </tr>
                </template>

                <template #colgroup>
                    <colgroup>
                        <col style='min-width: 40%;' />
                        <col style='min-width: 20%;' />
                        <col style='min-width: 20%;' />
                        <col style='width: 10%' />
                        <col style='width: 10%' />
                        <col style='width: 10%' />
                        <col style='width: 10%' />
                        <col style='width: 10%' />
                        <col style='width: 10%' />
                    </colgroup>
                </template>

                <template #body>
                    <tr v-for='(row, key) in rows' :key='key' @click='() => handleClickRow(row)'
                        style='cursor: pointer;'>
                        <td>{{ row.name }}</td>
                        <td>{{ row.departmentName }}</td>
                        <td>{{ row.dutyName }}</td>
                        <td>{{ row.sentDocumentCount }}</td>
                        <td>{{ row.sentDocumentNewCount }}</td>
                        <td>{{ row.sentDocumentPendingCount }}</td>
                        <td>{{ row.sentDocumentAcceptedCount }}</td>
                        <td>{{ row.sentDocumentRejectedCount }}</td>
                        <td>{{ row.sentDocumentAbortedCount }}</td>
                    </tr>
                </template>
            </ui-table>
        </v-col>
    </v-row>
</template>

<script>
import UiTable from '@/domain/components/uiTable/UiTable.vue'
import CustomApi from '@/plugins/customApi'
import DocumentSentStatus from '@/domain/documents/components/DocumentSentStatus.vue'
import UiTableHeadFilter from '@/domain/components/uiTable/UiTableHeadFilter.vue'
import tableFilterMixin from '@/domain/components/uiTable/mixins/tableFilterMixin'
import modalMethodsMixin from '@/domain/mixins/modalMethodsMixin'
import TableHeaderDepartmentFilter from '@/domain/components/uiTable/filters/TableHeaderDepartmentFilter.vue'
import TableHeaderUserFilter from '@/domain/components/uiTable/filters/TableHeaderUserFilter.vue'
import TableHeaderDutyFilter from '@/domain/components/uiTable/filters/TableHeaderDutyFilter.vue'

const customApi = CustomApi()
export default {
    name: 'DocumentSummaryUsersPage',
    mixins: [tableFilterMixin, modalMethodsMixin],
    components: {
        TableHeaderDutyFilter,
        TableHeaderUserFilter,
        TableHeaderDepartmentFilter, UiTableHeadFilter, DocumentSentStatus, UiTable
    },
    data() {
        return {
            loading: false,
            loadingMore: false,
            hasMore: false,
            currentPage: 1,
            rows: [],
            filterSettings: {
                sortOnlyOne: false,
                setRouteQuery: true,
                resetSelected: {
                    'user': ['department', 'duty'],
                    'department': ['user', 'duty'],
                    'duty': ['user', 'department']
                }
            },
            filter: {
                user: { sort: 'asc', selected: [] },
                department: { sort: null, selected: [] },
                duty: { sort: null, selected: [] },
                sentDocumentCount: { sort: null },
                sentDocumentNewCount: { sort: null },
                sentDocumentPendingCount: { sort: null },
                sentDocumentAcceptedCount: { sort: null },
                sentDocumentRejectedCount: { sort: null },
                sentDocumentAbortedCount: { sort: null }
            }
        }
    },
    created() {
        this.getFilterFromRouteQuery(this.$route.query)
        this.loadData()
    },
    methods: {
        handleClickRow(row) {
            this.onOpenModal('DocumentsSummaryByUserModal', { item: row })
        },
        handleChangeFilter(column, filter) {
            this.setFilter(column, filter)
            this.loadData()
        },
        getRequestParams(page = 1) {
            return {
                page,
                limit: 50,
                ...this.getFilterParams()
            }
        },
        async loadData() {
            this.loading = true
            try {
                await this.fetch(this.getRequestParams(1))
            } catch (error) {

            } finally {
                this.loading = false
            }
        },
        async handleLoadMore() {
            this.loadingMore = true
            try {
                await this.fetch(this.getRequestParams(this.currentPage + 1))
            } catch (error) {

            } finally {
                this.loadingMore = false
            }
        },
        async fetch(params) {
            const {
                data: {
                    items,
                    hasMore,
                    pagination: { currentPage }
                }
            } = await customApi.factories.documents.summary.fetchUsers(params)

            this.rows = currentPage === 1 ? items : [...this.rows, ...items]
            this.currentPage = currentPage
            this.hasMore = hasMore
        }
    }
}
</script>