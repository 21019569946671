<template>
    <div
        class='d-flex flex-row'
        v-resize='handleWindowResize'
        :style='containerStyles'
        ref='Container'
    >
        <div class='documents-management-container px-8 pt-8'>
            <slot name='content' />
        </div>
        <document-sidebar class='documents-management-sidebar'>
            <slot name='sidebar' />
        </document-sidebar>
    </div>
</template>

<script>
import DocumentSidebar from '@/domain/documents/components/DocumentSidebar.vue'

export default {
    name: 'ItemsResizableContent',
    components: { DocumentSidebar },
    data() {
        return {
            sidebarTop: 0,
            height: document.documentElement.clientHeight,
            containerStyles: {}
        }
    },
    mounted() {
        this.resetStyle()
    },
    methods: {
        resetStyle() {
            const header = document.querySelector('header.header')?.clientHeight ?? 64
            const footer = document.querySelector('.documents-overview-disk-usage')?.clientHeight ?? 100
            const {top} = this.$refs.Container.getBoundingClientRect()
            let height = this.height - top - footer

            this.containerStyles = {
                top: `${header}px`,
                height: `${height}px`
            }
        },
        handleWindowResize() {
            this.resetStyle()
        }
    }
}
</script>