<template>
  <ValidationObserver ref="observer" v-slot="{ errors }">
    <form ref="form" :class="noClass ? '' : 'form'" @submit.prevent="onSubmit">
      <slot name="buttons-header" :loading="isLoading" :closeDialog="closeDialog" :changed="changed"/>
      <div :class="isDialog ? 'dialog__main' : ''">
        <FormMessages
            v-if="viewMessagesHeader && !ignoreErrors"
            :is-header="viewMessagesHeader"
            :validate-errors="errors"
            :message="loadingMessage"
        />
        <div class="form__header-messages">
          <slot name="header-messages"/>
        </div>
        <slot name="form" :changed="changed" :loading="isLoading"/>
        <FormMessages
            v-if="!viewMessagesHeader && !ignoreErrors"
            :is-header="!viewMessagesHeader"
            :validate-errors="errors"
            :message="loadingMessage"
        />
        <div class="form__footer-messages">
          <slot name="footer-messages"/>
        </div>
      </div>
      <div :class="isDialog ? 'dialog__footer justify-center justify-sm-end' : ''" class="d-flex gap-3" :style="{width: '100%'}">
        <slot name="buttons" :loading="isLoading" :closeDialog="closeDialog" :changed="changed"/>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {ValidationObserver} from "vee-validate";
import FormAlert from "./FormAlert.vue";
import FormMessages from "@/components/ui/form/FormMessages";

export default {
  props: {
    value: {
      type: [String, Number, Object],
      required: true,
    },
    formLoaderName: {
      type: String,
      default: 'init',
    },
    formErrorName: {type: String, default: "init"},
    service: {
      type: Function,
      required: false,
      default: null,
    },
    serviceProps: {
      type: Function,
      required: false,
      default: null,
    },
    isDialog: {
      type: Boolean,
      default: false,
    },
    noClass: {
      type: Boolean,
      default: false,
    },
    formatData: {
      type: Function,
      default: (data) => data,
    },
    autoSaveAfterChange: {
      type: Boolean,
      default: false,
    },
    viewMessagesHeader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errorMessage: "",
      successMessage: "",
      loaderName: this.formLoaderName,
      errorName: this.formErrorName,
      changed: false,
      ignoreErrors: true,
    };
  },
  provide() {
    return {
      $ignoreErrors: () => this.ignoreErrors,
    };
  },
  components: {
    FormMessages,
    ValidationObserver,
    FormAlert,
  },
  watch: {
    $refs: {
      handler(newValue, oldValue) {

      },
      immediate: true,
      deep: true,
    },
    value: {
      handler(newValue, oldValue) {
        if (Object.keys(oldValue).length > 0) {
          this.changed = true;
          if (this.autoSaveAfterChange) {
            this.onSubmit();
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    // test
    // this.showError("test error");
    // this.showSuccess("test success");
  },
  methods: {
    showError(message) {
      this.errorMessage = message;
    },
    showSuccess(message) {
      this.successMessage = message;
    },
    clearMessages() {
      this.errorMessage = null;
      this.successMessage = null;
    },
    closeDialog() {
      this.$parent.$parent.$parent;
    },
    clearForm() {
      this.$refs.form.reset();
    },
    async isValid() {
			const validation = await this.$refs.observer.validate();
			this.$emit("validation", validation)
      return validation;
    },
    async onSubmit() {
      this.ignoreErrors = false;
      this.clearMessages();
      if (!await this.isValid()) {
        return;
      }
      const value = this.formatData(this.value);
      this.$emit("submit", value);
      this.changed = false;
    },
  },
};
</script>
