<template>
    <v-row class='pa-8'>
        <v-col cols='12' class='d-flex align-end gap-3'>
            <ui-button
                v-if='isAdmin'
                background='primary'
                prepend-icon='plus'
                :modal='{
                    name: "ImportSickLeavesModal",
                    on: {
                        imported: handleSickLeavesImported
                    }
                }'
            >{{ $t('Ligonlapių įkėlimas') }}
            </ui-button>
            <ui-button
                v-if='isAdmin'
                background='primary'
                prepend-icon='plus'
                :modal="{name: 'BusinessTripsModal', props: {asAdmin: true}}"
            >
                {{ $t('Pridėti komandiruote') }}
            </ui-button>
            <v-spacer />
            <select-field
                :label="$t('Rodyti puslapyje')"
                :options='limitOptions'
                v-model='limit'
                @input='() => fetch()'
            />
            <ui-button
                v-if='isAdmin'
                background='violet-dark'
                prepend-icon='download'
                @click='handleDownloadExcel'
            >{{ $t('Atsisiųsti .xlsx') }}
            </ui-button>
        </v-col>
        <v-col cols='12'>
            <ui-table
                :loading='loading'
                :is-empty-table='rows.length === 0'
                :visible-load-more='hasMore'
                @clickLoadMore='handleLoadMore'
            >
                <template #head>
                    <tr>
                        <table-header-user-filter
                            :filter='filter.userId'
                            @changeFilter='handleChangeFilter'
                        />
                        <table-header-department-filter
                            :filter='filter.departmentId'
                            @changeFilter='handleChangeFilter'
                        />
                        <table-header-duty-filter
                            :filter='filter.dutyId'
                            @changeFilter='handleChangeFilter'
                        />
                        <table-header-date-filter
                            :title='$t("Data nuo")'
                            column='start'
                            :filter='filter.start'
                            @changeFilter='handleChangeFilter'
                        />
                        <table-header-date-filter
                            :title='$t("Data iki")'
                            column='end'
                            :filter='filter.end'
                            :min-date='filter.start.date'
                            @changeFilter='handleChangeFilter'
                        />
                        <ui-table-head-filter
                            :title='$t("Tipas")'
                            column='type'
                            :value='filter.type'
                            :select-options='eventTypeOptions'
                            select-option-searchable
                            @changeFilter='handleChangeFilter'
                        />
                        <ui-table-head-filter
                            :title='$t("Statusas")'
                            column='status'
                            :value='filter.status'
                            :select-options='eventStatusOptions'
                            @changeFilter='handleChangeFilter'
                        />
                        <table-header-date-filter
                            :title='$t("Statuso data")'
                            column='statusDate'
                            interval
                            :filter='filter.statusDate'
                            @changeFilter='handleChangeFilter'
                        />
                        <th>{{ $t('Komentaras') }}</th>
                        <th class='text-end'>{{ $t('Veiksmai') }}</th>
                    </tr>
                </template>

                <template #body>
                    <tr v-for='(row, key) in rowsFormatted' :key='key' @click='handleClickOnRow(row)' style='cursor: pointer'>
                        <td>{{ row.user.name }}</td>
                        <td>{{ row.user.department.name }}</td>
                        <td>{{ row.user.duty.name }}</td>
                        <td>{{ row.start }}</td>
                        <td>{{ row.end }}</td>
                        <td>{{ row.typeText }}</td>
                        <td>
                            <event-status
                                v-if='row.statusApprovalState == null'
                                :status-text='row.currentStatusText'
                                :status='row.currentStatus'
                            />
                            <event-status-with-approval-stages
                                v-else
                                :id='row.statusApprovalState.id'
                                :type='row.statusApprovalState.type'
                                :status='row.currentStatus'
                                :status-text='row.currentStatusText'
                            />
                        </td>
                        <td>{{ row.status.date }}</td>
                        <td style='max-width: 130px;overflow: hidden'>{{ row.message }}</td>
                        <td class='text-end' @click.prevent='() => {}'>
                            <department-event-row-actions
                                :row='row'
                                @updated='handleUpdatedRow'
                            />
                        </td>
                    </tr>
                </template>
            </ui-table>
        </v-col>
    </v-row>
</template>

<script>
import UiTable from '@/domain/components/uiTable/UiTable.vue'
import SelectField from '@/domain/fields/SelectField.vue'
import UiButton from '@/domain/components/UiButton.vue'
import DatepickerField from '@/domain/fields/DatepickerField.vue'
import CustomApi from '@/plugins/customApi'
import TableHeaderUserFilter from '@/domain/components/uiTable/filters/TableHeaderUserFilter.vue'
import TableHeaderDepartmentFilter from '@/domain/components/uiTable/filters/TableHeaderDepartmentFilter.vue'
import TableHeaderDutyFilter from '@/domain/components/uiTable/filters/TableHeaderDutyFilter.vue'
import TableHeaderDateFilter from '@/domain/components/uiTable/filters/TableHeaderDateFilter.vue'
import UiTableHeadFilter from '@/domain/components/uiTable/UiTableHeadFilter.vue'
import HraTooltip from '@/components/ui/HraTooltip.vue'
import checkIsAdminMixin from '@/mixins/checkIsAdminMixin'
import DepartmentEventRowActions from '@/domain/departmentVacations/components/DepartmentEventRowActions.vue'
import checkIsLeaderMixin from '@/mixins/checkIsLeaderMixin'
import modalMethodsMixin from '@/domain/mixins/modalMethodsMixin'
import DocumentSentStatus from '@/domain/documents/components/DocumentSentStatus.vue'
import EventStatus from '@/domain/components/EventStatus.vue'
import EventStatusWithApprovalStages from '@/domain/components/ApprovalStages/EventStatusWithApprovalStages.vue'

const customApi = CustomApi()

export default {
    name: 'DepartmentEventsTab',
    components: {
        EventStatusWithApprovalStages,
        EventStatus,
        DocumentSentStatus,
        DepartmentEventRowActions,
        HraTooltip,
        UiTableHeadFilter,
        TableHeaderDateFilter,
        TableHeaderDutyFilter,
        TableHeaderDepartmentFilter,
        TableHeaderUserFilter,
        DatepickerField,
        UiButton,
        SelectField,
        UiTable
    },
    mixins: [checkIsAdminMixin, checkIsLeaderMixin, modalMethodsMixin],
    provide() {
        return {
            createRow: this.createRow,
            updateRow: this.updateRow
        }
    },
    data() {
        return {
            loading: false,
            page: 1,
            limit: 10,
            hasMore: false,
            rows: [],

            eventTypeOptions: [
                { value: 1001, label: this.$t('Kasmetinės atostogos') },
                { value: 1002, label: this.$t('Tėvadienis / mamadienis') },
                { value: 1003, label: this.$t('Nėštumo ir gimdymo atostogos') },
                { value: 1004, label: this.$t('Tėvystės atostogos') },
                { value: 1005, label: this.$t('Atostogos vaikui prižiūrėti') },
                { value: 1006, label: this.$t('Mokymosi atostogos') },
                { value: 1007, label: this.$t('Kūrybinės atostogos') },
                { value: 1008, label: this.$t('Atostogos valstybinėms ar visuomeninėms pareigoms atlikti') },
                { value: 1009, label: this.$t('Neapmokamos atostogos') },
                { value: 1010, label: this.$t('Neapmokamas laisvas laikas (viena darbo diena)') },
                { value: 1011, label: this.$t('Pirmoji mokslo metų diena') },
                { value: 3001, label: this.$t('Remote work') },
                { value: 1013, label: this.$t('Pavadavimas') },
                { value: 4001, label: this.$t('Ligonlapis') },
                { value: 5001, label: this.$t('Komadiruotės įsakymas') },
                { value: 5002, label: this.$t('Komadiruotės prašymas') },
            ],
            eventStatusOptions: [
                { value: 'pending', label: this.$t('Laukiama patvirtinimo') },
                { value: 'approved', label: this.$t('Patvirtintas (įkelta)') },
                { value: 'aborted', label: this.$t('Atšauktas (panaikinta)') }
            ],
            limitOptions: [
                { value: 10, text: 10 },
                { value: 25, text: 25 },
                { value: 50, text: 50 },
                { value: 100, text: 100 },
                { value: 250, text: 250 },
                { value: 500, text: 500 },
                { value: 0, text: this.$t('Visus') }
            ],

            filter: {
                userId: {
                    selected: [],
                    sort: null
                },
                departmentId: {
                    selected: [],
                    sort: null
                },
                dutyId: {
                    selected: [],
                    sort: null
                },
                start: {
                    date: null,
                    sort: null
                },
                end: {
                    date: null,
                    sort: null
                },
                type: {
                    selected: []
                },
                status: {
                    selected: []
                },
                statusDate: {
                    from: null,
                    to: null,
                    sort: 'desc'
                }
            }
        }
    },
    computed: {
        rowsFormatted() {
            return this.rows.map(row => {
                let statusApprovalState = null

                if ([1001, 1002, 1003, 1004, 1005, 1006, 1007, 1008, 1009, 1010, 1011].includes(row.type)) {
                    statusApprovalState = {
                        id: row.id,
                        type: 'holidayEvent'
                    }
                }

                if ([3001].includes(row.type)) {
                    statusApprovalState = {
                        id: row.id,
                        type: 'remoteWorkEvent'
                    }
                }

                if ([5001, 5002].includes(row.type)) {
                    statusApprovalState = {
                        id: row.id,
                        type: 'businessTripEvent'
                    }
                }

                if ([4001, 1013].includes(row.type)) {
                    statusApprovalState = {
                        id: row.id,
                        type: 'statuses'
                    }
                }

                return {
                    ...row,
                    statusApprovalState
                }
            })
        }
    },
    mounted() {
        this.fetch()
    },
    methods: {
        createRow(data) {
            this.rows.unshift(data)
        },
        updateRow(data) {
            const index = this.rows.findIndex(row => data.id === row.id)

            if (index === -1) {
                return
            }

            this.$set(this.rows, index, {
                ...this.rows[index],
                ...data
            })
        },


        handleClickOnRow(row) {
            const modalName = {
                4001: 'InfoSickLeaveModal',
                5001: 'BusinessTripsModal',
                5002: 'BusinessTripsModal'
            }[row.type] ?? null

            if (modalName === null) {
                return
            }

            const modalProps = {
                id: row.id,
                asAdmin: this.isAdmin,
                asLeader: this.isLeader,
                readonly: true
            }

            this.onOpenModal(modalName, modalProps, {
                created: this.handleCreatedRow,
                updated: this.handleUpdatedRow
            })
        },
        handleChangeFilter(column, filter) {
            if (column === 'userId') {
                this.$set(this.filter, 'departmentId', { selected: [], sort: null })
                this.$set(this.filter, 'dutyId', { selected: [], sort: null })
            }

            if (column === 'departmentId') {
                this.$set(this.filter, 'userId', { selected: [], sort: null })
                this.$set(this.filter, 'dutyId', { selected: [], sort: null })
            }

            if (column === 'dutyId') {
                this.$set(this.filter, 'userId', { selected: [], sort: null })
                this.$set(this.filter, 'departmentId', { selected: [], sort: null })
            }

            if (column === 'start') {
                if (this.filter.end.date != null && this.filter.end.date < filter.date) {
                    this.$set(this.filter, 'end', { date: null, sort: null })
                }
            }

            this.$set(this.filter, column, filter)
            this.fetch()
        },
        handleSickLeavesImported(userIds, start, end) {
            this.$set(this.filter, 'userId', { selected: userIds, sort: null })
            this.$set(this.filter, 'departmentId', { selected: [], sort: null })
            this.$set(this.filter, 'dutyId', { selected: [], sort: null })
            this.$set(this.filter, 'status', { selected: [] })
            this.$set(this.filter, 'statusDate', { from: null, to: null })
            this.$set(this.filter, 'start', { date: start, sort: null })
            this.$set(this.filter, 'end', { date: end, sort: null })
            this.$set(this.filter, 'type', { selected: [4001] })
            this.fetch()
        },
        handleLoadMore() {
            this.fetch(true)
        },
        getFetchParams(nextPage) {
            let params = {
                limit: this.limit,
                page: 1,
                sortBy: []
            }

            if (nextPage) {
                params.page = this.page + 1
            }

            for (const [column, value] of Object.entries(this.filter)) {
                if (value.sort != null) {
                    params.sortBy.push(`${column},${value.sort}`)
                }

                if ((value.selected?.length ?? 0) > 0) {
                    params[column] = value.selected
                }

                if (value.date != null) {
                    params[column] = value.date
                }

                if (value.from != null) {
                    params[`${column}From`] = value.from
                }

                if (value.to != null) {
                    params[`${column}To`] = value.to
                }
            }

            return params
        },
        async fetch(nextPage = false) {
            this.loading = true
            try {
                const { data: { items, pagination } } = await customApi.factories.events.list
                    .fetch(this.getFetchParams(nextPage))

                this.page = pagination.currentPage
                this.rows = this.page === 1 ? items : [...this.rows, ...items]
                this.hasMore = pagination.currentPage < pagination.lastPage
            } catch (e) {
                this.hasMore = false
                this.rows = []
                this.page = 1
                console.error(e)
            } finally {
                this.loading = false
            }
        },
        getFetchParamsToDownload() {
            let params = this.getFetchParams()
            params.page = this.page

            return Object.entries(params)
                .map(([key, value]) => {
                    if (Array.isArray(value)) {
                        return value.map(v => `${key}[]=${v}`).join('&')
                    }

                    return `${key}=${value}`
                })
                .join('&')
        },
        handleDownloadExcel() {
            window.location.href = `/api/events/list/download?${this.getFetchParamsToDownload()}`
        },
        handleCreatedRow(data) {
            this.rows.unshift(data)
        },
        handleUpdatedRow(data) {
            const index = this.rows.findIndex(row => data.id === row.id)

            if (index === -1) {
                return
            }

            this.$set(this.rows, index, {
                ...this.rows[index],
                ...data
            })
        }
    }
}
</script>