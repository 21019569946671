export const importEmployees = ({importEmployees}) => importEmployees.map(employee => {
  return ({
    id: employee.id,
    timeCardNumber: employee.contract.timeCardNumber,
    number: employee.contract.number,
    name: employee.contract.name,
    brithDate: employee.contact.brithDate,
    email: employee.contract.email,
    departments: employee.contract.departments,
    duties: employee.contract.duties,
    validated: employee.validated,
    invalid: employee.invalid,
  });
}).sort((a, b) => Number(b.invalid) - Number(a.invalid));
export const importEmployeeData = ({importEmployees}) => (id, key) => {
  const employee = importEmployees.find(employee => employee.id === id);
  return employee[key];
};
export const importEmployeeChild = ({importEmployees}) => (id) => {
  return importEmployees.find(employee => employee.id === id)?.child ?? [];
};
export const counter = (state, {importEmployeeData}) => (id, key) => importEmployeeData(id, key)?.length ?? 0;
export const issetContractTimeCardNumber = ({importEmployees}) => {
  return (ignoreId, timeCardNumber) => {
    return importEmployees
      .filter(({id}) => id !== ignoreId)
      .map(({contract}) => contract.timeCardNumber).includes(timeCardNumber)
  };
};
export const issetContractNumber = ({importEmployees}) => {
  return (ignoreId, number) => {
    return importEmployees
      .filter(({id}) => id !== ignoreId)
      .map(({contract}) => contract.number).includes(number)
  };
};
export const importDepartmentOptions = ({importDepartmentOptions}) => importDepartmentOptions
export const importDutyOptions = ({importDutyOptions}) => importDutyOptions