<template>
    <div class='page max-page-width'>
        <v-row class='mx-6'>
            <v-col cols='12'>
                <div class='d-flex align-end my-3 gap-3'>
                    <datepicker-field :label="$t('Nuo (atostogų pirma diena)')" v-model='fromDate' />
                    <datepicker-field :min-date='fromDate' :label="$t('Iki (atostogų pirma diena)')" v-model='toDate' />
                    <div style='width: 270px'>
                        <select-field :label="$t('Atostogų tipas')" :options='vacationTypeOptions' multiple
                                      v-model='eventHolidayTypes' />
                    </div>
                    <div style='width: 270px'>
                        <select-field :label="$t('Statusas')" :options='statusOptions' multiple v-model='status' />
                    </div>
                    <div style='width: 200px'>
                        <select-field :label="$t('Mokėjimo tipas')" :options='payTypeOptions' multiple
                                      v-model='payTypes' />
                    </div>
                    <ui-button @click='fetchReport'>{{ $t('Rodyti ekrane') }}</ui-button>
                    <ui-button background='primary' @click='onGetVacationsReport'>{{ $t('Atsiųsti .xlsx') }}</ui-button>
                </div>
                <div class='d-flex gap-3'>
                    <form-checkbox :is-checked.sync='zeroDays'>
                        <div class='pl-2'>{{ $t('Rodyti atostogas su 0 d.d.') }}</div>
                    </form-checkbox>
                    <form-checkbox :is-checked.sync='intervalAtLeastOneDay'>
                        <div class='pl-2'>{{ $t('Rodyti atostogas, kurių bent viena diena yra pasirinktame intervale')
                            }}
                        </div>
                    </form-checkbox>
                </div>
            </v-col>
            <v-col cols='12' class='pb-3'>
                <ui-table
                    :loading='loading'
                    :is-empty-table='rows.length === 0'
                >
                    <template #empty-table>
                        <message-alert
                            id='table-alert-empty'
                            :message='$t("Įrašų sistemoje nerasta.")'
                            type='info'
                        />
                    </template>
                    <template #head>
                        <tr>
                            <th>{{ $t('Vardas Pavardė') }}</th>
                            <th>{{ $t('Tabelio nr.') }}</th>
                            <th>{{ $t('Skyrius') }}</th>
                            <th>{{ $t('Pareigos') }}</th>
                            <th :style='{ minWidth: "150px" }'>{{ $t('Pirma diena') }}</th>
                            <th :style='{ minWidth: "150px" }'>{{ $t('Paskutinė diena') }}</th>
                            <th :style='{ minWidth: "100px" }'>{{ $t('Darbo dienų sk.') }}</th>
                            <th :style='{ minWidth: "150px" }'>{{ $t('Statusas') }}</th>
                            <th :style='{ minWidth: "150px" }'>{{ $t('Statuso data') }}</th>
                            <th :style='{ minWidth: "150px" }'>{{ $t('Prašymo sukūrimo data') }}</th>
                            <th>{{ $t('Rūšis') }}</th>
                            <th>{{ $t('Apmokėjimas') }}</th>
                            <th>{{ $t('Komentaras') }}</th>
                        </tr>
                    </template>

                    <template #body>
                        <tr v-for='(row, key) in rows' :key='key'>
                            <td>{{ row.name }}</td>
                            <td>{{ row.timeCardNumber }}</td>
                            <td>{{ row.department }}</td>
                            <td>{{ row.duty }}</td>
                            <td :style='{ minWidth: "150px" }'>{{ row.start }}</td>
                            <td :style='{ minWidth: "150px" }'>{{ row.end }}</td>
                            <td :style='{ minWidth: "100px" }'>{{ row.workDays }}</td>
                            <td :style='{ minWidth: "150px" }'>{{ getEventStatusText(row.status) }}</td>
                            <td :style='{ minWidth: "150px" }'>{{ row.statusDate }}</td>
                            <td :style='{ minWidth: "150px" }'>{{ row.createDate }}</td>
                            <td>{{ row.type }}</td>
                            <td>{{ row.payType }}</td>
                            <td>{{ row.message }}</td>
                        </tr>
                    </template>
                </ui-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import moment from 'moment/moment'
import DatepickerField from '@/domain/fields/DatepickerField'
import SelectField from '@/domain/fields/SelectField'
import { availableHolidayPayTypes, eventHolidayType, eventHolidayTypes } from '@/utils/default'
import customApi from '@/plugins/customApi'
import UiButton from '@/domain/components/UiButton.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import TableCheckbox from '@/components/ui/table.old/TableCheckbox.vue'
import UiTable from '@/domain/components/uiTable/UiTable.vue'
import MessageAlert from '@/domain/messages/components/MessageAlert.vue'

export default {
    name: 'VacationReportsPage',
    components: {
        MessageAlert,
        UiTable,
        TableCheckbox,
        FormCheckbox, UiButton, SelectField, DatepickerField
    },
    data() {
        const today = moment().format('YYYY-MM-DD')

        return {
            loading: false,
            message: null,
            fromDate: today,
            toDate: today,
            eventHolidayTypes: eventHolidayTypes,
            status: ['0'],
            payTypes: ['pay_after', 'pay_before', 'pay_with_salary_by_month', 'free'],
            zeroDays: true,
            intervalAtLeastOneDay: false,
            statusOptions: [
                { value: '0', text: this.$t('Patvirtintos') },
                { value: '1', text: this.$t('Laukiančios patvirtinimo') },
                { value: '2', text: this.$t('Atšauktos') }
            ],
            payTypeOptions: [
                ...availableHolidayPayTypes.map(type => ({ value: type.value, text: type.title })),
                { value: 'free', text: this.$t('Neapmokamos') }
            ],
            vacationTypeOptions: eventHolidayTypes.map(key => ({
                value: key,
                text: eventHolidayType[key]
            })),
            rows: []
        }
    },
    methods: {
        getEventStatusText(status) {
            switch (status) {
                case 'approved':
                    return this.$t('patvirtintos')
                case 'aborted':
                    return this.$t('atšauktos')
                default:
                    return this.$t('laukiančios patvirtinimo')
            }
        },
        getRequestParams() {
            const eventHolidayTypes = this.eventHolidayTypes?.length === 0
                ? this.vacationTypeOptions.map(option => option.value)
                : this.eventHolidayTypes

            const status = this.status?.length === 0
                ? this.statusOptions.map(option => option.value)
                : this.status

            const payTypes = this.payTypes?.length === 0
                ? this.payTypeOptions.map(option => option.value)
                : this.payTypes

            return {
                fromDate: this.fromDate,
                toDate: this.toDate,
                eventHolidayTypes,
                status,
                zeroDays: this.zeroDays ? 1 : 0,
                intervalAtLeastOneDay: this.intervalAtLeastOneDay ? 1 : 0,
                payTypes
            }
        },
        async fetchReport() {
            this.loading = true

            try {
                const { data } = await customApi().factories.vacations.reports.fetch(this.getRequestParams())

                if (data.length === 0) {
                    this.rows = []
                    return
                }

                this.rows = data
            } catch (e) {
                this.rows = []
            } finally {
                this.loading = false
            }
        },
        onGetVacationsReport() {
            const downloadURL = new URL(`${window.location.origin}/api/events/holidays/reports/download`)
            const params = this.getRequestParams()

            Object.keys(params).forEach(key => {
                const value = params[key]

                if (Array.isArray(value)) {
                    value.forEach(val => {
                        downloadURL.searchParams.append(`${key}[]`, val)
                    })
                    return
                }

                if (['string', 'number'].includes(typeof value)) {
                    downloadURL.searchParams.append(key, value)
                }
            })

            window.location.href = downloadURL.href
        }
    }
}
</script>