<template>
    <hra-employee-crud-table>
        <hra-table :headers='headers' :rows='rows' hide-headers :need-actions='!readOnly' class='no-border-around'>
            <template #header.custom>
                <table-title-view class='mb-3 size-1' :title="$t('Papildomos atostogų dienos prie kasmetinių atostogų')"
                                  with-border />
            </template>

            <template #col.from='{colValue, row}'>
                <div class='pe-3 py-6' :class="{'hra-text--greyscale-500': !row.editable}">
                    {{ colValue }}
                    <template v-if='row.to'>
                        <b>{{ $t('iki') }}</b> {{ row.to }}
                    </template>
                </div>
            </template>

            <template #col.comment='{colValue, row}'>
                <div class='pa-3 py-6' :class="{'hra-text--greyscale-500': !row.editable}">{{ colValue }}</div>
            </template>

            <template #col.count='{colValue, row}'>
                <div class='px-3 py-6 text-end' :class="{'hra-text--greyscale-500': !row.editable}"
                     v-html='$t("{count} d. d.", {count: colValue})' />
            </template>

            <template #col.actions-base='{row}'>
                <template v-if='row.editable'>
                    <hra-tooltip :text="$t('Redaguoti')">
                        <ui-button
                            size='small'
                            prepend-icon='edit'
                            :modal='{name: "EmployeeVacationPlusModal", props: {service: onUpdate, payload: row, userId}}'
                        />
                    </hra-tooltip>
                    <base-confirm
                        v-if='row.type !== "base"'
                        tag='EMPLOYEE_ADDITIONAL_HOLIDAY_DESTROY_TAG'
                        :title="$t('Ar tikrai norite ištrinti?')"
                        @confirm='onDelete($event, row.id)'
                    >
                        <hra-tooltip :text="$t('Ištrinti')">
                            <ui-button size='small' background='danger-light' prepend-icon='bin' />
                        </hra-tooltip>
                    </base-confirm>
                </template>
            </template>

            <template #empty.text>{{ $t('Įrašų sistemoje pridėta nebuvo.') }}</template>
        </hra-table>

        <template #buttons>
            <ui-button
                v-if='!readOnly'
                size='small'
                background='primary'
                :modal='{name: "EmployeeVacationPlusModal", props: {service: onCreate, userId}}'
            >
                {{ $t('Pridėti') }}
            </ui-button>
        </template>
    </hra-employee-crud-table>
</template>

<script>
import { mapGetters } from 'vuex'
import HraTable from '@/components/ui/table/HraTable'
import TableTitleView from '@/components/ui/views/TableTitleView'
import HraButtonModal from '@/components/ui/button/HraButtonModal'
import HraTableEditableInlineTemplate from '@/components/ui/table/HraTableEditableInlineTemplate'
import HraTableHeader from '@/components/ui/table/HraTableHeader'
import HraTooltip from '@/components/ui/HraTooltip'
import HraEmployeeCrudTable from '@/components/ui/table/HraEmployeeCrudTable'
import UiButton from '@/domain/components/UiButton.vue'
import BaseConfirm from '@/domain/confirms/BaseConfirm.vue'

export default {
    name: 'EmployeeAdditionalHolidays',
    components: {
        BaseConfirm,
        UiButton,
        HraEmployeeCrudTable,
        HraTooltip,
        HraTableHeader,
        HraTableEditableInlineTemplate,
        HraButtonModal,
        TableTitleView,
        HraTable
    },
    props: {
        readOnly: { type: Boolean, default: false }
    },
    data() {
        return {
            headers: [
                { name: 'from', style: { width: '150px' } },
                { name: 'comment', style: { width: '500px' } },
                { name: 'count', style: { minWidth: '100px' } }
            ]
        }
    },
    computed: {
        ...mapGetters({
            userId: 'employeeStore/userId',
            rows: 'employeeStore/additionalHolidays'
        })
    },
    methods: {
        async onCreate(tag, payload) {
            return await this.$store.dispatch('employeeStore/createAdditionalHoliday', { tag, payload })
        },
        async onUpdate(tag, payload, id) {
            return await this.$store.dispatch('employeeStore/updateAdditionalHoliday', { tag, id, payload })
        },
        async onDelete(confirm, id) {
            if (await this.$store.dispatch('employeeStore/destroyAdditionalHoliday', { tag: confirm.tag, id })) {
                confirm?.close()
            }
        }
    }
}
</script>
