import CustomApi from '@/plugins/customApi'
import {
    mapFolders,
    mapGroups,
    mapGroupsFolders
} from '@/domain/documents/finder/store/mappers'
import groupsFolderActions from '@/domain/documents/finder/store/actions/groupsFolderActions'
import groupActions from '@/domain/documents/finder/store/actions/groupActions'

const namespaced = true
function getMode() {
    const modeFromStore = localStorage.getItem('documents-finder-mode')

    if (['grid', 'list'].includes(modeFromStore)) {
        return modeFromStore
    }

    return 'grid'
}

const state = () => ({
    groupsFolderId: null,
    loading: false,
    loadingMore: false,
    mode: getMode(),
    search: '',
    limit: 35,
    hasMore: false,
    breadcrumbs: [],
    groupsFolders: [],
    groups: [],
    historyNext: []
})

const getters = {
    groupsFolderId: ({ groupsFolderId }) => groupsFolderId,
    loading: ({ loading }) => loading,
    loadingMore: ({ loadingMore }) => loadingMore,
    visibleLoadMore: ({hasMore}) => hasMore,
    search: ({search}) => search,
    breadcrumbs: ({breadcrumbs}) => breadcrumbs,
    mode: ({mode}) => mode,
    title: ({breadcrumbs}) => breadcrumbs[breadcrumbs.length - 1]?.title ?? null,
    groupsFolders: ({ groupsFolders }) => groupsFolders,
    groups: ({ groups }) => groups,
    isSelectedAllDocuments: ({ documents }) => {
        if (documents.length === 0) {
            return false
        }

        const canSelectCount = documents.filter(({ item }) => item.canSelectToSend).length

        return documents.filter(({ selected }) => selected).length === canSelectCount
    },
    selectedDocuments: ({ documents }) => {
        return documents.filter(({ selected }) => selected).map(({ item }) => item)
    },
    hasHistoryNext: ({historyNext}) => !!historyNext.length,
    hasHistoryPrev: ({breadcrumbs}) => !!breadcrumbs.length
}

const customApi = CustomApi()
const actions = {
    setWith({state}, payload) {
        state.with = payload
    },
    getFetchParams({state}, payload) {
        const params = {
            ...payload,
            limit: state.limit
        }

        if (state.search != null && state.search !== '') {
            params.search = state.search
        }

        return params
    },
    async fetch({dispatch, state}, payload = null){
        try {
            const { data: { items, hasMore } } = await customApi.factories.documents
                .groups.folders.fetch(state.groupsFolderId, await dispatch('getFetchParams', payload ?? {}))

            const { folders, groups, breadcrumbs } = items

            if (payload == null) {
                state.groupsFolders = mapGroupsFolders(folders)
                state.groups = mapGroups(groups)
            } else {
                state.groupsFolders.push(...mapFolders(folders))
                state.groups.push(...mapGroups(groups))
            }

            state.breadcrumbs = breadcrumbs
            state.hasMore = hasMore
            return true
        } catch (error) {
            console.error(error)
            return false
        }
    },
    async load({dispatch, state}) {
        if (state.loading) {
            return
        }

        state.historyNext = []
        state.loading = true
        await dispatch('fetch')
        state.loading = false
    },
    async loadMore({dispatch, state}) {
        if (state.loadingMore) {
            return
        }

        state.loadingMore = true
        await dispatch('fetch', {
            foldersCount: state.folders.length,
            documentsCount: state.documents.length
        })
        state.loadingMore = false
    },
    async ['breadcrumbs/changeId']({state, dispatch}, payload) {
        state.groupsFolderId = payload
        state.search = ''
        dispatch('load')
    },
    async ['history/prev']({dispatch, state}) {
        const groupsFolderId = state.breadcrumbs[state.breadcrumbs.length - 2]?.id ?? null
        state.loading = true
        state.historyNext.push(state.groupsFolderId)
        state.groupsFolderId = groupsFolderId
        await dispatch('fetch')
        state.loading = false
    },
    async ['history/forward']({dispatch, state}) {
        state.loading = true
        state.groupsFolderId = state.historyNext.pop()
        await dispatch('fetch')
        state.loading = false
    },
    ['mode/change']({state}, payload) {
        localStorage.setItem('documents-finder-mode', payload)
        state.mode = payload
    },
    ['search/change']({state, dispatch}, payload) {
        state.groupsFolderId = null
        state.search = payload
        dispatch('load')
    },
    ...groupsFolderActions,
    ...groupActions
}

export default {
    namespaced,
    state,
    actions,
    getters
}