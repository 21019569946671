<template>
  <v-col v-if="loading" cols="12">
    <div class="d-flex justify-center">
      <v-progress-circular indeterminate size="32" width="2" color="primary" />
    </div>
  </v-col>
  <div v-else>
    <iframe :src="iframeSrc" width="100%" height="700px"></iframe>
  </div>
</template>

<script>
import * as XLSX from 'xlsx';

export default {
  props: {
    fileUrl: String,
  },
  data() {
    return {
      iframeSrc: null,
      loading: true,
    };
  },
  async mounted() {
    await this.previewXLS();
    this.loading = false;
  },
  methods: {
    async previewXLS() {
      if (this.fileUrl) {
        const response = await fetch(this.fileUrl);
        const blob = await response.blob();
        const reader = new FileReader();

        reader.onload = async (event) => {
          const arrayBuffer = event.target.result;
          const data = new Uint8Array(arrayBuffer);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const html = XLSX.utils.sheet_to_html(sheet);
          const encodedHtml = encodeURIComponent(html);

          this.iframeSrc = `data:text/html;charset=utf-8,${encodedHtml}`;
        };

        reader.readAsArrayBuffer(blob);
      }
    },
  },
};
</script>

<style>

</style>
