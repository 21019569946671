/*
* @param start - moment
* @param end - moment | null
* @returns - Array
* */
import _ from 'lodash'
import i18n from '@/plugins/i18n'
import moment from 'moment'
import router from '@/router'

export const getDateArray = (start, end, duration = null, format = 'YYYY-MM-DD') => {
    if(isNaN(start) || isNaN(end)) {
        return [];
    }

    if (duration == null) {
        duration = getDateDiffDaysCount(start, end) + 1
    }

    return Array(duration).fill(null).map((_, i) => {
        const date = start.clone().add(i, 'days')

        if (typeof format === 'function') {
            return format(date)
        }

        return date.format(format)
    })
}

/*
* @param start - moment
* @param end - moment | null
* @returns - number
* */
export const getDateDiffDaysCount = (start, end, abs = true) => {
    let diff = end.diff(start, 'days')
    return abs ? Math.abs(diff) : diff
}

export const UI_COLORS = {
    default: 'default',
    primary: 'primary',
    danger: 'danger',
    success: 'success',
    info: 'info',
    primaryLight: 'primary-light',
    violetDark: 'violet-dark',
    dangerLight: 'danger-light',
    warningLight: 'warning-light',
    successLight: 'success-light',
    white: 'white'
}

export const convertRowToInlineRowData = (fields, row) => {
    return _.mapValues(row, (value, key) => {
        if (key === 'id') {
            return value
        }

        const field = fields[key]
        let valid = true
        if (field?.valid === false) {
            valid = false
        }

        return {
            icon: field?.icon || '',
            multiple: field?.multiple || 0,
            rules: field?.rules || '',
            type: field?.type || 'text',
            unit: field?.unit || '',
            options: field?.options || [],
            valid,
            value
        }
    })
}

export const yesNoOptions = [
    { value: 0, text: i18n.t('Ne') },
    { value: 1, text: i18n.t('Taip') }
]

export const asyncTimeout = async (delay) => {
    return new Promise(resolve => {
        setTimeout(resolve, delay)
    })
}

export const VACATION_TYPE_YEARLY = 1 // Kasmetinės atostogos
export const VACATION_TYPE_FATHER_OR_MOTHER = 2 // Tėvadienis/mamadienis
export const VACATION_TYPE_MATERNITY = 3 // Nėštumo ir gimdymo atostogos
export const VACATION_TYPE_PARENTAL = 4 // Tėvystės atostogos
export const VACATION_TYPE_CHILDCARE = 5 // Atostogos vaikui prižiūrėti
export const VACATION_TYPE_STUDY = 6 // Mokymosi atostogos
export const VACATION_TYPE_CREATIVE = 7 // Kūrybinės atostogos
export const VACATION_TYPE_PUBLIC = 8 // Atostogos valstybinėms ar visuomeninėms pareigoms atlikti
export const VACATION_TYPE_FREE = 9 // Nemokamos atostogos
export const VACATION_TYPE_ONE_WORK_DAY = 10 // Nemokamas laisvas laikas (viena darbo diena)
export const VACATION_TYPE_FIRST_SCHOOL_DAY = 11 // Pirmoji mokslo metų diena
export const VACATION_TYPE_OTHER = 12 // Kitokios atostogos

export const vacationType = {
    [VACATION_TYPE_YEARLY]: i18n.t('Kasmetinės atostogos'),
    [VACATION_TYPE_FATHER_OR_MOTHER]: i18n.t('Tėvadienis / mamadienis'),
    [VACATION_TYPE_MATERNITY]: i18n.t('Nėštumo ir gimdymo atostogos'),
    [VACATION_TYPE_PARENTAL]: i18n.t('Tėvystės atostogos'),
    [VACATION_TYPE_CHILDCARE]: i18n.t('Atostogos vaikui prižiūrėti'),
    [VACATION_TYPE_STUDY]: i18n.t('Mokymosi atostogos'),
    [VACATION_TYPE_CREATIVE]: i18n.t('Kūrybinės atostogos'),
    [VACATION_TYPE_PUBLIC]: i18n.t('Atostogos valstybinėms ar visuomeninėms pareigoms atlikti'),
    [VACATION_TYPE_FREE]: i18n.t('Neapmokamos atostogos'),
    [VACATION_TYPE_ONE_WORK_DAY]: i18n.t('Neapmokamas laisvas laikas (viena darbo diena)'),
    [VACATION_TYPE_FIRST_SCHOOL_DAY]: i18n.t('Pirmoji mokslo metų diena')
    // 12: i18n.t("Kitokios atostogos"),
}

export const VACATION_STATUS_WAIT = 101
export const VACATION_STATUS_APPROVE = 102
export const VACATION_STATUS_PAST = 103
export const VACATION_STATUS_ABORT = 200
export const VACATION_STATUS_ABORT_SYSTEM = 201
export const VACATION_STATUS_ABORT_EMPLOYEE = 202
export const VACATION_STATUS_ABORT_ADMIN = 203
export const VACATION_STATUS_CHANGED_EMPLOYEE = 301

export const vacationStatus = {
    [VACATION_STATUS_WAIT]: i18n.t('laukiančios patvirtinimo'),
    [VACATION_STATUS_APPROVE]: i18n.t('patvirtintos'),
    [VACATION_STATUS_PAST]: i18n.t('įvykusios'),
    [VACATION_STATUS_ABORT]: i18n.t('atšauktos'),
    [VACATION_STATUS_ABORT_SYSTEM]: i18n.t('atšauktos'),
    [VACATION_STATUS_ABORT_EMPLOYEE]: i18n.t('atšauktos'),
    [VACATION_STATUS_ABORT_ADMIN]: i18n.t('atmestos'),
    [VACATION_STATUS_CHANGED_EMPLOYEE]: i18n.t('atšauktos')
}

const nowDate = moment().format('YYYY-MM-DD')

export const getEventStatusText = (end, status) => {
    if (status === 'aborted') {
        return i18n.t('atšauktos')
    }

    if (status === 'created') {
        return i18n.t('laukiančios patvirtinimo')
    }

    if (status === 'pending') {
        return i18n.t('laukiančios patvirtinimo')
    }

    if (end < nowDate) {
        return i18n.t('įvykusios')
    }

    return i18n.t('patvirtintos')
}

export const generateUuid = (withoutDash = true) => (
    withoutDash
        ? 'ixxxxxxxxyxxxxy4xxxyyxxxyxxxxxxxxxxxx'
        : 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
)
    .replace(/[xy]/g, function(c) {
        let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
    })


const buildFormData = (formData, data, parentKey) => {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
        })
    } else {
        let value = data == null ? '' : data
        if (typeof value === 'boolean') {
            value = value ? 1 : 0
        }

        formData.append(parentKey, value)
    }
}

export const jsonToFormData = data => {
    const formData = new FormData()
    buildFormData(formData, data)
    return formData
}

export const capitalizeFirstLetter = text => {
    return text.charAt(0).toUpperCase() + text.slice(1)
}

export const GLOBAL_TOAST_TAG = 'GLOBAL_TOAST_TAG'

export const redirectToAuth = (redirectTo = null) => {
    /* redirect to landing */
    // let url = window.location.protocol + '//'
    // url += window.location.hostname.split('.').slice(-2).join('.')
    const host = window.location.protocol
        + '//'
        + window.location.hostname.split('.').slice(-2).join('.')

    const url = new URL('/auth#login', host)
    url.searchParams.set('redirectTo', redirectTo ?? window.location.href);
    console.debug('redirectToAuth', url.href)
    window.location.href = url.href
    // window.location.href = url + '/auth'
}

export const EVENT_HOLIDAY_TYPE_YEARLY = 1001 // Kasmetinės atostogos
export const EVENT_HOLIDAY_TYPE_FATHER_OR_MOTHER = 1002 // Tėvadienis/mamadienis
export const EVENT_HOLIDAY_TYPE_MATERNITY = 1003 // Nėštumo ir gimdymo atostogos
export const EVENT_HOLIDAY_TYPE_PARENTAL = 1004 // Tėvystės atostogos
export const EVENT_HOLIDAY_TYPE_CHILDCARE = 1005 // Atostogos vaikui prižiūrėti
export const EVENT_HOLIDAY_TYPE_STUDY = 1006 // Mokymosi atostogos
export const EVENT_HOLIDAY_TYPE_CREATIVE = 1007 // Kūrybinės atostogos
export const EVENT_HOLIDAY_TYPE_PUBLIC = 1008 // Atostogos valstybinėms ar visuomeninėms pareigoms atlikti
export const EVENT_HOLIDAY_TYPE_FREE = 1009 // Nemokamos atostogos
export const EVENT_HOLIDAY_TYPE_ONE_WORK_DAY = 1010 // Nemokamas laisvas laikas (viena darbo diena)
export const EVENT_HOLIDAY_TYPE_FIRST_SCHOOL_DAY = 1011 // Pirmoji mokslo metų diena
export const EVENT_HOLIDAY_TYPE_OTHER = 1012 // Kitokios atostogos
export const EVENT_HOLIDAY_TYPE_DEPUTY = 1013
export const EVENT_HOLIDAY_TYPE_REMOTE_WORK = 3001;
export const EVENT_SICK_LEAVE = 4001;
export const EVENT_BUSINESS_TRIP_LEADER = 5001;
export const EVENT_BUSINESS_TRIP_EMPLOYEE = 5002;

export const allEventTypes = {
    EVENT_HOLIDAY_TYPE_YEARLY,
    EVENT_HOLIDAY_TYPE_FATHER_OR_MOTHER,
    EVENT_HOLIDAY_TYPE_MATERNITY,
    EVENT_HOLIDAY_TYPE_PARENTAL,
    EVENT_HOLIDAY_TYPE_CHILDCARE,
    EVENT_HOLIDAY_TYPE_STUDY,
    EVENT_HOLIDAY_TYPE_CREATIVE,
    EVENT_HOLIDAY_TYPE_PUBLIC,
    EVENT_HOLIDAY_TYPE_FREE,
    EVENT_HOLIDAY_TYPE_ONE_WORK_DAY,
    EVENT_HOLIDAY_TYPE_FIRST_SCHOOL_DAY,
    EVENT_HOLIDAY_TYPE_OTHER,
    EVENT_HOLIDAY_TYPE_DEPUTY,
    EVENT_SICK_LEAVE,
    EVENT_BUSINESS_TRIP_LEADER,
    EVENT_BUSINESS_TRIP_EMPLOYEE,
    EVENT_HOLIDAY_TYPE_REMOTE_WORK
}

export const eventHolidayTypes = [
    EVENT_HOLIDAY_TYPE_YEARLY,
    EVENT_HOLIDAY_TYPE_FATHER_OR_MOTHER,
    EVENT_HOLIDAY_TYPE_MATERNITY,
    EVENT_HOLIDAY_TYPE_PARENTAL,
    EVENT_HOLIDAY_TYPE_CHILDCARE,
    EVENT_HOLIDAY_TYPE_STUDY,
    EVENT_HOLIDAY_TYPE_CREATIVE,
    EVENT_HOLIDAY_TYPE_PUBLIC,
    EVENT_HOLIDAY_TYPE_FREE,
    EVENT_HOLIDAY_TYPE_ONE_WORK_DAY,
    EVENT_HOLIDAY_TYPE_FIRST_SCHOOL_DAY,
    // EVENT_HOLIDAY_TYPE_REMOTE_WORK
    // EVENT_HOLIDAY_TYPE_OTHER
]

export const eventHolidayType = {
    [EVENT_HOLIDAY_TYPE_YEARLY]: i18n.t('Kasmetinės atostogos'),
    [EVENT_HOLIDAY_TYPE_FATHER_OR_MOTHER]: i18n.t('Tėvadienis / mamadienis'),
    [EVENT_HOLIDAY_TYPE_MATERNITY]: i18n.t('Nėštumo ir gimdymo atostogos'),
    [EVENT_HOLIDAY_TYPE_PARENTAL]: i18n.t('Tėvystės atostogos'),
    [EVENT_HOLIDAY_TYPE_CHILDCARE]: i18n.t('Atostogos vaikui prižiūrėti'),
    [EVENT_HOLIDAY_TYPE_STUDY]: i18n.t('Mokymosi atostogos'),
    [EVENT_HOLIDAY_TYPE_CREATIVE]: i18n.t('Kūrybinės atostogos'),
    [EVENT_HOLIDAY_TYPE_PUBLIC]: i18n.t('Atostogos valstybinėms ar visuomeninėms pareigoms atlikti'),
    [EVENT_HOLIDAY_TYPE_FREE]: i18n.t('Neapmokamos atostogos'),
    [EVENT_HOLIDAY_TYPE_ONE_WORK_DAY]: i18n.t('Neapmokamas laisvas laikas (viena darbo diena)'),
    [EVENT_HOLIDAY_TYPE_FIRST_SCHOOL_DAY]: i18n.t('Pirmoji mokslo metų diena'),
    [EVENT_HOLIDAY_TYPE_REMOTE_WORK]: i18n.t('Remote work'),
    [EVENT_HOLIDAY_TYPE_DEPUTY]: i18n.t('Pavadavimas'),
    [EVENT_SICK_LEAVE]: i18n.t('Ligonlaipis'),
    [EVENT_BUSINESS_TRIP_LEADER]: i18n.t('Komandiruotė įsakymas'),
    [EVENT_BUSINESS_TRIP_EMPLOYEE]: i18n.t('Komandiruotė prašymas')
    // 12: i18n.t("Kitokios atostogos"),
}

export const query = obj => {
    const params = {}

    Object.keys(obj).map((key) => {
        if (obj[key] !== undefined) {
            return (params[key] = obj[key])
        }
    })

    return '?' + (new URLSearchParams(params)).toString()
}


export const availableHolidayPayTypes = [
    { value: 'pay_after', title: i18n.t('Išmokėti kartu su pareiginiu atlyginimu') },
    { value: 'pay_before', title: i18n.t('Išmokėti prieš atostogas') },
    { value: 'pay_with_salary_by_month', title: i18n.t('Išmoketi kartu su atlyginimais skaidant pamėnesiui') }
]