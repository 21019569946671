<template>
  <custom-dialog :max-width="670">
    <template #title>{{ $t('Atostogų prašymas') }}</template>
    <template #content>
      <text-field v-model="employeeId2" />
      <datepicker-field v-model="fromDate" />
      <datepicker-field v-model="toDate" />
      <ui-button @click="checkDays">check days</ui-button>
<!--      <validation-form>-->
<!--        <v-row class="pa-3">-->
<!--          <v-col cols="12">-->
<!--            <h1>Pasirinkite kitą norimą temą prie teisinės informacijos:</h1>-->
<!--          </v-col>-->
<!--          <v-col cols="12">-->
<!--            <form-radio-->
<!--                v-for="item in list"-->
<!--                :key="item.name"-->
<!--                name="test"-->
<!--                :radio-value="item.name"-->
<!--            ><p class="my-2 ms-3">{{ item.text }}</p></form-radio>-->
<!--          </v-col>-->
<!--        </v-row>-->
<!--      </validation-form>-->
    </template>

    <template #footer>
<!--      <ui-button-->
<!--          prepend-icon="close"-->
<!--          background="default"-->
<!--          @click="close"-->
<!--      >{{ $t('Uždaryti') }}-->
<!--      </ui-button>-->
<!--      <ui-button-->
<!--          prepend-icon="ok"-->
<!--          background="primary"-->
<!--      >{{ $t('Pasirinkti') }}-->
<!--      </ui-button>-->
    </template>
  </custom-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialogMixin";
import Form from "@/components/ui/form/Form.vue";
import TextField from "@/domain/fields/TextField.vue";
import MessageAlerts from "@/domain/messages/MessageAlerts.vue";
import SelectField from "@/domain/fields/SelectField.vue";
import UiButton from "@/domain/components/UiButton.vue";
import EmployeeTableView from "@/components/ui/views/EmployeeTableView.vue";
import ValidationForm from "@/domain/components/ValidationForm.vue";
import CustomDialog from "@/components/ui/dialog/CustomDialog.vue";
import {CREATE_DUTY_TAG} from "@/domain/company/store/tags";
import {GLOBAL_TOAST_TAG} from "@/utils/default";
import FormCheckbox from "@/components/ui/form/FormCheckbox.vue";
import FormRadio from "@/components/ui/form/FormRadio.vue";
import DatepickerField from "@/domain/fields/DatepickerField.vue";

export default {
  name: "EventModal",
  mixins: [dialogMixin],
  props: {
    id: {type: Number, default: null},
    employeeId: {type: Number, required: true},
  },
  components: {
    DatepickerField,
    FormRadio,
    FormCheckbox,
    CustomDialog,
    ValidationForm,
    EmployeeTableView,
    UiButton, SelectField, MessageAlerts, TextField, Form
  },
  data() {
    return {
      type: 'vacation_yearly',
      employeeId2: 1,
      fromDate: '2023-04-10',
      toDate: '2023-04-19',
    };
  },
  methods: {
    checkDays() {
      this.$store.dispatch("eventsStore/checkDays", {
        type: this.type,
        fromDate: this.fromDate,
        toDate: this.toDate,
        employeeId2: this.employeeId2,
      });
    }
  },
}
</script>