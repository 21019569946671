<template>
  <app-layout>
    <div class="page page_with-background">
      <route-tabs class="pt-5" :tabs="tabs"/>
    </div>
  </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import CustomTabs from "@/domain/components/CustomTabs";
import RouteTabs from "@/domain/components/RouteTabs";
import {permissionOnlyAdmin} from "@/utils/permissions";

export default {
  name: "CompanyLayout",
  components: {RouteTabs, CustomTabs, AppLayout},
  computed: {
    tabs() {
      if (this.hasPermissionTo(permissionOnlyAdmin)) {
        return [
          {name: "CompanyTreePage", text: this.$t("Įmonės struktūra")},
          {name: "CompanyDutiesPage", text: this.$t("Pareigybės")},
        ];
      }

      return [
        {name: "CompanyTreePage", text: this.$t("Įmonės struktūra")},
      ];
    },
  }
}
</script>