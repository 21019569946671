<template>
    <div class='d-flex flex-column gap-2'>
        <text-field
            :label='$t("setting.module.SettingHolidayAllOneOfTheConfirmingStaffIsNotAvailable.form.days")'
            v-model='config.days'
            rules='required'
            :disabled='!isEdit'
        />
        <select-field
            :label='$t("setting.module.SettingHolidayAllOneOfTheConfirmingStaffIsNotAvailable.form.type")'
            v-model='config.type'
            :options='typeOptions'
            rules='required'
            :disabled='!isEdit'
        />
        <employee-multi-select
            v-if='canSelectAdmins'
            :label='$t("setting.module.SettingHolidayAllOneOfTheConfirmingStaffIsNotAvailable.form.adminIds")'
            only-admins
            v-model='config.adminIds'
            rules='required'
            :disabled='!isEdit'
        />
    </div>
</template>

<script>
import MultilineSelectField from '@/domain/components/MultilineSelectField.vue'
import SelectField from '@/domain/fields/SelectField.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import TextField from '@/domain/fields/TextField.vue'
import EmployeeMultiSelect from '@/domain/fields/EmployeeMultiSelect.vue'

export default {
    name: 'SettingHolidayAllOneOfTheConfirmingStaffIsNotAvailableConfigForm',
    components: { EmployeeMultiSelect, TextField, FormCheckbox, SelectField, MultilineSelectField },
    props: {
        isEdit: { type: Boolean, default: false },
        config: { type: Object, default: () => ({}) }
    },
    data() {
        return {
            typesWhenCanSelectAdmins: ['can_sent_request_and_send_notifications_admins', 'can_not_sent_request'],
            typeOptions: [
                { value: 'can_sent_request' },
                { value: 'can_sent_request_and_send_notifications_admins' },
                { value: 'can_not_sent_request' }
            ].map(option => ({
                ...option,
                text: this.$t(`setting.module.SettingHolidayAllOneOfTheConfirmingStaffIsNotAvailable.form.typeOptions.${option.value}`)
            }))
        }
    },
    computed: {
        canSelectAdmins() {
            return this.typesWhenCanSelectAdmins.includes(this.config.type)
        }
    },
    watch: {
        'config.type': {
            handler(type) {
                if (!this.typesWhenCanSelectAdmins.includes(type)) {
                    this.config.adminIds = []
                }
            }
        }
    }
}
</script>