<template>
    <custom-dialog :max-width='550'>
        <template #title>{{ $t('Naujas aplankas')}}</template>
        <template #content>
            <v-row class='pa-3'>
                <v-col cols='12'>
                    <text-field v-model='title' :label="$t('Pavadinimas')" name='name' rules='required' />
                </v-col>
            </v-row>
        </template>
        <template #footer>
            <ui-button background='default' prepend-icon='close' @click='close'>{{ $t('Uždaryti') }}</ui-button>
            <ui-button :disabled='!title' :loading='loading' background='primary' prepend-icon='ok' @click='handleSubmit'>
                {{ $t('Sukurti') }}
            </ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import dialogMixin from '@/mixins/dialogMixin'
import UiButton from '@/domain/components/UiButton.vue'
import TextField from '@/domain/fields/TextField.vue'
import customApi from '@/plugins/customApi'
import { GLOBAL_TOAST_TAG } from '@/utils/default'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'

export default {
    name: 'GroupsFolderCreateModal',
    components: { CustomDialog, UiButton, TextField },
    mixins: [dialogMixin],
    props: {
        parentId: Number
    },
    data() {
        return {
            loading: false,
            title: ''
        }
    },
    methods: {
        async handleSubmit() {
            this.loading = true

            try {
                let payload = {
                    title: this.title
                }

                if (!!this.parentId) {
                    payload.parentId = this.parentId
                }

                const {data} = await customApi().factories.documents.groups.folders.create(payload)

                this.$emit('folderCreated', data)
                await this.$store.dispatch('messagesStore/showSuccessMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Išsaugotą sekmingai')
                })
                this.close()
            } catch (error) {
                await this.$store.dispatch('messagesStore/showErrorMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: error?.response?.data?.message[0] ?? this.$t('Serverio klaida')
                })
            } finally {
                this.loading = false
            }
        }
    }
}
</script>