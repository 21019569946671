import modalMethodsMixin from '@/domain/mixins/modalMethodsMixin'
import documentsItemsSidebars from '@/domain/documents/items/mixins/documentsItemsSidebars'

export default {
    props: {
        item: { type: Object, required: true }
    },
    mixins: [modalMethodsMixin, documentsItemsSidebars],
    inject: ['withStatus', 'withControls', 'selectable', 'storeNamespace', 'withSidebarAction'],
    computed: {
        document() {
            return this.item.item
        },
        accepted() {
            if (this.document.sentToUsersCount == null || this.document.sentToUsersCount === 0) {
                return '-'
            }

            return `${(this.document.sentToUsersAcceptedCount ?? 0)} / ${this.document.sentToUsersCount}`
        },
        status() {
            if (this.document.sentToUsersCount == null || this.document.sentToUsersCount === 0) {
                return 'new'
            }

            return (this.document.sentToUsersAcceptedCount ?? 0) === this.document.sentToUsersCount
                ? 'accepted' : 'pending'
        }
    },
    methods: {
        handleDocumentToggleSelect() {
            this.$store.dispatch(`${this.storeNamespace}/documents/toggleSelect`, this.document.id)
        },
        handleOpenDocumentDetailsSidebar() {
            if (!this.withSidebarAction) {
                return
            }

            this.openDocumentLastHistorySidebar({item: this.document})
        },
        handleOpenDocumentsSendModal() {
            this.closeSidebar()
            this.onOpenModal(
                'DocumentSendModal',
                {
                    documents: [this.document]
                },
                {
                    sent: (payload) => {
                        this.$store.dispatch(`${this.storeNamespace}/documents/updateMany`, payload)
                    }
                }
            )
        },
        handleOpenEditDocumentModal() {
            this.closeSidebar()
            this.onOpenModal(
                'DocumentEditModal',
                {
                    item: this.document
                },
                {
                    documentUpdated: (payload) => {
                        this.$store.dispatch(`${this.storeNamespace}/documents/update`, payload)
                    }
                }
            )
        },
        handleOpenPreviewDocumentModal() {
            this.closeSidebar()
            this.onOpenModal('DocumentPreviewModal', { item: this.document })
        },
        handleOpenHistoryDocumentModal() {
            this.closeSidebar()
            this.onOpenModal('DocumentHistoryModal', { item: this.document })
        },
        handleOpenMoveDocumentModal() {
            this.closeSidebar()
            this.onOpenModal(
                'DocumentMoveModal',
                { item: this.document },
                {
                    documentMoved: (payload) => {
                        this.$store.dispatch(`${this.storeNamespace}/documents/delete`, payload.id)
                    }
                }
            )
        },
        handleOpenArchiveDocumentModal() {
            this.closeSidebar()
            this.onOpenModal(
                'DocumentArchiveModal',
                {
                    item: this.document
                },
                {
                    documentArchived: (payload) => {
                        this.$store.dispatch(`${this.storeNamespace}/documents/delete`, payload)
                    }
                }
            )
        }
    }
}