<template>
    <tr>
        <td @click='handleChangeFolder'>
            <div class='d-flex flex-row' :class='{["pl-8"]: selectable}'>
                <ui-icon style='color: #8689A8'>folder</ui-icon>
                <span
                    class='ml-2'
                >{{ folder.title }}</span>
            </div>
        </td>
        <td v-if='withStatus'>-</td>
        <td v-if='withStatus'>-</td>
        <td>{{ folder.sizeText }}</td>
        <td>{{ folder.createdAt }}</td>
        <td>{{ folder.updatedAt }}</td>
        <td v-if='withControls'>
            <div class='d-flex flex-row align-center justify-end'>
                <v-spacer />
                <documents-finder-context-menu>
                    <documents-finder-context-menu-item
                        icon='edit'
                        :title='$t("Redaguoti")'
                        @click='handleOpenUpdateFolderModal'
                    />
                    <documents-finder-context-menu-item
                        v-if='isDeletable'
                        icon='bin'
                        :title='$t("Ištrinti")'
                        @click='handleOpenDeleteFolderModal'
                    />
                </documents-finder-context-menu>
            </div>
        </td>
    </tr>
</template>

<script>
import UiIcon from '@/domain/components/UiIcon.vue'
import DocumentsFinderContextMenu from '@/domain/documents/finder/components/ContextMenu.vue'
import DocumentsFinderContextMenuItem from '@/domain/documents/finder/components/ContextMenuItem.vue'
import documentFinderFolderItemMixin from '@/domain/documents/finder/mixins/documentFinderFolderItemMixin'

export default {
    name: 'ListFolderItem',
    components: { DocumentsFinderContextMenuItem, DocumentsFinderContextMenu, UiIcon },
    mixins: [documentFinderFolderItemMixin],
}
</script>