import CustomApi from '@/plugins/customApi'
import Vue from 'vue'

const state = () => ({
    fetching: [],
    defaultOptions: {
        admins: null,
        staffWithApprovals: null,
        all: null
    }
})

const getters = {
    defaultOptions: state => state.defaultOptions
}

const customApi = CustomApi()
const actions = {
    async fetchDefaultOptions({ state }, payload) {
        if (state.defaultOptions[payload] != null) {
            return
        }

        if (state.fetching.includes(payload)) {
            return
        }

        try {
            state.fetching.push(payload)
            const { data: { items } } = await customApi.factories.employee.fetchByFilter({
                onlyAdmins: payload === 'admins',
                onlyLeaderAndApprovalStaff: payload === 'staffWithApprovals',
                limit: 20,
                sortBy: ['name,asc']
            })
            Vue.set(state.defaultOptions, payload, items)
        } catch (e) {
            console.error(e)
        } finally {
            state.fetching = state.fetching.filter(x => x !== payload)
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions
}