<template>
    <setting-module-item
        :title='title'
        :description='description'
        :is-edit='isEdit'
        @edit='handleEdit'
        @save='handleSave'
        @cancel='handleCancel'
    >
        <template #company-config>
            <setting-module-item-config-company>
                <setting-remote-work-employee-visible-company-events-config-form
                    :is-edit='isEdit'
                    :config='formData.company'
                />
            </setting-module-item-config-company>
        </template>

        <template #departments-config>
            <setting-module-item-more
                :is-edit='isEdit'
                :count='formData.departments.length'
                @addDepartment='handleAddDepartment'
            >
                <setting-module-item-config-department
                    v-for='(department) in formData.departments'
                    :key='department.uuid'
                    :department-ids='department.departmentIds'
                    :is-edit='isEdit'
                    @changeDepartments='($event) => handleChangeDepartment(department, $event)'
                    @removeDepartment='() => handleRemoveDepartment(department.uuid)'
                >
                    <setting-remote-work-employee-visible-company-events-config-form
                        :is-edit='isEdit'
                        :config='department'
                    />
                </setting-module-item-config-department>
            </setting-module-item-more>
        </template>
    </setting-module-item>
</template>

<script>
import SettingModuleItem from '@/domain/settings/components/SettingModuleItem.vue'
import SettingModuleItemConfig from '@/domain/settings/components/SettingModuleItemConfig.vue'
import settingModuleItemMixin from '@/domain/settings/mixins/settingModuleItemMixin'
import { cloneDeep } from 'lodash'
import { generateUuid } from '@/utils/default'
import SettingModuleItemConfigDepartment from '@/domain/settings/components/SettingModuleItemConfigDepartment.vue'
import SettingModuleItemMore from '@/domain/settings/components/SettingModuleItemMore.vue'
import SettingHolidayAllAvailablePayTypesForm
    from '@/domain/settings/components/holiday/allAvailablePayTypes/ConfigForm.vue'
import SettingModuleItemConfigCompany from '@/domain/settings/components/SettingModuleItemConfigCompany.vue'
import SettingRemoteWorkEmployeeVisibleCompanyEventsConfigForm
    from '@/domain/settings/components/remoteWork/employeeVisibleCompanyEvents/ConfigForm.vue'

export default {
    name: 'SettingRemoteWorkEmployeeVisibleCompanyEvents',
    components: {
        SettingRemoteWorkEmployeeVisibleCompanyEventsConfigForm,
        SettingModuleItemConfigCompany,
        SettingHolidayAllAvailablePayTypesForm,
        SettingModuleItemMore,
        SettingModuleItemConfigDepartment,
        SettingModuleItemConfig,
        SettingModuleItem
    },
    mixins: [settingModuleItemMixin],
    data() {
        const defaultFormData = {
            mode: 'all',
            hideOtherDepartments: false,
            showAllDepartmentApprovalEmployees: false
        }

        return {
            isEdit: false,
            defaultFormData,
            formData: {
                company: cloneDeep(defaultFormData),
                departments: []
            }
        }
    },
    methods: {
        setFormDataFromSettings() {
            this.setFormDataCompany({
                mode: this.company.config.mode,
                hideOtherDepartments: this.company.config.hideOtherDepartments,
                showAllDepartmentApprovalEmployees: this.company.config.showAllDepartmentApprovalEmployees
            })
            this.setFormDataDepartments(this.departments.map(payload => ({
                uuid: generateUuid(),
                departmentIds: payload.departmentIds,
                mode: payload.config.mode,
                hideOtherDepartments: payload.config.hideOtherDepartments,
                showAllDepartmentApprovalEmployees: payload.config.showAllDepartmentApprovalEmployees
            })))
        },
        getFromFormDataCompanyPayload() {
            return {
                active: true,
                config: {
                    mode: this.formData.company.mode,
                    hideOtherDepartments: this.formData.company.hideOtherDepartments,
                    showAllDepartmentApprovalEmployees: this.formData.company.showAllDepartmentApprovalEmployees
                }
            }
        },
        getFromFormDataDepartmentsPayload() {
            return this.formData.departments.map(payload => ({
                active: true,
                config: {
                    mode: payload.mode,
                    hideOtherDepartments: payload.hideOtherDepartments,
                    showAllDepartmentApprovalEmployees: payload.showAllDepartmentApprovalEmployees
                },
                departmentIds: payload.departmentIds
            }))
        },
        async handleSave() {
            await this.saveAction('remote_work')
        }
    }
}
</script>