<template>
	<hra-table
		class="pa-3"
		:headers="headers"
		:rows="rows"
		need-actions
	>

		<template #col.actions-base>
		</template>

	</hra-table>
</template>

<script>
import HraTable from "@/components/ui/table/HraTable.vue";
import HraButton from "@/components/ui/button/HraButton.vue";

export default {
	name: "DisabilitiesTable",
	components: {HraButton, HraTable},
	props: {
		id: {type: String, required: true},
	},
	data() {
		return {
			headers: [
				{name: "number", text: this.$t("Darbo pažymėjimo nr. *")},
				{name: "fromDate", text: this.$t("Neįgalumas nuo data *")},
				{name: "toDate", text: this.$t("Neįgalumas iki data *")},
			],
		}
	},
	computed: {
		rows() {
			return this.$store.getters["setupStore/importEmployeeData"](this.id, "disabilities");
		},
	},
}
</script>