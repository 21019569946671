<template>
	<hra-table
		class="pa-3"
		:headers="headers"
		:rows="rows"
	>

	</hra-table>
</template>

<script>
import HraTable from "@/components/ui/table/HraTable.vue";

export default {
	name: "ChildTable",
	components: {HraTable},
	props: {
		id: {type: String, required: true},
	},
	data() {
		return {
			headers: [
				{name: "name", text: this.$t("Vardas *")},
				{name: "brithDate", text: this.$t("Gimimo metai *")},
				{name: "disability", text: this.$t("Neįgalumas *")},
				{name: "disabilityNo", text: this.$t("Neįgalumo pažymos numeris")},
				{name: "disabilityFrom", text: this.$t("Neįgalumas nuo")},
				{name: "disabilityTo", text: this.$t("Neįgalumas iki")},
			],
		}
	},
	computed: {
		rows() {
			return this.$store.getters["setupStore/importEmployeeData"](this.id, "child");
		},
	},
}
</script>