<template>
	<hra-table
		:headers="[{name: 'name'}]"
		:rows="rows"
		hide-headers
		need-actions
		expand-multiple-row
		:expand-row-ids.sync="expandRowIds"
	>
		<template #col.name="{row}">
			<div class="px-3">{{row.name}} <span v-if="row.count != null">{{row.count}}</span></div>
		</template>

		<template #col.actions-base="{row, expandRow}">
			<hra-tooltip :text="$t(row.isExpand ? 'Suskleisti' : 'Išskleisti')">
				<hra-button
					:size="buttonSize.small"
					:color="uiColor.default"
					:hover-background="!row.isExpand"
					@click="() => expandRow(row.id)"
				>
					<v-fab-transition>
						<div :key="`${row.isExpand ? 1 : 0}-expand`">
							<hra-icon :name="row.isExpand ? iconName.up : iconName.down"/>
						</div>
					</v-fab-transition>
				</hra-button>
			</hra-tooltip>
		</template>

		<template #row-expansion="{row}">
			<div class="pa-3 overflow-x-auto">
				<import-employee-contract v-if="row.id === 'contract'" :id="id" />
				<import-employee-contact v-if="row.id === 'contact'" :id="id" />
				<employee-disabilities-table v-if="row.id === 'disabilities'" />
				<employee-child-table v-if="row.id === 'child'" />
				<vacations-table v-if="row.id === 'vacations'" :id="id" />
			</div>
		</template>

	</hra-table>
</template>

<script>
import HraTable from "@/components/ui/table/HraTable.vue";
import HraTooltip from "@/components/ui/HraTooltip.vue";
import HraButton from "@/components/ui/button/HraButton.vue";
import HraIcon from "@/components/ui/HraIcon.vue";
import ContractsTable from "@/domain/setup/components/tables/ContractsTable.vue";
import DisabilitiesTable from "@/domain/setup/components/tables/DisabilitiesTable.vue";
import ChildTable from "@/domain/setup/components/tables/ChildTable.vue";
import VacationsTable from "@/domain/setup/components/tables/VacationsTable.vue";
import ImportEmployeeContract from "@/domain/setup/components/ImportEmployeeContract.vue";
import ImportEmployeeContact from "@/domain/setup/components/ImportEmployeeContact.vue";
import {ImportEmployeeService} from "@/domain/setup/services/ImportEmployeeService";
import EmployeeDisabilitiesTable from "@/domain/employee/components/tables/EmployeeDisabilitiesTable.vue";
import EmployeeChildTable from "@/domain/employee/components/tables/EmployeeChildTable.vue";

export default {
	name: "EmployeeExpandTable",
	components: {
		EmployeeChildTable,
		EmployeeDisabilitiesTable,
		ImportEmployeeContact,
		ImportEmployeeContract,
		VacationsTable, ChildTable, DisabilitiesTable, ContractsTable, HraIcon, HraButton, HraTooltip, HraTable},
	props: {
		id: {type: String, required: true},
		validated: {type: Object, required: true},
	},
	data() {
		return {
			expandRowIds: [],
		};
	},
	computed: {
		rows() {
			return [
				{
					id: "contract",
					name: this.$t("Darbo sutarties informacija"),
					count: null,
					invalid: !this.validated.contract,
				},
				{
					id: "contact",
					name: this.$t("Kontaktai"),
					count: null,
				},
				{
					id: "disabilities",
					name: this.$t("Neįgalumai"),
					count: this.$store.getters["setupStore/counter"](this.id, "disabilities"),
					invalid: !this.validated.disabilities,
				},
				{
					id: "child",
					name: this.$t("Vaikai"),
					count: this.$store.getters["setupStore/counter"](this.id, "child"),
					invalid: !this.validated.child,
				},
				{
					id: "vacations",
					name: this.$t("Atostogos"),
					count: this.$store.getters["setupStore/counter"](this.id, "vacations"),
					invalid: !this.validated.vacations,
				},
			];
		},
	},
	provide() {
		return {
			service: new ImportEmployeeService(this.id),
		};
	},
}
</script>