<template>
    <li ref='li'>
        <div class='route' ref='route' :class='{active: visible || !isDepartment}'>
            <div class='route__before border' ref='routeBefore' />
            <div class='route__after border' ref='routeAfter' />
        </div>

        <v-hover v-if='isDepartment'>
            <template #default='{hover}'>
                <div class='company-tree-department' :class='{active: visible}' ref='root'>
                    <div class='company-tree-department__header'>
                        <span>{{ item.name }}</span>
                    </div>
                    <Avatar v-if='item.leader' :employee-name='item.leader.name' :src='item.leader.avatarSrc'
                            size='44' />
                    <div v-if='item.leader' class='company-tree-department__name'>{{ item.leader.name }}</div>
                    <div v-if='item.leader' class='company-tree-department__duty'>{{ item.leader.duty.name }}</div>
                    <div class='company-tree-department__count' :class='{clickable: clickable}' @click='onToggle'>
                        {{ item.count }}
                        <hra-icon v-if='clickable' :name='visible ? iconName.up : iconName.down' />
                    </div>

                    <v-fade-transition v-if='isAdmin'>
                        <hover-overlay :hover='hover'>
                            <ui-button
                                size='small'
                                prepend-icon='edit'
                                background='white'
                                hover-color='primary'
                                :modal="{name: 'DepartmentUpdateModal', props: {id: item.id}, on: {saved: fetchTree}}"
                            >{{ $t('Redaguoti skyrių') }}
                            </ui-button>

                            <ui-button
                                size='small'
                                prepend-icon='plus'
                                background='white'
                                hover-color='primary'
                                :modal="{name: 'DepartmentCreateModal', props: {departmentId: item.id}, on: {saved: fetchTree}}"
                            >{{ $t('Pridėti skyrių') }}
                            </ui-button>

                            <ui-button
                                size='small'
                                prepend-icon='add'
                                background='white'
                                hover-color='primary'
                                :modal="{name: 'DepartmentAddEmployeeModal', props: {departmentId: item.id}, on: {saved: fetchTree}}"
                            >{{ $t('Pridėti darbuotoją') }}
                            </ui-button>

                            <ui-button
                                v-if='deletable'
                                size='small'
                                prepend-icon='bin'
                                background='white'
                                hover-color='danger'
                                :confirm="{props: {title: $t('Ar tikrai norite ištrinti skyriu?')}, on: {confirmed: deleteDepartment}}"
                            >{{ $t('Ištrinti skyrių') }}
                            </ui-button>
                        </hover-overlay>
                    </v-fade-transition>
                </div>
            </template>
        </v-hover>
        <div v-else class='company-tree-employees' ref='root'>
            <company-tree-item-employee
                v-for='profile in item'
                :key='profile.name'
                :departmentId='parentId'
                :profile='profile'
            />
        </div>

        <ul v-if='visible'>
            <company-tree-item
                v-if='item.users != null && item.users.length > 0'
                :item='item.users'
                :parent-id='item.id'
                @change='changeRenderRoutes'
            />
            <company-tree-item
                v-for='child in item.children'
                is-department
                :key='child.name'
                :item='child'
                @change='changeRenderRoutes'
            />
        </ul>
    </li>
</template>

<script>
import Avatar from '@/components/ui/Avatar.vue'
import HraIcon from '@/components/ui/HraIcon.vue'
import CompanyTreeEmployee from '@/domain/company/components/CompanyTreeEmployee.vue'
import HraButton from '@/components/ui/button/HraButton.vue'
import CompanyTreeItemEmployee from '@/domain/company/components/CompanyTreeItemEmployee.vue'
import HoverOverlay from '@/domain/components/HoverOverlay.vue'
import UiButton from '@/domain/components/UiButton.vue'
import BaseConfirm from '@/domain/confirms/BaseConfirm.vue'
import customApi from '@/plugins/customApi'
import { GLOBAL_TOAST_TAG } from '@/utils/default'
import { mapGetters } from 'vuex'
import { expandedDepartments } from '@/domain/company/store/getters'

export default { name: 'CompanyTreeItem',
    components: {
        BaseConfirm,
        UiButton, HoverOverlay, CompanyTreeItemEmployee, HraButton, CompanyTreeEmployee, HraIcon, Avatar
    },
    props: {
        parentId: { type: [String, Number], default: null },
        isDepartment: { type: Boolean, default: false },
        item: { type: [Object, Array], required: true }
    },
    inject: ['fetchTree', 'isAdmin'],
    computed: {
        ...mapGetters({
            expandedDepartments: 'companyStore/expandedDepartments'
        }),
        isEmptyApproval() {

            return true;
        },
        clickable() {
            if (this.item.children?.length > 0) {
                return true
            }

            return this.item.count > (this.item.leader == null ? 0 : 1)
        },
        visible() {
            if (!this.clickable) {
                return false
            }

            return this.expandedDepartments.includes(this.item?.id ?? null)
        },
        deletable() {
            if (this.item.children?.length > 0) {
                return false
            }

            return this.item.count === 0
        },
        changeLeaderModalData() {
            return {
                name: 'DepartmentChangeLeader',
                props: { departmentId: this.item?.id, employeeId: this.item.leader?.id ?? null },
                on: { saved: this.fetchTree }
            }
        }
    },
    mounted() {
        this.renderRoutes()
    },
    updated() {
        this.changeRenderRoutes()
    },
    methods: {
        async deleteDepartment(confirm) {
            const api = customApi(null)
            const response = await api.request(api.factories.company.departments.destroy(this.item.id))

            if (response == null) {
                return
            }

            await this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag: GLOBAL_TOAST_TAG,
                message: this.$t('Sekmingai ištrintas')
            })

            this.fetchTree()

            confirm.close()
        },
        onToggle() {
            if (!this.clickable) {
                return
            }

            this.$store.dispatch('companyStore/toggleDepartment', this.item.id)

            this.$nextTick(() => {
                this.$emit('change', this)
            })
        },
        changeRenderRoutes() {
            this.$nextTick(() => {
                this.$emit('change', this)

                this.$children.forEach(child => {
                    if (child.hasOwnProperty('renderRoutes')) {
                        child.renderRoutes()
                    }
                })
            })
        },
        renderRoutes() {
            const parentRoot = this.$parent.$refs.root

            if (parentRoot == null) {
                return
            }

            const { x: parentX } = parentRoot.getBoundingClientRect()
            const { x } = this.$refs.root.getBoundingClientRect()
            const { width } = this.$refs.li.getBoundingClientRect()
            const lineWidth = parentX - x
            const widthDivide = width / 2
            const isLeftSide = lineWidth > 0
            const borderOffset = 1 / 2 // border width 1px

            this.$refs.routeBefore.classList.remove('border-left', 'border-right')
            this.$refs.routeBefore.classList.add(isLeftSide ? 'border-right' : 'border-left')
            this.$refs.routeAfter.classList.remove('border-left', 'border-right')
            this.$refs.routeAfter.classList.add('border-top', isLeftSide ? 'border-left' : 'border-right')

            this.$refs.route.style.left = isLeftSide ? `${widthDivide + borderOffset}px` : `${(lineWidth + widthDivide)}px`
            this.$refs.route.style.width = `${(Math.abs(lineWidth + borderOffset))}px`
        },
        onChangeLeader() {
            this.$store.dispatch('companyStore/changeLeader', {
                departmentId: this.item.id,
                leader: {}
            })
        }
    }
}
</script>