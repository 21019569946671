<template>
	<Auth>
		<template #header>{{ $t("Pakeisti slaptažodį") }}</template>

		<template #form>
			<validation-form :tag="tag" @submit="onSubmit">
				<v-row>
					<v-col cols="12">
						<text-field
							rules="required|email"
							v-model="email"
							name="email"
							:label="$t('El. pašto adresas')"
						/>
					</v-col>
					<v-col cols="12">
						<text-field
							:rules="'required|min:8,' + $t('simbolius')"
							v-model="password"
							name="password"
							type="password"
							:label="$t('Slaptažodis')"
						/>
					</v-col>
					<v-col cols="12">
						<text-field
							rules="required|confirmed:password,"
							v-model="password_confirmation"
							name="passwordConfirmation"
							type="password"
							:label="$t('Pakartokite slaptažodį')"
						>
							<template #confirmed-error-message>{{ $t('Nesutampa slaptažodžiai') }}</template>
						</text-field>
					</v-col>
					<v-col cols="12">
						<message-alerts :listen="[tag]"/>
					</v-col>
					<v-col cols="12">
						<hra-button
							:loading="buttonLoading"
							:color="uiColor.primary"
							:type="buttonType.submit"
							full-width
						>{{ $t("Siųsti") }}
						</hra-button>
					</v-col>
				</v-row>
			</validation-form>
		</template>

		<template #after-card>
			<hra-button-link
				full-width no-background
				:text-width="buttonTextWidth.normal"
				:to="{ name: loginName }"
			>{{ $t('Grįžti į prisijungimą') }}
			</hra-button-link>
		</template>
	</Auth>
</template>

<script>
import authMixin from "@/domain/auth/mixins/authMixin";
import Auth from "@/domain/auth/components/Auth";
import TextField from "@/domain/fields/TextField";
import HraButton from "@/components/ui/button/HraButton";
import {ValidationObserver} from "vee-validate";
import MessageAlerts from "@/domain/messages/MessageAlerts";
import ValidationForm from "@/domain/components/ValidationForm";
import HraButtonLink from "@/components/ui/button/HraButtonLink";
import {login as loginName} from "@/domain/auth/routes";

export default {
	name: "ResetPasswordPage",
	components: {HraButtonLink, ValidationForm, MessageAlerts, ValidationObserver, HraButton, TextField, Auth},
	mixins: [authMixin],
	data() {
		return {
			tag: "AUTH_RESET_PASSWORD_TAG",
			email: this.$route.query?.email ?? "",
			password: "",
			password_confirmation: "",
			loginName,
		};
	},
	methods: {
		onSubmit() {
			this.service.resetPassword(this.tag, {
				token: this.$route.params.token,
				email: this.email,
				password: this.password,
				password_confirmation: this.password_confirmation,
			});
		},
	}
}
</script>