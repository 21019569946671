export default axios => ({
    categories: {
        fetch: () => axios.get(`/api/dtv/categories`),
        show: (name) => axios.get(`/api/dtv/categories/${name}`),
    },
    questions: {
        fetch: () => axios.get(`/api/dtv/questions`),
        fetchByCategory: (category) => axios.get(`/api/dtv/questions/${category}`),
        store: (payload) => axios.post(`/api/dtv/questions`, payload),
    },
})