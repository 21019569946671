import Button from "@/components/ui/button/Button";
import Avatar from "@/components/ui/Avatar";
import FormTextField from "@/components/ui/form/FormTextField";
import FormSelect from "@/components/ui/form/FormSelect";
import FormDatePicker from "@/components/ui/form/FormDatePicker";
import FormFileButton from "@/components/ui/form/FormFileButton";

export default {
    components: {Avatar, Button, FormTextField, FormSelect, FormDatePicker, FormFileButton},
    props: {
        componentName: {
            type: String,
            required: true,
        },
        attrs: {
            type: Object,
            default: () => ({}),
        },
        on: {
            type: Object,
            default: () => ({}),
        },
        value: {
            type: Object,
            required: true,
        },
        fieldTransPrefix: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
}
