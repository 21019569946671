<template>
    <document-sidebar-container
        :loading='loading'
        :title='$t("susipažinimo istorija")'
    >
        <template #header>
            <document-title-view
                class='pe-3'
                :title='item.title'
                :due-at='item.dueAt'
                icon='paper'
            >
                <div class='d-flex flex-row gap-3'>
                    <ui-button
                        class='pa-0'
                        size='medium'
                        background='primary'
                        only-text
                        style='min-height: auto;'
                        @click='handleOpenHistoryDocumentModal'
                    >
                        {{ $t('Detaliau') }}
                    </ui-button>
                    <ui-button
                        v-if='canCancel'
                        class='pa-0'
                        size='medium'
                        background='danger'
                        only-text
                        style='min-height: auto;'
                        :confirm='confirmCancel'
                    >
                        {{ $t('Atšaukti supažindinimą') }}
                    </ui-button>
                </div>
            </document-title-view>
        </template>

        <div v-if='!!itemTypeText'><b>{{ $t('Dokumento tipas:') }}</b> {{ itemTypeText }}</div>

        <ui-table
            class='mt-8'
            :loading='loadingEmployees'
            :loading-more='loadingMoreEmployees'
            :visible-load-more='hasMoreEmployees'
            :is-empty-table='isEmpty'
            @clickLoadMore='handleLoadMoreEmployees'
        >
            <template #head>
                <tr>
                    <ui-table-head-filter
                        :title='$t("Darbuotojas")'
                        column='user'
                        :sort-options='filter.user.options.sort'
                        v-model='filter.user.value'
                        @changeFilter='handleChangeFilter'
                    />
                    <ui-table-head-filter
                        :title='$t("statusas")'
                        column='status'
                        :select-options='filter.status.options.select'
                        v-model='filter.status.value'
                        @changeFilter='handleChangeFilter'
                    />
                    <th>{{ $t('Susipažinti iki') }}</th>
                </tr>
            </template>
            <template #body>
                <tr
                    v-for='row in rows'
                    :key='`row-id-${row.id}`'
                >
                    <td style='width: 160px;max-width: 160px'>
                        <document-table-employee
                            :name='row.user.name'
                            :duty-name='row.user.duty.name'
                        />
                    </td>
                    <td style='width: 150px'>
                        <document-sent-status
                            :status='row.status'
                            :date='row.statusAt'
                        />
                    </td>
                    <td style='width: 120px;max-width: 120px'>{{ row.dueAt }}</td>
                </tr>
            </template>
        </ui-table>
    </document-sidebar-container>
</template>

<script>
import DocumentSidebarContainer from '@/domain/documents/components/DocumentSidebarContainer.vue'
import DocumentTitleView from '@/domain/documents/components/DocumentTitleView.vue'
import UiButton from '@/domain/components/UiButton.vue'
import modalMethodsMixin from '@/domain/mixins/modalMethodsMixin'
import UiTable from '@/domain/components/uiTable/UiTable.vue'
import DocumentSentStatus from '@/domain/documents/components/DocumentSentStatus.vue'
import UiTableHeadFilter from '@/domain/components/uiTable/UiTableHeadFilter.vue'
import DocumentCommentTooltip from '@/domain/documents/components/DocumentCommentTooltip.vue'
import DocumentTableEmployee from '@/domain/documents/DocumentTableEmployee.vue'
import i18n from '@/plugins/i18n'
import _ from 'lodash'
import CustomApi from '@/plugins/customApi'
import UiIcon from '@/domain/components/UiIcon.vue'
import { GLOBAL_TOAST_TAG } from '@/utils/default'

const customApi = CustomApi()
const statusTitles = {
    'new': i18n.t('Nepradėtas'),
    'pending': i18n.t('Laukiama'),
    'rejected': i18n.t('Atmesta'),
    'accepted': i18n.t('Priimta'),
    'cancelled': i18n.t('Atšaukta')
}
export default {
    name: 'DocumentLastHistorySidebar',
    components: {
        UiIcon,
        DocumentTableEmployee,
        DocumentCommentTooltip,
        UiTableHeadFilter,
        DocumentSentStatus,
        UiTable, UiButton, DocumentTitleView, DocumentSidebarContainer
    },
    mixins: [modalMethodsMixin],
    props: {
        item: { type: Object, required: true }
    },
    data() {
        return {
            loading: false,
            loadingEmployees: false,
            loadingMoreEmployees: false,
            rows: [],
            page: 1,
            limit: 30,
            hasMoreEmployees: false,
            canCancel: false,
            filter: {
                user: {
                    options: {
                        sort: [
                            { value: 'asc', label: 'A-Z' },
                            { value: 'desc', label: 'Z-A' }
                        ]
                    },
                    value: {
                        selected: [],
                        sort: null
                    }
                },
                status: {
                    options: {
                        select: Object.entries(statusTitles).map(([value, label]) => ({ value, label }))
                    },
                    value: {
                        selected: []
                    }
                }
            }
        }
    },
    computed: {
        isEmpty() {
            return !this.loadingMoreEmployees && !this.loading && this.rows.length === 0
        },
        comments() {
            return this.rows.filter(({ comment }) => !!comment)
        },
        confirmCancel() {
            return {
                props: {
                    title: this.$t('Ar tikrai norite atšaukti supažindinimą?')
                },
                on: {
                    confirmed: (confirm) => this.handleCancel(confirm)
                }
            }
        },
        itemTypeText() {
            return this.item?.type?.name ?? null
        }
    },
    mounted() {
        this.firstLoad()
    },
    methods: {
        handleOpenHistoryDocumentModal() {
            this.onOpenModal('DocumentHistoryModal', { item: this.item })
        },
        getFetchParams(page = null) {
            let params = {
                last: 1,
                limit: this.limit,
                page: page ?? 1,
                sortBy: []
            }

            for (const [column, payload] of Object.entries(this.filter)) {
                if (payload.value.sort != null) {
                    params.sortBy.push(`${column},${payload.value.sort}`)
                }

                if ((payload.value.selected?.length ?? 0) > 0) {
                    params[column] = payload.value.selected
                }
            }

            return params
        },
        handleChangeFilter() {
            this.fetchSentUsers()
        },
        async handleLoadMoreEmployees() {
            this.loadingMoreEmployees = true
            await this.fetchSentUsers(this.page + 1)
            this.loadingMoreEmployees = false
        },
        async firstLoad() {
            this.loading = true
            await this.fetchSentUsers()
            this.loading = false
        },
        async fetchSentUsers(page = null) {
            try {
                const {
                    data: {
                        items, hasMore, pagination: { currentPage }
                    }
                } = await customApi.factories.documents.sentUsers.fetchByDocumentId(this.item.id, this.getFetchParams(page))

                this.page = currentPage
                this.hasMoreEmployees = hasMore
                this.rows = page == null ? items : [...this.rows, ...items]
            } catch (e) {
                console.error(e)
            }
        },
        async handleCancel(confirm) {
            try {
                const { data } = await customApi.factories.documents.sends.byDocumentId.cancel(this.item.id)
                await this.$store.dispatch('messagesStore/showSuccessMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Dokumento supažindinimas atšauktas')
                })
                this.$store.dispatch('documentsItemsRootStore/documents/update', data)
                this.firstLoad()
            } catch (e) {
                await this.$store.dispatch('messagesStore/showErrorMessage', {
                    tag: GLOBAL_TOAST_TAG,
                    message: this.$t('Įvyko klaida atšaukiant dokumento supažindinimą, bandykite dar kartą')
                })
            } finally {
                confirm.close()
            }
        }
    }
}
</script>


<style scoped lang='scss'>
.document-last-history-sidebar {
    &__comments {
    }

    &__comment-title {
        color: #70778C;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
        letter-spacing: 0.14px;
    }

    &__comment {
        border-top: 1px solid #DAD5E9;
        border-left: 1px solid #DAD5E9;
        border-right: 1px solid #DAD5E9;
        padding: 10px 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;

        .ui-icon {
            color: #70778C;
            font-size: 2rem;
        }

        &:first-child {
            border-radius: 4px 4px 0 0;
        }

        &:last-child {
            border-radius: 0 0 4px 4px;
            border-bottom: 1px solid #DAD5E9;
        }

        &:nth-child(even) {
            background: #FAF9FF;
        }

        span {
            color: #70778C;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-top: 1px;
        }
    }
}
</style>