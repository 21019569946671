<template>
    <div class='documents-finder-header-title'>
        {{ title }}
    </div>
</template>

<script>
export default {
    name: 'DocumentsFinderHeaderTitle',
    props: {
        defaultTitle: {type: String, default: ''}
    },
    inject: ['storeNamespace'],
    computed: {
        title() {
            return this.$store.getters[`${this.storeNamespace}/title`] ?? this.defaultTitle
        },
    }
}
</script>

<style scoped lang='scss'>
.documents-finder-header-title {
    color: #1C2538;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}
</style>