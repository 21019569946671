export default axios => ({
    employees: {
        fetch: (userId, params) => axios.get(`/api/approvals/employees/${userId}`, { params })
    },
    invites: {
        approve: (id, message = null) => {
            let payload = {}
            if (!!message) {
                payload.message = message
            }

            return axios.put(`/api/approvals/invites/${id}/approve`, payload)
        },
        abort: (id, message = null) => {
            let payload = {}
            if (!!message) {
                payload.message = message
            }

            return axios.put(`/api/approvals/invites/${id}/abort`, payload)
        }
    },
    tree: {
        businessTripEvent: (id) => axios.get(`/api/approvals/tree/business-trip-event/${id}`),
        holidayEvent: (id) => axios.get(`/api/approvals/tree/holiday-event/${id}`),
        remoteWork: (id) => axios.get(`/api/approvals/tree/remote-work/${id}`),
        remoteWorkEvent: (id) => axios.get(`/api/approvals/tree/remote-work-event/${id}`),
        user: {
            fetch: (id) => axios.get(`/api/approvals/tree/user/${id}`),
            fetchHolidayEvent: (id) => axios.get(`/api/approvals/tree/user/${id}/holiday-event`)
        }
    }
})