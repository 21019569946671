<template>
    <page
        :loader-tag='fetchTag'
        class='py-4 px-8'
    >
        <div class='d-flex gap-4 align-center'>
            <h1>{{ $t('Grafikai') }}</h1>
            <ui-button
                v-if='isAdmin'
                size='small'
                :modal='{name: "CreateScheduleModal", on: {created: scheduleCreated}}'
            >
                {{ $t('Sukurti grafiką') }}
            </ui-button>
        </div>
        <hra-table
            class='mt-4'
            :headers='headers'
            :rows='rows'
            need-actions
        >
            <template #col.actions-base='{row}'>
                <ui-button
                    size='small'
                    @click='editSchedule(row.id)'
                >{{ isAdmin ? $t('Redaguoti') : $t('Peržiūrėti') }}
                </ui-button>
                <ui-button
                    v-if='isAdmin'
                    size='small'
                    background='danger'
                    :confirm='{
                        props: {
                            title: deleteText
                        },
                        on: {
                            confirmed: (confirm) => deleteSchedule(confirm, row.id),
                        }
                    }'
                >{{$t('Ištrinti')}}
                </ui-button>
            </template>
        </hra-table>
    </page>
</template>

<script>

import customApi from '@/plugins/customApi'
import Page from '@/domain/components/Page.vue'
import HraTable from '@/components/ui/table/HraTable.vue'
import UiButton from '@/domain/components/UiButton.vue'
import BaseConfirm from '@/domain/confirms/BaseConfirm.vue'
import {
    permissionsButtonEmployeeCreate,
    permissionsButtonEmployeeDelete,
    permissionsButtonEmployeeEdit
} from '@/utils/permissions'

export default {
    name: 'SchedulesListPage',
    components: { BaseConfirm, UiButton, HraTable, Page },
    data() {
        return {
            fetchTag: 'FETCH_SCHEDULES_TAG',
            rows: [],
            headers: [
                { name: 'id', text: 'ID' },
                { name: 'scheduleId', text: 'PARENT ID' },
                { name: 'name', text: 'NAME' }
            ],
            deleteText: this.$t('Do you want to delete schedule?')
        }
    },
    beforeCreate() {
        const api = customApi(this.fetchTag)
        api.request(api.factories.schedules.fetchList())
            .then(data => {
                this.rows = data
                this.$store.dispatch('loadersStore/hideLoader', this.fetchTag)
            })
    },
    computed: {
        isAdmin() {
            return this.hasPermissionTo([
                ...permissionsButtonEmployeeCreate,
                ...permissionsButtonEmployeeEdit,
                ...permissionsButtonEmployeeDelete
            ])
        }
    },
    methods: {
        editSchedule(id) {
            this.$router.push({ name: 'ScheduleEditPage', params: { id } })
        },
        async deleteSchedule(confirm, id) {
            const api = customApi()
            await api.request(api.factories.schedules.deleteById(id))
            this.rows = this.rows.filter(row => row.id !== id)

            confirm.close()
        },
        scheduleCreated(schedule) {
            this.rows.push(schedule)
        }
    }
}
</script>