<template>
	<div
		class="calendar-day"
		:class="classes"
		v-on="on"
	>
		<hra-tooltip v-if="tooltip" :text="tooltip">
			<span>{{day}}</span>
		</hra-tooltip>
		<span v-else>{{day}}</span>
	</div>
</template>

<script>
import HraTooltip from "@/components/ui/HraTooltip";
export default {
	name: "HraCalendarDay",
	components: {HraTooltip},
	props: {
		date: {type: String, required: true},
		day: {type: String, required: true},
		disabled: {type: Boolean, default: false},
		today: {type: Boolean, default: false},
    holiday: {type: String, default: null},
		width: {type: Number, default: 100},
		disableClick: {type: Boolean, default: false},
	},
	inject: {
		handleClickDay: "handleClickDay",
	},
	computed: {
		classes() {
			return {
				disabled: this.disabled,
				today: this.today,
				holiday: this.holiday,
			};
		},
		styles() {
			return {
				width: `${this.width}%`,
			};
		},
		tooltip() {
      if (this.today || this.holiday) {
        return [
            this.today ? this.$t("Šiandien") : null,
            this.holiday ? this.holiday : null,
        ].filter(text => text != null).join(', ');
      }

      return null;
		},
		on() {
			if (this.disableClick) {
				return {};
			}

			return {
				click: this.onClickDay
			}
		}
	},
	methods: {
		onClickDay() {
			this.handleClickDay(this.date);
		},
	},
}
</script>
