<template>
    <div>
        <ui-calendar
            with-header
            class='px-5 px-sm-8 pt-7 pt-sm-9'
            :max-month-count='maxMonthCount'
            :year-month.sync='yearMonth'
            :events='calendarEvents'
            :select-start-date.sync='selectedNewEventStart'
            :select-end-date.sync='selectedNewEventEnd'
            :min-select-date='minSelectDate'
            clickable-dates
            shown-menu
            disable-mobile-click
            :sub-view-month='subViewMonth'
        >
            <template #day-menu>
                <div class='d-flex flex-column gap-2 pa-3'>
                    <ui-button
                        size='small'
                        @click='openCreateEventModal(EVENT_HOLIDAY_TYPE_YEARLY)'
                    >
                        {{ $t('Kasmetinės atostogos') }}
                    </ui-button>

                    <ui-button
                        size='small'
                        @click='openCreateEventModal(EVENT_HOLIDAY_TYPE_FATHER_OR_MOTHER)'
                    >
                        {{ $t('Tėvadienis / mamadienis') }}
                    </ui-button>

                    <ui-button
                        size='small'
                        @click='openCreateRemoteWorkModal'
                    >
                        {{ $t('Remote work application') }}
                    </ui-button>

                    <ui-button
                        v-if='isEnabledBusinessTripRequestEmployee'
                        size='small'
                        @click='openCreateBusinessTripModal'
                    >
                        {{ $t('Komandiruotės prašymas') }}
                    </ui-button>

                    <ui-button
                        size='small'
                        @click='openCreateEventModal()'
                    >
                        {{ $t('Kiti prašymai') }}
                    </ui-button>

                    <ui-button
                        size='small'
                        background='danger'
                        @click='clearSelectedInterval()'
                    >
                        {{ $t('Ištrinti pasirinkimą') }}
                    </ui-button>
                </div>
            </template>
        </ui-calendar>
        <vacation-legend class='mt-8 ps-5 pb-8 pb-md-0' />
    </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import {
    EVENT_BUSINESS_TRIP_EMPLOYEE, EVENT_BUSINESS_TRIP_LEADER,
    EVENT_HOLIDAY_TYPE_CHILDCARE,
    EVENT_HOLIDAY_TYPE_CREATIVE,
    EVENT_HOLIDAY_TYPE_DEPUTY,
    EVENT_HOLIDAY_TYPE_FATHER_OR_MOTHER, EVENT_HOLIDAY_TYPE_FIRST_SCHOOL_DAY,
    EVENT_HOLIDAY_TYPE_FREE,
    EVENT_HOLIDAY_TYPE_MATERNITY,
    EVENT_HOLIDAY_TYPE_ONE_WORK_DAY,
    EVENT_HOLIDAY_TYPE_PARENTAL,
    EVENT_HOLIDAY_TYPE_PUBLIC, EVENT_HOLIDAY_TYPE_REMOTE_WORK,
    EVENT_HOLIDAY_TYPE_STUDY,
    EVENT_HOLIDAY_TYPE_YEARLY, EVENT_SICK_LEAVE
} from '@/utils/default'
import UiCalendar from '@/domain/components/UiCalendar/UiCalendar.vue'
import UiButton from '@/domain/components/UiButton.vue'
import { VACATIONS_INIT_TAG } from '@/domain/employeeVacations/tags'
import { MODAL_REMOTE_WORK } from '@/tags/tagsRemoteWork'
import VacationLegend from '@/domain/employeeVacations/components/VacationLegend.vue'
import modalMethodsMixin from '@/domain/mixins/modalMethodsMixin'
import store from '@/store'
import isBondMobileMixin from '@/mixins/isBondMobileMixin'

export default {
    name: 'EventsCalendar',
    components: {
        UiCalendar,
        UiButton,
        VacationLegend
    },
    mixins: [modalMethodsMixin, isBondMobileMixin],
    inject: ['rootRefresh'],
    data() {
        return {
            yearMonth: moment().format('YYYY-MM'),
            tabs: [
                { name: 'calendar', text: this.$t('Kalendorius') },
                { name: 'history', text: this.$t('Atostogų prašymų istorija') }
            ],
            selectedNewEventStart: null,
            selectedNewEventEnd: null,
            minSelectDate: moment().format('YYYY-MM-DD'),
            VACATIONS_INIT_TAG,
            EVENT_HOLIDAY_TYPE_DEPUTY,
            EVENT_HOLIDAY_TYPE_YEARLY,
            EVENT_HOLIDAY_TYPE_FATHER_OR_MOTHER,
            MODAL_REMOTE_WORK
        }
    },
    computed: {
        ...mapGetters({
            userId: 'currentUserStore/userId'
        }),
        calendarEvents() {
            return this.$store.getters['employeeVacationsStore/calendarEvents'](() => {
                this.$root.$emit('EmployeeCalendarRefresh')
            })
        },
        maxMonthCount() {
            return this.isBondMobile ? 1 : 5
        },
        subViewMonth() {
            return this.isBondMobile ? 0 : 1
        },
        isEnabledBusinessTripRequestEmployee() {
            return this.$store.getters['currentUserStore/settings']?.business_trip
                ?.settingBusinessTripEmployeeCanSendRequest?.config.enabled
        },
    },
    mounted() {
        this.load()
    },
    methods: {
        refresh() {
            this.$store.dispatch('employeeVacationsStore/fetchCalendarEvents')
        },
        load() {
            Promise.all([
                this.$store.dispatch('employeeVacationsStore/setUserId', this.userId),
                this.$store.dispatch('employeeVacationsStore/fetchCalendarEvents')
            ]).then(() => {
            })
        },
        getYearMonth(date) {
            if (this.isBondMobile) {
                return date.add(2, 'months').format('YYYY-MM')
            }

            return date.subtract(2, 'months').format('YYYY-MM')
        },
        updateScreenWidth() {
            this.screenWidth = window.innerWidth
        },
        clearSelectedInterval() {
            this.selectedNewEventStart = null
            this.selectedNewEventEnd = null
        },
        openCreateEventModal(type = null) {
            this.onOpenModal('VacationModal', {
                userId: this.userId,
                eventType: type ?? EVENT_HOLIDAY_TYPE_YEARLY,
                start: this.selectedNewEventStart,
                end: this.selectedNewEventEnd
            }, {
                changed: () => this.rootRefresh()
            })
            this.clearSelectedInterval()
        },
        openCreateRemoteWorkModal() {
            this.onOpenModal('RemoteWorkModal', {
                userId: this.userId,
                start: this.selectedNewEventStart,
                end: this.selectedNewEventEnd
            }, {
                changed: () => this.rootRefresh()
            })
            this.clearSelectedInterval()
        },
        openCreateBusinessTripModal() {
            this.onOpenModal('BusinessTripsModal', {
                start: this.selectedNewEventStart,
                end: this.selectedNewEventEnd
            }, {
                created: () => this.rootRefresh()
            })
            this.clearSelectedInterval()
        }
    }
}
</script>