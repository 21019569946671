import Vue from 'vue'
import moment from 'moment'
import CustomApi from '@/plugins/customApi'
import { EVENT_HOLIDAY_TYPE_DEPUTY, eventHolidayType, getDateArray } from '@/utils/default'
import i18n from '@/plugins/i18n'

function getWeekDays() {
    return {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
    }
}

const getWeekdayKeyByIso = (() => {
    const keys = Object.keys(getWeekDays())
    return (value) => keys[value - 1] ?? null
})()

const getWeekdayIso = (() => {
    const keys = Object.keys(getWeekDays())
    return (value) => keys.indexOf(value) + 1
})()

const state = () => {
    return {
        fetchingEvents: false,
        events: [],
        minDate: moment().format('YYYY-MM-DD'),
        calendar: {
            editable: true,
            date: moment().startOf('month').format('YYYY-MM')
        },
        formData: {
            start: null,
            end: null,
            dates: [],
            weekdays: getWeekDays(),
            address: '',
            message: ''
        }
    }
}

const getters = {
    minDate: ({ minDate }) => minDate,
    calendarYearMonth: ({ calendar }) => calendar.date,
    calendarEditable: ({ calendar }) => calendar.editable,
    calendarSelectedWeekdays: ({ formData }) => {
        return Object.entries(formData.weekdays)
            .map(([name, value], key) => [key + 1, value])
            .filter(([weekday, value]) => value)
            .map(([weekday]) => weekday)
    },
    calendarEvents: ({ events }) => {
        const eventCalendarStatus = {
            'pending': 'wait',
            'approved': 'approve',
            'aborted': 'abort'
        }
        let payload = {}

        events.filter(({ currentStatus }) => currentStatus !== 'aborted')
            .map(event => {
                const start = moment(event.start)
                const end = moment(event.end)
                const status = eventCalendarStatus[event.currentStatus] ?? 'none'
                const type = event.type
                let tooltip = eventHolidayType[event.type] ?? null
                if (event.type === EVENT_HOLIDAY_TYPE_DEPUTY) {
                    tooltip = i18n.t('Pavaduojate <b>{name}</b>', { name: event?.parent?.user?.name ?? '' })
                }
                getDateArray(start, end).map(date => {
                    payload[date] = {
                        isStart: date === event.start,
                        isEnd: date === event.end,
                        tooltip,
                        type,
                        status
                    }
                })
            })

        return payload
    },
    formData: ({ formData }) => formData
}

const customApi = CustomApi()
const actions = {
    async fetchEvents({ state }, payload) {
        state.fetchingEvents = true
        try {
            const { data } = await customApi.factories.employee.holidays.fetch(payload)

            state.events = data
        } catch (error) {
        } finally {
            state.fetchingEvents = false
        }
    },
    initFormData({state, dispatch}, payload) {
        const minStart = moment().format('YYYY-MM-DD')

        Vue.set(state.formData, 'weekdays', payload.weekdays)
        Vue.set(state.formData, 'start', minStart > payload.start ? minStart : payload.start)
        Vue.set(state.formData, 'end', payload.end)

        const events = payload.events.filter(({ start }) => start >= minStart)
        const dates = events.map(({ start }) => start)
        const ignoreEventIds = events.map(({ id }) => id)
        Vue.set(state.formData, 'dates', dates)
        Vue.set(state.calendar, 'date', moment(payload.start).format('YYYY-MM'))
        Vue.set(state.formData, 'message', payload.message ?? '')
        Vue.set(state.formData, 'address', payload.address ?? '')
        state.events = state.events.filter(({ id }) => !ignoreEventIds.includes(id))
    },
    toggleDateInDates({ state }, payload) {
        const keyInDates = state.formData.dates.indexOf(payload)
        if (keyInDates === -1) {
            state.formData.dates.push(payload)
            return
        }

        state.formData.dates.splice(keyInDates, 1)
    },
    changedInterval({ state }) {
        if (state.formData.start == null || state.formData.end == null) {
            Vue.set(state.formData, 'dates', [])
            Vue.set(state.formData, 'weekdays', getWeekDays())
            return
        }

        if (state.formData.dates.length === 0) {
            return
        }

        const selectedWeekdaysIso = Object.entries(state.formData.weekdays)
            .filter(([_, value]) => value).map(([weekday]) => getWeekdayIso(weekday))
        let weekdaysInInterval = []
        const dates = state.formData.dates
        const startDate = moment(state.formData.start, 'YYYY-MM-DD')
        const endDate = moment(state.formData.end, 'YYYY-MM-DD').add(1, 'day')
        const selectedDates = new Array(endDate.diff(startDate, 'days')).fill(null)
            .map((_, days) => startDate.clone().add(days, 'days'))
            .filter(date => {
                const isoWeekday = date.isoWeekday()

                if (selectedWeekdaysIso.includes(isoWeekday)) {
                    if (!weekdaysInInterval.includes(isoWeekday)) {
                        weekdaysInInterval.push(isoWeekday)
                    }

                    return true
                }

                return dates.includes(date.format('YYYY-MM-DD'))
            })
            .map(date => date.format('YYYY-MM-DD'))

        weekdaysInInterval = weekdaysInInterval.map(value => getWeekdayKeyByIso(value))
        Vue.set(state.formData, 'weekdays', Object.fromEntries(
            Object.entries(getWeekDays())
                .map(([weekday]) => ([weekday, weekdaysInInterval.includes(weekday)]))
        ))
        Vue.set(state.formData, 'dates', selectedDates)
    },
    calendarSetDate({ state }, payload) {
        Vue.set(state.calendar, 'date', payload)
    },
    calendarToggleDate({ state, dispatch }, payload) {
        dispatch('toggleDateInDates', payload)

        if (payload < state.formData.start || state.formData.start == null) {
            Vue.set(state.formData, 'start', payload)
            dispatch('calendarSetDate', moment(payload, 'YYYY-MM-DD').format('YYYY-MM'))
        }

        if (payload > state.formData.end || state.formData.end == null) {
            Vue.set(state.formData, 'end', payload)
        }

        const date = moment(payload, 'YYYY-MM-DD')
        const weekdays = Object.keys(getWeekDays())
        Vue.set(state.formData.weekdays, weekdays[date.isoWeekday() - 1], false)
    },
    formChangeStartDate({ state, dispatch }, payload) {
        Vue.set(state.formData, 'start', payload)
        dispatch('calendarSetDate', moment(payload, 'YYYY-MM-DD').format('YYYY-MM'))
        dispatch('changedInterval')
    },
    formChangeEndDate({ state, dispatch }, payload) {
        Vue.set(state.formData, 'end', payload)
        dispatch('changedInterval')
    },
    formSetDates({ state, dispatch }, payload) {
        Vue.set(state.formData, 'dates', payload)
        dispatch('changedInterval')
    },
    formToggleWeekday({ state, dispatch }, { weekday, value }) {
        Vue.set(state.formData.weekdays, weekday, value)

        if (state.formData.start == null || state.formData.end == null) {
            Vue.set(state.formData, 'dates', [])
            Vue.set(state.formData, 'weekdays', getWeekDays())
            return
        }

        const isoWeekday = getWeekdayIso(weekday)

        if (!value) {
            const dates = state.formData.dates
                .filter(date => moment(date, 'YYYY-MM-DD').isoWeekday() !== isoWeekday)
            Vue.set(state.formData, 'dates', dates)
            Vue.set(state.formData.weekdays, weekday, false)
            return
        }

        const startDate = moment(state.formData.start, 'YYYY-MM-DD')
        const endDate = moment(state.formData.end, 'YYYY-MM-DD').add(1, 'day')
        const dates = new Array(endDate.diff(startDate, 'days')).fill(null)
            .map((_, days) => startDate.clone().add(days, 'days'))
            .filter(date => date.isoWeekday() === isoWeekday)
            .map(date => date.format('YYYY-MM-DD'))

        if (dates.length < 1) {
            Vue.set(state.formData.weekdays, weekday, false)
            return
        }

        Vue.set(state.formData, 'dates', [...(new Set([
            ...state.formData.dates,
            ...dates
        ]))])
        Vue.set(state.formData.weekdays, weekday, true)
    },
    formAddress({ state }, payload) {
        Vue.set(state.formData, 'address', payload)
    },
    formSetMessage({ state }, payload) {
        Vue.set(state.formData, 'message', payload)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions
}