import {localStorageGet, localStorageRemove, localStorageSave} from "@/utils/localStorage";
import _ from "lodash";

//  initial state
const state = () => ({
  localStorageName: "employees_import",
  headers: [],
  rows: [],
  departments: [],
  duties: [],
  loaded: false,
  loading: true,
  editRowId: null,
  rowsHaveErrors: [],
});

// getters
const getters = {
  editRowId(state) {
    return state.editRowId;
  },
  rowsHaveErrors(state) {
    return state.rowsHaveErrors.length > 0;
  },
  payload(state) {
    return {
      rows: state.rows.map(row => {
        let cols = {};

        Object.keys(row).forEach(name => {
          cols[name] = row[name].value;
        })

        return cols;
      }),
      departments: state.departments,
      duties: state.duties,
    };
  },
  isLoaded(state) {
    return state.loaded;
  },
  isLoading(state) {
    return state.loading;
  },
  tableHeaders(state) {
    return [
      ...(state.headers.map(header => ({
        ...header,
        style: {minWidth: "250px"}
      }))),
    ];
  },
  tableRows(state) {
    return state.rows;
  },
  departments(state) {
    return state.departments.map(option => ({
      ...option,
      text: option.text,
    }));
  },
  duties(state) {
    return state.duties;
  },
  employeesByDepartments(state) {
    let data = {none: {title: "None", data: []}, departments: {}};

    state.departments.forEach(department => {
      data.departments[department.value] = {
        title: department.text,
        data: []
      };
    });

    state.rows.map(row => {
      const employee = {
        id: row.id,
        name: row.name,
        department: null
      };

      if (!row.departments) {
        data.none.data.push(employee);
      } else {
        row.departments.forEach(department => {
          data.departments[department].data.push({
            ...employee,
            department
          });
        });
      }
    });

    return data;
  },
};

const getLocalStorageStateService = (localStorageName) => {
  const data = localStorageGet(localStorageName);

  if (!data || typeof data !== "object") {
    return null;
  }

  if (!data.hasOwnProperty("rows") || !data.hasOwnProperty("departments") || !data.hasOwnProperty("duties")) {
    return null;
  }

  if (!Array.isArray(data.rows) || !Array.isArray(data.departments) || !Array.isArray(data.duties)) {
    return null;
  }

  return {
    headers: data.headers,
    rows: data.rows,
    departments: data.departments,
    duties: data.duties,
  };
}

// actions
const actions = {
  setupLoadData: ({commit, state}, payload) => {
    // const initData = getLocalStorageStateService(state.localStorageName);
    // if (initData != null) {
    //
    //   return;
    // }

    // get empty user

  },
};

//  mutations
const mutations = {
  setEditRowId(state, payload) {
    state.editRowId = payload;
  },

  setRowError(state, payload) {
    if (state.rowsHaveErrors.indexOf(payload) === -1) {
      state.rowsHaveErrors.push(payload);
    }
  },
  removeRowError(state, payload) {
    state.rowsHaveErrors = state.rowsHaveErrors.filter(id => id !== payload);
  },

  save(state, payload) {
    state.headers = payload.headers;
    state.rows = payload.rows.map((row, key) => ({
      id: key,
      ...row
    }));
    state.departments = payload.departments;
    state.duties = payload.duties;
    state.loaded = true;

    this.commit("employeesImport/saveToLocalStorage");
  },
  saveToLocalStorage(state) {
    localStorageSave(state.localStorageName, {
      headers: state.headers,
      rows: state.rows,
      departments: state.departments,
      duties: state.duties,
    });
  },
  load(state) {
    const data = localStorageGet(state.localStorageName);
    if (data && typeof data === "object") {
      if (data.hasOwnProperty("rows") && data.hasOwnProperty("departments") && data.hasOwnProperty("duties")) {
        if (Array.isArray(data.rows) && Array.isArray(data.departments) && Array.isArray(data.duties)) {
          state.headers = data.headers;
          state.rows = data.rows;
          state.departments = data.departments;
          state.duties = data.duties;
          state.loaded = true;
          return;
        }
      }
    }
    state.loading = false;
    state.loaded = false;
  },
  updateRows(state, payload) {
    state.rows = payload;
    this.commit("employeesImport/saveToLocalStorage");
  },
  saveRow(state, payload) {
    state.rows = state.rows.map(row => {
      if (row.id === payload.id) {
        return {
          ...row,
          ...payload.row,
        };
      }
      return row;
    })

    this.commit("employeesImport/saveToLocalStorage");
  },
  deleteRow(state, payload) {
    state.rows = state.rows.filter(row => row.id !== payload);
    this.commit("employeesImport/saveToLocalStorage");
  },
  change(state, payload) {
    state.rows = state.rows.map(row => row.actions === payload.actions ? payload : row);

    this.commit("employeesImport/saveToLocalStorage");
  },
  createDepartment(state, payload) {
    state.departments.push({
      value: payload,
      text: payload,
    });

    this.commit("employeesImport/saveToLocalStorage");
  },
  changeDepartment(state, payload) {
    state.rows = state.rows.map((row) => {
      if (row.id !== payload.id) {
        return row;
      }

      if (row.departments == null) {
        return {
          ...row,
          departments: [payload.department],
        };
      }

      if (row.departments.indexOf(payload.department) > -1) {
        return row;
      }

      return {
        ...row,
        departments: [...row.departments, payload.department],
      };
    });

    this.commit("employeesImport/saveToLocalStorage");
  },
  deleteEmployeeDepartment(state, payload) {
    state.rows = state.rows.map((row) => {
      if (row.actions !== payload.id) {
        return row;
      }

      let departments = row.departments;
      if (departments != null) {
        departments = departments.filter(department => department !== payload.department);
        if (departments.length === 0) {
          departments = null;
        }
      }

      return {
        ...row,
        departments: departments,
      };
    });

    this.commit("employeesImport/saveToLocalStorage");
  },
  delete(state, payload) {
    state.rows = state.rows.filter(row => row.actions !== payload);

    this.commit("employeesImport/saveToLocalStorage");
  },
  createEmptyRow: (state, payload) => {
    state.rows.unshift(payload);
  },
  clear(state) {
    localStorageRemove(state.localStorageName);
    state.headers = [];
    state.rows = [];
    state.departments = [];
    state.duties = [];
    state.rowsHaveErrors = [];
    state.editRowId = null;
    state.loading = false;
    state.loaded = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
