<template>
    <custom-dialog :max-width='1200'>
        <template #title>{{ $t('Skyriaus valdymas') }}</template>
        <template #content>

            <v-row>
                <v-col cols='5'>
                    <validation-form>
                        <v-row class='pa-3'>
                            <v-col cols='12'>
                                <text-field
                                    rules='required'
                                    name='name'
                                    :label="$t('Skyriaus pavadinimas')"
                                    v-model='formData.name'
                                />
                            </v-col>
                            <v-col cols='12' v-if='visibleParent'>
                                <select-field
                                    rules='required'
                                    name='departmentId'
                                    filtered
                                    :label="$t('Tėvinis skyrius')"
                                    :options='departmentOptions'
                                    v-model='formData.departmentId'
                                >
                                    <template #label-append>
                                        <hra-tooltip :text="$t('Nurodomas skyrius/departamentas, kuriam hierarchiškai priklauso redaguojamas skyrius')">
                                            <ui-icon class='ml-2'>message-info</ui-icon>
                                        </hra-tooltip>
                                    </template>
                                </select-field>
                            </v-col>
                            <v-col cols='12'>
                                <select-field
                                    name='userId'
                                    :label="$t('Vadovas (neprivaloma)')"
                                    :single-line='false'
                                    :options-service='findUserService'
                                    v-model='userId'
                                    dynamic
                                    filtered
                                    clearable
                                >
                                    <template #selection='{item}'>
                                        <employee-table-view class='py-1' :employee='item.employee' />
                                    </template>
                                    <template #item='{item}'>
                                        <employee-table-view class='py-1' :employee='item.employee' />
                                    </template>
                                </select-field>
                            </v-col>
                            <v-col cols='12' v-if='ordersLength > 0'>
                                <text-field
                                    :label='$t("Min deputy count")'
                                    type='number'
                                    :min='0'
                                    :max='formData.holidayEventMaxDeputyCount'
                                    v-model='formData.holidayEventMinDeputyCount'
                                />
                            </v-col>
                            <v-col cols='12' v-if='ordersLength > 0'>
                                <text-field
                                    :label='$t("Max deputy count")'
                                    type='number'
                                    :min='formData.holidayEventMinDeputyCount'
                                    v-model='formData.holidayEventMaxDeputyCount'
                                />
                            </v-col>
                            <v-col cols='12'>
                                <message-alert
                                    v-if='ordersLength < 1'
                                    id='----'
                                    :message='$t("Atostogų tvirtinimo nustatymai neatlikti. Pagal nutylėjimą, prašymai tvirtinami aukščiau esančio skyriaus nustatymais/vadovo.")'
                                    type='info'
                                />
                                <message-alerts :listen='[tagId]' />
                            </v-col>
                        </v-row>
                    </validation-form>
                </v-col>
                <v-divider vertical class='my-2' />
                <v-col cols='7' class="pa-3">
                    <v-row class='pa-3'>
                        <v-col cols='12'>
                            <approval-levels
                                :title='$t("Prašymų tvirtinimo schema")'
                                :levelCount='ordersLength'

                                @create-new-level='handleCreateNewLevel'
                            >
                                <approval-level
                                    v-for='(level, index) in formData.orders'
                                    :key='level.id'
                                    :level='level'
                                    :order='index + 1'

                                    @update-level-users='handleUpdateLevelUsers'
                                    @update-level-min-to-approve='handleUpdateLevelMinToApprove'
                                    @remove-user-from-level='handleRemoveUserFromLevel'
                                    @remove-level='handleRemoveLevel'
                                />
                            </approval-levels>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class='px-3'>
                <v-col cols='12'>
                    <hra-table
                        :headers='usersTableHeaders'
                        :rows='users'
                        :loading='loading'
                    >
                        <template #header.custom>
                            <table-title-view
                                :total='users.length'
                                class='mb-3'
                            >
                                <hra-tooltip :text="$t('Žemiau matomas darbuotojų sąrašas, kuriam taikoma prašymų tvirtinimo schema')">
                                    <ui-icon class='mr-2'>message-info</ui-icon>
                                </hra-tooltip>
                                {{ $t('Darbuotojai') }}
                            </table-title-view>
                        </template>
                    </hra-table>
                </v-col>
            </v-row>
        </template>

        <template #footer>
            <ui-button
                prepend-icon='close'
                background='default'
                @click='close'
            >{{ $t('Uždaryti') }}
            </ui-button>
            <ui-button
                prepend-icon='ok'
                background='primary'
                @click='onSave'
            >{{ $t('Išsaugoti') }}
            </ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import dialogMixin from '@/mixins/dialogMixin'
import HraIcon from '@/components/ui/HraIcon.vue'
import Form from '@/components/ui/form/Form.vue'
import TextField from '@/domain/fields/TextField.vue'
import MessageAlerts from '@/domain/messages/MessageAlerts.vue'
import SelectField from '@/domain/fields/SelectField.vue'
import customApi from '@/plugins/customApi'
import UiButton from '@/domain/components/UiButton.vue'
import EmployeeTableView from '@/components/ui/views/EmployeeTableView.vue'
import departmentModalMixin from '@/domain/company/mixins/departmentModalMixin'
import ValidationForm from '@/domain/components/ValidationForm.vue'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import { GLOBAL_TOAST_TAG } from '@/utils/default'
import UserAutocompleteField from '@/domain/users/components/UserAutocompleteField.vue'
import HraTable from '@/components/ui/table/HraTable.vue'
import TableTitleView from '@/components/ui/views/TableTitleView.vue'
import SettingEventApprovalByIdOrderLevel from '@/domain/settings/components/SettingEventApprovalByIdOrderLevel.vue'
import MessageAlert from '@/domain/messages/components/MessageAlert.vue'
import HraTooltip from '@/components/ui/HraTooltip.vue'
import HraButtonModal from '@/components/ui/button/HraButtonModal.vue'
import UiIcon from '@/domain/components/UiIcon.vue'
import ApprovalLevels from '@/domain/components/ApprovalLevel/components/ApprovalLevels.vue'
import ApprovalLevel from '@/domain/components/ApprovalLevel/components/ApprovalLevel.vue'

export default {
    name: 'DepartmentUpdateModal',
    mixins: [dialogMixin, departmentModalMixin],
    components: {
        ApprovalLevel,
        ApprovalLevels,
        UiIcon,
        HraButtonModal,
        HraTooltip,
        MessageAlert,
        SettingEventApprovalByIdOrderLevel,
        TableTitleView, HraTable,
        UserAutocompleteField,
        CustomDialog,
        ValidationForm,
        EmployeeTableView,
        UiButton, SelectField, MessageAlerts, TextField, Form, HraIcon
    },
    props: {
        id: { type: [Number, String], required: true }
    },
    created() {
        this.getDepartments()
    },
    data() {
        return {
            formData: {
                orders: [],
            },
        }
    },
    async beforeMount() {
        const api = customApi()
        await api.request(api.factories.company.departments.fetchById(this.id))
            .then(data => {
                if (data == null) {
                    return
                }

                this.visibleParent = data.departmentId != null
                this.formData.name = data.name
                this.formData.departmentId = data.departmentId
                this.userId = data.hasDirectLeader ? data.leader?.id ?? null : null

                if (data.eventApprovalSetting) {
                    this.users = data.eventApprovalSetting.users.map(user => ({
                        ...user,
                        department: user.department?.name ?? '-',
                        duty: user.duty?.name ?? '-'
                    }))

                    this.formData.orders = data.eventApprovalSetting.orders
                }

                this.formData.holidayEventMinDeputyCount = data.eventSettings.minDeputies
                this.formData.holidayEventMaxDeputyCount = data.eventSettings.maxDeputies
            })
            .finally(() => this.loading = false)
    },
    beforeDestroy() {
        this.$store.dispatch('messagesStore/clearMessages', this.tagId)
    },
    methods: {
        async onSave() {
            const api = customApi(this.tagId)
            const payload = await this.getPayload()
            const response = await api.request(api.factories.company.departments.update(this.id, payload))

            if (response == null) {
                return
            }

            this.$emit('saved')

            await this.$store.dispatch('messagesStore/showSuccessMessage', {
                tag: GLOBAL_TOAST_TAG,
                message: this.$t('Sėkmingai pakeista')
            })

            this.close()
        }
    }
}
</script>