export default axios => ({
    fetch: (params) => axios.get(`/api/v2/departments/vacations`, { params }),
    fetchWaiting: () => axios.get(`/api/v2/departments/vacations/waiting`),
    get: (id) => axios.get(`/api/events/${id}/holidays/details`),
    change: (id, payload) => axios.put(`/api/v2/departments/vacations/${id}`, payload),
    leader: {
        approve: (id, payload) => axios.post(`/api/events/${id}/holidays/leader/approve`, payload),
        abort: (id, payload) => axios.post(`/api/events/${id}/holidays/leader/abort`, payload)
    },
    admin: {
        approve: (id, payload) => axios.post(`/api/events/${id}/holidays/admin/approve`, payload),
        abort: (id, payload) => axios.post(`/api/events/${id}/holidays/admin/abort`, payload)
    }
});
