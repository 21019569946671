import moment from "moment";

//  initial state

const defaultState = () => ({
    yearFrom: moment().subtract(1, "years").year(),
    yearTo: moment().add(1, "years").year(),
    yearCurrent: moment().year(),
    countryCode: 'LT',
    holidayNames: {},
    calendarData: [],
    notApproveData: [],
    planned: [],
    past: [],
    abort: [],
    needToRefresh: null,
});

const state = () => ({
    ...defaultState(),
});

// getters
const getters = {
    yearFrom(state) {
        return state.yearFrom;
    },
    yearTo(state) {
        return state.yearTo;
    },
    needToRefresh(state) {
        return state.needToRefresh;
    },
    years(state) {
        return Array.apply(null, Array(state.yearTo - state.yearFrom + 1))
            .map((_, i) => state.yearFrom + i);
    },
    yearCurrent(state) {
        return state.yearCurrent;
    },
    countryCode(state) {
        return state.countryCode;
    },
    calendarData(state) {
        return state.calendarData;
    },
    holidayNames(state) {
        return state.holidayNames;
    },
    notApproveRows(state) {
        return state.notApproveData.map(row => ({...row, actions: ''}));
    },
    plannedRows(state) {
        return state.planned.map(row => ({...row, actions: ''}));
    },
    pastRows(state) {
        return state.past.map(row => ({...row, actions: ''}));
    },
    abortRows(state) {
        return state.abort.map(row => ({...row, actions: ''}));
    },
};

// actions
const actions = {};

//  mutations
const mutations = {
    changeYear(state, year) {
        state.yearCurrent = year;
        state.yearFrom = moment(`${year}-01-01`).subtract(1, "years").year();
        state.yearTo = moment(`${year}-01-01`).add(1, "years").year();
    },
    prevYear(state) {
        state.yearCurrent = state.yearFrom;
        state.yearFrom = moment(`${state.yearCurrent}-01-01`).subtract(1, "years").year();
        state.yearTo = moment(`${state.yearCurrent}-01-01`).add(1, "years").year();
    },
    nextYear(state) {
        state.yearCurrent = state.yearTo;
        state.yearFrom = moment(`${state.yearCurrent}-01-01`).subtract(1, "years").year();
        state.yearTo = moment(`${state.yearCurrent}-01-01`).add(1, "years").year();
    },
    loadCalendarData(state, payload) {
        state.holidayNames = payload.holidayNames;
        state.calendarData = payload.calendarData;
        // state.calendarData = [];
    },
    loadNotApproveData(state, payload) {
        state.notApproveData = payload;
    },
    loadPlannedData(state, payload) {
        state.planned = payload;
    },
    loadPastData(state, payload) {
        state.past = payload;
    },
    loadAbortData(state, payload) {
        state.abort = payload;
    },
    setNeedToRefresh(state) {
        state.needToRefresh = (new Date()).getTime();
    },
    clearData(state) {
        Object.assign(state, defaultState());
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
