const initState = () => ({
  daysPerYear: 0,
  plusDays: 0,
  motherFatherDays: {
    accumulated: 0,
    reserved: 0,
    balance: 0,
  },
  yearly: {
    accumulated: 0,
    reserved: 0,
    balance: 0,
  },
});

const state = () => initState();

// getters
const getters = {
  daysPerYear: state => state.daysPerYear,
  plusDays: state => state.plusDays,
  motherFatherDays: state => state.motherFatherDays,
  yearly: state => state.yearly,
};

// actions
const actions = {
  init: ({commit}, payload) => {
    commit("init", payload);
  },
  destroy: ({commit}) => commit('destroy'),
};

//  mutations
const mutations = {
  init: (state, payload) => Object.assign(state, payload),
  destroy: state => Object.assign(state, initState()),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
