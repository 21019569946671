<template>
    <div class='d-flex flex-column gap-2'>
        <multiline-select-field
            :label='$t("setting.module.SettingHolidayAllAvailablePayTypes.form.types")'
            :search-placeholder='$t("setting.module.SettingHolidayAllAvailablePayTypes.form.typesPlaceholder")'
            select-by-key='value'
            :min='1'
            :options='availableTypes'
            :disabled='!isEdit'
            rules='required'
            v-model='config.types'
        />
        <select-field
            :label='$t("setting.module.SettingHolidayAllAvailablePayTypes.form.defaultType")'
            :options='defaultTypes'
            :disabled='!isEdit'
            rules='required'
            v-model='config.defaultType'
        />
        <form-checkbox
            :is-checked.sync='config.availableToChange'
            :disabled='!isEdit'
        >
            <span class='pl-2'>{{ $t('setting.module.SettingHolidayAllAvailablePayTypes.form.availableTypes') }}</span>
        </form-checkbox>
    </div>
</template>

<script>
import MultilineSelectField from '@/domain/components/MultilineSelectField.vue'
import SelectField from '@/domain/fields/SelectField.vue'
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import { availableHolidayPayTypes } from '@/utils/default'

export default {
    name: 'SettingHolidayAllAvailablePayTypesForm',
    components: { FormCheckbox, SelectField, MultilineSelectField },
    props: {
        isEdit: { type: Boolean, default: false },
        config: { type: Object, default: () => ({}) }
    },
    data() {
        return {
            availableTypes: availableHolidayPayTypes
        }
    },
    watch: {
        config: {
            handler(config) {
                if (!config.types.includes(config.defaultType)) {
                    config.defaultType = config.types[0]
                }
            },
            deep: true
        }
    },
    computed: {
        defaultTypes() {
            return this.availableTypes
                .filter(type => this.config.types.includes(type.value))
                .map(type => ({ value: type.value, text: type.title }))
        }
    }
}
</script>