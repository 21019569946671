export default {
  props: {
    id: {type: String, required: true},
    shown: {type: Boolean, default: true},
    type: {
      type: String,
      required: true,
      validator: function (value) {
        return ["error", "success", "info", "warning", "default"].includes(value);
      },
    },
    message: {type: String, required: true},
    noBorderRadius: {type: Boolean, default: false},
    transitionDelay: {type: Number, default: 0},
  },
  data() {
    return {
      closeDelay: 3000,
    };
  },
  watch: {
    shown: {
      handler(value) {
        if (value) {
          return;
        }

        setTimeout(() => {
          this.$destroy();
        }, 100 + this.transitionDelay);
      },
      immediate: true,
    },
  },
  computed: {
    iconClass() {
      return "icon-" + ({
        error: "message-error",
        success: "message-success",
        info: "message-info",
        warning: "warning",
        default: "warning",
      })[this.type];
    },
  },
  async beforeDestroy() {
    await this.close();
  },
  methods: {
    async close() {
      await this.$store.dispatch("messagesStore/closeMessage", this.id);
    },
    closeWithDelay() {
      setTimeout(async () => {
        await this.$store.dispatch("messagesStore/closeMessage", this.id);
      }, this.closeDelay);
    },
  },
}