export default {
    computed: {
        documentTypes: function() {
            return this.$store.getters['documents/document/getDocumentTypes']
        },
        isDocumentStatusesLoaded: function() {
            return this.$store.getters['documents/document/isDocumentStatusesLoaded']
        },
        documentTypeInvoiceIds() {
            return this.documentTypes.filter(({type}) => type === 'invoice').map(({id}) => id)
        },
        documentTypeOptions() {
            return this.documentTypes?.map(({ id, name }) => ({
                value: id,
                text: name
            })) ?? []
        }
    },
    created() {
        this.$store.dispatch('documents/document/loadDocumentTypes')
    },
    methods: {
        checkIsAllDocumentsAsInvoices(documents) {
            return documents.every(({documentTypeId}) => this.documentTypeInvoiceIds.includes(documentTypeId))
        }
    }
}
