<template>
    <tr>
        <td>
            <div class='d-flex flex-row align-center'>
                <div>
                    <ui-icon style='color: #8689A8'>paper</ui-icon>
                    <span
                        class='ml-2'
                    >{{ archiveDocument.title }}</span>
                </div>
            </div>
        </td>
        <td>{{ archiveDocument.sizeText }}</td>
        <td>{{ archiveDocument.createdAt }}</td>
        <td>{{ archiveDocument.updatedAt }}</td>
        <td v-if='withDeleteInfo'>{{ archivedAt }}<br>{{ archivedBy }}</td>
        <td v-if='withControls'>
            <div class='d-flex flex-row align-center justify-end'>
                <ui-button
                    class='px-0'
                    background='primary'
                    size='medium'
                    only-text
                    @click='handleOpenPreviewDocumentModal'
                >{{ $t('Peržiūrėti') }}
                </ui-button>
                <v-spacer />
                <documents-finder-context-menu>
                    <documents-finder-context-menu-item
                        icon='restore'
                        :title='$t("Atstatyti dokumentą")'
                        @click='handleOpenRestoreDocumentModal'
                    />
                    <documents-finder-context-menu-item
                        icon='bin'
                        :title='$t("Ištrinti dokumentą")'
                        @click='handleOpenWipeDocumentModal'
                    />
                </documents-finder-context-menu>
            </div>
        </td>
    </tr>
</template>

<script>
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue'
import UiIcon from '@/domain/components/UiIcon.vue'
import DocumentsFinderContextMenu from '@/domain/documents/finder/components/ContextMenu.vue'
import UiButton from '@/domain/components/UiButton.vue'
import DocumentsFinderContextMenuItem from '@/domain/documents/finder/components/ContextMenuItem.vue'
import documentFinderDocumentItemMixin from '@/domain/documents/finder/mixins/documentFinderDocumentItemMixin'
import documentFinderArchivedDocumentItemMixin
    from '@/domain/documents/finder/mixins/documentFinderArchivedDocumentItemMixin'

export default {
    name: 'ListArchivedDocumentItem',
    components: { DocumentsFinderContextMenuItem, UiButton, DocumentsFinderContextMenu, UiIcon, FormCheckbox },
    mixins: [documentFinderArchivedDocumentItemMixin],
    computed: {
        archivedAt() {
            return this.archiveDocument?.documentArchive?.createdAt
        },
        archivedBy() {
            return this.archiveDocument?.documentArchive?.user?.name ?? ''
        }
    }

}
</script>