<template>
    <div class='scheduler-sidebar' ref='sidebar'>
        <div class='scheduler-sidebar__container'>
            <table class='scheduler-table'>
                <thead class='scheduler-header'>
                <slot name='header' />
                </thead>
                <tbody class='scheduler-body'>
                <slot name='body' />
                </tbody>
            </table>
        </div>
        <div class='scheduler-sidebar__splitter' ref='splitter' @mousedown='onMouseDown' />
    </div>
</template>
<script>
export default {
    name: 'SchedulerSidebar',
    data: function() {
        return {
            clicked: false
        }
    },
    mounted() {
        document.addEventListener('mousemove', this.onMouseMoveEvent)
        document.addEventListener('mouseup', this.onMouseUp)
    },
    methods: {
        onMouseDown() {
            this.clicked = true
        },
        onMouseMoveEvent(event) {
            if (!this.clicked) {
                return
            }

            const width = event.clientX - this.$refs.sidebar.getBoundingClientRect().x
            this.$refs.sidebar.style.width = event.clientX + 'px'
        },
        onMouseUp() {
            this.clicked = false
        }
    }
}
</script>