import {
    allEventTypes,
    EVENT_BUSINESS_TRIP_EMPLOYEE,
    EVENT_BUSINESS_TRIP_LEADER,
    EVENT_HOLIDAY_TYPE_CHILDCARE,
    EVENT_HOLIDAY_TYPE_DEPUTY,
    EVENT_HOLIDAY_TYPE_FATHER_OR_MOTHER, EVENT_HOLIDAY_TYPE_REMOTE_WORK,
    EVENT_HOLIDAY_TYPE_YEARLY, EVENT_SICK_LEAVE,
    eventHolidayType,
    VACATION_STATUS_ABORT,
    VACATION_STATUS_WAIT,
    vacationType
} from '@/utils/default'
import i18n from '@/plugins/i18n'
import store from '@/store'

const getCalendarEventName = (type, status) => {
    const eventCalendarStatus = {
        'created': 'wait',
        'updated': 'wait',
        'pending': 'wait',
        'approved': 'approve',
        'aborted': 'abort',
        'changed': 'abort'
    }

    if (type === EVENT_HOLIDAY_TYPE_DEPUTY) {
        return `deputy--${eventCalendarStatus[status]}`
    }

    let name = 'vacation'

    if (type === EVENT_HOLIDAY_TYPE_CHILDCARE) {
        name = 'childcare'
    }

    if (type === EVENT_HOLIDAY_TYPE_FATHER_OR_MOTHER) {
        name = 'father-or-mother'
    }

    if (type === EVENT_HOLIDAY_TYPE_REMOTE_WORK) {
        name = 'remote-work'
    }

    if (type === EVENT_SICK_LEAVE) {
        name = 'sick-leave'
    }

    if (type === EVENT_BUSINESS_TRIP_LEADER || type === EVENT_BUSINESS_TRIP_EMPLOYEE) {
        name = 'business-trip'
    }

    return `${name}--${eventCalendarStatus[status]}`
}

export const getDeputyTitle = (status, name, userId) => {
    const currentUserId = store.getters['currentUserStore/userId']

    if (['created', 'updates'].includes(status)) {
        return i18n.t('Prašo pavaduoti <b>{name}</b>', { name })
    }

    if (userId === currentUserId) {
        return i18n.t('Pavaduojate <b>{name}</b>', { name })
    }

    return i18n.t('Pavaduos <b>{name}</b>', { name })
}

export const waitingEvents = ({ vacationsToApprove }) => {
    return vacationsToApprove
        .map(invite => {

            if (invite.type === 'remote-work') {
                return invite
            }

            const deputies = invite.approvalable?.deputies
                ?.filter(({ currentStatus }) => currentStatus === 'approved') ?? []

            return {
                ...invite,
                approvalable: {
                    ...invite.approvalable,
                    deputies
                }
            }
        })
        .sort((a, b) => a.approvalable.start < b.approvalable.start ? 1 : -1)
}

export const isLeader = ({ isLeader }) => isLeader
export const isAdmin = ({ isAdmin }) => isAdmin

export const selectedEventId = ({ selectedEventId }) => selectedEventId

export const departmentOptions = ({ departmentOptions }) => departmentOptions

export const availableDutyIds = ({ dutyOptions, employees, filter }) => {
    if (filter.departments.length === 0) {
        return dutyOptions.map(({ value }) => value)
    }

    const duties = employees.filter(({ departmentId }) => filter.departments.includes(departmentId)).map(({ dutyId }) => dutyId)
    return [...new Set([].concat.apply([], duties))]
}

export const dutyOptions = ({ dutyOptions }, { availableDutyIds }) => {
    return dutyOptions
        .filter((({ value }) => availableDutyIds.includes(value)))
        .sort((a, b) => a.text.localeCompare(b.text))
}

export const holidays = ({ holidays }) => holidays

export const detailVacations = ({ detailVacations }) => (id) => detailVacations[id]

export const filter = ({ filter }) => filter

export const fromDate = ({ filter: { fromDate } }) => fromDate

export const filterEmployees = ({ employees, filter: { employeeIds, departments, duties } }) => {
    const employeeIdLength = employeeIds.length
    const departmentLength = departments.length
    const dutyLength = duties.length

    return employees.filter(employee => {
        const hasId = employeeIdLength > 0 ? employeeIds.includes(employee.id) : true
        const hasDepartment = departmentLength > 0 ? departments.includes(employee.departmentId) : true
        const hasDuty = dutyLength > 0 ? duties.includes(employee.dutyId) : true

        return hasId && hasDepartment && hasDuty
    })
}

export const calendarColorAndTexts = (state, getters, rootState, rootGetters) => {
    const userConfig = rootGetters['currentUserStore/configs']
    const ignoreConfigs = rootGetters['currentUserStore/isAdmin']
        || rootGetters['currentUserStore/isLeader']
        || rootGetters['currentUserStore/isSubLeader']

    function getHolidayName(defaultName) {
        if (ignoreConfigs) {
            return defaultName
        }

        return userConfig.settingHolidayAllEmployeeVisibleCompanyEvents.mode === 'not_at_work'
            ? 'not-at-work'
            : defaultName
    }

    function getSickLeaveName(defaultName) {
        if (ignoreConfigs) {
            return defaultName
        }

        return userConfig.settingSickLeaveEmployeeVisibleCompanyEvents.mode === 'not_at_work'
            ? 'not-at-work'
            : defaultName
    }

    function getBusinessTripName(defaultName) {
        if (ignoreConfigs) {
            return defaultName
        }

        return userConfig.settingSickLeaveEmployeeVisibleCompanyEvents.mode === 'not_at_work'
            ? 'not-at-office'
            : defaultName
    }

    function getRemoteWorkName(defaultName) {
        if (ignoreConfigs) {
            return defaultName
        }

        return userConfig.settingSickLeaveEmployeeVisibleCompanyEvents.mode === 'not_at_work'
            ? 'not-at-office'
            : defaultName
    }

    const calendarEventTypeNames = {
        [allEventTypes.EVENT_HOLIDAY_TYPE_YEARLY]: getHolidayName('vacation'),
        [allEventTypes.EVENT_HOLIDAY_TYPE_FATHER_OR_MOTHER]: getHolidayName('father-or-mother'),
        [allEventTypes.EVENT_HOLIDAY_TYPE_MATERNITY]: getHolidayName('vacation'),
        [allEventTypes.EVENT_HOLIDAY_TYPE_PARENTAL]: getHolidayName('vacation'),
        [allEventTypes.EVENT_HOLIDAY_TYPE_CHILDCARE]: getHolidayName('childcare'),
        [allEventTypes.EVENT_HOLIDAY_TYPE_STUDY]: getHolidayName('vacation'),
        [allEventTypes.EVENT_HOLIDAY_TYPE_CREATIVE]: getHolidayName('vacation'),
        [allEventTypes.EVENT_HOLIDAY_TYPE_PUBLIC]: getHolidayName('vacation'),
        [allEventTypes.EVENT_HOLIDAY_TYPE_FREE]: getHolidayName('vacation'),
        [allEventTypes.EVENT_HOLIDAY_TYPE_ONE_WORK_DAY]: getHolidayName('vacation'),
        [allEventTypes.EVENT_HOLIDAY_TYPE_FIRST_SCHOOL_DAY]: getHolidayName('vacation'),
        [allEventTypes.EVENT_HOLIDAY_TYPE_OTHER]: getHolidayName('vacation'),
        [allEventTypes.EVENT_SICK_LEAVE]: getSickLeaveName('sick-leave'),
        [allEventTypes.EVENT_BUSINESS_TRIP_LEADER]: getBusinessTripName('business-trip'),
        [allEventTypes.EVENT_BUSINESS_TRIP_EMPLOYEE]: getBusinessTripName('business-trip'),
        [allEventTypes.EVENT_HOLIDAY_TYPE_REMOTE_WORK]: getRemoteWorkName('remote-work'),
        [allEventTypes.EVENT_HOLIDAY_TYPE_DEPUTY]: 'deputy'
    }

    const eventCalendarStatus = {
        'created': 'wait',
        'updated': 'wait',
        'pending': 'wait',
        'approved': 'approve',
        'aborted': 'abort',
        'changed': 'abort'
    }

    return (type, status) => {
        const calendarEventTypeName = calendarEventTypeNames[type]
        let text = eventHolidayType[type]

        if (calendarEventTypeName === 'not-at-work') {
            text = i18n.t('Nedirba')
        }

        if (calendarEventTypeName === 'not-at-office') {
            text = i18n.t('Nėra biure')
        }

        return {
            name: `${calendarEventTypeName}--${eventCalendarStatus[status]}`,
            text
        }
    }
}

export const employees = ({ isAdmin, isLeader, events, userBalances, canApproveUserIds }, {
    filterEmployees,
    calendarColorAndTexts
}, { currentUserStore }) => {

    return filterEmployees.map(employee => {
        let balance = userBalances[employee.id]?.toFixed(2) ?? '-'


        return {
            ...employee,
            balance,
            events: events.filter(event => event.userId === employee.id)
                .map(event => {
                    if (event.type === EVENT_HOLIDAY_TYPE_DEPUTY) {
                        return {
                            ...event,
                            isDeputy: true,
                            name: calendarColorAndTexts(event.type, event.currentStatus).name,
                            text: getDeputyTitle(event.currentStatus, event.parent.user.name, event.user.id)
                        }
                    }

                    return {
                        ...event,
                        ...calendarColorAndTexts(event.type, event.currentStatus),
                        // name: getCalendarEventName(event.type, event.currentStatus),
                        // text: eventHolidayType[event.type],
                        canApprove: canApproveUserIds.includes(event.user.id)
                    }
                }).sort((a, b) => a.start < b.start ? -1 : 1)
        }
    }).sort((a, b) => a.name.localeCompare(b.name))
}

export const employeeOptions = ({ employees, filter: { departments, duties } }) => {
    const departmentLength = departments.length
    const dutyLength = duties.length

    return employees.filter(employee => {
        const hasDepartment = departmentLength > 0 ? departments.includes(employee.departmentId) : true
        const hasDuty = dutyLength > 0 ? departments.includes(employee.dutyId) : true

        return hasDepartment && hasDuty
    }).map(({ id, name }) => ({
        value: id,
        text: name
    })).sort((a, b) => a.text.localeCompare(b.text))
}
