<template>
	<v-expansion-panels
		v-model="panel"
		multiple
	>
		<slot/>
	</v-expansion-panels>
</template>

<script>
export default {
	name: "ExpansionPanels",
	props: {
		value: { type: Array, required: true,},
	},
	data() {
		return {
			panel: [],
		};
	},
	watch: {
		panel: {
			handler(newPanel) {
				this.$emit('input', newPanel);
			},
		}
	},
	created() {
		this.panel = this.value;
	}
}
</script>