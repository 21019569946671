import Vue from 'vue'

/* BASE */
export const SET_SCHEDULE_ID = 'SET_SCHEDULE_ID'
export const SET_SCHEDULE_NAME = 'SET_SCHEDULE_NAME'
export const SET_FROM_DATETIME = 'SET_FROM_DATETIME'
export const SET_TO_DATETIME = 'SET_TO_DATETIME'
export const SET_SELECTED_TIME_INTERVAL = 'SET_SELECTED_TIME_INTERVAL'
export const SET_SELECTED_TIMETABLE_INTERVAL = 'SET_SELECTED_TIMETABLE_INTERVAL'
export const SET_DURATION_BY_USER_ID = 'SET_DURATION_BY_USER_ID'
export const SET_MINUS_BREAKS = 'SET_MINUS_BREAKS'
export const SET_HOLIDAYS = 'SET_HOLIDAYS'
export const SET_CURRENT_TAB = 'SET_CURRENT_TAB'
export const INIT_SCHEDULES = 'INIT_SCHEDULES'
const baseMutations = ({
    [SET_SCHEDULE_ID]: (state, payload) => state.scheduleId = payload,
    [SET_SCHEDULE_NAME]: (state, payload) => state.scheduleName = payload,
    [SET_FROM_DATETIME]: (state, payload) => state.from = payload,
    [SET_TO_DATETIME]: (state, payload) => state.to = payload,
    [SET_SELECTED_TIME_INTERVAL]: (state, payload) => state.selectedTimeInterval = payload,
    [SET_SELECTED_TIMETABLE_INTERVAL]: (state, payload) => state.selectedTimetableInterval = payload,
    [SET_DURATION_BY_USER_ID]: (state, payload) => state.durationByUserId = payload,
    [SET_MINUS_BREAKS]: (state, payload) => state.minusBreaks = payload,
    [SET_CURRENT_TAB]: (state, payload) => state.currentTab = payload,
    [SET_HOLIDAYS]: (state, payload) => state.holidays = payload,
    [INIT_SCHEDULES]: (state, payload) => state.schedules = payload
})

/* LINES */
export const INIT_LINES = 'INIT_LINES'
export const CREATE_NEW_LINE = 'CREATE_NEW_LINE'
export const UPDATE_LINE_BY_ID = 'UPDATE_LINE_BY_ID'
export const DELETE_LINE_BY_ID = 'DELETE_LINE_BY_ID'
const lineMutations = ({
    [INIT_LINES]: (state, payload) => state.lines = payload,
    [CREATE_NEW_LINE]: (state, payload) => state.lines.push(payload),
    [UPDATE_LINE_BY_ID]: (state, payload) => {
        const index = state.lines.findIndex(({id}) => id === payload.id)

        if (index < 0) {
            return
        }

        Vue.set(state.lines, index, payload)
    },
    [DELETE_LINE_BY_ID]: (state, payload) => state.lines = state.lines.filter(({id}) => id !== payload)
})

/* BREAKS */
export const INIT_BREAKS = 'INIT_BREAKS'
export const CREATE_NEW_BREAK = 'CREATE_NEW_BREAK'
export const UPDATE_BREAK_BY_ID = 'UPDATE_BREAK_BY_ID'
export const DELETE_BREAK_BY_ID = 'DELETE_BREAK_BY_ID'
export const TOGGLE_BREAK_BY_ID = 'TOGGLE_BREAK_BY_ID'
const breakMutations = ({
    [INIT_BREAKS]: (state, payload) => state.breaks = payload.map(item => ({
        ...item,
        checked: true
    })),
    [CREATE_NEW_BREAK]: (state, payload) => state.breaks.push({
        ...payload,
        checked: true
    }),
    [UPDATE_BREAK_BY_ID]: (state, payload) => {
        const index = state.breaks.findIndex(({id}) => id === payload.id)

        if (index < 0) {
            return
        }

        Vue.set(state.breaks, index, payload)
    },
    [DELETE_BREAK_BY_ID]: (state, payload) => state.breaks = state.breaks.filter(({id}) => id !== payload),
    [TOGGLE_BREAK_BY_ID]: (state, payload) => {
        const index = state.breaks.findIndex(({id}) => id === payload.id)

        if (index < 0) {
            return
        }

        const item = state.breaks[index]
        Vue.set(state.breaks, index, {
            ...item,
            checked: !(item.checked ?? false)
        })
    }
})

/* TIME INTERVALS */
export const INIT_TIME_INTERVALS = 'INIT_TIME_INTERVALS'
export const CREATE_NEW_TIME_INTERVAL = 'CREATE_NEW_TIME_INTERVAL'
export const UPDATE_TIME_INTERVAL_BY_ID = 'UPDATE_TIME_INTERVAL_BY_ID'
export const DELETE_TIME_INTERVAL_BY_ID = 'DELETE_TIME_INTERVAL_BY_ID'
const timeIntervalMutations = ({
    [INIT_TIME_INTERVALS]: (state, payload) => state.timeIntervals = payload,
    [CREATE_NEW_TIME_INTERVAL]: (state, payload) => state.timeIntervals.push(payload),
    [UPDATE_TIME_INTERVAL_BY_ID]: (state, payload) => {
        const index = state.timeIntervals.findIndex(({id}) => id === payload.id)

        if (index < 0) {
            return
        }

        Vue.set(state.timeIntervals, index, payload)
    },
    [DELETE_TIME_INTERVAL_BY_ID]: (state, payload) => state.timeIntervals = state.timeIntervals.filter(({id}) => id !== payload)
})

/* EVENTS */
export const INIT_EVENTS = 'INIT_EVENTS'
export const CREATE_NEW_EVENT = 'CREATE_NEW_EVENT'
export const UPDATE_EVENT_BY_ID = 'UPDATE_EVENT_BY_ID'
export const DELETE_EVENT_BY_ID = 'DELETE_EVENT_BY_ID'
const eventMutations = ({
    [INIT_EVENTS]: (state, payload) => state.events = payload,
    [CREATE_NEW_EVENT]: (state, payload) => state.events.push(payload),
    [UPDATE_EVENT_BY_ID]: (state, payload) => {
        const index = state.events.findIndex(({id}) => id === payload.id)

        if (index < 0) {
            return
        }

        Vue.set(state.events, index, payload)
    },
    [DELETE_EVENT_BY_ID]: (state, payload) => state.events = state.events.filter(({id}) => id !== payload)
})

/* TIMETABLES */
export const INIT_TIMETABLES = 'INIT_TIMETABLES'
export const CREATE_NEW_TIMETABLE = 'CREATE_NEW_TIMETABLE'
export const UPDATE_TIMETABLE_BY_ID = 'UPDATE_TIMETABLE_BY_ID'
export const DELETE_TIMETABLE_BY_ID = 'DELETE_TIMETABLE_BY_ID'
const timetablesMutations = ({
    [INIT_TIMETABLES]: (state, payload) => state.timetables = payload,
    [CREATE_NEW_TIMETABLE]: (state, payload) => state.timetables.push(payload),
    [UPDATE_TIMETABLE_BY_ID]: (state, payload) => {
        const index = state.timetables.findIndex(({id}) => id === payload.id)

        if (index < 0) {
            return
        }

        Vue.set(state.timetables, index, payload)
    },
    [DELETE_TIMETABLE_BY_ID]: (state, payload) => {
        state.timetables = state.timetables.filter(({id}) => id !== payload)
    }
})

/* TIMETABLE INTERVALS */
export const INIT_TIMETABLE_INTERVALS = 'INIT_TIMETABLE_INTERVALS'
export const CREATE_NEW_TIMETABLE_INTERVAL = 'CREATE_NEW_TIMETABLE_INTERVAL'
export const UPDATE_TIMETABLE_INTERVAL_BY_ID = 'UPDATE_TIMETABLE_INTERVAL_BY_ID'
export const DELETE_TIMETABLE_INTERVAL_BY_ID = 'DELETE_TIMETABLE_INTERVAL_BY_ID'
const scheduleTimetableIntervals = ({
    [INIT_TIMETABLE_INTERVALS]: (state, payload) => state.timetableIntervals = payload,
    [CREATE_NEW_TIMETABLE_INTERVAL]: (state, payload) => state.timetableIntervals.push(payload),
    [UPDATE_TIMETABLE_INTERVAL_BY_ID]: (state, payload) => {
        const index = state.timetableIntervals.findIndex(({id}) => id === payload.id)

        if (index < 0) {
            return
        }

        Vue.set(state.timetableIntervals, index, payload)
    },
    [DELETE_TIMETABLE_INTERVAL_BY_ID]: (state, payload) => state.timetableIntervals = state.timetableIntervals.filter(({id}) => id !== payload)
});

export default ({
    ...baseMutations,
    ...lineMutations,
    ...breakMutations,
    ...timeIntervalMutations,
    ...eventMutations,
    ...timetablesMutations,
    ...scheduleTimetableIntervals
})