import DocumentsOverview from '@/domain/documents/pages/DocumentsOverview.vue'


import DocumentsManagementLayout from '@/domain/documents/layouts/DocumentsManagementLayout.vue'

import MainLayout from '@/domain/documents/layouts/MainLayout'

import DocumentUserBoardPage from '@/domain/documents/userBoard/Page.vue'


import DocumentsItemsLayout from '@/domain/documents/items/Layout.vue'
import DocumentsFolders from '@/domain/documents/items/folders/Page.vue'
import DocumentsGroups from '@/domain/documents/items/groups/Page.vue'
import DocumentsArchive from '@/domain/documents/items/archive/Page.vue'

import DocumentSummaryLayout from '@/domain/documents/summary/Layout.vue'
import DocumentSummaryMainPage from '@/domain/documents/summary/main/Page.vue'
import DocumentSummaryDocumentsPage from '@/domain/documents/summary/documents/Page.vue'
import DocumentSummaryUsersPage from '@/domain/documents/summary/users/Page.vue'

import DocumentsExpirationTrackingLayout from '@/domain/documents/expirationTracking/Layout.vue'
import DocumentsExpirationTrackingPage from '@/domain/documents/expirationTracking/main/Page.vue'

import DocumentPublicSignPage from '@/domain/documents/publicSign/Page.vue'
import i18n from '@/plugins/i18n'

export default [
    {
        name: 'DocumentPublicSignPage',
        path: '/documents/public/sign/:token',
        component: DocumentPublicSignPage,
        meta: { canGuest: true }
    },
    {
        path: '/documents',
        component: MainLayout,
        children: [
            { name: 'DocumentsUser', path: 'user/board', component: DocumentUserBoardPage },
            {
                name: 'DocumentsManagement',
                path: 'manage',
                component: DocumentsManagementLayout,
                children: [
                    {
                        name: 'DocumentsOverview',
                        path: '',
                        component: DocumentsOverview,
                        meta: {
                            title: i18n.t('Dokumentų valdymas'),
                            titleIcon: 'writing-gear'
                        }
                    },
                    {
                        name: 'Documents',
                        path: 'items',
                        component: DocumentsItemsLayout,
                        children: [
                            {
                                name: 'DocumentsFolders',
                                path: 'folders/:folderId?',
                                component: DocumentsFolders,
                                meta: {
                                    title: i18n.t('Aplankai'),
                                    titleIcon: 'folder'
                                }
                            },
                            {
                                name: 'DocumentsGroups',
                                path: 'groups/:folderId?',
                                component: DocumentsGroups,
                                meta: {
                                    title: i18n.t('Dokumentų grupavimas'),
                                    titleIcon: 'writing'
                                }
                            },
                            {
                                name: 'DocumentsArchive',
                                path: 'archive',
                                component: DocumentsArchive,
                                meta: {
                                    title: i18n.t('Ištrintų dokumentų archyvas'),
                                    titleIcon: 'bin'
                                }
                            }
                        ]
                    },
                    {
                        name: 'DocumentSummaryLayout',
                        path: 'summary',
                        component: DocumentSummaryLayout,
                        children: [
                            {
                                name: 'DocumentSummaryMainPage',
                                path: 'main',
                                component: DocumentSummaryMainPage
                            },
                            {
                                name: 'DocumentSummaryDocumentsPage',
                                path: 'documents',
                                component: DocumentSummaryDocumentsPage
                            },
                            {
                                name: 'DocumentSummaryUsersPage',
                                path: 'users',
                                component: DocumentSummaryUsersPage
                            }
                        ]
                    },
                    {
                        name: 'DocumentsExpirationTrackingLayout',
                        path: 'expire-tracking',
                        component: DocumentsExpirationTrackingLayout,
                        children: [
                            {
                                name: 'DocumentsExpirationTrackingPage',
                                path: '',
                                component: DocumentsExpirationTrackingPage
                            }
                        ]
                    }
                ]
            }
        ]
    }
]