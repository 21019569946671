import {
    EVENT_HOLIDAY_TYPE_CHILDCARE,
    EVENT_HOLIDAY_TYPE_CREATIVE, EVENT_HOLIDAY_TYPE_DEPUTY,
    EVENT_HOLIDAY_TYPE_FATHER_OR_MOTHER,
    EVENT_HOLIDAY_TYPE_FIRST_SCHOOL_DAY,
    EVENT_HOLIDAY_TYPE_FREE,
    EVENT_HOLIDAY_TYPE_ONE_WORK_DAY,
    EVENT_HOLIDAY_TYPE_PARENTAL,
    EVENT_HOLIDAY_TYPE_PUBLIC,
    EVENT_HOLIDAY_TYPE_STUDY,
    EVENT_HOLIDAY_TYPE_YEARLY,
    eventHolidayType,
    eventHolidayTypes,
    getDateArray
} from '@/utils/default'
import moment from 'moment'
import i18n from '@/plugins/i18n'
import { VACATION_MODAL_INIT_TAG } from '@/domain/vacationModal/tags'

export const user = ({ user }) => user
export const asAdmin = ({ asAdmin }) => asAdmin
export const buttonActions = ({ actions }) => actions
export const editableCalendar = ({actions}) => {
    return actions.change || actions.create || actions.update
}
export const currentEvent = ({ currentEvent }) => currentEvent
export const sendInviteForEmployeeAndLeader = ({sendInviteForEmployeeAndLeader}) => sendInviteForEmployeeAndLeader
export const currentEventAborted = ({ currentEventAborted }) => currentEventAborted

export const typeOptions = ({ asAdmin }) => {
    const onlyAdminTypes = [
        // EVENT_HOLIDAY_TYPE_CHILDCARE
    ]
    const availableTypes = asAdmin
        ? eventHolidayTypes
        : eventHolidayTypes.filter(type => !onlyAdminTypes.includes(type))

    return availableTypes.map(type => ({
        value: type,
        text: eventHolidayType[type]
    }))
}

export const fields = ({ asAdmin, currentEvent, actions }, getters, rootState, rootGetters) => {
    const loading = rootGetters['loadersStore/hasLoading'](VACATION_MODAL_INIT_TAG)
    const disabled = loading || (!actions.change && !actions.create && !actions.update)

    return {
        user: { visible: !loading && asAdmin, disabled: true },
        type: { visible: true, disabled },
        start: { visible: true, disabled },
        end: { visible: true, disabled },
        payBeforeStart: {
            visible: [
                EVENT_HOLIDAY_TYPE_YEARLY,
                EVENT_HOLIDAY_TYPE_FATHER_OR_MOTHER,
                EVENT_HOLIDAY_TYPE_PARENTAL,
                EVENT_HOLIDAY_TYPE_CHILDCARE,
                EVENT_HOLIDAY_TYPE_FIRST_SCHOOL_DAY
            ].includes(currentEvent.type),
            disabled
        },
        deputies: {
            visible: [
                EVENT_HOLIDAY_TYPE_YEARLY,
                EVENT_HOLIDAY_TYPE_FATHER_OR_MOTHER,
                EVENT_HOLIDAY_TYPE_PARENTAL,
                EVENT_HOLIDAY_TYPE_CHILDCARE,
                EVENT_HOLIDAY_TYPE_STUDY,
                EVENT_HOLIDAY_TYPE_CREATIVE,
                EVENT_HOLIDAY_TYPE_PUBLIC,
                EVENT_HOLIDAY_TYPE_FREE,
                EVENT_HOLIDAY_TYPE_ONE_WORK_DAY,
                EVENT_HOLIDAY_TYPE_FIRST_SCHOOL_DAY
            ].includes(currentEvent.type),
            disabled
        },
        attachment: {
            visible: [
                EVENT_HOLIDAY_TYPE_STUDY,
                EVENT_HOLIDAY_TYPE_PUBLIC
            ].includes(currentEvent.type),
            disabled
        },
        message: { visible: true, disabled }
    }
}

export const calendarEvents = ({ events }) => {
    const eventCalendarStatus = {
        'pending': 'wait',
        'approved': 'approve',
        'aborted': 'abort',
    }
    let payload = {};

    events.filter(({ currentStatus }) => currentStatus !== 'aborted')
        .map(event => {
            const start = moment(event.start)
            const end = moment(event.end)
            const status = eventCalendarStatus[event.currentStatus] ?? 'none'
            const type = event.type
            let tooltip = eventHolidayType[event.type] ?? null
            if (event.type === EVENT_HOLIDAY_TYPE_DEPUTY) {
                tooltip = i18n.t('Pavaduojate <b>{name}</b>', { name: event?.parent?.user?.name ?? '' })
            }
            getDateArray(start, end).map(date => {
                payload[date] = {
                    isStart: date === event.start,
                    isEnd: date === event.end,
                    tooltip,
                    type,
                    status
                }
            })
        })

    return payload
}

export const approvals = ({approvalsTree}) => {
    console.debug('approvalsTree', approvalsTree)
    return approvalsTree?.orders ?? []
}