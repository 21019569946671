<template>
	<app-layout>
		<route-tabs class="mt-5" :tabs="tabs" />
	</app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import CustomTabs from "@/domain/components/CustomTabs";
import RouteTabs from "@/domain/components/RouteTabs";

export default {
	name: "DebuggerLayout",
	components: {RouteTabs, CustomTabs, AppLayout},
	data() {
		return {
			tabs: [
				{name: "DebuggerPage", text: "Debugger"},
				{name: "DebuggerReportsPage", text: "Ataskaitos"},
			]
		}
	},
}
</script>