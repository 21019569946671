import Vue from 'vue'

const state = () => ({
    selectedDepartmentIds: []
})

const getters = {
    selectedDepartmentIds: ({ selectedDepartmentIds }) => selectedDepartmentIds
}

const actions = {
    setDepartmentIds({ state }, departmentIds) {
        Vue.set(state, 'selectedDepartmentIds', departmentIds)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions
}