<template>
    <custom-dialog :max-width='512'>
        <template #title>{{ $t('Palikite komentarą') }}</template>
        <template #content>
            <v-row class='pa-3'>
                <v-col cols='12'>
                    <div class='document-sign-comment'>
                        <h1>{{ $t('Dokumentas') }}</h1>

                        <div class='document-sign-comment__file mt-3'>
                            <ui-icon>paper</ui-icon>
                            <div class='document-sign-comment__file-text'>{{ item.document.title }}</div>
                        </div>
                    </div>
                </v-col>
                <v-col cols='12'>
                    <textarea-field
                        :label='$t("Palikite komentarą")'
                        :placeholder='$t("Palikite komentarą")'
                        v-model='comment'
                    />
                    <message-alert
                        v-if='!!error'
                        class='mt-2'
                        id='---'
                        :message='error'
                        type='error'
                    />
                </v-col>
            </v-row>
        </template>

        <template #footer>
            <ui-button
                prepend-icon='close'
                @click='close'
            >{{ $t('Uždaryti') }}</ui-button>
            <ui-button
                prepend-icon='ok'
                background='success'
                @click='handleSubmit'
            >{{ $t('Siųsti') }}</ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import dialogMixin from '@/mixins/dialogMixin'
import UiButton from '@/domain/components/UiButton.vue'
import DocxPreview from '@/domain/documents/components/DocxPreview.vue'
import ExcelPreview from '@/domain/documents/components/ExcelPreview.vue'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import TextareaField from '@/domain/fields/TextareaField.vue'
import MessageAlert from '@/domain/messages/components/MessageAlert.vue'
import UiIcon from '@/domain/components/UiIcon.vue'

export default {
    name: 'DocumentSignCommentModal',
    components: { UiIcon, MessageAlert, TextareaField, UiButton, ExcelPreview, DocxPreview, CustomDialog },
    mixins: [dialogMixin],
    props: {
        item: { type: Object, required: true },
        action: {type: Function, required: true}
    },
    data() {
        return {
            loading: false,
            error: null,
            comment: ''
        }
    },
    methods: {
        async handleSubmit() {
            if (this.comment.length < 3) {
                this.error = this.$t('Komentaras turi būti ilgesnis nei 3 simboliu')
                return
            }

            this.error = null

            this.loading = true
            await this.action(this.comment)
            this.loading = false
        }
    }
}
</script>

<style lang='scss'>
.document-sign-comment {
    h1 {
        color: #1C2538;
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    &__file {
        border-radius: 4px;
        border: 1px solid #DAD5E9;
        background: #FFF;
        display: flex;
        flex-direction: row;
        gap: 14px;
        padding: 10px 24px;
        min-height: 64px;
        align-items: center;

        .ui-icon {
            font-size: 22px;
            color: #70778C;
        }

        &-text {
            color: #1C2538;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }
}
</style>