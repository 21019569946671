<template>
    <custom-dialog :max-width='900'>
        <template #title>{{ $t('Pasirinkite perkeliamo dokumento vietą') }}</template>
        <template #content>
            <folder-select-finder
                ref='Finder'
                class='pt-4 px-7'
                :folder-id='selectedFolderId'
            />
        </template>
        <template #footer>
            <ui-button prepend-icon='close' background='default' @click='close'>{{ $t('Uždaryti') }}</ui-button>
            <ui-button prepend-icon='ok' background='primary' @click='submit' :loading='loading'>
                {{ $t('Perkelti čia') }}
            </ui-button>
        </template>
    </custom-dialog>
</template>

<script>
import dialogMixin from '@/mixins/dialogMixin'
import customApi from '@/plugins/customApi'
import CustomDialog from '@/components/ui/dialog/CustomDialog.vue'
import UiButton from '@/domain/components/UiButton.vue'
import FolderSelectFinder from '@/domain/documents/components/finders/FolderSelectFinder.vue'

export default {
    name: 'DocumentMoveModal',
    components: { FolderSelectFinder, UiButton, CustomDialog },
    mixins: [dialogMixin],
    props: {
        item: { type: Object, required: true }
    },
    data() {
        return {
            selectedFolderId: this.item.documentId,
            loading: false
        }
    },
    methods: {
        async submit() {
            const folderId = this.$refs.Finder.getFolderId()
            if (folderId === this.item.documentId) {
                this.showSuccessInRootToast(this.$t('Perkelta sėkmingai'))
                this.close()
                return
            }

            this.loading = true

            try {
                const { data } = await customApi().factories.documents.documents.move(this.item.id, { folderId })
                this.$emit('documentMoved', data)
                this.showSuccessInRootToast(this.$t('Perkelta sėkmingai'))
                this.close()
            } catch (error) {
                this.showErrorInRootToast(error, this.$t('Nepavyko perkelti dokumento'))
            } finally {
                this.loading = false
            }
        }
    }
}
</script>