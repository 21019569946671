<template>
    <div class='d-flex flex-column gap-3'>
        <form-alert v-if='message' v-bind='message' />
        <div v-if='!disabled' ref='dropArea' class='avatar-upload-drop-area' @click='onClick'
             @drop.prevent='onDrop' @dragover.prevent='onHighlight' @dragenter='onHighlight' @dragleave='onUnHighlight'>
            <img src='@/assets/images/upload-icon.png' alt='upload image'>
            <p v-html="$t('Įkelkite dokumentą čia arba <span>spustelėkite</span>, kad juos pasirinktumėte.')" />
            <input ref='fileUpload' type='file' :accept="acceptTypes.join(', ')" @change='onChange' />
            <span>{{ $t('Dokumento dydis ne didesnis nei {size} MB.', { size: maxFileSize }) }}</span>
        </div>
        <div ref='files'>
            <form-alert v-for='({key, name, url}) in files' :key='key' icon='file'>
                <div class='d-flex justify-space-between align-center gap-3' style='width: 100%'>
                    <a v-if='url' target='_blank' :href='url'>{{ name }}</a>
                    <span v-else>{{ name }}</span>
                    <hra-button :size='buttonSize.auto' :color='uiColor.dangerLight' no-background only-icon
                                @click='onDelete(key)'>
                        <hra-icon :name='iconName.bin' />
                    </hra-button>
                </div>
            </form-alert>
        </div>
    </div>
</template>

<script>
import FormAlert from '@/components/ui/form/FormAlert'
import HraIcon from '@/components/ui/HraIcon'
import HraButton from '@/components/ui/button/HraButton'

export default {
    name: 'HraFormFile',
    components: { HraButton, HraIcon, FormAlert },
    props: {
        value: { type: [Array, String, Object, File, null], default: null },
        acceptTypes: { type: Array, default: () => ([]) },
        multiple: { type: Boolean, default: false },
        maxFileSize: { type: Number, default: 10 },
        disabled: { type: Boolean, default: false }
    },
    data() {
        return {
            message: null
        }
    },
    computed: {
        files() {
            if (this.value == null) {
                return []
            }

            return (this.multiple ? this.value : [this.value])
                .map((file, key) => ({
                    key,
                    name: typeof file === 'string' ? this.$t('Priedas') : file.name,
                    url: typeof file === 'string' ? `${file}` : null
                }))
        }
    },
    methods: {
        onHighlight() {
            this.$refs.dropArea.classList.add('highlight')
        },
        onUnHighlight() {
            this.$refs.dropArea.classList.remove('highlight')
        },
        onClick() {
            this.$refs.fileUpload.click()
        },
        onChange(event) {
            this.message = null
            const files = event.dataTransfer?.files || event.target?.files

            if (files.length > 1) {
                this.message = { type: 'error', text: 'error file length' }
                return
            }

            const file = files[0]
            if (this.acceptTypes.length > 0 && !this.acceptTypes.includes(file.type)) {
                this.message = { type: 'error', text: 'error file type ' + file.type }
                return
            }

            if (file.size / 1024 / 1024 > this.maxFileSize) {
                this.message = { type: 'error', text: 'error file size ' + this.maxFileSize }
                return
            }

            this.$refs.files.scrollIntoView()

            this.$emit('input', file)
        },
        onDrop(event) {
            this.onUnHighlight()
            this.onChange(event)
        },
        onDelete(key) {
            let files = this.value

            if (Array.isArray(files)) {
                // TODO: clear files when multiple
            } else {
                files = null
            }

            this.$emit('input', files)
        }
    }
}
</script>