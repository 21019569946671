import {VacationModalService} from "@/domain/vacationModal/services/vcationModalService";
import store from "@/store";
import customApi from "@/plugins/customApi";

export class LeaderVacationModalService extends VacationModalService {

  async init(tag, checkTag, currentId, employeeId) {
    const api = customApi(tag);
    await this.baseInit(
      tag,
      checkTag,
      await api.request(api.factories.employee.vacation.fetch(employeeId)),
      // await api.request(api.factories.employee.leaderVacations.fetch(null, employeeId)),
      currentId,
      employeeId
    );
    await store.dispatch("vacationModalStore/setIsAdmin");
  }

  async check(tag, employeeId) {
    if (!store.getters["vacationModalStore/canCheck"]) {
      return;
    }
    const id = store.getters["vacationModalStore/currentId"];
    const formData = store.getters["vacationModalStore/formData"];

    const payload = {
      typeId: formData.typeId,
      fromDate: formData.fromDate,
      toDate: formData.toDate,
      payBeforeStart: formData.payBeforeStart,
      deputyId: formData.deputyId,
    }

    const api = customApi(tag);

    employeeId = employeeId == null ? store.getters["authStore/employeeId"] : employeeId;

    const message = await api.request(api.factories.employee.vacation.checkAdmin(employeeId, id, payload));
    if (message) {
      await store.dispatch("messagesStore/showInfoMessage", {tag, message});
      await store.dispatch("loadersStore/hideLoader", tag);
    }
  }

  async create(tag, employeeId) {
    const api = customApi(tag);
    const formData = store.getters["vacationModalStore/formData"];
    const vacation = await api.request(api.factories.employee.vacation.storeAdmin(employeeId, this.formatFormData(formData)));
    // const vacation = await api.request(api.factories.employee.leaderVacations.store(this.formatFormData(formData), employeeId));


    if (vacation == null) {
      return false;
    }

    await store.dispatch("employeeStore/createVacation", vacation);
    await store.dispatch("loadersStore/hideLoader", tag);
    return true;
  }

  async change(tag, employeeId) {
    const id = store.getters["vacationModalStore/currentId"];
    const formData = store.getters["vacationModalStore/formData"];
    formData.period = [formData.fromDate, formData.toDate];

    const api = customApi(tag);
    const vacation = await api.request(api.factories.employee.vacation.updateAdmin(employeeId, id, this.formatFormData(formData)));
    // const event = await api.request(api.factories.employee.leaderVacations.change(id, this.formatFormData(formData), employeeId));
    // const vacation = await api.request(api.factories.employee.leaderVacations.update(id, this.formatFormData(formData), employeeId));

    if (vacation == null) {
      return false;
    }

    // await store.dispatch("employee/leaderVacations/change", {id, data: event});
    // await store.dispatch("employee/leaderVacations/update", event);
    await store.dispatch("employeeStore/changeVacation", vacation);
    await store.dispatch("loadersStore/hideLoader", tag);
    return true;
  }
}

export default new LeaderVacationModalService;